import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { combine } from '@turf/turf';
import { LoaderService } from 'loaderService';
import { ToastrService } from 'ngx-toastr';
import { PeopleService } from '../people.service';
import { ExcelService } from '../../amenity-booking/services/excel.service';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from "lodash";
import { GlobalSetupService } from '../../global-setup/services/global-setup.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-people-super-admin-modal',
  templateUrl: './people-super-admin-modal.component.html',
  styleUrls: ['./people-super-admin-modal.component.scss']
})
export class PeopleSuperAdminModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PeopleSuperAdminModalComponent>,
    private toastrService : ToastrService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private excelService :ExcelService,
    private peopleService: PeopleService,
    private router: Router,
    private globalSetupService:GlobalSetupService) { 

      this.filteredRoles= this.searchRoles.valueChanges.pipe(
        startWith(""),
        map((location) =>
            location
                ? this.filterLocations(location)
                : this.rolesData.slice()
        )
    );

    this.filteredTenantCompany= this.searchTenantCompany.valueChanges.pipe(
      startWith(""),
      map((location) =>
          location
              ? this.filterTenantCompany(location)
              : this.tenantCompanyData.slice()
      )
  );
  //this.getAllTenant(0,0);
  //this.getRolesList();
    
    }
    originalRolesLength
    TotalNoOfEntitiesUploadedRNW=0
  onboardUserSelected = false;
  rolesData=[];
  tenantCompanyData=[];
  filteredTenantCompany;
  addDepartmentType='';
  searchRoles = new FormControl("");
  searchTenantCompany= new FormControl("");
  selectedTenantComapny=null;
  fileName;
  fileToUpload;
  filteredRoles
  currentZone;
  pageType;
  tenantIdUnique=70010;
  incorrectData = [];
correctData = [];
addUserTypeInternalorTenant='internal'
saveCorrectData = [];
listViewExcelResponse;
noOfEntitiesUploaded;
totalIncorrectLength;
lastPage
dataSource
submittedUsers=false

nameSearchIsLoading=false
customRoleSelected=null
customRoleList=[]

displayedColumns=['name','email','status'];
pageSizeOptions = [10, 25, 100];
length;
pageSizeMat = 10;
pageIndex = 0;
@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
notMappedEle;

showIncorrectData = [];
incorrectDataColumns = [
  // "slNo",
  "firstName",
  "lastName",
  "corporateEmail",
 "tenantAdmin",
  "tenantName",

  "reason",
];
addUsersNonTeams
addUsersManuallyForm: FormGroup;
disableAditionalRoles=false;
pageSize;
  unsubscribe$ = new Subject<void>();
  @ViewChild("labelImport", { static: false }) labelImport: ElementRef;
  @ViewChild ("fileUploader",{static:false}) fileUploader:ElementRef;
  editPeopleMode=false
  tenantId;
  internal=false;
  onboardUserEmailVerify
  ngOnInit() {
    // This component is used to upload users via excel sheet and was written for Tenant Engagement (Ansar).
    this.currentZone=this.data['currentZone'];
    this.pageType = this.data['pageType'];
    this.rolesData=this.data['rolesData']
    if(this.rolesData){

      this.rolesData.forEach((e)=>{ if(e.name=='Tenant Admin'){
        this.tenantIdUnique=e.id
      } })
    this.rolesData = this.rolesData.filter(role => role.name !== 'Tenant Admin');
  
  }
    this.tenantCompanyData=this.data['tenantCompany']
    this.addUsersManuallyForm = this.formBuilder.group({
      employeeName: ["", [Validators.required, Validators.pattern('^[A-Za-z ]+$')]],
      lastName: ["",[Validators.required, Validators.pattern('^[A-Za-z ]+$')]],
      email: ["", [Validators.required, Validators.email]],
      additionalRoles: [false],
      tenantAdmin:[false]
      // locations: this.formBuilder.array(
      //     [],
      //     [Validators.required, Validators.minLength(1)]
      // ),

  });
  if(this.data['edit'] && (this.data['pageType']=='onboardUserManualy' || this.data['pageType']  =='editInternalUserSuperAdmin')){
    this.editPeopleMode=true;
    this.tenantId=this.data['element']['tenantId'];
    this.editFormSetup(this.data['element']);
  
    
    }




  }
 
 
  filterLocations(name: string) {
    // this.locationNonTeams = null;
     let roleDataList = this.rolesData.filter((role) =>
         role.name.toLowerCase().includes(name.toLowerCase())
     );
     return roleDataList;
 }
 
 filterTenantCompany(name: string) {
   // this.locationNonTeams = null;
    let roleDataList = this.tenantCompanyData.filter((role) =>
        role.name.toLowerCase().includes(name.toLowerCase())
    );
    return roleDataList;
}
  editFormSetup(ele){
    let i=false;
    if(this.data['pageType']  !='editInternalUserSuperAdmin')
     ele.roles.forEach((e)=>{if( e.id==this.tenantIdUnique)i=true })
    let flag=false;
    if(i){
      flag=true;
    }
    this.addUsersManuallyForm.patchValue({
      employeeName:ele.firstName,
      lastName:ele.lastName,
      email:ele.email,
      additionalRoles:ele.roles.length==0?false:true,
      tenantAdmin:flag

    })
    ele.roles.forEach((e)=>{
      this.rolesData.forEach((e1)=>{
        if(e.id==e1.id){
          this.customRoleList.push(e1);
        }
      })
    })
    this.originalRolesLength=this.customRoleList.length
    console.log(this.customRoleList)
    let tenant;
    if(this.data['pageType']  !='editInternalUserSuperAdmin'){
    this.tenantCompanyData.forEach((e)=>{
      if(e.tenantId==ele.tenantId){
        tenant=e;
      }
    })
   
    this.selectCustomTenantComapny(tenant);
    this.searchTenantCompany.setValue(tenant.name)}
    if(this.data['pageType']  =='editInternalUserSuperAdmin'){
     
if( ele.roles){
  ele.roles.forEach((e)=>{
    if(e.id==1){
this.disableAditionalRoles=true;

    }
  })
}
 if(ele.globalAdmin){
this.disableAditionalRoles=true;

}

    }

  }

 
  closePopupInMiddle() {
   this.dialogRef.close();
  }
  onboardUsers(){
    if(this.onboardUserSelected){
      this.validateExcelOnboarding();
    }else{
      this.onboardUserSelected = true;
    }
    
    
  }
 
  getNotMappedUsersList(ele){
    this.notMappedEle=ele
    this.lastPage=this.pageType;
    this.pageType='showUnMappedUsers';
    this.loadUnMappedUsers(ele);
    this.dialogRef.updateSize('65%');

  }
  getPrevOrNextRecords(event) {
    this.pageIndex = event.pageIndex;
    this.pageSizeMat = event.pageSize;
    this.loadUnMappedUsers(this.notMappedEle);
    //this.loaderService.loadingOn();
    //this.getHierarchy();
  }
  loadUnMappedUsers(ele){
   const event$= this.peopleService.loadUnmappedUsers(this.currentZone,ele.tenantId,this.pageSizeMat,this.pageIndex);
   this.loaderService.showLoaderUntilCompleted(event$).subscribe((res)=>{
    this.dataSource=new MatTableDataSource(res['response']['content'])
    this.length = res["response"]["totalElements"];
   } ,(err)=>{

   })
  }
  backBtnUnmappedUsers(){
    this.pageType=this.lastPage
    this.dialogRef.updateSize('45%');
  }
  navigateToBuilding(){
    this.router.navigate(['/layout/digital-building']);
    this.dialogRef.close('no');

  }
  uploadCSV(files: FileList) {
    this.fileToUpload = null;
    this.fileName = undefined;
    var fileName = Array.from(files).map(f => f.name).join(", ");
    const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
    if( 'xlsx' || extension === 'xls'){
      this.fileToUpload = files.item(0);
      this.fileName = fileName;
      console.log('fileToUpload', this.fileToUpload);
    } else {
      this.toastrService.error("Please import valid .xlsx file.");      
    }

  }
  removeUploadedFile() {
    // this.submitted = false;
    this.fileName = undefined;
    this.fileToUpload = null;
    this.labelImport.nativeElement.innerText = "";
    this.fileUploader.nativeElement.value = '';
  }
  downloadExcelSheet() { 
    this.loaderService.loadingOn();
    const downloadSub$ = this.peopleService
      .downloadBulkUploadEntities(this.currentZone,true).subscribe((res:Blob) => {
          this.loaderService.loadingOff();
         if(!!res){
          const fileName = "users.xlsx";
          this.excelService.saveApiResponseToFile(res, fileName);
         }
      },
      (err) => {
          this.loaderService.loadingOff();
      });
    // this.subscription.add(downloadSub$);
  }

  validateExcelOnboarding() {

    const validateCSVOnboarding$ = this.peopleService.validateExcelOnboarding(
      this.fileToUpload,
      this.currentZone
    );
    this.loaderService
      .showLoaderUntilCompleted(validateCSVOnboarding$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
         
          if (!!res['response']) {
            this.listViewExcelResponse = res['response'];
            if (res["response"]["value"].length > 0) {
              this.incorrectData = [];
              this.correctData = [];
              this.saveCorrectData = [];
              res["response"]["value"].forEach((entity) => {
                if (entity.csvDto) {
                  let {
                  
                    firstName,
                    lastName,
                    corporateEmail,
                    tenantAdmin,
                    tenantName,
                
                  } = entity.csvDto;
                  let uploadData = {
                    firstName,
                    lastName,
                    corporateEmail,
                    tenantAdmin,
                    tenantName,
                    errorFields: entity.columns,
                    reason: entity.reason,
                  };
                  if (entity.columns.length > 0) {
                    this.incorrectData.push(uploadData);
                  } else {
                    this.correctData.push(uploadData);
                    this.saveCorrectData.push(entity.csvDto);
                  }
                }
              });
              this.TotalNoOfEntitiesUploadedRNW =
              this.incorrectData.length + this.correctData.length;
              this.noOfEntitiesUploaded = this.saveCorrectData.length;
              if (this.incorrectData.length == 0) {
               
              } else {
                this.totalIncorrectLength = this.incorrectData.length;
                this.showIncorrectData = this.incorrectData.slice(0, this.pageSize);
              }
            }
            else {
              this.toastrService.error("No Enteries were added");
              return;
            }
           
          }
          this.pageType = 'onboardingExcelTable';
          this.dialogRef.updateSize('75%');
          
        },
        (error) => {
          this.toastrService.error(
            "Something went wrong!! Please make sure file is in correct format",
            ""
          );
        }
      );
  }
  getToolTipData(hoverData) {
    return hoverData;
  }

  reUploadEntities(){
    this.pageType = 'onboarding_reUploadCSV';
    this.dialogRef.updateSize('45%');
    this.fileName = undefined;
    this.fileToUpload = null;
    // this.labelImport.nativeElement.innerText = "";
    if(this.fileUploader.nativeElement.value){
    this.fileUploader.nativeElement.value = '';
    }
  }

  downloadCurrentCSVFile() {
    this.listViewExcelResponse;
    this.loaderService.loadingOn();
    const downloadSub$ = this.peopleService
      .downloadBulkUploadEntities(this.currentZone,true, this.listViewExcelResponse).subscribe((res:Blob) => {
          this.loaderService.loadingOff();
         if(!!res){
          const fileName = "users.xlsx";
          this.excelService.saveApiResponseToFile(res, fileName);
         }
      },
      (err) => {
          this.loaderService.loadingOff();
      });
  }

  camelCasingToNormal(value: string): string {
    return value
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      .replace(/\b\w/g, (match) => match.toUpperCase()); // capitalize the first letter
  }

  proceedToOnboard(){
    if(this.saveCorrectData.length > 0){ 
      const validateCSVOnboarding$ = this.peopleService.saveOnboardedEntitiesViaExcelTenantAdmin(
        this.currentZone,
        this.saveCorrectData,true
      );
      this.loaderService
        .showLoaderUntilCompleted(validateCSVOnboarding$)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            this.toastrService.success( this.saveCorrectData.length+ " Users has been succesfully onboarded");
            const response = res['response'];
            if(res){
              this.dialogRef.close();
            }
          },
          (err) => {
            this.toastrService.error(err['error']['message']);
          }
        );
    } else {
      this.toastrService.error("Please upload the correct file containing accurate Tenant user data.");
    }
  }

  validateEmail(email) {
    // Regular expression to match a basic email pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test the email against the regex
    return emailRegex.test(email);
  }
  verifyEmail(){

    const  verify$= this.peopleService.verifyEmail(this.onboardUserEmailVerify);
    this.loaderService.showLoaderUntilCompleted(verify$).subscribe(
     (res) => {
         if(res['response']=="User does not exist in the system"){
           this.pageType = "onboardUserManualy";
         
           // this.loadDepartment();
       
           // this.getCountryCodes();
           this.addUsersManuallyForm.patchValue({
               email:this.onboardUserEmailVerify
           })
         }
         else{
           this.toastrService.error(res['response'])
         }
        
 
 
 
       },
       (error) => {
 
       }
   );
  
 }

  onboardUserManualyy(){
    this.pageType='addEventManual'
  }

  get f() {
    return this.addUsersManuallyForm.controls;
  }
  
  submitManuallyForm(){
    this.submittedUsers=true
    if (this.addUsersManuallyForm.invalid) {
    //  this.toastrService.error('Please fill all the mandatory fields')
      return;
    }
    if(this.f.additionalRoles.value && this.customRoleList.length==0){
      return;
    }

this.submittedUsers=false;
let roles=[];
console.log( 'role list' , this.customRoleList)
if(this.f.additionalRoles.value)
{this.customRoleList.forEach((e)=>{
  roles.push({"roleId":e.id ,zoneIds:[this.currentZone] });
})}


  // app role included
  // let i=false;
  // roles.find((e)=>{if(e.roleId==13)i=true});
  // if(!i)
  // roles.push({"roleId":13 ,zoneIds:[this.currentZone] });


let payLoad={
  "allSiteAccess": false,
  "email": this.f.email.value,
  "firstName": this.f.employeeName.value,
  "password": null,
  "lastName": this.f.lastName.value,
  "rolesToAssign": roles,
  "phoneNum": "",
  "tenantId": 1,
  "empCode": "",
  "deptId": null,
  "departName": "",
  "grade": "MGR",

 
  "supervisorId": "",
  "autoAssign": false,
  "globalAdmin": this.data['element']['globalAdmin'],
  allLocationAccess:   this.data['element']['allLocationAccess'], 
}
// if(!this.editPeopleMode)
// { const $onBoard= this.peopleService.onBoardUsers(payLoad,this.currentZone,true,false);
//  this.loaderService.showLoaderUntilCompleted($onBoard).subscribe((res)=>{
//   this.toastrService.success('User has been successfully onboarded')
// this.closePopup();
//  },(e)=>{} )
// console.log(payLoad)}

  payLoad['id']=this.data['element']['userId']
  const $onBoard= this.peopleService.editUsers(payLoad,this.currentZone,true,true);
 this.loaderService.showLoaderUntilCompleted($onBoard).subscribe((res)=>{
  
  this.toastrService.success('Additional role added for Internal Employee ' +this.data['element']['name'] +'.');
this.closePopup();
 },(e)=>{} )
console.log(payLoad)





  }

  submitManuallyFormTenant(e){
    this.submittedUsers=true
    if (this.addUsersManuallyForm.invalid) {
    //  this.toastrService.error('Please fill all the mandatory fields')
      return;
    }
    if(this.selectedTenantComapny==null){
      return;
    }

this.submittedUsers=false;
let roles=[];
console.log( 'role list' , this.selectedTenantComapny)
if(this.f.tenantAdmin.value)
roles.push({"roleId":this.tenantIdUnique ,zoneIds:[this.currentZone] });
if(!this.editPeopleMode ) 
{
  roles.push({"roleId":13,zoneIds:[this.currentZone] });
}
// let i=false;
// roles.find((e)=>{if(e.roleId==13)i=true});
// if(!i)
// roles.push({"roleId":13 ,zoneIds:[this.currentZone] });

let payLoad={
  "allSiteAccess": false,
  "email": this.f.email.value,
  "firstName": this.f.employeeName.value,
  "password": null,
  "lastName": this.f.lastName.value,
  "rolesToAssign": roles,
  "phoneNum": "",
  "tenantId": this.selectedTenantComapny.tenantId,
  "empCode": "",
  "deptId": null,
  "departName": "",
  "grade": "MGR",
 
  "supervisorId": "",
  "autoAssign": false,
  "globalAdmin": false
}
if(!this.editPeopleMode) 
{const $onBoard= this.peopleService.onBoardUsers(payLoad,this.currentZone,false,true);
 this.loaderService.showLoaderUntilCompleted($onBoard).subscribe((res)=>{
  this.toastrService.success('User has been successfully onboarded')
this.closePopup();
 },(e)=>{} )
console.log(payLoad)}
else{
  payLoad['id']=this.data['element']['userId']
  const $onBoard= this.peopleService.editUsers(payLoad,this.currentZone,false,true);
 this.loaderService.showLoaderUntilCompleted($onBoard).subscribe((res)=>{
  this.toastrService.success('User has been successfully edited')
this.closePopup();
 },(e)=>{} )
console.log(payLoad)
}

  }


  @ViewChild('customInput5', { static: false }) customInput5: ElementRef<HTMLInputElement>;


    

  selectCustomRole(selectedLocations) {
    this.customRoleSelected = selectedLocations;
    if(selectedLocations.id==1)
    this.customInput5.nativeElement.value='Super Admin'
  else if(selectedLocations.id==13)
  this.customInput5.nativeElement.value='General User'
   
}
getNameWithoutUnderscore(name){
  if(name=='SUPER_ADMIN')return "Super Admin";
  if(name=='GENERAL_USER')return "General User"
;



  return name;
}
tagCustomLocationsNonTeams() {
  if (this.customRoleSelected == null) {
      this.toastrService.error("Select one role");
      return;
  } else {
      if (this.customRoleList.length > 0) {
          let exist = false;
          this.customRoleList.forEach((element) => {
              if (
                  element["id"] ==
                  this.customRoleSelected["id"]
              ) {
                  this.toastrService.error("Role already added");
                  exist = true;
                  return;
              }
          });
          if (!exist) {
              this.customRoleList.push(this.customRoleSelected);
          }
      } else {
          this.customRoleList.push(this.customRoleSelected);
      }
      this.customRoleSelected = null;
      this.searchRoles.setValue("");
  }
}
removeCustomRole(i){
  this.customRoleList.splice(i);
  this.customRoleList=this.cloneList(this.customRoleList);

}
getSpecialTooltip(){

  let msg=' Internal Users: Employees of the parent organization \n Tenant Users: Employees of a specific company using the services of the parent organization'
  return msg;
    }
selectCustomTenantComapny(
  dropData
){
  this.selectedTenantComapny=dropData
}
  cloneList(list) {
    return _.cloneDeep(list);
  }
  closePopup(){
    this.dialogRef.close();
  }
  tabPeopleClick(e){
    this.addUsersManuallyForm.reset();
  }
  alertYes(){
    this.dialogRef.close('yes');
  }
  alertNo(){
    this.dialogRef.close('no');
  }
}
