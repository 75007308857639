import { Component, Inject, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatInput, MatSelect, MatOption, MatCheckbox, MatTableDataSource,MatRadioButton, MatRadioGroup,  MatPaginator, PageEvent  } from '@angular/material';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import {debounceTime, delay, tap, filter, map, takeUntil, take, catchError, finalize} from 'rxjs/operators';
import 'rxjs/add/observable/of';
import { Router } from '@angular/router';
//import { CommonService } from '../../../services/common-service.service';
import { CommonService } from '../../../../services/common-service.service';
import { environment } from '../../../../../environments/environment';
import { ModalRequestServiceService } from '../../../../custom-modal/service/modal-request-service.service';
// import { EMSService } from '../../../../shared/services/ems.service';
import { EMSService } from '../../../../shared/services/ems.service';

declare const Checkout;
declare const Razorpay;
@Component({
  selector: 'app-ems-modal',
  templateUrl: './tms-modal.component.html',
  styleUrls: ['./tms-modal.component.scss']
})
  
export class TMSModalComponent implements AfterViewInit{
 
  private currentZone;
  public accountLedgersDataSource;
  public accountLedgersColumns = ['accountLedgerId','tenantId', 'activity', 'amount', 'createdAt'];
  public accountBalance;
  public accountLedgerId;

  public depositLedgersDataSource;
  public depositLedgersColumns = ['depositLedgerId','tenantId', 'activity', 'amount', 'createdAt'];
  public depositBalance;
  public depositLedgerId;

  public creditsLedgersDataSource;
  public creditsLedgersColumns = ['creditsLedgerId','tenantId', 'activity', 'amount', 'createdAt'];
  public creditsBalance;
  public creditsLedgerId;

  @ViewChild('paginator0',{ static: true }) paginator0: MatPaginator;

  @ViewChild('paginator1',{ static: true }) paginator1: MatPaginator;

  @ViewChild('paginator2',{ static: true }) paginator2: MatPaginator;

  public tenantId;
  public paymentModeToSettleAccountBalance;
  public paymentModeToRefundDepositBalance;
  public paymentReferenceToSettleAccountBalance;
  public paymentReferenceToRefundDepositBalance;
  public paymentModesToSettleAccountBalance;
  public paymentModesToRefundDepositBalance;

  public returnDepositAmount;

  public depositBalanceAdditionalChargesBoolean:Boolean = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {name:'', amount:''};

  public paymentGatewaysToSettleAccountBalanceByTenant;
  public pgSelected;

  public min = new Date();
  public resourceList: any = [];
  public planList: any = {};

  objectKeys = Object.keys;

  @ViewChild('razorpayContainer',{ static: true }) razorpayContainer: ElementRef; 
  @ViewChild('termsAndConditionsContainer',{ static: true }) termsAndConditionsContainer: ElementRef; 
  //this.termsAndConditionsContainer.nativeElement.innerHTML = htmlStrDummy;

  public regionArray;
  public countryArray;
  public countriesListForRegion;
  public stateArray;
  public statesListForCountry;
  public cityArray;
  public citiesListForState;
  public siteArray;
  public sitesListForCity
  public buildingArray;
  public buildingListForSite;
  public floorArray;
  public floorListForBuilding;

  public selectedSite;
  public selectedCity: string;
  public selectedState: string;
  public selectedCountry: string
  public selectedRegion: string;
  public selectedBuilding: string;
  public selectedFloor: string;

  public numbers = Array.from(Array(40),(x,i)=>i);
  public transactionDetail: any = {};

  roleList = [];
  locationList = [];
  selectedRole: string;
  selectedLocation: string;

  //ems user autocomplete

  public bankServerSideCtrl: FormControl = new FormControl();
  public bankServerSideFilteringCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  public  filteredServerSideBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  pageLimt:number  = 1;
  pageNo:number = 0;
  pageSize : number = 10;
  totalPage;
  showLoadMore:boolean = false;
  searchQuery = '';
  selectedEmsUser:any = [];
  selectedEmsUserIds = [];
  allUsers:any=[];


  constructor(
    public router: Router,
    public matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TMSModalComponent>,
    public dialog: MatDialog,
    public commonService: CommonService,
    public modalRequestServiceService: ModalRequestServiceService,
    private emsService : EMSService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    }
    ngAfterViewInit() {    
      let currentContext = this;
      this.currentZone = currentContext.data['currentZone'];
      this.commonService.changeSiteSelectionFlag(false);
      //let htmlStrDummy = '<p>&nbsp;</p><p><strong>Agreement between user and www.sheetsofsanfrancisco.com</strong></p><p>Welcome to www.sheetsofsanfrancisco.com. The www.sheetsofsanfrancisco.com website (the “Site”) is comprised of various web pages operated by Zinc Retail Inc (“Zinc”). www.sheetsofsanfrancisco.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the “Terms”). Your use of www.sheetsofsanfrancisco.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.</p><p>www.sheetsofsanfrancisco.com is an E-commerce Site</p><p>On line retail of specialist waterproof bedsheets and accessories</p><p>&nbsp;</p><p><strong>Electronic Communications</strong></p><p>Visiting www.sheetsofsanfrancisco.com or sending emails to Zinc constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p><p>Zinc does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use www.sheetsofsanfrancisco.com only with permission of a parent or guardian.</p><p>&nbsp;</p>';
      //this.termsAndConditionsContainer.nativeElement.innerHTML = htmlStrDummy;
        if(currentContext.data['type'] === 'addFloorToTenant'){
          this.regionArray = currentContext.data['regionArray'];
          this.countryArray = currentContext.data['countryArray'];
          this.stateArray = currentContext.data['stateArray'];
          this.cityArray = currentContext.data['cityArray'];
          this.siteArray = currentContext.data['siteArray'];
          this.buildingArray = currentContext.data['buildingArray'];
          this.floorArray = currentContext.data['floorArray'];
          //setting the current site selected
          this.selectedSite = (currentContext.data['currentSite']);
          this.onSelectSite();
        }
        else if(currentContext.data['type'] === 'addRolesAndLocation'){
          this.roleList = currentContext.data['roleList'];
          this.locationList = currentContext.data['locationList'];
        }

    }

  ngOnInit() {

    if(this.data['type'] == 'changeUser') {
      this.tenantId = (this.data as any ).tenantId
    }
    this.bankServerSideFilteringCtrl.valueChanges
    .pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      takeUntil(this._onDestroy),
      debounceTime(200),
      map(search => {
        this.pageNo = 0;
        
        this.searchQuery = search;
        
        if(this.searchQuery.length >= 1) {
          this.filteredServerSideBanks = new ReplaySubject<any[]>(1);
          let data:any = {}
          data.q = search;
          data.page = this.pageNo;
          data.size = this.pageSize;
          data.tenantId = this.tenantId;
           // return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
          this.emsService.getUserListByTenantId(data, this.currentZone).subscribe(res => {
            let felteredData = (res as any).response['content'];
            felteredData.forEach(element => {
              var isFirst = false;
              let i = 0;
              if(this.allUsers.length > 0) {
                isFirst = false;
                this.allUsers.forEach(usr => {
                  if(usr.userId == element.userId) {
                    i = 1;
                  }
                });
              }else {
                isFirst = true;
                this.allUsers.push(element); //initial push
              }
              if(i === 0 && !isFirst){
                this.allUsers.push(element); //  new user added
              }
            })
            if(this.allUsers.length > 0){
              this.allUsers.filter(user => user.name.toLowerCase().indexOf(search) > -1);
            }
            this.filteredServerSideBanks.next(this.allUsers);
            this.totalPage = (res as any).response['totalPages'];
            if(this.totalPage > 1 && this.pageNo < this.totalPage) {
              this.showLoadMore = true;
            }
          })
        }
       
      }),
      delay(500)
    )
    .subscribe(filteredBanks => {
      this.searching = false;
    },error => {
      this.searching = false;
    });

  }
  
  onNoClick(): void {
    let currentContext = this;
    currentContext.commonService.changeZone(currentContext.currentZone);
    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
    //this.dialogRef.close();    
  }

  onSelectSite() {
    var currentContext = this;
    currentContext.buildingListForSite = this.buildingArray.filter(building => {
      //return (currentContext.selectedSite == building.siteId);
      return ((currentContext.selectedSite as any).siteId == building.siteId);
    });
    //if (this.buildingListForSite.length == 0) 
    {
      this.floorListForBuilding = null;
      currentContext.selectedBuilding = null;
      currentContext.selectedFloor = null;
    }
  }

  onSelectBuilding() {
    var currentContext = this;
    currentContext.floorListForBuilding = this.floorArray.filter(floor => {
      return (currentContext.selectedBuilding == floor.buildingId);
    });
  }
  onSelectFloor() {
    var currentContext = this;
  }

  addFloor1(){
    let floorStr = '';
    //this.regionArray.filter((region)=>{if(region.id == this.selectedRegion){floorStr = (region['name'])}});
    //this.countryArray.filter((country)=>{if(country.id == this.selectedCountry){floorStr = country['name']+', '+floorStr}});
    //this.stateArray.filter((state)=>{if(state.id == this.selectedState){floorStr = state['name']+', '+floorStr}});
    //this.cityArray.filter((city)=>{if(city.id == this.selectedCity){floorStr = city['name']+', '+floorStr}});
    this.siteArray.filter((site)=>{if(site.id == (this.selectedSite as any).siteId){floorStr = site['name']+floorStr}});    
    this.buildingArray.filter((building)=>{if(building.id == this.selectedBuilding){floorStr = building['name']+', '+floorStr}});
    this.floorArray.filter((floor)=>{if(floor.id == this.selectedFloor){floorStr = floor['name']+', '+floorStr}});
  

    //this.cityArray.filter((region)=>{if(region.id == this.selectedRegion){floorStr += region['name']}});
    if(this.selectedBuilding && this.selectedFloor){
        let floor = {};
        (floor as any).name = floorStr;
        (floor as any).id = this.selectedFloor;        
        this.modalRequestServiceService.changeFloor(floor);        
    }else{
      this.commonService.openSnackBar('Floor Selection Missing, please select Tenant Floor again', '');
    }
    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
  }

  addRoleAndLocation(){
    let roleAndLocationStr = '';
    this.roleList.filter( (role) => {if(role.id == this.selectedRole){roleAndLocationStr = (role as any).name+roleAndLocationStr}});
    this.locationList.filter( location => {
      if(location.id == this.selectedLocation){
        roleAndLocationStr = roleAndLocationStr + ' - ' + (location as any).name;
      }
    });

    if(this.selectedRole && this.selectedLocation){
      let roleAndLocation = {};
      (roleAndLocation as any).role_id = this.selectedRole;
      (roleAndLocation as any).location_id = this.selectedLocation;
      (roleAndLocation as any).name = roleAndLocationStr;
      this.modalRequestServiceService.changeRoleAndLocation(roleAndLocation);      
    }else{
      this.commonService.openSnackBar('Role and Location Selection missing', '');
    }
    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
  }

  // ems autocomplete
  getNextRecords = () => {
    this.pageNo = this.pageNo+1;
    let data:any = {}
    data.q = this.searchQuery;
    data.page = this.pageNo;
    data.size = this.pageSize;
    data.tenantId = this.tenantId;
    // return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
    this.emsService.getUserListByTenantId(data, this.currentZone).subscribe(res => {
      let resData = (res as any).response['content'];

      resData.forEach(element => {
        let i = 0;
        this.allUsers.forEach(usr => {
          if(usr.userId == element.userId) {
            i = 1;
          }
        });
        if(i === 0){
          this.allUsers.push(element); //  new user added
        }
      })
      // resData.forEach(element => {
      //   this.allUsers.push(element);
      // });
      if(this.allUsers.length > 0){
        this.allUsers.filter(user => user.name.toLowerCase().indexOf(this.searchQuery) > -1);
      }
      this.filteredServerSideBanks.next(this.allUsers);
      this.totalPage = (res as any).response['totalPages'];
      if(this.totalPage > 1 && this.pageNo < this.totalPage) {
        this.showLoadMore = true;
      }
    })
  } 

  addUser = () => {
    let selectedUser = this.allUsers.filter(usr => {
      if(usr.userId == this.selectedEmsUserIds) {
        return usr;
      }
    });
    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close({'selectedUser' : selectedUser});
  }
}