<div class="modal-card fixedHeight" *ngIf="pageType == 'addServices'">
    <div class="modal-header p-0">
        <h6 class="semi-bold-blue">
            <!-- <i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtn(pageType)"></i>  -->
            {{ "Curated Services" | translate }}
        </h6>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
        <!-- <app-sub-heading title="Global Setup > Booking Pro"></app-sub-heading> -->

        <form class="text-center" [formGroup]="myForm"  (ngSubmit)="submitCuratedForm()">
        <div class="form-row">
            <div class="modalData">
                <div class="col-12">
                    <div class="form-group col-lg-6 col-md-6 px-0">
                        <label class="float-left formLabel mb-1">{{"Service Name" | translate}} <span class="text-danger">*</span>
                            <i>{{"(25 Characters Limit)"}}</i>
                        </label>
                        <input type="text" class="form-control blueText mt-2"
                            placeholder="{{'Enter Custom Service Name' | translate}}" formControlName="spaceName"
                            name="space name" />
                        <div *ngIf="myForm.get('spaceName').errors?.required && curatedSubmitted" class="text-left mt-2">
                            <span class="regular-font-blue text-danger">Custom Service Name is required.</span>
                        </div>
                        <div *ngIf="myForm.get('spaceName').errors?.maxlength" class="text-left mt-2">
                            <span class="regular-font-blue text-danger">Custom Service Name can not be greater than 25 characters
                                .</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="form-group col-lg-6 col-md-6 px-0">
                        <label class="float-left formLabel mb-1">{{"Service Url" | translate}} <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control blueText mt-2" placeholder="{{'Enter Custom URL' | translate}}"
                            formControlName="spaceUrl" name="space name" (input)="urlValidate=false; iFrameCheck=false; " />
            
                    </div>
                    <div class="col-4">
                        <button class="btn btn-primary w-auto btn-main-outline btnSmall btn-radius mr-2p float-left font-regular"
                            type="button" (click)="verifyUrl()"
                            *ngIf="!urlValidate && !myForm.get('spaceUrl').errors?.required">Verify Link</button>
                        <button class="btn w-auto btn-primary btn-main-outline btnSmall btn-radius mr-2p float-left font-regular"
                            type="button" disabled="true" *ngIf="urlValidate">Link Verified!</button>
                    </div>
                    <br>
            
                </div>
                <div class="col-8 text-left"  [ngClass]="iFrameCheck ? 'mb-2' : '' ">
                    <label class="float-left formLabel mb-1" *ngIf="iFrameCheck">{{"Preview" | translate}}
                        <img
                        src="../../../../assets/images/curated-services/info.png" alt="Curated Services"
                        class="iconInfo ml-1 cursor-pointer" [matTooltip]="getToolTipDataInfo('Preview lets you check if external websites can be opened inside our platform. Some websites might not be supported due to their settings.')" matTooltipClass="custom-tooltip"></label>
                    <iframe #hiddenIframe [ngClass]="iFrameCheck ? 'displayIframe' : 'nonDisplayIframe' "
                        style="width: 100%; height: 150px;"></iframe>
                       <ng-container *ngIf="iFrameCheck">
                        <div class="col-12 tex-left mt-2 pl-1">
                            <p>Is the URL preview working ? <span class="text-danger">*</span></p>
                            <mat-radio-group class = "tp-radio-group"  formControlName="radioBtn" (change)="checkRadio()">
                                <mat-radio-button class = "tp-radio-button w-100 d-flex justify-content-center" value = "YES">
                                  Yes
                                </mat-radio-button>
                                <mat-radio-button class = "tp-radio-button mt-2 w-100 d-flex justify-content-center" value = "NO">
                                  No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="text-left mt-3" *ngIf="showToggle">
                        <div class="d-flex align-items-center">
                            <span class="mr-3">Redirect users to new tab on a browser</span> <mat-slide-toggle class="tableToggle" matTooltipClass="custom-tooltip"
                                formControlName="openNewTab">&nbsp;</mat-slide-toggle> 
                            </div>
                        <p class="text-gray mt-2">NOTE: Some websites are not supported to be opened within the Jumpree application as seen in the preview. In these scenarios, users can be redirected to open the link on a new tab in the browser.</p>
                        </div>
                        </ng-container>
                    </div>
                <div class="col-12">
                    <div *ngIf="myForm.get('spaceUrl').errors?.required && curatedSubmitted" class="text-left">
                        <span class="regular-font-blue text-danger">Space URL is required.</span>
                    </div>
                    <div *ngIf="myForm.get('spaceUrl').errors?.pattern && myForm.get('spaceUrl').touched && curatedSubmitted">
                        <span class="regular-font-blue text-danger">Invalid URL format</span>
                    </div>
                </div>
            
                <div class="col-12 mt-2">
                    <label class="float-left formLabel mb-2">{{"Service Image for App" | translate}} <span
                            class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2" *ngIf="!!appImagepathImage">
                    <img style="width: 85px;height: 50px;margin-top: 0px;" [src]="appImagepathImage" class="w-35" />
                </div>
                <div class="col-lg-6 col-md-6 align-left mb-0 pr-0">
            
                    <!-- {{ "uploadapppicture" | translate }} -->
            
                    <div class="custom-file curatedServices">
                        <div class="w-100">
                            <input type="file" class="form-control custom-file-input mt-2" id="importFile"
                                (change)="onChooseFileApp($event.target.files,$event)" aria-describedby="inputGroupFileAddon01"
                                placeholder="{{'uploadapppicture' | translate}}" [(ngModel)]="amenity.upload_appUrl"
                                [ngModelOptions]="{ standalone: true }" />
            
                            <div class="custom-file-label custom-upload-btn text-left customInput" for="inputGroupFile01"
                                #labelImport>
                                <span *ngIf="!appFileName" class="inputPlaceholder">{{
                                    "Choose Image to upload" | translate
                                    }}</span>
                                {{
                                appFileName?.length > 30
                                ? (appFileName | slice: 0:30) +
                                "..."
                                : appFileName
                                }}
                            </div>
                        </div>
                        <p class="text-gray">
                            *
                            {{
                            "This picture will appear on the app under the Services tab in the curated services section"
                            | translate
                            }}
                        </p>
                        <div *ngIf="upload_appUrl" class="text-left">
                            <span class="regular-font-blue text-danger">{{"Choose Image to upload" | translate}}</span>
                        </div>
                    </div>
            
            
                </div>
                <div class=" col-lg-4 col-md-4 mb-0 hint regular-font-blue text-left">
                    {{"forApp" | translate}} <br />
                    (170*196px)
                </div>
            
                <div class="col-12 mt-3">
                    <label class="float-left formLabel mb-2">{{"Service Image for dashboard" | translate}} <span
                            class="text-danger">*</span>
                    </label>
                </div>
            
                <div class=" col-lg-2 col-md-2" *ngIf="!!dashboardImagepathImage">
                    <img style="width: 85px;height: 50px;margin-top: 0px;" [src]="dashboardImagepathImage" class="w-35" />
                </div>
                <div class="form-group col-lg-6 col-md-6 align-left mb-0 pr-0">
            
                    <!-- {{ "uploaddashboardpicture" | translate }}
                                                            <span>(440*225px)</span> -->
                    <div class="custom-file curatedServices">
                        <div class="w-100">
                            <input type="file" class="form-control custom-file-input mt-2" id="importFile1"
                                (change)="onChooseFileDashboard($event.target.files,$event)"
                                aria-describedby="inputGroupFileAddon02" [(ngModel)]="amenity.upload_dashboardUrl"
                                [ngModelOptions]="{ standalone: true }" />
                            <div class="custom-file-label custom-upload-btn customInput text-left" for="inputGroupFile02"
                                #labelImport1>
                                <span *ngIf="!dashboardFileName" class="inputPlaceholder">
                                    {{
                                    "Choose image to upload" | translate
                                    }}
                                </span>
                                {{
                                dashboardFileName?.length > 30
                                ? (dashboardFileName
                                | slice: 0:30) + "..."
                                : dashboardFileName
                                }}
                            </div>
                        </div>
                        <p class="text-gray">
                            *
                            {{
                            "This picture will appear on the dashboard under the Curated Services Section"
                            | translate
                            }}
            
                        </p>
                        <div *ngIf="upload_appDashboardUrl" class="text-left">
                            <span class="regular-font-blue text-danger">{{"Pleaseuploadadashboardpicture" | translate}}</span>
                        </div>
                    </div>
            
            
            
            
                </div>
                <div class=" col-lg-4 col-md-4 mb-0 hint regular-font-blue text-left">
                    {{"forDashboard" | translate}} <br />
                    (440*225px)
                </div>
            </div>
                    <div class=" col-12 text-left mb-0 pt-3" *ngIf="!myForm.get('spaceName').invalid && urlValidate && appImagepathImage && dashboardImagepathImage">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "Save" | translate }}
                        </button>
                    </div>
        </div>
        </form>
    </div>
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'disableCurated'">
    <div class="modal-header p-0">
        <h6 class="semi-bold-blue">
            <!-- <i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtn(pageType)"></i>  -->
            {{ "Alert" | translate }}
        </h6>
        <!-- <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button> -->
    </div>
    <div class="modal-body align-left mt-4">
        <!-- <app-sub-heading title="Global Setup > Booking Pro"></app-sub-heading> -->
                <div class="col-12 px-0">
                    <p class="font-regular-gray text-left">Are you sure you want to disable users from viewing Curated services module inlocation "NUS Campus"? <br><br><br> Note: Users will be notified about the module being unavailable.</p>
                </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main btnSmall btn-radius mr-2p float-right font-regular" type="button"
                        (click)="closeAlertPopup('NO')">
                        {{ "No" | translate }}
                    </button>
                    <button class="btn  btn-primary btn-main-outline btnSmall btn-radius mr-2p float-right font-regular" type="button" (click)="closeAlertPopup('YES')">
                        {{ "Yes" | translate }}
                    </button>
                </div>
    </div>
        
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'openNewTabUrl'">
    <div class="modal-header p-0">
        <h6 class="semi-bold-blue">
            <!-- <i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtn(pageType)"></i>  -->
            {{ "Confirm Navigation" | translate }}
        </h6>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
        <!-- <app-sub-heading title="Global Setup > Booking Pro"></app-sub-heading> -->
                <div class="col-12 px-0 tex-left">
                    <h6 class="semi-bold-blue tex-left" >The curated service "{{data?.dialogData?.serviceName}}" will be opened on a new tab in your default browser.</h6>
                    <p class="blueText text-left mt-4">Are you sure you would like to proceed?</p>
                </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main btnSmall btn-radius mr-2p float-right font-regular" type="button"
                        (click)="closeAlertPopupData('NO',data?.dialogData)">
                        {{ "No" | translate }}
                    </button>
                    <button class="btn  btn-primary btn-main-outline btnSmall btn-radius mr-2p float-right font-regular" type="button" (click)="closeAlertPopupData('YES',data?.dialogData)">
                        {{ "Yes" | translate }}
                    </button>
                </div>
    </div>
        
</div>


