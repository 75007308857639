import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WeeklyComponent } from "./weekly.component";

@NgModule({
  imports: [CommonModule],
  declarations: [WeeklyComponent],
  exports: [WeeklyComponent],
})
export class WeeklyModule {}
