import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRoute } from "@angular/router";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LoginService } from "../../login/login.service";
import { CommonService } from "../../services/common-service.service";
import { Subject } from "rxjs";
import { EMSService } from "../services/ems.service";
import { first } from "rxjs/operators";
import { RESPONSIBILITIES } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    public emsService: EMSService,
    public commonService: CommonService,
    private toaster: ToastrService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return true;
    // let sub$ = new Subject<boolean>();
    // let action$ = sub$.asObservable();
    // sub$.next(true);
    let currentZone = localStorage.getItem("currentZone");
    let ssadmin_session = localStorage.getItem("ssadmin_session");
    let globalAdmin = localStorage.getItem("ssadmin_globalAdmin");
    if ((!!currentZone || globalAdmin) && !!ssadmin_session) {
      return true;
    } else {
      this.toaster.error("Invalid session ,Please login again");
      this.router.navigate(["/login"]);
    }
  }

  storeLoggedUser = (user) => {
    localStorage.setItem("ssadmin_userImg", user["imageUrl"]);
    localStorage.setItem("ssadmin_session", user["sessionId"]);
    localStorage.setItem("ssadmin_userId", user["userId"]);
    localStorage.setItem("ssadmin_user", user["email"]);
    localStorage.setItem("ssadmin_name", user["name"]);
    localStorage.setItem("ssadmin_tenantId", user["tenantId"]);
    return;
  };
}
