import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import * as i0 from "@angular/core";
export class ExcelService {
    constructor() { }
    convertJSONtoXLSX(jsonData, fileName) {
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, fileName);
    }
    saveAsExcelFile(buffer, fileName) {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, fileName + '.xlsx');
    }
    saveApiResponseToFile(response, filename) {
        // Save the XLSX response to a file
        saveAs(response, filename);
    }
}
ExcelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExcelService_Factory() { return new ExcelService(); }, token: ExcelService, providedIn: "root" });
