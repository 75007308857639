import { Component, Input, OnInit } from "@angular/core";
import { LoaderService } from "loaderService";
import { DemandSupplyServiceService } from "src/app/layout/demand-supply/service/demand-supply-service.service";

@Component({
    selector: "modal-manage",
    templateUrl: "./modal-manage.component.html",
    styleUrls: ["./modal-manage.component.scss"],
})
export class ModalManageComponent {
    @Input() currentZone: number;
    @Input() data;
    selectedIndex;
    availabilityList;
    moveType = "recommended";
    moveDataObj;
    manageRequiredEntities;
    tempManageRequiredEntities;
    selected = 0;
    total = 0;
    progress = 0;
    floorList = [];
    availableList = {};
    recommendedList = [];
    dataObj = [];
    floorIds;
    constructor(
        private demandSupplyService: DemandSupplyServiceService,
        private loadingService: LoaderService
    ) {}

    ngOnInit() {
        this.moveType = "recommended";
        if (this.moveType == "recommended") {
            this.getRecommendedOptions();
        } else if (this.moveType == "manual") {
            this.getMoveOptions();
        }
    }

    getRecommendedOptions() {
        // entity/recommendation/multiUser
        const option$ = this.demandSupplyService.getRecommendedFloors(
            this.currentZone,
            this.data
        );
        const options$ = this.loadingService
            .showLoaderUntilCompleted(option$)
            .subscribe(
                (res) => {
                    // console.log("Recommended List", this.recommendedList);
                    this.recommendedList = res["response"];
                },
                (err) => {}
            );
    }

    onChange() {
        if (this.moveType == "recommended") {
            this.getRecommendedOptions();
        } else if (this.moveType == "manual") {
            this.getMoveOptions();
        }
    }

    getFloorIds(option) {
        // console.log(option);
        this.floorIds = [];
        option.forEach((option) => {
            option.floorCounts.forEach((floor) => {
                this.floorIds.push(floor.zoneLocationDto.zoneId);
            });
        });
        // console.log(this.floorIds);
    }

    getMoveOptions() {
        let data = {
            recurringStartTime: 0,
            recurringEndTime: 2359,
            startTime: 0,
            endTime: 123456,
        };

        this.data.tickets.forEach((ticket) => {
            let data = {
                enitityType: ticket["entityType"]["id"],
                required: ticket["quantity"],
            };
            this.dataObj.push(data);
        });
        const costCode$ = this.demandSupplyService.getAllAvailability(
            data,
            this.currentZone
        );
        const costcodes$ = this.loadingService
            .showLoaderUntilCompleted(costCode$)
            .subscribe(
                (res) => {
                    // console.log(res);
                    // console.log(this.dataObj);
                    this.availabilityList = res["response"];
                    this.dataObj.forEach((element) => {
                        this.availabilityList.entityTypeMap[
                            element.enitityType
                        ]["required"] = element.required;
                    });
                    this.manageRequiredEntities = JSON.parse(
                        JSON.stringify(this.availabilityList.entityTypeMap)
                    );
                    Object.entries(this.manageRequiredEntities).forEach(
                        (entry) => {
                            const [key, value] = entry;
                            if (value.hasOwnProperty("required")) {
                                this.total = this.total + value["required"];
                            }
                        }
                    );
                    this.availabilityList.values.forEach((building) => {
                        building.floorCounts.forEach((floor) => {
                            let countRequired = 0;
                            let totalCountAvailable = 0;
                            floor.entityTypeCounts.forEach((entity) => {
                                if (
                                    this.availabilityList.entityTypeMap[
                                        entity.entityType.id
                                    ].hasOwnProperty("required")
                                ) {
                                    countRequired =
                                        countRequired +
                                        parseInt(
                                            this.availabilityList.entityTypeMap[
                                                entity.entityType.id
                                            ]["required"]
                                        );
                                }
                                // console.log(entity.availableCount);
                                totalCountAvailable =
                                    totalCountAvailable +
                                    parseInt(entity.availableCount);
                            });
                            floor["countRequired"] = countRequired;
                            floor["totalCountAvailable"] = totalCountAvailable;
                            // console.log(`total is ${totalCountAvailable}`);
                        });
                    });
                    // this.availabilityList.entityTypeMap['']
                },
                (err) => {}
            );
    }

    getRequiredWorkstationCount(type, entityId) {
        let count = 0;
        if (type == "individual") {
            if (this.availabilityList.entityTypeMap[entityId]["required"]) {
                return this.availabilityList.entityTypeMap[entityId][
                    "required"
                ];
            } else {
                return 0;
            }
        } else {
            if (this.availabilityList.entityTypeMap[entityId]["required"]) {
                count =
                    count +
                    parseInt(
                        this.availabilityList.entityTypeMap[entityId][
                            "required"
                        ]
                    );
                return count;
            }
        }
    }

    // addOrRemoveClass(previous: HTMLElement, current: HTMLElement){
    //   previous.classList.remove('buttonActive');
    //   current.classList.add('buttonActive');
    // }

    getLegendColor(type, entityId) {
        if (type == "manual") {
            return this.availabilityList.entityTypeColorMap[entityId];
        } else {
            return this.recommendedList["entityTypeColorMap"][entityId];
        }
    }

    check(event, floor) {
        if (event.checked) {
            if (this.progress != 100) {
                this.floorList.push(floor);
            } else {
                event.checked = false;
            }
            // this.floorList.push(floor)
        } else {
            this.floorList.splice(
                this.floorList.findIndex(
                    (element) =>
                        floor.zoneLocationDto.zoneId ==
                        element.zoneLocationDto.zoneId
                ),
                1
            );
        }
        this.floorIds = [];
        this.floorList.forEach((floor) => {
            this.floorIds.push(floor.zoneLocationDto.zoneId);
        });
        // console.log(this.floorIds);
        this.calculate(event.checked);
    }

    calculate(flag) {
        this.availableList = {};
        this.floorList.forEach((floor) => {
            floor["entityTypeCounts"].forEach((element) => {
                if (
                    this.availableList.hasOwnProperty(
                        element["entityType"]["id"]
                    )
                ) {
                    if (flag) {
                        // console.log(element["entityType"]["id"]);
                        // console.log(element.availableCount)
                        this.availableList[element["entityType"]["id"]] =
                            this.availableList[element["entityType"]["id"]] +
                            element.availableCount;
                    } else {
                        if (
                            this.availableList[element["entityType"]["id"]] >
                            element.availableCount
                        )
                            this.availableList[element["entityType"]["id"]] =
                                this.availableList[
                                    element["entityType"]["id"]
                                ] - element.availableCount;
                        else
                            this.availableList[element["entityType"]["id"]] =
                                element.availableCount -
                                this.availableList[element["entityType"]["id"]];
                    }
                } else {
                    this.availableList[element["entityType"]["id"]] =
                        element.availableCount;
                }
            });
        });
        this.checkProgress();
    }

    checkProgress() {
        let commonAvailableList = {};
        let flag = "";
        this.selected = 0;
        Object.entries(this.availableList).forEach((entry) => {
            const [key, value] = entry;
            if (
                this.availabilityList.entityTypeMap[key].hasOwnProperty(
                    "required"
                )
            ) {
                commonAvailableList[key] = value;
            }
        });
        // console.log(commonAvailableList);
        for (const key of Object.keys(commonAvailableList)) {
            // const value = commonAvailableList[key]
            // Do something with 'value'...
            if (
                commonAvailableList[key] >=
                this.availabilityList.entityTypeMap[key]["required"]
            ) {
                flag = "satisfied";
                // console.log(flag);
                this.selected =
                    this.selected +
                    this.availabilityList.entityTypeMap[key]["required"];
            } else {
                flag = "not satisfied";
                this.selected = this.selected + commonAvailableList[key];
                // console.log(flag);
            }
        }

        this.progress = (this.selected / this.total) * 100;
        // console.log(`${this.selected} out of ${this.total} selected`);
        // console.log(`${this.progress}% progress`);
    }

    submit(automatically, manually) {
        // console.log("Automatically", automatically);
        // console.log("Manually", manually);
    }
}
