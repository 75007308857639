<div *ngIf="pageType == 'selectedColleagueModal'">
  <div class="modal-header modalHeaderPadding" style="border-bottom: 0rem !important;">
    <h6 class="semi-bold-blue">{{"addColleagues" | translate}}</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body align-center pb-0">
    <div class="m-auto text-center custom-img">
      <span class="workStatusDot" [style.background]="userInfo?.statusColor" *ngIf="userInfo?.statusColor"></span>
      <div>
      <img *ngIf="userInfo.img!=null" [src]="userInfo.img" alt="img" class="img-fluid" />
      </div>
      <div *ngIf="userInfo.img==null" class="textCircle img-fluid pt-1 m-auto" >
                    <div aria-label="Profile Image"
                        class="mb-2"
                        id="Profile Image"
                        style="font-size: 25px; color: rgb(255, 255, 255); font-family: FontAwesome; font-weight: normal; font-style: normal;"
                    >
                        
                    </div>
      </div>
      <p class="semi-bold-blue mb-1 mt-2">{{ userInfo.name }}</p>
      <p class="text-blue mb-1" style="font-size: 14px; font-weight: normal; margin-top: -5px; color: #1f2551;">{{userInfo.department}}</p>
      <div *ngIf="userInfo.deskNo">
          <img class="cursor-pointer pointerSize" src="./assets/images/jumpree_digital/workplace.svg" style="width: 16px;">
          <span class="regular-font ml-2 fontSizeDesk">{{userInfo.deskNo}}</span>
          <!-- <span class="regular-font ml-2 fontSizeDesk">Hidden</span> -->
      </div>
      <div class="w-75 mx-auto my-3">
        <p class="fontSizeLoc">{{ userInfo.loc }}</p>
        <!-- <p class="fontSizeLoc">Location: 24 Twin Tower, Berry Street New York, 32823</p> -->
      </div>
      <button
        class="btn btn-main small-btn font-weight-bold"
        style="border-radius: 100px"
        (click)="sendColleagueRequest()"
      >
      {{"addToFavourites" | translate}}
      </button>
    </div>
  </div>
</div>

<div class="modalCollegue" *ngIf="pageType == 'selectedColleagueWorkStatus'">
  <div class="modal-header modalHeaderPadding">
    <div class="w-25 d-flex align-items-baseline">
      <i class="fa fa-chevron-left mr-2 cursor-pointer" (click)="closePopup()"></i>
      <h6 class="semi-bold-blue text-align-left">
        {{data?.dialogData?.name}}
      </h6>
    </div>
  </div>

  <div class="modal-body align-center pb-0">
    <div class="calendar-schedule">
      <!-- <owl-date-time-inline [min]="minDateTime"  [pickerType]="'calendar'" [(ngModel)]="dateTime" [owlDateTimeFilter]="myScheduleFilter" (click)="removeSelecteddate()"  ></owl-date-time-inline> -->
      <full-calendar
          #fullCalendar
          *ngIf="calendarOptions"
          [options]="calendarOptions"
      ></full-calendar>

      
     
  </div>
  <div class="d-flex align-items-center mt-2 regular-font">
    <span class="calendar-schedule-WFH mr-2"></span>  Work From Home
  </div>
  <div class="d-flex align-items-center mt-1 regular-font">
    <span class="calendar-schedule-Office mr-2"></span>  Work From Office
  </div>
  <div class="d-flex align-items-center mt-1 regular-font">
    <span class="calendar-schedule-Leave mr-2"></span> On leave
  </div>
  </div>
</div>

