<div class="col p-0 mt-4">
    <form [formGroup]="spaceRequestForm" (ngSubmit)="createSpaceRequest()">
        <div class="col d-lg-flex d-md-flex p-0">
            <div class="col text-left form-group">
                <label class="float-left">{{
                    "preferredLocation" | translate
                }}</label>
                <mat-form-field appearance="fill" class="w-100">
                    <mat-select
                        id="preferred_location"
                        formControlName="preferredLocation"
                        placeholder="Select"
                        (selectionChange)="
                            getAllDepartments();
                            getAllWorkStations();
                            getAllShiftList()
                        "
                        [ngClass]="{
                            'is-invalid borderCurved':
                                submitted && f.preferredLocation.errors
                        }"
                    >
                        <mat-option
                            *ngFor="
                                let location of preferredLocationList;
                                let i = index
                            "
                            [value]="location.id"
                        >
                            {{ location.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    *ngIf="submitted && f.preferredLocation.errors"
                    class="invalid-feedback d-block"
                >
                    Preferred location is required
                </div>
            </div>
            <div class="col text-left form-group">
                <label class="float-left">{{
                    "departmentName" | translate
                }}</label>
                <mat-form-field appearance="fill" class="w-100">
                    <mat-select
                        id="department_name"
                        formControlName="departmentName"
                        placeholder="Select"
                        (selectionChange)="getAllCostCode()"
                        [ngClass]="{
                            'is-invalid borderCurved':
                                submitted && f.departmentName.errors
                        }"
                    >
                        <mat-option
                            *ngFor="
                                let department of departmentList;
                                let i = index
                            "
                            [value]="department.id"
                        >
                            {{ department.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    *ngIf="submitted && f.departmentName.errors"
                    class="invalid-feedback d-block"
                >
                    Department is required
                </div>
            </div>
            <div class="col text-left form-group">
                <label class="float-left">{{ "costCode" | translate }}</label>
                <mat-form-field appearance="fill" class="w-100">
                    <mat-select
                        id="cost_code"
                        formControlName="costCode"
                        placeholder="Select"
                    >
                        <mat-option
                            *ngFor="let item of costCodeList; let i = index"
                            [value]="item.id"
                        >
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col text-left form-group">
                <label class="float-left">{{ "reason" | translate }}</label>
                <input
                    id="reason"
                    type="text"
                    class="form-control"
                    formControlName="reason"
                    [ngClass]="{ 'is-invalid': submitted && f.reason.errors }"
                />
                <div
                    *ngIf="submitted && f.reason.errors"
                    class="invalid-feedback d-block"
                >
                    Reason is required
                </div>
            </div>
        </div>
        <div class="col-lg-9 col-md-9 p-0 d-lg-flex d-md-flex">
            <div class="col">
                <label class="float-left">{{ "startDate" | translate }}</label>
                <div class="input-group form-group">
                    <i
                        class="fa fa-calendar calendarPosition"
                        [owlDateTimeTrigger]="startDate"
                    ></i>
                    <input
                        id="start_date"
                        formControlName="startDate"
                        class="form-control borderCurved w-100"
                        [owlDateTimeTrigger]="startDate"
                        [min]="currentDate"
                        [owlDateTime]="startDate"
                        [ngClass]="{
                            'is-invalid': submitted && f.startDate.errors
                        }"
                    />
                    <div
                        *ngIf="submitted && f.startDate.errors"
                        class="invalid-feedback d-block text-left"
                    >
                        Start date is required
                    </div>
                    <owl-date-time
                        [pickerType]="'calendar'"
                        #startDate
                    ></owl-date-time>
                </div>
            </div>
            <div class="col">
                <label class="float-left">{{ "endDate" | translate }}</label>
                <div class="input-group form-group">
                    <i
                        class="fa fa-calendar calendarPosition"
                        [owlDateTimeTrigger]="endDate"
                    ></i>
                    <input
                        id="end_date"
                        formControlName="endDate"
                        class="form-control borderCurved w-100"
                        [owlDateTimeTrigger]="endDate"
                        [min]="currentDate"
                        [owlDateTime]="endDate"
                        [ngClass]="{
                            'is-invalid': submitted && f.endDate.errors
                        }"
                    />
                    <div
                        *ngIf="submitted && f.endDate.errors"
                        class="invalid-feedback d-block text-left"
                    >
                        End date is required
                    </div>
                    <owl-date-time
                        [pickerType]="'calendar'"
                        #endDate
                    ></owl-date-time>
                </div>
            </div>
            <div class="col">
                <label class="float-left">{{ "shift" | translate }}</label>
                <mat-form-field appearance="fill" class="w-100">
                    <mat-select
                        id="shift"
                        formControlName="shift"
                        placeholder="Select"
                        [ngClass]="{
                            'is-invalid borderCurved':
                                submitted && f.shift.errors
                        }"
                    >
                        <mat-option
                            *ngFor="let item of shiftList; let i = index"
                            [value]="item.id"
                        >
                            {{ getBookTime(item) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    *ngIf="submitted && f.shift.errors"
                    class="invalid-feedback d-block text-left"
                >
                    Shift is required
                </div>
            </div>
        </div>
        <div
            class="col-lg-9 col-md-9 p-0 h-100 overflow-x-auto custom-scrollbar"
        >
            <ng-container formArrayName="workstationQtyMapping">
                <ng-container
                    *ngFor="
                        let workstationForm of workstationQtyMapping.controls;
                        let i = index
                    "
                >
                    <div
                        class="col d-lg-flex d-md-flex p-0"
                        [formGroup]="workstationForm"
                    >
                        <div class="col-4 form-group text-left">
                            <label class="float-left" *ngIf="i == 0">{{
                                "typeOfWorkstation" | translate
                            }}</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-select
                                    formControlName="workstation"
                                    placeholder="Select"
                                >
                                    <mat-option
                                        *ngFor="
                                            let workstation of workstationList;
                                            let i = index
                                        "
                                        [value]="workstation.id"
                                    >
                                        {{ workstation.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <div *ngIf="submitted && workstationForm.workstation.errors" class="invalid-feedback d-block">
                                Type of workstation is required
                                </div> -->
                        </div>
                        <div class="col-4 form-group text-left">
                            <label class="float-left" *ngIf="i == 0">{{
                                "quantity" | translate
                            }}</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input
                                    matInput
                                    type="number"
                                    formControlName="quantity"
                                />
                            </mat-form-field>
                            <!-- <div *ngIf="submitted && workstationForm.quantity.errors" class="invalid-feedback d-block">
                                Quantity is required
                                </div> -->
                        </div>
                        <div
                            class="col-1 d-flex align-items-center"
                            *ngIf="workstationQtyMapping.length > 1"
                        >
                            <mat-icon
                                class="delete-btn"
                                (click)="deleteWorkstation(i)"
                                >delete_forever</mat-icon
                            >
                        </div>
                        <div
                            class="col-3 d-flex align-items-center"
                            *ngIf="i + 1 == workstationQtyMapping.length"
                        >
                            <img
                                src="./assets/images/add.svg"
                                class="block-img"
                                (click)="addWorkstationsQty()"
                            />
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div
            class="col-12 mt-4 text-left"
            [ngClass]="{ 'mt-4': workstationQtyMapping.value > 1 }"
        >
            <img
                src="./assets/images/preferred-allocation.svg"
                class="block-img form-group"
                (click)="setPageType('preferredAllocation')"
            />
            <img
                src="./assets/images/calendar-checked.svg"
                class="block-img form-group"
                (click)="setPageType('availability')"
            />
            <!-- <img src="./assets/images/dollar.svg" class="block-img d-inline-block form-group" (click)="setPageType('cost')"/> -->
        </div>
        <div
            class="col-12"
            *ngIf="
                workstationQtyMapping.value[0].workstation ||
                workstationQtyMapping.value[0].quantity
            "
        >
            <table
                mat-table
                [dataSource]="workstationQtyMapping.value"
                class="form-group"
            >
                <ng-container matColumnDef="workstation">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "workstation" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ getWorkstationName(element) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "quantity" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.quantity || "NA" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "Action" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <img
                            src="./assets/images/edit.png"
                            class="block-img ml-4"
                            (click)="editWorkstation(element)"
                        />

                        <img
                            src="./assets/images/delete-photo.png"
                            class="block-img ml-4"
                            (click)="deleteWorkstation(element)"
                        />
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>

            <!-- <mat-paginator *ngIf="length" class="w-100 mtop-1" #paginator [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="getNextRecords($event)"
                [length]="length"></mat-paginator> -->
        </div>
        <div class="col-12">
            <button
                type="submit"
                id="submit_btn"
                class="btn btn-main mt-4 btnSmall float-right"
                *ngIf="data.type == 'editRequest'"
            >
                {{ "update" | translate }}
            </button>
            <button
                type="submit"
                id="submit_btn"
                class="btn btn-main mt-4 btnSmall float-right"
                *ngIf="data.type == 'newRequest'"
            >
                {{ "Send" | translate }}
            </button>
        </div>
    </form>
</div>
