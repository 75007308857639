<div class="row p-0">
  <div class="col-12 p-0" *ngIf="showColleagues">
    <app-weekly (dateSelected)="dateSelected($event)"></app-weekly>
  </div>

  <div class="col-12 p-0">
    <form
      id="workstatus_form"
      class="form-group"
      [formGroup]="workStatusForm"
      (ngSubmit)="onSubmitSearch()"
    >
      <div class="form-row">
        <div class="form-group col-lg-12 col-md-4 col-sm">
          <label for="inputEmail4" class="labelColleague"
            >{{"searchColleagues" | translate}} </label
          >
          <div class="input-group pl-0">
            <input
              #name
              type="text"
              id="name"
              name="name"
              [(ngModel)]="eName"
              formControlName="workstatusSearch"
              autocomplete="off"
              class="form-control blueText"
              placeholder="{{getTranslate('enterColleagueName')}}"
              (input)="getEmployeeNameAutocompleteList(name.value)"
              [ngClass]="{
                'is-invalid': submitted && f.workstatusSearch.errors
              }"
              required
              [matAutocomplete]="auto"
              (keydown.enter)="$event.preventDefault()"             
            />
            <i
              class="fa fa-spinner loading_span"
              *ngIf="nameSearchIsLoading"
              aria-hidden="true"
            ></i>
            <button type="button" class="close close_button" aria-label="Close"
              (click)="name.value = ''" *ngIf="!nameSearchIsLoading && name.value != ''">
              <span aria-hidden="true">
                &times;</span>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                class="autocompleteSearch"
                *ngFor="let dropData of nameAutocompleteList$ | async"
                [value]="dropData.name.trim()"
                (click)="onselectNameData(dropData)"
              >
                <!-- <span>{{dropData?.name}}</span> -->
                <div class="row customRow pt-2 pb-2">
                  <!-- <div class="col-2 d-flex align-items-center"> -->
                   
                    <div class="px-3 custom-img">
                    <img
                    *ngIf="dropData.imageUrl!=null"
                      [src]="dropData.imageUrl"
                      alt="img"
                      class="img-Icon"
                    />
                    <div
                    *ngIf="dropData.imageUrl==null"
                    class="textCircle pt-1"                    
                >
                    <div
                    
                        aria-label="Profile Image"
                        class="mb-2"
                        id="Profile Image"
                        style="font-size: 25px; color: rgb(255, 255, 255); font-family: FontAwesome; font-weight: normal; font-style: normal;"
                    >
                        
                    </div>
                    </div>
                  </div>
                  <div class="col-10 text-left pl-0">
                    <p class="regular-font mb-1 mt-1">{{ dropData.name }}</p>
                    <!-- <p class="regular-font fontSizeLoc">Ecoworld</p> -->
                  </div>
                </div>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <!-- <div class="form-group col-lg-3 col-md-4 col-sm-4">
                    <button id="searchBtn" type="submit" class="btn btn-main btnSmall mt-4 customBtn px-3">
                        {{ "search" | translate }}
                    </button>
                    <button type="button" class="btn btn-main btnSmall ml-2 mt-4 customBtn" id="reset" (click)="reset()">
                        {{ "clear" | translate }}
                    </button>
                </div> -->
      </div>
    </form>
    <div class="text-center m-auto customScroll" *ngIf="!showColleagues">
      <img
        src="./assets/images/jumpree_digital/colleagues_search.svg"
        alt="Get Started"
        style="width: 170px"
      />
      <div class="col-12">
        <!-- <button class="btn btn-main btnSmall smallBtn float-right ml-4" (click)="closeColleaguesList()">Ok</button> -->
      </div>
    </div>
    <div class="customScroll" *ngIf="showColleagues">
      <ng-container *ngFor="let colleague of colleaguesList; let i = index">
        <div class="row align-items-center borderDiv py-2">
          <div class="col-lg-12">
            <div class="row align-items-center">
               
              <div class="ml-3 custom-img" >
                <span class="workStatusDot" [ngClass]="getWorkStatusColor(colleague)" *ngIf="colleague.workStatus"></span>     
                <img [src]="colleague.image" *ngIf="colleague.image!=null" alt="img" class="img-fluid" />
                <div
                *ngIf="colleague.image==null"
                class="textCircle img-fluid pt-1"                    
            >
                <div
                
                    aria-label="Profile Image"
                    class="mb-2"
                    id="Profile Image"
                    style="font-size: 25px; color: rgb(255, 255, 255); font-family: FontAwesome; font-weight: normal; font-style: normal;"
                >
                    
                </div>
                </div>
              </div>
              <div class="col-8 text-left pl-2">
                <p class="regular-font mb-1 mt-1 ml-3">{{ colleague.name }}</p>
                <!-- <p class="regular-font fontSizeLoc">{{ colleague.workStatus == "office" ? "Ecoworld, Vikroli"
                  : colleague.workStatus == "home" ? "Work from home"
                  : "Leave" }}</p> -->
                <!-- <p class="regular-font fontSizeLoc" *ngIf="colleague.location || colleague.workStatus"> -->
            
                  <div class="regular-font fontSizeLoc d-flex" *ngIf="colleague.location">
                    <img src="./assets/images/jumpree_digital/location_icon.svg" class="wLoc">
                    <!-- {{colleague.location ? colleague.location : colleague.workStatus}} -->
                    <div class="ml-1">
                      <div class="regular-font fontSizeLoc"> {{colleague?.location?.name}}, {{colleague?.location?.building?.name }}</div>
                      <div class="regular-font fontSizeLoc mt-0">{{colleague?.location?.site?.name }}, {{colleague?.location?.state?.name
                        }}</div>
                    </div>
                  
                  </div>
                  <div class="regular-font fontSizeLoc d-flex" *ngIf="!colleague.location && colleague.workLocationDto">
                    <img src="./assets/images/jumpree_digital/location_icon.svg" class="wLocw">
                    <!-- {{colleague.location ? colleague.location : colleague.workStatus}} -->
                    <div class="ml-1 mt-0 pt-0">
                      <div class="regular-font fontSizeLoc" *ngIf="colleague?.workLocationDto?.floor?.name || colleague?.workLocationDto?.building?.name"> {{colleague?.workLocationDto?.floor?.name}}<span *ngIf="colleague?.workLocationDto?.floor?.name">,</span> {{colleague?.workLocationDto?.building?.name }}</div>
                      <div class="regular-font fontSizeLoc mt-0">{{colleague?.workLocationDto?.name }}, {{colleague?.workLocationDto?.state?.name
                        }}</div>
                    </div>
                  
                  </div>
                <div class="">
                  <img *ngIf="colleague.deskNumber" class="cursor-pointer pointerSize w-16" [ngClass]="
                                !colleague.deskNumber ? 'disable' : ''
                                " src="./assets/images/jumpree_digital/workplace.svg" />
                  <span class="regular-font fontSizeDesk" [ngClass]="
                                !colleague.deskNumber ? 'disable' : ''
                                ">
                    {{
                    colleague.deskNumber
                    ? colleague.deskNumber
                    : ""
                    }}
                    <!-- {{
                                  colleague.deskActiveStatus == "Visible"
                                    ? colleague.deskNumber
                                    : colleague.deskActiveStatus
                                }} -->
                    <!-- {{
                                  colleague.workStatus == 'office'
                                    ? colleague.deskNumber
                                    : colleague.deskActiveStatus
                                }} -->
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 row  align-items-baseline text-right pr-4 m-top-minus">
            <div class="col-6 custom-desk">
              
            </div>
            <div class="col-6 text-align-end">
              <span>
                <img *ngIf="(colleague?.wayfinding || colleague?.allData?.allocation?.wayFindingDto?.wayFindingRedirectionUrl) && colleague.deskNumber != 'HIDDEN'" src="./assets/images/wayfinidngflexi.png"
               class="wayfind cursor-pointer" (click)="wayFinding(colleague?.allData)" />
              </span>

              <img
                class="cursor-pointer  w-30"
                (click)="openWorkstatusDialog(colleague)"
                src="./assets/images/jumpree_digital/analytics/Calendar.svg"
              />
             
              <span class="">
                <img
                class="cursor-pointer mr-2 w-30"
                (click)="deleteColleague(colleague)"
                src="./assets/images/jumpree_digital/analytics/Cross.svg"
                
              />
              </span>
              
              <!-- <span class="regular-font ml-2 fontSizeDesk">{{colleague.deskActiveStatus}}</span> -->
            </div>
          </div>
          <!-- <div class="col-lg-4 text-left">
            <p class="regular-font mt-3">{{ colleague.location }}</p>
          </div> -->
        </div>
        <!-- <div class="row">
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-6">
                                <img src="./assets/images/jumpree_digital/avatar@3x.jpg" alt="img" class="img-fluid">
                            </div>
                            <div class="col-6 text-left">
                                <p class="regular-font">Ram Kumar</p>
                                <p class="regular-font">Ecoworld</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 text-left">
                        <div class="d-inline-flex">
                            <img class="cursor-pointer pointerSize" src="./assets/images/amenity_booking/location_icon.png">
                            <span class="regular-font ml-2">D22</span>
                            <span class="regular-font ml-2">Hidden</span>
                        </div>
                    </div>
                </div> -->
      </ng-container>
      <div class="col-12">
        <!-- <button
          class="btn btn-main btnSmall smallBtn float-right ml-4"
          (click)="closeColleaguesList()"
        >
          Ok
        </button> -->
      </div>
    </div>
  </div>
</div>
