<div class="container">
    <div
        *ngIf="
            modalType === 'ADD_EXPENSETYPE' || modalType === 'EDIT_EXPENSETYPE'
        "
    >
        <div class="modal-header">
            <h4 class="modal-title">
                {{
                    modalType === "ADD_EXPENSETYPE"
                        ? ("addExpenseType" | translate)
                        : ("editExpenseType" | translate)
                }}
            </h4>
            <button id="close_popup"
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400">
            <form
                [formGroup]="expenseForm"
                (ngSubmit)="onSubmitExpenseType()"
                class="form"
            >
                <div class="form-group" style="width: 98%;">
                    <label class="float-left text-uppercase font-weight-bold">
                        {{ "expenseType" | translate }}
                    </label>
                    <input id="expense_category"
                        type="text"
                        name="category"
                        class="form-control"
                        formControlName="expenseCategory"
                        required
                        autocomplete="off"
                    />
                </div>
                <div
                    class="invalid-feedback text-left"
                    *ngIf="required('expenseCategory')"
                >
                    {{'expenceTypeNameIsRequired' | translate}}.
                </div>
                <div
                    class="invalid-feedback text-left"
                    *ngIf="lengthValidation('expenseCategory', 'min', false)"
                >
                    {{'mandatoryFieldMinLength' | translate}}.
                </div>
                <div
                    class="invalid-feedback text-left"
                    *ngIf="lengthValidation('expenseCategory', 'max', false)"
                >
                    {{'mandatoryFieldMaxLength' | translate}}.
                </div>
                <div class="form-group"  style="width: 98%;">
                    <label
                        class="float-left text-uppercase font-weight-bold"
                        attr.for="{{ 'subExpense' }}"
                    >
                        {{ "subCategory" | translate }}
                    </label>
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            id="subExpense"
                            placeholder="{{ 'subCategory' | translate }}"
                            formControlName="subcategories"
                            autocomplete="off"
                        />
                        <div class="input-group-append">
                            <button id="add_sub_category"
                                class="btn btn-primary btn-main"
                                type="button"
                                (click)="addSubCategory()"
                                [disabled]="!isValid('subcategories')"
                            >
                                <i class="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="invalid-feedback text-left"
                    *ngIf="required('subcategories')"
                >
                    {{'expenceTypeNameIsRequired' | translate}}.
                </div>
                <div
                    class="invalid-feedback text-left"
                    *ngIf="lengthValidation('subcategories', 'min', false)"
                >
                    {{'mandatoryFieldMinLength' | translate}}.
                </div>
                <div
                    class="invalid-feedback text-left"
                    *ngIf="lengthValidation('subcategories', 'max', false)"
                >
                    {{'mandatoryFieldMaxLength' | translate}}.
                </div>
                <div formArrayName="subExpenses" class="sub-category">
                    <div
                        [formGroupName]="i"
                        *ngFor="let item of subExpenses.controls; let i = index"
                    >
                        <div class="form-group">
                            <label
                                class="float-left text-uppercase font-weight-bold"
                                attr.for="{{ 'subCategory_' + i }}"
                                *ngIf="i == 0"
                            >
                                {{ "subCategory" | translate }}
                            </label>
                            <div class="input-group mb-3">
                                <input
                                    id="{{ 'subCategory_' + i }}"
                                    type="text"
                                    class="form-control sub-expense-type"
                                    placeholder="{{
                                        'subCategory' | translate
                                    }}"
                                    formControlName="expenseCategory"
                                    autocomplete="off"
                                />
                                <!-- <div class="input-group-append" *ngIf="i === 0">
                  <button class="btn btn-primary btn-main" type="button" [disabled]="!subExpenses.valid"
                    (click)="addSubCategory()">
                    <i class="fa fa-plus"></i>
                  </button>
                </div> -->
                                <div class="input-group-append">
                                    <button id="remove_sub_category"
                                        class="btn btn-primary btn-main"
                                        type="button"
                                        (click)="removeSubCategory(item, i)"
                                    >
                                        <!-- <i class="fa fa-trash"></i> -->
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div
                                class="invalid-feedback text-left"
                                *ngIf="
                                    item['controls'].expenseCategory?.errors
                                        ?.required &&
                                    item['controls'].expenseCategory?.touched
                                "
                            >
                                {{'subCategoryNameRequired' | translate}}
                            </div>
                            <div
                                class="invalid-feedback text-left"
                                *ngIf="
                                    item['controls'].expenseCategory?.errors
                                        ?.minlength
                                "
                            >
                                {{'mandatoryFieldMinLength' | translate}}.
                            </div>
                            <div
                                class="invalid-feedback text-left"
                                *ngIf="
                                    item['controls'].expenseCategory?.errors
                                        ?.maxlength
                                "
                            >
                                {{'mandatoryFieldMaxLength' | translate}}.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dropdown-divider" style="margin-top: 20px"></div>
                <div class="text-right">
                    <button
                        class="btn btn-primary btn-main btnSmall"
                        [disabled]="!expenseForm.valid"
                    >
                        {{
                            modalType === "ADD_EXPENSETYPE"
                                ? ("add" | translate)
                                : ("update" | translate)
                        }}
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div
        *ngIf="
            modalType === 'ADD_EXPENSE' ||
            modalType === 'EDIT_EXPENSE' ||
            modalType === 'ADD_RECURRING_EXPENSE' ||
            modalType === 'EDIT_RECURRING_EXPENSE'
        "
    >
        <div class="modal-header">
            <h4 class="modal-title">
                {{
                    modalType === "ADD_EXPENSE"
                        ? ("addExpense" | translate)
                        : ("editExpense" | translate)
                }}
            </h4>
            <button id="close_modal"
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body expense-form-body" mat-dialog-content>
            <form
                class="form"
                [formGroup]="expenseForm"
                (ngSubmit)="saveExpense()"
            >
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label
                                        for="expense_type"
                                        class="float-left text-uppercase font-weight-bold"
                                    >
                                        {{ "expenseType" | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        name="expense_type"
                                        id="expense_type"
                                        formControlName="expenseType"
                                        class="form-control"
                                        [matAutocomplete]="
                                            expenseTypeAutocomplete
                                        "
                                        autocomplete="off"
                                    />
                                    <mat-autocomplete
                                        #expenseTypeAutocomplete="matAutocomplete"
                                        (optionSelected)="
                                            onSelectExpenseType(
                                                $event.option.value
                                            )
                                        "
                                    >
                                        <mat-option
                                            *ngFor="
                                                let option of expenseTypeAuto
                                                    | async
                                            "
                                            [value]="option['expenseCategory']"
                                        >
                                            {{ option["expenseCategory"] }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div
                                    class="invalid-feedback text-left"
                                    *ngIf="required('expenseType')"
                                >
                                    {{ "expenseTypeRequired" | translate}}.
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label
                                        for="sub_expense_type"
                                        class="float-left text-uppercase font-weight-bold"
                                    >
                                        {{ "subexpenseType" | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        name="sub_expense_type"
                                        id="sub_expense_type"
                                        formControlName="subExpenseType"
                                        class="form-control"
                                        [matAutocomplete]="
                                            subExpenseTypeAutocomplete
                                        "
                                        autocomplete="off"
                                    />
                                    <mat-autocomplete
                                        #subExpenseTypeAutocomplete="matAutocomplete"
                                        (optionSelected)="
                                            onSelectSubExpenseType(
                                                $event.option.value
                                            )
                                        "
                                    >
                                        <mat-option
                                            *ngFor="
                                                let option of subExpenseTypeAuto
                                                    | async
                                            "
                                            [value]="option['expenseCategory']"
                                        >
                                            {{ option["expenseCategory"] }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div
                                    class="invalid-feedback text-left"
                                    *ngIf="required('subExpenseType')"
                                >
                                    {{ "subExpenseTypeRequired" | translate }}.
                                </div>
                            </div>
                            <ng-container
                                *ngIf="
                                    modalType === 'ADD_RECURRING_EXPENSE' ||
                                    modalType === 'EDIT_RECURRING_EXPENSE'
                                "
                            >
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label
                                            for="profile_name"
                                            class="float-left text-uppercase font-weight-bold"
                                        >
                                            {{ "profileName" | translate }}
                                        </label>
                                        <input
                                            type="text"
                                            name="profile_name"
                                            id="profile_name"
                                            formControlName="profileName"
                                            class="form-control"
                                            autocomplete="off"
                                        />
                                    </div>
                                    <div
                                        class="invalid-feedback text-left"
                                        *ngIf="required('profileName')"
                                    >
                                        {{ "profileNameIsRequired" | translate}}.
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label
                                            for="start_date"
                                            class="float-left text-uppercase font-weight-bold"
                                        >
                                            {{ "startDate" | translate }}
                                        </label>
                                        <input
                                            type="text"
                                            name="start_date"
                                            id="start_date"
                                            formControlName="startDate"
                                            class="form-control"
                                            autocomplete="off"
                                            #exStartDate
                                            [owlDateTimeTrigger]="
                                                expenseStartDt
                                            "
                                            [owlDateTime]="expenseStartDt"
                                        />
                                        <owl-date-time
                                            [pickerType]="'calendar'"
                                            #expenseStartDt
                                        ></owl-date-time>
                                        <span
                                            class="fa fa-calendar input-icon"
                                            aria-hidden="true"
                                            (click)="exStartDate.click()"
                                        ></span>
                                    </div>
                                    <div
                                        class="invalid-feedback text-left"
                                        *ngIf="required('startDate')"
                                    >
                                        {{ "startDateReqValidation" | translate}}.
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label
                                            for="end_date"
                                            class="float-left text-uppercase font-weight-bold"
                                        >
                                            {{ "endDate" | translate }}
                                        </label>
                                        <input
                                            type="text"
                                            name="end_date"
                                            id="end_date"
                                            formControlName="endDate"
                                            class="form-control"
                                            autocomplete="off"
                                            #exEndDate
                                            [owlDateTimeTrigger]="expenseEndDt"
                                            [owlDateTime]="expenseEndDt"
                                        />
                                        <owl-date-time
                                            [pickerType]="'calendar'"
                                            #expenseEndDt
                                        ></owl-date-time>
                                        <span
                                            class="fa fa-calendar input-icon"
                                            aria-hidden="true"
                                            (click)="exEndDate.click()"
                                        ></span>
                                    </div>
                                    <div
                                        class="invalid-feedback text-left"
                                        *ngIf="required('endDate')"
                                    >
                                        {{ "endDateIsRequired" | translate}}.
                                    </div>
                                </div>
                            </ng-container>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label
                                        for="account_number"
                                        class="float-left text-uppercase font-weight-bold"
                                    >
                                        {{ "accountNumber" | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        name="account_number"
                                        id="account_number"
                                        formControlName="accountNumber"
                                        class="form-control"
                                        autocomplete="off"
                                    />
                                </div>
                                <div
                                    class="invalid-feedback text-left"
                                    *ngIf="required('accountNumber')"
                                >
                                    {{ "accountNumberIsRequired" | translate}}.
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label
                                        for="expense_date"
                                        class="float-left text-uppercase font-weight-bold"
                                    >
                                        {{ "expenseDate" | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        name="expense_date"
                                        id="expense_date"
                                        formControlName="dateOfExpense"
                                        class="form-control"
                                        [owlDateTimeTrigger]="expenseDt"
                                        [owlDateTime]="expenseDt"
                                        autocomplete="off"
                                        #exDate
                                    />
                                    <owl-date-time
                                        [pickerType]="'calendar'"
                                        #expenseDt
                                    ></owl-date-time>
                                    <span id="ext_date"
                                        class="fa fa-calendar input-icon"
                                        aria-hidden="true"
                                        (click)="exDate.click()"
                                    ></span>
                                </div>
                                <div
                                    class="invalid-feedback text-left"
                                    *ngIf="required('dateOfExpense')"
                                >
                                    {{ "expenseDateIsRequired" | translate}}.
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label
                                        for="expense_statue"
                                        class="float-left text-uppercase font-weight-bold"
                                    >
                                        {{ "expenseStatus" | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        name="expense_statue"
                                        id="expense_statue"
                                        formControlName="expenseStatus"
                                        class="form-control"
                                        [matAutocomplete]="
                                            expenseStatusAutocomplete
                                        "
                                        autocomplete="off"
                                    />
                                    <mat-autocomplete
                                        #expenseStatusAutocomplete="matAutocomplete"
                                    >
                                        <mat-option
                                            *ngFor="
                                                let option of expenseStatusList
                                            "
                                            [value]="option"
                                        >
                                            {{ option }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div
                                    class="invalid-feedback text-left"
                                    *ngIf="required('expenseStatus')"
                                >
                                    {{ "expenseStatusIsRequired" | translate}}.
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label
                                        for="expense_notes"
                                        class="float-left text-uppercase font-weight-bold"
                                    >
                                        {{ "notes" | translate }}
                                    </label>
                                    <textarea
                                        name="expense_notes"
                                        id="expense_notes"
                                        formControlName="notes"
                                        placeholder="{{ 'notes' | translate }}"
                                        class="form-control"
                                    ></textarea>
                                </div>
                                <div
                                    class="invalid-feedback text-left"
                                    *ngIf="
                                        expenseForm
                                            .get('notes')
                                            .hasError('maxlength')
                                    "
                                >
                                    {{ "maxCharactersAllowed" | translate}}.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label
                                        for="amount"
                                        class="float-left text-uppercase font-weight-bold"
                                    >
                                        {{ "amount" | translate }}
                                    </label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <select id="amount_val"
                                                class="form-control"
                                                formControlName="currency"
                                            >
                                                <option value="USD">USD</option>
                                                <option value="INR">INR</option>
                                            </select>
                                        </div>
                                        <input
                                            type="number"
                                            min="1"
                                            name="amount"
                                            id="amount"
                                            formControlName="amount"
                                            class="form-control"
                                            autocomplete="off"
                                        />
                                    </div>
                                    <span
                                        class="fa fa-inr input-icon input-grp-icon"
                                        aria-hidden="true"
                                        *ngIf="
                                            expenseForm.get('currency').value ==
                                            'INR'
                                        "
                                    ></span>
                                    <span
                                        class="fa fa-usd input-icon input-grp-icon"
                                        aria-hidden="true"
                                        *ngIf="
                                            expenseForm.get('currency').value ==
                                            'USD'
                                        "
                                    ></span>
                                </div>
                                <div
                                    class="invalid-feedback text-left"
                                    *ngIf="required('amount')"
                                >
                                    {{ "costValueRequired" | translate}}.
                                </div>
                                <div
                                    class="invalid-feedback text-left"
                                    *ngIf="
                                        expenseForm.get('amount').errors
                                            ?.notValidAmt
                                    "
                                >
                                    {{
                                        expenseForm.get("amount").errors
                                            ?.notValidAmt?.value
                                    }}.
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div
                                    class="form-group"
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                    "
                                >
                                    <div
                                        class="img-container"
                                        style="
                                            height: 200px;
                                            width: 200px;
                                            border: 1px solid #555;
                                            align-self: center;
                                        "
                                    >
                                        <img
                                            name="img"
                                            *ngIf="
                                                showImage &&
                                                attachmentType === 'img'
                                            "
                                            [src]="imgSrc"
                                            class="img-responsive attachment"
                                        />
                                        <div
                                            *ngIf="
                                                showImage &&
                                                attachmentType !== 'img'
                                            "
                                            class="attachment-file"
                                        >
                                            <i
                                                class="fa fa-2x fa-file-pdf-o"
                                                aria-hidden="true"
                                            ></i>
                                            <div>{{ attachment?.name }}</div>
                                        </div>
                                    </div>
                                    <!-- <label for="expense_date" class="float-left text-uppercase font-weight-bold">{{'expenseDate' | translate}}</label> -->
                                    <button id="file_upload"
                                        class="btn btn-primary btn-main"
                                        style="align-self: center; width: 200px"
                                        (click)="fileUpload.click()"
                                    >
                                        <i
                                            class="fa fa-paperclip"
                                            aria-hidden="true"
                                        ></i>
                                        {{ "attachReceipt" | translate }}
                                    </button>
                                    <input
                                        type="file"
                                        name="expense_attachment"
                                        #fileUpload
                                        id="expense_attachment"
                                        style="visibility: hidden"
                                        (change)="onSelectAttachment($event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dropdown-divider" style="margin-top: 20px"></div>
                <div class="text-right">
                    <button id="expense_modal_type"
                        class="btn btn-primary btn-main btnSmall"
                        [disabled]="!expenseForm.valid"
                    >
                        {{
                            modalType === "ADD_EXPENSE" ||
                            modalType === "ADD_RECURRING_EXPENSE"
                                ? ("add" | translate)
                                : ("update" | translate)
                        }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
