import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CommonService } from "commonService";
import { HomeService } from "src/app/layout/home/service/home.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LoaderService } from "loaderService";
import { ToastrService } from "ngx-toastr";
import { EMPTY, Observable, of, Subject } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { WorkStatusService } from "src/app/layout/manage-request/manage-booking/containers/work-status/service/work-status.service";

@Component({
  selector: "app-search-location-reuseable-modal",
  templateUrl: "./search-location-reuseable-modal.component.html",
  styleUrls: ["./search-location-reuseable-modal.component.scss"],
})
export class SearchLocationReuseableModalComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  searchQueryItems = { searchType: "", searchValue: "" };
  locationForm: FormGroup;
  submitted = false;
  workStatusResponse;
  ids:any[];
  eName: string;
  nameSearchIsLoading: boolean = false;
  locationId: any;
  pageType;
  dialogData = {};
  selectedDateLocation = [];
  selectedLocationData: any;
  @Input() locationList;
  @Input() selectedDate;
  @Input() selectedOfficeDates;
  @Input() currentZonePass;

  currentZone: string;
  filteredDesks: Observable<any>;
  selectedLocationId: any;

  @Output() notifySearchLocation: EventEmitter<any> = new EventEmitter();
  @Output() saveDateLocation: EventEmitter<any> = new EventEmitter();

  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    private workStatusService: WorkStatusService
  ) {
    this.ids = new Array<any>();
  }

  ngOnInit() {
    this.locationForm = this.formBuilder.group({
      locationSearch: [""],
    });
    let i=0;
    this.selectedOfficeDates.forEach(element => {
      this.locationForm.addControl('locationSearch'+String(element.key),new FormControl(this.ids[i++],Validators.required));
    });
  

    this.commonService.currentZone
    .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
    .subscribe((res) => {
      this.currentZone = res;
    });


//if(this.currentZonePass)this.currentZone=this.currentZonePass
    this.onFocusEvent();
    let val=null;
   
    this.filteredDesks.subscribe(filteredDesksArray => {
      filteredDesksArray.forEach(e => {
        if (e.id == this.currentZonePass) {
          val = e;
        }
      });
    if(val)
    {  this.selectedOfficeDates.forEach(element => {
        this.locationForm.get('locationSearch' + String(element.key)).setValue(val.name.trim());
        this.onSelectLocation(val, element.key);
      });}
    });


  }

  // convenience getter for easy access to form fields
  get f() {
    return this.locationForm.controls;
  }
  checkError(date:any){
    const inputForm = this.locationForm.get('locationSearch'+date.key);
    if(inputForm.errors && (inputForm.dirty || inputForm.touched || this.submitted)){
      return true;
    }
    return false;
  }
  onSubmitSearch() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.locationForm.invalid) {
      return;
    } else {
      if (this.selectedOfficeDates.length) {
        this.saveLocations();
      } else {
        this.searchQueryItems = {
          searchType: "name",
          // searchValue: this.locationForm.value.locationSearch,
          searchValue: this.locationId,
        };
      }
    }
  }

  onFocusEvent(event=null) {
    this.filteredDesks = this.locationForm
      .get("locationSearch")
      .valueChanges.pipe(
        startWith(""),
        map((desk) =>
          desk ? this.filterDesks(desk) : this.locationList.slice()
        )
      );
  }

  /*on click of suggested data from typeahead dropdown*/
  onselectData(val) {
    this.selectedLocationData = val;
    this.locationId = val.id;
    this.eName = val.name.trim();
  }

  onSelectLocation(val, date) {
    this.selectedDateLocation.push({
      dayStart: [date],
      zoneId: val.id,
    });
    // console.log('this.selectedDateLocation', this.selectedDateLocation)
  }

  saveLocations() {
    // console.log('this.selectedDateLocation', this.selectedDateLocation)
    this.saveDateLocation.emit(this.selectedDateLocation);
   
  }

  filterDesks(name: string) {
    if (!!this.locationList && this.locationList.length > 0) {
      return this.locationList.filter(
        (loc) =>
          // console.log('location is... ', loc)
          loc.name.toLowerCase().indexOf(name.toLowerCase()) === 0
      );
    }
  }

  closeSearchLocationModal() {
    this.notifySearchLocation.emit(this.selectedLocationData);
  }
}
