import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import {HttpClientModule} from '@angular/common/http';
// import { Response } from '@angular/common/http';
// import { Headers, RequestOptions } from '@angular/common/http';
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment, modulesIds } from "../../../../environments/environment";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ToastrService } from "ngx-toastr";
import { ModuleidService } from "../../../services/moduleid-service";
import { map, debounceTime, shareReplay, distinctUntilChanged } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';
@Injectable({providedIn: "root"})
export class CardService {
    private moduleId;
    private BASE_URL;
    EMSURL;
    BASEURL;
    selectedCardSub$ = new BehaviorSubject<any>(null);
    selectedCardItem = this.selectedCardSub$.asObservable();

    constructor(
        private http: HttpClient,
        private toastrService: ToastrService,
        @Inject("BASE_URL") baseUrl: string,
        public moduleidService: ModuleidService
    ) {
        this.moduleId = 48;
        // this.BASE_URL = environment.BASE_URL + "ems/";
        // this.BASE_URL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.BASEURL = environment.BASE_URL;
    }

    getCards(zoneId) {
        //let postObj: any = { level: zonelevel };
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         "ss-header":
        //             '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
        //             zoneId +
        //             ', "moduleId":48,"sessionId":"c42b5b8a-2d6b-4023-a63e-f0c5fd543e6d"}'
        //     })
        // };
        // ,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)}
        let url = `${this.EMSURL}card/config`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    getCustomCards(zoneId) {
        let url = `${this.EMSURL}card/get/customCard`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    getConfigs(zoneId) {

        let url = `${this.EMSURL}module/config`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    updateConfig(zoneId, moduleId, data) {

        let datas = {
            "config": JSON.stringify(data),
            "moduleId": 48,
            "zoneId": zoneId
        }
        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId": ${moduleId},"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
          })
        };
        return this.http.post(this.EMSURL + "module/updateConfig?generate=true", datas, httpOptions);
    }
    createCard(zoneId, data, image) {
        //let postObj: any = { level: zonelevel };

        const formData: FormData = new FormData();
        formData.append('card', JSON.stringify(data));
        formData.append('file', image, image.name);
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         "ss-header":
        //             '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
        //             zoneId +
        //             ', "moduleId":48,"sessionId":"c42b5b8a-2d6b-4023-a63e-f0c5fd543e6d"}'
        //     })
        // };

        let url = `${this.EMSURL}card/createCard`
        return this.http.post(url, formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    editCard(zoneId, data, image) {
        //let postObj: any = { level: zonelevel };

        const formData: FormData = new FormData();
        formData.append('card', JSON.stringify(data));
        if (!!image) {
            formData.append('file', image, image.name);
        }

        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         "ss-header":
        //             '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
        //             zoneId +
        //             ', "moduleId":48,"sessionId":"c42b5b8a-2d6b-4023-a63e-f0c5fd543e6d"}'
        //     })
        // };
        let url = `${this.EMSURL}card/editCard`
        return this.http.post(url, formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    editCustomCard(zoneId, data, image) {
        //let postObj: any = { level: zonelevel };

        const formData: FormData = new FormData();
        formData.append('card', JSON.stringify(data));
        if (!!image) {
            formData.append('file', image, image.name);
        }

        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         "ss-header":
        //             '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
        //             zoneId +
        //             ', "moduleId":48,"sessionId":"c42b5b8a-2d6b-4023-a63e-f0c5fd543e6d"}'
        //     })
        // };
        let url = `${this.EMSURL}card/edit/customCard`
        return this.http.post(url, formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    disabledCard(zoneId, data) {
        //let postObj: any = { level: zonelevel };

        // const formData: FormData = new FormData();
        // formData.append('card',JSON.stringify(data));
        // formData.append('file', image, image.name);
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         "ss-header":
        //             '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
        //             zoneId +
        //             ', "moduleId":48,"sessionId":"c42b5b8a-2d6b-4023-a63e-f0c5fd543e6d"}'
        //     })
        // };
        let url = `${this.EMSURL}card/disableCard`
        return this.http.post(url, data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    setEditCardItem(item) {
        this.selectedCardSub$.next(item);
    }
    removeEditCardItem() {
        this.selectedCardSub$.next(null);
    }
    saveConfigCard(zoneId, data) {
        let url = `${this.EMSURL}card/assignPriority`
        return this.http.post(url, data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getAllServices(zoneId) {

        let url = `${this.EMSURL}module/appSide`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    serviceValidate(zoneId, data) {
        //let data = [];
        let url = `${this.EMSURL}card/validateCardModules`
        return this.http.post(url, data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    notificationSend(zoneId, data) {
        //let data = [];
        let url = `${this.BASEURL}cns/notification/sendV2`
        return this.http.post(url, data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    broadcastingnotification(startDate, endDate, page = 0, size = 0, zoneId) {
        let url = `${this.BASEURL}cns/broadcastingnotification/getAll?startTime=${startDate}&endTime=${endDate}&page=${page}&size=${size}`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, 33) })
    }

    getTanentList(zoneId) {
        let url = `${this.BASEURL}tms/tenant/list`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getZoneList(floorList, zoneId, page = 0, pageSize = 10) {
        let floorIds = floorList.join(",");
        //let url = `${this.EMSURL}user/search/v2?searchString=&searchParams=name,email,phone_num&zoneId=${zoneId}`
        let url = `${this.EMSURL}user/allUsersByZone?zoneIds=${floorIds}&page=${page}&size=${pageSize}`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getZoneListLocation(floorList, zoneId, page = 0, pageSize = 10) {
        let floorIds = floorList.join(",");
        //let url = `${this.EMSURL}user/search/v2?searchString=&searchParams=name,email,phone_num&zoneId=${zoneId}`
        let url = `${this.EMSURL}user/allUsersByZone/V2?zoneIds=${floorIds}&page=${page}&size=${pageSize}`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getUsersBySerach(textStaring, zoneId) {
        let url = `${this.EMSURL}user/search/v2?searchString=${textStaring}&searchParams=name,email,phone_num`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getTenantUsersBySerach(tenantid, zoneId) {
        let tenantList = tenantid.join("");
        let url = `${this.EMSURL}user/search/v2?searchString=&searchParams=name,email,phone_num&tenantId=${tenantid}&page=0&size=10000`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    public getchildZonesByLevelv2(data, zoneId): Observable<Object> {
        const endpoint = this.EMSURL + "zones/childZones/v2";
        return this.http
            .post(endpoint, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(shareReplay());
    }

    getNotificationUsers(notificationId, zoneId, page, size) {
        //let url = `${this.BASEURL}cns/broadcastingnotification/getById?id=${notificationId}`;
        let url = `${this.BASEURL}cns/broadcastingnotification/userInfo/auditId?id=${notificationId}&page=${page}&size=${size}`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getDownload(startDate, endDate, zoneId) {
        let url = `${this.BASEURL}cns/broadcastingnotification/downloadCSV?startTime=${startDate}&endTime=${endDate}`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getAuthCorporateConfig(zoneId) {
        let url = `${this.EMSURL}card/configExact`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getAuthCorporateSave(data, zoneId) {
        //let data = [];
         let url = `${this.EMSURL}card/updateCorporateMapping`
         return this.http.post(url,data, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
     }


    uploadUrl(zoneId,fileToUpload: File) {

    const endpoint = `${this.EMSURL}media/uploadMedia?fileType=img/png&targetFolder=Test`
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    var yourHeadersConfig = {
        "content-type": "multipart/form-data"
    };
    const httpOptions = {
        headers: new HttpHeaders({"ss-header":`{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId": ${this.moduleId},"correlationId":"${uuidv4()}"}`})
    };
    return this.http.post(endpoint, formData, httpOptions);

    }


    updateLogoUrl(zoneId, data) {

        let url = `${this.EMSURL}module/update/logoUrl`;

        return this.http.post(url, data,
            { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });

    }

    getIntegrationData(zoneId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":53,"sessionId":"' + localStorage.getItem('ssadmin_session') + '"}'
            })
        };
        let url = `${this.BASEURL}spacemanagement/settings/get`;
        return this.http.get(url, httpOptions);
    }

    setupIntegrationData(data, zoneId): Observable<Object> {
        const endpoint = this.BASEURL + "spacemanagement/settings/save";
        return this.http
            .post(endpoint, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(shareReplay());
    }

    testIntegrationData(data, zoneId): Observable<Object> {
        const endpoint = this.BASEURL + "spacemanagement/booking/create/integrationTest";
        return this.http
            .post(endpoint, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(shareReplay());
    }

    notificationCsvUpload(zoneId,form) {
        let url = `${this.EMSURL}bulk/upload/notificationCsv`
        return this.http.post(url,form, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    notificationCsvUploadV2(zoneId,form) {
        let url = `${this.EMSURL}bulk/upload/notificationCsv/V2`
        return this.http.post(url,form, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    downloadTemplate(zoneId) {
        let url = `${this.EMSURL}bulk/download/notificationCsv`
        return this.http.get(url, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    getUserList(zoneId) {
        let url = `${this.EMSURL}user/getUserDefaultSite?page=0&size=10000`
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }

    getZonesBySites(zoneId) {
        return this.http.get(this.EMSURL + "zones/allSites/user", {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }



}
