import { Injectable, Inject } from "@angular/core";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { ModuleidService } from 'src/app/services/moduleid-service';
import { environment } from 'src/environments/environment';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { Observable, Subject, throwError, BehaviorSubject, of } from "rxjs";
import { v4 as uuidv4 } from 'uuid';
import { share, shareReplay } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  moduleId;
  BASEURL;
  localVal;

  private messageSourceValidateIntegration = new BehaviorSubject(false);
    currentMessageValidateIntegration = this.messageSourceValidateIntegration.asObservable();

  constructor(
    public http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public moduleidService: ModuleidService
  ) {
    this.BASEURL = environment.BASE_URL;
    this.moduleId = moduleidService.getModueId();
    //this.moduleId = 3;
    this.localVal = localStorage.getItem("language");
   }

  getAllIntegrationList(zoneId) {
    let url = this.BASEURL + `ems/integrations/getStructure`;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      })
      .pipe(
      );
  }

  getIntegrationsConfigDetails(zoneId) {
    let url = this.BASEURL + `ems/integrations/getConfig`;
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":null, "moduleId":null,"sessionId": "${localStorage.getItem("ssadmin_session")},"locale": "${this.localVal}""}`
      })
    };
    return this.http
      .get(url, httpOptions)
      .map(function(response) {
          return response;
      });
    // url = this.BASEURL + `ems/integrations/getConfig`;

    // return this.http
    //   .get<any>(url, {
    //     params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
    //   })
    //   .pipe(
    //   );

  //   return of({
  //     "message": "Success",
  //     "statusCode": 200,
  //     "api": "/ems/integrations/getConfig",
  //     "response": {
  //         "id": 1,
  //         "zoneId": 5,
  //         "integrationType": "Wifi Data",
  //         "integrationConfig": {
  //             "wispConfig": {
  //                 "wispEnabled": true,
  //                 "isWispEnabled": true,
  //                 "wispAuthUrl": "https://smartenspaces.com",
  //                 "wispOccupantsApiUrl": "https://smartenspaces.com",
  //                 "wispSpacesApiUrl": "https://smartenspaces.com",
  //                 "clientId": "Test",
  //                 "clientSecret": "test",
  //                 "permAllocation.schedular.time": "test",
  //                 "schedularSuccessEmail": true,
  //                 "schedularFailEmail": true,
  //                 "alertEmails": "alok.ranjan@smartenspaces.com,faishal.raza@smartenspaces.com",
  //                 "pageSize": "10",
  //                 "permAllocationTemplateId": "Test",
  //                 "notificationErrorMessage": "Test",
  //                 "restCallSize": "20"
  //             },
  //             "wifiCheckinConfig": {
  //                 "wifiDataSuccessEmail": true,
  //                 "wifiDataFailEmail": true,
  //                 "errorEmailList": "alok.ranjan@smartenspaces.com,faishal.raza@smartenspaces.com",
  //                 "emailTemplateId": "1182"
  //             },
  //             "zoomConfig": {
  //                 "api_KEY": "PmEaLv_uTdSQy_7bYVzaCQ",
  //                 "api_SECRET": "YcF7XQhefgMy6jvsMPH01HY6ntK2qX9Z",
  //                 "API_KEY": "PmEaLv_uTdSQy_7bYVzaCQ",
  //                 "API_SECRET": "YcF7XQhefgMy6jvsMPH01HY6ntK2qX9Z",
  //                 "baseUrl": "https://api.zoom.us/v2/",
  //                 "dynamic_JWT": true,
  //                 "tokenLifeTimeMinutes": 1,
  //                 "token": "null",
  //                 "redirectUrl": "http://client1.smartenspaces.com/spacemanagement/zoom/accept/getAccessToken",
  //                 "zoomAppUrl": "https://zoom.us/signin?_x_zm_rtaid=c_18e8suRPWue9CN1Blstg.1644997477549.3ab15b24bd3655eb613d636d83157afc&_x_zm_rhtaid=902",
  //                 "authType": "oauth"
  //             }
  //         }
  //     }
  // })
  }

  changeValidateIntegration(message: any) {
    this.messageSourceValidateIntegration.next(message);
  }

  updateIntegrationsConfigDetails(data, zoneId) {
    let url = this.BASEURL + `ems/integrations/updateConfig`;

    return this.http
      .put<any>(url,data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      })
      .pipe(
      );
  }

  validateWISPIntegrationsConfigDetails(data, zoneId) {
    let url = this.BASEURL + `spacemanagement/allocation/permAllocation/wispIntegration/test`;

    return this.http
      .post<any>(url,data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      })
      .pipe(
      );
  }

  validateSftpIntegrationsConfigDetails(data, zoneId) {
    let url = this.BASEURL + `ems/user/sftpValidation/test`;

    return this.http
      .post<any>(url,data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      })
      .pipe(
      );
  }

  getAuthToken(zoneId,token){
    let url = this.BASEURL + `spacemanagement/zoom/accept/getAuthToken?code=`+token;
   
    return this.http
    .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
    })
    .pipe(
    );
  }

  externalEmailValidator(zoneId, gecId, emailList): Observable<any> {
      const httpOptions = {
          headers: new HttpHeaders({
              "ss-header":
                  '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                  zoneId +
              ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","gecId":"' + gecId + '","locale":"' + this.localVal + '"}'
          })
      };
      let url = this.BASEURL + `amenitybooking/booking/super-admin/externalEmailValidation?emailList=${emailList}`;
      return this.http.get(url, httpOptions);
  }

  testIntegrationData(data, zoneId): Observable<Object> {
    const endpoint = this.BASEURL + "amenitybooking/booking/integrationTest";
    return this.http
        .post(endpoint, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
    })
        .pipe(shareReplay());
  }

  getIntegrationMapping(zoneId, pageIndex, pageSize): Observable<any> {
    const httpOptions = {
        headers: new HttpHeaders({
            "ss-header":
                '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' + zoneId +
            ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","locale":"' + this.localVal +'"}'
        })
    };
    let url = this.BASEURL + `amenitybooking/gecintegrationmapping/superadmin/get?page=${pageIndex}&size=${pageSize}`;
    return this.http.get<any>(url,httpOptions);
  }

  getIntegrationStructure(zoneId): Observable<any> {
    const httpOptions = {
        headers: new HttpHeaders({
            "ss-header":
                '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' + zoneId +
            ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') +'","locale":"' + this.localVal +'"}'
        })
    };
    let url = this.BASEURL + `ems/integrations/getAllConfigEnabled`;
    return this.http.get<any>(url,httpOptions);
  }

  getBuildingAndFloor(zoneId, gecId,page, size): Observable<any> {
    const httpOptions = {
        headers: new HttpHeaders({
            "ss-header":
                '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' + zoneId +
            ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","gecId":"' + gecId +'","locale":"' + this.localVal +'"}'
        })
    };
    let url = this.BASEURL + `amenitybooking/entity/super-admin/get/gecId?gecId=${gecId}&page=${page}&size=${size}&getAll=true`;
    return this.http.get<any>(url,httpOptions);
  }

  saveEntities(zoneId, identifiedEntities,gecId) {
    const httpOptions = {
        headers: new HttpHeaders({
            "ss-header":
                '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                zoneId +
            ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","gecId":"' + gecId +'","locale":"' + this.localVal +'"}'
        })
    };
    let url = this.BASEURL + `amenitybooking/entity/create/bulk/v2`;
    return this.http.post<any>(url, identifiedEntities, httpOptions);
  }

  saveMappings(zoneId, data) {
    const httpOptions = {
        headers: new HttpHeaders({
            "ss-header":
                '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' + zoneId +
            ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","locale":"' + this.localVal +'"}'
        })
    };
    let url = this.BASEURL + `amenitybooking/gecintegrationmapping/superadmin/save/admin`;
    return this.http.post<any>(url, data, httpOptions);
  }

  updateStructure(zoneId, integrationData) {
    const httpOptions = {
        headers: new HttpHeaders({
            "ss-header":
                '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                zoneId +
            ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","locale":"' + this.localVal +'"}'
        })
    };
    let url = this.BASEURL + `ems/integrations/updateStructure`;
    return this.http.put<any>(url, integrationData, httpOptions);
  }

  getZoomJWT(zoneId, integrationId) {
    const httpOptions = {
      headers: new HttpHeaders({
          "ss-header":
              '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
              zoneId +
          ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","locale":"' + this.localVal +'"}'
        })
    };
    let url = this.BASEURL + `amenitybooking/zoom/getJWT?integrationId=${integrationId}`;

    return this.http
    .get<any>(url, httpOptions)
      .pipe(
      );
  }

  sendZoomAuthToken(zoneId, token, integrationId) {
    const httpOptions = {
        headers: new HttpHeaders({
            "ss-header":
                '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' + zoneId +
            ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","locale":"' + this.localVal +'"}'
        })
    };
    let url = this.BASEURL + `amenitybooking/zoom/accept/getAuthToken?${token}&integrationId=${integrationId}`;
    return this.http.post<any>(url, httpOptions);
  }

  permissionCheck(data, zoneId): Observable<Object> {
    let url = this.BASEURL + "amenitybooking/booking/permissionCheck";
    return this.http
        .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
        .pipe(shareReplay());
  }

  getHidValidate(data, zoneId):Observable<Object>{
    let url = this.BASEURL + "ems/integrations/hid/validate";
    return this.http.post(url, data, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)}).pipe(shareReplay())
  }

  setHidAccess(data, zoneId):Observable<Object>{
    let url = this.BASEURL + "ems/integrations/updateConfigByHID";
    return this.http.post(url, data, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)}).pipe(shareReplay())
  }
  
}

