<div>
    <!-- <app-loader></app-loader> -->
    <div class="" *ngIf="pageType == 'availability'">
        <div class="modal-header">

            <button id="close_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title">
                {{'wantToProceed' | translate}} {{data['remainingDesks']}} {{'desks' | translate | lowercase}}?
            </h5>
            <p class="mt-3">
                {{'stageForTicketNo' | translate}} {{data['requestDetails']['ticketId']}}
            </p>

            <p>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="requestStatus">
                    <mat-radio-button id="partial_data" [value]="1">{{'partial' | translate | uppercase}}</mat-radio-button>
                    &nbsp;
                    <mat-radio-button id="completed_data" [value]="2">{{'completed' | translate | uppercase}}</mat-radio-button>
                </mat-radio-group>
            </p>
            <button class="btn btn-primary btn-cancel btn-small" (click)="closePopup()">{{'cancel' | translate}}</button> &nbsp;
            <button id="status_confirmation" class="btn btn-primary btn-main btn-small"
                (click)="confirmStatus()">{{'confirm' | translate}}</button>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>


    <div class="" *ngIf="pageType == 'wayFindingOnly'">
        <div class="modal-header">
            <h4 class="modal-title">
                Alert
            </h4>
            <button id="close_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p *ngIf="wfhEnabled && wfhSettingsEnabled">
                <ng-container *ngIf="bookingInfo['deskAvailable'] else other_content">
                    Allocation already present on this day
                </ng-container>
                <ng-template #other_content>You need to be scheduled to come to office to make  a booking for the selected date/s</ng-template>
            </p>

            <p *ngIf="!wfhEnabled">
                <!-- You already have an existing booking. Please try again once your booking ends -->
                <ng-container *ngIf="bookingInfo['deskAvailable'] else other_content">
                    Allocation already present on this day
                </ng-container>
                <ng-template #other_content>{{selectedDateObj['isAllowBookingFalseReason']}}</ng-template>
            </p>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <div class="" *ngIf="pageType == 'booknow'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{'Enter request number' | translate}}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label for="exampleFormControlInput1">{{'REQUEST NUMBER' | translate}}</label>
                <input type="text" class="form-control" id="requestNumber" placeholder="" name="requestNumber"
                    [(ngModel)]="requestNumber">
            </div>
            <div class="form-group">
                &nbsp;
                <a id="view_ticket_details" href="javascript:void(0)" class="pull-right"
                    (click)="getTicketDetails()">{{'View Ticket Details' | translate}}</a>
            </div>

            <div *ngIf="showTicketInfo">
                <hr class="w-100">
                <b>{{'ticketDetails' | translate}}</b>
                <p class="mt-3">
                    <span class="ticketHeading">{{'requestedBy' | translate}}</span> -
                </p>
                <p> <span class="ticketHeading">{{'Demand' | translate}}</span> {{ticketDetails['requestDetails']['count']}}</p>
                <p> <span class="ticketHeading">{{'requestedType' | translate}}</span> {{ticketDetails['requestType']}}</p>

                <button id="cancel_btn" class="btn btn-primary btn-cancel btn-small"
                    (click)="closePopup()">{{'cancel' | translate}}</button> &nbsp;
                <button id="check_availability_btn" class="btn btn-primary btn-main btn-small"
                    (click)="checkAvailablity()">{{'checkAvailability' | translate}}</button>
            </div>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <!--add meeting room modal-->
    <div class="" *ngIf="pageType == 'addMeeting'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "addMeetingRoom" | translate }}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <!-- <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-row">
                        <div class="col-lg-12 col-md-12 col-sm-12"> -->
            <form class="form" id="add_meeting" [formGroup]="addMeetingRoomForm" (ngSubmit)="onaddMeetingSubmit()">
                <div class="row">
                    <div class="col-md-4 col-lg-4 col-sm-12 img-file-container">
                        <div class="image-container mt-2">
                            <label class="custom-file-label custom-upload-btn" for="inputGroupFile01" #labelImport
                                style="display: none;"></label>
                            <img [src]="imgURL" class="img-responsive  image-content" *ngIf="imgURL">
                            <input [(ngModel)]="uploadData" [ngModelOptions]="{standalone: true}" type="file"
                                class="form-control custom-file-input" id="importFile"
                                (change)="uploadFile($event.target.files,$event)"
                                aria-describedby="inputGroupFileAddon01" style="display: none;" #file />
                        </div>
                        <button type="button" class="btn btn-success mt-3" (click)="file.click()">
                            {{'selectFile' | translate}}
                        </button>
                    </div>
                    <div class="col-md-8 col-lg-8 col-sm-12">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{ "category" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <mat-form-field appearance="fill" class="">
                                            <mat-select [(ngModel)]="category" placeholder="{{'select' | translate}}" id="category_name"
                                                formControlName="categoryName"
                                                [ngClass]="{ 'is-invalid': submitted && f.categoryName.errors }">
                                                <mat-option *ngFor="let item of categoryList; let i = index"
                                                    value="{{ item.name }}">
                                                    {{ item.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="submitted && f.categoryName.errors" class="invalid-feedback">
                                            <div *ngIf="f.categoryName.errors.required">
                                                {{"Pleaseselectthecategory" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"building" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <mat-form-field appearance="fill" class="">
                                            <mat-select #buildingId [(ngModel)]="building_name" placeholder="{{'select' | translate}}"
                                                id="building_name" formControlName="buildingName"
                                                [ngClass]="{ 'is-invalid': submitted && f.buildingName.errors }"
                                                (selectionChange)="getFloorList(buildingId.value)">
                                                <mat-option *ngFor="let item of buildingList; let i = index"
                                                    value="{{ item.zoneId }}">
                                                    {{ item.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="submitted && f.buildingName.errors" class="invalid-feedback">
                                            <div *ngIf="f.buildingName.errors.required">
                                                {{"Pleaseselectthebuildingname" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"floor" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <mat-form-field appearance="fill" class="">
                                            <mat-select [(ngModel)]="floor_name" placeholder="{{'select' | translate}}" id="floor_name"
                                                formControlName="floorName"
                                                [ngClass]="{ 'is-invalid': submitted && f.floorName.errors }">
                                                <mat-option *ngFor="let item of floorList; let i = index"
                                                    value="{{ item.zoneId }}">
                                                    {{ item.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="submitted && f.floorName.errors" class="invalid-feedback">
                                            <div *ngIf="f.floorName.errors.required">
                                                {{"Pleaseselectthefloorname" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"amenities" | translate}}</label>
                                    <div class="input-group mb-1">
                                        <mat-form-field class="mat-select-width" [floatLabel]="'never'">
                                            <mat-label style="text-transform: none !important;">&nbsp;{{'select' | translate}}</mat-label>
                                            <mat-select multiple id="amenities_option" [(ngModel)]="amenities"
                                                formControlName="ameNities" placeholder="{{'select' | translate}}">
                                                <mat-option disabled>Select Amenities</mat-option>
                                                <mat-option [value]="list.name" *ngFor="let list of amenitiesList">
                                                    {{ list.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="submitted && f.ameNities.errors" class="invalid-feedback">
                                            <div *ngIf="f.ameNities.errors.required">
                                                {{"Pleaseselecttheamenities" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <mat-chip-list>
                                        <mat-chip class="mat-elevation-z88" *ngFor="let amenity of amenities"
                                            [color]="color" removable="true" (removed)="removeAmenity(amenity)">
                                            <!-- {{ amenity }}  -->
                                            <!-- {{ amenity | slice: 0:20 }}
                                            <span *ngIf="amenity.length > 20">...</span>
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list> --> 
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"specialrequest" | translate}}</label>
                                    <div class="input-group mb-1">
                                        <mat-form-field class="mat-select-width" [floatLabel]="'never'">
                                            <mat-label style="text-transform: none !important;">&nbsp;{{'select' | translate}}</mat-label>
                                            <mat-select multiple id="special_request_option"
                                                [(ngModel)]="special_request" formControlName="specialRequest"
                                                placeholder="{{'select' | translate}}">
                                                <mat-option disabled>Select Special Request</mat-option>
                                                <mat-option [value]="list.name" *ngFor="let list of specialList">
                                                    {{ list.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="submitted && f.specialRequest.errors" class="invalid-feedback">
                                            <div *ngIf="f.specialRequest.errors.required">
                                                {{"Pleaseselectthespecialrequest" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <mat-chip-list>
                                        <mat-chip class="mat-elevation-z88" *ngFor="let splRequest of special_request"
                                            [color]="color" removable="true" (removed)="removeSplRequest(splRequest)">
                                            <!-- {{ splRequest }} -->
                                           <!-- {{ splRequest | slice: 0:20 }}
                                            <span *ngIf="splRequest.length > 20">...</span>
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list> -->
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"Entername" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <input id="user_name"
                                            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
                                            class="form-control" [(ngModel)]="username" formControlName="userName"
                                            [ngClass]="{'is-invalid': submitted && f.userName.errors}">
                                        <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                                            <div *ngIf="f.userName.errors.required">
                                                {{"Pleaseenterthename" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"capacity" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <input id="capacity" maxlength="4" class="form-control" (keypress)="keyPress($event)"
                                            [(ngModel)]="capacity" placeholder="{{ 'capacity' | translate}}"
                                            formControlName="capaCity"
                                            [ngClass]="{'is-invalid':submitted && f.capaCity.errors}">
                                        <div *ngIf="submitted && f.capaCity.errors" class="invalid-feedback">
                                            <div *ngIf="f.capaCity.errors.required">
                                                {{"Pleaseenterthecapacity" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="isAllowedCapacity">
                                    <label class="font-weight-bold">{{
                                    "allowedCapacity" | translate 
                                }}<small class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <input id="allowed_capacity" (keypress)="keyPress($event)" class="form-control"
                                        maxlength="4" [(ngModel)]="allowed_capacity" formControlName="allowedcapaCity"
                                            placeholder="{{ 'allowedCapacity' | translate}}" [ngClass]="{
                                        'is-invalid':
                                            submitted && f.allowedcapaCity.errors
                                    }">
                                        <div *ngIf="submitted && f.allowedcapaCity.errors" class="invalid-feedback">
                                            <div *ngIf="f.allowedcapaCity.errors.required">{{
                                            "Pleaseentertheallowedcapacity" | translate
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="isOutlookVisible">
                                    <label class="font-weight-bold">{{"enteroutlookemail" | translate}}</label>
                                    <div class="input-group">
                                        <input id="outlook_email" class="form-control" [(ngModel)]="outlook_email"
                                            formControlName="outlookEmail"
                                            [ngClass]="{'is-invalid':submitted && f.outlookEmail.errors}">
                                        <div *ngIf="submitted && f.outlookEmail.errors" class="invalid-feedback">
                                            <div *ngIf="f.outlookEmail.errors.required">
                                                {{"Pleaseentertheoutlookemail" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 bt-1"></div>
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 my-3 text-right">
                        <button type="submit" id="add_btn" class="btn btn-primary btn-main btnSmall">
                            {{ "add" | translate }}
                        </button>
                    </div>
                </div>
                <!-- </div> -->
            </form>
            <!-- </div>
    </div>
        </div>
        </div> -->
        </div>
    </div>
    <div class="" *ngIf="pageType == 'doneMeeting'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "addMeetingRoom" | translate }}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <b>{{'successfullyAdded' | translate}}</b>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 br-1"></div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <button type="submit" id="ok_btn" class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ "ok" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="" *ngIf="pageType == 'addAmenities'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "addAmenity" | translate }}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <form class="form-group" id="add_amenity" [formGroup]="addAmenityForm"
                                (ngSubmit)="onaddAmenitySubmit()">
                                <div class="form-row">
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                        <label class="">{{
                                "Entername" | translate
                            }}*</label>
                                        <div class="input-group">
                                            <input id="add_amenity_name" class="form-control" [(ngModel)]="amenity_name"
                                                formControlName="amenityName" [ngClass]="{
                                    'is-invalid':
                                        amenitysubmitted && g.amenityName.errors
                                }">
                                            <div *ngIf="amenitysubmitted && g.amenityName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="g.amenityName.errors.required">{{
                                        "Pleaseenterthename" | translate
                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 bt-1"></div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 m-0">
                                        <button type="submit" id="add_amenity_btn"
                                            class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                                            {{ "add" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="" *ngIf="pageType == 'edit_amenity'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "editAmenity" | translate }}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <form class="form-group" id="edit_amenity" [formGroup]="editAmenityForm"
                                (ngSubmit)="oneditAmenitySubmit()">
                                <div class="form-row">
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                        <label class="">{{
                                "Entername" | translate
                            }}*</label>
                                        <div class="input-group">
                                            <input id="edit_amenity_name" class="form-control"
                                                [(ngModel)]="update_amenity" formControlName="updateamenityName"
                                                [ngClass]="{
                                    'is-invalid':
                                        editamenitysubmitted && t.updateamenityName.errors
                                }">
                                            <div *ngIf="editamenitysubmitted && t.updateamenityName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="t.updateamenityName.errors.required">{{
                                        "Pleaseenterthename" | translate
                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 bt-1"></div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 m-0">
                                        <button type="submit" id="edit_amenity_btn"
                                            class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                                            {{ "update" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="" *ngIf="pageType == 'addSpecialRequests'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "addSpecialRequest" | translate }}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <form class="form-group" id="add_special_request" [formGroup]="addSpecialRequestForm"
                                (ngSubmit)="onaddSpecialRequestSubmit()">
                                <div class="form-row">
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                        <label class="">{{
                                "Entername" | translate
                            }}*</label>
                                        <div class="input-group">
                                            <input id="add_special_request_name" class="form-control"
                                                [(ngModel)]="special_request_name" formControlName="specialRequestName"
                                                [ngClass]="{
                                    'is-invalid':
                                        specialrequestsubmitted && h.specialRequestName.errors
                                }">
                                            <div *ngIf="specialrequestsubmitted && h.specialRequestName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="h.specialRequestName.errors.required">{{
                                        "Pleaseenterthename" | translate
                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 bt-1"></div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 m-0">
                                        <button type="submit" id="add_special_request_btn"
                                            class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                                            {{ "add" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="" *ngIf="pageType == 'edit_specialrequest'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "editAmenity" | translate }}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <form class="form-group" id="edit_special_request" [formGroup]="editSpecialRequestForm"
                                (ngSubmit)="oneditSpecialRequestSubmit()">
                                <div class="form-row">
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                        <label class="">{{
                                "Entername" | translate
                            }}*</label>
                                        <div class="input-group">
                                            <input id="edit_special_request_name" class="form-control"
                                                [(ngModel)]="update_special_request"
                                                formControlName="updatespecialRequest" [ngClass]="{
                                    'is-invalid':
                                        editspecialreqsubmitted && v.updatespecialRequest.errors
                                }">
                                            <div *ngIf="editspecialreqsubmitted && v.updatespecialRequest.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="v.updatespecialRequest.errors.required">{{
                                        "Pleaseenterthename" | translate
                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 bt-1"></div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 m-0">
                                        <button type="submit" id="edit_amenity_btn"
                                            class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                                            {{ "update" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="" *ngIf="pageType == 'delete_meeting'">
        <div class="modal-header">
            <h5>{{deleteInfoData.displayName}}</h5>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                            {{ "areyousureyouwanttodelete" | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <button type="submit" id="delete_amenity_btn" (click)="ondeleteMeetingSubmit()"
                        class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ "ok" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="" *ngIf="pageType == 'delete_amenity'">
        <div class="modal-header">
            <h5>{{delete_amenity}}</h5>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                            {{ "areyousureyouwanttodelete" | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <button type="submit" id="delete_amenity_btn" (click)="deleteAmenity()"
                        class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ "ok" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'delete_specialrequest'">
        <div class="modal-header">
            <h5>{{delete_special_request}}</h5>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                            {{ "areyousureyouwanttodelete" | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <button type="submit" id="delete_amenity_btn" (click)="deleteSpecialReq()"
                        class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ "ok" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'edit_meeting'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "editMeetingRoom" | translate }}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <form class="form-group" id="edit_meeting" [formGroup]="editMeetingRoomForm"
                (ngSubmit)="oneditMeetingSubmit()">
                <div class="row">
                    <div class="col-md-4 col-lg-4 col-sm-12 img-file-container">
                        <div class="image-container mt-2">
                            <label class="custom-file-label custom-upload-btn" for="inputGroupFile01" #labelImport
                                style="display: none;"></label>
                            <img [src]="editimgURL" class="img-responsive  image-content" *ngIf="editimgURL">
                            <input [(ngModel)]="edituploadData" [ngModelOptions]="{standalone: true}" type="file"
                                class="form-control custom-file-input" id="importFile"
                                (change)="edituploadFile($event.target.files,$event)"
                                aria-describedby="inputGroupFileAddon01" style="display: none;" #file />
                        </div>
                        <button type="button" class="btn btn-success mt-3" (click)="file.click()">
                            {{'selectFile' | translate}}
                        </button>
                    </div>
                    <div class="col-md-8 col-lg-8 col-sm-12">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"Entername" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <input id="edit_user_name" class="form-control" [(ngModel)]="editusername"
                                            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
                                            formControlName="edituserName"
                                            [ngClass]="{'is-invalid': editsubmitted && k.edituserName.errors}">
                                        <div *ngIf="editsubmitted && k.edituserName.errors" class="invalid-feedback">
                                            <div *ngIf="k.edituserName.errors.required">
                                                {{"Pleaseenterthename" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"building" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <mat-form-field appearance="fill" class="">
                                            <mat-select #editBuildingId [(ngModel)]="edit_building_name"
                                                placeholder="{{'select' | translate}}" id="edit_buildingName"
                                                formControlName="editbuildingName"
                                                [ngClass]="{ 'is-invalid': editsubmitted && k.editbuildingName.errors }"
                                                (selectionChange)="getFloorList(editBuildingId.value)">
                                                <mat-option *ngFor="let item of buildingList; let i = index"
                                                    value="{{ item.zoneId }}">
                                                    {{ item.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="editsubmitted && k.editbuildingName.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="k.editbuildingName.errors.required">
                                                {{"Pleaseselectthebuildingname" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"floor" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <mat-form-field appearance="fill" class="">
                                            <mat-select [(ngModel)]="edit_floor_name" placeholder="{{'select' | translate}}"
                                                id="edit_floor_name" formControlName="editfloorName"
                                                [ngClass]="{ 'is-invalid': editsubmitted && k.editfloorName.errors }">
                                                <mat-option *ngFor="let item of floorList; let i = index"
                                                    value="{{ item.zoneId }}">
                                                    {{ item.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="editsubmitted && k.editfloorName.errors" class="invalid-feedback">
                                            <div *ngIf="k.editfloorName.errors.required">
                                                {{"Pleaseselectthefloorname" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"amenities" | translate}}</label>
                                    <div class="input-group">
                                        <mat-form-field class="mat-select-width" [floatLabel]="'never'">
                                            <mat-label style="text-transform: none !important;">&nbsp;{{'select' | translate}}</mat-label>
                                            <mat-select multiple id="edit_amenities_option" [(ngModel)]="edit_amenities"
                                                formControlName="editameNities" placeholder="{{'select' | translate}}">
                                                <mat-option disabled>Select Amenities</mat-option>
                                                <mat-option [value]="list.name" (click)="deselectItem(list.name)"
                                                *ngFor="let list of amenitiesList">
                                                    {{ list.name }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="editsubmitted && k.editameNities.errors" class="invalid-feedback">
                                            <div *ngIf="k.editameNities.errors.required">
                                                {{"Pleaseselecttheamenities" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <mat-chip-list>
                                        <mat-chip class="mat-elevation-z88" *ngFor="let amenity of edit_amenities"
                                            [color]="color" removable="true" (removed)="removeAmenity(amenity, 'edit')">
                                            <!-- {{ amenity }}  -->
                                           <!-- <div>
                                                {{ amenity | slice: 0:20 }}
                                                <span *ngIf="amenity.length > 20">...</span>
                                            </div>
                                            <div>
                                                <mat-icon matChipRemove>cancel</mat-icon>
                                            </div>
                                        </mat-chip>
                                    </mat-chip-list> -->
                                </div>
                                <div class="form-group" *ngIf="isOutlookVisible">
                                    <label class="font-weight-bold">{{"enteroutlookemail" | translate}}</label>
                                    <div class="input-group input-width">
                                        <input id="edit_outlook_email" class="form-control"
                                            [(ngModel)]="edit_outlook_email" formControlName="editoutlookEmail"
                                            [ngClass]="{'is-invalid': editsubmitted && k.editoutlookEmail.errors}">
                                        <div *ngIf="editsubmitted && k.editoutlookEmail.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="k.editoutlookEmail.errors.required">
                                                {{ "Pleaseentertheoutlookemail" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{"capacity" | translate}} <small
                                            class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <input id="edit_capacity" class="form-control" (keypress)="keyPress($event)"
                                            [(ngModel)]="editcapacity" formControlName="editcapaCity"
                                            maxlength="4" placeholder="{{ 'capacity' | translate}}"
                                            [ngClass]="{'is-invalid':editsubmitted && k.editcapaCity.errors}">
                                        <div *ngIf="editsubmitted && k.editcapaCity.errors" class="invalid-feedback">
                                            <div *ngIf="k.editcapaCity.errors.required">
                                                {{"Pleaseenterthecapacity" | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="isAllowedCapacity">
                                    <label class="font-weight-bold">{{
                                                "allowedCapacity" | translate 
                                            }}<small class="text-danger reqLabel">*</small></label>
                                    <div class="input-group">
                                        <input id="edit_allowed_capacity" (keypress)="keyPress($event)"
                                        maxlength="4" class="form-control" [(ngModel)]="edit_allowed_capacity"
                                            placeholder="{{ 'allowedCapacity' | translate}}"
                                            formControlName="editallowedcapaCity" [ngClass]="{
                                                    'is-invalid':
                                                    editsubmitted && k.editallowedcapaCity.errors
                                                }">
                                        <div *ngIf="editsubmitted && k.editallowedcapaCity.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="k.editallowedcapaCity.errors.required">{{
                                                        "Pleaseentertheallowedcapacity" | translate
                                                    }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="font-weight-bold">{{"specialrequest" | translate}}</label>
                                    <div class="input-group">
                                        <mat-form-field class="mat-select-width" [floatLabel]="'never'">
                                            <mat-label style="text-transform: none !important;">&nbsp;{{'select' | translate}}</mat-label>
                                            <mat-select multiple id="edit_special_request_option"
                                                [(ngModel)]="edit_special_request" formControlName="editspecialRequest"
                                                placeholder="{{'select' | translate}}">
                                                <mat-option disabled>Select Special Request</mat-option>
                                                <mat-option [value]="list.name" *ngFor="let list of specialList" 
                                                (click)="deselectSpecialReqItem(list.name)">
                                                    {{ list.name }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="editsubmitted && k.editspecialRequest.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="k.editspecialRequest.errors.required">
                                                {{ "Pleaseselectthespecialrequest" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <mat-chip-list>
                                        <mat-chip class="mat-elevation-z88"
                                            *ngFor="let editSplReq of edit_special_request" [color]="color"
                                            removable="true" (removed)="removeSplRequest(editSplReq, 'edit')">
                                            <!-- {{ editSplReq }}  -->
                                         <!--   {{ editSplReq | slice: 0:20 }}
                                            <span *ngIf="editSplReq.length > 20">...</span>
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 bt-1"></div>
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 m-0">
                        <button type="submit" id="update_btn" class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                            {{ "update" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-card *ngIf="pageType == 'qrCode'">
        <mat-card-title>
            <div class="qr-close">
                <span class="font-13">{{qrName}}</span>
                <i class="fa fa-window-close" style="float:right" aria-hidden="true" (click)="onNoClick()"></i>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div id="print-section">
                <!-- <img width="50%" height="50%" [src]="qrCode['qrImage']" /> -->
                <qrcode [qrdata]="QrCode" [colorlight]="'#04ff00'" [colordark]="'#ad0c1c'" [size]="256" [level]="'M'">
                </qrcode>

                <!-- <div class="row qr-code-div">
                      <div class="qr-header">
                      </div>
                      <canvas id="canvas" class="qr-code-canvas"></canvas>
                    </div> -->
            </div>
            <button class="" mat-raised-button color="primary" (click)="print(QrCode)">{{'print' | translate | titlecase}}</button>

        </mat-card-content>
    </mat-card>
    <div class="" *ngIf="pageType == 'bookingConfirmation'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "bookConfirm" | translate }}
            </h4>
            <button id="close_btn" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="booking-success-container">
                <div class="like-img-container">
                    <img src="./assets/images/like.svg" alt="bokking-success" />
                </div>
                <div class="booking-info">
                    <div class="booking-header">{{ "successfully booked" | translate }}</div>
                    <div class="booking-history">
                        <div class="history-header">{{ "booking details" | translate }} </div>
                        <div class="table-responsive booking-details">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td class="table-title">{{ "name" | translate }}</td>
                                        <td class="tab-info">{{bookedUser}}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-title" style="min-width: 150px;">
                                            {{ "meetingRoom" | translate }}</td>
                                        <td class="tab-info">{{bookedMeetingRoom}}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-title">{{ "department" | translate }}</td>
                                        <td class="tab-info">{{bookedDept}}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-title">{{ "bookingtill" | translate }}</td>
                                        <td class="tab-info">{{bookedTime}}</td>
                                    </tr>
                                    <tr *ngIf="data?.videLinkEnable">
                                        <td class="table-title">{{ data?.vieoLinkType }} link</td>
                                        <td class="tab-info"><a [href]="data?.link" target="_blank"> Open {{ data?.vieoLinkType }} Meeting </a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button id="ok_btn" type="button" class="btn btn-main btn-ok" data-dismiss="modal"
                        (click)="gotoListPage()">
                        {{ "ok" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="pageType == 'createDeskBooking'">

        <div class="modal-header">
            You have selected {{data.bookingSelected.length}} days
        </div>
        <div class="modal-body modal-content">
            <p class="modal-title">
                We Recommend !!
            </p>


            <div (click)="selectOption(i, recommend)" class="showOptions" [ngClass]="{'selectedDiv': selectedDiv === i }" *ngFor="let recommend of recommendations; let i=index">
                <p class="ml-3 mb-0">Option {{i+1}}</p>
                <div class="mat-card p-3">
                    
                    <ng-container *ngFor="let buildingFloorDetails of recommend | keyvalue">
                        <p>{{buildingFloorDetails["value"]["zoneLocationDto"]["building"]["name"]}}</p>
                        <p><b>{{buildingFloorDetails["value"]["zoneLocationDto"]["name"]}}</b>  <span class="float-right">{{buildingFloorDetails["value"]["availableDates"].length}}/{{data.bookingSelected.length}}</span></p>
                    </ng-container>
                </div>
            </div>
            <!-- <div (click)="selectOption(1)" class="showOptions" [ngClass]="{'selectedDiv': selectedOption === 1 }">
                
                <div class="mat-card p-3">
                    <p>Building 6A</p>
                    <p><b>Floor 1</b>  <span class="float-right">4/4</span></p>
                </div>
            </div>

            <div (click)="selectOption(2)"  class="showOptions" [ngClass]="{'selectedDiv': selectedOption === 2 }">
                <p class="ml-3 mb-0">Option 2</p>
                <div class="mat-card p-3">
                    <p>Building 6A ({{option1}})</p>
                    <p><b>Floor 2</b>  <span class="float-right">3/4</span></p>

                    <p>Building 6A ({{option2}})</p>
                    <p><b>Floor 3</b>  <span class="float-right">1/4</span></p>
                </div>
            </div> -->

            <div class="mt-3">
                <button (click)="continueBooking()" class="btn btn-main float-right">Continue</button>
            </div>
        </div>
    </div>

    <div *ngIf="pageType == 'bookingSeatManual'" class="bookingInformation">
        <ng-container *ngIf="!bookingConfirmation">
            <div class="modal-header">
                <button id="close_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <ng-container *ngFor="let item of data['selectedInfo']; let i=index">
                <div class="modal-header">
                    <p><b>Desk {{item['selectedSeat'][0]['displayName']}},</b>
                    <br /><b>{{item.floorName}}</b></p>
                </div>
                <div class="modal-body modal-content">
                <p class="seatsInfo"> 
                    <i class="fa fa-calendar float-left fa-5x calenderImg" aria-hidden="true"></i>
                        Reserve for selected {{(item['selectedDate'].split(",").length>1)? item['selectedDate'].split(",").length+' days' : 'day'}} <br />
                        ({{item.selectedDate}})
                    </p>

                </div>
            </ng-container>
        
            
            <div class="modal-footer">
                <button class="btn btn-main" (click)="confirmBooking()">Book Now</button>
            </div>                          
        </ng-container>
        <ng-container *ngIf="bookingConfirmation">
            <div class="modal-header">
                <h1 mat-dialog-title class="mx-auto mt-4"><img src="assets/images/check.svg" /></h1>
            </div>
            
            <ng-container *ngFor="let item of data['selectedInfo']; let i=index">
                <div class="modal-body modal-content">
                    <p class="mx-auto"><b>Desk {{item['selectedSeat'][0]['displayName']}},</b>
                    <br /><b>{{item.floorName}}</b></p>
                </div>
            </ng-container>
            <div class="modal-footer">
                <button class="btn btn-main" (click)="confirmOk()">Ok</button>
            </div>     
           
        </ng-container>
        
    </div>

    <div class="container" *ngIf="data.type == 'integration'">
        <div class="modal-header">
          <h4 class="modal-title">
            <!--*ngIf="data.type != 'addUserRole'"-->
            <ng-container *ngIf="data.headerName; else newDeb" >
                {{"setUpAzureActiveDirectory" | translate}}
            </ng-container>
            <ng-template #newDeb>
                {{"setUpTeamsAzureActiveDirectory" | translate}}
            </ng-template>
          </h4>
          <button
            id="close_btn"
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="overflow-y:visible;">
          <div class="row">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">Application Client ID</label>
              <input type="text" class="form-control" [(ngModel)]="loginDetails.applClientId"
                [ngModelOptions]="{standalone: true}" id="application_client_id" placeholder="********"
                >
            </div>
            <div class="form-group col-md-6"> 
              <label for="inputAccountNumber">Directory Tenant ID</label>
              <input type="text" class="form-control" id="dir_id" [(ngModel)]="loginDetails.dir_tenant_id"
                [ngModelOptions]="{standalone: true}" placeholder="********"
                >
              </div>
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">Client Key Secret</label>
              <input type="text" class="form-control" id="client_secret" [(ngModel)]="loginDetails.clientSecret"
                [ngModelOptions]="{standalone: true}" placeholder="******">
            </div>
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">Outlook Mail Box</label>
              <input type="text" class="form-control" id="outlook_email" [(ngModel)]="loginDetails.outlookEmail"
                [ngModelOptions]="{standalone: true}" placeholder="">
            </div>
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">Meeting Room Email</label>
              <input type="text" class="form-control" id="meeting_room_email" [(ngModel)]="loginDetails.meetingroomEmail"
                [ngModelOptions]="{standalone: true}" placeholder="">
            </div>
            <div class="form-group col-md-6">
              <label for="AddressinputAddress">Timezone</label>
              <div class="input-group">
                <mat-form-field class="custom-mat" appearance="fill">
                  <mat-select [(ngModel)]="timezone" name="zone" placeholder="{{'select' | translate}}" id="zone_name"
                  [ngModelOptions]="{standalone: true}">
                          <mat-option *ngFor="let zone of timeZones" value="{{zone.value}}">
                            {{ zone.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>
            <div class="form-group col-md-12">&nbsp;</div>
          </div>
        </div>
          <div class="">
            <!-- <button id="close_del_card" class="btn btn-main">{{'help' | translate}}</button> -->
            <ng-container  *ngIf="!isDisabled">
            <button id="setup_card" [disabled]="isDisabled" (click)="setUpIntegration()" class="btn btn-main float-right ml-4">{{'save' | translate}}</button>
             </ng-container>    
            <!-- <button id="cancel_card" (click)="closePopup()" class="btn btn-main float-right ml-4">{{'cancel' | translate}}</button> -->
            <button id="test_card" class="btn btn-main float-right" (click)="testIntegration()">
              {{'testIntegration' | translate}}</button>
          </div>
        
        </div>
      </div>



    <div class="container" *ngIf="data.type == 'integration-gsuite'">
        <div class="modal-header">
          <h4 class="modal-title">
            <!--*ngIf="data.type != 'addUserRole'"-->
            <ng-container *ngIf="data.headerName; else newDeb" >
                {{"setupGsuiteCalendar" | translate}}
            </ng-container>
            <ng-template #newDeb>
                {{"setUpTeamsAzureActiveDirectory" | translate}}
            </ng-template>
          </h4>
          <button
            id="close_btn"
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="overflow-y:visible;">
          <div class="row">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">Google Json Cred Path</label>
              <textarea  class="form-control" [(ngModel)]="gsuiteDetails.googleJsonCredential"
                [ngModelOptions]="{standalone: true}" id="application_client_id" placeholder="********"
                >
              </textarea>
            </div>
 
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">Google Application Name</label>
              <input type="text" class="form-control" id="googleApplicationName" [(ngModel)]="gsuiteDetails.googleApplicationName"
                [ngModelOptions]="{standalone: true}" placeholder="******">
            </div>
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">Google Service Account Id</label>
              <input type="text" class="form-control" id="googleServieAccountId" [(ngModel)]="gsuiteDetails.googleServiceAccountId"
                [ngModelOptions]="{standalone: true}" placeholder="">
            </div>
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">Google Service Account User</label>
              <input type="text" class="form-control" id="googleServiceAccountUser" [(ngModel)]="gsuiteDetails.googleServiceAccountUser"
                [ngModelOptions]="{standalone: true}" placeholder="">
            </div>

            <div class="form-group col-md-6">
                <label for="inputAccountNumber">Google User Email</label>
                <input type="text" class="form-control" id="userOutlookEmail" [(ngModel)]="gsuiteDetails.userOutlookEmail"
                  [ngModelOptions]="{standalone: true}" placeholder="">
              </div>
            <div class="form-group col-md-6">
                <label for="inputAccountNumber">Google Meeting Room Email</label>
                <input type="text" class="form-control" id="googleMeetingRoomEmail" [(ngModel)]="gsuiteDetails.googleMeetingRoomEmail"
                  [ngModelOptions]="{standalone: true}" placeholder="">
              </div>
            <div class="form-group col-md-6">
              <label for="AddressinputAddress">Timezone</label>
              <div class="input-group">
                <mat-form-field class="custom-mat" appearance="fill">
                  <mat-select [(ngModel)]="timezone" name="zone" placeholder="{{'select' | translate}}" id="zone_name"
                  [ngModelOptions]="{standalone: true}">
                          <mat-option *ngFor="let zone of timeZones" value="{{zone.value}}">
                            {{ zone.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>
            <div class="form-group col-md-12">&nbsp;</div>
          </div>
        </div>
          <div class="">
            <!-- <button id="close_del_card" class="btn btn-main">{{'help' | translate}}</button> -->
            <ng-container  *ngIf="!isDisabled">
            <button id="setup_card" [disabled]="isDisabled" (click)="setUpGsuiteIntegration()" class="btn btn-main float-right ml-4">{{'save' | translate}}</button>
            </ng-container>
            <!-- <button id="cancel_card" (click)="closePopup()" class="btn btn-main float-right ml-4">{{'cancel' | translate}}</button> -->
            <button id="test_card" class="btn btn-main float-right" (click)="testIntegrationGsuite()">
              {{'testIntegration' | translate}}</button>
          </div>
        
        </div>
      </div>


      <div class="container" *ngIf="data.type == 'videoLink'">
        <div class="modal-header">
          <h4 class="modal-title">
            <!--*ngIf="data.type != 'addUserRole'"-->
            {{ "zoomintegration" | translate }}
          </h4>
          <button
            id="close_btn"
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="overflow-y:visible;">
          <div class="row">
         
            <div class="form-group col-md-6">
              <label for="inputAccountNumber">API KEY</label>
              <input type="text" class="form-control" [(ngModel)]="calendar.zoomApiKey" 
                [ngModelOptions]="{standalone: true}" id="application_client_id" placeholder="********"
                >
            </div>
            <div class="form-group col-md-6"> 
              <label for="inputAccountNumber">API SECRET</label>
              <input type="text" class="form-control" id="dir_id" [(ngModel)]="calendar.zoomApiSecret" 
                [ngModelOptions]="{standalone: true}" placeholder="********"
                >
              </div>

        </div>
          <div class="">
            <!-- <button id="close_del_card" class="btn btn-main">{{'help' | translate}}</button> -->
            <ng-container  *ngIf="!isDisabled">
            <button id="setup_card" [disabled]="isDisabled" (click)="videoSetUpIntegration()" class="btn btn-main float-right ml-4">{{'save' | translate}}</button>
            </ng-container>    
            <!-- <button id="cancel_card" (click)="closePopup()" class="btn btn-main float-right ml-4">{{'cancel' | translate}}</button> -->
            <button id="test_card" class="btn btn-main float-right" (click)="videoTestIntegration()">
              {{'testIntegration' | translate}}</button>
          </div>
        
        </div>
      </div>


      <div class="container" *ngIf="data.type == 'successfail'">
        <div class="modal-header">
          <button
            id="close_btn"
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="overflow-y:visible;">
            <div class="row justify_content" *ngIf="data.success">
               <img src="./assets/images/check.svg" />
            </div>
            <br/>
          <div class="row justify_content" >
                <p>Your integration is {{data.msg}}</p>
            </div>
        </div>
      </div>

      <div class="container" *ngIf="data.type == 'downloadDateSelect'">
        <div class="modal-header">
           <h2>Date Selection</h2> 
          <button
            id="close_btn"
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="overflow-y:visible;text-align:left;">
            <div class="row">
            <div class="form-group col-md-4 col-lg-4">
                <label class="font-weight-bold"  >{{"startDate" | translate}} </label>
                <div class="input-group">
                    <input id="start_date" class="form-control" name="startDate"
                        placeholder="{{ 'startDate' | translate }}" [owlDateTimeTrigger]="dt"
                        [owlDateTime]="dt"    [(ngModel)]="startDate" readonly/>
                    <span [owlDateTimeTrigger]="dt"><i
                            class="fa fa-calendar fa-calender-icons"></i></span>
                    <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
            </div>
            <div class="form-group col-md-4 col-lg-4">
                <label class="font-weight-bold">{{"endDate" | translate}} </label>
                <div class="input-group">
                    <input id="end_date" class="form-control" name="endDate"
                        placeholder="{{ 'endDate' | translate }}" [owlDateTimeTrigger]="ed"
                        [owlDateTime]="ed" [min]="startDate" [(ngModel)]="endDate" readonly/>
                    <span [owlDateTimeTrigger]="ed"><i
                            class="fa fa-calendar fa-calender-icons"></i></span>
                    <owl-date-time [pickerType]="'calendar'" #ed></owl-date-time>
                </div>
            </div>
            <button id="save_btn" mat-mini-fab class="btn btn-main mt-4 margin-left-20 btnSmall bgColor margin-bottom-4" (click)="downloadBookings()">
                <img src="./assets/images/save.svg" alt=""/> 
            </button>
        </div>
      </div>
    </div>

      <div class="container" *ngIf="data.type == 'myBookings'">
        <form class="form-group" id="booking_details" [formGroup]="registerForm">
            <div class="modal-header">
                {{ "search" | translate }}
                <button id="reset_booking" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-left">
                <label for="searchCategory">SEARCH BY</label>
                <div class="input-group">
                    <mat-form-field appearance="fill">
                        <mat-select placeholder="{{'select' | translate}}" id="locationSearch"
                            formControlName="searchBy"
                            [ngClass]="{ 'is-invalid': submitted && l.searchBy.errors }">
                            <mat-option *ngFor="let item of data.searchCriteria; let i = index"
                                value="{{ item.searchKey }}">
                                {{ item.name }} 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submitted && l.searchBy.errors" class="invalid-feedback">
                        <div *ngIf="l.searchBy.errors.required">{{'Pleaseselectthecategory' | translate}}
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <button id="apply_btn" type="button" id="apply-filter" class="btn btn-main btn-apply" data-dismiss="modal" (click)="getSearchCategory()">
                        {{ "apply" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
      
