import { Component, ElementRef, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as Highcharts from "highcharts";
import { DatePipe } from '@angular/common';

const COLORS = ['#6D7089', '#6D91B4', '#DF9F7D', '#ECCF93', '#B1CBE1'];
const COLORED_PIE_CHART_COLORS = ['#F36D21', '#1F2551', '#86BCE3', '#FCC04F', '#5C5D5D'];
const PIE_CHART_COLORS = ['#20254E', '#EBEBEB', '#E27538'];

@Component({
  selector: 'analytics-modal',
  templateUrl: './analytics-modal.component.html',
  styleUrls: ['./analytics-modal.component.scss']
})
export class AnalyticsModalComponent implements OnInit {
  pageType;
  Highcharts = Highcharts;
  chart: Highcharts.Chart | null;
  chartOption;
  mainData;
  tableData;
  leftSectionData;
  rightSectionData;
  bookingIconUrl = 'assets/images/jumpree_digital/analytics/Bookings.png';
  footerHtml: string;

  @ViewChild('leftSection', { static: false }) leftSectionElement: ElementRef;
  @ViewChild('rightSection', { static: false }) rightSectionElement: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<AnalyticsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() {
    this.mainData = this.data['childGraphs'];
    if (this.data['childGraphs'].length > 0) {
      var leftSection = { ...this.data['childGraphs'][0] };
      var rightSection = { ...this.data['childGraphs'][1] };

      var startTime = new Date(this.data['startDate']);
      var endTime = new Date(this.data['endDate']);
      this.footerHtml = rightSection['footer'];

      const startTimeConverted = this.getOrdinalSuffix(startTime);
      const endTimeConverted = this.getOrdinalSuffix(endTime);
      if (this.footerHtml) {
        this.footerHtml = this.footerHtml.replace('{{startTime}}', startTimeConverted);
        this.footerHtml = this.footerHtml.replace('{{endTime}}', endTimeConverted);
      }

      this.pageType = this.data['type'];
      if (leftSection) {
        if (leftSection['chartType'] == 'PIE_CHART') {
          if (leftSection['values'] && leftSection['values'].length > 0) {
            let dataPoints = [];
            const labels = leftSection['labels'];
            leftSection['values'].forEach(function (val, i) {
              let points = {
                name: labels[i],
                y: Number(val),
                color: PIE_CHART_COLORS[i % PIE_CHART_COLORS.length]
              };
              dataPoints.push(points);
            });
            leftSection['pieChart'] = this.loadPieChart(dataPoints, true);
          }
        } else if (leftSection['chartType'] == 'COLORED_PIE_CHART') {
          if (leftSection['values'] && leftSection['values'].length > 0) {
            let dataPoints = [];
            const labels = leftSection['labels'];
            leftSection['values'].forEach(function (val, i) {
              let points = {
                name: labels[i],
                y: Number(val),
                color: COLORED_PIE_CHART_COLORS[i % (COLORED_PIE_CHART_COLORS.length)]
              };
              dataPoints.push(points);
            });
            leftSection['pieChart'] = this.loadPieChart(dataPoints, false);
          }
        } else if (leftSection['chartType'] == 'BAR_CHART_VERTICAL') {
          if (leftSection['values'] && leftSection['values'].length > 0) {
            let dataPoints = [];
            let color = '#20254E';
            let largest = leftSection['values'].reduce((a, b) => Math.max(a, b));
            leftSection['values'].forEach(function (val, i) {
              if (val == largest) {
                color = '#AF0808'
              } else {
                color = '#20254E';
              }
              let points = {
                y: Number(val),
                color: color
              };
              dataPoints.push(points);
            });
            leftSection['verticalBarChart'] = this.loadVerticalBarChart(dataPoints, leftSection['labels'], false);
          }
        }
        this.leftSectionData = leftSection;
      }
      if (rightSection) {
        if (rightSection['chartType'] == 'PIE_CHART') {
          if (rightSection['values'] && rightSection['values'].length > 0) {
            let dataPoints = [];
            const labels = leftSection['labels'];
            rightSection['values'].forEach(function (values, i) {
              let points = {
                name: labels[i],
                y: Number(values),
                color: PIE_CHART_COLORS[i % PIE_CHART_COLORS.length]
              };
              dataPoints.push(points);
            });
            rightSection['pieChart'] = this.loadPieChart(dataPoints, true);
          }
        } else if (rightSection['chartType'] == 'COLORED_PIE_CHART') {
          if (rightSection['values'] && rightSection['values'].length > 0) {
            let dataPoints = [];
            const labels = rightSection['labels'];
            rightSection['values'].forEach(function (val, i) {
              let points = {
                name: labels[i],
                y: Number(val),
                color: COLORED_PIE_CHART_COLORS[i % (COLORED_PIE_CHART_COLORS.length)]
              };
              dataPoints.push(points);
            });
            rightSection['pieChart'] = this.loadPieChart(dataPoints, false);
          }
        } else if (rightSection['chartType'] == 'BAR_CHART_VERTICAL') {
          if (rightSection['values'] && rightSection['values'].length > 0) {
            let dataPoints = [];
            let color = '#20254E';
            let largest = rightSection['values'].reduce((a, b) => Math.max(a, b));
            rightSection['values'].forEach(function (val, i) {
              if (val == largest) {
                color = '#AF0808'
              } else {
                color = '#20254E';
              }
              let points = {
                y: Number(val),
                color: color
              };
              dataPoints.push(points);
            });
            rightSection['verticalBarChart'] = this.loadVerticalBarChart(dataPoints,
              rightSection['labels'], false);
          }
        } else if (rightSection['chartType'] == 'TABLE_VIEW') {
          if (rightSection['labels'] && rightSection['labels'].length > 0) {
            if (rightSection['labels'][0] != ' ')
              rightSection['labels'].unshift(' ');
          }
          if (rightSection['values'] && rightSection['values'].length > 0) {
            if (rightSection['values'][0][0] != 1)
              rightSection['values'].map((val, index) => val.unshift(index + 1));
          }
        }
        this.rightSectionData = rightSection;
      }
    }
  }

  handleClick(event: Event) {
    const clickedElement = event.target as HTMLElement;
    const leftElementContent = this.leftSectionElement.nativeElement as HTMLElement;
    const rightElementContent = this.rightSectionElement.nativeElement as HTMLElement;

    if (!(leftElementContent.contains(clickedElement) || rightElementContent.contains(clickedElement))) {
      this.closePopup();
    }
  }

  closePopup() {
    this.dialogRef.close();
  }

  loadPieChart(dataPoints, showCenter) {
    let displayLegends = !showCenter; // for displaying legends if we are not showing center %
    let showCenterVal;
    let height;
    let dval = "";
    if (dataPoints.length > 0) {
      if (dataPoints[0]['y'] != null && dataPoints[0]['y'] != undefined) {
        dval = dataPoints[0]['y'];
      }
    }
    if (showCenter) {
      showCenterVal = dval + '%';
      height = 200;
    }
    else {
      showCenterVal = "";
      height = 250;
    }
    return {
      chart: {
        type: "pie",
        height: height,
        events: {
          render: function () {
            // Create the label            
            // Get chart center coordinates
            var centerX = this.plotWidth / 2;
            var centerY = this.plotHeight / 2;
            var label = this.renderer
              .label(showCenterVal, centerX, centerY)
              .css({
                color: '#1f2551',
                fontSize: '30px',
                fontWeight: 'bold',
              })
              .attr({
                fill: 'rgba(255, 255, 255, 0.75)',
                padding: 10,
                r: 5,
              })
              .add();
            // Position the label in the center of the chart
            var labelBox = label.getBBox();
            label.translate(centerX - labelBox.width / 3, centerY - labelBox.height / 3);

          }
        },

      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: '<b>{point.y} ({point.percentage:.1f}%)</b>',
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.y} ({point.percentage:.1f}%)</b>',
            distance: -50,
            style: {
              color: 'red',
              textOutline: 'none',
              fontWeight: 'bold',
              fontSize: '14px',
            },
          },
        },
      },
      series: [
        {
          type: "pie",
          id: "data",
          data: dataPoints,
          innerSize: "80%",
          dataLabels: {
            enabled: false,
          },

          showInLegend: displayLegends,
        },
      ],
      legend: {
        labelFormatter: function () {
          const legendItem = dataPoints.find(item => item.name === this.name);
          return legendItem.name + '<br>' + legendItem.y + '%';

        },
      }
    }
  }

  loadVerticalBarChart(dataPoints, labels, dataLables) {
    return {
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: labels,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        labels: {
          enabled: false,
        },
        gridLineDashStyle: 'Dash',
      },
      plotOptions: {
        series: {
          pointWidth: 10,
          borderRadius: 5,
        },
        column: {
          dataLabels: {
            enabled: dataLables,
            format: '{point.y}',
            style: {
              fontWeight: 'bold',
            },
          },
        },
      },
      title: {
        text: "",
      },
      series: [
        {
          showInLegend: false,
          name: 'value',
          data: dataPoints,

        },
      ],
    }
  }

  getBgColorByIndex(index) {
    return COLORS[index % (COLORS.length)];
  }

  getSplitVal(val: string, index: number) {
    return val.split('~')[index];
  }

  getFirstLetters(data: string) {
    var name = data.split('~')[0];
    const nameArr = name.split(' ');
    if (nameArr.length > 1) {
      return Array.from(nameArr[0])[0] + Array.from(nameArr[nameArr.length - 1])[0];
    } else
      return Array.from(nameArr[0])[0];
  }

  getOrdinalSuffix(date: Date): string {
    const day: number = date.getDate();
    var result = day + '';
    const lastDigit = day > 20? day % 10:day;
    switch (lastDigit) {
      case 1: result += 'st';
        break;
      case 2: result += 'nd';
        break;
      case 3: result += 'rd';
        break;
      default: result += 'th';
        break;
    }
    result += ' ' + new DatePipe('en-US').transform(date, 'MMM yyyy');
    return result;
  }
}
