import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-colleagues',
  templateUrl: './colleagues.component.html',
  styleUrls: ['./colleagues.component.scss']
})
export class ColleaguesComponent implements OnInit {

  showSearchColleagues = true;
  @Input() colleagueObj;
  @Output() notifyParentColleague: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {

  }

  // showSearchColleague() {
  //   this.showSearchColleagues = true;
  // }

  getNotification(event)
  {   
      if(event=="true")
      { 
        this.notifyParentColleague.emit('true');
      }    
  }
}
