import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatInput, MatSelect, MatOption, MatCheckbox, MatTableDataSource, MatRadioButton, MatRadioGroup } from '@angular/material';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss']
})
export class SettingsDialogComponent implements OnInit {

  pageType: any;
  intivationId: any;
  questionDetails: any;
  alldetails = [];
  alldetailsList: any[];
  uploadData;

  @ViewChild("labelImport", { static: false }) labelImport: ElementRef;
  public fileToUpload: File;
  constructor(
    public dialogRef: MatDialogRef<SettingsDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pageType = this.data['type'];
    if (this.data['type'] == 'visitorInfo') {

      this.intivationId = this.data['invitationVisitorInfoId'];
      //this.getAllActiveIdentityAnswered();
    }
  }

  ngOnInit() {
    if (this.data['type'] == 'showDetails') {

      this.questionDetails = this.data;
      this.showDetailsOfQuestions(this.questionDetails)

    }
  }

  showDetailsOfQuestions(questionDetails) {
    this.alldetails.push(questionDetails.data)

    let arraList = this.alldetails;
    let questionList = [];
    let optionVal = null;
    arraList.forEach((element) => {
      let KeyName = "";
      if (element.type == "TEXT") {
        KeyName = "VALIDATE_TEXT_TYPE";

        let queObje = {
          id: element.id,
          type: element.type,
          question: element.name,
          option: optionVal,
          fieldKey: KeyName,
          // title: element.title,
          // isMandatory: element.isMandatory,
          // isAutoApproval:element.isAutoApproval
        };
        questionList.push(queObje);
      } else if (element.type == "NUMBER") {
        KeyName = "VALIDATE_NUMBER_TYPE";

        let queObje = {
          id: element.id,
          type: element.type,
          question: element.name,
          option: optionVal,
          fieldKey: KeyName,
          // title: element.title,
          // isMandatory: element.isMandatory,
          // isAutoApproval:element.isAutoApproval
        };
        questionList.push(queObje);
      } else if (element.type == "FILE_UPLOAD") {
        KeyName = "VALIDATE_FILE_UPLOAD_TYPE";

        let queObje = {
          id: element.id,
          type: element.type,
          question: element.name,
          option: optionVal,
          fieldKey: KeyName,
          // title: element.title,
          // isMandatory: element.isMandatory,
          // isAutoApproval:element.isAutoApproval
        };
        questionList.push(queObje);
      }
      else if (element.type == "RADIO_BUTTON") {
        KeyName = "VALIDATE_RADIO_TYPE";
        if (!!element.attributes) {
          optionVal =
            element.attributes.values;
          let arrayOption = [];
          // for (const [key, value] of Object.entries(optionVal)) {
          //   let keyVal = { key: key, val: value };
          //   arrayOption.push(keyVal);
          // }
          arrayOption.push(...optionVal)
          let queObje = {
            id: element.id,
            type: element.type,
            question: element.name,
            option: arrayOption,
            fieldKey: KeyName,
            //title: element.title,
            //isMandatory: element.isMandatory,
            //isAutoApproval:element.isAutoApproval
          };
          questionList.push(queObje);
        }
      } else if (element.type == "CHECKBOX") {
        KeyName = "VALIDATE_CHECKBOX_TYPE";
        if (!!element.attributes) {
          optionVal =
            element.attributes.values;
          let arrayOption = [];
          // for (const [key, value] of Object.entries(optionVal)) {
          //   let keyVal = { key: key, val: value };
          //   arrayOption.push(keyVal);
          // }
          arrayOption.push(...optionVal);
          let queObje = {
            id: element.id,
            type: element.type,
            question: element.name,
            option: arrayOption,
            fieldKey: KeyName,
            // title: element.title,
            // isMandatory: element.isMandatory,
            // isAutoApproval:element.isAutoApproval
          };
          questionList.push(queObje);
        }
      }
      else if (element.type == "DATE") {
        KeyName = "VALIDATE_DATE_TYPE";

        let queObje = {
          id: element.id,
          type: element.type,
          question: element.metadata.question,
          option: optionVal,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
          isAutoApproval: element.isAutoApproval,
          selectedvalue: element.metadata.ruleBasedCondition[KeyName].attributes.rules.type
        };
        questionList.push(queObje);
      }

      else if (element.type == "SINGLE_SELECT_DROPDOWN" || element.type == "MULTI_SELECT_DROPDOWN") {
        KeyName = "VALIDATE_DROPDOWN_TYPE";
        let queObje = {
          id: element.id,
          type: element.type,
          //question: element.metadata.question,
          question: element.name,
          option: optionVal,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
          isAutoApproval: element.isAutoApproval,
          // selectedvalue: element.metadata.ruleBasedCondition[KeyName].attributes.rules.optionList
          selectedvalue: element.attributes.values
        };
        questionList.push(queObje);
      }
    });
    this.alldetailsList = questionList;
  }

  uploadCSV(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
        .map(f => f.name)
        .join(", ");
    this.fileToUpload = files.item(0);


}

  closePopup() {
    this.dialogRef.close();
  }

}

export class VistorsCsvRecord {

  public name: any;
  public email: any;
  public mobile: any;
  public company: any;
  public visitorTypeId: any;
  constructor() { }
}

