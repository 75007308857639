import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TenantVerifyService } from './tenantVerify.service';
import { Router } from '@angular/router';

@Component({
    selector: 'tenant-verify',
    templateUrl: './tenantVerify.component.html',
    styleUrls: ['./tenantVerify.component.scss']
    
})

export class TenantVerifyComponent implements OnInit {
    token: string;
    userType: string;
    tenantVerifyRequest: any ={};
    tenantVerifyMessage: string = 'Account Unverified';
    tenantverifiedName: string;
    constructor(public route: ActivatedRoute,public router:Router, public tenantVerifyService:TenantVerifyService){

    }
    ngOnInit(){
        var currentContext = this;
        this.route.params.subscribe(params => {
           this.token = params['token'];
           this.userType = params['userType'];
           this.tenantVerifyRequest['token'] = this.token;
        });
        this.tenantVerifyService.verifyTenantAccount(this.tenantVerifyRequest).subscribe(res=>{
            this.tenantVerifyMessage = JSON.stringify(res['message']+ ' for user account');
            this.tenantverifiedName = res['response']['name']+'('+res['response']['email']+')' ;     
            if(currentContext.userType){
                this.tenantVerifyMessage += '(tenant):';
                this.router.navigate(["/login", this.tenantVerifyMessage, this.tenantverifiedName]);
            }            
        },err=>{
            this.tenantVerifyMessage = 'Something went wrong! ,'+JSON.stringify(err['error']['message']);           
        });
    }
}