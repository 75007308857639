import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AlertService } from 'alertService';
import { CommonService } from 'commonService';
import { EMSService } from 'emsService';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap, map, delay } from 'rxjs/operators';
import { HomeService } from 'src/app/layout/home/service/home.service';
import { ImsService } from '../../service/ims-service';
import { LoaderService } from 'loaderService';
@Component({
  selector: 'app-imsmodal',
  templateUrl: './imsmodal.component.html',
  styleUrls: ['./imsmodal.component.scss']
})
export class IMSModalComponent implements OnInit, AfterViewInit, OnDestroy {
  currentZone;

  ticketData;
  stateList;
  userList;
  editManageTicket: any = {};
  editMyTicket: any = {};
  priorityList = ['LOW', 'MEDIUM', 'HIGH'];
  isCommentRequired: boolean = false;
  isAssignUserRequired: boolean = false;
  currentState;
  currentPriority;
  imgFile;
  url;
  showImage;
  category = {
    'name': undefined,
    'id': undefined,
    'categoryDetail': undefined
  };
  groupName;
  subCategoryList = [{ 'name': '' }, { 'name': '' }];
  newSubCategoryList: any[] = [];
  slaLevelList: any[] = [{ 'group': '', 'time': '' }];
  newSlaLevelList: any[] = [];
  groupUsers = [];
  allZones = [];
  selectedZones = [];
  group = {};
  selectedUsers = [];
  allUsers = [];
  userGroups = [];
  userGroupsNew = [];
  zoneMap = {};
  newSubCategory;

  //auto complete for ems user drop down with  pagination 

  public bankServerSideCtrl: FormControl = new FormControl();
  public bankServerSideFilteringCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  public filteredServerSideBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  pageLimt: number = 10;
  pageNo: number = 0;
  pageSize: number = 10;
  totalPage;
  shortDate;
  showLoadMore: boolean = false;
  searchQuery = '';
  selectedEmsUser: any = [];
  selectedEmsUserIds = [];
  selectedEmsUserIdss = [];
  isSlaEdit;
  //add SLA
  subCategory = {
    'edit': undefined,
    'level': undefined,
    'subCategoryName': undefined,
    'sla': undefined,
    'teamId': undefined,
    'interval': undefined
  };
  teamDataList = [];
  chatList;
  chatfile;
  chaturl;
  chatImgFile;
  chatTicketId: any;
  raisedById;
  chatShowImage = false;
  showMoreChat = true;
  chatPageIndex = 1;
  chatPageSize= 5;
  totalChatCount = 0;

  chatText;

  constructor(
    public matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<IMSModalComponent>,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public commonService: CommonService,
    public emsService: EMSService,
    public imsService: ImsService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private homeService: HomeService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any) {



    if (data['type'] == 'editUserGroup') {
      this.groupName = data['name'];
    }
    if (data['type'] == 'editUserGroupNew') {
      this.currentZone = data['zone'];
      this.groupName = data['teamName'];
    }
    if (data['type'] == 'groupDetails') {
      this.currentZone = data['zone'];
      this.getUserGroupDetails(data);
    }
    if (data['type'] == 'editImsCategory') {

      this.category['name'] = data.categoryName;
      this.getCategoryById(data['categoryId']);
    }
    if (data['type'] == 'AddEscalation') {
      this.subCategory = data.subCategory;
      this.teamDataList = data.teamDataList;
      // this.getCategoryById(data['categoryId']);

    }
    if (data["type"] == "chatShow") { 
      this.chatList = data["stateList"]; 
      this.chatTicketId  =  data.ticketData.ticketId;
      if(data['userList'] <= 5){
        this.showMoreChat = false;
      }
      //this.raisedById = data["ticketData"]["raisedById"];ssadmin_userId
      this.raisedById = localStorage.getItem('ssadmin_userId');
    }
  }


  ngOnInit() {
    this.commonService.currentZone.subscribe(res => {
      if ((res)) {
        this.currentZone = res;
        this.getAllZones();
      }
    });

    //sankar start
    if (this.data["type"] === "editManageTicketNew") {

      this.ticketData = (this.data as any).ticketData;
      this.stateList = (this.data as any).stateList;
      this.userList = (this.data as any).userList;
      this.currentState = this.ticketData.currentState;
      this.editManageTicket.state = this.currentState;
      this.currentZone = this.data.zone;
      // if (this.currentState == 'RESOLVED' || this.currentState == 'CANCELLED') {
      if (this.currentState == 'CANCELLED' || this.currentState == 'ON_HOLD') {
        this.isCommentRequired = true;
      } else {
        this.isCommentRequired = false;
      }
      this.currentPriority = this.ticketData.priority;
      this.editManageTicket.groupId = this.ticketData.teamId;
      if (!!this.editManageTicket.groupId) {
        this.onGroupSelect()
      }
      this.editManageTicket.user = this.ticketData.assignee;
      this.editManageTicket.priority = this.currentPriority;
      this.getAllUserGroupsNew();

    } else if (this.data["type"] === "editManageTicket") {
      this.ticketData = (this.data as any).ticketData;
      this.stateList = (this.data as any).stateList;
      this.userList = (this.data as any).userList;
      this.currentState = this.ticketData.currentState;
      this.editManageTicket.state = this.currentState;
      // if (this.currentState == 'RESOLVED' || this.currentState == 'CANCELLED') {
      if (this.currentState == 'CANCELLED' || this.currentState == 'ON_HOLD') {
        this.isCommentRequired = true;
      } else {
        this.isCommentRequired = false;
      }
      this.currentPriority = this.ticketData.priority;
      this.editManageTicket.groupId = this.ticketData.teamId;
      if (!!this.editManageTicket.groupId) {
        this.onGroupSelect()
      }
      this.editManageTicket.user = this.ticketData.assignee;
      this.editManageTicket.priority = this.currentPriority;
      this.getAllUserGroups();

    } 
    else if (this.data["type"] === "viewManageTicket") {
      this.ticketData = (this.data as any).ticketData;
      this.currentZone = this.data['zone'];
    } else if (this.data["type"] === "editMyTicket") {
      this.ticketData = (this.data as any).ticketData;
      this.stateList = (this.data as any).stateList;
      this.currentState = this.ticketData.currentState;
      this.editMyTicket.state = this.currentState;
      // if (this.currentState == 'RESOLVED' || this.currentState == 'CANCELLED') {
      if (this.currentState == 'CANCELLED') {
        this.isCommentRequired = true;
      } else {
        this.isCommentRequired = false;
      }
      this.currentPriority = this.ticketData.priority;
      this.editMyTicket.priority = this.currentPriority;
    } else if (this.data["type"] === 'addUsersToGroup') {
      this.currentZone = this.data['zone'];
      let page = 0;
      let size = 10000000;
      // this.emsService.getEMSUsers(this.currentZone, page, size).subscribe(res => {
      //   this.allUsers = res['response'].content;
      // }, err => {

      // })
    } else if (this.data["type"] === 'addImsCategory') {
      this.getAllUserGroups();
    } else if (this.data['type'] === 'viewTeamMembers') {
      this.groupUsers = this.data['teamInfo']['memberList'];
    } else if (this.data['type'] === 'addTeamMembers') {
      // this.groupUsers = this.data['teamInfo']['memberList'];
    }
    //sankar end


    this.bankServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          this.pageNo = 0;

          this.searchQuery = search;

          if (this.searchQuery.length >= 3) {
            let data: any = {}
            data.q = search;
            data.page = this.pageNo;
            data.size = this.pageSize;
            // return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
            this.emsService.getUserList(data, this.currentZone).subscribe(res => {
              let felteredData = (res as any).response['content'];
              felteredData.forEach(element => {
                var isFirst = false;
                let i = 0;
                if (this.allUsers.length > 0) {
                  isFirst = false;
                  this.allUsers.forEach(usr => {
                    if (usr.userId == element.userId) {
                      i = 1;
                    }
                  });
                } else {
                  isFirst = true;
                  this.allUsers.push(element); //initial push
                }
                if (i === 0 && !isFirst) {
                  this.allUsers.push(element); //  new user added
                }
              })
              if (this.allUsers.length > 0) {
                this.allUsers.filter(user => user.name.toLowerCase().indexOf(search) > -1);
              }
              this.filteredServerSideBanks.next(this.allUsers);
              this.totalPage = (res as any).response['totalPages'];
              if (this.totalPage > 1 && this.pageNo < this.totalPage) {
                this.showLoadMore = true;
              }
            })
          }

        }),
        delay(500)
      )
      .subscribe(filteredBanks => {
        this.searching = false;
      }, error => {
        this.searching = false;
      });


  }


  onClickEmsUserCheckbox = (user) => {
    /* let userId = user.userId;
     let data = this.selectedEmsUserIds.filter(usr => {
       return usr == userId;
     })
     
     if(data) {
      
       // this.selectedEmsUserIds = this.selectedEmsUserIds.filter(usr => {
       //   return usr != userId;
       // });
       this.selectedEmsUserIds.splice( this.selectedEmsUserIds.indexOf(userId), 1 );
       // this.selectedEmsUserIds.push(userId);
     }else {
       //this.selectedEmsUser.push(user);  
       this.selectedEmsUserIds.push(userId);
     }
     */
    let userId = user.userId;
    if (this.selectedEmsUserIdss.length > 0) {
      let i = 0;
      let k = 0;
      let userIdsLength = this.selectedEmsUserIdss.length; //3
      this.selectedEmsUserIdss.forEach(element => {
        k++;
        if (element == userId) {
          this.selectedEmsUserIdss.splice(this.selectedEmsUserIdss.indexOf(userId), 1);
          this.selectedEmsUser = this.selectedEmsUser.filter(usr => {
            return usr.userId != userId;
          });
          i = 1;
        }

      });
      if (i == 0) {
        this.selectedEmsUserIdss.push(userId);
        this.selectedEmsUser.push(user);
      }
      // 
    } else {
      this.selectedEmsUser.push(user);
      this.selectedEmsUserIdss.push(userId);

    }
    this.selectedEmsUserIds = this.selectedEmsUserIdss;

  }


  getNextRecords = () => {
    this.pageNo = this.pageNo + 1;
    let data: any = {}
    data.q = this.searchQuery;
    data.page = this.pageNo;
    data.size = this.pageSize;
    // return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
    this.emsService.getUserList(data, this.currentZone).subscribe(res => {
      let resData = (res as any).response['content'];

      resData.forEach(element => {
        let i = 0;
        this.allUsers.forEach(usr => {
          if (usr.userId == element.userId) {
            i = 1;
          }
        });
        if (i === 0) {
          this.allUsers.push(element); //  new user added
        }
      })
      // resData.forEach(element => {
      //   this.allUsers.push(element);
      // });
      if (this.allUsers.length > 0) {
        this.allUsers.filter(user => user.name.toLowerCase().indexOf(this.searchQuery) > -1);
      }
      this.filteredServerSideBanks.next(this.allUsers);
      this.totalPage = (res as any).response['totalPages'];
      if (this.totalPage > 1 && this.pageNo < this.totalPage) {
        this.showLoadMore = true;
      } else {
        this.showLoadMore = false;
      }
    })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getAllUserGroups() {
    this.imsService.getAllGroups(this.currentZone).subscribe(res => {
      this.userGroups = res['response'];
      if (this.category && this.category['escalationList'] && this.category['escalationList'].length > 0) {
        let selectedEscalationList = this.category['escalationList'];
        for (let item of selectedEscalationList) {
          for (let element of this.userGroups) {
            if (item.team.id == element.teamId) {
              this.slaLevelList.push({ 'group': element, 'time': item.intervalHour, 'id': item.escalataionId });
            }
          }
        }
      }
    }, res => {
      this.openSnackBar('Fetching User Groups Failed', '');
    })
  }

  getAllUserGroupsNew() {
    this.imsService.getAllGroups(this.currentZone).subscribe(res => {

      this.userGroupsNew = (res as any).response;
      this.userGroups = res['response'];
      if (this.category && this.category['escalationList'] && this.category['escalationList'].length > 0) {
        let selectedEscalationList = this.category['escalationList'];
        for (let item of selectedEscalationList) {
          for (let element of this.userGroups) {
            if (item.team.id == element.teamId) {
              this.slaLevelList.push({ 'group': element, 'time': item.intervalHour, 'id': item.escalataionId });
            }
          }
        }
      }

    }, res => {
      this.openSnackBar('Fetching User Groups Failed', '');
    })
  }
  getAllActiveUserGroups() {
    this.imsService.getAllActiveUserGroups(this.currentZone).subscribe(res => {
      this.userGroups = res['response'].content;
      if (this.category && this.category['escalationList'] && this.category['escalationList'].length > 0) {
        let selectedEscalationList = this.category['escalationList'];
        for (let item of selectedEscalationList) {
          for (let element of this.userGroups) {
            if (item.team.id == element.teamId) {
              this.slaLevelList.push({ 'group': element, 'time': item.intervalHour, 'id': item.escalataionId });
            }
          }
        }
      }
    }, res => {
      this.openSnackBar('Fetching User Groups Failed', '');
    })
  }



  openSnackBar(message: string, action: string) {
    this.matSnackBar.open(message, action, {
      duration: 5000,
    });
  }



  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  onNoClick(type): void {
    let currentContext = this;
    currentContext.commonService.changeZone(currentContext.currentZone);
    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
    this.dialogRef.close(type);
  }

  cancel() {
    let currentContext = this;
    currentContext.onNoClick("");
  }

  closePopup() {
    this.commonService.changeZone(this.currentZone);
    this.dialogRef.close();
  }

  closePopupIms() {
    this.dialogRef.close();
  }

  closePopupImsEdit() {
    let data = {};
    data['type'] = 'categoryEdit';
    data['result'] = 'success';
    this.dialogRef.close(data);
  }

  //sankar code start
  updateManageTicketNew = () => {
    if (this.editManageTicket.comment != undefined) {
      this.editManageTicket.comment = this.editManageTicket.comment.trim();
    }
    if ((this.currentState == 'CANCELLED' || this.currentState == 'ON_HOLD') && this.editManageTicket.comment == '') {
      return false;
    }
    let data: any = {}
    data.ticketId = this.ticketData.ticketId;
    data.oldState = this.ticketData.currentState;
    data.newState = this.editManageTicket.state;
    data.comment = this.editManageTicket.comment;
    data.subCategory = { 'id': this.ticketData.subCategoryId };
    data.team = { 'id': this.editManageTicket.groupId };
    data.priority = this.editManageTicket.priority;
    data.assignee = this.editManageTicket.user;
    //data.assignee = 3;


    this.imsService.updateImsTicketNew(this.currentZone, data, this.imgFile).subscribe(res => {
      this.commonService.openSnackBar('Ticket updated successfully', "");
      this.onNoClick("editTicket");
    }, err => {
      this.commonService.openSnackBar(err.error.message, "");
    })
  }

  //sankar code start
  updateManageTicket = () => {
    if (this.editManageTicket.comment != undefined) {
      this.editManageTicket.comment = this.editManageTicket.comment.trim();
    }
    if ((this.currentState == 'CANCELLED' || this.currentState == 'ON_HOLD') && this.editManageTicket.comment == '') {
      return false;
    }
    let data: any = {}
    data.ticketId = this.ticketData.ticketId;
    data.oldState = this.ticketData.currentState;
    data.newState = this.editManageTicket.state;
    data.comment = this.editManageTicket.comment;
    data.subCategory = { 'id': this.ticketData.subCategoryId };
    data.team = { 'id': this.editManageTicket.groupId };
    data.priority = this.editManageTicket.priority;
    data.assignee = this.editManageTicket.user;
    //data.assignee = 3;


    this.imsService.updateImsTicket(this.currentZone, data, this.imgFile).subscribe(res => {
      this.commonService.openSnackBar('Ticket updated successfully', "");
      this.onNoClick("editTicket");
    }, err => {
      this.commonService.openSnackBar(err.error.message, "");
    })
  }

  onChangeState = (event) => {
    this.currentState = this.editManageTicket.state
    //alert(this.editManageTicket.state)

    // if (this.currentState == 'RESOLVED' || this.currentState == 'CANCELLED') {
    if (this.currentState == 'CANCELLED' || this.currentState == 'ON_HOLD') {
      this.isCommentRequired = true;
    } else {
      this.isCommentRequired = false;
    }

    if (this.currentState == 'IN_PROGRESS') {
      this.isAssignUserRequired = true;
    } else {
      this.isAssignUserRequired = false;
    }

  }

  onChangeStateNew = (event) => {
    this.currentState = this.editManageTicket.state
    //alert(this.editManageTicket.state)

    // if (this.currentState == 'RESOLVED' || this.currentState == 'CANCELLED') {
    if (this.currentState == 'CANCELLED' || this.currentState == 'ON_HOLD') {
      this.isCommentRequired = true;
    } else {
      this.isCommentRequired = false;
    }
    if (this.currentState == 'REASSIGN') {
      this.isAssignUserRequired = true;
    } else {
      this.isAssignUserRequired = false;
    }

  }


  onMyTicketChangeState = (event) => {
    this.currentState = this.editMyTicket.state;
    // if (this.currentState == 'RESOLVED' || this.currentState == 'CANCELLED') {
    if (this.currentState == 'CANCELLED') {
      this.isCommentRequired = true;
    } else {
      this.isCommentRequired = false;
    }

  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.imgFile = event.target.files[0];
      reader.onload = event => {
        this.url = event.target["result"];
        this.showImage = true;
      };
    }
  }
  updateMyTicket = () => {

    let data: any = {}
    data.ticketId = this.ticketData.id;
    data.oldState = this.ticketData.currentState;
    data.newState = this.editMyTicket.state;
    data.comment = this.editMyTicket.comment;
    data.category = { 'id': this.ticketData.subCategory.id };
    data.priority = this.editMyTicket.priority;
    data.assignee = 3;
    this.imsService.updateMyTicket(this.currentZone, this.imgFile, data).subscribe(res => {
      this.commonService.openSnackBar('Ticket updated successfully', "");
      this.onNoClick("editTicket");
    }, err => {
      this.commonService.openSnackBar('Please try again later', "");
    })
  }
  //sankar code end


  addUserGroup() {
    var data = {}
    data['name'] = this.groupName;
    data['zoneId'] = this.currentZone;
    this.imsService.addUserGroup(data, this.currentZone).subscribe(res => {
      data['type'] = 'addUserGroup';
      data['result'] = 'success';
      this.commonService.openSnackBar('Added Group Successfully', '');
      this.dialogRef.close(data);
    }, err => {
      this.commonService.openSnackBar('Something went wrong', '');
    })


  }

  editUserGroup() {
    this.data['name'] = this.groupName;
    this.data['zoneId'] = this.currentZone;

    this.imsService.editUserGroup(this.data, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar('Changed Group Name Successfully', '');
      this.dialogRef.close();
    }, err => {
      let errMessage = (err as any).error;
      this.commonService.openSnackBar(errMessage.message, '');
    })


  }


  editUserGroupNew() {
    this.data['name'] = this.groupName;
    this.data['zone'] = this.currentZone;
    let dataNew = {
      "teamId": this.data['teamId'],
      "name": this.groupName,
      "zoneIdList": [parseInt(this.currentZone)]
    }
    this.imsService.updateTeam(dataNew, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar('Changed Team Name Successfully', '');
      this.dialogRef.close();
    }, err => {
      let errMessage = (err as any).error;
      this.commonService.openSnackBar(errMessage.message, '');
    })


  }

  addUserGroupNew() {
    // var data = {}
    // data['name'] = this.groupName;
    // data['zoneId'] = this.currentZone;
    let data = {
      "name": this.groupName,
      "zoneIdList": [this.currentZone]
    }

    this.imsService.createTeam(data, this.currentZone).subscribe(res => {
      data['type'] = 'addUserGroup';
      data['result'] = 'success';
      this.commonService.openSnackBar('Added Team Successfully', '');
      this.dialogRef.close();
    }, err => {
      this.commonService.openSnackBar('Something went wrong', '');
    })


  }

  addSubCategoryBox() {
    this.subCategoryList.push({ 'name': '' });
  }

  addNewSubCategoryBox() {
    this.newSubCategoryList.push({ 'name': '' });
  }

  addImsCategory() {

    var data = {};
    var categoryDetail = {};
    var subCategories = [];
    var escalationList = [];
    categoryDetail['category'] = { 'name': this.category['name'] };

    var zoneList = [];
    for (let item of this.selectedZones) {
      zoneList.push(item.id);
    }
    categoryDetail['zoneIdList'] = zoneList;

    for (let item of this.subCategoryList) {
      if (item['name'] && item['name'] != "") {
        subCategories.push(item['name']);
      }
    }
    let i = 0;
    for (let item of this.slaLevelList) {

      if (item['group'] && item['group'] != "" && item['time'] && item['time'] != "") {
        ++i;
        var levelDetail = {};
        levelDetail['level'] = i;
        levelDetail['intervalHour'] = item['time'];
        levelDetail['team'] = { 'id': item['group']['id'] };
        escalationList.push(levelDetail);

      }
    }

    data['categoryDetail'] = categoryDetail;
    data['subCategories'] = subCategories;
    data['escalationList'] = escalationList;

    this.imsService.addCategoryAndEscalation(data, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar("Added Category Successfully", '');
      var data = {};
      data['type'] = 'addImsCategory';
      data['result'] = 'success';
      this.dialogRef.close(data);
    }, err => {
      this.commonService.openSnackBar(err.error.message, '');
    })


  }

  addCategory() {


    var data = {};
    var category = {}
    category['name'] = this.category['name'];
    var zoneList = [];
    for (let item of this.selectedZones) {
      zoneList.push(item.id);
    }
    data['category'] = category;
    data['zoneIdList'] = zoneList;

    this.imsService.addCategory(data, this.currentZone).subscribe(res => {
      var parentId = res['response'].id;
      if (this.subCategoryList && this.subCategoryList.length > 0) {
        var subCategories = [];
        for (let item of this.subCategoryList) {
          if (item['name'] && item['name'] != "") {
            subCategories.push(item['name']);
          }
        }
        var dataSub = {};
        var parentCategory = {};
        parentCategory['id'] = parentId;
        dataSub['parentCategory'] = parentCategory;
        dataSub['subCategories'] = subCategories;

        if (subCategories && subCategories.length > 0) {
          this.imsService.addSubCategory(dataSub, this.currentZone).subscribe(res => {


          }, err => {

          })
        }



      }

      this.commonService.openSnackBar("Added Category Succesfully", '');
      this.dialogRef.close();
    }, err => {
      this.commonService.openSnackBar('Something went wrong', '');
      this.dialogRef.close();
    })
  }

  getAllZones() {
    this.homeService.getZonesByLevel('site', this.currentZone).subscribe(res => {
      this.allZones = res['response'];
      this.selectedZones = [];
      for (let item of this.allZones) {
        if (this.zoneMap[item.id]) {
          this.selectedZones.push(item);
        }
      }
    }, err => {

    })
  }


  getUserGroupDetails(data) {
    var groupId = !!data.teamId ? data.teamId : data.id;
    this.imsService.getUserGroupDetails(groupId, this.currentZone).subscribe(res => {

      this.groupUsers = (res as any).response.memberDetail;
      this.group = res['response'].team;
    }, err => {

    })

  }

  getCategoryById(id) {
    this.imsService.getCategoryById(id, this.currentZone).subscribe(res => {
      this.category = res['response'];
      this.subCategoryList = [];
      if (this.category && this.category['categoryDetail'] && this.category['categoryDetail'].zoneIdList) {
        if (this.category['categoryDetail'].zoneIdList.length > 0) {
          for (let item of this.category['categoryDetail'].zoneIdList) {
            this.zoneMap[item] = true;
          }
          this.getAllZones();
        }
      }
      for (let item of this.category['subCategories']) {
        this.subCategoryList.push({ 'name': item });
      }
      this.slaLevelList = [];
      for (let item of this.category['escalationList']) {
        //this.slaLevelList.push({ 'group': item.team, 'time': item.intervalHour, 'id': item.escalataionId })
      }
      this.getAllActiveUserGroups();


    }, err => {

    })
  }


  selectUserForTeamDelete(event) {

  }

  deleteUserFromGroup(id, index) {
    var data = {};
    data['team'] = { 'id': this.group['id'] };
    data['members'] = [id];
    this.imsService.deleteUserFromGroup(data, this.currentZone).subscribe(res => {
      this.groupUsers.splice(index, 1);
      this.commonService.openSnackBar("Successfully removed the user ", "");
    },
      err => {
        let errMessage = (err as any).error;
        this.commonService.openSnackBar(errMessage.message, "");
      })
  }

  deleteUserFromSelectedUser(index) {

    this.selectedUsers.splice(index, 1);
  }

  addUsersToGroup() {

    let userIds = [];
    for (let item of this.selectedEmsUser) {
      userIds.push(item.userId);
    }
    var data = {};
    //data['team'] = { 'id': this.data.teamId };
    data['team'] = { 'id': !!this.data.teamId ? this.data.teamId : this.data.id };
    data['members'] = userIds;

    this.imsService.addUsersToGroup(data, this.currentZone).subscribe(res => {
      this.dialogRef.close();
      this.commonService.openSnackBar("Added Users Successfully ", "");
    }, err => {

    })


  }

  addSlaLevel() {

    this.slaLevelList.push({ 'group': '', 'time': '' });

  }

  addNewSlaLevel() {
    this.newSlaLevelList.push({ 'group': '', 'time': '' });
  }

  editCategory() {



    var data = {};
    var category = {}
    category['name'] = this.category['categoryDetail'].category.name;
    category['id'] = this.category['categoryDetail'].category.id;
    var zoneList = [];
    for (let item of this.selectedZones) {
      zoneList.push(item.id);
    }
    data['category'] = category;
    data['zoneIdList'] = zoneList;

    this.imsService.updateCategory(data, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar("Successfully Updated", "");
      this.data['categoryName'] = res['response'].name;

    }, err => {
      let errMessage = (err as any).error;
      this.commonService.openSnackBar(errMessage.message, "");
    })



  }

  editEscalationLevel(slaDetail, level) {



    var data = {};
    data['category'] = { 'id': this.category['categoryDetail'].category.id };
    data['categoryTeam'] = { 'id': slaDetail.id };
    let escalationList = [];
    var sla = {};
    sla['intervalHour'] = slaDetail.time;
    sla['level'] = level;
    sla['team'] = { 'id': slaDetail.group.id };
    escalationList.push(sla);
    data['escalationList'] = escalationList;


    this.imsService.updateEscalation(data, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar('Updated Succesfully', '');
    }, err => {
      // this.commonService.openSnackBar('Operation Failed', '');
      let errMessage = (err as any).error;
      this.commonService.openSnackBar(errMessage.message, "");
    })

  }

  addEscalationLevel(slaDetail, level) {



    var data = {};
    data['category'] = { 'id': this.category['categoryDetail'].category.id };
    //data['categoryTeam'] = { 'id': slaDetail.id };
    let escalationList = [];
    var sla = {};
    sla['intervalHour'] = slaDetail.time;
    sla['level'] = this.slaLevelList.length + level;
    sla['team'] = { 'id': slaDetail.group.id };
    escalationList.push(sla);
    data['escalationList'] = escalationList;


    this.imsService.addEscalation(data, this.currentZone).subscribe(res => {

      var slaId = res['response'][0].id;
      let slaItem = this.newSlaLevelList[level - 1];
      slaItem['id'] = slaId;
      this.slaLevelList.push(slaItem);
      this.newSlaLevelList.splice(level - 1, 1);

      this.commonService.openSnackBar('Added Succesfully', '');
    }, err => {
      this.commonService.openSnackBar(err.error.message, '');
    })

  }

  onGroupSelect() {
    this.editManageTicket.user = '';
    this.groupUsers = [];
    this.imsService.getUserGroupDetails(this.editManageTicket.groupId, this.currentZone).subscribe(res => {
      // this.groupUsers = res['response'].memberDetail;
      this.groupUsers = (res as any).response.memberDetail;
    }, err => {
    })
  }


  removeSubCategory(index) {
    this.subCategoryList.splice(index, 1);
  }

  removeEscalation(index) {
    this.slaLevelList.splice(index, 1);
  }

  addMoreSubCategory() {

    let data = {};
    data['parentCategory'] = { 'id': this.category['categoryDetail'].category.id };
    data['subCategories'] = [this.newSubCategory];

    this.imsService.addSubCategory(data, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar('Added Succesfully', '');
      this.subCategoryList.push({ 'name': this.newSubCategory });
      this.newSubCategory = '';

    }, err => {
      this.commonService.openSnackBar(err.error.message, '');
    })

  }
  deleteTeamMember = (teamMemberId, index) => {
    let delObj: any = {};
    delObj['team'] = { 'id': this.data['teamRow']['teamId'] };
    delObj['members'] = [];
    delObj['members'].push(teamMemberId);
    this.imsService.deleteTeamMember(delObj, this.currentZone).subscribe(res => {
      this.toastrService.success(`User removed from the team ${this.data['teamRow']['teamName']}`);
      this.groupUsers.splice(index, 1);
    }, err => {
      let errordata = (err as any).error;
      this.toastrService.error(errordata['message']);
    })

  }
  addUsersToTeam = () => {
    let userIds = [];
    for (let item of this.selectedEmsUser) {
      userIds.push(item.userId);
    }
    var data = {};
    data['team'] = { 'id': this.data['teamRow']['teamId'] };
    data['members'] = userIds;

    this.imsService.addUsersToTeam(data, this.currentZone).subscribe(res => {
      this.dialogRef.close();
      this.commonService.openSnackBar("Added Users Successfully ", "");
    }, err => {

    })
  }

  addSlaLevelNew = (isFormValid, formVal) => {
    if (!isFormValid) {
      return;
    }
    let data = {};
    if (this.subCategory['edit']) {
      data = {
        // "categoryTeam": {
        //   "id": this.subCategory['teamId']
        // },
        // "category": {
        //   "id": this.subCategory['categoryId']
        // },
        "subCategoryId": this.subCategory['subCategoryId'],
        "escalationList": [
          {
            "escalataionId": this.subCategory['escalataionId'],
            "intervalHour": this.subCategory['interval'],
            "level": this.subCategory['sla'],
            "team": {
              "id": this.subCategory['teamId']
            }
          }
        ]
      }

      this.imsService.updateSlaLevel(data, this.currentZone).subscribe(res => {
        this.toastrService.success('Thank you for adding an escalation matrix');
        this.dialogRef.close();
      }, err => {
        let errorData = (err as any).error;
        this.toastrService.error(errorData['message']);
      })
    } else {
      data = {
        "subCategoryId": this.subCategory['subCategoryId'],
        "escalationList": [
          {
            "intervalHour": this.subCategory['interval'],
            "level": this.subCategory['sla'],
            "team": {
              "id": this.subCategory['teamId']
            }
          }
        ]
      }

      this.imsService.createSlaLevel(data, this.currentZone).subscribe(res => {
        this.toastrService.success('Thank you for adding an escalation matrix');
        this.dialogRef.close();
      }, err => {
        let errorData = (err as any).error;
        this.toastrService.error(errorData['message']);
      })
    }

  }
  //intervel time hour validation
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  cancelSlaEdit(){

  }

  loadImage = (chatList) => {
    // if(typeof(chatList.preSignedImage) == 'string'){
    //   return chatList.preSignedImage;
    // } 
    return 'assets/images/user_image.png';
 }

  moreShowChat = () => {
    let stateList;
    let userList;
 
    
    this.imsService.getChatHistory(this.data["ticketData"].ticketId, this.currentZone,this.chatPageIndex,this.chatPageSize).subscribe(res => {
      let list = (res as any).response['list'];
      list.sort(function(a, b) {
        return a[1] - b[1];
      });

      list.forEach(element => {
        this.chatList.unshift(element)
      });
      let totalPage =  Math.ceil(((res as any).response.totalCount)/5)
      this.chatPageIndex+=1
      this.showMoreChat = this.chatPageIndex == totalPage?false:true

      

      
    },err=>{
      
    })
  }

  chatFileUpload(event) {
    var fileName = event.target.value;
    var fileType: string = fileName.substring(fileName.lastIndexOf(".") + 1);
    fileType = fileType.toLowerCase();
    if (fileType == "jpg" || fileType == "png" || fileType == "jpeg") {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        this.chatImgFile = event.target.files[0];
       
        reader.onload = (event) => {
          this.chaturl = event.target["result"];
          this.chatShowImage = true;
        };
      }
      var fileToUpload = event.target.files[0];
    }
  }

  errorMsg:any = "";
  saveChat = () => {
    if(typeof(this.chatText) == 'string' && this.chatText.trim().length<1) {
      this.errorMsg = "Characters limit must be greater than 0";
      return;
    }else if(typeof(this.chatText) == 'undefined'){
      this.errorMsg = "Please enter comment";
      return;
    }
    let data = {
      ticketId: this.chatTicketId,
      comment: this.chatText,
    };

    const saveChatApi = this.imsService.saveChat(data, this.chatImgFile, this.currentZone);
    this.loaderService.showLoaderUntilCompleted(saveChatApi)
      .subscribe(
        (res) => {
          
          this.chatImgFile = '';
          this.imsService.getChatHistory(this.chatTicketId, this.currentZone,0,5).subscribe(res => {
            let stateList;
            this.totalChatCount = (res as any).response['totalCount']
            stateList = (res as any).response['list'];
            stateList.reverse();
            this.chatPageIndex =0
            this.chatList = stateList;
            if((res as any).response['totalCount'] > 5){
              this.showMoreChat = true;
              this.chatPageIndex =1;
            }
            //this.openEditView('chatShow', row, stateList, userList);
          },err=>{
            //this.openEditView('chatShow', row, stateList, userList);
          })
          //this.chatList.push(res['response']);
          this.chatText = ''
          // this.alertService.success(
          //   "Thank you for adding an escalation matrix"
          // );
          //this.dialogRef.close();
          //this.moreShowChat();
          this.chatShowImage = false
        },
        (err) => {
          let errorData = (err as any).error;
          //this.alertService.error(errorData["message"]);
        }
      );
  };


  @HostListener('window:scroll', ['$event']) 
  scrollHandler(event) {

  }

}

