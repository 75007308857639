import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { Observable, of, BehaviorSubject } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { map, catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment';
export class SanitizationService {
    constructor(http, baseUrl, moduleidService) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.dataObj = new BehaviorSubject({});
        this.userSubject$ = new BehaviorSubject([]);
        this.users$ = this.userSubject$.asObservable();
        //this.BASE_URL = environment.BASE_URL+'ems/';
        //alert(baseUrl);
        //this.BASEURL = 'http://10.8.0.7:8125/';
        //this.BASEURL = baseUrl;
        this.BASEURL = environment.BASE_URL; //correct
        this.IMSURL = environment.BASE_URL + 'ims-new/';
        this.SMURL = environment.BASE_URL_SPACE;
        this.EMSURL = environment.BASE_URL + 'ems/';
        this.moduleId = moduleidService.getModueId();
    }
    get data() {
        return this.dataObj.asObservable();
    }
    getAllTeams(zoneId) {
        let url;
        url = this.IMSURL + `team/all?page=0&size=`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    getConfig(zoneId) {
        let url;
        url = this.SMURL + `sanitizationConfig/getAll`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    submitConfig(zoneId, data) {
        let url;
        url = this.SMURL + `sanitizationConfig/`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllTickets(zoneId, reqData, pageable) {
        let url = this.IMSURL + `ticket/get-all-ticket/search-filter?zoneId=${zoneId}&page=${pageable.page}&size=${pageable.size}`;
        return this.http.post(url, reqData, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getStates(zoneId, state) {
        let url = this.IMSURL + `ticket/getNextState/v2?state=${state}`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    updateStates(zoneId, reqData) {
        let url = this.IMSURL + `ticket/update-ticket/state`;
        return this.http.post(url, reqData, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    searchFilterTeams(zoneId, teamName) {
        let url;
        url = this.IMSURL + `team/search?name=${teamName}`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    getFloorSlaMapping(zoneId) {
        let url;
        url = this.IMSURL + `team/sanitization/team/zone`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    getTeamDetails(zoneId, teamId) {
        let url;
        url = this.IMSURL + `team/get-detail?teamId=${teamId}`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    addTeam(zoneId, data) {
        let url = this.IMSURL + `team/add/v1`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    addTeamMembers(zoneId, data) {
        let url = this.IMSURL + `team-member/create`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getTeamDetailMulti(zoneId) {
        let url;
        //url = this.IMSURL + `team/get-detail/multi`;
        url = this.IMSURL + `team/all/v3`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    deleteMapping(zoneId, data) {
        let url = this.IMSURL + `team/sanitization/team/zone/disable`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    updateTeamMapping(zoneId, data) {
        let url = this.IMSURL + `team/sanitization/team/zone`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    deleteTeamMember(zoneId, data) {
        let url = this.IMSURL + `team-member/deactive-all-per-team`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAutocompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
            let resData = [];
            if (data.searchType === "user") {
                this.users$.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    resData = res;
                }));
                return of(resData);
            }
            else
                return of([]);
        }
        if (data.searchType === "user") {
            let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
            return this.http
                .get(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
                .pipe(map((res) => res["response"]), catchError((err) => of([])));
        }
    }
}
