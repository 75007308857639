import { HttpParams } from '@angular/common/http';

export class ZoneAndModuleHttpParams extends HttpParams {
    private zoneId;
    private moduleId;

    constructor(public zone: number, public modId?: number) {
        super();
        this.zoneId = zone;
        this.moduleId = this.modId
    }
   


    getZone(){
        return this.zoneId;
    }

    getModule(){
        return this.moduleId;
    }
}
