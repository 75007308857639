import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'commonService';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AmenitiesService } from '../services/amenities-service';
import { LoaderService } from "../../../shared/modules/loader/loader.service";

@Component({
  selector: 'app-manage-amenities-modal',
  templateUrl: './manage-amenities-modal.component.html',
  styleUrls: ['./manage-amenities-modal.component.scss']
})
export class ManageAmenitiesModalComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  registerForm: FormGroup;
  editAmenitiesForm: FormGroup;

  submitted = false;
  pageType;
  wtitle;
  currentZone;

  displayedColumns: string[] = ['amenity', 'count'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  constructor(
    private toastrService: ToastrService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private loadingService: LoaderService,
    private amenityService: AmenitiesService,
    public dialogRef: MatDialogRef<ManageAmenitiesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      this.currentZone = this.data["currentZone"];
      this.pageType = this.data["type"];

    
    }

  ngOnInit() {
    if (this.pageType == "editAmenity") {
        this.wtitle = "Update";
        let wdata = this.data["name"];
        this.registerForm = this.formBuilder.group({
          amenityName: [wdata["name"], [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      });
    } else if (this.pageType == "addAmenity") {
        this.wtitle = "Add";
        this.registerForm = this.formBuilder.group({
            amenityName: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
        });
    } else if(this.pageType=='showEditAmenities') {
      let amenities = [];
      if(this.data['seatInfo']['entityInfo']['amenities'] != null){
        this.data['seatInfo']['entityInfo']['amenities'].forEach(res => {
          amenities.push(res.id);
        });
       
      }
      this.editAmenitiesForm = this.formBuilder.group({
        amenityName: this.formBuilder.array(
          this.data['amenitiesList'].map(category => {
            let checked = false;
            if(amenities.length>0 && amenities.includes(category.id) ){
              checked = true;
            }
            //let isSelected = thselectedCategoryIds.some(id => id === category.id);
            return this.formBuilder.control({
              id: category.id,
              name: category.name,
              checked: checked
            });
        }))
      }, );

      this.editAmenitiesForm.controls['amenityName'].setValidators(requireCheckboxesToBeCheckedValidator(1));
    }else if(this.pageType == 'showAllAmenities'){
      this.loadAllAmenities(this.data['floorId']);
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  get fEditAmenities() {
    return this.editAmenitiesForm.controls;
  }
  
  closePopup() {
    this.dialogRef.close();
  }

  onEditAmenity(){
    if (this.editAmenitiesForm.invalid) {
      return;
    } else {
      let selectedSeats= [];
      let filteredAmenities = this.editAmenitiesForm.get('amenityName').value;
      
      
      // filteredAmenities.map(s=> {
      //   if(s.checked){
      //     return s.id
      //   }
      // })

      let checkedAmenities =    filteredAmenities.reduce((ids, thing) => {
        if (thing.checked) {
          ids.push(thing.id);
        }
        return ids;
      }, []);
      
      selectedSeats.push(this.data['seatInfo']['entityInfo']['id'])
      let reqObj = {
          entityIds: selectedSeats,
          amenityIds: checkedAmenities
      };

      const peopleLoad$ = this.amenityService.postAmenitiesWithSeats(this.currentZone, reqObj);
      let uploadAmenities = this.loadingService.showLoaderUntilCompleted(peopleLoad$).subscribe(data => {
                      this.toastrService.success("Amenities updated successfully");
              },
              error => {
                  this.toastrService.error( 'Something went wrong!! Please make sure file is in correct format');
              }
          );
    /*
        if (index === -1) {
          this.filteredAmenities.push(amenity.id);
        } else {
          this.filteredAmenities.splice(index, 1)
        }

      
  
      const peopleLoad$ = this.amenitiesService.postAmenitiesWithSeats(this.currentZone, reqObj);
      let uploadAmenities = this.loadingService.showLoaderUntilCompleted(peopleLoad$).subscribe(data => {
                      this.toastrService.success("File upload successfully");
              },
              error => {
                  this.toastrService.error( 'Something went wrong!! Please make sure file is in correct format');
              }
          );
          */
    }
  }

  loadAmenities = [];
  loadAllAmenities(floorId){
    this.loadAmenities = [];
    const peopleLoad$ = this.amenityService.loadAmenitiesForFloors(this.currentZone, floorId);
      let uploadAmenities = this.loadingService.showLoaderUntilCompleted(peopleLoad$).subscribe(data => {
                this.loadAmenities = data['response'];
                this.dataSource.data = this.loadAmenities;
          },
          error => {
              this.toastrService.error( 'Something went wrong!! Please make sure file is in correct format');
          }
      );
  }
  onSubmitAmenity() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    } else {
        let data;
        if (this.pageType == "editAmenity") {
            data = {
                id: this.data["name"]["id"],
                name: this.registerForm.value.amenityName,
                amenityCategory: "SEAT",
                "status":"ENABLED",
                "specialRequest":false
            };
        } else if (this.pageType == "addAmenity") {
            data = {
                name: this.registerForm.value.amenityName,
                amenityCategory: "SEAT",
                "status":"ENABLED",
                "specialRequest":false
            };
        }
        this.amenityService
          .createOrUpdateAmenities(
              this.currentZone,
              data,
              this.pageType
          )
          .pipe(
              takeUntil(this.unsubscribe$),
          )
          .subscribe((res) => {
              if (this.pageType == "editAmenity") {
                  this.toastrService.success(
                      "Updated Amenity successfully"
                  );
              } else {
                  this.toastrService.success(
                      "Saved Amenity successfully"
                  );
              }
              this.closePopup();
          });
    }
  }

  editAmenities(eve,prop){  
    if(eve.checked){
      prop.setValue({id: prop.value.id, name: prop.value.name, checked: true}); 
    }else{
      prop.setValue({id: prop.value.id, name: prop.value.name, checked: false}); 
    }
  }

}

import { ValidatorFn } from '@angular/forms';

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate (formGroup: FormGroup) {
    let checked = 0;
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];
      if (control.value.checked === true) {
        checked ++;
      }
    });

    if (checked < minRequired) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }

    return null;
  };
}
