import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource, } from "@angular/material";
import * as _ from "lodash";
import { Angular5Csv } from "angular5-csv/dist/Angular5-csv";
import { forkJoin } from "rxjs";
import * as Highcharts from "highcharts";
export class EventModalComponent {
    constructor(dialogRef, formBuilder, dialog, eventService, toastService, commonService, loaderService, data) {
        this.dialogRef = dialogRef;
        this.formBuilder = formBuilder;
        this.dialog = dialog;
        this.eventService = eventService;
        this.toastService = toastService;
        this.commonService = commonService;
        this.loaderService = loaderService;
        this.data = data;
        this.pageType = "";
        this.additionalDetails = [];
        this.eventType = "manual";
        this.promotionToggle = false;
        this.advanceToggle = false;
        this.notifyToggle = false;
        this.filterType = [
            { id: "current_week", name: "Current week" },
            { id: "last_15_days", name: "Last 15 days" },
            { id: "last_month", name: "Last month" },
            { id: "custom_date", name: "Custom date" },
        ];
        this.empCost = 0;
        this.guestLimit = 0;
        this.noGuestInfo = false;
        this.isEditEvent = false;
        this.guestName = true;
        this.guestAge = false;
        this.guestGender = false;
        this.guestEmail = true;
        this.guestMobile = false;
        this.guestCost = 0;
        this.questionList = [
            { id: "MULTIPLE_CHOICE", name: "Radio Button" },
            { id: "SHORT_ANSWER", name: "Short Answer" },
            { id: "CHECK_BOX", name: "Check Box" },
        ];
        this.promotion = {
            id: null,
            heading: "",
            subheading: "",
            buttonText: "",
            backgroundImage: null,
            colour: "#ffffff",
            imgFile: null,
        };
        this.categoryList = [];
        this.event = null;
        this.displayedColumns = [
            "timeStamp",
            "employee",
            "employeeMobile",
            "employeeEmail",
            "noOfGuest",
            "paymentStatus",
        ];
        this.displayedColumnsGuest = [
            "employee",
            "noOfGuest",
            "guestName",
            "guestEmail",
            "guestMobile",
            "paymentStatus",
        ];
        this.isNotifyTimeDisable = false;
        this.isAdvanceTimeDisable = false;
        this.Highcharts = Highcharts;
        this.totalCheckBoxCount = 0;
        this.totalMCQCount = 0;
        this.feedbackLoaderArray = [];
        this.fb = this.formBuilder;
        this.dashboardImgFile = null;
        this.eventImageChange = false;
        this.eventPromotionImageChange = false;
        this.endDate = null;
        this.startIntervals = [];
        this.endIntervals = [];
        this.notifyTimeSlots = [];
        this.advanceTimeSlots = [];
        this.quesType = 1;
        this.quesList = [];
        this.submittedSurvey = false;
        this.surveyTitle = "";
        this.saveSurvey = "";
        this.currencyList = [{ id: "INR", value: "INR" }];
        this.submitted = false;
        this.pageSizeOptions = [10, 25, 100];
        this.pageSize = 10;
        this.pageIndex = 0;
        this.pageSize1 = 10;
        this.pageIndex1 = 0;
        this.selectedType = "current_week";
        this.searchActive = true;
        this.userRegisterSearch = "";
        this.guestSearch = "";
        this.userRegisterSearchActive = true;
        this.guestSearchActive = true;
        this.quesListTemp = [];
        this.viewSurveyEnabled = false;
        this.downloadReport = () => {
            let startDate = new Date(this.startDate);
            let endDate = new Date(this.endDate);
            let sd = "";
            let ed = "";
            if (startDate.getTime() <= endDate.getTime()) {
                sd = this.commonService.getTimeStamp(startDate.getTime(), "start");
                ed = this.commonService.getTimeStamp(endDate.getTime(), "end");
            }
            else {
            }
            let data = {};
            data.startTime = sd;
            data.endTime = ed;
            // let startDate = new Date(this.startDate);
            // let endDate = new Date(this.endDate);
            let getReport = this.eventService.getReportData(data, this.currentZone);
            forkJoin([getReport]).subscribe((result) => {
                let resData = result[0].response;
                let columns = result[1];
                let vmsdownloadList = [];
                let obj = {};
                obj.startDate = "Event Start Date";
                obj.endDate = "Event End Date";
                obj.eventName = "Event Name";
                obj.eventStatus = "Event Status";
                obj.eventCat = "Event Category";
                obj.eventDisc = "Event Description";
                obj.registration = "Registration";
                obj.survey = "Survey";
                obj.feedback = "Feedback";
                obj.avgFeedbackRating = "Average Feedback Rating";
                vmsdownloadList = resData.map((elem) => {
                    let obj = {
                        startDate: this.formatDate(elem.startDate),
                        endDate: this.formatDate(elem.endDate),
                        eventName: elem.title,
                        eventStatus: elem.presentStatus,
                        eventCat: elem.categoryName,
                        eventDisc: elem.description,
                        registration: elem.toRegister ? elem.registerdUserCount : 'NA',
                        survey: elem.surveyCount == null ? "NA" : elem.surveyCount,
                        feedback: elem.feedbackCount == null ? "NA" : elem.feedbackCount,
                        avgFeedbackRating: elem.averageFeedBackCount == null
                            ? "NA"
                            : elem.averageFeedBackCount,
                    };
                    return obj;
                });
                vmsdownloadList.unshift(obj);
                new Angular5Csv(vmsdownloadList, "Events List");
            }, (err) => { });
        };
        this.downloadRegisterdUser = () => {
            // let startDate = new Date(this.startDate);
            // let endDate = new Date(this.endDate);
            let getReport = this.eventService.getRegisterdUserReport(this.eventId, this.currentZone);
            forkJoin([getReport]).subscribe((result) => {
                let resData = result[0].response;
                let columns = result[1];
                let max = 0;
                resData.forEach((e) => {
                    if (e.totalMembers > max)
                        max = e.totalMembers;
                });
                let vmsdownloadList = [];
                let obj = {};
                obj.time = "Time Stamp";
                obj.emp = "Employee";
                obj.empMobile = "Employee Phone Number";
                obj.empEmail = "Employee email";
                obj.noOfGuest = "Number of Guest";
                // max = 10;
                for (let i = 0; i < max; i++) {
                    obj["name" + i] = "Guest " + (i + 1) + " Name";
                    obj["email" + i] = "Guest " + (i + 1) + " Email";
                    obj["phone" + i] = "Guest " + (i + 1) + " Phone Number";
                }
                // obj["status"] = "Guest" + " Payment Status";
                vmsdownloadList = resData.map((elem) => {
                    let arr = elem.commonGuestReservationDTOs;
                    if (arr == null)
                        arr = [];
                    let obj = {
                        time: this.formatTimestamp(elem.createdAt),
                        emp: elem.name,
                        empMobile: elem.phoneNumber,
                        empEmail: elem.email,
                        noOfGuest: elem.totalMembers,
                    };
                    arr.forEach((e, i) => {
                        obj["name" + i] = e.guestName;
                        obj["email" + i] = e.guestEmail;
                        obj["phone" + i] = e.guestPhone;
                    });
                    for (let i = arr.length; i < max; i++) {
                        obj["name" + i] = "NA";
                        obj["email" + i] = "NA";
                        obj["phone" + i] = "NA";
                    }
                    // obj["status"] = "NA";
                    return obj;
                });
                vmsdownloadList.unshift(obj);
                new Angular5Csv(vmsdownloadList, "Registered User List");
            }, (err) => { });
        };
        this.downloadRegisterdUserGuest = () => {
            // let startDate = new Date(this.startDate);
            // let endDate = new Date(this.endDate);
            let getReport = this.eventService.getRegisterdUserGuestReport(this.eventId, this.userRegisterId, this.currentZone);
            forkJoin([getReport]).subscribe((result) => {
                let resData = result[0].response.content;
                let columns = result[1];
                let vmsdownloadList = [];
                let obj = {};
                obj.emp = "Employee Name";
                obj.noOfGuest = "Number of Guest";
                obj.guestName = "Guest Name";
                obj.guestEmail = "Guest email";
                obj.guestMobile = "Guest Phone Number";
                // obj.paymentStatus = "Guest Payment Status";
                vmsdownloadList = resData.map((elem, i) => {
                    let obj = {
                        emp: i == 0 ? elem.userDTO.username : "",
                        noOfGuest: i == 0 ? resData.length : "",
                        guestName: elem.guestName,
                        guestEmail: elem.guestEmail,
                        guestMobile: elem.guestPhone,
                    };
                    return obj;
                });
                vmsdownloadList.unshift(obj);
                new Angular5Csv(vmsdownloadList, "Guest List");
            }, (err) => { });
        };
        this.duplicateMCQOption = false;
        this.pageType = data["type"];
        this.currentDateOrig = new Date();
        this.currentDateOrig.setHours(0, 0, 0, 0);
    }
    presentStatusCheck() {
        // Assuming you have the event object available in your component
        if (!this.isEditEvent)
            return false;
        return this.event.presentStatus !== 'UPCOMING';
    }
    ngOnInit() {
        this.currentZone = this.data["currentZone"];
        this.settingsManualForm = this.formBuilder.group({
            inAdvance: [false, null],
            notifyUser: [false, null],
            userRegister: [false, null],
            feedback: [false, null],
            maxParticipants: [false, null],
            empPayment: [false, null],
            allowGuest: [false, null],
            enableGuestPayment: [false, null],
            promotion: [false, null],
            survey: [false, null],
            surveyAnonymous: [false, null],
            eventTitle: ["", [Validators.required, Validators.maxLength(25)]],
            eventCategory: ["", Validators.required],
            eventDisc: ["", [Validators.required, Validators.maxLength(250)]],
            startDate: [{ value: '', disabled: this.presentStatusCheck() }, Validators.required],
            endDate: [{ value: '', disabled: this.presentStatusCheck() }, Validators.required],
            startTime: [{ value: '', disabled: this.presentStatusCheck() }, Validators.required],
            endTime: [{ value: '', disabled: this.presentStatusCheck() }, Validators.required],
            location: [""],
            locationURL: ["", Validators.pattern("https?://.+")],
            eventURL: ["", Validators.pattern("https?://.+")],
            additionalDetails: this.fb.array([]),
        });
        this.eventService.getAllCategory(this.currentZone).subscribe((res) => {
            this.categoryList = res.response;
            if (!this.isEditEvent) { }
            //  this.category = this.categoryList[0]["id"];
        });
        // this.settingsURLForm = this.formBuilder.group({
        //   inAdvance: [false, null],
        //   promotion: [false, null],
        //   notifyUser: [false, null],
        //   additionalDetails: this.fb.array([]),
        //   eventTitle: ["", [Validators.required, Validators.maxLength(25)]],
        //   eventCategory: ["", Validators.required],
        //   eventDisc: ["", [Validators.required, Validators.maxLength(250)]],
        //   eventURL: ["", [Validators.required, Validators.pattern("https?://.+")]],
        //   startDate: ["", Validators.required],
        //   endDate: ["", Validators.required],
        //   startTime: ["", Validators.required],
        //   endTime: ["", Validators.required],
        // });
        this.empCostType = this.currencyList[0].id;
        this.guestCostType = this.currencyList[0].id;
        if (this.pageType == "addEvent") {
            this.addQues();
        }
        if (this.pageType == "feedback") {
            this.feedbackData = this.data["feedbackData"];
            this.rating = Array.from({ length: this.feedbackData.rating });
            this.nonRating = Array.from({ length: 5 - this.feedbackData.rating });
            this.createFeedbackLoadersArray(this.feedbackData.eventRatings.mapRating, this.feedbackData.responseCount);
        }
        if (this.pageType == "surveyDetail") {
            this.surveyData = this.data["surveyData"];
            this.createSurveyArray();
        }
        if (this.pageType == "editEvent") {
            this.isEditEvent = true;
            this.pageType = "addEvent";
            this.event = this.data["eventData"];
            this.populateEventData(this.event);
        }
        if (this.pageType == "userRegisterdDetail") {
            this.userRegisterdCount = this.data["userRegisterdCount"];
            this.eventId = this.data["id"];
            this.loadRegisterdUserDetails();
        }
    }
    loadRegisterdUserDetails() {
        const $feedback = this.eventService.getRegsiterdUserDetails(this.currentZone, this.eventId, this.pageIndex, this.pageSize);
        this.loaderService.showLoaderUntilCompleted($feedback).subscribe((res) => {
            this.dataSource = new MatTableDataSource(res["response"]["content"]);
            this.length = res["response"]["totalElements"];
        }, (e) => { });
    }
    getDate0(e) {
        let newDate = new Date(e);
        return newDate;
    }
    populateEventData(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (event.type == "WEB_REDIRECT") {
                this.eventType = "url";
            }
            else {
                this.eventType = "manual";
            }
            let additionalDetailsTemp = [];
            event.customField.forEach((e) => {
                //this.addDetails(e.label,e.value)
                this.addDetails(e.label, e.value, e.id);
                //this.addDetails1(e.label, e.value, e.id);
            });
            const formData = {
                inAdvance: event.displayEventInAdvance,
                notifyUser: event.notifyPresentDay,
                userRegister: event.toRegister,
                feedback: event.eventAdditionalDetails.eventFeedback,
                maxParticipants: event.maxUser == 0 ? false : true,
                empPayment: false,
                allowGuest: event.inviteGuests,
                promotion: event.eventPromotion,
                survey: event.eventAdditionalDetails.eventSurvey,
                surveyAnonymous: event.eventAdditionalDetails.surveyAnonymous,
                eventTitle: event.title,
                eventCategory: event.eventCategory.id,
                eventDisc: event.description,
                location: event.address,
                locationURL: event.locationUrl,
                eventURL: event.redirectionUrl,
            };
            this.settingsManualForm.patchValue(formData);
            // if (this.eventType == "url") {
            //   const formData = {
            //     inAdvance: event.displayEventInAdvance,
            //     notifyUser: event.notifyPresentDay,
            //     promotion: event.eventPromotion,
            //     eventTitle: event.title,
            //     eventCategory: event.eventCategory.id,
            //     eventDisc: event.description,
            //     eventURL: event.redirectionUrl,
            //     // ... add values for other form controls
            //   };
            //   this.settingsURLForm.patchValue(formData);
            // }
            //   this.eventTitle=event.title;
            //   this.eventDisc=event.description;
            //   this.promotionToggle=event.eventPromotion,
            // this.advanceToggle=event.displayEventInAdvance
            // this.notifyToggle=event.notifyPresentDay,
            this.category = event.eventCategory.id;
            this.startDate = this.getDate0(event.startDate);
            yield this.onStartDateChange(this.startDate);
            this.endDate = this.getDate0(event.endDate);
            yield this.onEndDateChange(this.endDate);
            this.startTime = event.startDate;
            yield this.onStartTimeChange();
            this.endTime = event.endDate;
            yield this.onEndTimeChange();
            let curDate = new Date();
            if (curDate.getTime() >= new Date(this.startTime).getTime()) {
                const date = new Date(this.startTime);
                const slotStartTime = this.formatTime(date.getHours(), date.getMinutes());
                this.startIntervals.push({
                    id: date.getTime(),
                    name: slotStartTime["time12"],
                });
            }
            this.dashboardImageUrl = event.imageUrl[0].imageUrl;
            if (event.eventPromotionDto) {
                this.promotion = event.eventPromotionDto;
                this.promotion.backgroundImage = this.encodeEventPromoImage(event.eventPromotionDto.imageUrl);
                this.promotion.subheading = event.eventPromotionDto.subHeading;
            }
            if (!!event.displayEventInAdvance) {
                this.advanceDate = this.getDate0(event.advanceEventDisplayDate);
                yield this.onAdvanceDateChange(this.advanceDate);
                this.advanceTime = event.advanceEventDisplayDate;
                // if display time has gown
                let curDate = new Date();
                if (curDate.getTime() >= new Date(this.advanceTime).getTime()) {
                    this.isAdvanceTimeDisable = true;
                    const date = new Date(this.advanceTime);
                    const slotStartTime = this.formatTime(date.getHours(), date.getMinutes());
                    this.advanceTimeSlots.push({
                        id: date.getTime(),
                        name: slotStartTime["time12"],
                    });
                }
            }
            /*
        advanceTimeSlots
        */
            if (!!event.notifyPresentDay) {
                let curDate = new Date();
                this.notifyUserTime = event.presentDayTime;
                if (curDate.getTime() >= new Date(this.notifyUserTime).getTime()) {
                    this.isNotifyTimeDisable = true;
                    const date = new Date(this.notifyUserTime);
                    const slotStartTime = this.formatTime(date.getHours(), date.getMinutes());
                    this.notifyTimeSlots.push({
                        id: date.getTime(),
                        name: slotStartTime["time12"],
                    });
                }
            }
            this.maxParticipants = event.maxUser;
            this.empCostType = event.currency;
            this.empCost = event.price;
            if (event.eventAdditionalDetails) {
                if (event.eventAdditionalDetails.eventSurvey) {
                    this.surveyDate = this.getDate0(event.eventAdditionalDetails.eventSurveyTime);
                    yield this.onSurveyDateChange(this.surveyDate);
                    this.surveyTime = event.eventAdditionalDetails.eventSurveyTime;
                }
                if (event.eventAdditionalDetails.eventFeedback) {
                    this.feedbackDate = this.getDate0(event.eventAdditionalDetails.eventFeedbackTime);
                    yield this.onFeedbackDateChange(this.feedbackDate);
                    this.feedbackTime = event.eventAdditionalDetails.eventFeedbackTime;
                }
                if (event.inviteGuests) {
                    this.guestCost = event.eventAdditionalDetails.guestPrice;
                    this.guestLimit = event.eventAdditionalDetails.noOfGuest;
                    this.noGuestInfo =
                        event.eventAdditionalDetails.guestInfoDTO.guestInfo.capture;
                    this.guestEmail =
                        event.eventAdditionalDetails.guestInfoDTO.guestInfo.email;
                    this.guestMobile =
                        event.eventAdditionalDetails.guestInfoDTO.guestInfo.phone;
                    this.guestName =
                        event.eventAdditionalDetails.guestInfoDTO.guestInfo.name;
                }
                if (event.eventAdditionalDetails.eventSurvey) {
                    const surveyTemp = event.eventAdditionalDetails.eventSurveyDto;
                    this.surveyTitle = surveyTemp.surveyTitle;
                    // {
                    //   type: "MULTIPLE_CHOICE" ,
                    //   checkAns: [{ option: "" }, { option: "" }],
                    //   MCQAns: [{ option: "" }, { option: "" }],
                    //   value: "",
                    //   isMandatory:false
                    // }
                    surveyTemp.surveyQuestions.forEach((e) => {
                        let arr = [];
                        e.surveyValues.forEach((e1) => {
                            arr.push({ option: e1.value });
                        });
                        this.quesList.push({
                            type: e.type,
                            value: e.label,
                            isMandatory: e.mandatory,
                            checkAns: e.type == "CHECK_BOX" ? arr : [],
                            MCQAns: e.type == "MULTIPLE_CHOICE" ? arr : [],
                        });
                    });
                }
            }
            // Set values for the entire form
        });
    }
    convertQuestionTypeToArray(questionType) {
        const questionTypeData = this.surveyData.questionAndValueTypeDetails[questionType];
        if (!!questionTypeData) {
            if (questionType == "SHORT_ANSWER")
                return Object.keys(questionTypeData).map((key) => {
                    return {
                        question: key,
                        responses: questionType == "SHORT_ANSWER"
                            ? questionTypeData[key][0].comment
                            : questionTypeData[key],
                    };
                });
            else if (questionType == "CHECK_BOX") {
                return Object.keys(questionTypeData).map((key) => {
                    let arr = questionTypeData[key];
                    let labels = [];
                    let tot = 0, mx = 0;
                    arr.forEach((e) => {
                        labels.push(e.value);
                        tot += e.count;
                        if (e.count > mx)
                            mx = e.count;
                    });
                    let dataPoints = [];
                    arr.forEach((e, i) => {
                        let color;
                        if (mx == e.count) {
                            color = "#AF0808";
                        }
                        else {
                            color = "#20254E";
                        }
                        const percentage = Math.round((e.count / tot) * 100);
                        let points = {
                            y: e.count,
                            color: color,
                        };
                        dataPoints.push(points);
                    });
                    return {
                        question: key,
                        chartOptions: this.loadVerticalBarChart(dataPoints, labels, true),
                        responses: tot,
                    };
                });
            }
            else {
                return Object.keys(questionTypeData).map((key) => {
                    let arr = questionTypeData[key];
                    //  let labels=[]
                    let tot = 0, mx = 0;
                    arr.forEach((e) => {
                        //    labels.push(e.value)
                        tot += e.count;
                        if (e.count > mx)
                            mx = e.count;
                    });
                    let dataPoints = [];
                    arr.forEach((e, i) => {
                        const neutralColors = [
                            "#AF0808",
                            "#20254E",
                            "#D3D3D3",
                            "#A9A9A9",
                            "#808080",
                            "#696969",
                            "#DCDCDC",
                            "#F5F5F5",
                            "#F0F0F0",
                            "#E0E0E0",
                            "#CCCCCC",
                            "#EDEDED",
                        ];
                        let color = neutralColors[i % 12];
                        if (mx == e.count) {
                            color = "#AF0808";
                            mx = -1;
                        }
                        const percentage = Math.round((e.count / tot) * 100);
                        let points = {
                            name: e.value,
                            y: percentage,
                            color: color,
                        };
                        dataPoints.push(points);
                    });
                    return {
                        question: key,
                        chartOptions: this.loadPieChart(dataPoints, false),
                        responses: tot,
                    };
                });
            }
        }
    }
    loadPieChart(dataPoints, showCenter) {
        let displayLegends = !showCenter; // for displaying legends if we are not showing center %
        let showCenterVal;
        let height;
        let dval = "";
        if (dataPoints.length > 0) {
            if (dataPoints[0]["y"] != null && dataPoints[0]["y"] != undefined) {
                dval = dataPoints[0]["y"];
            }
        }
        if (showCenter) {
            showCenterVal = dval + "%";
            height = 200;
        }
        else {
            showCenterVal = "";
            height = 250;
        }
        return {
            chart: {
                type: "pie",
                width: 350,
                backgroundColor: "#F3F7FA",
                height: height,
                events: {
                    render: function () {
                        // Create the label
                        // Get chart center coordinates
                        var centerX = this.plotWidth / 2;
                        var centerY = this.plotHeight / 2;
                        var label = this.renderer
                            .label(showCenterVal, centerX, centerY)
                            .css({
                            color: "#1f2551",
                            fontSize: "30px",
                            fontWeight: "bold",
                        })
                            .attr({
                            fill: "#F3F7FA",
                            padding: 10,
                            r: 5,
                        })
                            .add();
                        // Position the label in the center of the chart
                        var labelBox = label.getBBox();
                        label.translate(centerX - labelBox.width / 3, centerY - labelBox.height / 3);
                    },
                },
            },
            credits: {
                enabled: false,
            },
            title: {
                text: "",
            },
            tooltip: {
                pointFormat: "<b>{point.y} ({point.percentage:.1f}%)</b>",
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.y} ({point.percentage:.1f}%)</b>",
                        distance: -50,
                        style: {
                            color: "red",
                            textOutline: "none",
                            fontWeight: "bold",
                            fontSize: "14px",
                        },
                    },
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            backgroundColor: "#F3F7FA",
                            padding: "5px",
                        },
                    },
                },
            },
            series: [
                {
                    type: "pie",
                    id: "data",
                    data: dataPoints,
                    innerSize: "80%",
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: displayLegends,
                },
            ],
            legend: {
                labelFormatter: function () {
                    const legendItem = dataPoints.find((item) => item.name === this.name);
                    if (!!legendItem) {
                        return legendItem.name + "<br>" + legendItem.y + "%";
                    }
                },
                align: "right",
                verticalAlign: "middle",
                layout: "vertical",
                itemMarginTop: 10,
                itemMarginBottom: 10,
            },
        };
    }
    loadVerticalBarChart(dataPoints, labels, dataLables) {
        return {
            chart: {
                type: "column",
                backgroundColor: "#F3F7FA",
                height: 200,
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                categories: labels,
                crosshair: true,
            },
            yAxis: {
                min: 0,
                labels: {
                    enabled: false,
                },
                title: null,
                gridLineDashStyle: "Dash",
            },
            plotOptions: {
                series: {
                    pointWidth: 10,
                    borderRadius: 5,
                },
                column: {
                    dataLabels: {
                        enabled: dataLables,
                        format: "{point.y}",
                        style: {
                            fontWeight: "bold",
                        },
                    },
                },
            },
            title: {
                text: "",
            },
            series: [
                {
                    showInLegend: false,
                    name: "value",
                    data: dataPoints,
                },
            ],
        };
    }
    createSurveyArray() {
        this.shortAnswerArray = this.convertQuestionTypeToArray("SHORT_ANSWER");
        this.checkBoxArray = this.convertQuestionTypeToArray("CHECK_BOX");
        this.multipleChoiceArray =
            this.convertQuestionTypeToArray("MULTIPLE_CHOICE");
    }
    createFeedbackLoadersArray(obj, totalResponse) {
        this.feedbackLoaderArray = [];
        const ratingCounts = obj;
        const totalRatings = totalResponse; // Assuming ratings from 1.0 to 5.0
        const ratingPercentages = {};
        for (let i = 5; i >= 1; i -= 1) {
            const rating = i.toFixed(1);
            const count = ratingCounts[rating] || 0;
            const percentage = Math.round((count / totalRatings) * 100);
            this.feedbackLoaderArray.push({ star: i, percentage: percentage });
            //arr[5-i].percentage = percentage;
        }
    }
    get additionalDetailsArray() {
        return this.settingsManualForm.get("additionalDetails");
    }
    addDetails(head = null, desc = null, id = null) {
        this.additionalDetailsArray.push(this.fb.group({
            id: [id],
            heading: [head, [Validators.required, Validators.maxLength(25)]],
            description: [desc, [Validators.required, Validators.maxLength(4000)]],
        }));
    }
    removeDetails(index) {
        this.additionalDetailsArray.removeAt(index);
    }
    // get additionalDetailsArray1(): FormArray {
    //   return this.settingsURLForm.get("additionalDetails") as FormArray;
    // }
    // addDetails1(head = null, desc = null, id = null) {
    //   this.additionalDetailsArray1.push(
    //     this.fb.group({
    //       id: [id],
    //       heading: [head, [Validators.required, Validators.maxLength(25)]],
    //       description: [desc, [Validators.required, Validators.maxLength(4000)]],
    //     })
    //   );
    // }
    // removeDetails1(index: number) {
    //   this.additionalDetailsArray1.removeAt(index);
    // }
    closePopup() {
        this.dialogRef.close(this.pageType);
    }
    closePopupAddEvent() {
        this.dialogRef.close('withoutAddingEventClosed');
    }
    addGeneralOptions() {
        this.additionalDetails.push({});
    }
    get f() {
        return this.settingsManualForm.controls;
    }
    imageChange(files, event) {
        if (this.isEditEvent) {
            this.eventImageChange = true;
        }
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        const file = event.target.files[0];
        const maxSizeInBytes = 1048576; // 1 MB
        if (!file.type.startsWith("image/")) {
            this.toastService.error("Please upload a valid image");
            return;
        }
        if (file.size > maxSizeInBytes) {
            this.toastService.error("Please upload an image up to 1 MB in size");
            return;
        }
        let image = new Image();
        reader.onload = (_event) => {
            let val1 = null;
            val1 = reader.result;
            image.src = val1;
            // Access the image dimensions after it's loaded
            image.onload = () => {
                const width = image.width;
                const height = image.height;
                if (width < 360 || height < 800) {
                    this.toastService.error("Uploaded image Size is not in  360 x 800 for highest quality of resolution on phone displays.");
                    this.dashboardImageUrl = val1;
                    this.dashboardImgFile = event.target.files[0];
                }
                else {
                    this.dashboardImageUrl = val1;
                    this.dashboardImgFile = event.target.files[0];
                }
            };
            // this.dashboardImageUrl = this.dashboardImageUrl;
        };
    }
    imageChange1(files, event) {
        if (this.isEditEvent) {
            this.eventPromotionImageChange = true;
        }
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        const file = event.target.files[0];
        const maxSizeInBytes = 1048576; // 1 MB
        if (!file.type.startsWith("image/")) {
            this.toastService.error("Please upload a valid image");
            return;
        }
        if (file.size > maxSizeInBytes) {
            this.toastService.error("Please upload an image up to 1 MB in size");
            return;
        }
        let image = new Image();
        reader.onload = (_event) => {
            //this.promotion.backgroundImage = reader.result; va
            let val1 = null;
            val1 = reader.result;
            image.src = val1;
            // Access the image dimensions after it's loaded
            image.onload = () => {
                const width = image.width;
                const height = image.height;
                if (width < 360 || height < 800) {
                    this.toastService.error("Uploaded image Size is not in  360 x 800 for highest quality of resolution on phone displays.");
                    this.promotion.backgroundImage = reader.result;
                    this.promotion.imgFile = event.target.files[0];
                }
                else {
                    this.promotion.backgroundImage = reader.result;
                    this.promotion.imgFile = event.target.files[0];
                }
            };
        };
    }
    // time
    onStartDateChange(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.startDate=this.settingsForm.startDate.value
            this.startDate = event;
            let sd = new Date(this.startDate);
            let ed = new Date(this.endDate);
            //if (this.endDate == null || (sd && ed && sd.getTime() > ed.getTime()))
            this.endDate = event;
            this.startIntervals = [];
            this.endIntervals = [];
            // this.startTime=null
            // this.endTime=null
            this.startIntervals = yield this.populateTimeIntervals(event);
            if (this.startIntervals && this.startIntervals.length > 0)
                this.startIntervals.pop();
            if (this.startIntervals) {
                let i = this.startIntervals.findIndex((e) => e.id == this.startTime);
                if (i == -1)
                    this.startTime = null;
            }
            else
                this.startTime = null;
            this.onEndDateChange(this.endDate);
            // this.onAdvanceDateChange(event);
            //this.populateEndTimeIntervals();
        });
    }
    onEndDateChange(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.startDate=this.settingsForm.startDate.value
            // this.startTime=null
            // this.endTime=null
            this.endDate = event;
            let sd = new Date(this.startDate);
            let ed = new Date(this.endDate);
            // this.feedbackDate = this.endDate;
            // this.surveyDate = this.endDate;
            this.endIntervals = yield this.populateTimeIntervals(this.endDate);
            if (sd && ed && sd.getTime() == ed.getTime() && this.startTime) {
                this.endIntervals = this.endIntervals.filter((e, i) => {
                    return e.id > this.startTime;
                });
            }
            if (this.endIntervals) {
                let i = this.endIntervals.findIndex((e) => e.id == this.endTime);
                if (i == -1)
                    this.endTime = null;
            }
            else
                this.endTime = null;
            // this.onSurveyDateChange(this.endDate);
            // this.onFeedbackDateChange(this.endDate);
            // this.onStartTimeChange();
        });
    }
    onStartTimeChange() {
        //this.populateEndTimeIntervals();
        let sd = new Date(this.startDate);
        let ed = new Date(this.endDate);
        // this.advanceDate=null
        // this.advanceTime=null
        // this.notifyUserTime=null
        if (!this.isAdvanceTimeDisable)
            this.onAdvanceDateChange(this.startDate);
        if (!this.isNotifyTimeDisable)
            this.populateNotifyTimeSlots(this.startDate);
        if (this.endDate)
            this.endIntervals = this.populateTimeIntervals(this.endDate);
        if (sd && ed && sd.getTime() == ed.getTime() && this.startTime) {
            this.endIntervals = this.endIntervals.filter((e, i) => {
                return e.id > this.startTime;
            });
        }
    }
    onEndTimeChange() {
        // this.surveyDate=null
        // this.feedbackDate=null
        // this.surveyTime = null;
        // this.feedbackTime = null;
        this.onSurveyDateChange(this.endDate);
        this.onFeedbackDateChange(this.endDate);
    }
    onSurveyDateChange(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.surveyDate = event;
            this.surveyTimeSlots = yield this.populateTimeIntervals(event);
            if (this.endDate &&
                this.surveyDate &&
                this.endDate.getTime() == this.surveyDate.getTime()) {
                this.surveyTimeSlots = this.surveyTimeSlots.filter((e) => {
                    return e.id >= this.endTime;
                });
            }
            this.surveyTime = this.endTime;
            if (this.surveyTimeSlots) {
                let i = this.surveyTimeSlots.findIndex((e) => e.id == this.surveyTime);
                if (i == -1)
                    this.surveyTime = null;
            }
            else
                this.surveyTime = null;
            // const currentDate=new Date();
        });
    }
    populateNotifyTimeSlots(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notifyTimeSlots = yield this.populateTimeIntervals(event);
            this.notifyTimeSlots = this.notifyTimeSlots.filter((e) => {
                return e.id <= this.startTime;
            });
            if (this.notifyTimeSlots.length >= 1)
                this.notifyTimeSlots.pop();
            if (this.notifyTimeSlots.length >= 1) {
                this.notifyUserTime =
                    this.notifyTimeSlots[this.notifyTimeSlots.length - 1].id;
            }
            let i = -1;
            if (this.notifyTimeSlots)
                i = this.notifyTimeSlots.findIndex((e) => e.id == this.notifyUserTime);
            if (i == -1)
                this.notifyUserTime = null;
            if (this.notifyTimeSlots.length == 0) {
                this.settingsManualForm.get("notifyUser").setValue(false);
            }
            // const currentDate=new Date();
        });
    }
    reEnableSurvey(event) {
        this.viewSurveyEnabled = false;
        this.duplicateMCQOption = false;
        if (event.checked) {
            if (!this.endDate || !this.endTime) {
                this.toastService.error("First Select the event end Date and Time");
                this.settingsManualForm.get("survey").setValue(false);
                return;
            }
            else if (!this.f.userRegister.value) {
                this.toastService.error("To enable Surveys, it is mandatory to enable User Registration. This is required as user data is needed to share Survey form. ");
                this.settingsManualForm.get("survey").setValue(false);
                return;
            }
            if (this.quesList.length == 0) {
                this.addQues();
            }
            this.pageType = "survey1";
            this.dialogRef.updateSize("60%");
            this.quesListTemp = JSON.parse(JSON.stringify(this.quesList));
            this.surveyTitleTemp = this.surveyTitle;
        }
        else {
        }
    }
    isUserRegisterEnabled(event, key) {
        if (event.checked) {
            if (!this.f.userRegister.value) {
                if (key == "empP") {
                    this.toastService.error("To enable Payments, it is mandatory to enable User Registration. This is required as user data is needed to gather payment information. ");
                    this.settingsManualForm.get("empPayment").setValue(false);
                }
                else if (key == "maxP") {
                    this.toastService.error("To enable Maximum Participants, it is mandatory to enable User Registration. This is required as user data is needed to calculate number of participants. ");
                    this.settingsManualForm.get("maxParticipants").setValue(false);
                }
                else if (key == "guest") {
                    this.toastService.error("To enable guests, it is mandatory to enable User Registration. This is required as user data is needed to gather information about guests. ");
                    this.settingsManualForm.get("allowGuest").setValue(false);
                }
                return;
            }
        }
        else {
        }
    }
    viewSurvey() {
        this.viewSurveyEnabled = true;
        this.duplicateMCQOption = false;
        this.pageType = "survey1";
        this.dialogRef.updateSize("60%");
        this.quesListTemp = JSON.parse(JSON.stringify(this.quesList));
        this.surveyTitleTemp = this.surveyTitle;
    }
    onFeedbackDateChange(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.feedbackDate = event;
            this.feedbackTimeSlots = yield this.populateTimeIntervals(event);
            if (this.endDate &&
                this.feedbackDate &&
                this.endDate.getTime() == this.feedbackDate.getTime()) {
                this.feedbackTimeSlots = this.feedbackTimeSlots.filter((e) => {
                    return e.id >= this.endTime;
                });
            }
            this.feedbackTime = this.endTime;
            let i = -1;
            if (this.feedbackTimeSlots)
                i = this.feedbackTimeSlots.findIndex((e) => e.id == this.feedbackTime);
            if (i == -1)
                this.feedbackTime = null;
        });
    }
    reEnableFeedback(event) {
        if (event.checked) {
            if (!this.endDate || !this.endTime) {
                this.toastService.error("First Select the event end Date and Time");
                this.settingsManualForm.get("feedback").setValue(false);
                return;
            }
            else if (!this.f.userRegister.value) {
                this.toastService.error("To enable Feedback, it is mandatory to enable User Registration. Only registered participants will be asked feedback.");
                this.settingsManualForm.get("feedback").setValue(false);
                return;
            }
        }
        else {
        }
    }
    userRegisterChange(event) {
        if (!event.checked) {
            this.settingsManualForm.get("feedback").setValue(false);
            this.settingsManualForm.get("allowGuest").setValue(false);
            this.settingsManualForm.get("maxParticipants").setValue(false);
            this.settingsManualForm.get("empPayment").setValue(false);
            this.settingsManualForm.get("survey").setValue(false);
        }
    }
    reEnableAdvance(event) {
        if (event.checked) {
            if (!this.startDate || !this.startTime) {
                this.toastService.error("First Select the event start Date and Time");
                this.settingsManualForm.get("inAdvance").setValue(false);
                return;
            }
        }
        else {
        }
    }
    reEnableNotify(event) {
        if (event.checked) {
            if (!this.startDate || !this.startTime) {
                this.toastService.error("First Select the event start Date and Time");
                this.settingsManualForm.get("notifyUser").setValue(false);
                return;
            }
            if (this.notifyTimeSlots.length == 0) {
                this.toastService.error("Their is no time slots available to notify the users");
                this.settingsManualForm.get("notifyUser").setValue(false);
                return;
            }
        }
        else {
        }
    }
    populateTimeIntervals(date) {
        let startIntervals = [];
        if (date) {
            const selectedDate = new Date(date);
            const currentDate = new Date();
            const isToday = this.isSameDate(selectedDate, currentDate);
            const startDateTime = selectedDate;
            const timeInterval = 15; // in minutes
            const totalIntervals = (24 * 60) / timeInterval;
            let i = 0;
            for (i = 0; i < totalIntervals; i++) {
                const minutes = i * timeInterval;
                const hours = Math.floor(minutes / 60);
                const mins = minutes % 60;
                const date = new Date(startDateTime);
                date.setHours(hours);
                date.setMinutes(mins);
                const slotStartTime = this.formatTime(date.getHours(), date.getMinutes());
                startIntervals.push({
                    id: date.getTime(),
                    name: slotStartTime["time12"],
                });
            }
            i = i - 1;
            // for 11:59
            if (true) {
                const minutes = i * timeInterval;
                const hours = Math.floor(minutes / 60);
                let mins = minutes % 60;
                mins = mins + 14;
                const date = new Date(startDateTime);
                date.setHours(hours);
                date.setMinutes(mins);
                const slotStartTime = this.formatTime(date.getHours(), date.getMinutes());
                startIntervals.push({
                    id: date.getTime(),
                    name: slotStartTime["time12"],
                });
            }
            // 11:59
            // Populate end intervals based on the selected start time
            if (isToday)
                startIntervals = startIntervals.filter((interval) => {
                    const intervalDate = new Date(interval.id);
                    const currentTime = currentDate.getTime();
                    const intervalTime = intervalDate.getTime();
                    return interval.id > currentTime;
                });
            return startIntervals;
        }
    }
    isSameDate(date1, date2) {
        return (date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate());
    }
    formatTime(hours, minutes) {
        const isAM = hours < 12;
        const formattedHours24 = String(hours).padStart(2, "0");
        const formattedHours12 = String(hours % 12 || 12).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const period = isAM ? "AM" : "PM";
        return {
            time24: `${formattedHours24}${formattedMinutes}`,
            time12: `${formattedHours12}:${formattedMinutes} ${period}`,
        };
    }
    onAdvanceDateChange(event) {
        this.advanceDate = event;
        this.advanceTimeSlots = this.populateTimeIntervals(event);
        if (event &&
            this.startDate &&
            event.getTime() == this.startDate.getTime()) {
            this.advanceTimeSlots = this.advanceTimeSlots.filter((e) => {
                return e.id <= this.startTime;
            });
            if (this.advanceTimeSlots.length > 1) {
                this.advanceTime =
                    this.advanceTimeSlots[this.advanceTimeSlots.length - 2].id;
            }
            else if (this.advanceTimeSlots.length == 1)
                this.advanceTime =
                    this.advanceTimeSlots[this.advanceTimeSlots.length - 1].id;
        }
        let i = -1;
        if (this.advanceTimeSlots)
            i = this.advanceTimeSlots.findIndex((e) => e.id == this.advanceTime);
        if (i == -1)
            this.advanceTime = null;
    }
    onAdvanceDateChange1(event) {
        this.advanceDate = event;
        this.advanceTimeSlots = this.populateTimeIntervals(event);
    }
    changeQuesType(e) { }
    //checkAns = [{ option: null }, { option: null }];
    //time
    cloneList(list) {
        return _.cloneDeep(list);
    }
    removeCheckAns(k, i) {
        if (this.quesList[k].checkAns.length == 1) {
            this.toastService.error('Atleast one option is required');
            return;
        }
        this.quesList = this.cloneList(this.quesList);
        this.quesList[k].checkAns.splice(i, 1);
        this.quesList = this.cloneList(this.quesList);
    }
    addCheckAns(k) {
        this.quesList = this.cloneList(this.quesList);
        this.quesList[k].checkAns.push({ option: '' });
        this.quesList = this.cloneList(this.quesList);
    }
    // MCQAns = [{ option: null }, { option: null }];
    //time
    removeMCQAns(k, i) {
        if (this.quesList[k].MCQAns.length == 1) {
            this.toastService.error('Atleast one option is required');
            return;
        }
        this.quesList = this.cloneList(this.quesList);
        this.quesList[k].MCQAns.splice(i, 1);
        this.quesList = this.cloneList(this.quesList);
    }
    addMCQAns(k) {
        this.quesList = this.cloneList(this.quesList);
        this.quesList[k].MCQAns.push({ option: '' });
        this.quesList = this.cloneList(this.quesList);
    }
    delQues(k) {
        if (this.quesList.length == 1) {
            this.toastService.error('Atleast one question should be their');
            return;
        }
        this.quesList = this.cloneList(this.quesList);
        this.quesList.splice(k, 1);
        this.quesList = this.cloneList(this.quesList);
    }
    addQues() {
        this.quesList = this.cloneList(this.quesList);
        this.quesList.push({
            type: "MULTIPLE_CHOICE",
            checkAns: [{ option: "" }, { option: "" }],
            MCQAns: [{ option: "" }, { option: "" }],
            value: "",
            isMandatory: false,
        });
        this.quesList = this.cloneList(this.quesList);
    }
    submitSurvey1(f) {
        const formData = f.value;
        this.submittedSurvey = true;
        // if(this.duplicateMCQOption)return;
        if (this.surveyTitle.trim() == "") {
            return;
        }
        let flag = 0;
        this.quesList.forEach((e, qi1) => {
            if (e.value.trim() == "") {
                flag = 1;
                return;
            }
            else {
                this.quesList.forEach((e1, qi2) => {
                    if (qi1 != qi2) {
                        if (e.value.trim() == e1.value.trim() && e.type == e1.type) {
                            flag = 1;
                            return;
                        }
                    }
                });
                if (e.type == "MULTIPLE_CHOICE") {
                    e.MCQAns.forEach((e1, i) => {
                        // e1.option = formData[`mcq${i}`];
                        if (e1.option == null || e1.option.trim() == "") {
                            flag = 1;
                            return;
                        }
                        e.MCQAns.forEach((e2, j) => {
                            if (j != i) {
                                if (e1.option.trim() == e2.option.trim()) {
                                    flag = 1;
                                    return;
                                }
                            }
                        });
                    });
                }
                else if (e.type == "CHECK_BOX") {
                    e.checkAns.forEach((e1, i) => {
                        //e1.option = formData[`checkBoxAns${i}`];
                        if (e1.option == null || e1.option.trim() == "") {
                            flag = 1;
                            return;
                        }
                        e.checkAns.forEach((e2, j) => {
                            if (j != i) {
                                if (e1.option.trim() == e2.option.trim()) {
                                    flag = 1;
                                    return;
                                }
                            }
                        });
                    });
                }
            }
        });
        if (flag)
            return;
        this.submittedSurvey = false;
        this.pageType = "addEvent";
        this.dialogRef.updateSize("60%");
    }
    saveSurveyRef() {
        this.pageType = "survey3";
        this.dialogRef.updateSize("45%");
    }
    backEvent() {
        this.pageType = "addEvent";
        this.dialogRef.updateSize("60%");
    }
    removeAddInfo(k) {
        this.additionalDetails.splice(k, 1);
    }
    onEditManualSubmit() { }
    onManualSubmit() {
        this.submitted = true;
        if (this.settingsManualForm.invalid) {
            this.toastService.error('Please fill all the mandatory fields');
            return;
        }
        else {
            if (this.dashboardImageUrl == "" || this.dashboardImageUrl == null) {
                return;
            }
            //    if(this.eventType=='manual' &&  (!this.f.location.value || (this.f.location.value && this.f.location.value.trim()=='')) )return;
            if (this.eventType == 'url' && !this.f.eventURL.value)
                return;
            if (this.f.promotion.value == true) {
                let flag = 1;
                if (this.promotion.heading.trim() == "") {
                    this.toastService.error("Please enter promotion heading");
                }
                else if (this.promotion.subheading.trim() == "") {
                    this.toastService.error("Please enter promotion  Sub heading");
                }
                else if (this.promotion.buttonText.trim() == "") {
                    this.toastService.error("Please enter enter cta");
                }
                else if (!this.promotion.backgroundImage) {
                    this.toastService.error("Please select promotion Image");
                }
                else {
                    flag = 0;
                }
                if (flag)
                    return;
            }
            if (this.f.inAdvance.value == true) {
                if (!this.advanceDate) {
                    this.toastService.error("Please select date in 'Display in advance' ");
                    return;
                }
                else if (!this.advanceTime) {
                    this.toastService.error("Please select time in 'Display in advance' ");
                    return;
                }
            }
            if (this.f.notifyUser.value == true) {
                if (!this.notifyUserTime) {
                    this.toastService.error("Please select time in 'Notify Users' ");
                    return;
                }
            }
            if (this.eventType == 'manual') {
                if (this.f.survey.value == true) {
                    if (!this.surveyTime) {
                        this.toastService.error("Please select time in 'Survey' ");
                        return;
                    }
                }
                if (this.f.feedback.value == true) {
                    if (!this.feedbackTime) {
                        this.toastService.error("Please select time in 'Feedback' ");
                        return;
                    }
                }
                if (this.f.maxParticipants.value == true) {
                    if (!this.maxParticipants) {
                        this.toastService.error("Please enter number of persons in 'Maximum Participants' ");
                        return;
                    }
                    if (this.isEditEvent) {
                        if (this.maxParticipants < this.event.maxUser) {
                            this.toastService.error(`Number of max participants cannot be less than previously added. ( must be greater than or equal to  ${this.event.maxUser} )`);
                            return;
                        }
                    }
                }
                if (this.f.empPayment.value == true) {
                    if (!this.empCost) {
                        this.toastService.error("Please enter amount in Payments ");
                        return;
                    }
                }
                if (this.f.allowGuest.value == true) {
                    if (!this.guestLimit) {
                        this.toastService.error("Please enter number of persons in guest limit");
                        return;
                    }
                    else if (!this.noGuestInfo &&
                        !this.guestName &&
                        !this.guestEmail &&
                        !this.guestMobile) {
                        this.toastService.error("Please select one of the entity in capture information of guest");
                        return;
                    }
                    else if (this.f.enableGuestPayment.value && !this.guestCost) {
                        this.toastService.error("Please enter amount in Cost per guest ");
                        return;
                    }
                    if (this.f.maxParticipants.value && this.guestLimit >= (this.maxParticipants)) {
                        this.toastService.error("Number of guests cannot be greater than or equal to number of max participants.");
                        return;
                    }
                    if (this.isEditEvent) {
                        if (this.guestLimit < this.event.eventAdditionalDetails.noOfGuest) {
                            this.toastService.error(`Number of guests cannot be less than previously added. ( must be greater than or equal to  ${this.event.eventAdditionalDetails.noOfGuest} )`);
                            return;
                        }
                    }
                }
            }
            this.submitted = false;
            /*
      inAdvance: ["", null],
            userRegister: ["", null],
            feedback: ["", null],
            maxParticipants: ["", null],
            empPayment: ["", null],
            allowGuest: ["", null],
            promotion: ["", null],
            survey: ["", null],
            surveyDate: ["", null],
            surveyAnonymous: ["", null],
            eventTitle: ["", [Validators.required, Validators.maxLength(25)]],
            eventCategory: ["", Validators.required],
            eventDisc: ["", [Validators.required, Validators.maxLength(250)]],
            startDate: ["", Validators.required],
            endDate: ["", Validators.required],
            startTime: ["", Validators.required],
            endTime: ["", Validators.required],
            location: ["", Validators.required],
            locationURL: ["", Validators.pattern("https?://.+")],
            additionalDetails: this.fb.array([]),
            tennat id confirma
            */
            let surveyQuestionArr = [];
            this.quesList.forEach((e) => {
                let valueArr = [];
                if (e.type == "MULTIPLE_CHOICE") {
                    e.MCQAns.forEach((e1) => {
                        valueArr.push(e1.option);
                    });
                }
                else if (e.type == "CHECK_BOX") {
                    e.checkAns.forEach((e1) => {
                        valueArr.push(e1.option);
                    });
                }
                surveyQuestionArr.push({
                    mandatory: e.isMandatory,
                    label: e.value,
                    type: e.type,
                    values: valueArr,
                });
            });
            let typeTemp;
            if (this.eventType == 'url') {
                typeTemp = 'WEB_REDIRECT';
            }
            else {
                typeTemp = this.f.userRegister.value ? 'FREE' : 'INFO';
            }
            /*
      {
       
        "type": "PAID",
        "price": 0.00,
        "currency": "INR",
        "redirectionUrl": null,
        "zoneMapping": [
          {
            "zoneId": 5,
            "tenantId": 1,
            "enterpriseId": 1
          }
        ],
        "customField": [
          {
            "type": "FIELDS",
            "label": "email",
            "value": "age",
            "isMandatory": true
          },
          {
            "type": "FIELDS",
            "label": "email",
            "value": "age",
            "isMandatory": true
          }
        ],
        "inviteGuests": true,
        "notifyPresentDay": true,
        "presentDayTime": 0,
        "displayEventInAdvance": true,
        "notifyUserInAdvance": true,
        "advanceEventDisplayDate": 1,
        "address": "banglore",
        "eventPromotionDto": {
          "heading": "heading_28ef42a0f9b4",
          "subHeading": "subHeading_0c88a46e6299",
          "colour": "colour_083dcffedaf7",
          "buttonText": "REGISTER",
          "status": true
        },
        "eventPromotion": true,
        "locationUrl": "urlloc",
        "eventAdditionalDetails": {
          "eventSurvey": true,
          "eventSurveyTime": 1,
          "eventFeedback": true,
          "eventFeedbackTime": 1,
          "captureGuest": true,
          "noOfGuest": 10,
          "guestPrice": 100,
          "eventSurveyDto": {
            "surveyTitle": "event update",
            "surveyQuestions": [
              {
                "isMandatory": true,
                "label": "Select your Option",
                "type": "CHECK_BOX",
                "values": [
                  "NICE EVENT",
                  "NICE FOOD",
                  "BAD_EVENT"
                ]
              },
              {
                "isMandatory": true,
                "label": "how are you",
                "type": "MULTIPLE_CHOICE",
                "values": [
                  "good",
                  "bad"
                ]
              },
              {
                "isMandatory": true,
                "label": "how are you",
                "type": "SHORT_ANSWER",
                "values": []
              }
            ]
          },
          "guestInfoDTO": {
            "guestInfo": {
      
              // tennant id
              "name": true,
              "age": false
            }
          }
        }
      }
      
      */
            if (!this.isEditEvent) {
                let customFieldTemp = [];
                let additionalDetailsArr = this.additionalDetailsArray.value;
                additionalDetailsArr.forEach((e) => {
                    customFieldTemp.push({
                        isMandatory: true,
                        label: e.heading,
                        type: "HEADINGS",
                        value: e.description,
                    });
                });
                let payLoad = {
                    title: this.f.eventTitle.value,
                    eventCategory: { id: this.f.eventCategory.value },
                    description: this.f.eventDisc.value,
                    maxUser: this.maxParticipants,
                    startDate: this.startTime,
                    endDate: this.endTime,
                    startUtc: this.commonService.getTimeStamp(this.startTime, "start"),
                    scheduledDate: 0,
                    type: typeTemp,
                    price: this.empCost,
                    currency: this.empCostType,
                    zoneMapping: [
                        { enterpriseId: 1, tenantId: 1, zoneId: this.currentZone },
                    ],
                    customField: customFieldTemp,
                    address: this.f.location.value,
                    locationUrl: this.f.locationURL.value,
                    redirectionUrl: this.f.eventURL.value,
                    inviteGuests: this.f.allowGuest.value,
                    displayEventInAdvance: this.f.inAdvance.value,
                    notifyUserInAdvance: this.f.inAdvance.value,
                    notifyPresentDay: this.f.notifyUser.value,
                    presentDayTime: this.notifyUserTime == null ? 0 : this.notifyUserTime,
                    advanceEventDisplayDate: this.advanceTime,
                    eventPromotion: this.f.promotion.value,
                    eventPromotionDto: {
                        heading: this.promotion.heading,
                        subHeading: this.promotion.subheading,
                        colour: this.promotion.colour,
                        buttonText: this.promotion.buttonText,
                        status: true,
                    },
                    guestCurrency: this.guestCostType,
                    toRegister: this.f.userRegister.value,
                    eventAdditionalDetails: {
                        eventSurvey: this.f.survey.value,
                        eventFeedback: this.f.feedback.value,
                        surveyAnonymous: this.f.surveyAnonymous.value,
                        eventSurveyTime: this.surveyTime,
                        captureGuest: this.noGuestInfo,
                        eventFeedbackTime: this.feedbackTime,
                        guestPrice: this.guestCost,
                        noOfGuest: this.guestLimit,
                        eventSurveyDto: {
                            surveyTitle: this.surveyTitle,
                            surveyQuestions: surveyQuestionArr,
                        },
                        guestInfoDTO: {
                            guestInfo: {
                                name: this.guestName,
                                phone: this.guestMobile,
                                capture: this.noGuestInfo,
                                email: this.guestEmail,
                            },
                        },
                    },
                };
                const create = this.eventService.createEvent(payLoad, this.dashboardImgFile, this.promotion.imgFile, this.currentZone);
                this.loaderService.showLoaderUntilCompleted(create).subscribe((e) => {
                    this.closePopup();
                }, (err) => { });
            }
            else {
                let customFieldTemp = [];
                let additionalDetailsArr = this.additionalDetailsArray.value;
                additionalDetailsArr.forEach((e) => {
                    customFieldTemp.push({
                        isMandatory: true,
                        label: e.heading,
                        type: "HEADINGS",
                        value: e.description,
                        id: e.id,
                    });
                });
                let payLoad = {
                    id: this.event.id,
                    title: this.f.eventTitle.value,
                    eventCategory: { id: this.f.eventCategory.value },
                    description: this.f.eventDisc.value,
                    maxUser: this.maxParticipants,
                    startDate: this.startTime,
                    endDate: this.endTime,
                    startUtc: this.commonService.getTimeStamp(this.startTime, "start"),
                    scheduledDate: 0,
                    type: typeTemp,
                    price: this.empCost,
                    currency: this.empCostType,
                    zoneMapping: [
                        { enterpriseId: 1, tenantId: 1, zoneId: this.currentZone },
                    ],
                    customField: customFieldTemp,
                    address: this.f.location.value,
                    locationUrl: this.f.locationURL.value,
                    redirectionUrl: this.f.eventURL.value,
                    inviteGuests: this.f.allowGuest.value,
                    displayEventInAdvance: this.f.inAdvance.value,
                    notifyUserInAdvance: this.f.inAdvance.value,
                    notifyPresentDay: this.f.notifyUser.value,
                    presentDayTime: this.notifyUserTime == null ? 0 : this.notifyUserTime,
                    advanceEventDisplayDate: this.advanceTime,
                    eventPromotion: this.f.promotion.value,
                    eventPromotionDto: {
                        id: this.promotion.id,
                        heading: this.promotion.heading,
                        subHeading: this.promotion.subheading,
                        colour: this.promotion.colour,
                        buttonText: this.promotion.buttonText,
                        status: true,
                        imageUrl: this.event.eventPromotionDto
                            ? this.event.eventPromotionDto.imageUrl
                            : null,
                    },
                    guestCurrency: this.guestCostType,
                    toRegister: this.f.userRegister.value,
                    eventAdditionalDetails: {
                        id: this.event.eventAdditionalDetails.id,
                        eventSurvey: this.f.survey.value,
                        eventFeedback: this.f.feedback.value,
                        eventSurveyTime: this.surveyTime,
                        surveyAnonymous: this.f.surveyAnonymous.value,
                        captureGuest: this.noGuestInfo,
                        eventFeedbackTime: this.feedbackTime,
                        guestPrice: this.guestCost,
                        noOfGuest: this.guestLimit,
                        eventSurveyDto: {
                            surveyTitle: this.surveyTitle,
                            surveyQuestions: surveyQuestionArr,
                        },
                        guestInfoDTO: {
                            guestInfo: {
                                name: this.guestName,
                                phone: this.guestMobile,
                                capture: this.noGuestInfo,
                                email: this.guestEmail,
                            },
                        },
                    },
                };
                if (!this.eventImageChange) {
                    this.dashboardImgFile = null;
                }
                if (!this.eventPromotionImageChange) {
                    this.promotion.imgFile = null;
                }
                const create = this.eventService.editEvent(payLoad, this.dashboardImgFile, this.promotion.imgFile, this.currentZone, this.event.id);
                this.loaderService.showLoaderUntilCompleted(create).subscribe((e) => {
                    this.closePopup();
                    this.toastService.success("Event has been successfully updated");
                }, (err) => { });
            }
        }
    }
    validateStartDateTime() {
        if (!this.startDate) {
            this.toastService.error("Please Select Start Date First");
        }
    }
    validateEndDateTime() {
        if (!this.endDate) {
            this.toastService.error("Please Select Start and End Date First");
        }
    }
    backSurvey1() {
        this.pageType = "survey1";
        this.dialogRef.updateSize("60%");
    }
    backSurvey2() {
        this.pageType = "survey2";
        this.dialogRef.updateSize("45%");
    }
    updatePromotionColor() { }
    getLoaderGradient(percentage) {
        const color = "#0f1f54"; // Set your desired color
        const gradient = `linear-gradient(90deg, ${color} ${percentage}%, white ${percentage}%)`;
        return gradient;
    }
    downloadReportTypeChange(event) {
        const today = new Date();
        if (this.selectedType == "current_week") {
            this.startDate = new Date();
            this.endDate = new Date();
            this.startDate.setDate(today.getDate() - today.getDay() + 1);
            this.endDate.setDate(today.getDate() + (7 - today.getDay()));
        }
        else if (this.selectedType == "last_15_days") {
            this.startDate = new Date();
            this.endDate = new Date();
            this.startDate.setDate(today.getDate() - 15); // Subtracting 15 days from current date
        }
        else if (this.selectedType == "last_month") {
            this.startDate = new Date();
            this.endDate = new Date();
            this.startDate.setMonth(this.startDate.getMonth() - 1); // Subtracting 1 month from current month
        }
        else if (this.selectedType == "custom_date") {
            this.startDate = new Date();
            this.endDate = new Date();
        }
    }
    formatDate(timestamp) {
        // Assuming timestamp is in milliseconds
        const date = new Date(timestamp);
        // Define months array
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        // Get components of the date
        const year = date.getFullYear();
        const month = months[date.getMonth()];
        const day = date.getDate();
        // Create the formatted date string
        const formattedDate = `${month} ${day}`;
        return formattedDate;
    }
    getPrevOrNextRecords(event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadRegisterdUserDetails();
        //this.loaderService.loadingOn();
        //this.getHierarchy();
    }
    getPrevOrNextRecordsGuest(event) {
        this.pageIndex1 = event.pageIndex;
        this.pageSize1 = event.pageSize;
        this.loadGuestDeatils();
        //this.loaderService.loadingOn();
        //this.getHierarchy();
    }
    openGuestDetails(ele) {
        if (ele.totalMembers == 0)
            return;
        this.pageType = "userRegisterdGuestDetail";
        //this.dataSource=[]
        this.userRegisterId = ele.userId;
        // this.resetPaginator();
        this.loadGuestDeatils();
    }
    loadGuestDeatils() {
        const $feedback = this.eventService.getRegsiterdUserGuestDetails(this.currentZone, this.eventId, this.userRegisterId, this.pageIndex, this.pageSize);
        this.loaderService.showLoaderUntilCompleted($feedback).subscribe((res) => {
            this.dataSource1 = new MatTableDataSource(res["response"]["content"]);
            this.length1 = res["response"]["totalElements"];
        }, (e) => { });
    }
    resetPaginator() {
        this.paginator.firstPage();
    }
    resetPaginator1() {
        this.paginator1.firstPage();
    }
    closeGuestPopup() {
        this.pageType = "userRegisterdDetail";
    }
    formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        // Extract date components
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        // Extract time components
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        // Construct the formatted timestamp
        const formattedTimestamp = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        return formattedTimestamp;
    }
    searchRegisterUserName(key) {
        if (this.userRegisterSearch == "") {
            this.toastService.error("Not a valid search");
            return;
        }
        this.resetPaginator();
        this.userRegisterSearchActive = false;
        const $feedback = this.eventService.getUsersByKey(this.currentZone, key, this.eventId, this.pageIndex, this.pageSize);
        this.loaderService.showLoaderUntilCompleted($feedback).subscribe((res) => {
            this.dataSource = new MatTableDataSource(res["response"]["content"]);
            this.length = res["response"]["totalElements"];
            // this.isEvents=res['response'].length;
        }, (e) => { });
    }
    clearSearchRegisterUserName() {
        this.userRegisterSearch = "";
        this.userRegisterSearchActive = true;
        this.resetPaginator();
        this.loadRegisterdUserDetails();
    }
    searchGuest(key) {
        if (this.guestSearch == "") {
            this.toastService.error("Not a valid search");
            return;
        }
        this.resetPaginator1();
        this.guestSearchActive = false;
        const $feedback = this.eventService.getGuestsByKey(this.currentZone, key, this.eventId, this.userRegisterId, this.pageIndex, this.pageSize);
        this.loaderService.showLoaderUntilCompleted($feedback).subscribe((res) => {
            this.dataSource1 = new MatTableDataSource(res["response"]["content"]);
            this.length1 = res["response"]["totalElements"];
            // this.isEvents=res['response'].length;
        }, (e) => { });
    }
    clearSearchGuest() {
        this.guestSearch = "";
        this.guestSearchActive = true;
        this.resetPaginator1();
        this.loadGuestDeatils();
    }
    deleteEvent(key) {
        this.dialogRef.close(key);
    }
    downloadSurveyReport() {
        const eventId = this.data["id"];
        const $feedback = this.eventService.getCustomQuestion(this.currentZone, eventId);
        this.loaderService.showLoaderUntilCompleted($feedback).subscribe((res) => {
            let userDetailsShow = res["response"]["show"];
            let questions = res["response"]["questionLabels"];
            let getReport = this.eventService.getSurveyReportData(this.currentZone, eventId);
            forkJoin([getReport]).subscribe((result) => {
                let resData = result[0].response;
                let vmsdownloadList = [];
                let obj = {};
                obj.time = "Time Stamp";
                if (userDetailsShow == 1) {
                    obj.empName = "Employee Name";
                    obj.empEmail = "Employee Email";
                }
                questions.forEach((e, i) => {
                    obj["q" + i] = "Question " + (i + 1) + ": " + e;
                });
                vmsdownloadList = resData.map((elem) => {
                    let obj = {};
                    obj["time"] = this.formatTimestamp(elem.createdAt);
                    if (userDetailsShow == 1) {
                        obj["empName"] = elem.userDTO.username;
                        obj["empEmail"] = elem.userDTO.email;
                    }
                    questions.forEach((e, i) => {
                        let arr = elem.surveyResponseDtos[e];
                        let str = "";
                        if (!!arr) {
                            arr.forEach((e1, j) => {
                                str += e1;
                                if (j != arr.length - 1)
                                    str += ", ";
                            });
                        }
                        if (str == "")
                            str = "NA";
                        obj["q" + i] = str;
                    });
                    return obj;
                });
                vmsdownloadList.unshift(obj);
                new Angular5Csv(vmsdownloadList, "Survey Report");
            }, (err) => { });
            // this.isEvents=res['response'].length;
        }, (e) => { });
    }
    // eventTypeChange(){
    //   if(this.eventType=='url'){
    //     this.additionalDetailsArray1.value=
    //     let arr=this.additionalDetailsArray.value;
    //     arr.forEach(element => {
    //       this.addDetails1(element.heading,element.description,element.id)
    //     });
    //   }
    //   else{
    //     let arr=this.additionalDetailsArray1.value;
    //     arr.forEach(element => {
    //       this.addDetails(element.heading,element.description,element.id)
    //     });
    //   }
    // }
    backSurvey() {
        // this.dialogRef.close('SurveyClosed')
        this.pageType = "addEvent";
        this.dialogRef.updateSize("60%");
        if (!this.viewSurveyEnabled)
            this.settingsManualForm.get("survey").setValue(false);
        this.quesList = JSON.parse(JSON.stringify(this.quesListTemp));
        this.surveyTitle = this.surveyTitleTemp;
    }
    checkDuplicateOptions(quesIndex, optionIndex) {
        const currentOption = this.quesList[quesIndex].MCQAns[optionIndex].option;
        if (currentOption == null || currentOption == '')
            return;
        let flag = this.quesList[quesIndex].MCQAns
            .filter((ans, index) => index !== optionIndex) // Exclude current option
            .some(ans => ans.option.trim() === currentOption.trim());
        this.duplicateMCQOption = this.duplicateMCQOption || flag;
        return flag;
    }
    checkDuplicateOptionsCheck(quesIndex, optionIndex) {
        const currentOption = this.quesList[quesIndex].checkAns[optionIndex].option;
        if (currentOption == null || currentOption == '')
            return;
        let flag = this.quesList[quesIndex].checkAns
            .filter((ans, index) => index !== optionIndex) // Exclude current option
            .some(ans => ans.option.trim() === currentOption.trim());
        this.duplicateMCQOption = this.duplicateMCQOption || flag;
        return flag;
    }
    checkDuplicateQuestions(quesIndex) {
        const currentValue = this.quesList[quesIndex].value;
        return this.quesList
            .filter((ques, index) => index !== quesIndex) // Exclude current question
            .some(ques => ques.value.trim() === currentValue.trim() && ques.type == this.quesList[quesIndex].type);
    }
    encodeEventPromoImage(url) {
        const segments = url.split('/');
        const imageNameIndex = segments.length - 1;
        const imageName = segments[imageNameIndex];
        let encodedImageName = '';
        for (let i = 0; i < imageName.length; i++) {
            const char = imageName.charAt(i);
            if (/[^\w\d]/.test(char)) {
                encodedImageName += '%' + char.charCodeAt(0).toString(16).toUpperCase();
            }
            else {
                encodedImageName += char;
            }
        }
        segments[imageNameIndex] = encodedImageName;
        return segments.join('/');
    }
}
