<div class="container-fluid mt-2 px-0">
    <div class="">
        <mat-tab-group
            class="customer_tab"
            (selectedTabChange)="tabClick($event)"
        >
            <mat-tab label="{{'myWorkStatus' | translate}}">
                <ng-template matTabContent>
                    <div *ngIf="gotIt" class="">
                        <div class="d-flex">
                            <img
                                src="./assets/images/jumpree_digital/1.svg"
                                alt="smartenspaces-Logo"
                                class="m-auto w-50"
                            />
                        </div>
                        <p class="paraGraph">
                            {{"workArrangementsText" | translate}}
                        </p>

                        <button
                            class="btn btn-main small-btn float-right"
                            type="button"
                            (click)="mySchedule('preference')"
                        >
                            {{"gotIt" | translate}}
                        </button>
                    </div>
                    <div *ngIf="calenderView">
                        <div>
                            <div class="container row">
                                <div class="col-lg-9 calendar-schedule">
                                    <!-- <owl-date-time-inline [min]="minDateTime"  [pickerType]="'calendar'" [(ngModel)]="dateTime" [owlDateTimeFilter]="myScheduleFilter" (click)="removeSelecteddate()"  ></owl-date-time-inline> -->
                                    <full-calendar
                                        #fullcalendar
                                        *ngIf="calendarOptions"
                                        [options]="calendarOptions"
                                    ></full-calendar>
                                </div>
                                <div class="col-lg-3 subDivStyle">
                                    <mat-radio-group
                                        aria-label="Select an option"
                                        name="deskBookingMode"
                                        class="font-variant-none"
                                        (change)="selectBookingType($event)"
                                    >
                                        <div class="col-12">
                                            <mat-radio-button
                                                class="col-lg-12 mt-4 officeColor"
                                                value="OFFICE"
                                                [checked]="
                                                    selectedBookingType ==
                                                    'OFFICE'
                                                "
                                            >
                                              {{"office" | translate}}
                                            </mat-radio-button>
                                            <mat-radio-button
                                                class="col-lg-12 mt-4 homeColor"
                                                value="WFH"
                                                [checked]="
                                                    selectedBookingType == 'WFH'
                                                "
                                            >
                                                {{"home" | translate}}
                                            </mat-radio-button>
                                            <mat-radio-button
                                                class="col-lg-12 mt-4 leaveColor"
                                                value="LEAVE"
                                                [checked]="
                                                    selectedBookingType ==
                                                    'LEAVE'
                                                "
                                            >
                                                {{"leave" | translate}}
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div (click)="selectBookingType('OFFICE')">
                      <i
                        class="iconFont officeColor"
                        [ngClass]="[
                          selectedBookingType == 'OFFICE' ? ' fa-check' : 'fa fa-square'
                        ]"
                      ></i
                      >Office
                    </div>
                    <div (click)="selectBookingType('WFH')">
                      <i
                        class="iconFont homeColor"
                        [ngClass]="[
                          selectedBookingType == 'WFH' ? ' fa-check' : 'fa fa-square'
                        ]"
                      ></i
                      >Home
                    </div>
                    <div (click)="selectBookingType('LEAVE')">
                      <i
                        class="iconFont leaveColor"
                        [ngClass]="[
                          selectedBookingType == 'LEAVE' ? ' fa-check' : 'fa fa-square'
                        ]"
                      ></i
                      >Leave
                    </div> -->
                                </div>
                                <p class="semi-bold-blue">
                                    {{"attendanceScheduleText" | translate}}
                                </p>
                            </div>

                            <button
                                class="btn btn-main small-btn float-right pl-4 pr-4 mr-4 pt-1 pb-1"
                                type="button"
                                (click)="myScheduleCreate()"
                                *ngIf="selectedDates.length > 0"
                            >
                               {{"done" | translate}}
                            </button>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="{{'preferences' | translate}}" label="Preferences">
                <ng-template matTabContent>
                    <div class="preferenceScroll">
                        <div class="d-flex">
                            <img
                                src="./assets/images/jumpree_digital/preferences.png"
                                alt="smartenspaces-Logo"
                                class="m-auto w-40"
                            />
                        </div>
                        <p class="semi-bold-blue mt-4" *ngIf="question">
                            {{ question.questionDescrption }}
                        </p>
                        <!-- <p class="semi-bold-blue">
                Office preference will always be visible.
              </p> -->

                        <ng-container>
                            <div
                                class="row col-12 d-flex flex-row ml-0 mb-3 px-0"
                            >
                                <div
                                    *ngFor="
                                        let option of preferencesOptionsanswers;
                                        let i = index
                                    "
                                    class="mr-5"
                                >
                                    <div class="form-check form-check-inline">
                                        <mat-checkbox
                                            class="custom-checkbox-btn ml-0 mr-2"
                                            value="{{ option.id }}"
                                            (change)="getPreferences(option)"
                                            [(ngModel)]="option.checked"
                                            [disabled]="!option.isEditable"
                                        >
                                            {{ getTranslate(option.name) }}</mat-checkbox
                                        >
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <button
                            class="btn btn-main small-btn float-right"
                            type="button"
                            (click)="savePreference()"
                        >
                            {{"done" | translate}}
                        </button>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
