import { Injectable } from '@angular/core';
import { BehaviorSubject,Observable, Subject } from 'rxjs';
@Injectable()
export class DataService {

  constructor() { }

    private obserCheck = new BehaviorSubject(5);    
    oberVal = this.obserCheck.asObservable();

    
    obserChange(zone){
        this.obserCheck.next(zone);
    }
}
