<div class="page-style">
    <div *ngIf="data.from == 'phone'">
        <span *ngIf="data.type == 'edit'">{{'edit' | translate}}</span>
        <span *ngIf="data.type != 'edit'">{{'add' | translate}}</span>
         {{'phoneNumber' | translate}}
         <button id="close_modal" aria-label="Close" class="close" style="float: right;" type="button" 
         (click)="closeModal()">
           <span aria-hidden="true">×</span></button>
        <!-- <i class="fa fa-window-close" style="float:right" aria-hidden="true" 
        (click)="closeModal()"></i> -->
        <form name="form" (ngSubmit)="f.form.valid && addEditPhonenumber()" #f="ngForm" novalidate>
            <div class="form-group">
                <label>&nbsp;</label>
                <input type="text" id="phone_no" class="form-control col-lg-6" name="phone" [(ngModel)]="model.phone" #phone="ngModel" [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }" required placeholder="{{'phoneNumber' | translate}}"/>
                <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                    <div *ngIf="phone.errors.required">{{'phoneNumberRequired' | translate}}</div>
                </div>
            </div>
            <div class="form-group">
                <button class="btn btn-main">{{'submit' | translate}}</button>
            </div>
        </form>
    </div>
    <div *ngIf="data.from == 'email'">
            <span *ngIf="data.type == 'edit'">{{'edit' | translate}}</span>
            <span *ngIf="data.type != 'edit'">{{'add' | translate}}</span> 
            {{'emailAddress' | translate}}
            <button id="close_dismiss" aria-label="Close" class="close" style="float: right;" type="button" 
            (click)="closeModal()">
              <span aria-hidden="true">×</span></button>
            <!-- <i class="fa fa-window-close" style="float:right" aria-hidden="true" 
            (click)="closeModal()"></i> -->
            <form name="form" (ngSubmit)="f.form.valid && addEditEmail()" #f="ngForm" novalidate>
                <div class="form-group">
                    <label>&nbsp;</label>
                    <input id="email_addr" type="text" class="form-control col-lg-6" name="email" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required placeholder="{{'emailAddress' | translate}}"/>
                    <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                        <div *ngIf="email.errors.required">{{'emailAddressRequired' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-main">{{'submit' | translate}}</button>
                </div>
            </form>
    </div>
    <mat-card *ngIf="data.type == 'addEmergency' || data.type == 'editEmergency'">
        <mat-card-title>
          {{data.type == 'addEmergency' ? 'Create an Emergency' : 'Edit an Emergency'}}
          <!-- <i
            class="fa fa-window-close"
            style="float:right"
            aria-hidden="true"
            (click)="closeModal()"
          ></i> -->
          <button id="dismis_modal" aria-label="Close" class="close" style="float: right;" type="button" 
          (click)="closeModal()">
            <span aria-hidden="true">×</span></button>
        </mat-card-title>
        <hr />
        
          <form
        class="form"
        name="form"
        (ngSubmit)="f.form.valid && createEditEmergency()"
        #f="ngForm"
        novalidate
      >
       
        <mat-card-content>
    
          <div class="container">
            <div class="row">
              <div class="col-md-2">
                <div class="image-container">
                    <img name="img" *ngIf="showImage" [src]="url" class="img-responsive  image-content" />
                </div>
                <br />
                <div *ngIf="data.type == 'addEmergency'">
                    <input type="file" id="on_select_file" (change)="onSelectFile($event)" name="eventFile" [(ngModel)]="emergencyDataObj.eventFile" #eventFile="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && eventFile.invalid
                    }"
                    required style="display:none" #file/>
                    <button type="button" id="file_click" class="btn btn-success" (click) ="file.click()" [ngClass]="{
                      'is-invalid-btn': f.submitted && eventFile.invalid
                    }">{{'selectFile' | translate}}</button>
                </div>
                <div *ngIf="data.type == 'editEmergency'">
                    <input type="file" id="file_select" (change)="onSelectFile($event)" name="eventFile" [(ngModel)]="emergencyDataObj.eventFile" #eventFile="ngModel"
                    style="display:none" #file/>
                    <button id="select_file" type="button" class="btn btn-success" (click) ="file.click()">{{'selectFile' | translate}}</button>
                </div>
                <br />
                <span
                  >{{'supportedFormat' | translate}}<br />
                  <h6 class="custom-h6">.JPG, .PNG, .JPEG</h6></span
                >
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">{{'emergencyType' | translate | uppercase}}</label>
                        <mat-select id="emergency_type" name="emergencyType" placeholder="{{'select' | translate}}" class="form-control"  [(ngModel)]="emergencyDataObj.emergencyType" #emergencyType="ngModel" [ngClass]="{
                          'is-invalid': f.submitted && emergencyType.invalid
                        }"
                        required>
                          <mat-option color="primary" *ngFor="let type of emergencyTypes"  [value]="type.id">{{type.emergency}}</mat-option>
                        </mat-select>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">{{'zoneOrLocation' | translate}}</label>
                        <mat-select id="zone_id" name="zoneIds" placeholder="{{'selectLocation' | translate | titlecase}}" class="form-control"  [(ngModel)]="emergencyDataObj.zoneId" #zoneIdss="ngModel" [ngClass]="{
                          'is-invalid': f.submitted && zoneIdss.invalid
                        }"
                        required>
                          <mat-option color="primary" *ngFor="let zone of zoneList"  [value]="zone.id">{{zone.name}}</mat-option>
                        </mat-select>
                      </div>
                  </div>
                  <!-- <div class="col-md-2">
                      <div class="form-group" style="width:100px">
                            <label class="form-label">PHONE NUMBER</label>
                            <mat-select style="width:100px" name="countryCode" placeholder="Select" class="form-control"  [(ngModel)]="emergencyDataObj.countryCode" #countryCode="ngModel" [ngClass]="{
                                'is-invalid': f.submitted && countryCode.invalid
                              }"
                              required>
                                <mat-option color="primary" *ngFor="let code of countryCodes"  [value]="code">{{code}}</mat-option>
                              </mat-select>
                      </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">{{'phoneNumber' | translate | uppercase}}</label>
                        <input id="phn_no" class="form-control" placeholder="{{'phoneNumber' | translate}}" type="text"
                        minlength="10"
                        name="phoneNumber" [(ngModel)]="emergencyDataObj.phone_number" autocomplete="off" #phoneNumber="ngModel"
                        [ngClass]="{
                            'is-invalid': f.submitted && phoneNumber.invalid
                        }"
                        required/>
                        <!-- <div [hidden]="phoneNumber.valid || phoneNumber.pristine"
      class="alert alert-danger">
      <div [hidden]="!phoneNumber.hasError('minlength')">Phone number should be 10 digit</div>
      <div [hidden]="!phoneNumber.hasError('required')">Phone number is required</div>
      <div [hidden]="!phoneNumber.hasError('pattern')">Phone number should be only numbers</div>
   </div> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">{{'sms_content' | translate | uppercase}}</label>
                        <input id="sms_content" class="form-control" placeholder="{{'sms_content' | translate}}" type="text"  name="smsContent" [(ngModel)]="emergencyDataObj.sms_content" autocomplete="off" #smsContent="ngModel"
                        [ngClass]="{
                          'is-invalid': f.submitted && smsContent.invalid
                        }"
                        required/>
                      </div>
                  </div>
                </div>
                <div class="row " >
                  <ng-container *ngFor="let detailObj of emergencyDetailsObj; let i = index">
                      <div class="col-md-4" >
                          <div class="form-group">
                            <label class="form-label">{{'type' | translate | uppercase}} {{i+1}}</label>
                            <mat-select id="type_{{i}}" name="type_{{i}}" placeholder="{{'select' | translate}}" class="form-control"  [(ngModel)]="detailObj.type" >
                                <mat-option color="primary" *ngFor="let type of types"  [value]="type">{{type}}</mat-option>
                            </mat-select>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">{{'contact' | translate}}</label>
                            <!-- <input *ngIf="detailObj.type != 'EMAIL' && detailObj.type != 'SMS'" class="form-control" placeholder="Contact Email"  type="email" name="content_{{i}}" [(ngModel)]="detailObj.contact" autocomplete="off" #email="ngModel" required [ngClass]="{
                                'is-invalid': f.submitted && email.untouched && email.invalid 
                              }"/> -->
                            <input id="contact_email" *ngIf="detailObj.type == 'EMAIL'" class="form-control" placeholder="{{'contactEmail' | translate | titlecase}}" type="email" name="content_{{i}}" [(ngModel)]="detailObj.contact" autocomplete="off" #email="ngModel" required [ngClass]="{
                                'is-invalid': f.submitted && email.untouched && email.invalid  
                              }" />
                            <input id="contact_sms" *ngIf="detailObj.type == 'SMS'" class="form-control" placeholder="{{'contactNumber' | translate | titlecase}}" type="text" name="content_{{i}}" [(ngModel)]="detailObj.contact" autocomplete="off"  #phone="ngModel"  [ngClass]="{
                                'is-invalid': f.submitted &&  phone.invalid 
                              }"
                              minlength="10"required/>
                            <!-- <div   [hidden]="phone.valid || phone.pristine"
                            class="alert alert-danger">
                                <div [hidden]="!phone.hasError('minlength')">Phone number should be 10 digit</div>
                                <div [hidden]="!phone.hasError('required')">Phone number is required</div>
                                <div [hidden]="!phone.hasError('pattern')">Phone number should be only numbers</div>
                             </div> -->
                          </div>
                      </div>
                      <div class="col-md-2 text-right">
                        <a href="javascript:void(0)" id="remove_detail_obj" (click)="removeDetailObj(i)"><i class="fa fa-2x fa-times-circle remove-circle"></i></a>
                      </div>
                      
                  </ng-container>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <a href="javascript:void(0)" id="add_detail_obj" (click)="addDetailObj()"><i class="fa fa-plus"></i> {{'addAnotherLine' | translate | uppercase}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
        <hr/>
        <mat-card-footer>
            <div style="margin:15px">
                <button id="cancel_btn" class="pull-left btn btn-main" type="button" (click)="closeModal()">Cancel</button>
                <button id="save_btn" class="pull-right btn  btn-main" type="submit">Save</button>
            </div>
        </mat-card-footer>
    </form>
        
      </mat-card>
      <mat-card *ngIf="data.type === 'addEmergencyUser'">
          <mat-card-title>
            <div style="margin-bottom:5px;">
              <div class="title-container">
                <span>{{'addUser' | translate}}</span>
                <button id="modal_close" aria-label="Close" class="close" style="float: right;" type="button" 
                (click)="closeModal()">
                  <span aria-hidden="true">×</span></button>
                <!-- <span><i
                  class="fa fa-window-close"
                  style="float:right"
                  aria-hidden="true"
                  (click)="closeModal()"
                ></i></span> -->
              </div>
            </div>
            </mat-card-title>
            <hr />
        <mat-card-content>
            <form class="form" name="form" (ngSubmit)="frm.form.valid && createEmergencyUser(frm.form.valid, frm.form.value )" #frm="ngForm" novalidate>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label><b>{{'name' | translate | uppercase}}</b></label>
                    <div class="input-group mb-3" *ngIf="!showSearch">
                        <div class="input-group-prepend">
                          <i class="fa fa-search input-group-text" id="basic-addon1" (click)="openSearchUser()"></i>
                        </div>
                        <input id="user_name" name="name" class="form-control" [(ngModel)]="createEmeUserObj.name" #nme="ngModel" required autocomplete="off" [ngClass]="{ 'is-invalid': frm.submitted && nme.invalid }"  [readonly]="isFormReadonly"/>
                      </div>
                    <mat-form-field *ngIf="showSearch">
                        <mat-select id="show_search_user" name="name" [formControl]="bankServerSideCtrl" placeholder="{{'SearchUser' | translate}}"   [(ngModel)]="createEmeUserObj.name">
                          <!-- place ngx-mat-select-search inside mat-option to allow opening without initial selection -->
                          <mat-option>
                            <ngx-mat-select-search  placeholderLabel="Search User" noEntriesFoundLabel="'no matching found'"  [formControl]="bankServerSideFilteringCtrl" [searching]="searching">
                                <!-- <i class="fa fa-plus fa-2x" (click)="addNewUser()"></i> -->
                                <mat-icon ngxMatSelectSearchClear (click)="addNewUser()">{{'addCircle' | translate}}</mat-icon>
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let user of filteredServerSideBanks | async" [value]="user.userId"   (click)="onClickEmsUserCheckbox(user)">
                            {{user.name +' - '+ user.email}}
                          </mat-option>
                          <button id="load_more_btn" *ngIf="showLoadMore" value="loadmore" style="font-size: 16px;width: 100%;text-align: left;"  (click)="getNextRecords()">{{'loadMore' | translate}}...</button>
                        </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="form-group">
                    <label><b>{{'email' | translate}}</b></label>
                    <input id="email_address" name="email" class="form-control" type="email" [(ngModel)]="createEmeUserObj.email" #emil="ngModel" required autocomplete="off" [ngClass]="{ 'is-invalid': frm.submitted && emil.invalid }"  [readonly]="isFormReadonly" [email]="true"/>
                  </div>
                  <div class="form-group">
                    <label><b>{{'phoneNumber' | translate | uppercase}}</b></label>
                    <div class="form-itemcontainer">
                      <select id="phn_no_input" name="code" class="form-control col-lg-3" [(ngModel)]="createEmeUserObj.countryCode" #code="ngModel" required [ngClass]="{ 'is-invalid': frm.submitted && code.invalid }" [disabled]="isFormReadonly">
                        <option *ngFor="let code of countryCodes;" [value]="code.countryCode">{{code.countryCode}}</option>
                      </select>
                      <label class="col-lg-1">&nbsp;</label>
                      <input type="number" id="mob_no" [max]="15" name="phoneNumber" class="form-control col-lg-8" [(ngModel)]="createEmeUserObj.mobile" #phnNumber="ngModel" required autocomplete="off" [ngClass]="{ 'is-invalid': frm.submitted && phnNumber.invalid }" [readonly]="isFormReadonly" maxlength="15"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 frm-btn-container">
                  <button type="button" id="cancel_button" (click)="closeModal()" class="btn btn-outline-secondary">{{'cancel' | translate}}</button>
                  <button type="submit" id="submit_button" class="btn btn-main">{{'add' | translate}}</button>
                </div>
              </div>
            </form>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="data.type === 'viewEmergencyUserList'">
          <mat-card-title>
            <div style="margin-bottom:5px;">
              <div class="title-container">
                <h4 class="modal-title">{{emergencyData.emergency_type['emergency']}}</h4>
                <button aria-label="Close" id="close_modal_btn" class="close" style="float: right;" type="button" (click)="closeModal()">
                  <span aria-hidden="true">×</span></button>
                <!-- <span><i
                  class="fa fa-window-close"
                  style="float:right"
                  aria-hidden="true"
                  (click)="closeModal()"
                ></i></span> -->
              </div>
            </div>
            </mat-card-title>
            <hr />
        <mat-card-content>
          <div class="team-list-container table-responsive" style="padding: 10px;">
            <div class="list">
                <table class="tbl table table-border" style="width: 100%;">
                    <tbody style="height: 220px;overflow-y: auto;">
                        <tr  style="text-align:'center';vertical-align:'middle';"  *ngFor="let emergencyUser of emergencyData['config_details'] ; let i = index">
                            <td >{{i+1}}</td>
                            <td>{{emergencyUser.name}} <i *ngIf="emergencyUser.is_call_enabled" class="fa fa-phone" aria-hidden="true"></i></td>
                            <td>{{emergencyUser.phone}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
</div>