import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalLoaderComponent } from './modal-loader.component';

@NgModule({
    imports: [CommonModule, RouterModule,NgxLoadingModule],
    declarations: [ModalLoaderComponent],
    exports: [ModalLoaderComponent]
})
export class ModalLoaderModule {}
