import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CommonService } from 'commonService';
import { LoaderService } from 'loaderService';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DigitalBookingProService } from '../digital-booking-pro.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-booking-pro-modal',
  templateUrl: './booking-pro-modal.component.html',
  styleUrls: ['./booking-pro-modal.component.scss']
})
export class BookingProModalComponent implements OnInit {
  pageType: any;
  globalAdmin;
  addSpaces: FormGroup;
  addCategory: FormGroup;
  addCategoryList = [];
  addaddOns: FormGroup;
  addaddOnsList = [];
  addspecialrequest: FormGroup;
  addspecialrequestList = [];
  editSpaces: FormGroup;
  submittedSpaces: boolean;
  submittedCateogry:boolean;
  submittedaddaddOns:boolean;
  submittedaddspecialrequest:boolean;
  spaces=[];
  spaceTypeData = [];
  spaceTypeId = '';
  showSpaceName = false;
  spaceArray = [];
  private unsubscribe$ = new Subject<void>();
  currentZone;
  dialogData: any;
  public fileToUpload: File;
  public fileToUploadApp: File;
  public appImagepathImage:any;
  public dashboardImagepathImage:any;
  public fileToUploadDashboard: File;
  public editfileToUpload: File;
  appFileName = "";
  dashboardFileName = "";
  upload_appUrl  = false;
  upload_appDashboardUrl = false; 
  formData: FormData = new FormData();
  addOnName;
  spaceNameTitle;
  specialReqName;
  capacityName;
  upload_csv_excel;
  categoryList = [];
  entityList = [];
  capacityList = [];
  currentAmenityId;
  spaceTypeAddEdit = "ADD";
  amenity = { amenityName: '', upload_appUrl: '', upload_dashboardUrl: '', config: '', id: "" };
  addcategory;
  selectedCategory;
  edit_name = '';
  showCategoryError = false;
  showAddonError = false;
  showSpecialReq = false;
  add_name ='';
  editAdding;
  editAddingSubmit:boolean;
  editSaveList = [];
  editErrorMsg = "Category";
  addTitle = "Categories";
  itemsList = [];
  constructor(
     public dialogRef: MatDialogRef<BookingProModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private digitalBookingProService: DigitalBookingProService,
    private commonService: CommonService,
    private loaderService: LoaderService, private translate: TranslateService, private toastrService: ToastrService,) { 
      this.commonService.currentZone
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((res) => {
        this.currentZone = res;
      });
    }

  ngOnInit() {
    this.globalAdmin=localStorage.getItem("ssadmin_globalAdmin");
    this.pageType = this.data['type'];
    if(this.pageType=="addSpaces"){
        this.addSpaces = this.formBuilder.group({
          spaceType: ['', Validators.required],
          spaceName: ['', [Validators.required, Validators.maxLength(30)]],
        });
      this.spaceArray = this.data['spaceArray']; 
      console.log("this.spaceArray",this.spaceArray); 
    }
  if(this.pageType=="editSpaces"){
      this.spaceTypeAddEdit = "EDIT"
      this.dialogData=this.data['data'];
      this.spaceArray = this.data['spaceArray']; 
      this.appImagepathImage = this.dialogData['appImageUrl'];
      this.dashboardImagepathImage= this.dialogData['dashboardImageUrl'];
      // this.appFileName=this.dialogData['appImageUrl'];
      // this.dashboardFileName = this.dialogData['dashboardImageUrl'];
      this.addCategoryList = this.dialogData['entityTypeList'] == null?[]:this.dialogData['entityTypeList'];
      this.addaddOnsList = this.dialogData['amenityListAddons'];
      this.addspecialrequestList = this.dialogData['amenityListSpecialRequest'];
      this.spaceTypeData = this.dialogData;
      this.spaceTypeId =this.dialogData['id'];
      this.spaceNameTitle = this.dialogData['title'];
      this.editSpaces = this.formBuilder.group({
        spaceType: [this.dialogData['amenityType'], Validators.required],
        spaceName: [this.dialogData['title'], Validators.required],
         });
      if(this.data['activate'] == "activate"){
        this.pageType = "uploadImage";
      }

    
  }
  if(this.pageType == "addCategory") {
    console.log(this.data['itemsList']);
    this.itemsList = this.data['itemsList'];
    if(this.data['inputType'] == 'addOns'){
        this.editErrorMsg = "Add-ons";
        this.addTitle = "Add-ons";
    }else if(this.data['inputType'] == "specialRequest"){
        this.editErrorMsg = "Special Request";
        this.addTitle="Special Request";
    }
    this.editAdding = this.formBuilder.group({
      editAdding: ['', Validators.required],
    });
    
    //this.add_name = this.data['itemData']['name'];
  }
  if(this.pageType == "edit_category") {
    this.itemsList = this.data['itemsList'];
    if(this.data['inputType']=='title')
    {
      this.edit_name = this.data['itemData']['title'];
    }
    else{
      this.edit_name = this.data['itemData']['name'];
    }
  }
  }
  getTranslate(word) {
    let translatedWord = word;
    const test = this.translate.get([word]).subscribe(translation => {
      translatedWord = translation;
    });
    return translatedWord[word];
  }
  closePopup(){
    this.dialogRef.close(this.pageType);
  }
  saveSpaces(submitType){
    this.submittedSpaces = true;
  let space = this.addSpaces.get('spaceName').value.trim();
  if(space==""){
    this.addSpaces.get('spaceName').setErrors({ 'required': true });
  }
    if (this.addSpaces.invalid){    
    //  this.addSpaces.get('spaceName').setErrors({'incorrect': true});
      return false;
    }
    else{
      this.spaceNameTitle = this.addSpaces.get('spaceName').value;
      this.pageType = "uploadImage";
     
    }
  }
  getSpaceName(){ 
    this.submittedSpaces=true;   
    if(this.addSpaces.get('spaceName').value!="")
    {
      this.showSpaceName=true;      
      // this.spaces.push(this.addSpaces.get('spaceName').value);
      this.saveSpaces('ADDMORE');  
    }  
     
  }
  confirmDeletePopup(){
    this.pageType="showDeleteAlert";
  }

  deleteSpace() {

      let data :any = {};
      data.name = this.data['data']['title'];
      data.amenityType = this.data['data']['amenityType'];
      data.id = this.data['data']['id'];
      data.appImageUrl = this.data['data']['appImageUrl'];
      data.dashboardImageUrl = this.data['data']['dashboardImageUrl'];
      data.iconUrl = this.data['data']['cardLogoUrl'];
      data.status = "DISABLED";
    
    this.formData.append("model", JSON.stringify(data));
     const currentuserModule$ = this.digitalBookingProService.postDigitalBookingPro(5, this.formData);
     this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
       let resp = res['response'];
       this.dialogRef.close();
       this.toaster.success(res['message']);
   }, err => {
       //this.commonService.openSnackBar(err.error.message, "");
   })
  
    // let spaces = this.digitalBookingProService.deleteSpace(this.dialogData['id']);
    // this.loaderService.showLoaderUntilCompleted(spaces).subscribe(
    //   (res) => {
    //     this.toaster.success(res['message']);
    //     this.closePopup();
    //   },
    //   (error) => {
    //     console.log('No load');
    //   }
    // );
  }

  removeSpaces(val) {
    const index = val;
    if (index > -1) { // only splice array when item is found
      this.spaces.splice(index, 1); // 2nd parameter means remove one item only
    }
  }
  saveEditSpaces(){
    this.submittedSpaces = true;
    if(this.editSpaces.invalid){     
      return false;
    }
    else{
      let editObj={
      id: this.dialogData['id'],
      name:this.editSpaces.get('spaceName').value,
      amenityType : this.editSpaces.get('spaceType').value      
      }
      this.spaceNameTitle = this.editSpaces.get('spaceName').value;
      this.pageType = "uploadImage";
    //   let spaces = this.digitalBookingProService.editSpaces(editObj);
    //   this.loaderService.showLoaderUntilCompleted(spaces).subscribe(
    //   (res) => {
    //     this.toaster.success(res['message']);
    //     //this.closePopup();
    //     this.pageType = "uploadImage";
    //   },
    //   (error) => {
    //     console.log('No load');
    //   }
    // );
    }

  }

  onChooseFileApp(files: FileList, event: { target: HTMLInputElement }) {
    var fileName = event.target.value;
    var fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    fileType = fileType.toLowerCase();
    if (
      fileType !== "png" &&
      fileType !== "jpg" &&
      fileType !== "jpeg"
    ) {
      this.appImagepathImage = "";
      this.amenity.upload_appUrl = "";
      this.toastrService.error(this.translate.instant("notValidFile"));
      return;
    }
    // if (event.target.files[0].size > 1130 * 496) {
    //   this.toastrService.error("Height and Width must not exceed 1130*496");
    //   return;
    // }
    //this.dashboardImagepathImage = event.target.files[0];
   this.upload_appUrl = false;
    var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		
    reader.onload = (event:any) => {
      const img = new Image();

      // Set the image source to the loaded data
      img.src = event.target.result;

      // When the image is loaded, get its width and height
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        console.log(`Width: ${width}, Height: ${height}`);
       // if (event.target.files[0].size > 160947) {
        if(width > 1130 && height > 496 ){
          this.toastrService.error(this.translate.instant("heightAndWidhMustNotExceed") );
          return;
        }else{
          this.appImagepathImage = reader.result; 
          this.fileToUploadApp = files.item(0);
          this.appFileName = this.fileToUploadApp.name;
        }
      };
			
		}
		// reader.onload = (_event) => {
		// 	this.appImagepathImage = reader.result; 
		// }
    
    //this.formData.append("appFile", this.fileToUploadApp, this.fileToUploadApp.name);
    

  }

  onChooseFileDashboard(files: FileList, event: { target: HTMLInputElement }) {
    var fileName = event.target.value;
    var fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    fileType = fileType.toLowerCase();
    if (
      fileType !== "png" &&
      fileType !== "jpg" &&
      fileType !== "jpeg"
    ) {
      this.amenity.upload_dashboardUrl = "";
      this.dashboardImagepathImage = "";
      this.toastrService.error(this.translate.instant("notValidFile"));
      return;
    }
    // if (event.target.files[0].size > 440 * 225) {
    //   this.toastrService.error("Height and Width must not exceed 440*225");
    //   return;
    // }
     //this.dashboardImagepathImage = event.target.files[0];
    this.upload_appDashboardUrl = false;
     var reader = new FileReader();
     reader.readAsDataURL(event.target.files[0]);
     reader.onload = (event: any) => {
      const img = new Image();

      // Set the image source to the loaded data
      img.src = event.target.result;

      // When the image is loaded, get its width and height
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        console.log(`Width: ${width}, Height: ${height}`);
       // if (event.target.files[0].size > 160947) {
        if(width > 440 && height > 225 ){
          this.toastrService.error(this.translate.instant("heightAndWidhMustNotExceed440"));
          return;
        }else{
          this.dashboardImagepathImage = reader.result; 
          this.fileToUploadDashboard = files.item(0);
          this.dashboardFileName = this.fileToUploadDashboard.name;
        }
      };
       
     }
    //  reader.onload = (_event) => {
    //    this.dashboardImagepathImage = reader.result; 
    //  }
    
    //this.formData.append("dashboardFile", this.fileToUploadDashboard, this.fileToUploadDashboard.name);

  }

  onSubmitAmenitiesBookable() {
    if(this.amenity.upload_appUrl == '' && this.spaceTypeAddEdit == "ADD"){
      this.upload_appUrl  = true;  
    }else if(this.amenity.upload_dashboardUrl == ''  && this.spaceTypeAddEdit == "ADD"){ 
      this.upload_appUrl  = false;
      this.upload_appDashboardUrl = true; 
    }else{
      this.upload_appUrl  = false; 
      this.upload_appDashboardUrl = false; 
      this.addCategory = this.formBuilder.group({
        addCategory: ['', Validators.required],
      });
      this.addaddOns = this.formBuilder.group({
        addaddOns: ['', Validators.required],
        });
        this.addspecialrequest = this.formBuilder.group({
          addspecialrequest: ['', Validators.required],
      });
       // this.pageType="showSpacesAdded";
       this.formData = new FormData();
      let data :any = {};
      if(this.spaceTypeAddEdit == "ADD"){
        if(this.spaceTypeId != "") {
          data.id = this.spaceTypeId; //This for going back and front creating new amenity so we added this
        }
        data.name = this.addSpaces.value.spaceName,
        data.amenityType = this.addSpaces.value.spaceType
      }else{
        data.name = this.editSpaces.value.spaceName,
        data.amenityType = this.editSpaces.value.spaceType
      }
      if(this.spaceTypeAddEdit == "ADD"){
      this.formData.append("model", JSON.stringify(data));
      if(this.fileToUploadApp != undefined){
        this.formData.append("appFile", this.fileToUploadApp, this.fileToUploadApp.name);
      }
      if(this.fileToUploadDashboard != undefined){
        this.formData.append("dashboardFile", this.fileToUploadDashboard, this.fileToUploadDashboard.name);
      }
      

       const currentuserModule$ = this.digitalBookingProService.postDigitalBookingPro(this.currentZone, this.formData);
       this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
         let resp = res['response'];
         if(!!resp) {
           if(this.spaceTypeAddEdit == "ADD"){
            this.spaces.push(this.addSpaces.get('spaceName').value);
            //this.addSpaces.get('spaceName').reset();
          }else{
             this.spaces.push(this.editSpaces.get('spaceName').value);
             this.editSpaces.get('spaceName').reset();
          }
             
             this.submittedSpaces=false;
             this.spaceTypeData = resp;
             this.spaceTypeId =resp.id
             //this.pageType = "uploadImage";
             this.pageType = 'parking_category';
             this.toastrService.success(res['message']);
         }
     }, err => {
         this.toastrService.error(err.error.message);
     })
    } else{
      //need to write update api here
      //this.spaceTypeData = resp;
      //this.spaceTypeId =resp.id
      
        let data :any = {};
        data.name = this.spaceTypeData['title'];
        data.amenityType = this.spaceTypeData['amenityType'];
        data.id = this.spaceTypeData['id'];
        data.appImageUrl = this.spaceTypeData['appImageUrl'];
        data.dashboardImageUrl = this.spaceTypeData['dashboardImageUrl'];
        data.iconUrl = this.spaceTypeData['cardLogoUrl'];
      
        this.formData.append("model", JSON.stringify(data));
        if(this.fileToUploadApp != undefined){
          this.formData.append("appFile", this.fileToUploadApp, this.fileToUploadApp.name);
        }
        if(this.fileToUploadDashboard != undefined){
          this.formData.append("dashboardFile", this.fileToUploadDashboard, this.fileToUploadDashboard.name);
        }
        const currentuserModule$ = this.digitalBookingProService.postDigitalBookingPro(this.currentZone, this.formData);
        this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
          this.pageType = 'parking_category';
        }, err => {
          this.toastrService.error(err.error.message);
      })

      
    }
      
    }

  }

  getAddCategory(){ 
    this.submittedCateogry = true; 
    let saveCategoryVal =  this.addCategory.get('addCategory').value.trim()
    if(saveCategoryVal == ''){
      this.addCategory.get('addCategory').reset();
    }  
    if(this.addCategory.valid){
      this.showSpaceName = true;      
      // this.saveCategory('ADDMORE');  
      let name = this.addCategory.get('addCategory').value.trim();
      let selectedCategories = this.addCategoryList.map(res=>res.name.toLowerCase());
      if(!selectedCategories.includes(name.toLowerCase())){
        let addcategoryObj = {
          "name": name ,
          "globalCategory": {
              "id": this.spaceTypeId
          }
        }
        this.addCategoryList.push(addcategoryObj);
        this.addCategory.get('addCategory').reset();
        this.submittedCateogry=false;
      }else{
        this.toastrService.error(this.translate.instant("categoryWith") + " " + name + " " + this.translate.instant("alreadyExists"));
        return false;
      }
      return true;
    }  else {
      return false;
    }
     
  }

  saveCategory(){
    //this.submittedCateogry = true;
      const currentuserModule$ = this.digitalBookingProService.createcategoryNew(this.addCategoryList,this.spaceTypeId);
      this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
        let resp = res['response'];
        if(!!resp) {
          resp.forEach(result=>{
            this.addCategoryList.forEach(element => {
              if(element['name'] == result['name']){
                element["id"] = result['id'];
              } 
            });
          })
            //this.addCategory.get('addCategory').reset();
            this.pageType="addons";
        }
        }, err => {
          this.toastrService.error(err.error.message, "");
        })
  }
  removeCategory(val,indexVal) {
    let index = val.name;
    if(!!val && !!val.id){
      this.addCategoryList = this.addCategoryList.filter((elm,i)=>i !=indexVal)
    }else{
      this.addCategoryList = this.addCategoryList.filter((elm,i)=>i != indexVal
      );
    }
  }


  getAddAddOns(){ 
    this.submittedaddaddOns=true; 
    let saveAddonsVal =  this.addaddOns.get('addaddOns').value.trim()
    if(saveAddonsVal == ''){
      this.addaddOns.get('addaddOns').reset();
    }  
    if(this.addaddOns.valid){
      this.showSpaceName=true;      
      let name = this.addaddOns.get('addaddOns').value.trim();
      let selectedAddOnList = this.addaddOnsList.map(res=>res.name.toLowerCase());
      if(!selectedAddOnList.includes(name.toLowerCase())){  
        let adaOnsObj = {
          "name": name,
          "specialRequest": false, // special requests
          "globalCategory": {
              "id": this.spaceTypeId
          },
          "status":"ENABLED"
        }
        this.addaddOnsList.push(adaOnsObj);
        this.addaddOns.get('addaddOns').reset();
        this.submittedaddaddOns=false;
      }else{
        this.toastrService.error(this.translate.instant("addonWith") + " " + name +" "+this.translate.instant("alreadyExists"));
        return false;
      }
      return true;
    } else {
      return false;
    } 
  }

  saveAddOns(){
    //this.submittedaddaddOns = true;
    //this.showSpaceName = false;
       
        //this.addaddOnsList = this.addaddOnsList.filter(ele=>ele.id == undefined);
        const currentuserModule$ = this.digitalBookingProService.addonsAndSpecialRequestNew(this.addaddOnsList,this.spaceTypeId,false);
        this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
          let resp = res['response'];
          if(!!resp) {

            resp.forEach(result=>{
              this.addaddOnsList.forEach(element => {
                if(element['name'] == result['name']){
                  element["id"] = result['id'];
                } 
              });
            })
            console.log(this.addaddOnsList);
            this.addaddOns.get('addaddOns').reset();
              this.pageType="special_request";  
          }
        }, err => {
          this.toastrService.error(err.error.message, "");
        })
      
  }
  removeAddOns(val,indexVal) {
    const index = val.name;
    if(!!val && !!val.id){
      this.addaddOnsList = this.addaddOnsList.filter((elm,i)=>i !=indexVal )
    }else{
      this.addaddOnsList = this.addaddOnsList.filter((elm,i)=>i !=indexVal )
    }
  }

  getAddSpecialRequest(type){ 
      this.submittedaddspecialrequest=true;
      let saveSpecialVal =  this.addspecialrequest.get('addspecialrequest').value.trim()
      if(saveSpecialVal == ''){
        this.addspecialrequest.get('addspecialrequest').reset();
      }   
      if(this.addspecialrequest.valid){    
        let name = this.addspecialrequest.get('addspecialrequest').value.trim();
        let selectedSpecialRequestList = this.addspecialrequestList.map(res=>res.name.toLowerCase());
        if(!selectedSpecialRequestList.includes(name.toLowerCase())){  

        let addSpecialRequest = {
          "name": name,
          "specialRequest": true, // special requests
          "globalCategory": {
              "id": this.spaceTypeId
          },
          "status":"ENABLED"
        }
        this.addspecialrequestList.push(addSpecialRequest);
        this.addspecialrequest.get('addspecialrequest').reset();
        this.submittedaddspecialrequest=false;
      }else{
          this.toastrService.error(this.translate.instant("speciaRequestWith") + " " + name + " " + this.translate.instant("alreadyExists"));
        return false;
      }
      return true;  
    } else {
      return false;
    }
     
  }

  saveAddSpecialRequest(){
    //this.submittedaddspecialrequest = true;
    let messageSuc = this.pageType == "editSpaces" ? this.translate.instant("activatedSmall") : this.translate.instant("added");       
      
     
        const currentuserModule$ = this.digitalBookingProService.addonsAndSpecialRequestNew(this.addspecialrequestList,this.spaceTypeId,true);
          this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
            let resp = res['response'];
            if(!!resp) {
              this.toastrService.success(this.translate.instant("amenity") + " " + messageSuc + " " + this.translate.instant("successfully") );
              this.closePopup();
            }
        }, err => {
          this.toastrService.error(err.error.message, "");
        })
    
  }
  removeAddSpecialRequest(val,indexVal) {
    const index = val.name;
    if(!!val && !!val.id){
      this.addspecialrequestList = this.addspecialrequestList.filter((elm,i)=>i !=indexVal )
    }else{
      this.addspecialrequestList =  this.addspecialrequestList.filter((elm,i)=>i !=indexVal )
    }
    
  }

  // skipCategory(type){
  //     if(type =='category'){
  //       this.saveCategory();
  //       this.pageType="addons"; 

  //     }else if(type=='addons'){
  //       this.saveAddOns();
  //       this.pageType="special_request";
  //     }else if(type=='special'){
  //       this.saveAddSpecialRequest()
  //       this.dialogRef.close();
  //     }
  // }
  



  editCategorySubmit(val){
    let sameName=false; 
    this.edit_name=this.edit_name.trim();
    if(this.itemsList.length>0) {
      this.itemsList.forEach(element => {
        element.name=element.name.trim();
        if(element.name.toLowerCase()==this.edit_name.toLowerCase() && element.name != this.data['itemData']['name'].trim())
        {
          sameName = true;
          return;
        }
      });
    }
     
    if(sameName)
    {
      this.toastrService.error(this.edit_name + " " + this.translate.instant("alreadyPresent"));
        return;
    }
    if(this.edit_name.trim()==""){
      this.showCategoryError=true;
      return;
    }
    if(this.data['inputType'] == 'category'){
      let addcategoryObj = [{
        "id":this.data['itemData']['id'],
        "name": this.edit_name,
        "globalCategory": {
            "id": this.data['itemData']['globalCategory']['id']
        }
      }]
      const currentuserModule$ = this.digitalBookingProService.createcategoryUpdate(addcategoryObj[0]);
          this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
            let resp = [];
            resp[0] = res['response'];
            resp['inputType'] = this.data['inputType'];
            resp['edit'] = "edit";
            if(!!resp) {
              this.dialogRef.close(resp);
              this.toastrService.success(this.translate.instant('categoryUpdatedSuccessfully'));
            }
            }, err => {
              this.toastrService.error(err.error.message, "");
            })


    }else if(this.data['inputType'] == 'title'){
      let addcategoryObj = [this.data['itemData']];
      addcategoryObj[0]['name'] = this.edit_name;
      if(this.edit_name.trim().length>30){
        this.toaster.error(this.translate.instant("theInputExceedsTheMaximumLength"));
        return;  
      }
      const currentuserModule$ = this.digitalBookingProService.createTitleUpdate(addcategoryObj[0]);
          this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
            let resp = [];
            resp[0] = res['response'];
            resp['inputType'] = this.data['inputType'];
            resp['edit'] = "edit";
            if(!!resp) {
              this.dialogRef.close(resp);
            }
            }, err => {
              this.toastrService.error(err.error.message, "");
            })
    }
    else{
      let addSpecialRequest = [{
        "id":this.data['itemData']['id'],
        "name": this.edit_name,
        "specialRequest": this.data['inputType'] == 'specialRequest'?true:false, // special requests
        "globalCategory": {
            "id": this.data['itemData']['globalCategory']['id']
        }
      }]
      const currentuserModule$ = this.digitalBookingProService.addonsAndSpecialRequestUpdate(addSpecialRequest[0]);
              this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
                let resp = [];
                resp[0] = res['response'];
                resp['inputType'] = this.data['inputType'];
                resp['edit'] = "edit";
                if(!!resp) {
                  this.dialogRef.close(resp);
                  if (resp['inputType']=='addOns'){
                    this.toastrService.success(this.translate.instant('addonsUpdatedSuccessfully'));
                  }
                  else if (resp['inputType'] == 'specialRequest'){
                    this.toastrService.success(this.translate.instant('specialRequestUpdatedSuccessfully'));
                  }
                }
            }, err => {
              this.toastrService.error(err.error.message, "");
            })
    } 
  }

  editgetAddSpecialRequest(add_name){
    this.editAddingSubmit=true;
    let saveSpecialVal =  this.editAdding.get('editAdding').value.trim()
    if(saveSpecialVal == ''){
      this.editAdding.get('editAdding').reset();
      return;
    }
    if(this.editAdding.get('editAdding').value!="")
    {
      let sameName=false;
      this.itemsList.forEach(element => {
        if(element.name.toLowerCase()==saveSpecialVal.toLowerCase())
        {
          sameName = true;
          return;
        }
      });
      
      if(sameName)
      {
        this.toastrService.error(saveSpecialVal + " " + this.translate.instant("alreadyPresent"));
      }
      else{
      let nameExist = false;
       this.editSaveList.forEach(element => {
        if(element.name.toLowerCase()==saveSpecialVal.toLowerCase())
        {
          nameExist = true;
          return;
        }
      });
      if(nameExist)
      {
        this.toastrService.error(saveSpecialVal + " " + this.translate.instant("alreadyAdded"));
      }
      else
      {
      this.editSaveAddSpecialRequest(add_name);  
      }
      }
    }  
     
  }

  editSaveAddSpecialRequest(val){
    this.editAddingSubmit = true;
    let nameExist = false;
    if(this.editAdding.invalid && this.editSaveList.length == 0 ){     
      return false;
    }
      if(val == 'ADD'){
        if (!!this.editAdding.get('editAdding').value) {
          let saveSpecialVal = this.editAdding.get('editAdding').value.trim();
          if(saveSpecialVal == ''){
            this.editAdding.get('editAdding').reset();
            return;
          }
          this.editSaveList.forEach(element => {
            if (element.name.toLowerCase() == saveSpecialVal.toLowerCase()) {
              nameExist = true;
              return;
            }
          });
          this.itemsList.forEach(element => {
            if (element.name.toLowerCase() == saveSpecialVal.toLowerCase()) {
              nameExist = true;
              return;
            }
          });
          if (this.editAdding.invalid && this.editSaveList.length == 0) {
            return false;
          }
          else if (nameExist) {
            this.toastrService.error(saveSpecialVal +" "+this.translate.instant("alreadyAdded"));
            return;
          }
        }
        if(this.data['inputType'] == 'category'){ 
          if(!!this.editAdding.get('editAdding').value){
          let addcategoryObj = {
            "name": this.editAdding.get('editAdding').value,
            "globalCategory": {
                "id": this.data['globalEntityCategory']['id']
            }
          }
          this.editSaveList.push(addcategoryObj);
        }
          const currentuserModule$ = this.digitalBookingProService.createcategory(this.editSaveList);
              this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
                let resp = res['response'];
                resp['inputType'] = this.data['inputType'];
                resp['edit'] = "add";
                if(!!resp) {
                  this.dialogRef.close(resp);
                  if (resp['inputType']=='category'){
                    this.toastrService.success(this.translate.instant('categoryCreatedSuccessfully'));
                  }
                }
                }, err => {
                  this.toastrService.error(err.error.message, "");
                })


        }else{
          if(!!this.editAdding.get('editAdding').value){
          let addSpecialRequest = {
            "name": this.editAdding.get('editAdding').value,
            "specialRequest": this.data['inputType'] == 'specialRequest'?true:false, // special requests
            "globalCategory": {
                "id": this.data['globalEntityCategory']['id']
            },
            "status":"ENABLED"
          }
          this.editSaveList.push(addSpecialRequest);
          }
          const currentuserModule$ = this.digitalBookingProService.addonsAndSpecialRequest(this.editSaveList);
                this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
                  let resp = res['response'];
                  resp['inputType'] = this.data['inputType'];
                  resp['edit'] = "add";
                  if(!!resp) {
                    this.dialogRef.close(resp);
                     if (resp['inputType'] == 'addOns') {
                       this.toastrService.success(this.translate.instant('addOnsCreatedSuccessfully'));
                    } else if (resp['inputType'] == 'specialRequest') {
                       this.toastrService.success(this.translate.instant('specialRequestCreatedSuccessfully') );
                    }
                  }
              }, err => {
                this.toastrService.error(err.error.message, "");
              })
        }

      }else{
        if(this.data['inputType'] == 'category'){
         
          let addcategoryObj = {
            "name": this.editAdding.get('editAdding').value,
            "globalCategory": {
                "id": this.data['globalEntityCategory']['id']
            }
          }
         
          this.editSaveList.push(addcategoryObj);
          this.editAdding.get('editAdding').reset();
          this.editAddingSubmit=false; 
          
        }else{
          let addSpecialRequest = {
            "name": this.editAdding.get('editAdding').value,
            "specialRequest": this.data['inputType'] == 'specialRequest'?true:false, // special requests
            "globalCategory": {
                "id": this.data['globalEntityCategory']['id']
            },
            "status":"ENABLED"
          }
          
          this.editSaveList.push(addSpecialRequest)
          this.editAdding.get('editAdding').reset();
          this.editAddingSubmit=false; 
        }
      }
   }
   
   editRemoveAddSpecialRequest(val){
    
    const index = val.name;
    this.editSaveList =  this.editSaveList.filter((elm)=>elm.name != index )
  }
  backBtn(type){
    if(type =='parking_category'){
      this.pageType="uploadImage"; 
    }else if(type=='addons'){
      this.addCategory.get('addCategory').reset();
      this.submittedCateogry=false;
      this.pageType="parking_category";
    }else if(type=='special_request'){
      this.addaddOns.get('addaddOns').reset();
      this.submittedaddaddOns=false;
      this.pageType="addons";
    }
  }
  
}
