import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";

import { ActivatedRoute, Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import { tap, catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import "rxjs/add/operator/do";
import { CommonService } from "./services/common-service.service";
import { ZoneAndModuleHttpParams } from "./shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { type } from "os";
import { v4 as uuidv4 } from "uuid";
import { environment, RESPONSIBILITIES } from "../environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private currentZone;
  private globalAdmin: any = true;
  private currentModuleId;
  localValue;
  constructor(
    public toasterService: ToastrService,
    public commonService: CommonService,
    public route :ActivatedRoute,
    public router: Router
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.commonService.currentZone.subscribe((res) => {
      this.currentZone = res;
    });

    this.commonService.isGlobalAdmin.subscribe((res) => {
      this.globalAdmin = res;
    });
    if (request.params instanceof ZoneAndModuleHttpParams) {
      var obj = {};
      obj["correlationId"] = uuidv4();
      obj["version"] = "1.0";
      obj["clientKey"] = "ADMIN_WEB_APP";
      if (this.globalAdmin != true) {
        if (!request.params.getZone()) {
          obj["zoneId"] = 5;
        } else {
          obj["zoneId"] = request.params.getZone();
        }
      }
      else {
        if (!!request.params.getZone()) {
            obj["zoneId"] = request.params.getZone();
        }
    }

      //obj["enterpriseId"] = 1;
      if (
        request.params.getModule() == 3 ||
        request.params.getModule() == 101 ||
        request.params.getModule() == 1 ||
        request.params.getModule() == 9 ||
        request.params.getModule() == 4 ||
        request.params.getModule() == 24 ||
        request.params.getModule() == 2
      ) {
        obj["moduleId"] = request.params.getModule();
      }

      if (localStorage.getItem("ssadmin_session")) {
        obj["sessionId"] = localStorage.getItem("ssadmin_session");
      }

      if (typeof obj["sessionId"] != "undefined") {
        if (!obj["sessionId"]) {
          let sessionCookie = this.check_cookie_name("sessionId");
          obj["sessionId"] = sessionCookie;
        }
      }

      if(!obj["sessionId"]){
        obj["sessionId"] = 'null';
      }
      let url = request.url;
      if (!!localStorage.getItem("language")) {
        obj["locale"] = localStorage.getItem("language");
      }
      // if(url.includes("/bs/")){
      //   obj["moduleId"] = 101;
      // }

      // <<<<<<< HEAD
      // =======
      //       if(url.includes("/bs/booking/entity/utilization") ||
      //          url.includes("/bs/entity/zone/") ||
      //          url.includes("/bs/entity/zone/type/") ||
      //          url.includes("/bs/amenity/all?") ||
      //          url.includes("/bs/amenity/all/count") ||
      //          url.includes("/bs/entityAmenityMapping/multi") ){
      //         obj["moduleId"] = 3;
      //       }
      // >>>>>>> 0b46f92c94d95d68006eae9550307f21a374d8ea

      // if (url.includes("/bites/")) {
      //   obj["moduleId"] = 24;
      // }
      if (url.includes("/ems/zones/")) {
        obj["enterpriseId"] = 2;
      }

      if (url.includes("/ems/") || url.includes("/communityV2/") || url.includes("/vms/")) {
        // obj["moduleId"] = 22;
        obj["moduleId"] = request.params.getModule(); //get passed moduleid
      } else {
        obj["moduleId"] = request.params.getModule() == 3 ? 3 : 1;
      }

      if (url.includes("/cns/")) {
        obj["moduleId"] = 33;
      }

      let startIdx = this.commonService.getNthIndex(url, "/", 3);
      let endIdx = url.indexOf("?") == -1 ? url.length : url.indexOf("?");
      let requestUriForEMSValidation = url.substring(
        startIdx as any,
        endIdx as any
      );
      if (url.includes("/bites/")) {
        request = request.clone({
          setHeaders: {
            //"feature_key": "PRE_INVITE",
            "ss-header": JSON.stringify(obj),
            //"requestUri": requestUriForEMSValidation,
            //"requestMethod": request.method
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            feature_key: "PRE_INVITE",
            "ss-header": JSON.stringify(obj),
            //"requestUri": requestUriForEMSValidation,
            //"requestMethod": request.method
          },
        });
      }

      

      let modifiedRequest = request.clone();

      // Extract the URL as a string
      const url1 = modifiedRequest.urlWithParams;
 
      // Use string manipulation to extract the 'type' query parameter
      const typeMatch = url1.match(/[\?&]type=([^&]*)/);
      // const keyAnalytics = url1.match(/[\?&]key=([^&]*)/);
      const type = typeMatch ? decodeURIComponent(typeMatch[1]) : null;

  
      // You can now work with the 'type' value as needed
    
      

      return next.handle(request).do(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            //if(event.body && event.body.success)
            //this.toasterService.success(event.body.success.message, event.body.success.title, { positionClass: 'toast-bottom-center' });
          }
        },
        (err: any) => {
          if (
            err instanceof HttpErrorResponse &&
            err.status != 200 &&
            err.status != 0
          ) {
            let error = (err as any).error;
            try {
              if (
                !!error["api"] &&
                error["api"] != "/ems/custom-field-metadata/all-active" &&
                error["api"] != "/ems/custom-field/all-custom-key" &&
                error["api"] != "/communityV2/custom-field/all-active" &&
                error["api"] != "/communityV2/assessment/user-responses" &&
                error["api"] !=
                  "/communityV2/custommessage/getByZoneIdCustomKey" &&
                error["api"] !=
                  "/communityV2/autodelete/getByZoneIdCustomKey" &&
                error["api"] !=
                  "/communityV2/assessment/user-assessment/temperature/otherusers" &&
                error["api"] != "/amenitybooking/booking/permissionCheck" &&
                error["api"] != "/amenitybooking/booking/integrationTest" &&
                error["api"] != "/spacemanagement/allocation/uploadCSV" &&
                error["api"]!="/ems/config/getByZoneId"&&
                error["api"]!="/vms/api/validatePhoneNumber"
                
              ) {
                if(type!='' )
                this.toasterService.error(err.error.message, err.error.title, {
                  positionClass: "toast-top-center",
                });
              

              }
            } catch (e) {
              if (
                !!error["api"] &&
                error["api"] != "/ems/custom-field-metadata/all-active" &&
                error["api"] != "/ems/custom-field/all-custom-key" &&
                error["api"] != "/communityV2/custom-field/all-active" &&
                error["api"] != "/communityV2/assessment/user-responses" &&
                error["api"] !=
                  "/communityV2/custommessage/getByZoneIdCustomKey" &&
                error["api"] !=
                  "/communityV2/autodelete/getByZoneIdCustomKey" &&
                error["api"] !=
                  "/communityV2/assessment/user-assessment/temperature/otherusers" &&
                error["api"] != "/amenitybooking/booking/permissionCheck" &&
                error["api"] != "/amenitybooking/booking/integrationTest" &&
                error["api"]!="/vms/api/validatePhoneNumber"
              ) {
                this.toasterService.error("An error occurred", "", {
                  positionClass: "toast-top-center",
                });
              }
            }
          }
        }
      );
    } else {
      return next.handle(request);
    }
  }

  check_cookie_name(name) {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) {
      return match[2];
      // console.log(match[2]);
    } else {
      console.log("--something went wrong---");
    }
  }
}
