import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "src/app/services/moduleid-service";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError, timeout, tap, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class QrcodeService {
    moduleId;
    BASEURL;
    EMSURL;
    constructor(
        private http: HttpClient,
        private moduleidService: ModuleidService,
        private toastrService: ToastrService,
        @Inject("BASE_URL") baseUrl: string
    ) {
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL;
        this.moduleId = this.moduleidService.getModueId();
    }


    getAllFloorDetails(zoneId, data: any): Observable<any> {
        let searchParams = data.searchArr;
        if (searchParams) {
            let status = "states=PARTIAL,COMPLETE";
            let leaseStart = "";
            let leaseEnd = "";
            let buildingIds = "";
            if (searchParams.status != "") {
                status = "states=" + searchParams.status;
            }

            if (searchParams.leaseStart != "") {
                leaseStart = "&leaseStart=" + searchParams.leaseStart;
            }

            if (searchParams.leaseEnd != "") {
                leaseEnd = "&leaseEnd=" + searchParams.leaseEnd;
            }

            if (searchParams.buildingIds != "") {
                buildingIds = "&buildingIds=" + searchParams.buildingIds;
            }

            searchParams = status + leaseStart + leaseEnd + buildingIds;
        } else {
            searchParams = "";
        }

        //const headers = new HttpHeaders().set('ss-header', '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":1,"sessionId":"2c234e44-c39f-4a0b-ba78-52992466abbd","enterpriseId":1}');
        //let url = `http://192.168.1.37:9084/spacemanagement/onboarding/floorDetails/getAll?size=${data.pageSize}&page=${data.pageIndex}`+searchParams;
        //let url = this.BASEURL + `onboarding/floorDetails?` + searchParams;
        let url = this.BASEURL + `socialdistancing/floorDetails`;

        //return this.http.get<any>(url, { headers: headers });
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    saveBooking(zoneId, data: any): Observable<any> {
        var seatIds = data['selectedSeats'].map(function(obj){
            return obj.id; 
        }).join(',');

        let url = this.BASEURL + `reports/qrCodes/entities?entityIds=` + seatIds;
        //return this.http.get<any>(url, { headers: headers });
        return this.http.get(url,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId), responseType: 'blob'}).pipe();
    }

    printSeats(zoneId,printAll, floorId){
        let url = this.BASEURL + `reports/qrCodes?zoneId=${floorId}&showAll=${printAll}`
        //return this.http.get<any>(url, { headers: headers });
        return this.http.get(url,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId), responseType: 'blob'}).pipe();
    }

    getSpaceViewAnalytics(data,currentZone){
        let url = `${this.BASEURL}allocation/mapView?dayTime=${data.dayTime}&mapViewType=${data.viewType}&zoneId=${data.floorId}`;
        return this.http.get(url,{params: new ZoneAndModuleHttpParams(currentZone, this.moduleId)});
        /*return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId)
            })
            .pipe(
                distinctUntilChanged(),
                shareReplay(),
                map(res => res["response"]),
                catchError(err => {
                    let errorMsgData = (err as any).error;
                    const message = errorMsgData["message"];
                    this.toastrService.error("No data found");
                    return throwError(err);
                })
            );*/
    }

}