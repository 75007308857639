import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, of, Subject } from "rxjs";
import { ExpenseService } from "../shared/service/expense.service";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "loaderService";
import { CommonService } from "commonService";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormArray,
    Validators,
    ValidatorFn,
    AbstractControl,
} from "@angular/forms";
import { map, startWith, takeUntil, tap } from "rxjs/operators";

@Component({
    selector: "expense-modal",
    styleUrls: ["expense-modal.component.scss"],
    templateUrl: "expense-modal.component.html",
})
export class ExpenseModalComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public modalType: string = "";
    expenseForm: FormGroup;
    currentzone;
    expenseTypeAuto: Observable<{}[]>;
    subExpenseTypeAuto: Observable<{}[]>;
    imgFile: any;
    imgSrc: string | ArrayBuffer;
    showImage: boolean;
    attachment: any;
    get subExpenses(): FormArray {
        return this.expenseForm.get("subExpenses") as FormArray;
    }
    attachmentType: string = "";
    expenseStatusList: string[] = ["PAID", "NOT_PAID"];
    constructor(
        public dialogRef: MatDialogRef<ExpenseModalComponent>,
        private fb: FormBuilder,
        private expenseService: ExpenseService,
        private toastrService: ToastrService,
        private loaderService: LoaderService,
        private commonService: CommonService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}
    getSubcategoryFrom(): FormGroup {
        return this.fb.group({
            expenseCategory: [
                this.expenseForm.get("subcategories").value,
                [
                    Validators.required,
                    Validators.maxLength(40),
                    Validators.minLength(3),
                ],
            ],
        });
    }

    ngOnInit() {
        this.modalType = this.data["type"];
        this.currentzone = this.data["currentzone"];
        if (this.modalType === "ADD_EXPENSETYPE") {
            this.expenseForm = this.fb.group({
                expenseCategory: [
                    "",
                    [
                        Validators.required,
                        Validators.minLength(3),
                        Validators.maxLength(40),
                    ],
                ],
                subcategories: [
                    "",
                    [Validators.minLength(3), Validators.maxLength(40)],
                ],
                subExpenses: this.fb.array([], [this.uniqueValidator]),
            });
        } else if (this.modalType === "EDIT_EXPENSETYPE") {
            const item = this.data["item"];
            this.expenseForm = this.fb.group({
                id: [item.id],
                expenseCategory: [
                    item.expenseCategory,
                    [
                        Validators.required,
                        Validators.minLength(3),
                        Validators.maxLength(40),
                    ],
                ],
                subcategories: [
                    "",
                    [Validators.minLength(3), Validators.maxLength(40)],
                ],
                subExpenses: this.fb.array([], [this.uniqueValidator]),
            });
            if (!!item.subExpenses) {
                if (item.subExpenses.length) {
                    item.subExpenses.forEach((element) => {
                        this.subExpenses.push(
                            this.setSubexpenseValue({
                                id: element.id,
                                expenseCategory: element.expenseCategory,
                            })
                        );
                    });
                }
            }
        } else if (this.modalType === "ADD_EXPENSE") {
            this.expenseForm = this.fb.group({
                dateOfExpense: [null, [Validators.required]],
                expenseType: [null, [Validators.required]],
                expenseTypeId: [null, [Validators.required]],
                subExpenseType: [null, [Validators.required]],
                subExpenseTypeId: [null, [Validators.required]],
                currency: ["USD", [Validators.required]],
                accountNumber: [null, [Validators.required]],
                amount: [null, [Validators.required, this.amountValidation]],
                notes: ["", [Validators.maxLength(500)]],
                expenseStatus: ["", [Validators.required]],
                status: ["ENABLED", [Validators.required]],
            });
            this.expenseTypeAuto = this.expenseForm
                .get("expenseType")
                .valueChanges.pipe(
                    startWith(""),
                    map((value) =>
                        this._filter(value, this.data["expenseType"])
                    )
                );

            // this.subExpenseTypeAuto = this.expenseForm
            //     .get("expenseType")
            //     .valueChanges.pipe(
            //         startWith(""),
            //         map((value) =>
            //             this._filter(value, this.data["expenseType"])
            //         )
            //     );
        } else if (this.modalType === "EDIT_EXPENSE") {
            const item = this.data["item"];
            const selectedExpense = this.data["expenseType"].filter(ele => ele.id === item.expenseType.parentExpenseId);
            this.expenseForm = this.fb.group({
                id: item.id,
                dateOfExpense: [new Date(item.createdAt), [Validators.required]],
                expenseType: [selectedExpense[0]["expenseCategory"], [Validators.required]],
                expenseTypeId: [item.expenseType.parentExpenseId, [Validators.required]],
                subExpenseType: [item.expenseType.expenseCategory, [Validators.required]],
                subExpenseTypeId: [item.expenseType.id, [Validators.required]],
                currency: [item.currency, [Validators.required]],
                accountNumber: [item.accountNumber, [Validators.required]],
                amount: [item.amount, [Validators.required, this.amountValidation]],
                notes: [item.notes, [Validators.maxLength(500)]],
                expenseStatus: [item.expenseStatus, [Validators.required]],
                status: [item.status, [Validators.required]],
            });
            
            this.expenseTypeAuto = this.expenseForm
                .get("expenseType")
                .valueChanges.pipe(
                    startWith(""),
                    map((value) =>
                        this._filter(value, this.data["expenseType"])
                    )
                );

            // this.subExpenseTypeAuto = this.expenseForm
            //     .get("expenseType")
            //     .valueChanges.pipe(
            //         startWith(""),
            //         map((value) =>
            //             this._filter(value, this.data["expenseType"])
            //         )
            //     );
            this.onSelectExpenseType(selectedExpense[0]["expenseCategory"]);
                setTimeout(() => {
                    this.expenseForm.get("subExpenseType").setValue(item.expenseType.expenseCategory)
                    this.expenseForm.get("subExpenseTypeId").setValue(item.expenseType.id)
                }, 200); 
        }else if(this.modalType === "ADD_RECURRING_EXPENSE") {
            this.expenseForm = this.fb.group({
                profileName:[null, [Validators.required]],
                repeatEvery:[null, [Validators.required]],
                startDate:[null,[Validators.required]],
                endDate:[null,[Validators.required]],
                isNeverExpires:[true],
                dateOfExpense: [null, [Validators.required]],
                expenseType: [null, [Validators.required]],
                expenseTypeId: [null, [Validators.required]],
                subExpenseType: [null, [Validators.required]],
                subExpenseTypeId: [null, [Validators.required]],
                currency: ["USD", [Validators.required]],
                accountNumber: [null, [Validators.required]],
                amount: [null, [Validators.required, this.amountValidation]],
                notes: ["", [Validators.maxLength(500)]],
                expenseStatus: ["", [Validators.required]],
                status: ["ENABLED", [Validators.required]],
            });
            this.expenseTypeAuto = this.expenseForm
                .get("expenseType")
                .valueChanges.pipe(
                    startWith(""),
                    map((value) =>
                        this._filter(value, this.data["expenseType"])
                    )
                );

            this.subExpenseTypeAuto = this.expenseForm
                .get("expenseType")
                .valueChanges.pipe(
                    startWith(""),
                    map((value) =>
                        this._filter(value, this.data["expenseType"])
                    )
                );
        }else if (this.modalType === "EDIT_RECURRING_EXPENSE") {
            const item = this.data["item"];
            this.expenseForm = this.fb.group({
                id: item.id,
                profileName:[null, [Validators.required]],
                repeatEvery:[null, [Validators.required]],
                startDate:[null,[Validators.required]],
                endDate:[null,[Validators.required]],
                isNeverExpires:[true],
                dateOfExpense: [new Date(item.createdAt), [Validators.required]],
                expenseType: ['test', [Validators.required]],
                expenseTypeId: [item.expenseType.parentExpenseId, [Validators.required]],
                subExpenseType: [item.expenseType.expenseCategory, [Validators.required]],
                subExpenseTypeId: [item.expenseType.id, [Validators.required]],
                currency: [item.currency, [Validators.required]],
                accountNumber: [item.accountNumber, [Validators.required]],
                amount: [item.amount, [Validators.required, this.amountValidation]],
                notes: [item.notes, [Validators.maxLength(500)]],
                expenseStatus: [item.expenseStatus, [Validators.required]],
                status: [item.status, [Validators.required]],
            });
            this.expenseTypeAuto = this.expenseForm
                .get("expenseType")
                .valueChanges.pipe(
                    startWith(""),
                    map((value) =>
                        this._filter(value, this.data["expenseType"])
                    )
                );

            this.subExpenseTypeAuto = this.expenseForm
                .get("expenseType")
                .valueChanges.pipe(
                    startWith(""),
                    map((value) =>
                        this._filter(value, this.data["expenseType"])
                    )
                );
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    setSubexpenseValue(val): FormGroup {
        return this.fb.group({
            expenseCategory: [
                val.expenseCategory,
                [
                    Validators.required,
                    Validators.maxLength(40),
                    Validators.minLength(3),
                ],
            ],
            id: [
                val.id,
                [
                    Validators.required,
                    Validators.maxLength(40),
                    Validators.minLength(3),
                ],
            ],
        });
    }
    onSubmitExpenseType = () => {
        if (this.expenseForm.invalid) {
            return;
        }
        this.loaderService.loadingOn();
        const frmData = this.expenseForm.value;
        let reqObj: any = {
            expenseCategory: frmData.expenseCategory,
            subExpenses: frmData.subExpenses,
        };
        if (this.modalType === "EDIT_EXPENSETYPE") {
            reqObj = { ...reqObj, id: this.data["item"]["id"] };
            this.expenseService
                .updateExpenseType(reqObj, this.currentzone)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    (res) => {
                        this.toastrService.success(
                            "Expense type successfully added"
                        );
                        this.closePopup();
                        this.loaderService.loadingOff();
                    },
                    (err) => {
                        this.loaderService.loadingOff();
                    }
                );
        } else {
            this.expenseService
                .createExpenseType(reqObj, this.currentzone)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    (res) => {
                        this.toastrService.success(
                            "Expense type successfully added"
                        );
                        this.closePopup();
                        this.loaderService.loadingOff();
                    },
                    (err) => {
                        this.loaderService.loadingOff();
                    }
                );
        }
    };
    closePopup = () => {
        this.dialogRef.close();
    };
    addSubCategory = (): void => {
        let val = this.expenseForm.get("subcategories").value.trim();
        if (!!val) {
            this.subExpenses.push(this.getSubcategoryFrom());
            // this.subExpenses.insert(1, this.getSubcategoryFrom());
            let subCat = this.expenseForm.get("subcategories");
            subCat.patchValue("");
        }
    };
    removeSubCategory = (item, index): void => {
        this.subExpenses.removeAt(index);
    };
    required(name: string): boolean {
        return (
            this.expenseForm.get(name).hasError("required") &&
            this.expenseForm.get(name).touched
        );
    }
    lengthValidation(name, type, isFormArray): boolean {
        if (type === "min") {
            return this.expenseForm.get(name).hasError("minlength");
        } else {
            return this.expenseForm.get(name).hasError("maxlength");
        }
    }
    isValid(name) {
        return this.expenseForm.get(name).valid;
    }
    public uniqueValidator(fromArray: FormArray) {
        const duplicateControls = [];
        const uniqueControls = [];

        

        fromArray.controls.forEach((control) => {
            const counts = fromArray.controls.filter((x) => {
                return (
                    x.get("expenseCategory").value ===
                    control.get("expenseCategory").value
                );
            });

            const count = fromArray.controls.filter(
                (x) =>
                    x.get("expenseCategory").value ===
                    control.get("expenseCategory").value
            ).length;

            if (count > 1) {
                duplicateControls.push(control);
            } else {
                uniqueControls.push(control);
            }
        });

        duplicateControls.forEach((duplicateControl) => {
            duplicateControl.get("expenseCategory").setErrors(
                Object.assign(
                    {},
                    duplicateControl.get("expenseCategory").errors,
                    {
                        notUnique: true,
                    }
                )
            );
        });

        uniqueControls.forEach((control: any) => {
            let errors = control.get("expenseCategory").errors;
            // @ts-ignore
            if (errors) {
                delete errors.notUnique;
                errors = Object.keys(control.get("expenseCategory").errors)
                    .length
                    ? control.get("expenseCategory").errors
                    : null;
            }
            control.get("expenseCategory").setErrors(errors);
        });

        return null;
    }
    amountValidation(formControl: FormControl): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const forbidden = formControl.value;
            return forbidden <= 0
                ? { notValidAmt: { value: "Please enter valid amount" } }
                : null;
        };
    }
    private _filter(value: string, data) {
        if (!!value) {
            const filterValue = value.toLowerCase();
            if (!!data) {
                return data.filter((option) =>
                    option["expenseCategory"]
                        .toLowerCase()
                        .includes(filterValue)
                );
            } else {
                return data;
            }
        } else {
            return data;
        }
    }
    onSelectExpenseType(event): void {
        const selectedExpenseType = this.data["expenseType"].filter(
            (element) => {
                if (element.expenseCategory === event) {
                    return element;
                }
            }
        );
        this.expenseForm
            .get("expenseTypeId")
            .setValue(selectedExpenseType[0].id);
        this.expenseForm.get("subExpenseType").setValue(null);
        this.expenseForm.get("subExpenseTypeId").setValue(null);
        if (!!selectedExpenseType[0].subExpenses) {
            this.subExpenseTypeAuto = of(selectedExpenseType[0].subExpenses);
        } else {
            this.subExpenseTypeAuto = of(null);
        }
    }
    onSelectSubExpenseType(event): void {
        const expenseTypeId = this.expenseForm.get("expenseTypeId").value;
        const selectedExpenseType = this.data["expenseType"].filter(
            (element) => {
                if (element.id === expenseTypeId) {
                    return element;
                }
            }
        );
        if (!!selectedExpenseType[0].subExpenses) {
            const selectedSubExpenseType = selectedExpenseType[0].subExpenses.filter(
                (element) => {
                    if (element.expenseCategory === event) {
                        return element;
                    }
                }
            );
            
            if (!!selectedSubExpenseType[0].id) {
                this.expenseForm
                    .get("subExpenseTypeId")
                    .setValue(selectedSubExpenseType[0].id);
            }
        }
    }
    onSelectAttachment(event) {
        var fileName = event.target.value;
        var fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
        fileType = fileType.toLowerCase();
        if (
            fileType == "jpg" ||
            fileType == "png" ||
            fileType == "jpeg" ||
            fileType == "svg"
        ) {
            this.attachmentType = "img";
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                this.imgFile = event.target.files[0];
                reader.onload = (event) => {
                    this.imgSrc = event.target["result"];
                    this.showImage = true;
                };
            }
        } else {
            if (event.target.files && event.target.files[0]) {
                this.attachmentType = "file";
                this.showImage = true;
            }
        }
        var fileToUpload = event.target.files[0];
        this.attachment = fileToUpload;
    }
    saveExpense = () => {
        if (this.expenseForm.invalid) {
            return;
        }
        let expenseDate = this.commonService.getTimeStamp(
            new Date(this.expenseForm.get("dateOfExpense").value).getTime()
        );
        let reqObj: any = {
            expenseType: {
                id: this.expenseForm.get("subExpenseTypeId").value,
            },
            zoneId: this.currentzone,
            currency: this.expenseForm.get("currency").value,
            accountNumber: this.expenseForm.get("accountNumber").value,
            amount: this.expenseForm.get("amount").value,
            notes: this.expenseForm.get("notes").value,
            dateOfExpense: expenseDate,
            expenseStatus: this.expenseForm.get("expenseStatus").value,
            status: this.expenseForm.get("status").value,
        };
        if (this.modalType === "ADD_EXPENSE") {
            const sub$ = this.expenseService.createExpense(
                reqObj,
                this.currentzone
            );
            this.loaderService
                .showLoaderUntilCompleted(sub$)
                .pipe(
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    (res) => {
                        //need to call file upload api
                        this.closePopup();
                    },
                    (err) => {}
                );
        }else if(this.modalType === "EDIT_EXPENSE") {
            reqObj.id = this.expenseForm.get("id").value
            const sub$ = this.expenseService.updateExpense(
                reqObj,
                this.currentzone
            );
            this.loaderService
                .showLoaderUntilCompleted(sub$)
                .pipe(
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    (res) => {
                        //need to call file upload api
                        this.closePopup();
                    },
                    (err) => {}
                );
        }else if(this.modalType === "ADD_RECURRING_EXPENSE") {


            // {
                
            //     "profileName":"Recurring expense 1",
            // }
            const sub$ = this.expenseService.createExpense(
                reqObj,
                this.currentzone
            );
            this.loaderService
                .showLoaderUntilCompleted(sub$)
                .pipe(
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    (res) => {
                        //need to call file upload api
                        this.closePopup();
                    },
                    (err) => {}
                );
        }else if(this.modalType === "EDIT_RECURRING_EXPENSE") {
            const sub$ = this.expenseService.createExpense(
                reqObj,
                this.currentzone
            );
            this.loaderService
                .showLoaderUntilCompleted(sub$)
                .pipe(
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    (res) => {
                        //need to call file upload api
                        this.closePopup();
                    },
                    (err) => {}
                );
        }
    };
}
