<div>
    <app-modal-loader [loading]="loading"></app-modal-loader>
    <mat-card class="example-card" *ngIf="pageType == 'showQrcode'">
        <div class="row">
            <div class="col-md-6">
                <div class="visitor-info-header">
                    {{'showQrcode' | translate}}
                </div>
            </div>
            <div class="col-md-6">
                <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider> 
        <mat-card-content>
            <br />
            <div class="row" *ngIf="encryptedQRCode!=''">
                <div class="col-md-6 mg-10" style="margin:0px auto;" id="print-section">
                    <qrcode [qrdata]="encryptedQRCode" [size]="256" [level]="'M'"></qrcode>
                </div>
                <div class="col-md-12">
                    <button class="btn btn-primary" [disabled]="true" (click)="printPage()">{{'print' | translate}}</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-tab-group style="background-color: #fff;height:400px"  *ngIf="data['type']=='visitorInfo'"  (selectedTabChange)="getVisitManagerList($event)" >
    <mat-tab label="Visitor Info" >
    <div >
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            {{'visitorInfo' | translate}}
                        </div>
                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <mat-card-content>
                <div class="row">
                    <div class="col-md-3">
                        <div mat-card-avatar class="" *ngIf="data.visitorImage">
                            <img class="user-avatar-image" [src]="data.visitorImage" />
                        </div>

                    </div>
                    <div class="col-md-9 visitor-details">
                        <div class="visitor-details-inner">

                            <div class="row detail-row">

                                <div class="col-md-4 user-detail-param">{{'visitorName' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{data.visitorName}}</div>
                            </div>

                            <div class="row detail-row" *ngIf="data && data.visitorCompany">
                                <div class="col-md-4 user-detail-param">{{'visitorCompany' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{data.visitorCompany}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-4 user-detail-param">{{'hostCompany' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{data.hostCompany}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-4 user-detail-param">{{'phoneNo' | translate | titlecase}}</div>
                                <div class="col-md-4 user-detail-value">{{data.visitorMobile}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-4 user-detail-param">{{'emailId' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{data.visitorEmail}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-4 user-detail-param">{{'purpose' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{data.purpose}}</div>
                            </div>


                            <!-- <div class="row detail-row" *ngIf="!!declaration">
                                <div class="col-md-4 user-detail-param">Declaration</div>
                                <div class="col-md-4 user-detail-value" *ngIf="!!questionAndAnswers">
                                    {{declaration}}
                                </div>
                            </div> -->

                        </div>
                    </div>
                </div>


            </mat-card-content>

         <!-- QUESTIONS ANSWERS ARE COMMENTED FOR  UNDER VISITORINFO -->
            <!-- <div class="row" *ngIf="questionEntered">
                <div class="col-md-3" *ngIf="model.optionsq.length>0">
                    <ng-container *ngFor="let opt of model.optionsq">
                        <p><b>{{opt['qoption'][0]}}</b> : {{opt['typenumber'][0]}}</p>
                    </ng-container>
                </div>
                <div class="col-md-9">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            Custom checklist
                        </div>

                    </mat-card-header>

                    <div class="container">
                        <div *ngIf="questionAndAnswers.length>0">
                            <ng-container class="row " *ngFor="let que of questionAndAnswers;let k = index">
                                <div *ngIf="que['selectedAnswer'] != ''">
                                    <h6 class="mg-10">{{k+1}}. {{que['metadata']['question']}}</h6>
                                    <div class="">
                                        <b>ANS. {{getSelectedAnswers(que)}}
                                            {{que['metadata']['values'][this.data['data']['metadata']['questionResponse'][que.id]] }} -->
                                            <!-- <span *ngIf="checkFlagWithAnswer(que) == 1"><img
                                                    src="../../../../assets/images/redflag.png" width="15px" /></span>
                                        </b>
                                    </div> 
                                </div>
                            </ng-container>
 

                        </div>
                        <div *ngIf="questionAndAnswers.length==0">

                            No Questions available

                        </div>
                    </div>
                </div>
            </div>  -->

            <mat-divider></mat-divider>


            <div>



            </div>
        </mat-card>
    </div>
    </mat-tab>

    <mat-tab label="Additional Details" *ngIf="isExtraInformationRequired"> 
        <mat-card> 
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            {{'additionalDetails' | translate}} 
                        </div>
                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>
      <div *ngIf="questionListFinal.length>0">

          
      <mat-card-content>
        <div class="row">

            <div class="col-md-9 visitor-details">
                <div class="visitor-details-inner">

                    <div class="row detail-row">

                        <div class="col-md-5 user-detail-value">{{'attemptedNumber' | translate}}</div>
                        <div class="col-md-4 user-detail-value">{{attemptedNumber}}</div>
                    </div>

                    <!-- <div class="row detail-row">
                        <div class="col-md-5 user-detail-value">Assessment Status</div>
                        <div class="col-md-4 user-detail-value">{{assessmentStatus}} </div>
                    </div> -->

                    <div class="row detail-row">
                        <div class="col-md-5 user-detail-value">{{'createdAt' | translate}}</div>
                        <div class="col-md-4 user-detail-value">{{createdAt| date: "MMM-dd yyyy, h:mm a"}}</div>
                    </div> 

                </div>
            </div> 
             <br>
        </div>
        <hr style="display:none"> <hr>
    <div *ngFor=' let item of questionListFinal' style="margin-top: 10px;margin-left: 10px;">
         <span>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{item.question}}</b> </span> 
    <div class='row' *ngIf = 'item.fieldType == "RADIO"'   >
        <div class='col-md-8'  >   
          <mat-radio-group class="example-radio-group" style="margin-left:12px;font-size: 18px;"  >
            <!-- [disabled]="true"  -->
            <mat-radio-button class="example-radio-button"   [checked]="ans.checked"   *ngFor="let ans of item?.option " [value]="ans.val"  style="margin-left:10px;font-size: 18px;">
                
                <span> {{ans.val}} </span>   
            </mat-radio-button>
          </mat-radio-group> 

        </div>
        <hr>
       </div>
        

        <div *ngIf = 'item.fieldType == "CHECKBOX"' class='row'   style="margin-left: -2px;" >
            <br>
              <div class='col-md-8' >
                <section  *ngIf ="!!item?.option" >  
                    <!-- [disabled]="true"  -->
                  <mat-checkbox *ngFor="let ans of item?.option"    [checked]="ans.checked"    ngDefaultControl  style="margin-left:12px;font-size: 10px;" >
                           
                    <span style="font-size: 15px">{{ans.val}}</span>
                  </mat-checkbox> 
             </section>
              </div> 
              <!-- <hr>    -->
         </div>
         <div *ngIf = 'item.fieldType == "TEXT"' class='row' style="margin-right: 10px;">
          <div class='col-md-4'>
              <br>
              <input type ="text" class="form-control"  style="font-size: 15px;margin-left:20px;width: auto;" [(ngModel)]="item.answer"  >
        
          </div>  
          <!-- <hr>   -->
        </div>
        
        <div *ngIf = 'item.fieldType == "DATE"' class='row'>
            <div class='col-md-4'>
                <br>
                 <span style="font-size: 15px;margin-left:20px;">{{item.timeMillis | date:(item.dateType) ? "dd/MM/yyyy" : "MMM/yyyy"}} </span>
            </div>   
            <!-- <hr>  -->
          </div>
          
          <div *ngIf = 'item.fieldType == "DROPDOWN"' class='row'>
            <div class='col-md-4'>
                <br>
                <select style="font-size: 15px;margin-left:20px;width: auto;" class="form-control">
                    <option>{{item.drSelected}}</option>
                </select>
            </div>  
            <!-- <hr>   -->
          </div>
          <hr>
    </div>
    <!-- <div style= 'height:300px;overflow-x: hidden'> -->
    <div>
        <div class="container">
            <div *ngIf="DataList.length>0">
                <ng-container class="row "  *ngFor="let ans of DataList;let k = index"> 
                    <div *ngIf="!!ans['answerSelected'] && ans.isActive && ans.fieldType != 'DROPDOWN'">
    
                        <h6 class="mg-10">{{k+1}}. {{ans['metadata']['question']}}</h6>
                        <div class="">
                            <b>ANS. {{ans.answerSelected}}
                                 
                            </b>
                        </div> 
                    </div>
                </ng-container>  
            </div>
            <!-- <div *ngIf="DataList.length==0">
    
                No Details available
    
            </div> -->
        </div>   
          </div>
</mat-card-content> 
</div>  
<div *ngIf="questionListFinal.length==0" style="margin-left:40%;margin-top: 100px;">
    {{'noDetailsAvailable' | translate}}
</div>
     
     
    </mat-card>          
      </mat-tab>
      <!-- <mat-tab label="Customfield Details" >
        <mat-card class="example-card">
        <div class="row">
            <div class="col-md-6">
                <mat-card-header>
                    <div class="visitor-info-header">
                    
                    </div>
                </mat-card-header>
            </div>
            <div class="col-md-6">
                <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
        <mat-card-content>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6"><b>ModuleEntity Id</b></div>
                  <div class="col-md-6" > {{moduleEntityId}}
                     </div>
                </div>
              </div><br>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6"><b>Attempted Number</b></div>
                  <div class="col-md-6"> {{attemptedNumber}}</div>
                </div>
              </div> <br>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6"><b>Assessment Status</b></div>
                  <div class="col-md-6">{{assessmentStatus}} </div>
                </div>
              </div><br>
              <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6"><b>Created At</b></div>
                    <div class="col-md-6">{{createdAt| date : 'hh:mm a'}}</div>
                  </div>
                </div> <br>
            </div>
            <hr> <hr>
        <div *ngFor=' let item of questionListFinal' style="margin-top: 10px;margin-left: 10px;">
            <b>Q.</b><span><b>{{item.question}}</b></span>
        
        <div class='row' *ngIf = 'item.fieldType == "RADIO"'   >
            <div class='col-md-8'  >
                  [(ngModel)]="item.metadata.defaultValue" -->
              <!-- <mat-radio-group class="example-radio-group"   >
                <mat-radio-button class="example-radio-button"  [disabled]="true"  [checked]="ans.checked"   *ngFor="let ans of item?.option " [value]="ans.val" style="margin-left:10px;font-size: 10px;">
                  <b>{{ans.val}} </b>   
                </mat-radio-button>
              </mat-radio-group> 

            </div>
            <hr>
           </div>
            

            <div *ngIf = 'item.fieldType == "CHECKBOX"' class='row'  >
                  <div class='col-md-8' >
                    <section  *ngIf ="!!item?.option"  style="margin-right: 10px;" >  
                         
                      <mat-checkbox *ngFor="let ans of item?.option"  [disabled]="true"   [checked]="ans.checked"    ngDefaultControl  style="margin-left:10px;font-size: 10px;" >
                                <b style="font-size: 15px;">{{ans.val}}</b>
                      </mat-checkbox> 
                 </section>
                  </div> 
                  <hr>   
             </div>
             <div *ngIf = 'item.fieldType == "TEXT"' class='row'>
              <div class='col-md-4'>
                  <input type ="text"  style="font-size: 15px;" [(ngModel)]="item.answer" [disabled]="true" >
            
              </div>    
            </div>
            <hr>
        </div>
    
    </mat-card-content>  
      </mat-card>
      </mat-tab>  -->
      <mat-tab label="Visitor Self Assessment
      " *ngIf="customFieldsEnable">
        <mat-card> 
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            {{'selfAssessment' | translate}}
                        </div>
                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>
      <div >

          
      <mat-card-content>
       <!--  <div class="row">

            <div class="col-md-9 visitor-details">
                <div class="visitor-details-inner">

                    <div class="row detail-row">

                        <div class="col-md-5 user-detail-value">Attempted Number</div>
                        <div class="col-md-4 user-detail-value">{{attemptedNumber}}</div>
                    </div>

                    <div class="row detail-row">
                        <div class="col-md-5 user-detail-value">Assessment Status</div>
                        <div class="col-md-4 user-detail-value">{{assessmentStatus}} </div>
                    </div>

                    <div class="row detail-row">
                        <div class="col-md-5 user-detail-value">Created At</div>
                        <div class="col-md-4 user-detail-value">{{createdAt| date: "MMM-dd yyyy, h:mm a"}}</div>
                    </div> 

                </div>
            </div> 
             <br> 
        </div>-->
        <div *ngIf="questionListFinalHealth.length>0" >
    <div  *ngFor=' let item of questionListFinalHealth' style="margin-top: 10px;margin-left: 10px;">
         <span><b style="margin-left: 21px;">{{item.question}}</b></span> 
    <div class='row' *ngIf = 'item.fieldType == "RADIO"'   >
        <div class='col-md-8'  >   
          <mat-radio-group class="example-radio-group" style="margin-left:12px;font-size: 18px;"  >
            <!-- [disabled]="true"  -->
            <mat-radio-button class="example-radio-button"   [checked]="ans.checked"   *ngFor="let ans of item?.option " [value]="ans.val"  style="margin-left:10px;font-size: 18px;">
                
                <span> {{ans.val}} </span>
            </mat-radio-button>
          </mat-radio-group> 

        </div>
        <hr>
       </div>
        

        <div *ngIf = 'item.fieldType == "CHECKBOX"' class='row'   style="margin-left: -2px;" >
            <br>
              <div class='col-md-8' >
                <section  *ngIf ="!!item?.option" >  
                    <!-- [disabled]="true"  -->
                  <mat-checkbox *ngFor="let ans of item?.option"    [checked]="ans.checked"    ngDefaultControl  style="margin-left:12px;font-size: 10px;" >
                           
                    <span style="font-size: 15px">{{ans.val}}</span>
                  </mat-checkbox> 
             </section>
              </div> 
              <!-- <hr>    -->
         </div>
         <div *ngIf = 'item.fieldType == "TEXT"' class='row' style="margin-right: 10px;">
          <div class='col-md-4'>
              <br>
              <input type ="text" class="form-control"  style="font-size: 15px;margin-left:20px;width: auto;" [(ngModel)]="item.answer"  >
        
          </div>  
          <!-- <hr>   -->
        </div>
        
        <div *ngIf = 'item.fieldType == "DATE"' class='row'>
            <div class='col-md-4'>
                <br>
                 <span style="font-size: 15px;margin-left:20px;">{{item.timeMillis | date: "MMM/yyyy"}} </span>
            </div>   
            <!-- <hr>  -->
          </div>
          
          <div *ngIf = 'item.fieldType == "DROPDOWN"' class='row'>
            <div class='col-md-4'>
                <br>
                <select style="font-size: 15px;margin-left:20px;width: auto;" class="form-control">
                    <option>{{item.drSelected}}</option>
                </select>
            </div>  
            <!-- <hr>   -->
          </div>
          <hr>
    </div>
</div>
    <!-- <div style= 'height:300px;overflow-x: hidden'> -->
    <div>
        <div class="container">
            <div *ngIf="DataList.length>0">
                <ng-container class="row "  *ngFor="let ans of DataList;let k = index"> 
                    <div *ngIf="!!ans['answerSelected'] && ans.isActive && ans.fieldType != 'DROPDOWN'">
    
                        <h6 class="mg-10">{{k+1}}. {{ans['metadata']['question']}}</h6>
                        <div class="">
                            <b>ANS. {{ans.answerSelected}}
                                 
                            </b>
                        </div> 
                    </div>
                </ng-container>  
            </div>
            <!-- <div *ngIf="DataList.length==0">
    
                No Details available
    
            </div> -->
        </div>   
          </div>
</mat-card-content> 
</div>  
<div *ngIf="questionListFinalHealth.length==0" style="margin-left:40%;margin-top: 100px;">
    {{'noDetailsAvailable' | translate}}
</div>
     
     
    </mat-card>   
      </mat-tab>
    </mat-tab-group>


    <div *ngIf="data['type']=='createInviteVisitor'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            {{'createInvitation' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <!-- start -->
            <form #f="ngForm" (ngSubmit)="onInviteSubmit(f)" [formGroup]="form">

                <div class="container">
                    <div class="row">
                        <div class="col-md-12 meeting-details">
                            <h6><b>{{'meetingDetails' | translate}}</b></h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">

                            <label>{{'MeetingStartTime' | translate}}</label>
                            <input class="form-control" formControlName="scheduleStart" [owlDateTime]="inviteStartsOn"
                                [owlDateTimeTrigger]="inviteStartsOn" required>
                            <owl-date-time #inviteStartsOn></owl-date-time>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">

                            <label>{{'MeetingEndTime' | translate}}</label>
                            <input class="form-control" type="text" formControlName="scheduleEnd"
                                [owlDateTime]="inviteEndsOn" [owlDateTimeTrigger]="inviteEndsOn" required>
                            <owl-date-time #inviteEndsOn></owl-date-time>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">

                            <label>{{'MeetingPurpose' | translate}}</label>
                            <input class="form-control" type="text" formControlName="purpose" required>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">

                            <label>{{'locationZoneNameAddress' | translate}} </label>
                            <input class="form-control" type="text" formControlName="location" required
                                [matAutocomplete]="locationAuto">
                            <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
                            <mat-autocomplete #locationAuto="matAutocomplete">
                                <mat-option *ngFor="let location of locations"
                                    [value]="location.zoneId+'|'+location.name+','+location.address">
                                    {{ location.zoneId +"|"+location.name +","+location.address}}
                                </mat-option>
                            </mat-autocomplete>


                        </div>
                    </div>
                    <mat-divider class="section-divider"></mat-divider>
                    <div class="row">
                        <div class="col-md-12 host-details">
                            <h6><b>{{'hostDetails' | translate}}</b></h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">

                            <label>{{'hostDetailIdEmailName' | translate}}</label>
                            <input class="form-control" type="text" formControlName="hostEmail" required
                                [matAutocomplete]="auto">

                            <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let host of hosts"
                                    [value]="host.userId+'|'+host.email+'|'+host.name">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <img class="example-option-img" aria-hidden
                                                [src]="hostImgageUrl(host.imageUrl)" height="30">
                                        </div>
                                        <div class="col-md-6">
                                            <small >{{host.name | uppercase}} | </small>
                                            <small >{{ host.userId+' | '+host.email }}</small>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <mat-divider class="section-divider"></mat-divider>
                    <div class="row"
                        *ngIf="vmsConfig?.preInvite?.hostConfig?.commonHostConfig?.fields?.isWebexEnabled?.enabled == true">
                        <div class="col-md-6">
                            <mat-checkbox class="example-margin" formControlName="webEx"
                                [attr.required]="(vmsConfig?.preInvite?.hostConfig?.commonHostConfig?.fields?.isWebexEnabled?.required ==true) ? true : false">
                                {{'sendWebexInformation' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="vmsConfig?.preInvite?.hostConfig?.commonHostConfig?.fields?.isWifiEnabled?.enabled == true">
                        <div class="col-md-6">
                            <mat-checkbox class="example-margin" formControlName="wifi"
                                [attr.required]="(vmsConfig?.preInvite?.hostConfig?.commonHostConfig?.fields?.isWifiEnabled?.required ==true) ? true : false">
                                {{'sendWifiInformation' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" class="visitor-section-details">
                            <h6><b>{{'visitorDetails' | translate}}</b></h6>
                        </div>
                    </div>
                    <div class="row" style="text-align: center;">

                        <div class="upload-btn-wrapper">
                            <button class="btn">{{'uploadGuestViaCSV' | translate}}</button>
                            <input class="form-control" type="file" #fileImportInput name="File Upload"
                                id="txtFileUpload" (change)="vistorsFileChangeListener($event)" accept=".csv" />
                        </div>
                        <div> <i><a href="./assets/sample.csv" target="_blank">{{'sampleCSVDocument' | translate}}</a></i></div>

                    </div>

                    <div class="row">
                        <table>
                            <tbody>
                                <tr *ngFor="let csvData of csvRecords;let i = index;">
                                    <td>
                                        <table>
                                            <tr>
                                                <td>{{i+1}}</td>
                                            </tr>
                                            <tr>
                                                <td><span>{{'name' | translate}} : {{csvData.name}}</span> </td>
                                            </tr>
                                            <tr>
                                                <td><span>{{'email' | translate}} : {{csvData.email}}</span> </td>
                                            </tr>
                                            <tr>
                                                <td><span>{{'mobile' | translate}} : {{csvData.mobile}}</span> </td>
                                            </tr>
                                            <tr>
                                                <td><span>{{'company' | translate}} : {{csvData.company}}</span> </td>
                                            </tr>
                                            <tr>
                                                <td><span>{{'VisitorType' | translate}} : {{csvData.visitorTypeId}}</span> </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                    <div class="row">
                        <h4><span>{{'or' | translate }}</span></h4>
                    </div>
                    <ng-container formArrayName="visitors">
                        <div *ngFor="let ctrl of form.get('visitors')['controls']; let i = index" [formGroupName]="i">
                            <div class="row">
                                <div class="col-md-12">

                                    <div style="float:right;">
                                        <button mat-icon-button color="primary" class="btn btn-main" (click)="onRemoveVisitors(i)">
                                            <mat-icon matTooltip="close">{{'close' | translate}}</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>{{'name' | translate}}</label>
                                    <input class="form-control" type="text" formControlName="name" required>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>{{'email' | translate}}</label>
                                    <input class="form-control" type="text" formControlName="email" required>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>{{'mobile' | translate}}</label>
                                    <input class="form-control" type="text" formControlName="mobile" required>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>{{'company' | translate}}</label>
                                    <input class="form-control" type="text" formControlName="company">

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>{{'VisitorType' | translate}}</label>
                                    <mat-select formControlName="visitorTypeId" required>
                                        <mat-option *ngFor="let visitorType of visitorTypes" [value]="visitorType.id">
                                            {{visitorType.type}}
                                        </mat-option>
                                    </mat-select>

                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <div class="col-md-12">
                            <div style="float:left;"><button mat-raised-button color="primary"
                                    (click)="onAddVisitors()">{{'addAnotherVisitor' | translate}}
                                </button></div>
                            <div style="float:right;"><button type="submit" mat-raised-button color="primary">
                                {{'sendInvitation' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </form>

            <!-- end -->
        </mat-card>
    </div>

    <!-- displayQrCode -->
    <div *ngIf="data['type']=='displayQrCode'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            {{'qrcode' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <mat-card-content>


                <div id="print-section" class="printQr">

                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-visitor" style="font-weight: bold">{{'visitor' | translate}} </span> <span
                                class="left-text">
                                {{data.visitorName}} </span>
                        </div>
                        <!-- <div class="col-md-5 qr-right-title">
                            {{data.visitorName}}
                        </div> -->
                    </div>

                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-company" style="font-weight: bold"> {{'company' | translate}} </span>
                            <span>{{data.visitorCompany}}</span>
                        </div>
                        <!-- <div class="col-md-6 qr-right-title">
                            {{data.visitorCompany}}
                        </div> -->
                    </div>

                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-purpose" style="font-weight: bold"> {{'purpose' | translate}} </span>
                            <span>{{data.purpose}}</span>
                        </div>
                        <!-- <div class="col-md-6 qr-right-title">
                            {{data.purpose}}
                        </div> -->
                    </div>

                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-host" style="font-weight: bold"> {{'host' | translate}}
                            </span><span>{{data.hostName}}</span>
                        </div>
                        <!-- <div class="col-md-6 qr-right-title">
                            {{data.hostName}}
                        </div> -->
                    </div>


                    <div class="row qr-code-div">

                        <qrcode [qrdata]="data.qrcode" [size]="200" [level]="'L'"></qrcode>
                    </div>
                </div>
                <button class="printButton btn-main" mat-raised-button color="primary" printSectionId="print-section"
                    ngxPrint>{{'print' | translate}}</button>

            </mat-card-content>

        </mat-card>
    </div>



    <div *ngIf="data['type']=='createInviteVisitorNew'">

        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            {{'createInvitation' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>


            <div>


                <div class="container">
                    <div class="row step-one-visitors">
                        {{'stepOneVisitorInformation' | translate}}
                    </div>

                    <div class="row">
                        <form class="form-group col-lg-12" [formGroup]="addVisitorInfo" 
                        (ngSubmit)="onSubmitaddVisitors()">
                        <div class="form-row">
                            <div class="form-group col-md-2" *ngIf="!!isVisitorName">
                                <label>{{'name' | translate}}&nbsp;<span *ngIf="vmsConfig.preInvite?.visitorConfig?.commonVisitorConfig?.fields?.visitorName?.required">*</span>
                                </label>
                                <input [(ngModel)]="visitorInfo_name" class="form-control" (keypress)="keyPress($event)"
                                formControlName="visitorName" name="name" type="text" 
                                [ngClass]="{ 'is-invalid': submitted && f.visitorName.errors }">
                                <div *ngIf="submitted && f.visitorName.errors" class="invalid-feedback">
                                    <div *ngIf="f.visitorName.errors.required">{{ 'Pleaseenterthename' | translate }}</div>
                                  </div>
                            </div>
                            <div class="form-group col-md-2" *ngIf="!!isVisitorEmail">
                                <label>{{'email' | translate}}&nbsp;<span *ngIf="vmsConfig.preInvite?.visitorConfig?.commonVisitorConfig?.fields?.visitorEmail?.required">*</span></label>
                                <input [(ngModel)]="visitorInfo_email" class="form-control" (keypress)="keyPress($event)"
                                formControlName="visitorEmail" name="email" type="text" 
                                [ngClass]="{ 'is-invalid': submitted && f.visitorEmail.errors }">
                                <div *ngIf="submitted && f.visitorEmail.errors" class="invalid-feedback">
                                    <div *ngIf="f.visitorEmail.errors.required">{{ 'pleaseenteremail' | translate }}</div>
                                  </div>
                            </div>
                            <div class="form-group col-md-3" *ngIf="!!isVisitorMobile">
                                <label>{{'mobileCountryCodeReq' | translate}}&nbsp;<span *ngIf="vmsConfig.preInvite?.visitorConfig?.commonVisitorConfig?.fields?.visitorPhone?.required">*</span>
                                </label>
                                <input [(ngModel)]="visitorInfo_mobile" class="form-control" (keypress)="keyPress($event)"
                                formControlName="visitorMobile" type="text" name="phone" 
                                placeholder="+xx-xxxxxxxxxxx" 
                                [ngClass]="{ 'is-invalid': submitted && f.visitorMobile.errors }">
                                <div *ngIf="submitted && f.visitorMobile.errors" class="invalid-feedback">
                                    <div *ngIf="f.visitorMobile.errors.required">{{ 'pleaseentermobilenumber' | translate }}</div>
                                  </div>
                            </div>
                            <div class="form-group col-md-2" *ngIf="!!isVisitorCompany">
                                <label>{{'company' | translate}}&nbsp;<span *ngIf="vmsConfig.preInvite?.visitorConfig?.commonVisitorConfig?.fields?.visitorCompany?.required">*</span>
                                </label>
                                <input [(ngModel)]="visitorInfo_company" class="form-control" (keypress)="keyPress($event)"
                                formControlName="visitorCompany" type="text" name="company" 
                                [ngClass]="{ 'is-invalid': submitted && f.visitorCompany.errors }">
                                <div *ngIf="submitted && f.visitorCompany.errors" class="invalid-feedback">
                                    <div *ngIf="f.visitorCompany.errors.required">{{ 'pleaseentercompany' | translate }}</div>
                                  </div>
                            </div>
                            <div class="col-md-2">
                                <!-- <button mat-raised-button color="primary" class="add-visitor-btn btn-main" (click)='addVisitor()'
                                    [disabled]="!isAddVisitorActive()">Add</button> -->
                                    <button mat-raised-button color="primary" class="add-visitor-btn btn-main"
                                    >{{'add' | translate}}</button>
                            </div>
                        </div>

                        </form>

                    </div>


                    <div *ngIf="visitorList.length" class="visitor-list-container  visitor-list">
                        <mat-table [dataSource]="dataSource" matSort>

                            <ng-container matColumnDef="no">
                                <mat-header-cell *matHeaderCellDef> {{'SlNo' | translate}} </mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index;">{{i + 1}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="visitor">
                                <mat-header-cell *matHeaderCellDef>{{'visitor' | translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.name}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="email">
                                <mat-header-cell *matHeaderCellDef>{{'email' | translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.email }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="phone">
                                <mat-header-cell *matHeaderCellDef>{{'phone' | translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.mobile}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="company">
                                <mat-header-cell *matHeaderCellDef>{{'company' | translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.company}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="remove">
                                <mat-header-cell *matHeaderCellDef>{{'remove' | translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index;">
                                    <div (click)="removeVisitor(i)">
                                        <i class="material-icons">
                                            {{'close' | translate}}
                                        </i>
                                    </div>


                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                            <mat-row *matRowDef="let element; columns:columns"></mat-row>


                        </mat-table>
                    </div>



                    <!-- visitors end -->
                    <div class="row visitor_bulk_upload">
                        <div class="sample-csv-visitor"> <i><a style="color:'red'" class="sample-csv-link"
                                    href="../../../../assets/sample.csv" target="_blank">{{'downloadSample' | translate}}</a></i></div>
                        <div class="upload-visitor-csv">
                            {{'bulkUpload' | translate}}

                            <input type="file" (change)="uploadVisitorsCSV($event)" placeholder="{{'uploadFile' | translate}}"
                                accept=".csv" #uploadBulkVisitor>
                        </div>
                        <div class="csv-upload-div" (click)="uploadBulkVisitor.click()">
                            <div class="row">
                                <div class="col-md-12 text-left">
                                    <img class="upload-icon-img"  color ="#0f1f54"  src="../../../../assets/images/upload-icon.png" />
                                    {{'bulkUpload' | translate}}
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- host detail start -->
                    <div class="row step-one-visitors">
                        {{'stepTwoHostInformation' | translate}}
                    </div>

                    <div class="row">

                        <div class="col-md-2">

                            <label>{{'MeetingStartTime' | translate}}</label>
                            <input class="form-control" [owlDateTime]="inviteStartsOn" 
                                [owlDateTimeTrigger]="inviteStartsOn" readonly [min]="minDate"
                                [(ngModel)]="hostInfo.scheduleStart">
                            <owl-date-time [pickerType]="'both'" [hour12Timer]="true" #inviteStartsOn></owl-date-time>

                        </div>



                        <div class="col-md-2">

                            <label>{{'MeetingEndTime' | translate}}</label>
                            <input class="form-control" type="text" readonly [min]="hostInfo.scheduleStart"
                                [owlDateTime]="inviteEndsOn" [owlDateTimeTrigger]="inviteEndsOn"
                                [(ngModel)]="hostInfo.scheduleEnd">
                            <owl-date-time [pickerType]="'both'" [hour12Timer]="true"  #inviteEndsOn></owl-date-time>

                        </div>




                        <div class="col-md-2">

                            <label>{{'MeetingPurpose' | translate}}</label>
                            <input class="form-control" type="text" [(ngModel)]="hostInfo.purpose">

                        </div>

                        <div class="col-md-3">

                            <label>{{'hostDetailIdEmailName' | translate}}</label>
                            <input class="form-control" type="text" [matAutocomplete]="auto"
                                (keyup)="onHostChange($event.target.value)" [(ngModel)]="hostInfo.host">

                            <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged()">
                                <mat-option *ngFor="let host of hosts"
                                    [value]="host.userId+'|'+host.email+'|'+host.name+'|'+host.tenantId">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <img class="example-option-img" aria-hidden
                                                [src]="hostImgageUrl(host.imageUrl)" height="30">
                                        </div>
                                        <div class="col-md-6">
                                            <small >{{host.name | uppercase}} | </small>
                                            <small >{{ host.userId+' | '+host.email }}</small>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>


                        </div>

                        <div class="col-md-3">

                            <label>{{'locationZoneNameAddress' | translate}} </label>
                            <input class="form-control" type="text" [matAutocomplete]="locationAuto"
                                (click)="onClickLocationBox()" [(ngModel)]="hostInfo.location">
                            <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
                            <mat-autocomplete #locationAuto="matAutocomplete">
                                <mat-option *ngFor="let location of locations"
                                    [value]="location.zoneId+'|'+location.name+','+location.address">
                                    {{ location.zoneId +"|"+location.name +","+location.address}}
                                </mat-option>
                            </mat-autocomplete>


                        </div>





                    </div>


                    <div class="row invite-action-btn">
                        <div class="col-md-12">
                            <div style="float:left;"><button mat-raised-button color="primary" class="btn btn-main"
                                    (click)="cancelInvitation()">{{'cancel' | translate}}</button></div>
                            <div style="float:right;"><button [disabled]="!isSendInvitation()" type="submit"
                                    mat-raised-button color="primary" class="btn btn-main" (click)="sendInvitation()">
                                    {{'sendInvitation' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <!-- end -->
        </mat-card>
    </div>

    <div *ngIf="data['type'] == 'customChecklist'">

    </div>


    <div *ngIf="data['type'] == 'additionalDetails'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            {{'additionalDetails' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <mat-card-content>
                <div style="margin-top:20px;" *ngIf="model.optionsq.length>0">
                    <form name="form" (ngSubmit)="f.form.valid && onSubmitAdditionalDetails()" #f="ngForm" novalidate>
                        <ng-container *ngFor="let opt of model.optionsq; let k = index">
                            <div class="form-group row mg-10">
                                <div class="col-lg-4">
                                    <label>{{'idType' | translate}}</label>
                                    <input type="text" class="form-control" placeholder="{{'enterIdType' | translate}}"
                                        [(ngModel)]="opt.qoption" disabled name="qoption-{{k}}"
                                        [ngClass]="{ 'is-invalid': f.submitted && qoption.invalid }" required
                                        #qoption="ngModel">
                                </div>
                                <div class="col-lg-4">
                                    <label>{{'idName' | translate}}</label>
                                    <!-- <input type="text" class="form-control" placeholder="Enter ID Type/Number" [(ngModel)]="opt.typenumber" name="typenumber-{{k}}" [ngClass]="{ 'is-invalid': f.submitted && typenumber.invalid }" required #typenumber="ngModel"> -->
                                    <input type="text" class="form-control" placeholder="{{'enterIdTypeOrNumber' | translate}}"
                                        [(ngModel)]="opt.typenumber" name="typenumber-{{k}}">

                                </div>
                                <div class="col-lg-4">
                                    <!-- <span *ngIf="k==0"  class="addOptions" (click)="addOptions()">+Add More</span> -->
                                    <!-- <small *ngIf="k>1" ><a href="javascript:void(0)" (click)="removeAdditionalInfo(k)"><i class="fa fa-times-circle remove-circle"></i></a></small> -->
                                </div>


                            </div>
                        </ng-container>
                        <div class="col-md-2">
                            <button mat-raised-button color="primary" class="add-visitor-btn btn-main"
                                (click)='saveAdditionalDetails()'>{{'save' | translate}}</button>
                        </div>
                    </form>
                </div>
                <div style="margin-top:20px;" *ngIf="model.optionsq.length==0">
                    <p>{{'noDetailsAvailableToenter' | translate}}</p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <!-- check out for visitor -->
    <div *ngIf="data['type']=='checkOut'"  >

        <mat-card class='example' >
            <div class="row" style='width: inherit;' >
                <div class="col-md-10">
                    <mat-card-header>
                        <div >
                           <span> {{'areYouSureWantToCheckOut' | translate}} {{data['visitorName']}}
                        </span>
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-2">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <mat-card-content style='width: inherit;'>
                   <div class='printQr'> 
                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                          <span class="right-text-company" style="font-weight: bold">
                                 </span>
                        </div>

                    </div>

                    <div class="row qr-row" >
                        <div class="col-md-12 qr-left-title">
                            <button class="checkbtn btn-main" mat-raised-button  
                                printSectionId="print-section" (click)="checkOutUser()">
                                {{'checkOut' | translate}}</button>

                            <button class="checkbtn1 btn-main" mat-raised-button color="primary"
                                printSectionId="print-section" (click)="closePopup()">
                                {{'cancel' | translate}}</button>
                        </div>

                    </div>
 
                </div>


            </mat-card-content>

        </mat-card>
    </div>


    <!-- check Out for MY VISTIOR -->


    <div *ngIf="data['type']=='checkOutMyVisitor'"  >

        <mat-card class='example' >
            <div class="row" style='width: inherit;' >
                <div class="col-md-10">
                    <mat-card-header>
                        <div >
                           <span> {{'areYouSureWantToCheckOut' | translate}} {{data['visitorName']}}
                        </span>
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-2">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <mat-card-content style='width: inherit;'>
                   <div class='printQr'> 
                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                          <span class="right-text-company" style="font-weight: bold">
                                 </span>
                        </div>

                    </div>

                    <div class="row qr-row" >
                        <div class="col-md-12 qr-left-title">
                            <button class="checkbtn btn-main" mat-raised-button color="primary"
                                printSectionId="print-section" (click)="checkOutMyVistiotor()">
                                {{'checkOut' | translate}}</button>

                            <button class="checkbtn1 btn-main" mat-raised-button color="primary"
                                printSectionId="print-section" (click)="closePopup()">
                                {{'cancel' | translate}}</button>
                        </div>

                    </div>
 
                </div>


            </mat-card-content>

        </mat-card>
    </div>


    <mat-card class="example-card" *ngIf="pageType == 'showDetails'">
        <div class="row">
            <div class="col-md-6" >
                <div class="visitor-info-header" >
                    {{'preview' | translate}}
                </div>
            </div>
            <div class="col-md-6">
                <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider> 
        <mat-card-content> 
            <div *ngFor=' let item of alldetailsList'>
                <div class="row"> 
                      <div class="col-md-9 visitor-details">
                        <div class="visitor-details-inner">

                            <div class="row detail-row">

                                <div class="col-md-5 user-detail-value">{{'fieldType' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{item.fieldType}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-5 user-detail-value">{{'IsMandatory' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{item.isMandatory}}</div>
                            </div> 

                        </div>
                    </div>
                    <div class="col-md-3">

                    </div>
                </div>
                <hr>
                <span><b style="margin-left: 21px;">{{item.question}}</b></span> 
             <div class="row" style="margin-left: 15px;">

            <div class='row' *ngIf = 'item.fieldType == "RADIO"'   >
                
                <div class='col-md-8'  > 
                  <mat-radio-group class="example-radio-group" >
                    <mat-radio-button class="example-radio-button"  *ngFor="let ans of item?.option " [value]="ans.val" style="margin-left:10px;font-size: 20px;">
                      <b style="margin-left: 21px;">{{ans.val}} </b>   
                    </mat-radio-button>
                  </mat-radio-group> 

                </div>
                  
               </div>
                

                <div *ngIf = 'item.fieldType == "CHECKBOX"' class='row'  >
                    <br>
                      <div class='col-md-8' >
                        <section  *ngIf ="!!item?.option"  style="margin-right: 10px;" >  
                           <mat-checkbox *ngFor="let ans of item?.option"  [checked]="ans.checked"    ngDefaultControl  style="margin-left:20px;font-size: 20px;" >
                                    <b style="font-size: 20px;">{{ans.val}}</b>
                          </mat-checkbox> 
                     </section>
                      </div>    
                 </div>
                 <div *ngIf = 'item.fieldType == "TEXT"' class='row'> 
                  <div class='col-md-4'><br>
                      <input type ="text" class="form-control" style="width: 100px">
                
                  </div>    
                </div>
                <div *ngIf = 'item.fieldType == "DATE"' class='row'> 
                    <!-- <span>Selected Type</span>  -->
                    <div class='col-md-4'>
                          <br> 
                        <input type ="text" [(ngModel)]="item .selectedvalue"  style="width: auto" class="form-control">
                  
                    </div>    
                  </div>

                  <div *ngIf = 'item.fieldType == "DROPDOWN"' class='row'> 
                    <!-- <span>Selected Type</span>  -->
                    <div class='col-md-4'>
                          <br> 
                         <select style="width: 150px" class="form-control">
                             <!-- <option></option> -->
                             <option *ngFor="let it of item.selectedvalue">{{it}}</option>
                         </select>
                  
                    </div>    
                  </div>
                  
             </div>
            </div>
            
        </mat-card-content>
    </mat-card>

    <mat-card class="example-card" *ngIf="pageType == 'showDetailsHealth'">
        <div class="row">
            <div class="col-md-6" >
                <div class="visitor-info-header" >
                    {{'preview' | translate}}
                </div>
            </div>
            <div class="col-md-6">
                <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider> 
        <mat-card-content> 
            <div *ngFor=' let item of alldetailsListHealth'>
                <div class="row"> 
                      <div class="col-md-9 visitor-details">
                        <div class="visitor-details-inner">

                            <div class="row detail-row">

                                <div class="col-md-5 user-detail-value">{{'fieldType' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{item.fieldType}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-5 user-detail-value">{{'IsMandatory' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{item.isMandatory}}</div>
                            </div> 

                        </div>
                    </div>
                    <div class="col-md-3">

                    </div>
                </div>
                <hr>
                <span><b style="margin-left: 21px;">{{item.question}}</b></span> 
             <div class="row" style="margin-left: 15px;">

            <div class='row' *ngIf = 'item.fieldType == "RADIO"'   >
                
                <div class='col-md-8'  > 
                  <mat-radio-group class="example-radio-group" >
                    <mat-radio-button class="example-radio-button"  *ngFor="let ans of item?.option " [value]="ans.val" style="margin-left:10px;font-size: 20px;">
                      <b>{{ans.val}} </b>   
                    </mat-radio-button>
                  </mat-radio-group> 

                </div>
                  
               </div>
                

                <div *ngIf = 'item.fieldType == "CHECKBOX"' class='row'  >
                    <br>
                      <div class='col-md-8' >
                        <section  *ngIf ="!!item?.option"  style="margin-right: 10px;" >  
                           <mat-checkbox *ngFor="let ans of item?.option"  [checked]="ans.checked"    ngDefaultControl  style="margin-left:20px;font-size: 20px;" >
                                    <b style="font-size: 20px;">{{ans.val}}</b>
                          </mat-checkbox> 
                     </section>
                      </div>    
                 </div>
                 <div *ngIf = 'item.fieldType == "TEXT"' class='row'> 
                  <div class='col-md-4'><br>
                      <input type ="text" class="form-control" style="width: 100px">
                
                  </div>    
                </div>
                <div *ngIf = 'item.fieldType == "DATE"' class='row'> 
                    <!-- <span>Selected Type</span>  -->
                    <div class='col-md-4'>
                          <br> 
                        <input type ="text" [(ngModel)]="item .selectedvalue"  style="width: auto" class="form-control">
                  
                    </div>    
                  </div>

                  <div *ngIf = 'item.fieldType == "DROPDOWN"' class='row'> 
                    <!-- <span>Selected Type</span>  -->
                    <div class='col-md-4'>
                          <br> 
                         <select style="width: 150px" class="form-control">
                             <!-- <option></option> -->
                             <option *ngFor="let it of item.selectedvalue">{{it}}</option>
                         </select>
                  
                    </div>    
                  </div>
                  
             </div>
            </div>
            
        </mat-card-content>
    </mat-card>
</div>