import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DemandRequestModalComponent } from "../../../demand-request/demand-request-modal/demand-request-modal.component";

@Component({
    selector: "app-relocation-modal",
    templateUrl: "./relocation-modal.component.html",
    styleUrls: ["./relocation-modal.component.scss"],
})
export class RelocationModalComponent implements OnInit {
    dataSource;
    displayedColumns = [
        "building",
        "floor",
        "workstations",
        "employees",
        "moveEmployees",
    ];
    spans = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DemandRequestModalComponent>
    ) {}

    ngOnInit() {
        // console.log("rowDatas", this.data["rowDatas"]);
        this.dataSource = this.data["rowDatas"].reduce((current, next) => {
            next.floors.forEach((b) => {
                if (next.userIds[b.zoneId]) {
                    current.push({
                        building: next.building,
                        floor: b,
                        selected: "custom",
                        user: next.user[b.zoneId],
                        count: next.userIds[b.zoneId].length,
                        users: next.userIds[b.zoneId],
                    });
                }
            });
            return current;
        }, []);
        this.cacheSpan("building", (d) => d.building);
    }

    closePopup() {
        this.dialogRef.close();
    }
    getRowSpan(col, index) {
        const f = this.spans[index] && this.spans[index][col];
        return f;
    }

    cacheSpan(key, accessor) {
        for (let i = 0; i < this.dataSource.length; ) {
            let currentValue = accessor(this.dataSource[i]);
            let count = 1;

            // Iterate through the remaining rows to see how many match
            // the current value as retrieved through the accessor.
            for (let j = i + 1; j < this.dataSource.length; j++) {
                if (currentValue != accessor(this.dataSource[j])) {
                    break;
                }
                count++;
            }

            if (!this.spans[i]) {
                this.spans[i] = {};
            }

            // Store the number of similar values that were found (the span)
            // and skip i to the next unique row.
            this.spans[i][key] = count;
            i += count;
        }
    }
    // Selected rows
    radioSelected(element) {
        // console.log('Element', element);
    }
    proceed() {
        let data = {
            rowDatas: this.dataSource,
            departmentInfo: this.data["departmentInfo"],
        };
        this.dialogRef.close(data);
    }
}
