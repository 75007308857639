import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, shareReplay, timeout, tap, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";

@Injectable({
    providedIn: "root",
})
export class OnboardingSpaceService {
    moduleId;
    BASEURL;
    EMSURL;
    upload_Url;
    constructor(
        private http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        private toastrService: ToastrService,
        private moduleidService: ModuleidService
    ) {
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL;
        this.upload_Url = environment.BASE_UPLOAD_URL;
        this.moduleId = this.moduleidService.getModueId();
    }
    uploadFile(file, data, zoneId, isDarkMode = false) {
        let url = `${this.upload_Url}webconversion/generateTilesUpdated`;
        if(!!isDarkMode) {
            url = `${this.upload_Url}webconversion/generateTilesUpdatedDark`;
        }
        const formData: FormData = new FormData();
        formData.append("file", file, file.name);
        formData.append("floorDetails", JSON.stringify(data));

        return this.http
            .post(
                url,
                formData
            )
            .pipe(
                // timeout(500000),
                catchError((err) => {
                    let errorMsgData = (err as any).error;
                    const message = errorMsgData["message"];
                    this.toastrService.error(message);
                    return throwError(err);
                })
            );
    }

    // uploadFile(file, data, zoneId) {
    //     const formData: FormData = new FormData();
    //     formData.append("file", file, file.name);
    //     formData.append("floorDetails", JSON.stringify(data));
    //     // return this.http
    //     //     .post(
    //     //         `${this.base_url}/webconversion/generateTilesUpdated`,
    //     //         formData
    //     //     )

    //     return this.http
    //         .post(
    //             `${this.upload_Url}webconversion/generateTilesUpdated`,
    //             formData
    //         )
    //         .pipe(
    //             catchError(err => {
    //                 let errorMsgData = (err as any).error;
    //                 const message = errorMsgData["message"];
    //                 this.toastrService.error(message);
    //                 return throwError(err);
    //             }),
    //             timeout(1000000),
    //             shareReplay()
    //         );
    // }
    getOnboardedFloorByZone(zoneId, parentZoneId): Observable<any> {
        /*const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    '{"version":"1.0","clientKey":"END_USER_MOBILE_APP","zoneId":5,"moduleId":3,"sessionId":"2c234e44-c39f-4a0b-ba78-52992466abbd"}'
            })
        };*/
        return (
            this.http
                .get(
                    `${this.BASEURL}onboarding/floorOnboarding?zoneId=${zoneId}`,
                    {
                        params: new ZoneAndModuleHttpParams(
                            parentZoneId,
                            this.moduleId
                        ),
                    }
                )
                // this.http
                //     .get("http://localhost:4200/assets/onboardEdit.json")
                /*this.http
                .get(
                    `http://10.8.0.66:9084/spacemanagement/onboarding/floorOnboarding?zoneId=${zoneId}`,
                    {
                        params: new ZoneAndModuleHttpParams(
                            parentZoneId,
                            this.moduleId
                        )
                    }
                    httpOptions
                )*/
                .pipe(
                    catchError((err) => {
                        let errorMsgData = (err as any).error;
                        const message = errorMsgData["message"];
                        // this.toastrService.error(message);
                        return throwError(err);
                    }),
                    shareReplay(),
                    map((res) => res["response"])
                )
                
        );
    }
    onboardEntities(data, zoneId): Observable<any> {
        let url = `${this.BASEURL}onboarding/onboardEntities`;
        return this.http.post(
            url,
            data,
            { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) }
        );
    }
}
