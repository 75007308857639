import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-asset-modal',
  templateUrl: './asset-modal.component.html',
  styleUrls: ['./asset-modal.component.scss']
})
export class AssetModalComponent implements OnInit {

  currentZone;

  constructor(public dialogRef: MatDialogRef<AssetModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currentZone = this.data["currentZone"];
   }

  ngOnInit() {}

  closePopup() {
    this.dialogRef.close();
  }
}
