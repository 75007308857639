<div class="container-fluid mt-4">
    <div class="col">
        <mat-radio-group
            class="float-left"
            [(ngModel)]="moveType"
            (change)="onChange()"
        >
            <mat-radio-button value="recommended">{{
                "recommended" | translate
            }}</mat-radio-button>
            <mat-radio-button class="ml-4" value="manual">{{
                "manual" | translate
            }}</mat-radio-button>
        </mat-radio-group>
    </div>
    <ng-container *ngIf="moveType == 'recommended'">
        <div class="col d-flex flex-wrap p-0 mb-4" *ngIf="recommendedList">
            <div
                class="col-lg-4 col-md-4 col-sm-12 mt-4"
                *ngFor="let option of recommendedList.values; let i = index"
            >
                <div>
                    <b class="ml-3">Option {{ i + 1 }}</b>
                </div>
                <mat-card
                    class="cardSize mt-2 mat-elevation-z3 fs-14"
                    [class.active]="selectedIndex === i"
                    (click)="selectedIndex = i; getFloorIds(option)"
                >
                    <div *ngFor="let buildings of option; let j = index">
                        <div class="">
                            <div class="col buildingTitle mat-elevation-z1">
                                <b>{{ buildings?.building?.name }}</b>
                            </div>
                            <ng-container
                                *ngFor="let floor of buildings.floorCounts"
                            >
                                <div class="d-flex align-items-center mt-3">
                                    {{ floor?.zoneLocationDto?.name }}
                                    <ng-container
                                        *ngFor="
                                            let workstation of floor.entityTypeCounts;
                                            let k = index
                                        "
                                    >
                                        <div
                                            class="totalCircle circlePosition"
                                            [ngStyle]="{
                                                'background-color': getLegendColor(
                                                    'recommended',
                                                    workstation.entityType.id
                                                )
                                            }"
                                            [ngClass]="k > 0 ? '' : ''"
                                        ></div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="col d-flex">
            <div class="col-5 d-flex flex-wrap">
                <div
                    class="d-flex align-items-center"
                    *ngFor="
                        let entity of recommendedList['entityTypeMap']
                            | keyvalue;
                        let m = index
                    "
                >
                    <div
                        class="smallCircle ml-3"
                        [ngStyle]="{
                            'background-color': getLegendColor(
                                'recommended',
                                entity.key
                            )
                        }"
                        [ngClass]="m > 0 ? 'ml-3' : ''"
                    ></div>
                    <div class="ml-2 fs-14">
                        {{ entity.value.name }}
                    </div>
                </div>
            </div>
            <div class="col-3 offset-4 d-flex">
                <!-- <div #automatically class="col buttons d-flex justify-content-center align-items-center"
                    (click)="addOrRemoveClass(manually, automatically)">
                    {{'assignWorkstationsAutomatically'|translate}}
                </div>
                <div #manually class="col buttons d-flex justify-content-center align-items-center ml-4"
                    (click)="addOrRemoveClass(automatically, manually)">
                    {{'assignWorkstationsManually'|translate}}
                </div> -->
                <button
                    class="col buttons d-flex justify-content-center align-items-center ml-4 rounded-pill"
                    (click)="submit('automatically', 'manually')"
                >
                    {{ "submit" | translate }}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="moveType == 'manual'">
        <div class="col p-0 mt-5" *ngIf="availabilityList">
            <div class="col d-flex flex-wrap p-0">
                <div
                    class="col-lg-4 col-md-4 col-sm-12 mt-4"
                    *ngFor="
                        let building of availabilityList.values;
                        let i = index
                    "
                >
                    <div>
                        <b class="ml-3">{{ building.building.name }}</b>
                    </div>
                    <mat-card class="cardSize mt-2 mat-elevation-z3">
                        <div
                            *ngFor="
                                let floor of building.floorCounts;
                                let j = index
                            "
                        >
                            <div
                                *ngIf="floor.entityTypeCounts.length"
                                class="vh-25 position-relative borderBottom"
                                [ngClass]="{
                                    borderBottom:
                                        j != building.floorCounts.length - 1
                                }"
                            >
                                <div
                                    class="d-flex justify-content-between"
                                    [ngClass]="{ 'mt-3': j > 0 }"
                                >
                                    <div>
                                        {{ floor.zoneLocationDto.name }}
                                    </div>
                                    <mat-checkbox
                                        class=""
                                        (change)="check($event, floor)"
                                    >
                                    </mat-checkbox>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <ng-container
                                        *ngFor="
                                            let workstation of floor.entityTypeCounts;
                                            let k = index
                                        "
                                    >
                                        <div
                                            class="totalCircle circlePosition"
                                            [ngStyle]="{
                                                'background-color': getLegendColor(
                                                    'manual',
                                                    workstation.entityType.id
                                                )
                                            }"
                                            [ngClass]="k > 0 ? '' : ''"
                                        ></div>
                                    </ng-container>
                                    <div class="col">
                                        {{ floor.totalCountAvailable }}/{{
                                            floor.countRequired
                                        }}
                                    </div>
                                </div>
                                <div class="d-flex mt-3">
                                    <div
                                        class="align-items-center"
                                        *ngFor="
                                            let workstation of floor.entityTypeCounts;
                                            let l = index
                                        "
                                        [ngClass]="
                                            floor.entityTypeCounts.length == 1
                                                ? 'd-none'
                                                : 'd-flex'
                                        "
                                    >
                                        <div
                                            class="smallCircle"
                                            [ngClass]="l > 0 ? 'ml-3' : ''"
                                            [ngStyle]="{
                                                'background-color': getLegendColor(
                                                    'manual',
                                                    workstation.entityType.id
                                                )
                                            }"
                                        ></div>
                                        <div class="ml-2">
                                            {{ workstation.availableCount }}/{{
                                                getRequiredWorkstationCount(
                                                    "individual",
                                                    workstation.entityType.id
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="col d-flex align-items-center mt-5">
            <div class="col-2 pl-lg-0 align-items-center">
                <label>{{ "demandCompletion" | translate }}</label>
                <!-- <mat-progress-bar mode="determinate" value="{{progress}}" class="progressBar"></mat-progress-bar> -->
                <div class="col p-0 progressPercent">{{ progress }}%</div>
            </div>
            <div class="col-3 d-flex flex-wrap">
                <div
                    class="d-flex align-items-center"
                    *ngFor="
                        let entity of availabilityList.entityTypeMap | keyvalue;
                        let m = index
                    "
                >
                    <div
                        class="smallCircle ml-3"
                        [ngStyle]="{
                            'background-color': getLegendColor(
                                'manual',
                                entity.key
                            )
                        }"
                        [ngClass]="m > 0 ? 'ml-3' : ''"
                    ></div>
                    <div class="ml-2 fs-14">
                        {{ entity.value.name }}
                    </div>
                </div>
            </div>
            <!-- <div #automatically class="col buttons d-flex justify-content-center align-items-center"
                (click)="addOrRemoveClass(manually, automatically)">
                {{'assignWorkstationsAutomatically'|translate}}
            </div>
            <div #manually class="col buttons d-flex justify-content-center align-items-center ml-4"
                (click)="addOrRemoveClass(automatically, manually)">
                {{'assignWorkstationsManually'|translate}}
            </div> -->
            <button
                class="col buttons d-flex justify-content-center align-items-center ml-4 rounded-pill"
                (click)="submit('automatically', 'manually')"
            >
                {{ "submit" | translate }}
            </button>
        </div>
    </ng-container>
</div>
