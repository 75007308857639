<div class="col">
    <div class="row mt-4">
        <div class="col-md-6">
            <mat-card-header>
                <div class="info-header">
                    <h5 class="colorPrimary font-weight-bold">{{'deleteAmenities' | translate}}</h5>
                </div>

            </mat-card-header>
        </div>
        <div class="col-md-6">
            <button type="button" id="close_popup" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">×</span>
            </button>
        </div>

    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mt-4">
        <p class="font-weight-bold"> {{ "wouldLikeToDeleteTheSelectedAmenity" | translate }} </p>
    </mat-dialog-content>
    <div class="row mb-4">
        <div class="col-6">
            <button id="allDesks" type="submit" class="btn btn-main mt-4 w-100 buttonRounded" (click)="submit('all')">{{ "fromAllDesks" | translate }}</button>
        </div>
        <div class="col-6">
            <button id="selectDesks" type="submit" class="btn btn-main mt-4 w-100 buttonRounded" (click)="submit('select')">{{ "selectDesks" | translate }}</button>
        </div>
    </div>
</div>
