<div class="scroller">
    <div class="modal-card fixedHeight" *ngIf="pageType == 'addSpaces'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{"addASpace" | translate}}
            </h5>
            <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true" (click)="closePopup()">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading
                title="{{'globalSetupBooking' | translate}}"
            ></app-sub-heading>
            <form [formGroup]="addSpaces" (ngSubmit)="saveSpaces('ADD')">
                <div class="pl-0 mt-4">
                    <div class="row col-12 px-0">
                        <div class="form-group col-lg-4 col-md-12 pr-0">
                            <label class="float-left formLabel"
                                >{{"spaceType" | translate}}
                            </label>
                            <mat-select
                                formControlName="spaceType"
                                class="form-control"
                                placeholder="{{'selectHere' | translate}}"
                                name="space type"
                            >
                                <mat-option
                                    [value]="spaceValue.value"
                                    *ngFor="let spaceValue of spaceArray"
                                >
                                    {{ getTranslate(spaceValue.name)}}
                                </mat-option>
                            </mat-select>

                            <div
                                *ngIf="
                                    submittedSpaces &&
                                    addSpaces.controls.spaceType.errors 
                                "
                            >
                                <p class="text-danger formLabel">
                                   {{"spaceTypeIsRequired" | translate}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12 px-0">
                        <div class="form-group col-lg-4 col-md-12 pr-0">
                            <label class="float-left formLabel"
                                >{{"spaceName" | translate}}
                            </label>
                            <input
                                type="text"
                                class="form-control blueText"
                                placeholder="{{'enterSpaceName' | translate}}"
                                formControlName="spaceName"
                                name="space name"
                            />
                            <div
                                *ngIf="submittedSpaces && addSpaces.get('spaceName').hasError('required')">
                                <p class="text-danger formLabel">
                                    {{"spaceNameIsRequired" | translate}}
                                </p>
                            </div>
                            <div *ngIf="addSpaces.get('spaceName').hasError('maxlength') && submittedSpaces">
                                <p class="text-danger formLabel"> 
                                   {{"theInputExceedsTheMaximumLength" | translate}}
                                </p>
                            </div>
                        </div>
                        <!-- <button
                    type="button"
                    class="smallBtnCircleAdd ml-3 semi-bold-blue"                    
                    (click)="getSpaceName()">
                    +
                </button>                 -->
                    </div>

                    <div class="row col-12 px-0" *ngIf="showSpaceName">
                        <div
                            class="form-group col-lg-5"
                            *ngFor="let spacename of spaces; let i = index"
                        >
                            <div
                                class="inner-card-auto regular-font d-flex justify-content-between"
                            >
                                <span>{{ spacename }}</span>
                                <span
                                    aria-hidden="true"
                                    class="cursor-pointer"
                                    (click)="removeSpaces(i)"
                                    >&times;</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row col-12 px-0">
                        <div
                            class="form-group col-lg-10 col-md-12 pr-0 float-left"
                        >
                            <p class="note pt-3">
                                {{"noteThisSpaceWillBeVisibleToAllTheOnboard" | translate}}
                            </p>
                        </div>
                        <div class="form-group col-lg-2 col-md-12 pr-0">
                            <button
                                class="btn btn-main btnSmall  semi-bold-blue ml-3 smallBtn float-right"
                                type="submit"
                            >
                                {{"add" | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-card fixedHeight" *ngIf="pageType == 'uploadImage'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <!-- <i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtn(pageType)"></i>  -->
                {{ spaceNameTitle }}
            </h5>
            <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true" (click)="closePopup()">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <!-- <app-sub-heading title="Global Setup > Booking Pro"></app-sub-heading> -->
            <div class="col-12">
                <div class="text-center">
                    <img
                        src="assets/images/amenity_booking/Layer_2.svg"
                        class="w-35"
                    />
                </div>
            </div>
            <form class="text-center" (ngSubmit)="onSubmitAmenitiesBookable()">
                <div class="form-row">
                    <!-- <div class="form-group col-lg-12 col-md-12 align-left">
                <p class="exampleFormControl">{{'enternameofamenity' | translate}}</p>
                <input type="text" class="form-control" id="amenity_name" placeholder="" name="amenity_name"
                    [(ngModel)]="amenity.amenityName" [ngModelOptions]="{standalone: true}">
            </div> -->
                    <div class="form-group col-lg-2 col-md-2">
                        <img
                            *ngIf="!!appImagepathImage"
                            style="width:50px;height:50px;margin-top:9px;"
                            [src]="appImagepathImage"
                            class="w-35"
                        />
                    </div>
                    <div class="form-group col-lg-6 col-md-6 align-left mb-0">
                        <label class="w-100 mt-3">
                            <!-- {{ "uploadapppicture" | translate }} -->
                            <span></span>
                            <div class="custom-file">
                                <input
                                    [(ngModel)]="amenity.upload_appUrl"
                                    [ngModelOptions]="{ standalone: true }"
                                    type="file"
                                    class="form-control custom-file-input mt-2"
                                    id="importFile"
                                    (change)="
                                        onChooseFileApp(
                                            $event.target.files,
                                            $event
                                        )
                                    "
                                    aria-describedby="inputGroupFileAddon01"
                                    placeholder="{{
                                        'uploadapppicture' | translate
                                    }}"
                                />

                                <label
                                    class="custom-file-label custom-upload-btn customInput"
                                    for="inputGroupFile01"
                                    #labelImport
                                >
                                    <span
                                        *ngIf="!appFileName"
                                        class="inputPlaceholder"
                                        >{{
                                            "uploadapppicture" | translate
                                        }}</span
                                    >
                                    {{
                                        appFileName?.length > 30
                                            ? (appFileName | slice: 0:30) +
                                              "..."
                                            : appFileName
                                    }}
                                </label>
                            </div>
                        </label>
                        <div *ngIf="upload_appUrl">
                            <span class="errorMessage mt-3"
                                >{{"Pleaseuploadapppicture" | translate}}</span
                            >
                        </div>
                    </div>
                    <div
                        class="form-group col-lg-4 col-md-4 mb-0 hint font-regular-gray"
                    >
                        {{"forApp" | translate}} <br />
                        (1130*496px)
                    </div>
                    <div
                        class="form-group col-lg-12 col-md-12 align-left mb-0 mt-1 mLeft font-regular-gray"
                    >
                        <p class="w-80">
                            *
                            {{
                                "thispicturewillappearontheappwhileuserisbookingthisamenity"
                                    | translate
                            }}
                        </p>
                    </div>
                    <div class="form-group col-lg-2 col-md-2">
                        <img
                            *ngIf="!!dashboardImagepathImage"
                            style="width:50px;height:50px;margin-top:9px;"
                            [src]="dashboardImagepathImage"
                            class="w-35"
                        />
                    </div>
                    <div class="form-group col-lg-6 col-md-6 align-left mb-0">
                        <label class="w-100 mt-3">
                            <!-- {{ "uploaddashboardpicture" | translate }}
                    <span>(440*225px)</span> -->
                            <div class="custom-file">
                                <input
                                    [(ngModel)]="amenity.upload_dashboardUrl"
                                    [ngModelOptions]="{ standalone: true }"
                                    type="file"
                                    class="form-control custom-file-input mt-2"
                                    id="importFile1"
                                    (change)="
                                        onChooseFileDashboard(
                                            $event.target.files,
                                            $event
                                        )
                                    "
                                    aria-describedby="inputGroupFileAddon02"
                                />
                                <label
                                    class="custom-file-label custom-upload-btn customInput"
                                    for="inputGroupFile02"
                                    #labelImport1
                                >
                                    <span
                                        *ngIf="!dashboardFileName"
                                        class="inputPlaceholder"
                                    >
                                        {{
                                            "uploaddashboardpicture" | translate
                                        }}
                                    </span>
                                    {{
                                        dashboardFileName?.length > 30
                                            ? (dashboardFileName
                                                  | slice: 0:30) + "..."
                                            : dashboardFileName
                                    }}
                                </label>
                            </div>
                        </label>
                        <div *ngIf="upload_appDashboardUrl">
                            <span class="errorMessage"
                                >{{"Pleaseuploadadashboardpicture" | translate}}</span
                            >
                        </div>
                    </div>
                    <div
                        class="form-group col-lg-4 col-md-4 mb-0 hint font-regular-gray"
                    >
                        {{"forDashboard" | translate}} <br />
                        (440*225px)
                    </div>
                    <div
                        class="form-group col-lg-12 col-md-12 align-left mt-1 mLeft font-regular-gray"
                    >
                        <p class="w-80">
                            *
                            {{
                                "thispicturewillappearonthedashboardfortheadmin"
                                    | translate
                            }}
                        </p>
                    </div>

                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "next" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-card fixedHeight" *ngIf="pageType == 'parking_category'">
        <div class="modal-header p-0">
            <h5 class="regular-font">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn(pageType)"
                ></i>
                {{ spaceNameTitle }}
            </h5>
            <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true" (click)="closePopup()">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'basicSetupp' | translate}}"></app-sub-heading>
            <form [formGroup]="addCategory"  (ngSubmit)="saveCategory()">
                <div class="pl-0 mt-4">
                    <div class="form-group align-left col-12">
                        <p class="font-regular-gray">
                            {{ "would" | translate }} {{ spaceNameTitle }}
                            {{ "wouldmeetingroomhavecategories" | translate }}?
                            {{ "thisisusefulforauser" | translate }}
                        </p>
                        <p class="font-regular-gray">
                            {{
                                "eg.parkingcanhavetwoorfourwheelerparking"
                                    | translate
                            }}
                        </p>
                        <div class="col-12 mt-4">
                            <div class="text-center">
                                <img
                                    src="assets/images/amenity_booking/Group_13432.svg"
                                    class="w-50"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row col-12 px-0">
                        <div class="form-group col-lg-4 col-md-12 pr-0">
                            <label class="float-left formLabel"> &nbsp;</label>
                            <input
                                type="text"
                                class="form-control blueText"
                                formControlName="addCategory"
                                name="space name"
                            />

                            <div
                                *ngIf="
                                    submittedCateogry &&
                                    addCategory.controls.addCategory.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{"catagoryIsRequired" | translate}}
                                </p>
                            </div>
                        </div>
                        <div>
                            <button
                                type="button"
                                class="smallBtnCircleAdd ml-3 semi-bold-blue"
                                (click)="getAddCategory()"
                            >
                                +
                            </button>
                        </div>
                        <div class="col-lg-4">
                            <p class="categorystyle">
                                <strong>{{ "addCategories" | translate }}</strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row col-12 px-1"
                        *ngIf="addCategoryList?.length > 0"
                    >
                        <div
                            class="form-group ml-3"
                            *ngFor="
                                let spacename of addCategoryList;
                                let i = index
                            "
                        >
                            <div
                                class="inner-card-auto regular-font d-flex justify-content-between"
                            >
                                <span>{{ spacename.name }}</span>
                                <span
                                    aria-hidden="true"
                                    class="cursor-pointer ml-3"
                                    (click)="removeCategory(spacename, i)"
                                    >&times;</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row col-12 px-0">
                        <div class="form-group col-12 text-left mb-0">
                            <button
                                *ngIf="addCategoryList.length > 0"
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                                type="submit"
                            >
                                {{ "next" | translate }}
                            </button>
                            
                            <button
                                *ngIf="addCategoryList.length == 0"
                                class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                                type="submit"
                                
                            >
                                {{ "skip" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-card fixedHeight" *ngIf="pageType == 'addons'">
        <div class="modal-header p-0">
            <h5 class="regular-font">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn(pageType)"
                ></i>
                {{ spaceNameTitle }}
            </h5>
            <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true" (click)="closePopup()">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'basicSetupp' | translate}}"></app-sub-heading>
            <form [formGroup]="addaddOns" (ngSubmit)="saveAddOns()" >
                <div class="pl-0 mt-4">
                    <div class="form-group align-left col-12">
                        <p class="font-regular-gray">
                            {{ "would"  | translate}} {{ spaceNameTitle }}
                            {{ "haveAddOns"  | translate}}
                            {{
                                "egInTheCaseOfMeetingRoomsYouCouldHaveProjectors"
                             | translate}}
                        </p>

                        <div class="col-12 mt-4">
                            <div class="text-center">
                                <img
                                    src="assets/images/amenity_booking/Group_13433.svg"
                                    class="w-50"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row col-12 px-0">
                        <div class="form-group col-lg-4 col-md-12 pr-0">
                            <label class="float-left formLabel"> &nbsp;</label>
                            <input
                                type="text"
                                class="form-control blueText"
                                formControlName="addaddOns"
                                name="addaddOns"
                            />
                            <div
                                *ngIf="
                                    submittedaddaddOns &&
                                    addaddOns.controls.addaddOns.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{ "addonIsRequired"  | translate}}
                                </p>
                            </div>
                        </div>
                        <div>
                            <button
                                type="button"
                                class="smallBtnCircleAdd ml-3 semi-bold-blue"
                                (click)="getAddAddOns()"
                            >
                                +
                            </button>
                        </div>
                        <div class="col-lg-4">
                            <strong>
                                <p class="categorystyle">{{ "addAddOns"  | translate}}</p>
                            </strong>
                        </div>
                    </div>

                    <div
                        class="row col-12 px-1"
                        *ngIf="addaddOnsList?.length > 0"
                    >
                        <div
                            class="form-group ml-3"
                            *ngFor="
                                let spacename of addaddOnsList;
                                let i = index
                            "
                        >
                            <div
                                class="inner-card-auto regular-font d-flex justify-content-between"
                            >
                                <span>{{ spacename.name }}</span>
                                <span
                                    aria-hidden="true"
                                    class="cursor-pointer  ml-3"
                                    (click)="removeAddOns(spacename, i)"
                                    >&times;</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row col-12 px-0">
                        <div class="form-group col-12 text-left mb-0">
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                                *ngIf="addaddOnsList.length > 0"
                                type="submit"
                            >
                                {{ "next" | translate }}
                            </button>
                            
                            <button
                                *ngIf="addaddOnsList.length == 0"
                                class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                                type="submit"
                                
                            >
                                {{ "skip" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-card fixedHeight" *ngIf="pageType == 'special_request'">
        <div class="modal-header p-0">
            <h5 class="regular-font">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn(pageType)"
                ></i>
                {{ spaceNameTitle }}
            </h5>
            <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true" (click)="closePopup()">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'basicSetupp' | translate}}"></app-sub-heading>
            <form
                [formGroup]="addspecialrequest" (ngSubmit)="saveAddSpecialRequest()"
               
            >
                <div class="pl-0 mt-4">
                    <div class="form-group align-left col-12">
                        <p class="font-regular-gray">
                            {{ "would" | translate }} {{ spaceNameTitle }}
                            {{ "wouldyourusersespecialrequests" | translate }}
                        </p>

                        <div class="col-12 mt-4">
                            <div class="text-center">
                                <img
                                    src="assets/images/amenity_booking/Group_13434.svg"
                                    class="w-50"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row col-12 px-0">
                        <div class="form-group col-lg-4 col-md-12 pr-0">
                            <label class="float-left formLabel"> &nbsp;</label>
                            <input
                                type="text"
                                class="form-control blueText"
                                formControlName="addspecialrequest"
                                name="Specialrequest"
                            />
                            <div
                                *ngIf="
                                    submittedaddspecialrequest &&
                                    addspecialrequest.controls.addspecialrequest
                                        .errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{ "specialRequestIsRequired" | translate }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <button
                                type="button"
                                class="smallBtnCircleAdd ml-3 semi-bold-blue"
                                (click)="getAddSpecialRequest('UPDATE')"
                            >
                                +
                            </button>
                        </div>
                        <div class="col-lg-4">
                            <p class="categorystyle">
                               <strong> {{ "addSpecialRequests" | translate }}</strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row col-12 px-1"
                        *ngIf="addspecialrequestList?.length > 0"
                    >
                        <div
                            class="form-group  ml-3"
                            *ngFor="
                                let spacename of addspecialrequestList;
                                let i = index
                            "
                        >
                            <div
                                class="inner-card-auto regular-font d-flex justify-content-between"
                            >
                                <span>{{ spacename.name }}</span>
                                <span
                                    aria-hidden="true"
                                    class="cursor-pointer ml-3"
                                    (click)="
                                        removeAddSpecialRequest(spacename, i)
                                    "
                                    >&times;</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row col-12 px-0">
                        <div class="form-group col-12 text-left mb-0">
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                                type="submit"
                            >
                                {{ "finish" | translate }}
                            </button>
                            
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-card fixedHeight" *ngIf="pageType == 'editSpaces'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "editASpace" | translate }}
            </h5>
            <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true" (click)="closePopup()">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupBooking' | translate}}"></app-sub-heading>
            <form [formGroup]="editSpaces" (ngSubmit)="saveEditSpaces()">
                <div class="pl-0 mt-4">
                    <div class="row col-12 px-0">
                        <div class="form-group col-lg-4 col-md-12 pr-0">
                            <label class="float-left formLabel"
                                >{{ "spaceType" | translate }}
                            </label>
                            <mat-select
                                formControlName="spaceType"
                                class="form-control"
                                placeholder="{{'selectHere' | translate}}"
                            >
                                <mat-option value="COLLABORATIVE">
                                    {{ "collaborativeSpaces" | translate }}
                                </mat-option>
                                <mat-option value="PERSONAL">
                                    {{ "personalSpaces" | translate }}
                                </mat-option>
                            </mat-select>

                            <div
                                *ngIf="
                                    submittedSpaces &&
                                    editSpaces.controls.spaceType.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{ "spaceTypeIsRequired" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12 px-0">
                        <div class="form-group col-lg-4 col-md-12 pr-0">
                            <label class="float-left formLabel"
                                >{{ "spaceName" | translate }}
                            </label>
                            <input
                                type="text"
                                class="form-control blueText"
                                placeholder="{{'enterSpaceName' | translate}}"
                                formControlName="spaceName"
                            />

                            <div
                                *ngIf="
                                    submittedSpaces &&
                                    editSpaces.controls.spaceName.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{ "spaceNameIsRequired" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12 px-0 mt-4">
                        <div
                            class="form-group col-lg-6 col-md-12 pr-0 float-left"
                        >
                            <p class="note">
                                {{ "noteChangesMadeWillBeApplicableTo" | translate }}
                            </p>
                        </div>
                        <div class="form-group col-lg-6 col-md-12 pr-0">
                            <button
                                class="btn btn-main btnSmall  semi-bold-blue ml-3 smallBtn float-right"
                                type="submit"
                            >
                                {{ "save" | translate }}
                            </button>
                            <button
                                class="btn btn-main btnSmall btn-main-outline semi-bold-blue smallBtn float-right"
                                type="button"
                                (click)="confirmDeletePopup()"
                            >
                               {{ "deleteSpaces" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-card fixedHeight" *ngIf="pageType == 'showDeleteAlert'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "alert" | translate }}
            </h5>
            <button type="button" class="close" aria-label="Close"></button>
        </div>
        <div class="modal-body align-left">
            <div class="pl-0 mt-3">
                <div class="row col-12 mt-5">
                    <h6 class="regular-font-blue  w-100 line-height">
                        {{ "byDeactivatingThisSpace" | translate }},
                        {{ data?.data?.title }} {{ "willNoLongerBeVisible" | translate }} 
                    </h6>
                    <h6 class="regular-font-blue  w-100 mt-3">
                        {{ "areYourSureWanttoDeactivate" | translate }}
                        {{ data?.data?.title }}?
                    </h6>
                </div>
            </div>
            <div class="mt-3">
                <ng-container>
                    <div class="form-group col-lg-12 col-md-12 pr-0">
                        <button
                            type="button"
                            class="btn btn-main ml-3  btnSmall smallBtn float-right"
                            (click)="deleteSpace()"
                        >
                            {{ "yes" | translate }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-main btnSmall btn-main-outline smallBtn float-right"
                            (click)="closePopup()"
                        >
                           {{ "no" | translate }} 
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="modal-card fixedHeight" *ngIf="pageType == 'showSpacesAdded'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "confirmation" | translate }}
            </h5>
            <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true" (click)="closePopup()">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <div class="pl-0 mt-3">
                <div class="row col-12 mt-5">
                    <h6 class="regular-font-blue pr-2 w-100">
                        {{ "youHaveSuccessfullyAddedTheGlobalSpace" | translate }}
                    </h6>
                </div>
            </div>
            <div class="customMargin">
                <ng-container>
                    <div class="form-group col-lg-12 col-md-12 pr-0">
                        <button
                            type="button"
                            class="btn btn-main btnSmall smallBtn float-right"
                            (click)="closePopup()"
                        >
                            {{"ok" | translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'addCategory'">
        <div class="modal-header pl-0 pt-0 pb-0">
            <h5 class="semi-bold-blue">
                {{ "add" | translate }} {{ addTitle }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body width-400 align-left mt-2">
            <p class="openSans italic semi-heading">
                {{ "basicSetup" | translate }}
            </p>
            <form
                [formGroup]="editAdding"
                (ngSubmit)="editSaveAddSpecialRequest('ADD')"
            >
                <div class="row col-12 px-0">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel"> &nbsp;</label>
                        <input
                            type="text"
                            class="form-control blueText"
                            formControlName="editAdding"
                            name="editAdding"
                        />
                        <div
                            *ngIf="
                                editAddingSubmit &&
                                editAdding.controls.editAdding.errors &&
                                editSaveList.length == 0
                            "
                        >
                            <p class="text-danger formLabel">
                                {{ editErrorMsg }} is required
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <button
                            type="button"
                            class="smallBtnCircleAdd ml-3 semi-bold-blue"
                            (click)="editgetAddSpecialRequest(add_name)"
                        >
                            +
                        </button>
                    </div>
                    <div class="col-lg-4">
                        <p class="categorystyle">
                            {{ "add" | translate }} {{ editErrorMsg }}
                        </p>
                    </div>
                </div>

                <div class="row col-12 px-0" *ngIf="editSaveList?.length > 0">
                    <div
                        class="form-group col-lg-5"
                        *ngFor="let spacename of editSaveList; let i = index"
                    >
                        <div
                            class="inner-card-auto regular-font d-flex justify-content-between"
                        >
                            <span>{{ spacename.name }}</span>
                            <span
                                aria-hidden="true"
                                class="cursor-pointer"
                                (click)="editRemoveAddSpecialRequest(spacename)"
                                >&times;</span
                            >
                        </div>
                    </div>
                </div>
                <div class="form-group col-12 text-left mb-0">
                    <button
                        class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                        type="submit"
                    >
                        {{ "add" | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'edit_category'">
        <div class="modal-header pl-0 pt-0 pb-0">
            <h5 class="semi-bold-blue">{{ "edit" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body width-400 align-left">
            <p class="openSans italic semi-heading">
                {{ "basicSetup" | translate }}
            </p>
            <form (ngSubmit)="editCategorySubmit('EDIT')">
                <div class="row col-12 px-0">
                    <div class="form-group align-left col-12">
                        <input
                            type="text"
                            class="form-control blue-regular col-lg-4"
                            id="category_value"
                            placeholder=""
                            name="edit_category_value"
                            [(ngModel)]="edit_name"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                    <p class="text-danger pl-3" *ngIf="showCategoryError">
                        {{ "pleaseEnterValue" | translate }}
                    </p>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "update" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
