import {
    Component,
    Inject,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    OnDestroy
  } from "@angular/core";
  import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatSnackBar,
    MatInput,
    MatSelect,
    MatOption,
    MatCheckbox,
    MatTableDataSource,
    MatRadioButton,
    MatRadioGroup,
    MatDivider
  } from "@angular/material";
  import {
    FormControl,
    FormGroup,
    FormBuilder,
    FormArray,
    Validators
  } from "@angular/forms";
  import "rxjs/add/observable/of";
  import { ReplaySubject, Subject } from "rxjs";
  import {
    debounceTime,
    delay,
    tap,
    filter,
    map,
    takeUntil
  } from "rxjs/operators";
  import { forkJoin } from "rxjs/observable/forkJoin";
  import { Observable } from "rxjs";
  import { LoginService } from "../login.service";
  import { CommonService } from "../../services/common-service.service";
  import { Router, ActivatedRoute } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
  
  @Component({
    selector: "app-login-modal",
    templateUrl: "./login-modal.component.html",
    styleUrls: ["./login-modal.component.scss"]
  })
  export class LoginModalComponent implements OnInit, AfterViewInit {
    myControl = new FormControl();
    
  
    loading = false;
    pageType= '';
    selectLanguage = '';
    selectLanguageList = [{id:7,country: "US",isDefaultLocale : false, language : "English - US", locale : "en",status : "ENABLED"}];
    constructor(
      public matSnackBar: MatSnackBar,
      public dialogRef: MatDialogRef<LoginModalComponent>,
      public dialog: MatDialog,
      public loginService: LoginService,
      public commonService: CommonService,
      public route: ActivatedRoute,
      public router: Router,
      public translate: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
  
    ngAfterViewInit() {
      
    }
  
    ngOnInit() {
      this.pageType= this.data['type'];
      if(this.pageType=='language'){
          this.selectLanguageList = this.data['listLanguage'];
      }
      console.log("this.ageType",this.pageType);
    }
  
    onCloseEventModal = () => {
      this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
    };
    onlangaugeCloseEventModal = () => {
      this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
        localStorage.setItem("language","en");
        this.translate.use( "en" );
        this.dialogRef.close();
    };
  
    onOtpChange($event){
      this.otpElength = $event.length;
      this.otp = $event;
    }
  
    private otp:number = 0;
    private otpElength:number = 0;
    public otpLength = 6;
    public errorOtp:boolean = false;
  
    confirmOtp(){
      let currentContext = this;
      if(this.otpElength != this.otpLength){
        this.errorOtp = true;
      }else{
        this.errorOtp = false;
        
        let user = {
          "id": this.data['userId'],
          "verificationEntity": this.data['verficationEntity'],
          "verificationCode": this.otp
        }
        
  
        currentContext.loginService.login(user).subscribe(
          function(res) {
              if (res) {
  
                    //   localStorage.setItem(
                    //       "ssadmin_session",
                    //       res["response"]["sessionId"]
                    //   );
                    //   localStorage.setItem(
                    //       "ssadmin_userId",
                    //       res["response"]["userId"]
                    //   );
                    //   localStorage.setItem(
                    //       "ssadmin_user",
                    //       res["response"]["email"]
                    //   );
                    //   localStorage.setItem(
                    //       "ssadmin_name",
                    //       res["response"]["name"]
                    //   );
                    //   localStorage.setItem(
                    //       "ssadmin_tenantId",
                    //       res["response"]["tenantId"]
                    //   );
                    //   localStorage.setItem(
                    //       "userObj",
                    //       JSON.stringify(res["response"])
                    //   );
  
                    //   let zoneMap = Object.keys(res["response"]["zonesMap"]);
                    //   currentContext.commonService.changeZone(zoneMap[0]);
                    //   localStorage.setItem("currentZone", zoneMap[0]);
                    //   currentContext.onCloseEventModal();
                    //   //localStorage.setItem("currentZone", "5");//needs to be deleted
                    //   currentContext.router.navigate(["/layout"]);
               
                    currentContext.dialogRef.close({'response': res["response"]});
              }
          },
          function(err) {
                  currentContext.commonService.openSnackBar(
                      err.error.message,
                      ""
                  );
          }
      );
      }
    }

    languageSubit(){
      localStorage.setItem("language",this.selectLanguage);
      this.translate.use( this.selectLanguage );
      this.dialogRef.close();
    }
  
  }  