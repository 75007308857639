import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, NgForm, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";

import { ToastrService } from "ngx-toastr";
import { NgForOfContext } from "@angular/common";
import * as _ from "lodash";
import { EventCatService } from "../service/events-cat.service";
import { LoaderService } from "loaderService";

import { CommonService } from "commonService";
import { ExcelService } from "../../services/excel.service";
@Component({
  selector: "app-event-modal",
  templateUrl: "./tenant-modal.component.html",
  styleUrls: ["./tenant-modal.component.scss"],
})
export class tenantModal implements OnInit {
  pageType;
  addType = "";
  currentZone;
  editCatName = "";
  editCatData;
  incorrectDataColumns = [
    // "slNo",
    "name",
    "reason",
  ];
  manualForm;
  fb = this.formBuilder;
  submittedExcel;
  listViewExcelResponse;
  incorrectData;
  correctData;
  noOfEntitiesUploaded;
  saveCorrectData;
  totalIncorrectLength;
  showIncorrectData;
  TotalNoOfEntitiesUploadedRNW = 0;
  submitted = false;

  category = "";
  categoryList = [];
  excelUploadedFile = null;
  showUploadedExcelFileName = "";
  constructor(
    public dialogRef: MatDialogRef<tenantModal>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private eventCatService: EventCatService,
    private toastService: ToastrService,
    private loader: LoaderService,
    private excelService: ExcelService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.pageType = data["type"];
    this.currentZone = data["currentZone"];
  }

  ngOnInit() {
    this.manualForm = this.formBuilder.group({
      categoryList: this.fb.array([]),
    });

    if (this.pageType == "editCat") {
      this.editCatData = this.data["category"];
      this.editCatName = this.editCatData.name;
    }
  }

  selectType() {
    if (this.addType == "manual") {
      this.pageType = "addEventManual";
    } else {
      this.submittedExcel = true;
      if (this.showUploadedExcelFileName != "") {
        this.validateExcelOnboarding();
      }
    }
  }

  validateExcelOnboarding() {
    const validateCSVOnboarding$ = this.eventCatService.validateExcelOnboarding(
      this.excelUploadedFile,
      this.showUploadedExcelFileName,
      this.currentZone
    );
    this.loader
      .showLoaderUntilCompleted(validateCSVOnboarding$)

      .subscribe(
        (res) => {
          if (!!res["response"]) {
            this.listViewExcelResponse = res["response"];
            if (res["response"]["value"].length > 0) {
              this.incorrectData = [];
              this.correctData = [];
              this.saveCorrectData = [];
           
              res["response"]["value"].forEach((entity) => {
              
                if (entity.csvDto) {
                  let { name } = entity.csvDto;
                  let uploadData = {
                    name,
                    errorFields: entity.columns,
                    reason: entity.reason,
                  };
                  if (entity.columns.length > 0) {
                    this.incorrectData.push(uploadData);
                  } else {
                    this.correctData.push(uploadData);
                    this.saveCorrectData.push(entity.csvDto);
                  }
                 
                }
              });
              this.TotalNoOfEntitiesUploadedRNW =
                this.incorrectData.length + this.correctData.length;
              this.noOfEntitiesUploaded = this.saveCorrectData.length;
              if (this.incorrectData.length == 0) {
                this.dialogRef.updateSize("45%");
              } else {
                this.totalIncorrectLength = this.incorrectData.length;
                this.showIncorrectData = this.incorrectData.slice(0, 10);
              }
            } else {
              this.toastService.error("No Enteries were added");
              return;
            }
          }
          this.pageType = "onboardingExcelTable";
          this.dialogRef.updateSize("75%");

          // if (this.digitalVersion) {
          //   if (!this.approach || this.approach == "") {
          //     this.updateConfig('LIST');
          //   }
          // }
        },
        (error) => {
         
        }
      );
  }

  closePopup() {
    
    this.dialogRef.close(this.pageType);
  }


  closePopupDelCat() {
    
    this.dialogRef.close('yes');
  }
  

  submitEditCat() {
    this.submitted = true;
    this.editCatName=this.editCatName.trim();
    if (this.editCatName == "") return;

   
    if(this.editCatName.length>50){
      this.toastService.error(' Please enter at most 50 characters.');
      return
    }
    this.submitted = false;
  
    this.editCatData.category = this.editCatName;
    const $category = this.eventCatService.updateCategory(
      this.editCatData,
      this.currentZone,this.editCatData.tenantId,this.editCatName
    );
    this.loader.showLoaderUntilCompleted($category).subscribe((e) => {
      this.closePopup();
      this.toastService.success("Tenant has been succesfully Edited");
    });
  }
 validateCategoryString(input) {
    // Regular expression to match only alphabets with minimum 4 and maximum 25 characters
    var regex = /^[a-zA-Z\s]*$/;
    
    // Test the input against the regular expression
    return regex.test(input);
}
  addCategory() {
    this.category=this.category.trim();
    if (this.category == "") {
      this.toastService.error("Please Add atleast 1 tenant");
      return;
    }
 
   
   
    if(this.category.length>50){
      this.toastService.error(' Please enter at most 50 characters.');
      return
    }
    // let i = this.categoryList.findIndex((e) => {
    //   return e.category.trim().includes(this.category.trim());
    // });
    // if (i != -1) {
    //   this.category = "";
    //   this.toastService.error("Duplicate Category is not allowed");
    //   return;
    // }
    // console.log(i);
  
    const $category = this.eventCatService.addCatedorySingle(
      this.category,
      this.currentZone
    );
    this.loader.showLoaderUntilCompleted($category).subscribe((e) => {
      //this.closePopup();
      this.toastService.success("Tenant has been succesfully added");
      this.categoryList.push({ category: this.category });
      this.category = "";
    });
   
  }
  removeCategory(i) {
    this.categoryList.splice(i, 1);
  }
  onSubmitCateory() {
    this.category=this.category.trim();
    if (!this.categoryList.length && this.category == "") {
      this.toastService.error("Please Add atleast 1 tenant");
      return ;
    }
    if (this.category == "") {
      this.closePopup();
      return;
    }
   

   
    if(this.category.length>50){
      this.toastService.error(' Please enter at most 50 characters.');
      return
    }
    const $category = this.eventCatService.addCatedorySingle(
      this.category,
      this.currentZone
    );
    this.loader.showLoaderUntilCompleted($category).subscribe((e) => {
      this.closePopup();
      this.toastService.success("Tenant has been succesfully added");
      this.category = "";
    });
  }

  downloadViaExcelTemplate(value?) {
    const customQues$ = this.eventCatService.downloadViaExcelTemplate(
      this.currentZone
    );

    this.loader.showLoaderUntilCompleted(customQues$).subscribe(
      (res: Blob) => {
        if (!!res) {
          const fileName = "tenant.xlsx";
          this.excelService.saveApiResponseToFile(res, fileName);
        }
      },
      (err) => {}
    );
  }

  uploadExcel(files: FileList) {
    var fileName = Array.from(files)
      .map((f) => f.name)
      .join(", ");
    const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (extension === "xlsx" || extension === "xls" || extension=='csv') {
      this.excelUploadedFile = files.item(0);
      this.showUploadedExcelFileName = fileName;
    } else {
      this.toastService.error("Please import valid .xlsx file.");
    }
  }
  getToolTipData(hoverData) {
    return hoverData;
  }
  reUploadEntities() {
    this.pageType = "onboarding_reUploadCSV";
    this.excelUploadedFile = undefined;
    this.showUploadedExcelFileName = undefined;
    this.dialogRef.updateSize("40%");
  }
  downloadCurrentCSVFile() {
    var tempData = [...this.incorrectData];
    tempData.push(...this.correctData);
    let downloadArr = tempData.map((row) => {
      var errors = row.errorFields.map((v) => this.camelCasingToNormal(v));
      var entityDatas;

      entityDatas = {
        "Tenant Company Name": row.name,

        "Error Fields": errors ? errors.toString() : errors,
        Reason: row.reason,
      };

      return entityDatas;
    });
    const fileName = "Tenant";
    this.excelService.convertJSONtoXLSX(downloadArr, fileName);
  }
  camelCasingToNormal(value: string): string {
    return value
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/\b\w/g, (match) => match.toUpperCase()); // capitalize the first letter
  }

  proceedToOnboard() {
  

    this.categoryList = [];
    if(this.correctData.length==0){
      this.toastService.error("Please upload the correct file containing accurate Tenant Name");
      return ;
    }
    this.correctData.forEach((e) => {
      this.categoryList.push(e.name);
    });

    const $category = this.eventCatService.addCatedory(
      this.categoryList,
      this.currentZone
    );
    this.loader.showLoaderUntilCompleted($category).subscribe((e) => {
      this.closePopup();
      this.toastService.success("Tenant has been succesfully added");
    });
  }
}
