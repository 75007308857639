import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-digital-dashboard-intro-modal',
  templateUrl: './digital-dashboard-intro-modal.component.html',
  styleUrls: ['./digital-dashboard-intro-modal.component.scss']
})
export class DigitalDashboardIntroModalComponent implements OnInit {
  // private unsubscribe$ = new Subject<void>();

  // submitted = false;
  userName = localStorage.getItem('ssadmin_name');
  pageType;
  currentZone;
  showFirstIntro = true;
  showSecondIntro = false;
  showThirdIntro = false;

  constructor(
    public dialogRef: MatDialogRef<DigitalDashboardIntroModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      this.currentZone = this.data["currentZone"];
      this.pageType = this.data["type"];
    }

    ngOnInit() {
      console.log('show digital intro is true...');
    }

    closeDigitalIntro() {
      this.dialogRef.close();
    }

    showFirstDigitalIntro() {
      this.showFirstIntro = true;
      this.showSecondIntro = false;
      this.showThirdIntro = false;
    }

    showSecondDigitalIntro() {
      this.showFirstIntro = false;
      this.showSecondIntro = true;
      this.showThirdIntro = false;
    }


    showThirdDigitalIntro() {
      this.showFirstIntro = false;
      this.showSecondIntro = false;
      this.showThirdIntro = true;
    }


}
