import { Component, OnInit } from '@angular/core';
import { CommonService } from 'commonService';
import { EMPTY, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ManageRequestsService } from 'src/app/layout/manage-request/service/manage-requests.service';

@Component({
  selector: 'app-add-users-modal',
  templateUrl: './add-users-modal.component.html',
  styleUrls: ['./add-users-modal.component.scss']
})
export class AddUsersModalComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  isloading = false;
  currentZone;
  filterData;

  searchBy = 'user';
  searchTermSubject$ = new Subject<any>();
    searchTermAction$ = this.searchTermSubject$.asObservable();
    autocompleteList$ = this.searchTermAction$.pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(200),
        tap(() => {
            this.isloading = true;
        }),
        switchMap((searchTerm) =>
            of(searchTerm).pipe(
                mergeMap((res) =>
                    this.manageRequestsService.getAutocompleteList(
                        {
                            searchTerm,
                            searchType: this.searchBy,
                        },
                        this.currentZone
                    )
                )
            )
        ),
        tap((searchRes) => {
            this.isloading = false;
        }),
        catchError((err) => {
            return EMPTY;
        })
    );
    
  constructor(
    private manageRequestsService: ManageRequestsService,
    private commonService: CommonService,
  ) { 
    this.commonService.currentZone
        .pipe(
            takeUntil(this.unsubscribe$),
            distinctUntilChanged()
        )
        .subscribe(res => {
            this.currentZone = res;
        });
  }

  ngOnInit() {
  }

  getAutocompleteList = (searchTerm) => {
    this.searchTermSubject$.next(searchTerm.trim());
  };
  /*on click of suggested data from typeahead dropdown*/
  onselectData(val) {

  }

  searchSeat(value){

  }
}
