<div *ngIf="pageType == 'addAmenity' || pageType == 'editAmenity'">
    <div class="modal-header">
        {{ wtitle }} {{'amenity' | translate}} 
        <button id="close_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
              <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmitAmenity()">
            <div class="form-group row">
              <label class="col-3 col-form-label">{{'Entername' | translate}}</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" placeholder="" formControlName="amenityName">
                <div *ngIf="submitted && f.amenityName.errors" class="invalid-feedback">
                  <div *ngIf="f.amenityName.errors.required">
                      {{ "pleaseEnterAmenityName" | translate }}
                  </div>
                  <div *ngIf="f.amenityName.errors.minlength">
                      {{ "minLengthError" | translate }}
                  </div>
                  <div *ngIf="f.amenityName.errors.maxlength">
                      {{ "maxLengthError" | translate }}
                  </div>
              </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <button type="submit" class="btn btn-main pull-right">{{wtitle}}</button>
              </div>
            </div>
          </form>
    </div>
    <!-- <div class="modal-footer border-0 m-2"></div> -->
</div>

<div *ngIf="pageType=='showEditAmenities'">
  <div class="modal-header">
      {{'editAmenityForSeat' | translate}} - {{data['seatInfo']['entityInfo']['displayName']}}
      <button id="close_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <form [formGroup]="editAmenitiesForm" (ngSubmit)="onEditAmenity()">
        <div class="amenities-list row col-12" [formGroup]="prop" *ngFor="let prop of fEditAmenities.amenityName['controls']; let i = index">
            <div class="form-group">
              <!-- {{prop.value}} -->
                <mat-checkbox class="ml-1" [value]="prop.value.checked" (change)="editAmenities($event,prop)"
                [checked]="prop.value.checked">
                    <strong class="fontSizeChkBox">{{prop.value.name}}</strong>
                </mat-checkbox>
            </div>
        </div>
        <div class="flex row col-12 text-danger" role="alert" *ngIf="fEditAmenities['amenityName'].invalid">
          {{'PleaseSelectCheckbox' | translate}}
        </div>
        <div class="row col-12">
          <button type="submit" class="btn btn-main pull-right">{{'submit' | translate}}</button>
        </div>
      </form>
      <!-- {{editAmenitiesForm.valid}}
      {{editAmenitiesForm.value | json}} -->
  </div>
  <div class="modal-footer border-0 m-2"></div>
</div>



<div *ngIf="pageType=='showAllAmenities'">
  <div class="modal-header">
      <h6 class="font-weight-bold">{{'allAmenities' | translate}}</h6>
      <button id="close_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
    <div class="p-0 m-0 table-responsive w-100">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      
        <!-- amenity Column -->
        <ng-container matColumnDef="amenity">
          <th mat-header-cell *matHeaderCellDef> {{'amenity' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element?.amenity.name}} </td>
        </ng-container>
      
        <!-- count Column -->
        <ng-container matColumnDef="count">
          <th mat-header-cell *matHeaderCellDef> {{'count' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element?.count}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <div class="modal-footer border-0 m-2"></div>
</div>
