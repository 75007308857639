<div class="mainIntroContainer">
    <div class="introScreen" *ngIf="showFirstIntro">
        <!-- <div class="posText">
            <p class="qaFont">Quick Actions <span class="alFont ml-2">(Across Locations)</span></p>
        </div> -->
        <div class="intro-container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row p-3 d-flex align-items-center">
                        <div class="col-lg-5">
                            <img src="./assets/images/jumpree_digital/sitting.svg" class="w-100">
                        </div>
                        <div class="col-lg-7">
                            <div class="my-5">
                                <p class="intro-font">{{"manageYour" | translate}} <span class="semi-bold-blue">{{"immediateAction" | translate}}</span>{{"inThisSection" | translate}} </p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="dot active" (click)="showFirstDigitalIntro()"></span>
                                    <span class="dot" (click)="showSecondDigitalIntro()"></span>
                                    <span class="dot" (click)="showThirdDigitalIntro()"></span>

                                </div>
                                <div class="d-flex">
                                    <button type="button"
                                    class="btn btn-main btnSmall btn-main-outline semi-bold-blue smallBtn"
                                    (click)="closeDigitalIntro()">{{"skip" | translate}}</button>
                                    <button type="button"
                                    class="btn btn-main btnSmall smallBtn float-right ml-3"
                                    (click)="showSecondDigitalIntro()">{{"next" | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="introScreenSecond" *ngIf="showSecondIntro">
        <div class="intro-container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row p-3 d-flex align-items-center">
                        <div class="col-lg-5">
                            <img src="./assets/images/jumpree_digital/intro3.svg" class="w-100">
                        </div>
                        <div class="col-lg-7">
                            <div class="my-5">
                                <p class="intro-font">
                                    {{"createYourCustomListOfColleaguesYouCollaborateWithRegularly" | translate}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="dot" (click)="showFirstDigitalIntro()"></span>
                                    <span class="dot active" (click)="showSecondDigitalIntro()"></span>
                                    <span class="dot" (click)="showThirdDigitalIntro()"></span>
                                </div>
                                <div class="d-flex">
                                    <button type="button"
                                    class="btn btn-main btnSmall btn-main-outline semi-bold-blue smallBtn"
                                    (click)="closeDigitalIntro()">{{"skip" | translate}}</button>
                                    <button type="button"
                                    class="btn btn-main btnSmall smallBtn float-right ml-4"
                                    (click)="showThirdDigitalIntro()">{{"next" | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="introScreenThird" *ngIf="showThirdIntro">
        <div class="col-12 posIntroAddress d-flex">
            <div class="text-center">
                <!-- <img alt="img" class="img-fluid" src="./assets/images/jumpree_digital/avatar@3x.jpg"> -->
                <div
                class="textCircle img-fluid pt-1"                    
            >
                <div
                
                    aria-label="Profile Image"
                    class="mb-2"
                    id="Profile Image"
                    style="font-size: 30px; color: rgb(255, 255, 255); font-family: FontAwesome; font-weight: normal; font-style: normal;"
                >
                    
                </div>
                </div>
            </div>
            <div class="col customMargin">
                <div class="d-flex flex-column">
                    <div>
                        <h4 class="semi-bold-blue textColor" style="font-size: 12px;">
                            <span class="regular-font textColor">{{"hi" | translate}} </span>
                            {{userName}}
                        </h4>
                    </div>
                    <div>
                        <p class="floor-address cursor-pointer font-weight-bold ml-0 textColor">
                            {{"selectWorkStatus" | translate}}&nbsp;
                            <i class="fa fa-angle-down font-bold posDnArrow textColor" aria-hidden="true"></i>
                            <img class="cursor-pointer ml-3" src="./assets/images/jumpree_digital/calendar_icon.svg">
                        </p>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="intro-container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row p-3 d-flex align-items-center">
                        <div class="col-lg-5">
                            <img src="./assets/images/jumpree_digital/work-from-home.svg" class="w-100">
                        </div>
                        <div class="col-lg-7">
                            
                            <div class="my-5">
                                <p class="intro-font">
                                    {{"selectYourWorkStatusWhetherWorkingFormOfficeHomeLeave" | translate}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="col-12">
                                    <div class="d-inline-flex justify-content-between align-items-center">
                                        <span class="dot" (click)="showFirstDigitalIntro()"></span>
                                        <span class="dot" (click)="showSecondDigitalIntro()"></span>
                                        <span class="dot active" (click)="showThirdDigitalIntro()"></span>        
                                    </div>
                                    <!-- <button type="button"
                                    class="btn btn-main btnSmall btn-main-outline semi-bold-blue smallBtn">Skip</button> -->
                                    <button type="button"
                                    class="btn btn-main btnSmall smallBtn float-right ml-4"
                                    (click)="closeDigitalIntro()">{{"gotIt" | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row col-12 mt-4 posIntroAddress">
            <div  class="customLocBg">
                <img  class="cursor-pointer pointerSize" src="./assets/images/amenity_booking/location_icon.png">
                <span  class="floor-address semi-bold-blue ml-2">Ecoworld,</span>
                <span  class="floor-address cursor-pointer ml-2">Mumbai&nbsp;
                    <i  class="fa fa-angle-down font-bold posDnArrow" aria-hidden="true"></i>
                </span>
            </div>
        </div> -->
    </div>
</div>