import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar, MatDialog, MatDialogRef } from "@angular/material";
import "rxjs/add/operator/map";

import "rxjs/add/operator/toPromise";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";
@Injectable()
export class DigitalService {
  dialogRef;
  private readonly BASE_URL;

  constructor(
    public matSnackBar: MatSnackBar,
    public http: HttpClient,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.BASE_URL = environment.BASE_URL + "ems/";
  }

  private globalVersionCheckSource = new BehaviorSubject<boolean>(false);
  versionCheck = this.globalVersionCheckSource.asObservable();
  private jumpreeProCheckSource = new BehaviorSubject<boolean>(false);
  jumpreeProVersionCheck = this.jumpreeProCheckSource.asObservable();
  nissanDepartment = false;

  globalVersionCheck(status) {
    this.globalVersionCheckSource.next(status);
  }
  globalJumpreeProVerisonCheck(status){
    this.jumpreeProCheckSource.next(status);
  }
}
