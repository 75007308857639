<div class="row">
    <div class="col-md-12">
        <div style="margin-left: -2%">
            <div class="leaflet-conntainer">
                <div class="mapSection" class="d-block">
                    <div class="pb-4 ml-4">
                        <div id="mapid" class="mt-2 groove"></div>
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-2 mb-2">
                            <div class="text-center pl-0 float-left legends ng-star-inserted">
                                <span class="dot" style="background-color: green"></span>
                                <span class="fs-14 ml-2">{{"available" | translate}}</span>
                            </div>
                            <div class="text-center pl-4 float-left legends ng-star-inserted">
                                <span class="dot" style="background-color: red"></span>
                                <span class="fs-14 ml-2">{{"unavailable" | translate}} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>