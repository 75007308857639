<div *ngIf="pageType == 'addEvent'">
  <div class="modal-header pl-0">
    <h5>
      {{ this.isEditEvent ? "Edit Event" : "Create Event" }}
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopupAddEvent()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">Events</p>
  </div>
  <div class="modal-body width-400 align-left">
    <ng-container>
      <div class="form-group row">
        <p
          class="font-weight-bold fontColorBold mb-0 pl-3 enable-mutiday font-14 fontColor"
        >
          Select the medium to create the event
        </p>
      </div>
      <div class="form-group pl-3">
        <div class="form-row">
          <mat-radio-group
            
            class="font-color"
            [(ngModel)]="eventType"
           
          >
            <mat-radio-button
              class="pb-0 custom-radio-btn custom-radio-btn-label"
              value="manual"
            >
              Manually Create the Event
            </mat-radio-button>
            <p class="ml-custom-left col-12 pl-0">
              Choose this option if you would like to create the event on this
              platform
            </p>
            <mat-radio-button
              class="pb-0 custom-radio-btn custom-radio-btn-label"
              value="url"
            >
              Redirect to URL
            </mat-radio-button>
            <p class="ml-custom-left col-12 pl-0">
              Choose this option if you would like to redirect the users to the
              event on another platform
            </p>
          </mat-radio-group>
        </div>

        <ng-container >
          <form (ngSubmit)="onManualSubmit()" [formGroup]="settingsManualForm">
            <ng-container>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label
                    >ENTER TITLE
                    <span class="italic cstm-label">(Character Limit 25)</span>
                    <span class="color-red">&nbsp;*</span>
                  </label>
                  <input
                  
                    formControlName="eventTitle"
                    maxlength="25"
                    class="form-control blue-regular"
                    id="dir_id"
                    placeholder="Enter Title"
                    type="text"
                    [ngClass]="{
                      'is-invalid': submitted && f.eventTitle.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && f.eventTitle.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.eventTitle.errors.required">
                      Title is required.
                    </div>
                    <div *ngIf="f.eventTitle.errors.maxlength">
                      Title must be at most 25 characters.
                    </div>
                  </div>
                  <div
                  *ngIf=" f.eventTitle.value!=''"
                  class="d-block invalid-feedback"
                >
                {{25-f.eventTitle.value.length}} characters left
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="inputCountry"
                    >SELECT EVENT CATEGORY
                    <span class="color-red">&nbsp;*</span></label
                  >

                  <mat-form-field appearance="fill" style="display: block">
                    <mat-select
                      [(ngModel)]="category"
                      placeholder="Select Category"
                      formControlName="eventCategory"
                      name="searchType"
                      id="searchType"
                      class="mat-css-arrow mat-css-down"
                    >
                      <mat-option
                        *ngFor="let searchBy of categoryList; let i = index"
                        [value]="searchBy.id"
                      >
                        {{ searchBy.category }}
                      </mat-option>
                    </mat-select>
                   
                  </mat-form-field>
                  <div
                  *ngIf="submitted && f.eventCategory.errors"
                  class="d-block invalid-feedback"
                >
                  <div *ngIf="f.eventCategory.errors.required">
                    Category is required.
                  </div>
                 
                </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label
                    >DESCRIPTION
                    <span class="italic cstm-label">(Character Limit 250)</span>
                    <span class="color-red">&nbsp;*</span></label
                  >
                  <textarea
                  
                    formControlName="eventDisc"
                    maxlength="250"
                    class="form-control align-left input-text"
                    rows="5"
                    cols="50"
                    placeholder="Add description"
                    [ngClass]="{
                      'is-invalid': submitted && f.eventDisc.errors
                    }"
                  ></textarea>
                  <div
                    *ngIf="submitted && f.eventDisc.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.eventDisc.errors.required">
                      Description is required
                    </div>
                    <div *ngIf="f.eventDisc.errors.maxLength">
                      Description must be at most 250 characters.
                    </div>
                  </div>
                  <div
                  *ngIf=" f.eventDisc.value!=''"
                  class="d-block invalid-feedback"
                >
                {{250-f.eventDisc.value.length}} characters left
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-3">
                  <label
                    >Event Start Date
                    <span class="color-red">&nbsp;*</span></label
                  >
                  <div class="input-group">
                    <input
                    
                      id="start_date"
                      class="form-control br-0"
                      name="startDate"
                      placeholder="Select Start Date"
                   
                
                      [min]="currentDateOrig"
                      [(ngModel)]="startDate"
                      [ngClass]="{
                        'is-invalid': submitted && f.startDate.errors
                      }"
                      formControlName="startDate"
                
                      (ngModelChange)="onStartDateChange($event)"
                      [owlDateTime]="dt1"
                      [disabled]="presentStatusCheck()"
                  
                    />
                    <span [owlDateTimeTrigger]="dt1" class="m-ne">
                      <img
                        src="../../../../assets/images/event/calendrer.png"
                        style="width: 70px"
                      />
                    </span>
                    <owl-date-time
                      [pickerType]="'calendar'"
                      #dt1
                    ></owl-date-time>
                  </div>
                  <div
                    *ngIf="submitted && f.startDate.errors"
                    class="invalid-feedback d-block mt-neg-1 mb-1"
                  >
                    <div *ngIf="f.startDate.errors.required">
                      Start date is required.
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label
                    >Event End Date
                    <span class="color-red">&nbsp;*</span></label
                  >
                  <div class="input-group">
                    <input
               
                      id="start_date"
                      class="form-control br-0"
                      name="endDate"
                      placeholder="Select End Date"
                  
                      [owlDateTime]="dt2"
                      [(ngModel)]="endDate"
                      [ngClass]="{
                        'is-invalid': submitted && f.endDate.errors
                      }"
                      [min]="startDate"
                      formControlName="endDate"
                      (ngModelChange)="onEndDateChange($event)"
                      [disabled]="presentStatusCheck()"
              
                    />
                    <span [owlDateTimeTrigger]="dt2" class="m-ne">
                      <img
                        src="../../../../assets/images/event/calendrer.png"
                        style="width: 70px"
                      />
                    </span>
                    <owl-date-time
                      [pickerType]="'calendar'"
                      #dt2
                    ></owl-date-time>
                  </div>

                  <div
                    *ngIf="submitted && f.endDate.errors"
                    class="invalid-feedback is-invalid d-block mt-neg-1 mb-1"
                  >
                    <div *ngIf="f.endDate.errors.required">
                      End date is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-neg-1">
                <div
                  class="form-group col-md-3"
                  (click)="validateStartDateTime()"
                >
                  <label for="inputCountry"
                    >start time <span class="color-red">&nbsp;*</span>
                  </label>

                  <mat-form-field appearance="fill" style="display: block">
                    <mat-select
                    [disabled]="presentStatusCheck()"
                      [(ngModel)]="startTime"
                      placeholder="Select time"
                      (selectionChange)="onStartTimeChange()"
                      name="searchType"
                      id="searchType"
                      class="mat-css-arrow mat-css-down"
                      formControlName="startTime"
                    >
                      <mat-option
                        *ngFor="let searchBy of startIntervals; let i = index"
                        [value]="searchBy.id"
                      >
                        {{ searchBy.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div
                    *ngIf="submitted && f.startTime.errors"
                    class="invalid-feedback d-block is-invalid"
                  >
                    <div *ngIf="f.startTime.errors.required">
                      Start time is required.
                    </div>
                  </div>
                </div>

                <div
                  class="form-group col-md-3"
                  (click)="validateEndDateTime()"
                >
                  <label for="inputCountry"
                    >End time <span class="color-red">&nbsp;*</span>
                  </label>

                  <mat-form-field appearance="fill" style="display: block">
                    <mat-select
                    [disabled]="presentStatusCheck()"
                      [(ngModel)]="endTime"
                      (selectionChange)="onEndTimeChange()"
                      placeholder="select time"
                      formControlName="endTime"
                      name="searchType"
                      id="searchType"
                      class="mat-css-arrow mat-css-down"
                    >
                      <mat-option
                        *ngFor="let searchBy of endIntervals; let i = index"
                        [value]="searchBy.id"
                      >
                        {{ searchBy.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    *ngIf="submitted && f.endTime.errors"
                    class="invalid-feedback d-block"
                  >
                    <div *ngIf="f.endTime.errors.required">
                      End time is required.
                    </div>
                  </div>
                </div>
              </div>
              <!-- 
                        <amenity-time-selection
                      
                        [date] = "sd"
                       
                      >
                    </amenity-time-selection>    -->
                    <ng-container *ngIf="eventType == 'url'">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label
                          >ENTER EVENT URL (Enter Valid URL in https:// format)
                          <span class="color-red">&nbsp;*</span></label
                        >
                        <input
                          type="url"
                          [ngClass]="{ 'is-invalid': submitted && (!f.eventURL.value || f.eventURL.errors) }"
                          formControlName="eventURL"
                          class="form-control blue-regular"
                          id="dir_id"
                          placeholder="Enter URL"
                          type="text"
                        />
                        <div
                          
                          class="invalid-feedback"
                        >
                          <div *ngIf="submitted && !f.eventURL.value">
                            Redirect url is required
                          </div>
                          <div *ngIf="submitted && f.eventURL.errors">
                            Enter a valid url
                          </div>
                        </div>
                      </div>
                    </div>
                    </ng-container>
        

                    <ng-container *ngIf="eventType == 'manual'">
              <!-- <div [ngStyle]="{'display': 'none'}" class="form-row">
                <div class="form-group col-md-4">
                  <label
                    >ENTER LOCATION 
                    <img
                      class="ml-1 mb-1"
                      mat-button
                      matTooltipClass="custom-tooltip"
                      matTooltipPosition="right"
                      matTooltip="Enter the specific venue if your event is hosted at a location other than our standard office building. This information will be visible to registered employees and linked to the super admin's location for seamless filtering and coordination."
                      src="../../../../assets/images/event/info.png"
                    />
                  </label>
                  <input
                    formControlName="location"
                   type="text"
                    class="form-control blue-regular"
                    id="dir_id"
                    placeholder="Enter location"
                    type="text"
                    maxlength="100"
                    [ngClass]="{ 'is-invalid': submitted  && f.location.errors }"
                  />
                  <div *ngIf="f.location.value?.length>0" class="invalid-feedback d-block">
                    {{100 - f.location.value?.length}} characters left
                  </div>
                  <div *ngIf="submitted  && f.location.errors" class="invalid-feedback">
                  
                    <div *ngIf="f.location.errors.pattern">Please enter only alphabets</div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="form-row">
                <div class="form-group col-md-4">
                  <label>ENTER URL for LOCATION </label>
                  <input
                    [ngClass]="{
                      'is-invalid': submitted && f.locationURL.errors
                    }"
                    formControlName="locationURL"
                    class="form-control blue-regular"
                    id="dir_id"
                    placeholder="Enter links to a map"
                    type="url"
                  />
                </div>
              </div>

              <div
                *ngIf="submitted && f.locationURL.errors"
                class="d-block invalid-feedback"
              >
                <div *ngIf="f.locationURL.errors.pattern">
                  Please enter a valid URL for the location.
                </div>
              </div> -->
            </ng-container>
            </ng-container>

            <ng-container>
              <div class="form-row">
                <p
                  class="mb-0 font-weight-bold mb-0 enable-mutiday font-14 fontColor"
                >
                  <span class="info-text"> Add Additional Information </span>

                  <img
                    class="cursor-pointer margleft-10 iconSizeCross"
                    
                    src="../../../../assets/images/event/Add.png"
                    (click)="addDetails()"
                  />
                </p>
              </div>

              <ng-container formArrayName="additionalDetails">
                <div
                  *ngFor="
                    let item of additionalDetailsArray.controls;
                    let k = index
                  "
                >
                  <div [formGroup]="item">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label
                          >ENTER HEADING {{ k + 1 }}
                          <span class="italic cstm-label"
                            >(Character Limit 25)</span
                          >
                          <span class="color-red">&nbsp;*</span></label
                        >
                        <input
                          type="text"
                          class="form-control input-text width-50"
                          maxlength="25"
                          placeholder="Enter Title"
                          formControlName="heading"
                          required
                        />
                        <div
                          class="d-block invalid-feedback"
                          *ngIf="
                            submitted &&
                            item.get('heading').hasError('required')
                          "
                        >
                          Heading is required
                        </div>
                       <div
  *ngIf="item.get('heading').value && item.get('heading').value !== ''"
  class="d-block invalid-feedback"
>
  {{ 25 - item.get('heading').value.length }} characters left
</div>
</div>
                      <div class="col-1">
                        <img
                          class="cursor-pointer mt-3 margleft-10 iconSizeCross"
                          (click)="removeDetails(k)"
                          src="../../../../assets/images/event/cross_colored.png"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label
                          >ENTER DESCRIPTION {{ k + 1 }}
                          <span class="italic cstm-label"
                            >(Character Limit 4000)</span
                          >
                          <span class="color-red">&nbsp;*</span></label
                        >
                        <textarea
                          maxlength="4000"
                          class="form-control align-left input-text"
                          rows="5"
                          cols="50"
                          formControlName="description"
                          placeholder="Add Description"
                          required
                        ></textarea>
                        <div
                          class="d-block invalid-feedback"
                          *ngIf="
                            submitted &&
                            item.get('description').hasError('required')
                          "
                        >
                          Description is required
                        </div>
                        <div
  *ngIf="item.get('description').value && item.get('description').value !== ''"
  class="d-block invalid-feedback"
>
  {{ 4000 - item.get('description').value.length }} characters left
</div>

                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="mb-3">
              <div class="form-row">
                <p
                  class="font-weight-bold mb-0 enable-mutiday font-14 fontColor"
                >
                  Select Image <span class="color-red">&nbsp;*</span>
                </p>
              </div>
              <div class="form-row">
                <p class="ml-custom-left-1">
                  Image Size must be at least 360 x 800 for highest quality of
                  resolution on phone displays
                </p>
              </div>

              <div class="form-row align-left">
                <div class="col-md-4 p-0">
                  <!-- <label class="w-100 mt-1"
                                >
                                <div class="custom-file">
                                    
                                    <label
                                        class="custom-file-label custom-upload-btn"
                                        for="inputGroupFile02"
                                        #labelImport1
                                        >{{ dashboardFileName }}</label
                                    >
                                </div>
                            </label> -->
                  <input
                    type="file"
                    class="form-control custom-file-input mt-2"
                    id="importFile1"
                    style="display: none"
                    (change)="imageChange($event.target.files, $event)"
                    #fileInputtemp
                    aria-describedby="inputGroupFileAddon02"
                  />
                  <button
                    type="button"
                    class="col-6 btn btn-main btn-white f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                    (click)="fileInputtemp.click()"
                  >
                    Browse
                  </button>
                  <div
                    class="d-block invalid-feedback"
                    *ngIf="
                      submitted &&
                      (dashboardImageUrl == '' || dashboardImageUrl == null)
                    "
                  >
                    Image is required
                  </div>
                  <img
                    *ngIf="dashboardImageUrl"
                    class="m-2"
                    [src]="dashboardImageUrl"
                    style="width: 150px; height: 150px"
                  />
                </div>
              </div>
            </div>

            <!-- 
 -->
            <div class="mb-3">
              <div class="form-row">
                <p
                  class="font-weight-bold m-0 enable-mutiday font-14 fontColor"
                >
                  Create Promotion
                  <mat-slide-toggle
                    class="ml-4"
                    formControlName="promotion"
                    
                    
                    >&nbsp;</mat-slide-toggle
                  >
                  <img
                    class="ml-2 mt-neg"
                    mat-button
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="right"
                    matTooltip="The Create Promotions feature allows you to create a splash screen. Users will see the Promotion the first time they open the app after the event has been published. They will see it only once. "
                    src="../../../../assets/images/event/info.png"
                  />
                </p>
                <p
                  *ngIf="f.promotion.value == true"
                  class="col-12 ml-custom-left-1 pl-0"
                >
                  Image Size must be at least 360 x 800 for highest quality of
                  resolution on phone displays
                </p>
              </div>
              <ng-container *ngIf="f.promotion.value == true">
                <div
                  class="card-promotion mb-3"
                  [ngStyle]="{
                    'background-image': 'url(' + promotion.backgroundImage + ')'
                  }"
                >
                  <input
                    type="file"
                    class="form-control custom-file-input mt-2"
                    id="importFile1"
                    style="display: none"
                    (change)="imageChange1($event.target.files, $event)"
                    #fileInput12
                    aria-describedby="inputGroupFileAddon02"
                  />
                  <button
                    type="button"
                    class="pro-btn btn m-3 pos-btn pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                    [innerText]="
                      promotion.backgroundImage ? 'Edit Image' : 'Browse Image'
                    "
                    (click)="fileInput12.click()"
                    [ngStyle]="{
                      color: promotion.backgroundImage ? '#ffffff' : '#0f1f54',
                      'border-color': promotion.backgroundImage
                        ? '#ffffff'
                        : '#0f1f54'
                    }"
                  ></button>
                  <div class="grp-inp w-100">
                    <textarea
                      rows="2"
                      cols="12"
                      maxlength="15"
                      style="outline: none"
                      class="si-38 mb-0 inp-head"
                      [ngStyle]="{
                        color: promotion.backgroundImage
                          ? promotion.colour
                          : '#0f1f54'
                      }"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="promotion.heading"
                    ></textarea>
                    <div
                      class="si-38-placeholder"
                      [hidden]="promotion?.heading?.trim() !== ''"
                    >
                      <p class="mb-0 heading-p-style">Enter Heading</p>

                      <p class="openSans size-limit">15 characters</p>
                    </div>
                  </div>
                  <div class="grp-inp w-100">
                    <textarea
                      style="outline: none"
                      rows="3"
                      cols="18"
                      maxlength="25"
                      [ngStyle]="{
                        color: promotion.backgroundImage
                          ? promotion.colour
                          : '#0f1f54'
                      }"
                      class="si-20 mt-0 mb-0 inp-subhead"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="promotion.subheading"
                    ></textarea>
                    <div
                      class="si-38-placeholder-1"
                      [hidden]="promotion?.subheading?.trim() !== ''"
                    >
                      <p class="mb-0 heading-p-style-1">Enter Sub Heading</p>
                      <p class="openSans size-limit">25 characters</p>
                    </div>
                  </div>
                  <div class="grp-inp">
                    <input
                    maxlength="15"
                      class="si-20 mb-0 inp-btn btn"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="promotion.buttonText"
                      [ngStyle]="{
                        'background-color': promotion.backgroundImage
                          ? '#800612'
                          : '#0f1f54'
                      }"
                    />
                    <div
                      class="si-38-placeholder-1 top-92"
                      [hidden]="promotion?.buttonText?.trim() !== ''"
                    >
                      <p class="openSans" style="color: white">Enter CTA</p>
                    </div>
                  </div>
                </div>

                <div class="">
                  <p class="col-6 ml-custom-left-1 pl-0">
                    The text for the Enter CTA button must be related to an
                    action the user needs to perform and also related to the
                    event. By default the user will be directed to the Events
                    Information page.
                  </p>
                  <p class="col-6 ml-custom-left-1 pl-0">
                    Promotions will be displayed from the date of display in
                    advance (if enabled) else it will be displayed on the date
                    of the event.
                  </p>
                  <!-- <div class="col-md-4 p-0">
                            

                <input
                          
                          
                type="color"
                class="form-control  mt-2"
              
                (change)="
                imageChange(
                        $event.target.files,
                        $event
                    )
                "
              
            />
                
            </div> -->
                </div>
                <div class="col-3 pl-0">
                  <div class="colorOuterShadow">
                    <input
                      [(colorPicker)]="promotion.colour"
                      id="choose_color"
                      class="square-input"
                      [(style.background)]="promotion.colour"
                      (cpSliderDragEnd)="updatePromotionColor()"
                    />
                  </div>
                  <span class="btn-font"> Text color for promotion </span>
                </div>
              </ng-container>
            </div>

            <div class="mb-3">
              <div class="form-row">
                <p
                  class="font-weight-bold m-0 enable-mutiday font-14 fontColor"
                >
                  Display in advance
                  <mat-slide-toggle
                    class="ml-4"
                    formControlName="inAdvance"
                    
                    
                    (change)="reEnableAdvance($event)"
                    >&nbsp;</mat-slide-toggle
                  >
                </p>
                <p
                  *ngIf="f.inAdvance.value == true"
                  class="col-12 ml-custom-left-1 pl-0"
                >
                  Choose the date and time to notify users about the event
                </p>
              </div>
              <ng-container *ngIf="f.inAdvance.value == true">
                <div class="form-row">
                  <div class="col-md-4">
                    <label>Select date </label>
                    <div class="input-group">
                      <input
                        id="start_date"
                        class="form-control br-0"
                        name="adDate"
                        placeholder="{{ 'startDate' | translate }}"
                     
                        [owlDateTime]="dt3"
                    
                        [(ngModel)]="advanceDate"
                        [disabled]="isAdvanceTimeDisable"
                        [max]="startDate"
                        [min]="currentDateOrig"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="onAdvanceDateChange($event)"
                      />
                      <span [owlDateTimeTrigger]="dt3" class="m-ne">
                        <img
                          src="../../../../assets/images/event/calendrer.png"
                          style="width: 70px"
                        />
                      </span>
                      <owl-date-time
                        [pickerType]="'calendar'"
                        #dt3
                      ></owl-date-time>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-3">
                    <label>time to notify from </label>

                    <mat-form-field appearance="fill" style="display: block">
                      <mat-select
                        [(ngModel)]="advanceTime"
                        placeholder="Select time"
                        [disabled]="isAdvanceTimeDisable"
                        [ngModelOptions]="{ standalone: true }"
                        name="searchType"
                        id="searchType"
                        class="mat-css-arrow mat-css-down"
                      >
                        <mat-option
                          *ngFor="
                            let searchBy of advanceTimeSlots;
                            let i = index
                          "
                          [value]="searchBy.id"
                        >
                          {{ searchBy.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="mb-3">
              <div class="form-row">
                <p
                  class="font-weight-bold m-0 enable-mutiday font-14 fontColor"
                >
                  Notify Users
                  <mat-slide-toggle
                    class="ml-4"
                    formControlName="notifyUser"
                    
                    (change)="reEnableNotify($event)"
                    >&nbsp;</mat-slide-toggle
                  >
                </p>
                <p
                  *ngIf="f.notifyUser.value == true"
                  class="col-12 ml-custom-left-1 pl-0"
                >
                  Choose the time to notify users on the day of event
                </p>
              </div>
              <ng-container *ngIf="f.notifyUser.value == true">
                <div class="form-row">
                  <div class="col-md-3">
                    <label>Time to notify from </label>

                    <mat-form-field appearance="fill" style="display: block">
                      <mat-select
                        [(ngModel)]="notifyUserTime"
                        placeholder="Select time"
                        [ngModelOptions]="{ standalone: true }"
                        name="searchType"
                        id="searchType"
                        class="mat-css-arrow mat-css-down"
                        [disabled]="isNotifyTimeDisable"
                      >
                        <mat-option
                          *ngFor="
                            let searchBy of notifyTimeSlots;
                            let i = index
                          "
                          [value]="searchBy.id"
                        >
                          {{ searchBy.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- bb -->
            <ng-container *ngIf="eventType == 'manual'">
              <div class="form-row mb-3">
                <p
                  class="font-weight-bold m-0 enable-mutiday font-14 fontColor"
                >
                  Ask User to Register
                  <mat-slide-toggle
                    class="ml-4"
                    (change)="userRegisterChange($event)"
                    formControlName="userRegister"
                    
                    >&nbsp;</mat-slide-toggle
                  >
                </p>
              </div>

              <div class="mb-3">
                <div class="form-row">
                  <p
                    class="font-weight-bold m-0 col-12 enable-mutiday font-14 fontColor pl-0"
                  >
                    Survey
                    <mat-slide-toggle
                      class="ml-4"
                      formControlName="survey"
                      
                      (change)="reEnableSurvey($event)"
                      >&nbsp;</mat-slide-toggle
                    >
                  </p>
                  <p
                    *ngIf="f.survey.value == true"
                    class="col-6 ml-custom-left-1 pl-0"
                  >
                    Choose the date and time to notify users about the eventThe
                    Survey will be sent to all registered participants only
                    after the event end time.
                  </p>
                </div>
                <ng-container *ngIf="f.survey.value == true">
                  <div class="form-row">
                    <div class="col-md-4">
                      <div class="input-group">
                        <input
                          id="start_date"
                          class="form-control br-0"
                          name="suDate"
                          placeholder="Select date"
                     
                          [owlDateTime]="dt4"
                          [disabled]="presentStatusCheck() "
                          [min]="endDate"
                          [(ngModel)]="surveyDate"
                          [ngModelOptions]="{ standalone: true }"
                          (ngModelChange)="onSurveyDateChange($event)"
                        />
                        <span [owlDateTimeTrigger]="dt4" class="m-ne">
                          <img
                            src="../../../../assets/images/event/calendrer.png"
                            style="width: 70px"
                          />
                        </span>
                        <owl-date-time
                          [pickerType]="'calendar'"
                          #dt4
                        ></owl-date-time>
                      </div>
                    </div>
                  </div>
                  <div class="form-row mb-3">
                    <div class="col-md-3">
                      <label>time to notify from </label>

                      <mat-form-field appearance="fill" style="display: block">
                        <mat-select
                        [disabled]="presentStatusCheck() "
                          placeholder="Select time"
                          [(ngModel)]="surveyTime"
                          [ngModelOptions]="{ standalone: true }"
                          name="searchType"
                          id="searchType"
                          class="mat-css-arrow mat-css-down"
                        >
                          <mat-option
                            *ngFor="
                              let searchBy of surveyTimeSlots;
                              let i = index
                            "
                            [value]="searchBy.id"
                          >
                            {{ searchBy.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-row">
                    <p
                      (click)="viewSurvey()"
                      class="font-weight-bold cursor-pointer enable-mutiday under-line font-14 fontColor"
                    >
                      View Survey
                    </p>
                  </div>
                  <div class="form-row">
                    <p
                      class="font-weight-bold enable-mutiday mb-0 font-14 fontColor"
                    >
                      Make survey Anonymous
                      <mat-slide-toggle
                        class="ml-4"
                        formControlName="surveyAnonymous"
                        
                        >&nbsp;</mat-slide-toggle
                      >
                    </p>
                  </div>
                </ng-container>
              </div>

              <div class="mb-3">
                <div class="form-row">
                  <p
                    class="font-weight-bold m-0 enable-mutiday font-14 fontColor"
                  >
                    Feedback
                    <mat-slide-toggle
                      class="ml-4"
                      formControlName="feedback"
                      
                      (change)="reEnableFeedback($event)"
                      >&nbsp;</mat-slide-toggle
                    >
                    <img
                      class="ml-2 mt-neg"
                      mat-button
                      matTooltipClass="custom-tooltip"
                      matTooltipPosition="right"
                      matTooltip="Feedback about the event is sent as a 5 star rating to the user "
                      src="../../../../assets/images/event/info.png"
                    />
                  </p>
                  <p
                    *ngIf="f.feedback.value == true"
                    class="col-12 ml-custom-left-1 pl-0"
                  >
                    By default, Event feedback can be shared only after Event
                    End time
                  </p>
                </div>
                <ng-container *ngIf="f.feedback.value == true">
                  <div class="form-row">
                    <div class="col-md-4">
                      <div class="input-group">
                        <input
                          id="start_date"
                          class="form-control br-0"
                          name="feDate"
                          placeholder="{{ 'startDate' | translate }}"
                     
                          [owlDateTime]="dt5"
                          [(ngModel)]="feedbackDate"
                          [min]="endDate"
                          [ngModelOptions]="{ standalone: true }"
                          (ngModelChange)="onFeedbackDateChange($event)"
                          [disabled]="presentStatusCheck() "
                        />
                        <span [owlDateTimeTrigger]="dt5" class="m-ne">
                          <img
                            src="../../../../assets/images/event/calendrer.png"
                            style="width: 70px"
                          />
                        </span>
                        <owl-date-time
                          [pickerType]="'calendar'"
                          #dt5
                        ></owl-date-time>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-3">
                      <label>Event End time </label>

                      <mat-form-field appearance="fill" style="display: block">
                        <mat-select
                          placeholder="Select time"
                          [(ngModel)]="feedbackTime"
                          [disabled]="presentStatusCheck() "
                          [ngModelOptions]="{ standalone: true }"
                          name="searchType"
                          id="searchType"
                          class="mat-css-arrow mat-css-down"
                        >
                          <mat-option
                            *ngFor="
                              let searchBy of feedbackTimeSlots;
                              let i = index
                            "
                            [value]="searchBy.id"
                          >
                            {{ searchBy.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="mb-3">
                <div class="form-row">
                  <p
                    class="font-weight-bold enable-mutiday mb-0 font-14 fontColor"
                  >
                    Maximum Participants
                    <mat-slide-toggle
                      class="ml-4"
                      (change)="isUserRegisterEnabled($event, 'maxP')"
                      formControlName="maxParticipants"
                      
                      >&nbsp;</mat-slide-toggle
                    >
                  </p>
                </div>

                <ng-container *ngIf="f.maxParticipants.value == true">
                  <div class="form-row mt-3">
                    <div class="col-1">
                      <input
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="maxParticipants"
                        type="number"
                        name="duration"
                        id="duration"
                        
                        class="form-control custom_shadow"
                        min="0"
                      />
                    </div>
                    <div class="col-1 d-flex align-items-center">
                      <span class="ml-2 mt-2">Persons</span>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- <div class="mb-3">
                <div class="form-row">
                  <p
                    class="font-weight-bold mb-0 enable-mutiday font-14 fontColor"
                  >
                    Enable Payments
                    <mat-slide-toggle
                      class="ml-4"
                      formControlName="empPayment"
                      
                      (change)="isUserRegisterEnabled($event,'empP')"
                      >&nbsp;</mat-slide-toggle
                    >
                  </p>
                </div>

                <ng-container *ngIf="f.empPayment.value == true">
                  <div class="form-row mt-3">
                    <div class="col-12">
                      <p
                        class="font-weight-bold enable-mutiday font-14 fontColor"
                      >
                        Cost Per Employee
                      </p>
                    </div>

                    <div class="col-md-2">
                      <label>Currency </label>

                      <mat-form-field appearance="fill" style="display: block">
                        <mat-select
                        placeholder="Select time"
                          [(ngModel)]="empCostType"
                          [ngModelOptions]="{ standalone: true }"
                          name="searchType"
                          id="searchType"
                          class="mat-css-arrow mat-css-down"
                        >
                          <mat-option
                            *ngFor="let searchBy of currencyList; let i = index"
                            [value]="searchBy.id"
                          >
                            {{ searchBy.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col-md-3">
                      <label>Amount</label>
                      <input
                        [(ngModel)]="empCost"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control blue-regular"
                        id="dir_id"
                        placeholder="Enter Amount"
                        type="number"
                        min="0"
                      />
                    </div>
                  </div>
                </ng-container>
              </div> -->

              <div class="mb-3">
                <div class="form-row">
                  <p
                    class="font-weight-bold mb-0 enable-mutiday font-14 fontColor"
                  >
                    Allow Guest
                    <mat-slide-toggle
                      class="ml-4"
                      (change)="isUserRegisterEnabled($event, 'guest')"
                      formControlName="allowGuest"
                      
                      >&nbsp;</mat-slide-toggle
                    >
                  </p>
                  <p
                *ngIf="f.allowGuest.value == true"
                class="col-12 ml-custom-left-1 pl-0"
              >
              The maximum number of participants is inclusive of the number of guests allowed per employee.
              </p>
                </div>
                

                <ng-container *ngIf="f.allowGuest.value == true">
                  <div class="form-row mb-3 ">
                    <p
                      class="font-weight-bold enable-mutiday font-14 fontColor col-12"
                    >
                      Set Guest Limit per Employee
                    </p>

                    <div class="col-1">
                      <input
                        type="number"
                        name="duration"
                        id="duration"
                        
                        [(ngModel)]="guestLimit"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control custom_shadow"
                        min="0"
                      />
                    </div>
                    <div class="col-1 d-flex align-items-center">
                      <span class="ml-2 mt-2">Persons</span>
                    </div>
                  </div>

                  <div class="form-row mb-3">
                    <p
                      class="font-weight-bold enable-mutiday mb-0 font-14 fontColor col-12"
                    >
                      Capture Guest Information
                    </p>
                    <p
                   
                    class="col-12 ml-custom-left-1 mb-1 mt-0"
                  >
                  Email and Name of Guest is required mandatorily to share event details upon employee registration
                  </p>
                    <div class="row d-flex flex-column ml-2">
                      <div class="form-check form-check-inline">
                        <mat-checkbox
                          class="example-margin ml-0 pt-2 pb-2 notAdmin"
                          [(ngModel)]="guestEmail"
                          [ngModelOptions]="{ standalone: true }"
                          [disabled]="true"
                          [class.mat-checkbox-disabled]="true"
                        >
                          <span
                            class="mat-check-label-custom"
                            
                            >Email of Guest</span
                          >
                        </mat-checkbox>
                      </div>

                      <div class="form-check form-check-inline">
                        <mat-checkbox
                          class="example-margin ml-0 pt-2 pb-2 notAdmin"
                          [(ngModel)]="guestName"
                          [ngModelOptions]="{ standalone: true }"
                          [disabled]="true"
                          [class.mat-checkbox-disabled]="true"
                        >
                          <span
                            class="mat-check-label-custom"
                            
                            >Name of Guest</span
                          >
                        </mat-checkbox>
                      </div>

                      <div class="form-check form-check-inline">
                        <mat-checkbox
                          class="example-margin ml-0 pt-2 pb-2 notAdmin"
                          [(ngModel)]="guestMobile"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <span
                            class="mat-check-label-custom"
                            
                            >Phone Number of Guest</span
                          >
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="form-row mb-3">
                    <p
                      class="font-weight-bold enable-mutiday mb-0 font-14 fontColor"
                    >
                      Enable Payment for Guest
                      <mat-slide-toggle
                        class="ml-4"
                        formControlName="enableGuestPayment"
                        
                        >&nbsp;</mat-slide-toggle
                      >
                    </p>
                  </div>
<ng-container *ngIf="f.enableGuestPayment.value == true" >
                  <div class="form-row">
                    <div class="col-12">
                      <p
                        class="font-weight-bold enable-mutiday font-14 fontColor"
                      >
                        Cost Per Guest
                      </p>
                    </div>

                    <div class="col-md-2">
                      <label>Currency </label>

                      <mat-form-field appearance="fill" style="display: block">
                        <mat-select
                        placeholder="Select time"
                          [(ngModel)]="guestCostType"
                          [ngModelOptions]="{ standalone: true }"
                          name="searchType"
                          id="searchType"
                          class="mat-css-arrow mat-css-down"
                        >
                          <mat-option
                            *ngFor="let searchBy of currencyList; let i = index"
                            [value]="searchBy.id"
                          >
                            {{ searchBy.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col-md-3">
                      <label>Amount</label>
                      <input
                        [(ngModel)]="guestCost"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control blue-regular"
                        id="dir_id"
                        placeholder="Enter amount"
                        type="number"
                        min="0"
                      />
                    </div>
                  </div>
                </ng-container> -->
                </ng-container>
              </div>
            </ng-container>

            <div class="form-row">
              <div class="col-12">
                <button
                  type="submit"
                  class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                >
                  Done
                </button>
              </div>
            </div>
          </form>
        </ng-container>


     
      </div>

    </ng-container>
  </div>
</div>

<div *ngIf="pageType == 'survey1'">
  <div class="modal-header pl-0">
   
    <h5>
      <i
    
      aria-hidden="true"
      class="fa fa-angle-left cursor-pointer mr-2 backBtn"
      (click)="backSurvey()"
    ></i>
      Create survey for the Event</h5>
  
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">Events &gt; Survey</p>
  </div>
  <div class="modal-body width-400 align-left">
    <form #surf="ngForm" (ngSubmit)="submitSurvey1(surf)">
      <div class="form-row mb-3">
        <div class="col-md-7">
          <label>Survey Title </label>
          <input
            class="form-control blue-regular"
            id="dir_id"
            [(ngModel)]="surveyTitle"
            name="surveyTitle"
            placeholder="Enter title"
            type="text"
            required
            [ngClass]="{
              'is-invalid': submittedSurvey && surveyTitle.trim() == ''
            }"
            maxlength="250"
          />
          <div
            *ngIf="submittedSurvey && surveyTitle.trim() == ''"
            class="invalid-feedback"
          >
            Survey title is required
          </div>
          <div *ngIf="surveyTitle?.length>0" class="invalid-feedback d-block">
            {{250 - surveyTitle?.length}} characters left
          </div>
        </div>
      </div>
      <ng-container *ngFor="let ques of quesList; let k = index">
        <div class="form-row mb-3">
          <div class="col-md-7">
            <label> Question {{ k + 1 }}</label>
            <input
              class="form-control blue-regular"
              id="dir_id"
              placeholder="Enter question"
              [(ngModel)]="ques.value"
              type="text"
              required
              name="ques{{ k + 1 }}"
              [ngClass]="{
                'is-invalid': submittedSurvey && (ques.value.trim() == '' || checkDuplicateQuestions(k))
              }"
              maxlength="250"
            />
            <div
              *ngIf="submittedSurvey && (ques.value.trim() == '' || checkDuplicateQuestions(k))"
              class="invalid-feedback"
            >
            {{ checkDuplicateQuestions(k) ? 'Duplicate question' : 'Question is required' }}
            </div>
            <div *ngIf="ques.value?.length>0" class="invalid-feedback d-block">
              {{250 - ques.value?.length}} characters left
            </div>
          </div>
          <div class="col-md-4">
            <label for="inputCountry">Question Type</label>

            <mat-form-field appearance="fill" style="display: block">
              <mat-select
                [(ngModel)]="ques.type"
                placeholder="Select time"
                (selectionChange)="changeQuesType($event)"
                name="searchType"
                id="searchType"
                class="mat-css-arrow mat-css-down"
                name="quesType{{ k + 1 }}"
              >
                <mat-option
                  *ngFor="let ql of questionList; let i = index"
                  [value]="ql.id"
                >
                  {{ ql.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="ques.type == 'MULTIPLE_CHOICE'">
          <ng-container *ngFor="let ans of ques.MCQAns; let i = index">
            <div class="form-row align-items-center">
              <div class="col-3 inline-1">
                <img class="" src="../../../../assets/images/event/Radio.png" />
                <input
                  class="form-control blue-regular ml-2"
                  [(ngModel)]="ans.option"
                  id="dir_id"
                  placeholder="Enter Option {{ i + 1 }}"
                  type="text"
                  name="mcq{{ k }}{{ i }}"
                  required
                  [ngClass]="{
                    'is-invalid':
                      submittedSurvey &&
                      (ans.option == null || ans.option.trim() == '' || checkDuplicateOptions(k, i) ),
                    'b-none': ans.option != null && ans.option.trim() != '' && !checkDuplicateOptions(k, i) 
                  }"
                  maxlength="50"
                />
              </div>

              <div class="pull-right col-9">
                <img
                  class="icon-size cursor-pointer mr-5 pull-right"
                  (click)="removeMCQAns(k, i)"
                  src="../../../../assets/images/event/Cross.png"
                />
              </div>
            </div>
            <div
              *ngIf="
                submittedSurvey &&
                (ans.option == null || ans.option.trim() == '' || checkDuplicateOptions(k, i))
              "
              class="pl-4 mt-0 invalid-feedback d-block"
            >
            {{ checkDuplicateOptions(k, i) ? 'Duplicate option' : 'MCQ option is required' }}
            </div>
            <div *ngIf="ans.option?.length>0" class=" pl-4 mt-0  invalid-feedback d-block">
              {{50 - ans.option?.length}} characters left
            </div>
          </ng-container>
          <div class="form-row">
            <div class="col-6 align-items-center">
              <img class="" src="../../../../assets/images/event/Radio.png" />
              <span
                style="color: #5c5d5d"
                class="cursor-pointer link-cstm ml-2"
                (click)="addMCQAns(k)"
                >Add Option
              </span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="ques.type == 'SHORT_ANSWER'">
          <div class="form-row mb-3">
            <p class="font-weight-bold pl-3 enable-mutiday font-14 fontColor">
              short answer
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="ques.type == 'CHECK_BOX'">
          <ng-container *ngFor="let ans of ques.checkAns; let i = index">
            <div class="form-row align-items-center">
              <div class="col-3 inline-1">
                <img
                  class=""
                  src="../../../../assets/images/event/Checkbox.png"
                />
                <input
                  class="form-control blue-regular ml-2"
                  [(ngModel)]="ans.option"
                  id="dir_id"
                  placeholder="Enter Option {{ i + 1 }}"
                  type="text"
                  required
                  name="checkBoxAns{{ k }}{{ i }}"
                  [ngClass]="{
                    'is-invalid':
                      submittedSurvey &&
                      (ans.option == null || ans.option.trim() == '' || checkDuplicateOptionsCheck(k, i)  ),
                    'b-none': ans.option != null && ans.option.trim() != '' && !checkDuplicateOptionsCheck(k, i)
                  }"
                  maxlength="50"
                />
            
              </div>

              <div class="pull-right col-9">
                <img
                  class="icon-size mr-5 cursor-pointer pull-right"
                  (click)="removeCheckAns(k, i)"
                  src="../../../../assets/images/event/Cross.png"
                />
              </div>
            </div>
            <div
              *ngIf="
                submittedSurvey &&
                (ans.option == null || ans.option.trim() == '' || checkDuplicateOptionsCheck(k, i)   )
              "
              class="pl-4 mt-0 invalid-feedback d-block"
            >
              
              {{ checkDuplicateOptionsCheck(k, i) ? 'Duplicate option' : 'Checkbox option is required' }}
            </div>
            <div *ngIf="ans.option?.length>0" class=" pl-4 mt-0 invalid-feedback d-block">
              {{50 - ans.option?.length}} characters left
            </div>
          </ng-container>
          <div class="form-row">
            <div class="col-6 align-items-center">
              <img
                class=""
                src="../../../../assets/images/event/Checkbox.png"
              />
              <span
                style="color: #5c5d5d"
                class="cursor-pointer link-cstm ml-2"
                (click)="addCheckAns(k)"
                >Add Option
              </span>
            </div>
          </div>
        </ng-container>

        <div class="form-row">
          <p
            class="font-weight-bold cursor-pointer m-0 enable-mutiday font-14 fontColor"
          >
            Mandatory Question
            <mat-slide-toggle
              class="ml-4"
              
              [(ngModel)]="ques.isMandatory"
              name="quesMand{{ k + 1 }}"
              >&nbsp;</mat-slide-toggle
            >
            <img
              class="cursor-pointer"
              (click)="delQues(k)"
              src="../../../../assets/images/event/Delete.png"
            />
          </p>
        </div>
      </ng-container>
      <div class="form-row">
        <div class="col-12">
          <button
            type="submit"
            class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
          >
            Done
          </button>

          <button
            (click)="addQues()"
            type="button"
            class="btn btn-main pull-right btn-white f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
          >
            Add More
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- <div *ngIf="pageType == 'survey2'">
  <div class="modal-header pl-0">
    <h5>
      <i
        aria-hidden="true"
        class="fa fa-angle-left cursor-pointer mr-2 backBtn"
        (click)="backSurvey1()"
      ></i>
      Create Event
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0 fontColor">Events &gt; Surveys</p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="form-row mb-3">
      <p class="enable-mutiday font-14 openSans col-12">
        Would you like to save this survey questionnaire template for future
        use?
      </p>

      <mat-radio-group
        
        class="font-color pl-3 disp-flex"
        [(ngModel)]="saveSurvey"
      >
        <mat-radio-button
          class="pb-0 custom-radio-btn custom-radio-btn-label mt-3"
          value="true"
        >
          Yes
        </mat-radio-button>

        <mat-radio-button
          class="pb-0 mt-3 custom-radio-btn custom-radio-btn-label"
          value="false"
        >
          No
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-row">
      <div class="col-12">
        <button
          *ngIf="saveSurvey !== ''"
          (click)="saveSurveyRef()"
          class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="pageType == 'survey3'">
  <div class="modal-header pl-0">
    <h5>
      <i
        aria-hidden="true"
        class="fa fa-angle-left cursor-pointer mr-2 backBtn"
        (click)="backSurvey2()"
      ></i>
      Create Event
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0 fontColor">Events &gt; Surveys</p>
  </div>
  <div class="modal-body width-400 align-left">
    <button
      (click)="backEvent()"
      class="btn btn-main f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      Go Back to Event Creation
    </button>
  </div>
</div> -->

<div *ngIf="pageType == 'feedback'">
  <div class="modal-header pl-0">
    <h5>FeedBack Responses ( {{ feedbackData.responseCount }} responses)</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-4">
    <p class="italic mt-2 openSans pl-2 mb-0 fontColor">Events &gt; Feedback</p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="row ml-3 mr-3">
      <div class="col-12 pl-0 box-feedback pt-3 pb-3">
        <div class="col-12">
          <span class="mt-2 openSans pl-2 mb-0 fontColorBold f-16"
            >How would you rate the {{ feedbackData.eventName }} ?</span
          >
        </div>
        <div class="col-12">
          <p class="italic mt-2 openSans pl-2 mb-0 fontColor">
            {{ feedbackData.responseCount }} responses
          </p>
        </div>
        <div class="col-12 mt-2 ml-2 mb-4">
          <ng-container *ngFor="let item of rating">
            <img
              src="../../../../assets/images/event/star_blue.png"
              alt=""
              class="mr-2"
            />
          </ng-container>
          <ng-container *ngFor="let item of nonRating">
            <img
              src="../../../../assets/images/event/star.png"
              alt=""
              class="mr-2"
            />
          </ng-container>
          <span class="mt-2 openSans pl-2 mb-0 fontColorBold f-16"
            >Average Rating {{ feedbackData.rating }}/5
          </span>
        </div>
        <ng-container *ngFor="let item of feedbackLoaderArray">
          <div class="col-12 mt-3 ml-3 dis-flex">
            <span class="mr-3"> {{ item.star }} star </span>
            <span
              [style.background]="getLoaderGradient(item.percentage)"
              class="loader-model"
            ></span>
            <span class="ml-3"> {{ item.percentage }}%</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="pageType == 'surveyDetail'">
  <div class="modal-header pl-0">
    <h5>Survey Responses ( {{ surveyData.responseCount }} responses)</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-4">
    <p class="italic mt-2 openSans pl-2 mb-0 fontColor">Events &gt; Survey</p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="row">
      <div class="col-12">
        <img
          src="../../../../assets/images/event/download.png"
          (click)="downloadSurveyReport()"
        />
        <span class="italic mt-2 openSans mb-0 fontColor">
          Download Responses
        </span>
      </div>
      <div class="col-12">
        <p class="mt-2 openSans pl-2 mb-0 fontColorBold f-16">Summary</p>
      </div>
    </div>
    <div class="row ml-3 mr-3">
      <ng-container *ngFor="let ele of shortAnswerArray">
        <div class="col-12 pl-0 box-feedback pt-3 pb-3 mt-3 max-overflow">
          <div class="col-12">
            <span class="mt-2 openSans pl-2 mb-0 fontColorBold f-16">{{
              ele.question
            }}</span>
          </div>
          <div class="col-12">
            <p class="italic mt-2 openSans pl-2 mb-0 fontColor">
              {{ ele.responses.length }} responses
            </p>
          </div>
          <div class="col-12 model-overflow">
            <ng-container *ngFor="let val of ele.responses">
              <div class="col-12 mt-2 mb-4">
                <p class="col-white">
                  {{ val }}
                </p>
                <!-- <ng-container *ngFor="let item of rating" >
<img src="../../../../assets/images/event/star_blue.png" alt="" class="mr-2">
        </ng-container>
        <ng-container *ngFor="let item of nonRating" >
          <img src="../../../../assets/images/event/star.png" alt="" class="mr-2">
        </ng-container> -->
                <!-- <span class="mt-2 openSans pl-2 mb-0 fontColorBold f-16">Average Rating {{feedbackData.rating}}/5 </span> -->
              </div>
            </ng-container>
          </div>
          <!-- <ng-container *ngFor="let item of feedbackLoaderArray">

      <div class="col-12 mt-3 ml-3 dis-flex">
        <span class="mr-3"> {{item.star}} star </span> <span [style.background]="getLoaderGradient(item.percentage)" class="loader-model" ></span> <span class="ml-3"> {{item.percentage}}%</span>
      </div>



     </ng-container> -->
        </div>
      </ng-container>
    </div>
    <div class="row ml-3 mr-3">
      <ng-container *ngFor="let ele of checkBoxArray">
        <div class="col-12 pl-0 box-feedback pt-3 pb-3 mt-3">
          <div class="col-12">
            <span class="mt-2 openSans pl-2 mb-0 fontColorBold f-16">{{
              ele.question
            }}</span>
          </div>
          <div class="col-12">
            <p class="italic mt-2 openSans pl-2 mb-0 fontColor">
              {{ ele.responses }} responses
            </p>
          </div>

          <div class="col-12" *ngIf="ele.responses != 0">
            <highcharts-chart
              class="highCharts"
              [Highcharts]="Highcharts"
              [options]="ele.chartOptions"
              highcharts-chart
            >
            </highcharts-chart>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row ml-3 mr-3">
      <ng-container *ngFor="let ele of multipleChoiceArray">
        <div class="col-12 pl-0 box-feedback pt-3 pb-3 mt-3">
          <div class="col-12">
            <span class="mt-2 openSans pl-2 mb-0 fontColorBold f-16">{{
              ele.question
            }}</span>
          </div>
          <div class="col-12">
            <p class="italic mt-2 openSans pl-2 mb-0 fontColor">
              {{ ele.responses }} responses
            </p>
          </div>

          <div class="col-12" *ngIf="ele.responses != 0">
            <highcharts-chart
              class="highCharts"
              [Highcharts]="Highcharts"
              [options]="ele.chartOptions"
              highcharts-chart
            >
            </highcharts-chart>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="pageType == 'userRegisterdDetail'">
  <div class="modal-header pl-0">
    <h5>Registrations Details ( {{ dataSource?.data?.length }} responses)</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-4">
    <p class="italic mt-2 openSans pl-2 mb-0 fontColor">
      Events &gt; Registrations
    </p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="col-lg-12 mt-neg-1">
      <div class="col-12 px-0">
        <div class="form-rows mb-0">
          <div class="form-group col-lg-12 row col-md-12 col-sm col-xs">
            <div class="pl-0 col-10 d-flex align-items-center">
              <div class="w-40">
                <label for="serch" class="lbl-text"><b>SEARCH</b></label>
                <div class="input-group ">
                <input
                  class="form-control"
                  [(ngModel)]="userRegisterSearch"
                  placeholder="&#xF002; Search Employee Name, Email or Phone Number"
                  style="font-family: Arial, FontAwesome"
                  (keyup.enter)="userRegisterSearch && searchRegisterUserName(userRegisterSearch)"
                />
                <button *ngIf="userRegisterSearch || !userRegisterSearchActive" aria-label="Close" class="close close_button" type="button" 
                style="position: relative;right: 24px;z-index: 1000;"   (click)="clearSearchRegisterUserName()">
            <span aria-hidden="true">×</span>
        </button>

                </div>

              </div>
              <div class="pr-0 ml-4 pt-4">
                <button
                
                  class="btn btn-main f-size-small btn-radius p-1 btnSmall filter btn-primary v-m"
                  type="button"
                  (click)="searchRegisterUserName(userRegisterSearch)"
                >
                  Search
                </button>
                <!-- <button
                  *ngIf="!userRegisterSearchActive"
                  class="btn btn-main btn-white f-size-small btn-radius p-1 btnSmall filter btn-primary v-m"
                  type="button"
                  (click)="clearSearchRegisterUserName()"
                >
                  Clear
                </button> -->
                <img
                  (click)="downloadRegisterdUser()"
                  class="cursor-pointer"
                  src="../../../../assets/images/event/download.png"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <img class="float-right topIcon ml-2 cursor-pointer dwnldbtnpsn"
  src="../../assets/images/jumpree_digital/download.png"> -->
      </div>
      <div class="col-lg-12 p-0 col-md-12 col-sm-12 mt-3">
        <table
          class="mat-sort_table"
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="category"
          matSortDirection="desc"
        >
          <!-- <table class="mat-sort_table" mat-table [dataSource]="dataSource" matSort matSortActive="parentGroup"
              matSortDirection="desc"> -->
          <!-- Sl No -->
          <ng-container matColumnDef="timeStamp">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Time Stamp
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ formatTimestamp(element.createdAt) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="employee">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Employee
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="employeeEmail">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Employee email
            </th>
            <td
              mat-cell
              [matTooltip]="element.email"
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.email | slice : 0 : 12 }}...
            </td>
          </ng-container>
          <ng-container matColumnDef="employeeMobile">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Employee Phone Number
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.phoneNumber }}
            </td>
          </ng-container>
          <ng-container matColumnDef="noOfGuest">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Number of Guest
            </th>
            <td
              mat-cell
              [ngClass]="{
                'link-cstm': element.totalMembers != 0,
                'cursor-pointer': element.totalMembers != 0
              }"
              (click)="openGuestDetails(element)"
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.totalMembers }}
            </td>
          </ng-container>

          <ng-container matColumnDef="paymentStatus">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Payment Status
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.status }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="col-12" *ngIf="dataSource?.data?.length == 0">
          <p class="text-center bolder mt-2">
            {{ "No Data is available" | translate }}
          </p>
        </div>
      </div>
      <mat-paginator
        class="w-100 mtop-1"
        #paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="getPrevOrNextRecords($event)"
        [length]="length"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<div *ngIf="pageType == 'userRegisterdGuestDetail'">
  <div class="modal-header pl-0">
    <h5>
      <i
        aria-hidden="true"
        class="fa fa-angle-left cursor-pointer mr-2 backBtn"
        (click)="closeGuestPopup()"
      ></i>
      Guest Details
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-4">
    <p class="italic mt-2 openSans pl-2 mb-0 fontColor">
      Events &gt; Registrations &gt; Guests Details
    </p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="col-lg-12 mt-neg-1">
      <div class="col-12 px-0">
        <div class="form-rows mb-0">
          <div class="form-group col-lg-12 row col-md-12 col-sm col-xs">
            <div class="pl-0 col-10 d-flex align-items-center">
              <div class="w-40">
                <label for="serch" class="lbl-text"><b>SEARCH</b></label>
                <div class="input-group ">
                <input
                  class="form-control"
                  [(ngModel)]="guestSearch"
                  placeholder="&#xF002; Search Guest Name, Email or Phone Number"
                  style="font-family: Arial, FontAwesome"
                  (keyup.enter)="guestSearch && searchGuest(guestSearch)"
                />
                <button *ngIf="guestSearch || !guestSearchActive" aria-label="Close" class="close close_button" type="button" 
                style="position: relative;right: 24px;z-index: 1000;"  (click)="clearSearchGuest()">
            <span aria-hidden="true">×</span>
        </button>
        </div>
              </div>
              <div class="pr-0 ml-4 pt-4">
                <button
                
                  class="btn btn-main f-size-small btn-radius p-1 btnSmall filter btn-primary v-m"
                  type="button"
                  (click)="searchGuest(guestSearch)"
                >
                  Search
                </button>
                <!-- <button
                  *ngIf="!guestSearchActive"
                  class="btn btn-main btn-white f-size-small btn-radius p-1 btnSmall filter btn-primary v-m"
                  type="button"
                  (click)="clearSearchGuest()"
                >
                  Clear
                </button> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <img class="float-right topIcon ml-2 cursor-pointer dwnldbtnpsn"
  src="../../assets/images/jumpree_digital/download.png"> -->
      </div>
      <div class="col-lg-12 p-0 col-md-12 col-sm-12 mt-3">
        <table
          class="mat-sort_table"
          mat-table
          [dataSource]="dataSource1"
          matSort
          matSortActive="category"
          matSortDirection="desc"
        >
          <!-- <table class="mat-sort_table" mat-table [dataSource1]="dataSource1" matSort matSortActive="parentGroup"
              matSortDirection="desc"> -->
          <!-- Sl No -->
          <ng-container matColumnDef="employee">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Employee Name
            </th>
            <td
              mat-cell
              [attr.rowspan]="dataSource1?.data?.length"
              *matCellDef="let element; let i = index"
              [style.display]="i == 0 ? '' : 'none'"
              class="align-left"
            >
              {{ element.userDTO.username }}
            </td>
          </ng-container>
          <ng-container matColumnDef="noOfGuest">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Number of Guests
            </th>
            <td
              [attr.rowspan]="dataSource1?.data?.length"
              mat-cell
              *matCellDef="let element; let i = index"
              [style.display]="i == 0 ? '' : 'none'"
              class="align-left"
            >
              {{ dataSource1?.data?.length }}
            </td>
          </ng-container>

          <ng-container matColumnDef="guestName">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Guest Name
            </th>
            <td
              mat-cell
              [matTooltip]="element.email"
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.guestName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="guestEmail">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Guest Email
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.guestEmail | slice : 0 : 12 }}...
            </td>
          </ng-container>
          <ng-container matColumnDef="guestMobile">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Guest Phone Number
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.guestPhone }}
            </td>
          </ng-container>

          <ng-container matColumnDef="paymentStatus">
            <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                      (click)="sortingData('parentGroup')"> -->
            <th mat-header-cell *matHeaderCellDef class="align-left">
              Guest Payment Status
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.status }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsGuest"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsGuest"></tr>
        </table>
        <div class="col-12" *ngIf="dataSource1?.data?.length == 0">
          <p class="text-center bolder mt-2">
            {{ "No Data is available" | translate }}
          </p>
        </div>
      </div>
      <mat-paginator
        class="w-100 mtop-1"
        #paginator1
        [pageSize]="pageSize1"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="getPrevOrNextRecordsGuest($event)"
        [length]="length1"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<div *ngIf="pageType == 'deleteEvent'">
  <div class="modal-header pl-0">
    <h5>Delete event</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-4">
    <p class="italic mt-2 openSans pl-2 mb-0 fontColor">Events &gt; Delete</p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="col-12">
      <span class="mt-2 openSans mb-2 fontColor">
        Are you sure you want to delete {{ data["eventName"] }} ? Registrants
        (if any) will be notified automatically regarding event cancellation.
      </span>
    </div>
    <div class="col-12">
      <button
        (click)="deleteEvent('yes')"
        class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 ml-3 m-1 filter btn-primary"
      >
        Yes
      </button>
      <button
        (click)="deleteEvent('no')"
        class="btn btn-main btn-white pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
      >
        No
      </button>
    </div>
  </div>
</div>
<div *ngIf="data.type == 'downloadDateSelect'">
  <div class="modal-header pl-0">
    <h5>Download</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">Events &gt; Download</p>
  </div>
  <div class="modal-body width-400 align-left">
    <p class="dark-blue1 fontColorBold mb-2">
      {{ "downloadReportFrom" | translate }}
    </p>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      [(ngModel)]="selectedType"
      (change)="downloadReportTypeChange($event)"
    >
      <mat-radio-button
        class="example-radio-button mr-4"
        *ngFor="let type of filterType"
        [value]="type.id"
      >
        {{ type["name"] }}
      </mat-radio-button>
    </mat-radio-group>
    <ng-container *ngIf="selectedType == 'custom_date'">
      <form>
        <div class="row mt-2">
          <div class="form-group col-md-4 col-lg-4">
            <label class="font-weight-bold"
              >{{ "startDate" | translate }}
            </label>
            <div class="input-group">
              <input
                id="start_date"
                class="form-control"
                name="startDate1"
                placeholder="{{ 'startDate' | translate }}"
                [owlDateTimeTrigger]="sdt"
                [owlDateTime]="sdt"
                [(ngModel)]="startDate"
                readonly
                style="background-color: white"
              />
              <span [owlDateTimeTrigger]="sdt"
                ><i class="fa fa-calendar fa-calender-icons"></i
              ></span>
              <owl-date-time [pickerType]="'calendar'" #sdt></owl-date-time>
            </div>
          </div>
          <div class="form-group col-md-4 col-lg-4">
            <label class="font-weight-bold">{{ "endDate" | translate }} </label>
            <div class="input-group">
              <input
                id="end_date"
                class="form-control"
                name="endDate1"
                placeholder="{{ 'endDate' | translate }}"
                [owlDateTimeTrigger]="ed"
                [owlDateTime]="ed"
                [min]="startDate"
                [(ngModel)]="endDate"
                readonly
                style="background-color: white"
              />
              <span [owlDateTimeTrigger]="ed"
                ><i class="fa fa-calendar fa-calender-icons"></i
              ></span>
              <owl-date-time [pickerType]="'calendar'" #ed></owl-date-time>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <div class="row mt-1">
      <div class="col">
        <button
          type="button"
          class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 ml-3 m-1 filter btn-primary"
          (click)="downloadReport()"
        >
          {{ "download" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
