<div *ngIf="pageType == 'addEvent'">
  <div class="modal-header pl-0">
    <h5>
     
      Onboard Users
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">People</p>
  </div>
  <div class="modal-body width-400 align-left">
    <ng-container *ngIf="addType == '' || !showExcel">  
    <div class="row">
      <p class="f-16 pl-3 enable-mutiday font-14 fontColor">
        How would you like to onboard new users ?
      </p>
    </div>

    <div class="form-row">
      <mat-radio-group
        
        class="font-color pl-3 csm-class"
        [(ngModel)]="addType"
      >
        <mat-radio-button
          class="pb-0 mb-3 custom-radio-btn custom-radio-btn-label"
          value="manual"
        >
          Add Manually
        </mat-radio-button>

        <mat-radio-button
          class="pb-0 mb-3 custom-radio-btn custom-radio-btn-label"
          value="excel"
        >
          Add via excel
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-container>
    <ng-container *ngIf="addType == 'excel' && showExcel">

      
        <div class="row">
            <p class="fontColor pl-3">
                Bulk upload Tenant employees
                </p>
                </div>
        <div class="row">
            <p class="fontColorSemi pl-3">Note: Onboarding Tenant employees will require mentioning the employee name, corporate email address and tenant name mandatorily.  </p>
        
        </div>
      <div class="row">
        <div class="col-md-5">
          <label></label>
          <button
            type="button"
            class="form-control1"
            (click)="downloadViaExcelTemplate()"
          >
            <span style="font-family: 'Open Sans'">{{
              "downloadTemplate" | translate
            }}</span>
            <span class="ml-3"
              ><img
                src="./assets/images/downloadTemplate.png"
                style="width: 1rem"
            /></span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <label for="uploadFile" class="label1 float-left mt-2 font-label"
            >{{ "upload" | translate }} (.xlsx {{ "file" | translate }})</label
          >
          <div class="input-group clear-both float-left">
            <input
              type="file"
              class="form-control custom-file-input"
              id="importFile"
              aria-describedby="inputGroupFileAddon01"
              (click)="fileInputXLSX.value = null"
              #fileInputXLSX
              accept=".xlsx"
              (change)="uploadExcel($event.target.files)"
            />
            <label
              (click)="fileInputXLSX.click()"
              class="custom-file-label1 custom-upload-btn font-label"
              for="inputGroupFile01"
              #labelImport
            ></label>
          </div>
        </div>
        <div
          class="input-group col-md-4 mt-3"
          *ngIf="showUploadedExcelFileName != ''"
        >
          <p class="inner-card-auto p-1">
            <span class="text-overflow-hidden ml-1">{{
              showUploadedExcelFileName
            }}</span>
            <button
              type="button"
              class="close text-right right-flex"
              aria-label="Close"
            >
              <span aria-hidden="true" (click)="showUploadedExcelFileName = ''"
                >&times;</span
              >
            </button>
          </p>
        </div>
      </div>

      <div
        class="row col-12 text-danger"
        *ngIf="submittedExcel && showUploadedExcelFileName == ''"
      >
        Please upload file
      </div>
    </ng-container>

    <button
      *ngIf="addType != ''"
      (click)="selectType()"
      class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      Next
    </button>
  </div>
</div>
<div class="" *ngIf="pageType == 'verifyMail'">
  <div class="modal-header pl-0">
      <h6 class="semi-bold-blue">
          {{"onboardUser" | translate}}
      </h6>
      <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
  >
      <span aria-hidden="true">&times;</span>
  </button>
  </div>
  <div class="col-12 pl-4 ">
      <p class="italic mt-2 openSans pl-0 mb-0">
          People
      </p>
  </div>
  <div class="modal-body align-left width-400">
  
      <div class="row ">
          <div class="form-group col-lg-6 col-md-12  ">
              <label class="float-left formLabel"
                  >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
              >
              <input
                  type="email"
                  class="form-control blueText"
                  placeholder="{{'enterEmailID' | translate}}"
               [(ngModel)]="onboardUserEmailVerify"
              
              />
           
          </div>
          </div>

   
      <div class="">
          <ng-container>
              <div class="form-group col-lg-12 col-md-12 pr-0">
                  <button
                      type="button"
                      *ngIf="validateEmail(onboardUserEmailVerify)"
                      class="btn btn-main btnSmall smallBtn float-right ml-4"
                      (click)="verifyEmail()"
                  >
                      {{"next" | translate}}
                  </button>
                
              </div>
          </ng-container>
      </div>
  </div>
</div>



<div *ngIf="pageType == 'onboardingExcelTable'">
  <div class="modal-header pl-0 pr-0">
    <h5>{{ "alert" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body width-400 align-left mt-2 pb-0">
    <ng-container *ngIf="incorrectData.length > 0">
      <p class="regular-semibold-font">
        {{ "successfullyUploaded" | translate }}
                {{ TotalNoOfEntitiesUploadedRNW }} users ,
                {{'belowAreTheListOfEntitiesWhichHaveIssues' | translate}}.
      </p>
      <table mat-table [dataSource]="showIncorrectData" class="vms-error-table">
        <!-- First Name Column -->
        <ng-container matColumnDef="fname">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            First Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('First Name')
            }"
            [matTooltip]="getToolTipData(element.firstName)"
          >
            {{ element.firstName | slice : 0 : 20 }}
            <span *ngIf="element.firstName?.length > 20">...</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="lname">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Last Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Last Name')
            }"
            [matTooltip]="getToolTipData(element.lastName)"
          >
            {{ element.lastName | slice : 0 : 20 }}
            <span *ngIf="element.lastName?.length > 20">...</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Email
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Email')
            }"
            [matTooltip]="getToolTipData(element.corporateEmail)"
          >
            {{ element.corporateEmail | slice : 0 : 20 }}
            <span *ngIf="element.corporateEmail?.length > 20">...</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="tenantAdmin">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Tenant Admin
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Tenant Admin')
            }"
            [matTooltip]="getToolTipData(element.corporateEmail)"
          >
            {{ element.tenantAdmin | slice : 0 : 20 }}
            <span *ngIf="element.corporateEmail?.length > 20">...</span>
          </td>
        </ng-container>
        <!-- Capacity is only for Collab Spaces -->

        <!-- Reason Column -->
        <ng-container matColumnDef="reason">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="align-left"
            style="width: 20vw"
          >
            {{ "reason" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [matTooltip]="getToolTipData(element.reason)"
          >
            <span
              >{{ element.reason | slice : 0 : 30 }}
              <ng-container *ngIf="element.reason?.length > 30"
                >...</ng-container
              >
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="incorrectDataColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: incorrectDataColumns"></tr>
      </table>
      <!-- <ng-container *ngIf="totalIncorrectLength > 10">
        <mat-paginator
          class="w-100"
          #paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[10, 25, 100]"
          [showFirstLastButtons]="true"
          (page)="getNextRecords($event)"
          [length]="totalIncorrectLength"
        ></mat-paginator>
      </ng-container> -->

      <div class="row text-center button-alignToEnd float-right p-2 mt-2">
        <button
          class="btn btn-main f-size-small pl-3 pr-3 btn-radius p-1 m-1 filter btn-primary pull-right"
          (click)="reUploadEntities()"
        >
          {{ "reuploadFilesWithFixes" | translate }}
        </button>
        <button
          class="btn-main-white-nb ml-4 btn btn-main f-size-small pl-3 pr-3 btn-radius p-1 m-1 filter btn-primary pull-right"
          (click)="proceedToOnboard()"
        >
          {{ "proceedToOnboardTheRemainingEntries" | translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="correctData.length > 0 && incorrectData.length == 0">
      <p class="regular-semibold-font">
        {{ "successfullyUploaded" | translate }}
        {{ noOfEntitiesUploaded }} users. Click on Proceed to onboard the users.
      </p>
      <button
        class="btn btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
        (click)="proceedToOnboard()"
      >
        {{ "proceed" | translate }}
      </button>
    </ng-container>
  </div>
</div>
<div *ngIf="pageType == 'onboarding_reUploadCSV'">
  <div class="modal-header pl-0 pr-0">
    <h5>{{ "reUploadFile" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body width-400 align-left mt-2">
    <div class="row">
      <div class="col-12 pl-2">
        <img
          class="cursor-pointer"
          src="../../assets/images/jumpree_digital/download.png"
          (click)="downloadCurrentCSVFile()"
          width="45"
        />
        <span class="regular-semibold-font">
          {{ "downloadCurrentFile" | translate }}
        </span>
      </div>
    </div>
    <p class="regular-semibold-font mt-3">
      {{ "uploadFileWithFixes" | translate }}
    </p>
    <div class="row">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
        <label for="uploadFile" class="label1"
          >{{ "upload" | translate }} (.xlsx {{ "file" | translate }})</label
        >
        <div class="input-group">
          <input
            type="file"
            #fileInput
            class="form-control custom-file-input"
            id="importFile"
            (click)="fileInput.value = null"
            aria-describedby="inputGroupFileAddon01"
            accept=".xlsx"
            (change)="uploadExcel($event.target.files)"
          />
          <label
            class="custom-file-label1 custom-upload-btn"
            for="inputGroupFile01"
          >
          </label>
        </div>
      </div>
    </div>
    <div class="row m-0 mt-4" *ngIf="showUploadedExcelFileName">
      <p class="pConfirmationMessage custom-shadow ml-0">
        {{ showUploadedExcelFileName }}
        <span
          class="ml-4 cursor-pointer font-weight-bold"
          (click)="showUploadedExcelFileName = undefined"
          >×</span
        >
      </p>
    </div>
    <div class="row" *ngIf="showUploadedExcelFileName">
      <div class="col">
        <button
          type="button"
          class="btn btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
          (click)="validateExcelOnboarding()"
        >
          {{ "proceed" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>



<div class="" *ngIf=" pageType == 'onboardUserManualy' "
>
<div class="modal-header pl-0 pr-0">
    <!-- <h5 class="semi-bold-blue"><i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtnUsers()" *ngIf="!individual && pageType != 'editUsers'"></i> {{pageType != 'editUsers' ? 'Add' : 'Edit'}} {{"users" | translate}} <span *ngIf="!individual">(3/3)</span></h5> -->
    <h5 >{{editPeopleMode?'Edit Users' : 'Onboard Users'}} </h5>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
           closePopup()
        "
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="col-12">
  <p class="italic mt-2 openSans pl-2 mb-0">People</p>
</div>
<div class="modal-body width-400 align-left mt-2">
    

 






<form
        [formGroup]="addUsersManuallyForm"
        (ngSubmit)="submitManuallyFormTenant(pageType)"
    >
        <div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12  " 
           
            >
                <label class="float-left formLabel"
                    >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
                >
                <input
                    type="text"
                    [ngClass]="{'disabled': disableTenantEditForm}"
                    class="form-control blueText"
                    placeholder="{{'enterEmailID' | translate}}"
                    formControlName="email"
                />
                <div
                    *ngIf="
                        submittedUsers &&
                        addUsersManuallyForm.controls.email.errors
                    "
                >
                    <div class="text-danger formLabel">
                        {{"emailIsRequired" | translate}}
                    </div>
                </div>
            </div>
            <div
            class="form-group col-lg-6 col-md-12"
            style="margin-top: -0.3rem;"
        >
        
          

           
        </div>
       
         
        </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"firsNameCaps" | translate}}
                        <span class="text-danger">*</span></label
                    >
                    <input
                    [ngClass]="{'disabled': disableTenantEditForm}"
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'Entername' | translate}}"
                        formControlName="employeeName"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersManuallyForm.controls.employeeName.errors
                        "
                    >
                        <div class="text-danger formLabel">
                          <div *ngIf="addUsersManuallyForm.get('employeeName').hasError('required') ">
                            First Name is required
                          </div>
                          <div *ngIf="addUsersManuallyForm.get('employeeName').hasError('pattern') ">
                            First Name must contain only alphabets
                          </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"lastNameCaps" | translate}}
                        <span class="text-danger">*</span>
                       
                    </label>
                    <input
                    [ngClass]="{'disabled': disableTenantEditForm}"
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'Entername' | translate}}"
                        formControlName="lastName"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersManuallyForm.controls.lastName.errors
                        "
                    >
                        <div class="text-danger formLabel">
                          <div *ngIf="addUsersManuallyForm.get('lastName').hasError('required') ">
                            Last Name is required
                          </div>
                          <div *ngIf="addUsersManuallyForm.get('lastName').hasError('pattern') ">
                            Last Name must contain only alphabets
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6 col-md-12 ">
                <mat-checkbox
                [ngClass]="{'disabled': disableTenantEditForm}"
                    class="example-margin"
                    formControlName="tenantAdmin"
                    >Tenant Admin</mat-checkbox
                >
            </div>
            </div>
           

          
            <div class="row mt-2">
                <div class="form-group col-lg-12 d-flex col-md-12">
                    <!-- <p
                        class="mr-2 blueText"
                        *ngIf="accessAllLocationsNonTeams"
                    >
                        {{"usersWillBeMappedToAllLocationTaggedToGeneralUserRole" | translate}}
                        <span
                            class="light-blue-anchor ml-2 informationIcon"
                            >i</span
                        >
                    </p> -->
                    <p
                        class="mr-2 blueText"
                   
                    >
                        {{"allUsersAreTaggedToAGeneralUserRole" | translate}}
                        <span
                            class="light-blue-anchor ml-2 informationIcon"
                            >i</span
                        >
                    </p>
                    <div class="col-5 px-0">
                        <button
                        *ngIf="!disableTenantEditForm"
                            class="btn btn-main btnSmall  semi-bold-blue ml-4 smallBtn float-right"
                            type="submit"
                        >
                            {{"done" | translate}}
                        </button>
                     
                    </div>
                </div>
            </div>
        </div>
    </form>






  
</div>
</div>