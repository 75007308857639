import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subject,BehaviorSubject,Observable } from 'rxjs';
import { ZoneAndModuleHttpParams } from '../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { CommonService } from '../../services/common-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatInput, MatSelect, MatOption, MatCheckbox, MatTableDataSource,MatRadioButton, MatRadioGroup } from '@angular/material';
@Injectable()
export class ModalRequestServiceService {

  public isAssigned:Subject<Object> = new Subject<Object>();

  private messageSource = new BehaviorSubject([]);
  private dropDownSource = new BehaviorSubject([]);

  currentMessage = this.messageSource.asObservable();
  dropDownMessage = this.messageSource.asObservable();
  private tableSizeSource = new BehaviorSubject<number>(0);

  tableSize = this.tableSizeSource.asObservable();

  setSize(size: number) {
    this.tableSizeSource.next(size)
  }

  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  openSnackBar(message: string, action: string) {
    this.matSnackBar.open(message, action, {
      duration: 5000,
    });
  }

  changeDropDownMessage(message: any) {
    this.dropDownSource.next(message)
  }

  private floorSource = new BehaviorSubject({});
  currentFloor = this.floorSource.asObservable();

  changeFloor(floor: any){
    this.floorSource.next(floor);
  }

  private roleAndLocationSource = new BehaviorSubject([]);
  currentRoleAndLocation = this.roleAndLocationSource.asObservable();
 
  changeRoleAndLocation(roleAndLocation: any){
    this.roleAndLocationSource.next(roleAndLocation);
  }

  constructor(public http: HttpClient, private commonService: CommonService,  public matSnackBar: MatSnackBar) { }

  ngOnDestroy(){
    this.messageSource.next([]);
    this.floorSource.next({});
    //this.floorSource.unsubscribe();
  }

  onChange(data){
    this.isAssigned.next(data);
    this.floorSource.unsubscribe();
  }
  // addEntity(meeting) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'session_id':localStorage.getItem("authentication")
  //     })
  //   };

  //   var meetingList=[];
  //   meetingList.push(meeting);
  //   return this.http.post(environment.ADD_MEETING_ENTITY, meetingList, httpOptions);

  // }
  // getBookedSlots(BookedSlotsRequest) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'session_id':localStorage.getItem("authentication")
  //     })
  //   };


  //   return this.http.post(environment.GET_BOOKED_SLOTS, BookedSlotsRequest, httpOptions);
  // }
  // getBookedSlots_v1(startTime,endTime,entityId,zoneId, moduleId) {

    

  //   var endpoint = environment.baseBookingOnboardingUrl+"/booking/entity/time?limit=100000&offset=0&sortCriteria=BOOKING_END_TIME&sortDirection=ASC&startTime={startTime}&endTime={endTime}&entityId={entityId}";
  //   endpoint = endpoint.replace("{entityId}",entityId);
  //   endpoint = endpoint.replace("{startTime}",startTime)//"1536762600000");
  //   endpoint = endpoint.replace("{endTime}",endTime)//"1538332200000");
  //   return this.http.get(endpoint,{params: new ZoneAndModuleHttpParams(zoneId, moduleId)});
  // }

  // editEntity(meeting) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'session_id':localStorage.getItem("authentication")
  //     })
  //   };
  //   var meetingList=[];
  //   meetingList.push(meeting);
  //   return this.http.put(environment.UPDATE_MEETING_ENTITY+meeting.id+'?siteID=1&buildingID=1&floorID=1&zoneID=1', meeting, httpOptions)
  // }

  // deleteMeetingEntity(meetingId) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'session_id':localStorage.getItem("authentication")
  //     })
  //   };

  //   return this.http.get(environment.DELETE_MEETING_ENTITY+meetingId,httpOptions);
  // }
  

  // postFile(fileToUpload: File) {
  //   // var header  = {};
  //   // header["bucketName"]="ssmeetingroom";
  //   // let headers = new Headers(header);
  //   // let options = new RequestOptions({ headers: headers });


  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'bucketName':'ssmeetingroom'
  //     })
  //   };


  //   let UPLOAD_IMAGE=environment.UPLOAD_IMAGE+fileToUpload.name+"&targetFolder=room&mimeType=image/svg+xml";
  //   const endpoint = UPLOAD_IMAGE;
  //   const formData: FormData = new FormData();

  //   formData.append('Body', fileToUpload);

  //   return this.http.post(endpoint, formData,httpOptions);
  // }
  // getAttributes(type) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'session_id':localStorage.getItem("authentication")
  //     })
  //   };
  //   return this.http.get(environment.GET_ATTRIBUTES, httpOptions);
  // }

    

  // getBuildings(tenant){
  //   return this.http.get(environment.getBuildingsForTenant+tenant)
  // }

  // getAllTenants(){
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //         'ss-header': '{"version":"1.0","clientKey":"END_USER_MOBILE_APP","zoneId":5}'
  //     })
  //   };
  //   return this.http.get(environment.baseEmsUrl+"/enterprise/getAll", httpOptions);
  // }



  // addVehicle(vehicle){
  //   return this.http.post(environment.addVehicle,vehicle);
  // }

  // deleteVehicle(deleteVehicle){
  //   return this.http.put(environment.deleteVehicle,deleteVehicle);
  // }

  // addParkingSlotCount(parkingslot){
  //   return this.http.post(environment.addParkingSlotCount,parkingslot);
  // }

  // editParkingSlotCount(parkingslot){
  //   return this.http.put(environment.editParkingSlotCount,parkingslot);
  // }

  // getRegisteredUsers(eventId,zoneId,page,size){
  //   return this.http.get(environment.baseBudClubUrl+"/events/fetchRegisteredUsersForEvent?eventId="+eventId+"&page="+page+"&size="+size,{params: new ZoneAndModuleHttpParams(zoneId, 10)});
  // }


  // editVehicle(editVehicle){
  //   return this.http.put(environment.editVehicle,editVehicle);
  // }
  // public updatePass(data){
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type':  'application/json',
  //       'session_id':localStorage.getItem("ssadmin_session")
  //     })
  //   };
    

  //   return this.http.post(environment.updatePass,data,httpOptions);
  // }
/*
  public updateTicket(postReqObj, zoneId){
    const httpOptions = {
      headers: new HttpHeaders({
        'ss-header': '{"version":"1.0","clientKey":"END_USER_MOBILE_APP","zoneId":' + localStorage.getItem("defaultZone") + ', "sessionId":"' + localStorage.getItem("ssadmin_session") + '"}'
      })
    };
    return this.http.post(environment.updateTicket,postReqObj,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }
*/
  //Community Service
  /*
  public createNewClub(fileToUpload: File,data, zoneId): Observable<Object> {
    const endpoint = environment.createNewClub;
    const formData: FormData = new FormData();
    formData.append('clubInfo',JSON.stringify(data));
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(endpoint, formData,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }
  // createNewClub(data){
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'ss-header': '{"version":"1.0","clientKey":"END_USER_MOBILE_APP","zoneId":'+localStorage.getItem("defaultZone")+',"sessionId":"' + localStorage.getItem("ssadmin_session") + '"}'
  //     })
  //   };

  // return this.http.post(environment.createNewClub,data,httpOptions);
  // }

  editClubDetails(data, zoneId){
    return this.http.post(environment.editClubDetails,data,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }

  reApprovePost(data, zoneId){
    return this.http.post(environment.reApprovePost,data,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }
  */
}
