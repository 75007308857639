import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountVerifyComponent } from './accountVerify.component';

const routes: Routes = [
    {
        path: '',
        component: AccountVerifyComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountVerifyRoutingModule {}
