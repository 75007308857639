import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { environment, modulesIds } from '../../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImsService {

  private moduleId;
  private BASE_IMS_URL;
  private BASE_EMS_URL;
  constructor(private http: HttpClient) {
    this.moduleId = 9;
    this.BASE_IMS_URL = environment.BASE_URL + 'ims-new/';
    this.BASE_EMS_URL = environment.BASE_URL + 'ems/';
  }

  getCustomFields(zoneId) {
    return this.http.get(this.BASE_EMS_URL + "custom-field/all-custom-key?customKey=USER_ADD_DETAILS", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllUnits(zoneId) {
    return this.http.get(this.BASE_EMS_URL + "user/getDistinctCustomFieldValues?customFieldName=Unit Number", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllUserUnits(zoneId, userIds) {
    return this.http.get(this.BASE_EMS_URL + "user/getDistinctCustomFieldValuesAndUserId?customFieldName=Unit Number&userIds=" + userIds, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getFilteredTickets(zoneId, postObj, page, size) {
    //ticket/get-all-ticket/search-filter?zoneId=5&page=5&size=5
    let url = 'ticket/get-all-ticket/search-filter/v3';
    let queryParams = '?page={page}&size={size}&zoneId={zoneId}'
      .replace('{page}', page)
      .replace('{size}', size)
      .replace('{zoneId}', zoneId);
    return this.http.post(this.BASE_IMS_URL + url + queryParams, postObj, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });

  }

  getManageTicketsFilterList(zoneId) {
    return this.http.get(this.BASE_IMS_URL + "messageCategory/groupKey?groupKey=ims-dashboard-filter", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllStates(zoneId) {
    let url = 'ticket/get-all-state';
    let queryParams = '';
    return this.http.get(this.BASE_IMS_URL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllPriority(zoneId) {
    let url = 'ticket/get-all-priority';
    let queryParams = '';
    return this.http.get(this.BASE_IMS_URL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllParentCategory(zoneId) {
    let url = 'category/all/parent';
    let queryParams = '?zoneId={zoneId}'.replace('{zoneId}', zoneId);
    return this.http.get(this.BASE_IMS_URL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllChildCategory(zoneId) {
    let url = 'category/all/child-no-parent';
    let queryParams = '';
    return this.http.get(this.BASE_IMS_URL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllTeams(zoneId, page?, size?, isActive?) {
    page = 0; size = 100; isActive = 1;
    let url = 'team/all';
    let queryParams = '?page={page}&size={size}&isActive={isActive}'
      .replace('{page}', page)
      .replace('{size}', size)
      .replace('{isActive}', isActive);
    return this.http.get(this.BASE_IMS_URL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  // public getTeamsByZone(data, zoneId): Observable<Object> {
  //   data['page'] = data['page'] - 1;
  //   return this.http.get(this.BASE_IMS_URL + `team/all/v2?&zoneId=${zoneId}&page=${data['page']}&size=${data['size']}`, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  // }

  getAllCategoryAndEscalationListNew(zoneId, pageNo, pageSize) {
    pageNo = pageNo - 1
    return this.http.get(this.BASE_IMS_URL + "category/all/category-team/v2?page=" + pageNo + "&size=" + pageSize + "&zoneId=" + zoneId, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) })
  }

  public addCategoryAndSubCategory(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/add/category/v2", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateCategoryNew(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/update/category/v2", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  public createTeam(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "team/add/v1", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  public getTeamsByZone(data, zoneId): Observable<Object> {
    data['page'] = data['page'] - 1;
    return this.http.get(this.BASE_IMS_URL + `team/all/v2?&zoneId=${zoneId}&page=${data['page']}&size=${data['size']}`, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  public getTeamDetails(id, zoneId) {
    return this.http.get(this.BASE_IMS_URL + `team/get-detail?teamId=${id}`, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  public updateTeam(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "team/update/v2", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  public getCategoryDetails(id, zoneId) {
    return this.http.get(this.BASE_IMS_URL + `category/get/category-team?categoryId=${id}`, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  createSlaLevel(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/add/next-level", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateSlaLevel(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/update/escalation/v2", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  deleteEscalation(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/de-activate/escalation", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  disabledCategory(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/statusUpdate/v2", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  disabledSubCategory(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/statusUpdate/sub-category", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllGroups(zoneId) {
    return this.http.get(this.BASE_IMS_URL + "team/all?page=0&size=10000", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllActiveUserGroups(zoneId) {
    return this.http.get(this.BASE_IMS_URL + "team/all?isActive=true&page=0&size=10000", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateImsTicketNew(zoneId, data, imgData) {
    const formData: FormData = new FormData();
    if (imgData) {
      formData.append('file', imgData, imgData.name);
    }
    formData.append('ticket', JSON.stringify(data));
    return this.http.post(this.BASE_IMS_URL + "ticket/update-ticket/v3", formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateImsTicket(zoneId, data, imgData) {
    const formData: FormData = new FormData();
    if (imgData) {
      formData.append('file', imgData, imgData.name);
    }
    formData.append('ticket', JSON.stringify(data));
    return this.http.post(this.BASE_IMS_URL + "ticket/update-ticket", formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  updateMyTicket(zoneId, imgData, data) {
    const formData: FormData = new FormData();
    if (imgData) {
      formData.append('file', imgData, imgData.name);
    }
    formData.append('event', JSON.stringify(data));
    var url = `ticket/update-ticket`;
    return this.http.post(this.BASE_IMS_URL + url, formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  addUserGroup(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "team/add", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  editUserGroup(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "team/update", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  addCategoryAndEscalation(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/add/category-sub-escalation", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  addCategory(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/add/category", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  addSubCategory(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/add/sub-category", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getUserGroupDetails(id, zoneId) {
    return this.http.get(this.BASE_IMS_URL + 'team-member/team-id?teamId=' + id, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getCategoryById(id, zoneId) {
    return this.http.get(this.BASE_IMS_URL + 'category/id?categoryId=' + id, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  deleteUserFromGroup(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "team-member/deactive-all-per-team", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  addUsersToGroup(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "team-member/create", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateCategory(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/update/category", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateEscalation(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/update/escalation", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  addEscalation(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "category/add/escalation", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  public deleteTeamMember(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "team-member/deactive-all-per-team", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  addUsersToTeam(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "team-member/create", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getNextState(currentState, zoneId) {
    var url = `ticket/getNextState?state=${currentState}`;
    return this.http.get(this.BASE_IMS_URL + url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getTicketById(zoneId, id) {
    var url = `ticket/get/v2?ticketId=${id}`;
    return this.http.get(this.BASE_IMS_URL + url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getNextStateNew(currentState, zoneId) {
    var url = `ticket/getNextState/v2?state=${currentState}`;
    return this.http.get(this.BASE_IMS_URL + url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  updateImsTicketSamepage(zoneId, data, imgData) {
    return this.http.post(this.BASE_IMS_URL + "ticket/update-ticket/state", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  updatePriority(data, zoneId) {
    return this.http.post(this.BASE_IMS_URL + "ticket/update-priority", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getChatHistory(categoryId,zoneId,pageIndex,pazeSize){
    return this.http.get(this.BASE_IMS_URL + "ticket/get-comments?ticketId="+categoryId +"&page="+pageIndex+"&size="+pazeSize+"&sort=createdAt,DESC", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) })
  }

  saveChat(data,imgData,zoneId){
    const formData: FormData = new FormData();
    if (imgData) {
      formData.append('file', imgData, imgData.name);
    }
    formData.append('ticket', JSON.stringify(data));
  
   // return this.http.post(this.BASE_URL + "category/ticket/comment", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) })
    return this.http.post(this.BASE_IMS_URL + "ticket/comment", formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }


}