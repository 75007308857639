import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import {HttpClientModule} from '@angular/common/http';
// import { Response } from '@angular/common/http';
// import { Headers, RequestOptions } from '@angular/common/http';

import "rxjs/add/operator/map";

import "rxjs/add/operator/toPromise";
import { Observable } from "rxjs";
import { environment, modulesIds } from "../../../../environments/environment";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ToastrService } from "ngx-toastr";
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class WorkStatusService {
    private moduleId;
    private BASE_URL;
    EMSURL;
    ENVURL;
    constructor(
        private http: HttpClient,
        private toastrService: ToastrService,
        @Inject("BASE_URL") baseUrl: string
    ) {
        this.moduleId = modulesIds.enterpriseManagementSystem;
        // this.BASE_URL = environment.BASE_URL + "ems/";
        this.BASE_URL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.ENVURL = environment.BASE_URL;
    }

    getFilteredZonesByLevel(level, zoneId) {
        return this.http.get(this.EMSURL + "zones/allSites/user", {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    loadPrefrences() {
        let userId = localStorage.getItem('ssadmin_userId');
        let url =
          this.ENVURL +
          `/wfh/question-response/getAll/v2?userId=${userId}`;
        return this.http.get<any>(url, {
          params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
      }

      createPreference(data) {
        let url =
          this.ENVURL +
          `wfh/question-response/addAnswers`;
        return this.http.post<any>(url, data, {
          params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
      }
      loadBookedSeats(fdate, ldate) {
        let url =
          this.ENVURL +
          `wfh/calendar/generaluser/getAllStatusAndAvailableAndBookedSeats/v2?startTime=${fdate}&endTime=${ldate}`;
        return this.http.get<any>(url, {
          params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
      }

      loadCalendarView(fdate, ldate) {
        let url =
          this.ENVURL +
          `amenitybooking/booking/generaluser/quickActions?startDate=${fdate}&endDate=${ldate}`;
        return this.http.get<any>(url, {
          params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
      }

      createRoster(data) {
        let url =
          this.ENVURL +
          `wfh/rosters/generaluser/createRostersForSpecificDaysV2?isAutoAllocationEnabled=false&editFlow=true&startTime=1669852800000&endTime=1672531199000`;
        return this.http.post<any>(url, data, {
          params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
      }

      saveRosterZones(data) {
        let url = this.ENVURL + `wfh/rosters/generaluser/changeRosterZones`;
        return this.http.post<any>(url, data, {
          params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
      }
    
}
