import {
    Component,
    Inject,
    Injectable,
    OnInit,
    ViewChild,
    OnDestroy,
} from "@angular/core";
import { EMSService } from "emsService";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { OnboardingService } from "../services/onboarding.service";
import { ToastrService } from "ngx-toastr";
//import { LoaderService } from "loaderService";
import { TranslateService } from "@ngx-translate/core";
import * as L from "leaflet";
import { LeafletService } from "leafletService";
import "leaflet-draw";
import { forkJoin, Subject, EMPTY, of, Observable } from "rxjs";
import {
    LeafLetInit,
    TilesOption,
    PolygonOption,
    MarkerOption,
    DrawPolygonOption,
} from "../onboard-space/models/leaflet.interface";
import { CommonService } from 'commonService';
import { LoaderService } from 'loaderService';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ManageRequestsService } from "../../manage-request/service/manage-requests.service";
declare const $;

@Component({
    selector: "app-onboarding-modal",
    templateUrl: "./onboarding-modal.component.html",
    styleUrls: ["./onboarding-modal.component.scss"],
})
export class OnboardingModalComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    registerForm: FormGroup;
    addUserForm: FormGroup;
    editUserForm: FormGroup;
    autoUploadForm: FormGroup;
    editsubmitted = false;
    submitted = false;
    addsubmitted = false;
    pageType;
    currentZone;
    wtitle;
    buildingForm: FormGroup;
    disableUserForm: FormGroup
    countryCodeList;
    ///Leaflet start
    leaflet_url;
    leaflet_blockInfo;
    workstationColorArr = [];
    workstationListData;
    leaflet_seatsGeojson;
    leaflet_overlaydata;
    leaflet_scaleData;
    buildingList;
    map: L.Map;
    drawControl;
    drawnItems;
    markerWithBindPopup = {
        showMarker: false,
    };

    invalidEntires = [];

    masterSelected: boolean;
    checklist: any;
    checkedList: any;
    editAllocationData;
    stagestatus;
    newworkstation;
    newworkstationlocation;
    userDto;
    userZoomLevel: number = 14;
    UserMarkerObj: any;
    country_code: any = null;
    department_name: any = null;
    tenant_data: any = null;
    time = { hour: 13, minute: 30 };
    departmentList = [];
    tenantList: any = [];
    ///Leaflet end
    starttime;
    endtime;
    user_first_name;
    user_last_name;
    user_email;
    user_grade;
    user_emp_code;
    user_phone_number;
    edit_department_name;
    editstarttime;
    editendtime;
    user_tenant;
    edit_country_code
    update_id;
    edit_tenant;
    edit_phone_number;
    bookingIsloading: boolean = false;
    bookingDropdownEnable: boolean = false;
    isBookingFrmValid: boolean = false;
    bookingSearchTermSubject$ = new Subject<any>();
    bookingSearchTermAction$ = this.bookingSearchTermSubject$.asObservable();
    bookingAutocompleteList$ = this.bookingSearchTermAction$.pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
            this.bookingIsloading = true;
            this.bookingDropdownEnable = true;
        }),
        switchMap((searchTerm) =>
            of(searchTerm).pipe(
                mergeMap((res) =>
                    this.manageRequestsService.getBookingAutocompleteList2(
                        {
                            searchTerm,
                            searchType: this.bookingObj.bookingType
                        },
                        this.currentZone
                    )
                )
            )
        ),
        tap((searchRes) => {
            this.bookingIsloading = false;
            if (!!searchRes && searchRes.length) {
                this.bookingDropdownEnable = true;
            } else {
                this.bookingDropdownEnable = false;
            }
        }),
        catchError((err) => {
            this.bookingDropdownEnable = false;
            return EMPTY;
        })
    );

    bookingObj: any = {
        bookingType: "employee",
        id: "",
        selectedName: "",
    }
    superVisorName;
    supervisor_name;
    selectedAutoValue;
    selectDisableUser=null;
    isEditPeople = false;
    isaddPeople = false;
    tenantAdminId;
    checkTenantAdmin;
    tenantAdminDropdownDisabled: boolean = false;
    isEmailEnabledCheck;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<OnboardingModalComponent>,
        private onboardingService: OnboardingService,
        private emsService: EMSService,
        private toastrService: ToastrService,
        private commonService: CommonService,
        private loaderService: LoaderService,
        private leafletService: LeafletService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private manageRequestsService: ManageRequestsService
    ) {
        this.currentZone = this.data["currentZone"];
        this.pageType = this.data["type"];
        this.getCountryCodes().subscribe();
        this.masterSelected = false;
        this.checklist = [
            { id: 1, value: "Elenor Anderson", isSelected: false },
            { id: 2, value: "Caden Kunze", isSelected: true },
            { id: 3, value: "Ms. Hortense Zulauf", isSelected: true },
            { id: 4, value: "Grady Reichert", isSelected: false },
            { id: 5, value: "Dejon Olson", isSelected: false },
            { id: 6, value: "Jamir Pfannerstill", isSelected: false },
            { id: 7, value: "Aracely Renner DVM", isSelected: false },
            { id: 8, value: "Genoveva Luettgen", isSelected: false },
        ];
    }

    ngOnInit() {
        this.bookingObj.id = '';
        if (this.pageType == "editWorkStation") {
            this.wtitle = "Update";
            let wdata = this.data["workstation"];
            this.registerForm = this.formBuilder.group({
                workstation: [wdata["name"], [Validators.required, Validators.maxLength(30)]],
            });
        } else if (this.pageType == "addWorkStation") {
            this.wtitle = "Add";
            this.registerForm = this.formBuilder.group({
                workstation: ["", [Validators.required, Validators.maxLength(30)]],
            });
        }
        else if (this.pageType == "editBuildingName") {
            this.wtitle = "Update";
            this.registerForm = this.formBuilder.group({
                id: [this.data["data"]["BuildingId"]],
                buildingName: [this.data["data"]["Buildings"], [Validators.required, Validators.maxLength(30)]],
            });
        }
        else if (this.pageType == "editDepartment") {
            this.wtitle = "Update";
            let wdata = this.data["department"];
            this.registerForm = this.formBuilder.group({
                department: [wdata["name"], [Validators.required, Validators.maxLength(30)]],
            });
        } else if (this.pageType == "addDepartment") {
            this.wtitle = "Add";
            this.registerForm = this.formBuilder.group({
                department: ["", [Validators.required, Validators.maxLength(100)]],
            });
        }
        else if (this.pageType == "addBuilding") {
            this.buildingForm = this.formBuilder.group({
                buildingName: ["", [Validators.required, Validators.maxLength(25)]],
            });
        } else if (this.pageType == "allocationMap") {
            const zoneId = this.data["data"]["floorDetailsDTO"]["zoneId"];
            this.markerWithBindPopup = {
                showMarker: true,
            };
            this.showMap(zoneId);
        } else if (this.pageType == "viewFloor") {
            const zoneId = this.data["data"]["zoneId"];
            this.showFloor(zoneId);
        } else if (this.pageType == "showUploadIssues") {
            this.invalidEntires = this.data["invalidEntries"];
            //this.showFloor(zoneId);
        } else if (this.pageType == "showAssestsDesks") {
        } else if (this.pageType == "editAllocation") {
            this.editAllocationData = this.data;
            this.stagestatus = "Edit Allocation Popup";
        } else if (this.pageType == "addPeople") {
            // this.getCurrentUserModules(this.currentZone);
            
            this.isaddPeople = false;
            this.starttime = { hour: 0, minute: 0 };
            this.endtime = { hour: 23, minute: 59 };
            this.getDepartment();
            this.addUserForm = this.formBuilder.group({
                firstName: ["", Validators.required],
                lastName: ["", Validators.required],
                // email: ["", Validators.required],
                email: [""],
                countryCode: ["", Validators.required],
                grade: ["", null],
                employeeCode: ["", null],
                phoneNumber: ["", Validators.required],
                Department: ["", Validators.required],
                StartTime: ["", null],
                EndTime: ["", null],
                tenant: ["", Validators.required]
            });
            // Calling 48 Config API for isEmailHidden check for different customers
            this.getCountryCodes().subscribe(() => {
            if(this.countryCodeList.length>0){
                this.country_code = this.countryCodeList[0]['countryCode']; 
            }
            });
            this.commonService.getConfig(this.currentZone,48).subscribe( res => {
                let configDetails = (res as any).response;
                let configDet = JSON.parse(configDetails);  
                this.isEmailEnabledCheck = configDet.ems.profile.isEmailHidden;
                if(!!configDet.ems.profile && configDet.ems.profile.isEmailHidden) {
                // console.log('disabled');
                this.addUserForm.controls['email'].clearValidators();
                this.addUserForm.controls['email'].updateValueAndValidity();
                this.addUserForm.get('email').valueChanges.subscribe(val=>{
                    if(!!val && val.length > 0){
                    this.addUserForm.get('email').markAsTouched();
                    this.addUserForm.get('email').setValidators([Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
                    } else{
                    this.addUserForm.get('email').setErrors(null);
                    }
                    // this.memberForm.controls['email'].updateValueAndValidity();
                });
                } else {
                // console.log('enabled');
                this.addUserForm.controls['email'].setValidators([Validators.required, Validators.email]);
                this.addUserForm.controls['email'].updateValueAndValidity();
                }
            });
     
        }
        else if (this.pageType == "editPeople") {
            // this.getCurrentUserModules(this.currentZone);
            let userInfo = this.data["userData"];
            // console.log('user data is... ', this.data["userData"])
            this.update_id = userInfo.userId;
            /** if (userInfo.shiftId != null) {
                let shiftIds = userInfo.shiftId;
                if (!!shiftIds) {
                    this.onboardingService.getAllShifts(this.currentZone).subscribe(res => {
                        let shiftTimeData = res["response"].filter(t => t.id === shiftIds);
                        let startTime = shiftTimeData[0]['startTime'];
                        let endTime = shiftTimeData[0]['endTime'];
                        let shours = Math.floor(startTime / 100);
                        let sminutes = startTime % 100;
                        let ehours = Math.floor(endTime / 100);
                        let eminutes = endTime % 100;
                        this.editstarttime = { hour: Number(shours), minute: Number(sminutes) };
                        this.editendtime = { hour: Number(ehours), minute: Number(eminutes) };
                    });
                } else {
                    this.editstarttime = { hour: 0, minute: 0 };
                    this.editendtime = { hour: 23, minute: 59 };
                }
            } else {
                this.editstarttime = { hour: 0, minute: 0 };
                this.editendtime = { hour: 23, minute: 59 };
            } **/
            this.editstarttime = { hour: 0, minute: 0 };
            this.editendtime = { hour: 23, minute: 59 };

            this.getDepartment();
            this.editUserForm = this.formBuilder.group({
                editfirstName: ["", Validators.required],
                editlastName: ["", Validators.required],
                // editemail: ["", Validators.required],
                editemail: [""],
                editcountryCode: ["", Validators.required],
                editgrade: ["", null],
                editemployeeCode: ["", null],
                editphoneNumber: ["", Validators.required],
                editDepartment: ["", Validators.required],
                editStartTime: ["", null],
                editEndTime: ["", null],
                edittenant: ["", [Validators.required, this.validateEditTenant()]]
            });


            // Calling 48 Config API for isEmailHidden check for different customers
            this.commonService.getConfig(this.currentZone,48).subscribe( res => {
                let configDetails = (res as any).response;
                let configDet = JSON.parse(configDetails);  
                this.isEmailEnabledCheck = configDet.ems.profile.isEmailHidden;
                if(!!configDet.ems.profile && configDet.ems.profile.isEmailHidden) {
                // console.log('disabled');
                this.editUserForm.controls['editemail'].clearValidators();
                this.editUserForm.controls['editemail'].updateValueAndValidity();
                this.editUserForm.get('editemail').valueChanges.subscribe(val=>{
                    if(!!val && val.length > 0){
                    this.editUserForm.get('editemail').markAsTouched();
                    this.editUserForm.get('editemail').setValidators([Validators.required, Validators.email]);
                    } else{
                    this.editUserForm.get('editemail').setErrors(null);
                    }
                    // this.memberForm.controls['email'].updateValueAndValidity();
                });
                }
            });

            if (!!userInfo.phoneNum) {
                let mobData = userInfo.phoneNum.split('-');
                if(mobData!='' && mobData.length>1){
                    this.getPhoneNumbers(mobData)
                  }else if(mobData.length == 1){
                    //This comes when +91- not exists
                    this.user_phone_number = mobData[0];
                    this.editUserForm.get("editphoneNumber").setValue(mobData[0]);
                  }else{
                    if (!!mobData[0] && mobData[0] !== "null") {
                        this.edit_country_code = mobData[0];
                        this.editUserForm.get("editcountryCode").setValue(mobData[0]);
                    } else {
                        this.edit_country_code = "";
                        this.editUserForm.get("editcountryCode").setValue("");
                    }
                    if (!!mobData[1] && mobData[1] !== "null") {
                        this.user_phone_number = mobData[1];
                        this.editUserForm.get("editphoneNumber").setValue(mobData[1]);
                    } else {
                        this.user_phone_number = "";
                        this.editUserForm.get("editphoneNumber").setValue("");
                    }
                  }
            } else {
                this.editUserForm.get("editcountryCode").setValue("");
                this.editUserForm.get("editphoneNumber").setValue("");
                this.edit_country_code = "";
                this.user_phone_number = "";
            }
            this.user_first_name = userInfo.firstName;
            this.user_last_name = userInfo.lastName;
            this.user_email = userInfo.email;
            this.user_emp_code = userInfo.empId;
            this.edit_department_name = userInfo.deptId;
            this.user_tenant = userInfo.tenantId;
            if (!!userInfo && !!userInfo['supervisor'] && !!userInfo['supervisor'].firstName && !!userInfo['supervisor'].lastName) {
                this.bookingObj.selectedName = userInfo['supervisor'].firstName + ' ' + userInfo['supervisor'].lastName;
            } else if (!!userInfo && !!userInfo['supervisor'] && !!userInfo['supervisor'].firstName) {
                this.bookingObj.selectedName = userInfo['supervisor'].firstName
            } else {
                this.bookingObj.selectedName = '';
            }
            // console.log(userInfo);
            this.getSuperVisorName(userInfo['supervisor']);
        } else if (this.pageType == "uploadAutoPopup") {
            this.autoUploadForm = this.formBuilder.group({
                autoUpload: ['', null]
            });
        }
        else if(this.pageType == "disableUser"){
            this.disableUserForm = this.formBuilder.group({
                disableUsers: ['', null]
            });

        }
     
        this.onboardingService.getTenants(this.currentZone).subscribe(res => {
            if (res['message']) {
                this.tenantList = res.response.content.map(t => { return { name: t.name, id: t.tenant_id, active: t.active  } });
                // if(this.checkTenantAdmin == 'TENANT_ADMIN') {
                //     this.tenantList = this.tenantList.filter((item) => {
                //         return item.id == this.tenantAdminId;
                //     })
                // }
                if(this.pageType == "editPeople"){
                    this.editUserForm.get("edittenant").setValue(this.user_tenant);
                }
                // var tenantListResp = res['response']['content'].filter((item) => {return item.active != false});
                // this.tenantList = tenantListResp.map(t => { return { name: t.name, id: t.tenant_id } });
            }
        }, error => {
        })

    }

    getPhoneNumbers(mobData) {
        if (!!mobData[0] && mobData[0] !== "null") {
            this.edit_country_code = mobData[0];
            this.editUserForm.get("editcountryCode").setValue(mobData[0]);
        } else {
            this.edit_country_code = "";
            this.editUserForm.get("editcountryCode").setValue("");
        }
        if ((!!mobData[1] && mobData[1] !== "null") && (!!mobData[2] && mobData[2] !== "null")
            && (!!mobData[3] && mobData[3] !== "null")) {
            this.user_phone_number = mobData[1] + '-' + mobData[2] + '-' + mobData[3];
            this.editUserForm.get("editphoneNumber").setValue(mobData[1] + '-' + mobData[2] + '-' + mobData[3]);
        } else if ((!!mobData[1] && mobData[1] !== "null") && (!!mobData[2] && mobData[2] !== "null")) {
            this.user_phone_number = mobData[1] + '-' + mobData[2];
            this.editUserForm.get("editphoneNumber").setValue(mobData[1] + '-' + mobData[2]);
        } else if (!!mobData[1] && mobData[1] !== "null") {
            this.user_phone_number = mobData[1];
            this.editUserForm.get("editphoneNumber").setValue(mobData[1]);

        } else {
        // console.log('enabled');
        this.editUserForm.controls['editemail'].setValidators([Validators.required, Validators.email]);
        this.editUserForm.controls['editemail'].updateValueAndValidity();
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    validateEditTenant = (): ValidatorFn => {
        return (formGroup) => {
            if(this.tenantList.length > 0){
                let result = this.tenantList.filter(el => el.id === formGroup.value);
                if(result.length>0 && result[0]['active']){
                    return null;
                }else{
                    return {
                        validate: true,
                      };
                }
            }
            return {
                validate: true,
              };
        };
    }

    showInfo = true;
    checkTenantUserSelection(){
       
        let selVal = this.editUserForm.get("edittenant").value;
        let result = this.tenantList.filter(el => el.id === selVal);
        // console.log(result);
        if(result.length>0 && result[0]['active']){
          this.showInfo = false;
        }else{
          this.showInfo = true;
        }
        // console.log(this.showInfo);
    }

    checkTenantUser(){
       
        let selVal = this.editUserForm.get("edittenant").value;
        let result = this.tenantList.filter(el => el.id === selVal);
        if(result.length>0 && result[0]['active']){
          return true;
        }else{
          return false;
        }
    }

    getBookingAutocompleteList = (searchTerm?) => {
        this.isBookingFrmValid = false;
        this.bookingSearchTermSubject$.next(searchTerm.trim());
    };

    getSuperVisorName(obj) {
        if(!!obj){
            this.bookingObj.id = obj.id;
        }
    }

    onselectBookingData = async (val) => {
        this.bookingObj.id = val.userId;
        this.bookingObj.selectedName = val.name.trim();
        this.bookingDropdownEnable = false;
        this.isBookingFrmValid = true;
        if (this.bookingObj.id == this.data.editedUserId) {
            this.isBookingFrmValid = false;
        }
    }

    getDepartment() {
        const deptLoad$ = this.onboardingService.getAllDepartments(this.currentZone);
        this.loaderService.showLoaderUntilCompleted(deptLoad$)
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe(res => {
                let filterdep = res["response"].filter(t => t.status === true);
                this.departmentList = filterdep;
            });
    }

    checkUncheckAll() {
        for (var i = 0; i < this.checklist.length; i++) {
            this.checklist[i].isSelected = this.masterSelected;
        }
        this.getCheckedItemList();
    }

    selectedTenant() {
        let result = this.tenantList.filter(el => el.active === true);
        this.tenantList = result;
    }

    getCheckedItemList() {
        this.checkedList = [];
        for (var i = 0; i < this.checklist.length; i++) {
            if (this.checklist[i].isSelected)
                this.checkedList.push(this.checklist[i]);
        }
        this.checkedList = JSON.stringify(this.checkedList);
    }

    isAllSelected() {
        this.masterSelected = this.checklist.every(function (item: any) {
            return item.isSelected == true;
        });
        this.getCheckedItemList();
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.registerForm.controls;
    }

    get b() {
        return this.buildingForm.controls;
    }

    get t() {
        return this.addUserForm.controls;
    }

    get k() {
        return this.editUserForm.controls;
    }

    getCountryCodes() {
        let currentContext = this;
        return new Observable<void>((observer) => {
        const countryCodes = this.commonService.getCountryCodes(22, this.currentZone)
        this.loaderService.showLoaderUntilCompleted(countryCodes).subscribe(res => {
            let countryCodes;
            countryCodes = (res as any).response;
            //Below code is written for removing duplicate country codes
            currentContext.countryCodeList = currentContext.countryCodeList = countryCodes.filter((obj, index, array) => {
                return array.findIndex(item => item.countryCode === obj.countryCode) === index;
            });
            observer.next();
            observer.complete();
        }, err => {
            this.commonService.openSnackBar(err.error.message, '');
        });
    })
    }

    onManualBooking() {
        this.dialogRef.close({
            isManualBooking: true,
            rowData: this.editAllocationData,
            userRowData: this.userDto,
        });
    }

    onViewMap() { }

    onViewBooking() {
        this.dialogRef.close({ isViewBooking: true });
    }

    onSubmitWorkStation() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        } else {
            let data;
            if (this.pageType == "editWorkStation") {
                data = {
                    id: this.data["workstation"]["id"],
                    entityCategory: "SEAT",
                    name: this.registerForm.value.workstation,
                };
            } else if (this.pageType == "addWorkStation") {
                data = {
                    entityCategory: "SEAT",
                    name: this.registerForm.value.workstation,
                };
            }
            this.onboardingService
                .createOrUpdateWorkStation(
                    this.currentZone,
                    data,
                    this.pageType
                )
                .pipe(
                    takeUntil(this.unsubscribe$),
                )
                .subscribe((res) => {
                    if (this.pageType == "editWorkStation") {
                        this.toastrService.success(
                            "Updated workstation successfully"
                        );
                    } else {
                        this.toastrService.success(
                            "Saved workstation successfully"
                        );
                    }
                    this.closePopup();
                });
        }
    }


    onSubmitDepartment() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        } else {
            let data;
            if (this.pageType == "editDepartment") {
                this.data["department"]['name'] = this.registerForm.value.department;
                this.data["department"]['modifiedAt'] = new Date().getTime(),
                    data = this.data['department'];
            } else if (this.pageType == "addDepartment") {
                data = {
                    "name": this.registerForm.value.department,
                    "status": true,
                    "tenantId": 1,
                    "enterpriseId": 1,
                    "createdAt": new Date().getTime(),
                    "createdBy": 1,
                    "modifiedAt": 1,
                    "modifiedBy": 1,
                    "color": null
                };
            }
            this.onboardingService
                .createOrUpdateDepartment(
                    this.currentZone,
                    data,
                    this.pageType
                )
                .pipe(
                    takeUntil(this.unsubscribe$),
                )
                .subscribe((res) => {
                    if (this.pageType == "editDepartment") {
                        this.toastrService.success(
                            "Updated department successfully"
                        );
                    } else {
                        this.toastrService.success(
                            "Saved department successfully"
                        );
                    }
                    this.closePopup();
                });
        }
    }

    onStatusChange() {
        let changeData = {
            userId: this.editAllocationData.allocation.user.userId,
            newStatus: this.editAllocationData.selectedStatus,
        };
        this.onboardingService
            .changeActivityStatus(this.currentZone, changeData)
            .pipe(
                takeUntil(this.unsubscribe$),
            )
            .subscribe((res) => {
                this.toastrService.success("Modified User Status successfully");

                if (
                    res["response"] &&
                    res["response"]["reallocationInformationDto"] &&
                    res["response"]["reallocationInformationDto"]["newEntity"]
                ) {
                    this.stagestatus = "Edit Allocation Success";
                    this.newworkstation =
                        res["response"]["reallocationInformationDto"][
                        "newEntity"
                        ]["displayName"];
                } else {
                    this.stagestatus = "Edit Allocation Failure"; //teja
                    this.userDto =
                        res["response"]["reallocationInformationDto"];
                }

                // this.closePopup();
            });
    }

    onSubmitAssests() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        } else {
            let data;
            if (this.pageType == "editAssests") {
                data = {
                    id: this.data["assests"]["id"],
                    entityCategory: "SEAT",
                    name: this.registerForm.value.assests,
                };
            } else if (this.pageType == "addAssests") {
                data = {
                    entityCategory: "SEAT",
                    name: this.registerForm.value.assests,
                };
            }
            this.onboardingService
                .createOrUpdateAssests(this.currentZone, data, this.pageType)
                .pipe(
                    takeUntil(this.unsubscribe$),
                )
                .subscribe((res) => {
                    if (this.pageType == "editAssests") {
                        this.toastrService.success(
                            "Updated assests successfully"
                        );
                    } else {
                        this.toastrService.success(
                            "Saved assests successfully"
                        );
                    }
                    this.closePopup();
                });
        }
    }

    closePopup() {
        this.dialogRef.close();
    }

    closeAutoPopup() {
        let autoValue = 'resetUpload';
        this.dialogRef.close(autoValue);
    }

    get buildingError() {
        const control = this.buildingForm.get("buildingName");
        return control.hasError("required") && control.touched;
    }

    onSubmitBuilding() {
        this.submitted = true;
        if (this.buildingForm.valid) {
            let building_name = this.buildingForm.get("buildingName").value;
            let reqObj: any = {
                description: null,
                name: building_name,
                parentZoneId: this.currentZone,
                type: "BUILDING",
            };
            this.emsService
                .addZones(reqObj, this.currentZone)
                .pipe(
                    map(res => {
                        let response = res["response"];
                        let result = { ...response, zoneId: response.id };
                        return result
                    }),
                    takeUntil(this.unsubscribe$),
                )
                .subscribe(
                    (res) => {
                        this.toastrService.success("Building added successfully")
                        let newZoneData = res;
                        this.buildingForm.reset();
                        this.dialogRef.close(newZoneData);
                    },
                    (err) => { }
                );
        }
        else {
            return;
        }
    }
    /*
    showMap() {
        setTimeout(() => {
            var mymap = L.map("mapid").setView([-9.27, 35.09], 7);
            L.tileLayer(
                "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
                {
                    maxZoom: 18,
                    attribution: "",
                    id: "mapbox/streets-v11",
                    tileSize: 512,
                    zoomOffset: -1,
                    minZoom: 4
                }
            ).addTo(mymap);

            var latLong = [
                {
                    lat: 51.508,
                    lng: 85.56
                },
                {
                    lat: 51.51,
                    lng: 92.77
                },
                {
                    lat: -4.18,
                    lng: 37.83
                },
                {
                    lat: 51.508,
                    lng: 11.1
                },
                {
                    lat: 5.49,
                    lng: 10.85
                },
                {
                    lat: 1.4,
                    lng: 9.51
                },
                {
                    lat: -10.61,
                    lng: 29.3
                },
                {
                    lat: -9.27,
                    lng: 35.04
                },
                {
                    lat: -6.22,
                    lng: 30.59
                },
                {
                    lat: 1.5,
                    lng: 30.03
                },
                {
                    lat: 1.17,
                    lng: 28.76
                },
                {
                    lat: 1.71,
                    lng: 15.95
                },
                {
                    lat: 2.2,
                    lng: 22.61
                },
                {
                    lat: 0.679,
                    lng: 34.77
                }
            ];

            let myIcon = L.icon({
                iconUrl: "./assets/images/checkbox.png",
                iconSize: [32, 37],
                iconAnchor: [16, 17],
                popupAnchor: [0, -28]
            });

            mymap.on("zoomend", res => {
                var newzoom = "" + 4 * mymap.getZoom() + "px";
               
                if (mymap.getZoom() <= 7) {
                    $(".leaflet-marker-icon").css({
                        width: newzoom,
                        height: newzoom,
                        "margin-left": "-10px",
                        "margin-top": "-10px"
                    });
                } else {
                    $(".leaflet-marker-icon").css({
                        width: 32,
                        height: 37,
                        "margin-left": "-16px",
                        "margin-top": "-17px"
                    });
                }
            });

            let i = 0;
            latLong.forEach(coord => {
                var getCentroid = function(arr) {
                    return arr.reduce(
                        function(x, y) {
                            return [
                                x[0] + y[0] / arr.length,
                                x[1] + y[1] / arr.length
                            ];
                        },
                        [0, 0]
                    );
                };

                var arr = [
                    [coord.lat, coord.lng],
                    [coord.lat + 1, coord.lng + 1],
                    [coord.lat + 1, coord.lng]
                ];
                var marker = L.marker(getCentroid(arr), {
                    icon: myIcon,
                    draggable: false
                });

                L.polygon([
                    [coord.lat, coord.lng],
                    [coord.lat + 1, coord.lng + 1],
                    [coord.lat + 1, coord.lng]
                ])
                    .addTo(mymap)
                    .on("click", function() {
                        if (mymap.hasLayer(marker)) {
                            mymap.removeLayer(marker);
                            //this.selectedFloors.slice(1,1);
                        } else {
                            //this.selectedFloors.push('marker');
                            mymap.addLayer(marker);
                        }
                    });

                marker.addTo(mymap).on("click", function() {
                    mymap.removeLayer(marker);
                    //this.selectedFloors.slice(1,1);
                });
            });
        }, 2000);
    }
*/
    initLeafletMap = () => {
        let initMapOption: LeafLetInit = {
            mapId: "map",
            minZoom: 10,
            maxZoom: 14,
            attributionControl: false,
            setViewLatLng: [0.25, 0.5],
            zoomLeavel: this.userZoomLevel,
        };
        let tilesOption: TilesOption = {
            tileUrl: this.leaflet_url,
            maxZoom: 14,
            attribution: "smartenspaces",
            id: "smartenspaces",
        };
        if (this.map) {
            this.leafletService.deleteMap(this.map);
        }
        this.map = L.map(initMapOption.mapId, {
            minZoom: initMapOption.minZoom,
            maxZoom: initMapOption.maxZoom,
            attributionControl: initMapOption.attributionControl,
        }).setView(
            [initMapOption.setViewLatLng[0], initMapOption.setViewLatLng[1]],
            initMapOption.zoomLeavel
        );

        var southWest = L.latLng(-0.1, -0.1),
            northEast = L.latLng(1.1, 1.1);
        var bounds = L.latLngBounds(southWest, northEast);


        this.map.setMaxBounds(bounds);

        forkJoin(
            // this.leafletService.initMap(initMapOption),
            this.leafletService.addTiles(this.map, tilesOption),
            this.leafletService.initDrawMapControl(this.map)
        ).subscribe(([res2, drawControlObj]) => {
            // this.map = mapObj;
            // res2.addTo(this.map); // need to chk
            this.drawpolygon();
            //this.drawnItems = drawControlObj.drawnItems;
            //this.drawControl = drawControlObj.controlObj;
        });

        this.map.on("zoomend", (res) => {
            this.userZoomLevel = this.map.getZoom();
            $(".leaflet-marker-pane").css({
                "z-index": 700
            })
            if (this.map.getZoom() >= 14) {

                $(".leaflet-tooltip.my-labels").css({
                    "font-size": "20px",
                    "-webkit-text-stroke": "1px black",
                    "margin-top": "26px",
                    "font-family": "OpenSans",
                    "letter-spacing": "1.5px",
                    "font-weight": "bold"
                });
            } else if (this.map.getZoom() === 11) {
                $(".leaflet-tooltip.my-labels").css({
                    "font-size": "5px",
                    "-webkit-text-stroke": "0.8px black",
                    "margin-top": "2px",
                    "font-family": "OpenSans",
                    "letter-spacing": "1.5px",
                    "font-weight": "bold"
                });
            } else if (this.map.getZoom() === 12) {
                $(".leaflet-tooltip.my-labels").css({
                    "font-size": "10px",
                    "-webkit-text-stroke": "0.8px black",
                    "margin-top": "2px",
                    "font-family": "OpenSans",
                    "letter-spacing": "1.5px",
                    "font-weight": "bold"
                });
            } else if (this.map.getZoom() === 13) {
                $(".leaflet-tooltip.my-labels").css({
                    "font-size": "13px",
                    "-webkit-text-stroke": "1px black",
                    "margin-top": "15px",
                    "font-family": "OpenSans",
                    "letter-spacing": "1.5px",
                    "font-weight": "bold"
                });
            } else {
                $(".leaflet-tooltip.my-labels").css({
                    "font-size": 0,
                    "-webkit-text-stroke": "1px black",
                    "margin-top": "15px",
                    "font-family": "OpenSans",
                    "letter-spacing": "1.5px",
                    "font-weight": "bold"
                });
            }
            if (this.userZoomLevel >= 13) {
                if (this.UserMarkerObj) {
                    this.UserMarkerObj.addTo(this.map);
                }
            } else {
                if (this.UserMarkerObj) {
                    this.leafletService.removeLayer(this.map, this.UserMarkerObj);
                }
            }
        });
    };

    showMap(zoneId) {
        this.showFloor(zoneId);
    }

    showFloor(zoneId) {
        let data = {
            zoneId: zoneId,
        };
        this.onboardingService
            .getFloorDetailsByFloorId(this.currentZone, data)
            .pipe(
                takeUntil(this.unsubscribe$),
            )
            .subscribe((res) => {
                let response = res["response"];

                this.leaflet_url = response["tileUrl"];
                this.leaflet_scaleData = response["scaleAttributes"];
                this.leaflet_blockInfo = response["blockInfo"];
                //this.leaflet_overlaydata = response["overlaydata"];
                this.leaflet_overlaydata = response["overlaydata"];
                this.leaflet_seatsGeojson = {};
                this.workstationListData = [];



                this.formColorArray();
                const workStationOnboardingList =
                    response["entityInformation"]["workStationOnboardingList"];

                const unOnboardedListData = response["unonboardedEntities"];
                let newArr: any = [];
                workStationOnboardingList.forEach((entity) => {
                    let coordinates_arr =
                        entity["entityChildType"]["attributes"]["coordinates"];
                    let workstationBlockName =
                        entity["entityChildType"]["name"];
                    let coordinates = [];
                    if (!!coordinates_arr && coordinates_arr != "") {
                        // coordinates = coordinates_arr.map(Object.values);
                        coordinates = coordinates_arr.map(
                            ({ seatName, x: lat, y: lng }) => ({ lat, lng })
                        );
                    }

                    const seats = this.leaflet_blockInfo[
                        workstationBlockName
                    ].sort();
                    let seatId = seats[0];
                    let count = seats.length;
                    let newObj = {
                        key: workstationBlockName,
                        seatId: seatId,
                        area: entity.area,
                        coordinates: coordinates,
                        workstationName:
                            entity.entityChildType.entityTypeId.name,
                        workstationId: entity.entityChildType.entityTypeId.id,
                        count: count,
                        drawType: "polygon",
                        originalArea: entity.originalArea,
                        color: this.getColorByWorkstation(workstationBlockName),
                    };
                    this.workstationListData = [
                        ...this.workstationListData,
                        newObj,
                    ];
                    entity["listOfSeat"].forEach((seatsData) => {
                        let seat_id = seatsData.displayName;
                        let attributes = seatsData.attributes;
                        this.leaflet_seatsGeojson[seat_id] = attributes;
                    });
                });
                if (
                    unOnboardedListData != "" &&
                    typeof unOnboardedListData != "undefined"
                ) {
                    for (const [key, entity] of Object.entries(
                        unOnboardedListData
                    )) {
                        let coordinates_arr =
                            entity["wrkStationObj"]["entityChildType"][
                            "attributes"
                            ]["coordinates"];
                        let workstationBlockName =
                            entity["wrkStationObj"]["entityChildType"]["name"];
                        let coordinates = [];
                        if (!!coordinates_arr && coordinates_arr != "") {
                            coordinates = coordinates_arr.map(Object.values);
                        }
                        const seats = this.leaflet_blockInfo[
                            workstationBlockName
                        ].sort();
                        let seatId = seats[0];
                        let count = seats.length;
                        let newObj = {
                            key: workstationBlockName,
                            seatId: seatId,
                            area: entity["wrkStationObj"]["area"],
                            coordinates: coordinates,
                            workstationName: "",
                            workstationId: "",
                            count: count,
                            drawType: "polygon",
                            originalArea: entity["originalArea"],
                            color: this.getColorByWorkstation(
                                workstationBlockName
                            ),
                        };
                        this.workstationListData = [
                            ...this.workstationListData,
                            newObj,
                        ];
                        entity["wrkStationObj"]["listOfSeat"].forEach(
                            (seatsData) => {
                                let seat_id = seatsData.displayName;
                                let attributes = seatsData.attributes;
                                this.leaflet_seatsGeojson[seat_id] = attributes;
                            }
                        );
                    }
                }
                this.initLeafletMap();
            });
    }

    getColorByWorkstation(workstation) {
        let colorData = this.workstationColorArr.find((clrData) => {
            if (clrData.key === workstation) {
                return clrData;
            }
        });
        if (!!colorData) {
            return colorData.value;
        }
        // return this.workstationColorArr[workstation];
    }

    formColorArray = () => {
        let currentContext = this;
        currentContext.workstationColorArr = [];
        for (const [key, value] of Object.entries(this.leaflet_blockInfo)) {
            let colrObj: any = {};
            colrObj.key = key;
            colrObj.value =
                "#" +
                (0x1000000 + Math.random() * 0xffffff)
                    .toString(16)
                    .substr(1, 6);
            currentContext.workstationColorArr.push(colrObj);
        }
    };

    getBuildingName(buildingId) {
        let data = this.buildingList.filter(
            (building) => building.zoneId === parseInt(buildingId)
        );
        if (!!data) {
            return data[0].name;
        }
        return;
    }

    drawpolygon = () => {
        for (var a in this.leaflet_overlaydata) {
            let polygonArray = [];
            let biggerArray = [];
            let vertices = this.leaflet_overlaydata[a];
            for (let i = 0; i < vertices.length; i++) {
                let objdata = [];
                objdata.push(vertices[i]["Y"]);
                objdata.push(vertices[i]["X"]);
                polygonArray.push(objdata);
            }
            biggerArray.push(polygonArray);
            let polygonOption: PolygonOption = {
                polygonArray: biggerArray,
                color: "",
                fillColor: this.getWorkstationColor(a),
                fillOpacity: 1,
                weight: 0.01,
                label: a,
            };

            this.leafletService
                .drawPolygon(this.map, polygonOption, this.data["data"])
                .pipe(
                    takeUntil(this.unsubscribe$),
                )
                .subscribe((polygonRes) => { });
        }
        this.showUser(this.data["data"])
    };

    showUser = (data) => {
        if (!!data) {
            let biggerArray = [];
            let userSeat = data['entityInfo']['displayName'];
            let vertices = this.leaflet_overlaydata[userSeat];
            let polyArray = vertices.map(v => {
                let newArr = [];
                newArr = [...newArr, v.Y, v.X];
                return newArr;
            });
            biggerArray = [...biggerArray, polyArray];
            let reqObj = {
                polygonCoordinates: biggerArray,
                userData: data
            }
            this.leafletService.addUserInfoMarker(this.map, reqObj)
                .pipe(
                    takeUntil(this.unsubscribe$),
                )
                .subscribe(res => {

                    this.UserMarkerObj = res.marker;
                })

        }
    }

    getWorkstationColor(seatId) {
        var colorData: any = {};
        for (const key in this.leaflet_blockInfo) {
            if (this.leaflet_blockInfo[key].includes(seatId)) {
                colorData = this.workstationColorArr.find((clrData) => {
                    if (clrData.key === key) {
                        return clrData;
                    }
                });
            }
        }
        if (!!colorData) {
            return colorData.value;
        }
    }

    onSubmitAddUsers() {
        this.addsubmitted = true;
        if (this.addUserForm.invalid) {
            return;
        }
        else {
            this.loaderService.loadingOn();
            let userData = this.addUserForm.value;
            let phone = userData.countryCode + "-" + userData.phoneNumber;
            let empCode;
            if (userData.employeeCode != null && userData.employeeCode != '') {
                empCode = userData.employeeCode.toUpperCase();
            }
            let data = {
                "email": userData.email,
                "firstName": userData.firstName,
                "password": "",
                "lastName": userData.lastName,
                "phoneNum": phone,
                "rolesToAssign": [],
                "tenantId": userData.tenant,
                "empCode": empCode,
                "deptId": userData.Department,
                "grade": userData.grade,
                "shiftStartTime": this.starttime.hour + ":" + this.starttime.minute,
                "shiftEndTime": this.endtime.hour + ":" + this.endtime.minute,
                "supervisorId": this.bookingObj.id,
                "autoAssign": true
            };
            const addPeopleDetails$ = this.onboardingService.saveUserDetails(this.currentZone, data)
            this.loaderService.showLoaderUntilCompleted(addPeopleDetails$)
                .subscribe(res => {
                    if (res['message']) {
                        this.toastrService.success(res['message']);
                        this.dialogRef.close();
                    }
                }, error => {
                    if (!error && !!error['error']['message']) {
                        this.toastrService.error(error['error']['message']);
                    }
                    //this.dialogRef.close();
                });
        }
    }

    onSubmitEditUsers() {
        //For triggering validation
        let selVal = this.editUserForm.get("edittenant").value;
        this.editUserForm.get("edittenant").setValue(selVal);
        this.editsubmitted = true;
        if (this.editUserForm.invalid) {
            return;
        } else {
            this.loaderService.loadingOn();
            let userData = this.editUserForm.value;
            let phone = this.edit_country_code + "-" + this.user_phone_number;
            let empCode;
            if (this.user_emp_code != null && this.user_emp_code != '') {
                empCode = this.user_emp_code.toUpperCase();
            } else {
                empCode = null;
            }
            let data = {
                "id": this.update_id,
                "email": this.user_email,
                "firstName": this.user_first_name,
                "password": "",
                "lastName": this.user_last_name,
                "phoneNum": phone,
                "rolesToAssign": [],
                "tenantId": this.user_tenant,
                "empCode": empCode,
                "deptId": this.edit_department_name,
                "shiftStartTime": this.editstarttime.hour + ":" + this.editstarttime.minute,
                "shiftEndTime": this.editendtime.hour + ":" + this.editendtime.minute,
                "supervisorId": this.bookingObj.id
            };
            const editPeopleDetails$ = this.onboardingService.saveupdatedUserDetails(this.currentZone, data)
            this.loaderService.showLoaderUntilCompleted(editPeopleDetails$)
                .subscribe(res => {
                    if (res['message']) {
                        this.toastrService.success(res['message']);
                        this.dialogRef.close();
                    }
                }, error => {
                    if (!error && !!error['error']['message']) {
                        this.toastrService.error(error['error']['message']);
                    }
                    //this.dialogRef.close();
                });
        }
    }

    onSubmitAutoUpload() {
        if (!!this.selectedAutoValue) {
            let data = {
                'value': this.selectedAutoValue,
                'type': this.data.type,
                'event': this.data.eventData
            }
            this.dialogRef.close(data);
        } else {
            this.toastrService.error("Please choose the reason");
        }
    }

    onCloseRoleModal(): void {
        let currentContext = this;
        currentContext.commonService.changeZone(currentContext.currentZone);
        this.dialogRef.close();
    }

    keyPress(event: any) {
        const pattern = /[0-9\+\-\ ]/;

        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
    onUpdateBuilding = () => {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        let data = {
            id: this.registerForm.value["id"],
            name: this.registerForm.value["buildingName"]
        }
        this.onboardingService
            .UpdateBuilding(this.currentZone, data)
            .pipe(
                takeUntil(this.unsubscribe$),
            )
            .subscribe((res) => {
                this.toastrService.success("Updated Building name successfully");
                this.closePopup();
            });
    }

    onSubmitDisableUser()
    {
        if(this.selectDisableUser){
            let data;
            
            if(this.selectDisableUser==1){
                const peopleLoad$ = this.onboardingService.enableOrDisablePeople(this.currentZone, this.data.userObj);
                    this.loaderService.showLoaderUntilCompleted(peopleLoad$)
                    .pipe(
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe(res => {
                            let status;
                            if (res) {
                                status = true;
                                const updateDisableYes$ = this.onboardingService.deleteFutureBookings(this.currentZone, this.data.userId)
                                const updateDisableYesSpace$ = this.onboardingService.deleteFutureBookingspacemanagement(this.currentZone, this.data.userId)
                                this.loaderService.showLoaderUntilCompleted
                                (forkJoin([updateDisableYes$, updateDisableYesSpace$]))
                                    .subscribe(res => {
                                        
                                    }, error => {
                                        // if (!error && !!error['error']['message']) {
                                        //     this.toastrService.error(error['error']['message']);
                                        // }
                                      
                                    });
                                    this.dialogRef.close();
                            } 
                        },err => {
                    
                          })
            }else if(this.selectDisableUser==2){
                const peopleLoad$ = this.onboardingService.enableOrDisablePeople(this.currentZone, this.data.userObj);
                    this.loaderService.showLoaderUntilCompleted(peopleLoad$)
                    .pipe(
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe(res => {

                        this.dialogRef.close();

                    });
            }
        }
    }
    // getCurrentUserModules(zone) {        
    //     this.emsService.getCurrentUserModules(zone).subscribe(res => {
    //         let tenantAdminOrSuperAdmin = JSON.parse(localStorage.getItem('userObj'));
    //         this.tenantAdminId = res['response']['tenantId'];
    //         console.log('tenant id is... ', this.tenantAdminId)
    //         let checkAdminLoginType = res['response']['rolesMap'][this.currentZone].includes('SUPER_ADMIN');
    //         console.log('chekced... ', checkAdminLoginType)
    //         if(checkAdminLoginType) {
    //             this.checkTenantAdmin = 'SUPER_ADMIN';
    //             this.tenantAdminDropdownDisabled = false;
    //         } else {
    //             this.checkTenantAdmin = 'TENANT_ADMIN';
    //             this.tenantAdminDropdownDisabled = true;
    //         }
           
    //     }, err => {
    //         this.toastrService.error(err.error.message, "");
    //     })
    // }
}


export function validateEditTenant(tenantList): ValidatorFn {
    return function validate(formGroup:any) {
        // console.log(tenantList);
        if(tenantList.length > 0){
            let result = tenantList.filter(el => el.id === formGroup.value);
            // console.log(result);
            if(result[0]['active']){
                return null;
            }
        }
        return {
            validate: true,
          };
    };


  }
