import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Angular5Csv } from "angular5-csv/dist/Angular5-csv";
import { CommonService } from "commonService";
import { LoaderService } from "loaderService";
import { ToastrService } from "ngx-toastr";
import { EMPTY, Observable, of, Subject } from "rxjs";
import { catchError, distinctUntilChanged, map, mergeMap, startWith, switchMap, takeUntil, tap } from "rxjs/operators";
import { GlobalSetupService } from "../../global-setup/services/global-setup.service";
import { SubscriptionService } from "../service/subscription.service";

@Component({
  selector: "app-subscription-modal",
  templateUrl: "./subscription-modal.component.html",
  styleUrls: ["./subscription-modal.component.scss"],
})
export class SubscriptionModalComponent implements OnInit {
  pageType: any;
  submittedDepartmentType: boolean;
  submittedDepartment: boolean = false;
  departmentForm: FormGroup;
  public fileToUpload: File;
  addDepartmentType = "";
  uploadedFileName = "";
  submitted: boolean;
  usersList = [];
  templateData = [];
  incorrectData = [];
  eName;
  correctData = [];
  peopleList = [];
  emailCtrl = new FormControl();
  filteredPeople: Observable<any[]>;
  departmentValueName: any;
  private unsubscribe$ = new Subject<void>();
  @ViewChild("labelImport", { static: false }) labelImport: ElementRef;
  @ViewChild ("fileUploader",{static:false}) fileUploader:ElementRef;
  currentZone: any;
  departmentCountForIntroScreen: any;
  templateDownloaded: boolean;
  displayedColumnsIncorrectData = [];
  showLoader = false; 
  fileType = "";
  subscriptionTitle;
  userObj = {
    pageIndex: 0,
    showSearch: false,
    searchValue: "",
    pageSize: 10
  }
  constructor(
    public dialogRef: MatDialogRef<SubscriptionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalSetupService: GlobalSetupService,
    public subscriptionService: SubscriptionService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toaster: ToastrService,
    public dialog: MatDialog
  ) {
    // this.filteredPeople = this.emailCtrl.valueChanges.pipe(
    //   map((people) =>
    //     people ? this.filterPeoples(people) : this.peopleList.slice()
    //   )
    // );
  }
  nameSearchTermSubject$ = new Subject<any>();
  nameSearchTermAction$ = this.nameSearchTermSubject$.asObservable();
  nameAutocompleteList$ = this.nameSearchTermAction$.pipe(
    tap(() => {
      this.showLoader = true;
      console.log('hello');
    }),
    switchMap((searchTerm) =>
      of(searchTerm).pipe(
        mergeMap((res) =>
         this.globalSetupService.loadUsers(
            this.userObj,
            this.currentZone
          )
          
        )
      )
    ),
    tap((searchRes) => {
      this.showLoader = false;   
    }),
    catchError((err) => {
      return EMPTY;
    })
  );

  ngOnInit() {    
    this.pageType = this.data["type"];
    console.log(this.data);
    if (this.pageType == "addUsers") {
      
      this.subscriptionTitle = this.data["dialogData"]["title"];
      this.departmentForm = this.formBuilder.group({
        departmentName: ["", [Validators.required, Validators.email]],
      });
    }

    if (this.pageType == "incorrectEntries") {
      let dialogData = this.data["dialogData"];
      this.fileType = dialogData["fileType"];
      this.templateData = dialogData["templateData"];
      this.correctData = dialogData["correctData"];
      this.incorrectData = dialogData["incorrectData"];
      this.fileToUpload = dialogData["fileUploaded"];

      this.displayedColumnsIncorrectData = ["slNo", "emailID", "reason"];
    } else if (this.pageType == "uploadCsvWithFixes") {
      let dialogData = this.data["dialogData"];
      this.templateData = dialogData["templateData"];
      this.incorrectData = dialogData["incorrectData"];
      this.fileToUpload = dialogData["fileUploaded"];
      console.log(this.incorrectData);
    }
  }
  // getPeople() {
  //   this.showLoader = true;
  //   let location = this.globalSetupService.loadUsers(this.userObj);
  //   location.subscribe(
  //     (res) => {
  //       this.peopleList = res['response']['content'];   
  //       this.showLoader = false;     
  //     },
  //     (error) => {
  //       console.log('No load');
  //     }
  //   );
  // }
  peopleSelection(email){
    console.log(email);
    this.departmentForm.get('departmentName').setValue(email);
    // this.departmentForm.get('departmentName').setErrors(null);
  }
  filterPeoples=(searchTerm?) => {  
    console.log(searchTerm); 
    this.userObj.searchValue=searchTerm;
      this.showLoader = false;
      this.nameSearchTermSubject$.next(searchTerm.trim());  
  };
  closePopupInMiddle() {
    let data = {
      pageType: this.pageType
    }
    this.dialogRef.close(data);
  }
  backBtnDepartment(pType) {
    this.pageType = pType;
  }
  removeDepartment(val) {
    const index = this.usersList.findIndex((item) => item == val);
    console.log("index is... ", index);
    if (index > -1) {
      // only splice array when item is found
      this.usersList.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  getToolTipData(hoverData) {
    return hoverData;
  }
  addSubscriptionManually() {
    this.submittedDepartmentType = true;
    if (this.addDepartmentType == "" && this.submittedDepartmentType) {
      this.toaster.error("Please select one option");
      this.submittedDepartmentType = false;
    }
    if (this.addDepartmentType == "add_manually") {
      this.pageType = "addSubManually";
    }

    if (this.addDepartmentType == "add_excel") {
      this.submitted = true;
console.log(this.uploadedFileName);
      if (this.uploadedFileName != "") {
        this.proceedSubscriptionUploadFile();
      }
    }
  }

  downloadTemplate(type) {
    this.templateDownloaded = true;
    this.globalSetupService
      .downloadOnboardUsersTemplate(this.currentZone, type)
      .subscribe(
        (res) => {
          let downloadRes = res["response"];
          // console.log(downloadRes)
          this.commonService.downloadCSV(downloadRes);
        },
        (err) => {
          this.commonService.downloadCSV(err.error.text);
        }
      );
    // this.subscription.add(downloadSub$);
  }

  addMoreUsers() {
    this.submittedDepartment = true;
    if (this.departmentForm.valid) {
      this.submittedDepartment = false;
      this.departmentValueName = this.departmentForm.value.departmentName;
      this.postUser("addMoreUsers");
    }
  }
  postUser(type) {
    let reqObj: any = {
      email: this.departmentValueName,
      planName: this.subscriptionTitle.toUpperCase()
    };
    let users = this.subscriptionService.addUsers(
      // this.currentZone,
      reqObj
    );
    this.loaderService.showLoaderUntilCompleted(users).subscribe(
      (result) => {
        let res = result["response"];

        if (res) {
          if (!this.usersList.includes(res.user.email)) {
            this.usersList.push(res.user.email);
          }
          this.toaster.success("License added succesfully");
          if(type!="addMoreUsers"){
          this.closePopupInMiddle();
          }
        }
      },
      (error) => {
        console.log("No load");
      }
    );
    // if (!this.usersList.includes(this.departmentValueName)) {
    //   this.usersList.push(this.departmentValueName);
    // }
    // this.toaster.success("User added successfully");
  }

  proceedSubscriptionUploadFile() {
    if (this.fileToUpload) {
      const allocation$ =
        this.subscriptionService.validateOnboardSubscriptionTemplate(
          this.fileToUpload
        );
      this.loaderService
        .showLoaderUntilCompleted(allocation$)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (data) => {
            this.templateData = [];
            console.log(data);
            if (data["response"]["value"].length > 0) {
              data["response"]["value"].map((res) => {
                let { slNo, email, user } = res.csvDto;
                let userData = {
                  slNo,
                  email,
                  user,
                  errorFields: res.columns,
                  reason: res.reason,
                };
                if (res.columns.length) {
                  this.incorrectData.push(userData);
                } else {
                  this.correctData.push(userData);
                }

                this.templateData.push(userData);
              });
              //this.toastr.error("Something went wrong", "");
            }
            // console.log(this.errorRecordsCount)
            let templateData = {
              closePopup: true,
              templateData: this.templateData,
              correctData: this.correctData,
              incorrectData: this.incorrectData,
              uploadedFile: this.fileToUpload,
              proceedToUpload: true,
              pageType: "addSubExcel",
              departmentCount: this.departmentCountForIntroScreen,
            };
            this.dialogRef.close(templateData);
          },
          (error) => {
            this.toaster.error("Something went wrong!! Please make sure file is in correct format", "");
            // this.commonService.openSnackBar(
            //   "Something went wrong!! Please make sure file is in correct format",
            //   ""
            // );
          }
        );
    } else {
      this.toaster.error("No file selected!");
    }
  }

  submitUsers() {
    this.submittedDepartment = true;
    console.log(this.usersList.length, this.departmentForm.value.departmentName);
    if (this.departmentForm.invalid && this.usersList.length == 0) {
      return;
    } 
    else if(this.usersList.length > 0 && this.departmentForm.value.departmentName == null ){
      this.closePopupInMiddle();
    }
    else {
      this.departmentValueName = this.departmentForm.value.departmentName;;
      this.postUser("submitAddUser");
      
    }
  }

  uploadCSV(files: FileList) {
    this.submitted = false;
    this.templateData = [];
    this.correctData = [];
    this.incorrectData = [];

    // this.uploadedFileList = Array.from(files);
    if (this.isCSVFile(files[0])) {
      this.fileToUpload = files.item(0);
      if (this.fileToUpload) {
        this.labelImport.nativeElement.innerText = "";
        this.uploadedFileName = Array.from(files)
          .map((f) => f.name)
          .join(", ");
      } else {
        this.toaster.error('No file selected!');
          //alert("No file selected!");
      }
    }else {
      this.toaster.error("Please import valid .csv file.");
      this.fileReset();
    }
    this.fileUploader.nativeElement.value = null;
  }

  fileReset() {
    this.labelImport.nativeElement.value = "";
  }

  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  removeUploadedFile() {
    this.submitted = false;
    this.uploadedFileName = "";
    this.fileToUpload = null;
    this.labelImport.nativeElement.innerText = "";
  }

  closeConfirmPopup(loadUserOnClosePopup) {
    let data = {
      pageType: "closingPopUpNotIndividual",
      loadUserOnClosePopup: loadUserOnClosePopup,
    };
    this.dialogRef.close(data);
  }

  proceedToUpload() {
    let data = {
      closePopup: true,
      templateData: this.templateData,
      correctData: this.correctData,
      incorrectData: this.incorrectData,
      uploadedFile: this.fileToUpload,
      uploadCorrectEntries: true,
      proceedToUpload: true,
      pageType: this.pageType,
      uploadType: this.data["fileType"],
    };
    this.dialogRef.close(data);
  }

  closeReUploadUsersPopup() {
    let templateData = {
      closePopup: true,
      templateData: this.templateData,
      incorrectData: this.incorrectData,
      uploadedFile: this.fileToUpload,
      uploadType: this.data["uploadType"],
    };
    this.dialogRef.close(templateData);
  }

  downloadCurrentFile() {
    // console.log(this.templateData)
    let downloadArr = [];

    let obj: any = {};

    if (this.data["dialogData"]["uploadType"] == "SUBSCRIPTIONS") {
      obj.slNo = "Serial Number";
      obj.emailID = "Email ID";
      obj.errorFields = "Error Fields";
      obj.reason = "Reason";

      downloadArr = this.templateData.map((elementData) => {
        // console.log(bookingData)
        let { slNo, email, errorFields, reason } = elementData;
        return {
          slNo,
          email,
          errorFields: errorFields.toString(),
          reason,
        };
      });
    }

    downloadArr.unshift(obj);
    new Angular5Csv(downloadArr, "download_data");
  }

  proceedUploadFile() {
    console.log(this.fileToUpload);
    console.log(this.data);
    if (this.fileToUpload) {
      let type = this.data["dialogData"]["uploadType"];
      const allocation$ = this.globalSetupService.validateOnboardUsersTemplate(
        this.fileToUpload,
        type
      );
      this.loaderService
        .showLoaderUntilCompleted(allocation$)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (data) => {
            if (data["response"]["value"].length > 0) {
              if (type == "SUBSCRIPTIONS") {
                data["response"]["value"].map((user) => {
                  let { slNo, email } = user.csvDto;
                  let uploadData = {
                    slNo,
                    email,
                    errorFields: user.columns,
                    reason: user.reason,
                  };
                  if (user.columns.length) {
                    this.incorrectData.push(uploadData);
                  } else {
                    this.correctData.push(uploadData);
                  }

                  this.templateData.push(uploadData);
                });
              }
            }
            // console.log(this.errorRecordsCount)
            let templateData = {
              closePopup: true,
              templateData: this.templateData,
              correctData: this.correctData,
              incorrectData: this.incorrectData,
              uploadedFile: this.fileToUpload,
              proceedToUpload: true,
              pageType: this.pageType,
              uploadType: this.data["uploadType"],
              usersCountLength: this.data["usersCountLength"],
            };
            this.dialogRef.close(templateData);
            // console.log(templateData)
          },
          (error) => {
            this.commonService.openSnackBar(
              "Something went wrong!! Please make sure file is in correct format",
              ""
            );
          }
        );
    } else {
      this.toaster.error("No file selected!");
    }
  }
}
