<div class="col p-0 mt-4">
    <form [formGroup]="addTeamForm" (ngSubmit)="submit()">
        <div class="col">
            <div class="col">
                <h5><b>{{ "teams" | translate }}</b></h5>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="col-12 flex form-group">
            <div class="col">
                <label>{{ "selectTeam" | translate }}</label><br />
                <mat-form-field class="border-0 w-100">
                    <mat-select formControlName="existingTeam" class="border-0" panelClass="panel-width"
                        (selectionChange)="onChangeTeam($event)" (openedChange)="checkDropdownClose($event)"
                        [compareWith]="attributeDisplay"
                        [ngClass]="{'is-invalid': f.submitted && f.existingTeam.invalid}">
                        <div class="col p-0">
                            <mat-form-field class="w-100" floatLabel=never>
                                <input matInput #searchInp class="w-100" placeholder="{{'searchTeams' | translate}}" autocomplete="off"
                                    formControlName="teamSearch" (keyup)="getMatchingTeams($event)">
                            </mat-form-field>
                        </div>
                        <mat-option [disabled]="true">Select</mat-option>
                        <mat-option *ngFor="let team of filteredTeamsList; let i = index" [value]="team">
                            {{ team.teamName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field>
                <mat-select formControlName="existingTeam" id="team">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search Team"
                    noEntriesFoundLabel="'no matching found'"
                    [formControl]="filteredTeamsFilteringCtrl" [searching]="searching">
                </ngx-mat-select-search>
                    </mat-option>
                  <mat-option *ngFor="let team of filteredTeams._events[0]; let i = index" [value]="team">
                    {{ team.teamName }}
                </mat-option>
                </mat-select>
              </mat-form-field> -->
                <div *ngIf="f.existingTeam.touched && f.existingTeam.invalid" class="invalid-feedback">
                    <div *ngIf="f.existingTeam.errors.required">{{ "teamsRequired" | translate }}</div>
                </div>
            </div>
            <div class="col mw-5">
                <label></label><br />
                <div class=" d-flex justify-content-center">{{ "or" | translate }}</div>
            </div>
            <div class="col">
                <label>{{ "enterNewTeamName" | translate }}</label><br />
                <div class="input-group">
                    <input class="form-control" type="text" formControlName="newTeam"
                        [ngClass]="{'is-invalid': f.submitted && f.newTeam.invalid}">
                </div>
                <div *ngIf="f.newTeam.touched && f.newTeam.invalid" class="invalid-feedback">
                    <div *ngIf="f.newTeam.errors.required">{{ "teamsRequired" | translate }}</div>
                </div>
            </div>
            <div class="col" *ngIf="!showSearchUser">
                <label></label><br />
                <button id="add_teams_btn" type="submit" class="btn btn-main btnSmall float-left">
                    {{ "addTeam" | translate }}
                </button>
            </div>
        </div>

        <div class="col-10 d-flex" *ngIf="showSearchUser">
            <div class="col" *ngIf="showSearchUser">
                <label>{{ "search" | translate }}</label><br />
                <mat-form-field class="border-0 w-100">
                    <mat-select formControlName="usersList" class="border-0" panelClass="panel-width"
                        (openedChange)="checkDropdownClose($event)" floatLabel=never multiple>
                        <div class="col p-0">
                            <mat-form-field class="w-100" floatLabel=never>
                                <input #searchTerm matInput class="" name="user-dep" id="user-dep" autocomplete="off"
                                    (input)="getAutocompleteList(searchTerm.value)" type="text" id="search-data"
                                    formControlName="searchUser" />
                            </mat-form-field>
                        </div>
                        <mat-option [disabled]="true">Select</mat-option>
                        <mat-option *ngFor="let dropData of autocompleteList$ | async" [value]="dropData.userId">
                            <span>{{dropData?.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-2 mw-5" *ngIf="showSearchUser">
                <label></label><br />
                <div class="p-0 d-flex justify-content-center"></div>
            </div>
            <div class="col">
                <label></label><br />
                <button id="add_teams_btn" type="submit" class="btn btn-main btnSmall float-left">
                    {{ "addTeam" | translate }}
                </button>
            </div>
        </div>
        <div class="col-12 mt-4">
            <div class="col" *ngIf="teamDetails">
                <mat-card class="pb-2 mb-4">
                    <mat-card-title><b>{{teamDetails.teamName}}</b></mat-card-title>
                    <mat-divider></mat-divider>
                    <mat-card-content class="cardContentScroll">
                        <div class="col d-flex pl-0" *ngFor='let user of teamDetails.memberList'>
                            <div class="col-4 mt-2 p-0">
                                {{user.firstName}}
                            </div>
                            <div class="col-4 mt-2 p-0">
                                {{user.phoneNum}}
                            </div>
                            <div class="col-4 mt-2 p-0 text-end">
                                <img src="./assets/images/delete-photo.png" class="block-img ml-4 cursor-pointer"
                                    (click)="deleteUser(teamDetails, user)" />
                            </div>
                        </div>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>