import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as _moment from "moment";
import { takeUntil } from 'rxjs/operators';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
const moment = _moment.default ? _moment.default : _moment;
// export const MY_CUSTOM_FORMATS = {
//   parseInput: 'll LT',
//   fullPickerInput: 'll LT',
//   datePickerInput: 'll',
//   timePickerInput: 'LT',
//   monthYearLabel: 'MMM YYYY',
//   dateA11yLabel: 'll',
//   monthYearA11yLabel: 'MMMM YYYY',
// };
export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD MMM YYYY',
    fullPickerInput: 'DD MMM YYYY',
    datePickerInput: 'DD MMM YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD MMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
};
export class AmenityBookingModel2Component {
    constructor(dialogRef, data, commonService, manageRequestsService, amenityBookingService, route, formBuilder, loaderService, toastrService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.commonService = commonService;
        this.manageRequestsService = manageRequestsService;
        this.amenityBookingService = amenityBookingService;
        this.route = route;
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.toastrService = toastrService;
        this.visitorBookingEnabled = false;
        this.isEmailEntered = false;
        this.formSubmitted = false;
        this.validSelection = false;
        this.nameSearchIsLoading = false;
        this.searchUserDropdownEnable = false;
        this.startDate = new Date();
        // uploadedFileList;
        this.templateDownloaded = false;
        this.subscription = new Subscription();
        this.unsubscribe$ = new Subject();
        this.templateData = [];
        this.correctData = [];
        this.incorrectData = [];
        this.displayedColumnsIncorrectData = [
            "slNo",
            "empEmail",
            "entityName",
            "building",
            "floor",
            "startDate",
            "reason"
        ];
        this.proceedbtn = true;
        this.placeholders = {
            'First Name of Visitor': 'Eg. Jane',
            'Last Name of Visitor': 'Eg. Doe',
            'Tag to Department': 'Select Department Name',
            'Email of Visitor': 'Eg. abc@companyname.com',
        };
        this.dynamicFields = [];
        this.deskDetailsData = [];
        this.deleteDates = [];
        this.deleteTitle = "";
        this.deleteUser = "";
        this.showWayFinding = false;
        this.timeList = {
            startTimeList: [],
            endTimeList: [],
            selectedStartTime: "",
            selectedEndTime: ""
        };
        this.gridViewBookingTimeList = {
            startTimeList: [],
            endTimeList: [],
            selectedStartTime: "",
            selectedEndTime: ""
        };
        this.capacityList = [{ value: 5, selected: false }, { value: 10, selected: false }, { value: 20, selected: false }, { value: 30, selected: false }];
        this.selectedAddOns = [];
        this.selectedCapacity = [];
        this.selectedRequests = [];
        this.enableVCLink = true;
        this.specialReqList = [];
        this.totalParticipantsCount = 0;
        this.selectedParticipants = [];
        this.selectedEmail = "";
        this.isErrorInput = false;
        this.selectedAmenitiesFiltered = [];
        this.time12hrFormat = true;
        this.disableTimeSlots = false;
        this.selectedEndTime = "";
        this.timeSlotError = false;
        this.specialRequestSelected = [];
        this.nameSearchTermSubject$ = new Subject();
        this.nameSearchTermAction$ = this.nameSearchTermSubject$.asObservable();
        this.nameAutocompleteList$ = this.nameSearchTermAction$.pipe(tap(() => {
            this.nameSearchIsLoading = true;
            this.searchUserDropdownEnable = true;
        }), switchMap((searchTerm) => of(searchTerm).pipe(mergeMap((res) => this.manageRequestsService.getEmployeeNameAutoCompleteList({
            searchTerm,
            searchType: this.eName
        }, this.currentZone)))), tap((searchRes) => {
            this.nameSearchIsLoading = false;
            if (!!searchRes && searchRes.length) {
                this.searchUserDropdownEnable = true;
            }
            else {
                this.searchUserDropdownEnable = false;
            }
        }), catchError((err) => {
            this.searchUserDropdownEnable = false;
            return EMPTY;
        }));
        this.bookingType = "employee";
        this.sendCnfMailList = [];
        this.emailbookingIsloading = false;
        this.bookingDropdownEnable = false;
        this.bookingSearchTermemailSubject$ = new Subject();
        this.bookingSearchTermemailAction$ = this.bookingSearchTermemailSubject$.asObservable();
        this.bookingAutoemailcompleteList$ = this.bookingSearchTermemailAction$.pipe(takeUntil(this.unsubscribe$), tap(() => {
            this.emailbookingIsloading = true;
            this.bookingDropdownEnable = true;
        }), switchMap((searchTerm) => of(searchTerm).pipe(mergeMap((res) => this.manageRequestsService.getBookingAutocompleteList({
            searchTerm,
            searchType: this.bookingType,
        }, this.currentZone)))), tap((searchRes) => {
            this.emailbookingIsloading = false;
            if (!!searchRes && searchRes.length) {
                this.bookingDropdownEnable = true;
            }
            else {
                this.bookingDropdownEnable = false;
            }
        }), catchError((err) => {
            this.bookingDropdownEnable = false;
            return EMPTY;
        }));
        this.getEmployeeNameAutocompleteList = (searchTerm) => {
            this.validSelection = false;
            this.proceedbtn = false;
            this.nameSearchIsLoading = false;
            this.nameSearchTermSubject$.next(searchTerm.trim());
        };
        this.onselectNameData = (val) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.validSelection = true;
            this.userData = val;
            this.searchedUserId = val.userId;
            this.eName = val.name.trim();
            this.empId = val.empId;
            this.departmentName = val.departName;
            this.searchUserDropdownEnable = false;
            this.proceedbtn = true;
        });
        this.getUserLegendName = users => {
            if (!!users.name) {
                const fName = users.name.length ? users.name.split(' ')[0].charAt(0) : '';
                const lName = users.name.length ? users.name.split(' ')[1].charAt(0) : '';
                return `${fName}${lName}`;
            }
            return '';
        };
        this.getBookTime = (element, type) => {
            let startTime;
            let endTime;
            if (element.recurringStartTime !== null && element.recurringEndTime !== null) {
                startTime = element.recurringStartTime.toString();
                endTime = element.recurringEndTime.toString();
            }
            else {
                startTime = "";
                endTime = "";
            }
            let bookingStart = "00:00";
            let bookingEnd = "23:59";
            if (startTime.length === 2) {
                startTime = "00" + startTime;
            }
            if (endTime.length === 2) {
                endTime = "00" + endTime;
            }
            if (startTime.length === 3) {
                startTime = 0 + startTime;
            }
            if (endTime.length === 3) {
                endTime = 0 + endTime;
            }
            bookingStart = (startTime.length !== 1) ? `${startTime.slice(0, 2)}:${startTime.slice(2, 4)}` : bookingStart;
            bookingEnd = (endTime.length !== 1) ? `${endTime.slice(0, 2)}:${endTime.slice(2, 4)}` : bookingEnd;
            bookingStart = moment(bookingStart, ["HH.mm"]).format("hh:mm A");
            bookingEnd = moment(bookingEnd, ["HH.mm"]).format("hh:mm A");
            if (type == "both") {
                return `${bookingStart} - ${bookingEnd}`;
            }
            else if (type == "start") {
                return `${bookingStart}`;
            }
            else if (type == "end") {
                return `${bookingEnd}`;
            }
        };
        this.getBookingAutoEmailcompleteList = (searchTerm) => {
            this.isErrorInput = false;
            this.bookingSearchTermemailSubject$.next(searchTerm.trim());
        };
        this.eventsSubscription = Subscription.EMPTY;
    }
    ngOnInit() {
        //this.currentZone = localStorage.getItem('currentZone');
        this.visitorInformationForm = this.formBuilder.group({});
        let amenityData = this.amenityBookingService.getmyAmenityObj();
        if (amenityData && amenityData.length > 0) {
            let amenity = amenityData[0];
            let config = amenity['config'] ? JSON.parse(amenity['config']) : {};
            if (config) {
                let spaceManagementConfig = config["spaceManagementConfig"];
                this.amenityViewType = spaceManagementConfig['amenityViewType'];
            }
            this.entityCategoryName = amenity && amenity['entityCategoryName'] ? amenity['entityCategoryName'] : '';
        }
        this.commonService.currentZone.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentZone = +(res);
        }));
        console.log("Data", this.data);
        this.pageType = this.data['type'];
        this.gecId = this.data['gecId'];
        if (this.pageType == 'bookViaMapDateSelection') {
            this.mapDateSelectionData = this.data['showData'];
        }
        else if (this.pageType == 'viewParmenentDetails') {
            this.viewPermanentBookingData = this.data['data'];
        }
        else if (this.pageType == 'viewParmenentDetailsflexiVisitor') {
            this.viewFlexibleVisitorBookingData = this.data['dataArray'];
            this.visitorDataBooked = this.data['visitorData'];
            this.gecId = this.visitorDataBooked[0].gecId;
            this.getAllDepartments();
        }
        else if (this.pageType == 'incorrectEntries') {
            this.templateData = this.data['templateData'];
            this.correctData = this.data['correctData'];
            this.incorrectData = this.data['incorrectData'];
            this.fileToUpload = this.data['fileUploaded'];
        }
        else if (this.pageType == 'uploadCsvWithFixes') {
            this.templateData = this.data['templateData'];
            this.incorrectData = this.data['incorrectData'];
            this.fileToUpload = this.data['fileUploaded'];
        }
        else if (this.pageType == 'bookNowManageBooking') {
            this.visitorBookingEnabled = this.data['visitorBookingEnabled'];
            if (this.amenityViewType === 'LIST' && this.visitorBookingEnabled) {
                this.manageBookingFOrP = 'flexible';
            }
        }
        else if (this.pageType == 'bookNowManageVisitor') {
            this.visitorBookingEnabled = this.data['visitorBookingEnabled'];
            this.getAllVisitorParameters();
            this.getAllDepartments();
        }
        else if (this.pageType === "groupBookingDetails") {
            this.getGroupBookingDetails();
        }
        else if (this.pageType === 'deleteGroupBooking') {
            // this.getGroupBookingDetails()
            this.deleteGroupBooking();
        }
        else if (this.pageType == 'emailAlert') {
            this.visitorBookingEnabled = this.data['visitorBookingEnabled'];
        }
        else if (this.pageType == 'gridViewFilter') {
            this.getSetUpData();
            console.log("Time Slots", this.data['timeSlots']);
            this.timeList.startTimeList = this.data['timeSlots'].slice(1);
            this.timeList.endTimeList = this.data['timeSlots'].slice(1);
        }
        else if (this.pageType == 'gridViewBooking') {
            this.loadAllInfo();
            console.log("This grid View Save data", this.data);
            this.gridViewBookingRoom = this.data['meetingRoomDetails']['room'];
            this.gridViewBookingBuilding = this.data['meetingRoomDetails']['entityInfo']['zoneLocationDto']['building']['name'];
            this.gridViewBookingFloor = this.data['meetingRoomDetails']['entityInfo']['zoneLocationDto']['name'];
            this.gridViewBookingEntityInfo = this.data['meetingRoomDetails']['entityInfo'];
            this.gridViewBookingImage = this.data['meetingRoomDetails']['entityInfo']['entityType']['globalEntityCategory']['dashboardImageUrl'];
            console.log("Grid View Create", this.data['currentDate']);
            this.gridViewBookingDate = this.data['currentDate'];
            this.selectedTime = this.data['meetingRoomDetails']['timeSlots'][this.data.slotIndex]['slot']['displayValue'];
            for (let i = this.data.slotIndex; i < this.data['meetingRoomDetails']['timeSlots'].length; i++) {
                if (this.data['meetingRoomDetails']['timeSlots'][i]['meetings'].length > 0) {
                    this.selectedEndTime = this.data['meetingRoomDetails']['timeSlots'][i]['slot']['displayValue'];
                    break;
                }
            }
            console.log("Selected Time", this.selectedTime);
            console.log("Selected End Time", this.selectedEndTime);
            this.floorId = this.data['meetingRoomDetails']['FloorId'];
            this.getAllAmenities();
            this.getSpecialRequests();
        }
    }
    getAllVisitorParameters() {
        const getVisitorParameters$ = this.amenityBookingService.getInitialVisitorParameters(this.currentZone, 12001, this.gecId);
        this.loaderService.showLoaderUntilCompleted(getVisitorParameters$).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
            if (!!res["response"]) {
                this.visitorParams = res["response"];
                this.visitorFields = this.visitorParams;
                this.generateDynamicFields(this.visitorParams);
            }
        });
    }
    getGroupBookingDetails() {
        this.loaderService.loadingOn();
        const getGroupBookingDetails$ = this.amenityBookingService.getGroupBookingDetailsById(this.currentZone, this.data.gecId, this.data.dataArray.groupBookingId, this.data.dataArray.status);
        this.loaderService.showLoaderUntilCompleted(getGroupBookingDetails$).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
            if (!!res["response"]) {
                this.groupBookingDetails = res['response'];
            }
            this.loaderService.loadingOff();
        }, err => {
            this.loaderService.loadingOff();
            this.toastrService.error(err.error.message);
        });
    }
    deleteGroupBooking() {
        this.loaderService.loadingOn();
        const getGroupBookingDetails$ = this.amenityBookingService.deleteGroupBooking(this.currentZone, this.data.gecId, this.data.dataArray.groupBookingId, true);
        this.loaderService.showLoaderUntilCompleted(getGroupBookingDetails$).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
            if (!!res["response"]) {
                this.deleteUser = res['response']['hostDetails']['username'];
                this.deleteTitle = res['response']['groupTitle'];
                let delDates = res['response']['startTime'];
                console.log("Delete Dates", delDates);
                delDates.sort((a, b) => a - b);
                this.deleteDates = delDates.map(timestamp => this.formatDate(timestamp));
            }
            this.loaderService.loadingOff();
        }, err => {
            this.loaderService.loadingOff();
            this.toastrService.error(err.error.message);
        });
    }
    deleteGroupBookingConfirm() {
        this.data["deleteGroupBooking"] = true;
        this.dialogRef.close(this.data);
    }
    showDeskDetails(item) {
        this.loaderService.loadingOn();
        const getDeskDetails$ = this.amenityBookingService.trackAllUsers(this.currentZone, this.data.gecId, this.data.dataArray.groupBookingId, item[0]);
        this.loaderService.showLoaderUntilCompleted(getDeskDetails$).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
            if (!!res["response"]) {
                this.pageType = "showDeskDetails";
                this.dateOn = this.formatDate(item[0]);
                this.location = `${item[1].siteName}, ${item[1].zoneName}`;
                this.deskDetailsData = res['response']['Accepted'];
            }
            this.loaderService.loadingOff();
        }, err => {
            this.loaderService.loadingOff();
            this.toastrService.error(err.error.message);
        });
    }
    sanitizeName(name) {
        return name.replace(/\s+/g, '_');
    }
    generateDynamicFields(fields) {
        //console.log(fields);
        fields.forEach(field => {
            const sanitizedFieldName = this.sanitizeName(field.name);
            const validators = [];
            if (field.isMandatory) {
                validators.push(Validators.required);
            }
            if (field.name === 'Email of Visitor') {
                validators.push(Validators.email);
                validators.push(Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'));
            }
            if (field.name === 'First Name of Visitor' || field.name === 'Last Name of Visitor') {
                validators.push(Validators.pattern('^[a-zA-Z]+$'));
            }
            this.visitorInformationForm.addControl(sanitizedFieldName, this.formBuilder.control('', validators));
        });
        //console.log(this.visitorInformationForm.controls);
    }
    getPlaceholder(fieldName) {
        return this.placeholders[fieldName] || 'Enter Details';
    }
    getAllDepartments() {
        const getDepartments$ = this.amenityBookingService.getAllDepartmentList(this.currentZone, this.gecId);
        this.loaderService
            .showLoaderUntilCompleted(getDepartments$)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
            let deptListVal = res["response"];
            this.departmentListVal = deptListVal.filter((dept) => dept.status === true && dept.name != '');
            //console.log(this.departmentListVal);
        });
    }
    onBlur(field) {
        let sanitizedFieldName = this.sanitizeName(field);
        let getFieldValue = this.visitorInformationForm.get(sanitizedFieldName).value;
        if (typeof getFieldValue === 'string') {
            this.visitorInformationForm.controls[sanitizedFieldName].setValue(getFieldValue.trim());
        }
    }
    onFormSubmit() {
        if (!this.validSelection) {
            this.toastrService.error('Please select a valid Host');
            return;
        }
        if (this.visitorInformationForm.controls[this.sanitizeName('First Name of Visitor')].value.trim() == '' || this.visitorInformationForm.controls[this.sanitizeName('Last Name of Visitor')].value.trim() == '') {
            this.toastrService.error('Name cannot be empty');
            return;
        }
        if (this.visitorInformationForm.valid) {
            this.hostId = this.searchedUserId;
            this.visitorFormValues = {};
            let visitorDepartmentInfo = {};
            this.visitorParams.forEach(field => {
                let sanitizedFieldName = this.sanitizeName(field.name);
                let getFormControlName = this.visitorInformationForm.get(sanitizedFieldName).value;
                if (field.name == 'Tag to Department' && getFormControlName) {
                    visitorDepartmentInfo[field.id] = String(getFormControlName);
                    this.visitorDepartment = JSON.stringify(visitorDepartmentInfo);
                    this.amenityBookingService.setVisitorDepartment(this.visitorDepartment);
                }
                else if (field.name == 'Tag to Department' && !getFormControlName) {
                    this.visitorDepartment = '';
                    this.amenityBookingService.setVisitorDepartment(this.visitorDepartment);
                }
                this.visitorFormValues[field.id] = String(getFormControlName);
            });
            let additionalParameters = JSON.stringify(this.visitorFormValues);
            this.visitorDataInfo = {
                additionalParameterJson: additionalParameters,
                hostId: this.hostId
            };
            // console.log(this.visitorDataInfo);
            if (this.visitorInformationForm.get(this.sanitizeName('Email of Visitor')).value == '') {
                this.openAlert(this.visitorDataInfo);
            }
            else {
                this.proceesVisitorInfo(this.visitorDataInfo);
            }
        }
    }
    updateManageBookingForFlexible(value) {
        console.log(value);
        this.manageBookingForFlexible = value;
        let visitorFlags = {
            visitorBookingEnabled: this.visitorBookingEnabled,
            manageBookingFOrP: this.manageBookingFOrP,
            manageBookingForFlexible: this.manageBookingForFlexible
        };
        this.amenityBookingService.setVisitorInfoFlags(visitorFlags);
    }
    changeBookingFor() {
        // console.log(this.manageBookingFOrP);
    }
    searchUser() {
        console.log('Search users: ', this.searchOption);
    }
    bulkUpload() {
        console.log('Bulk upload');
    }
    closePopup() {
        this.data["closePopup"] = true;
        this.dialogRef.close(this.data);
    }
    closeVisitorManagePopup() {
        this.data["closePopup"] = true;
        this.dialogRef.close(this.data);
    }
    closeUploadFixesPopup() {
        let templateData = {
            "closePopup": true,
            "templateData": this.templateData,
            "incorrectData": this.incorrectData,
            "uploadedFile": this.fileToUpload
        };
        this.dialogRef.close(templateData);
    }
    proceed() {
        this.data["closePopup"] = false;
        this.data["userData"] = this.userData;
        this.data['manageBookingFOrP'] = this.manageBookingFOrP;
        this.dialogRef.close(this.data);
    }
    proceedVisitor() {
        this.data["closePopup"] = false;
        this.data['manageBookingForFlexible'] = this.manageBookingForFlexible;
        this.data['visitorBookingEnabled'] = this.visitorBookingEnabled;
        //console.log(this.data);
        this.dialogRef.close(this.data);
    }
    proceesVisitorInfo(data) {
        //console.log(data);
        this.amenityBookingService.setonBehalfofVisitors(data);
        this.route.navigate(['/layout/amenity-booking/map-view'], { skipLocationChange: true, });
        this.dialogRef.close();
    }
    // getToolTipData(hoverData) {
    //   return hoverData;
    // }
    getToolTipData(data) {
        return data;
    }
    closeConfirmPopup() {
        this.dialogRef.close();
    }
    proceedToUpload() {
        let data = {
            "closePopup": true,
            "templateData": this.templateData,
            "correctData": this.correctData,
            "incorrectData": this.incorrectData,
            "uploadedFile": this.fileToUpload,
            "uploadCorrectEntries": true,
            "proceedToUpload": true
        };
        this.dialogRef.close(data);
    }
    proceedDateSelection() {
        let startTime = this.startDate['_d'] ? this.startDate['_d'] : this.startDate;
        let data = {
            "closePopup": false,
            "startDate": this.commonService.getTimeStamp(startTime.getTime(), "start")
        };
        this.dialogRef.close(data);
    }
    doneViewPermanentBooking() {
        let data = {
            "closePopup": false
        };
        this.dialogRef.close(data);
    }
    downloadTemplate() {
        this.templateDownloaded = true;
        const downloadSub$ = this.amenityBookingService.downloadBulkUploadTemplate(this.currentZone).subscribe((res) => {
            let downloadRes = res["response"];
            // console.log(downloadRes)
            this.commonService.downloadCSV(downloadRes);
        }, (err) => {
            this.commonService.downloadCSV(err.error.text);
        });
        this.subscription.add(downloadSub$);
    }
    uploadCSV(files) {
        this.templateData = [];
        this.correctData = [];
        this.incorrectData = [];
        this.fileToUpload = files.item(0);
        this.labelImport.nativeElement.innerText = Array.from(files)
            .map(f => f.name)
            .join(", ");
        // this.uploadedFileList = Array.from(files);
    }
    // deleteArr(index){
    //   this.fileToUpload = null;
    //   this.labelImport.nativeElement.value = "";
    //   this.labelImport.nativeElement.innerText = '';
    //   // this.uploadedFileList.splice(index, 1)
    // }
    proceedUploadFile() {
        // console.log(this.errorRecordsCount)
        if (this.fileToUpload) {
            const allocation$ = this.amenityBookingService.validatePermanentBulkTemplate(this.currentZone, this.gecId, this.fileToUpload);
            this.loaderService.showLoaderUntilCompleted(allocation$)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((data) => {
                // console.log(data["response"])
                if (data["response"]["value"].length > 0) {
                    data["response"]["value"].map(booking => {
                        let { slNo, empEmail, entityName, buildingName, floorName, startTime } = booking.allocationCsvDTO;
                        let bookingData = {
                            slNo,
                            empEmail,
                            entityName,
                            building: buildingName,
                            floor: floorName,
                            startDate: startTime,
                            errorFields: booking.columns,
                            reason: booking.reason,
                        };
                        if (booking.columns.length) {
                            this.incorrectData.push(bookingData);
                        }
                        else {
                            this.correctData.push(bookingData);
                        }
                        this.templateData.push(bookingData);
                    });
                    //this.toastr.error("Something went wrong", "");
                }
                // console.log(this.errorRecordsCount)
                let templateData = {
                    "closePopup": true,
                    "templateData": this.templateData,
                    "correctData": this.correctData,
                    "incorrectData": this.incorrectData,
                    "uploadedFile": this.fileToUpload,
                    "proceedToUpload": true
                };
                this.dialogRef.close(templateData);
                // console.log(templateData)
            }, (error) => {
                this.commonService.openSnackBar("Something went wrong!! Please make sure file is in correct format", "");
            });
        }
        else {
            this.toastrService.error('No file selected!');
        }
    }
    getCategoryName(name) {
        if (!name || name.includes('Default-'))
            return 'NA';
        return name;
    }
    downloadCurrentFile() {
        // console.log(this.templateData)
        let bookingsListToDownload = [];
        let obj = {};
        obj.slNo = 'Sl No';
        obj.empEmail = 'Employee Email';
        obj.entityName = 'Entity Name';
        obj.floor = 'Floor Name';
        obj.building = 'Building Name';
        obj.startDate = 'Start Date(DD-MM-YYYY)';
        obj.errorFields = 'Error Fields';
        obj.reason = 'Reason';
        bookingsListToDownload = this.templateData.map(bookingData => {
            // console.log(bookingData)
            let { slNo, empEmail, entityName, floor, building, startDate, errorFields, reason } = bookingData;
            return {
                slNo,
                empEmail,
                entityName,
                floor,
                building,
                startDate,
                errorFields: errorFields.toString(),
                reason
            };
        });
        bookingsListToDownload.unshift(obj);
        new Angular5Csv(bookingsListToDownload, 'permanent_booking_data');
    }
    openAlert(visitorData) {
        this.data["closePopup"] = false;
        this.data["visitorInfo"] = visitorData;
        // console.log(this.data);
        this.pageType = "emailAlert";
    }
    backToVisitorPopup() {
        this.pageType = "bookNowManageVisitor";
    }
    sendVisitorInfoWithoutEmail() {
        //console.log(this.data["visitorInfo"]);
        const visitorData = this.data["visitorInfo"];
        this.amenityBookingService.setonBehalfofVisitors(visitorData);
        this.route.navigate(['/layout/amenity-booking/map-view'], { skipLocationChange: true, });
        this.dialogRef.close();
    }
    goBack() {
        this.pageType = "groupBookingDetails";
    }
    convertTo12HrFormat(timeStr) {
        let time = parseInt(timeStr, 10);
        let hours = Math.floor(time / 100);
        let minutes = time % 100;
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + period;
    }
    objectEntries(obj) {
        return Object.entries(obj || {});
    }
    objectKeys(obj) {
        return Object.keys(obj || {});
    }
    formatDate(timestamp) {
        const parseDate = Number(timestamp);
        const newDate = new Date(parseDate);
        const day = newDate.getDate();
        const month = newDate.toLocaleString('default', { month: 'short' });
        const year = newDate.getFullYear();
        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    }
    wayFinding(element) {
        let Obj = {
            user: {
                'userId': element['userDTO']['userId']
            },
            zoneLocationDto: {
                'zoneId': element['deskDetailsWithZoneDto']['floorId']
            },
            entityInfo: {
                'id': element['deskDetailsWithZoneDto']['deskId']
            },
            requestDetails: {
                'recurringStartTime': element['recurringStartTime'] === 0 ? "000" : element['recurringStartTime'].toString(),
                'recurringEndTime': element['recurringEndTime'].toString()
            },
        };
        let newElement = Object.assign({}, element, Obj);
        this.wayFindingEntity = newElement;
        this.globalId = this.data.gecId;
        this.selectedDate = this.data.selectedDate;
        this.amenityBookingService.setMapviewInCalflexiPermanent(true);
        this.pageType = 'showWayFinding';
        this.dialogRef.updateSize('60%', '85%');
        // this.displayTab.emit('F');
    }
    backToDeskDetails() {
        this.pageType = "showDeskDetails";
        this.dialogRef.updateSize('40%', 'auto');
    }
    closeGridFilter() {
        this.dialogRef.close({ "closeGridViewFilter": true });
    }
    getSetUpData() {
        this.amenityBookingService
            .getAllAddons(this.currentZone, this.data['globalId'])
            .subscribe((res) => {
            let allReq = res["response"];
            if (allReq.length > 0) {
                this.addonsList = allReq.filter((res) => res.specialRequest == false).map(item => ({
                    id: item.id,
                    name: item.name,
                    selected: false
                }));
                this.specialRequestsList = allReq.filter((res) => res.specialRequest == true).map(item => ({
                    id: item.id,
                    name: item.name,
                    selected: false
                }));
                ;
            }
        }, (err) => {
            this.toastrService.error(err.message);
        });
    }
    updateList(e, ind, listType) {
        let selectedList;
        let sourceList;
        if (listType === 'specialRequest') {
            selectedList = this.selectedRequests;
            sourceList = this.specialRequestsList;
        }
        else if (listType === 'capacity') {
            selectedList = this.selectedCapacity;
            sourceList = this.capacityList;
        }
        else if (listType === 'addOn') {
            selectedList = this.selectedAddOns;
            sourceList = this.addonsList;
        }
        if (e.checked) {
            selectedList.push(sourceList[ind]);
        }
        else {
            const itemIndex = selectedList.findIndex(item => item.id === sourceList[ind].id);
            if (itemIndex > -1) {
                selectedList.splice(itemIndex, 1);
            }
        }
    }
    updateEndTimeList() {
        console.log("Selected Time", this.timeList.selectedStartTime);
        if (this.timeList.selectedStartTime === '11:00 PM') {
            this.timeList.endTimeList = this.data['timeSlots'].filter(item => item.displayValue === '12:00 AM');
            this.timeList.selectedEndTime = this.timeList.endTimeList[0].displayValue;
        }
        else {
            const selectedIndex = this.timeList.startTimeList.findIndex(item => item.displayValue === this.timeList.selectedStartTime);
            this.timeList.endTimeList = this.data['timeSlots'].slice(selectedIndex + 2);
            this.timeList.selectedEndTime = this.timeList.endTimeList[0].displayValue;
        }
    }
    convertTo24HrFormat(time) {
        let [hours, minutesPeriod] = time.split(':');
        let minutes = minutesPeriod.slice(0, 2);
        let period = minutesPeriod.slice(2).trim();
        hours = parseInt(hours);
        if (period.toUpperCase() === 'PM' && hours !== 12) {
            hours = hours + 12;
        }
        else if (period.toUpperCase() === 'AM' && hours === 12) {
            hours = 0;
        }
        return parseInt(`${hours.toString().padStart(2, '0')}${minutes}`);
    }
    saveGridFilter() {
        let data = {
            startTime: this.convertTo24HrFormat(this.timeList.selectedStartTime),
            endTime: this.convertTo24HrFormat(this.timeList.selectedEndTime),
            capacity: this.selectedCapacity.map(item => item.value),
            addOns: this.selectedAddOns.map(item => item.id),
            specialRequests: this.selectedRequests.map(item => item.id)
        };
        this.dialogRef.close({ data, "filterApplied": true });
    }
    getAllAmenities() {
        //let filterData = this.amenityService.myAmenityObj;
        let filterData = this.amenityBookingService.getmyAmenityObj();
        if (filterData.length > 0) {
            let data = filterData[0];
            let configVal = JSON.parse(data["config"]);
            console.log("Config Val", configVal);
            if (!!configVal) {
                this.allowParticipants =
                    configVal["spaceManagementConfig"]["amenityBookingType"] ==
                        "SINGLE_USER_PARTICIPANT"
                        ? true
                        : false;
            }
            this.externalBooking = configVal["features"].filter((f) => f.key == "EXTERNAL_BOOKINGS")[0].isEnabled;
            let allIntegrationsArray = data["integrationsMappingStatusDto"].map((i) => i.integrationsDto);
            this.videoConfLinkList = allIntegrationsArray.filter((i) => i.vcLink);
            if (this.videoConfLinkList.length > 0) {
                this.video_conf_link = this.videoConfLinkList[0].integrationId;
            }
            else {
                this.enableVCLink = false;
            }
            console.log("Video Conf Link", this.video_conf_link);
            console.log("allow Participants", this.allowParticipants);
            console.log("Video Conf Link List", this.videoConfLinkList);
        }
    }
    getSpecialRequests() {
        if (this.gridViewBookingEntityInfo['amenities'] && this.gridViewBookingEntityInfo['amenities'].length > 0) {
            this.gridViewBookingEntityInfo['amenities'].forEach(element => {
                if (element['specialRequest']) {
                    this.specialReqList.push(element);
                }
            });
        }
    }
    closeGridBooking() {
        this.dialogRef.close({ "closeGridViewBooking": true });
    }
    addParticipants() {
        this.pageType = "add_participants";
        this.dialogRef.updateSize('40%', 'auto');
    }
    addSendConfirmationMailtTo() {
        this.isErrorInput = false;
        var term = this.selectedEmail;
        var re = new RegExp("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$");
        if (re.test(term)) {
            if (this.selectedEmail) {
                if (this.sendCnfMailList.indexOf(this.selectedEmail) !== -1) {
                    this.toastrService.error("Participants should be unique");
                }
                else {
                    this.sendCnfMailList.push(this.selectedEmail);
                    //user list in alphabetical order
                    this.sendCnfMailList.sort();
                }
                this.selectedEmail = "";
            }
        }
        else {
            this.selectedEmail = "";
        }
    }
    removeEmail(i) {
        if (i > -1) {
            this.sendCnfMailList.splice(i, 1);
        }
    }
    sendInvite() {
        let reqObj = {};
        let guestsmail = [];
        for (var i = 0; i < this.sendCnfMailList.length; i++) {
            guestsmail.push({
                guestEmail: this.sendCnfMailList[i],
            });
        }
        this.selectedParticipants = guestsmail;
        this.totalParticipantsCount = guestsmail.length;
        reqObj["allParticipants"] = this.sendCnfMailList;
        console.log("Selected Participants", this.selectedParticipants);
        console.log("Total Participants", this.sendCnfMailList);
        this.pageType = "gridViewBooking";
        this.dialogRef.updateSize('45%', 'auto');
    }
    closeParticipantsPopup() {
        this.pageType = "gridViewBooking";
        this.dialogRef.updateSize('45%', 'auto');
    }
    getAmenities(amenity) {
        amenity.checked = !amenity.checked;
        const index = this.selectedAmenitiesFiltered.some((val) => val.id === amenity.id);
        if (!index) {
            this.selectedAmenitiesFiltered.push(amenity);
        }
        else {
            let checkDel = this.selectedAmenitiesFiltered.some((val) => val.id === amenity.id);
            if (checkDel) {
                this.selectedAmenitiesFiltered = this.selectedAmenitiesFiltered.filter(function (obj) {
                    return obj.id !== amenity.id;
                });
            }
        }
        console.log("Selected Amenities", this.selectedAmenitiesFiltered);
        this.selectedAmenitiesFiltered.forEach(element => {
            let data = {
                id: element.id,
                name: element.name,
                specialRequest: element.specialRequest
            };
            this.specialRequestSelected.push(data);
        });
    }
    loadAllInfo() {
        let loadAPis = this.amenityBookingService.getConfigSettings(this.currentZone, this.data['globalId']);
        this.loaderService
            .showLoaderUntilCompleted(loadAPis)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
            this.mainSettingsConfig = res['response'];
            this.timeConfig = this.mainSettingsConfig;
            this.settingsConfig = this.timeConfig;
            this.checkAllSettings();
        });
    }
    checkAllSettings() {
        //True for 12hr format & 24hr format pass false
        if (!!this.settingsConfig) {
            if (this.settingsConfig['clockType'] == 'TWENTY_FOUR_HOUR') {
                this.time12hrFormat = false;
            }
            else {
                this.time12hrFormat = true;
            }
        }
        let startTime;
        let endTime;
        let startHours = "00";
        let startMinutes = "00";
        let endHours = "23";
        let endMinutes = "59";
        console.log("Start Time", this.selectedTime);
        console.log("End Time", this.selectedEndTime);
        let [hours, minutes, period] = this.selectedTime.split(/:| /);
        hours = period === 'PM' ? (parseInt(hours) < 12 ? parseInt(hours) + 12 : parseInt(hours)) : (parseInt(hours) === 12 ? '00' : hours);
        startHours = hours.toString().padStart(2, '0');
        startMinutes = minutes.toString().padStart(2, '0');
        if (this.selectedEndTime !== "") {
            let [hr, endMin, endPeriod] = this.selectedEndTime.split(/:| /);
            hr = endPeriod === 'PM' ? (parseInt(hr) < 12 ? parseInt(hr) + 12 : parseInt(hr)) : (parseInt(hr) === 12 ? '00' : hr);
            endHours = hr.toString().padStart(2, '0');
            endMinutes = endMin.toString().padStart(2, '0');
        }
        console.log("Start Hours", startHours);
        console.log("Start Minutes", startMinutes);
        console.log("End Hours", endHours);
        console.log("End Minutes", endMinutes);
        let lockStartHours;
        let lockStartMinutes;
        let startTimeSlots;
        let endTimeSlots;
        let inBetween;
        if (this.settingsConfig['lockTimeEnabled']) {
            let selectedSlot = this.convertTo24HrFormat(this.selectedTime);
            lockStartHours = this.settingsConfig['lockedStartTimeTiming']['hourOfDay'].toString().padStart(2, '0');
            lockStartMinutes = this.settingsConfig['lockedStartTimeTiming']['minOfDay'].toString().padStart(2, '0');
            endHours = this.settingsConfig['lockedEndTimeTiming']['hourOfDay'].toString().padStart(2, '0');
            endMinutes = this.settingsConfig['lockedEndTimeTiming']['minOfDay'].toString().padStart(2, '0');
            if (selectedSlot) {
                let startInMinutes = this.settingsConfig['lockedStartTimeTiming']['hourOfDay'] * 60 + this.settingsConfig['lockedStartTimeTiming']['minOfDay'];
                let endInMinutes = this.settingsConfig['lockedEndTimeTiming']['hourOfDay'] * 60 + this.settingsConfig['lockedEndTimeTiming']['minOfDay'];
                console.log("Selected Slot", selectedSlot);
                let selectedInMinutes = Math.floor(selectedSlot / 100) * 60 + selectedSlot % 100;
                ;
                inBetween = selectedInMinutes >= startInMinutes && selectedInMinutes <= endInMinutes;
            }
            console.log("end Time  in 12", this.convertTo12HrFormat(endHours + ":" + endMinutes + ":00"));
            if (inBetween && this.selectedEndTime !== "") {
                console.log("Selected End Time", this.selectedEndTime);
                let [hr, endMin, endPeriod] = this.selectedEndTime.split(/:| /);
                hr = endPeriod === 'PM' ? (parseInt(hr) < 12 ? parseInt(hr) + 12 : parseInt(hr)) : (parseInt(hr) === 12 ? '00' : hr);
                endHours = hr.toString().padStart(2, '0');
                endMinutes = endMin.toString().padStart(2, '0');
            }
            if (lockStartHours > startHours) {
                startHours = lockStartHours;
                startMinutes = lockStartMinutes;
            }
            else if (lockStartHours == startHours && lockStartMinutes > startMinutes) {
                startMinutes = lockStartMinutes;
            }
            else if (startHours > endHours || (startHours == endHours && startMinutes > endMinutes)) {
                this.disableTimeSlots = true;
                console.log(" From the setting");
                startTimeSlots = this.generateTimeSlots('00:00', '01:00:00', 15);
                endTimeSlots = this.generateTimeSlots('23:00:00', '23:59:00', 15);
                this.gridViewBookingTimeList = {
                    startTimeList: startTimeSlots,
                    endTimeList: endTimeSlots,
                    selectedStartTime: startTimeSlots[0],
                    selectedEndTime: endTimeSlots[endTimeSlots.length - 1]
                };
                //  this.loadWayFinding();
                return;
            }
        }
        startTime = startHours + ":" + startMinutes + ":00";
        endTime = endHours + ":" + endMinutes + ":00";
        console.log("Start Time ", startTime);
        console.log("End Time ", endTime);
        this.startTime = startTime;
        this.endTime = endTime;
        startTimeSlots = this.generateTimeSlots(startTime, endTime, 15);
        console.log("Stat Time Slot", startTimeSlots);
        startTimeSlots.pop();
        // if(startTimeSlots && startTimeSlots.length==0){
        //   this.disableTimeSlots = true;
        //   console.log(" From the not setting")
        //   startTimeSlots = this.generateTimeSlots('00:00','01:00:00',15);
        //   endTimeSlots = this.generateTimeSlots('23:00:00','23:59:00',15);
        //   this.gridViewBookingTimeList = {
        //     startTimeList: startTimeSlots,
        //     endTimeList: endTimeSlots,
        //     selectedStartTime: startTimeSlots[0],
        //     selectedEndTime: endTimeSlots[endTimeSlots.length-1]
        //   }
        //   this.loadWayFinding()
        //   return;
        // } else if(startTimeSlots.length>0 && startTimeSlots[0]['disabled']){
        //   this.disableTimeSlots = true;
        // }
        let defaultStartTimeSelection = startTimeSlots[0];
        // if (this.settingsConfig['timeDisplayDefaultTimingEnabled'])
        //   defaultStartTimeSelection = this.displayStartTime(startTimeSlots);
        let defaultEndTimeSelection;
        startTime = defaultStartTimeSelection['hour'] + ":" + defaultStartTimeSelection['minute'] + ":00";
        endTimeSlots = this.getEndTimeSlots(startTime, endTime);
        defaultEndTimeSelection = endTimeSlots[endTimeSlots.length - 1];
        if (this.settingsConfig['timeDisplayDefaultTimingEnabled'])
            defaultEndTimeSelection = this.displayEndTime(endTimeSlots);
        // if(this.disableTimeSlots){
        //   startTimeSlots = this.generateTimeSlots('00:00','01:00:00',15);
        //   endTimeSlots = this.generateTimeSlots('23:00:00','23:59:00',15);
        //   this.gridViewBookingTimeList = {
        //     startTimeList: startTimeSlots,
        //     endTimeList: endTimeSlots,
        //     selectedStartTime: startTimeSlots[0],
        //     selectedEndTime: endTimeSlots[endTimeSlots.length-1]
        //   }
        //   this.loadWayFinding()
        // } else {
        // console.log("Else Condition")
        // startTimeSlots = startTimeSlots.filter(v => !v['disabled']);
        this.gridViewBookingTimeList = {
            startTimeList: startTimeSlots,
            endTimeList: endTimeSlots,
            selectedStartTime: defaultStartTimeSelection,
            selectedEndTime: defaultEndTimeSelection
        };
        // this.loadWayFinding()
        // }
    }
    // Checking display Start time settings
    displayStartTime(startTimeSlots) {
        console.log("Start Time Slots display", startTimeSlots);
        let defaultStartTimeSelection = startTimeSlots[0];
        if (this.settingsConfig['timeDisplayDefaultTimingEnabled']) {
            let sHours = this.settingsConfig['startTimeDisplayDefaultTiming']['hourOfDay'] * 60;
            let sMinutes = this.settingsConfig['startTimeDisplayDefaultTiming']['minOfDay'];
            let startMin = sHours + sMinutes;
            let findStartTimeSelection = startTimeSlots.filter(res => res.id == startMin);
            if (findStartTimeSelection.length > 0) {
                defaultStartTimeSelection = findStartTimeSelection[0];
            }
        }
        return defaultStartTimeSelection;
    }
    // Check display End time settings
    displayEndTime(endTimeSlots) {
        let defaultEndTimeSelection = endTimeSlots[endTimeSlots.length - 1];
        if (this.settingsConfig['timeDisplayDefaultTimingEnabled']) {
            let eHours = this.settingsConfig['endTimeDisplayDefaultTiming']['hourOfDay'] * 60;
            let eMinutes = this.settingsConfig['endTimeDisplayDefaultTiming']['minOfDay'];
            let endMin = eHours + eMinutes;
            let findEndTimeSelection = endTimeSlots.filter(res => res.id == endMin);
            if (findEndTimeSelection.length > 0) {
                defaultEndTimeSelection = findEndTimeSelection[0];
            }
            else {
                let timeslot = this.getDefaultEndTimeSlot(eHours, eMinutes, endMin);
                let defaultEndTimeIndex = 0;
                for (let i = 0; i < endTimeSlots.length; i++) {
                    if (timeslot.id < endTimeSlots[i].id) {
                        break;
                    }
                    defaultEndTimeIndex++;
                }
                let startTime = new Date("01/01/2000 " + this.startTime);
                let startHours = startTime.getHours();
                let startMins = startTime.getMinutes();
                let startMin = (startHours * 60) + startMins;
                let selectedTime = this.gridViewBookingTimeList['selectedStartTime']['hour'] + ":" + this.gridViewBookingTimeList['selectedStartTime']['minute'] + ":00";
                let selectedStartTime = new Date("01/01/2000 " + selectedTime);
                let selectedStartHours;
                let selectedStartMins;
                let selectedStartMin;
                if (selectedTime !== '') {
                    selectedStartHours = selectedStartTime.getHours();
                    selectedStartMins = selectedStartTime.getMinutes();
                    selectedStartMin = (selectedStartHours * 60) + selectedStartMins;
                }
                if (selectedStartMin && selectedStartMin >= timeslot.id) {
                    defaultEndTimeSelection = endTimeSlots[endTimeSlots.length - 1];
                }
                else if (startMin < timeslot.id) {
                    if (endTimeSlots.length > defaultEndTimeIndex) {
                        endTimeSlots.splice(defaultEndTimeIndex, 0, timeslot);
                        defaultEndTimeSelection = endTimeSlots[defaultEndTimeIndex];
                    }
                }
            }
        }
        return defaultEndTimeSelection;
    }
    // Generate time slots for start and end time
    generateTimeSlots(start, end, interval) {
        let timeSlots = [];
        let startTime = new Date("01/01/2000 " + start);
        let endTime = new Date("01/01/2000 " + end);
        let addLastSlot = 0;
        while (startTime <= endTime) {
            let hour = startTime.getHours();
            let minute = startTime.getMinutes();
            let hourwith24Id = (hour * 60) + minute;
            let hoursIn24Format = hour.toString().padStart(2, '0');
            ;
            let minutesIn24Format = minute.toString().padStart(2, '0');
            ;
            let ampm = "";
            if (this.time12hrFormat) {
                ampm = hour >= 12 ? 'PM' : 'AM';
                hour = hour % 12;
                hour = hour ? hour : 12; // the hour '0' should be '12' in 12-hour format
            }
            let generateHour = hour.toString().padStart(2, '0');
            let generateMinute = minute.toString().padStart(2, '0');
            let timeSlot = {
                id: hourwith24Id,
                value: generateHour + ":" + generateMinute + " " + ampm,
                hour: hoursIn24Format,
                minute: minutesIn24Format,
                disabled: false
            };
            if (this.time12hrFormat) {
                timeSlot["ampm"] = ampm;
            }
            if (!timeSlots[timeSlots.length - 1] || timeSlots[timeSlots.length - 1].id != timeSlot.id)
                timeSlots.push(timeSlot);
            startTime.setMinutes(startTime.getMinutes() + interval);
            if (startTime > endTime && addLastSlot == 0) {
                addLastSlot = 1;
                startTime = new Date(endTime);
            }
        }
        return timeSlots;
    }
    //For generating endTime slots
    getEndTimeSlots(startTime, endTime) {
        let timediff = this.settingsConfig['bookingSlotTiming']['hourOfDay'] * 60 + this.settingsConfig['bookingSlotTiming']['minOfDay'];
        let updatedEndTime;
        if (this.settingsConfig['limitBookingDuration']) {
            let hour = this.settingsConfig['limitBookingDurationTiming']['hourOfDay'];
            let min = this.settingsConfig['limitBookingDurationTiming']['minOfDay'];
            let timeConv = hour * 60 + min;
            let startHoursMins = new Date("01/01/2000 " + startTime);
            let endHoursMins = new Date("01/01/2000 " + endTime);
            let checkStartTime = startHoursMins.getHours() * 60 + startHoursMins.getMinutes();
            let checkEndTime = endHoursMins.getHours() * 60 + endHoursMins.getMinutes();
            if (checkStartTime + timeConv < checkEndTime) {
                updatedEndTime = checkStartTime + timeConv;
                var hours = Math.floor(updatedEndTime / 60);
                var minutes = updatedEndTime % 60;
                endTime = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":00";
            }
        }
        let endTimeSlots = this.generateTimeSlots(startTime, endTime, timediff);
        endTimeSlots.splice(0, 1);
        return endTimeSlots;
    }
    getDefaultEndTimeSlot(hours, minutes, endMin) {
        hours = hours / 60;
        let hoursIn24Format = hours.toString().padStart(2, '0');
        ;
        let minutesIn24Format = minutes.toString().padStart(2, '0');
        ;
        let ampm = "";
        if (this.time12hrFormat) {
            ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12' in 12-hour format
        }
        let generateHour = hours.toString().padStart(2, '0');
        let generateMinute = minutes.toString().padStart(2, '0');
        let timeSlot = {
            id: endMin,
            value: generateHour + ":" + generateMinute + " " + ampm,
            hour: hoursIn24Format,
            minute: minutesIn24Format,
            disabled: false
        };
        if (this.time12hrFormat) {
            timeSlot["ampm"] = ampm;
        }
        return timeSlot;
    }
    loadWayFinding() {
        let date = this.data['currentDate'];
        let timeZone = localStorage.getItem("currentTimeZone");
        let userId = localStorage.getItem("ssadmin_userId");
        let startDateTime = this.commonService.getTimeStampTimeZone(date, timeZone, "start");
        let endDateTime = this.commonService.getTimeStampTimeZone(date, timeZone, "end");
        let startTime = startDateTime;
        let endTime = endDateTime;
        let recuuringStartTime = this.gridViewBookingTimeList.selectedStartTime['hour'] + this.gridViewBookingTimeList.selectedStartTime['minute'];
        let recuuringEndTime = this.gridViewBookingTimeList.selectedEndTime['hour'] + this.gridViewBookingTimeList.selectedEndTime['minute'];
        ;
        let requestObject = {
            "zoneId": this.floorId,
            "availabilityRequestDto": []
        };
        requestObject.availabilityRequestDto.push({
            "request": {
                "requestDetails": {
                    "userId": userId,
                    "startTime": startTime,
                    "endTime": endTime,
                    "recurringStartTime": recuuringStartTime,
                    "recurringEndTime": recuuringEndTime
                }
            },
            "zoneId": this.floorId
        });
        let getAvailabilityMapDataBooking$ = this.amenityBookingService.getAvailabilityMapDataBooking(requestObject, this.currentZone, this.data['globalId']);
        this.loaderService.showLoaderUntilCompleted(getAvailabilityMapDataBooking$).pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
            let availableEntities = response['value']['availableEntities'];
            let findRoomIndex = availableEntities.findIndex((room) => room.id == this.data['meetingRoomDetails']['entityInfo']['id']);
            if (findRoomIndex === -1) {
                this.toastrService.error(`${this.data['meetingRoomDetails']['room']} is not available for the selected time slot`);
                this.timeSlotError = true;
            }
            else {
                this.timeSlotError = false;
            }
        }, (err) => {
            this.toastrService.error(err.message);
        });
    }
    saveGridBooking() {
        if (this.allowParticipants) {
            if (!this.titleName) {
                this.toastrService.error("please enter the title for booking");
                return;
            }
        }
        if (!this.enableVCLink)
            this.video_conf_link = undefined;
        let date = this.data['currentDate'];
        let timeZone = localStorage.getItem("currentTimeZone");
        let userId = localStorage.getItem("ssadmin_userId");
        let startDateTime = this.commonService.getTimeStampTimeZone(date, timeZone, "start");
        let endDateTime = this.commonService.getTimeStampTimeZone(date, timeZone, "end");
        let startTime = startDateTime;
        let endTime = endDateTime;
        let recuuringStartTime = this.gridViewBookingTimeList.selectedStartTime['hour'] + this.gridViewBookingTimeList.selectedStartTime['minute'];
        let recuuringEndTime = this.gridViewBookingTimeList.selectedEndTime['hour'] + this.gridViewBookingTimeList.selectedEndTime['minute'];
        ;
        let seatInfo = [];
        let specialRequest = [];
        if (this.gridViewBookingEntityInfo['amenities'] && this.gridViewBookingEntityInfo['amenities'].length > 0) {
            this.gridViewBookingEntityInfo['amenities'].forEach(element => {
                let data = {
                    id: element['id'],
                    name: element['name'],
                    specialRequest: element['specialRequest']
                };
                specialRequest.push(data);
            });
        }
        let entityInfo = {
            id: this.gridViewBookingEntityInfo['id'],
            displayName: this.gridViewBookingEntityInfo['displayName'],
            specialRequest: specialRequest
        };
        let requestDetails = {
            entityInfos: [entityInfo],
            startTime: startTime,
            endTime: endTime,
            demandType: "USER_DEPARTMENT",
            demandId: 1,
            userId: parseInt(userId),
            recurringStartTime: recuuringStartTime,
            recurringEndTime: recuuringEndTime,
            rosterId: null,
            floorId: null,
            floorName: null,
            buildingId: this.data['meetingRoomDetails']['entityInfo']['zoneLocationDto']['building']['id'],
            buildingName: this.gridViewBookingBuilding,
            zoneId: this.floorId,
            count: null,
            featureKey: null,
            workingDays: null,
            specialRequests: this.specialRequestSelected,
        };
        let seatInfoObj = {
            requestDetails: requestDetails,
            isWFHRequest: true,
            participants: this.selectedParticipants,
            title: this.titleName,
        };
        seatInfo.push(seatInfoObj);
        if (!!this.video_conf_link && this.enableVCLink) {
            seatInfo[seatInfo.length - 1]["integrationId"] = this.video_conf_link;
            seatInfo[seatInfo.length - 1]["meetingLinkProvider"] = "";
        }
        console.log("Save Booking", seatInfo);
        this.loaderService.loadingOn();
        this.amenityBookingService.saveAmenityMultiBooking(seatInfo, this.currentZone, this.data['globalId'], userId ? userId : null).pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (res["response"]["validRequests"].length > 0) {
                this.dialogRef.close({ BookingSaved: true });
            }
        }), (err) => {
            this.loaderService.loadingOff();
            this.toastrService.error(err.error.message);
        });
    }
    confirmNo() {
        this.dialogRef.close({ notConfirmed: true });
    }
    confirmYes() {
        this.dialogRef.close({ confirmed: true });
    }
    gridViewAlertConfirm() {
        this.dialogRef.close({ confirmed: true });
    }
    getDeptName(value) {
        let visitorInfo = this.data['visitorData'];
        this.gecId = visitorInfo[0]['gecId'];
        let val = parseInt(value);
        if (val) {
            if (this.departmentListVal && this.departmentListVal.length > 0) {
                let dept = this.departmentListVal.filter(item => item.id == val);
                return dept[0].name;
            }
        }
        return "";
    }
}
