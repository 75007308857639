import { Injectable, Inject, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, throwError, Subject, combineLatest, EMPTY, of } from "rxjs";
import { catchError, shareReplay, timeout, tap, filter, switchMap, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";
import { CommonService } from 'commonService';
export interface User {
    value: number | string,
    display: string,
    imgUrl?:string,
    legend:string
}
@Injectable()
export class MapActionIconsService implements OnInit {
    moduleId;
    BASEURL;
    EMSURL;
    upload_Url;
    currentZone;
    searchTermSubject$ = new Subject<any>();
    searchTermAction$ = this.searchTermSubject$.asObservable();
    emsUserurl;
    // return this.http.get(this.BASE_EMS_URL+endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    emsUserList$ = combineLatest(this.searchTermAction$).pipe(
        filter(([searchParams]) => Boolean(searchParams)),
        switchMap(([searchParams]) =>  this.http.get(`${this.emsUserurl}`,{params: new ZoneAndModuleHttpParams(this.currentZone, this.moduleId)})),
        map((res:any) => {
            let user:User[];
            let result = res['response']['content'];
            if(result.length){
                user =  result.map(item => {
                    const fName = (item.firstName.length)? item.firstName.charAt(0): '';
                    const lName = (item.lastName.length)? item.lastName.charAt(0): '';
                    const result = {  value: item.userId, display: item.name, imgUrl: item.imageUrl, legend:  `${fName} ${lName}`};
                    return result;
                })
                return user
            }
            // return EMPTY;
        }),
        catchError(err => throwError(err))
    )
    constructor(
        private http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        private toastrService: ToastrService,
        private moduleidService: ModuleidService,
        private commonService: CommonService,
    ) {
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL;
        this.upload_Url = environment.BASE_UPLOAD_URL;
        this.moduleId = this.moduleidService.getModueId();
    }

    ngOnInit() {
        this.currentZone = this.commonService.currentZone
        .pipe(
            filter(zone => Boolean(zone))
        ).subscribe(res => {
            this.currentZone = res;
        })
    }

    public requestAutocompleteItems(text){
        this.emsUserurl=`${this.EMSURL}ems/user/all?sortBy=createdAt&searchColumn=firstName&searchValue=${text}&page=${0}&size=${100000}`;
        
        this.searchTermSubject$.next(text);
    }
}