<!-- Add Integration page -->
<div *ngIf="pageType == 'addIntegration_map'">
    <div class="modal-header pl-0">
        <h5>{{ "tagASpace" | translate }}</h5>
        <button
            type="button"
            class="close pr-0"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 mt-1">
        <p class="openSans ml-2 italic">{{ "integrations" | translate }} > {{ "manage" | translate }}</p>
    </div>
    <div class="modal-body width-400 align-left">
        <div class="row">
            <div class="col-6">
                <p class="label1">{{ "selectSpace" | translate }}</p>
                <div class="form-group">
                    <div class="input-group">
                        <mat-form-field appearance="fill">
                            <mat-label *ngIf="!selectedSpace">{{
                                "selectSpace" | translate
                            }}</mat-label>
                            <mat-select
                                [(ngModel)]="selectedSpace"
                                name="space"
                            >
                                <mat-option
                                    *ngFor="let sp of spaces"
                                    [value]="sp.globalCategoryId.id"
                                >
                                    {{ sp.globalCategoryId.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p class="label1">{{ "selectIntegration" | translate }}</p>
                <div class="form-group">
                    <div class="input-group">
                        <mat-form-field appearance="fill">
                            <mat-label *ngIf="!selectedIntegration">{{
                                "selectIntegration"| translate
                            }}</mat-label>
                            <mat-select
                                [(ngModel)]="selectedIntegration"
                                name="integration"
                                [disabled]="!selectedSpace"
                            >
                                <mat-option
                                    *ngFor="let in of integrations"
                                    [value]="in.id"
                                >
                                    <span
                                        *ngIf="
                                            in.integrationName != 'Office 365'
                                        "
                                        >{{ in.integrationName }}</span
                                    >
                                    <span
                                        *ngIf="
                                            in.integrationName == 'Office 365'
                                        "
                                        >Microsoft 365</span
                                    >
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col pr-0">
                <button
                    type="button"
                    class="btn btn-main btnSmall rounded-pill mr-2 font-regular float-right"
                    (click)="next()"
                    [disabled]="!(selectedSpace && selectedIntegration)"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="pageType == 'addIntegration_confirmation'">
    <div class="modal-header pl-0">
        <span class="float-left pr-2" (click)="pageType = 'addIntegration_map'">
            <i
                class="fa fa-chevron-left cursor-pointer small"
                aria-hidden="true"
            ></i>
        </span>
        <h5>{{ selectedSpaceName }}</h5>
        <button
            type="button"
            class="close pr-0"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 mt-1">
        <p class="openSans ml-2 italic">{{ "tagAnIntegration" | translate }}</p>
    </div>
    <div class="modal-body width-400 align-left">
        <div class="row">
            <p class="pConfirmationMessage">
                {{ "addIntegrationConfirmationMessage" | translate }}
            </p>
        </div>
        <div class="row">
            <div class="col pr-0">
                <div class="flex-container">
                    <button
                        class="btn btn-White btn-main btnSmall rounded-pill mr-2 font-regular"
                        type="button"
                        (click)="confirmMessage('No')"
                    >
                        {{ "no" | translate }}
                    </button>
                    <button
                        class="btn btn-primary btn-main btnSmall rounded-pill mr-2 font-regular"
                        type="button"
                        (click)="confirmMessage('Yes')"
                    >
                        {{ "yes" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="pageType == 'addIntegration_buildingFloorView'">
    <div class="modal-header pl-0">
        <span
            class="float-left pr-2"
            (click)="pageType = 'addIntegration_confirmation'"
        >
            <i
                class="fa fa-chevron-left cursor-pointer small"
                aria-hidden="true"
            ></i>
        </span>
        <h5>{{ selectedSpaceName }}</h5>
        <button
            type="button"
            class="close pr-0"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 mt-1">
        <p class="dark-blue ml-2" style="font-style: italic;">
            {{ "tagAnIntegration" | translate }}
        </p>
    </div>
    <div class="modal-body width-400 align-left">
        <div class="row">
            <p class="pConfirmationMessage">
                {{ "thereAre" | translate}}
                {{ totalEntities}} {{"entitiesIn" | translate}}
                "{{ selectedSpaceName }}"
                {{ "thatNeedToBeMappedToOutlookEmailID" | translate}}
            </p>
        </div>
        <div class="row">
            <table mat-table [dataSource]="buildingFloorView">
                <ng-container matColumnDef="building">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "buildings" | translate }}
                    </th>
                    <td
                        class="alignTopBuilding"
                        mat-cell
                        *matCellDef="let data; let i = index"
                        [attr.rowspan]="
                            getRowSpan('building', i, data.no, data.Rowspan)
                        "
                        [style.display]="
                            getRowSpan('building', i, data.no, data.Rowspan)
                                ? ''
                                : 'none'
                        "
                    >
                        <span class="buildingCustom">{{ data?.building }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="floor">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "floor" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let data; let i = index">
                        {{ data?.floor?.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="onboardedEntities">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "onboardedEntities" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let data; let i = index">
                        {{ data?.entity.length }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="mappedEntities">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "mappedEntities" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let data; let i = index">
                        {{ getMappedEntities(data?.entity) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let data; let i = index">
                        <span class="cursor-pointer" (click)="rightArrow(data)">
                            <i
                                class="fa fa-arrow-right more-arrow"
                                aria-hidden="true"
                            ></i>
                        </span>
                    </td>
                </ng-container>
                <tr
                    mat-header-row
                    *matHeaderRowDef="buildingFloorDisplayColumns"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: buildingFloorDisplayColumns"
                ></tr>
            </table>

            <div class="col-12" *ngIf="buildingFloorView.length == 0">
                <p class="text-center bolder mt-2">
                    {{ "NoDatasAvailable" | translate }}
                </p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col pr-0">
                <div class="flex-container">
                    <button
                        type="button"
                        class="btn btn-main btnSmall rounded-pill ml-2 font-regular"
                        (click)="buildingFloorMapDoneBtn()"
                    >
                        {{ "done" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="pageType == 'addIntegration_entityEmailMapping'">
    <div class="modal-header pl-0">
        <h5>{{ selectedSpaceName }}</h5>
        <button
            type="button"
            class="close pr-0"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 mt-1">
        <p class="openSans ml-2 italic">{{ "tagAnIntegration" | translate }}</p>
    </div>
    <div class="modal-body width-400 align-left">
        <div class="row">
            <div class="col">
                <div class="float-left">
                    <span
                        class="float-left"
                        (click)="pageType = 'addIntegration_buildingFloorView'"
                    >
                        <i
                            class="fa fa-chevron-left cursor-pointer small"
                            aria-hidden="true"
                        ></i>
                    </span>
                    &nbsp;
                    <span style="font-family: 'OpenSans-Regular';"
                        >{{ emailValidationData["building"] + ", " }}
                        {{ emailValidationData["floor"]["name"] }}</span
                    >
                </div>
            </div>
        </div>
        <h6
            class="mt-4 ml-0 mb-3"
            style="text-align: left; font-family: 'OpenSans-Regular';"
        >
            {{ "entityName" | translate}}
        </h6>
        <div
            class="row align-items-end mt-3"
            *ngFor="let en of entitiesBeforeValidation"
        >
            <div class="col-3">
                <span for="entityName" class="float-left mb-1">{{
                    en.entity?.displayName
                }}</span>
            </div>
            <div class="col-5">
                <input
                    type="email"
                    placeholder="{{'enterMailIDhere' | translate}}"
                    class="form-control newAmenityInput float-left"
                    [(ngModel)]="en.email"
                    name="emailId"
                    required
                />
            </div>
            <ng-container *ngIf="submittedEmails || emailNotExist">
                <div class="col-4 pl-0 text-left" *ngIf="!en.valid">
                    <span class="errorMessage">
                        <i class="fa fa-close">
                            <span style="font-family: 'OpenSans-SemiBold';">
                                {{ en.reason }}
                            </span>
                        </i>
                    </span>
                </div>
                <div class="col-4 pl-0 text-left" *ngIf="en.valid">
                    <span>
                        <i class="fa fa-check" style="color:green"></i>
                    </span>
                </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <div class="col pr-1">
                <div class="flex-container">
                    <button
                        *ngIf="validEmailsCheck"
                        type="button"
                        class="btn btn-main btnSmall rounded-pill font-regular"
                        (click)="pageType = 'addIntegrationEmail_confirmation'"
                    >
                        {{ "proceed" | translate }}
                    </button>
                    <button
                        *ngIf="!submittedEmails || validEmailsCheck"
                        type="button"
                        class="btn btn-main btnSmall rounded-pill font-regular ml-2"
                        (click)="validateEmails()"
                    >
                        {{ "validate" | translate }}
                    </button>
                    <button
                        *ngIf="submittedEmails && !validEmailsCheck"
                        type="button"
                        class="btn btn-main btnSmall rounded-pill font-regular ml-2"
                        (click)="emailValidationDoneBtn()"
                    >
                        {{ "done" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="pageType == 'addIntegrationEmail_confirmation'">
    <div class="modal-header pl-0">
        <span class="float-left pr-2" (click)="pageType = 'addIntegration_map'">
            <i
                class="fa fa-chevron-left cursor-pointer small"
                aria-hidden="true"
            ></i>
        </span>
        <h5>{{ selectedSpaceName }}</h5>
        <button
            type="button"
            class="close pr-0"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 mt-1">
        <p class="openSans ml-2 italic">{{ "tagAnIntegration" | translate }}</p>
    </div>
    <div class="modal-body width-400 align-left">
        <div class="row">
            <p class="pConfirmationMessage pr-1">
                {{ "entitiesWIthInvalidEmailConfirmationMessage" | translate }}
            </p>
        </div>
        <div class="row">
            <div class="col pr-0">
                <div class="flex-container">
                    <button
                        class="btn btn-White btn-main btnSmall rounded-pill mr-2 font-regular"
                        type="button"
                        (click)="emailValidationConfirmMessage('No')"
                    >
                        {{ "no" | translate }}
                    </button>
                    <button
                        class="btn btn-primary btn-main btnSmall rounded-pill mr-2 font-regular"
                        type="button"
                        (click)="emailValidationConfirmMessage('Yes')"
                    >
                        {{ "yes" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="pageType == 'disabledIntegration'">
    <div class="modal-header pl-0">
        <h5>{{ "alert" | translate }}</h5>
        <button
            type="button"
            class="close pr-0"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 mt-1">
        <p class="openSans ml-2 italic">
            {{ "disableIntegration" | translate }}
        </p>
    </div>
    <div class="modal-body width-400 align-left">
        <div class="row">
            <p class="pConfirmationMessage">
                {{ "byDisablingThisFeature" | translate}}
                <span class="dark-blue">
                    {{ disableData["integrationsDto"]["integrationName"] == "Office 365"
                        ? "Microsoft 365" : disableData["integrationsDto"]["integrationName"]
                }} </span>    
                {{
                    "wouldBeDisabledFromAllTheOnboardedSpaces" | translate
                }}
                <span class="dark-blue">{{
                    getIntegrationRespectiveName(
                        disableData["integrationsDto"]["integrationName"]
                    ) + " Calendar."
                }}</span>
            </p>
            <p class="pConfirmationMessage">
                {{
                    'disableOutlookWithTeams' | translate
                }}
            </p>
            <p class="pConfirmationMessage">
                {{ "wouldYouStillikeToProceed" | translate}}
            </p>
        </div>
        <div class="row">
            <div class="col pr-0">
                <div class="flex-container">
                    <button
                        class="btn btn-White btn-main btnSmall rounded-pill mr-2 font-regular"
                        type="button"
                        (click)="disableIntegration('No')"
                    >
                        {{ "no" | translate }}
                    </button>
                    <button
                        class="btn btn-primary btn-main btnSmall rounded-pill mr-2 font-regular"
                        type="button"
                        (click)="disableIntegration('Yes')"
                    >
                        {{ "yes" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
