<div class="col mt-4 mb-4">
    <!-- <input #searchTerm class="form-control py-2 fs-16" name="user-dep" id="user-dep" autocomplete="off"
    (input)="getAutocompleteList(searchTerm.value)" type="text" id="search-data" placeholder={{placeholderValue}}
    [ngClass]="{ 'is-invalid': submitted && f.searchVal.errors }" [matAutocomplete]="auto" /> -->
    <mat-form-field>
        <mat-select class="" panelClass="panel-width" [multiple]="true">
            <div class="select-container">
                <mat-optgroup>
                    <mat-form-field style="width:100%;">
                        <input matInput #searchTerm class="widthFill text-black" placeholder="search "
                            (input)="getAutocompleteList(searchTerm.value)">
                    </mat-form-field>
                </mat-optgroup>
            </div>
            <mat-option [disabled]="true">{{ "select" | translate }}</mat-option>
            <mat-option *ngFor="let dropData of autocompleteList$ | async" [value]="dropData.name.trim()" >
                <span>{{dropData?.name}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>