import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AssetModalComponent } from '../../asset-modal/asset-modal.component';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  pageType;
  currentZone;
  addAssetForm: FormGroup;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<AssetModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private formBuilder: FormBuilder) { 
    this.currentZone = this.data["currentZone"];
    this.pageType = this.data["type"];
    // if(this.pageType === 'asset') {
      this.addAssetForm = this.formBuilder.group({
        assetName: [""],
        assetCategory: [""],
        manufacturer: [""],
        brand: [""],
        model: [""],
        serialNo: [""],
        status: [""],
        condition: [""],
        audits: [""]
    });
    // } else if (this.pageType === '') {
      
    // }
  }

  ngOnInit() {
  }

  closePopup() {
    this.dialogRef.close();
  }

  get f() {
    return this.addAssetForm.controls;
  }

  onAssetSubmit() {}

  changeAudit(event) {

  }
}