<div *ngIf="!alert">
    <div class="modal-card">
        <div *ngIf="title" class="modal-header p-0">
            <h6 class="semi-bold-blue">
                {{ title }}
            </h6>
            <button type="button" class="close" aria-label="Close" (click)="decline()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body align-left pl-0">
            <!-- <app-sub-heading title="Global Setup > People"></app-sub-heading> -->
            <p class="msg1" *ngIf="message1 !==''">{{message1}}</p>
            <p class="msg1" style="margin-top: -8px !important;" *ngIf="message2 !== ''">{{message2}}</p>
            <p class="regular-blue mt-4 f-16">
                {{ message }}
            </p>

            <div class="customMargin">
                <ng-container>
                    <div class="form-group col-lg-12 col-md-12 pr-0 text-right mb-1">
                        
                        <button
                            id="decline_btn"
                            type="button"
                            class="btn btn-main btnSmall smallBtn btn-main-outline"
                            (click)="decline()"
                        >
                            {{ btnCancelText }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-main btnSmall smallBtn  ml-3 "
                            (click)="accept()"
                        >
                            {{ btnOkText }}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="scroller" *ngIf="alert">
    <div class="modal-card">
        <div class="modal-header p-0">
            <h6 class="semi-bold-blue">
                {{ "alert" | translate }}
            </h6>
        </div>

        <div class="modal-body align-left pl-0">
            <!-- <app-sub-heading title="Global Setup > People"></app-sub-heading> -->
            <p class="regular-blue mt-2 f-16">
                {{ message }}
            </p>

            <div class="customMargin">
                <ng-container>
                    <div class="form-group col-lg-12 col-md-12 pr-0 text-right mb-1">
                        
                        <button
                            *ngIf="buttons"
                            id="decline_btn"
                            type="button"
                            class="btn btn-main btnSmall smallBtn  btn-main-outline"
                            (click)="decline()"
                        >
                            {{ btnCancelText }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-main btnSmall smallBtn  ml-3"
                            (click)="accept()"
                        >
                            {{ btnOkText }}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
