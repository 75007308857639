import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { environment} from '../../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from '../../../services/common-service.service';


@Injectable()
export class EventService {

  private moduleId;
  private readonly BASE_URL;
  private readonly BASE_URL_VMS;

  constructor(private http: HttpClient, public commonService: CommonService) {
    this.moduleId = 5; 
    this.BASE_URL = environment.BASE_URL ;
    this.BASE_URL_VMS = environment.BASE_URL + 'vms/';
  }

  getAllCategory( zoneId) {
    return this.http.get(this.BASE_URL + "communityV2/event/eventAdmin/getAllCategory", {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getAllEvents( zoneId,pageIndex,pageSize,categoryId) {
    if(categoryId==null)
    return this.http.get(this.BASE_URL + `communityV2/event/eventAdmin/getAll/v2?&page=${pageIndex}&size=${pageSize}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
    else{
      return this.http.get(this.BASE_URL + `communityV2/event/eventAdmin/getAll/v2?categoryIds=${categoryId}&page=${pageIndex}&size=${pageSize}`, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      });

    }
  }
  getAllEventsByMonth( zoneId,pageIndex,pageSize,sd,ed){
    return this.http.get(this.BASE_URL + `communityV2/event/eventAdmin/getAllEventsMonth?startTimeInMillis=${sd}&endTimeInMillis=${ed}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  public createEvent(data,dashboardImgFile,promotionImgFile,zoneId): Observable<Object> {
    const formData: FormData = new FormData();
    if (dashboardImgFile) {
      formData.append('files', dashboardImgFile, dashboardImgFile.name);
    }
    if (promotionImgFile) {
      formData.append('promotionFile', promotionImgFile, promotionImgFile.name);
    }
    formData.append('eventDetails', JSON.stringify(data));
    const endpoint = this.BASE_URL+'communityV2/event/eventAdmin/create/v2';
    return this.http.post(endpoint,formData, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }
deleteEvent(zoneId,id){

  return this.http.post(this.BASE_URL + `communityV2/event/eventAdmin/deleteEvent?eventId=${id}`,[], {
    params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
  });

}
  public editEvent(data,dashboardImgFile,promotionImgFile,zoneId,id): Observable<Object> {
    const formData: FormData = new FormData();
    if (dashboardImgFile) {
      formData.append('files', dashboardImgFile, dashboardImgFile.name);
    }
    if (promotionImgFile) {
      formData.append('promotionFile', promotionImgFile, promotionImgFile.name);
    }
    formData.append('eventDetails', JSON.stringify(data));
    const endpoint = this.BASE_URL+`communityV2/event/eventAdmin/update/v2?eventId=${id}`;
    return this.http.put(endpoint,formData, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }
  getFeedbackDetails(zoneId,id){
    return this.http.get(this.BASE_URL + `communityV2/feedback-responses/eventAdmin/rating?eventId=${id}`,{
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getSurveyDetails(zoneId,id){
    //id=19
    return this.http.post(this.BASE_URL + `communityV2/event-survey/eventAdmin/report?eventId=${id}`,[], {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getAllEventsByDate(zoneId,date,pageIndex,pageSize){
   
    return this.http.get(this.BASE_URL + `communityV2/event/eventAdmin/getAll/v2?startTimeInMillis=${date}&page=${pageIndex}&size=${pageSize}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getRegsiterdUserDetails(zoneId,id,pageIndex,pageSize){
    //id=20
    return this.http.get(this.BASE_URL + `communityV2/registration/eventAdmin/getRegisteredUsers/v2?eventId=${id}&page=${pageIndex}&size=${pageSize}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getRegisterdUserReport(id,zoneId){
    //id=20
    return this.http.get(this.BASE_URL + `communityV2/registration/eventAdmin/getUserAndGuestRegistrations?eventId=${id}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getRegsiterdUserGuestDetails(zoneId, eventId,userId,pageIndex,pageSize){
    //eventId=20
 
    return this.http.get(this.BASE_URL + `communityV2/registration/eventAdmin/getRegisteredGuest?eventId=${eventId}&userId=${userId}&page=${pageIndex}&size=${pageSize}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getRegisterdUserGuestReport(eventId,userId,zoneId){
   // eventId=20
   // userId=2
    return this.http.get(this.BASE_URL + `communityV2/registration/eventAdmin/getRegisteredGuest?eventId=${eventId}&userId=${userId}&page=${0}&size=${10}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }


  getReportData(data, zoneId):Observable<any>{
    return this.http.get(this.BASE_URL + `communityV2/event/eventAdmin/downloadEvents?startDateInMillis=${data['startTime']}&endDateInMillis=${data['endTime']}`, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getEventByName(zoneId,name,pageNo,size){
    return this.http.get(this.BASE_URL + `communityV2/event/eventAdmin/searchEventsByName?eventName=${name}&page=${pageNo}&size=${size}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getUsersByKey(zoneId,key,eId,pageNo,size){
   // eId=20
    return this.http.get(this.BASE_URL + `communityV2/registration/eventAdmin/searchRegisteredUsers?eventId=${eId}&searchKey=${key}&page=${pageNo}&size=${size}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getGuestsByKey(zoneId,key,eId,uId,pageNo,size){
    //eId=20
    return this.http.get(this.BASE_URL + `communityV2/registration/eventAdmin/searchRegisteredGuests?eventId=${eId}&userId=${uId}&searchKey=${key}&page=${pageNo}&size=${size}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getIndividualEventData(zoneId,id){
    return this.http.get(this.BASE_URL + `communityV2/event/eventAdmin/getEventById/v2?eventId=${id}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getCustomQuestion(zoneId,id){
  //  id=19
    return this.http.get(this.BASE_URL + `communityV2/event-survey/eventAdmin/dynamicSurveyColumn?eventId=${id}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getSurveyReportData(zoneId,id){
    //id=19;
    return this.http.post(this.BASE_URL + `communityV2/event-survey/eventAdmin/report/v2?eventId=${id}`,[], {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getAllEventsData(zoneId){

    return this.http.get(this.BASE_URL + `communityV2/event/eventAdmin/downloadEvents`, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  
  }
}