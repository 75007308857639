<div>
    <div class="customcheck-modal">
        <div *ngIf="pageType == 'customchecklistadd' || pageType == 'customchecklistedit'">
                <div class="modal-header modal-header-pad">
                    <h5 class="semi-bold-blue" *ngIf="pageType == 'customchecklistadd'">
                        Add Custom Checklist
                    </h5>
                    <h5 class="semi-bold-blue" *ngIf="pageType == 'customchecklistedit'">
                        Edit Custom Checklist
                    </h5>
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()"
                        style="color: #1f2551;">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body align-left mb-2">
                    <p class="sub-heading">VMS > Settings</p>
                    <form name="form" (ngSubmit)=" onSubmit(f)" #f="ngForm" novalidate>
                    <div class="form-group row col-lg-12">
                        <div class="col-lg-7 pad-lr-0">
                        <label for="companyName"
                            class="lbl-text"><b>TITLE</b></label>
                        <input type="text" class="form-control input-text" placeholder="Enter title"
                            name="title" [(ngModel)]="modelvms.title" #title="ngModel" 
                            required #mytitle />
                            <div *ngIf="title.invalid && (title.dirty || title.touched || f.submitted)"
                            >
                                <div *ngIf="title.errors?.required" class="text-danger formLabel">Title is required
                                </div>
                            </div>
                        </div>

                    </div>
                        <div class="col-lg-6 pad-lr-0">
                            <img class="cursor-pointer iconSizeDlt"
                                src="../../../../assets/images/plusicon.png" (click)="addOptions()">
                        </div>
                        <ng-container *ngIf="modelvms.optionsq.length"  >
                    <ng-container *ngFor="let opt of modelvms.optionsq; let k = index">
                        <div class="form-group row col-lg-12">
                            <div class="col-lg-7 pad-lr-0">
                                 <label for="companyName" class="lbl-text"><b>ANSWER {{k+1}}</b></label>
                                <input type="text" class="form-control input-text"
                                    placeholder="Enter answer" [(ngModel)]="opt.qoption"
                                    name="qoption-{{ k }}" required #qoption="ngModel" />
                                    <div *ngIf=" ( modelvms.optionsq.length && ( qoption.invalid && (qoption.dirty || qoption.touched || f.submitted)))"
                                    >
                                        <div *ngIf="(modelvms.optionsq.length  && qoption.errors?.required)" class="text-danger formLabel">Answer is required
                                        </div>
                                    </div>
                            </div>
                            <div class="col-lg-5 pt-1 flag-this-ans">
                                <span class="flg-ans flg-text" style="margin-top: 6px;">Flag this answer</span>
                                <mat-slide-toggle class="settings-tglbtn tableToggle"
                                    style="margin-top:7px" [(ngModel)]="opt.redflag"
                                    name="redflag-{{ k }}">&nbsp;</mat-slide-toggle>
                                <img class="cursor-pointer iconSizeDlt mar-lt-18" id="img_id"
                               
                                    src="../../../../assets/images/deleteiconvms.png"
                                    (click)="removeQuestionOption(k)">
                            </div>
                        </div>
                    </ng-container></ng-container>
                    <div class="form-group">
                        <p class="ins-cmt flg-text">
                            <span style="margin-right: 15px;">
                            Insert A Comment Box For Additional Information
                            </span>

                            <mat-slide-toggle class="settings-tglbtn tableToggle vt-al"
                                (change)="onChange($event.checked)" [checked]="showToogleIcon">
                                &nbsp;</mat-slide-toggle>
                        </p>
                    </div>
                    <div class="form-group row col-lg-12" *ngIf="showExtraDesc">
                        <div class="col-lg-7 pad-lr-0">
                            <label for="companyName"
                                class="lbl-text"><b>Description</b></label>
                            <textarea
                                class="form-control input-text"
                                rows="5" cols="50"
                                name="extraDescription"
                                [(ngModel)]="modelvms.extraDescription"
                                placeholder="Enter Description"
                                #extraDescription="ngModel" required>
                            </textarea>
                            <div *ngIf="extraDescription.invalid && (extraDescription.dirty || extraDescription.touched || f.submitted)">
                              <div *ngIf="extraDescription.errors?.required" class="text-danger formLabel">Description
                                  is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="pageType == 'customchecklistadd'">
                        <button type="submit"
                            class="pull-right btn btn-main btnSmall regular-bold ml-3 bdr-100 mar-left-0 mt-2">
                            Done
                        </button>
                    </div>
                    <div class="form-group" *ngIf="pageType == 'customchecklistedit'">
                        <button type="submit"
                            class="pull-right btn btn-main btnSmall regular-bold ml-3 bdr-100 mar-left-0">
                            Save
                        </button>
                    </div>
                    </form>
                </div>    
        </div>

    </div>
        <div class="customcheck-modal">
            <div *ngIf="pageType == 'customiseinstrution'">
                <div class="modal-header modal-header-pad">
                    <h5 class="semi-bold-blue">
                        Custom Instructions
                    </h5>
                    <button type="button" class="close" aria-label="Close" (click)="closePopupCustom()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body align-left">
                    <p class="sub-heading">VMS > Settings</p>
                    <form name="form" name="form" (ngSubmit)="fh.form.valid && onSubmitHeading(fh)" #fh="ngForm"
                        novalidate>
                        <div class="form-group row col-lg-12">
                            <div class="col-lg-7 pad-lr-0">
                                <label for="companyName"
                                    class="lbl-text"><b>{{'heading' | translate}}</b></label>
                                <input type="text" class="form-control input-text" placeholder="Enter Heading"
                                     name="heading1" [(ngModel)]="headingCheckList.heading" #heading1="ngModel" required />
                                     <div *ngIf="heading1.invalid && (heading1.dirty || heading1.touched || fh.submitted)">
                                         <div *ngIf="heading1.errors?.required" class="text-danger formLabel">Heading is
                                             required</div>
                                     </div>
                            </div>

                        </div>
                        <div class="form-group row col-lg-12">
                            <div class="col-lg-7 pad-lr-0">
                                <label for="companyName"
                                    class="lbl-text"><b>{{'description' | translate}}</b></label>
                                <textarea class="form-control input-text" rows="5" cols="50"
                                    name="headingDescription1"
                                    [(ngModel)]="headingCheckList.headingDescription" #headingDescription1="ngModel"
                                    placeholder="Enter Description" required>
                            </textarea>
                            <div *ngIf="headingDescription1.invalid && (headingDescription1.dirty || headingDescription1.touched || fh.submitted)">
                                <div *ngIf="headingDescription1.errors?.required" class="text-danger formLabel">
                                    Description is required</div>
                            </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit"
                                class="pull-right btn btn-main btnSmall regular-bold ml-3 bdr-100 mar-left-0">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    <div class="invitationCard">
        <mat-card class="example-card" *ngIf="pageType == 'showQrcode'">
            <div class="row modal-header pl-0">
                <div class="col-md-6">
                    <div class="visitor-info-header">
                        Show Qrcode
                    </div>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-card-content class="modal-body align-left">
                <br />
                <div class="row" *ngIf="encryptedQRCode!=''">
                    <div class="col-md-6 mg-10" style="margin:0px auto;text-align: center;" id="print-section-qr-code">
                        <qrcode   [elementType]="'img'" [qrdata]="encryptedQRCode"  [size]="256" [level]="'M'"></qrcode>
                    </div>
                    <div class="col-md-12">
                        <button class="btn btn-main btnSmall regular-bold ml-3 bdr-100 mar-left-0"
                            (click)="printPage()">Print</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
<div class="invitationCard">
        <mat-card class="popup-card pt-0" *ngIf="pageType == 'proxypopup'">
            <div class="row modal-header pl-0">
                <div class="col-md-6">
                    <div class="visitor-info-header">
                        <h5> Proxy User </h5>
                    </div>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>
            <mat-card-content style="text-align: left; padding:2rem;" class="modal-body align-left">
                <br />
                <br />
                <br />
                <div class="row">
                    <div class="col-md-5">
                        <label for="search-user" class="input-text-proxy">SEARCH USER</label>
                        <input class="form-control float-left w-100 p-2 backWhite" placeholder="&#xF002; Search User"
                            style="font-family:Arial, FontAwesome" [(ngModel)]="user" [matAutocomplete]="auto1"
                            (input)="onSearchUser($event.target.value)" disabled="{{editProxyBtn || editProxyBtnMod }}" />
                        <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option *ngFor="let host of nameAutocompleteList$ | async" [value]="host.name"
                                (click)="onSelectUser(host)">
                                <div class="row">

                                    <div class="col-md-6">
                                        <small>{{host.name | uppercase}}</small>

                                    </div>
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="col-md-5">
                        <label for="search-user" class="input-text-proxy">ASSIGN PROXY</label>
                        <input class="form-control float-left w-100 p-2 backWhite" placeholder="&#xF002; Assign Proxy"
                            [matAutocomplete]="auto2" style="font-family:Arial, FontAwesome" [(ngModel)]="userProxy"
                            (input)="onSearchProxy($event.target.value)" />
                        <mat-autocomplete #auto2="matAutocomplete">
                            <mat-option *ngFor="let host of nameAutocompleteList$ | async" [value]="host.name"
                                (click)="onSelectProxyUser(host)">
                                <div class="row">

                                    <div class="col-md-6">
                                        <small>{{host.name | uppercase}}</small>

                                    </div>
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="col-md-2" (click)="addProxyUser()" *ngIf="!editProxyBtn" style="margin-top: 24px;">
                        <!-- <div class="add-sign cursor-pointer">&#43;</div> -->
                        <i class="fa fa-plus cursor-pointer" style="font-size: 26px;"></i>
                    </div>
                </div>
                <br />
                <!-- <div class="row" *ngIf="!editProxyBtn">
                <p class="ml-3">Do want to notify user?</p>
                <span class="light-blue-anchor ml-2 informationIcon" (click)="changetooltipTrue()">i
                    <div class="display-overlay" *ngIf="IstooltipShown"></div>
                        <span class="anchor-text" *ngIf="IstooltipShown">
                             <h3 class="anchor-text-heading" *ngIf="!editProxyBtn">Proxy Users: {{toolTipText}}</h3>
                             <h3 class="anchor-text-heading" *ngIf="editProxyBtn">Proxy Users: {{notifyToogle ? 'Enabled' : 'Disabled'}}</h3>    
                    </span>
                </span>  
                <mat-slide-toggle
                  class="marginLeft"
                  [(ngModel)]="notifyToogle"
                  (change)="toggleProxyChanged($event.checked)"
                  *ngIf="!editProxyBtn">&nbsp;
                </mat-slide-toggle>  
                <span *ngIf="editProxyBtn" class="ml-3">{{notifyToogle ? 'Enabled' : 'Disabled'}}</span>
            </div> -->
                <br />
                <div>
                    <mat-table [dataSource]="dataSourceProxy" class="proxyTable1" *ngIf="dataSourceProxy.length>0">
                        <ng-container matColumnDef="User">
                            <mat-header-cell *matHeaderCellDef>
                                User
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.User }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Proxy">
                            <mat-header-cell *matHeaderCellDef>
                                Proxy
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.Proxy}}
                            </mat-cell>
                        </ng-container>
                        <!-- <ng-container matColumnDef="Notify User">
                      <mat-header-cell
                        *matHeaderCellDef
                        
                      >
                        Notify User
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"                            
                      >
                      <mat-slide-toggle                          
                      [(ngModel)]="element.Notify_User"
                      (change)="notifyToggleEdit($event.checked,element)"
                      class="tablecellvalue"
                      >&nbsp;</mat-slide-toggle
                      > 
                      </mat-cell>
                    </ng-container> -->

                        <ng-container matColumnDef="Actions">
                            <mat-header-cell *matHeaderCellDef>
                                Action
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <img _ngcontent-pwh-c12="" class="cursor-pointer iconSize "
                                    id="img_id" src="../../../../assets/images/edit_icon.svg"
                                    (click)="editProxy(element)">
                                <img _ngcontent-pwh-c12="" class="cursor-pointer iconSize "
                                    id="img_id" src="../../../../assets/images/deleteIcon.png"
                                    (click)="deleteProxy(element)">
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedProxyColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedProxyColumns"></mat-row>
                    </mat-table>
                </div>
            </mat-card-content>

            <div class="col-12">
                <button type="submit" class="btn btn-main btnSmall regular-bold ml-3 float-right bdr-100 mar-btm"
                    *ngIf="dataSourceProxy.length>0 && !editProxyBtn" (click)="createProxy()" [disabled]="disableDone">
                    DONE
                </button>
                <button type="button" class="btn btn-main btnSmall regular-bold ml-3 float-right bdr-100 mar-btm"
                    *ngIf="editProxyBtn" (click)="updateProxyApi()">
                    Save
                </button>
            </div>
        </mat-card>
</div>


<div *ngIf="data['type']=='visitorInfo'"class="rounded-0"  >
    <div class="modal-header">
        <h6 class="dark-blue ">
            <strong>Visitor Info</strong>
        </h6>
        <button
            type="button"
            class="close pr-0"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="col-12 mt-1">
        <p class="dark-blue1  pl-2">
            Visitor &gt; Settings
        </p>
    </div>
<div class="modal-body align-left">
    <mat-card class="example-card pt-0">
        <div class="row">
            <div class="col-md-12">
    <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
        <span aria-hidden="true">×</span>
    </button> -->
    <mat-tab-group style="background-color: #fff"  class="visitorsTabInfo m-custom"  (selectedTabChange)="getVisitManagerList($event)" >
    <mat-tab label="Visitor Info" class="ml-0" >
    <div class="invitationCard" >
        <mat-card class="example-card">
            

            <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">×</span>
            </button> -->

            <mat-card-content>
                <div class="row">
                    <div class="col-md-3" *ngIf="data.visitorImage">
                        <div mat-card-avatar class="" >
                            <img class="user-avatar-image" [src]="data.visitorImage" />
                        </div>

                    </div>
                    <div class="col-md-9 visitor-details">
                        <div class="visitor-details-inner">

                            <div class="row detail-row">

                                <div class="col-md-4 user-detail-value">Visitor Name</div>
                                <div class="col-md-4 user-detail-param">{{data.visitorName}}</div>
                            </div>

                            <div class="row detail-row" *ngIf="data && data.visitorCompany">
                                <div class="col-md-4 user-detail-value">Visitor Company</div>
                                <div class="col-md-4 user-detail-param">{{data.visitorCompany}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-4 user-detail-value">Host Company</div>
                                <div class="col-md-4 user-detail-param">{{data.hostCompany}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-4 user-detail-value">Phone No</div>
                                <div class="col-md-5 user-detail-param">{{data.visitorMobile}}</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-4 user-detail-value">Email Id</div>
                                <div  *ngIf="data && data.visitorEmail" class="col-md-4 user-detail-param">{{data.visitorEmail}}</div>
                                <div *ngIf="!(data && data.visitorEmail)"  class="col-md-4 user-detail-param">NA</div>
                            </div>

                            <div class="row detail-row">
                                <div class="col-md-4 user-detail-value">Purpose</div>
                                <div class="col-md-4 user-detail-param">{{data.purpose}}</div>
                            </div>


                            <div class="row detail-row" *ngIf="!!declaration">
                                <div class="col-md-4 user-detail-value">Declaration</div>
                                <div class="col-md-4 user-detail-param" *ngIf="!!questionAndAnswers">
                                    {{declaration}}
                                </div>
                            </div>

                            <div *ngIf="data && !!data['customFieldResponse'] && data['customFieldResponse'].length">
                                <div class="row col-md-12 user-detail-param mb-2 p-3 f-18">Additional Details</div>
                               
                                <ng-container *ngFor="let opt of data['customFieldResponse']">
                                    <div class="row detail-row">
                                        <div class="col-md-4 user-detail-param">{{opt.fieldName}}</div>
                                        <div class="col-md-4 user-detail-value">
                                            {{(opt.fieldValue=="")?"NA":opt.fieldValue}}
                                        </div>
                                    </div>
                                </ng-container>
                                
                            </div>

                        </div>
                    </div>
                </div>


            </mat-card-content>


            <div class="row" *ngIf="questionEntered">
                <div class="col-md-3" *ngIf="model.optionsq.length>0">
                    <ng-container *ngFor="let opt of model.optionsq">
                        <p><b>{{opt['qoption'][0]}}</b> : {{opt['typenumber'][0]}}</p>
                    </ng-container>
                </div>
                <div class="col-md-9">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            Custom checklist
                        </div>

                    </mat-card-header>

                    <div class="container">
                        <div *ngIf="questionAndAnswers.length>0">
                            <ng-container class="row " *ngFor="let que of questionAndAnswers;let k = index">
                                <div *ngIf="que['selectedAnswer'] != ''">
                                    <h6 class="mg-10">{{k+1}}. {{que['metadata']['question']}}</h6>
                                    <div class="">
                                        <b>ANS. {{getSelectedAnswers(que)}}
                                            <!-- {{que['metadata']['values'][this.data['data']['metadata']['questionResponse'][que.id]] }} -->
                                            <span *ngIf="checkFlagWithAnswer(que) == 1"><img
                                                    src="../../../../assets/images/redflag.png" width="15px" /></span>
                                        </b>
                                    </div>


                                    <!-- <b *ngIf="questionAns['selectedAnswer']['description']" style="margin-left:15px;">
                                        Description: {{questionAns['selectedAnswer']['description']}}
                                    </b> -->

                                    <!-- <div class="col-lg-12 text-danger" *ngIf="questionAns['selectedAnswer'] == ''">
                                        Question not answered
                                    </div> -->
                                </div>
                            </ng-container>


                            <!--If no questions answered show only active questions-->

                            <!-- <ng-container *ngIf="noQuestionsAnswered">
                                <ng-container class="row " *ngFor="let que of questionAndAnswers;let k = index">
                                    <div *ngIf="que['isActive'] == true">
                                        <h6 class="mg-10">{{k+1}}. {{que['metadata']['question']}}</h6>
                                        <div class="col-lg-12">
                                          <b>ANS. {{getSelectedAnswers(que)}} </b>
                                        </div>
                                        <b *ngIf="que['selectedAnswer']['description']" style="margin-left:15px;">
                                            Description: {{que['selectedAnswer']['description']}}
                                        </b>
                                    </div>
                                </ng-container>
                            </ng-container> -->


                        </div>
                        <div *ngIf="questionAndAnswers.length==0">

                            No Questions available

                        </div>
                    </div>
                </div>
            </div>

            <mat-divider></mat-divider>


            <div>



            </div>
        </mat-card>
    </div>
    </mat-tab>

    <mat-tab label="Additional Details" class="ml-2" > 
        <div class="invitationCard" >
            <mat-card class="example-card">
                
    
                <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">×</span>
                </button> -->
    
                <mat-card-content>
        
        
  <div style= 'height:300px;overflow-x: hidden'>
    <div class="container">
        <div *ngIf="DataList.length>0">
            <ng-container class="row "  *ngFor="let ans of DataList;let k = index"> 
                <div *ngIf="!!ans['answerSelected'] && ans.isActive && ans.fieldType != 'DROPDOWN'">

                    <h6 class="mg-10">{{k+1}}. {{ans['metadata']['question']}}</h6>
                    <div class="">
                        <b>ANS. {{ans.answerSelected}}
                             
                        </b>
                    </div> 
                </div>
            </ng-container> 
            

        </div>
        <div *ngIf="DataList.length==0">

            No Details available

        </div>
    </div>
          
  </div>
     </mat-card-content>
     </mat-card>
     </div>
     
               
      </mat-tab>
     
    </mat-tab-group>
    </div>
    </div>
    </mat-card>
    </div>
   
</div>


    <div *ngIf="data['type']=='createInviteVisitor'" class="rounded-0"  >
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header class="modal-header pl-0 ">
                        <div class="visitor-info-header">
                            <h5>
                            Create Invitation</h5>
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <!-- start -->
            <form #f="ngForm"   (ngSubmit)="onInviteSubmit(f)">

                <div class="container">
                    <div class="row">
                        <div class="col-md-12 meeting-details">
                            <h6><b>Meeting Details</b></h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">

                            <label>Meeting Start Time</label>
                            <input class="form-control width-fit-content" formControlName="scheduleStart" [owlDateTime]="inviteStartsOn"
                                [owlDateTimeTrigger]="inviteStartsOn" required>
                            <owl-date-time #inviteStartsOn></owl-date-time>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">

                            <label>Meeting End Time</label>
                            <input class="form-control" type="text" formControlName="scheduleEnd"
                                [owlDateTime]="inviteEndsOn" [owlDateTimeTrigger]="inviteEndsOn" required>
                            <owl-date-time #inviteEndsOn></owl-date-time>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">

                            <label>Meeting Purpose</label>
                            <input class="form-control" type="text" formControlName="purpose" required>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">

                            <label>Location [ZONE|NAME|Address] </label>
                            <input class="form-control" type="text" formControlName="location" required
                                [matAutocomplete]="locationAuto">
                            <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
                            <mat-autocomplete #locationAuto="matAutocomplete">
                                <mat-option *ngFor="let location of locations"
                                    [value]="location.zoneId+'|'+location.name+','+location.address">
                                    {{ location.zoneId +"|"+location.name +","+location.address}}
                                </mat-option>
                            </mat-autocomplete>


                        </div>
                    </div>
                    <mat-divider class="section-divider"></mat-divider>
                    <div class="row">
                        <div class="col-md-12 host-details">
                            <h6><b>Host Details</b></h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">

                            <label>Host Detail [ID|EMAIL|NAME]</label>
                            <input class="form-control" type="text" formControlName="hostEmail" required
                                [matAutocomplete]="auto">

                            <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let host of hosts"
                                    [value]="host.userId+'|'+host.email+'|'+host.name">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <img class="example-option-img" aria-hidden
                                                [src]="hostImgageUrl(host.imageUrl)" height="30">
                                        </div>
                                        <div class="col-md-6">
                                            <small >{{host.name | uppercase}} | </small>
                                            <small >{{ host.userId+' | '+host.email }}</small>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <mat-divider class="section-divider"></mat-divider>
                    <div class="row"
                        *ngIf="vmsConfig?.preInvite?.hostConfig?.commonHostConfig?.fields?.isWebexEnabled?.enabled == true">
                        <div class="col-md-6">
                            <mat-checkbox class="example-margin" formControlName="webEx"
                                [attr.required]="(vmsConfig?.preInvite?.hostConfig?.commonHostConfig?.fields?.isWebexEnabled?.required ==true) ? true : false">
                                Send
                                the WebEx information.</mat-checkbox>
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="vmsConfig?.preInvite?.hostConfig?.commonHostConfig?.fields?.isWifiEnabled?.enabled == true">
                        <div class="col-md-6">
                            <mat-checkbox class="example-margin" formControlName="wifi"
                                [attr.required]="(vmsConfig?.preInvite?.hostConfig?.commonHostConfig?.fields?.isWifiEnabled?.required ==true) ? true : false">
                                Send
                                the Wifi information.</mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" class="visitor-section-details">
                            <h6><b>Visitor Details</b></h6>
                        </div>
                    </div>
                    <div class="row" style="text-align: center;">

                        <div class="upload-btn-wrapper">
                            <button class="btn">Upload guests via a csv</button>
                            <input class="form-control" type="file" #fileImportInput name="File Upload"
                                id="txtFileUpload" (change)="vistorsFileChangeListener($event)" accept=".csv" />
                        </div>
                        <div> <i><a href="./assets/sample.csv" target="_blank">Sample CSV document</a></i></div>

                    </div>

                    <div class="row">
                        <table>
                            <tbody>
                                <tr *ngFor="let csvData of csvRecords;let i = index;">
                                    <td>
                                        <table>
                                            <tr>
                                                <td>{{i+1}}</td>
                                            </tr>
                                            <tr>
                                                <td><span>Name : {{csvData.name}}</span> </td>
                                            </tr>
                                            <tr>
                                                <td><span>Email : {{csvData.email}}</span> </td>
                                            </tr>
                                            <tr>
                                                <td><span>Mobile : {{csvData.mobile}}</span> </td>
                                            </tr>
                                            <tr>
                                                <td><span>Company : {{csvData.company}}</span> </td>
                                            </tr>
                                            <tr>
                                                <td><span>VisitorType : {{csvData.visitorTypeId}}</span> </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                    <div class="row">
                        <h4><span>OR</span></h4>
                    </div>
                    <ng-container formArrayName="visitors">
                        <div *ngFor="let ctrl of form.get('visitors')['controls']; let i = index" [formGroupName]="i">
                            <div class="row">
                                <div class="col-md-12">

                                    <div style="float:right;">
                                        <button mat-icon-button color="primary" (click)="onRemoveVisitors(i)">
                                            <mat-icon matTooltip="close">close</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>Name</label>
                                    <input class="form-control" type="text" formControlName="name" required>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>Email</label>
                                    <input class="form-control" type="text" formControlName="email" required>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>Mobile</label>
                                    <input class="form-control" type="text" formControlName="mobile" required>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>Company</label>
                                    <input class="form-control" type="text" formControlName="company">

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <label>Visitor Type</label>
                                    <!-- <mat-select formControlName="visitorTypeId" required>
                                        <mat-option *ngFor="let visitorType of visitorTypes" [value]="visitorType.id">
                                            {{visitorType.type}}
                                        </mat-option>
                                    </mat-select> -->

                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <div class="col-md-12">
                            <div style="float:left;"><button mat-raised-button color="primary"
                                    (click)="onAddVisitors()">Add Another
                                    Visitor</button></div>
                            <div style="float:right;"><button type="submit" mat-raised-button color="primary">Send
                                    Invitation</button>
                            </div>
                        </div>
                    </div>

                </div>

            </form>

            <!-- end -->
        </mat-card>
    </div>

    <!-- displayQrCode -->
    <div *ngIf="data['type']=='displayQrCode'">
       
            <div class="modal-header">
                <h6 class="dark-blue pl-1">
                    <strong>{{ "QR Code" | translate }}</strong>
                </h6>
                <button
                    type="button"
                    class="close pr-0"
                    aria-label="Close"
                    (click)="closePopup()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
          

            

            <!-- <mat-divider></mat-divider> -->

     
            <div class="modal-body width-400 ">       

                <div id="print-section" class="row printQr" style="font-family: Arial, Helvetica, sans-serif;margin-left: 62px">
                    <table class="printBack">
                        <tr>
                            <td style="font-family: Arial, Helvetica, sans-serif;">
                                <h1 *ngIf="data?.onlyForLevi">LEVI STRAUSS & CO.</h1> 
                                <h1 *ngIf="!data?.onlyForLevi && data.printCompanyNameData">{{data?.printCompanyNameData}}</h1> 
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td class="printBack" style="width: 350px;font-family: Arial, Helvetica, sans-serif;"> 
                                <div style="text-align: left;" >
                                    <div style="padding:5px;"  >
                                        <span style="font-size:18px"> Visitor Name : </span> <h3 style="display:inline;margin-left:5px;font-size:18px">{{data.visitorName}}</h3>
                                
                                    </div >
                                    <div style="padding:5px" >
                                        <span style="font-size:18px"> Host Name : </span> <h3 style="display:inline;margin-left:5px;font-size:18px">{{data.hostName}}</h3>
                                    </div >
                                    <div style="padding:5px" >
                                        <span style="font-size:18px"> Host Company : </span> <h3 style="display:inline;margin-left:5px;font-size:18px">{{data.hostCompany}}</h3>
                                    </div >
                                    <div style="padding:5px" >
                                        <span style="font-size:18px"> Date Of Visit : </span> <h3 style="display:inline;margin-left:5px;font-size:18px">{{ data.scheduledStart | date: "MMM-dd yyyy" }}</h3>
                                    </div >
                                    <div style="padding:5px" >
                                        <span style="font-size:18px"> Time Of Visit : </span> <h3 style="display:inline;margin-left:5px;font-size:18px">{{ data.scheduledStart | date: "h:mm a" }}</h3>
                                    </div>
                                </div>
                            </td>
                            <td class="printBack" style="text-align: center;" *ngIf="data?.visitorImage && data?.visitorPhotoRequired">
                                    <img  [src]="data.visitorImage" alt="No Image" style="width:170px;height:170px;border-radius:50%" class="mt-3">
                                
                                <h1 style="font-family: Arial, Helvetica, sans-serif;">VISITOR</h1>
                            </td>
                            <td *ngIf="!data?.visitorPhotoRequired" class="printBack" style="text-align: center; ">
                                <ng-container *ngIf="!data?.visitorPhotoRequired">
                                    <qrcode  [elementType]="'img'"  [qrdata]="data.qrcode" [size]="200" [level]="'L'"></qrcode>
                                </ng-container>  
                            <h1 style=" font-family: Arial, Helvetica, sans-serif;">VISITOR</h1>
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- <div id="print-section" class="printQr" *ngIf="data?.printData != 'IMAGE' && !data?.printCompanyNameData" >

                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-visitor" style="font-weight: bold;">Visitor </span> <span
                                class="left-text">
                                {{data.visitorName}} </span>
                        </div>
                       
                    </div>

                    <div class="row qr-row" *ngIf="data.printData !== 'IMAGE'">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-company" style="font-weight: bold"> Company </span>
                            <span>{{data.visitorCompany}}</span>
                        </div>
                       
                    </div>

                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-purpose" style="font-weight: bold"> Purpose </span>
                            <span>{{data.purpose}}</span>
                        </div>
                       
                    </div>

                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-host" style="font-weight: bold"> Host
                            </span><span>{{data.hostName}}</span>
                        </div>
                        
                    </div>

                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                            <span class="right-text-host" style="font-weight: bold"> Date
                            </span><span>{{ data.scheduledStart | date: "MMM-dd yyyy, h:mm a" }}</span>
                        </div>
                        
                    </div>
                    <div class="row qr-code-div">
                        <ng-container *ngIf="data.printData == 'IMAGE'; else newDeb" >
                            <img  class="example-option-img" aria-hidden
                            [src]="data.visitorImage" width="200" alt="No image found">
                        </ng-container>
                        <ng-template #newDeb>
                            <qrcode  [qrdata]="data.qrcode" [size]="200" [level]="'L'"></qrcode>
                        </ng-template>  
                    </div>
                </div> -->
                <button class="btn  btn-main f-size-small btn-radius p-1 m-3 btnSmall filter btn-primary " mat-raised-button color="primary" printSectionId="print-section" 
                    ngxPrint>Print</button>
                <!-- <button style="margin-left: 350px;" mat-raised-button color="primary" printSectionId="print-section" 
                    ngxPrint *ngIf="data?.printData != 'IMAGE' && !!data?.printCompanyNameData">print</button> -->

</div>
       
    </div>



  






    <div *ngIf="pageType == 'createInviteVisitorNew'">
        <div class="modal-header">
            <h6 class="dark-blue pl-1">
                <strong>{{ "inviteVisitor" | translate }}</strong>
            </h6>
            <button
                type="button"
                class="close pr-0"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
      
        <div class="modal-body width-400 align-left">
          
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group example-radio-group-1"
                [(ngModel)]="selectedInviteType"
              
            >
                <mat-radio-button
                    class="example-radio-button example-radio-button-2"
                    *ngFor="let type of inviteType"
                    [value]="type.id"
                >
                    {{ type["name"] }}
                </mat-radio-button>
            </mat-radio-group>


            <ng-container *ngIf="selectedInviteType==2">
                <div class="row">
                    <div class="col-md-5">
                        <label></label>
                        <button
                            type="button"
                            class="form-control1"
                            (click)="downloadViaExcelTemplate()"
                        >
                            <span style="font-family: 'Open Sans';">{{
                                "downloadTemplate" | translate
                            }}</span>
                            <span class="ml-3"
                                ><img
                                    src="./assets/images/downloadTemplate.png"
                                    style="width: 1rem;"
                            /></span>
                        </button>
                    </div> </div>
                    <div class="row">
                    <div class="col-md-5">
                        <label
                            for="uploadFile"
                            class="label1 float-left mt-2 font-label"
                            >{{ "upload" | translate }} (.xlsx
                            {{ "file" | translate }})</label
                        >
                        <div
                            class="input-group  clear-both float-left"
                        >
                            <input
                                type="file"
                                class="form-control custom-file-input"
                                id="importFile"
                                aria-describedby="inputGroupFileAddon01"
                                (click)="fileInputXLSX.value = null"
                                #fileInputXLSX
                                accept=".xlsx"
                                (change)="uploadExcel($event.target.files)"
                            />
                            <label
                            (click)="fileInputXLSX.click()" 
                                class="custom-file-label1 custom-upload-btn font-label"
                                for="inputGroupFile01"
                                #labelImport
                            ></label>
                        </div>
                        
                    </div> 
                    <div
                    class="input-group col-md-4 mt-3"
                    *ngIf="showUploadedExcelFileName != ''"
                >
                    <p
                        class=" inner-card-auto  p-1 "
                    >
                        <span
                            class="text-overflow-hidden ml-1"
                            >{{ showUploadedExcelFileName }}</span
                        >
                        <button
                            type="button"
                            class="close text-right right-flex"
                            aria-label="Close"
                        >
                            <span
                                aria-hidden="true"
                                (click)="showUploadedExcelFileName = '' "
                                >&times;</span
                            >
                        </button>
                    </p>
                </div>
                
                </div>
               
                <div
                    class="row col-12 text-danger"
                    *ngIf="submittedExcel && showUploadedExcelFileName == ''"
                >
                    Please upload file
                </div>
            </ng-container>
        


        

            <ng-container *ngIf="selectedInviteType==1 || (showUploadedExcelFileName!='' && selectedInviteType==2 ) ">
                <div class="row">
                    <div class="col">
                        <button
                            type="button"
                            class="btn  btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
                            (click)="inviteVisitor1()"
                        >
                            {{ "proceed" | translate }}
                        </button>
                    </div>
                </div>
            </ng-container>
          
        </div>
    </div>

    <div *ngIf="pageType == 'createInviteVisitorManually'">
        <div class="modal-header pl-0">
            <h5>
                <i aria-hidden="true" class="fa fa-angle-left cursor-pointer mr-2 backBtn" (click)="closeInvite1()"></i>
                Step 1: Visitor Information
            </h5>
            <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-1">
            <p class="dark-blue1  pl-2">
               Visitor &gt; Settings
            </p>
        </div>

        <div class="modal-body width-400 align-left">
    
           


            <div class="row">


            
                   <form class="form-group col-lg-12" [formGroup]="addVisitorInfo"
                                        (ngSubmit)=" addVisitorInfo.valid &&  onSubmitaddVisitors()">
                                        <div class="form-row">
                                            <div class="form-group col-md-3" *ngIf="!!isVisitorName">
                                                <label>Name <span
                                                        *ngIf="vmsConfig.preInvite?.visitorConfig?.commonVisitorConfig?.fields?.visitorName?.required">*</span></label>
                                                <input class="form-control" [(ngModel)]="visitorInfo.name"
                                                    formControlName="visitorName" name="name" type="text" placeholder="Enter name"
                                                    [ngClass]="{ 'is-invalid': submitted && fav.visitorName.errors }">
                                                    <div *ngIf="submitted && fav.visitorName.errors" class="invalid-feedback">
                                                        <div *ngIf="fav.visitorName.errors.required">Please enter the name</div>
                                                        <div *ngIf="fav.visitorName.errors.pattern">Please enter a valid name</div>
                                                      </div>
                                            </div>
                                            <div class="form-group col-md-3" *ngIf="!!isVisitorEmail">
                                                <label>Email </label>
                                                <input class="form-control" [(ngModel)]="visitorInfo.email" 
                                                    formControlName="visitorEmail" name="email" type="text" placeholder="Enter email"
                                                    [ngClass]="{ 'is-invalid': submitted && fav.visitorEmail.errors }">
                                                    <div *ngIf="submitted && fav.visitorEmail.errors" class="invalid-feedback">
                                                        <div *ngIf="fav.visitorEmail.errors.required">Please enter the email</div>
                                                        <div *ngIf="fav.visitorEmail.errors.email">Please enter a valid email address</div>
                                                      </div>
                                            </div>

                                            <div class="form-group col-md-2"  >
                                                <label for="subcategoryInput">{{ 'countryCode' | translate}}<span
                                                    *ngIf="vmsConfig.preInvite?.visitorConfig?.commonVisitorConfig?.fields?.visitorName?.required">*</span></label>
                                                <div class="input-group dis-err" >
                                                  <mat-form-field    appearance="fill" style="min-width: 10px;"  >
                                                    <mat-select class="custom-select-vms" formControlName="visitorCountryCode" (ngModelChange)="CCSelectionChange()"    [(ngModel)]="visitorInfo.countryCode" placeholder="{{'select' | translate}}" id="country_code"
                                                     >
                                                      <mat-option *ngFor="let countryCode of countryCodeList" value="{{countryCode.countryCode}}">
                                                        {{ countryCode.countryCode }}
                                                      </mat-option>
                                                    </mat-select>
                                                  </mat-form-field>
                                                 
                                                </div>
                                                <div *ngIf="submitted && fav.visitorCountryCode.errors" class="invalid-feedback">
                                                    <div *ngIf="fav.visitorCountryCode.errors.required">Select Country Code
                                                    </div>

                                              </div>
                                              </div>


                                            <div class="form-group col-md-3" *ngIf="!!isVisitorMobile">
                                                <label>Mobile <span
                                                        *ngIf="vmsConfig.preInvite?.visitorConfig?.commonVisitorConfig?.fields?.visitorPhone?.required">*</span>
                                                </label>
                                                <input  class="form-control" [(ngModel)]="visitorInfo.mobile"
                                                    formControlName="visitorMobile" type="text" name="phone"
                                                    placeholder="Enter mobile no"
                                               
                                                    (input)="mobileChange()"
                                                    [ngClass]="{ 'is-invalid': invalidNumber}"
                                                    >

                                                    <div class="invalid-feedback d-csm-block" *ngIf="invalidNumber" > Please enter the Valid mobile number</div>
                                                       
                                                    

                                                  


                                            </div>
                                            <div class="form-group col-md-4" *ngIf="!!isVisitorCompany">
                                                <label>Company</label>
                                                <input class="form-control" [(ngModel)]="visitorInfo.company"
                                                    formControlName="visitorCompany" type="text" name="company"
                                                    [ngClass]="{ 'is-invalid': submitted && fav.visitorCompany.errors }">
                                                <div *ngIf="submitted && fav.visitorCompany.errors" class="invalid-feedback">
                                                    <div *ngIf="fav.visitorCompany.errors.required">{{ 'pleaseentercompany' |
                                                        translate }}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-1 mt-3" *ngIf="visitorInfo.countryCode && visitorInfo.name  && visitorInfo.mobile" >
                                                <!-- <button mat-raised-button color="primary" class="add-visitor-btn btn-main" (click)='addVisitor()'
                         [disabled]="!isAddVisitorActive()">Add</button> -->
                                                <button  color="primary" type="submit" (click)="submitted=true"
                                                    class="cstmbtn b-prop"  >  <img class="cursor-pointer addPlusStyle"  src="../../../../assets/images/space_planning/add_circle_icon.svg" > 
                                                </button>

                                                   
                                            </div>
                                        </div>
                
                                    </form>
                                     
                
                
                                    </div>
                                    <div *ngIf="visitorList.length" class="mt-3">
                                        <mat-table [dataSource]="dataSource" matSort class="locationTableVMS wrap-word">
                
                                        
                
                                            <ng-container matColumnDef="name">
                                                <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                                                <mat-cell *matCellDef="let element">{{element.name}}
                                                </mat-cell>
                                            </ng-container>
                
                                            <ng-container matColumnDef="email">
                                                <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                                                <mat-cell matTooltip="{{ element.email?element.email:'NA'}}" *matCellDef="let element">{{  element.email? (element.email.length<=15 ? element.email : (element.email.slice(0,15) + '...')) : 'NA' }}
                                                </mat-cell>
                                            </ng-container>
                
                                            <ng-container matColumnDef="phone">
                                                <mat-header-cell *matHeaderCellDef>Mobile</mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.mobile}}
                                                </mat-cell>
                                            </ng-container>
                
                                           
                
                                            <ng-container matColumnDef="action">
                                                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                                                <mat-cell *matCellDef="let element; let i = index;">
                                                <button class="b-prop" [disabled]="isEditVisitor"   >
                                                    <img class="cursor-pointer checkinputwidth"  [ngClass]="{ 'disabled-img': isEditVisitor }"  (click)="editVisitor(i)"
                                                    src="../../../../assets/images/space_planning/edit_circle_icon.svg" />
                                                </button>
                                                <button class="b-prop" [disabled]="isEditVisitor"  >
                                                    <img class="cursor-pointer checkinputwidth" (click)="removeVisitor(i)"  [ngClass]="{ 'disabled-img': isEditVisitor }"
                                                    src="../../../../assets/images/space_planning/delete_circle_icon.svg" />
                                                </button>
                
                                                </mat-cell>
                                            </ng-container>
                
                                            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                            <mat-row *matRowDef="let element; columns:columns"></mat-row>
                
                
                                        </mat-table>
                                    </div>
                
                                    <ng-container *ngIf="visitorList.length">
                                        <div class="row">
                                            <div class="col">
                                                <button
                                                    type="button"
                                                    class="btn  btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
                                                    (click)="inviteVisitor2()"
                                                >
                                                    {{ "Next" | translate }}
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>

        </div>
    </div>

    <div *ngIf="pageType == 'onboardingExcelTable'">
        <div class="modal-header pl-0 pr-0">
            <h5>{{'alert' | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left mt-2 pb-0">
            <ng-container *ngIf="incorrectData.length>0">
                <p class="regular-semibold-font">
                    {{ 'successfullyUploaded' | translate }} 
                    {{ TotalNoOfEntitiesUploadedRNW }} entities, 
                    {{'belowAreTheListOfEntitiesWhichHaveIssues' | translate}}.
                </p>    
                <table mat-table [dataSource]="showIncorrectData" class="vms-error-table">
                    <!-- First Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "name" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'Name'
                                )
                            }"
                            [matTooltip]="getToolTipData(element.name)"
                        >
                            {{ element.name | slice: 0:20 }}
                            <span *ngIf="element.name?.length > 20"
                                >...</span
                            >
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "email" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'Email'
                                )
                            }"
                            [matTooltip]="getToolTipData(element.email)"
                        >
                            {{ element.email ? (element.email | slice: 0:20) : 'NA' }}
                            <span *ngIf="element?.email?.length > 20">...</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="mobile">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "mobile" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'Mobile'
                                )
                            }"
                            [matTooltip]="getToolTipData(element.mobile)"
                        >
                            {{ element.mobile | slice: 0:20 }}
                            <span *ngIf="element.addons?.length > 20">...</span>
                        </td>
                    </ng-container>
    
                  
    
                    <!-- Capacity is only for Collab Spaces -->
                 
                    <!-- Reason Column -->
                    <ng-container matColumnDef="reason">
                        <th mat-header-cell *matHeaderCellDef class="align-left" style="width: 20vw;">
                            {{ "reason" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [matTooltip]="getToolTipData(element.reason)"
                        >
                            <span>{{ element.reason | slice: 0:30 }}
                                <ng-container *ngIf="element.reason?.length > 30">...</ng-container>
                            </span>
                        </td>
                    </ng-container>
    
                    <tr
                        mat-header-row
                        *matHeaderRowDef="incorrectDataColumns"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: incorrectDataColumns"
                    ></tr>
                </table>
                <ng-container *ngIf="totalIncorrectLength>10">
                    <mat-paginator
                        class="w-100"
                        #paginator
                        [pageSize]="pageSize"
                        [pageSizeOptions]="[10,25,100]"
                        [showFirstLastButtons]="true"
                        (page)="getNextRecords($event)"
                        [length]="totalIncorrectLength"
                    ></mat-paginator>
                </ng-container>
    
                <div class="row text-center button-alignToEnd float-right p-2 mt-2">
                    <button
                        class="btn btn-main f-size-small pl-3 pr-3 btn-radius p-1 m-1  filter btn-primary pull-right"
                        (click)="reUploadEntities()">
                        {{ "reuploadFilesWithFixes" | translate }}
                    </button>
                    <button
                        class=" btn-main-white-nb  ml-4 btn btn-main f-size-small pl-3 pr-3 btn-radius p-1 m-1  filter btn-primary pull-right "
                        (click)="proceedToOnboard(1)">
                        {{ "proceedToOnboardTheRemainingEntries" | translate }}
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="correctData.length>0 && incorrectData.length == 0">
                <p class="regular-semibold-font">
                    {{ 'successfullyUploaded' | translate }} 
                    {{ noOfEntitiesUploaded }} entities, 
                    {{ 'proceedToOnboard' | translate }}.
                </p>
                <button
                    class="btn  btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
                    (click)="proceedToOnboard(2)">
                    {{ "proceed" | translate }}
                </button>
            </ng-container>
        </div>
    </div>
    <div *ngIf="pageType == 'onboarding_reUploadCSV'">
        <div class="modal-header pl-0 pr-0">
            <h5>{{'reUploadFile' | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left mt-2">
            <div class="row">
                <div class="col-12 pl-2">
                    <img
                        class="cursor-pointer"
                        src="../../assets/images/jumpree_digital/download.png"
                        (click)="downloadCurrentCSVFile()"
                        width="45"
                    />
                    <span class="regular-semibold-font">
                        {{'downloadCurrentFile' | translate}}
                    </span>
                </div>
            </div>
            <p class="regular-semibold-font mt-3">
                {{ 'uploadFileWithFixes' | translate }} 
            </p>    
            <div class="row">
                <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                    <label for="uploadFile" class="label1">{{ "upload" | translate }} (.xlsx
                        {{ "file" | translate }})</label>
                    <div class="input-group">
                        <input 
                            type="file" 
                            #fileInput
                            class="form-control custom-file-input" 
                            id="importFile"
                            (click)="fileInput.value = null"
                            aria-describedby="inputGroupFileAddon01" 
                            accept=".xlsx"
                            (change)="uploadExcel($event.target.files)"
                        />
                        <label 
                            class="custom-file-label1 custom-upload-btn" 
                            for="inputGroupFile01">
                        </label>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-4" *ngIf="showUploadedExcelFileName">
                <p class="pConfirmationMessage custom-shadow ml-0">
                    {{showUploadedExcelFileName}}
                    <span class="ml-4 cursor-pointer font-weight-bold" (click)="showUploadedExcelFileName = undefined">×</span>
                </p>
            </div>
            <div class="row" *ngIf="showUploadedExcelFileName">
                <div class="col">
                    <button type="button" class="btn  btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right" (click)="validateExcelOnboarding()">
                        {{'proceed' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>


  


   





    <div *ngIf="pageType == 'createInviteVisitorHost'">
        <div class="modal-header pl-0">
            <h5>
                <i aria-hidden="true" class="fa fa-angle-left cursor-pointer mr-2 backBtn" (click)="closeInvite2()"></i>
                Step 2: Host Information
            </h5>
            <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-1">
            <p class="dark-blue1  pl-2">
               Visitor &gt; Settings
            </p>
        </div>


<!--   <div class="modal-body width-400 align-left">
    
           


            <div class="row">


            
                   <form class="form-group col-lg-12" [formGroup]="addVisitorInfo"
                                        (ngSubmit)="onSubmitaddVisitors()">
                                        <div class="form-row">    -->


        <div class="modal-body width-400 align-left">
    
            <div class="row mt-2">
                <!-- <div class=" col-md-5 ">
                    <label class="font-weight-bold"
                        >{{ "startDate" | translate }}
                    </label>
                    <div class="input-group">
                        <input
                            id="start_date"
                            class="form-control"
                            name="startDate"
                            placeholder="{{ 'startDate' | translate }}"
                            [owlDateTimeTrigger]="dt"
                            [owlDateTime]="dt"
                            [(ngModel)]="hostInfo.scheduleStart"
                            readonly
                        />
                        <span [owlDateTimeTrigger]="dt"
                            ><i class="fa fa-calendar fa-calender-icons"></i
                        ></span>
                        <owl-date-time
                            [pickerType]="'calendar'"
                            #dt
                        ></owl-date-time>
                    </div>
                </div>
                <div class=" col-md-5 ">
                    <label class="font-weight-bold"
                        >{{ "endDate" | translate }}
                    </label>
                    <div class="input-group">
                        <input
                            id="end_date"
                            class="form-control"
                            name="endDate"
                            placeholder="{{ 'endDate' | translate }}"
                            [owlDateTimeTrigger]="ed"
                            [owlDateTime]="ed"
                            [min]="startDate"
                        
                            [(ngModel)]="hostInfo.scheduleEnd"
                            readonly
                        />
                        <span [owlDateTimeTrigger]="ed"
                            ><i class="fa fa-calendar fa-calender-icons"></i
                        ></span>
                        <owl-date-time
                            [pickerType]="'calendar'"
                            #ed
                        ></owl-date-time>
                    </div>
                </div> -->

                <div class="form-group col-md-5 label-cstm vms-base">
                 
                    <label>Meeting Start Time</label>
                    <div class="input-group width-105 ">
                    <input class="form-control br-0 " [owlDateTime]="inviteStartsOn" 
                    placeholder="Select start time"
                  
                        [owlDateTimeTrigger]="inviteStartsOn" readonly [min]="minDate"
                        [(ngModel)]="hostInfo.scheduleStart">
                        <span [owlDateTimeTrigger]="inviteStartsOn"
                        ><i class="fa fa-calendar fa-calender-icons "></i
                      ></span>
                    <owl-date-time [hour12Timer]="true" #inviteStartsOn></owl-date-time>
</div>
                </div>


              



                <div class="form-group col-md-5 label-cstm vms-base">

                    <label>Meeting End Time</label>
                    <div class="input-group width-105 ">
                   
                    <input class="form-control br-0 " type="text" readonly [min]="hostInfo.scheduleStart"  placeholder="Select end time"
                        [owlDateTime]="inviteEndsOn" [owlDateTimeTrigger]="inviteEndsOn"
                        [(ngModel)]="hostInfo.scheduleEnd"
                    
                        >
                        <span [owlDateTimeTrigger]="inviteEndsOn"
                        ><i class="fa fa-calendar fa-calender-icons "></i
                      ></span>
                      
                    <owl-date-time [hour12Timer]="true"  #inviteEndsOn></owl-date-time>
                    </div>

                </div>

                <div class="form-group col-md-5 label-cstm">

                    <label>Meeting Purpose</label>
                    <input  placeholder="Enter purpose" class="form-control br-0 " type="text" [(ngModel)]="hostInfo.purpose">

                </div>

                <div class="form-group col-md-5 label-cstm">

                    <label>Search By Email  </label>
                    <input class="form-control br-0 " type="text" [matAutocomplete]="auto"
                    placeholder="Search by email, Id or name"
                        (keyup)="onHostChange($event.target.value)" [(ngModel)]="hostInfo.host">

                    <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)" >
                        <mat-option *ngFor="let host of hosts ; let i=index"
                            [value]="host.email">
                            <div class="row">
                               
                                <div class="col-md-6">
                                
                                    <small >{{ host.email }}</small>
                                </div>
                            </div>
                        </mat-option>
                    </mat-autocomplete>


                </div>

                <div class="col-md-5 label-cstm">

                    <label>Location  </label>
                    <input class="form-control br-0 "  type="text" [matAutocomplete]="locationAuto"
                    (keyup)="onLocationChange($event.target.value)" 
                       [(ngModel)]="hostInfo.location"  placeholder="Search by location, name, address" >
                    <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
                    <mat-autocomplete #locationAuto="matAutocomplete" (optionSelected)="onLocationSelectionChanged($event)" >
                        <mat-option *ngFor="let location of locations ; let i =index"
                            [value]="i"   >
                            {{ location.name +","+location.address}}
                        </mat-option>
                    </mat-autocomplete>


                </div>
            </div>
                <ng-container *ngIf="hostInfo.scheduleStart && hostInfo.scheduleEnd && hostInfo.purpose && hostInfo.hostId!=null && hostInfo.zoneId!=null  " >
                <div class="row invite-action-btn float-right mr-1">
                    
                        <div style="float:right; border-radius: 100px;"><button style=" border-radius: 100px;" class="btn btn-main f-size-small pl-4 pr-4 btn-radius p-1 m-1  filter btn-primary pull-right" [disabled]="!isSendInvitation() || showDisable" type="submit"
                                mat-raised-button color="primary" (click)="sendInvitation()">Send
                                Invitation</button>
                        </div>
                    </div>
                </ng-container>
              

  </div>


            <!-- <div class="row">

                <div class="col-md-4">

                    <label>Meeting Start Time</label>
                    <input class="form-control" [owlDateTime]="inviteStartsOn" 
                        [owlDateTimeTrigger]="inviteStartsOn" readonly [min]="minDate"
                        [(ngModel)]="hostInfo.scheduleStart">
                    <owl-date-time [hour12Timer]="true" #inviteStartsOn></owl-date-time>

                </div>



                <div class="col-md-4">

                    <label>Meeting End Time</label>
                    <input class="form-control" type="text" readonly [min]="hostInfo.scheduleStart"
                        [owlDateTime]="inviteEndsOn" [owlDateTimeTrigger]="inviteEndsOn"
                        [(ngModel)]="hostInfo.scheduleEnd">
                    <owl-date-time [hour12Timer]="true"  #inviteEndsOn></owl-date-time>

                </div>




                <div class="col-md-4">

                    <label>Meeting Purpose</label>
                    <input class="form-control" type="text" [(ngModel)]="hostInfo.purpose">

                </div>

                <div class="col-md-12">

                    <label>Search By Email  [ID|EMAIL|NAME]</label>
                    <input class="form-control" type="text" [matAutocomplete]="auto"
                        (keyup)="onHostChange($event.target.value)" [(ngModel)]="hostInfo.host">

                 
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged()">
                        <mat-option *ngFor="let host of hosts"
                            [value]="host.userId+'|'+host.email+'|'+host.name+'|'+host.tenantId">
                            <div class="row">
                                <div class="col-md-1">
                                    <img class="example-option-img" aria-hidden
                                        [src]="hostImgageUrl(host.imageUrl)" height="30">
                                </div>
                                <div class="col-md-6">
                                    <small >{{host.name | uppercase}} | </small>
                                    <small >{{ host.userId+' | '+host.email }}</small>
                                </div>
                            </div>
                        </mat-option>
                    </mat-autocomplete>


                </div>

                <div class="col-md-12">

                    <label>Location [ZONE|NAME|Address] </label>
                    <input class="form-control" type="text" [matAutocomplete]="locationAuto"
                        (click)="onClickLocationBox()" [(ngModel)]="hostInfo.location">
                  
                    <mat-autocomplete #locationAuto="matAutocomplete">
                        <mat-option *ngFor="let location of locations"
                            [value]="location.zoneId+'|'+location.name+','+location.address">
                            {{ location.zoneId +"|"+location.name +","+location.address}}
                        </mat-option>
                    </mat-autocomplete>


                </div>





            </div>
 -->

                

        </div>
    



    <div *ngIf="data['type'] == 'customChecklist'">

    </div>


    <div *ngIf="data['type'] == 'additionalDetails'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="visitor-info-header">
                            Additional Details
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <mat-card-content>
                <div style="margin-top:20px;" *ngIf="model.optionsq.length>0">
                    <form name="form" (ngSubmit)="f.form.valid && onSubmitAdditionalDetails(f)" #f="ngForm" novalidate>
                        <ng-container *ngFor="let opt of model.optionsq; let k = index">
                            <div class="form-group row mg-10">
                                <div class="col-lg-4">
                                    <label>ID TYPE</label>
                                    <input type="text" class="form-control" placeholder="Enter ID Type"
                                        [(ngModel)]="opt.qoption" disabled name="qoption-{{k}}"
                                        [ngClass]="{ 'is-invalid': f.submitted && qoption.invalid }" required
                                        #qoption="ngModel">
                                </div>
                                <div class="col-lg-4">
                                    <label>ID NAME</label>
                                    <!-- <input type="text" class="form-control" placeholder="Enter ID Type/Number" [(ngModel)]="opt.typenumber" name="typenumber-{{k}}" [ngClass]="{ 'is-invalid': f.submitted && typenumber.invalid }" required #typenumber="ngModel"> -->
                                    <input type="text" class="form-control" placeholder="Enter ID Type/Number"
                                        [(ngModel)]="opt.typenumber" name="typenumber-{{k}}">

                                </div>
                                <div class="col-lg-4">
                                    <!-- <span *ngIf="k==0"  class="addOptions" (click)="addOptions()">+Add More</span> -->
                                    <!-- <small *ngIf="k>1" ><a href="javascript:void(0)" (click)="removeAdditionalInfo(k)"><i class="fa fa-times-circle remove-circle"></i></a></small> -->
                                </div>


                            </div>
                        </ng-container>
                        <div class="col-md-2">
                            <button mat-raised-button color="primary" class="add-visitor-btn"
                                (click)='saveAdditionalDetails()'>Save</button>
                        </div>
                    </form>
                </div>
                <div style="margin-top:20px;" *ngIf="model.optionsq.length==0">
                    <p>No details available to enter</p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <!-- check out for visitor -->
    <div *ngIf="data['type']=='checkOut'"  >

        <mat-card class='example' >

            <div class="row modal-header pl-0">
                <div class="col-md-10 pl-0">
                    <div class="visitor-info-header">
                        <h5 style="font-size: 16px;" >  Are you sure that you want to checkout: {{data['visitorName']}} </h5>
                    </div>
                </div>
                <div class="col-md-2">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <!-- <div class="row" class="m-2"  style='width: inherit;' >
                <div class="col-md-10">
                    <mat-card-header>
                        <div   >
                           <span> Are you sure that you want to checkout: {{data['visitorName']}}
                        </span>
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-2">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider> -->

            <mat-card-content style='width: inherit;'>
                   <div class='printQr'> 
                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                          <span class="right-text-company" style="font-weight: bold">
                                 </span>
                        </div>

                    </div>

                    <div class="row qr-row" >
                        <div class="col-md-12 qr-left-title"  style="margin-left: 5.6rem;">
                            <button style="border-radius: 100px;" class="btn ml-4 btn-main f-size-small btn-radius p-1 btnSmall filter btn-primary" mat-raised-button color="primary"
                                printSectionId="print-section" (click)="checkOutUser()">
                                Check Out</button>

                            <button style="border-radius: 100px;"  class="btn ml-4 btn-main btn-white f-size-small btn-radius p-1 btnSmall filter btn-primary" mat-raised-button color="primary"
                                printSectionId="print-section" (click)="closePopup()">
                                Cancel</button>
                        </div>

                    </div>
 
                </div>


            </mat-card-content>

        </mat-card>
    </div>


    <!-- check Out for MY VISTIOR -->


    <div *ngIf="data['type']=='checkOutMyVisitor'"  >

        <mat-card class='example' >
           

            <div class="row modal-header pl-0">
                <div class="col-md-10 pl-0">
                    <div class="visitor-info-header">
                        <h5 style="font-size: 16px;" >  Are you sure that you want to checkout: {{data['visitorName']}} </h5>
                    </div>
                </div>
                <div class="col-md-2">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <mat-card-content style='width: inherit;'>
                   <div class='printQr'> 
                    <div class="row qr-row">
                        <div class="col-md-12 qr-left-title">
                          <span class="right-text-company" style="font-weight: bold">
                                 </span>
                        </div>

                    </div>

                    <div class="row qr-row" >
                        <div class="col-md-12 qr-left-title" style="margin-left: 5.6rem;" >
                            <button style="border-radius: 100px;" class="btn ml-4 btn-main f-size-small btn-radius p-1 btnSmall filter btn-primary" mat-raised-button color="primary"
                                printSectionId="print-section" (click)="checkOutMyVistiotor()">
                                Check Out</button>

                            <button style="border-radius: 100px;" class="btn ml-4 btn-main f-size-small btn-radius p-1 btnSmall filter btn-primary" mat-raised-button color="primary"
                                printSectionId="print-section" (click)="closePopup()">
                                Cancel</button>
                        </div>

                    </div>
 
                </div>


            </mat-card-content>

        </mat-card>
    </div>
    <div *ngIf="data.type == 'downloadDateSelect'">
        <div class="modal-header">
            <h6 class="dark-blue pl-1">
                <strong>{{ "downloadReport" | translate }}</strong>
            </h6>
            <button
                type="button"
                class="close pr-0"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-1">
            <p class="dark-blue1  pl-2">
                {{ data["selectedAmenityName"] | translate }}
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <p class="dark-blue1 mb-2">
                {{ "downloadReportFrom" | translate }}
            </p>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="selectedType"
                (change)="downloadReportTypeChange($event)"
            >
                <mat-radio-button
                    class="example-radio-button"
                    *ngFor="let type of filterType"
                    [value]="type.id"
                >
                    {{ type["name"] }}
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="selectedType == 'custom_date'">
                <div class="row mt-2">
                    <div class="form-group col-md-4 col-lg-4">
                        <label class="font-weight-bold"
                            >{{ "startDate" | translate }}
                        </label>
                        <div class="input-group">
                            <input
                                id="start_date"
                                class="form-control"
                                name="startDate"
                                placeholder="{{ 'startDate' | translate }}"
                                [owlDateTimeTrigger]="dt"
                                [owlDateTime]="dt"
                                [(ngModel)]="startDate"
                                readonly
                            />
                            <span [owlDateTimeTrigger]="dt"
                                ><i class="fa fa-calendar fa-calender-icons"></i
                            ></span>
                            <owl-date-time
                                [pickerType]="'calendar'"
                                #dt
                            ></owl-date-time>
                        </div>
                    </div>
                    <div class="form-group col-md-4 col-lg-4">
                        <label class="font-weight-bold"
                            >{{ "endDate" | translate }}
                        </label>
                        <div class="input-group">
                            <input
                                id="end_date"
                                class="form-control"
                                name="endDate"
                                placeholder="{{ 'endDate' | translate }}"
                                [owlDateTimeTrigger]="ed"
                                [owlDateTime]="ed"
                                [min]="startDate"
                                [(ngModel)]="endDate"
                                readonly
                            />
                            <span [owlDateTimeTrigger]="ed"
                                ><i class="fa fa-calendar fa-calender-icons"></i
                            ></span>
                            <owl-date-time
                                [pickerType]="'calendar'"
                                #ed
                            ></owl-date-time>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col">
                    <button
                        type="button"
                        class="btn btn-main float-right rounded-pill"
                        (click)="downloadReport()"
                    >
                        {{ "download" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
