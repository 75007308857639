<div *ngIf="!alert">
    <div class="modal-card">
        <div *ngIf="title" class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ title }}
            </h5>
        </div>

        <div class="modal-body align-left pl-0">
            <!-- <app-sub-heading title="Global Setup > People"></app-sub-heading> -->
            <h5 class="regular-blue mt-2 f-16">
                {{ message }}
            </h5>

            <div class="customMargin">
                <ng-container>
                    <div class="form-group col-lg-12 col-md-12 pr-0 mb-2 text-right">
                        
                        <button
                            id="decline_btn"
                            type="button"
                            class="btn btn-main btnSmall smallBtn mb-2 btn-main-outline"
                            (click)="decline()"
                        >
                            {{ btnCancelText  | translate}}
                        </button>
                        <button
                            type="button"
                            class="btn btn-main btnSmall smallBtn  ml-3 mb-2"
                            (click)="accept()"
                        >
                            {{ btnOkText  | translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="scroller" *ngIf="alert">
    <div class="modal-card">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "alert" | translate }}
            </h5>
        </div>

        <div class="modal-body align-left pl-0">
            <!-- <app-sub-heading title="Global Setup > People"></app-sub-heading> -->
            <h5 class="regular-blue mt-2 f-16">
                {{ message }}
            </h5>

            <div class="customMargin">
                <ng-container>
                    <div class="form-group col-lg-12 col-md-12 pr-0 mb-2 text-right">
                        
                        <button
                            *ngIf="buttons"
                            id="decline_btn"
                            type="button"
                            class="btn btn-main btnSmall smallBtn  mb-2 btn-main-outline"
                            (click)="decline()"
                        >
                            {{ btnCancelText | translate}}
                        </button>
                        <button
                            type="button"
                            class="btn btn-main btnSmall smallBtn  ml-3 mb-2"
                            (click)="accept()"
                        >
                            {{ btnOkText | translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
