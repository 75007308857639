import {
  Component,
  Inject,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatInput,
  MatSelect,
  MatOption,
  MatCheckbox,
  MatTableDataSource,
  MatRadioButton,
  MatRadioGroup
} from "@angular/material";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
  FormArray,
  NgForm
} from "@angular/forms";
import { ReplaySubject, Subject, Observable, BehaviorSubject } from "rxjs";
//import { modulesIds } from "../../../../environments/environment";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import "rxjs/add/observable/of";
import { CommonService } from "../../../services/common-service.service";
import { SelfAssessmentService } from "../services/self-assessment.service";
import { routerTransition } from "../../../router.animations";
import { Router } from "@angular/router";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { EMSService } from "../../../shared/services/ems.service";
import { TMSService } from "../../../shared/services/tms.service";
import {
  debounceTime,
  delay,
  tap,
  filter,
  map,
  takeUntil,
  take,
  catchError,
  finalize,
  flatMap
} from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
declare var $: any;
declare var jQuery: any;

import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'll LT',
  fullPickerInput: 'll LT',
  datePickerInput: 'll',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'll',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: "app-assessment-modal",
  templateUrl: "./assessment-modal.component.html",
  styleUrls: ["./assessment-modal.component.scss"],
  providers: [
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ]
})
export class AssessmentModalComponent implements OnInit, AfterViewInit {
  optionValues='';
  options = [];
  preSelectedOptions: string[]=[];
  selectedOptions= this.preSelectedOptions.reduce<Record<string, boolean>>(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: true,
    }),
    {},
  );

  currentZone = "";
  model: any = { optionsq: [] };
  pageType: any;
  public dateTime1: any;
  public dateTime2: any;
  public dateTime3: any;
  public dateTime4: any;
  repeatSelection = "DAILY";
  daysSelection: any = {};
  daysSelectionOfWeek = {
    day1: "MON",
    day2: "TUE",
    day3: "WED",
    day4: "THU",
    day5: "FRI",
    day6: "SAT",
    day7: "SUN"
  };
  days = new Int8Array(31).map((curr, index) => curr = index + 1);
  selfAssessment: any;
  regularExpression: any;
  userId = null;
  questionList = [];
  favoriteSeason: string;
  public bankServerSideCtrl: FormControl = new FormControl();
  public bankServerSideFilteringCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  public filteredServerSideBanks: ReplaySubject<any[]> = new ReplaySubject<
    any[]
  >(1);
  protected _onDestroy = new Subject<void>();
  pageLimt: number = 1;
  pageNo: number = 0;
  pageSize: number = 10;
  totalPage;
  showLoadMore: boolean = false;
  searchQuery = "";
  selectedEmsUser: any = [];
  selectedEmsUserIds:any = "";
  allUsers = [];
  temperatureCheckEnabled = false;
  temperatureCheckDisaply = false;
  temSubmitEnable = false;

  loading: boolean = false;
  temValue = null;
  maxTemVal = 100;
  fahrenheitQuestionId = null;
  temperatureList = [];
  temperatureData = [];
  selfAssessmentThank = false;
  selfassessmentRes:any = [];
  selfAssessmentSoryy = false;
  selfAssessmentMsg = null;
  temValueType = "C";
  oxygenReading: any;
  oxygenquestionId: any;
  oxygenReads = [];
  oxymeterSubmitEnable = false;
  oxymeterCheckEnable = false;
  oxymeterCheckDisplay = false;
  maxOximeter: any;
  tempShow :boolean;
  oxygenShow : boolean;
  hideOxygen: boolean;

  
  scheduleTime = "8.00";
  scheduleTimeType = "PM";
  responseFormatType = 'radio';
  customDays: boolean = false;
  dateResponse: any;

  minDate = new Date(new Date().setHours(0, 0, 0, 0));


  constructor(
    public matSnackBar: MatSnackBar,
    private tmsService: TMSService,
    public dialogRef: MatDialogRef<AssessmentModalComponent>,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public commonService: CommonService,
    public selfassesService: SelfAssessmentService,
    public emsService: EMSService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pageType = this.data["type"];
  }

  ngOnInit() {
    if (this.pageType == "editCheckList") {
      let data = this.data["data"];
      this.model.title = data["metadata"]["question"];
      this.currentZone = data["zoneId"];
      let approvalCond = Object.keys(data.metadata.approvalConditionValues);
      let k = 65;
      for (var i = 0; i < Object.keys(data["metadata"]["values"]).length; i++) {
        if (approvalCond.includes(String.fromCharCode(k)) == false) {
          this.preSelectedOptions.push(data["metadata"]["values"][String.fromCharCode(k)]);
        } 
        let optionAvail = data["metadata"]["values"][String.fromCharCode(k)];
        this.options.push(optionAvail);
        k++;
      }
      this.selectedOptions= this.preSelectedOptions.reduce<Record<string, boolean>>(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: true,
        }),
        {},
      );

      this.repeatSelection = data["metadata"]["frequency"];
      let cronSelectedInfo = this.commonService.parseCronForOptions(
        this.repeatSelection,
        data["metadata"]["cronExpression"]
      );

      if (this.repeatSelection == "MONTHLY") {
        this.monthSelection = cronSelectedInfo;
      } else {
        for (let [key, value] of Object.entries(this.daysSelectionOfWeek)) {
          let selectedCron = cronSelectedInfo.includes(value);
          if (selectedCron == true) {
            this.daysSelection[key] = true;
          }
        }
      }

      this.dateTime1 = this.formateDate(
        new Date(data["metadata"]["startTime"])
      );
      //this.dateTime2 = new Date(data['metadata']['startTime']);

      this.dateTime3 = this.formateDate(new Date(data["metadata"]["endTime"]));
      //this.dateTime4 = new Date(data['metadata']['endTime']);
    }

    if (this.pageType == "userCheckList") {
      this.selfAssessment = this.data["data"]["metadata"]["question"];
      this.selectedEmsUserIds = this.data["data"];
      this.maxTemVal = this.data["maxTem"];

      this.getTemperature();
      this.getAssesmentOxygen();
    }
    if (this.pageType == "takeAssessment") {
      this.currentZone = this.data["zoneId"];
      this.maxTemVal = this.data["maxTem"];
      this.bankServerSideFilteringCtrl.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => (this.searching = true)),
          takeUntil(this._onDestroy),
          debounceTime(200),
          map(search => {
            this.pageNo = 0;

            this.searchQuery = search;

            if (this.searchQuery.length >= 3) {
              let data: any = {};
              data.q = search;
              data.page = this.pageNo;
              data.size = this.pageSize;
              // return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
              this.emsService
                .getUserList(data, this.currentZone)
                .subscribe(res => {
                  this.allUsers = [];
                  let felteredData = (res as any).response["content"];
                  felteredData.forEach(element => {
                    var isFirst = false;
                    let i = 0;
                    /*
              if(this.allUsers.length > 0) {
                isFirst = false;
                this.allUsers.forEach(usr => {
                  if(usr.userId == element.userId) {
                    i = 1;
                  }
                });
              }else {
                isFirst = true;
                this.allUsers.push(element); //initial push
              }*/
                    if (i === 0 && !isFirst) {
                      this.allUsers.push(element); //  new user added
                    }
                  });
                  if (this.allUsers.length > 0) {
                    this.allUsers.filter(
                      user => user.name.toLowerCase().indexOf(search) > -1
                    );
                  }
                  this.filteredServerSideBanks.next(this.allUsers);
                  this.totalPage = (res as any).response["totalPages"];
                  if (this.totalPage > 1 && this.pageNo < this.totalPage) {
                    this.showLoadMore = true;
                  }
                });
            }
          }),
          delay(500)
        )
        .subscribe(
          filteredBanks => {
            this.searching = false;
          },
          error => {
            this.searching = false;
          }
        );
      this.temperatureCheck();
    }
  }

  ngAfterViewInit() {
    if (this.pageType == "editCheckList") {
      //this.loading = true;
      /*
      setTimeout(function(){
        $(function() {
          $('#example2-cron').cronBuilder();
          $(".cron-input").css("margin-top","10px");
          $(".weeklyCheckbox").css("margin-left","10px");
          //this.loading = false;
          $('#submit-question').click(function(){
              var regularExpression = $('#example2-cron').data('cronBuilder').getExpression();
              $('#example2-result').val(regularExpression);
              
          })
        });
      },3000);*/
    }
  }

  formateDate(date) {
    let month =
      (date.getMonth() + 1).toString().length == 1
        ? "0" + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString();
    let day =
      date.getDate().toString().length == 1
        ? "0" + date.getDate().toString()
        : date.getDate().toString();
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  checkFlagWithAnswer(ele) {
    let approvalValues = Object.keys(
      ele["metadata"]["approvalConditionValues"]
    );
    let answeredOption = this.data["data"]["metadata"]["questionResponse"][
      ele["id"]
    ];
    let showFlag = 0;
    if (!!answeredOption) {
      answeredOption.forEach(element => {
        if (
          Object.keys(ele["metadata"]["approvalConditionValues"]).includes(
            element
          ) == false
        ) {
          showFlag = 1;
        }
      });
    }

    return showFlag;
  }

  getSelectedAnswers(ele) {
    let answered = [];
    let answeredOption = this.data["data"]["metadata"]["questionResponse"][
      ele["id"]
    ];
    let showFlag = 0;
    if (!!answeredOption) {
      answeredOption.forEach(element => {
        answered.push(ele["metadata"]["values"][element]);
      });
    }
    return answered;
  }

  // removeQuestionOption = qoption => {
  //   this.model.optionsq.splice(qoption, 1);
  // };

  closePopup() {
    this.dialogRef.close();
  }

  isRepeatDateSelected: boolean = false;
  selectedDays: any;
  monthSelection: any;
  isRedFlagValid: boolean = true;
  onSubmit(f) {
    // this.loading = true;
    this.isRepeatDateSelected = false;
    this.selectedDays = [];
    if (this.repeatSelection != "MONTHLY") {
      for (let [key, value] of Object.entries(this.daysSelection)) {
        if (value == true) {
          this.isRepeatDateSelected = true;
          this.selectedDays.push(this.daysSelectionOfWeek[key]);
        }
      }
    } else {
      this.isRepeatDateSelected = true;
    }

    let approvalOptionsArray = [];
    this.model["optionsq"].forEach(element => {
      approvalOptionsArray.push(element["redflag"]);
    });
    let redFlagAdded = approvalOptionsArray.includes(true);
    let redFlagNotAdded = approvalOptionsArray.includes(false);
    this.toastrService.clear();
    
    // if (f.form.valid && (redFlagAdded == false || redFlagNotAdded == false)) {
    //   this.isRedFlagValid = false;
    //   if (redFlagAdded == false) {
    //     this.toastrService.error("Please flag atleast one option");
    //   }

    //   if (redFlagNotAdded == false) {
    //     this.toastrService.error("Please unflag atleast one option");
    //   }
    // } else {
    //   this.isRedFlagValid = true;
    // }

    if (f.form.valid && this.isRepeatDateSelected == true && this.isRedFlagValid == true) {
      let questionOptions = this.options;
      let optionsArray = {};
      let approvalOptionsArray = {};
      let i = 65;
      questionOptions.forEach(key => {
        if (i > 92) {
          this.toastrService.error("You cant have options more than 26");
          return;
        }
        optionsArray[String.fromCharCode(i)] = key;

        if (!this.selectedOptions[key]) {
          approvalOptionsArray[String.fromCharCode(i)] = "PRESENT";
        }
        i++;
      });

      var d1 = new Date(this.dateTime1);
      var d2 = new Date(this.dateTime2);
      let startingDate =
        (d1.getMonth() + 1).toString() +
        "/" +
        d1.getDate() +
        "/" +
        d1.getFullYear();
      //let startingTime = d2.getHours()+':'+d2.getMinutes()+':'+d2.getSeconds();

      //let startDateAndTime = startingDate +" "+ startingTime;
      let startDateConvert = new Date(startingDate);
      var startMillSec = startDateConvert.getTime();

      var d3 = new Date(this.dateTime3);
      //var d4 = new Date(this.dateTime4);
      let endingDate =
        (d3.getMonth() + 1).toString() +
        "/" +
        d3.getDate() +
        "/" +
        d3.getFullYear();
      //let endingTime = d4.getHours()+':'+d4.getMinutes()+':'+d4.getSeconds();

      //let endingDateAndTime = endingDate +" "+ endingTime;
      let endDateConvert = new Date(endingDate);
      var endMillSec = endDateConvert.getTime() + 86399999;

      let daySelection;
      if (this.repeatSelection != "MONTHLY") {
        daySelection = this.selectedDays;
      } else {
        daySelection = this.monthSelection;
      }

      let regularExp = this.commonService.getCronExpression(
        this.repeatSelection,
        daySelection
      );

      setTimeout(() => {
        //We added because to get the regular expression
        let data = {
          id: this.data["data"]["id"],
          question: this.model.title,
          values: optionsArray,
          approvalConditionValues: approvalOptionsArray,
          fieldType: "RADIO",
          startTime: startMillSec,
          endTime: endMillSec,
          frequency: this.repeatSelection.toUpperCase(), //Default values can be(DAILY,WEEKLY,MONTHLY)
          cronExpression: regularExp, //Generated CRON EXPRESSION From Dashboard
          active: "true",
          global: "false"
        };
        this.toastrService.clear();
        this.selfassesService.updateCustomCheckList(this.currentZone, data).subscribe(
          res => {
            this.toastrService.success("Updated successfully");
            this.dialogRef.close();
           // this.loading = false;
          },
          err => {
           // this.loading = false;
          },
          () => {
           // this.loading = false;
          }
        );
      }, 0);
    } else {
     // this.loading = false;
      return;
    }
  }

  //getQuestions
  getQuestion = () => {
    this.temSubmitEnable = false;
    this.oxymeterSubmitEnable = false;
    if (!!this.selectedEmsUserIds) {
      this.temperatureCheckDisaply = true; 
      this.oxymeterCheckDisplay = true
      //let userId = localStorage.getItem("ssadmin_userId");
      this.selfassesService
        .getQuestions(this.selectedEmsUserIds["userId"], this.currentZone)
        .subscribe(
          res => {
            this.questionList = res["response"]["questions"];
            if (!!this.questionList) {
              this.questionList.forEach(element => {
                element.options = this.getKeyValue(element.metadata.options);
              });
              this.selfAssessmentSoryy = false;
              this.selfAssessmentThank = false;
              // this.toastrService.success("There is no Self Assessment scheduled for today");  
            } else {
              this.temSubmitEnable = this.temperatureCheckEnabled;
              this.oxymeterSubmitEnable = this.oxymeterCheckEnable;
              this.selfAssessmentMsg = res["response"]["message"];
              this.selfassessmentRes = res["response"];
              if (res["response"]["passed"]) {
                this.selfAssessmentThank = true;
                this.selfAssessmentSoryy = false;
              } else {
                this.selfAssessmentThank = false;
                this.selfAssessmentSoryy = true;
              }

              //  this.toastrService.success("Your Self Assessment already submitted");
            }
          },
          err => {}
        );
      this.getTemperature();
      this.getAssesmentOxygen();
    } else {
      this.toastrService.success("Please select the Employee");
    }
  };

  getTemperature = () => {
    this.temperatureData = [];
    this.selfassesService
      .getTemperature(this.selectedEmsUserIds["userId"], this.data["zoneId"])
      .subscribe(
        res => {
          this.fahrenheitQuestionId = res["response"]["question"][0]["id"];
          this.temperatureList = res["response"]["answer"];
          this.temperatureList.forEach(element => {
            if (
              !!element.metadata.questionResponse[this.fahrenheitQuestionId] &&
              element.metadata.questionResponse[this.fahrenheitQuestionId]
                .response
            ) {
              this.temperatureData.push({
                createdDate: element.createdAt,
                tem:
                parseFloat(element.metadata.questionResponse[this.fahrenheitQuestionId]
                    .response.attributes.value),
                units:element.metadata.questionResponse[this.fahrenheitQuestionId]
                .response.attributes.unit,
              });
              this.tempShow = true; 
            }
          });
        },
        err => {}
      );
  };
  temperatureCheck = () => {
    this.selfassesService.temperatureCheck(this.data["zoneId"]).subscribe(
      res => {
        if (!!res["response"]) {
          let resCheck = JSON.parse(res["response"]);
          this.temperatureCheckEnabled = resCheck.temperatureCheckEnabled;
          this.oxymeterCheckEnable = resCheck.oxygenCheckEnabled;
        }
      },
      err => {}
    );
  };

  saveTemperature() {
    if (!!this.temValue) {
      let questionResponse = {};
      //questions[this.fahrenheitQuestionId] =  this.temValue;

      questionResponse[this.fahrenheitQuestionId] = {
        response: {
          operation: "VALIDATE_TEMPERATURE",
          attributes: {
            value: this.temValue,
            unit: this.temValueType == "F" ? "ferhanite" : "celsius"
          }
        }
      };

      let data = {
        submittedFor: this.selectedEmsUserIds["userId"],
        details: { questions: questionResponse }
      };
      this.selfassesService.saveTemperature(data, this.data["zoneId"]).subscribe(
        res => {
          this.temValue = null;
          this.temValueType = "F";
          this.temperatureList.unshift(res["response"]);
          this.temperatureData.unshift({
            createdDate: res["response"].createdAt,
            tem:
            parseFloat(res["response"].metadata.questionResponse[this.fahrenheitQuestionId]
                .response.attributes.value),
            units:res["response"].metadata.questionResponse[this.fahrenheitQuestionId]
            .response.attributes.unit,
          });
        },
        err => {}
      );
    } else {
      this.toastrService.error("Please enter user temperature");
    }
  }

  getNextRecords = () => {
    this.pageNo = this.pageNo + 1;
    let data: any = {};
    data.q = this.searchQuery;
    data.page = this.pageNo;
    data.size = this.pageSize;
    // return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
    this.emsService.getUserList(data, this.currentZone).subscribe(res => {
      let resData = (res as any).response["content"];

      resData.forEach(element => {
        let i = 0;
        this.allUsers.forEach(usr => {
          if (usr.userId == element.userId) {
            i = 1;
          }
        });
        if (i === 0) {
          this.allUsers.push(element); //  new user added
        }
      });
      // resData.forEach(element => {
      //   this.allUsers.push(element);
      // });
      if (this.allUsers.length > 0) {
        this.allUsers.filter(
          user => user.name.toLowerCase().indexOf(this.searchQuery) > -1
        );
      }
      this.filteredServerSideBanks.next(this.allUsers);
      this.totalPage = (res as any).response["totalPages"];
      if (this.totalPage > 1 && this.pageNo < this.totalPage) {
        this.showLoadMore = true;
      }
    });
  };

  submitQuestion = () => {
    let flag = true;

    let ansew = {};
    this.questionList.forEach(element => {
      ansew[element.id] = [element.defaultValue];
      if (!!element.defaultValue) {
        ansew[element.id] = [element.defaultValue];
      } else {
        flag = false;
      }
      // ansew.push({id:''});
    });
    let data = {
      submittedFor: this.selectedEmsUserIds["userId"],
      assessment: {
        questions: ansew
      }
    };
    if ((flag && !!this.temValue) || !this.temperatureCheckEnabled ) {
      if(this.temperatureCheckEnabled){
        this.saveTemperature();
      
      }
      if( !!this.oxygenReading || !this.oxymeterCheckEnable){ 
        if(this.oxymeterCheckEnable){
          this.submitoxygenAssesment();
        }
        
      }
      
      this.temperatureList = [];
      this.selfassesService.submitQuestions(data, this.currentZone).subscribe(
        res => {
          this.selfassessmentRes = res["response"];
          this.temSubmitEnable = this.temperatureCheckEnabled;
          this.oxymeterSubmitEnable = this.oxymeterCheckEnable;
          // this.toastrService.success("Your Self Assessment has submitted");
          //this.selfAssessmentThank = true;
          // this.selectedEmsUserIds = null;
          this.questionList = [];
          this.temValue = null;
          this.temValueType = "F";
          this.oxygenReading = null;
          this.selfAssessmentMsg = res["response"]["message"];
          if (res["response"]["passed"]) {
            this.selfAssessmentThank = true;
            this.selfAssessmentSoryy = false;
          } else {
            this.selfAssessmentThank = false;
            this.selfAssessmentSoryy = true;
          }
        },
        err => {}
      );
    } else {
      if (!flag) {
        this.toastrService.error("Please enter self assessment answers");
      } else if (this.temValue != "") {
        this.toastrService.error("Please enter user temperature");
      }
    }
  };

  getKeyValue = keyValue => {
    let arrayOption = [];
    Object.entries(keyValue).forEach(([key, val]) => {
      arrayOption.push({ key: key, value: val });
    });
    return arrayOption;
  };

  checkTemperature=(tem,temType)=>{
    let temChange = tem;
    let returnTemVal = true;
    
    if(temType == "ferhanite"){
      if(tem > this.maxTemVal){
        returnTemVal = false;
      }
    }else{
      temChange = tem * 9 / 5 + 32;
      if(temChange > this.maxTemVal){
        returnTemVal = false;
      }
    }
    
    return returnTemVal;
  }

  getAssesmentOxygen(){
    this.oxygenReads = [];
    this.selfassesService.getAssesmentOxygenReading(this.selectedEmsUserIds["userId"], this.data["zoneId"]).subscribe((res:any)=>{
 
       this.oxygenquestionId=res.response.question[0].id ;
       this.maxOximeter = res.response.question[0].metadata.ruleBasedCondition.VALIDATE_OXYGEN_READING.attributes.rules.min;  
       res.response.answer.forEach(element => {
        
        var quesResponse = {
          createdDate: element.createdAt,
          value: element.metadata.questionResponse[this.oxygenquestionId].response.attributes.value
            
        }
        this.oxygenReads.push(quesResponse);
        this.oxygenShow = true
       }); 
       
    },err=>{ 
    })

  }
  submitoxygenAssesment(){ 
    if (!!this.oxygenReading) {
      let questionResponse = {};  
      questionResponse[this.oxygenquestionId] = {
        response: {
          operation: "VALIDATE_OXYGEN_LEVEL",
          attributes: {
            value: this.oxygenReading,
            unit:  "percentage"
          }
        }
      };

      let data = {
        submittedFor: this.selectedEmsUserIds["userId"],
        details: { questions: questionResponse }
      };

      this.selfassesService.submitAssesmentOxygenReading(data,this.currentZone).subscribe((res:any)=>{
        this.oxygenReading = null;
            this.oxygenReads.unshift({
              createdDate: res["response"].createdAt,
              value: res["response"].metadata.questionResponse[this.oxygenquestionId]
              .response.attributes.value
            });
  
        
      },err=>{ 
      })
    } else {
      this.toastrService.error("Please Enter User Oxymeter  Reading")
    }
  }
  checkValidOxy(reading){
    if(reading>100){
      this.oxygenReading = 100;
    }
  }

  checkOxygen(value){
    var retMeter = true; 
     if(value <this.maxOximeter ){
      retMeter = false;
     }
     return retMeter
  }

  disableAutomaticResponse = "";
  selectResponseFormat(e) {
    if(e.value == "radio") {
      this.responseFormatType = "radio";
      this.disableAutomaticResponse = "";
    } else if(e.value == "checkBox") {
      this.responseFormatType = "checkBox";
      this.disableAutomaticResponse = "";
    } else if(e.value == "inputBox") {
      this.responseFormatType = "inputBox";
      this.disableAutomaticResponse = "disabled";
    } else {
      this.responseFormatType = "date";
      this.disableAutomaticResponse = "";
    }
  }

  selectResponse() {}

  scheduleTimeChange = () =>{
    if (this.scheduleTimeType == "PM") {
      this.scheduleTimeType = "PM";
    } else {
      this.scheduleTimeType = "AM";
    }
    
  }
  addOptions1(){
    if(this.optionValues.length==0)
      return;
    this.options.push(this.optionValues);
    this.optionValues = '';
  }
  removeOption(i){
    this.options.splice(i, 1);
  }
}
