import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AssetModalComponent } from '../../asset-modal/asset-modal.component';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
  serviceDataSource = [
    {
      date: '1616437521811',
      time: '',
      description: 'Cooler was replaced with a new component',
      cost: 'USD 45'
    }
  ];
  displayedColumns = ['date', 'time', 'description', 'cost', 'invoice', 'action'];
  private unsubscribe$ = new Subject<void>();
  pageType;
  currentZone;

  constructor(public dialogRef: MatDialogRef<AssetModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.currentZone = this.data["currentZone"];
    this.pageType = this.data["type"];
  }

  ngOnInit() {
  }

  editAsset(ele) {}

  deleteAsset(ele) {}

  getBookTime = (element) => {
    let startTime = element.requestDetails.recurringStartTime.toString();
    //let endTime = element.requestDetails.recurringEndTime.toString();
    let bookingStart = "00:00";
    //let bookingEnd = "23:59";
    if(startTime.length === 2) {
      startTime = "00"+startTime;
    }
    // if(endTime.length === 2) {
    //   endTime = "00"+endTime;
    // }
    if(startTime.length === 3) {
      startTime = 0+startTime;
    }
    // if(endTime.length === 3) {
    //   endTime = 0+endTime;
    // }
    bookingStart = (startTime.length !==1)? `${startTime.slice(0,2)}:${startTime.slice(2,4)}`  : bookingStart;
    //bookingEnd = (endTime.length !==1)? `${endTime.slice(0,2)}:${endTime.slice(2,4)}` : bookingEnd;
    //return `${bookingStart} - ${bookingEnd}`
    return `${bookingStart}`
}
}
