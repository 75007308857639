import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import { environment, modulesIds } from "../../../environments/environment";
import { ZoneAndModuleHttpParams } from "../zone-and-module-http-params/zone-and-module-http-params.component";
import { Observable } from "rxjs";
import { map, debounceTime, shareReplay, distinctUntilChanged } from "rxjs/operators";
import { BehaviorSubject, Subject, of } from "rxjs";
import { catchError} from "rxjs/operators";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import { v4 as uuidv4 } from 'uuid';
@Injectable()
export class EMSService {
    private moduleId;
    private readonly BASE_URL;
    constructor(private http: HttpClient) {
        this.moduleId = 22;
        this.BASE_URL = environment.BASE_URL + "ems/";
    }

    getAllModules(zoneId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"END_USER_MOBILE_APP","zoneId":${zoneId}, "sessionId":"${localStorage.getItem("ssadmin_session")}",
                    "correlationId":"${uuidv4()}"}`
            })
        };
        return this.http
            .get(this.BASE_URL + "module/all", {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .map(function(response) {
                return response;
            });
    }

    getAllModulesByZones(zoneId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"END_USER_MOBILE_APP","zoneId":${zoneId}, "sessionId":"${localStorage.getItem("ssadmin_session")}",
                    "correlationId":"${uuidv4()}"}`
            })
        };
        return this.http
            .get(this.BASE_URL + "module-zone/get-modules-by-zone/"+zoneId, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .map(function(response) {
                return response;
            });
    }
    getModuleViewedByZone(zoneId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"END_USER_MOBILE_APP","zoneId":${zoneId}, "sessionId":"${localStorage.getItem("ssadmin_session")}",
                    "correlationId":"${uuidv4()}"}`
            })
        };
        return this.http
            .get(this.BASE_URL + "module-zone/get-modules-viewed-by-zone/"+zoneId, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .map(function(response) {
                return response;
            });
    }
    saveViewedModules(zoneId,moduleId,postObj) {
        moduleId = moduleId == 90001 || moduleId == 20000 || moduleId == 15001 || moduleId == 12002 || moduleId == 16000 ? 12001 : moduleId;
        if(moduleId==19000 || moduleId == 20000 || moduleId == 15001 || moduleId== 16000)moduleId=12001
        return this.http.post(this.BASE_URL + "module-zone/save-module-viewed-by-zone/"+zoneId, postObj, {
            params: new ZoneAndModuleHttpParams(zoneId, moduleId)
        });
    }
    
    getzoneByModules(zoneId,moduleId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"END_USER_MOBILE_APP","zoneId":${zoneId}, "sessionId":"${localStorage.getItem("ssadmin_session")}",
                    "correlationId":"${uuidv4()}"}`
            })
        };
        return this.http
            .get(this.BASE_URL + "module-zone/get-zones-by-module/"+moduleId, {
                params: new ZoneAndModuleHttpParams(zoneId, moduleId)
            })
            .map(function(response) {
                return response;
            });
    }
    scheduleDemo(zoneId,moduleId,postObj, category?){
        let url = "module-zone/save";
        if(category === 'SMART_ACCESS'){
            return this.http.post(this.BASE_URL + url, postObj, {
                params: new ZoneAndModuleHttpParams(zoneId)
            });    
        }
        return this.http.post(this.BASE_URL + url, postObj, {
            params: new ZoneAndModuleHttpParams(zoneId, moduleId)
        });
    }

    getUserProfile(userId, zoneId) {
        return this.http.get(
            this.BASE_URL + "user/userDetails?users=" + userId
        );
    }

    validateModuleId() {
        let userId = localStorage.getItem("ssadmin_user");
        let zoneId = localStorage.getItem("currentZone");

        this.getUserProfile(userId, zoneId).subscribe(res => {
            return res["response"].length !== 0 ? true : false;
        });
    }

    validateSession(moduleName) {
        let zoneId = 5;
        if (!moduleName) {
            moduleName = "";
        }
        let requestobj = {
            moduleName: moduleName
        };
        return this.http.post(this.BASE_URL + "auth/validateUser", requestobj, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getConfig(moduleId, zoneId, roleId) {
        let url = this.BASE_URL + "module/getConfig";
        let queryParams = "?moduleId={moduleId}&zoneId={zoneId}&clientId={clientId}&roleId={roleId}"
            .replace("{moduleId}", moduleId)
            .replace("{zoneId}", zoneId)
            .replace("{clientId}", "4")
            .replace("{roleId}", roleId);
        return this.http.get(url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getCurrentUserModules(zoneId,proxyId?) {
        let proxyParam = '';
        if(typeof(proxyId) != 'undefined' && proxyId != null && proxyId!='') {
            proxyParam = `?proxyId=${proxyId}`;
        }

        let url = this.BASE_URL + "user/myProfile"+proxyParam;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
        .pipe(
            shareReplay(1),
            distinctUntilChanged()
        );
    }

    getCurrentUserModulesHybridRequestProxyApprovals(zoneId, proxyId) {
        let url;
        if(!!proxyId) {
            url = `user/myProfile?proxyId=${proxyId}`;
        } else {
            url = `user/myProfile`;
        }
        return this.http.get(this.BASE_URL + url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
        .pipe(
            shareReplay(1),
            distinctUntilChanged()
        );
    }

    searchHosts(term, zoneId) {
        let apiUrl =
            this.BASE_URL + "user/all?searchColumn=email&searchValue=" + term;
        var hosts = this.http
            .get(apiUrl, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(
                debounceTime(500),
                map((data: any) => {
                    return data.response.totalPages != 0
                        ? (data as any[])
                        : [{ name: "No Record Found" } as any];
                })
            );
        return hosts;
    }
    searchHostsUser(term, zoneId) {

        let apiUrl = this.BASE_URL + "user/search/v3?searchString=" + term + "&searchParams=name";
        var hosts = this.http.get(apiUrl, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) }).pipe(
            debounceTime(500),
            map(
                (data: any) => {

                    return (
                        data.response.totalPages != 0 ? data.response as any[] : [{ "name": "No Record Found" } as any]
                    );
                }
            )
        );
        return hosts;
    }
    //anpr
    getchildZonesByLevel(obj, zoneId) {
        const endpoint = "zones/childZones";
        return this.http.post(this.BASE_URL + endpoint, obj, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public getchildZonesByLevelv2(data, zoneId): Observable<Object> {
        const endpoint = this.BASE_URL + "zones/childZones/v2";
        return this.http
            .post(endpoint, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(shareReplay());
    }


    getAnalyticsStatus(zoneId) {
        
        let url =  this.BASE_URL + `config/getByZoneId?zoneId=${zoneId}&key=DOWNLOAD_REPORTS`;

        return this.http.get(url  , {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    //jumpree-members starts
    createMember(postObj, zoneId) {
        let url = "user/admin/addUser";
        let queryParams = "";
        return this.http.post(this.BASE_URL + url + queryParams, postObj, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    onboardMembersinBulkByCSV(postObj, zoneId) {
        let url = "user/addUser/bulk";
        let queryParams = "";
        return this.http.post(this.BASE_URL + url + queryParams, postObj, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    onboardMembersToAppInBulkByCSV(postObj, zoneId) {
        let url = "user/addUser/bulk/v2";
        let queryParams = "";
        return this.http.post(this.BASE_URL + url + queryParams, postObj, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    onboardMembersToAppInBulkBySampleCSV(zoneId) {
        let url = "user/template/addUser/bulk";
        let queryParams = "";
        return this.http.get(this.BASE_URL + url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    changeEMSUserStatus(postObj, zoneId) {
        const endpoint = "user/admin/changeStatus";
        return this.http.post(this.BASE_URL + endpoint, postObj, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    //jumpree-members ends
    public getZonesByLevel(zoneId, level): Observable<Object> {
        const endpoint = this.BASE_URL + "zones/getAllZones?level=" + level;
        return this.http.get(endpoint, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public getAllZoneDataByLevel(zoneId, level) {
        //const endpoint = "http://10.1.20.135:12111/ems/zones/getAllZones?level=floor";
        const endpoint =
            this.BASE_URL + "zones/getAllZoneLocationsBySite?level=" + level+'&siteId='+zoneId;
        // const endpoint = this.BASE_URL+"zones/getZoneLocationsOfSite?level="+level;
        return this.http.get(endpoint, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    public getEMSUsers(zoneId, page, size): Observable<Object> {
        let url = "user/all";
        let queryParams = "?page={page}&size={size}"
            .replace("{page}", page)
            .replace("{size}", size);
        return this.http.get(this.BASE_URL + url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public addZones(data, zoneId): Observable<Object> {
        const endpoint = this.BASE_URL + "zones";
        return this.http.post(endpoint, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public enableZone(data, zoneId): Observable<Object> {
        const endpoint = this.BASE_URL + "zones/enableZone?zoneId=" + zoneId;
        return this.http.post(endpoint, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public disableZone(data, zoneId): Observable<Object> {
        const endpoint = this.BASE_URL + "zones?zoneId=" + zoneId;
        return this.http.delete(endpoint, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public getParentZones(zoneId, zoneIds) {
        const endpoint = this.BASE_URL + "zones/zoneLocation?zones=" + zoneIds;
        return this.http.get(endpoint, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public getUserListByEmailName(zoneId, searchItem) {
        const endpoint = `user/search/name/email?name=${searchItem}`;
        return this.http.get(this.BASE_URL + endpoint, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    public getUserListByEmailNamePaginatined(zoneId, data) {
        const endpoint = `user/search/name/email?name=${data["q"]}&page=${data["page"]}&size=${data["size"]}`;
        return this.http.get(this.BASE_URL + endpoint, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    public getUserList(data, zoneId): Observable<any> {
        let url = `${this.BASE_URL}user/search/name?q=${data["q"]}&page=${data["page"]}&size=${data["size"]}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public getUserListByTenantId(data, zoneId): Observable<any> {
        let url = `${this.BASE_URL}user/search/name/emailAndTenant?name=${data["q"]}&page=${data["page"]}&size=${data["size"]}&tenantId=${data["tenantId"]}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    public getUserListByEmail(data, zoneId): Observable<any> {
        let url = `${this.BASE_URL}user/search/email?email=${data["q"]}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    public manageTicketTableSizeSource = new BehaviorSubject<number>(0);

    tableSize = this.manageTicketTableSizeSource.asObservable();
    setSize(size: number) {
        this.manageTicketTableSizeSource.next(size);
    }

    public getAllSites(zoneId){
        return this.http.get(this.BASE_URL + "zones/allSites/user", {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        }).pipe(
            catchError(err => of([]))
        );;
    }

    public getActiveDigitalIntro(zoneId) {
        const endpoint = this.BASE_URL + "introductoryScreens/getVisited";
        //const endpoint = "https://digitaldev.smartenspaces.com/ems/introductoryScreens/reset";
        return this.http.get(endpoint, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    public postVisitedDigitalIntro(zoneId, visitedIntroData) {

        const endpoint = this.BASE_URL + "introductoryScreens/markVisited?ids="+visitedIntroData;
        // const endpoint = 'https://digitaldev.smartenspaces.com/ems/' + "introductoryScreens/markVisited?ids="+visitedIntroData;
        return this.http.post(endpoint, {}, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    public getDigitalSettingsConfig(zoneId) {
        const endpoint = this.BASE_URL + "module/config";
        let zoneid = null;
        // const endpoint = 'https://digitaldev.smartenspaces.com/ems/' + "module/config";
        return this.http.get(endpoint, {
            // params: new ZoneAndModuleHttpParams(zoneid, this.moduleId)
            headers: new HttpHeaders({
                // 'ss-header': `{"correlationId":"e8aa1683-b874-41a8-a682-d57ec9906546","version":"1.0","zoneId":5,"clientKey":"ADMIN_WEB_APP","mouduleId": "${this.moduleId}"`
                'ss-header': `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId": "${this.moduleId}","sessionId": "${localStorage.getItem("ssadmin_session")}"}`
            })
        });
    }

    public postDigitalSettingsConfig(zoneId, configData) {
        let zoneid = null;
        // {"config":{{updated config json as string}},"moduleId":22,"zoneId":8}
        let data = {"config":JSON.stringify(configData),"moduleId":22,"zoneId":null};
        console.log('settings post data... ', data)
        // const endpoint = 'https://digitaldev.smartenspaces.com/ems/' + "module/updateConfig?generate=true";
        const endpoint = this.BASE_URL + "module/global-admin/updateConfig?generate=false";
        return this.http.post(endpoint, data, {
            headers: new HttpHeaders({
                // 'ss-header': `{"correlationId":"e8aa1683-b874-41a8-a682-d57ec9906546","version":"1.0","zoneId":5,"clientKey":"ADMIN_WEB_APP","mouduleId": "${this.moduleId}"`
                'ss-header': `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId": "${this.moduleId}","sessionId": "${localStorage.getItem("ssadmin_session")}"}`
            })
        });
    }

    getFloorSenseUrl(zoneId) {
let url= this.BASE_URL + 'locker/access';
// "http://amenitiesbookingqa.smartenspaces.com/ems/locker/access",
        return this.http.get(
          url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        }
        );
    }

}
