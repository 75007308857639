<div>
  <div class="col-sm-12" *ngIf="data.type == 'preview'">
    <div class="modal-header">
      <h4 class="modal-title">
        <!--*ngIf="data.type != 'addUserRole'"-->
        {{ "cardPreview" | translate }}
      </h4>
      <button
        id="close_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="onClosePreViewModal('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <div
            [style.background-image]="'url(' + this.data.imgFile + ')'"
            style="
              width: 261px;
              height: 342px;
              margin: 10px;
              border-radius: 15px;
              background-repeat: no-repeat;
              justify-content: center;
            "
          >
            <div style="height: 150px">
              <div style="padding: 10px 0px 0px 16px">
              <p class="title-style">
                {{ data.createCradObject.titleone }}
              </p>
              <p class="title-style">
                {{ data.createCradObject.titletwo }}
              </p>
            </div>
              <div style="padding: 10px 0px 0px 16px">
              <p class="title-style">
                {{ data.createCradObject.tagone }}
              </p>
              <p class="title-style">
                {{ data.createCradObject.tagtwo }}
              </p>
            </div>
            </div>
            <div 
            [ngClass]="(!!data.createCradObject.modules && data.createCradObject.modules.length == 1)?'example-container-child service-backgroundSingle':'example-container-child service-background'"
            >
              <div (click)= "clickMore(data.createCradObject.modules,0)" id="click_more" class="more-service">
                <span *ngIf="data.createCradObject.moduleMore" >
                  {{data.createCradObject.more}} {{'services' | translate}} 
                  <i class="fa fa-arrow-right more-arrow" aria-hidden="true"></i>
                </span>
                
              </div>
              <div  *ngIf="!!data.serviceList && data.serviceList.length != 1" >
                <div
                  *ngFor="let item of data.createCradObject.modules.slice(0,6); index as s"
                  style="float: left; height: 70px"
                >
                  <div  >
                    <div class="example-box-child">
                      <img
                        [src]="item.sidemenuImageUrl"
                        alt="service icon"
                        style="width: 25px"
                      />

                    </div>
                    <div
                      style="
                        width: 50px;
          height: 25px;
          font-family: OpenSans;
          font-size: 9px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin:5px 8px 5px 0px;
                      "
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="!!data.serviceList && data.serviceList.length == 1"
                class="single-service"
              >
                <p class="callToAction">{{ data.createCradObject.callToAction }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <button
              (click)="onClosePreViewModal('save')"
              class="btn btn-main pull-right"
            >
              {{'save' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="data.type == 'deleteCard'">
    <div class="modal-header">
      <h4 class="modal-title">
        <!--*ngIf="data.type != 'addUserRole'"-->
        {{ "deleteCard" | translate }}
      </h4>
      <button
        id="close_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="onCloseDeleteCardNo()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p style="text-align: center">
        {{ "doYouWantToDeleteCard" | translate }}
      </p>
      <div class="buttonCenter">
        <button (click)="onCloseDeleteCard()" id="close_del_card" class="btn btn-main">{{'yes' | translate}}</button>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="data.type == 'deleteservice'">
    <div class="modal-header">
      <h4 class="modal-title">
        <!--*ngIf="data.type != 'addUserRole'"-->
        {{ "deleteCard" | translate }}
      </h4>
      <button
        id="close_del_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="onCloseDeleteService('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p style="text-align: center">
        {{'doYouWantToDelete' | translate}} {{ data.title | translate }} {{'card' | translate | lowercase}}?
      </p>
      <div class="buttonCenter">
        <button id="close_del_service" (click)="onCloseDeleteService(true)" class="btn btn-main">
          {{'yes' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="data.type == 'alReady'">
    <div class="modal-header">
      <h4 class="modal-title">
        <!--*ngIf="data.type != 'addUserRole'"-->
        {{ "newCard" | translate }}
      </h4>
      <button
        id="close_card_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="onCloseAlReadyCard('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p style="text-align: center">
        {{
          "serviceAlreadyExistLikeToReplaceAndContinue"
            | translate
        }}
      </p>
      <div class="buttonCenter">
        <button
          style="margin-right: 10px"
          (click)="onCloseAlReadyCard(false)"
          class="btn btn-main btn-secondary"
        >
          {{'no' | translate}}
        </button>
        <button id="close_already_card_btn" (click)="onCloseAlReadyCard(true)" class="btn btn-main">{{'yes' | translate}}</button>
      </div>
    </div>
  </div>

  <div class="col-sm-12" *ngIf="data.type == 'imagecrop'">
    <div class="modal-header">
      <h4 class="modal-title">
        <!--*ngIf="data.type != 'addUserRole'"-->
        {{ "cropThePhoto" | translate }}
      </h4>
      <button
        id="close_modal_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="onCloseRoleModalClose()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="overflow-y: inherit">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="3 / 4"
        [transform]="transform"
        [cropperMinWidth]="0"
        [cropperMinHeight]="0"
        [resizeToWidth]="0"
        [resizeToHeight]="0"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <div class="row">
        <div class="col-lg-4"></div>

        <div class="col-lg-2">
          <div>
            <span id="zoom_out"
              (click)="zoomOut()"
              style="font-size: 28px; margin-right: 10px"
              class="fa fa-search-minus"
            ></span>
            <span id="zoom_in"
              (click)="zoomIn()"
              style="font-size: 28px; margin-right: 10px"
              class="fa fa-search-plus"
            ></span>
          </div>
        </div>
        <div class="col-lg-2">
          <button id="crop_btn" (click)="onCloseRoleModal()" class="btn btn-main">
            {{'cropAndUpload' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container" style="border-radius:10px" *ngIf="data.type == 'sendNotification'">
    <div class="modal-header">
      <h4 class="modal-title">
        <!--*ngIf="data.type != 'addUserRole'"-->
        {{ "confirmationNeeded" | translate }}
      </h4>
      <button
        id="close_del_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="onCloseDeleteService('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {{'onceConfirmMessageSelectedUserImmediatelyNotified' | translate}}.  
      </p>
      <p>
        {{'noOptionToEditMessageOnceSent' | translate}}.
      </p>
      <div class="buttonRight">
        <button id="send_notification_btn" (click)="sendNotifications()" class="btn btn-main pull-left">
          {{'send' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="data.type == 'outlookIntegration'">
    <div class="modal-header">
      <h4 class="modal-title">
        <!--*ngIf="data.type != 'addUserRole'"-->
        {{ "setUpAzureActiveDirectory" | translate }}
      </h4>
      <button
        id="close_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="onClosesetUpDir(data.type)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputAccountNumber">Application Client ID</label>
          <input type="text" class="form-control" [(ngModel)]="loginDetails.applClientId"
            [ngModelOptions]="{standalone: true}" id="application_client_id" placeholder="********"
            >
        </div>
        <div class="form-group col-md-6"> 
          <label for="inputAccountNumber">Directory Tenant ID</label>
          <input type="text" class="form-control" id="dir_id" [(ngModel)]="loginDetails.dir_tenant_id"
            [ngModelOptions]="{standalone: true}" placeholder="********"
            >
          </div>
        <div class="form-group col-md-6">
          <label for="inputAccountNumber">Client Key Secret</label>
          <input type="text" class="form-control" id="client_secret" [(ngModel)]="loginDetails.clientSecret"
            [ngModelOptions]="{standalone: true}" placeholder="******">
        </div>
        <div class="form-group col-md-6">
          <label for="inputAccountNumber">Outlook Mail Box</label>
          <input type="text" class="form-control" id="outlook_email" [(ngModel)]="loginDetails.outlookEmail"
            [ngModelOptions]="{standalone: true}" placeholder="">
        </div>
        <div class="form-group col-md-6">
          <label for="inputAccountNumber">Meeting Room Email</label>
          <input type="text" class="form-control" id="meeting_room_email" [(ngModel)]="loginDetails.meetingroomEmail"
            [ngModelOptions]="{standalone: true}" placeholder="">
        </div>
        <div class="form-group col-md-6">
          <label for="AddressinputAddress">Timezone</label>
          <div class="input-group">
            <mat-form-field class="custom-mat" appearance="fill">
              <mat-select [(ngModel)]="timezone" name="zone" placeholder="{{'select' | translate}}" id="zone_name"
              [ngModelOptions]="{standalone: true}">
                      <mat-option *ngFor="let zone of timeZones" value="{{zone.value}}">
                        {{ zone.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </div>
        <div class="form-group col-md-12">&nbsp;</div>
      </div>
    </div>
      <div class="">
        <!-- <button id="close_del_card" class="btn btn-main">{{'help' | translate}}</button> -->
        <button id="setup_card" [disabled]="isDisabled" (click)="setUpIntegration()" class="btn btn-main float-right ml-4">{{'setup' | translate}}</button>
        <button id="cancel_card" (click)="cancelCard()" class="btn btn-main float-right ml-4">{{'cancel' | translate}}</button>
        <button id="test_card" class="btn btn-main float-right" (click)="testIntegration()">
          {{'testIntegration' | translate}}</button>
      </div>
    
    </div>
  </div>

  <div class="container" *ngIf="data.type == 'setUpAzure'">
    <div class="modal-header">
      <h4 class="modal-title">
        <!--*ngIf="data.type != 'addUserRole'"-->
        {{ "setUpAzureActiveDirectory" | translate }}
      </h4>
      <button
        id="close_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="onClosesetUpDir(data.type)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="inputAccountNumber">Application Client ID</label>
          <input type="text" class="form-control" [(ngModel)]="loginDetails.applClientId"
            [ngModelOptions]="{standalone: true}" id="application_client_id" placeholder="********"
            >
        </div>
        <div class="form-group col-md-6"> 
          <label for="inputAccountNumber">Directory Tenant ID</label>
          <input type="text" class="form-control" id="dir_id" [(ngModel)]="loginDetails.dir_tenant_id"
            [ngModelOptions]="{standalone: true}" placeholder="********"
            >
          </div>
        <div class="form-group col-md-6">
          <label for="inputAccountNumber">Client Key Secret</label>
          <input type="text" class="form-control" id="client_secret" [(ngModel)]="loginDetails.clientSecret"
            [ngModelOptions]="{standalone: true}" placeholder="******">
        </div>
        <div class="form-group col-md-6">
          <label for="inputAccountNumber">Outlook Mail Box</label>
          <input type="text" class="form-control" id="outlook_email" [(ngModel)]="loginDetails.outlookEmail"
            [ngModelOptions]="{standalone: true}" placeholder="">
        </div>
        <div class="form-group col-md-6">
          <label for="inputAccountNumber">Meeting Room Email</label>
          <input type="text" class="form-control" id="meeting_room_email" [(ngModel)]="loginDetails.meetingroomEmail"
            [ngModelOptions]="{standalone: true}" placeholder="">
        </div>
        <div class="form-group col-md-12">&nbsp;</div>
      </div>
    </div>
      <div class="">
        <!-- <button id="close_del_card" class="btn btn-main">{{'help' | translate}}</button> -->
        <button id="setup_card" class="btn btn-main float-right ml-4">{{'setup' | translate}}</button>
        <button id="cancel_card" (click)="cancelCard()" class="btn btn-main float-right ml-4">{{'cancel' | translate}}</button>
        <button id="test_card" class="btn btn-main float-right" (click)="testIntegration()">
          {{'testIntegration' | translate}}</button>
      </div>
    
    </div>
  </div>

</div>
