<div
    [ngClass]="[
        data.type != 'newRequest' ? 'd-none' : 'd-block',
        data.type != 'editRequest' ? 'd-none' : 'd-block'
    ]"
>
    <!-- <div *ngIf="pageType === 'sendRequest'"> 
        [hidden]="data.type != 'newRequest' || data.type != 'editRequest'"-->
    <div class="modal-header">
        <h4
            id="send_request_title"
            class="modal-title"
            *ngIf="data.type == 'newRequest'"
        >
            {{ "newRequests" | translate }}
        </h4>
        <h4
            id="send_request_title"
            class="modal-title"
            *ngIf="data.type == 'editRequest'"
        >
            {{ "editRequest" | translate }}
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid p-0">
            <mat-tab-group>
                <mat-tab
                    label="Space"
                    *ngIf="isShowable('demandSupply_newRequest_Space')"
                >
                    <ng-template matTabContent>
                        <app-create-space-request
                            [data]="data"
                            (viewInsightsData)="getViewInsightsData($event)"
                            (pageType)="changePageType($event)"
                            (createdRequestId)="requestIdEvent($event)"
                            [currentZone]="data.currentZone"
                        ></app-create-space-request>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Move"
                    *ngIf="isShowable('demandSupply_newRequest_Move') || true"
                >
                    <ng-template matTabContent>
                        <span class="vh-60">
                            <app-create-move-request
                                (viewInsightsData)="getViewInsightsData($event)"
                                (pageType)="changePageType($event)"
                                (moveDataObj)="getMoveRequestData($event)"
                                (createdRequestId)="requestIdEvent($event)"
                                [currentZone]="data.currentZone"
                                [config]="data.config"
                            ></app-create-move-request>
                        </span>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <!-- </div> -->
</div>
<div
    *ngIf="data.type == 'saveNotification' || data.type == 'updateNotification'"
>
    <div class="modal-header">
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeConfirmationPopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="data.type == 'saveNotification'">
            <b
                >Your request has been succesfully submitted. Your request ID is
                {{ requestId }}</b
            >
        </div>
        <div *ngIf="data.type == 'updateNotification'">
            <b
                >Your request ID with {{ requestId }} has been succesfully
                updated</b
            >
        </div>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end mb-2">
        <button
            mat-button
            class="btn-main mr-2 rounded-pill"
            (click)="closeConfirmationPopup()"
            cdkFocusInitial
        >
            Ok
        </button>
    </div>
</div>
<div *ngIf="data.type == 'preferredAllocation'">
    <div class="modal-header d-flex align-items-center border-bottom-0">
        <img class="block-img" src="./assets/images/preferred-allocation.svg" />
        <div class="col">
            <h6 id="send_request_title" class="modal-title ">
                <span class="borderBottom"
                    >{{
                        "currentAllocationInPreferredLocation" | translate
                    }}
                    ({{
                        preferredZoneEntityInsightList.values[0].floorCounts[0]
                            .zoneLocationDto.site.name
                    }})</span
                >
            </h6>
        </div>
        <button
            type="button"
            class="close ml-5"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="col">
                <div class="col d-flex p-0">
                    <div
                        class="col-lg-4 col-md-4 col-sm-12 mt-4"
                        *ngFor="
                            let building of preferredZoneEntityInsightList.values;
                            let i = index
                        "
                    >
                        <div class="text-left">
                            <b class="ml-3 text-left">{{
                                building.building.name
                            }}</b>
                        </div>
                        <mat-card class="cardSize mt-2 mat-elevation-z3">
                            <div
                                *ngFor="
                                    let floor of building.floorCounts;
                                    let j = index
                                "
                            >
                                <div
                                    *ngIf="floor.entityTypeCounts.length"
                                    class="vh-25 position-relative borderBottom"
                                    [ngClass]="{
                                        borderBottom:
                                            j != building.floorCounts.length - 1
                                    }"
                                >
                                    <div
                                        class="d-flex justify-content-between"
                                        [ngClass]="{ 'mt-3': j > 0 }"
                                    >
                                        <div>
                                            {{
                                                floor.zoneLocationDto.name
                                                    | slice: 0:20
                                            }}<span
                                                *ngIf="
                                                    floor.zoneLocationDto.name
                                                        .length > 20
                                                "
                                                >...</span
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex align-items-center mt-3 flex-wrap"
                                    >
                                        <ng-container
                                            *ngFor="
                                                let workstation of floor.entityTypeCounts;
                                                let k = index
                                            "
                                        >
                                            <div
                                                class="totalCircle circlePosition"
                                                [ngStyle]="{
                                                    'background-color': getLegendColor(
                                                        workstation.entityType
                                                            .id
                                                    )
                                                }"
                                                [ngClass]="k > 0 ? '' : ''"
                                            ></div>
                                        </ng-container>
                                        <div class="col ml-2 text-left">
                                            {{ floor.totalCountAvailable }}/{{
                                                floor.countRequired
                                            }}
                                            <span class="fs-12"
                                                >(Available/Required)</span
                                            >
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3">
                                        <div
                                            class="align-items-center"
                                            *ngFor="
                                                let workstation of floor.entityTypeCounts;
                                                let l = index
                                            "
                                            [ngClass]="
                                                floor.entityTypeCounts.length ==
                                                1
                                                    ? 'd-none'
                                                    : 'd-flex'
                                            "
                                        >
                                            <div
                                                class="smallCircle"
                                                [ngClass]="l > 0 ? 'ml-3' : ''"
                                                [ngStyle]="{
                                                    'background-color': getLegendColor(
                                                        workstation.entityType
                                                            .id
                                                    )
                                                }"
                                            ></div>
                                            <div class="ml-2">
                                                {{
                                                    workstation.availableCount
                                                }}/{{
                                                    getRequiredWorkstationCount(
                                                        workstation.entityType
                                                            .id
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <div class="col d-flex flex-wrap mt-4">
                    <div
                        class="d-flex align-items-center"
                        *ngFor="
                            let entity of preferredZoneEntityInsightList.entityTypeMap
                                | keyvalue;
                            let m = index
                        "
                    >
                        <div
                            class="smallCircle ml-3"
                            [ngStyle]="{
                                'background-color': getLegendColor(entity.key)
                            }"
                            [ngClass]="m > 0 ? 'ml-3' : ''"
                        ></div>
                        <div class="ml-2">
                            {{ entity.value.name }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col d-flex" *ngFor='let index of preferredLocationList; let i = index; let even = even'>
                <div class="col">
{{data}}
                </div>
                <div class="col">
                    {{data}}

                </div>
            </div> -->
            <!-- <mat-grid-list cols="2" rowHeight="1:0.8" [gutterSize]="'10px'">
                <mat-grid-tile *ngFor="let location of preferredLocationList">
                    <mat-grid-tile-header class=""><h6 ><b> Building 6A </b></h6></mat-grid-tile-header>
                    <mat-card class="cardSize mb-1 cardPosition h-auto" >
                        <span *ngFor="let floor of location.floors; let i = index">
                            <div class="col p-0 h-50" [ngClass]="[i > 0 ? 'margin-top-2' : '', (i == 0 && location.floors.length > 1) ? 'margin-bottom-1' : '']">
                                <div class="col p-0">
                                    <div class="col-4 p-0 text-left">{{floor.name}}</div>
                                </div>
                                <div class="col d-flex p-0 mt-2">
                                    <div class="p-0 d-flex align-items-center" *ngFor="let entity of floor.entities; let j = index">
                                        <div class="circle" [ngStyle]="{'background-color':entity.color}" [ngClass]="j > 0 ? 'circlePosition' : '' "></div>
                                    </div>
                                    <div class="col p-0">
                                        {{floor.used}}/{{floor.total}} (used/allocated)
                                        <div class="col mt-2 d-flex ml-23">
                                            <div *ngFor="let entity of floor.entities; let k = index">
                                                <div class="col p-0 d-flex align-items-center" [ngClass]="k > 0 ? 'ml-4' : ''">
                                                    <div class="circle" [ngStyle]="{'background-color':entity.color}">
                                                        
                                                    </div>
                                                    <div>
                                                        {{entity.used}}/{{entity.total}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="i != location.floors.length-1"></mat-divider>
                        </span>
                    </mat-card>
                </mat-grid-tile>
              </mat-grid-list>
            <div class="col p-0 d-lg-flex d-md-flex">
                <div clas="col">
                    <mat-card></mat-card>
                </div>
                <div clas="col">
                    <mat-card></mat-card>
                </div>
                <div clas="col">
                    <mat-card></mat-card>
                </div>
            </div> -->
        </div>
    </div>
</div>
<div *ngIf="data.type == 'availability'">
    <div class="modal-header d-flex align-items-center border-bottom-0">
        <img class="block-img" src="./assets/images/calendar-checked.svg" />
        <div class="col">
            <h6 id="send_request_title" class="modal-title ">
                <span class="borderBottom">{{
                    "availabilityInYourPreferredLocation" | translate
                }}</span>
            </h6>
        </div>
        <button
            type="button"
            class="close ml-5"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid p-0">
            <table
                mat-table
                [dataSource]="availabilityDataSource"
                class="form-group"
            >
                <ng-container matColumnDef="workstation">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "requiredWorkstation" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        {{ element?.entityType?.name || "NA" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "quantity" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        {{ element?.availableCount || "NA" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="availability">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "availability" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <div
                            class="d-flex align-items-center justify-content-center"
                            *ngIf="element.isDemandSatisfied"
                        >
                            <div class="availableCircle mr-2"></div>
                            {{ "available" | translate }}
                        </div>
                        <div
                            class="d-flex align-items-center justify-content-center"
                            *ngIf="!element.isDemandSatisfied"
                        >
                            <div class="notAvailableCircle mr-2"></div>
                            {{ "notAvailable" | translate }}
                        </div>
                    </td>
                </ng-container>
                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedAvailabiltyColumns"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedAvailabiltyColumns"
                ></tr>
                <!-- <tr mat-header-row *matHeaderRowDef="displayedAvailabiltyColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedAvailabilityColumns"></tr> -->
            </table>

            <!-- <mat-paginator *ngIf="length" class="w-100 mtop-1" #paginator [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="getNextRecords($event)"
                [length]="length"></mat-paginator> -->
        </div>
    </div>
</div>
<div *ngIf="data.type == 'cost'">
    <div class="modal-header d-flex align-items-center border-bottom-0">
        <!-- <img class="block-img" src="./assets/images/dollar.svg" />
        <div class="col">
            <h6 id="send_request_title" class="modal-title ">
                <span class="borderBottom">{{
                    "approximateSpaceCostInPreferredLocation" | translate
                }}</span>
            </h6>
        </div> -->
        <button
            type="button"
            class="close ml-5"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid p-0">
            <table mat-table [dataSource]="costDataSource" class="form-group">
                <ng-container matColumnDef="workstation">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "workstation" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.workstation || "NA" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "priceUnit" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.price || "NA" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "quantity" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.quantity || "NA" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="approxCost">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "approxCost" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.cost || "NA" }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>

            <!-- <mat-paginator *ngIf="length" class="w-100 mtop-1" #paginator [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="getNextRecords($event)"
                [length]="length"></mat-paginator> -->
        </div>
        <div class="col p-0 pr-5 d-flex justify-content-end">
            <h6 class="d-flex align-items-end mr-4">
                <b>
                    {{ "totalPrice" | translate }}
                </b>
            </h6>
            <mat-card class="col-lg-2 col-md-3 p-2">
                $8000
            </mat-card>
        </div>
    </div>
</div>
