import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { environment} from '../../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from '../../../services/common-service.service';


@Injectable()
export class EventCatService {

  private moduleId;
  private readonly BASE_URL;
  private readonly BASE_URL_VMS;

  constructor(private http: HttpClient, public commonService: CommonService) {
    this.moduleId = 5; 
    this.BASE_URL = environment.BASE_URL ;
    this.BASE_URL_VMS = environment.BASE_URL + 'vms/';
  }

  getAllCategory( zoneId ,pageNo, size ) {
    return this.http.get(this.BASE_URL + `communityV2/eventCategory/global/admin/getAllActiveCategory?page=${pageNo}&size=${size}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getAllCategoryDownloadData(zoneId){
    return this.http.get(this.BASE_URL + `communityV2/eventCategory/global/admin/getAllCategory`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  addCatedory(data,zoneId){
    return this.http.post(this.BASE_URL + "communityV2/eventCategory/global/admin/addMultiEventCategory", data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      });
  }
 
  addCatedorySingle(data,zoneId){
    return this.http.post(this.BASE_URL + "communityV2/eventCategory/global/admin/addEventCategory", data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      });
  }
  updateCategory(data,zoneId){
    return this.http.post(this.BASE_URL + "communityV2/eventCategory/global/admin/updateEventCategory", data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      });
  }
  downloadViaExcelTemplate(zoneId){
    const endpoint = this.BASE_URL + "communityV2/eventCategory/global/admin/downloadCategoryTemplateBulkUpload";
    const data=null
    return this.http.post(endpoint,data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) ,responseType: 'blob' });
  }

  validateExcelOnboarding(fileToUpload,fileName,zoneId){
    const endpoint = this.BASE_URL + "communityV2/eventCategory/global/admin/categoryValidateBulkUpload";
    var formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileName);
    return this.http.post(endpoint,formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllCategoryByName( zoneId ,pageNo, size,name ) {
    return this.http.get(this.BASE_URL + `communityV2/eventCategory/global/admin/searchCategory?page=${pageNo}&size=${size}&category=${name}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

}