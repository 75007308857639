<div class="col p-0 mt-4" *ngIf="!manageTab">
    <form [formGroup]="moveRequestForm" (ngSubmit)="createMoveRequest()">
        <div class="col" hidden>
            <mat-radio-group class="float-left" formControlName="moveType">
                <!-- <mat-radio-button value="self">Self</mat-radio-button> ml-4-->
                <mat-radio-button class="" value="team">{{
                    "team" | translate
                }}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div
            class="col"
            formGroupName="moveByTeamForm"
            *ngIf="f.moveType.value == 'team'"
        >
            <div class="col p-0 d-flex mt-5 form-group">
                <!-- <div class="col-lg-3 col-md-3 col-sm-6 text-left form-group pl-lg-0 pl-md-0">
                <label class="float-left">{{'department' | translate}}</label>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-select id="cost_code" formControlName="department" placeholder="Select" (selectionChange)="getCostCodeList()" [ngClass]="{ 'is-invalid': submitted && f.moveByTeamForm.controls.department.errors }">
                        <mat-option *ngFor="let department of departmentList; let i = index" [value]="department.id">
                            {{ department.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="submitted && f.moveByTeamForm.controls.department.errors" class="invalid-feedback d-block">
                    Department is required
                    </div>
            </div> -->
                <div
                    class="col-lg-3 col-md-3 col-sm-6 text-left form-group pl-lg-0 pl-md-0"
                >
                    <label class="float-left">{{
                        "department" | translate
                    }}</label>
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-select
                            id="department"
                            formControlName="department"
                            placeholder="Select"
                            (selectionChange)="getAllAllocation()"
                        >
                            <mat-option
                                *ngFor="
                                    let department of departmentList;
                                    let i = index
                                "
                                [value]="department.id"
                            >
                                {{ department.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div
                        *ngIf="getDeptSelectedFormControlError('department')"
                        class="invalid-feedback d-block"
                    >
                        Please select a department
                    </div>
                </div>
                <div
                    class="col-lg-6 col-md-6 col-sm-6 text-left form-group pl-lg-0 pl-md-0"
                >
                    <label class="float-left">{{ "reason" | translate }}</label>
                    <input
                        id="reason"
                        type="text"
                        class="form-control"
                        formControlName="reason"
                        [ngClass]="{
                            'is-invalid': getFormControlErrors('reason')
                        }"
                    />
                    <div
                        *ngIf="getFormControlErrors('reason')"
                        class="invalid-feedback d-block"
                    >
                        Reason is required
                    </div>
                </div>
                <div
                    class="input-group input-icons col-lg-3 col-md-3 col-sm-6 text-left form-group pl-lg-0 pl-md-0"
                    style="margin-top: -5px;"
                >
                    <div class="input-group input-icons">
                        <label class="float-left">{{
                            "startDate" | translate
                        }}</label>
                        <i
                            class="fa fa-calendar icon"
                            [owlDateTimeTrigger]="startDate1"
                        ></i>
                        <input
                            id="start_date"
                            placeholder="{{ startDate | date: 'MMMM d, y' }}"
                            formControlName="startDate"
                            class="form-control borderCurved w-100"
                            [owlDateTimeTrigger]="startDate1"
                            [owlDateTime]="startDate1"
                            (ngModelChange)="getAllAllocation()"
                        />
                        <owl-date-time
                            [pickerType]="'calendar'"
                            #startDate1
                        ></owl-date-time>
                    </div>
                </div>
            </div>

            <div class="col p-0">
                <div class="col p-0 form-group">
                    <h6 class="float-left">
                        <b>{{ "moveTo" | translate }}</b>
                    </h6>
                </div>
                <div class="col p-0 d-lg-flex d-md-flex form-group">
                    <mat-radio-group
                        aria-label="Select an option"
                        formControlName="moveToType"
                    >
                        <!-- <mat-radio-button class="float-left" value="1">Different workstation within the same location</mat-radio-button> ml-4-->
                        <!-- <mat-radio-button class="" value="2">{{'otherLocation' | translate}}</mat-radio-button> -->
                    </mat-radio-group>
                    <!-- <mat-radio-group id="moveToType" class="float-left" >
                    <mat-radio-button value="1">Different workstation within the same location</mat-radio-button>
                    <mat-radio-button class="ml-4" value="2">Other Location</mat-radio-button>
                </mat-radio-group> -->
                </div>
                <!-- <div
                    class="col"
                    *ngIf="f.moveByTeamForm.controls.moveToType.value == 1"
                >
                    1
                </div> -->
                <!-- *ngIf="f.moveByTeamForm.controls.moveToType.value == 2" -->
                <div class="col p-0">
                    <div class="col p-0 d-lg-flex d-md-flex">
                        <div
                            class="col-lg-3 col-md-3 col-sm-12 text-left form-group pl-lg-0 pl-md-0"
                        >
                            <label class="float-left">{{
                                "city" | translate
                            }}</label>
                            <mat-form-field class="w-100" appearance="fill">
                                <mat-select
                                    id="city"
                                    formControlName="city"
                                    placeholder="Select"
                                    (selectionChange)="
                                        getPreferredLocationList()
                                    "
                                    [ngClass]="{
                                        'is-invalid': getFormControlErrors(
                                            'city'
                                        )
                                    }"
                                >
                                    <mat-option
                                        *ngFor="
                                            let city of cityList;
                                            let i = index
                                        "
                                        [value]="city.id"
                                    >
                                        {{ city.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div
                                *ngIf="getFormControlErrors('city')"
                                class="invalid-feedback d-block"
                            >
                                City is required
                            </div>
                        </div>
                        <div
                            class="col-lg-3 col-md-3 col-sm-12 text-left form-group pl-lg-0 pl-md-0"
                        >
                            <label class="float-left">{{
                                "preferredLocation" | translate
                            }}</label>
                            <mat-form-field class="w-100" appearance="fill">
                                <mat-select
                                    id="preferred_location"
                                    formControlName="preferredLocation"
                                    placeholder="Select"
                                    [ngClass]="{
                                        'is-invalid': getFormControlErrors(
                                            'preferredLocation'
                                        )
                                    }"
                                >
                                    <mat-option
                                        *ngFor="
                                            let zone of preferredZoneList;
                                            let i = index
                                        "
                                        [value]="zone.id"
                                    >
                                        {{ zone.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div
                                *ngIf="
                                    getFormControlErrors('preferredLocation')
                                "
                                class="invalid-feedback d-block"
                            >
                                Preferred Location is required
                            </div>
                        </div>
                        <div
                            class="col text-left"
                            *ngIf="config.moveRequest.viewInsights"
                        >
                            <img
                                *ngIf="config.moveRequest.showLocationInsights"
                                src="./assets/images/preferred-allocation.svg"
                                class="mt-2 block-img form-group"
                                (click)="setPageType('preferredAllocation')"
                            />
                            <img
                                *ngIf="
                                    config.moveRequest.showAvailabilityInsights
                                "
                                src="./assets/images/calendar-checked.svg"
                                class="mt-2 block-img form-group"
                                (click)="setPageType('availability')"
                            />
                            <!-- <img
                                *ngIf="config.moveRequest.showCostInsights"
                                src="./assets/images/dollar.svg"
                                class="mt-2 block-img d-inline-block form-group"
                                (click)="setPageType('cost')"
                            /> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf="dataSource.length">
                <table mat-table [dataSource]="dataSource" class="form-group">
                    <ng-container matColumnDef="employee">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "employee" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                        >
                            <div class="col p-0 d-flex align-items-center">
                                <div class="col text-right">
                                    <div
                                        *ngIf="!element.user.imageUrl"
                                        class="userLegend d-inline-flex justify-content-center"
                                    >
                                        <div class="d-flex align-items-center">
                                            {{ getUserLegend(element.user) }}
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="!!element.user.imageUrl"
                                        class="d-inline-block"
                                    >
                                        <img
                                            [src]="element.user.imageUrl"
                                            class="userLegend"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="col text-left pl-0">
                                    {{ element?.user?.name || "NA" }}
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="site">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "site" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                        >
                            {{
                                element?.floorDetailsDTO?.zoneLocationDTO?.site
                                    ?.name || "NA"
                            }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="building">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "building" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                        >
                            {{
                                element?.floorDetailsDTO?.zoneLocationDTO
                                    ?.building?.name || "NA"
                            }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="floor">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "floor" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                        >
                            {{
                                element?.floorDetailsDTO?.zoneLocationDTO
                                    ?.name || "NA"
                            }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="workstation">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "workstation" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                        >
                            {{ element?.entityInfo?.displayName || "NA" }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="currentWorkstation">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "currentWorkstation" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                        >
                            {{ element?.entityType?.name || "NA" }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="preferredWorkstation">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "preferredWorkstation" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                        >
                            <mat-form-field>
                                <mat-select [(value)]="element.entityType.id">
                                    <mat-option
                                        *ngFor="let pwt of workstationList"
                                        [value]="pwt.id"
                                    >
                                        {{ pwt.name }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "action" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                        >
                            <mat-checkbox
                                class=""
                                (change)="selectMoveUsers($event, element)"
                            ></mat-checkbox>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>
            </div>
        </div>
        <div class="col" *ngIf="f.moveType.value == 'self'">
            {{ "self" | translate }}
        </div>
        <div class="row row1">
            <div class="col-4 pt-3">
                <p *ngIf="moveTeamUserList.length > 0">
                    {{ getUserListLength() }}
                </p>
            </div>
            <div class="col-8">
                <span class="ml-2">
                    <img
                        src="./assets/images/preferred-allocation.svg"
                        class="block-img"
                    />
                    {{ "Current Allocation" }}
                </span>
                <span class="ml-2">
                    <img
                        src="./assets/images/calendar-checked.svg"
                        class="block-img"
                    />
                    {{ "Availability" }}
                </span>
                <!-- <span class="ml-2">
                    <img
                        src="./assets/images/dollar.svg"
                        class="block-img d-inline-block"
                    />
                    {{ "Space Cost" }}
                </span> -->
                <button
                    type="submit"
                    id="submit_btn"
                    class="btn btn-main btnSmall float-right rounded-pill mt-2"
                >
                    {{ "submit" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="manageTab">
    <modal-manage></modal-manage>
</div>
