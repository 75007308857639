<div>
    <div *ngIf="data['type']=='addUserGroup'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'addTeam' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" id="close_popup" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>


            <mat-card-content>
                <div class="row group-name">
                    <label>{{'teamName' | translate}}</label>
                    <input class="form-control" id="team_name" [(ngModel)]="groupName" type="text" name="groupName">
                </div>

                <div class="row add-user-group-btn">
                    <button mat-raised-button class="btn btn-main" id="add_btn" (click)="addUserGroup()"> {{'add' |
                        translate}} </button>
                </div>


            </mat-card-content>

        </mat-card>
    </div>

    <div *ngIf="data['type']=='addUserGroupNew'">
        <mat-card class="example-card" style="height: 170px;">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'addTeam' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" id="close_popup_btn" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>


            <mat-card-content>
                <div class="row group-name">
                    <label>{{'teamName' | translate}}</label>
                    <input class="form-control" id="grp_name" [(ngModel)]="groupName" type="text" name="groupName">
                </div>

                <div class="row add-user-group-btn">
                    <button mat-raised-button class="btn btn-main" id="add_usr_grp" (click)="addUserGroupNew()"> {{'add'
                        | translate}} </button>
                </div>


            </mat-card-content>

        </mat-card>
    </div>



    <div *ngIf="data['type']=='editUserGroup'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'edit' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" id="close_modal" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>


            <mat-card-content>
                <div class="row group-name">
                    <label>{{'groupName' | translate}}</label>
                    <input id="group_name" class="form-control" [(ngModel)]="groupName" type="text" name="groupName">
                </div>

                <div class="row add-user-group-btn">
                    <button id="edit_user_group" mat-raised-button class="btn btn-main" (click)="editUserGroup()">
                        {{'save' | translate}} </button>
                </div>


            </mat-card-content>

        </mat-card>
    </div>


    <div *ngIf="data['type']=='editUserGroupNew'">
        <mat-card class="example-card" style="height:170px;">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'edit' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button id="close_edit_user_modal" type="button" class="close" aria-label="Close"
                        (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>


            <mat-card-content>
                <div class="row group-name">
                    <label>{{'groupName' | translate}}</label>
                    <input id="edit_group_name" class="form-control" [(ngModel)]="groupName" type="text"
                        name="groupName">
                </div>

                <div class="row add-user-group-btn">
                    <button id="edit_user_grp_btn" mat-raised-button class="btn btn-main" (click)="editUserGroupNew()">
                        {{'save' | translate}} </button>
                </div>


            </mat-card-content>

        </mat-card>
    </div>

    <!--sankar modal for manage ticket edit-->
    <div *ngIf="data['type'] == 'editManageTicketNew'">
        <mat-card>
            <mat-card-title>
                {{'addActivity' | translate}} - {{ticketData.ticketId}}
                <i class="fa fa-window-close" id="close_edit_manage_modal" style="float:right" aria-hidden="true"
                    (click)="closePopup()"></i>
            </mat-card-title>
            <hr />
            <form class="form" name="form" id="update_manage_ticket" (ngSubmit)="f.form.valid && updateManageTicketNew()" #f="ngForm" novalidate>
                <mat-card-content>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="">
                                    <div class="form-group">
                                        <div class="image-container">
                                            <img name="img" *ngIf="showImage" [src]="url"
                                                class="img-responsive  image-content" />
                                        </div>
                                        <br />
                                        <input type="file" id="on_select_file" (change)="onSelectFile($event)"
                                            name="myticket" [(ngModel)]="editMyTicket.img" #myticketFile="ngModel"
                                            style="display:none" #file />
                                        <button type="button" id="file_click" class="btn btn-success"
                                            (click)="file.click()">{{'selectFile' | translate}}</button>
                                        <br />
                                        <span>{{'supportedFormat' | translate}}<br />
                                            <small><b>.JPG, .PNG, .JPEG</b></small></span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-8">
                            <div style="
                  border: 1px solid #000;
                  padding: 6px;
                  border-radius: 10px;">
                                <div class="row">

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{'category' | translate}}</label>
                                            <input id="category_name" class="form-control" type="text"
                                                name="categoryName" [value]="data.ticketData.primaryCategory"
                                                readonly />
                                        </div>
                                    </div>



                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{'subCategory' | translate}}</label>
                                            <input id="sub_category" class="form-control" type="text"
                                                name="subCategoryName" [value]="data.ticketData.subCategory" readonly />
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label> {{'team' | translate}}</label>
                                            <div class="input-group">
                                                <mat-form-field appearance="fill">
                                                    <mat-select placeholder="{{'select' | translate}}" name="groupId"
                                                        [(ngModel)]="editManageTicket.groupId" id="groupId"
                                                        #group="ngModel" [ngModelOptions]="{standalone: true}"
                                                        [disabled]="true">
                                                        <mat-option disabled>Select</mat-option>
                                                        <mat-option *ngFor="let group of userGroupsNew"
                                                            [value]="group.teamId" (click)="onGroupSelect()">
                                                            {{ group.teamName }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <!-- <select id="groupId" class="form-control" name="groupId"
                                                [(ngModel)]="editManageTicket.groupId" #group="ngModel"
                                                [disabled]="true" required (change)="onGroupSelect()">

                                                <option [value]="group.teamId" *ngFor="let group of userGroupsNew">
                                                    {{ group.teamName }}
                                                </option>
                                            </select> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'selectPriority' | translate}}</label>
                                        <div class="input-group">
                                            <mat-form-field appearance="fill">
                                                <mat-select placeholder="{{'select' | translate}}" name="priority"
                                                    [(ngModel)]="editManageTicket.priority" id="sel_priority"
                                                    [value]="currentPriority" #priority="ngModel"
                                                    [ngModelOptions]="{standalone: true}" [ngClass]="{
                                                    'is-invalid': f.submitted && priority.invalid
                                                  }">
                                                    <mat-option disabled>Select</mat-option>
                                                    <mat-option [value]="priority"
                                                        *ngFor="let priority of priorityList">
                                                        {{ priority }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <select id="priority" class="form-control" name="priority"
                                            [(ngModel)]="editManageTicket.priority" [value]="currentPriority"
                                            #priority="ngModel" [ngClass]="{
                                  'is-invalid': f.submitted && priority.invalid
                                }" required>

                                            <option [value]="priority" *ngFor="let priority of priorityList">
                                                {{ priority }}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>



                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'selectStatus' | translate}}</label>
                                        <div class="input-group">
                                            <mat-form-field appearance="fill">
                                                <mat-select placeholder="{{'select' | translate}}" name="sel_status"
                                                    [(ngModel)]="editManageTicket.state" id="status"
                                                    [value]="currentState" #state="ngModel"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="{'is-invalid': f.submitted && state.invalid}">
                                                    <mat-option disabled>Select</mat-option>
                                                    <mat-option [value]="state.valueState"
                                                        (click)="onChangeStateNew($event)"
                                                        *ngFor="let state of stateList">
                                                        {{ state.nextState }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <select id="status" class="form-control" name="status"
                                            [(ngModel)]="editManageTicket.state" [value]="currentState" #state="ngModel"
                                            [ngClass]="{
                              'is-invalid': f.submitted && state.invalid
                            }" (change)="onChangeStateNew($event)" required>
                                            <option [value]="state.valueState" *ngFor="let state of stateList">
                                                {{ state.nextState }}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'selectUserAssign' | translate}}</label>
                                        <div class="input-group">
                                            <mat-form-field appearance="fill">
                                                <mat-select placeholder="{{'select' | translate}}" name="user"
                                                    [(ngModel)]="editManageTicket.user" id="select_user"
                                                    [value]="currentState" #user="ngModel"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [disabled]="!isAssignUserRequired"
                                                    [ngClass]="{'is-invalid': f.submitted && user.invalid}">
                                                    <mat-option disabled>Select User</mat-option>
                                                    <mat-option [value]="user.id" *ngFor="let user of groupUsers">
                                                        {{ user.firstName }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <select id="user" class="form-control" name="user"
                                            [(ngModel)]="editManageTicket.user" #user="ngModel" [ngClass]="{
                            'is-invalid': f.submitted && user.invalid
                          }" [disabled]="!isAssignUserRequired" required>

                                            <option value="">
                                                Select User
                                            </option>
                                            <option [value]="user.id" *ngFor="let user of groupUsers">
                                                {{ user.firstName }}
                                            </option>
                                        </select> -->
                                        <!-- <option value="0">Select User</option>
                      <option value="1">Test 1</option>
                      <option value="2">Test 2</option>
                      <option value="3">Test 3</option> -->
                                        <!-- <option [value]="user" *ngFor="let user of userList">
                                  {{ user }}
                                </option> -->
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'comment' | translate}} </label>
                                        <textarea name="comment" class="form-control" id="comments"
                                            *ngIf="!isCommentRequired"
                                            [(ngModel)]="editManageTicket.comment"></textarea>
                                        <textarea name="comment" class="form-control" id="req_comments"
                                            *ngIf="isCommentRequired" required #comment="ngModel"
                                            [(ngModel)]="editManageTicket.comment"
                                            [ngClass]="{ 'is-invalid': f.submitted && comment.invalid }"></textarea>

                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </mat-card-content>
                <hr />
                <mat-card-footer>
                    <div style="margin:15px">
                        <button class="pull-left btn btn-main" id="cancel_btn" type="button" (click)="closePopup()">
                            {{'cancel' | translate}}
                        </button>
                        <button class="pull-right btn btn-main" id="submit_btn" type="submit">
                            {{'submit' | translate}}
                        </button>
                    </div>
                </mat-card-footer>
            </form>
        </mat-card>
    </div>

    <!--sankar modal for manage ticket edit-->
    <div *ngIf="data['type'] == 'editManageTicket'">
        <mat-card>
            <mat-card-title>
                {{'editTickets' | translate}} - {{ticketData.ticketId}}
                <i class="fa fa-window-close" id="close_edit_ticket_modal" style="float:right" aria-hidden="true"
                    (click)="closePopup()"></i>
            </mat-card-title>
            <hr />
            <form class="form" name="form" id="edit_manage_ticket" (ngSubmit)="f.form.valid && updateManageTicket()" #f="ngForm" novalidate>
                <mat-card-content>
                    <div class="row" style="margin:0.5rem;">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="">
                                    <div class="form-group">
                                        <div class="image-container">
                                            <img name="img" *ngIf="showImage" [src]="url"
                                                class="img-responsive  image-content" />
                                        </div>
                                        <br />
                                        <input id="edit_ticket" type="file" (change)="onSelectFile($event)"
                                            name="myticket" [(ngModel)]="editMyTicket.img" #myticketFile="ngModel"
                                            style="display:none" #file />
                                        <button type="button" id="edit_file_click" class="btn btn-success"
                                            (click)="file.click()">{{'selectFile' | translate}}</button>
                                        <br />
                                        <span>{{'supportedFormat' | translate}}<br />
                                            <small><b>.JPG, .PNG, .JPEG</b></small></span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-8">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'selectPriority' | translate}}</label>
                                        <div class="input-group">
                                            <mat-form-field appearance="fill">
                                                <mat-select placeholder="{{'select' | translate}}"
                                                    [(ngModel)]="editManageTicket.priority" id="select_priority"
                                                    [value]="currentPriority" #priority="ngModel" [ngClass]="{
                                                    'is-invalid': f.submitted && priority.invalid
                                                  }" [ngModelOptions]="{standalone: true}">
                                                    <mat-option disabled>Select</mat-option>
                                                    <mat-option *ngFor="let priority of priorityList"
                                                        [value]="priority">
                                                        {{ priority }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <select id="priority" class="form-control" name="priority"
                                            [(ngModel)]="editManageTicket.priority" [value]="currentPriority"
                                            #priority="ngModel" [ngClass]="{
                                'is-invalid': f.submitted && priority.invalid
                              }" required>

                                            <option [value]="priority" *ngFor="let priority of priorityList">
                                                {{ priority }}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>



                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'selectStatus' | translate}}</label>
                                        <div class="input-group">
                                            <mat-form-field appearance="fill">
                                                <mat-select placeholder="{{'select' | translate}}"
                                                    [(ngModel)]="editManageTicket.state" id="select_status"
                                                    name="status" [value]="currentState" #state="ngModel" [ngClass]="{
                                                    'is-invalid': f.submitted && state.invalid
                                                  }" [ngModelOptions]="{standalone: true}">
                                                    <mat-option disabled>Select</mat-option>
                                                    <mat-option *ngFor="let state of stateList" [value]="state"
                                                        (click)="onChangeState($event)">
                                                        {{ state }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <select id="status" class="form-control" name="status"
                                            [(ngModel)]="editManageTicket.state" [value]="currentState" #state="ngModel"
                                            [ngClass]="{
                            'is-invalid': f.submitted && state.invalid
                          }" (change)="onChangeState($event)" required>
                                            <option [value]="state" *ngFor="let state of stateList">
                                                {{ state }}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>

                            </div>
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'selectTeam' | translate}}</label>
                                        <div class="input-group">
                                            <mat-form-field appearance="fill">
                                                <mat-select placeholder="{{'select' | translate}}"
                                                    [(ngModel)]="editManageTicket.groupId" id="select_groupId"
                                                    name="groupId" #group="ngModel"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="true">
                                                    <mat-option disabled>Select</mat-option>
                                                    <mat-option *ngFor="let group of userGroups" [value]="group.teamId"
                                                        (click)="onGroupSelect()">
                                                        {{ group.teamName }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <select id="groupId" class="form-control" name="groupId"
                                            [(ngModel)]="editManageTicket.groupId" [disabled]="true" #group="ngModel"
                                            required (change)="onGroupSelect()">
                                            <option [value]="group.teamId" *ngFor="let group of userGroups">
                                                {{ group.teamName }}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'selectUserAssign' | translate}}</label>
                                        <div class="input-group">
                                            <mat-form-field appearance="fill">
                                                <mat-select placeholder="{{'select' | translate}}"
                                                    [(ngModel)]="editManageTicket.user" id="select_user_assign"
                                                    name="user" #user="ngModel" [ngModelOptions]="{standalone: true}"
                                                    [disabled]="true" [ngClass]="{
                                                    'is-invalid': f.submitted && user.invalid
                                                  }" [disabled]="!isAssignUserRequired">
                                                    <mat-option disabled>Select</mat-option>
                                                    <mat-option *ngFor="let user of groupUsers" [value]="user.id">
                                                        {{ user.firstName }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <select id="user" class="form-control" name="user"
                                            [(ngModel)]="editManageTicket.user" #user="ngModel" [ngClass]="{
                          'is-invalid': f.submitted && user.invalid
                        }" [disabled]="!isAssignUserRequired" required>

                                            <option [value]="0">
                                                Select User
                                            </option>
                                            <option [value]="user.id" *ngFor="let user of groupUsers">
                                                {{ user.firstName }}
                                            </option>
                                        </select> -->
                                        <!-- <option value="0">Select User</option>
                    <option value="1">Test 1</option>
                    <option value="2">Test 2</option>
                    <option value="3">Test 3</option> -->
                                        <!-- <option [value]="user" *ngFor="let user of userList">
                                {{ user }}
                              </option> -->

                                    </div>
                                </div>


                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'comment' | translate}} </label>
                                        <textarea name="comment" class="form-control" id="edit_manage_comments"
                                            *ngIf="!isCommentRequired"
                                            [(ngModel)]="editManageTicket.comment"></textarea>
                                        <textarea name="comment" class="form-control" id="edit_comments"
                                            *ngIf="isCommentRequired" required #comment="ngModel"
                                            [(ngModel)]="editManageTicket.comment"
                                            [ngClass]="{ 'is-invalid': f.submitted && comment.invalid }"></textarea>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </mat-card-content>
                <hr />
                <mat-card-footer>
                    <div style="margin:15px">
                        <button class="pull-left btn btn-main" id="edit_cancel_btn" type="button"
                            (click)="closePopup()">
                            {{'cancel' | translate}}
                        </button>
                        <button class="pull-right btn btn-main" id="edit_submit_btn" type="submit">
                            {{'submit' | translate}}
                        </button>
                    </div>
                </mat-card-footer>
            </form>
        </mat-card>
    </div>


    <div *ngIf="data['type'] == 'editMyTicket'">
        <mat-card>
            <mat-card-title>
                {{'editTickets' | translate}}
                <i class="fa fa-window-close" id="close_edit_myticket_modal" style="float:right" aria-hidden="true"
                    (click)="closePopup()"></i>
            </mat-card-title>
            <hr />
            <form class="form" name="form" (ngSubmit)="f.form.valid && updateMyTicket()" #f="ngForm" novalidate>
                <mat-card-content>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="image-container">
                                    <img name="img" *ngIf="showImage" [src]="url"
                                        class="img-responsive  image-content" />
                                </div>
                                <br />
                                <input type="file" id="edit_file" (change)="onSelectFile($event)" name="myticket"
                                    [(ngModel)]="editMyTicket.img" #myticketFile="ngModel" style="display:none" #file />
                                <button type="button" id="edit_ticket_file" class="btn btn-success"
                                    (click)="file.click()">{{'selectFile' | translate}}</button>
                                <br />
                                <span>{{'supportedFormat' | translate}}<br />
                                    <small><b>.JPG, .PNG, .JPEG</b></small></span>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{'selectPriority' | translate}}</label>
                                        <select id="edit_priority" class="form-control" name="priority"
                                            [(ngModel)]="editMyTicket.priority" [value]="currentPriority"
                                            #priority="ngModel" [ngClass]="{
                                  'is-invalid': f.submitted && priority.invalid
                                }" required>

                                            <option [value]="priority" *ngFor="let priority of priorityList">
                                                {{ priority }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{'selectStatus' | translate}}</label>
                                        <select id="edit_status" class="form-control" name="status"
                                            [(ngModel)]="editMyTicket.state" [value]="currentState" #state="ngModel"
                                            [ngClass]="{
                                'is-invalid': f.submitted && state.invalid
                              }" (change)="onMyTicketChangeState($event)" required>
                                            <option [value]="state" *ngFor="let state of stateList">
                                                {{ state }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group" *ngIf="!isCommentRequired">
                                        <label>{{'comment' | translate}} </label>
                                        <textarea id="edit_ticket_comment" name="comment" class="form-control"
                                            [(ngModel)]="editMyTicket.comment"></textarea>
                                    </div>
                                    <div class="form-group" *ngIf="isCommentRequired">
                                        <label>{{'comment' | translate}} </label>
                                        <textarea id="edit_req_comment" name="comment" class="form-control"
                                            [(ngModel)]="editMyTicket.comment"></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </mat-card-content>
                <hr />
                <mat-card-footer>
                    <div style="margin:15px">
                        <button class="pull-left btn btn-warning" id="cancel_ticket_btn" type="button"
                            (click)="closePopup()">
                            {{'cancel' | translate}}
                        </button>
                        <button class="pull-right btn  btn-primary btn-jumpree" id="create_ticket_btn" type="submit">
                            {{'create' | translate}}
                        </button>
                    </div>
                </mat-card-footer>
            </form>
        </mat-card>
    </div>
    <div *ngIf="data['type'] == 'viewManageTicket'">
        <mat-card>
            <mat-card-title style="color:#5157C6">
                {{'Ticket' | translate}} - {{ticketData.ticketId}}
                <!--need to show ticket name-->
                <i class="fa fa-window-close" id="close_pop_btn" style="float:right" aria-hidden="true"
                    (click)="closePopup()"></i>
            </mat-card-title>
            <hr />
            <mat-card-content>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'currentStatus' | translate}} </b></div>
                            <div class="col-md-6">
                                {{ticketData.currentState}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'Priority' | translate}}</b></div>
                            <div class="col-md-6">{{ticketData.priority}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'raisedAt' | translate}}</b></div>
                            <div class="col-md-6">{{ticketData.raisedAt | date: shortDate}} {{ticketData.raisedAt | date
                                : 'hh:mm a'}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'raisedBy' | translate}}</b></div>
                            <div class="col-md-6">{{!!ticketData.raisedBy?ticketData.raisedBy.firstName:"NA"}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'primaryCategory' | translate}}</b></div>
                            <div class="col-md-6">{{ticketData.primaryCategory}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'subCategory' | translate}}</b></div>
                            <div class="col-md-6">{{ticketData.subCategory}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'incidentOccuredAt' | translate}}</b></div>
                            <div class="col-md-6">
                                {{ticketData.incidentOccuredAt | date: shortDate}} {{ticketData.incidentOccuredAt | date
                                : 'hh:mm a'}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row" *ngIf="ticketData?.raisedBy?.unitNumber">
                            <div class="col-md-6"><b>{{'unitNumber' | translate}}</b></div>
                            <div class="col-md-6">{{ticketData?.raisedBy?.unitNumber}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3"><b>{{'reopenCount' | translate}}</b></div>
                            <div class="col-md-9">{{ticketData.reopenCount}}</div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
              <div class="row">
                <div class="col-md-6"><b>Company</b></div>
                <div class="col-md-6">-----</div>
              </div>
            </div> -->
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3"><b>{{'incidentPlace' | translate}}</b></div>
                            <div class="col-md-9">{{ticketData.address}} ,{{ticketData.zoneAddress}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3"><b>{{'description' | translate | titlecase}}</b></div>
                            <div class="col-md-9">{{ticketData.description}}</div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'teamName' | translate}}</b></div>
                            <div class="col-md-6"> {{ticketData.teamName}}</div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'assignee' | translate}}</b></div>
                            <div class="col-md-6"> {{!!ticketData.assigneeDetail ?
                                ticketData.assigneeDetail.firstName:"Not Assigned"}}</div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'resolvedAt' | translate}}</b></div>
                            <div class="col-md-6">{{ticketData.resolvedAt | date: shortDate}} {{ticketData.resolvedAt |
                                date : 'hh:mm a'}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6"><b>{{'resolvedBy' | translate}}</b></div>
                            <div class="col-md-6"> {{!!ticketData.resolvedBy ? ticketData.resolvedBy.firstName:"NA"}}
                            </div>
                        </div>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12" *ngIf="ticketData.image != null && ticketData.image.length > 0" style="color:#5157C6;font-size:20px;">
                        <b>{{'newTicketImages' | translate}}</b>
                    </div>
                    <div class="col-md-12">
                        <div class="col-md-2" *ngFor="let image of ticketData.image; let i =index">
                            <img style="margin-top: 16px;width: 150px;" class="comment-image" src="{{image}}">
                        </div>
                    </div>
                </div>
                <hr *ngIf="ticketData.image != null && ticketData.image.length > 0">
                <div class="row">

                    <div class="col-md-12" style="color:#5157C6;font-size:20px;">
                        <b>{{'ticketTimeline' | translate}}</b>
                    </div>

                </div>

                <ul class="timeline">
                    <ng-container *ngFor="let activity of ticketData.activityHistory; let i =index">
                        <li [ngClass]="{'timeline-inverted': i%2!=0}">
                            <div class="timeline-badge"
                                [ngClass]="{'info': activity.state=='NEW', 'danger':activity.state=='CANCELLED' , 'warning':(activity.state=='ON_HOLD' || activity.state=='IN_PROGRESS'), 'success':(activity.state=='RESOLVED' || activity.state=='CLOSEED') }">
                            </div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="timeline-title">{{'Ticket' | translate}}-{{ticketData.ticketId}}</h4>
                                    <!-- <p><small class="text-muted"><i class="glyphicon glyphicon-time"></i> 11 hours ago via Twitter</small></p> -->
                                </div>
                                <div class="timeline-body">
                                    <div class="row">
                                        <!-- <div class="col-md-6">
                        created:
                      </div>
                      <div class="col-md-6">
                        {{activity.createdAt | date: shortDate}} {{activity.createdAt | date : 'hh:mm a'}}
                      </div> -->
                                        <div class="col-md-6">
                                            {{'modified' | translate}}:
                                        </div>
                                        <div class="col-md-6">
                                            {{activity.modifiedAt | date: shortDate}} {{activity.modifiedAt | date :
                                            'hh:mm a'}}
                                        </div>
                                        <div class="col-md-6">
                                            {{'status' | translate}}:
                                        </div>
                                        <div class="col-md-6">
                                            {{activity.state}}
                                        </div>
                                        <div class="col-md-6">
                                            {{'comment' | translate}}:
                                        </div>
                                        <div class="col-md-6">
                                            {{activity.comment}}
                                        </div>
                                        <div class="col-md-6 ">
                                            <img class="comment-image" src="{{activity.imageUrl}}">
                                        </div>
                                    </div>
                                    <!-- <p>Mussum ipsum cacilds, vidis litro abertis. Consetis adipiscings elitis. Pra lá , depois divoltis porris, paradis. Paisis, filhis, espiritis santis. Mé faiz elementum girarzis, nisi eros vermeio, in elementis mé pra quem é amistosis quis leo. Manduma pindureta quium dia nois paga. Sapien in monti palavris qui num significa nadis i pareci latim. Interessantiss quisso pudia ce receita de bolis, mais bolis eu num gostis.</p> -->
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>

    <!--sankar modal for manage ticket edit end-->

    <!-- raees start -->
    <div *ngIf="data['type']=='addImsCategory'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'addCategory' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button id="close_ims_modal" type="button" class="close" aria-label="Close"
                        (click)="closePopupIms()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>


            <mat-card-content>

                <div class="row">

                    <div class="col-md-6 category-name">
                        <label>{{'categoryName' | translate | uppercase}}</label>
                        <input id="category_name_text" class="form-control" [(ngModel)]="category.name" type="text"
                            name="categoryName">
                    </div>

                    <div class="col-md-6 category-name">
                        <label>{{'zoneOrLocation' | translate}}</label>
                        <mat-select id="select_zone" class="form-control" [(ngModel)]="selectedZones" multiple>
                            <mat-option *ngFor="let item of allZones" [value]="item">{{item.name}}
                            </mat-option>
                        </mat-select>

                    </div>

                </div>

                <div class="row level-header">
                    {{'subCategories' | translate}}
                </div>

                <div class="row">

                    <div class="col-md-5 category-name" *ngFor="let item of subCategoryList; let idx = index">
                        <div class="row">

                            <div class="col-md-10">
                                <label>{{'subCategory' | translate | uppercase}}</label>
                                <input id="sub_category_name" class="form-control" [(ngModel)]="item.name" type="text"
                                    name="subCategory-{{idx}}">
                            </div>

                            <div class="col-md-2">
                                <button id="rmv_sub_category" type="button" class="close sub-cat-remove"
                                    aria-label="Close" (click)="removeSubCategory(idx)">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                        </div>
                    </div>


                </div>


                <div class="row" style="cursor: pointer;" id="add_sub_category_box" (click)="addSubCategoryBox()">
                    <div class="add-another-plus-icon">
                        <i class="material-icons">
                            {{'add' | translate | lowercase}}
                        </i>
                    </div>
                    <div class="add-another-btn">
                        {{'addAnother' | translate}}
                    </div>
                </div>

                <div class="row category-divider">
                    <mat-divider></mat-divider>
                </div>

                <div class="row level-header">
                    {{'SLADetails' | translate}}
                </div>

                <div class="row escalation-level" *ngFor="let item of slaLevelList; let idx = index">


                    <div class="col-md-4 sla-level">
                        {{'SLALevel' | translate}} - {{idx+1}}
                    </div>

                    <div class="col-md-4">
                        <label>{{'selectTeam' | translate | uppercase}}</label>
                        <mat-select id="sel_team" class="form-control" [(ngModel)]="item.group">
                            <mat-option *ngFor="let item of userGroups" [value]="item">{{item.name}}</mat-option>
                        </mat-select>
                    </div>

                    <div class="col-md-2">
                        <label>{{'timeMinutes' | translate}}</label>
                        <input id="time_min" class="form-control" [(ngModel)]="item.time" type="text"
                            name="subCategory-{{idx}}">
                    </div>

                    <div class="col-md-2">
                        <button id="rmv_escalation_btn" type="button" class="close escalation-remove" aria-label="Close"
                            (click)="removeEscalation(idx)">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>





                    <!-- <div class="col-md-6 category-name" *ngFor="let item of subCategoryList; let idx = index">
              <label>SUB CATEGORY</label>
              <input class="form-control" [(ngModel)]="item.name" type="text" name="subCategory-{{idx}}">
            </div> -->

                </div>

                <div class="row" style="cursor: pointer;" id="add_sla_level" (click)="addSlaLevel()">
                    <div class="add-another-plus-icon">
                        <i class="material-icons">
                            {{'add' | translate | lowercase}}
                        </i>
                    </div>
                    <div class="add-another-btn">
                        {{'addAnother' | translate}}
                    </div>
                </div>



                <div class="row category-add-cancel-row">
                    <div class="col-md-6">
                        <button id="cancel_button" class="add-can-btn" mat-raised-button color="primary"
                            (click)="closePopupIms()">{{'cancel' | translate | uppercase}}</button>
                    </div>
                    <div class="col-md-6">
                        <button id="add_button" class="add-cat-btn" mat-raised-button color="primary"
                            (click)="addImsCategory()">{{'add' | translate | uppercase}}</button>
                    </div>
                </div>

            </mat-card-content>

        </mat-card>
    </div>

    <div *ngIf="data['type']=='editImsCategory'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'editCategory' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button id="ims_edit_btn" type="button" class="close" aria-label="Close"
                        (click)="closePopupImsEdit()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>


            <mat-card-content>

                <div class="row">

                    <div class="col-md-6 category-name">
                        <label>{{'categoryName' | translate | uppercase}}</label>
                        <input id="category_det_name" class="form-control"
                            [(ngModel)]="category.categoryDetail.category.name" type="text" name="categoryName">
                    </div>

                    <div class="col-md-6 category-name">
                        <label>{{'zoneOrLocation' | translate}}</label>
                        <mat-select id="sel_location" class="form-control" [(ngModel)]="selectedZones" multiple>
                            <mat-option *ngFor="let item of allZones" [value]="item">{{item.name}}</mat-option>
                        </mat-select>

                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12 ">
                        <button id="save_category_btn" class="save-category-div" mat-raised-button color="primary"
                            (click)="editCategory()">{{'save' | translate | uppercase}}</button>
                    </div>
                </div>

                <div class="row">
                    <mat-divider></mat-divider>
                </div>

                <!-- <div class="row">
  
            <div class="col-md-6 category-name" *ngFor="let item of subCategoryList; let idx = index">
              <label>SUB CATEGORY</label>
              <input [disabled]="true" class="form-control" [(ngModel)]="item.name" type="text"
                name="subCategory-{{idx}}">
            </div>
  
          </div> -->

                <div class="row sub-category-list">
                    {{'subCategories' | translate}}
                </div>

                <!-- sttt -->
                <div class="row category-chips">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip id="sub_categoryname" class="sub-category-chip"
                            *ngFor="let item of subCategoryList; let idx = index">{{
                            item.name }}
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <!-- endd -->

                <!-- start -->

                <div class="row">

                    <div class="col-md-6 category-name">

                        <input class="form-control" id="new_sub_category" type="text" name="subCategory"
                            [(ngModel)]="newSubCategory">
                    </div>

                    <div class="col-md-6">
                        <button id="add_more_sub_category" [disabled]="!newSubCategory" mat-raised-button
                            color="primary" (click)="addMoreSubCategory()">{{'addSubCategory' | translate}}</button>
                    </div>

                </div>
                <!-- end -->




                <!-- <div class="row" style="cursor: pointer;" (click)="addNewSubCategoryBox()">
            <div class="add-another-plus-icon">
              <i class="material-icons">
                add
              </i>
            </div>
            <div class="add-another-btn">
              Add Another
            </div>
          </div> -->

                <div class="row category-divider">
                    <mat-divider></mat-divider>
                </div>

                <div class="row sub-category-list">
                    {{'SLADetails' | translate}}
                </div>

                <div class="row escalation-level" *ngFor="let item of slaLevelList; let idx = index">


                    <div class="col-md-3 sla-level">
                        {{'SLALevel' | translate}} - {{idx+1}}
                    </div>

                    <div class="col-md-3">
                        <label>{{'selectTeam'| translate | uppercase}}</label>
                        <mat-select id="select_team" class="form-control" [(ngModel)]="item.group">
                            <mat-option *ngFor="let item of userGroups" [value]="item">{{item.name}}</mat-option>
                        </mat-select>
                    </div>

                    <div class="col-md-3">
                        <label>{{'timeHours'| translate}}</label>
                        <input id="time_in_hours" class="form-control" [(ngModel)]="item.time" type="text"
                            name="subCategory-{{idx}}">
                    </div>

                    <div class="col-md-3 escalation-save-div">
                        <button mat-raised-button color="primary" id="save_btn"
                            (click)="editEscalationLevel(item, idx+1)">{{'save'| translate | uppercase}}</button>
                    </div>

                </div>




                <!-- start -->

                <div class="row escalation-level" *ngFor="let item of newSlaLevelList; let idx = index">


                    <div class="col-md-3 sla-level">
                        {{'SLALevel' | translate}} - {{slaLevelList.length + idx+1}}
                    </div>

                    <div class="col-md-3">
                        <label>{{'selectTeam' | translate | uppercase}}</label>
                        <mat-select id="select_user_team" class="form-control" [(ngModel)]="item.group">
                            <mat-option *ngFor="let item of userGroups" [value]="item">{{item.name}}</mat-option>
                        </mat-select>
                    </div>

                    <div class="col-md-3">
                        <label>{{'timeHours'| translate}}</label>
                        <input id="sub_category_time" class="form-control" [(ngModel)]="item.time" type="text"
                            name="subCategory-{{idx}}">
                    </div>

                    <div class="col-md-3 escalation-save-div">
                        <button id="add_escalation" mat-raised-button color="primary"
                            (click)="addEscalationLevel(item, idx+1)">{{'add' | translate | uppercase}}</button>
                    </div>

                </div>
                <!-- end -->

                <div class="row" style="cursor: pointer;" id="add_new_sla_level" (click)="addNewSlaLevel()">
                    <div class="add-another-plus-icon">
                        <i class="material-icons">
                            {{'add' | translate | lowercase}}
                        </i>
                    </div>
                    <div class="add-another-btn">
                        {{'addAnother' | translate}}
                    </div>
                </div>

                <!-- <div class="row">
            <div class="col-md-12">
              <button mat-raised-button color="primary" (click)="editEscalationLevel()">SAVE</button>
            </div>
          </div> -->

                <div class="row category-add-cancel-row">
                    <!-- <div class="col-md-6">
              <button class="add-can-btn" mat-raised-button color="primary">CANCEL</button>
            </div>
            <div class="col-md-6">
              <button class="add-cat-btn" mat-raised-button color="primary">CANCEL</button>
            </div> -->
                </div>

            </mat-card-content>

        </mat-card>
    </div>



    <div *ngIf="data['type']=='groupDetails'">
        <mat-card class="example-card" style="height: 132px;">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'listOfUsers' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button id="modal_dismiss" type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>

            <div class="group-user-content">
                <mat-card-content>

                    <div *ngFor="let item of groupUsers; let i=index" class="row group-user-row">
                        <div class="col-md-2 group-user-index">
                            {{i+1}}
                        </div>
                        <div class="col-md-4 group-user-name">
                            {{item.firstName}}
                        </div>
                        <div class="col-md-6">
                            <div class="row group-user-action">
                                <!-- Remove ?
                  <div>
                    <button mat-button color="primary">Yes</button>
                    <button mat-button color="primary">No</button>
                  </div> -->
                                <!-- <div class="group-edit"><i class="material-icons">
                      edit
                    </i></div> -->
                                <div class="delete-group" id="del_user_grp" (click)="deleteUserFromGroup(item.id, i)"><i
                                        class="material-icons">
                                        {{'delete' | translate}}
                                    </i></div>
                            </div>
                        </div>

                    </div>


                </mat-card-content>
            </div>

        </mat-card>
    </div>


    <div *ngIf="data['type']=='addUsersToGroup'">
        <mat-card class="example-card" style="height: 170px;">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'listOfUsers' | translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" id="add_user_modal_close" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>

            <div class="group-user-content">
                <mat-card-content>

                    <form class="form-inline">
                        <div class="form-group mb-2" style="margin-left:25px">
                            <mat-form-field>
                                <mat-select id="ems_user_ids" [formControl]="bankServerSideCtrl"
                                    placeholder="{{'SearchUser' | translate}}" [multiple]="true"
                                    [(ngModel)]="selectedEmsUserIds">
                                    <!-- place ngx-mat-select-search inside mat-option to allow opening without initial selection -->
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search User"
                                            noEntriesFoundLabel="'no matching found'"
                                            [formControl]="bankServerSideFilteringCtrl" [searching]="searching">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let user of filteredServerSideBanks | async"
                                        [value]="user.userId" (click)="onClickEmsUserCheckbox(user)">
                                        {{user.name +' - '+ user.email}}
                                    </mat-option>
                                    <button id="next_records" *ngIf="showLoadMore" value="loadmore"
                                        style="font-size: 16px;width: 100%;text-align: left;"
                                        (click)="getNextRecords()">{{'loadMore' | translate}}...</button>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <button id="add_users" mat-raised-button style="margin-left:35px" class="btn btn-main"
                            (click)="addUsersToGroup()">
                            {{'addUsers' | translate | titlecase}}</button>
                    </form>
                    <div class="row">

                        <!-- <div class="col-md-6 category-name">
                <label>Select Users</label>
                <mat-select class="form-control" [(ngModel)]="selectedUsers" multiple>
                  <mat-option *ngFor="let item of allUsers" [value]="item">{{item.name}}</mat-option>
                </mat-select>
              </div> -->
                        <div class="col-md-6" style="margin-left: 30px;">
                            <!-- <label>Select Users</label> -->

                        </div>
                        <div class="col-md-6">

                        </div>

                    </div>

                    <div *ngFor="let item of selectedEmsUser; let i=index" class="row group-user-row">
                        <div class="col-md-2 group-user-index">
                            {{i+1}}
                        </div>
                        <div class="col-md-4 group-user-name">
                            {{item.name}}
                        </div>
                        <div class="col-md-6">
                            <div class="row group-user-action">

                                <!-- <div class="delete-group" (click)="deleteUserFromSelectedUser(i)"><i class="material-icons">
                      delete
                    </i></div> -->
                            </div>
                        </div>

                    </div>


                </mat-card-content>
            </div>

        </mat-card>
    </div>


    <!------ADD SLA-->
    <div *ngIf="data['type']=='AddEscalation'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{subCategory.edit && subCategory.level !=0 ? ('editLevel' | translate) :'addLevel' |
                            translate}}
                        </div>

                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

            </div>

            <mat-divider></mat-divider>


            <mat-card-content>
                <form class="form" name="addSla" #frm="ngForm"
                    (ngSubmit)="frm.form.valid && addSlaLevelNew(frm.form.valid, frm.form.value)" novalidate>
                    <div class="row" style="padding: 9px;">
                        <div class="form-group col-xs-10 col-sm-8 col-md-8 col-lg-8">
                            <label class="text-uppercase">{{'subCategory' | translate}}</label>
                            <input type="text" placeholder="e.g 2" class="form-control" name="subCategory"
                                [(ngModel)]="subCategory.subCategoryName" readonly />
                        </div>


                        <div class="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                            <label class="text-uppercase">{{'SLALevel' | translate}}</label>
                            <input type="text" placeholder="e.g 2" class="form-control" name="slaLevel"
                                [(ngModel)]="subCategory.sla" readonly (keypress)="numberOnly($event)" maxlength="1" />
                        </div>

                        <div class="clearfix"></div>
                        <div class="form-group col-xs-10 col-sm-8 col-md-8 col-lg-8">
                            <label class="text-uppercase">{{'team' | translate}}</label>
                            <select name="teamList" class="form-control" required [(ngModel)]="subCategory.teamId"
                                #teamList="ngModel" [ngClass]="{
                                              'is-invalid': frm.submitted && teamList.invalid
                                          }">
                                <option value="">{{ "select" | translate }}</option>
                                <ng-container *ngFor="let team of teamDataList; let i = index">
                                    <option [value]="team.teamId">{{team.teamName}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                            <label class="text-uppercase">{{'timeMinutes' | translate}}</label>
                            <input placeholder="e.g 12" type="text" class="form-control" name="intervalLevel"
                                [(ngModel)]="subCategory.interval" required #intervalLevel="ngModel" [ngClass]="{
                                              'is-invalid': frm.submitted && intervalLevel.invalid
                                          }" (keypress)="numberOnly($event)" maxlength="6" />
                        </div>


                        <div class="form-group col-xs-10 col-sm-12 col-md-12 col-lg-12 text-right"
                            style="margin-top: 26px;">

                            <button type="submit" class="btn btn-primary">{{subCategory.edit && subCategory.level !=0 ?
                                ('updateSLA' | translate) : 'addSLA' | translate}}</button>
                            <button type="button" *ngIf="isSlaEdit" (click)="cancelSlaEdit()"
                                class="btn btn-primary">{{'cancel' | translate}}</button>
                        </div>

                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- raees end -->
    <div *ngIf="data['type']=='viewTeamMembers'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            {{'listOfUsers' | translate}}
                        </div>
                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="group-user-content">
                <mat-card-content>
                    <ng-container *ngIf=" data['teamInfo']['memberList']?.length > 0">
                        <div *ngFor="let item of groupUsers; let i=index" class="row group-user-row">
                            <div class="col-md-2 group-user-index">
                                {{i+1}}
                            </div>
                            <div class="col-md-4 group-user-name">
                                {{item.firstName}}
                            </div>
                            <div class="col-md-6">
                                <div class="row group-user-action">
                                    <div class="delete-group" (click)="deleteTeamMember(item.id, i)">
                                        <i class="fa fa-trash text-primary"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                    <ng-container *ngIf=" data['teamInfo']['memberList']?.length == 0">
                        <div class="text-center">{{'noUserAdded' | translate}}</div>
                    </ng-container>



                </mat-card-content>
            </div>

        </mat-card>
    </div>
    <div *ngIf="data['type']=='addTeamMembers'">
        <mat-card class="example-card">
            <div class="row">
                <div class="col-md-6">
                    <mat-card-header>
                        <div class="info-header">
                            <!-- Add members to the {{data['teamInfo']['teamName']}} -->
                            {{'Addusers' | translate}}
                        </div>
                    </mat-card-header>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="group-user-content">
                <mat-card-content>

                    <form class="form-inline">
                        <div class="form-group mb-2" style="margin-left:25px">
                            <mat-form-field>
                                <mat-select [formControl]="bankServerSideCtrl"
                                    placeholder="{{'SearchUser' | translate}}" [multiple]="true"
                                    [(ngModel)]="selectedEmsUserIds">
                                    <!-- place ngx-mat-select-search inside mat-option to allow opening without initial selection -->
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search User"
                                            noEntriesFoundLabel="'no matching found'"
                                            [formControl]="bankServerSideFilteringCtrl" [searching]="searching">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let user of filteredServerSideBanks | async"
                                        [value]="user.userId" (click)="onClickEmsUserCheckbox(user)">
                                        {{user.name +' - '+ user.email}}
                                    </mat-option>
                                    <button *ngIf="showLoadMore" value="loadmore"
                                        style="font-size: 16px;width: 100%;text-align: left;"
                                        (click)="getNextRecords()">{{'loadMore' | translate}}...</button>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <button mat-raised-button style="margin-left:35px" color="primary" (click)="addUsersToTeam()">
                            {{'addUsers' | translate | titlecase}}</button>
                    </form>
                    <div class="row">
                        <div class="col-md-6" style="margin-left: 30px;">

                        </div>
                        <div class="col-md-6">

                        </div>

                    </div>

                    <div *ngFor="let item of selectedEmsUser; let i=index" class="row group-user-row">
                        <div class="col-md-2 group-user-index">
                            {{i+1}}
                        </div>
                        <div class="col-md-4 group-user-name">
                            {{item.name}}
                        </div>
                        <div class="col-md-6">
                            <div class="row group-user-action">
                            </div>
                        </div>

                    </div>


                </mat-card-content>
            </div>

        </mat-card>
    </div>
    <div *ngIf="data['type'] == 'chatShow'">
        <mat-card class="example-card"  (scroll)="scrollHandler($event)" >
          <div class="row">
            <div class="col-md-6">
              <mat-card-header>
                <div class="info-header">
                  <!-- Add members to the {{data['teamInfo']['teamName']}} -->
                  Chat
                </div>
              </mat-card-header>
            </div>
            <div class="col-md-6">
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <mat-divider>
           
          </mat-divider>
          <div class="group-user-content">
            <mat-card-content   >
              <div class="container" >
                <div style="flex-direction: row">
                  <div  style="text-align: right;color:#2b31ba" *ngIf="showMoreChat" (click)="moreShowChat()" >More chat</div>
                </div>
              
              </div>
             
              <div class="container" >
                <div style="flex-direction: row" *ngFor="let chatLists of chatList" >
                  <div
                    class="{{
                      raisedById == chatLists.createdBy
                        ? 'containerChat'
                        : 'containerChat darker'
                    }}"
                    class="containerChat"
    
                    *ngIf="!!chatLists.comment ||  !!chatLists.preSignedImage"
                  >
                    
           
                    <div>
                    <img
                      class="{{
                        raisedById == chatLists.createdBy
                          ? 'right imageCircle'
                          : 'imageCircle'
                      }}"
                      class="comment-image"
                      [src]="loadImage(chatLists)"
                    />
                      <p
                        class="{{
                          raisedById == chatLists.createdBy ? 'textAlign' : ''
                        }}"
                      >
                        {{ chatLists.comment }}
                      </p>
                      
                      <p style="float: right;width: 100%;"   *ngIf = 'chatLists.preSignedImage != null && chatLists.createdBy ==  raisedById'  >
                      
                        <img 
                      
                        style="width:100px;float:right;margin-right: 78px;;border-radius:0%"
                        class="comment-image"
                        src={{chatLists.preSignedImage}}
                      />
                      </p>
                      
                      <p style="float: right;width: 100%;"  *ngIf = 'chatLists.preSignedImage != null && chatLists.createdBy !=  raisedById'   >
                        
                        <img 
                      
                        style="width:100px;float:left;margin-right: auto;border-radius:0%"
                        class="comment-image"
                        src={{chatLists.preSignedImage}}
                      />
                      </p>
                    </div>
                    <div
                      class="{{
                        raisedById == chatLists.createdBy
                          ? 'time-right'
                          : 'time-left'
                      }}"
                      >{{chatLists.createdAt | date:"medium"}}</div>
                   
                  </div>
                
                </div>
                
                <img
                  *ngIf="chatShowImage"
                  class="comment-image"
                  src="{{ chaturl }}"
                />
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    name="chatText"
                    [(ngModel)]="chatText"
                    minlength="1"
                    maxlength="100"
                  />
                  <div class="input-group-append" id="topLoad">
                    <input
                      type="file"
                      (change)="chatFileUpload($event)"
                      name="eventFileData"
                      #eventFileData="ngModel"
                      [(ngModel)]="chatfile"
                      style="display: none"
                      #file
                    />
                    <span class="input-group-text" (click)="file.click()">
                      <i class="fa fa-paperclip" aria-hidden="true"></i
                    ></span>
                    <span class="input-group-text" (click)="saveChat()">
                      <i class="fa fa-paper-plane-o" aria-hidden="true"></i
                    ></span>
                    
                  </div>
                  
                </div>
                <div>
                  <span *ngIf="errorMsg!=''" class="text-danger">{{errorMsg}}</span>
                </div>
              </div>
            </mat-card-content>
          </div>
        </mat-card>
      </div>

</div>