import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EMSService } from 'emsService';
import { LoaderService } from 'loaderService';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-intro-modal-module-activation',
  templateUrl: './intro-modal-module-activation.component.html',
  styleUrls: ['./intro-modal-module-activation.component.scss']
})
export class IntroModalModuleActivationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<IntroModalModuleActivationComponent>,
  private emsservice: EMSService,
  private loaderService: LoaderService,
  private toastrService : ToastrService) { }
  pageType;
  private unsubscribe$ = new Subject<void>();
  inProgressContactSales;
  currentZone;
  category:string= ""
  scheduledDemo:string = ""
  ngOnInit() {
    this.pageType = this.data['type'];
    this.inProgressContactSales = this.data['inProgressContactSales'];
    this.currentZone = this.data['currentZone'];
    this.scheduledDemo = this.data['scheduledDemo']
    this.category = this.data['category']
  }
  closePopup(){
    this.dialogRef.close();
  }
  resquestScheduledemo(){
    let requestObj = {
      moduleId: this.data['moduleId'],
      zoneId: this.currentZone,
      contactSalesStatus: "IN_PROGRESS",
      status:"IN_PROGRESS"
    }
  
    const currentuserModule$ = this.emsservice.scheduleDemo(this.currentZone,this.data['moduleId'],requestObj, 'SMART_ACCESS');
       this.loaderService.showLoaderUntilCompleted(currentuserModule$).pipe(takeUntil(this.unsubscribe$)).subscribe(res => { 
        this.toastrService.success("We've notified the Jumpree Sales team. Expect to hear from them soon!");
        this.inProgressContactSales = true;
        if(this.category === 'SMART ACCESS'){
          this.scheduledDemo = "IN PROGRESS"
          this.dialogRef.close("success");  
        }
     }, err => {
         //this.toastrService.error(err.error.message);
     })
  
  }

  ngOnDestroy() {
    if(this.unsubscribe$){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    }
}
}
