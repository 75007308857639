import { Component, EventEmitter, OnInit, Output,Input } from "@angular/core";
import { CommonService } from "commonService";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-weekly",
  templateUrl: "./weekly.component.html",
  styleUrls: ["./weekly.component.scss"],
})
export class WeeklyComponent implements OnInit {
  calenderDates = [];
  currentDate = this.commonService.getTimeStamp(
    new Date().getTime(),
    "start"
  );
  displayCalendar = [];
  disableNextBtn: boolean = false;
  disablePrevBtn: boolean = false;
  selectedDate: number;
  page: number = 1; 
  timeZoneByConfig = 'Asia/Kolkata';
 
  @Output() dateSelected = new EventEmitter<Object>();
  @Input() isManual=true;
  constructor(public commonService: CommonService, public translate: TranslateService) {
   
    
  }

  ngOnInit() {
  
      const commonTimeSub$ = this.commonService.currentTimeZone.subscribe((res) => {
        this.timeZoneByConfig = res;
        if(this.timeZoneByConfig == "Asia/Kolkata" || this.timeZoneByConfig == null || this.timeZoneByConfig == ""){
          this.timeZoneByConfig = localStorage.getItem('currentTimeZone');
        }
      });

      if(this.timeZoneByConfig == null){
          this.timeZoneByConfig = localStorage.getItem('currentTimeZone');

      }


        this.selectedDate = this.commonService.getTimeStampTimeZone(
          new Date().getTime(), this.timeZoneByConfig,
          "start"
        );

        // all days of the current month
        if(this.isManual){
          this.calenderDates = this.getAllDaysInMonth(this.selectedDate);
        }
        this.disablePrevBtn = true;

     
  }

  // getAllDaysInMonth(selectedDate) {
  //   const date = this.commonService.getTimeStampTimeZoneInDateObj(selectedDate, 'UTC', "start");
  //   let resultList = [];
  //   let weekNameList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //   let i = 0;
  //   // check for get month    
  //   while (i <= 30) {
  //     let timeStamp = this.commonService.getTimeStampTimeZone(date.getTime(), 'UTC', "start");      
  //     resultList.push({
  //       id: timeStamp,
  //       date: date.getUTCDate(),
  //       day: weekNameList[date.getUTCDay()],
  //     });      
  //     // }
  //     date.setDate(date.getDate() + 1);
  //     i++;
  //   }
  //   return resultList;
  // }
  getTranslate(word) {
    let translatedWord = word;
    const test = this.translate.get([word]).subscribe(translation => {
      translatedWord = translation;
    });
    return translatedWord[word];
  }
  getAllDaysInMonth(selectedDate) {
    const date = this.commonService.getTimeStampTimeZoneInDateObj(selectedDate, 'UTC', "start");
    let getTime = date.getTime();
    let resultList = [];
    let weekNameList = [this.getTranslate("Sun"), this.getTranslate("Mon"), this.getTranslate("Tue"), this.getTranslate("Wed"), this.getTranslate("Thu"), this.getTranslate("Fri"), this.getTranslate("Sat") ];
    let i = 0;
    // check for get month    
    while (i <= 30) {
      let timeStamp = this.commonService.getTimeStampTimeZone(getTime, 'UTC', "start");   
      let conDate = new Date(getTime);   
      resultList.push({
        id: timeStamp,
        date: conDate.getUTCDate(),
        day: weekNameList[conDate.getUTCDay()],
      });      
      // }
      getTime = getTime+86400*1000;
      i++;
    }
    //console.log(resultList);
    return resultList;
  }

  nextPrevWeek = (type) => {
    if (type == "N") {
      if (this.page == 5) {
        return false;
      }
      this.page += 1;
      this.disablePrevBtn = false;
      if (this.page == 5) {
        this.disableNextBtn = true;
      } else {
        this.disableNextBtn = false;
      }      
    } else {
      if (this.page == 1) {
        return false;
      }
      this.page -= 1;
      this.disableNextBtn = false;
      if (this.page == 1) {
        this.disablePrevBtn = true;
      } else {
        this.disablePrevBtn = false;
      }
    }

  };

  updateDynamicallyDates = (type,date) => {
    this.selectedDate = date;
    const dates = new Date(date);
    this.calenderDates=this.getAllDaysInMonth(this.currentDate);
    let cDate=this.calenderDates.map(res=>res.id);
    let index = cDate.indexOf(this.selectedDate);
    let i= Math.floor(index/7);
    this.page=i;     
    this.nextPrevWeek(type);
  }

  addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  loadDateInfo(d,selectionType?) {
    let dateObj = {
      stype:selectionType,
      date: d
    };
    this.selectedDate = d;
    this.dateSelected.emit(dateObj);
    this.commonService.selectedDate = d;
  }
}
