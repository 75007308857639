<div>
    <div *ngIf="pageType === 'sendRequest'">
        <div class="modal-header">
            <h4 id="send_request_title" class="modal-title">
                {{ "newRequests" | translate }}
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid p-0">
                <!-- <div class="vertical"></div> -->
                <form [formGroup]="requestFormGroup">
                    <div class="col p-0 d-lg-flex d-md-flex">
                        <div class="col-lg-7 col-md-7 col-sm-6">
                            <div class="row">
                                <div class="col form-group">
                                    <p class="float-left">Primary information</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 pr-lg-0 pr-md-0 form-group d-sm-flex d-md-flex flex-column justify-content-md-end justify-content-sm-end text-left">
                                    <label class="float-left">{{'TYPE OF WORKSTATION' | translate }}</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select id="workstation_type" formControlName="workStation" placeholder="Select">
                                            <mat-option *ngFor="let item of workStationList; let i = index"
                                                [value]="item.id">
                                                {{ item.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4 col-md-4 form-group d-sm-flex d-md-flex flex-column justify-content-md-end justify-content-sm-end text-left">
                                    <label class="float-left">{{'QUANTITY' | translate}}</label>
                                    <input id="quantity" type="number" class="form-control" formControlName="quantity">
                                </div>
                                <div class="col-lg-4 col-md-4 pl-lg-0 pl-md-0 form-group d-sm-flex d-md-flex flex-column justify-content-md-end justify-content-sm-end text-left">
                                    <label class="float-left">{{'CITY' | translate }}</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select id="city" formControlName="city" placeholder="Select">
                                            <mat-option *ngFor="let item of cityList; let i = index" [value]="item.id">
                                                {{ item.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 pr-lg-0 pr-md-0 form-group d-sm-flex d-md-flex flex-column justify-content-md-end justify-content-sm-end text-left">
                                    <label>{{'Location' | translate }}</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select id="location" formControlName="location" placeholder="Select">
                                            <mat-option *ngFor="let item of locationList; let i = index"
                                                [value]="item.id">
                                                {{ item.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-radio-group class="float-left" formControlName="validity">
                                        <mat-radio-button class="label form-group" value="Permanent">
                                            {{ 'permanent' | translate}}
                                        </mat-radio-button>
                                        <mat-radio-button class="label form-group pl-lg-2 pl-md-2" value="Temporary">
                                            {{ "temporary" | translate}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 pr-lg-0 pr-md-0 padding-top-4 form-group">
                                    <div class="input-group input-icons">
                                        <i class = "fa fa-calendar icon" [owlDateTimeTrigger]="startDate1"></i>
                                        <input id="start_date" placeholder="{{ 'Start Date' | translate }}" formControlName="startDate"
                                        class="form-control borderCurved w-100" [owlDateTimeTrigger]="startDate1"
                                        [owlDateTime]="startDate1"> 
                                        <owl-date-time [pickerType]="'calendar'" #startDate1></owl-date-time>    
                                        <!-- <input placeholder="{{ 'Start Date' | translate }}" formControlName="startDate"
                                            class="form-control borderCurved" [owlDateTimeTrigger]="startDate1"
                                            [owlDateTime]="startDate1">
                                        <span [owlDateTimeTrigger]="startDate1"><i
                                                class="fa fa-calendar fa-calender-icons"></i></span>
                                        <owl-date-time [pickerType]="'calendar'" #startDate1></owl-date-time> -->
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 padding-top-4 form-group">
                                    <div class="input-group input-icons">
                                        <i class = "fa fa-calendar icon" [owlDateTimeTrigger]="endDate1"></i>
                                    <input id="end_date" placeholder="{{ 'End date' | translate }}" formControlName="endDate"
                                    class="form-control borderCurved w-100" [owlDateTimeTrigger]="endDate1" [owlDateTime]="endDate1">
                                    <owl-date-time [pickerType]="'calendar'" #endDate1></owl-date-time>
                                        <!-- <input placeholder="{{ 'End date' | translate }}" formControlName="endDate"
                                            class="form-control borderCurved" [owlDateTimeTrigger]="endDate1"
                                            [owlDateTime]="endDate1">
                                        <span [owlDateTimeTrigger]="endDate1"><i
                                                class="fa fa-calendar fa-calender-icons"></i></span>
                                        <owl-date-time [pickerType]="'calendar'" #endDate1></owl-date-time> -->
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 pl-lg-0 pl-md-0 padding-top-4 form-group">
                                    <!-- <ngb-timepicker formControlName="startTime">
                                    </ngb-timepicker> -->
                                    <mat-form-field appearance="fill">
                                        <mat-select id="shift_start" formControlName="shiftStart" placeholder="Select">
                                            <mat-option *ngFor="let item of notifyTimesList; let i = index"
                                                [value]="item">
                                                {{ item }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 col-md-3 pl-lg-0 pl-md-0 padding-top-4 form-group">
                                    <!-- <ngb-timepicker formControlName="endTime">
                                    </ngb-timepicker> -->
                                    <mat-form-field appearance="fill">
                                        <mat-select id="shift_end" formControlName="shiftEnd" placeholder="Select">
                                            <mat-option *ngFor="let item of notifyTimesList; let i = index"
                                                [value]="item">
                                                {{ item }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col form-group">
                                    <div class="float-left d-flex align-items-center">
                                        <label>{{'Dual utilisation' | translate }}</label> &nbsp;
                                        <mat-slide-toggle formControlName="dualUtilisation">
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col form-group">
                                    <p class="float-left">Expense Details</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 form-group d-sm-flex d-md-flex flex-column justify-content-md-end justify-content-sm-end text-left">
                                    <label for="costCode">{{'COST CODE' | translate }}</label>
                                    <input id="cost_code" type="text" class="form-control" formControlName="costCode">
                                </div>
                                <div class="col-lg-4 col-md-4 form-group d-sm-flex d-md-flex flex-column justify-content-md-end justify-content-sm-end text-left">
                                    <label for="departmentName">{{'DEPARTMENT NAME' | translate}}</label>
                                    <input id="depatment_name" type="text" class="form-control" formControlName="departmentName">
                                </div>
                            </div>
                        </div>
                        <mat-divider vertical></mat-divider>
                        <div class="col-lg-5 col-md-5 col-sm-6">
                            <div class="row">
                                <div class="col form-group">
                                    <p class="float-left">Additional Requirements</p>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-5 col-md-5">
                                    <p class="float-left">{{'Laptops' | translate }}</p>
                                </div>
                                <div class="col-lg-5 col-md-5">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="laptop" formControlName="laptop" placeholder="Select">
                                            <mat-option *ngFor="let item of laptopList; let i = index"
                                                [value]="item.id">
                                                {{ item.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-5 col-md-5">
                                    <p class="float-left">{{'IP Phone' | translate }}</p>
                                </div>
                                <div class="col-lg-5 col-md-5">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="ip_phone" formControlName="ipPhone" placeholder="Select">
                                            <mat-option *ngFor="let item of laptopList; let i = index"
                                                [value]="item.id">
                                                {{ item.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-5 col-md-5">
                                    <p class="float-left">{{'VPN' | translate }}</p>
                                </div>
                                <div class="col-lg-5 col-md-5">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="vpn" formControlName="vpn" placeholder="Select">
                                            <mat-option *ngFor="let item of laptopList; let i = index"
                                                [value]="item.id">
                                                {{ item.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-5 col-md-5">
                                    <p class="text-left">{{'Biometric Access' | translate }}</p>
                                </div>
                                <div class="col-lg-5 col-md-5">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="biometric_access" formControlName="bioMetric" placeholder="Select">
                                            <mat-option *ngFor="let item of laptopList; let i = index"
                                                [value]="item.id">
                                                {{ item.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-5 col-md-5">
                                    <p class="text-left">{{'Special Requests' | translate }}</p>
                                </div>
                                <div class="col-lg-7 col-md-7">
                                    <textarea id="special_request" class="float-left" formControlName="specialRequest" cols="20"
                                        rows="5"></textarea>
                                </div>
                            </div>
                            <div class="row pt-5">
                                <div class="col form-group">
                                    <button id="create_new_request" type="button" class="btn btn-main float-right rounded-pill"
                                        (click)="sendRequest()">{{'Send
                                        Request' |
                                        translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer border-0">
        </div>
    </div>

    <div *ngIf="pageType === 'if-analysis'">
        <div class="header-body">
            <div class="col d-flex p-0">
                <div class="col p-0">
                    <h4 id="analysis_title" class="modal-title">
                        {{ "What if analysis" | translate }}
                    </h4>
                </div>
                <div class="col-1">
                    
                    <button id="close_analysis" type="button" class="mt-2 close float-right" aria-label="Close" (click)="closePopup()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <form [formGroup]="whatIfAnalysisForm">
                <div class="row">
                    <!-- <div class="col-4">
                        <label>{{'REQUEST TYPE' | translate }}</label>
                        <mat-form-field appearance="fill">
                            <mat-select id="request_type" formControlName="requestType">
                                <mat-option *ngFor="let item of requestTypeList; let i = index" [value]="item.id">
                                    {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <p class="float-left">{{'Choose one of the below priorities' | translate}}</p>
                            </div>
                        </div>
                        <mat-radio-group formControlName="whatIfType">
                            <div class="row form-group">
                                <div class="col-6">
                                    <mat-radio-button class="label" value="REQUEST_SIZE">
                                        {{ "Request Size" | translate}}
                                    </mat-radio-button>
                                </div>
                                <div class="col-6">
                                    <mat-radio-button class="label" value="REQUIREMENT_DATE">
                                        {{ "Requirement Date" | translate}}
                                    </mat-radio-button>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6">
                                    <mat-radio-button class="label" value="REQUESTED_DATE">
                                        {{ "Date of Request" | translate}}
                                    </mat-radio-button>
                                </div>
                                <div class="col-6">
                                    <mat-radio-button class="label" value="REQUESTER">
                                        {{ "Requester" | translate}}
                                    </mat-radio-button>
                                </div>
                            </div>
                        </mat-radio-group>
                        <div class="row pt-3">
                            <div class="col">
                                <button id="apply" type="button" class="btn btn-main float-right rounded-pill" (click)="whatIfAnalysisApply()">{{'Apply' |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="pageType === 'recommendations'">
        <div class="modal-header">
            <h4 id="recommendation_title" class="modal-title">
                {{ "Recommendations" | translate }}
            </h4>
            <button id="close_recommendation" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-2">
                            <p class="reco-header">{{'Request By' | translate }}</p>
                            <h6 class="reco-value">{{'Desmond Eagle' | translate}}</h6>
                        </div>
                        <div class="col-lg-2">
                            <p class="reco-header">{{'Request Type' | translate }}</p>
                            <h6 class="reco-value">{{'Space' | translate}}</h6>
                        </div>
                        <div class="col-lg-2">
                            <p class="reco-header">{{'Requested Desk' | translate }}</p>
                            <h6 class="reco-value">{{'100' | translate}}</h6>
                        </div>
                        <div class="col-lg-2">
                            <p class="reco-header">{{'Currently Allocated' | translate }}</p>
                            <h6 class="reco-value">{{'300' | translate}}</h6>
                        </div>
                        <div class="col-lg-2">
                            <p class="reco-header">{{'Currently Occupied' | translate }}</p>
                            <h6 class="reco-value">{{'200' | translate}}</h6>
                        </div>
                        <div class="col-lg-2">
                            <p class="reco-header">{{'Balance' | translate }}</p>
                            <h6 class="reco-value">{{'100' | translate}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <button id="share_recommendations" type="button" class="btn btn-main float-right rounded-pill" (click)="shareRecommendations()">{{'Share' |
                        translate}}</button>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-12">
                    <p class="float-left">{{'These are the following recommendations to fulfilling your demand' |
                        translate}}</p>
                </div>
                <div class="col-2 col-21">
                    <mat-card class="p-0" style="min-height: 250px;">
                        <div class="row example-box m-0"
                            style="background-image: url('../../../../assets/images/login.jpg');">
                            <div class="col-12">
                                <h6 class="float-left pt-3 card-heading" >{{'Optimize'}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-2">
                                <p class="reco-header p-2">{{'There are currently 100 unassigned desks previously
                                    allocated'
                                    | translate }}</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-2 col-21">
                    <mat-card class="p-0" style="min-height: 250px;">
                        <div class="row example-box m-0"
                            style="background-image: url('../../../../assets/images/login.jpg');">
                            <div class="col-12">
                                <h6 id="optimize" class="float-left pt-3 card-heading" >{{'workFromHome'| translate }}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-2">
                                <p class="text-left p-2">
                                    <b>80 Desks</b><br />
                                    <small>(20 Employees can work from home)</small>
                                </p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-2 col-21">
                    <mat-card class="p-0" style="min-height: 250px;">
                        <div class="row example-box m-0"
                            style="background-image: url('../../../../assets/images/login.jpg');">
                            <div class="col-12">
                                <h6 class="float-left pt-3 card-heading" >{{'location'| translate }}

                                    
                                </h6>
                                <mat-checkbox class="float-right lh-40"
                                                [checked]="allComplete"
                                                (change)="setAll($event.checked)">
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-2">
                                <ng-container *ngFor="let subtask of subtasks"> 
                                <p class="reco-header p-2">
                                    <mat-checkbox 
                                    [(ngModel)]="subtask.completed"
                                    (ngModelChange)="updateAllComplete()"
                                    >
                                    </mat-checkbox>
                                    <b class="ml-2">{{subtask.name}}</b>
                                    <small>80 Desks</small>
                                </p>
                                </ng-container>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-2 col-21">
                    <mat-card class="p-0" style="min-height: 250px;">
                        <div class="row example-box m-0"
                            style="background-image: url('../../../../assets/images/login.jpg');">
                            <div class="col-12">
                                <h6 class="float-left pt-3 card-heading" >{{'shift'| translate }}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-2">
                                <p class="reco-header p-1 pl-2"> 4.00am - 9.00am </p>
                                <p class="reco-header p-1 pl-2"> 10.00am - 12.00pm </p>
                                <p class="reco-header p-1 pl-2"> 1.00pm - 6.00pm </p>
                                <p class="reco-header p-1 pl-2"> 7.00pm - 8.00pm </p>
                                <p class="reco-header p-1 pl-2"><a class="anchormore">more...</a></p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-2 col-21">
                    <mat-card class="p-0" style="min-height: 250px;">
                        <div class="row example-box m-0"
                            style="background-image: url('../../../../assets/images/login.jpg');">
                            <div class="col-12">
                                <h6 class="float-left pt-3 card-heading" >{{'Space Conversion'| translate }}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-2">
                                <p class="reco-header p-1 pl-2"> Meeting room1 <small class="floatRight">10 seats</small></p>
                                <p class="reco-header p-1 pl-2"> Meeting room2 <small class="floatRight">20 seats</small></p>
                                <p class="reco-header p-1 pl-2"> Meeting room3 <small class="floatRight">12 seats</small></p>
                                <p class="reco-header p-1 pl-2"> Meeting room24 <small class="floatRight">23 seats</small></p>
                                <p class="reco-header p-1 pl-2"><a class="anchormore">more...</a></p>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
        </div>
    </div>
    <div *ngIf="pageType === 'share-info'">
        <div class="modal-header">
            <h4 id="share_info_title" class="modal-title">
                {{ "Share" | translate }}
            </h4>
            <button id="share_info_close" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Request ID' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'RZ34fds' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Request Type' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'Space' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Requested Desks' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'100' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Currently Allocated' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'300' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Currently Occupied' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'200' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Balance' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'100' | translate}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <!-- button -->
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-2 col-21 col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center">
                    <mat-card class="p-0">
                        <div id="first_img" class="row example-box m-0">
                            <div class="col-12">
                                <h6 class="main_para">{{'Optimize'}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-3">
                                <p class="reco-header p-3 text-center txt_bold">{{'There are currently 100 unassigned
                                    desks previously allocated'
                                    | translate }}</p>
                            </div>
                            <div class="col-12 pt-3 text-center">
                                <p class="mb-0 txt_lght">Total Cost:</p>
                                <b>SGD 50,000/Month</b>
                            </div>
                            <div class="col-12 pt-2">
                                <p class="p-2 text-center" style="font-size:9px; font-weight: 600;">*This is an
                                    approximate cost considering space and other expenses</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-2 col-21 col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center">
                    <mat-card class="p-0">
                        <div id="second_img" class="row example-box m-0">
                            <div class="col-12">
                                <h6 class="main_para">{{'Work From Home'}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-4 pb-3">
                                <b style="font-size: 17px;" class="p-3">80 Desks</b>
                                <p class="text-center txt_bold p-1" style="font-size: 10px;">{{'(20 employees can work
                                    from home)'| translate }}</p>
                            </div>
                            <div class="col-12 pt-2 text-center">
                                <p class="mb-0 txt_lght">Total Cost:</p>
                                <b>SGD 50,000/Month</b>
                            </div>
                            <div class="col-12 pt-2">
                                <p class="p-2 text-center" style="font-size:9px; font-weight: 600;">*This is an
                                    approximate cost considering space and other expenses</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-2 col-21 col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center">
                    <mat-card class="p-0">
                        <div id="third_img" class="row example-box m-0">
                            <div class="col-12">
                                <h6 class="main_para">{{'Location'}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-4 pb-3">
                                <b style="font-size: 17px;" class="p-3">{{'Millennia'| translate }}</b>
                                <p class="text-center txt_bold p-1" style="font-size: 10px;">100 Desks</p>
                            </div>
                            <div class="col-12 pt-2 text-center">
                                <p class="mb-0 txt_lght">Total Cost:</p>
                                <b>SGD 50,000/Month</b>
                            </div>
                            <div class="col-12 pt-2">
                                <p class="p-2 text-center" style="font-size:9px; font-weight: 600;">*This is an
                                    approximate cost considering space and other expenses</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <div class="col-lg">
                    <button id="share_info_share" type="button" class="btn btn-main float-right rounded-pill">{{'Share' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="pageType === 'share-recommendations'">
        <div class="modal-header">
            <!-- <h4 class="modal-title">
                {{ "Share" | translate }}
            </h4> -->
            <div class="modal-title">
                <button id="share_recommendations_back" class="btn btn-link rounded-pill" (click)="backToRecommendations()">{{'back' | translate}}</button>
            </div>
            <button id="share_recommendations_close" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Request ID' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'RZ34fds' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Request By' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'Deskmond Eagle' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Request Type' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'Space' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Requested Desks' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'100' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Currently Allocated' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'300' | translate}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Currently Occupied' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'200' | translate}}</h6>
                        </div>
                        <!-- <div class="col-lg-2 col-md-6 col-sm-6 col-xs">
                            <p class="reco-header">{{'Balance' | translate }}</p>
                            <h6 class="reco-value fnt_bld">{{'100' | translate}}</h6>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs">
                        <p class="reco-header">{{'Balance' | translate }}</p>
                        <h6 class="reco-value fnt_bld">{{'100' | translate}}</h6>
                    </div>
                    <!-- button -->
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <p class="float-left">{{'Share following suggestions to the requestor' | translate}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-2 col-21 col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center">
                    <mat-card class="p-0">
                        <div id="first_img" class="row example-box m-0">
                            <div class="col-12">
                                <h6 class="main_para">{{'Optimize'}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-3">
                                <p class="reco-header p-3 text-center txt_bold">{{'There are currently 100 unassigned
                                    desks previously allocated'
                                    | translate }}</p>
                            </div>
                            <div class="col-12 pt-3 text-center">
                                <p class="mb-0 txt_lght">Total Cost:</p>
                                <b>SGD 50,000/Month</b>
                            </div>
                            <div class="col-12 pt-2">
                                <p class="p-2 text-center" style="font-size:9px; font-weight: 600;">*This is an
                                    approximate cost considering space and other expenses</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-2 col-21 col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center">
                    <mat-card class="p-0">
                        <div id="second_img" class="row example-box m-0">
                            <div class="col-12">
                                <h6 class="main_para">{{'Work From Home'}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-4 pb-3">
                                <b style="font-size: 17px;" class="p-3">80 Desks</b>
                                <p class="text-center txt_bold p-1" style="font-size: 10px;">{{'(20 employees can work
                                    from home)'| translate }}</p>
                            </div>
                            <div class="col-12 pt-2 text-center">
                                <p class="mb-0 txt_lght">Total Cost:</p>
                                <b>SGD 50,000/Month</b>
                            </div>
                            <div class="col-12 pt-2">
                                <p class="p-2 text-center" style="font-size:9px; font-weight: 600;">*This is an
                                    approximate cost considering space and other expenses</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-2 col-21 col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center">
                    <mat-card class="p-0">
                        <div id="third_img" class="row example-box m-0">
                            <div class="col-12">
                                <h6 class="main_para">{{'Location'}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-4 pb-3">
                                <b style="font-size: 17px;" class="p-3">{{'Millennia'| translate }}</b>
                                <p class="text-center txt_bold p-1" style="font-size: 10px;">100 Desks</p>
                            </div>
                            <div class="col-12 pt-2 text-center">
                                <p class="mb-0 txt_lght">Total Cost:</p>
                                <b>SGD 50,000/Month</b>
                            </div>
                            <div class="col-12 pt-2">
                                <p class="p-2 text-center" style="font-size:9px; font-weight: 600;">*This is an
                                    approximate cost considering space and other expenses</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <div class="col-lg">
                    <button id="share_recommendations_share" type="button" class="btn btn-main float-right rounded-pill">{{'Share' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="data.type  == 'preferredAllocation'">
        <div class="modal-header d-flex align-items-center border-bottom-0">
            <img class="block-img" src="./assets/images/preferred-allocation.svg"/>
                <div class="col">
                    <h6 id="send_request_title" class="modal-title ">
                        <span class="borderBottom">{{ "currentAllocationInPreferredLocation" | translate }}</span>
                    </h6>
                </div>
            <button type="button" class="close ml-5" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid p-0">
                <div class="col">
                    <div class="col d-flex flex-wrap p-0" *ngIf="preferredZoneEntityInsightList">
                        <div class="col-lg-4 col-md-4 col-sm-12 mt-4" *ngFor="let building of preferredZoneEntityInsightList.values; let i = index">
                            <div class="text-left"><b class="ml-3 text-left">{{building.building.name}}</b></div>
                            <mat-card class="cardSize mt-2 mat-elevation-z3">
                                <div *ngFor="let floor of building.floorCounts; let j = index">
                                    <div *ngIf="floor.entityTypeCounts.length" class="vh-25 position-relative borderBottom" [ngClass] = "{'borderBottom': building.floorCounts.length != 1}">
                                        <div class="d-flex justify-content-between" [ngClass] = "{'mt-3': j>0}">
                                            <div>
                                                {{floor.zoneLocationDto.name| slice: 0:20}}<span *ngIf="floor.zoneLocationDto.name.length > 20">...</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mt-3 flex-wrap">
                                            <ng-container *ngFor="let workstation of floor.entityTypeCounts; let k = index">
                                                <div class="totalCircle circlePosition" [ngStyle]="{'background-color':getLegendColor(workstation.entityType.id)}" [ngClass]="k>0 ? '' : '' ">
                                                </div>
                                            </ng-container>
                                            <div class="col ml-2 text-left">
                                                {{floor.totalCountAvailable}}/{{floor.countRequired}} 
                                                <span class="fs-12">(Available/Required)</span>
                                            </div>
                                        </div>
                                        <div class="d-flex mt-3">
                                            <div class="align-items-center" *ngFor="let workstation of floor.entityTypeCounts; let l = index" [ngClass]="floor.entityTypeCounts.length == 1 ? 'd-none' : 'd-flex' ">
                                                <div class="smallCircle" [ngClass]="l>0 ? 'ml-3' : ''" [ngStyle]="{'background-color':getLegendColor(workstation.entityType.id)}"></div>
                                                <div class="ml-2">{{workstation.availableCount}}/{{getRequiredWorkstationCount(workstation.entityType.id)}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                    <div class="col d-flex flex-wrap mt-4">
                        <div class="d-flex align-items-center" *ngFor="let entity of preferredZoneEntityInsightList.entityTypeMap | keyvalue; let m = index">
                            <div class="smallCircle ml-3"  [ngStyle]="{'background-color':getLegendColor(entity.key)}" [ngClass]="m>0 ? 'ml-3' : ''">
                            </div>
                            <div class="ml-2">
                                {{entity.value.name}}
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data.type  == 'availability'">
        <div class="modal-header d-flex align-items-center border-bottom-0">
            <img class="block-img" src="./assets/images/calendar-checked.svg"/>
                <div class="col">
                    <h6 id="send_request_title" class="modal-title ">
                        <span class="borderBottom">{{ "availabilityInYourPreferredLocation" | translate }}</span>
                    </h6>
                </div>
            <button type="button" class="close ml-5" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid p-0">
                <table mat-table [dataSource]="availabilityDataSource" class="form-group">
                    <ng-container matColumnDef="workstation">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'requiredWorkstation' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            {{element?.entityType?.name || 'NA'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'quantity' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            {{element?.availableCount || 'NA'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="availability">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'availability' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <div class="d-flex align-items-center justify-content-center" *ngIf="element.isDemandSatisfied">
                                <div class="availableCircle mr-2"></div>
                                {{'available'|translate}}
                            </div>
                            <div class="d-flex align-items-center justify-content-center" *ngIf="!element.isDemandSatisfied">
                                <div class="notAvailableCircle mr-2"></div>
                                {{'notAvailable'|translate}}
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedAvailabiltyColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedAvailabiltyColumns;"></tr>
                    <!-- <tr mat-header-row *matHeaderRowDef="displayedAvailabiltyColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedAvailabilityColumns"></tr> -->
                </table>
                <!-- <mat-paginator *ngIf="length" class="w-100 mtop-1" #paginator [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="getNextRecords($event)"
                    [length]="length"></mat-paginator> -->
            </div>
        </div>
    </div>
    <div *ngIf="!showApproveRejectComments && (data.type == 'approvalNotification' || data.type == 'rejectNotification')">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="closeConfirmationPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="data.type == 'approvalNotification'">
                <b>Your request with Id {{data.dataObj.requestId}} has been approved</b>
            </div>
            <div *ngIf="data.type == 'rejectNotification'">
                <b>Your request with Id {{data.dataObj.requestId}} has been rejected</b>
            </div>
        </div>
        <div mat-dialog-actions class="d-flex justify-content-end mb-0">
            <button mat-button class="btn-main mr-3 rounded-pill" (click)="closeConfirmationPopup()" cdkFocusInitial>Ok</button>
          </div>
    </div>
    <div *ngIf="showApproveRejectComments">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="closeConfirmationPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <label class="text-left">{{"comments" | translate}}</label>
            <input type="text" class="form-control" [(ngModel)]="approveRejectComments">
            <!-- <div *ngIf="data.type == 'approvalNotification'">
                <b>Your request with Id {{requestId}} has been approved</b>
            </div>
            <div *ngIf="data.type == 'rejectNotification'">
                <b>Your request with Id {{requestId}} has been rejected</b>
            </div> -->
        </div>
        <div mat-dialog-actions class="d-flex justify-content-end mb-0">
            <button mat-button class="btn-main mr-3 rounded-pill" (click)="approveRejectRequest()" cdkFocusInitial>Ok</button>
          </div>
    </div>
</div>