import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { CommonService } from 'commonService';
import { EMSService } from 'emsService';
import { LoaderService } from 'loaderService';
import { ReplaySubject, Subject, EMPTY, of } from 'rxjs';
import { debounceTime, delay, takeUntil, tap, filter, map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { SanitizationService } from '../../services/sanitization.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss']
})
export class AddTeamComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _onDestroy = new Subject<void>();
  public searching = false;

  addTeamForm = new FormGroup({
    teamSearch: new FormControl(''),
    existingTeam: new FormControl('', [Validators.required]),
    newTeam: new FormControl('', [Validators.required]),
    searchUser: new FormControl(''),
    usersList: new FormControl('')
  });

  teamsList = [];
  filteredTeamsList = [];
  teamDetails;
  showSearchUser = false;
  isloading = false;
  currentZone;
  myplaceHolder: string = 'Search Teams';
  public filteredTeamsFilteringCtrl: FormControl = new FormControl();
  public filteredTeams: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  searchTermSubject$ = new Subject<any>();
  searchTermAction$ = this.searchTermSubject$.asObservable();
  autocompleteList$ = this.searchTermAction$.pipe(
    takeUntil(this.unsubscribe$),
    debounceTime(200),
    tap(() => {
      this.isloading = true;
    }),
    switchMap((searchTerm) =>
      of(searchTerm).pipe(
        mergeMap((res) =>
          this.sanitizationService.getAutocompleteList(
            {
              searchTerm,
              searchType: 'user',
            },
            this.currentZone
          )
        )
      )
    ),
    tap((searchRes) => {
      this.isloading = false;
    }),
    catchError((err) => {
      return EMPTY;
    })
  );

  constructor(
    public dialog: MatDialog,
    private loaderService: LoaderService,
    private sanitizationService: SanitizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private emsService: EMSService,
    private toastrService: ToastrService
  ) {
    this.currentZone = data.currentZone
  }

  ngOnInit() {
    this.getTeams();
    this.filteredTeamsFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.teamsList) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.teamsList.filter(team => team.teamName.toLowerCase().indexOf(search) > -1);
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredBanks => {
        this.searching = false;
        this.filteredTeams.next(filteredBanks);
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get f() {
    return this.addTeamForm.controls;
  }

  checkDropdownClose(event) {
    // if(event == true){

    // }else{
    //   this.addTeamForm.controls.teamSearch.reset();
    //   this.addTeamForm.controls.searchUser.reset();
    // }
  }

  getAutocompleteList = (searchTerm) => {
    if (!searchTerm) {
      this.addTeamForm.controls['newTeam'].setValidators([Validators.required]);
      this.addTeamForm.controls['existingTeam'].clearValidators();
    }
    this.searchTermSubject$.next(searchTerm.trim());
  };

  onChangeTeam(event) {
    if (this.addTeamForm.controls.existingTeam.value) {
      this.showSearchUser = true;
      this.addTeamForm.controls['existingTeam'].setValidators([Validators.required]);
      this.addTeamForm.controls['newTeam'].clearValidators();
      this.getTeamDetails(event.value.teamId)
    } else {
      this.showSearchUser = false;
    }
  }

  attributeDisplay(attribute1, attribute2) {
    if (attribute1.teamId == attribute2.teamId) {
      return attribute1.teamName;
    } else {
      return "";
    }
  }

  deleteUser(team, user) {
    let teamId = team.teamId;
    let userId = user.id;
    let data = {
      "team": {
        "id": teamId
      },
      "members": [userId]
    };
    const teamDetails = this.sanitizationService.deleteTeamMember(this.data.currentZone, data);
    this.loaderService
      .showLoaderUntilCompleted(teamDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if (res.response) {
          var prevExistingTeam = this.addTeamForm.controls.existingTeam.value;
          var prevUserList = this.addTeamForm.controls.usersList.value;
          this.addTeamForm.controls.existingTeam.setValue(prevExistingTeam);
          this.addTeamForm.controls.usersList.setValue(prevUserList);
          this.getTeamDetails(this.addTeamForm.controls.existingTeam.value.teamId)
          this.toastrService.success(res.message);
        }
        else {
          this.toastrService.error(res.message);
        }
      });
  }

  getTeamDetails(teamId) {
    const teamDetails = this.sanitizationService.getTeamDetails(this.data.currentZone, teamId);
    this.loaderService
      .showLoaderUntilCompleted(teamDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.teamDetails = res.response;
      });
  }

  getMatchingTeams(event) {
    if (this.addTeamForm.controls.teamSearch.value) {
      var searchString = this.addTeamForm.controls.teamSearch.value;
      // const teamDetails = this.sanitizationService.searchFilterTeams(this.data.currentZone, searchString);
      // this.loaderService
      //   .showLoaderUntilCompleted(teamDetails)
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe(res => {
      //     this.filteredTeamsList = res.response;
      //   });
      const teamDetails = this.sanitizationService.searchFilterTeams(this.data.currentZone, searchString);
      teamDetails
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => {
          this.filteredTeamsList = res.response;
        });
    } else {
      this.addTeamForm.controls.teamSearch.reset();
      this.showSearchUser = false;
      this.filteredTeamsList = [];
    }
  }

  checkPlaceHolder() {
    if (this.myplaceHolder) {
      this.myplaceHolder = null
      return;
    } else {
      this.myplaceHolder = 'Enter Name'
      return
    }
  }

  getTeams() {
    const teamDetails = this.sanitizationService.getAllTeams(this.data.currentZone);
    this.loaderService
      .showLoaderUntilCompleted(teamDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.teamsList = res.response;
      });
  }

  submit() {
    var memberId = [];
    if (this.addTeamForm.controls.usersList.value) {
      this.addTeamForm.controls.usersList.value.forEach(element => {
        memberId.push(element);
      });
    } else {
    }
    if (this.addTeamForm.controls.existingTeam.value && !this.addTeamForm.controls.newTeam.value) {
      let data = {
        "name": this.addTeamForm.controls.existingTeam.value.teamName,
        "zoneIdList": [this.currentZone],
      };
      //this.addTeam(data);
      if (this.addTeamForm.controls.usersList.value) {
        this.addMembersToTeam(memberId);
      } else {
        this.toastrService.error('Select users to add to the team');
      }
    } else if (this.addTeamForm.controls.newTeam.value && !this.addTeamForm.controls.existingTeam.value) {
      let data = {
        "name": this.addTeamForm.controls.newTeam.value,
        "zoneIdList": [this.currentZone],
      };
      this.addTeam(data);
    } else if (this.addTeamForm.controls.existingTeam.value && this.addTeamForm.controls.newTeam.value) {
      this.toastrService.error('Select an existing team or enter a new team only')
    } else {

    }
  }

  addTeam(data) {
    const teamDetails = this.sanitizationService.addTeam(this.currentZone, data);
    this.loaderService
      .showLoaderUntilCompleted(teamDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.toastrService.success(res.message);
      }, err => {
        this.toastrService.error(err)
      });
    this.dialog.closeAll();
  }

  addMembersToTeam(memberList) {
    let data = {
      "team": {
        "id": this.addTeamForm.controls.existingTeam.value.teamId
      },
      "members": memberList
    };
    const teamDetails = this.sanitizationService.addTeamMembers(this.currentZone, data);
    this.loaderService
      .showLoaderUntilCompleted(teamDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.toastrService.success(res.message);
      }, err => {
        this.toastrService.error(err)
      });
    this.dialog.closeAll();
  }

}
