import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "src/app/services/moduleid-service";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError,of } from "rxjs";
import { catchError, timeout, tap, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class SocialDistancingService {
    moduleId;
    BASEURL;
    EMSURL;
    constructor(
        private http: HttpClient,
        private moduleidService: ModuleidService,
        private toastrService: ToastrService,
        @Inject("BASE_URL") baseUrl: string
    ) {
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL;
        this.moduleId = this.moduleidService.getModueId();
    }

    getmapDetails(floorZoneId, currentZone, type = ""): Observable<any> {
        let url = `${this.BASEURL}socialdistancing/coloredCounts?zoneId=${floorZoneId}`;
        if (!!type) {
            url = `${this.BASEURL}socialdistancing/coloredCounts?zoneId=${floorZoneId}&view=${type}`;
        }

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError(this.handleError)
            );
    }

    getStatus(currentZone): Observable<any> {
        const url = `${this.EMSURL}ems/activityStatus/all`;
        return this.http
            .get(url, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError(this.handleError)
            );
    }
    getDepartmentDetails(currentZone): Observable<any> {
        const url = `${this.BASEURL}socialdistancing/departmentCounts`;
        return this.http
            .get(url, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError(this.handleError)
            );
    }

    getAllReAllocations(zoneId, reqObj): Observable<any> {
        let searchParams = "";
        if (reqObj.searchQuery != "") {
            searchParams = `&searchString=${reqObj["searchQuery"]}`;
        }
        if (
            reqObj["floorId"] != "" &&
            typeof reqObj["floorId"] !== "undefined"
        ) {
            searchParams += `&zoneId=${reqObj["floorId"]}`;
        }
        if (reqObj["filter"] != "") {
            searchParams += `&filter=${reqObj["filter"]}`;
        }
        let url =
            this.BASEURL +
            `socialdistancing/reallocation?size=${reqObj["pageSize"]}&page=${reqObj["pageIndex"]}${searchParams}`;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe(
                
                catchError(this.handleError)
            );
    }

    getAllSocialDistancingDetails(zoneId): Observable<any> {
        let url = this.BASEURL + `socialdistancing/floorDetails`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(
            catchError(err => of([]))
        );
    }

    getAllSocialDistanceDetails(zoneId): Observable<any> {
        let url = this.BASEURL + `socialdistancing/average`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(
            catchError(err => of([]))
        );
    }

    getAllSocialDistancingApplyMeters(zoneId, data): Observable<any> {
        let url = this.BASEURL + `socialdistancing/findValidSeats`;
        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getAllSocialDistancingReEnableSeats(zoneId, data): Observable<any> {
        let url = this.BASEURL + `socialdistancing/reEnableSeats`;
        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    updateSocialDistancingSeats(data, currentZone) {
        const url = `${this.BASEURL}/socialdistancing/updateSocialDistancingInFloor`;
        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError(this.handleError)
            );
    }

    getAllSocialDistancingDepartments(zoneId, locId): Observable<any> {
        let locationId = "";
        if (locId != "") {
            locationId = "?zoneId=" + locId;
        }

        let url =
            this.BASEURL + `socialdistancing/departmentCounts` + locationId;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    addActivityStatus(data, currentZone) {
        const url = `${this.EMSURL}ems/activityStatus`;
        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map(
                    (res) => res["response"],
                    this.toastrService.success(
                        "Added Activity status successfully"
                    )
                ),
                catchError(this.handleError)
            );
    }

    updateActivityStatus(data, currentZone) {
        const url = `${this.EMSURL}ems/activityStatus`;
        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map(
                    (res) => res["response"],
                    this.toastrService.success(
                        "Updated Activity status successfully"
                    )
                ),
                catchError(this.handleError)
            );
    }

    getLocationAvailability(
        currentZone,
        rowdata,
        floorId = ""
    ): Observable<any> {
        const url = `${this.BASEURL}entity/findAvailableCountsSocialDistancing`;
        return this.http
            .post<any>(url, rowdata, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError(this.handleError)
            );
    }
    getAvailabilityMapView(currentZone, rowdata): Observable<any> {
        const url = `${this.BASEURL}entity/availabilityMapViewSocialDistancing`;
        return this.http
            .post<any>(url, rowdata, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError(this.handleError)
            );
    }
    bookSeat(currentZone, data): Observable<any> {
        const url = `${this.BASEURL}socialdistancing/assignSeats`;
        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError(this.handleError)
            );
    }
    handleError = (err) => {
        /*let errorMsgData = (err as any).error;
        const message = errorMsgData["message"];
        this.toastrService.error(message);*/
        return throwError(err);
    };
}
