import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'commonService';
import { LoaderService } from 'loaderService';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CuratedServicesService } from '../curated-services.service';

@Component({
  selector: 'app-curated-services-modal',
  templateUrl: './curated-services-modal.component.html',
  styleUrls: ['./curated-services-modal.component.scss']
})
export class CuratedServicesModalComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('hiddenIframe',{ static: false }) iFrame: ElementRef;
  iframeLoadInterval: any;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public translate: TranslateService,
  private formBuilder: FormBuilder,
  public dialogRef: MatDialogRef<CuratedServicesModalComponent>,
  public toastrService:ToastrService,
  private commonService: CommonService,
  private loaderService: LoaderService, 
  private curatedServicesService:CuratedServicesService) {
    }
  currentZone = null;
  pageType :any;
  myForm: FormGroup; 
  showToggle = false
  public appImagepathImage:any;
  public fileToUploadApp: File;
  public fileToUploadDashboard: File;
  public dashboardImagepathImage: any;
  formData: FormData = new FormData();
  curatedSubmitted = false;
  appFileName = "";
  dashboardFileName = "";
  upload_appUrl  = false;
  upload_appDashboardUrl =  false;
  amenity = { amenityName: '', upload_appUrl: '', upload_dashboardUrl: '', config: '', id: "" };
  verificationResult;
  urlValidate = false;
  iFrameCheck = false;
  openNewTab;
  ngOnInit() {
    this.pageType = this.data['type'];
    this.currentZone = this.data['currentZone']; 
    this.myForm = this.formBuilder.group({
      spaceName: ['', [Validators.required, Validators.maxLength(25)]],
      spaceUrl: ['', Validators.required],
      openNewTab:[false],
      radioBtn: ['', Validators.required],
      // Add more form controls if needed
    });
    if(this.data['dialogType']=="editModal"){
      if(this.data['dialogData']){
        let dialogData = this.data['dialogData'];
        this.myForm.get('spaceName').setValue(dialogData['serviceName']);
        this.myForm.get('spaceUrl').setValue(dialogData['serviceUrl']);
        if(dialogData['redirectType'] == "WEBVIEW_WITH_HEADER"){
          this.myForm.get('radioBtn').setValue('NO');
          this.myForm.get('openNewTab').setValue(true);
          this.showToggle = true;
        }else{
          this.myForm.get('radioBtn').setValue('YES');
          this.myForm.get('openNewTab').setValue(dialogData['newTab']);
          this.showToggle = false;
        }
         
        this.appImagepathImage=dialogData['appImageUrl'];
        this.dashboardImagepathImage=dialogData['dashboardImageUrl'];
        setTimeout(() => {this.verifyUrlEdit(dialogData['serviceUrl']);},500);     
      }
    }
  
    
  }
  closePopup(){
    this.dialogRef.close();
  }
  
  onChooseFileApp(files: FileList, event: { target: HTMLInputElement }) {
    var fileName = event.target.value;
    var fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    fileType = fileType.toLowerCase();
    if (event.target.files[0].size > 1048576) { // 1MB = 1048576 bytes
      this.appImagepathImage = "";
      this.amenity.upload_appUrl = "";
      this.toastrService.error(this.translate.instant("Image size should be less than 1MB"));
      return;
  }
    if (
      fileType !== "png" &&
      fileType !== "jpg" &&
      fileType !== "jpeg"
    ) {
      this.appImagepathImage = "";
      this.amenity.upload_appUrl = "";
      this.toastrService.error(this.translate.instant("notValidFile"));
      return;
    }
   
   this.upload_appUrl = false;
    var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		
    reader.onload = (event:any) => {
      const img = new Image();

      // Set the image source to the loaded data
      img.src = event.target.result;

      // When the image is loaded, get its width and height
      img.onload = () => {
        const width = img.width;
        const height = img.height;
       // if (event.target.files[0].size > 160947) {
        if(width > 170 && height > 196 ){
          this.toastrService.error(this.translate.instant("Height and Width must not exceed 170*196") );
          return;
        }else{
          this.appImagepathImage = reader.result; 
          this.fileToUploadApp = files.item(0);
          this.appFileName = this.fileToUploadApp.name;
        }
      };
			
		}
  }

  onChooseFileDashboard(files: FileList, event: { target: HTMLInputElement }) {
    var fileName = event.target.value;
    var fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    fileType = fileType.toLowerCase();
    if (event.target.files[0].size > 1048576) { // 1MB = 1048576 bytes
      this.appImagepathImage = "";
      this.amenity.upload_appUrl = "";
      this.toastrService.error(this.translate.instant("Image size should be less than 1MB"));
      return;
  }
    if (
      fileType !== "png" &&
      fileType !== "jpg" &&
      fileType !== "jpeg"
    ) {
      this.amenity.upload_dashboardUrl = "";
      this.dashboardImagepathImage = "";
      this.toastrService.error(this.translate.instant("notValidFile"));
      return;
    }
    this.upload_appDashboardUrl = false;
     var reader = new FileReader();
     reader.readAsDataURL(event.target.files[0]);
     reader.onload = (event: any) => {
      const img = new Image();

      // Set the image source to the loaded data
      img.src = event.target.result;

      // When the image is loaded, get its width and height
      img.onload = () => {
        const width = img.width;
        const height = img.height;
       // if (event.target.files[0].size > 160947) {
        if(width > 440 && height > 225 ){
          this.toastrService.error(this.translate.instant("heightAndWidhMustNotExceed440"));
          return;
        }else{
          this.dashboardImagepathImage = reader.result; 
          this.fileToUploadDashboard = files.item(0);
          this.dashboardFileName = this.fileToUploadDashboard.name;
        }
      };
       
     }
  }

  submitCuratedForm(){
    this.curatedSubmitted= true;
    if(this.data['dialogType']=="addModal"){
    if(this.amenity.upload_appUrl == ''){
      this.upload_appUrl  = true;  
    }
    if(this.amenity.upload_dashboardUrl == ''){ 
      this.upload_appDashboardUrl = true; 
    }
    if(this.amenity.upload_appUrl == '' || this.amenity.upload_appUrl == ''){
      return;
    }
    if (this.myForm.get('spaceUrl').invalid || this.myForm.get('spaceName').invalid) {
      return;
    }
    if(this.myForm.get('spaceName').value.trim() === ''){
      this.toastrService.error("Please enter a valid service name.");
      return;
    }
    this.formData = new FormData();
    let data :any = {};
    data.serviceName = this.myForm.get('spaceName').value,
    data.serviceUrl = this.myForm.get('spaceUrl').value,
    data.status = "ENABLED";
    data.zoneId = this.currentZone;
    data.redirectType = this.myForm.get('openNewTab').value == true ? "WEBVIEW_WITH_HEADER" : "WEB";
 

    this.formData.append("model", JSON.stringify(data));
    if (this.fileToUploadApp != undefined) {
      this.formData.append("appFile", this.fileToUploadApp, this.fileToUploadApp.name);
    }
    if (this.fileToUploadDashboard != undefined) {
      this.formData.append("dashboardFile", this.fileToUploadDashboard, this.fileToUploadDashboard.name);
    }

    const currentuserModule$ = this.curatedServicesService.saveCuratedSerivces(this.currentZone, this.formData);
       this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
         let resp = res['response'];
         if(resp){
          this.toastrService.success("Curated service has been successfully saved.");
          this.dialogRef.close();
         }
         
     }, err => {
         this.toastrService.error(err.error.message);
     })
  }
  else if(this.data['dialogType']=="editModal"){
   let dialogData = this.data['dialogData'];
    if (this.myForm.get('spaceUrl').invalid || this.myForm.get('spaceName').invalid) {
      return;
    }
    if(this.myForm.get('spaceName').value.trim() === ''){
      this.toastrService.error("Please enter a valid service name.");
      return;
    }
    this.formData = new FormData();
    let data :any = {};
    data.id = dialogData['id'];
    data.serviceName = this.myForm.get('spaceName').value,
    data.serviceUrl = this.myForm.get('spaceUrl').value,
    data.status = dialogData['status'];
    data.zoneId = this.currentZone;
    data.redirectType = this.myForm.get('openNewTab').value == true ? "WEBVIEW_WITH_HEADER" : "WEB";
    //data.newTab = this.myForm.get('openNewTab').value;

    if (this.fileToUploadApp != undefined) {
      this.formData.append("appFile", this.fileToUploadApp, this.fileToUploadApp.name);
    }else{
      data.appImageUrl = dialogData['appImageUrl'];
    }
    if (this.fileToUploadDashboard != undefined) {
      this.formData.append("dashboardFile", this.fileToUploadDashboard, this.fileToUploadDashboard.name);
    }else{
      data.dashboardImageUrl = dialogData['dashboardImageUrl'];
    }
    this.formData.append("model", JSON.stringify(data));

    const currentuserModule$ = this.curatedServicesService.saveCuratedSerivces(this.currentZone, this.formData);
       this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => { 
         let resp = res['response'];
         if(resp){
          this.toastrService.success("Curated service has been successfully saved.");
          this.dialogRef.close();
         }
         
     }, err => {
         this.toastrService.error(err.error.message);
     })
  }
}
verifyUrlEdit(url) {
  this.iFrame.nativeElement.src = "";
  let websiteUrl=url;

  if (!websiteUrl) {
    this.toastrService.error('Please enter a valid URL.');
    return;
  }

  const urlWithoutFragment = websiteUrl.split('#')[0];
  const sanitizedUrl = urlWithoutFragment.replace(/[^a-zA-Z0-9/.:]/g, '');

  // Perform basic URL validation
  const urlPattern = /^(https?:\/\/)?([\w.-]+\.[a-z]{2,})(\/[\w .-]*)*\/?$/i;
  if (urlPattern.test(sanitizedUrl)) {
    this.urlValidate = true;
    // this.toastrService.success('Service URL has been successfully verified.'); 
      const iframe = this.iFrame.nativeElement as HTMLIFrameElement;
      iframe.src = websiteUrl;
      this.iFrameCheck = true;
  } else {
    this.urlValidate = false;
  //  this.toastrService.error('Service URL is Invalid. Please enter a valid URL starting with https://');
  }
}
verifyUrl() {
  this.iFrame.nativeElement.src = "";
  let websiteUrl=this.myForm.get('spaceUrl').value;

  if (!websiteUrl) {
    this.toastrService.error('Please enter a valid URL.');
    return;
  }

  const urlWithoutFragment = websiteUrl.split('#')[0];
  const sanitizedUrl = urlWithoutFragment.replace(/[^a-zA-Z0-9/.:]/g, '');

  // Perform basic URL validation
  const urlPattern = /^(https?:\/\/)?([\w.-]+\.[a-z]{2,})(\/[\w .-]*)*\/?$/i;
  if (urlPattern.test(sanitizedUrl)) {
    this.urlValidate = true;
    this.toastrService.success('Service URL has been successfully verified.'); 
      const iframe = this.iFrame.nativeElement as HTMLIFrameElement;
      iframe.src = websiteUrl;
      this.iFrameCheck = true;
  } else {
    this.urlValidate = false;
   this.toastrService.error('Service URL is Invalid. Please enter a valid URL starting with https://');
  }
}

closeAlertPopup(data){
  this.dialogRef.close(data);
}
closeAlertPopupData(data,element){
let dataInfo = {
  type : data,
  element: element
}
  this.dialogRef.close(dataInfo);
}
getToolTipDataInfo(data){
  return data;
}


checkRadio(){
  if (this.myForm.get('radioBtn').value=='NO') {
    this.showToggle = true;
    return;
  }else{
    this.myForm.get('openNewTab').setValue(false);
    this.showToggle = false;
  }
}
}
