import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ModuleidService } from "src/app/services/moduleid-service";
import { environment } from "src/environments/environment";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { Observable } from "rxjs";
import { v4 as uuidv4 } from "uuid";
@Injectable({
  providedIn: "root",
})
export class PeopleService {
  moduleId;
  BASEURL;
  EMSURL;
  BASEEMSURL;
  BASE_URL_Tenant;
  constructor(
    public http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public moduleidService: ModuleidService
  ) {
    this.BASEURL = baseUrl;
    this.EMSURL = environment.BASE_URL + "ems/";
    this.BASEEMSURL = environment.BASE_URL;
    this.moduleId = moduleidService.getModueId();
    this.BASE_URL_Tenant = environment.BASE_URL + "tms/tenant/digital/";
  }

  loadDepartments(deptObj): Observable<any> {
    let url =
      this.EMSURL +
      `department/admin/getAll?page=${deptObj["pageIndex"]}&size=${deptObj["pageSize"]}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  

  loadUnmappedUsers(zone,id,ps,pi){
    let url =
      this.EMSURL +
      `user/getUserDetailsByTenant?tenantId=${id}&page=${pi}&size=${ps}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zone, this.moduleId),
    });
  }

  loadUsers(userObj) {
    let url;
    if (userObj.showSearch) {
      url =
        this.EMSURL +
        `user/search/v3?searchString=${userObj["searchValue"]}&searchParams=name,email,phone_num,phoneNum&page=${userObj["pageIndex"]}&size=${userObj["pageSize"]}`;
    } else {
      url =
        this.EMSURL +
        `user/admin/all?sortBy=createdAt&searchColumn=&searchValue=&page=${userObj["pageIndex"]}&size=${userObj["pageSize"]}`;
    }

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  loadUsersWithZone(zone, userObj) {
    let url, tenantId;
    let tenantAdmin =
      localStorage.getItem("tenantAdmin") == "true" ? true : false;
    if (tenantAdmin) tenantId = localStorage.getItem("tenantId");

    if (tenantAdmin) {
        if (userObj.showSearch)
        url =
          this.EMSURL +
          `user/tenantAdmin/getAll?tenantId=${tenantId}&searchString=${userObj.searchValue}&page=${userObj.pageIndex}&size=${userObj.pageSize}&searchBy=${userObj.searchBy}`;
          else 
          url =
          this.EMSURL +
          `user/tenantAdmin/getAll?tenantId=${tenantId}&page=${userObj.pageIndex}&size=${userObj.pageSize}`;
        
    } else {
      if (userObj.showSearch) {
        if (localStorage.getItem("tenant_Engagement") == "true") {
          url =
            this.EMSURL +
            `user/admin/tenantFlowUser/getAll?superAdmin=true&searchString=${userObj.searchValue}&page=${userObj.pageIndex}&size=${userObj.pageSize}&searchBy=${userObj.searchBy}`;
        } else {
          url =
            this.EMSURL +
            `user/super-admin/getPaginated?searchString=${userObj["searchValue"]}&searchParams=name,email,phone_num,phoneNum&page=${userObj["pageIndex"]}&size=${userObj["pageSize"]}&zoneId=${zone}`;
        }
      } else {
        //url = this.EMSURL + `user/admin/all?sortBy=createdAt&searchColumn=&searchValue=&page=${userObj['pageIndex']}&size=${userObj['pageSize']}`;
        if (localStorage.getItem("tenant_Engagement") == "true") {
          url =
            this.EMSURL +
            `user/admin/tenantFlowUser/getAll?superAdmin=true&searchString=${userObj.searchValue}&page=${userObj.pageIndex}&size=${userObj.pageSize}&searchBy=${userObj.searchBy}`;
        } else {
          url =
            this.EMSURL +
            `user/super-admin/getPaginated?zoneId=${zone}&sortBy=createdAt&searchColumn=${userObj.searchBy}&searchValue=${userObj.searchValue}&page=${userObj.pageIndex}&size=${userObj.pageSize}`;
        }
      }
    }

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zone, this.moduleId),
    });
  }
  mapUserToLoction(id, zone, status, sa) {
let cancelBookingFlag=false;
    if(status=='DISABLED'){
      cancelBookingFlag=true;
    }
    let url =
      this.EMSURL +
      `roles/admin/updateGeneralUserRole?userId=${id}&status=${status}&superAdmin=${sa}&cancelBooking=${cancelBookingFlag}`;
    return this.http.post<any>(
      url,
      {},
      {
        params: new ZoneAndModuleHttpParams(zone, this.moduleId),
      }
    );
  }
  mapAllUsers(zone, sa, validate) {
    let url =
      this.EMSURL +
      `roles/admin/enableAllUser?validate=${validate}&superAdmin=${sa}`;
    return this.http.post<any>(
      url,
      {},
      {
        params: new ZoneAndModuleHttpParams(zone, this.moduleId),
      }
    );
  }
  enabledDisableDept(id, checked) {
    let url;
    if (checked) {
      url = this.EMSURL + `department/admin/enable?id=${id}`;
    } else {
      url = this.EMSURL + `department/admin/disable?id=${id}`;
    }
    return this.http.post<any>(
      url,
      {},
      {
        params: new ZoneAndModuleHttpParams(null, this.moduleId),
      }
    );
  }

  enabledDisableUserDept(data) {
    let url;
    url = this.EMSURL + `user/admin/changeStatus`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  downloadUsersCsv(zoneId, dataObj): Observable<any> {
    let url = this.EMSURL + `user/super-admin/getAllUserDetailsCsv`;
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
          this.moduleId
        }","sessionId": "${localStorage.getItem("ssadmin_session")}"}`,
      }),
    };
    return this.http.post<any>(url, dataObj, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadUsersCsvTenantPeople(zoneId) {
    let url = this.EMSURL + `user/tenant/download/csv?zoneId=${zoneId}`;
    return this.http.get(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadUsersCsvTenantPeopleTenantAdmin(zoneId) {
    let id = localStorage.getItem("tenantId");
    let url =
      this.EMSURL +
      `user/tenant/download/csv?zoneId=${zoneId}&tenantAdmin=true&tenantId=${id}`;
    return this.http.get(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  downloadBulkUploadEntities(zoneId, sa, data = {}) {
    const headers = new HttpHeaders({
      "ss-header":
        '{"correlationId":"' +
        uuidv4() +
        '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
        zoneId +
        ',"moduleId":12001,"sessionId":"' +
        localStorage.getItem("ssadmin_session") +
        '"}',
    });

    let url =
      environment.BASE_URL +
      `tms/downloadUserTemplateBulkUpload?superAdmin=${sa}`;
    // responseType: 'blob' is for xlsx file conversion
    return this.http.post(url, data, {
      headers: headers,
      responseType: "blob",
    });
  }

  validateExcelOnboarding(fileToUpload, zoneId) {
    const endpoint =
      environment.BASE_URL + "ems/user/validateBulkUpload/tenant/v2?superAdmin=true";
    var formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(endpoint, formData, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  saveOnboardedEntitiesViaExcel(zoneId, validDatas): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
          zoneId +
          ',"moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") +
          '"}',
      }),
    };
    let url = environment.BASE_URL + `ems/user/saveBulkUpload/tenant`;
    return this.http.post(url, validDatas, httpOptions);
  }

  saveOnboardedEntitiesViaExcelTenantAdmin(
    zoneId,
    validDatas,
    sa
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
          zoneId +
          ',"moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") +
          '"}',
      }),
    };
    let url =
      environment.BASE_URL + `ems/user/saveBulkUpload/tenant/v2?superAdmin=${sa}`;
    return this.http.post(url, validDatas, httpOptions);
  }
  verifyEmail(email) {
    let url;
    url = this.EMSURL + `user/emailValidation?email=${email}`;
    return this.http.get(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
  edtUserDetails(id,sa,zoneid) {
    let url;
    url = this.EMSURL + `user/getById?id=${id}&superAdmin=${sa}`;
    return this.http.get(url, {
      params: new ZoneAndModuleHttpParams(zoneid, this.moduleId),
    });
  }
  getAllTenant(zoneId, pageNo, size) {
    //?page=${pageNo}&size=${size}
    return this.http.get(this.BASE_URL_Tenant + `getAll?validate=true`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  onBoardUsers(data, zoneId, internal, sa) {
    let url;
    url =
      this.EMSURL +
      `user/admin/tenantFlowUser/add?superAdmin=${sa}&internal=${internal}`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  //  /ems/user/admin/editUserDetails
  editUsers(data, zoneId, internal, sa) {
    let url;
    url =
      this.EMSURL +
      `user/admin/editUserDetails/tenantFlow?superAdmin=${sa}&internal=${internal}`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  disbaleUser(zoneId, data) {
    let url;
    url = this.EMSURL + `user/admin/changeStatus`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
}
