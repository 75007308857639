import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";

import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { User } from "./user";
import { ZoneAndModuleHttpParams } from "../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { modulesIds } from "../../environments/environment";
import { Subject } from "rxjs";
import { v4 as uuidv4 } from 'uuid';
@Injectable()
export class LoginService {
    private moduleId;
    responsibilityList = [];
    localVal;
    private readonly BASE_URL;
    private readonly ORIGIN_URL;
    private readonly DEV_CLOUD;;
    constructor(private http: HttpClient) {
        this.moduleId = modulesIds.enterpriseManagementSystem;
        this.BASE_URL = environment.BASE_URL + "ems/";
        this.ORIGIN_URL = environment.BASE_URL;
        this.DEV_CLOUD = environment.BASE_DEV_CLOUD,
        this.localVal = localStorage.getItem("language");

    }
    messageSource = new BehaviorSubject("default message");
    currentMessage = this.messageSource.asObservable();

    setResponsibility(list) {
        this.changeMessage(list);
        //   return Observable.of(list);
    }

    changeMessage(message: any) {
        this.messageSource.next(message);
    }

    login(user: any) {
        let locale = localStorage.getItem("language");
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"ADMIN_WEB_APP","correlationId":"${uuidv4()}","locale":"${locale}"}`
            })
        };

        return this.http
            .post(this.BASE_URL + "user/login", user, httpOptions)
            .map(function(response) {
                return response;
            });
    }

    requestOtp(user) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
            })
        };
        return this.http.post(
            this.BASE_URL + "file/user/login",
            { email: user.email },
            httpOptions
        );
    }

    otpLogin(user) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
            })
        };
        return this.http.post(
            this.BASE_URL +
                "file/user/verifyOtp?code=" +
                user.otp +
                "&email=" +
                user.email,
            {},
            httpOptions
        );
    }

    // getModule(id) {
    //   return this.http.get(environment.getModule + id).map(function (response) {
    //     return response;
    //   })
    // }

    sendForgotPasswordEmail(forgotPasswordRequest) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
            })
        };
        return this.http
            .post(
                this.BASE_URL + "user/forgotPassword",
                forgotPasswordRequest,
                httpOptions
            )
            .map(function(response) {
                return response;
            });
    }

    logOut(zoneId) {
        return this.http.post(
            this.BASE_URL + "user/logout",
            {},
            { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) }
        );
    }

    usermappingsByEmai(email){
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"ADMIN_WEB_APP","correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
            })
        };
        return this.http
            .get(this.ORIGIN_URL+"gms/mapping/usermappings-by-email?email="+email, httpOptions)
            .map(function(response) {
                return response;
            });
    }
    sendResetPasswordEmailLink(forgotPasswordRequest){
        const httpOptions ={
            headers: new HttpHeaders({
                'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
            })
        };
        return this.http.post(this.BASE_URL+"user/forgotPassword",forgotPasswordRequest,httpOptions);
    }
    languageSelection(zone){
        const httpOptions ={
            headers: new HttpHeaders({
                'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
            })
        };
        return this.http
            .get("https://devcloud.smartenspaces.com/gms/locale/all?status=ENABLED", httpOptions)
            .map(function(response) {
                return response;
            });
    }
}
