import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  NgForm,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";

import { ToastrService } from "ngx-toastr";
import { NgForOfContext } from "@angular/common";
import * as _ from "lodash";
import { TenantPeopleService } from "../service/events-cat.service";
import { LoaderService } from "loaderService";

import { CommonService } from "commonService";
import { ExcelService } from "../../services/excel.service";
import { GlobalSetupService } from "../../services/global-setup.service";
import { map, startWith } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { EMSService } from "emsService";
import { forkJoin } from "rxjs";
@Component({
  selector: "app-event-modal",
  templateUrl: "./events-cat-modal.component.html",
  styleUrls: ["./events-cat-modal.component.scss"],
})
export class TenantPeopleModalComponent implements OnInit {
  pageType;
  addType = "";
  currentZone;
  editCatName = "";
  editCatData;
  incorrectDataColumns = [
    // "slNo",
    "fname",
    "lname",
    "email",
    "typeOfUser",
    "globalAdmin",
    "tag",
    "tenantName",
    "reason",
  ];
  incorrectDataColumnsManualAcess = [
    // "slNo",
    "fname",
    "lname",
    "email",
    "typeOfUser",
    "globalAdmin",
    "tenantName",
    "reason",
  ];
  manualForm;
  addUsersNonTeams;
  tenantIdUnique
  fb = this.formBuilder;
  submittedExcel;
  listViewExcelResponse;
  incorrectData;
  correctData;
  noOfEntitiesUploaded;
  saveCorrectData;
  totalIncorrectLength;
  showIncorrectData;
  TotalNoOfEntitiesUploadedRNW = 0;
  submitted = false;

  category = "";
  categoryList = [];
  excelUploadedFile = null;
  showUploadedExcelFileName = "";
  nameSearchIsLoading: boolean = false;
  locationNonTeams;
  filteredCustomLocationsAdditionalNonTeams;
  filteredRoles;
  roleList;
  searchControl = new FormControl("");
  searchTenantCompany = new FormControl("");
  filteredTenantCompany;
  tenantCompanyData = [];
  rolesNonTeams;
  selectedTenantComapny = null;
  previousPage;
  disableLocationByIndex;
  taggedLocations = [];
  editUserEnable = false;
  userData;
  manualAccess;
  onboardUserEmailVerify;
  LOCATIONS_ELEMENT_DATA;
  customLocationList;
  addLocNonTeams = false;
  roleListWithTenantAdmin=[]
  locationsListNonTeams = [];
  searchLocationNonTeams = new FormControl("");
  addUserTypeInternalorTenant;
  additionalRoles = new FormControl(false);
  previousUserPageType;
  submittedUsers;
  constructor(
    public dialogRef: MatDialogRef<TenantPeopleModalComponent>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private eventCatService: TenantPeopleService,
    private toastService: ToastrService,
    private loader: LoaderService,
    private excelService: ExcelService,
    private translate: TranslateService,
    private globalSetupService: GlobalSetupService,
    private commonService: CommonService,
    private emsService: EMSService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pageType = data["type"];
    this.currentZone = data["currentZone"];
    this.filteredCustomLocationsAdditionalNonTeams =
      this.searchLocationNonTeams.valueChanges.pipe(
        startWith(""),
        map((location) =>
          location
            ? this.filterCustomLocationsNonTeams(location)
            : this.customLocationList.slice()
        )
      );
    this.filteredRoles = this.searchControl.valueChanges.pipe(
      startWith(""),
      map((roles) => (roles ? this.filterRoles(roles) : this.roleList))
    );
    this.filteredTenantCompany = this.searchTenantCompany.valueChanges.pipe(
      startWith(""),
      map((location) =>
        location
          ? this.filterTenantCompany(location)
          : this.tenantCompanyData.slice()
      )
    );
  }
  ngOnInit() {
    this.addUsersNonTeams = this.formBuilder.group({
      employeeName: ["", Validators.required],
      lastName: ["",Validators.required],
      email: ["", [Validators.required, Validators.email]],

      globalAdmin: [false],
      allLocationGeneralUser: [false],
    });
    this.manualForm = this.formBuilder.group({
      categoryList: this.fb.array([]),
    });
    this.getGlobalSetting();
    if (this.pageType == "editUsersNonTeams") {
      this.editUserDataEvent();
    }

    
  }

  selectCustomTenantComapny(dropData) {
    this.selectedTenantComapny = dropData;
  }
  removeRoleAndDisableLocation() {
    if (
      this.previousPage == "addUserManuallyNonTeams" ||
      this.previousPage == "editUsersNonTeams"
    ) {
      let loc = this.locationsListNonTeams;
      loc.splice(this.disableLocationByIndex, 1);
      this.pageType = this.previousPage;
    }
  }
  goBack() {
    this.pageType = this.previousPage;
  }
  removeRolesNonTeams(locIndex: number, rolesIndex: number) {
    let loc = this.locationsListNonTeams;
    let role = loc[locIndex]["roles"];
    const roleSelected = role;
    if (roleSelected.length == 1) {
      this.disableLocationByIndex = locIndex;
      let previousPage = this.pageType;
      this.pageType = "ShowAlertRole";
      this.previousPage = previousPage;
    } else {
      roleSelected.splice(rolesIndex, 1);
    }
  }
 
  filterTenantCompany(name: string) {
    let roleDataList = this.tenantCompanyData.filter((role) =>
      role.name.toLowerCase().includes(name.toLowerCase())
    );
    return roleDataList;
  }

  filterCustomLocationsNonTeams(name: string) {
    this.locationNonTeams = null;
    let roleDataList = this.customLocationList.filter((role) =>
      role.name.toLowerCase().includes(name.toLowerCase())
    );
    return roleDataList;
  }
  selectLocationNonTeams(selectedLocations) {
    this.locationNonTeams = selectedLocations;
  }
  filterRoles(name: string) {
    this.rolesNonTeams = null;
    return this.roleList.filter((role) =>
      role.name.toLowerCase().includes(name.toLowerCase())
    );
  }

  getGlobalSetting() {
    this.emsService.getDigitalSettingsConfig(this.currentZone).subscribe(
      (res) => {
        let resp = res["response"];
        resp = JSON.parse(resp);

        if (resp["manualAccessAllLocations"] == "false")
          this.manualAccess = false;
        else this.manualAccess = true;

        if (this.manualAccess)
          this.addUsersNonTeams.patchValue({
            allLocationGeneralUser: true,
          });
      },
      (err) => {
        this.commonService.openSnackBar(err.error.message, "");
      }
    );
  }

  editUserDataEvent() {
    this.editUserEnable = true;
    this.userData = this.data["userData"];
   // this.loadLocation();
this.loadUseFulData();
  }


  loadUseFulData(){
    const loc=  this.globalSetupService.loadLocations("ENABLE") 
    const roles=    this.globalSetupService.getAllRoles(this.currentZone)
  const tenant=  this.eventCatService.getAllTenant(null,0,0)
  this.loader.loadingOn();
  forkJoin([loc, roles,tenant]).subscribe( (res)=> {
   console.log('fork jpoin ',res)
 
   this.LOCATIONS_ELEMENT_DATA = res[0]["response"]["content"];
   this.customLocationList = this.LOCATIONS_ELEMENT_DATA; // all location tenant update
   this.roleList = (res[1] as any).response; //roles
   this.roleListWithTenantAdmin=this.roleList
   if(this.roleList){

    this.roleList.forEach((e)=>{ if(e.name=='Tenant Admin'){
      this.tenantIdUnique=e.id
    } })
  this.roleList = this.roleList.filter(role => role.name !== 'Tenant Admin');

}


   this.tenantCompanyData = res[2]["response"];
  
   if (this.editUserEnable) {

     this.populateUserData(this.userData);
   }
   this.loader.loadingOff();
         
        },
        (err )=> {
         this.loader.loadingOff();
      
       }
     );
  }
  populateUserData(user) {
    if(this.manualAccess){
      user.allLocationAccess=true;
    }
    this.addUsersNonTeams.patchValue({
      employeeName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      globalAdmin: user.globalAdmin,
      allLocationGeneralUser: user.allLocationAccess,
    });
  
    this.selectedTenantComapny = this.tenantCompanyData.find((e) => {
      return e.tenantId == user.tenantId;
    });
   
if(this.selectedTenantComapny)
 this.searchTenantCompany.setValue(this.selectedTenantComapny.name);


    if (user["rolesMap"]) {
      let locarray = Object.entries(user["rolesMap"]);
      if (locarray.length > 0) {
        locarray.forEach((element) => {
          let obj = this.LOCATIONS_ELEMENT_DATA.find(
            (item) => item.zoneId == element[0]
          );
          let roleValues = element[1] as [];
          let roless = [];
          if (roleValues.length > 0) {
            roleValues.forEach((ele) => {
              let roleVal: String;
              roleVal = ele;
              if (roleVal != "GENERAL_USER" && roleVal != "APP USER" ) {
                let roleSelected = this.roleListWithTenantAdmin.find(
                  (item) => item.name.toString() == roleVal
                );
                let roleExtract = {
                  id: roleSelected["id"],
                  role: roleSelected["name"],
                };
                roless.push(roleExtract);
              }
            });
          }
          if (roless.length > 0) {
            let locObj = {
              name: obj["name"],
              zoneId: obj["zoneId"],
              roles: roless,
            };
            this.locationsListNonTeams.push(locObj);
          }
        });
      }
    }
    if (this.locationsListNonTeams.length > 0 || user.globalAdmin) {
      this.additionalRoles.setValue(true);
      this.addLocNonTeams = true;
    }

    this.addUserTypeInternalorTenant =
      user.tenantId == 1 ? "internal" : "tenant";
  }

  validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }
  selectType() {
    if (this.addType == "manual") {
      this.pageType = "addEventManual";
    } else {
      this.pageType = "addEventExcelNew";
    }
  }

  selectType1() {
    this.submittedExcel = true;
    if (this.showUploadedExcelFileName != "") {
      this.validateExcelOnboarding();
    }
  }


  @ViewChild('customInput', { static: false }) customInput: ElementRef<HTMLInputElement>;

  selectRolesNonTeams(selectedRoles) {
    this.rolesNonTeams = selectedRoles;
    if(selectedRoles.id==1)
    this.customInput.nativeElement.value='Super Admin'
  else if (selectedRoles.id==13)
  this.customInput.nativeElement.value='General User';
  }
  verifyEmail() {
    const verify$ = this.eventCatService.verifyEmail(
      this.onboardUserEmailVerify
    );
    this.loader.showLoaderUntilCompleted(verify$).subscribe(
      (res) => {
        if (res["response"] == 'User does not exist in the system') {
          this.pageType = "addUserManuallyNonTeams";
          this.loadUseFulData();
        
          this.addUsersNonTeams.patchValue({
            email: this.onboardUserEmailVerify,
          });
        } else {
          this.toastService.error(res["response"]);
        }
      },
      (error) => {}
    );
  }
  tagRolesLocationsNonTeams() {
    let count = 0;
    let roleExist = false;
    if (this.locationNonTeams == null) {
      this.toastService.error(this.translate.instant("pleaseAddLocation"));
      return;
    }
    if (this.rolesNonTeams == null) {
      this.toastService.error(this.translate.instant("pleaseAddRoles"));
      return;
    }

    if (this.locationsListNonTeams.length > 0) {
      this.locationsListNonTeams.forEach((element) => {
        if (element["zoneId"] == this.locationNonTeams["zoneId"]) {
          if (element["roles"].length > 0) {
            element["roles"].forEach((roles) => {
              if (roles["id"] == this.rolesNonTeams["id"]) {
                this.toastService.error("Role already added");
                roleExist = true;
                count++;
                return;
              }
            });
            if (!roleExist) {
              let rolesObj = {
                id: this.rolesNonTeams["id"],
                role: this.rolesNonTeams["name"],
              };
              element["roles"].push(rolesObj);
              count++;
            }
          } else {
            let rolesObj = {
              id: this.rolesNonTeams["id"],
              role: this.rolesNonTeams["name"],
            };
            element["roles"].push(rolesObj);
            count++;
          }
        }
      });
      if (count == 0) {
        let roleObj = [
          {
            id: this.rolesNonTeams["id"],
            role: this.rolesNonTeams["name"],
          },
        ];
        let locObj = {
          name: this.locationNonTeams["name"],
          zoneId: this.locationNonTeams["zoneId"],
          roles: roleObj,
        };
        this.locationsListNonTeams.push(locObj);
      }
    } else {
      let roleObj = [
        {
          id: this.rolesNonTeams["id"],
          role: this.rolesNonTeams["name"],
        },
      ];
      let locObj = {
        name: this.locationNonTeams["name"],
        zoneId: this.locationNonTeams["zoneId"],
        roles: roleObj,
      };
      this.locationsListNonTeams.push(locObj);
    }
    this.searchLocationNonTeams.setValue("");
    this.searchControl.setValue("");
    this.locationNonTeams = null;
    this.rolesNonTeams = null;
  }

 

  toggleNonTeamsLocations(eve) {
    if (eve.checked) this.addLocNonTeams = true;
    else this.addLocNonTeams = false;
    this.locationsListNonTeams = [];
  }

  hasValidRoles(loc: any): boolean {
    return loc.roles.some(
      (role) => role.role !== "APP USER" && role.role !== "GENERAL_USER"
    );
  }
  validateExcelOnboarding() {
    const validateCSVOnboarding$ = this.eventCatService.validateExcelOnboarding(
      this.excelUploadedFile,
      this.showUploadedExcelFileName,
      this.currentZone
    );
    this.loader
      .showLoaderUntilCompleted(validateCSVOnboarding$)

      .subscribe(
        (res) => {
          if (!!res["response"]) {
            this.listViewExcelResponse = res["response"];
            if (res["response"]["value"].length > 0) {
              this.incorrectData = [];
              this.correctData = [];
              this.saveCorrectData = [];

              res["response"]["value"].forEach((entity) => {
                if (entity.csvDto) {
                  let {
                    firstName,
                    lastName,
                    corporateEmail,
                    typeOfUser,
                    globalAdmin,
                    tagAsGeneralUser,
                    tenantName,
                  } = entity.csvDto;
                  let uploadData = {
                    firstName,
                    lastName,
                    corporateEmail,
                    typeOfUser,
                    globalAdmin,
                    tagAsGeneralUser,
                    tenantName,
                    errorFields: entity.columns,
                    reason: entity.reason,
                  };
                  if (entity.columns.length > 0) {
                    this.incorrectData.push(uploadData);
                  } else {
                    this.correctData.push(uploadData);
                    this.saveCorrectData.push(entity.csvDto);
                  }
                }
              });
              this.TotalNoOfEntitiesUploadedRNW =
                this.incorrectData.length + this.correctData.length;
              this.noOfEntitiesUploaded = this.saveCorrectData.length;
              if (this.incorrectData.length == 0) {
                this.dialogRef.updateSize("45%");
              } else {
                this.totalIncorrectLength = this.incorrectData.length;
                this.showIncorrectData = this.incorrectData.slice(0, 10);
              }
            } else {
              this.toastService.error("No Enteries were added");
              return;
            }
          }
          this.pageType = "onboardingExcelTable";
          this.dialogRef.updateSize("75%");

          // if (this.digitalVersion) {
          //   if (!this.approach || this.approach == "") {
          //     this.updateConfig('LIST');
          //   }
          // }
        },
        (error) => {
          this.commonService.openSnackBar(
            "Something went wrong!! Please make sure file is in correct format",
            ""
          );
        }
      );
  }

  closePopup() {
    this.dialogRef.close(this.pageType);
  }

  downloadViaExcelTemplate(value?) {
    const customQues$ = this.eventCatService.downloadViaExcelTemplate();

    this.loader.showLoaderUntilCompleted(customQues$).subscribe(
      (res: Blob) => {
        if (!!res) {
          const fileName = "onboardUser.xlsx";
          this.excelService.saveApiResponseToFile(res, fileName);
        }
      },
      (err) => {}
    );
  }

  uploadExcel(files: FileList) {
    var fileName = Array.from(files)
      .map((f) => f.name)
      .join(", ");
    const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (extension === "xlsx" || extension === "xls") {
      this.excelUploadedFile = files.item(0);
      this.showUploadedExcelFileName = fileName;
    } else {
      this.toastService.error("Please import valid .xlsx file.");
    }
  }
  getToolTipData(hoverData) {
    return hoverData;
  }
  reUploadEntities() {
    this.pageType = "onboarding_reUploadCSV";
    this.excelUploadedFile = undefined;
    this.showUploadedExcelFileName = undefined;
    this.dialogRef.updateSize("40%");
  }
  downloadCurrentCSVFile() {
    const customQues$ = this.eventCatService.downloadViaExcelTemplate(
      this.listViewExcelResponse
    );

    this.loader.showLoaderUntilCompleted(customQues$).subscribe(
      (res: Blob) => {
        if (!!res) {
          const fileName = "onboardUser.xlsx";
          this.excelService.saveApiResponseToFile(res, fileName);
        }
      },
      (err) => {}
    );
  }
  camelCasingToNormal(value: string): string {
    return value
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/\b\w/g, (match) => match.toUpperCase()); // capitalize the first letter
  }

  proceedToOnboard() {
    //  this.categoryList = [];
    if (this.correctData.length == 0) {
      this.toastService.error("Please upload the correct file containing accurate Internal/Tenant user data.");
      return;
    }


    const $category = this.eventCatService.saveBulkUpload(
      this.correctData,
      this.currentZone
    );
    this.loader.showLoaderUntilCompleted($category).subscribe((e) => {
      this.closePopup();
      this.toastService.success("Users has been succesfully onboarded");
    });
  }
 

  submitAddUserNonTeams(previousPageType) {
    this.previousUserPageType = previousPageType;
    this.submittedUsers = true;
    if (
      this.addUserTypeInternalorTenant == "internal" &&
      this.addUsersNonTeams.get("globalAdmin").value
    ) {
      this.locationsListNonTeams = [];
    }
    if (
      this.addUserTypeInternalorTenant == "internal" &&
      this.addLocNonTeams &&
      !this.addUsersNonTeams.get("globalAdmin").value &&
      this.customLocationList.length == 0
    ) {
      return;
    }

    if (
      this.addUserTypeInternalorTenant == "tenant" &&
      !this.selectedTenantComapny
    ) {
      return;
    }

    if (this.addUsersNonTeams.controls.errors != null) {
      return false;
    }
    let locCount = 0;
    let loc = this.locationsListNonTeams;
    let rolesToAssign = [];
    loc.forEach((locations) => {
      locations["roles"].forEach((roles) => {
        rolesToAssign.push({
          roleId: roles.id,
          zoneIds: [],
        });
      });
      locCount = locCount + 1;
    });
    let uniqueRoles = [
      ...new Map(rolesToAssign.map((item) => [item["roleId"], item])).values(),
    ];
    uniqueRoles.forEach((indRoles) => {
      loc.forEach((locations) => {
        locations["roles"].forEach((roles) => {
          if (indRoles["roleId"] == roles.id) {
            indRoles["zoneIds"].push(locations["zoneId"]);
          }
        });
      });
    });

    let globalAdmin = this.addUsersNonTeams.get("globalAdmin").value;

    let allLocationGeneralUser = this.addUsersNonTeams.get(
      "allLocationGeneralUser"
    ).value;
    if (!globalAdmin) {
      let locObj;

      let allZoneIds = [];
      let tempdata = uniqueRoles;
      tempdata.forEach((item) => {
        allZoneIds = allZoneIds.concat(item.zoneIds);
      });

      const uniqueZoneIDs = [...new Set(allZoneIds)];

      // if (uniqueZoneIDs.length > 0) {
      //     locObj = {
      //         roleId: "13",
      //         zoneIds: uniqueZoneIDs,
      //     };
      //     uniqueRoles.push(locObj);
      // }
    }
    let obj;
    let internal = true;
    let tenantId = 1;
    if (this.addUserTypeInternalorTenant == "tenant") {
      globalAdmin = false;
      allLocationGeneralUser = false;
      internal = false;
      tenantId = this.selectedTenantComapny.tenantId;

      if(this.pageType=='addUserManuallyNonTeams' || this.selectedTenantComapny.tenantId !=this.userData['tenantId'] ){
        this.locationsListNonTeams = [];
      uniqueRoles = [];
      }
     
    } else {
      tenantId = 1;
    }

    obj = {
      email: this.addUsersNonTeams.get("email").value,
      firstName: this.addUsersNonTeams.get("employeeName").value,
      password: null,
      lastName: this.addUsersNonTeams.get("lastName").value,
      rolesToAssign: uniqueRoles,
      phoneNum: "",
      tenantId: tenantId,
      empCode: "",
      deptId: "",
      departName: "",
      grade: "MGR",
      shiftStartTime: "0:0",
      shiftEndTime: "23:59",
      supervisorId: "",
      autoAssign: false,
      globalAdmin: globalAdmin,
      allLocationAccess: allLocationGeneralUser,
      allSiteAccess: false,
    };

    /*
payload:{
    "allSiteAccess": false,
    "email": "pradeep.thimmaiah4@smartenspaces.com",
    "firstName": "pradeep",
    "password": null,
    "lastName": "t",
    // "rolesToAssign": [
    //     {
    //         "roleId": "13",
    //         "zoneIds": [
    //             404393
    //         ]
    //     }
    // ],
    "phoneNum": "",
    "tenantId": 1,
    "empCode": "",
    "deptId": 1005,
    "departName": " it",
    "grade": "MGR",
    "shiftStartTime": "0:0",
    "shiftEndTime": "23:59",
    "supervisorId": "",
    "autoAssign": false,
    "globalAdmin": false,
    "allLocationAccess":true
}
    */

    if (this.pageType == "addUserManuallyNonTeams") {
      obj = Object.assign({}, obj);
    } else if (this.pageType == "editUsersNonTeams") {
      obj = Object.assign({ id: this.userData["userId"] }, obj);
    }

    if (this.addUsersNonTeams.invalid) {
      return;
    }
    // else if (this.selectedRoles == null) {
    //   this.pageType = "ShowAlertPopup";
    // }
    else {
      if (this.pageType == "addUserManuallyNonTeams") {
        const users$ = this.eventCatService.addUserManually(obj, internal);
        this.loader
          .showLoaderUntilCompleted(users$)

          .subscribe(
            (res) => {
              if (!!res["response"]) {
                let data,msg='';
if(tenantId!=1){
msg="Tenant User has been successfully onboarded"
}
else{
  
   if(allLocationGeneralUser){
    msg=obj.firstName+' '+obj.lastName+' has been successfully onboarded as an Internal User across all'+' locations '
  }
  else if(globalAdmin){
    msg=obj.firstName+' '+obj.lastName+' has been successfully onboarded as an Internal User across '+ this.LOCATIONS_ELEMENT_DATA.length +' locations '
  }
  else if(this.locationsListNonTeams.length!=0) {
    
    msg=obj.firstName+' '+obj.lastName+' has been successfully onboarded as an Internal User across '+ this.locationsListNonTeams.length +' locations '

  }
  else{
        
    msg=obj.firstName+' '+obj.lastName+' has been successfully onboarded as an Internal User across '+ this.locationsListNonTeams.length +' locations. Location mapping can be done from the Super Admin profile. '

    
  }
}


                this.toastService.success(
                  msg
                );
                this.dialogRef.close(data);
              }
            },
            (err) => {}
          );
      } else if (this.pageType == "editUsersNonTeams") {
        const users$ = this.eventCatService.updateUser(obj, internal);
        this.loader
          .showLoaderUntilCompleted(users$)

          .subscribe(
            (res) => {
              if (!!res["response"]) {
                let data;

                data = {
                  pageType: this.pageType,
                  data: this.data,
                };
                this.toastService.success(
                  this.translate.instant("yourChangesHasBeenSavedSuccessfully")
                );
                this.dialogRef.close(data);
              }
            },
            (err) => {}
          );
      }
    }
  }
  getSpecialTooltip(){

let msg=' Internal Users: Employees of the parent organization \n Tenant Users: Employees of a specific company using the services of the parent organization'
return msg;
  }
  getNameWithoutUnderscore(name){
    if(name=='SUPER_ADMIN')return "Super Admin";
    if(name=='GENERAL_USER')return "General User"
;



    return name;
  }
  alertYes() {
    this.dialogRef.close("yes");
  }
  alertNo() {
    this.dialogRef.close("no");
  }
}
