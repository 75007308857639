<div class="row spinner-container" *ngIf="customLoaderService.loading$ | async">
    <div class="modal-overlay" id="modal-overlay"></div>
    <div class="modal" id="modal">
        <div class="modal-guts row">
            <div
                class="col-md-12 col-lg-12 col-sm-12"
                style="padding: 5px 0px 0px 22px;"
            >
                <p class="loading h4">{{"Loading" | translate}} ...</p>
                <div class="progress">
                    <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        [ngClass]="{
                            stepOneCompleted:
                                (customLoaderService.stepper$ | async) ===
                                'first',
                            stepTwoCompleted:
                                (customLoaderService.stepper$ | async) ===
                                'second',
                            stepThreeCompleted:
                                (customLoaderService.stepper$ | async) ===
                                'third',
                            stepFourCompleted:
                                (customLoaderService.stepper$ | async) ===
                                'fourth'
                        }"
                    ></div>
                </div>
                <div class="steps-form">
                    <div class="steps-row setup-panel">
                        <div class="steps-step text-left">
                            <a
                                href="#step-9"
                                type="button"
                                class="btn btn-indigo btn-circle"
                                [ngClass]="{
                                    stepOnebg:
                                        (customLoaderService.stepper$
                                            | async) === 'first' ||
                                        (customLoaderService.stepper$
                                            | async) === 'second' ||
                                        (customLoaderService.stepper$
                                            | async) === 'third'
                                }"
                            ></a>
                            <p>{{"Initiate" | translate}}</p>
                        </div>
                        <div class="steps-step text-center">
                            <a
                                href="#step-10"
                                type="button"
                                class="btn btn-default btn-circle"
                                [ngClass]="{
                                    stepTwobg:
                                        (customLoaderService.stepper$
                                            | async) === 'second' ||
                                        (customLoaderService.stepper$
                                            | async) === 'third'
                                }"
                            ></a>
                            <p>{{"analysingFile" | translate}}</p>
                        </div>
                        <div class="steps-step text-right">
                            <a
                                href="#step-11"
                                type="button"
                                class="btn btn-default btn-circle"
                                [ngClass]="{
                                    stepThreebg:
                                        (customLoaderService.stepper$
                                            | async) === 'third'
                                }"
                            ></a>
                            <p>{{"Finished" | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-6 col-lg-6">
        <div>
            
        </div>
    </div>
    <div
        class="col-md-6 col-lg-6"
        style="border-left:1px solid blue;height: 100%"
    >
        <div
            class="container"
            style="display:flex; flex-direction: column;  align-items: center; justify-content: center;top: 50%; bottom: 50%; position: absolute; margin: 0 auto;"
        >
            
        </div>
    </div> -->
    <!-- <div class="modal-overlay" id="modal-overlay"></div>

    <div class="modal" id="modal">
        <div class="modal-guts">
            <div class="row" style="height:100%">
                <div class="col-md-5 col-lg-5">
                    <div>
                        <div id="circle">
                            <div class="loader-icon">
                                <div class="loader-icon">
                                    <div class="loader-icon">
                                        <div class="loader-icon"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-7 col-lg-7"
                    style="display:flex; flex-direction:column; justify-content:center; border-left: 1px solid #cec8c8; padding: 25px"
                >
                    <ngb-carousel
                        [showNavigationArrows]="false"
                        [showNavigationIndicators]="false"
                        interval="8000"
                        [keyboard]="false"
                        [pauseOnHover]="false"
                        [wrap]="true"
                        [activeId]="'slideOne'"
                    >
                        <ng-template ngbSlide id="slideOne">
                            <div class="picsum-img-wrapper">
                                <img
                                    src="/assets/images/carousel1.PNG"
                                    alt="Random first slide"
                                />
                            </div>
                        </ng-template>
                        <ng-template ngbSlide id="slideTwo">
                            <div class="picsum-img-wrapper">
                                <img
                                    src="/assets/images/carousel2.PNG"
                                    alt="Random second slide"
                                />
                            </div>
                        </ng-template>
                        <ng-template ngbSlide id="slideThree">
                            <div class="picsum-img-wrapper">
                                <img
                                    src="/assets/images/carousel3.PNG"
                                    alt="Random third slide"
                                />
                            </div>
                        </ng-template>
                        <ng-template ngbSlide id="slideFour">
                            <div class="picsum-img-wrapper">
                                <img
                                    src="/assets/images/carousel4.PNG"
                                    alt="Random third slide"
                                />
                            </div>
                        </ng-template>
                    </ngb-carousel>
                    <hr style="margin-top:1rem" />
                    <h5 style="margin-top:8px" class="text-center">
                        Please wait we are onboarding your file
                    </h5>
                    <label class="checkbox-container"
                        >on boarding file
                        <input
                            type="checkbox"
                            [checked]="
                                (customLoaderService.stepper$ | async) ===
                                    'first' ||
                                (customLoaderService.stepper$ | async) ==
                                    'second' ||
                                (customLoaderService.stepper$ | async) ==
                                    'third'
                            "
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="checkbox-container"
                        >creating tiles
                        <input
                            type="checkbox"
                            [checked]="
                                (customLoaderService.stepper$ | async) ===
                                    'second' ||
                                (customLoaderService.stepper$ | async) ==
                                    'third'
                            "
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="checkbox-container"
                        >finalizing
                        <input
                            type="checkbox"
                            [checked]="
                                (customLoaderService.stepper$ | async) ===
                                'third'
                            "
                        />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
    </div> -->
</div>
