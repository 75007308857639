import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { environment} from '../../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from '../../../services/common-service.service';


@Injectable()
export class VMSService {

  private moduleId;
  private readonly BASE_URL;
  private readonly BASE_URL_VMS;

  constructor(private http: HttpClient, public commonService: CommonService) {
    this.moduleId = 9; 
    this.BASE_URL = environment.BASE_URL ;
    this.BASE_URL_VMS = environment.BASE_URL + 'vms/';
  }
  //vms start
  getInvitations(zoneId, type, value,pageIndex,pazesize) {
    console.log('clalled')
    let url = this.BASE_URL_VMS + "api/fetchRecordsByQuery?type={type}&value={value}&limit="+pazesize+"&offset="+pageIndex+"&siteId=" + zoneId;
    if (value && type) {
      url = url.replace('{type}', type).replace('{value}', value);
    } else {
      url = url.replace('{type}', '').replace('{value}', '');
    }
    console.log(this.moduleId)
    return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
x
  getInvitationsManage(zoneId, type, value,pageIndex,pazesize) {
    console.log('clalled man')
    let url = this.BASE_URL_VMS + "api/fetchRecordsByQuery?type={type}&value={value}&limit="+pazesize+"&offset="+pageIndex+"&siteId=" + zoneId+"&manageVisitor=true";
    if (value && type) {
      url = url.replace('{type}', type).replace('{value}', value);
    } else {
      url = url.replace('{type}', '').replace('{value}', '');
    }
    console.log(this.moduleId)
    return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  createInvitation(createInvite, zoneId) {
    const endpoint = this.BASE_URL_VMS + "api/digital/createInvitation";
    return this.http.post(endpoint, createInvite, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  ValidatePhoneNo(number, zoneId) {
    const endpoint = this.BASE_URL_VMS + "api/validatePhoneNumber";
    return this.http.post(endpoint, {'number':number}, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  downloadViaExcelTemplate(zoneId){
    const endpoint = this.BASE_URL_VMS + "api/downloadTemplateBulkUpload";
    const data=null
    return this.http.post(endpoint,data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) ,responseType: 'blob' });
  }
  validateExcelOnboarding(fileToUpload,fileName,zoneId){
    const endpoint = this.BASE_URL_VMS + "api/validateBulkUpload";
    var formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileName);
    return this.http.post(endpoint,formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getReport(from, to, zoneId) {
    return this.http.get(this.BASE_URL_VMS+ "api/reports?" + "startTime=" + from + "&endTime=" + to, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  approvePhoto(data, zoneId) {
    return this.http.put(this.BASE_URL_VMS + "api/approvePhoto", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getCheckOut(data,zoneId){
    console.log(this.BASE_URL_VMS + "api/verifyQRCode/v2") 
    // https://jumpreepreprod.smartenspaces.com/vms/api/verifyQRCode/v2
    // return this.http.put(this.BASE_URL + "api/verifyQRCode/v2", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });

    return this.http.put(this.BASE_URL_VMS + "api/verifyQRCode/v2", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });

  }

  rejectPhoto(data, zoneId) {
    return this.http.put(this.BASE_URL_VMS + "api/rejectPhoto", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  approveActivation(data, zoneId) {
    return this.http.put(this.BASE_URL_VMS + "api/activatePass", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  rejectActivation(data, zoneId) {
    return this.http.put(this.BASE_URL_VMS + "api/activatePass", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }


  searchColumnList(zoneId) {
    return this.http.get(this.BASE_URL_VMS + "messageCategory/getKeyByCategoryId?categoryId=15", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  public manageVisitTableSizeSource = new BehaviorSubject<number>(0);

  tableSize = this.manageVisitTableSizeSource.asObservable();
  setSize(size: number) {

  this.manageVisitTableSizeSource.next(size)
  }
  getReportData(data, zoneId):Observable<any>{
    return this.http.get(this.BASE_URL_VMS + `api/reports?startTime=${data['startTime']}&endTime=${data['endTime']}`, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  //vms end

  getAllActiveCustomCheckList(zoneId){
    //return this.http.get(this.BASE_URL_VMS + "custom-field/all-active?customKey=CUSTOM_CHECK_LIST", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    return this.http.get(this.BASE_URL_VMS + "custom-field/all?page=0&size=1000", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllActiveIdentity(zoneId){
    return this.http.get(this.BASE_URL_VMS + "custom-field/all-active?customKey=ADDITIONAL_VMS_FIELD&page=0&size=100", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllAdditionalInformation(zoneId){
    return this.http.get(this.BASE_URL_VMS + "fields/getAll", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllCustomColumns(zoneId){
    return this.http.get(this.BASE_URL_VMS + "fields/getAllByZoneId", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllIdentityDetailsByInvitationId(zoneId,invitationId){
    return this.http.get(this.BASE_URL_VMS + "custom-field-metadata/all-active?moduleActionKey=ADDITIONAL_VMS_FIELD&moduleEntityId="+invitationId, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllActiveFastTrackPassList(zoneId){
    return this.http.get(this.BASE_URL_VMS + "custom-field/all-active?customKey=FAST_TRACK_PASS", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllActiveTerms(zoneId){
    return this.http.get(this.BASE_URL_VMS + "custom-field/all-active?customKey=TermsAndConditions", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  saveGeneralActiveSettings(zoneId, data) {
    let activeOrInActive = "";
    if(data){
      activeOrInActive = "ACTIVE";
    }else{
      activeOrInActive = "INACTIVE";
    }
    return this.http.put(this.BASE_URL_VMS + "fields/customFieldEnabled?customField="+activeOrInActive,{}, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  saveGeneralSettings(zoneId, data) {
    return this.http.post(this.BASE_URL_VMS + "fields/save", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  updateGeneralSettings(zoneId, data) {
    return this.http.put(this.BASE_URL_VMS + "fields/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  deleteGeneralSettings(zoneId,id){
    return this.http.put(this.BASE_URL_VMS + "fields/delete?id="+id,{}, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  saveCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL_VMS + "custom-field/create", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL_VMS + "custom-field/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  updateMetaDataCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL_VMS + "custom-field-metadata/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  saveMetaDataCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL_VMS + "custom-field-metadata/create", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllCustomCheckList(zoneId,id){
    return this.http.get(this.BASE_URL_VMS + "custom-field-metadata/all-active?moduleActionKey=CUSTOM_CHECK_LIST&moduleEntityId="+id,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getQRcode(zoneId,floorId){
    return this.http.get(this.BASE_URL  + "ems/zones/getZoneQrInfo?id="+zoneId + "&floorId=" + floorId,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getConfig(zoneId, moduleId){
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":'+moduleId+',"enterpriseId":"1"}'
      })
    };
    return this.http.get(this.BASE_URL+"ems/module/config", httpOptions)
      .map(function (response) {
        return response;
    });
  }

  updateConfig(zoneId,moduleId,data){

    let datas = {
      "config": JSON.stringify(data),
      "moduleId": moduleId,
      "zoneId": zoneId
    }
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":'+moduleId+',"sessionId":"'+localStorage.getItem('ssadmin_session')+'"}'
      })
    };
    return this.http.post(this.BASE_URL+"ems/module/updateConfig?generate=true",datas,httpOptions);
  }

  getAllQusetonsForVistors(zoneId,id){
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":'+this.moduleId+'}'
      })
    };

    return this.http.get(this.BASE_URL_VMS + "custom-field-metadata/all-active?moduleActionKey=USER_ADD_DETAILS&moduleEntityId="+id,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  
  enableProxy(zoneId,data){  
    return this.http.put(this.BASE_URL_VMS + "fields/proxyEnbled?proxyfield="+data,{}, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  createProxy(zoneId,data){
    // let objData=`${data.map(obj => JSON.stringify(obj)).join()} `;
    // console.log(objData);
    return this.http.post(this.BASE_URL_VMS+"proxyusers/create",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllProxy(zoneId){   
    return this.http.get(this.BASE_URL_VMS+ "proxyusers/getAll",{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getUserProxy(zoneId,id){   
    return this.http.get(this.BASE_URL_VMS+ "proxyusers/get?id="+id,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateProxy(zoneId,data){
    // let objData=`${data.map(obj => JSON.stringify(obj)).join()} `;
    // console.log(objData);
    return this.http.put(this.BASE_URL_VMS+"proxyusers/update?id="+data.userId,data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateNotifyUsers(zoneId,data){

    return this.http.put(this.BASE_URL_VMS+"proxyusers/notifications/enabled?id="+data.userId+"&enabled="+data.notify,{}, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getProxyStatus(zoneId){
    return this.http.get(this.BASE_URL_VMS+"fields/getProxyStatus", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }


  deleteProxyUsers(zoneId, id){
    return this.http.delete(this.BASE_URL_VMS+"proxyusers/delete?id="+id ,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  searchProxyUsers(zoneId, name,id){
    return this.http.get(this.BASE_URL_VMS+"proxyusers/search/users?searchString="+name+"&searchId="+id ,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getProxyUsers(zoneId, name){
    return this.http.get(this.BASE_URL_VMS+"proxyusers/getAll/search?searchString="+name,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
}