<div class="mat-select-panel" *ngIf="data.type == 'editTenantProfile'">
        <div class="col-md-12">
            <div class="modal-header">
                <h4 class="modal-title"> {{'editCustomerProfile' | translate}} ({{'code' | translate}} : {{tenantProfile.tenantCode}}) </h4>
                <button class="close" id="close_btn" type="button">
                    <span (click)="onNoClick()">×</span></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">{{'name' | translate}}</label>
                                <input id="tenant_name" class="form-control" type="string" [(ngModel)]="tenantProfile.name">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">{{'status' | translate}}</label>
                                <input id="tenant_profile" class="form-control" type="string" [(ngModel)]="tenantProfile.status" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">{{'description' | translate | titlecase}}</label>
                                <textarea id="description" class="form-control" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"
                                    [(ngModel)]="tenantProfile.description"> </textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="text-uppercase">{{'email' | translate}}</label>
                                <input id="email" class="form-control" type="string" [(ngModel)]="tenantProfile.email" readonly>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="text-uppercase">{{'website' | translate}}</label>
                                <input id="website" class="form-control" type="string" [(ngModel)]="tenantProfile.website" required>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label>Phone</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.phone" required>
                            </div>
                        </div> -->
                            <div class="form-group col-md-3">
                                <label class="text-uppercase">{{'phone' | translate | uppercase}}</label>
                                <mat-select placeholder="{{'select' | translate}}"
                                class="form-control" id="phonecountry_code" [(ngModel)]="tenantProfile.phoneCountryCode">
                              <mat-option *ngFor="let countryCode of countryCodeList"  
                                  [value]="countryCode.countryCode">{{countryCode.countryCode}}</mat-option>
                              </mat-select>
                            </div>
                            <div class="form-group col-md-4">
                                <label class="text-uppercase"> &nbsp;</label>
                                <input id="tenant_phone" class="form-control" type="number" [(ngModel)]="tenantProfile.phone" required>
                            </div>
                    </div>
                    <div class="row">
                        <br />
                        <div class="col-md-12">{{'address' | translate}} </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">{{'addressLine' | translate}}</label>
                                <input id="address_line" class="form-control" type="string" [(ngModel)]="tenantProfile.addressLine"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">{{'city' | translate}}</label>
                                <input id="city" class="form-control" type="string" [(ngModel)]="tenantProfile.addressCity"
                                    required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">{{'state' | translate}}</label>
                                <input id="state" class="form-control" type="string" [(ngModel)]="tenantProfile.addressState"
                                    required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">{{'country' | translate}}</label>
                                <input id="country" class="form-control" type="string" [(ngModel)]="tenantProfile.addressCountry"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">{{'ZipCode' | translate}}</label>
                                <input id="zipcode" class="form-control" type="string" [(ngModel)]="tenantProfile.companyZipCode"
                                    placeholder="{{'zCode' | translate}}" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">{{'emailType' | translate}}</label>
                                <mat-select placeholder="{{'select' | translate}}"
                                            class="form-control" id="emailType" [(ngModel)]="tenantProfile.allowedEmailType">
                                          <mat-option *ngFor="let emailType of data.emailTypeList"  
                                              [value]="emailType">{{emailType}}</mat-option>
                                          </mat-select>
                                <!-- <mat-select placeholder="Select company user email types"
                                    [(ngModel)]="tenantProfile.allowedEmailType">
                                    <mat-option *ngFor="let emailType of data.emailTypeList" [value]="emailType">
                                        {{emailType}}</mat-option>
                                </mat-select> -->
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <br />
                        <div class="col-md-12">{{'customerPointOfContact' | translate}}  </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">{{'name' | translate}}</label>
                                <input class="form-control" type="string" id="tenant_firstname"
                                    [(ngModel)]="tenantProfile.tenantPOCFirstName"
                                    readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label>Phone</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.tenantPOCPhone"
                                    readonly>
                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-5"  style="padding-right:5px;">
                                    <div class="form-group">
                                        <label class="text-uppercase" style="font-size:9px">{{'customerPhone' | translate}}</label>
                                        <mat-select placeholder="{{'select' | translate}}"
                                        class="form-control" id="customCountryCode" disabled='true' [(ngModel)]="tenantProfile.tenantPOCPhoneCountryCode">
                                      <mat-option *ngFor="let countryCode of countryCodeList"  
                                          [value]="countryCode.countryCode">{{countryCode.countryCode}}</mat-option>
                                      </mat-select>
                                        <!-- <select class="form-control" disabled='true'
                                        [(ngModel)]="tenantProfile.tenantPOCPhoneCountryCode"                                         
                                        id="phoneCountryCode">
                                            <option *ngFor="let countryCode of countryCodeList" [value]="countryCode.countryCode">{{countryCode.countryCode}}</option>
                                        </select> -->
                                    </div>
                                </div>
                                <div class="col-md-7" style="padding-left:0px;">
                                    <div class="form-group">
                                        <label class="text-uppercase">&nbsp;</label>
                                        <input id="poc_code" class="form-control" type="number" [(ngModel)]="tenantProfile.tenantPOCPhone" 
                                        readonly>
                                    </div>
                                </div>                                  
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{'email' | translate | uppercase}} 
                                    <a id="email_change" href="javascript:void(0)" (click)="onChangeUser()">&nbsp;<i class="fa fa-lg fa-pencil-square-o" style="color:#212529;"></i>
                                    </a>
                                </label>
                                <input id="poc_email" class="form-control" type="string" [(ngModel)]="tenantProfile.tenantPOCEmail"
                                    readonly>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                            <label>Floor IDs</label>
                            <input class="form-control" type="string" [(ngModel)]="tenantProfile.zoneIds" required>
                            </div>
                        </div>
                        </div> -->
                    <div class="row" *ngIf="tenantProfile.user_reg_type === 'CORPORATE'">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">GST</label>
                                <input id="gst" class="form-control" type="string" [(ngModel)]="tenantProfile.tenantGST"
                                    required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <h5>{{'customerPointOfContact' | translate}}</h5>
                            <div class="form-group">
                                <label class="text-uppercase">PAN</label>
                                <input id="cust_point_of_contact" class="form-control" type="string" [(ngModel)]="tenantProfile.tenantPAN"
                                    required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">TAN</label>
                                <input id="tan" class="form-control" type="string" [(ngModel)]="tenantProfile.tenantTAN"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="selectedFloors && selectedFloors.size !== 0">
                        <div class="col-md-12">
                            <h6>{{'selectedFloors' | translate}}:</h6>
                            <div *ngFor="let floor of selectedFloors; let i = index;">
                                <div class="chip" *ngIf="isValidFloorId(floor.id)">
                                    {{floor.name}}
                                    <i class="fa fa-times" id="remove_floor_{{i}}" (click)="removeFloor(floor)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                     <!-- custom field start -->
                     <div *ngIf="customFieldList.length > 0">
                        <div class="form-group">{{'additionalInformation' | translate}}</div>
                        <div class="container">
                            <div class="row">
                                <ng-container *ngFor="let newField of customFieldList; let i = index">
                                    <div class="col-md-6 form-group">
                                        <label [ngClass]="{ 'required' :newField.is_mandatory}" class="text-uppercase">{{newField.label | uppercase }}</label>
                                        <input name="input_{{i}}" id="input_{{i}}" *ngIf="newField.field_type == 'TEXT' && !newField.is_mandatory" type="text" [(ngModel)]="newField.value" class="form-control" />
                                        <input name="input_{{i}}" id="input_{{i}}" *ngIf="newField.field_type == 'TEXT' &&  newField.is_mandatory" type="text" [(ngModel)]="newField.value"  required class="form-control"/>
                                        <div class="image-container" *ngIf="newField.field_type == 'FILE'">
                                            <a *ngIf="newField.value.length > 0" [href]="newField.value" download  target="_blank">{{'viewFile' | translate}}</a>
                                            <input  type="file" (change)="getImageUrl($event.target.files, $event, i)" name="file_{{i}}" [(ngModel)]="newField.img" #file/> 
                                                    <!-- <button type="button" class="btn btn-sm btn-success btn-upload" (click) ="file.click()"><i class="fa fa-upload" aria-hidden="true"></i> Select File</button> -->
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                        <!-- custom field end -->
                    <div class="row">
                        <div class="col-md-12" style="bottom: 6px;">
                            <label>
                                <a style="border-bottom: 1px dotted; color:#212529; cursor:pointer"
                                   id="add_floor_to_tenant" (click)="addFloorToTenant()">
                                    {{'addfloors' | translate}}</a>
                            </label>


                            <label style="float:right">
                                <a style="float:right;cursor:pointer">
                                    {{'didNotReceiveLink' | translate | uppercase}}?</a>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-main" id="update_profile" (click)="editTenantProfile(tenantProfile)"
                                *ngIf="tenantProfile.status !== 'NEW'">{{'updateProfile' | translate}}</button>
                            <button class="btn btn-main" id="complete_tenant_profile" (click)="editTenantProfile(tenantProfile)"
                                *ngIf="tenantProfile.status === 'NEW'">{{'completeProfile' | translate}}</button>

                            <button class="btn btn-main pull-right" id="resend_link" (click)="resendActivationLink()"
                            >{{'resendActivationLink' | translate}}</button>
                        </div>
                    </div>
                </div>
        </div>
        <!-- <div class="col-md-8">
            <div class="section-title">Edit Customer Profile (Code : {{tenantProfile.tenantCode}})</div>
        </div>
        <div class="col-md-4 text-right">
            <i class="fa fa-window-close no-click-btn" aria-hidden="true" (click)="onNoClick()"></i>
        </div> -->
   <!-- <div class="bs-border">
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">Name</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.name">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">Status</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.status" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">Description</label>
                                <textarea class="form-control" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"
                                    [(ngModel)]="tenantProfile.description"> </textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="text-uppercase">Email</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.email" readonly>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="text-uppercase">Website</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.website" required>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                       
                            <div class="form-group col-md-3">
                                <label class="text-uppercase">PHONE</label>
                                <select class="form-control" 
                                [(ngModel)]="tenantProfile.phoneCountryCode"                                         
                                id="phoneCountryCode">
                                    <option *ngFor="let countryCode of countryCodeList" [value]="countryCode.countryCode">{{countryCode.countryCode}}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-4">
                                <label class="text-uppercase"> &nbsp;</label>
                                <input class="form-control" type="number" [(ngModel)]="tenantProfile.phone" required>
                            </div>
                    </div>
                    <div class="row">
                        <br />
                        <div class="col-md-12">Address </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">address line</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.addressLine"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">city</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.addressCity"
                                    required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">State</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.addressState"
                                    required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">Country</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.addressCountry"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">ZipCode</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.companyZipCode"
                                    placeholder="Zip Code" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">Email Type</label>
                                <mat-select placeholder="Select company user email types"
                                    [(ngModel)]="tenantProfile.allowedEmailType">
                                    <mat-option *ngFor="let emailType of data.emailTypeList" [value]="emailType">
                                        {{emailType}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <br />
                        <div class="col-md-12">Customer Point of Contact  </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="text-uppercase">Name</label>
                                <input class="form-control" type="string"
                                    [(ngModel)]="tenantProfile.tenantPOCFirstName"
                                    readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                       
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-5"  style="padding-right:5px;">
                                    <div class="form-group">
                                        <label class="text-uppercase" style="font-size:9px">Customer Phone</label>
                                        <select class="form-control" disabled='true'
                                        [(ngModel)]="tenantProfile.tenantPOCPhoneCountryCode"                                         
                                        id="phoneCountryCode">
                                            <option *ngFor="let countryCode of countryCodeList" [value]="countryCode.countryCode">{{countryCode.countryCode}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-7" style="padding-left:0px;">
                                    <div class="form-group">
                                        <label class="text-uppercase">&nbsp;</label>
                                        <input class="form-control" type="number" [(ngModel)]="tenantProfile.tenantPOCPhone" 
                                        readonly>
                                    </div>
                                </div>                                  
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>EMAIL 
                                    <a href="javascript:void(0)" (click)="onChangeUser()"><i class="fa fa-lg fa-pencil-square-o " style="
                                    color:#3F50B5"></i>
                                    </a>
                                </label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.tenantPOCEmail"
                                    readonly>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row" *ngIf="tenantProfile.user_reg_type === 'CORPORATE'">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">GST</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.tenantGST"
                                    required>
                            </div>
                        </div>
                        <div class="col-md-4">Customer Point of Contact
                            <div class="form-group">
                                <label class="text-uppercase">PAN</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.tenantPAN"
                                    required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="text-uppercase">TAN</label>
                                <input class="form-control" type="string" [(ngModel)]="tenantProfile.tenantTAN"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="selectedFloors && selectedFloors.size !== 0">
                        <div class="col-md-12">
                            <h6>Selected Floors:</h6>
                            <div *ngFor="let floor of selectedFloors">
                                <div class="chip" *ngIf="isValidFloorId(floor.id)">
                                    {{floor.name}}
                                    <i class="fa fa-times" (click)="removeFloor(floor)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                     <div *ngIf="customFieldList.length > 0">
                        <div class="form-group">Additional Information</div>
                        <div class="container">
                            <div class="row">
                                <ng-container *ngFor="let newField of customFieldList; let i = index">
                                    <div class="col-md-6 form-group">
                                        <label [ngClass]="{ 'required' :newField.is_mandatory}" class="text-uppercase">{{newField.label | uppercase }}</label>
                                        <input name="input_{{i}}" *ngIf="newField.field_type == 'TEXT' && !newField.is_mandatory" type="text" [(ngModel)]="newField.value" class="form-control" />
                                        <input name="input_{{i}}" *ngIf="newField.field_type == 'TEXT' &&  newField.is_mandatory" type="text" [(ngModel)]="newField.value"  required class="form-control"/>
                                        <div class="image-container" *ngIf="newField.field_type == 'FILE'">
                                            <a *ngIf="newField.value.length > 0" [href]="newField.value" download  target="_blank">View File</a>
                                            <input  type="file" (change)="getImageUrl($event.target.files, $event, i)" name="file_{{i}}" [(ngModel)]="newField.img" #file/> 
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                       
                    <div class="row">
                        <div class="col-md-12">
                            <label>
                                <a style="border-bottom: 1px dotted; color:turquoise; cursor:pointer"
                                    (click)="addFloorToTenant()">add
                                    Floors</a>
                            </label>


                            <label style="float:right">
                                <a style="float:right;cursor:pointer">
                                    DIDN'T RECEIVE LINK?</a>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" (click)="editTenantProfile(tenantProfile)"
                                *ngIf="tenantProfile.status !== 'NEW'">Update
                                Profile</button>
                            <button mat-raised-button color="primary" (click)="editTenantProfile(tenantProfile)"
                                *ngIf="tenantProfile.status === 'NEW'">Complete
                                Profile</button>

                            <button mat-raised-button color="primary" class="pull-right" (click)="resendActivationLink()"
                            >Resend activation link</button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div> -->

</div>
<mat-card *ngIf="data.type == 'editTenantProfile1'">
    <mat-card-title>
      <span>{{'addAppMembersByCSV' | translate}}</span>
        <i id="no_click"
          class="fa fa-window-close"
          style="float:right"
          aria-hidden="true"
          (click)="onNoClick()"
        ></i>
      </mat-card-title>
      <hr />
      <!-- <form (ngSubmit) = "uploadCSV(f.valid)" class="form"
      name="form"
      #f="ngForm"
      novalidate> -->
       <form class="form"
      name="form"
      novalidate>
      <mat-card-content>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{'name' | translate}}</label>
                    <input id="tenant_prof_name" name="name" class="form-control" type="string" [(ngModel)]="tenantProfile.name" readonly>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{'status' | translate}}</label>
                    <input id="status_name" class="form-control"  name="status" type="string" [(ngModel)]="tenantProfile.status" readonly>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{'description' | translate | titlecase}}</label>
                    <textarea id="tenant_description" name="description" class="form-control" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"
                        [(ngModel)]="tenantProfile.description"> </textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{'email' | translate}}</label>
                    <input id="tenant_email" name="email" class="form-control" type="string" [(ngModel)]="tenantProfile.email" readonly>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{'website' | translate}}</label>
                    <input id="tenant_website" name="website" class="form-control" type="string" [(ngModel)]="tenantProfile.website" required>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="form-group">
                    <label>Phone</label>
                    <input class="form-control" type="string" [(ngModel)]="tenantProfile.phone" required>
                </div>
            </div> -->
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-4"  style="padding-right:5px;">
                        <div class="form-group">
                            <label>{{'phone' | translate | uppercase}}</label>
                            <select class="form-control"  name="phoneCountryCode"
                            [(ngModel)]="tenantProfile.phoneCountryCode"                                         
                            id="phoneCountryCode">
                                <option *ngFor="let countryCode of countryCodeList" [value]="countryCode.countryCode">{{countryCode.countryCode}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-8" style="padding-left:0px; padding-top:15.6%">
                        <div class="form-group">
                            <input id="tenant_phone_num" class="form-control" name="phone" type="number" [(ngModel)]="tenantProfile.phone" required>
                        </div>
                    </div>                                  
                </div>
            </div>
        </div>
        <div class="row">
            <br />
            <div class="col-md-12">{{'address' | translate}} </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{'addressLine' | translate}}</label>
                    <input name="addressLine" id="addr_line" class="form-control" type="string" [(ngModel)]="tenantProfile.addressLine"
                        required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{'city' | translate}}</label>
                    <input name="city" id="tenant_city" class="form-control" type="string" [(ngModel)]="tenantProfile.addressCity"
                        required>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{'state' | translate}}</label>
                    <input name="state" id="tenant_state" class="form-control" type="string" [(ngModel)]="tenantProfile.addressState"
                        required>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{'country' | translate}}</label>
                    <input name="country" id="tenant_country" class="form-control" type="string" [(ngModel)]="tenantProfile.addressCountry"
                        required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{'ZipCode' | translate}}</label>
                    <input name="zip" id="tenant_zipcode" class="form-control" type="string" [(ngModel)]="tenantProfile.companyZipCode"
                        placeholder="{{'zCode' | translate}}" required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{'emailType' | translate}}</label>
                    <mat-select id="tenant_email_type" placeholder="{{'selectCompanyUserEmailType' | translate}}" name="allowedEmailType"
                        [(ngModel)]="tenantProfile.allowedEmailType">
                        <mat-option *ngFor="let emailType of data.emailTypeList" [value]="emailType">
                            {{emailType}}</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>

        <div class="row">
            <br />
            <div class="col-md-12">{{'customerPointOfContact' | translate}}</div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{'name' | translate}}</label>
                    <input id="tenant_first_name" class="form-control" type="string" name="tenantPOCFirstName"
                        [(ngModel)]="tenantProfile.tenantPOCFirstName"
                        readonly>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- <div class="col-md-6">
                <div class="form-group">
                    <label>Phone</label>
                    <input class="form-control" type="string" [(ngModel)]="tenantProfile.tenantPOCPhone"
                        readonly>
                </div>
            </div> -->
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5"  style="padding-right:5px;">
                        <div class="form-group">
                            <label>{{'customerPhone' | translate}}</label>
                            <select class="form-control" disabled='true' name="tenantPOCPhoneCountryCode"
                            [(ngModel)]="tenantProfile.tenantPOCPhoneCountryCode" id="tenant_phone_code">
                                <option *ngFor="let countryCode of countryCodeList" [value]="countryCode.countryCode">{{countryCode.countryCode}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-7" style="padding-left:0px;">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <input id="poc_number" class="form-control" name="tenantPOCPhone" type="number" [(ngModel)]="tenantProfile.tenantPOCPhone" 
                            readonly>
                        </div>
                    </div>                                  
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'email' | translate}}</label>
                    <input class="form-control" id="tenant_poc_email" name="tenantPOCEmail" type="string" [(ngModel)]="tenantProfile.tenantPOCEmail"
                        readonly>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                <label>Floor IDs</label>
                <input class="form-control" type="string" [(ngModel)]="tenantProfile.zoneIds" required>
                </div>
            </div>
            </div> -->
        <div class="row" *ngIf="tenantProfile.user_reg_type === 'CORPORATE'">
            <div class="col-md-4">
                <div class="form-group">
                    <label>GST</label>
                    <input name="tenantGST" id="tenant_gst"
                    class="form-control" type="string" [(ngModel)]="tenantProfile.tenantGST"
                        required>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>PAN</label>
                    <input name="pan" id="tenant_pan" class="form-control" type="string" [(ngModel)]="tenantProfile.tenantPAN"
                        required>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>TAN</label>
                    <input name="tan" id="tenant_tan" class="form-control" type="string" [(ngModel)]="tenantProfile.tenantTAN"
                        required>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="selectedFloors.size !== 0">
            <div class="col-md-12">
                <h6>{{'selectedFloors' | translate}}:</h6>
                <div *ngFor="let floor of selectedFloors; let i = index;">
                    <div class="chip" *ngIf="isValidFloorId(floor.id)">
                        {{floor.name}}
                        <i class="fa fa-times" id="remove_floor_{{i}}" (click)="removeFloor(floor)"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-12">
                <label>
                    <a style="border-bottom: 1px dotted; color:turquoise; cursor:pointer"
                        (click)="addFloorToTenant()">add
                        Floors</a>
                </label>
            </div>
        </div> -->
        <div class="row" >
            <div class="col-md-12">&nbsp;
            </div>
        </div>
        <div class="row" >
            <div class="col-md-12">
              <!-- <button
                type="button"
                class="pull-right btn btn-main"
                (click)="addNewFloor()"
              > -->
              <button
                type="button"
                class="pull-right btn btn-main"
              >
              <i class="fa fa-plus"></i> {{'addFloors' | translate}}
              </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="btn btn-main" id="tenant_update" (click)="editTenantProfile(tenantProfile)"
                    *ngIf="tenantProfile.status !== 'NEW'">Update
                    Profile</button>
                <button class="btn btn-main" id="tenant_complete" (click)="editTenantProfile(tenantProfile)"
                    *ngIf="tenantProfile.status === 'NEW'">Complete
                    Profile</button>
            </div>
        </div>
      </mat-card-content>
      </form>
</mat-card>