import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  NgModel,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MatOption,
  MatSelect,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "../../../services/common-service.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { LoaderService } from "loaderService";
import { ToastrService } from "ngx-toastr";
import { CardService } from "../service/card.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as moments from 'moment-timezone';

@Component({
  selector: "app-roles-modal",
  templateUrl: "./card-modal.component.html",
  styleUrls: ["./card-modal.component.scss"],
})
export class CardModalComponent implements OnInit {
  currentZone;
  private unsubscribe$ = new Subject<void>();
  scale = 1;
  transform: ImageTransform = {};
  file;
  timezone;
  timeZones;
  zones;
  isDisabled = true;
  loginDetails = {
    applClientId: '', dir_tenant_id: '', clientSecret: '', outlookEmail: '',
    meetingroomEmail: ''
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CardModalComponent>,
    private commonService: CommonService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    private cardService: CardService
  ) {
    this.currentZone = data["currentZone"];
    //this.getCountryCodes();
  }

  ngOnInit() {
    this.commonService.currentZone.subscribe(
      (res) => {
        //&& this.commonService.isCurrentComponentRouteSame(this.router.url, this.currentRoute)
        if (res) {
          this.currentZone = res;
        }
      },
      (err) => {
        this.commonService.openSnackBar("Something Went Wrong !!", "");
      }
    );
    this.fileChangeEvent(this.data.fileData);
    this.zones = moments.tz.names();
        this.timeZones = [];
        this.zones.forEach(element => {
            var zoneObj = {};
            zoneObj['name'] = element + " " + "(" + moments.tz(element).format('Z z') + ")";
            zoneObj['value'] = element;
            if (zoneObj['value'].includes("/")) {
                this.timeZones.push(zoneObj);
            }
        });
  }

  imageChangedEvent: any = "";
  croppedImage: any = "";

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.file = this.dataURLtoFile(event.base64, "jjej.png");
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  onCloseRoleModal(): void {
    let data = [];
    data['type'] = "fileData";
    data["fileDta"] = this.file;
    this.dialogRef.close(data);
  }


  onCloseRoleModalClose():void{
    let data = [];
    data['type'] = "";
    data["fileDta"] = '';
    this.dialogRef.close(data);
    // this.dialogRef.close();
  }

  onClosePreViewModal(type):void{
    this.data["submitType"] = type;
    this.dialogRef.close(this.data);
  }
  onCloseDeleteCard():void{
    this.data["delete"] = true;
    this.dialogRef.close(this.data);
  }
  onCloseDeleteCardNo():void{
    this.data["delete"] = false;
    this.dialogRef.close(this.data);

  }
  onCloseAlReadyCard(type):void{
    this.data["submitType"] = type
    this.dialogRef.close(this.data);
  }
  onCloseDeleteService(type):void{
    this.data["delete"] = type;
    this.dialogRef.close(this.data);
  }

  onClosesetUpDir(type):void{
    this.data["setUpAzure"] = type;
    this.dialogRef.close(this.data);
  }

  getIntegratedOutlook(){
    if(!!this.data['data']['office']){
      this.loginDetails.applClientId = this.data['data']['office']['appOfficeClientId'];
      this.loginDetails.dir_tenant_id = this.data['data']['office']['appOfficeTenant'];
      this.loginDetails.clientSecret = this.data['data']['office']['appOfficeClientSecret'];
      this.timezone = this.data['data']['office']['appOfficeOutlookTimezone'];
    }
  }

  testIntegration(){
    let data = {
      "tenantId": this.loginDetails.dir_tenant_id,
      "clientId": this.loginDetails.applClientId,
      "clientSecret": this.loginDetails.clientSecret,
      "userOutlookEmail": this.loginDetails.outlookEmail,
      "meetingRoomEmail": this.loginDetails.meetingroomEmail
    }
    const testInt$ = this.cardService.testIntegrationData(data,this.currentZone);
    this.loaderService.showLoaderUntilCompleted(testInt$)
    .pipe(
        takeUntil(this.unsubscribe$)
    )
    .subscribe(res => {
      if(res['response']){
        this.isDisabled = false;
        this.toastrService.success(res['message']);
        this.data["setUpAzure"] = "setUpAzure";
        //this.dialogRef.close(this.data);
      }
    });
  }

  setUpIntegration(){
    
    let meta = {
      "appOfficeTenant" : this.loginDetails.dir_tenant_id,
    "appOfficeClientId" : this.loginDetails.applClientId,
    "delegatedUserEmail" : "",
    "appOfficeClientSecret" : this.loginDetails.clientSecret,
    "appOfficeOutlookTimezone" : this.timezone
  }
    let data = {
    "metadata":JSON.stringify(meta),
    "moduleId" : 53,
    "zoneId" : this.currentZone,
    "action" : "EXTERNAL_BOOKING_SETTING"
    }
    const testInt$ = this.cardService.setupIntegrationData(data,this.currentZone);
    this.loaderService.showLoaderUntilCompleted(testInt$)
    .pipe(
        takeUntil(this.unsubscribe$)
    )
    .subscribe(res => {
      if(res['response']){
        this.isDisabled = false;
        this.toastrService.success(res['message']);
        this.data["outlookIntegration"] = "outlookIntegration";
        this.dialogRef.close(this.data);
      }
    });
  }

  cancelCard(){
    this.data["type"] = "setUpAzure";
    this.dialogRef.close(this.data);
  }

  sendNotifications(){
    this.data["type"] = "sendNotification";
    this.dialogRef.close(this.data);
  }
 

  clickMore = (data, i) => {
    let dataArray = this.data.createCradObject["allModules"];

    if (!!this.data.createCradObject["more"] && this.data.createCradObject["more"] == "More") {
      this.data.createCradObject.modules = dataArray.slice(6, 12);
      this.data.createCradObject["more"] = "Back";
    } else {
      this.data.createCradObject.modules = dataArray.slice(0, 6);
      this.data.createCradObject["more"] = "More";
    }
}



}

export interface ImageTransform {
  scale?: number;
  rotate?: number;
  flipH?: boolean;
  flipV?: boolean;
}
