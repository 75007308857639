import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";
import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
@Injectable()
export class RolesandresponsibilitiesService {
    moduleId;
    BASEURL;
    EMSURL;
    constructor(
        public http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        public moduleidService: ModuleidService
    ) {
        //this.BASE_URL = environment.BASE_URL+'ems/';
        //alert(environment.BASE_URL);
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
    }

    private _refreshNeeded = new Subject<void>();
    get refreshNeeded() {
        return this._refreshNeeded;
    }

    getRolesandResponsibilities(zoneId): Observable<any> {
        let url;
        url = this.EMSURL + `ems/roles/responsibilityMapping/byCategory`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getResponsibilitiesModules(zoneId): Observable<any> {
        let url;
        url = this.EMSURL + `ems/roles/responsibilityModuleMapping/all`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    addRoles(zoneId, data: any): Observable<any> {
        let url;
        url = this.EMSURL + `ems/roles`;

        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe();
    }

    responsibilityMapping(zoneId,data:any): Observable<any> {
        let url;
        url = this.EMSURL + `ems/roles/responsibilityMapping`;

        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe();
    }


    handleError = (err) => {
        /*let errorMsgData = (err as any).error;
    const message = errorMsgData["message"];
    this.toastrService.error(message);*/
        return throwError(err);
    };

    getAllRoles(zoneId){
        const endpoint = "ems/roles/getAll"
        return this.http.get(this.EMSURL+endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    getAllZones(zoneId){
        const endpoint = "ems/zones/getAllZones?level=site"
        return this.http.get(this.EMSURL+endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    getallUsers(zoneId,data){
        //limit=${data.limit}&offset=${data.offset}
        const endpoint=`ems/user/all?sortBy=createdAt&searchColumn=${ data.searchColumn }&searchValue=${data.searchValue}&page=${data.offset}&size=${data.size}`;
        return this.http.get(this.EMSURL+endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }
    getAllTenants(pageIndex, pageSize, zoneId) {
    
        const endpoint = "tms/tenant/list?page=0&size=10000&sort=createdAt,desc"    
        return this.http.get(this.EMSURL+endpoint, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    getAllResponsibilityByModuleId(zoneId){
        const endpoint = "ems/roles/responsibilityModuleMapping/all"
        return this.http.get(this.EMSURL+endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
      }
    
    getAllRoleCategories(zoneId){
        const endpoint = "ems/roles/categories"
        return this.http.get(this.EMSURL+endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    getRolesResponsibilityMapping(zoneId){
        return this.http.get(this.EMSURL+"ems/roles/responsibilityMapping",{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)})
    }

    addRolesResponsibilityMapping(zoneId,roleResponsibilityMapping){
        const endpoint = "ems/roles/responsibilityMapping"
        return this.http.post(this.EMSURL+endpoint,roleResponsibilityMapping,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    addNewRole(roleData,zoneId){
        const endpoint = "ems/roles"
        return this.http.post(this.EMSURL+endpoint,roleData,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    createUserRole(roleData,zoneId){
        const endpoint = "ems/user/assignRole"
        return this.http.post(this.EMSURL+endpoint,roleData,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }
      
    deleteUserRole(roleData,zoneId){
        const endpoint = `ems/roles/userRoleMappings/delete?ids=${roleData}`
        // return this.http.delete(this.BASE_URLRRk+endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
        return this.http.delete(this.EMSURL+endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    addNewUser(userData,zoneId){
        const endpoint = "ems/user/admin/addUser"
        return this.http.post(this.EMSURL+endpoint,userData,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }
    
    deleteUser(userIds,zoneId){
        const endpoint = "ems/admin/deleteUsers"
        return this.http.post(this.EMSURL+endpoint,userIds,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    editUser(userData,zoneId){
        const endpoint = "ems/user/admin/edit"
        return this.http.post(this.EMSURL+endpoint,userData,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }

    changeStatus(zoneId,data){
        const endpoint="ems/user/admin/changeStatus";
        return this.http.post(this.EMSURL+endpoint,data,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }
    
    
    
    createOrEditManageUserRole(roleData,zoneId,type){
    
        var endpoint;
        if(type=="add"){
           endpoint= "ems/user/admin/addUser";
        }else{
           //endpoint= "ems/user/admin/editUserDetails"; SPRT-6517 changes
           endpoint= "ems/user/admin/editUserDetails/V2";
        }
        return this.http.post(this.EMSURL+endpoint,roleData,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
    }
    additionalDetailslist(zoneId){
        return this.http.get(this.EMSURL + "ems/custom-field/all-custom-key?customKey=USER_ADD_DETAILS&page=0&size=1000", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    
    }
    additionalDetailsActivelist(zoneId,userId){   
        const endpoint = `ems/custom-field-metadata/all-active?moduleActionKey=USER_ADD_DETAILS&moduleEntityId=${userId}&page=0&size=100`;
        
       return this.http.get(this.EMSURL+endpoint,{params: new ZoneAndModuleHttpParams(zoneId,this.moduleId)});
    
     }
     addUserAdditionalDetails(zoneId,data){

        return this.http.post(this.EMSURL + "ems/custom-field-metadata/create", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
     
      }
     updateUserAdditionalDetails(zoneId,data){

        return this.http.post(this.EMSURL + "ems/custom-field-metadata/update/v2", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
     
      }
}
