<div *ngIf="pageType == 'addEvent'">
  <div class="modal-header pl-0">
    <h5>
     
      Onboard Users
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">Global Setup &gt; People</p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="row">
      <p class="f-16 pl-3 enable-mutiday font-14 fontColor">
        How would you like to onboard new users?
      </p>
    </div>

    <div class="form-row">
      <mat-radio-group
        
        class="font-color pl-3 csm-class"
        [(ngModel)]="addType"
      >
        <mat-radio-button
          class="pb-0 mb-3 custom-radio-btn custom-radio-btn-label"
          value="manual"
        >
          Add Manually
        </mat-radio-button>

        <mat-radio-button
          class="pb-0 mb-3 custom-radio-btn custom-radio-btn-label"
          value="excel"
        >
          Add via excel
        </mat-radio-button>
      </mat-radio-group>
    </div>

 
    <button
      *ngIf="addType != ''"
      (click)="selectType()"
      class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      Next
    </button>
  </div>
</div>

<div *ngIf="pageType == 'addEventExcelNew'">
  <div class="modal-header pl-0 pr-0">
    <h5>{{ "onboardUser" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">Global Setup &gt; People</p>
  </div>
  <div class="modal-body width-400 align-left mt-2 pb-0">
    <!-- <ng-container *ngIf="incorrectData.length > 0">
      <p class="regular-semibold-font">
        {{ "successfullyUploaded" | translate }}
        {{ TotalNoOfEntitiesUploadedRNW }} ,
        {{ "belowAreTheListOfEntitiesWhichHaveIssues" | translate }}.
      </p> -->
      <ng-container *ngIf="addType == 'excel'">
        <div class="row">
          <p class="regular-semibold-font pl-3" style="text-align: justify;">
            Add employees categorised as Internal or Tenants. 
          </p> 

        </div>

        <div class="row ">
          <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
              <p
                  class="p-0 blueText col-12 mb-0"
                  style="text-align: justify;"
              >
              Note: Onboarding Internal employees will require mentioning the employee name, corporate email address mandatorily. Onboarding Tenant employees will require mentioning the employee name, corporate email address and tenant name mandatorily.
                
              </p>
          
             
          </div>
      </div>
        <div class="row">
          <div class="col-md-5">
            <label></label>
            <button
              type="button"
              class="form-control1"
              (click)="downloadViaExcelTemplate()"
            >
              <span style="font-family: 'Open Sans'">{{
                "downloadTemplate" | translate
              }}</span>
              <span class="ml-3"
                ><img
                  src="./assets/images/downloadTemplate.png"
                  style="width: 1rem"
              /></span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <label for="uploadFile" class="label1 float-left mt-2 font-label"
              >{{ "upload" | translate }} (.xlsx {{ "file" | translate }})</label
            >
            <div class="input-group clear-both float-left">
              <input
                type="file"
                class="form-control custom-file-input"
                id="importFile"
                aria-describedby="inputGroupFileAddon01"
                (click)="fileInputXLSX.value = null"
                #fileInputXLSX
                accept=".xlsx"
                (change)="uploadExcel($event.target.files)"
              />
              <label
                (click)="fileInputXLSX.click()"
                class="custom-file-label1 custom-upload-btn font-label"
                for="inputGroupFile01"
                #labelImport
              ></label>
            </div>
          </div>
          <div
            class="input-group col-md-4 mt-3"
            *ngIf="showUploadedExcelFileName != ''"
          >
            <p class="inner-card-auto p-1">
              <span class="text-overflow-hidden ml-1">{{
                showUploadedExcelFileName
              }}</span>
              <button
                type="button"
                class="close text-right right-flex"
                aria-label="Close"
              >
                <span aria-hidden="true" (click)="showUploadedExcelFileName = ''"
                  >&times;</span
                >
              </button>
            </p>
          </div>
        </div>
  
        <div
          class="row col-12 text-danger"
          *ngIf="submittedExcel && showUploadedExcelFileName == ''"
        >
          Please upload file
        </div>
      </ng-container>
   
  


    <div class="row mt-2">
      <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
          <p
              class="p-0 blueText col-12"
              style="text-align: justify;"
          >
          *All users are tagged to a general user role by default. Specific location mapping for all users along with Additional role mapping for tenants can be added via Super Admin profile. 
             
          </p>
      
         
      </div>
  </div>
  <div class="row "> <div class="col-12 float-right px-0">
    <button
    *ngIf="showUploadedExcelFileName != ''"
    (click)="selectType1()"
    class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
  >
    Next
  </button>
  </div>
  
</div>
   
  </div>
</div>



<div *ngIf="pageType == 'onboardingExcelTable'">
  <div class="modal-header pl-0 pr-0">
    <h5>{{ "alert" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body width-400 align-left mt-2 pb-0">
    <ng-container *ngIf="incorrectData.length > 0">
      <p class="regular-semibold-font">
        {{ "successfullyUploaded" | translate }}
        {{ TotalNoOfEntitiesUploadedRNW }} users ,
        {{ "belowAreTheListOfEntitiesWhichHaveIssues" | translate }}.
      </p>
      <table mat-table [dataSource]="showIncorrectData" class="vms-error-table">
        <!-- First Name Column -->
        <ng-container matColumnDef="fname">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            First Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('First Name'  )
            }"
            [matTooltip]="getToolTipData(element.firstName)"
          >
            {{ element.firstName | slice : 0 : 20 }}
            <span *ngIf="element.name?.length > 20">...</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="lname">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Last Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Last Name')
            }"
            [matTooltip]="getToolTipData(element.lastName)"
          >
            {{ element.lastName | slice : 0 : 20 }}
            <span *ngIf="element.name?.length > 20">...</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Email
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Email')
            }"
            [matTooltip]="getToolTipData(element.corporateEmail)"
          >
            {{ element.corporateEmail | slice : 0 : 20 }}
            <span *ngIf="element.name?.length > 20">...</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="typeOfUser">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Type of user
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Type of user')
            }"
            [matTooltip]="getToolTipData(element.typeOfUser)"
          >
            {{ element.typeOfUser | slice : 0 : 20 }}
            <span *ngIf="element.name?.length > 20">...</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="globalAdmin">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Global Admin
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Global Admin')
            }"
            [matTooltip]="getToolTipData(element.globalAdmin)"
          >
            {{ element.globalAdmin | slice : 0 : 20 }}
            <span *ngIf="element.name?.length > 20">...</span>
          </td>
        </ng-container>
        <ng-container *ngIf="!manualAccess"  matColumnDef="tag">
          <th mat-header-cell matTooltip ='Tag as General User to all location' *matHeaderCellDef class="align-left">
            Tag as General...
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Tag as General User to all location')
            }"
            [matTooltip]="getToolTipData(element.tagAsGeneralUser)"
          >
            {{ element.tagAsGeneralUser     | slice : 0 : 20 }}
            <span *ngIf="element.name?.length > 20">...</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="tenantName">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Tenant Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Tenant Name')
            }"
            [matTooltip]="getToolTipData(element.tenantName)"
          >
          <span *ngIf="element.tenantName!=''" >
            {{ element.tenantName | slice : 0 : 20 }}</span>
            <span *ngIf="element.tenantName==''" >
             --</span>
            <span *ngIf="element.tenantName?.length > 20">...</span>
          </td>
        </ng-container>
        <!-- Capacity is only for Collab Spaces -->

        <!-- Reason Column -->
        <ng-container matColumnDef="reason">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="align-left"
            style="width: 20vw"
          >
            {{ "reason" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [matTooltip]="getToolTipData(element.reason)"
          >
            <span
              >{{ element.reason | slice : 0 : 30 }}
              <ng-container *ngIf="element.reason?.length > 30"
                >...</ng-container
              >
            </span>
          </td>
        </ng-container>
<ng-container *ngIf="!manualAccess" >
        <tr mat-header-row *matHeaderRowDef="incorrectDataColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: incorrectDataColumns"></tr>
      </ng-container>
      <ng-container *ngIf="manualAccess" >
        <tr mat-header-row *matHeaderRowDef="incorrectDataColumnsManualAcess"></tr>
        <tr mat-row *matRowDef="let row; columns: incorrectDataColumnsManualAcess"></tr>
      </ng-container>
      </table>
      <!-- <ng-container *ngIf="totalIncorrectLength > 10">
        <mat-paginator
          class="w-100"
          #paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[10, 25, 100]"
          [showFirstLastButtons]="true"
          (page)="getNextRecords($event)"
          [length]="totalIncorrectLength"
        ></mat-paginator>
      </ng-container> -->

      <div class="row text-center button-alignToEnd float-right p-2 mt-2">
        <button
          class="btn btn-main f-size-small pl-3 pr-3 btn-radius p-1 m-1 filter btn-primary pull-right"
          (click)="reUploadEntities()"
        >
          {{ "reuploadFilesWithFixes" | translate }}
        </button>
        <button
          class="btn-main-white-nb ml-4 btn btn-main f-size-small pl-3 pr-3 btn-radius p-1 m-1 filter btn-primary pull-right"
          (click)="proceedToOnboard()"
        >
          {{ "proceedToOnboardTheRemainingEntries" | translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="correctData.length > 0 && incorrectData.length == 0">
      <p class="regular-semibold-font">
        {{ "successfullyUploaded" | translate }}
        {{ noOfEntitiesUploaded }} users. Click on Proceed to onboard the users.
      </p>
      <button
        class="btn btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
        (click)="proceedToOnboard()"
      >
        {{ "proceed" | translate }}
      </button>
    </ng-container>
  </div>
</div>
<div *ngIf="pageType == 'onboarding_reUploadCSV'">
  <div class="modal-header pl-0 pr-0">
    <h5>{{ "reUploadFile" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body width-400 align-left mt-2">
    <div class="row">
      <div class="col-12 pl-2">
        <img
          class="cursor-pointer"
          src="../../assets/images/jumpree_digital/download.png"
          (click)="downloadCurrentCSVFile()"
          width="45"
        />
        <span class="regular-semibold-font">
          {{ "downloadCurrentFile" | translate }}
        </span>
      </div>
    </div>
    <p class="regular-semibold-font mt-3">
      {{ "uploadFileWithFixes" | translate }}
    </p>
    <div class="row">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
        <label for="uploadFile" class="label1"
          >{{ "upload" | translate }} (.xlsx {{ "file" | translate }})</label
        >
        <div class="input-group">
          <input
            type="file"
            #fileInput
            class="form-control custom-file-input"
            id="importFile"
            (click)="fileInput.value = null"
            aria-describedby="inputGroupFileAddon01"
            accept=".xlsx"
            (change)="uploadExcel($event.target.files)"
          />
          <label
            class="custom-file-label1 custom-upload-btn"
            for="inputGroupFile01"
          >
          </label>
        </div>
      </div>
    </div>
    <div class="row m-0 mt-4" *ngIf="showUploadedExcelFileName">
      <p class="pConfirmationMessage custom-shadow ml-0">
        {{ showUploadedExcelFileName }}
        <span
          class="ml-4 cursor-pointer font-weight-bold"
          (click)="showUploadedExcelFileName = undefined"
          >×</span
        >
      </p>
    </div>
    <div class="row" *ngIf="showUploadedExcelFileName">
      <div class="col">
        <button
          type="button"
          class="btn btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
          (click)="validateExcelOnboarding()"
        >
          {{ "proceed" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>




<div class="" *ngIf="pageType == 'addEventManual'">
  <div class="modal-header pl-0">
      <h5 class="semi-bold-blue">
      
          {{"onboardUser" | translate}}
      </h5>
      <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12 pl-0 ">
      <p class="italic mt-2 openSans pl-4 mb-0">
          People
      </p>
  </div>
  <div class="modal-body align-left width-400">
  
      <div class="row">
          <div class="form-group col-lg-6 col-md-12  ">
              <label class="float-left formLabel"
                  >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
              >
              <input
                  type="email"
                  class="form-control blueText"
                  placeholder="{{'enterEmailID' | translate}}"
               [(ngModel)]="onboardUserEmailVerify"
              
              />
              <div
                  *ngIf="
                      submittedUsers &&
                      addUsersNonTeams.controls.email.errors
                  "
              >
                  <div class="text-danger formLabel">
                      {{"emailIsRequired" | translate}}
                  </div>
              </div>
          </div>
          </div>

   
      <div class="">
          <ng-container>
              <div class="form-group col-lg-12 col-md-12 pr-0">
                  <button
                      type="button"
                      *ngIf="validateEmail(onboardUserEmailVerify)"
                      class="btn btn-main btnSmall smallBtn float-right ml-4"
                      (click)="verifyEmail()"
                  >
                      {{"next" | translate}}
                  </button>
                
              </div>
          </ng-container>
      </div>
  </div>
</div>

<div
class=""
*ngIf="
    pageType == 'addUserManuallyNonTeams'
    || pageType=='editUsersNonTeams' 


"
>
<div class="modal-header pl-0">
    <!-- <h5 class="semi-bold-blue"><i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtnUsers()" *ngIf="!individual && pageType != 'editUsers'"></i> {{pageType != 'editUsers' ? 'Add' : 'Edit'}} {{"users" | translate}} <span *ngIf="!individual">(3/3)</span></h5> -->
    <h5 class="semi-bold-blue" *ngIf="
    pageType == 'addUserManuallyNonTeams'



">
       
        {{"onboardUser" | translate}}
        <!-- <span *ngIf="!individual">(3/3)</span> -->
    </h5>
    <h5 class="semi-bold-blue" *ngIf="
   pageType=='editUsersNonTeams' 


">
       
        {{"editUser" | translate}}
        <!-- <span *ngIf="!individual">(3/3)</span> -->
    </h5>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
           
                closePopup()
        "
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="col-12">
  <p class="italic mt-2 openSans pl-2 mb-0">People</p>
</div>
<div class="modal-body align-left">
   
    <form
        [formGroup]="addUsersNonTeams"
        (ngSubmit)="submitAddUserNonTeams(pageType)"
    >
        <div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
                    >
                    <input
                    
                
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'enterEmailID' | translate}}"
                        formControlName="email"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersNonTeams.controls.email.errors
                        "
                    >
                        <div class="text-danger formLabel">
                            {{"emailIsRequired" | translate}}
                        </div>
                    </div>
                </div>

            
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"firsNameCaps" | translate}}
                        <span class="text-danger">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'Entername' | translate}}"
                        formControlName="employeeName"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersNonTeams.controls.employeeName
                                .errors
                        "
                    >
                        <div class="text-danger formLabel">
                            {{"firstNameIsRequired" | translate}}
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"lastNameCaps" | translate}}
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'Entername' | translate}}"
                        formControlName="lastName"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersNonTeams.controls.lastName.errors
                        "
                    >
                        <div class="text-danger formLabel">
                            {{"lastNameIsRequired" | translate}}
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="row col-12">
                <p class="regular-semibold-font">
                   Is this user an Internal user or Tenant user? 
                   <span
                   matTooltipClass="custom-tooltip1"
                   matTooltipPosition="right"
                   matTooltip="{{getSpecialTooltip()}}"
                   class="light-blue-anchor ml-2 informationIcon"
                   >i</span>
                   
                </p>
            </div>
            <div class="row col-12">
                <mat-radio-group
                    aria-label=""
                    class="radioBtn d-flex flex-column h-auto"
                    [(ngModel)]="addUserTypeInternalorTenant"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="editUserEnable"
                    required
                >
                    <!-- <mat-radio-button id="ad_integration" value="ad_integration" class="mb-3">
                    {{ 'AD Integrations' | translate }}
                    <span class="light-blue-anchor ml-2 informationIcon">i</span>
                  </mat-radio-button> -->

                    <mat-radio-button
                        id="add_manually"
                        class="mb-3"
                        value="internal"
                    >
                        Internal
                    </mat-radio-button>

                    <mat-radio-button
                        id="add_excel"
                        class="mb-3"
                        value="tenant"
                    >
                        Tenant
                    </mat-radio-button>
                </mat-radio-group>
            </div>

<ng-container *ngIf="addUserTypeInternalorTenant=='internal'" >
            <div class="row"> <div class="form-group col-lg-12 col-md-12">
                <mat-checkbox
                    class="example-margin"
                    [disabled]="manualAccess"
                    [ngClass]="{'disabled': manualAccess}"
                    formControlName="allLocationGeneralUser"
                    > Tag user to all location as general user </mat-checkbox
                >

            </div> 
          
           <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
                    <p
                        class="p-0 blueText col-12"
                      *ngIf="!manualAccess"
                    >
                    NOTE: The user will be mapped as a general user to all existing locations and new locations.
                        
                    </p>
                    <p   class="p-0 blueText col-12"
                    *ngIf="manualAccess" >

                    NOTE: The user will be mapped as a general user to all existing locations and new locations. This can be modified in the Settings tab.
                    </p>
                
                   
                </div>
          </div>
            <p class="regular-semibold-font">
                {{"additionalRolesCaps" | translate}}
                 <mat-slide-toggle
                     class="toggleBtn ml-2"
                     (change)="
                         toggleNonTeamsLocations($event)
                     "
                     [formControl]="additionalRoles"
                 ></mat-slide-toggle>
             </p>
            <div class="row"  *ngIf="addLocNonTeams">
                <div class="form-group col-lg-12 col-md-12">
                    <mat-checkbox
                        class="example-margin"
                       
                        formControlName="globalAdmin"
                        >{{"globalAdmin" | translate}} </mat-checkbox
                    >
                </div>
                <div
                    class="form-group col-lg-12 col-md-12"
                    *ngIf="!addUsersNonTeams.get('globalAdmin').value"
                >
                  
                    <ng-container
                        *ngIf="
                            customLocationList.length > 0 
                        "
                    >
                       
                        <div class="row" *ngIf="addLocNonTeams">
                            <div class="form-group col-lg-5 col-md-12">
                                <label class="formLabel"
                                    >{{"locationCaps" | translate}}  <span class="text-danger">*</span> </label
                                >
                               
                                <div
                                    class="input-group pl-0"
                                   
                                >
                                    
                                    <input
                                        type="text"
                                        autocomplete="off"
                                        id="locationSelection"
                                        class="form-control blueText textIndent float-right pl-3"
                                        placeholder="Select Location"
               
                                       
                                        [formControl]="
                                            searchLocationNonTeams
                                        "
                                        required
                                        [matAutocomplete]="auto7"
                                        #customInput1
                                    />
                                    <i class="fa fa-search iconSearch"></i>
                                    <i
                                        style="position: absolute;
                                                                                    right: 10px;
                                                                                    top: 10px;"
                                        class="fa fa-spinner loading_span"
                                        *ngIf="nameSearchIsLoading"
                                        aria-hidden="true"
                                    ></i>
                                    <mat-autocomplete
                                        multiple
                                        #auto7="matAutocomplete"
                                    >
                                        <mat-option
                                            class="autocompleteSearch"
                                            *ngFor="
                                                let dropData of filteredCustomLocationsAdditionalNonTeams
                                                    | async
                                            "
                                            [value]="dropData.name"
                                            (click)="
                                                selectLocationNonTeams(
                                                    dropData
                                                )
                                            "
                                        >
                                            <span>{{
                                                dropData.name
                                            }}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="form-group col-lg-5 col-md-12">
                                <label class="formLabel">{{"Roles" | translate}} <span class="text-danger">*</span></label>
                                <div class="input-group pl-0">
                                   
                                    <input
                                        type="text"
                                        autocomplete="off"
                                        class="form-control pl-3 blueText textIndent float-right"
                                        placeholder="Select Role"
                                        required
                                        [formControl]="
                                        searchControl
                                        "
                                        [matAutocomplete]="auto"
                                        #customInput
                                    />
                                    <i class="fa fa-search iconSearch"></i>
                                    <i
                                        style="position: absolute;
                                          right: 10px;
                                          top: 10px;"
                                        class="fa fa-spinner loading_span"
                                        *ngIf="nameSearchIsLoading"
                                        aria-hidden="true"
                                    ></i>
                                    <mat-autocomplete
                                        #auto="matAutocomplete"
                                    >
                                        <ng-container
                                            *ngFor="
                                                let dropData of filteredRoles
                                                    | async
                                            "
                                        >
                                            <mat-option
                                                class="autocompleteSearch"
                                                *ngIf="
                                                    dropData.id != 13
                                                "
                                                [value]="dropData.name"
                                                (click)="
                                                    selectRolesNonTeams(
                                                        dropData
                                                    )
                                                "
                                            >
                                                <span>{{

                                                  getNameWithoutUnderscore( dropData.name )
                                                   
                                                }}</span>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <button
                                class="smallBtnCircleAdd semi-bold-blue mt-4 ml-5"
                                (click)="tagRolesLocationsNonTeams()"
                                type="button"
                            >
                                +
                            </button>
                        </div>
                    </ng-container>

                    <div
                        class="row align-items-center"
                        *ngIf="locationsListNonTeams.length > 0"
                    >
                        <ng-container
                            *ngFor="
                                let loc of locationsListNonTeams;
                                let i = index
                            "
                        >
                        <ng-container *ngIf="hasValidRoles(loc)">
                            <h6 class="semi-bold-blue col-5">
                                {{ loc.name }}
                            </h6>
                            <div class="row p-1 mt-2 col-7 pt-0 ml-1">
                                <div
                                    class="form-group w-fit ml-1"
                                    *ngFor="
                                        let role of loc.roles;
                                        let j = index
                                    "
                                >
                                    <div
                                        class="inner-card-auto  d-flex justify-content-between align-items-center py-1"
                                    >
                                        <span
                                            class="gray-regular font-small mr-1"
                                            >{{ getNameWithoutUnderscore( role.role )}}</span
                                        >
                                        <span
                                            aria-hidden="true"
                                            (click)="
                                                removeRolesNonTeams(
                                                    i,
                                                    j
                                                )
                                            "
                                            class="cursor-pointer"
                                            >&times;</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="addUserTypeInternalorTenant=='tenant'" >
          <div class="row" >
            <div class="form-group col-lg-5 col-md-12">
          <div
          class=""
      >
          <label class="formLabel"
              >Tenant Name
              <span class="text-danger"
                  >*</span
              ></label
          >
          <div class="input-group pl-0">
             
              <input
                  type="text"
                  autocomplete="off"
                  id="locationSelection"
                  class="form-control blueText textIndent float-right pl-3"
                  placeholder="Select Tenant Name"
                
                  [formControl]="searchTenantCompany"
                  required
                  [matAutocomplete]="auto5"
                  #customInput5
              />
              <i class="fa fa-search iconSearch"></i>
              <i
                  style="position: absolute;right: 10px;top: 10px;"
                  class="fa fa-spinner loading_span"
                  *ngIf="nameSearchIsLoading"
                  aria-hidden="true"
              ></i>
              <mat-autocomplete
                  multiple
                  #auto5="matAutocomplete"
              >
                  <ng-container
                      *ngFor="
                          let dropData of filteredTenantCompany
                              | async
                      "
                  >
                      <mat-option
                          class="autocompleteSearch"
                         
                          [value]="dropData.name"
                          (click)="
                          selectCustomTenantComapny(
                                  dropData
                              )
                          "
                      >
                          <span>{{
                              dropData.name
                          }}</span>
                      </mat-option>
                  </ng-container>
              </mat-autocomplete>
          </div>
          <div
              *ngIf="
                  submittedUsers &&
                  !selectedTenantComapny
              "
          >
              <p class="text-danger formLabel">
                 {{"required" | translate}}
              </p>
          </div>
      </div>
      </div>
      </div>
            </ng-container>

            <div class="row mt-2">
                <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
                    <p
                        class="p-0 blueText col-12"
                      *ngIf="!manualAccess"
                    >
                    *All users are tagged to a general user role by default. Specific location mapping for all users along with Additional role mapping for tenants can be added via Super Admin profile.
                        
                    </p>
                    <p   class="p-0 blueText col-12"
                    *ngIf="manualAccess" >

                      *All users are tagged to a general user role in all locations. Additional roles for tenants can be added via the Super Admin profile. 
                    </p>
                
                   
                </div>
            </div>
            <div class="row "> <div class="col-12 float-right px-0">
              <button
              *ngIf="addUserTypeInternalorTenant=='tenant' || addUserTypeInternalorTenant=='internal'"
                  class="btn btn-main btnSmall float-right  semi-bold-blue ml-4 smallBtn float-right"
                  type="submit"
              >
                  {{"save" | translate}}
              </button>
             
          </div></div>
        </div>
    </form>
</div>
</div>

<div class="" *ngIf="pageType == 'ShowAlertRole'">
  <div class="modal-header pl-0">
      <h5 class="semi-bold-blue">
          {{ "alert" | translate }}
      </h5>
  </div>

  <div class="modal-body align-left">
      <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
      <div class="row">
          <h6 class="regular-blue pl-3">
              {{ "ifYouRemoveAllTheRolesInaLocation" | translate }}
          </h6>
      </div>
      <div class="row col-12 d-inline-block">
          <button
              class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
              type="button"
              (click)="removeRoleAndDisableLocation()"
          >
              {{ "yes" | translate }}
          </button>
          <button
              class="btn btn-White btn-main btnSmall btn-radius mr-2 float-right font-regular"
              type="button"
              (click)="goBack()"
          >
              {{ "no" | translate }}
          </button>
      </div>
  </div>
</div>

<div class="" *ngIf="pageType == 'ShowAlertdisableUser'">
  <div class="modal-header pl-0">
      <h5 class="semi-bold-blue">
          {{ "alert" | translate }}
      </h5>
  </div>

  <div class="modal-body align-left">
  
      <div class="row ">
          <p class="fontColor pl-3 mb-1">
              You are attempting to disable ”{{data['userName']}}”. The user will be logged out of the system. 
          </p>
      </div>
    
      <div class="row ">
          <p class="fontColor pl-3">
            User will not have access to all mapped locations and their additional roles (if any) will be void once disabled. 
          </p>
      </div>
      <div class="row mt-2">
          <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
              <p
              class="fontColorSemi "
                  style="text-align: justify;"
              >
              Are you sure you want to proceed?
          </p>
              </div>
              </div>
      <div class="row col-12 d-inline-block">
          <button
              class="btn btn-main  pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
              type="button"
              (click)="alertYes()"
          >
              {{ "yes" | translate }}
          </button>
          <button
              class="btn btn-main btn-white pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
              type="button"
              (click)="alertNo()"
          >
              {{ "no" | translate }}
          </button>
      </div>
  </div>
</div>



