import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import { BehaviorSubject, Subject } from "rxjs";
import { Observable } from "rxjs";
import {
    environment,
    modulesIds
} from "../../../../../environments/environment";
import { ZoneAndModuleHttpParams } from "../../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { v4 as uuidv4 } from 'uuid';
@Injectable()
export class HeaderService {
    private moduleId;
    private BASE_URL;
    localVal;
    constructor(private http: HttpClient) {
        this.moduleId = modulesIds.seatBooking;
        this.BASE_URL = environment.BASE_URL;
        //this.BASE_URL = "http://10.8.0.66:8084/";
        this.localVal = localStorage.getItem("language");
    }

    public postFile(zoneId, fileToUpload: File): Observable<Object> {
        const endpoint =
            this.BASE_URL + "ems/media/uploadLogo?width=150&height=150";
        const formData: FormData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        var yourHeadersConfig = {
            "content-type": "multipart/form-data"
        };
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":48,"correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
            })
        };
        return this.http.post(endpoint, formData, httpOptions);
    }

    public changePassword(zoneId,data): Observable<Object> {
        const endpoint = this.BASE_URL+"ems/user/changePassword";
        let httpOptions;
        if (zoneId != undefined || zoneId != null) {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":48,"correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
                })
            };
        }
        else {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":48,"correlationId":"${uuidv4()}","locale":"${this.localVal}"}`
                })
            };
        }       
        return this.http
          .post(endpoint, data, httpOptions);
    
    }

   
}
