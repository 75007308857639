import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from 'src/environments/environment';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../services/moduleid-service";
export class CustomersService {
    constructor(http, baseUrl, moduleidService) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.selectedCustomerSub$ = new BehaviorSubject(null);
        this.selectedCustomerItem = this.selectedCustomerSub$.asObservable();
        this.BASEURL = environment.BASE_URL + 'tms/';
        this.BASE_EMS_URL = environment.BASE_URL + 'ems/';
        this.moduleId = moduleidService.getModueId();
    }
    getActiveList(zoneId) {
        let url = "custom-fields/list/";
        return this.http.get(this.BASEURL + url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    registerTenantDetails(tenantRequest, zoneId) {
        let url = "tenant/generate";
        let queryParams = "";
        return this.http.post(this.BASEURL + url + queryParams, tenantRequest, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    createZohoCustomer(data, zoneId) {
        let url = "addons/invoice/createCustomer";
        let queryParams = "";
        return this.http.post(this.BASEURL + url + queryParams, data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    getCompanyEmailTypesForAdmin(zoneId) {
        let url = "user_reg_type";
        let queryParams = "";
        return this.http.get(this.BASEURL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    getTenantProfiles(page, size, sort, zoneId) {
        let url = "tenant/list";
        let queryParams = "?page={page}&size={size}&sort={sort}".replace('{page}', page).replace('{size}', size).replace('{sort}', sort);
        return this.http.get(this.BASEURL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    getFilteredTenantProfiles(page, size, sortCriteria, sortDirection, zoneId, searchColumn, searchStr) {
        let url = 'tenant/list';
        //?page=0&size=10&sort=created_at,desc
        let queryParams = '?search={searchColumn}:{searchStr}&page={page}&size={size}&sort={sort},{direction}'
            .replace('{page}', page)
            .replace('{size}', size)
            .replace('{sort}', sortCriteria)
            .replace('{direction}', sortDirection)
            .replace('{searchColumn}', searchColumn)
            .replace('{searchStr}', searchStr);
        return this.http.get(this.BASEURL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    getTenantProfileById(tenantId, zoneId) {
        let url = "tenant-by-id";
        let queryParams = "?tenant_id={tenant_id}".replace('{tenant_id}', tenantId);
        return this.http.get(this.BASEURL + url + queryParams, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    updateTenantProfileById(tenantProfile, zoneId) {
        let url = "tenant/update-by-id";
        let queryParams = "";
        return this.http.put(this.BASEURL + url + queryParams, tenantProfile, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    getFieldTypes(zoneId) {
        let url = "custom-fields/field-types";
        return this.http.get(this.BASEURL + url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    getList(zoneId) {
        let url = "custom-fields/list-by-category/all?category=TENANT";
        return this.http.get(this.BASEURL + url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    saveSetup(data, zoneId) {
        let url = "custom-fields/setup";
        return this.http.post(this.BASEURL + url, data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    uploadFile(data, zoneId) {
        const formData = new FormData();
        if (data) {
            formData.append('file', data, data.name);
        }
        let url = "media/compressAndUpload?fileType=" +
            data.type +
            "&targetFolder=Test";
        return this.http.post(this.BASEURL + url, formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
    sendActivationLink(dataReq, zoneId) {
        let url = "user/resendActivationLink";
        let queryParams = "";
        return this.http.post(this.BASE_EMS_URL + url, dataReq, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }
}
CustomersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomersService_Factory() { return new CustomersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("BASE_URL"), i0.ɵɵinject(i2.ModuleidService)); }, token: CustomersService, providedIn: "root" });
