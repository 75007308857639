import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms'
import { Directive, OnInit, forwardRef, Input } from '@angular/core';
 

@Directive({
  selector: '[selectValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: selectorvalidator, multi: true }
  ]
})
export class selectorvalidator implements Validator, OnInit {
  @Input('selectValidator') tenantList;
  ngOnInit() {
    
  }
 
  validate(c: FormControl) {
    // console.log(c);
    let v: number = +c.value;
    let result = this.tenantList.filter(el => el.tenant_id === v);
    // console.log(v);
    // console.log(this.tenantList);
    // console.log(result);
    if (result.length>0 && result[0].active) {
     
      return null;
    }
    return { 'requireddd': true };
  }
}