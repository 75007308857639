import { AssetModalComponent } from "./layout/asset-management/modal/asset-modal/asset-modal.component";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxPrintModule } from "ngx-print";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { HeaderModalComponent } from "./layout/components/header/modal/header-modal.component";
import { HeaderService } from "./layout/components/header/services/header.service";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

import { MySchedulesComponent } from "../app/layout/digital-dashboard/my-schedules/my-schedles.component";
import { TabNavigationService } from "./layout/space-planning/services/tab-navigation.service";
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  resourceTimelinePlugin,
]);

// import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../projects/ngx-loading/src/public_api';
import {
  MatDialogModule,
  MatCardModule,
  MatCheckboxModule,
  MatOptionModule,
  MatSelectModule,
  MatButtonModule,
  MatRadioModule,
  MatInputModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatIconModule,
  MatTooltipModule,
  MatTableModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDividerModule,
  MatChipsModule,
  MatPaginatorModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatGridListModule,
  MatProgressBarModule,
} from "@angular/material";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./shared";
import { ModalLoaderModule } from "./shared/modules/modal-loader/modal-loader.module";
import { LoginService } from "./login/login.service";
import { ControlMessagesComponent } from "./shared/formValidator/control-messages.component";
import { ConfirmationDialogComponent } from "./shared/confirmation-dialog/confirmation-dialog.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalRequestServiceService } from "./custom-modal/service/modal-request-service.service";
import { LoginRoutingModule } from "./login/login-routing.module";
import { AccountVerifyRoutingModule } from "./accountVerify/accountVerify-routing.module";
import { TenantVerifyRoutingModule } from "./tenantVerify/tenantVerify-routing.module";
import { ForgotPasswordRoutingModule } from "./forgotPassword/forgotPassword-routing.module";
import { NotfoundRoutingModule } from "./notfound/notfound-routing.module";
import { ForgotPasswordSuccessRoutingModule } from "./forgotPasswordSuccess/forgotPasswordSuccess-routing.module";
import { selectorvalidator } from "./shared/validators/selectorvalidator.directive";
import { CommonService } from "./services/common-service.service";
import { DigitalService } from "./services/digital-service.service";
import { LeafletService } from "./services/onboard-leaflet.service";
import { TokenInterceptor } from "./interceptor";
import { HomeService } from "./layout/home/service/home.service";
import { QrcodeService } from "./layout/qrcode/services/qrcode.service";
import { SocialDistancingService } from "./layout/social-distancing/services/social-distancing.service";
import { AlertService } from "./services/alert-service.service";
import { SafePipe } from "./safe.pipe";
import { DataService } from "./services/data.service";
import { ShareddataService } from "./services/shareddata.service";
import { EMSService } from "./shared/services/ems.service";
import { TMSService } from "./shared/services/tms.service";
import { ModuleidService } from "./services/moduleid-service";
import { QRCodeModule } from "angularx-qrcode";
import { MatStepperModule } from "@angular/material/stepper";

import { SubHeadingComponent } from "./layout/components/sub-heading/sub-heading.component";
import { RolesModalComponent } from "./layout/rolesandresponsibilities/modal/roles-modal.component";
import { RolesandresponsibilitiesService } from "./layout/rolesandresponsibilities/services/rolesandresponsibilities.service";
import { OnboardingModalComponent } from "./layout/onboarding/modal/onboarding-modal.component";
import { ManageRequestModalComponent } from "./layout/manage-request/modal/manage-request-modal.component";
import { ManageAmenitiesModalComponent } from "./layout/amenities/modal/manage-amenities-modal.component";
import { OnboardingService } from "./layout/onboarding/services/onboarding.service";
import { ManageRequestsService } from "./layout/manage-request/service/manage-requests.service";
import { Store } from "./store";
import { environment } from "../environments/environment";
import { LoaderComponent } from "./shared/modules/loader/loader.component";
import { PersonalComponent } from "./layout/digital-dashboard/shared/personal/personal.component";
import { PersonalModule } from "./layout/digital-dashboard/shared/personal/personal.module";
import { LoaderService } from "./shared/modules/loader/loader.service";
import { CustomLoaderComponent } from "./shared/modules/custom-loader/custom-loader.component";
import { CustomLoaderService } from "./shared/modules/custom-loader/custom-loader.service";
import { PeopleService } from "./layout/people/service/people.service";
import { DashboardService } from "./layout/digital-dashboard/services/dashboard.services";
import { WorkStatusService } from "./layout/workStatus/service/workstatus.service";
import { MapActionIconsModalComponent } from "./layout/components/map-action-icons/modal/map-action-icons-modal.component";
import { MapActionIconsService } from "./layout/components/map-action-icons/map-action-icons.service";
import { PrintPdfComponent } from "./layout/components/print-pdf/print-pdf.component";
import { PrientPdfService } from "./layout/components/print-pdf/print-pdf.service";
import { LoginModalComponent } from "./login/modal/login-modal.component";
import { NgOtpInputModule } from "ng-otp-input";
import { SelectCheckAllComponent } from "./layout/rolesandresponsibilities/modal/select-check-all.component";

import { CardModalComponent } from "./layout/cardconfig/modal/card-modal.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ExpenseModalComponent } from "./layout/expenses/modal/expense-modal.component";
import { ExpenseService } from "./layout/expenses/shared/service/expense.service";
import { VMSModalComponent } from "./layout/vms/modal/vms-modal.component";
import { VMSModalDigiComponent } from "./layout/digital-vms/modal/vms-modal.component";
import { IMSModalComponent } from "./layout/ims-service-requests/modal/imsmodal/imsmodal.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

import { AddUsersModalComponent } from "./layout/service-requests/sanitization/manage-teams/add-users-modal/add-users-modal.component";
import { AddTeamComponent } from "./layout/service-requests/sanitization/manage-teams/add-team/add-team.component";
import { SanitizationService } from "./layout/service-requests/sanitization/services/sanitization.service";
import { GeneralComponent } from "./layout/asset-management/modal/asset-modal-component/general/general.component";
import { FinanceComponent } from "./layout/asset-management/modal/asset-modal-component/finance/finance.component";
import { AttachmentsComponent } from "./layout/asset-management/modal/asset-modal-component/attachments/attachments.component";
import { ActivityLogsComponent } from "./layout/asset-management/modal/asset-modal-component/activity-logs/activity-logs.component";
import { ServiceComponent } from "./layout/asset-management/modal/asset-modal-component/service/service.component";
import { EmergencyModalComponent } from "./layout/emergency/modal/emergency-modal.component";

import { SettingsDialogComponent } from "./layout/asset-management/modal/settings-dialog/settings-dialog.component";
import { CustomersModalComponent } from "./layout/customers/modal/customers-modal.component";
import { TMSModalComponent } from "./layout/customers/modal/tms/tms-modal.component";
import { PEOPLECOUNTModalComponent } from "./layout/sm-people-count/modal/peoplecount-modal.component";
import { AssessmentModalComponent } from "./layout/self-assessment/modal/assessment-modal.component";
import { DemandSupplyModalComponent } from "./layout/demand-supply/demand-supply-modal/demand-supply-modal.component";
import { DemandRequestModalComponent } from "./layout/demand-supply/demand-request/demand-request-modal/demand-request-modal.component";
import { CreateMoveRequestComponent } from "./layout/demand-supply/demand-request/demand-request-modal/create-move-request/create-move-request.component";
import { CreateSpaceRequestComponent } from "./layout/demand-supply/demand-request/demand-request-modal/create-space-request/create-space-request.component";
import { ModalManageComponent } from "./layout/demand-supply/demand-request/demand-request-modal/create-move-request/modal-manage/modal-manage.component";
import { RelocationModalComponent } from "./layout/demand-supply/demand-supply-relocation/modal/relocation-modal/relocation-modal.component";

import { DeleteTagModalComponent } from "./layout/amenities/components/onboard-locations/delete-tag-modal/delete-tag-modal.component";
import { FaqModalComponent } from "./layout/faqs/faq-modal/faq-modal.component";
import { GlobalSetupModalComponent } from "./layout/global-setup/global-setup-modal/global-setup-modal.component";
import { ApiService } from "./layout/global-setup/services/api.service";
import { BookingProModalComponent } from "./layout/booking-pro/booking-pro-modal/booking-pro-modal.component";
import { SubscriptionModalComponent } from "./layout/subscriptions/subscription-modal/subscription-modal.component";
import { DashboardModalComponent } from "./layout/digital-dashboard/dashboard-modal/dashboard-modal.component";
import { DigitalWorkStatusModalComponent } from "./layout/workStatus/modal/digitalworkstatus-modal.component";
import { DigitalDashboardIntroModalComponent } from "./layout/digital-dashboard/digital-dashboard-intro-modal/digital-dashboard-intro-modal.component";
import { RequestsComponent } from "./layout/digital-dashboard/dashboard-modal/requests/requests.component";
import { ReuseableSearchFilterComponent } from "./layout/digital-dashboard/dashboard-modal/reuseable-search-filter/reuseable-search-filter.component";
import { ColleaguesComponent } from "./layout/digital-dashboard/dashboard-modal/colleagues/colleagues.component";
import { FilteredColleaguesModalComponent } from "./layout/digital-dashboard/dashboard-modal/filtered-colleagues-modal/filtered-colleagues-modal.component";
import { SearchLocationReuseableModalComponent } from "./layout/digital-dashboard/dashboard-modal/search-location-reuseable-modal/search-location-reuseable-modal.component";
import { WeeklyComponent } from "./layout/digital-dashboard/digital-main-component/reusable-digital-dashboard/weekly/weekly.component";
import { WeeklyModule } from "./layout/digital-dashboard/digital-main-component/reusable-digital-dashboard/weekly/weekly.module";

import { AmenityBookingService } from "./layout/amenity-booking/services/amenity-booking.service";
import { AmenityBookingModalComponent } from "./layout/amenity-booking/modal/amenity-booking-modal.component";
import { AmenityBookingModel2Component } from "./layout/amenity-booking/modal/amenity-booking-model2/amenity-booking-model2.component";
import { IntegrationPartnersModalComponent } from "./layout/integration/modal/integration-partners-modal/integration-partners-modal.component";
import { IntegrationManageModalComponent } from "./layout/integration/modal/integration-manage-modal/integration-manage-modal.component";
import { DigitalBuildingsModalComponent } from "./layout/digital-buildings/digital-buildings-modal/digital-buildings-modal.component";
import { DigitalBuildingsService } from "./layout/digital-buildings/services/digital-buildings.services";
import { IntegrationManageModalGlobalAdminComponent } from "./layout/integration-globaladmin/modal/integration-manage-modal/integration-manage-modal.component";
import { IntegrationPartnersModalGlobalAdminComponent } from "./layout/integration-globaladmin/modal/integration-partners-modal/integration-partners-modal.component";
import { IntegrationManageSuperAdminModalComponent } from "./layout/integration-superadmin/modal/integration-manage-modal/integration-manage-modal.component";
import { IntegrationPartnersSuperAdminModalComponent } from "./layout/integration-superadmin/modal/integration-partners-modal/integration-partners-modal.component";
import { AlertConfirmationDialogComponent } from "./shared/alert-confirmation-dialog/alert-confirmation-dialog.component";
import { CuratedServicesModalComponent } from "./layout/curated-services/curated-services-modal/curated-services-modal.component";
import { CuratedServicesService } from "./layout/curated-services/curated-services.service";
import { EventModalComponent } from "./layout/events/event-modal/event-modal.component";
import { ColorPickerModule } from "ngx-color-picker";
import { EventCatModalComponent } from "./layout/events-category/modal/events-cat-modal.component";
import { IntroModalModuleActivationComponent } from "./layout/intro-modal-module-activation/intro-modal-module-activation.component";
import { PeopleSuperAdminModalComponent } from "./layout/people-superadmin/people-super-admin-modal/people-super-admin-modal.component";
import { tenantModal } from "./layout/global-setup/tenant/modal/tenant-modal.component";
import { EventCatService } from "./layout/global-setup/tenant/service/events-cat.service";
import { peopleTenantAdminModal } from "./layout/people-superadmin/modal/people-tenant-admin-modal.component";
import { PeopleTenantAdminService } from "./layout/people-superadmin/service/people-tenant-admin.service";
import { TenantPeopleModalComponent } from "./layout/global-setup/People-Tenant/modal/events-cat-modal.component";
import { TenantPeopleService } from "./layout/global-setup/People-Tenant/service/events-cat.service";
import { AnalyticsModalComponent } from "./layout/amenity-booking/analytics/modal/analytics-modal/analytics-modal.component";
import { WayFindingComponent } from "./layout/amenity-booking/modal/way-finding/way-finding.component";
import { ModalComponent } from "./layout/emergency-contact/modal/modal.component";


// AoT requires an exported function for factories
export function HttpTranslateLoader(http: HttpClient) {
  // for development
  // return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-5/master/dist/assets/i18n/', '.json');
  // return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#dd0031",
      secondaryColour: "#006ddd",
      tertiaryColour: "#ffffff",
    }),
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      closeButton: true,
      timeOut: 2000,
      maxOpened: 1,
      autoDismiss: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCardModule,
    MatStepperModule,
    NgxPrintModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatOptionModule,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatChipsModule,
    MatGridListModule,
    // AuthModule,
    // HomeModule,
    // AngularFontAwesomeModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    //MatProgressSpinnerModule,
    MatSlideToggleModule,
    ModalLoaderModule,
    MatSliderModule,
    AppRoutingModule,
    OwlDateTimeModule,
    HighchartsChartModule ,
    OwlNativeDateTimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ImageCropperModule,
    QRCodeModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    FullCalendarModule,
    PersonalModule,
    WeeklyModule,
    ColorPickerModule,
  ],
  declarations: [
    AppComponent,
    ControlMessagesComponent,
    ConfirmationDialogComponent,
    AlertConfirmationDialogComponent,
    SafePipe,
    OnboardingModalComponent,
    LoaderComponent,
    CustomLoaderComponent,
    ManageRequestModalComponent,
    MapActionIconsModalComponent,
    PrintPdfComponent,
    RolesModalComponent,
    HeaderModalComponent,
    LoginModalComponent,
    SelectCheckAllComponent,
    CardModalComponent,
    // CalendarComponent,
    ExpenseModalComponent,
    VMSModalComponent,
    VMSModalDigiComponent,
    EventModalComponent,
    EventCatModalComponent,
    IMSModalComponent,
    AddTeamComponent,
    AddUsersModalComponent,
    AssetModalComponent,
    GeneralComponent,
    FinanceComponent,
    ServiceComponent,
    AttachmentsComponent,
    ActivityLogsComponent,
    EmergencyModalComponent,
    SettingsDialogComponent,
    CustomersModalComponent,
    TMSModalComponent,
    PEOPLECOUNTModalComponent,
    AssessmentModalComponent,
    DemandSupplyModalComponent,
    DemandRequestModalComponent,
    CreateSpaceRequestComponent,
    CreateMoveRequestComponent,
    ModalManageComponent,
    RelocationModalComponent,
    DeleteTagModalComponent,
    ManageAmenitiesModalComponent,
    AmenityBookingModalComponent,
    AmenityBookingModel2Component,
    DemandSupplyModalComponent,
    DemandRequestModalComponent,
    CreateSpaceRequestComponent,
    CreateMoveRequestComponent,
    ModalManageComponent,
    RelocationModalComponent,
    FaqModalComponent,
    selectorvalidator,
    GlobalSetupModalComponent,
    tenantModal,
    TenantPeopleModalComponent,
    peopleTenantAdminModal,
    DigitalBuildingsModalComponent,
    BookingProModalComponent,
    CuratedServicesModalComponent,
    SubscriptionModalComponent,
    SubHeadingComponent,
    DashboardModalComponent,
    DigitalWorkStatusModalComponent,
    DigitalDashboardIntroModalComponent,
    RequestsComponent,
    MySchedulesComponent,
    ColleaguesComponent,
    ReuseableSearchFilterComponent,
    FilteredColleaguesModalComponent,
    SearchLocationReuseableModalComponent,
    IntegrationManageModalComponent,
    IntegrationPartnersModalComponent,
    IntegrationManageModalGlobalAdminComponent,
    IntegrationPartnersModalGlobalAdminComponent,
    IntegrationManageSuperAdminModalComponent,
    IntegrationPartnersSuperAdminModalComponent,
    PeopleSuperAdminModalComponent,
    IntroModalModuleActivationComponent,
    AnalyticsModalComponent,
    WayFindingComponent,
    ModalComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: "BASE_URL", useFactory: getBaseUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthGuard,
    ModalRequestServiceService,
    LoginRoutingModule,
    AccountVerifyRoutingModule,
    TenantVerifyRoutingModule,
    ForgotPasswordRoutingModule,
    ForgotPasswordSuccessRoutingModule,
    LoginService,
    DataService,
    HomeService,
    EventCatService,
    PeopleTenantAdminService,
    QrcodeService,
    AlertService,
    CommonService,
    DigitalService,
    ShareddataService,
    LeafletService,
    OnboardingService,
    RolesandresponsibilitiesService,
    EMSService,
    TMSService,
    ModuleidService,
    Store,
    LoaderService,
    CustomLoaderService,
    TenantPeopleService,
    SocialDistancingService,
    ManageRequestsService,
    PeopleService,
    DashboardService,
    WorkStatusService,
    NotfoundRoutingModule,
    MapActionIconsService,
    PrientPdfService,
    HeaderService,
    ExpenseService,
    SanitizationService,
    AmenityBookingService,
    ApiService,
    DigitalBuildingsService,
    TabNavigationService,
    CuratedServicesService,
    DatePipe
  ],
  entryComponents: [
    OnboardingModalComponent,
    RolesModalComponent,
    ConfirmationDialogComponent,
    AlertConfirmationDialogComponent,
    ManageRequestModalComponent,
    MapActionIconsModalComponent,
    PrintPdfComponent,
    HeaderModalComponent,
    LoginModalComponent,
    CardModalComponent,
    ExpenseModalComponent,
    VMSModalComponent,
    VMSModalDigiComponent,
    EventModalComponent,
    EventCatModalComponent,
    IMSModalComponent,
    AddTeamComponent,
    AddUsersModalComponent,
    AssetModalComponent,
    EmergencyModalComponent,
    SettingsDialogComponent,
    CustomersModalComponent,
    TMSModalComponent,
    PEOPLECOUNTModalComponent,
    AssessmentModalComponent,
    DemandSupplyModalComponent,
    DemandRequestModalComponent,
    RelocationModalComponent,
    DeleteTagModalComponent,
    ManageAmenitiesModalComponent,
    AmenityBookingModalComponent,
    IntegrationManageModalComponent,
    AmenityBookingModel2Component,
    FaqModalComponent,
    GlobalSetupModalComponent,
    tenantModal,
    TenantPeopleModalComponent,
    peopleTenantAdminModal,
    DigitalBuildingsModalComponent,
    BookingProModalComponent,
    CuratedServicesModalComponent,
    SubscriptionModalComponent,
    DashboardModalComponent,
    DigitalWorkStatusModalComponent,
    DigitalDashboardIntroModalComponent,
    ColleaguesComponent,
    ReuseableSearchFilterComponent,
    FilteredColleaguesModalComponent,
    SearchLocationReuseableModalComponent,
    WeeklyComponent,
    PersonalComponent,
    IntegrationPartnersModalComponent,
    IntegrationManageModalGlobalAdminComponent,
    IntegrationPartnersModalGlobalAdminComponent,
    IntegrationManageSuperAdminModalComponent,
    IntegrationPartnersSuperAdminModalComponent,
    PeopleSuperAdminModalComponent,
    IntroModalModuleActivationComponent,
    AnalyticsModalComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function getBaseUrl() {
  return environment.BASE_URL_SPACE;
}
