<div>
    <!-- <app-loader></app-loader> -->
    <div class="" *ngIf="pageType == 'addWorkStation' || pageType == 'editWorkStation'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ wtitle }} {{ "workstation" | translate }}
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmitWorkStation()">
                <div class="form-group">
                    <label class="float-left">{{
                        "workStationName" | translate
                        }}</label>
                    <input id="workstation_name" type="text" formControlName="workstation" class="form-control"
                        [ngClass]="{
                            'is-invalid': submitted && f.workstation.errors
                        }" />
                    <div *ngIf="submitted && f.workstation.errors" class="invalid-feedback">
                        <div *ngIf="f.workstation.errors.required">
                            {{ "pleaseEnterWorkstation" | translate }}
                        </div>
                        <div *ngIf="f.workstation.errors.maxlength">
                            {{ "maxLengthError" | translate }}
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ wtitle }}
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>
    <div class="" *ngIf="pageType == 'editBuildingName'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ "building" | translate }}
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400">
            <form [formGroup]="registerForm" (ngSubmit)="onUpdateBuilding()">
                <div class="form-group">
                    <label class="float-left">{{
                        "buildingName" | translate
                        }}</label>
                    <input id="building_name" type="text" formControlName="buildingName" class="form-control" [ngClass]="{
                            'is-invalid': submitted && f.buildingName.errors
                        }" />
                    <div *ngIf="submitted && f.buildingName.errors" class="invalid-feedback">
                        <div *ngIf="f.buildingName.errors.required" class="text-left">
                            {{ "pleaseEnterBuilding" | translate }}
                        </div>
                        <div *ngIf="f.buildingName.errors.maxlength">
                            {{ "maxLengthError" | translate }}
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ wtitle }}
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <div class="" *ngIf="pageType == 'addDepartment' || pageType == 'editDepartment'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ wtitle }} {{ "department" | translate }}
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmitDepartment()">
                <div class="form-group">
                    <label class="float-left">{{
                        "departmentName" | translate
                        }}</label>
                    <input id="department_name" type="text" formControlName="department" class="form-control" [ngClass]="{
                            'is-invalid': submitted && f.department.errors
                        }" />
                    <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                        <div *ngIf="f.department.errors.required">
                            {{ "pleaseEnterDepartment" | translate }}
                        </div>
                        <div *ngIf="f.department.errors.maxlength">
                            {{ "maxLengthError" | translate }}
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ wtitle }}
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>




    <div class="" *ngIf="pageType == 'editAllocation'">
        <div class="modal-header">
            <h4 *ngIf="stagestatus == 'Edit Allocation Popup'" class="modal-title">
                {{ "editstatus" | translate }}
            </h4>
            <h4 *ngIf="stagestatus == 'Edit Allocation Success'" class="modal-title">
                {{ "thankyou" | translate }}
            </h4>

            <button id="close_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row p-0 m-0">
                <div *ngIf="stagestatus == 'Edit Allocation Popup'" class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label for="inputEmail4" class="text-uppercase">{{
                        "status" | translate
                        }}</label>
                    <div class="input-group">
                        <mat-form-field appearance="fill">
                            <mat-select id="edit_allocation" name="status"
                                [(ngModel)]="editAllocationData.selectedStatus" placeholder="{{'select' | translate}}">
                                <mat-option *ngFor="let sc of editAllocationData.status" [value]="sc.name">
                                    {{ sc.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <select id="edit_allocation"
                            class="form-control"
                            [(ngModel)]="editAllocationData.selectedStatus"
                            name="status"
                        >
                            <option
                                *ngFor="let sc of editAllocationData.status"
                                [ngValue]="sc.name"
                                >{{ sc.name }}</option
                            >
                        </select> -->
                    </div>
                </div>
                <div *ngIf="stagestatus == 'Edit Allocation Popup'" class="form-group col-lg-12 col-md-12 col-sm-12">
                    <p style="margin-top: 10px; margin-bottom: 20px;">
                        {{'autoAllocateNewWorkStation' | translate}}
                        <b>{{ editAllocationData.allocation.user.name }}</b>
                    </p>
                </div>
                <div *ngIf="stagestatus == 'Edit Allocation Success'" class="form-group col-lg-12 col-md-12 col-sm-12">
                    <p style="margin-top: 10px; margin-bottom: 20px;">
                        {{'belowWorkstationHasAllocatedFor' | translate}}
                        <b>{{ editAllocationData.allocation.user.name }}</b>
                    </p>

                    <b>{{'workstation' | translate}}:</b> {{ newworkstation }} <br /><br />
                    <b>{{'location' | translate}}:</b> {{ newworkstationlocation }}
                </div>

                <div *ngIf="stagestatus == 'Edit Allocation Failure'" class="form-group col-lg-12 col-md-12 col-sm-12">
                    <p style="margin-top: 10px; margin-bottom: 20px;">
                        <b>{{ editAllocationData.allocation.user.name }}</b>
                        {{'permanentSeatNotAvailable' | translate}}
                    </p>
                </div>

                <div *ngIf="stagestatus == 'Edit Allocation Popup'" class="text-center w-100">
                    <button id="status_change" class="btn btn-primary btn-main btnSmall mr-1 pull-right"
                        (click)="onStatusChange()">
                        {{ "confirm" | translate }}
                    </button>
                    <button id="close_modal_view" type="button" class="btn btn-outline-primary mr-1 pull-left"
                        style="border: 1px solid #adadad;" (click)="closePopup()">
                        {{ "cancel" | translate }}
                    </button>
                    <!-- <button
                class="btn btn-primary btn-main btnSmall mr-1 pull-left"
                (click)="closePopup()"
            >
                {{ "cancel" | translate }}
            </button> -->
                </div>

                <div *ngIf="stagestatus == 'Edit Allocation Success'" class="text-center w-100">
                    <button id="view_map" class="btn btn-primary btn-main btnBig mr-1 pull-left" (click)="onViewMap()">
                        {{ "viewonmap" | translate }}
                    </button>
                    <button id="view_booking" class="btn btn-primary btn-main btnBig mr-1 pull-right"
                        (click)="onViewBooking()">
                        {{ "viewbooking" | translate }}
                    </button>
                </div>

                <div *ngIf="stagestatus == 'Edit Allocation Failure'" class="text-center w-100">
                    <button type="button" class="btn btn-outline-primary mr-1 pull-left"
                        style="border: 1px solid #adadad;" (click)="onViewMap()">
                        {{ "cancel" | translate }}
                    </button>
                    <!-- <button
                class="btn btn-primary btn-main btnBig mr-1 pull-left"
                (click)="onViewMap()"
            >
                {{ "cancel" | translate }}
            </button> -->
                    <button id="manual_booking" class="btn btn-primary btn-main btnBig mr-1 pull-right"
                        (click)="onManualBooking()">
                        {{ "manuallyallocate" | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <div *ngIf="pageType == 'addBuilding'">
        <div class="modal-header">
            <h4 class="modal-title">{{ "addBuilding" | translate }}</h4>
            <button id="close_popup" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400">
            <form id="building_form" [formGroup]="buildingForm" (ngSubmit)="onSubmitBuilding()">
                <div class="form-group">
                    <label class="float-left">{{ "buildingName" | translate }}</label>
                    <input id="building_name" type="text" formControlName="buildingName" class="form-control" [ngClass]="{
                            'is-invalid': submitted && b.buildingName.errors
                        }" />
                    <div class="invalid-feedback" *ngIf="buildingError">
                        <div>
                            {{ "buildingNameIsRequired" | translate }}
                        </div>
                    </div>
                    <div *ngIf="submitted && b.buildingName.errors" class="invalid-feedback">
                        <div *ngIf="b.buildingName.errors.maxlength">
                            {{ "buildingLengthError" | translate }}
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <button id="add_building" class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ "add" | translate }}
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <div *ngIf="pageType == 'allocationMap'">
        <div class="modal-header">
            <h4 class="modal-title">{{ "allocationMap" | translate }}</h4>
            <button id="close_map_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div id="map" class="groove" style="height: 480px;"></div>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <div *ngIf="pageType == 'viewFloor'">
        <div class="modal-header">
            <h5 class="modal-title">{{ "viewFloor" | translate }}</h5>
            <button id="close_floor_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div id="map"></div>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <div *ngIf="pageType == 'showUploadIssues'">
        <div class="modal-header">
            <h5 class="modal-title">{{ "uploadIssues" | translate }}</h5>
            <button id="close_upload_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body  scrollerStyle"  >
            <ng-container *ngFor="let item of invalidEntires; let i = index">
                <p>
                    {{'record' | translate}} {{ item.value.slNo }} {{'failed' | translate}}<br/>
                    <strong>{{'reason' | translate}}: {{ item.reason }}</strong>  
                </p>
            </ng-container>
        </div>
        <!-- <div class="modal-footer border-0 m-2"></div> -->
    </div>

    <div class="" *ngIf="pageType == 'addAssests' || pageType == 'editAssests'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ wtitle }} {{ "assests" | translate }}
            </h4>
            <button id="close_assests_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="registerForm" id="add_assests" (ngSubmit)="onSubmitAssests()">
                <div class="form-group">
                    <label class="text-uppercase">{{
                        "assestsName" | translate
                        }}</label>
                    <input id="assests_name" type="text" formControlName="assests" class="form-control" [ngClass]="{
                            'is-invalid': submitted && f.assests.errors
                        }" />
                    <div *ngIf="submitted && f.assests.errors" class="invalid-feedback">
                        <div *ngIf="f.assests.errors.required">
                            {{ "pleaseEnterAssests" | translate }}
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button id="title_btn" class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ wtitle }}
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <div class="" *ngIf="pageType == 'showAssestsDesks'">
        <div class="modal-header">
            <h4 class="modal-title">
                {{'assestsDesk' | translate}}
            </h4>
            <button id="close_assests_desk_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="text-center mt-5">
                    <div class="row">
                        <div class="col-md-6">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <input id="master_checked" type="checkbox" [(ngModel)]="masterSelected"
                                        name="list_name" value="m1" (change)="checkUncheckAll()" />
                                    <strong>{{'selectOrUnselectAll' | translate}}</strong>
                                </li>
                            </ul>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let item of checklist;let i=index">
                                    <input id="check_all_i" type="checkbox" [(ngModel)]="item.isSelected"
                                        name="list_name" value="{{ item.id }}" (change)="isAllSelected()" />
                                    {{ item.value }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <code>{{ checkedList }}</code>
                        </div>
                        <button id="save_btn" class="btn btn-primary btn-main">{{'save' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>
    <div *ngIf="pageType == 'addPeople'">
        <div class="modal-header">
            <h4 class="modal-title">{{ "addPeople" | translate }}</h4>
            <button id="close_popup" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-justify">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <form id="add_user_form" class="form-group" [formGroup]="addUserForm"
                        (ngSubmit)="onSubmitAddUsers()">
                        <div class="form-row">
                            <div class="form-group col-lg-6 col-md-6 col-sm-6">
                                <label class="label-justify">{{
                                    "firstName" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <input id="first_name" type="text" formControlName="firstName" class="form-control"
                                        [ngClass]="{
                                        'is-invalid': addsubmitted && t.firstName.errors
                                    }" />
                                    <div *ngIf="addsubmitted && t.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="t.firstName.errors.required">
                                            {{ "pleaseenterfirstname" | translate }}
                                        </div>
                                        <div *ngIf="t.firstName.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-md-6 col-sm-6">
                                <label class="label-justify">{{
                                    "lastName" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <input type="text" formControlName="lastName" class="form-control" [ngClass]="{
                                    'is-invalid': addsubmitted && t.lastName.errors
                                }" />

                                    <div *ngIf="addsubmitted && t.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="t.lastName.errors.required">
                                            {{ "pleaseenterlastname" | translate }}
                                        </div>
                                        <div *ngIf="t.lastName.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-5 col-md-5 col-sm-5">
                                <label class="label-justify">{{
                                    "email" | translate
                                    }}</label>
                                <div class="input-group">
                                    <input type="text" formControlName="email" class="form-control" [ngClass]="{
                                    'is-invalid': addsubmitted && t.email.errors
                                }" />
                                    <div *ngIf="addsubmitted && t.email.errors" class="invalid-feedback">
                                        <div *ngIf="t.email.errors.required">
                                            {{ "pleaseenteremail" | translate }}
                                        </div>
                                        <div *ngIf="t.email.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-md-4 col-sm-4">
                                <label class="label-justify">{{
                                    "department" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="department" [(ngModel)]="department_name"
                                            placeholder="{{'select' | translate}}" formControlName="Department"
                                            [ngClass]="{
                                    'is-invalid': addsubmitted && t.Department.errors}">
                                            <mat-option *ngFor="let list of departmentList" [value]="list.id">
                                                {{list.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <select id="department" [(ngModel)]="department_name"
                                formControlName="Department"
                                class="form-control"
                                [ngClass]="{
                                    'is-invalid': addsubmitted && t.Department.errors
                                }">
                            <option value="null" disabled>Select</option>
                            <option *ngFor="let list of departmentList;" [value]="list.id">{{list.name}}</option>
                            </select>   -->

                                    <div *ngIf="addsubmitted && t.Department.errors" class="invalid-feedback">
                                        <div *ngIf="t.Department.errors.required">
                                            {{ "pleaseenterdepartment" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-md-3 col-sm-3">
                                <label class="">{{
                                    "employeeCode" | translate
                                    }}</label>
                                <div class="input-group">
                                    <input id="empCode" formControlName="employeeCode" class="form-control" [ngClass]="{
                                    'is-invalid': addsubmitted && t.employeeCode.errors
                                }" />
                                    <div *ngIf="addsubmitted && t.employeeCode.errors" class="invalid-feedback">
                                        <div *ngIf="t.tenant.errors.required">
                                            {{ "pleaseenteremployeecode" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-md-4 col-sm-4">
                                <label class="">{{
                                    "grade" | translate
                                    }}</label>
                                <div class="input-group">
                                    <input id="grade" formControlName="grade" class="form-control" [ngClass]="{
                                    'is-invalid': addsubmitted && t.grade.errors
                                }" />
                                    <div *ngIf="addsubmitted && t.grade.errors" class="invalid-feedback">
                                        <div *ngIf="t.tenant.errors.required">
                                            {{ "pleaseentergrade" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-lg-3 col-md-4 col-sm-4">
                                <label class="label-justify">{{
                                    "countryCode" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="country_code" [(ngModel)]="country_code"
                                            placeholder="{{'select' | translate}}" formControlName="countryCode"
                                            name="countryCode" [ngClass]="{
                                    'is-invalid': addsubmitted && t.countryCode.errors}">
                                            <mat-option *ngFor="let countryCode of countryCodeList"
                                                [value]="countryCode.countryCode">
                                                {{ countryCode.countryCode }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <select id="country_code" [(ngModel)]="country_code" formControlName="countryCode" class="form-control" name="countryCode" 
                                [ngClass]="{
                                    'is-invalid': addsubmitted && t.countryCode.errors
                                }">
                                <option value="null" disabled>Select</option>
                                <option *ngFor="let countryCode of countryCodeList" [value]="countryCode.countryCode"> {{ countryCode.countryCode }}</option>
                                </select>  -->
                                    <div *ngIf="addsubmitted && t.countryCode.errors" class="invalid-feedback">
                                        <div *ngIf="t.countryCode.errors.required">
                                            {{ "pleaseentercountrycode" | translate }}
                                        </div>
                                        <div *ngIf="t.countryCode.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-md-4 col-sm-4">
                                <label class="label-justify">{{
                                    "phoneNumber" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <input id="phone_number" (keypress)="keyPress($event)" formControlName="phoneNumber"
                                        class="form-control" [ngClass]="{
                                    'is-invalid': addsubmitted && t.phoneNumber.errors
                                }" />
                                    <div *ngIf="addsubmitted && t.phoneNumber.errors" class="invalid-feedback">
                                        <div *ngIf="t.phoneNumber.errors.required">
                                            {{ "pleaseenterphonenumber" | translate }}
                                        </div>
                                        <div *ngIf="t.phoneNumber.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-md-4 col-sm-4">
                                <label class="">{{
                                    "tenant" | translate
                                    }}</label>
                                <div class="input-group">
                                    
                                    <mat-form-field appearance="fill">
                                        <mat-select id="tenant" placeholder="{{'select' | translate}}"
                                            (click)="selectedTenant()"
                                            formControlName="tenant" [ngClass]="{
                                    'is-invalid': addsubmitted && t.tenant.errors}">
                                            <mat-option *ngFor="let tenant of tenantList" [value]="tenant.id">
                                                {{tenant.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <select id="tenant" class="form-control" formControlName="tenant" [ngClass]="{
                                'is-invalid': addsubmitted && t.tenant.errors}">
                                <option value="null" disabled>Select</option>
                                <option [value]="tenant.id" *ngFor="let tenant of tenantList">{{tenant.name}}</option>
                            </select>   -->
                                    <div *ngIf="addsubmitted && t.tenant.errors" class="invalid-feedback">
                                        <div *ngIf="t.tenant.errors.required">
                                            {{ "pleaseentertenant" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12"></div>
                            <div class="form-group col-lg-3 col-md-3 col-sm-3" *ngIf=isaddPeople>
                                <label class="label-justify">{{
                                    "shiftStartTime" | translate
                                    }}*</label>
                                <ngb-timepicker formControlName="StartTime" [(ngModel)]="starttime" [ngClass]="{
                                'is-invalid': addsubmitted && t.StartTime.errors
                            }"></ngb-timepicker>
                                <!-- <input type="text"
                                formControlName="StartTime"
                                class="form-control"
                                [ngClass]="{
                                    'is-invalid': addsubmitted && t.StartTime.errors
                                }"/>  -->
                                <div *ngIf="addsubmitted && t.StartTime.errors" class="invalid-feedback">
                                    <div *ngIf="t.StartTime.errors.required">
                                        {{ "pleaseenterstarttime" | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-md-3 col-sm-3" *ngIf=isaddPeople>
                                <label class="label-justify">{{
                                    "shiftEndTime" | translate
                                    }}*</label>
                                <ngb-timepicker formControlName="EndTime" [(ngModel)]="endtime" [ngClass]="{
                                'is-invalid': addsubmitted && t.EndTime.errors
                            }"></ngb-timepicker>
                                <!-- <input type="text"
                                formControlName="EndTime"
                                class="form-control"
                                [ngClass]="{
                                    'is-invalid': addsubmitted && t.EndTime.errors
                                }"/>  -->
                                <div *ngIf="addsubmitted && t.EndTime.errors" class="invalid-feedback">
                                    <div *ngIf="t.EndTime.errors.required">
                                        {{ "pleaseenterendtime" | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-md-3 col-sm-3">
                                <label class="label-justify">{{
                                    "supervisor" | translate
                                    }}</label>
                                <input [(ngModel)]="bookingObj.selectedName" type="text" #searchTerm="ngModel"
                                    class="form-control" name="user-dep" id="user-dep" autocomplete="off"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="{{'searchByEmployee' | translate}}"
                                    (input)="getBookingAutocompleteList(searchTerm.value)" [matAutocomplete]="auto" />
                                <!-- <i class="fa fa-spinner loading_span" *ngIf="bookingIsloading" aria-hidden="true"></i> -->
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let dropData of bookingAutocompleteList$ | async"
                                        [value]="dropData.name.trim()" (click)="onselectBookingData(dropData)">
                                        <span>{{dropData?.name}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                <button type="submit" id="add_btn"
                                    class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                                    {{'add' | translate}}
                                </button>
                                <button type="reset" id="reset_data_btn" (click)="onCloseRoleModal()"
                                    class="btn btn-default btn-main btnSmall mr-1 pull-right">
                                    {{'cancel' | translate}}
                                </button>
                            </div>
                            <!---->
                        </div>
                    </form>
                    <!-- <form id="add_user_form" [formGroup]="addUserForm" (ngSubmit)="onSubmitAddUsers()">
                        <div class="form-group">
                            <label class="float-left">{{ "Username" | translate }}</label>
                            <input id="user_name"
                                type="text"
                                formControlName="userName"
                                class="form-control"
                                [ngClass]="{
                                    'is-invalid': addsubmitted && t.userName.errors
                                }"
                            />
                            <div class="invalid-feedback" *ngIf="buildingError">
                                <div>
                                    {{ "buildingNameIsRequired" | translate }}
                                </div>
                            </div>
                            <div
                                *ngIf="addsubmitted && t.userName.errors"
                                class="invalid-feedback"
                            >
                                <div *ngIf="t.userName.errors.maxlength">
                                    {{ "buildingLengthError" | translate }}
                                </div>
                            </div>
                        </div>
        
                        <div class="text-center">
                            <button id="add_building"
                                class="btn btn-primary btn-main btnSmall mr-1 pull-right"
                            >
                                {{ "add" | translate }}
                            </button>
                        </div>
                    </form> -->
                </div>
            </div>

        </div>
        <!-- <div class="modal-footer border-0 m-2"></div> -->
    </div>
    <div *ngIf="pageType == 'editPeople'">
        <div class="modal-header">
            <h4 class="modal-title">{{ "editPeople" | translate }}</h4>
            <button id="close_popup" type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-justify">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <form id="edit_user_form" class="form-group" [formGroup]="editUserForm"
                        (ngSubmit)="onSubmitEditUsers()">
                        <div class="form-row">
                            <div class="form-group col-lg-6 col-md-6 col-sm-6">
                                <label class="label-justify">{{
                                    "firstName" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <input id="edit_first_name" type="text" formControlName="editfirstName"
                                        class="form-control" [(ngModel)]="user_first_name" [ngClass]="{
                                        'is-invalid': editsubmitted && k.editfirstName.errors
                                    }" />
                                    <div *ngIf="editsubmitted && k.editfirstName.errors" class="invalid-feedback">
                                        <div *ngIf="k.editfirstName.errors.required">
                                            {{ "pleaseenterfirstname" | translate }}
                                        </div>
                                        <div *ngIf="k.editfirstName.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-md-6 col-sm-6">
                                <label class="label-justify">{{
                                    "lastName" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <input id="edit_last_name" type="text" formControlName="editlastName"
                                        [(ngModel)]="user_last_name" class="form-control" [ngClass]="{
                                    'is-invalid': editsubmitted && k.editlastName.errors
                                }" />

                                    <div *ngIf="editsubmitted && k.editlastName.errors" class="invalid-feedback">
                                        <div *ngIf="k.editlastName.errors.required">
                                            {{ "pleaseenterlastname" | translate }}
                                        </div>
                                        <div *ngIf="k.editlastName.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-5 col-md-5 col-sm-5">
                                <label class="label-justify">{{
                                    "email" | translate
                                    }}</label>
                                <div class="input-group">
                                    <input id="edit_email" type="text" formControlName="editemail"
                                        [(ngModel)]="user_email" class="form-control" [ngClass]="{
                                    'is-invalid': editsubmitted && k.editemail.errors
                                }" readonly />
                                    <div *ngIf="editsubmitted && k.editemail.errors" class="invalid-feedback">
                                        <div *ngIf="k.editemail.errors.required">
                                            {{ "pleaseenteremail" | translate }}
                                        </div>
                                        <div *ngIf="k.editemail.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-md-4 col-sm-4">
                                <label class="label-justify">{{
                                    "department" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="edit_department" [(ngModel)]="edit_department_name"
                                            placeholder="{{'select' | translate}}" formControlName="editDepartment"
                                            [ngClass]="{
                                    'is-invalid': editsubmitted && k.editDepartment.errors}">
                                            <mat-option *ngFor="let list of departmentList" [value]="list.id">
                                                {{list.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="editsubmitted && k.editDepartment.errors" class="invalid-feedback">
                                        <div *ngIf="k.editDepartment.errors.required">
                                            {{ "pleaseenterdepartment" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-md-3 col-sm-3">
                                <label class="">{{
                                    "employeeCode" | translate
                                    }}</label>
                                <div class="input-group">
                                    <input id="edit_empCode" [(ngModel)]="user_emp_code"
                                        formControlName="editemployeeCode" class="form-control" [ngClass]="{
                                    'is-invalid': editsubmitted && k.editemployeeCode.errors
                                }" />
                                    <div *ngIf="editsubmitted && k.editemployeeCode.errors" class="invalid-feedback">
                                        <div *ngIf="k.editemployeeCode.errors.required">
                                            {{ "pleaseenteremployeecode" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group col-lg-3 col-md-4 col-sm-4">
                            <label class="">{{
                                "grade" | translate
                            }}</label>
                            <div class="input-group">
                            <input id="edit_grade" [(ngModel)]="user_grade"
                                formControlName="editgrade"
                                class="form-control"
                                [ngClass]="{
                                    'is-invalid': editsubmitted && k.grade.errors
                                }"/> 
                            <div
                                *ngIf="editsubmitted && k.grade.errors"
                                class="invalid-feedback"
                            >
                                <div *ngIf="k.tenant.errors.required">
                                    {{ "pleaseentergrade" | translate }}
                                </div>
                            </div>
                        </div>
                        </div> -->

                            <div class="form-group col-lg-3 col-md-4 col-sm-4">
                                <label class="label-justify">{{
                                    "countryCode" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="edit_country_code" [(ngModel)]="edit_country_code"
                                            placeholder="{{'select' | translate}}" formControlName="editcountryCode"
                                            name="countryCode" [ngClass]="{
                                    'is-invalid': editsubmitted && k.editcountryCode.errors}">
                                            <mat-option *ngFor="let countryCode of countryCodeList"
                                                [value]="countryCode.countryCode">
                                                {{ countryCode.countryCode }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="editsubmitted && k.editcountryCode.errors" class="invalid-feedback">
                                        <div *ngIf="k.editcountryCode.errors.required">
                                            {{ "pleaseentercountrycode" | translate }}
                                        </div>
                                        <div *ngIf="k.editcountryCode.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-md-4 col-sm-4">
                                <label class="label-justify">{{
                                    "phoneNumber" | translate
                                    }}*</label>
                                <div class="input-group">
                                    <input id="edit_phone_number" (keypress)="keyPress($event)"
                                        formControlName="editphoneNumber" [(ngModel)]="user_phone_number"
                                        class="form-control" [ngClass]="{
                                    'is-invalid': editsubmitted && k.editphoneNumber.errors
                                }" />
                                    <div *ngIf="editsubmitted && k.editphoneNumber.errors" class="invalid-feedback">
                                        <div *ngIf="k.editphoneNumber.errors.required">
                                            {{ "pleaseenterphonenumber" | translate }}
                                        </div>
                                        <div *ngIf="k.editphoneNumber.errors.maxlength">
                                            {{ "maxLengthError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-5 col-md-4 col-sm-4">
                                <label class="">{{
                                    "tenant" | translate
                                    }}</label>
                                <div class="input-group">
                                    <mat-form-field appearance="fill">
                                        <mat-select id="edit_tenant" [(ngModel)]="user_tenant"
                                            placeholder="{{'select' | translate}}" formControlName="edittenant"
                                            (click)="selectedTenant()"
                                            (selectionChange)="checkTenantUserSelection()"
                                            [ngClass]="{
                                    'is-invalid': editsubmitted && k.edittenant.errors}"
                                            >
                                            <mat-option *ngFor="let tenant of tenantList" [value]="tenant.id">
                                                {{tenant.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <ng-container *ngIf="tenantList.length>0 && showInfo">
                                        <i class="fa fa-info infoErr" *ngIf="!checkTenantUser()" title="Tenant got disabled"></i>
                                    </ng-container>

                                    <div *ngIf="editsubmitted && k.edittenant.errors" class="invalid-feedback">
                                        <div *ngIf="k.edittenant.errors.required">
                                            {{ "pleaseentertenant" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12"></div>
                            <div class="form-group col-lg-3 col-md-3 col-sm-3" *ngIf=isEditPeople>
                                <label class="label-justify">{{
                                    "shiftStartTime" | translate
                                    }}*</label>
                                <ngb-timepicker id="edit_start_time" formControlName="editStartTime"
                                    [(ngModel)]="editstarttime" [ngClass]="{
                                'is-invalid': editsubmitted && k.editStartTime.errors
                            }"></ngb-timepicker>
                                <div *ngIf="editsubmitted && k.editStartTime.errors" class="invalid-feedback">
                                    <div *ngIf="k.editStartTime.errors.required">
                                        {{ "pleaseenterstarttime" | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-md-3 col-sm-3" *ngIf=isEditPeople>
                                <label class="label-justify">{{
                                    "shiftEndTime" | translate
                                    }}*</label>
                                <ngb-timepicker id="edit_end_time" formControlName="editEndTime"
                                    [(ngModel)]="editendtime" [ngClass]="{
                                'is-invalid': editsubmitted && k.editEndTime.errors
                            }"></ngb-timepicker>
                                <div *ngIf="editsubmitted && k.editEndTime.errors" class="invalid-feedback">
                                    <div *ngIf="k.editEndTime.errors.required">
                                        {{ "pleaseenterendtime" | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-md-3 col-sm-3">
                                <label class="label-justify">{{
                                    "supervisor" | translate
                                    }}</label>
                                <input [(ngModel)]="bookingObj.selectedName" type="text" #searchTerm="ngModel"
                                    class="form-control" name="user-dep" id="user-dep" autocomplete="off"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="{{'searchByEmployee' | translate}}"
                                    (input)="getBookingAutocompleteList(searchTerm.value)" [matAutocomplete]="auto" />
                                <!-- <i class="fa fa-spinner loading_span" *ngIf="bookingIsloading" aria-hidden="true"></i> -->
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let dropData of bookingAutocompleteList$ | async"
                                        [value]="dropData.name.trim()" (click)="onselectBookingData(dropData)">
                                        <span>{{dropData?.name}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                <button type="submit" id="edit_btn"
                                    class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                                    {{'update' | translate}}
                                </button>
                                <button type="reset" id="reset_edit_data_btn" (click)="onCloseRoleModal()"
                                    class="btn btn-default btn-main btnSmall mr-1 pull-right">
                                    {{'cancel' | translate}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
    <div class="" *ngIf="pageType == 'uploadAutoPopup'">
        <div class="modal-header">
            <h6 class="modal-title">
                <strong>{{ "reasonforuploading" | translate }}</strong>
            </h6>
            <button type="button" class="close" aria-label="Close" (click)="closeAutoPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: left !important;">
            <form [formGroup]="autoUploadForm" (ngSubmit)="onSubmitAutoUpload()">
                <div>
                    <mat-radio-group class="custom-radio-group" [(ngModel)]="selectedAutoValue"
                        formControlName="autoUpload">
                        <mat-radio-button [value]="1">{{ 'addorremovedesks' | translate }}</mat-radio-button>
                        <div class=" form-row col-sm-12 margin-custom" *ngIf="selectedAutoValue == '1'">
                            <div class="col-1">
                                <img src="../../../assets/images/exclamation.jpg" alt="warning" class="warning-logo" />
                            </div>
                            <div class="col-11">
                                <span class="">{{ 'allbookingsassociated' | translate }}</span>
                            </div>
                        </div>
                        <mat-radio-button [value]="2">{{ 'changingnominclature' | translate }}</mat-radio-button>
                        <div class=" form-row col-sm-12 margin-custom" *ngIf="selectedAutoValue == '2'">
                            <div class="col-1">
                                <img src="../../../assets/images/exclamation.jpg" alt="warning" class="warning-logo" />
                            </div>
                            <div class="col-11">
                                <span class="">{{ 'allbookingsdeleted' | translate }}</span>
                            </div>
                        </div>
                        <mat-radio-button [value]="3">{{ 'both' | translate }}</mat-radio-button>
                        <div class=" form-row col-sm-12 margin-custom" *ngIf="selectedAutoValue == '3'">
                            <div class="col-1">
                                <img src="../../../assets/images/exclamation.jpg" alt="warning" class="warning-logo" />
                            </div>
                            <div class="col-11">
                                <span class="">{{ 'allbookingsmadebothdeleted' | translate }}</span>
                            </div>
                        </div>
                    </mat-radio-group>
                    <!-- <p>{{ 'allbookingsassociated' | translate }}</p>
                        <p>{{ 'allbookingsdeleted' | translate }}</p>
                        <p>{{ 'allbookingsmadebothdeleted' | translate }}</p> -->
                </div>
                <div class="text-center">
                    <button class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                        {{ "proceed" | translate }}
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>

    <div class="" *ngIf="pageType == 'disableUser'">
        <div class="modal-header">
            <h6 class="modal-title">
                <strong>{{ "Disable User" | translate }}</strong>
            </h6>
            <button type="button" class="close" aria-label="Close" (click)="closeAutoPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: left !important;">
            <form [formGroup]="disableUserForm" (ngSubmit)="onSubmitDisableUser()">
                <div>
                    <p class="fontDisable">Do you also want to delete all ongoing and future space bookings (flexible and permanent bookings) for "{{data?.userName}}" ?</p>
                    <div class="ml-4">
                    <mat-radio-group class="custom-radio-group" [(ngModel)]="selectDisableUser"
                        formControlName="disableUsers">
                        <mat-radio-button [value]="1">{{ 'Yes' | translate }}</mat-radio-button>
                     
                        <mat-radio-button [value]="2">{{ 'No' | translate }}</mat-radio-button>
 
                    </mat-radio-group>
                     </div>
                    <!-- <p>{{ 'allbookingsassociated' | translate }}</p>
                        <p>{{ 'allbookingsdeleted' | translate }}</p>
                        <p>{{ 'allbookingsmadebothdeleted' | translate }}</p> -->
                </div>
                <div class="text-center" *ngIf="selectDisableUser!=null">
                    <button class="btn btn-primary btn-main btnDisable mr-1 pull-right">
                        {{ "Disable user" | translate }}
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>
</div>
