import {
    Component,
    Inject,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    OnDestroy,
    NgModule
  } from "@angular/core";
  import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatSnackBar,
    MatInput,
    MatSelect,
    MatOption,
    MatCheckbox,
    MatTableDataSource,
    MatRadioButton,
    MatRadioGroup,
    MatDivider
  } from "@angular/material";
  import {
    FormControl,
    FormGroup,
    FormBuilder,
    FormArray,
    Validators
  } from "@angular/forms";
  import { modulesIds } from "../../../../../environments/environment";
  import { ZoneAndModuleHttpParams } from "../../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
  import "rxjs/add/observable/of";
  import { CommonService } from "../../../../services/common-service.service";
  
  import { ReplaySubject, Subject } from 'rxjs';
  import {debounceTime, delay, tap, filter, map, takeUntil} from 'rxjs/operators';
  import {Constants} from '../../../../../environments/environment'
  import { AlertService } from '../../../../services/alert-service.service';
  import { HeaderService } from "../services/header.service"
import { LoaderService } from "loaderService";
import { HomeService } from "src/app/layout/home/service/home.service";
import { TranslateService } from '@ngx-translate/core';
  
  export interface UserEmail {
    id: string;
    name: string;
  }
  
  export interface Food {
    value: string;
    viewValue: string;
  }
  
  
  @Component({
    selector: "app-header-modal",
    templateUrl: "./header-modal.component.html",
    styleUrls: ["./header-modal.component.scss"]
  })
  export class HeaderModalComponent implements OnInit {
    myControl = new FormControl();
    private currentZone;
    layoutFile:any;
    old_password = '';
    new_password = '';
    confirm_password = '';
    pswDisabled=false;
    selectedZone='';
    searchFilter: string = '';
    globalAdmin = 'false';
    constructor(
      public matSnackBar: MatSnackBar,
      public dialogRef: MatDialogRef<HeaderModalComponent>,
      public dialog: MatDialog,
      public commonService: CommonService,
      private ref: ChangeDetectorRef,
      private alertService : AlertService,
      private headerService : HeaderService,
      private loaderService: LoaderService,
      private homeService: HomeService, private translate: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.globalAdmin = localStorage.getItem("ssadmin_globalAdmin");
    }
  
    ngOnInit() {
      this.currentZone = this.data["currentZone"];
    }
    
    onCloseEventModal = () => {
      if(this.globalAdmin !='true'){
        this.commonService.changeZone(this.currentZone);
      } 
      this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
    };
    
    
    closeViewEventModal = () => {
      this.commonService.changeZone(this.currentZone);
      this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
    }
  
    fileToUpload: File = null;
    handleFileInput(files: FileList) {
      this.fileToUpload = files.item(0);
    }
  
    uploadLogoFile = () => {
      if (this.fileToUpload) {
        this.headerService.postFile(this.currentZone, this.fileToUpload).subscribe(data => {
          // alert("File uploaded successfully!");
          this.commonService.openSnackBar(data["message"], "");
          window.location.reload();
        }, error => {
          this.commonService.openSnackBar("Something went wrong!! Please make sure file is in correct format", '');
        });
      }
      else {
        this.commonService.openSnackBar("No file selected!","");
      }
    }
    changePassword=()=>{
      if(this.old_password == '' && this.new_password == '' && this.confirm_password == ''){
        this.commonService.openSnackBar(this.translate.instant("pleaseEnterAllTheFields"), ""); 
      }
      else if(this.new_password.length < 5 ){
        this.commonService.openSnackBar(this.translate.instant("newPasswordLengthMust"), ""); 
      }else if(this.new_password != this.confirm_password){
        this.commonService.openSnackBar(this.translate.instant("newPasswordAndConfirmPasswordShouldBeSame"), ""); 
      }else{
        this.pswDisabled = true;
        let data = {
          email: localStorage.getItem("ssadmin_user"),
          currentPassword: this.old_password,
          newPassword: this.new_password,
        };
        this.headerService.changePassword(this.currentZone, data).pipe(debounceTime(1000)).subscribe(data => {
          // alert("File uploaded successfully!");
          this.pswDisabled = false;
          this.commonService.openSnackBar(this.translate.instant("passwordChangedSuccessfully"), ""); 
        }, error => {
          this.pswDisabled = false;
          this.commonService.openSnackBar(error.error.message, '');
        });
      }
     
    }
    
    selectedZoneRadio(eve){
      
      
    }
    saveZone(){
      this.loaderService.loadingOn();
      this.homeService.updateZone(this.selectedZone).pipe().subscribe(res => {
     // alert("File uploaded successfully!");
     this.loaderService.loadingOff();
      if(res){
      this.commonService.changeZone(this.selectedZone);
      this.commonService.changeDefaultZone(this.selectedZone);
      this.getTimeZoneValue(this.selectedZone);
        this.dialogRef.close('selectedZone');
      }
    },err=>{
      this.loaderService.loadingOff();
    });}

    get filteredZones() {
      if(this.data.zones){
      return this.data.zones.filter(zone =>
        zone.name.toLowerCase().includes(this.searchFilter.toLowerCase())
      );
    }
  }

  getTimeZoneValue(zone) {
    if (!!zone) {
        this.commonService.getTimeZoneData(zone).subscribe(
            (res) => {
                if (!!res["response"] && !!res["response"]["timezone"]) {
                    let timeZoneByConfig = res["response"]["timezone"];
                    this.commonService.changeTimeZone(timeZoneByConfig);
                }
            },
            (err) => {}
        );
    }
}
  }
  