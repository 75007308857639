/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./colleagues.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../reuseable-search-filter/reuseable-search-filter.component.ngfactory";
import * as i3 from "../reuseable-search-filter/reuseable-search-filter.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../services/common-service.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../shared/modules/loader/loader.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../amenity-booking/services/amenity-booking.service";
import * as i11 from "../../../../services/digital-service.service";
import * as i12 from "ngx-toastr";
import * as i13 from "../../../manage-request/manage-booking/containers/work-status/service/work-status.service";
import * as i14 from "../../services/dashboard.services";
import * as i15 from "../../../../shared/confirmation-dialog/confirmation-dialog.service";
import * as i16 from "@angular/common";
import * as i17 from "./colleagues.component";
var styles_ColleaguesComponent = [i0.styles];
var RenderType_ColleaguesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColleaguesComponent, data: {} });
export { RenderType_ColleaguesComponent as RenderType_ColleaguesComponent };
function View_ColleaguesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "searchColleaguesContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-reuseable-search-filter", [], null, [[null, "notifyParentColleague"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notifyParentColleague" === en)) {
        var pd_0 = (_co.getNotification($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ReuseableSearchFilterComponent_0, i2.RenderType_ReuseableSearchFilterComponent)), i1.ɵdid(3, 4308992, null, 0, i3.ReuseableSearchFilterComponent, [i4.MatDialog, i5.FormBuilder, i6.CommonService, i7.TranslateService, i8.LoaderService, i9.Router, i10.AmenityBookingService, i11.DigitalService, i12.ToastrService, i13.WorkStatusService, i14.DashboardService, i15.ConfirmationDialogService], { colleagueObj: [0, "colleagueObj"] }, { notifyParentColleague: "notifyParentColleague" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colleagueObj; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ColleaguesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "colleaguesContainer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleaguesComponent_1)), i1.ɵdid(2, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSearchColleagues; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ColleaguesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-colleagues", [], null, null, null, View_ColleaguesComponent_0, RenderType_ColleaguesComponent)), i1.ɵdid(1, 114688, null, 0, i17.ColleaguesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColleaguesComponentNgFactory = i1.ɵccf("app-colleagues", i17.ColleaguesComponent, View_ColleaguesComponent_Host_0, { colleagueObj: "colleagueObj" }, { notifyParentColleague: "notifyParentColleague" }, []);
export { ColleaguesComponentNgFactory as ColleaguesComponentNgFactory };
