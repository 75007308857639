<div>
    <div class="rounded-0" *ngIf="pageType == 'choose_booking'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    aria-hidden="true"
                    class="fa fa-angle-left cursor-pointer mr-2 backBtn"
                    (click)="closePopup()"
                ></i>
                {{ "basicSetup" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400">
            <!-- <div class="col-12">
              <div class="text-center">
                  <img src="assets/images/amenity_booking/Layer_2.svg" class="w-35" />
              </div>
          </div> -->
            <div class="col-12">
                <div class="text-center mt-5">
                    <img
                        src="assets/images/amenity_booking/W1.png"
                        class="w-50"
                    />
                </div>
            </div>
            <ng-container *ngIf="!digitalVersion">
                <form (ngSubmit)="onSubmitChoosingMethod()">
                    <div class="form-row mt-5">
                        <div class="form-group col-lg-12 col-md-12 align-left">
                            <p class="font-regular-gray">
                                {{ "enternameofamenity" | translate }}
                            </p>
                            <input
                                type="text"
                                class="form-control newamenityInput"
                                id="amenity_name"
                                placeholder=""
                                name="amenity_name"
                                [(ngModel)]="amenity.amenityName"
                                [ngModelOptions]="{ standalone: true }"
                            />
                            <div *ngIf="amenity_name_error">
                                <span class="errorMessage">{{"pleaseEnterAmenityName" | translate}}</span>
                            </div>
                            <div *ngIf="amenity_name_space_error">
                                <span class="errorMessage">{{"Pleasedeletetheblankspaceatthestartofthename" | translate}}</span>
                            </div>
                            <div *ngIf="amenity_length_error">
                                <span class="errorMessage">{{"Maximum 30 characters allowed!" | translate}}</span>
                            </div>
                        </div>

                        <div class="form-group col-12 text-left mb-0">
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                                type="submit"
                            >
                                {{ "next" | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
    <div
        class=""
        *ngIf="pageType == 'onboardAmenity' || (pageType == 'uploadAmenity' && digitalVersion)"
    >
        <div class="modal-header pl-0">
            <h5>
                <i
                    *ngIf="!digitalVersion"
                    class="fa fa-angle-left cursor-pointer mr-2 backBtn"
                    aria-hidden="true"
                    (click)="backBtnChooseBooking()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400">
            <div class="col-12">
                <div class="text-center">
                    <img
                        src="assets/images/amenity_booking/Layer_2.svg"
                        class="w-35"
                    />
                </div>
            </div>
            <form (ngSubmit)="onSubmitAmenitiesBookable()">
                <div class="form-row">
                    <!-- <div class="form-group col-lg-12 col-md-12 align-left">
                      <p class="exampleFormControl">{{'enternameofamenity' | translate}}</p>
                      <input type="text" class="form-control" id="amenity_name" placeholder="" name="amenity_name"
                          [(ngModel)]="amenity.amenityName" [ngModelOptions]="{standalone: true}">
                  </div> -->
                    <div class="form-group col-lg-9 col-md-9 align-left mb-0">
                        <label class="w-100 mt-3 d-flex">
                            <!-- {{ "uploadapppicture" | translate }} -->
                            <span></span>
                            <div
                                class="form-group px-2"
                                *ngIf="!!appImagepathImage"
                            >
                                <img
                                    style="width: 50px"
                                    [src]="appImagepathImage"
                                />
                            </div>
                            <div class="custom-file">
                                <input
                                    [(ngModel)]="amenity.upload_appUrl"
                                    [ngModelOptions]="{ standalone: true }"
                                    type="file"
                                    class="form-control custom-file-input mt-2"
                                    id="importFile"
                                    (change)="
                                        onChooseFileApp(
                                            $event.target.files,
                                            $event
                                        )
                                    "
                                    aria-describedby="inputGroupFileAddon01"
                                    placeholder="{{
                                        'uploadapppicture' | translate
                                    }}"
                                />
                                <label
                                    class="custom-file-label custom-upload-btn customInput"
                                    for="inputGroupFile01"
                                    #labelImport
                                >
                                    <span
                                        *ngIf="!appFileName"
                                        class="inputPlaceholder"
                                        >{{
                                            "uploadapppicture" | translate
                                        }}</span
                                    >
                                    {{
                                        appFileName.length > 30
                                            ? (appFileName | slice: 0:30) +
                                              "..."
                                            : appFileName
                                    }}
                                </label>
                            </div>
                        </label>
                        <div *ngIf="upload_appUrl">
                            <span class="errorMessage mt-3"
                                >Please upload app picture</span
                            >
                        </div>
                    </div>
                    <div
                        class="form-group col-lg-3 col-md-3 mb-0 hint font-regular-gray"
                    >
                        For app: <br />
                        (1130*496px)
                    </div>
                    <div
                        class="form-group col-lg-12 col-md-12 align-left mb-0 mt-1 font-regular-gray"
                    >
                        <p class="w-80">
                            *
                            {{
                                "thispicturewillappearontheappwhileuserisbookingthisamenity"
                                    | translate
                            }}
                        </p>
                    </div>
                    <div class="form-group col-lg-9 col-md-9 align-left mb-0">
                        <label class="w-100 mt-3 d-flex">
                            <div
                                class="form-group px-2"
                                *ngIf="!!dashboardImagepathImage"
                            >
                                <img
                                    style="width: 50px"
                                    [src]="dashboardImagepathImage"
                                />
                            </div>
                            <div class="custom-file">
                                <input
                                    [(ngModel)]="amenity.upload_dashboardUrl"
                                    [ngModelOptions]="{ standalone: true }"
                                    type="file"
                                    class="form-control custom-file-input mt-2"
                                    id="importFile1"
                                    (change)="
                                        onChooseFileDashboard(
                                            $event.target.files,
                                            $event
                                        )
                                    "
                                    aria-describedby="inputGroupFileAddon02"
                                />
                                <label
                                    class="custom-file-label custom-upload-btn customInput"
                                    for="inputGroupFile02"
                                    #labelImport1
                                >
                                    <span
                                        *ngIf="!dashboardFileName"
                                        class="inputPlaceholder"
                                    >
                                        {{
                                            "uploaddashboardpicture" | translate
                                        }}
                                    </span>
                                    {{
                                        dashboardFileName.length > 30
                                            ? (dashboardFileName
                                                  | slice: 0:30) + "..."
                                            : dashboardFileName
                                    }}
                                </label>
                            </div>
                        </label>
                        <div *ngIf="upload_appDashboardUrl">
                            <span class="errorMessage">{{
                                "uploaddashboardpicture"
                                    | translate
                            }}</span>
                        </div>
                    </div>
                    <div
                        class="form-group col-lg-3 col-md-3 mb-0 hint font-regular-gray"
                    >
                        {{ "fordashboard" | translate }}: <br />
                        (440*225px)
                    </div>
                    <div
                        class="form-group col-lg-12 col-md-12 align-left mt-1 font-regular-gray"
                    >
                        <p class="w-80">
                            *
                            {{
                                "thispicturewillappearonthedashboardfortheadmin"
                                    | translate
                            }}
                        </p>
                    </div>
                    <!-- <div class="form-group align-left">
                        <p class="dark-blue">{{'bookingMedium' | translate}}</p>
                        <mat-radio-group class="display-row booking-medium" [(ngModel)]="bookingMedium"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="pb-0 custom-radio-btn font-regular-gray" value="SINGLE_USER">
                                {{'bookingbyoneuser' | translate}}
                            </mat-radio-button>
                            <mat-radio-button class="pb-0 custom-radio-btn font-regular-gray" value="SINGLE_USER_PARTICIPANT">
                                {{'bookablebysingleuser' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="bookingMedium_error">
                            <span class="errorMessage">Please select booking medium</span>
                        </div>
                    </div> -->
                    <div class="form-group align-left" *ngIf="!digitalVersion">
                        <p class="dark-blue">{{'bookingMedium' | translate}}</p>
                        <mat-radio-group class="display-row booking-medium" [(ngModel)]="bookingMedium"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="pb-0 custom-radio-btn font-regular-gray" value="SINGLE_USER">
                                {{'bookingbyoneuser' | translate}}
                            </mat-radio-button>
                            <!-- <mat-radio-button class="pb-0 custom-radio-btn" value="MULTIPLE_USER">
                                {{'bookablebymultipleuser' | translate}}
                            </mat-radio-button>-->
                            <mat-radio-button class="pb-0 custom-radio-btn font-regular-gray" value="SINGLE_USER_PARTICIPANT">
                                {{'bookablebysingleuser' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="bookingMedium_error">
                            <span class="errorMessage">{{"Pleaseselectbookingmedium" | translate}}</span>
                        </div>
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular ml-2"
                            type="submit"
                        >
                            {{ "next" | translate }}
                        </button>

                        <!-- <button
                            class="btn btn-primary btn-main btn-custom-border btnSmall btn-radius mr-2p float-right font-regular"
                            type="button"
                            (click)="skipUpload()"
                        >
                            {{ "skip" | translate }}
                        </button> -->
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'Basic_aproach'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer mr-2 backBtn"
                    aria-hidden="true"
                    (click)="backBtnOnboardAmenity()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="italic mt-2 openSans pl-2">
                {{ "basicSetup" | translate }}
            </p>
        </div>
        <div class="modal-body width-400 align-left p-2">
            <form (ngSubmit)="nextApproach()">
                <div class="form-row">
                    <div class="form-group align-left col-12 p-0 pl-1">
                        <p class="font-regular-gray" style="margin-bottom: 0.4rem;">{{'chooseApproach' | translate}}
                        </p>
                        <p><span class="sm-font">*{{'wayFindingwillbeenabled' | translate}}</span></p>
                        <!-- <mat-radio-group [(ngModel)]="approach" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="pb-0 custom-radio-btn col-6" value="MAP">
                                {{'map' | translate}}
                            </mat-radio-button>
                            <mat-radio-button class="pb-0 custom-radio-btn col-6" value="LIST">
                                {{'list' | translate}}
                            </mat-radio-button>
                        </mat-radio-group> -->
                    </div>
                    <!-- <div class="col-6 mt-25">
                        <div class="align-left">
                            <img src="assets/images/amenity_booking/Symbol_7.png" class="w-90 ml-12" />
                        </div>

                    </div> -->
                    <div class="col-12 mt-25" style="margin-top: -58px;margin-bottom: -2rem;">

                        <div class="align-center">
                            <img src="assets/images/amenity_booking/Symbol_7.png" class="w-50" />
                        </div>
                    </div>
                    <div class="form-group col-12 text-left mb-0 mt-3">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2 float-right font-regular"
                            type="submit"
                        >
                            {{ "yes" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2 btn-main-outline float-right font-regular"
                            (click)="mapViewValue()"
                            type="reset"
                        >
                            {{ "no" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'globalCategroy'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    *ngIf="!editFlow"
                    class="fa fa-angle-left cursor-pointer mr-2 backBtn"
                    aria-hidden="true"
                    (click)="backToPrevPage('uploadAmenity')"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closeCategoryPopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="saveGlobalCategory()">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <p class="font-regular-gray">
                            {{ "would" | translate }} "{{
                                amenity.amenityName | lowercase
                            }}"
                            {{ "wouldmeetingroomhavecategories" | translate }}?
                            {{ "thisisusefulforauser" | translate }}
                            {{ "thisBasisCharging" | translate }}
                        </p>
                        <p class="font-regular-gray">
                            {{
                                "eg.parkingcanhavetwoorfourwheelerparking"
                                    | translate
                            }}
                        </p>
                        <div class="col-12 mt-4">
                            <div class="text-center">
                                <img
                                    src="assets/images/amenity_booking/Group_13432.svg"
                                    class="w-50"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex ml-0" *ngIf="mainCategoryList.length">
                        <div
                            *ngFor="
                                let option of mainCategoryList;
                                let i = index
                            "
                        >
                            <div class="form-check form-check-inline" [matTooltip]="getToolTipData(option.name)">
                                <mat-checkbox
                                    class="custom-checkbox-btn ml-0 mr-5"
                                    value="{{ option.id }}"
                                    [checked]="option.checked"
                                    (change)="selectGlobalTool(option)"
                                >
                                {{ option?.name | slice: 0:22}} 
                                <span *ngIf="option.name && option.name.length > 12">...</span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex ml-0 font-regular-gray" *ngIf="!mainCategoryList.length">
                        {{ "thereAreCurrentlyNoCategoriesOnboarded" | translate }} <br /> {{ "pleaseReachOutToTheGlobalAdmin" | translate }}
                    </div>

                    <div
                        class="form-group col-12 text-left mb-0"
                        *ngIf="!editFlow"
                    >
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "next" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                            type="reset"
                            (click)="skipCategory()"
                        >
                            {{ "skip" | translate }}
                        </button>
                    </div>
                    <div
                        class="form-group col-12 text-left mb-0"
                        *ngIf="editFlow"
                    >
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular mt-3"
                            type="submit"
                        >
                            {{ "done" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'parking_category'">
        <div class="modal-header pl-0" style="padding-left: 0;">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer mr-2 backBtn"
                    aria-hidden="true"
                    (click)="backBtnbasicApproach()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="saveCategory()">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <p class="font-regular-gray">
                            {{ "would" | translate }} "{{
                                amenity.amenityName | lowercase
                            }}"
                            {{ "wouldmeetingroomhavecategories" | translate }}?
                            {{ "thisisusefulforauser" | translate }}
                        </p>
                        <p class="font-regular-gray">
                            {{
                                "eg.parkingcanhavetwoorfourwheelerparking"
                                    | translate
                            }}
                        </p>
                        <div class="col-12 mt-4">
                            <div class="text-center">
                                <img
                                    src="assets/images/amenity_booking/Group_13432.svg"
                                    class="w-50"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-12 mt-4">
                        <div class="form-row">
                            <div class="col-6">
                                <input
                                    [(ngModel)]="selectedCategory"
                                    type="text"
                                    class="form-control"
                                    id="selected_category"
                                    maxlength="30"
                                    [ngModelOptions]="{ standalone: true }"
                                    autocomplete="off"
                                />
                            </div>
                            <div class="col-1">
                                <div
                                    class="btn-block"
                                    (click)="addCategoryValue()"
                                >
                                    +
                                </div>
                            </div>
                            <div class="col-5 pl-3">
                                <p class="font-bold-custom">
                                    {{ "addCategories" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <!-- <mat-form-field class="example-chip-list" appearance="fill"> -->
                        <mat-chip-list class="custom-chip-edit">
                            <mat-chip
                                *ngFor="
                                    let category of categories;
                                    let i = index
                                "
                                (removed)="removeCategory(i,category)"
                            >
                                {{ category }}
                                <button matChipRemove type="button">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            *ngIf="!categories.length"
                            type="submit"
                        >
                            {{ "next" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btn-radius mr-2p float-right font-regular pl-4 pr-4"
                            *ngIf="categories.length"
                            type="submit"
                        >
                            {{ "saveAndProceed" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                            type="reset"
                            (click)="skipCategory()"
                        >
                            {{ "skip" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'addon_category'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    *ngIf="!editFlow"
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="backBtnparkingCategory()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closeCategoryPopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-row">
                <div class="form-group align-left col-12">
                    <p class="font-regular-gray mb-0">
                        {{ "wouldmeetingroomhaveaddon" | translate }}
                    </p>
                    <p class="font-regular-gray mb-4">
                        {{ "eg.incaseofmeeting" | translate }}
                    </p>
                    <div class="col-12">
                        <div class="text-center">
                            <img
                                src="assets/images/amenity_booking/Group_13433.svg"
                                class="w-70"
                            />
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="!digitalVersion">
                    <form (ngSubmit)="saveAddons()" class="w-100">
                        <div class="form-group col-12 mt-4">
                            <div class="form-row">
                                <div class="col-6">
                                    <input
                                        [(ngModel)]="addOnValue"
                                        type="text"
                                        class="form-control"
                                        id="add_on_value"
                                        maxlength="100"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                                <div class="col-1">
                                    <div
                                        class="btn-block"
                                        (click)="addAddOnValue()"
                                    >
                                        +
                                    </div>
                                    <!-- <a class="btn-block" href="#" (click)="addCategoryValue()"><i class="fa fa-plus"></i></a>
                              <button type="button" class="btn btn-main btn-block"
                                  (click)="addCategoryValue()">+</button> -->
                                </div>
                                <div class="col-5 pl-3">
                                <p class="font-bold-custom">{{"Addaddons" | translate}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <mat-chip-list class="custom-chip-edit">
                                <mat-chip
                                    *ngFor="
                                        let addon of addOnList;
                                        let i = index
                                    "
                                    (removed)="removeAddOn(i,addon)"
                                    class="py-4"
                                >
                                    {{ addon }}
                                    <button matChipRemove type="button">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                        <div class="form-group col-12 text-left mb-0">
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                                *ngIf="!addOnList.length"
                                type="submit"
                            >
                                {{ "next" | translate }}
                            </button>
                            <button
                                class="btn btn-primary btn-main btn-radius mr-2p float-right font-regular pl-4 pr-4"
                                *ngIf="addOnList.length"
                                type="submit"
                            >
                                {{ "saveAndProceed" | translate }}
                            </button>
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                                type="reset"
                                (click)="skipAddons()"
                            >
                                {{ "skip" | translate }}
                            </button>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="digitalVersion">
                    <form (ngSubmit)="saveGlobalAddons()" class="w-100">
                        <div class="row d-flex ml-0" *ngIf="mainAddonList.length">
                            <div
                                *ngFor="
                                    let option of mainAddonList;
                                    let i = index
                                "
                            >
                                <div class="form-check form-check-inline" [matTooltip]="getToolTipData(option.name)">
                                    <mat-checkbox
                                        class="custom-checkbox-btn ml-0 mr-5"
                                        value="{{ option.id }}"
                                        [checked]="option.checked"
                                        (change)="selectGlobalTool(option)"
                                    >
                                    {{ option?.name | slice: 0:22}} 
                                    <span *ngIf="option.name && option.name.length > 12">...</span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex ml-0 font-regular-gray" *ngIf="!mainAddonList.length">
                            {{ "thereAreCurrentlyNoAddOnsOnboarded" | translate }} <br /> {{ "pleaseReachOutToTheGlobalAdmin" | translate }}
                        </div>
                        <div
                            class="form-group col-12 text-left mb-0"
                            *ngIf="!editFlow"
                        >
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                                type="submit"
                            >
                                {{ "next" | translate }}
                            </button>
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                                type="reset"
                                (click)="skipAddons()"
                            >
                                {{ "skip" | translate }}
                            </button>
                        </div>
                        <div
                            class="form-group col-12 text-left mb-0"
                            *ngIf="editFlow"
                        >
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular mt-3"
                                type="submit"
                            >
                                {{ "done" | translate }}
                            </button>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- if(this.bookingMedium == "SINGLE_USER_PARTICIPANT") {
        this.pageType = 'outlook_integration'; //video_conference
      } else {
        this.pageType = 'onboard_meetingroom';
      } -->

    <div *ngIf="pageType == 'configure365'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="skipAddons()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">
                Configure your collaboration tools
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="dark-blue mb-5">
                To enable Office 365 for {{ amenity.amenityName }}, please
                configure office 365 in the integrations section.
            </div>

            <div class="form-group col-12 text-left mb-0">
                <button
                    class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                    (click)="goToOutLookOROnBoardRequest()"
                >
                    Got it
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'service_request'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    *ngIf="!editFlow"
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="backBtnAddonCategory()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closeCategoryPopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4 mt-2">
            <p class="italic openSans">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-row">
                <div class="form-group align-left col-12">
                    <p class="font-regular-gray">
                        {{
                            "wouldyouruserbeabletoraisespecialrequests"
                                | translate
                        }}
                    </p>
                    <div class="col-12">
                        <div class="text-center">
                            <img
                                src="assets/images/amenity_booking/Group_13434.svg"
                                class="w-50"
                            />
                        </div>
                    </div>
                    <!-- <mat-radio-group [(ngModel)]="special_request" [ngModelOptions]="{standalone: true}">
                          <mat-radio-button class="pb-0 custom-radio-btn col-2" value="yes">
                              {{'yes' | translate}}
                          </mat-radio-button>
                          <mat-radio-button class="pb-0 custom-radio-btn col-2" value="no">
                              {{'no' | translate}}
                          </mat-radio-button>
                      </mat-radio-group> -->
                </div>
                <ng-container *ngIf="!digitalVersion">
                    <form (ngSubmit)="saveSpecialRequests()" class="w-100">
                        <div class="form-group col-12 mt-4">
                            <div class="form-row">
                                <div class="col-6">
                                    <input
                                        [(ngModel)]="specialReqVal"
                                        type="text"
                                        class="form-control"
                                        id="add_on_value"
                                        maxlength="30"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                                <div class="col-1">
                                    <div
                                        class="btn-block"
                                        (click)="addSpecialReqValue()"
                                    >
                                        +
                                    </div>
                                </div>
                                <div class="col-5 pl-3">
                                    <p class="font-bold-custom">
                                        {{ "addSpecialRequests" | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <mat-chip-list class="custom-chip-edit">
                                <mat-chip
                                    *ngFor="
                                        let req of specialReqList;
                                        let i = index
                                    "
                                    (removed)="removeSpecialReq(i,req)"
                                >
                                    {{ req }}
                                    <button matChipRemove type="button">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                            </mat-chip-list>
                            <!-- <div class="col-12" *ngFor="let category of categories; let i = index"
                          style="margin: 10px 0px 10px 0px;">
                          <span class="custom-chip">
                              <span class="s1">{{category}}</span> &nbsp;
                              <a class="a1" href="javascript:void(0)" (click)="remove(i)">x</a>
                          </span>
                      </div> -->
                        </div>
                        <div class="form-group col-12 text-left mb-0">
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                                *ngIf="!specialReqList.length"
                                type="submit"
                            >
                                {{ "next" | translate }}
                            </button>
                            <button
                                class="btn btn-primary btn-main btn-radius mr-2p float-right font-regular pl-4 pr-4"
                                *ngIf="specialReqList.length"
                                type="submit"
                            >
                                {{ "saveAndProceed" | translate }}
                            </button>
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                                type="reset"
                                (click)="skipSpecialReq()"
                            >
                                {{ "skip" | translate }}
                            </button>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="digitalVersion">
                    <form
                        (ngSubmit)="saveGlobalSpecialRequests()"
                        class="w-100"
                    >
                        <div class="row d-flex ml-0" *ngIf="mainSpecialList.length">
                            <div
                                *ngFor="
                                    let option of mainSpecialList;
                                    let i = index
                                "
                            >
                                <div class="form-check form-check-inline" [matTooltip]="getToolTipData(option.name)">
                                    <mat-checkbox
                                        class="custom-checkbox-btn ml-0 mr-5"
                                        value="{{ option.id }}"
                                        [checked]="option.checked"
                                        (change)="selectGlobalTool(option)"
                                    >
                                        {{ option?.name | slice: 0:22}} 
                                        <span *ngIf="option.name && option.name.length > 12">...</span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex ml-0 font-regular-gray" *ngIf="!mainSpecialList.length">
                            {{ "thereAreCurrentlyNoSpecialRequestsOnboarded" | translate }} <br /> {{ "pleaseReachOutToTheGlobalAdmin" | translate }}
                        </div>
                        <div
                            class="form-group col-12 text-left mb-0"
                            *ngIf="!editFlow"
                        >
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                                type="submit"
                            >
                                {{ "finish" | translate }}
                            </button>
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                                type="reset"
                                (click)="skipSpecialReq()"
                            >
                                {{ "skip" | translate }}
                            </button>
                        </div>

                        <div
                            class="form-group col-12 text-left mb-0"
                            *ngIf="editFlow"
                        >
                            <button
                                class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular mt-3"
                                type="submit"
                            >
                                {{ "done" | translate }}
                            </button>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="pageType == 'outlook_permissions'">
        <div class="modal-header pl-0" (click)="backBtnOutlook()">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="backBtnOutlook()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="confirmDisableOffice365('outlook_permissions')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">
                {{ "configureTools" | translate }}
            </p>
        </div>
        <div class="col-12 mt-4 pl-4">
            <p class="dark-blue">{{ "pleaseensure" | translate }}</p>
            <p class="font-regular-gray mt-4">
                <i *ngIf="readCheck" class="fa fa-check"></i> Calendars.Read
            </p>
            <p class="font-regular-gray">
                <i *ngIf="writeCheck" class="fa fa-check"></i>
                Calendars.ReadWrite
            </p>
        </div>
        <div class="form-group row col-12 text-left mb-4 mt-5">
            <div class="col-9">
                <a target="_blank" class="dark-blue text-underline pl-2">{{
                    "ReadMoreAboutOutlook" | translate
                }}</a>
            </div>
            <div class="col-3">
                <button
                    class="btn btn-primary btn-main btnSmall btn-radius mr-2p font-regular"
                    type="button"
                    (click)="openApplicationTenant()"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div
        class="outlook_permissions"
        *ngIf="pageType == 'outlook_premissions_fail'"
    >
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer mr-2"
                    aria-hidden="true"
                    (click)="openApplicationTenant()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="confirmDisableOffice365('outlook_premissions_fail')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">
                {{ "configureTools" | translate }}
            </p>
        </div>
        <div class="col-12 pl-4">
            <p class="dark-blue">{{ "pleaseensure" | translate }}</p>
            <p class="openSans mt-4">
                <i *ngIf="readCheck" class="fa fa-check"></i>
                <i
                    *ngIf="!readCheck"
                    class="fa fa-close"
                    style="color: red"
                ></i>
                Calendars.Read
            </p>
            <p class="openSans">
                <i *ngIf="writeCheck" class="fa fa-check"></i>
                <i
                    *ngIf="!writeCheck"
                    class="fa fa-close"
                    style="color: red"
                ></i>
                Calendars.ReadWrite
            </p>
        </div>
        <div class="form-group row col-12 text-left mb-4 mt-4">
            <div class="col-9">
                <p class="dark-blue semiBold pl-2" style="font-size: 12px;">
                    {{ "pleaseensureallabove" | translate }}
                </p>
            </div>
            <!-- <div class="col-9">
              <a target="_blank" class="dark-blue text-underline pl-2">{{"ReadMoreAboutOutlook" | translate}}</a>
          </div> -->
            <div class="col-3">
                <button
                    type="button"
                    id="test_card"
                    class="btn btn-primary btn-main btn-radius font-regular float-right mt-5"
                    (click)="skipIntegrationForNow()"
                >
                    {{ "skipIntegrationForNow" | translate }}
                </button>
                <!-- <button class="btn btn-primary btn-main btn-radius mr-2p float-right mt-5 semiBold" type="button"
              (click)="skipIntegrationForNow()">
                  {{'skip' | translate}}
              </button> -->
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'outlook_application'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="openOutlook()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="confirmDisableOffice365('outlook_application')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4 mt-2">
            <p class="italic openSans">{{ "configureTools" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left removeScroll">
            <div class="form-group align-left mb-0">
                <!-- <div class="card-title">
                  {{'setupofficeoroutlookintegration' | translate}}
              </div> -->
                <div class="card-body custom-integration-card-body">
                    <div class="row">
                        <form
                            class="form-group"
                            id="outlook_details"
                            [formGroup]="outlookForm"
                            (ngSubmit)="checkPermissions()"
                        >
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="applicationClientId"
                                        >Application Client ID</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control blue-regular"
                                        formControlName="applicationClientID"
                                        [ngClass]="{
                                            'is-invalid':
                                                submittedOutlook &&
                                                outLookf.applicationClientID
                                                    .errors
                                        }"
                                        placeholder="Enter here"
                                    />
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="dirTenantId"
                                        >Directory Tenant ID</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control blue-regular"
                                        id="dir_id"
                                        formControlName="applicationTenantID"
                                        [ngClass]="{
                                            'is-invalid':
                                                submittedOutlook &&
                                                outLookf.applicationTenantID
                                                    .errors
                                        }"
                                        placeholder="Enter here"
                                    />
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="clientSecret"
                                        >Client Key Secret</label
                                    >
                                    <input
                                        type="password"
                                        class="form-control blue-regular"
                                        id="client_secret"
                                        formControlName="applicationClientSecret"
                                        [ngClass]="{
                                            'is-invalid':
                                                submittedOutlook &&
                                                outLookf.applicationClientSecret
                                                    .errors
                                        }"
                                        placeholder="Enter here"
                                    />
                                </div>
                                <div class="form-group col-md-12 mt-4 mb-0">
                                    <div class="float-right">
                                        <button
                                            type="button"
                                            id="test_card"
                                            *ngIf="!readCheck || !writeCheck"
                                            class="btn btn-main font-regular btn-radius pl-4 pr-4"
                                            (click)="
                                                confirmDisableOffice365(
                                                    'outlook_application'
                                                )
                                            "
                                        >
                                            {{
                                                "skipIntegrationForNow"
                                                    | translate
                                            }}
                                        </button>
                                        <button
                                            type="submit"
                                            id="test_card"
                                            class="btn btn-main btnSmall font-regular btn-radius ml-3"
                                            [ngClass]="{
                                                'btn-custom-border':
                                                    !readCheck || !writeCheck
                                            }"
                                        >
                                            {{ "next" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'skip_integration_alert'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="backBtnServiceReq()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4 mt-2">
            <p class="italic openSans">{{ "configureTools" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-group align-left">
                <div class="card-title dark-blue">
                    If you skip integration for now all the entity onboarded
                    will not be integrated to office365
                </div>
                <div class="card-body custom-integration-card-body">
                    <div class="row">
                        <div class="form-group col-md-12 pr-0">
                            <button
                                type="button"
                                id="test_card"
                                class="btn btn-main btn-radius font-regular float-right"
                                (click)="skipIntegrationAlertFinal()"
                            >
                                {{ "skipIntegrationForNow" | translate }}
                            </button>
                            <!-- <button id="test_card" class="btn btn-main btnSmall semiBold float-right btn-radius"
                              (click)="skipIntegrationAlertFinal()">
                              {{'skipIntegrationForNow' | translate}}</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'mail_integration'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="openApplicationTenant()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="confirmDisableOffice365('mail_integration')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4 mt-2">
            <p class="italic openSans">{{ "configureTools" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left pb-0">
            <div class="form-group align-left">
                <form
                    class="form-group"
                    id="outlook_email_details"
                    [formGroup]="outlookEmailForm"
                    (ngSubmit)="checkOutlookEmailPermissions()"
                >
                    <div class="row col-12">
                        <div class="form-row">
                            <div
                                *ngIf="!validateIntegration"
                                class="form-group col-md-12"
                            >
                                <p
                                    for="inputAccountNumber"
                                    class="font-regular-gray mb-2"
                                    style="font-size: 12px;"
                                >
                                    {{ "Entervalidmailbox" | translate }}
                                </p>
                                <input
                                    type="email"
                                    class="form-control blue-regular"
                                    id="outlook_email"
                                    formControlName="outlookEmail"
                                    [ngClass]="{
                                        'is-invalid':
                                            submittedEmailOutlook &&
                                            outLookEmailf.outlookEmail.errors
                                    }"
                                    placeholder="Enter here"
                                />
                            </div>
                            <div
                                *ngIf="!validateIntegration"
                                class="form-group col-md-12"
                            >
                                <p
                                    for="inputAccountNumber"
                                    class="font-regular-gray mb-2"
                                    style="font-size: 12px;"
                                >
                                    Enter resource Email ID(Eg.meeting room
                                    email ID)
                                </p>
                                <input
                                    type="email"
                                    class="form-control blue-regular"
                                    id="meeting_room_email"
                                    formControlName="outlookMeetingEmail"
                                    [ngClass]="{
                                        'is-invalid':
                                            submittedEmailOutlook &&
                                            outLookEmailf.outlookMeetingEmail
                                                .errors
                                    }"
                                    placeholder="Enter here"
                                />
                                <p
                                    *ngIf="
                                        submittedEmailOutlook &&
                                        outLookEmailf.outlookMeetingEmail.errors
                                    "
                                    class="errorMessage"
                                >
                                    {{ "pleaseEnterValidEmailId" | translate }}
                                </p>
                            </div>

                            <div
                                *ngIf="validateIntegration"
                                class="form-group col-md-12"
                            >
                                <label for="applicationClientId">{{
                                    "ApplicationClientID" | translate
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control font-regular-gray"
                                    [(ngModel)]="loginDetails.applClientId"
                                    [ngModelOptions]="{ standalone: true }"
                                    id="application_client_id"
                                    [disabled]="validateIntegration"
                                    placeholder="Enter here"
                                />
                            </div>
                            <div
                                *ngIf="validateIntegration"
                                class="form-group col-md-12"
                            >
                                <label for="dirTenantId">{{
                                    "DirectoryTenantID" | translate
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control font-regular-gray"
                                    id="dir_id"
                                    [(ngModel)]="loginDetails.dir_tenant_id"
                                    [disabled]="validateIntegration"
                                    [ngModelOptions]="{ standalone: true }"
                                    placeholder="Enter here"
                                />
                            </div>
                            <div
                                *ngIf="validateIntegration"
                                class="form-group col-md-12"
                            >
                                <label for="clientSecret">{{
                                    "ClientKeySecret" | translate
                                }}</label>
                                <input
                                    type="password"
                                    class="form-control font-regular-gray"
                                    id="client_secret"
                                    [(ngModel)]="loginDetails.clientSecret"
                                    [disabled]="validateIntegration"
                                    [ngModelOptions]="{ standalone: true }"
                                    placeholder="Enter here"
                                />
                            </div>

                            <div
                                *ngIf="!validateIntegration"
                                class="form-group col-md-12"
                            >
                                <div class="float-right mt-3">
                                    <button
                                        type="button"
                                        id="test_card"
                                        *ngIf="skipOutlookIntegration"
                                        class="btn btn-White btn-radius-white font-regular pl-4 pr-4"
                                        (click)="
                                            pageType = 'onboard_meetingroom'
                                        "
                                    >
                                        {{
                                            "skipIntegrationForNow" | translate
                                        }}
                                    </button>
                                    <button
                                        type="submit"
                                        id="test_card"
                                        class="btn btn-main btnSmall btn-radius font-regular ml-2 mb-0"
                                    >
                                        {{ "validate" | translate }}
                                    </button>
                                    <!--(click)="validateWholeIntegration()"-->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div *ngIf="validateIntegration" class="form-group col-md-12">
                    <button
                        type="button"
                        id="test_card"
                        class="btn btn-main btnSmall float-right btn-radius mb-3 font-regular"
                        (click)="saveIntegration()"
                    >
                        {{ "save" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'enable_outlook_edit_flow'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer"
                    aria-hidden="true"
                    (click)="openApplicationTenant()"
                ></i>
                <strong class="ml-2">{{ amenity.amenityName }}</strong>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic">{{ "configureTools" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="col-12">
                <div class="text-center">
                    <img
                        src="assets/images/amenity_booking/enable_outlook_msg.png"
                        class="w-50"
                    />
                </div>
            </div>
            <div class="form-group align-left">
                <p class="dark-blue">
                    {{ "successfullyEnabledOfficeEditOutlook" | translate }}
                </p>
                <div class="form-group col-md-12">
                    <button
                        type="button"
                        id="test_card"
                        class="btn btn-main btnSmall float-right font-regular btn-radius"
                        (click)="openEntities()"
                    >
                        {{ "proceed" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'outlook_integration'">
        <div class="modal-header ml-4 mr-4 pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="backBtnServiceReq()"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="confirmDisableOffice365('outlook_integration')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4 mt-2">
            <p class="italic openSans">{{ "configureTools" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="outlookIntegrationSubmit()">
                <div class="form-group align-left">
                    <p class="font-regular-gray mb-2">
                        {{ "wouldYouLikeToIntegrateWithOffice365" | translate }}
                    </p>
                    <p class="font-regular-gray">
                        {{ "throughthisintegration" | translate }}
                    </p>
                </div>

                <div
                    class="form-group col-12 text-left mb-0 mt-5"
                    *ngIf="integrationType != 'outlook'"
                >
                    <button
                        class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                        type="button"
                        (click)="openOutlook()"
                    >
                        {{ "yes" | translate }}
                    </button>
                    <button
                        class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                        type="submit"
                        (click)="confirmDisableOffice365('outlook_integration')"
                    >
                        {{ "no" | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>

    <!-- <div class="" *ngIf="pageType == 'collaboration_tool'">
      <div class="modal-header">
          <h5 class="dark-blue"><strong>{{amenity.amenityName}}</strong></h5>
          <button type="button" class="close" aria-label="Close" (click)="closePopup()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body width-400 align-left">
          <div class="col-12 prev-btn" style="margin-top:-3%;">
              <button id="cancel_booking" class="btn btn-default text-muted" (click)="backBtnOutlook()">
                  <img src="assets/images/sm_back.svg" /> {{ "back" | translate }}
              </button>
          </div>
          <form (ngSubmit)="configuredToolsSubmit()">
              <div class="form-group align-left">
                  <p class="">{{'configureTools' | translate}}</p>
              </div>
              <div class="form-group align-left">
                  <mat-chip-list class="custom-chip-edit">
                      <mat-chip class="custom-mat-chip" *ngFor="let req of collaborationTools; let i = index">
                          {{req}}
                          <button class="edit-mat-chip-btn">
                              <img src="./assets/images/edit_icon.svg" class="cursor-pointer iconSize"
                                  id="{{'edit_Amenity_'+i}}" (click)="editTool(req)" />

                          </button>
                      </mat-chip>
                  </mat-chip-list>
              </div>
              <div class="form-group col-12 text-left mb-0">
                  <button class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right" type="submit">
                      {{'next' | translate}}
                  </button>
              </div>
          </form>
      </div>
  </div> -->

    <div class="" *ngIf="pageType == 'edit_outlook'">
        <div class="modal-header pl-0">
            <h5>
                <strong>{{ "editsetupOffice" | translate }}</strong>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <!-- <p class="openSans italic">{{'configureTools' | translate}}</p> -->
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="editoutlookIntegrationSubmit()">
                <div class="form-row align-left">
                    <div class="form-group col-md-6">
                        <label for="applicationClientId"
                            >Application Client ID</label
                        >
                        <input
                            type="text"
                            class="form-control font-regular"
                            [(ngModel)]="loginDetails.applClientId"
                            [ngModelOptions]="{ standalone: true }"
                            id="application_client_id"
                            placeholder="Enter here"
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="dirTenantId">Directory Tenant ID</label>
                        <input
                            type="text"
                            class="form-control font-regular"
                            id="dir_id"
                            [(ngModel)]="loginDetails.dir_tenant_id"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Enter here"
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="clientSecret">Client Key Secret</label>
                        <input
                            type="password"
                            class="form-control blue-regular"
                            id="client_secret"
                            [(ngModel)]="loginDetails.clientSecret"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Enter here"
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="outlookEmail">Outlook Mail Box</label>
                        <input
                            type="text"
                            class="form-control blue-regular"
                            id="outlook_email"
                            [(ngModel)]="loginDetails.outlookEmail"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder=""
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="meetingRoomEmail">Meeting Room Email</label>
                        <input
                            type="text"
                            class="form-control blue-regular"
                            id="meeting_room_email"
                            [(ngModel)]="loginDetails.meetingroomEmail"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder=""
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="timezone">Timezone</label>
                        <div class="input-group">
                            <mat-form-field
                                class="custom-mat-17"
                                appearance="fill"
                            >
                                <mat-select
                                    [(ngModel)]="timezone"
                                    name="zone"
                                    placeholder="{{ 'select' | translate }}"
                                    id="zone_name"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                    <mat-option
                                        *ngFor="let zone of timeZones"
                                        value="{{ zone.value }}"
                                    >
                                        {{ zone.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <button
                            id="save_edit_integration"
                            class="btn btn-main btnSmall float-right font-regular btn-radius"
                            (click)="saveIntegration()"
                        >
                            {{ "save" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'video_conference'">
        <div class="modal-header pl-0">
            <h5>{{ amenityName }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic">{{ "configureTools" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="col-12 prev-btn" style="margin-top:-3%;">
                <button
                    id="cancel_booking"
                    class="btn btn-default text-muted"
                    (click)="backBtnOutlook()"
                >
                    <img src="assets/images/sm_back.svg" />
                    {{ "back" | translate }}
                </button>
            </div>
            <form (ngSubmit)="videoConferenceSubmit()">
                <div class="form-group align-left">
                    <p class="exampleFormControl dark-blue">
                        {{ "selectthevideoconferencetool" | translate }}
                    </p>
                    <div class="row d-flex flex-row ml-0">
                        <div
                            *ngFor="
                                let option of integrationTypes;
                                let i = index
                            "
                        >
                            <div class="form-check form-check-inline">
                                <mat-checkbox
                                    class="custom-checkbox-btn ml-0 mr-5"
                                    value="{{ option }}"
                                    (change)="selectConferenceTool(option)"
                                >
                                    {{ option }}</mat-checkbox
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="form-group align-left"
                    *ngIf="selectedTool == 'Zoom'"
                >
                    <div class="card custom-integration-card">
                        <div class="card-title">
                            {{ "zoomIntegration" | translate }}
                        </div>
                        <div
                            class="card-body custom-integration-card-body pt-0"
                        >
                            <div class="row">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="applicationClientId"
                                            >Application Client ID</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control blue-regular"
                                            [(ngModel)]="
                                                loginDetails.applClientId
                                            "
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            id="application_client_id"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="dirTenantId"
                                            >Directory Tenant ID</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control blue-regular"
                                            id="dir_id"
                                            [(ngModel)]="
                                                loginDetails.dir_tenant_id
                                            "
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="clientSecret"
                                            >Client Key Secret</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control blue-regular"
                                            id="client_secret"
                                            [(ngModel)]="
                                                loginDetails.clientSecret
                                            "
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="outlookEmail"
                                            >Outlook Mail Box</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control blue-regular"
                                            id="outlook_email"
                                            [(ngModel)]="
                                                loginDetails.outlookEmail
                                            "
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            placeholder=""
                                        />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="meetingRoomEmail"
                                            >Meeting Room Email</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control blue-regular"
                                            id="meeting_room_email"
                                            [(ngModel)]="
                                                loginDetails.meetingroomEmail
                                            "
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            placeholder=""
                                        />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="timezone">Timezone</label>
                                        <div class="input-group">
                                            <mat-form-field
                                                class="custom-mat"
                                                appearance="fill"
                                            >
                                                <mat-select
                                                    [(ngModel)]="timezone"
                                                    name="zone"
                                                    placeholder="{{
                                                        'select' | translate
                                                    }}"
                                                    id="zone_name"
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                >
                                                    <mat-option
                                                        *ngFor="
                                                            let zone of timeZones
                                                        "
                                                        value="{{ zone.value }}"
                                                    >
                                                        {{ zone.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <button
                                            id="validate_video_tool"
                                            class="btn btn-main btnSmall float-right font-regular btn-radius"
                                            (click)="testIntegration()"
                                        >
                                            {{ "validate" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="form-group col-12 text-left mb-0"
                    *ngIf="selectedTool === undefined"
                >
                    <button
                        class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                        type="submit"
                    >
                        {{ "next" | translate }}
                    </button>
                    <button
                        class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                        type="submit"
                        (click)="skipZoomIntegration()"
                    >
                        {{ "skip" | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'video_tool_selection'">
        <div class="modal-header pl-0">
            <h5>{{ amenityName }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="videoConfigToolsSubmit()">
                <div class="form-group align-left">
                    <p class="">{{ "configureTools" | translate }}</p>
                </div>
                <div class="form-group align-left">
                    <mat-chip-list class="custom-chip-edit">
                        <mat-chip
                            class="custom-mat-chip"
                            *ngFor="let req of videoTools; let i = index"
                        >
                            {{ req }}
                            <button class="edit-mat-chip-btn">
                                <img
                                    src="./assets/images/edit_icon.svg"
                                    class="cursor-pointer iconSize"
                                    id="{{ 'video_edit_tool_' + i }}"
                                    (click)="videoEditTool(req)"
                                />
                            </button>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <div class="form-group col-12 text-left mb-0">
                    <button
                        class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                        type="submit"
                    >
                        {{ "next" | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="rounded-0" *ngIf="pageType == 'onboard_meetingroom'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer backBtn mr-2"
                    aria-hidden="true"
                    (click)="backBtnvideoTool()"
                    *ngIf="!digitalVersion && data.flowType != 'onboard_edit_flow'"
                ></i>
                {{ amenity.amenityName }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-2">
            <p class="italic openSans pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <!-- <div class="col-12 prev-btn" style="margin-top:-3%;">
              <button id="cancel_booking" class="btn btn-default text-muted" *ngIf="backBtnDisabled"
               (click)="backBtnvideoTool()">
                  <img src="assets/images/sm_back.svg" /> {{ "back" | translate }}
              </button>
          </div> -->
            <div class="col-12 mb-4">
                <div class="text-center">
                    <img
                        src="assets/images/amenity_booking/Group_13435.svg"
                        class="w-50"
                    />
                </div>
            </div>
            <form (ngSubmit)="basicSetupSubmit()">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <p class="font-regular-gray" *ngIf="!digitalVersion">
                            {{
                                "successfullycompletedthebasicsetup" | translate
                            }}
                        </p>
                        <ng-container *ngIf="!digitalVersion">
                            <mat-radio-group
                                [(ngModel)]="selected_map"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="checkUploadOption()"
                            >
                                <mat-radio-button
                                    class="pb-0 custom-radio-btn col-3 font-regular-gray"
                                    value="via_map"
                                    *ngIf="approach == 'MAP'"
                                >
                                    {{ "viaMap" | translate }}
                                </mat-radio-button>
                                <mat-radio-button
                                    class="pb-0 custom-radio-btn col-5 font-regular-gray"
                                    value="via_excel"
                                    *ngIf="approach != 'MAP'"
                                >
                                    {{ "viaExcelDownload" | translate }}
                                </mat-radio-button>
                                <mat-radio-button
                                    class="pb-0 custom-radio-btn col-4 font-regular-gray"
                                    value="manual_onboard"
                                    *ngIf="approach != 'MAP'"
                                >
                                    {{ "manualOnboard" | translate }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </ng-container>

                        <ng-container *ngIf="digitalVersion">
                            <ng-container *ngIf="approach==''">
                                <mat-radio-group
                                    [(ngModel)]="selected_map"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="checkUploadOption()"
                                >
                                    <mat-radio-button
                                        class="pb-0 custom-radio-btn col-3 font-regular-gray"
                                        value="via_map"
                                    >
                                        {{ "viaMap" | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button
                                        class="pb-0 custom-radio-btn col-5 font-regular-gray"
                                        value="via_excel"
                                    >
                                        {{ "viaExcelDownload" | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button
                                        class="pb-0 custom-radio-btn col-4 font-regular-gray"
                                        value="manual_onboard"
                                    >
                                        {{ "manualOnboard" | translate }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </ng-container>

                            <ng-container  *ngIf="approach!=''">
                                <mat-radio-group
                                    [(ngModel)]="selected_map"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="checkUploadOption()"
                                >
                                    <mat-radio-button
                                        class="pb-0 custom-radio-btn col-3 font-regular-gray"
                                        value="via_map"
                                        *ngIf="approach == 'MAP'"
                                    >
                                        {{ "viaMap" | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button
                                        class="pb-0 custom-radio-btn col-5 font-regular-gray"
                                        value="via_excel"
                                        *ngIf="approach != 'MAP'"
                                    >
                                        {{ "viaExcelDownload" | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button
                                        class="pb-0 custom-radio-btn col-4 font-regular-gray"
                                        value="manual_onboard"
                                        *ngIf="approach != 'MAP'"
                                    >
                                        {{ "manualOnboard" | translate }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div
                        class="row"
                        *ngIf="
                            selected_map == 'via_map' ||
                            selected_map == 'via_excel'
                        "
                    >
                        <div class="form-group align-left col-6">
                            <label for="buildingNameViaMap font-regular-gray">{{
                                "building" | translate
                            }}</label>
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select
                                        #buildingId
                                        [(ngModel)]="buildingNameViaMap"
                                        placeholder="{{ 'select' | translate }}"
                                        [ngModelOptions]="{ standalone: true }"
                                        id="building_name"
                                        (selectionChange)="
                                            getFloorList(buildingId.value)
                                        "
                                    >
                                        <mat-option
                                            *ngFor="let item of buildingList"
                                            [value]="item.zoneId"
                                            (click)="buildingChangeName(item)"
                                        >
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group align-left col-6">
                            <label for="floorNameViaMap font-regular-gray">{{
                                "floor" | translate
                            }}</label>
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select
                                        #floorId
                                        placeholder="{{ 'select' | translate }}"
                                        id="floor_id"
                                        [(ngModel)]="floorNameViaMap"
                                        [ngModelOptions]="{ standalone: true }"
                                    >
                                        <mat-option
                                            *ngFor="let item of floorList"
                                            [value]="item"
                                            (click)="floorChangeName(item)"
                                        >
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-group align-left col-12 mt-3"
                        *ngIf="selected_map == 'via_map'"
                    >
                        <mat-radio-group
                            [(ngModel)]="selectedFormat"
                            [ngModelOptions]="{ standalone: true }"
                        >
                            <!-- <mat-radio-button class="pb-0 custom-radio-btn col-3" value="dwg_file">
                              .{{'dwgFile' | translate}}
                          </mat-radio-button> -->
                            <mat-radio-button
                                class="pb-0 custom-radio-btn col-5 font-regular-gray"
                                value="png_file"
                            >
                                .{{ "pngFile" | translate }}
                            </mat-radio-button>
                            <mat-radio-button
                                class="pb-0 custom-radio-btn col-5 font-regular-gray"
                                value="dwg_file"
                                *ngIf="!digitalVersion || jumpreeProNonTeams"
                            >
                                .{{ "dwgFile" | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div
                        class="row"
                        *ngIf="
                            selected_map == 'via_map' &&
                            selectedFormat == 'png_file'
                        "
                    >
                        <div class="form-group align-left col-8">
                            <label class="w-100 mt-1">
                                {{ "upload" | translate }} ({{
                                    "pngFile" | translate
                                }})
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input
                                            [(ngModel)]="upload_map"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            #fileInput
                                            (click)="fileInput.value = null"
                                            type="file"
                                            class="custom-file-input form-control"
                                            aria-describedby="inputGroupFileAddon01"
                                            (change)="onUploadPngFile($event)"
                                            autocomplete="off"
                                        />
                                        <label
                                            class="custom-file-label custom-upload-btn"
                                            for="inputGroupFile01"
                                            style="padding: 10px;"
                                            >{{ fileName }}</label
                                        >
                                    </div>
                                </div>
                                <!-- <div class="custom-file">
                              <input [(ngModel)]="upload_map" [ngModelOptions]="{standalone: true}" type="file"
                                  class="form-control custom-file-input mt-2" id="importDwgFile"
                                  (change)="uploadCadFile($event.target.files)"
                                  aria-describedby="inputGroupFileAddon02" />
                              <label class="custom-file-label custom-upload-btn" for="inputGroupFile03"
                                  #labelImport3></label>
                          </div> -->
                            </label>
                        </div>
                    </div>
                    <div
                        class="row"
                        *ngIf="
                            selected_map == 'via_map' &&
                            selectedFormat == 'dwg_file'
                        "
                    >
                        <div class="form-group align-left col-8">
                            <label class="w-100 mt-1">
                                {{ "upload" | translate }} ({{
                                    "autocad" | translate
                                }}
                                {{ "file" | translate }})
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input
                                            #fileInput
                                            (click)="fileInput.value = null"
                                            [(ngModel)]="upload_map_dwg"
                                            type="file"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            class="custom-file-input form-control"
                                            aria-describedby="inputGroupFileAddon01"
                                            (change)="
                                                onUploadAutocadFile($event)
                                            "
                                            autocomplete="off"
                                        />
                                        <label
                                            class="custom-file-label custom-upload-btn"
                                            for="inputGroupFile01"
                                            style="padding: 10px;"
                                            >{{ fileName }}</label
                                        >
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="row" *ngIf="selected_map == 'via_excel'">
                        <div class="form-group align-left col-4">
                            <button
                                id="download_template"
                                type="button"
                                (click)="downloadTemplate()"
                                class="btn btn-outline-primary btn-main-outline mt-4 "
                            >
                                {{ "downloadTemplate" | translate }}
                            </button>
                        </div>
                        <div class="form-group align-left col-2">
                            &nbsp;
                            <!-- <button id="save_btn" mat-mini-fab class="btn btn-main mt-4 bgColor ml-4 mat-mini-fab">
                              <img src="./assets/images/save.svg" alt="" />
                          </button> -->
                        </div>
                        <div class="form-group align-left col-8">
                            <label class="w-100 mt-1"
                                >{{ "upload" | translate }} (.CSV
                                {{ "file" | translate }})
                                <div class="custom-file">
                                    <input
                                        [(ngModel)]="upload_csv_excel"
                                        [ngModelOptions]="{ standalone: true }"
                                        type="file"
                                        class="form-control custom-file-input mt-2"
                                        id="importCsvFile"
                                        (change)="
                                            uploadCsvFile($event.target.files)
                                        "
                                        aria-describedby="inputGroupFileAddon03"
                                    />
                                    <label
                                        class="custom-file-label custom-upload-btn"
                                        for="inputGroupFile04"
                                        #labelImportFile
                                    ></label>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="row" *ngIf="selected_map == 'manual_onboard'">
                        <div class="form-group align-left col-6">
                            <label for="categoryName">{{
                                "category" | translate
                            }}</label>
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select
                                        [(ngModel)]="categoryName"
                                        placeholder="{{ 'select' | translate }}"
                                        [ngModelOptions]="{ standalone: true }"
                                        id="category_name"
                                    >
                                        <mat-option
                                            *ngFor="let item of categoryList"
                                            [value]="item"
                                        >
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group align-left col-6">
                            <label for="entityName"
                                >{{ "entityName" | translate
                                }}<span style="color:red">*</span></label
                            >
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control blue-regular"
                                    id="entityName"
                                    placeholder=""
                                    name="entityName"
                                    [(ngModel)]="entityName"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                            </div>
                        </div>
                        <div class="form-group align-left col-6">
                            <label for="buildingNameManual"
                                >{{ "building" | translate
                                }}<span style="color:red">*</span></label
                            >
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select
                                        #buildingId
                                        [(ngModel)]="buildingNameManual"
                                        placeholder="{{ 'select' | translate }}"
                                        [ngModelOptions]="{ standalone: true }"
                                        id="building_name_manual"
                                        (selectionChange)="
                                            getFloorList(buildingId.value)
                                        "
                                    >
                                        <mat-option
                                            *ngFor="let item of buildingList"
                                            [value]="item.zoneId"
                                        >
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group align-left col-6">
                            <label for="floorNameManual"
                                >{{ "floor" | translate
                                }}<span style="color:red">*</span></label
                            >
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select
                                        #floorId
                                        [(ngModel)]="floorNameManual"
                                        placeholder="{{ 'select' | translate }}"
                                        [ngModelOptions]="{ standalone: true }"
                                        id="floor_id_manual"
                                    >
                                        <mat-option
                                            *ngFor="let item of floorList"
                                            [value]="item.zoneId"
                                        >
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group align-left col-6">
                            <label for="addOnsName">{{
                                "addons" | translate
                            }}</label>
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select
                                        [(ngModel)]="addOnName"
                                        placeholder="{{ 'select' | translate }}"
                                        multiple
                                        [ngModelOptions]="{ standalone: true }"
                                        id="addon_name"
                                    >
                                        <mat-option
                                            *ngFor="let item of addonsList"
                                            [value]="item"
                                        >
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group align-left col-6">
                            <label for="specialReqName">{{
                                "specialRequests" | translate
                            }}</label>
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select
                                        [(ngModel)]="specialReqName"
                                        placeholder="{{ 'select' | translate }}"
                                        multiple
                                        [ngModelOptions]="{ standalone: true }"
                                        id="special_req_id"
                                    >
                                        <mat-option
                                            *ngFor="
                                                let item of specialRequestsList
                                            "
                                            [value]="item"
                                        >
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- <div class="form-group align-left col-6">
                          <label for="inputEmail4">{{ 'capacity' | translate }}</label>
                          <div class="input-group">

                              <input type="text" class="form-control" id="capacity" placeholder="" name="capacity"
                                  [(ngModel)]="capacityName" [ngModelOptions]="{standalone: true}">

                          </div>
                      </div> -->
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            (click)="onboardEntity('manual')"
                            type="submit"
                        >
                            {{ "next" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- <div class="" *ngIf="pageType == 'meetingroom_config'">
      <div class="modal-header">
          <h5><strong>{{amenityName}}</strong></h5>
          <button type="button" class="close" aria-label="Close" (click)="closePopup()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="col-12">
          <p class="openSans italic">{{'configureTools' | translate}}</p>
      </div>
      <div class="modal-body width-400 align-left">
          <form (ngSubmit)="validateConfigSubmit()">
              <div class="form-row">
                  <div class="form-group align-left col-12">
                      <p class="exampleFormControl">{{'successfullycompletedthebasicsetup' | translate}}</p>
                      <mat-radio-group [(ngModel)]="selected_map" [ngModelOptions]="{standalone: true}">
                          <mat-radio-button class="pb-0 custom-radio-btn col-2" value="via_map">
                              {{'viaMap' | translate}}
                          </mat-radio-button>
                          <mat-radio-button class="pb-0 custom-radio-btn col-2" value="via_excel">
                              {{'viaExcelDownload' | translate}}
                          </mat-radio-button>
                          <mat-radio-button class="pb-0 custom-radio-btn col-2" value="via_excel">
                              {{'manualOnboard' | translate}}
                          </mat-radio-button>
                      </mat-radio-group>
                  </div>
                  <div class="form-group align-left col-6">
                      <label for="inputEmail4">{{ 'building' | translate }}</label>
                      <div class="input-group">
                          <mat-form-field appearance="fill">
                              <mat-select [(ngModel)]="buildingName" placeholder="{{'select' | translate}}"
                                  [ngModelOptions]="{standalone: true}" id="building_name">
                                  <mat-option *ngFor="let item of buildingList; let i = index" value="{{ item }}">
                                      {{ item }}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="form-group align-left col-6">
                      <label for="inputEmail4">{{ 'floor' | translate }}</label>
                      <div class="input-group">
                          <mat-form-field appearance="fill">
                              <mat-select [(ngModel)]="floorName" placeholder="{{'select' | translate}}"
                                  [ngModelOptions]="{standalone: true}" id="floor_name">
                                  <mat-option *ngFor="let item of floorList; let i = index" value="{{ item }}">
                                      {{ item }}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="form-group align-left col-8">
                      <label class="w-100 mt-1">{{ "upload" | translate }} (.CSV {{ "file" | translate
                          }})
                          <div class="custom-file">
                              <input [(ngModel)]="upload_map" [ngModelOptions]="{standalone: true}" type="file"
                                  class="form-control custom-file-input mt-2" id="importCsvFile"
                                  (change)="uploadCsvFile($event.target.files)"
                                  aria-describedby="inputGroupFileAddon02" />
                              <label class="custom-file-label custom-upload-btn" for="inputGroupFile03"
                                  #labelImport3></label>
                          </div>
                      </label>
                  </div>
                  <div class="form-group col-12 text-left mb-0">
                      <button class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right" type="submit">
                          {{'next' | translate}}
                      </button>
                  </div>
              </div>
          </form>
      </div>
  </div> -->

    <div class="" *ngIf="pageType == 'addCategory'">
        <div class="modal-header pl-0">
            <h5>{{ addcategory }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="addCategorySubmit(add_name)">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <p class="exampleFormControl">
                            {{ "Pleaseenterthename" | translate }}
                        </p>
                        <input
                            type="text"
                            class="form-control blue-regular"
                            id="category_value"
                            placeholder=""
                            name="category_value"
                            maxlength="30"
                            [(ngModel)]="add_name"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "add" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'addAddon'">
        <div class="modal-header pl-0">
            <h5>{{ addcategory }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="addAddonSubmit(add_name)">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <p class="exampleFormControl">
                            {{ "Pleaseenterthename" | translate }}
                        </p>
                        <input
                            type="text"
                            class="form-control blue-regular"
                            id="category_value"
                            placeholder=""
                            name="category_value"
                            maxlength="100"
                            [(ngModel)]="add_name"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "add" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'onboard_identification'" style="max-width: 45vw;">
        <div class="modal-header pl-0">
            <h5>{{ "verification" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="addEntities()">
                <div class="form-row">
                    <div class="col-12">
                        <div class="align-center">
                            <img
                                src="assets/images/amenity_booking/onboard_floor_plan_upload.png"
                                class="w-65"
                            />
                        </div>
                    </div>
                    <div class="form-group align-left col-12">
                        <p class="exampleFormControl">
                            {{ "onlyIdentifiedEntities" | translate }}
                        </p>
                    </div>
                    <div class="form-group col-12 text-center mb-0 button-alignCenter">
                        <button
                            class="btn btn-primary btn-main-white-nb btn-radius mr-2 float-right prl-5 mt-1"
                            (click)="fewEntitiesFlow()" type="reset">
                            {{ "fewEntities" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btn-radius mr-2 float-right  prl-5 mt-1"
                            type="submit"
                        >
                            {{ "allEntities" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="pageType == 'onboard_end_flow'">
        <div class="modal-header pl-0">
            <h5>{{ amenity.amenityName }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12  pl-4 mt-2">
            <p class="openSans italic">{{ "cardConfig" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="OnboardFinalSubmit()">
                <div class="form-row">
                    <div class="col-12">
                        <div class="align-center">
                            <img
                                src="assets/images/amenity_booking/onboarded_success.png"
                                class="w-65"
                            />
                        </div>
                    </div>
                    <div class="form-group align-center col-12 pt-2">
                        <p class="mb-0">
                            {{ "successfullyOnboarded" | translate }}
                        </p>
                        <p class="exampleFormControl bold">
                            {{ "configureSettings" | translate }}
                        </p>
                    </div>
                    <div class="form-group col-12 align-center mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-main-outline btn-radius font-regular mr-2"
                            (click)="updateLater()"
                            type="submit"
                        >
                            {{ "later" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius font-regular mr-2"
                            (click)="startNow()"
                            type="submit"
                        >
                            {{ "startNow" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'addSpecialReq'">
        <div class="modal-header pl-0">
            <h5>{{ addcategory }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="addSpecialSubmit(add_name)">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <p class="exampleFormControl">
                            {{ "Pleaseenterthename" | translate }}
                        </p>
                        <input
                            type="text"
                            class="form-control blue-regular"
                            id="category_value"
                            placeholder=""
                            name="category_value"
                            maxlength="30"
                            [(ngModel)]="add_name"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "add" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'add_participants'">
        <div class="modal-header pl-0">
            <h5>{{ "addParticipants" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="addSendConfirmationMailtTo()">
                <div class="form-row">
                    <div class="form-group align-left col-9">
                        <p class="exampleFormControl">
                            {{ "Pleaseentertheemail" | translate }}
                        </p>
                        <input
                            [(ngModel)]="selectedEmail"
                            type="email"
                            class="form-control"
                            id="add_participant"
                            autocomplete="off"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="alex@gmail.com"
                            (input)="
                                getBookingAutoEmailcompleteList(selectedEmail)
                            "
                            [matAutocomplete]="auto"
                        />
                        <i
                            class="fa fa-spinner loading_span"
                            *ngIf="emailbookingIsloading"
                            aria-hidden="true"
                        ></i>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                                *ngFor="
                                    let dropData of bookingAutoemailcompleteList$
                                        | async
                                "
                                [value]="dropData.email.trim()"
                            >
                                <span>{{ dropData?.email }}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <div *ngIf="isErrorInput" class="invalid-feedback">
                            <div>{{ "invalidEmail" | translate }}</div>
                        </div>
                    </div>
                    <div
                        class="form-group col-3 text-left mb-0 font-regular"
                        style="margin-top: 2.2rem;"
                    >
                    <button type="submit" class="btnSubmit">
                    <img class="cursor-pointer iconSizeDlt"
                                src="../../../../assets/images/plusicon.png" ></button>
                        <!-- <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right"
                            type="submit"
                        >
                            {{ "add" | translate }}
                        </button> -->
                    </div>
                </div>
            </form>
            <div class="row mt-2 ml-2">
                <div
                    class="col-12"
                    *ngFor="let sendMail of sendCnfMailList; let i = index"
                    style="margin: 10px 0px 10px 0px;"
                >
                    <span class="sendEmail">
                        <!-- <span class="circle">{{ sendMail | uppercase | translate }}</span> -->
                        <span class="s1">{{ sendMail | translate }}</span>
                        &nbsp;
                        <a
                            class="a1"
                            href="javascript:void(0)"
                            (click)="removeEmail(i)"
                            >x</a
                        >
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    &nbsp;
                </div>
                <div class="col-2">
                    <button
                        class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                        type="button"
                        (click)="sendInvite()"
                    >
                        {{ "save" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'entitiesUploadedCsv'">
        <div class="modal-header pl-0">
            <h5>{{ amenity.amenityName }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4 mt-2">
            <p class="openSans italic">
                {{ "onboarding" | translate }}: {{ "step" | translate }}2
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="csvUploadedEntities()">
                <div class="form-row">
                    <div class="col-12">
                        <div class="align-center">
                            <img
                                src="assets/images/amenity_booking/Upload_csv_entities.svg"
                                class="w-35"
                            />
                        </div>
                    </div>
                    <div class="form-group align-left col-12 mb-0">
                        <p class="exampleFormControl ml-3">
                            {{
                                "successfullyaddedindividualparking" | translate
                            }}
                            '{{ amenity.amenityName }}'
                            {{ "letsmapcategories" | translate }}
                        </p>
                    </div>
                    <div class="form-group col-12 mb-0">
                        <button
                            class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5"
                            type="submit"
                        >
                            {{ "proceed" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'wayfinding'">
        <div class="modal-header pl-0">
            <h5>Way Finding</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                In order to enabled way finding please mark a start point
                (entrance) to this floor, center of the map and individual paths
                for {{ amenity.amenityName }}
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular"
                    type="button"
                    (click)="updateWayFinding('required')"
                >
                    Yes, Proceed
                </button>
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular"
                    type="button"
                    (click)="updateWayFinding('close')"
                >
                    No, Users do not need way finding
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'proceed'">
        <div class="modal-header pl-0">
            <h5>{{'alert' | translate}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >

                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                <b
                    >{{'pleaseSelectStartCentreMapBeforeProceeding' | translate}}</b
                >
            </p>
        </div>
        <div class="modal-body width-400 ">
            <div class="form-group col-12 mb-0 buttonAlignCenter">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5"
                    type="button"
                    (click)="proceedClose('mapstartcenter')"
                >
                    {{'ok' | translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'proceedWithCommonPath'">
        <div class="modal-header pl-0">
            <h5>{{'alert' | translate}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                <b
                    >{{'pleaseConfirmYouHaveIdentifiedAlltheCommonPaths' | translate}}</b
                >
            </p>
        </div>
        <div class="modal-body width-400 ">
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btnSmall  btn-main btn-radius mr-2  font-regular prl-5"
                    type="button"
                    (click)="proceedClose('commonPathsNo')"
                >
                    {{'no' | translate}}
                </button>
                <button
                    class="btn btn-primary btnSmall  btn-main btn-radius mr-2  font-regular prl-5"
                    type="button"
                    (click)="proceedClose('commonPathsYes')"
                >
                    {{'yes' | translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'bookingConfirmation'" style="margin-top: 90px;">
    <h1 mat-dialog-title class="mx-auto mt-4" align="center"><img src="assets/images/check.png" width="57.248" height="55.683" viewBox="0 0 57.248 55.683"/></h1>
        <p class="mx-auto c2" align="center" *ngIf="!visitorEmail"><strong>{{"yourbookingisconfirmed" | translate}}</strong></p>
        <p class="mx-auto c2 p-4" *ngIf="visitorEmail !== ''" align="center"><strong>{{"yourBookingConfirmedEmail" | translate}}</strong></p>
        <!-- <ng-container *ngFor="let item of data['data']; let i=index">
          <div class="modal-body modal-content" style="padding-bottom: 0rem !important;">
              <p class="mx-auto"><b> {{item['requestDetails']['timeStart']}} - {{item['requestDetails']['timeEnd']}}</b>
                  <br /><strong>{{item['requestDetails']['startTime'] | date: 'd -MMMM y':'UTC'}}</strong></p>
              <p class="mx-auto"><b> {{item['requestDetails']['entityInfos'][0]['displayName']}}</b>
              <br /><strong>{{item['requestDetails'].buildingName}}, {{item['requestDetails'].floorName}}</strong></p>
          </div>
      </ng-container> -->
        <ng-container *ngIf="!data['data'][0]['deskBookingEnabled']">
            <!-- <div
                class="modal-body modal-content confirmModal"
                style="padding-bottom: 0rem !important;text-align: left;"
            > -->
                <!-- <div class="row ml-custom">
                    <div class="col-md-3 text-right">
                        <img
                            src="./assets/images/amenity_booking/calendar_book_now.png"
                            class="pointerSize"
                        />
                    </div>
                    <div class="col-md-9">
                        <p class="mb-0">
                            <strong *ngFor="let item of dateArr; let i = index">
                                {{ item.date | date: "d":"UTC" }} -{{ getTranslate(item.date | date: "MMMM":"UTC") }}
                                {{ item.date | date: "y":"UTC" }} ({{
                                    item.timeDataStart
                                }}
                                - {{ item.timeDataEnd }})<span
                                    *ngIf="dateArr.length - 1 > i"
                                    >,<br
                                /></span>
                            </strong>
                        </p>
                    </div> -->
                    <!-- <div class="col-md-9">
                      <p class="mb-0"><b *ngFor="let time of timeArr; let i = index;">
                          {{time.timeDataStart}} - {{time.timeDataEnd}}
                          <span *ngIf="timeArr.length - 1 > i">,<br/></span></b></p>
                  </div> -->
                <!-- </div> -->
                <!-- <div class="row ml-custom">
                  <div class="col-md-3">&nbsp;</div>
                  <div class="col-md-9">
                     <p class="mb-0"> <strong *ngFor="let item of dateArr;let i = index;">
                      {{item.date | date: 'd -MMMM y':'UTC'}} ({{item.timeDataStart}} - {{item.timeDataEnd}})<span *ngIf="dateArr.length - 1 > i">,<br/></span>
                      </strong></p>
                  </div>
              </div> -->
                <!-- <div class="row mt-2 ml-custom">
                    <div class="col-md-3 text-right">
                        <img
                            src="./assets/images/amenity_booking/location_icon.png"
                            class="pointerSize"
                        />
                    </div>
                    <div class="col-md-9">
                        <p class="mb-2">
                            <b> {{ entityData.join(", ") }}</b>
                        </p>
                    </div>
                </div>
                <div class="row mb-3 ml-custom">
                    <div class="col-md-3">&nbsp;</div>
                    <div class="col-md-9">
                        <p class="mb-0">
                            <strong *ngFor="let ele of buildingData"
                                >{{ ele.building }}, {{ ele.floor }}, {{ele.siteName}}
                                <span *ngIf="buildingData.length > 1"
                                    ><br
                                /></span>
                            </strong>
                        </p>
                    </div>
                </div> -->
            <!-- </div> -->
            <!-- <p class="mx-auto mb-0">
                  <img src="./assets/images/amenity_booking/calendar_book_now.png"
                  class="pointerSize" />  
                  <b *ngFor="let time of timeArr; let i = index;"> 
                      {{time.timeDataStart}} - {{time.timeDataEnd}}
                      <span *ngIf="timeArr.length - 1 > i">,</span></b>
              </p>
              <p class="mx-auto" >
                  <strong *ngFor="let item of dateArr;let i = index;">
                      {{item | date: 'd -MMMM y':'UTC'}} <span *ngIf="dateArr.length - 1 > i">,</span>
                  </strong>
              </p>
              <p class="mx-auto">
                  <img src="./assets/images/amenity_booking/location_icon.png"
                  class="pointerSize" />
                  <b> {{entityData}}</b>
              </p>
              <p class="mx-auto">
              <strong *ngFor="let ele of buildingData">{{ele.building}}, {{ele.floor}}</strong>
               </p>  -->
        </ng-container>
        <div
            *ngIf="data['data'][0]['deskBookingEnabled']"
            class="mb-3 text-align-center"
        >
            <button
                class="btn btn-main btn-radius font-regular"
                style="border: 1px solid #091639"
                (click)="confirmOk()"
            >
                View Schedule
            </button>
        </div>
        <div
            class="modal-footer"
            style="float:right !important; border-top: 0px !important;"
            *ngIf="!data['data'][0]['deskBookingEnabled']"
        >
            <button
                class="btn btn-none float-right font-regular b1"
                style="border: 1px solid #091639"
                (click)="confirmOk()"
            >
            {{ "done" | translate }}
            </button>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'wayFindingMap'">
        <div class="modal-header pl-0">
            <h5>{{"hintWayfinding" | translate}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 ">
            <div class="col-lg-12 mb-0 buttonAlignCenter">
                <img
                    style="width:400px"
                    src="./assets/images/hintCommanPath.png"
                    class="cursor-pointer iconSize"
                    id="edit_entity"
                />
            </div>
            <div class="col-lg-12 mb-0 buttonAlignCenter">
                <p>
                    {{"identifyCommonPathsThatEmergeFromStartPoint" | translate}}
                </p>
            </div>
            <div class="col-lg-12 mb-0 buttonAlignCenter">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5"
                    type="button"
                    (click)="proceedClose('allPathsYes')"
                >
                    {{"ok" |
            translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'drawPathMap'">
        <div class="modal-header pl-0">
            <h5>{{"hintWayfinding" | translate}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 ">
            <div class="col-lg-12 mb-0 buttonAlignCenter">
                <img
                    style="width:400px"
                    src="./assets/images/hintwayfinding.png"
                    class="cursor-pointer iconSize"
                    id="edit_entity"
                />
            </div>
            <div class="col-lg-12 mb-0 buttonAlignCenter">
                <p>
                    {{"clickOnboardedEntitiesDrawOutPathsConnectingToTheCommonPaths" | translate}}
                </p>
            </div>
            <div class="col-lg-12 mb-0 buttonAlignCenter">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5"
                    type="button"
                    (click)="proceedClose('allPathsYes')"
                >
                {{"ok" | translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'qrCode'">
        <div class="modal-header pl-0">
            <h5>Meeting Rooms</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic">{{ "qrcode" | translate }}</p>
        </div>
        <div class="modal-body width-400">
            <div class="p-0 m-0 table-responsive w-100">
                <!-- <qrcode [qrdata]="myAngularxQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> -->
                <div id="printSection">
                    <div class="qrcodeCenter" *ngIf="myAngularxQrCode != ''">
                        <!-- <qrcode [qrdata]="myAngularxQrCode" [width]="400" [size]="256" [level]="'M'">
                          </qrcode> -->
                        <img
                            [src]="myAngularxQrCode"
                            width="150"
                            class="qr-code img-thumbnail img-responsive"
                        />
                    </div>
                </div>
                <div class="qrcodeCenter mt-2">
                    {{ entityNameQr }}
                </div>
                <br />
                <div class="qrcodeCenter">
                    <button
                        id="back_btn"
                        class="btn btn-main btnSmall btn-radius mr-2 float-right font-regular prl-5"
                        (click)="printPage()"
                    >
                        {{ "Print" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="rounded-0" *ngIf="pageType == 'bookNowUser'">
        <div class="modal-header pl-0">
            <h5>{{ "bookNow" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400">
            <form (ngSubmit)="onSubmitBookNow()">
                <div class="form-row mt-4">
                    <!-- <div class="form group col-lg-12" style="text-align: left;">
                          <mat-radio-group class="display-row" [(ngModel)]="bookingUser"
                          [ngModelOptions]="{standalone: true}" (change)="selectedUserOption()">
                          <mat-radio-button class="pb-0 custom-radio-btn col-lg-2" value="other_user">
                              {{'user' | translate}}
                          </mat-radio-button>
                          <mat-radio-button class="pb-0 custom-radio-btn col-lg-2" value="same_user">
                              {{'myself' | translate}}
                          </mat-radio-button>
                      </mat-radio-group>
                      </div> -->
                    <div class="form-group col-lg-12 col-md-12 align-left">
                        <div class="input-group" style="border:1px solid #ddd;">
                            <span class="input-group-append">
                                
                                <div
                                    class="input-group-text bg-transparent form-control borderCurved"
                                > 
                                <!-- <img class="cursor-pointer iconSizeFilter" src="./assets/images/amenity_booking/search_amenity_icon.png" style="width: 20px;"> -->
                                    <i class="fa fa-search"></i>
                                </div>
                            </span>
                            <!-- <input id="search_type_user" class="form-control py-2" type="text"
                                      placeholder="Search by name"
                                      (keyup)="searchEmployee()" /> -->
                            <input
                                [(ngModel)]="bookingObj.selectedName"
                                type="text"
                                #searchTerm="ngModel"
                                class="form-control"
                                name="user-dep"
                                id="user-dep"
                                autocomplete="off"
                                placeholder="{{ 'searchByName' | translate }}"
                                (input)="
                                    getBookingAutocompleteList(searchTerm.value)
                                "
                                [matAutocomplete]="auto"
                                
                            /> 
                            <span class="input-group-append" *ngIf="showLoader">
                                <div
                                    class="input-group-text bg-transparent form-control borderCurved"
                                >
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>
                            </span>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option
                                    *ngFor="
                                        let dropData of bookingAutocompleteList$
                                            | async
                                    "
                                    [value]="dropData.name.trim()"
                                    (click)="onselectBookingData(dropData)"
                                >
                                    <span>{{ dropData?.name }}</span>
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                        <p *ngIf="!selectedOption" class="errorMessage mt-1">{{"PleaseSelectValidUser" | translate}}</p>
                    </div>
                    <!-- <div class="form-group col-lg-2 col-md-12 align-left mt-4" *ngIf="bookingUser == 'other_user'">
                          <button type="button" class="btn btn-main btnSmall ml-3 btn-radius" id="search"
                          (click)="searchEmployee()">
                          {{ "search" | translate }}
                      </button>   
                      </div> -->
                    <div class="form-group col-12 text-left mb-0 mt-2">
                        <button class="btn btn-main btnSmall btn-radius mr-2p float-right font-regular" type="submit" [disabled]="!proceed">
                            {{'proceed' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'findColleague'">
        <div class="modal-header pl-0">
            <h5>{{ "findcolleague" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="textAlign">
                <strong>
                    {{
                        "wouldyouliketoenablefindcolleagueforparking"
                            | translate
                    }}
                    {{ amenity.amenityName }}
                </strong>
            </p>
        </div>
        <div class="col-12">
            <p class="textAlign">
                {{
                    "featureisrecommendedwhentherearemultipleentities"
                        | translate
                }}.
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular"
                    type="button"
                    (click)="updateFindColleague('required')"
                >
                    {{ "yesProceed" | translate }}
                </button>
                <button
                    class="btn btn-primary btn-main btn-radius mr-3 float-right font-regular"
                    type="button"
                    (click)="updateFindColleague('close')"
                >
                    {{ "noUsersdonotneedfindcolleague" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'zoom_in'">
        <div class="modal-header pl-0">
            <h5>
                {{ "customiseFloorPlan" | translate }} ({{
                    amenity.amenityName
                }})
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left modalPopupZoomPointer">
            <!-- <div class="col-12 prev-btn" style="margin-top:-3%;">
              <button id="cancel_booking" class="btn btn-default text-muted" (click)="backBtnbasicApproach()">
                  <img src="assets/images/sm_back.svg" /> {{ "back" | translate }}
              </button>
          </div> -->
            <form (ngSubmit)="zoomInSubmit()">
                <div class="form-row">
                    <div class="form-group align-left col-12 mb-0">
                        <p class="exampleFormControl mb-0 dark-blue">
                            {{ "currentView" | translate }}
                        </p>
                        <!-- <p class="exampleFormControl mb-0">
                          {{'pleasesetdefaultview' | translate}}</p>
                      <p class=""><span class="redColor">*</span>{{'usercanstillzoominandout' | translate}}</p> -->
                    </div>
                    <div class="form-group col-lg-12 " *ngIf="dashboardView">
                        <span
                            *ngIf="dashboardView"
                            (click)="getMobileView()"
                            class="float-right pl-8 pr-8 link2 dark-blue"
                            >{{ "switchtomobileview" | translate }}
                        </span>
                    </div>
                    <div
                        class="form-group col-lg-12 row align-left"
                        *ngIf="dashboardView"
                    >
                        <div class="col-4">
                            <span (click)="showZoom()">
                                <img
                                    [src]="
                                        zoomer == true
                                            ? './assets/images/amenity_booking/Group 14499.png'
                                            : './assets/images/amenity_booking/Group 14501.png'
                                    "
                                    class="cursor-pointer iconSize ml-1"
                                    style="width:3rem; height:3rem"
                                />

                                <label class="dark-blue">{{
                                    "adjustZoomLevel" | translate
                                }}</label>
                            </span>

                            
                        </div>
                            <div
                                *ngIf="zoomer"
                                class="col-4"
                                style="margin-top: -20px;"
                            >
                                <label
                                    for="zoomLevelDashboard"
                                    class="dark-blue"
                                    >{{ "Zoom" | translate }}</label
                                >
                                <div class="input-group">
                                    <mat-form-field
                                        class="custom-mat-zoom "
                                        appearance="fill"
                                    >
                                        <mat-select
                                            [(ngModel)]="startZoomLevelDashboard"
                                            name="min_zoom_level"
                                            placeholder="{{
                                                'select' | translate
                                            }}"
                                            id="min_zoom_level"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            (selectionChange)="minZoomset()"
                                        >
                                            <mat-option
                                                *ngFor="let zoom of zoomArr"
                                                value="{{ zoom.min }}"
                                            >
                                                {{ zoom.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        <div class="col-4" *ngIf="showPointer != 'DWG'">
                            <span (click)="showPoint()">
                                <img
                                    [src]="
                                        pointc == true
                                            ? './assets/images/amenity_booking/Group 14500.png'
                                            : './assets/images/amenity_booking/Group 14470.png'
                                    "
                                    class="cursor-pointer iconSize ml-1"
                                    style="width:3rem; height:3rem"
                                />
                                <label class="dark-blue">{{
                                    "Adjust Pointer Size" | translate
                                }}</label>
                            </span>
                            </div>
                            <div
                                *ngIf="pointc"
                                class="col-4"
                                style="margin-top: -20px;"
                            >
                                <label
                                    for="pointerSizeDashboard"
                                    class="dark-blue"
                                    >{{ "pt size" | translate }}</label
                                >
                                <div class="input-group">
                                    <mat-form-field
                                        class="custom-mat-zoom"
                                        appearance="fill"
                                    >
                                        <mat-select
                                            [(ngModel)]="pointerSizeDashboard"
                                            name="point_dashboard"
                                            placeholder="{{
                                                'select' | translate
                                            }}"
                                            id="point_dashboard"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            (selectionChange)="pointerset()"
                                        >
                                            <mat-option
                                                *ngFor="let point of pointSize"
                                                value="{{ point }}"
                                            >
                                                {{ point }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        
                    </div>
                    <!-- <div class="form-group col-lg-2 col-md-2 align-left" *ngIf="dashboardView">
                      <label for="inputEmail4">{{ 'maxzoom' | translate }}</label>
                      <div class="input-group">
                      <mat-form-field class="custom-mat-zoom" appearance="fill">
                          <mat-select [(ngModel)]="maxzoomdashboard" name="max_zoom_level"
                              placeholder="{{'select' | translate}}" id="max_zoom_level"
                              [ngModelOptions]="{standalone: true}" (selectionChange)="maxZoomset()">
                              <mat-option *ngFor="let zoom of maxzoomLevel" value="{{zoom}}">
                                  {{ zoom }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  </div> -->
                    <!-- <div class="form-group col-lg-10 col-md-10 mt-4" *ngIf="dashboardView">
                      <button *ngIf="dashboardView" (click)="getMobileView()"
                      class="btn btn-primary btn-main btn-radius float-right pl-8 pr-8" type="button">
                          {{'switchtomobileview' | translate}}
                      </button>
                  </div> -->
                    <div
                        class="form-group col-lg-12 col-md-12"
                        *ngIf="dashboardView"
                    >
                        <div class="leaflet-container" style="width: 100%;">
                            <div
                                id="map_dashboard"
                                class="groove dashBoardView"
                            ></div>
                        </div>
                    </div>
                    <div class="form-group col-lg-12 " *ngIf="mobileView">
                        <span
                            *ngIf="mobileView"
                            (click)="getDashboardView()"
                            class="float-right pl-8 pr-8 link2 dark-blue"
                        >
                            {{ "switchtodsahboardview" | translate }}
                        </span>
                    </div>
                    <div
                        class="form-group col-lg-12 row  align-left"
                        *ngIf="mobileView"
                    >
                        <div class="col-4">
                            <span (click)="showZoom()">
                                <img
                                    [src]="
                                        zoomer == true
                                            ? './assets/images/amenity_booking/Group 14499.png'
                                            : './assets/images/amenity_booking/Group 14501.png'
                                    "
                                    class="cursor-pointer iconSize ml-1"
                                    style="width:3rem; height:3rem"
                                />

                                <label class="dark-blue">{{
                                    "adjustZoomLevel" | translate
                                }}</label>
                            </span>

                            
                        </div>

                            <div
                                *ngIf="zoomer"
                                class="col-md-3 col-sm-12"
                                style="margin-top: -20px;"
                            >
                                <label for="zoomLevelApp" class="dark-blue">{{
                                    "Zoom" | translate
                                }}</label>
                                <div class="input-group">
                                    <mat-form-field
                                        class="custom-mat-zoom"
                                        appearance="fill"
                                    >
                                        <mat-select
                                            [(ngModel)]="startZoomLevelApp"
                                            name="min_zoom_app"
                                            placeholder="{{
                                                'select' | translate
                                            }}"
                                            id="min_zoom_app"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            (selectionChange)="minZoomsetApp()"
                                        >
                                            <mat-option
                                                *ngFor="let zoom of zoomArr"
                                                value="{{ zoom.min }}"
                                            >
                                                {{ zoom.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        <div class="col-md-4 col-sm-12" *ngIf="showPointer != 'DWG'">
                            <span (click)="showPoint()">
                                <img
                                    [src]="
                                        pointc == true
                                            ? './assets/images/amenity_booking/Group 14500.png'
                                            : './assets/images/amenity_booking/Group 14470.png'
                                    "
                                    class="cursor-pointer iconSize ml-1"
                                    style="width:3rem; height:3rem"
                                />
                                <label class="dark-blue">{{
                                    "Adjust Pointer Size" | translate
                                }}</label>
                            </span>
                            
                        </div>

                      
                            <div
                            *ngIf="pointc && showPointer != 'DWG'"
                            class="col-4"
                                style="margin-top: -20px;"
                            >
                                <label
                                    for="pointerSizeDashboard"
                                    class="dark-blue"
                                    >{{ "pt size" | translate }}</label
                                >
                                <div class="input-group">
                                    <mat-form-field
                                        class="custom-mat-zoom"
                                        appearance="fill"
                                    >
                                        <mat-select
                                            [(ngModel)]="pointerSizeDashboard"
                                            name="point_dashboard"
                                            placeholder="{{
                                                'select' | translate
                                            }}"
                                            id="point_dashboard"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            (selectionChange)="pointerset()"
                                        >
                                            <mat-option
                                                *ngFor="let point of pointSize"
                                                value="{{ point }}"
                                            >
                                                {{ point }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                     
                    </div>
                    <!-- <div class="form-group col-lg-2 col-md-2 align-left" *ngIf="mobileView">
                      <label for="inputEmail4">{{ 'maxzoom' | translate }}</label>
                      <div class="input-group">
                      <mat-form-field class="custom-mat-zoom" appearance="fill">
                          <mat-select [(ngModel)]="maxzoomApp" name="max_zoom_app"
                              placeholder="{{'select' | translate}}" id="max_zoom_app"
                              [ngModelOptions]="{standalone: true}" (selectionChange)="maxZoomsetApp()">
                              <mat-option *ngFor="let zoom of maxzoomLevelapp" value="{{zoom}}">
                                  {{ zoom }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  </div> -->
                    <!-- <div class="form-group col-lg-10 col-md-10 mt-4" *ngIf="mobileView">
                      <button *ngIf="mobileView" (click)="getDashboardView()"
                      class="btn btn-primary btn-main btn-radius float-right pl-8 pr-8" type="button">
                          {{'switchtodsahboardview' | translate}}
                      </button>
                  </div> -->
                    <div
                        class="form-group col-lg-12 col-md-12 mobileScreen"
                        *ngIf="mobileView"
                    >
                        <div class="col-12 text-center">
                            <img
                                src="assets/images/amenity_booking/mobile_view_1.png"
                                style="width:42%;height:7rem;"
                            />
                        </div>
                        <div
                            class="leaflet-container mobile col-12"
                            style="width: 40%;margin-left: 30%;"
                        >
                            <div id="map_dashboard" class="mt-4 groove"></div>
                        </div>
                        <div class="col-12 text-center">
                            <img
                                src="assets/images/amenity_booking/mobile_view_2.png"
                                style="width:42%;height:5rem;"
                            />
                        </div>
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <!-- <button class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right" type="submit">
                          {{'next' | translate}}
                      </button> -->
                        <button
                            class="btn btn-main  btnSmall btn-radius  mr-2 float-right font-regular"
                            type="submit"
                        >
                            {{ "save" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                            type="button"
                            (click)="closePopup()"
                        >
                            {{ "skip" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'circle_radius'">
        <div class="modal-header pl-0">
            <h5>
                {{ "customiseFloorPlan" | translate }} ({{
                    amenity.amenityName
                }})
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left modalPopupZoomPointer">
            <!-- <div class="col-12 prev-btn" style="margin-top:-3%;">
              <button id="cancel_booking" class="btn btn-default text-muted" (click)="backBtnbasicApproach()">
                  <img src="assets/images/sm_back.svg" /> {{ "back" | translate }}
              </button>
          </div> -->
            <form (ngSubmit)="circleRadiusSubmit()">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <p class="exampleFormControl mb-0 dark-blue">
                            {{ "currentView" | translate }}
                        </p>
                        <!-- <p class="exampleFormControl mb-0">
                          {{'pleasesetthepointersize' | translate}}</p> -->
                    </div>
                    <div class="form-group col-lg-12 " *ngIf="dashboardView">
                        <span
                            *ngIf="dashboardView"
                            (click)="getMobileView()"
                            class="float-right pl-8 pr-8 link dark-blue"
                            >{{ "switchtomobileview" | translate }}
                        </span>
                    </div>
                    <div
                        class="form-group col-lg-12 row  align-left"
                        *ngIf="dashboardView"
                    >
                        <div class="col-6 row">
                            <span (click)="showZoom()" class="col-8">
                                <img
                                    [src]="
                                        zoomer == true
                                            ? './assets/images/amenity_booking/Group 14499.png'
                                            : './assets/images/amenity_booking/Group 14501.png'
                                    "
                                    class="cursor-pointer iconSize ml-1"
                                    style="width:3rem; height:3rem"
                                />

                                <label class="dark-blue">{{
                                    "adjustZoomLevel" | translate
                                }}</label>
                            </span>

                            <div
                                *ngIf="zoomer"
                                class="col-4"
                                style="margin-top: -20px;"
                            >
                                <label
                                    for="zoomLevelDashboard"
                                    class="dark-blue"
                                    >{{ "Zoom" | translate }}</label
                                >
                                <div class="input-group">
                                    <mat-form-field
                                        class="custom-mat-zoom "
                                        appearance="fill"
                                    >
                                        <mat-select
                                            [(ngModel)]="startZoomLevelDashboard"
                                            name="min_zoom_level"
                                            placeholder="{{
                                                'select' | translate
                                            }}"
                                            id="min_zoom_level"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            (selectionChange)="minZoomset()"
                                        >
                                            <mat-option
                                                *ngFor="let zoom of zoomArr"
                                                value="{{ zoom.min }}"
                                            >
                                                {{ zoom.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 row" *ngIf="showPointer != 'DWG'">
                            <span (click)="showPoint()" class="col-8">
                                <img
                                    [src]="
                                        pointc == true
                                            ? './assets/images/amenity_booking/Group 14500.png'
                                            : './assets/images/amenity_booking/Group 14470.png'
                                    "
                                    class="cursor-pointer iconSize ml-1"
                                    style="width:3rem; height:3rem"
                                />
                                <label class="dark-blue">{{
                                    "Adjust Pointer Size" | translate
                                }}</label>
                            </span>
                            <div
                                *ngIf="pointc"
                                class="col-4"
                                style="margin-top: -20px;"
                            >
                                <label
                                    for="pointerSizeDashboard"
                                    class="dark-blue"
                                    >{{ "pt size" | translate }}</label
                                >
                                <div class="input-group">
                                    <mat-form-field
                                        class="custom-mat-zoom"
                                        appearance="fill"
                                    >
                                        <mat-select
                                            [(ngModel)]="pointerSizeDashboard"
                                            name="point_dashboard"
                                            placeholder="{{
                                                'select' | translate
                                            }}"
                                            id="point_dashboard"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            (selectionChange)="pointerset()"
                                        >
                                            <mat-option
                                                *ngFor="let point of pointSize"
                                                value="{{ point }}"
                                            >
                                                {{ point }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- <div class="form-group col-lg-10 col-md-10 mt-4" *ngIf="dashboardView">
                      <button *ngIf="dashboardView" (click)="getMobileView()"
                      class="btn btn-primary btn-main btn-radius float-right pl-8 pr-8" type="button">
                          {{'switchtomobileview' | translate}}
                      </button> -->
                        </div>
                    </div>
                    <div
                        class="form-group col-lg-12 col-md-12"
                        *ngIf="dashboardView"
                    >
                        <div class="leaflet-container" style="width: 100%;">
                            <div
                                id="map_dashboard"
                                class="groove dashBoardView"
                            ></div>
                        </div>
                    </div>
                    <div class="form-group col-lg-12 " *ngIf="mobileView">
                        <span
                            *ngIf="mobileView"
                            (click)="getDashboardView()"
                            class="float-right pl-8 pr-8 link dark-blue"
                        >
                            {{ "switchtodsahboardview" | translate }}
                        </span>
                    </div>
                    <div
                        class="form-group col-lg-12 row  align-left"
                        *ngIf="mobileView"
                    >
                        <div class="col-6 row">
                            <span (click)="showZoom()" class="col-8">
                                <img
                                    [src]="
                                        zoomer == true
                                            ? './assets/images/amenity_booking/Group 14499.png'
                                            : './assets/images/amenity_booking/Group 14501.png'
                                    "
                                    class="cursor-pointer iconSize ml-1"
                                    style="width:3rem; height:3rem"
                                />

                                <label class="dark-blue">{{
                                    "adjustZoomLevel" | translate
                                }}</label>
                            </span>

                            <div
                                *ngIf="zoomer"
                                class="col-4"
                                style="margin-top: -20px;"
                            >
                                <label
                                    for="zoomLevelDashboard"
                                    class="dark-blue"
                                    >{{ "Zoom" | translate }}</label
                                >
                                <div class="input-group">
                                    <mat-form-field
                                        class="custom-mat-zoom "
                                        appearance="fill"
                                    >
                                        <mat-select
                                            [(ngModel)]="startZoomLevelDashboard"
                                            name="min_zoom_level"
                                            placeholder="{{
                                                'select' | translate
                                            }}"
                                            id="min_zoom_level"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            (selectionChange)="minZoomset()"
                                        >
                                            <mat-option
                                                *ngFor="let zoom of zoomArr"
                                                value="{{ zoom.min }}"
                                            >
                                                {{ zoom.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 row" *ngIf="showPointer != 'DWG'">
                            <span (click)="showPoint()" class="col-8">
                                <img
                                    [src]="
                                        pointc == true
                                            ? './assets/images/amenity_booking/Group 14500.png'
                                            : './assets/images/amenity_booking/Group 14470.png'
                                    "
                                    class="cursor-pointer iconSize ml-1"
                                    style="width:3rem; height:3rem"
                                />
                                <label class="dark-blue">{{
                                    "Adjust Pointer Size" | translate
                                }}</label>
                            </span>
                            <div
                                *ngIf="pointc"
                                class="col-4"
                                style="margin-top: -20px;"
                            >
                                <label for="pointerSizeApp" class="dark-blue">{{
                                    "pt size" | translate
                                }}</label>
                                <div class="input-group">
                                    <mat-form-field
                                        class="custom-mat-zoom"
                                        appearance="fill"
                                    >
                                        <mat-select
                                            [(ngModel)]="pointerSizeApp"
                                            name="point_app"
                                            placeholder="{{
                                                'select' | translate
                                            }}"
                                            id="point_app"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            (selectionChange)="pointersetApp()"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let point of pointSizeApp
                                                "
                                                value="{{ point }}"
                                            >
                                                {{ point }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- <div class="form-group col-lg-10 col-md-10 mt-4" *ngIf="mobileView">
                      <button *ngIf="mobileView" (click)="getDashboardView()"
                      class="btn btn-primary btn-main btn-radius float-right pl-8 pr-8" type="button">
                          {{'switchtodsahboardview' | translate}}
                      </button> -->
                        </div>
                    </div>
                    <div
                        class="form-group col-lg-12 col-md-12 mobileScreen"
                        *ngIf="mobileView"
                    >
                        <div class="col-12 text-center">
                            <img
                                src="assets/images/amenity_booking/mobile_view_1.png"
                                style="width:42%;height:7rem;"
                            />
                        </div>
                        <div
                            class="leaflet-container mobile col-12"
                            style="width: 40%;margin-left: 30%;"
                        >
                            <div id="map_dashboard" class="mt-4 groove"></div>
                        </div>
                        <div class="col-12 text-center">
                            <img
                                src="assets/images/amenity_booking/mobile_view_2.png"
                                style="width:42%;height:5rem;"
                            />
                        </div>
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <!-- <button class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right" type="submit">
                          {{'next' | translate}}
                      </button> -->
                        <button
                            class="btn  btn-main btnSmall btn-radius mr-2 float-right font-regular"
                            type="submit"
                            id="savezoom"
                        >
                            {{ "save" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                            type="button"
                            (click)="closePopup()"
                        >
                            {{ "skip" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- <div class="" *ngIf="pageType == 'addCapacity'">
        <div class="modal-header pl-0 width-400">
            <h5>{{ "Adding Capacity" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modalScroll">
            <div
                class="form-group col-lg-10 col-md-10 mt-4"
                *ngFor="let entity of entitiesList"
            >
                <div class="form-group row">
                    <label for="staticEmail" class="col-sm-5 col-form-label"
                        >{{ entity.type }} :
                    </label>
                    <div class="col-sm-3">
                        <input
                            type="text"
                            class="form-control"
                            id="staticEmail"
                            maxlength="10"
                            [(ngModel)]="entity.capacity"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5"
                    type="button"
                    (click)="saveCapacity('required')"
                >
                    {{ "Save" | translate }}
                </button>
            </div>
        </div>
    </div> -->

    <div class="" *ngIf="pageType == 'showAllData'">
        <div class="modal-header pl-0 width-400">
            <h4>
                {{ showAllEmails ? "Participants" : ("Details" | translate) }}
            </h4>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left" *ngIf="!showAllEmails">
            <br />
            <div class="dark-blue" *ngIf="data?.dataArray?.createdByUser">
                {{ data?.dataArray?.title }}
                <span
                    class="spanFontSizeSmall"
                    *ngIf="
                        !data?.dataArray?.sameUserCreatedOrDiff &&
                        data?.dataArray?.tabType
                    "
                    >( Admin Booking: {{ data?.dataArray?.userDeatils?.name }} )
                </span>
            </div>
            <br />
            <div
                class="spanFontSizeLarge mb-15"
                *ngIf="data?.dataArray?.createdByUser"
            >
                Organiser
            </div>
            <span
                class="spanFontSizeSmall"
                *ngIf="data?.dataArray?.createdByUser"
                ><span class="legendCircle">{{
                    getUserLegendName(data.dataArray)
                }}</span>
                {{ data.dataArray.name }}
            </span>
            <span
                class="spanFontSizeLarge"
                style="margin-left: -10px"
                *ngIf="!data?.dataArray?.createdByUser"
            >
                <img
                    *ngIf="data?.dataArray?.userImage"
                    class="roundCircleLarge"
                    [src]="data?.dataArray?.userImage"
                />
                <span
                    *ngIf="data?.dataArray?.userImage == null"
                    class="legendCircle"
                    >{{ getUserLegendName(data.dataArray) }}</span
                >
                {{ data.dataArray.name }}
                {{
                    !data.dataArray?.createdByUser
                        ? " Invited you for " + data?.dataArray?.title
                        : ""
                }}
            </span>
            <br />
            <br />
            <div class="spanFontSizeLarge">When</div>
            <span class="spanFontSizeSmall">
                {{
                    data?.dataArray?.requestDetails?.startTime
                        | date: "d -MMMM y":"UTC"
                }}</span
            >
            <br />
            <br />
            <div class="spanFontSizeLarge">Time</div>
            <!-- <span class="spanFontSizeSmall"> {{getBookTime(data.dataArray)}}</span> -->
            <span class="spanFontSizeSmall">
                {{ getBookTime(data.dataArray, "both") }}</span
            >
            <br />
            <br />
            <div class="spanFontSizeLarge">Location</div>
            <span class="spanFontSizeSmall" *ngIf="data?.dataArray?.zoneLocationDto && data?.dataArray?.zoneLocationDto?.name != 'NA'">
                {{data?.dataArray?.zoneLocationDto?.name}},{{data?.dataArray?.zoneLocationDto?.building?.name}}, {{data?.dataArray?.zoneLocationDto?.site?.name}}, {{data?.dataArray?.zoneLocationDto?.city?.name}}, {{data?.dataArray?.zoneLocationDto?.state?.name}}, {{data?.dataArray?.zoneLocationDto?.country?.name}}</span
            >
            <span class="spanFontSizeSmall" *ngIf="data?.dataArray?.zoneLocationDto && data?.dataArray?.zoneLocationDto?.name == 'NA'">{{'NA'}}</span>
            <span class="spanFontSizeSmall" *ngIf="!data?.dataArray?.zoneLocationDto">{{'NA'}}</span>
            <br />
            <br />
            <ng-container *ngIf="data?.dataArray?.amenityArr != 'NA'">
                <div class="spanFontSizeLarge">Special Request</div>
                <span class="spanFontSizeSmall">
                    {{ data?.dataArray?.amenityArr }}</span
                >
                <br />
                <br />
            </ng-container>
            <ng-container
                *ngIf="
                    !!data?.enableParticipants &&
                    !!data?.dataArray?.integrationId
                "
            >
                <div class="spanFontSizeLarge">
                    {{ data?.dataArray?.meetingLinkProvider | titlecase }} Link
                </div>
                <span *ngIf="!!data?.dataArray?.joinUrl">
                    <a
                        class="spanFontSizeSmall"
                        [href]="data?.dataArray?.joinUrl"
                        target="_blank"
                        >{{ data?.dataArray?.joinUrl }}</a
                    >
                </span>
                <span
                    class="spanFontSizeSmall"
                    *ngIf="!data?.dataArray?.joinUrl"
                    >NA</span
                >
                <br />
                <br />
            </ng-container>
            <div
                class="spanFontSizeLarge"
                *ngIf="data?.dataArray?.participantList != null"
            >
                Participants
            </div>
            <div
                class="rowJustifyContent"
                *ngIf="data?.dataArray?.participantList != null"
            >
                <span class="spanFontSizeSmall mt-2">
                    {{ getParticipants(data?.dataArray) | slice: 0:75 }}..
                </span>
                <span
                    class="align-right"
                    *ngIf="data?.dataArray?.participantList != null"
                >
                    <a
                        class="spanAchor"
                        (click)="ShowAllEmail(data?.dataArray?.participantList)"
                    >
                        View All
                    </a>
                </span>
            </div>
            <div
                class="card cardYesMaybeNo"
                *ngIf="
                    data.currentTab != 'Manage Booking' &&
                    !data?.externalBooking &&
                    !data?.dataArray?.createdByUser &&
                    data?.dataArray?.showIcons &&
                    isUserParticipantsOrNot(data?.dataArray)
                "
            >
                <div class="spanFontSizeLarge ml-23">
                    Would you like to attend ?
                </div>
                <div class="rowStyleForPopup">
                    <span
                        (click)="statusChange(data, 'ACCEPTED')"
                        [class]="
                            data?.dataArray?.participantStatus == 'ACCEPTED'
                                ? 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-Activecolor'
                                : 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-color'
                        "
                        >Yes</span
                    >
                    <span
                        (click)="statusChange(data, 'TENTATIVE')"
                        [class]="
                            data?.dataArray?.participantStatus == 'TENTATIVE'
                                ? 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-Activecolor'
                                : 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-color'
                        "
                        >May Be</span
                    >
                    <span
                        (click)="statusChange(data, 'DECLINED')"
                        [class]="
                            data?.dataArray?.participantStatus == 'DECLINED'
                                ? 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-Activecolor'
                                : 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-color'
                        "
                        >No</span
                    >
                </div>
            </div>
        </div>
        <div class="modal-body align-left" *ngIf="showAllEmails">
            <!-- <br/> -->
            <div class="dark-blue">{{ data?.dataArray?.title }}</div>
            <div class="row mt-2">
                <div
                    class="col-12"
                    *ngFor="let sendMail of participantsArray; let i = index"
                    style="margin: 10px 0px 10px 0px"
                >
                    <div>
                        <span class="spanFontSizeSmall"
                            ><span class="legendCircle">{{
                                getUserLegendName1(sendMail)
                            }}</span>
                            {{
                                sendMail.user
                                    ? sendMail.user?.email
                                    : sendMail.guestEmail
                            }}
                        </span>
                        <span class="align-right">
                            <img
                                *ngIf="
                                    sendMail['participantStatus'] == 'ACCEPTED'
                                "
                                class="pull-right"
                                src="assets/images/IconCheckCircleRight.png"
                                style="width: 30px; height: 30px"
                            />
                            <img
                                *ngIf="
                                    sendMail['participantStatus'] ==
                                        'TENTATIVE' ||
                                    sendMail['participantStatus'] == 'PENDING'
                                "
                                class="pull-right"
                                src="assets/images/iconcheckquestion.png"
                                style="width: 30px; height: 30px"
                            />
                            <img
                                *ngIf="
                                    sendMail['participantStatus'] == 'DECLINED'
                                "
                                class="pull-right"
                                src="assets/images/iconcheckfailed.png"
                                style="width: 30px; height: 30px"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-group col-12 mb-0 p-0">
                <div *ngIf="showAllEmails">
                    <img
                        src="assets/images/IconCheckCircleRight.png"
                        style="width: 30px; height: 30px"
                    />
                    <span class="spanFontSizeSmall mr-4">Yes</span>
                    <img
                        src="assets/images/iconcheckquestion.png"
                        style="width: 30px; height: 30px"
                    />
                    <span class="spanFontSizeSmall mr-4">Maybe</span>
                    <img
                        src="assets/images/iconcheckfailed.png"
                        style="width: 30px; height: 30px"
                    />
                    <span class="spanFontSizeSmall mr-4">No</span>
                </div>
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5"
                    type="button"
                    (click)="closePopup()"
                >
                    {{ "done" | translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="" *ngIf="pageType == 'addCapacity'">
        <div class="modal-header pl-0 width-400">
            <h5>{{ "Adding Capacity" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modalScroll">
            <div
                class="form-group col-lg-10 col-md-10 mt-4"
                *ngFor="let entity of entitiesList"
            >
                <div class="form-group row">
                    <label for="staticEmail" class="col-sm-5 col-form-label"
                        >{{ entity.type }} :
                    </label>
                    <div class="col-sm-3">
                        <input
                            type="text"
                            class="form-control"
                            id="staticEmail"
                            maxlength="10"
                            [(ngModel)]="entity.capacity"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5"
                    type="button"
                    (click)="saveCapacity('required')"
                >
                    {{ "Save" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'addDepartment'">
        <div class="modal-header pl-0">
            <h5>{{ amenity.amenityName }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "departments" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-group col-lg-5 col-md-5 align-left pl-0">
                <label
                    for="deptValue"
                    class="font-regular-gray sm-font text-uppercase"
                    >{{ "selectthedept" | translate }}</label
                >
                <div class="input-group">
                    <mat-form-field appearance="fill">
                        <mat-select
                            [(ngModel)]="departmentValue"
                            name="dept_value"
                            placeholder="{{ 'select' | translate }}"
                            id="dept_value"
                            [ngModelOptions]="{ standalone: true }"
                            (selectionChange)="departmentSelect()"
                        >
                            <mat-option
                                *ngFor="let list of departmentListVal"
                                [value]="list.id"
                            >
                                {{ list.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5"
                    type="button"
                    (click)="saveDepartment()"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div
        class=""
        *ngIf="pageType == 'addOutlook' || pageType == 'editEmailOutlook'"
    >
        <div class="modal-header pl-0 width-400">
            <h5>{{ "outlook" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-2 pl-4">
            <p class="openSans italic mb-0">Office 365 Sync</p>
        </div>
        <div class="modalEmail pl-2">
            <ng-container *ngIf="!alertMissingEmails">
                <div class="col-lg-12 mt-3">
                    <b>{{ "entityName" | translate }}</b>
                </div>
                <form
                    [formGroup]="dynamicForm"
                    (ngSubmit)="onSubmitEnityMail()"
                    class="scroll-email-validator"
                >
                    <!-- <div> -->

                    <ng-container formArrayName="enitityEmail">
                        <div
                            *ngFor="
                                let subItem of dynamicForm.get('enitityEmail')[
                                    'controls'
                                ];
                                let subIdx = index
                            "
                            class="form-group col-lg-12 col-md-12 mt-4"
                            [formGroupName]="subIdx"
                        >
                            <div class="form-group row">
                                <p
                                    for="staticEmail"
                                    class="col-sm-3 col-form-label font-entity-name"
                                >
                                    {{ subItem.get("entityName").value }}
                                </p>
                                <div class="col-sm-5">
                                    <input
                                        type="text"
                                        [ngClass]="{
                                            'error-invalid':
                                                submitted &&
                                                !!loadEntityResponse &&
                                                subItem.get('emailAddress')
                                                    .value &&
                                                getReasonDetails(
                                                    subItem.get('emailAddress')
                                                        .value
                                                ) &&
                                                !getReasonDetails(
                                                    subItem.get('emailAddress')
                                                        .value
                                                ).valid
                                        }"
                                        class="form-control emailOutlook"
                                        id="staticEmail"
                                        formControlName="emailAddress"
                                        placeholder="Enter email ID here"
                                    />
                                </div>
                                <div
                                    class="col-sm-4"
                                    *ngIf="submitted && !!loadEntityResponse"
                                >
                                    <ng-container
                                        *ngIf="
                                            !subItem.get('emailAddress').value
                                        "
                                        style="color: red;"
                                    >
                                        <i
                                            class="fa fa-close mt-3"
                                            style="color:red"
                                        >
                                            {{ "emailIdMissing" | translate }}
                                        </i>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            !!subItem.get('emailAddress')
                                                .value &&
                                            getReasonDetails(
                                                subItem.get('emailAddress')
                                                    .value
                                            ) &&
                                            !getReasonDetails(
                                                subItem.get('emailAddress')
                                                    .value
                                            ).valid
                                        "
                                    >
                                        <i
                                            class="fa fa-close mt-3"
                                            style="color:red"
                                        >
                                            {{
                                                getReasonDetails(
                                                    subItem.get("emailAddress")
                                                        .value
                                                )["reason"]
                                            }}
                                        </i>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            !!subItem.get('emailAddress')
                                                .value &&
                                            getReasonDetails(
                                                subItem.get('emailAddress')
                                                    .value
                                            ) &&
                                            getReasonDetails(
                                                subItem.get('emailAddress')
                                                    .value
                                            ).valid
                                        "
                                    >
                                        <i
                                            class="fa fa-check mt-3"
                                            style="color:green"
                                        ></i>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- </div> -->
                    <div class="modal-body width-400 align-left">
                        <div class="form-group col-12 mb-0 pr-0">
                            <button
                                class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular"
                                type="submit"
                            >
                                {{ "validate" | translate }}
                            </button>
                            <button
                                class="btn btn-primary btn-main btn-radius mr-3 float-right font-regular btn-custom-border"
                                type="button"
                                (click)="proceedEmail()"
                                *ngIf="proceedEmailValidate"
                            >
                                {{ "proceed" | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="alertMissingEmails">
                <div class="mt-4">
                    <p class="col-lg-12 font-regular dark-blue">
                        Entites with missing/invalid/ already in use Email ID's
                        will not be on boarded. Would you still like to proceed?
                    </p>
                    <div class="form-group col-12 mb-3 pb-5">
                        <button
                            class="btn btn-primary btnSmall  btn-main btn-radius mr-2 float-right font-regular prl-5"
                            (click)="proceedSubmitEmail()"
                            *ngIf="proceedEmailValidate"
                        >
                            {{ "yes" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btnSmall  btn-custom-border btn-main btn-radius mr-2 float-right font-regular prl-5"
                            (click)="alertMissingEmails = !alertMissingEmails"
                        >
                            {{ "no" | translate }}
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- <div class="" *ngIf="pageType == 'showAllData'">
        <div class="modal-header pl-0 width-400">
            <h4>{{showAllEmails? "Participants" : 'Details' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left " *ngIf="!showAllEmails">
                <br/>
                <div class="dark-blue" *ngIf="data?.dataArray?.createdByUser"> {{data?.dataArray?.title}} <span class="spanFontSizeSmall" *ngIf="!data?.dataArray?.sameUserCreatedOrDiff && data?.dataArray?.tabType">( Admin Booking: {{data?.dataArray?.userDeatils?.name}} ) </span></div>
                <br/>
                <div class="spanFontSizeLarge  mb-15" *ngIf="data?.dataArray?.createdByUser" >{{"organiser" | translate}} </div> 
                <span class="spanFontSizeSmall" *ngIf="data?.dataArray?.createdByUser"><span class='legendCircle'  >{{getUserLegendName(data.dataArray)}}</span> {{data.dataArray.name}}  </span>
                <span class="spanFontSizeLarge" style=" margin-left:-10px" *ngIf="!data?.dataArray?.createdByUser ">
                    <img *ngIf="data?.dataArray?.userImage" class="roundCircleLarge" [src]="data?.dataArray?.userImage"/> 
                    <span *ngIf="data?.dataArray?.userImage == null" class='legendCircle'  >{{getUserLegendName(data.dataArray)}}</span> 
                    {{data.dataArray.name}} {{!data.dataArray?.createdByUser? " Invited you for "+data?.dataArray?.title :""}} 
                </span>   
                <br/> <br/>
                <div class="spanFontSizeLarge"> {{"when" | translate}}</div> 
                    <span class="spanFontSizeSmall">  {{data?.dataArray?.requestDetails?.startTime | date: 'd -MMMM y':'UTC' }}</span>
                <br/> <br/>
                <div class="spanFontSizeLarge">{{"time" | translate}} </div>   
                 <span class="spanFontSizeSmall"> {{getBookTime(data.dataArray)}}</span> 
                    <span class="spanFontSizeSmall"> {{getBookTime(data.dataArray,"both")}}</span>
                <br/> <br/>
                <div class="spanFontSizeLarge"> {{"location" | translate}}</div> 
                    <span class="spanFontSizeSmall"> {{data?.dataArray?.zoneLocationDto?.name}}, {{data?.dataArray?.zoneLocationDto?.address}}</span>
                <br/> <br/>
                <ng-container *ngIf="data?.dataArray?.amenityArr != 'NA'">
                    <div class="spanFontSizeLarge" > {{"specialrequest" | translate}}</div> 
                    <span class="spanFontSizeSmall" > {{data?.dataArray?.amenityArr}}</span>
                    <br/> <br/>
                </ng-container>
                <ng-container *ngIf="!!data?.enableParticipants && !!data?.dataArray?.integrationId">
                    <div class="spanFontSizeLarge" > {{data?.dataArray?.meetingLinkProvider | titlecase }} Link </div> 
                    <span *ngIf="!!data?.dataArray?.joinUrl">
                        <a class="spanFontSizeSmall" [href]="data?.dataArray?.joinUrl" 
                        target="_blank">{{data?.dataArray?.joinUrl}}</a>
                    </span>
                    <span class="spanFontSizeSmall" *ngIf="!data?.dataArray?.joinUrl">NA</span>
                    <br/> <br/>
                </ng-container>
                <div class="spanFontSizeLarge" *ngIf="data?.dataArray?.participantList != null"> {{"participants" | translate}}</div>
                <div class="rowJustifyContent" *ngIf="data?.dataArray?.participantList != null">
                    <span class="spanFontSizeSmall mt-2"> {{getParticipants(data?.dataArray) | slice:0:75}}.. </span>
                    <span class="align-right"   *ngIf="data?.dataArray?.participantList != null">
                        <a  class="spanAchor" (click)="ShowAllEmail(data?.dataArray?.participantList)">   {{"viewall" | translate}}</a>
                       </span>
                </div>  
                <div class="card cardYesMaybeNo"  *ngIf="data.currentTab !='Manage Booking' && !data?.externalBooking && !data?.dataArray?.createdByUser && data?.dataArray?.showIcons && isUserParticipantsOrNot(data?.dataArray)">
                    <div class="spanFontSizeLarge ml-23" >{{'participants' | translate}} ?</div>
                    <div class="rowStyleForPopup">
                        <span  (click)="statusChange(data,'ACCEPTED')" [class]="data?.dataArray?.participantStatus == 'ACCEPTED' ? 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-Activecolor' : 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-color'">{{"yes" | translate}}</span> 
                        <span  (click)="statusChange(data,'TENTATIVE')" [class]="data?.dataArray?.participantStatus == 'TENTATIVE' ? 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-Activecolor' : 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-color'" >{{"maybe" | translate}}</span> 
                        <span  (click)="statusChange(data,'DECLINED')" [class]="data?.dataArray?.participantStatus == 'DECLINED' ? 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-Activecolor' : 'spanFontSizeSmall buttonYesMaybeNo btn btn-default btn-radius mr-2 float-right prl-5 btn-color'">{{"no" | translate}}</span></div>
                </div>  
        </div>
        <div class="modal-body align-left " *ngIf="showAllEmails">
             <br/> 
            <div class="dark-blue">{{data?.dataArray?.title}}</div>
            <div class="row mt-2">
                <div class="col-12" *ngFor="let sendMail of participantsArray; let i = index"
                    style="margin: 10px 0px 10px 0px;">
                    <div >
                    <span class="spanFontSizeSmall"><span class="legendCircle">{{getUserLegendName1(sendMail)}}</span> {{sendMail.user? sendMail.user?.email:sendMail.guestEmail}} </span>
                    <span class="align-right">
                        <img *ngIf="sendMail['participantStatus'] == 'ACCEPTED'" class="pull-right" src="assets/images/IconCheckCircleRight.png" style="width: 30px;height: 30px;"/>
                        <img *ngIf="sendMail['participantStatus'] == 'TENTATIVE' 
                                    || sendMail['participantStatus'] == 'PENDING'"  class="pull-right" src="assets/images/iconcheckquestion.png" style="width: 30px;height: 30px;"/>
                        <img *ngIf="sendMail['participantStatus'] == 'DECLINED'"  class="pull-right" src="assets/images/iconcheckfailed.png" style="width: 30px;height: 30px;"/>
                    </span>
                </div> 
                </div>
            </div>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="form-group col-12 mb-0 p-0">
                <div *ngIf="showAllEmails">
                    <img src="assets/images/IconCheckCircleRight.png" style="width: 30px;height: 30px;"/> <span class="spanFontSizeSmall mr-4"> {{"yes" | translate}}</span> 
                    <img src="assets/images/iconcheckquestion.png" style="width: 30px;height: 30px;"/> <span class="spanFontSizeSmall mr-4"> {{"maybe" | translate}}</span> 
                    <img src="assets/images/iconcheckfailed.png" style="width: 30px;height: 30px;"/> <span class="spanFontSizeSmall mr-4"> {{"no" | translate}}</span> 
                </div>
                <button class="btn btn-primary btn-main btn-radius mr-2 float-right font-regular prl-5" type="button" (click)="closePopup()">
                    {{'done' | translate}}
                </button>
               
            </div>
        </div>
    </div> -->

    <div class="container" *ngIf="data.type == 'myBookings'">
        <form
            class="form-group"
            id="booking_details"
            [formGroup]="registerForm"
        >
            <div class="modal-header pl-0">
                {{ "search" | translate }}
                <button
                    id="reset_booking"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    (click)="closePopup()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-left">
                <label for="searchCategory">{{'searchBy' |translate }}</label>
                <div class="input-group">
                    <mat-form-field appearance="fill">
                        <mat-select
                            placeholder="{{ 'select' | translate }}"
                            id="locationSearch"
                            formControlName="searchBy"
                            [ngClass]="{
                                'is-invalid': submitted && l.searchBy.errors
                            }"
                        >
                            <mat-option
                                *ngFor="
                                    let item of data.searchCriteria;
                                    let i = index
                                "
                                value="{{ item.searchKey }}"
                            >
                                {{ getTranslate(item.name) }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div
                        *ngIf="submitted && l.searchBy.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="l.searchBy.errors.required">
                            {{ "Pleaseselectthecategory" | translate }}
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <button
                        id="apply_btn"
                        type="button"
                        id="apply-filter"
                        class="btn btn-main btn-apply font-regular"
                        (click)="getSearchCategory()"
                    >
                        {{ "apply" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div class="" *ngIf="pageType == 'proceedToDeleteCommonPath'">
        <div class="modal-header pl-0">
            <h5>{{ "alert" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="proceedClose('deleteCommonPathClosePopup')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                <b
                    >Do you want to delete the common path?<br /><br />
                    The way finding shown for the entities will be reset.</b
                >
            </p>
        </div>
        <div class="modal-body width-400 ">
            <div class="form-group col-12 mb-0 ">
                <button
                    class="btn btn-primary btnSmall  btn-main btn-radius mr-2  font-regular prl-5"
                    type="button"
                    (click)="proceedClose('deleteCommonPathNo')"
                >
                    {{ "no" | translate }}
                </button>
                <button
                    class="btn btn-primary btnSmall  btn-main btn-radius mr-2  font-regular prl-5"
                    type="button"
                    (click)="proceedClose('deleteCommonPathYes')"
                >
                    {{ "yes" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'edit_category'">
        <div class="modal-header pl-0">
            <h5>{{ "edit" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="editCategorySubmit()">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <input
                            type="text"
                            class="form-control blue-regular"
                            id="category_value"
                            placeholder=""
                            name="edit_category_value"
                            maxlength="30"
                            [(ngModel)]="edit_category_name"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "save" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'edit_addons'">
        <div class="modal-header pl-0">
            <h5>{{ "edit" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="editAddonSubmit()">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <input
                            type="text"
                            class="form-control"
                            id="addon_value"
                            placeholder=""
                            name="edit_addon_value"
                            maxlength="100"
                            [(ngModel)]="edit_addon_name"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "save" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'edit_special_req'">
        <div class="modal-header pl-0">
            <h5>{{ "edit" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="editSpecialReqSubmit()">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <input
                            type="text"
                            class="form-control blue-regular"
                            id="special_req_value"
                            placeholder=""
                            name="edit_special_req_value"
                            maxlength="30"
                            [(ngModel)]="edit_special_req_name"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                    <div class="form-group col-12 text-left mb-0">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular"
                            type="submit"
                        >
                            {{ "save" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'delete_addons'">
        <div class="modal-header pl-0">
            <h5>{{ "deleteAddon" | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="openSans italic pl-2">{{ "basicSetup" | translate }}</p>
        </div>
        <div class="modal-body width-400 align-left">
            <form (ngSubmit)="deleteAddonSubmit()">
                <div class="form-row">
                    <div class="form-group align-left col-12">
                        <p>
                            <span>{{ "deleteSelection" | translate }}?</span>
                        </p>
                    </div>
                    <div class="form-group col-12 text-left mb-0 mt-3">
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius font-regular mr-2 btn-main-outline float-right"
                            (click)="removeDelete()"
                            type="reset"
                        >
                            {{ "no" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btnSmall btn-radius font-regular mr-2 float-right"
                            type="submit"
                        >
                            {{ "yes" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'proceedWithOnboarding'">
        <div class="modal-header pl-0">
            <h5>{{'alert' | translate}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                {{ "youhaventonboardedtheentites" | translate }}.<br /><br />
                Do you want to delete the uploaded file?
            </p>
        </div>
        <div class="modal-body width-400 ">
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btn-main btnSmall mr-2 btn-radius btn-custom-border font-regular"
                    type="button"
                    (click)="closePopup()"
                >
                    {{ "no" | translate }}
                </button>
                <button
                    class="btn btn-primary btn-main btnSmall mr-2 btn-radius font-regular"
                    type="button"
                    (click)="proceedClose('deleteNewFloorYes')"
                >
                    {{ "yes" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'proceedWithUnmappedEntities'">
        <div class="modal-header pl-0">
			<h5>{{'alert' | translate}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                {{'oneOrMoreEntitiesHaveBeenMappedToTheCommonPath' | translate}}
            </p>
        </div>
        <div class="modal-body width-400 ">
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btnSmall  btn-main btn-radius mr-2  font-regular prl-5"
                    type="button"
                    (click)="closePopup()"
                >
                    {{ "no" | translate }}
                </button>
                <button
                    class="btn btn-primary btnSmall  btn-main btn-radius mr-2  font-regular prl-5"
                    type="button"
                    (click)="proceedClose('proceedWithUnmapping')"
                >
                    {{ "yes" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'proceedToConnectStartPointWithCP'">
        <div class="modal-header pl-0">
            <h5>{{'alert' | translate}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4">
            <p class="openSans italic mt-2">Edit Start Point</p>
        </div>
        <div class="col-12">
            <p class="textAlign font-regular-gray">
                {{
                    "pleaseConnectTheNewStartPointWithTheCommonPath" | translate
                }}
            </p>
        </div>
        <div class="modal-body width-400 ">
            <div class="form-group col-12 mb-0">
                <button
                    class="btn btn-primary btn-main btn-radius mr-2  prl-5 font-regular float-right"
                    type="button"
                    (click)="confirmOk()"
                >
                    {{ "proceed" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'proceedToDisableOffice365'">
        <div class="modal-header pl-0">
            <h5>
                <i
                    class="fa fa-angle-left cursor-pointer mr-2 backBtn"
                    aria-hidden="true"
                    (click)="openPrevModal()"
                ></i>
                {{ "alert" | translate }}
            </h5>
            <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
              <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">
                {{ "configureTools" | translate }}
            </p>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                {{ "proceedToDisableOfficeIntegration" | translate }}
            </p>
        </div>
        <div
            class="modal-body width-400 "
            style="padding-right: 15px !important;"
        >
            <div class="form-group col-12 mb-0 pr-0">
                <button
                    class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                    type="button"
                    (click)="videoConfigToolsSubmit()"
                >
                    {{ "yes" | translate }}
                </button>
                <button
                    class="btn btn-primary btn-main btnSmall btn-radius mr-2 float-right font-regular"
                    type="button"
                    (click)="openPrevModal()"
                >
                    {{ "no" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'proceedToSkipOfficeIntegration'">
        <div class="modal-header pl-0">
            <h5>{{'alert' | translate}}</h5>
            <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
              <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">Office 365 Emails</p>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                {{ "office365HasBeenIntegratedDuringSetUp" | translate }}
            </p>
            <p class="textAlign blue-regular">
                {{ "proceedToSkipOfficeIntegration" | translate }}
            </p>
        </div>
        <div
            class="modal-body width-400 "
            style="padding-right: 15px !important;"
        >
            <div class="form-group col-12 mb-0 pr-0">
                <button
                    class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                    type="button"
                    (click)="proceedToSkipOfficeIntegrate()"
                >
                    {{ "yes" | translate }}
                </button>
                <button
                    class="btn btn-primary btn-main btnSmall btn-radius mr-2 float-right font-regular"
                    type="button"
                    (click)="closePopup()"
                >
                    {{ "no" | translate }}
                </button>
            </div>
        </div>
    </div>

    <!-- Download with date Range -->
    <div *ngIf="data.type == 'downloadDateSelect'">
        <div class="modal-header">
            <h6 class="dark-blue pl-1">
                <strong>{{ "downloadReport" | translate }}</strong>
            </h6>
            <button
                type="button"
                class="close pr-0"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-1">
            <p class="dark-blue1 font-italic pl-2">
                {{ data["selectedAmenityName"] | translate }}
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <p class="dark-blue1 mb-2">
                {{ "downloadReportFrom" | translate }}
            </p>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="selectedType"
                (change)="downloadReportTypeChange($event)"
            >
                <mat-radio-button
                    class="example-radio-button"
                    *ngFor="let type of filterType"
                    [value]="type.id"
                >
                    {{ type["name"] }}
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="selectedType == 'custom_date'">
                <div class="row mt-2">
                    <div class="form-group col-md-4 col-lg-4">
                        <label class="font-weight-bold"
                            >{{ "startDate" | translate }}
                        </label>
                        <div class="input-group" [owlDateTimeTrigger]="dt">
                            <input 
                                class="form-control"
                                [(ngModel)]="startDateFormatted"
                                placeholder="{{ 'startDate' | translate }}"
                                readonly
                            />
                            
                            <span [owlDateTimeTrigger]="dt"
                                ><i class="fa fa-calendar fa-calender-icons calendar-icon"></i
                            ></span>
                            <input 
                                id="start_date"
                                class="calendarHidden"
                                name="startDate"
                                placeholder="{{ 'startDate' | translate }}"
                                [owlDateTimeTrigger]="dt"
                                [owlDateTime]="dt"
                                [(ngModel)]="startDate"
                                (ngModelChange)="startDateChange()"
                                readonly
                            />
                            <owl-date-time
                                [pickerType]="'calendar'"
                                #dt
                            ></owl-date-time>
                        </div>
                    </div>
                    <div class="form-group col-md-4 col-lg-4">
                        <label class="font-weight-bold"
                            >{{ "endDate" | translate }}
                        </label>
                        <div class="input-group" [owlDateTimeTrigger]="ed">
                            <input 
                                class="form-control"
                                [(ngModel)]="endDateFormatted"
                                placeholder="{{ 'startDate' | translate }}"
                                readonly
                            />
                            
                            <span [owlDateTimeTrigger]="ed"
                                ><i class="fa fa-calendar fa-calender-icons calendar-icon"></i
                            ></span>
                            <input
                                id="end_date"
                                class="calendarHidden"
                                name="endDate"
                                placeholder="{{ 'endDate' | translate }}"
                                [owlDateTimeTrigger]="ed"
                                [owlDateTime]="ed"
                                [min]="startDate"
                                [max]="maxAllowedEndDate"
                                [(ngModel)]="endDate"
                                (ngModelChange)="endDateChange($event)"
                                readonly
                            />
                            <owl-date-time
                                [pickerType]="'calendar'"
                                #ed
                            ></owl-date-time>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col">
                    <button
                        type="button"
                        class="btn btn-main float-right rounded-pill"
                        (click)="downloadBookings()"
                    >
                        {{ "download" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

   


    <div *ngIf="data.type == 'downloadDateSelectAnalytics'">
        <div class="modal-header">
            <h6 class="dark-blue pl-1">
                <strong>{{ "downloadReport" | translate }}</strong>
            </h6>
            <button
                type="button"
                class="close pr-0"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-1">
            <p class="dark-blue1 font-italic pl-2">
                {{ data["selectedAmenityName"] | translate }}
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <p class="dark-blue1 mb-2">
                {{ "downloadReportFrom" | translate }}
            </p>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="selectedTypeAnalytics"
                (change)="downloadReportTypeChangeAnalytics($event)"
            >
                <mat-radio-button
                    class="example-radio-button"
                    *ngFor="let type of filterTypeAnalytics"
                    [value]="type.id"
                >
                    {{ type["name"] }}
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="selectedTypeAnalytics == 'fire_safety'">
                <div class="row mt-2">
                    <div class="form-group col-md-4 col-lg-4">
                        <label class="font-weight-bold"
                            >{{ "date" | translate }}
                        </label>
                        <div class="input-group">
                            <input
                                id="fire_safety_date"
                                class="form-control"
                                name="fireSafetyDate"
                                placeholder="{{ 'selectDate' | translate }}"
                                [owlDateTimeTrigger]="dt"
                                [owlDateTime]="dt"
                                [(ngModel)]="fireSafetyDate"
                                readonly
                            />
                            <span [owlDateTimeTrigger]="dt"
                                ><i class="fa fa-calendar fa-calender-icons"></i
                            ></span>
                            <owl-date-time
                                [pickerType]="'calendar'"
                                #dt
                            ></owl-date-time>
                        </div>
                    </div>
                   
                </div>
            </ng-container>
            <ng-container *ngIf="selectedTypeAnalytics == 'work_from_office'">
                <div class="row mt-2">
                    <div class="form-group col-md-4 col-lg-4">
                        <label class="font-weight-bold"
                            >{{ "startDate" | translate }}
                        </label>
                        <div class="input-group">
                            <input
                                id="start_date_wfo"
                                class="form-control"
                                name="startDateWFO"
                                placeholder="{{ 'selectStartDate' | translate }}"
                                [owlDateTimeTrigger]="dt"
                                [owlDateTime]="dt"
                                [(ngModel)]="startDateWFO"
                                readonly
                            />
                            <span [owlDateTimeTrigger]="dt"
                                ><i class="fa fa-calendar fa-calender-icons"></i
                            ></span>
                            <owl-date-time
                                [pickerType]="'calendar'"
                                #dt
                            ></owl-date-time>
                        </div>
                    </div>
                    <div class="form-group col-md-4 col-lg-4">
                        <label class="font-weight-bold"
                            >{{ "endDate" | translate }}
                        </label>
                        <div class="input-group">
                            <input
                                id="end_date_wfo"
                                class="form-control"
                                name="endDateWFO"
                                placeholder="{{ 'selectEndDate' | translate }}"
                                [owlDateTimeTrigger]="ed"
                                [owlDateTime]="ed"
                                [min]="startDateWFO"
                                [(ngModel)]="endDateWFO"
                                readonly
                            />
                            <span [owlDateTimeTrigger]="ed"
                                ><i class="fa fa-calendar fa-calender-icons"></i
                            ></span>
                            <owl-date-time
                                [pickerType]="'calendar'"
                                #ed
                            ></owl-date-time>
                        </div>
                    </div>
                </div>
            </ng-container>
<ng-container *ngIf="fireSafetyDate!=null || (startDateWFO!=null && endDateWFO!=null )  " >
            <div class="row">
                <div class="col">
                    <button
                        type="button"
                        class="btn btn-main float-right rounded-pill"
                        (click)="downloadAnalytics()"
                    >
                        {{ "download" | translate }}
                    </button>
                </div>
            </div>
            </ng-container>
        </div>
    </div>




    <div *ngIf="pageType == 'bookNow-VCLinkEnable'">
        <div class="modal-header pl-0">
            <h5>{{'alert' | translate}}</h5>
            <button
                type="button"
                class="close pr-0"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-1">
            <p class="openSans ml-2 italic">
                {{ "videoConferencingLinks" | translate }}
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="row">
                <p class="pConfirmationMessage">
                    {{ "vcLinkNotSelectedMessage" | translate }}
                </p>
            </div>
            <div class="row mt-3">
                <div class="col pr-0">
                    <div class="flex-container">
                        <button
                            class="btn btn-White btn-main btnSmall rounded-pill mr-2 font-regular"
                            type="button"
                            (click)="vcLinkConfirmationMessage('No')"
                        >
                            {{ "no" | translate }}
                        </button>
                        <button
                            class="btn btn-primary btn-main btnSmall rounded-pill mr-2 font-regular"
                            type="button"
                            (click)="vcLinkConfirmationMessage('Yes')"
                        >
                            {{ "yes" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="pageType == 'bookNow-VCLinkOffice365'">
        <div class="modal-header pl-0">
            <h5>{{'alert' | translate}}</h5>
            <button
                type="button"
                class="close pr-0"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <!-- <div class="col-12 mt-1">
            <p class="openSans ml-2 italic">
                {{ "videoConferencingLinks" | translate }}
            </p>
        </div> -->
        <div class="modal-body width-400 align-left">
            <div class="row">
                <p class="pConfirmationMessage">
                    <!-- {{
                        "YourmeetingrequesthasbeensharedwiththeOffice365"
                            | translate
                    }}.
                    {{
                        "Youwillbenotifiedonthestatusofthesameshortly"
                            | translate
                    }}. -->
                    {{data['message']}}
                </p>
            </div>
            <div class="row mt-3">
                <div class="col pr-0">
                    <div class="flex-container">
                        <button
                            class="btn btn-primary btn-main btnSmall rounded-pill mr-2 font-regular"
                            type="button"
                            (click)="vcLinkOffice365Msg()"
                        >
                            {{ "ok" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="pageType == 'bookNowEdit-UpdateVCLink'">
        <div class="modal-header pl-0">
            <h5>{{'alert' | translate}}</h5>
            <button
                type="button"
                class="close pr-0"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 mt-1">
            <p class="openSans ml-2 italic">
                {{ "videoConferencingLinks" | translate }}
            </p>
        </div>
        <div class="modal-body width-400 align-left">
            <ng-container *ngIf="!data.vcLinkEnabled">
                <div class="row">
                    <p class="pConfirmationMessage">
                        {{ "vcLinkUpdateMessage" | translate }}
                    </p>
                </div>
                <div class="row mt-3">
                    <div class="col pr-0">
                        <div class="flex-container">
                            <button
                                class="btn btn-White btn-main btnSmall rounded-pill mr-2 font-regular"
                                type="button"
                                (click)="updateVCLinkMessage('No')"
                            >
                                {{ "no" | translate }}
                            </button>
                            <button
                                class="btn btn-primary btn-main btnSmall rounded-pill mr-2 font-regular"
                                type="button"
                                (click)="updateVCLinkMessage('Yes')"
                            >
                                {{ "yes" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="" *ngIf="pageType == 'disableAmenity'">
        <div class="modal-header pl-0">
            <h5>{{data["checked"]?getTranslate('activate') : getTranslate('deactivate')}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12">
            <p class="textAlign dark-blue">
                {{"areYouLikePerformAction" | translate}}
            </p>
        </div>
        <div class="flex-container mr-3 pb-3">
            <button
                class="btn btn-primary btn-White btn-radius pl-4 pr-4"
                type="button"
                (click)="disableAmenity('No')">
                {{"no" | translate}}
            </button>
            <button
                class="btn btn-primary btn-main btn-radius pl-4 pr-4 ml-2"
                type="button"
                (click)="disableAmenity('Yes')">
                {{"yes" | translate}}
            </button>
        </div>
    </div>

    <div *ngIf="pageType == 'moveEntityWithWayFindingConfirmation'">
        <div class="modal-header pl-0">
            <h5>{{'confirmation' | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left">
            <div class="row">
                <p class="pConfirmationMessage">{{'wayFindingReconfigureConfirmation' | translate}}</p>
                <p class="pConfirmationMessage">{{'Would you like to proceed?'}}</p>
            </div>
            <div class="row">
                <div class="col pr-0">
                    <div class="flex-container">
                        <button class="btn btn-White btn-main btnSmall rounded-pill mr-2 font-regular" type="button"
                            (click)="wayFindingConfirmMessage('No')">{{'no' | translate}}
                        </button>
                        <button class="btn btn-primary btn-main btnSmall rounded-pill mr-2 font-regular" type="button"
                            (click)="wayFindingConfirmMessage('Yes')">{{'yes' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   
    <div>
        <div class="rounded-0" *ngIf="pageType == 'utlsettings'">
            <div class="modal-header pl-0">
                <h5> Utilisation Settings </h5>
                <button type="button" class="close" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="utlForm" (ngSubmit)="onUtilSubmit()">
                    <div class="row inner-div">
                        <div class="col-4">
                            <div class="row pad-right-15">
                                <label class="font-weight-bold">{{'UTILISATION START TIME' | translate}}</label>
                                
                                <mat-select class="form-control align-left" [(ngModel)]="timeList.selectedStartTime"
                                    formControlName="utl_strt_time"
                                    (selectionChange)="changeTimings(timeList.selectedStartTime)" [ngClass]="{
                                    'is-invalid-2':
                                    ultSubmit && utlForm.controls.utl_strt_time.invalid
                                    }" placeholder="{{'Select Start Time' | translate}}">
                                    <mat-option *ngFor="let item of timeList.startTimeList; let i = index"
                                        [value]="item.timeVlaue">
                                        {{item.timeWithAMPM}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="row pad-right-15">
                                <label class="font-weight-bold">{{'UTILISATION BANCH MARK' | translate}}</label>
                                <input type="text" class="form-control align-left" [(ngModel)]="timeList.utlBenchMark"
                                    formControlName="utl_benchmark" [ngClass]="{'is-invalid-2': ultSubmit &&
                                  utlForm.controls.utl_benchmark.invalid}">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row pad-right-15">
                                <label class="font-weight-bold">{{'UTILISATION END TIME' | translate}}</label>
                                <mat-select class="form-control align-left" [(ngModel)]="timeList.selectedEndTime"
                                    formControlName="utl_end_time" [ngClass]="{'is-invalid-2': ultSubmit &&
                                  utlForm.controls.utl_end_time.invalid}"
                                  placeholder="{{'Select End Time' | translate}}">
                                    <mat-option *ngFor="let item of timeList.endTimeList; let i = index"
                                        [value]="item.timeVlaue">
                                        {{item.timeWithAMPM}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="row pad-right-15">
                                <label class="font-weight-bold">{{'INSTANT BOOKING DURATION' | translate}}</label>
                                <mat-select class="form-control align-left" [(ngModel)]="timeList.instantBkngDur"
                                    formControlName="utl_bkng_drn" [ngClass]="{'is-invalid-2': ultSubmit &&
                                  utlForm.controls.utl_bkng_drn.invalid}"
                                  placeholder="{{'Select Booking Duration' | translate}}">
                                    <mat-option *ngFor="let item of timeList.instantBokngDrn; let i = index"
                                        [value]="item.drn">
                                        {{item.drnTime}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row pad-right-15">
                                <!-- {{item | json}} -->
                                <label class="font-weight-bold">{{'WORK DAYS' | translate}}</label>
                                
                                <mat-select class="form-control align-left"
                                    placeholder="{{'Select Workdays' | translate}}" [(ngModel)]="timeList.workdaysSelect"
                                    formControlName="utl_work_days" [ngClass]="{'is-invalid-2': ultSubmit &&
                                  utlForm.controls.utl_work_days.invalid}" multiple>
                                    <mat-option *ngFor="let item of timeList.workdays"
                                        [value]="item.weekDayIndex">
                                        {{item.weekDayName}}
                                    </mat-option>
                                </mat-select>
                            
                            </div>
                        </div>
                    </div>
                    <div class="base-messages">
                        <p>* Utilisation will be calculated by above parameter selected</p>
                        <button class="btn btn-main btnSmall btn-radius font-regular" type="submit" *ngIf="utlForm.valid">
                            {{"Save" | translate}} </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div *ngIf="pageType == 'onboarding_choice'">
        <div class="modal-header pl-0 pr-0">
            <h5>{{'onboarding' | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left mt-2">
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group1 mt-0"
                [(ngModel)]="onboardingChoice">
                <mat-radio-button class="example-radio-button" value="map">{{'map' | translate}}
                </mat-radio-button>
                <mat-radio-button class="example-radio-button mt-3" value="excel">{{'Excel' | translate}}
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="onboardingChoice === 'map'">
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn btn1 btn-main float-right rounded-pill" (click)="proceedMapOnboarding()">
                            {{'proceed' | translate }}
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="onboardingChoice == 'excel'">
                <div class="row mt-4">
                    <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 form-group">
                        <button type="button" class="form-control1" (click)="onboardAndDownloadExcelTemplate()">
                            {{'downloadTemplate' | translate}}
                            <span class="ml-1"><img src="./assets/images/downloadTemplate.png"
                                    width="12"/></span>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                        <label for="uploadFile" class="label1">{{ "upload" | translate }} (.xlsx
                            {{ "file" | translate }})</label>
                        <div class="input-group">
                            <input 
                                type="file" 
                                #fileInput
                                class="form-control custom-file-input" 
                                id="importFile"
                                (click)="fileInput.value = null"
                                aria-describedby="inputGroupFileAddon01" 
                                accept=".xlsx"
                                (change)="uploadCSV($event.target.files)"
                            />
                            <label 
                                class="custom-file-label1 custom-upload-btn" 
                                for="inputGroupFile01">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-4" *ngIf="showUploadedCSVFileName">
                    <p class="pConfirmationMessage custom-shadow ml-0">
                        {{showUploadedCSVFileName}}
                        <span class="ml-4 cursor-pointer font-weight-bold" (click)="showUploadedCSVFileName = undefined">×</span>
                    </p>
                </div>
                <div class="row" *ngIf="showUploadedCSVFileName">
                    <div class="col">
                        <button type="button" class="btn btn1 btn-main float-right rounded-pill" (click)="validateCSVOnboarding()">
                            {{'proceed' | translate }}
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="pageType == 'onboardingCSVTable'">
        <div class="modal-header pl-0 pr-0">
            <h5>{{'alert' | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left mt-2 pb-0">
            <ng-container *ngIf="incorrectData.length>0">
                <p class="regular-semibold-font">
                    {{'belowAreTheListOfEntitiesWhichHaveIssues' | translate}}.
                </p>    
                <table mat-table [dataSource]="showIncorrectData">
                    <!-- Sl No -->
                    <!-- <ng-container matColumnDef="slNo">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "SlNo" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                            class="align-left"
                        >
                            {{ element.slNo }}
                        </td>
                    </ng-container> -->
    
                    <!-- First Name Column -->
                    <ng-container matColumnDef="entityName">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "Entity name" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'entityName'
                                )
                            }"
                            [matTooltip]="getToolTipData(element.entityName)"
                        >
                            {{ element.entityName | slice: 0:20 }}
                            <span *ngIf="element.entityName?.length > 20"
                                >...</span
                            >
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "Category" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'categories'
                                )
                            }"
                            [matTooltip]="getToolTipData(element.categories)"
                        >
                            {{ element.categories | slice: 0:20 }}
                            <span *ngIf="element?.categories?.length > 20">...</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="addons">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "Addons" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'addons'
                                )
                            }"
                            [matTooltip]="getToolTipData(element.addons)"
                        >
                            {{ element.addons | slice: 0:20 }}
                            <span *ngIf="element.addons?.length > 20">...</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="specialRequests">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "Special Requests" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes('specialRequests')
                            }"
                            [matTooltip]="getToolTipData(element.specialRequests)"
                        >
                            {{ element.specialRequests | slice: 0:20 }}
                            <span *ngIf="element.specialRequests?.length > 20">...</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef class="align-left">
                            {{ "Department" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'departments'
                                )
                            }"
                            [matTooltip]="getToolTipData(element.departments)"
                        >
                            {{ element.departments | slice: 0:20 }}
                            <span *ngIf="element.departments?.length > 20">...</span>
                        </td>
                    </ng-container>

                    <!-- Capacity is only for Collab Spaces -->
                    <ng-container *ngIf="isCollabSpace">
                        <ng-container matColumnDef="capacity">
                            <th mat-header-cell *matHeaderCellDef class="align-left">
                                {{ "Capacity" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [ngClass]="{
                                    'is-incorrect': element.errorFields.includes(
                                        'capacity'
                                    )
                                }"
                                [matTooltip]="getToolTipData(element.capacity)"
                            >
                                {{ element.capacity }}
                            </td>
                        </ng-container>    
                    </ng-container>

                    <!-- Reason Column -->
                    <ng-container matColumnDef="reason">
                        <th mat-header-cell *matHeaderCellDef class="align-left" style="width: 20vw;">
                            {{ "reason" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left"
                            [matTooltip]="getToolTipData(element.reason)"
                        >
                            <span>{{ element.reason | slice: 0:30 }}
                                <ng-container *ngIf="element.reason?.length > 30">...</ng-container>
                            </span>
                        </td>
                    </ng-container>
    
                    <tr
                        mat-header-row
                        *matHeaderRowDef="incorrectDataColumns"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: incorrectDataColumns"
                    ></tr>
                </table>
                <ng-container *ngIf="totalIncorrectLength>10">
                    <mat-paginator
                        class="w-100"
                        #paginator
                        [pageSize]="pageSize"
                        [pageSizeOptions]="[10,25,100]"
                        [showFirstLastButtons]="true"
                        (page)="getNextRecords($event)"
                        [length]="totalIncorrectLength"
                    ></mat-paginator>
                </ng-container>

                <div class="row text-center button-alignToEnd p-2 mt-2">
                    <button
                        class="btn btn-primary btn-main btn-radius font-regular"
                        (click)="reUploadEntities()">
                        {{ "reuploadFilesWithFixes" | translate }}
                    </button>
                    <button
                        class="btn btn-primary btn-main-white-nb btn-radius regular-semibold-font ml-4"
                        (click)="proceedToOnboard()">
                        {{ "proceedToOnboardTheRemainingEntries" | translate }}
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="correctData.length>0 && incorrectData.length == 0">
                <p class="regular-semibold-font">
                    {{ 'successfullyUploaded' | translate }} 
                    {{ noOfEntitiesUploaded }} entities, 
                    {{ 'proceedToOnboard' | translate }}.
                </p>
                <button
                    class="btn btn1 btn-primary btn-main btn-radius font-regular float-right"
                    (click)="proceedToOnboard()">
                    {{ "proceed" | translate }}
                </button>
            </ng-container>
        </div>
    </div>

    <div *ngIf="pageType == 'onboarding_reUploadCSV'">
        <div class="modal-header pl-0 pr-0">
            <h5>{{'reUploadFile' | translate }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left mt-2">
            <div class="row">
                <div class="col-12 pl-2">
                    <img
                        class="cursor-pointer"
                        src="../../assets/images/jumpree_digital/download.png"
                        (click)="downloadCurrentCSVFile()"
                        width="45"
                    />
                    <span class="regular-semibold-font">
                        {{'downloadCurrentFile' | translate}}
                    </span>
                </div>
            </div>
            <p class="regular-semibold-font mt-3">
                {{ 'uploadFileWithFixes' | translate }} 
            </p>    
            <div class="row">
                <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                    <label for="uploadFile" class="label1">{{ "upload" | translate }} (.xlsx
                        {{ "file" | translate }})</label>
                    <div class="input-group">
                        <input 
                            type="file" 
                            #fileInput
                            class="form-control custom-file-input" 
                            id="importFile"
                            (click)="fileInput.value = null"
                            aria-describedby="inputGroupFileAddon01" 
                            accept=".xlsx"
                            (change)="uploadCSV($event.target.files)"
                        />
                        <label 
                            class="custom-file-label1 custom-upload-btn" 
                            for="inputGroupFile01">
                        </label>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-4" *ngIf="showUploadedCSVFileName">
                <p class="pConfirmationMessage custom-shadow ml-0">
                    {{showUploadedCSVFileName}}
                    <span class="ml-4 cursor-pointer font-weight-bold" (click)="showUploadedCSVFileName = undefined">×</span>
                </p>
            </div>
            <div class="row" *ngIf="showUploadedCSVFileName">
                <div class="col">
                    <button type="button" class="btn btn1 btn-main float-right rounded-pill" (click)="validateCSVOnboarding()">
                        {{'proceed' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="" *ngIf="pageType == 'customEmailManage'">
        <div class="modal-header pl-0">
            <h5>All Emails</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-12 pl-4">
            <p class="italic mt-2 openSans">Booking &gt; Settings</p>
        </div>
       
        <div class="modal-body width-400 align-left">
            <div >
                <div class="row col-12 d-flex align-items-center  mx-0 p-0">
                   <div class="form-group col-lg-6 col-md-4 col-sm pr-0 p-0">
                       <label class="font-label-dbs ">EMAIL ID</label>
                       <div class="input-group ">
                           <span class="input-group-append">
                               <div class="input-group-text bg-transparent form-control">
                                   <i class="fa fa-search"></i>
                               </div>
                           </span>
                          
                                <input [(ngModel)]="requesterName" type="text" #searchTerm="ngModel" [ngModelOptions]="{standalone: true}"
                                class="form-control" name="user-dep" id="user-dep" autocomplete="off" placeholder="Search Email" 
                                (input)="getBookingAutocompleteList(searchTerm.value)" [matAutocomplete]="auto"   />
                                <span class="input-group-append" *ngIf="showLoader"  >
                                    <div class="input-group-text bg-transparent form-control borderCurved">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </div>
                                </span> 
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let dropData of bookingAutocompleteList$ | async"
                                      [value]="dropData.email.trim()" (click)="onselectUser (dropData)">
                                    <span>{{dropData?.email}}</span>
                                     </mat-option>
                                </mat-autocomplete>     
                           <!-- <i class="fa fa-spinner loading_span" *ngIf="nameSearchIsLoading" aria-hidden="true"></i> -->
                           <!-- <but *ngIf="requesterName && !showLoader " aria-label="Close" class="input-group-text bg-transparent form-control borderCurved" 
                                (click)="clearSearchRequester()">
                               <span aria-hidden="true">×</span>
                           </button> -->

                           <span class="input-group-append"  *ngIf="requesterName && !showLoader " >
                            <div class=" cursor-pointer input-group-text bg-transparent form-control borderCurved flex-custom " (click)="clearSearchRequester()">
                                <span style="font-size: 24px;" aria-hidden="true">×</span>
                            </div>
                        </span> 



                           <!-- <mat-autocomplete #auto="matAutocomplete">

                            (click)="clearSearchRequester()"
                               <mat-option *ngFor="let dropData of filteredApprovalRequestorsLists | async"
                                   [value]="dropData.name.trim()" (click)="onselectNameData(dropData)">
                                   <span>{{dropData?.name}}</span>
                               </mat-option>
                           </mat-autocomplete> -->
                           
                       </div>
                       
                   </div>
                   <div class="form-group col-lg-2 col-md-4 col-sm  px-0 ml-3 mt-4">
                      
                       <img class="cursor-pointer addPlusStyle" (click)="onselectNameData()" src="../../../../assets/images/space_planning/add_circle_icon.svg" > 
                   </div>
               </div>
           </div>
       
       
           <div class="row col-12 px-1">
               <ng-container *ngFor="let item of customEmail">
                   <div class="form-group ml-3">
                       <p class="inner-card-auto-dbs regular-font-dbs d-flex justify-content-between"
                           style="width:auto;" matTooltip="{{item.email}}">
                           <span>   {{ item.email.length <= 18 ? item.email : item.email.slice(0, 18) + '...' }}&nbsp;&nbsp;</span>
                           <button type="button" (click)="additionalUserRemove(item)"
                               class="close text-right" aria-label="Close" >
                               <span aria-hidden="true">&times;</span>
                           </button>
                       </p>
                   </div>
               </ng-container>
               
           </div>
        </div>
        <div
        class="modal-footer"
        style="float:right !important; border: none; "
        
    >
        <button
            class="btn btn-primary btn-main btnSmall btn-radius  float-right font-regular"
            
            (click)="confirmUserSelect()"
        >
            Okay
        </button>
    </div></div>

    <div class="" *ngIf="pageType === 'allowAdminBookingForUser'">
        <div class="modal-header pl-0">
            <h5>{{data?.entityCategoryName}}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body width-400 align-left">
                <div class="form-row">
                    <div class="col-12">
                        <div class="align-center">
                            <img
                                src="assets/images/Request.png"
                                style="width:40%"
                            />
                        </div>
                    </div>
                    <div class="form-group align-left col-12">
                        <p class="exampleFormControl" style="color:#1F2551;font-size: 14px;font-weight: 600;">
                           {{'configBookingSetting' | translate}}
                        </p>
                    </div>
                    <div class="form-group col-12 text-center mb-0" style="display: flex;justify-content: end;">
                        <button
                            class="btn btn-primary btn-main-white-nb btn-radius mr-2 float-right prl-5 mt-1"
                             type="button" (click)="closePopup()">
                            {{'later'| translate}}
                        </button>
                        <button type="button"
                            class="btn btn-primary btn-main btn-radius mr-2 float-right  prl-5 mt-1"
                            (click)="goToAllowSetting('goToAllowSetting')"
                        >
                            {{'startNow' | translate}}
                        </button>
                    </div>
                </div>
        </div>
    </div>
