/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i3 from "ngx-loading";
import * as i4 from "./modal-loader.component";
var styles_ModalLoaderComponent = [i0.styles];
var RenderType_ModalLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalLoaderComponent, data: {} });
export { RenderType_ModalLoaderComponent as RenderType_ModalLoaderComponent };
export function View_ModalLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngx-loading", [], null, null, null, i2.View_NgxLoadingComponent_0, i2.RenderType_NgxLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.NgxLoadingComponent, [i3.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"] }, null), i1.ɵpod(2, { backdropBorderRadius: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _ck(_v, 2, 0, "3px"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ModalLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-loader", [], null, null, null, View_ModalLoaderComponent_0, RenderType_ModalLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.ModalLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalLoaderComponentNgFactory = i1.ɵccf("app-modal-loader", i4.ModalLoaderComponent, View_ModalLoaderComponent_Host_0, { loading: "loading" }, {}, []);
export { ModalLoaderComponentNgFactory as ModalLoaderComponentNgFactory };
