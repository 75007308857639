<div>
    <div class="modal-header">
        <h5 class="modal-title">
            <b>IP Phone (ASTNFGSJEU6)</b>
        </h5>
        <button id="close_popup" type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid p-0 remove-border-bottom">
            <div class="vertical"></div>
            <mat-tab-group>
                <mat-tab label="General">
                    <!--add asset modal-->
                    <ng-template matTabContent>
                        <app-general></app-general>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Finance">
                    <ng-template matTabContent>
                        <app-finance></app-finance>
                    </ng-template>
                </mat-tab>    
                <mat-tab label="Service">
                    <ng-template matTabContent>
                        <app-service></app-service>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Attachments">
                    <ng-template matTabContent>
                        <app-attachments></app-attachments>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Activity logs">
                    <ng-template matTabContent>
                        <app-activity-logs></app-activity-logs>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
