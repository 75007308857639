import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import {HttpClientModule} from '@angular/common/http';
// import { Response } from '@angular/common/http';
// import { Headers, RequestOptions } from '@angular/common/http';

import "rxjs/add/operator/map";

import "rxjs/add/operator/toPromise";
import { Observable } from "rxjs";
import { environment, modulesIds } from "../../../../environments/environment";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ToastrService } from "ngx-toastr";
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class HomeService {
    private moduleId;
    private BASE_URL;
    private readonly DEV_CLOUD;
    EMSURL;
    constructor(
        private http: HttpClient,
        private toastrService: ToastrService,
        @Inject("BASE_URL") baseUrl: string
    ) {
        this.moduleId = modulesIds.enterpriseManagementSystem;
        // this.BASE_URL = environment.BASE_URL + "ems/";
        this.BASE_URL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.DEV_CLOUD = environment.BASE_DEV_CLOUD
    }

    getAppConfig(zoneId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId": ${zoneId}, "moduleId":48,"correlationId":"${uuidv4()}"}`
            })
        };
        return this.http
            .get(this.EMSURL + "module/config", httpOptions)
            .map(function(response) {
                return response;
            });
    }
    getConfig(zoneId, moduleId) {
        //{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"sessionId":"{{sessionId}}", "moduleId":102}
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":${moduleId},
                    "sessionId":"${localStorage.getItem("ssadmin_session")}",
                    "correlationId":"${uuidv4()}"}`
            })
        };
        return this.http
            .get(this.EMSURL + "module/config", httpOptions)
            .map(function(response) {
                return response;
            });
    }

    getUserDetails(zoneId) {
        return this.http.get(environment.BASE_URL + "/emsPru/user/myProfile", {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getZonesByLevel(level, zoneId) {
        return this.http.get(this.EMSURL + "zones/getAllZones?level=" + level, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    getFilteredZonesByLevel(level, zoneId) {
        return this.http.get(this.EMSURL + "zones/allSites/user", {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    getZonesByLevelNew(zonelevel, zoneId) {
        let postObj: any = { level: zonelevel };
        return this.http.post(this.EMSURL + "zones/allZonesByLevel", postObj, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    updateZone(zoneId) { 
        return this.http.post(this.EMSURL + "user/update-default-zone/"+zoneId,null, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getAllModules(zoneId) {
        return this.http.get(this.EMSURL + "module/all", {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    getEMSUsers(page, size, zoneId) {
        let url = "user/all";
        let queryParams = "?page={page}&size={size}"
            .replace("{page}", page)
            .replace("{size}", size);
        return this.http.get(this.EMSURL + url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getFilteredEMSUsers(searchColumn, searchValue, page, size, zoneId) {
        let url = this.EMSURL + "user/all";
        let queryParams = "?searchColumn={seachColumn}&searchValue={searchValue}&page={page}&size={size}"
            .replace("{seachColumn}", searchColumn)
            .replace("{searchValue}", searchValue)
            .replace("{page}", page)
            .replace("{size}", size);
        return this.http.get(url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getEMSUsersByName(nameStr, zoneId) {
        //{{host}}/ems/user/all?searchColumn=firstName&searchValue=Joe&page=0&size=1000
        let searchColumn = "firstName";
        let searchValue = nameStr;
        let page = "0";
        let size = "1000";

        let url = this.EMSURL + "user/all";
        let queryParams = "?searchColumn={seachColumn}&searchValue={searchValue}&page={page}&size={size}"
            .replace("{seachColumn}", searchColumn)
            .replace("{searchValue}", searchValue)
            .replace("{page}", page)
            .replace("{size}", size);
        return this.http.get(url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    getRoles(zoneId) {
        return this.http.get(this.EMSURL + "roles/getAll", {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    getRolesByCategory(category, zoneId) {
        //ems/roles/byCategory?category=TENANT
        let url = this.EMSURL + "roles/byCategory";
        let queryParams = "?category={category}".replace(
            "{category}",
            category
        );
        return this.http.get(url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getBookingEntityTypeList(zoneId) {
        let context = "bs/";
        let url = environment.BASE_URL + context + "entityType/all";
        let limit = 5 + "";
        let offset = 0 + "";
        let sortCriteria = "ENTITY_TYPE_CREATED_AT";
        let sortDirection = "ASC";
        let queryParams = "?limit={limit}&offset={offset}&sortCriteria={sortCriteria}&sortDirection={sortDirection}"
            .replace("{limit}", limit)
            .replace("{offset}", offset)
            .replace("{sortCriteria}", sortCriteria)
            .replace("{sortDirection}", sortDirection);

        return this.http.get(url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getRolesAndZonesForSalesProfile(userId, categoryName, zoneId) {
        let url = "roles/userRolesByCategory";
        let queryParams = "?userId={userId}&categories={categoryName}"
            .replace("{userId}", userId)
            .replace("{categoryName}", categoryName);
        return this.http.get(this.EMSURL + url + queryParams, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    languageSelection(zone){
        const httpOptions ={
            headers: new HttpHeaders({
                'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"correlationId":"${uuidv4()}"}`
            })
        };
        return this.http
            .get(this.DEV_CLOUD+"gms/locale/all?status=ENABLED", httpOptions)
            .map(function(response) {
                return response;
            });
    }
}
