import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, of, BehaviorSubject, throwError } from "rxjs";
import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { ModuleidService } from 'src/app/services/moduleid-service';
import { environment } from 'src/environments/environment';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';

@Injectable({
  providedIn: 'root'
})
export class EmergencyService {
    moduleId;
    BASEURL;
    selectedEmergencySub$ = new BehaviorSubject<any>(null);
    selectedEmergencyItem = this.selectedEmergencySub$.asObservable();

    constructor(
        public http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        public moduleidService: ModuleidService
      ) {
        this.BASEURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
        //this.moduleId = 3;
      }


      getAllEmergencyList(zoneId) {
        let url;
        url = this.BASEURL + `communityV2/emergency/category/all/zoneId?zoneId=${zoneId}`;
    
        return this.http
          .get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
          })
          .pipe(
          );
      }

      createEmergencyUser(zoneId, data) {
          let url;
          url = this.BASEURL + `communityV2/emergency/category/userdetails/update/v2`;
          return this.http
          .post<any>(url,data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
          })
          .pipe(
          );
      }

    setEditEmergencyItem(item) {
        this.selectedEmergencySub$.next(item);
      }

    removeEditEmergencyItem(){
      this.selectedEmergencySub$.next(null);
    }

    toggleEmergencyStatus (zoneId, emergencyId) {
      let url;
      url = this.BASEURL + `communityV2/emergency/config/toggleStatus?emergencyConfigId=${emergencyId}`;
      return this.http
      .post<any>(url,{}, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      })
      .pipe(
      );
    }

    createEmergencySetup (zoneId, data) {
      const formData: FormData = new FormData();
      formData.append('emergency_category', JSON.stringify(data));
      let url;
      url = this.BASEURL + `communityV2/emergency/category/create/v2`;
      return this.http
      .post<any>(url,formData, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      })
      .pipe(
      );
    }

    updateEmergencySetup (zoneId, data) {
    //   const httpOptions = {
    //     headers: new HttpHeaders({
    //         "Content-Type": "application/json",
    //         "ss-header":
    //             '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
    //             zoneId +
    //             ', "sessionId":"' +
    //             localStorage.getItem("ssadmin_session") +
    //             '"}'
    //     })
    // };
      const formData: FormData = new FormData();
      formData.append('emergency_category', JSON.stringify(data));
      let url;
      url = this.BASEURL + `communityV2/emergency/category/update/v2`;
      return this.http
      .put<any>(url,formData,{
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      })
      .pipe(
      );
    }

    public createEmergency(zoneId, fileData, data, type): Observable<Object> {
      const formData: FormData = new FormData();
      if (fileData) {
        formData.append('image_file', fileData, fileData.name);
      }
      formData.append('emergency_config', JSON.stringify(data));
      const endpoint = this.BASEURL+'communityV2/emergency/config/v2';
      if(type == 'editEmergency') {
        return this.http.put(endpoint,formData, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
      }else {
        return this.http.post(endpoint,formData, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
      }
      
    }

}