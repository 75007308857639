import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { environment , modulesIds} from '../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../zone-and-module-http-params/zone-and-module-http-params.component';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';


@Injectable()
export class TMSService {

  private moduleId;
  private readonly BASE_URL;
  private readonly BASE_ZOHO_URL;
  constructor(private http: HttpClient) { 
    this.moduleId = 2;
    this.BASE_URL = environment.BASE_URL+'tms/';
    this.BASE_ZOHO_URL = environment.BASE_URL+'zoho/';
  }

  getCustomerListByZoneId(zoneId) {
    return this.http.get(this.BASE_URL + "zone-tenant?zone_id="+zoneId, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getBuildingListByZoneAndTenant(tenantId, zoneId) {
    return this.http.get(this.BASE_URL + "zone/tenant-zone-details?tenant_id="+tenantId, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getCustomers(zoneId){
    let url = "tenant/name/list";
    let queryParams = "";
    return this.http.get(this.BASE_URL+url+queryParams, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }

  //public getAllCustomerDetailAddVisualize(tenantId,zoneIds,zoneId){
  getCustomersByTenantAndZones(tenantId,zoneIds,zoneId){
    let url = "tenant-zone/add?tenant_id="+tenantId+"&zone_ids="+zoneIds.join(',');
    let queryParams = "";
    return this.http.get(this.BASE_URL+url+queryParams, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }

  getAllTenantTree(zoneId,tenantId){
    let url = "tenant/location/tree?tenant_id="+tenantId;
    let queryParams = "";
    return this.http.get(this.BASE_URL+url+queryParams, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }

  getReasons(zoneId){
    let url = "creditnotes/getReasons?zoneId="+zoneId;
    let queryParams = "";
    return this.http.get(this.BASE_ZOHO_URL+url+queryParams, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }

}