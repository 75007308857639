// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
/*
"Tenant Registration"-- 2
"Discussion Booth"-- 3
"VMS"-- 9
"Seat Occupancy Dashboard"-- 11
"Discussion Booth Dashboard"-- 14
"Booking Dashboard"-- 15
"Incident Management Dashboard"-- 16
"Community Dashboard"-- 17
"Polling Dashboard"-- 18
"Anomalous Objects Dashboard"-- 19
"VMS Dashboard"-- 20
"Events Dashboard"-- 21
"Handpicked"-- 25
"Focus Pod"-- 27
*/
const hostName = window.location.host;
const protocolName = window.location.protocol;

export const modulesIds = {
    /*production: false,*/
    tenantRegistration: 2,
    enterpriseManagementSystem: 22,
    seatBooking: 3,
    tenantManagementSystem: 2,
    incidentManagementSystem: 4,
    community: 5,
    polling: 6,
    vms: 9,
    events: 10,
    smartaccess: 29,
    seatOccupancyDashboard: 11,
    tenantDashboard: 2,
    tenantNonCoworkingDashboard: 2,
    bookingDashboard: 15,
    incidentManagementDashboard: 16,
    communityDashboard: 17,
    pollingDashboard: 18,
    vmsDashboard: 20,
    eventsDashboard: 21,
    accessDashboard: 28,
    parkingSlotOccupancyDashboard: 30,
    enterpriseManagementSystemDashboard: 52,
    Handpicked: 25,
    HandpickedDashboard: 25,
    // SeatOccupancyDashboard: 35,
    SeatOccupancy: 35,
    PeopleOccupancyDashboard: 36,
    PeopleOccupancy: 36,
    PeopleCountingDashboard: 49,
    crmDashboard: 102,
    customersDashboard: 106,
    creditsDashboard: 103,
    membersDashboard: 105,
    roleResponsibilityDashboard: 104,
    InvoicesModule: 2,
    InvoicesContractModule: 101,
    invoicesDashboard: 108,
    reportIncidentDashboard: 106,
    meetingsroomDashboard: 107,
    emergencyDashboard: 112,
    vehicleAccessDashboard: 113,
    bitesDashboard: 24,
    Econcierge: 115,
    smartbooking: 116,
    printerDashboard: 117,
    analytics: 118,
    manageSeatBookingDashboard: 116,
};
export const Constants = {
    SAML_LOGIN_REQUIRED: false,
    FORGOT_PASSWORD_REQUIRED: true,
    DISABLE_ASSIGN_PASS: false,
    OAUTH_LOGIN_REQUIRED: false,
    MANAGE_ACCESS_ASSIGN: true,
    disableAssignPass: "false",
};

export const RESPONSIBILITIES = [
    "Dashboard_SM",
    "Onboard_Workstation",
    "Onboard_Space",
    "Onboard_People",
    "Onboard_Allocation",
    "Manage_Booking",
    "Busineess_Rules",
    "Social_Distancing",
    "ReAllocation",
    "Analytics_SM",
    "Analytics_Space_View",
    "Manage Roles_SM",
    "Manage_Profile",
    "Expense_Type",
    "Recurring_Expenses",
    "Expenses",
    "Contact_Tracing",
    "Card_Config",
    "Work_Force_Management",
    "Service_Requests",
    "DeskBooking_Booking",
    "DeskBooking_Settings",
    "DeskBooking_Status",
    "DeskBooking_MyBooking",
    "DeskBooking_Preferences",
    "DeskBooking_Analytics",
    "DeskBooking_QRCode",
    "MeetingRoom_Booking",
    "MeetingRoom_Settings",
    "MeetingRoom_Status",
    "MeetingRoom_MyBooking",
    "MeetingRoom_Analytics",
    "MeetingRoom_QRCode",
    "MeetingRoom_Onboarding",
    "WorkforceManagement_Team",
    "WorkforceManagement_Profile",
    "WorkforceManagement_Calendar",
    "WorkforceManagement_Analytics",
    "Manage Visitors",
    "My Visitors",
    "vms configurable",
    "ServiceRequest_MyTicket",
    "ServiceRequest_Manage",
    "ServiceRequest_Categories",
    "ServiceRequest_Team",
    "ServiceRequest_Sanitization",
    "DeskBooking_Settings_New",
    "DeskBooking_Status_New",
    "IntegrationEnabled",
    "WorkforceManagement_Manage_General",
    "WorkforceManagement_Manage_Proxy",
    "SelfAssessment",
    "Hybrid_Scheduler_Analytics_New",
    "Hybrid_Scheduler_Admin_Analytics",
    "DeskBooking_Amenities_Onboard",
    "DeskBooking_Amenities_Manage",
    "Hybrid_Scheduler_Preferences",
];

export const environment = {
    production: true,
    FAVICON_PATH: "../assets/images/smartenspaces.png",
    TITLE: "Jumpree",
    BASE_URL: `${protocolName}//${hostName}/`,
    BASE_URL_SPACE: `${protocolName}//${hostName}/spacemanagement/`,
    BASE_URL_WFH: `${protocolName}//${hostName}/wfh/`,
    BASE_DEV_CLOUD: `https://devcloud.smartenspaces.com/`,
    OPT_URL: `${protocolName}//${hostName}/alg/`,
    // BASE_URL: "https://demodeskmanagement.smartenspaces.com/",
    // BASE_URL_SPACE: "https://demodeskmanagement.smartenspaces.com/spacemanagement/",
    BASE_UPLOAD_URL: "https://preprodspacemanagementv2.smartenspaces.com/",
    BASE_UPLOAD_IMG_URL: "https://webtilesdev.smartenspaces.com/",
    BASE_IP: "https://webtiles.smartenspaces.com/",
    OAUTH_LOGIN_URL:
        "https://login.microsoftonline.com/18a01ad8-9727-498a-a47d-17374c6fd9f7/oauth2/v2.0/authorize?client_id=74121364-fbaf-4761-a08a-e449f4e9517a&response_type=code&redirect_uri=https://mondelez.smartenspaces.com/ems/web/oauthRedirect.html&response_mode=query&scope=openid offline_access user.read",
    OAUTH_LOGOUT_URL:
        "https://login.microsoftonline.com/18a01ad8-9727-498a-a47d-17374c6fd9f7/oauth2/logout?post_logout_redirect_uri=https%3A%2F%2Fmondelez.smartenspaces.com%2Fems%2Fweb%2FoauthLogout.html",
};
