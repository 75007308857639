<div class="modal-card" *ngIf="pageType == 'openDialogdata'">
    <div class="modal-header p-0">
        <h6 class="semi-bold-blue">
             {{"confirmation" | translate}}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="closePopup(pageType)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left">
        <div class="pl-0 mt-3">
            <ul class="list-unstyled px-0">
                <li class="regular-font">
                    {{msg}}
                </li>
            </ul>
            <div class="mt-5">
                <button class="btn btn-main btnSmall smallBtn mt-2 float-right" id="done" name="done" (click)="closePopup(pageType)">
                    {{"done" | translate}}
                </button>
            </div>

        </div>
    </div>
</div>

<div class="modal-card" *ngIf="pageType == 'editBuilding'">
    <div class="modal-header p-0">
        <h6 class="semi-bold-blue">
            {{"editBuilding" | translate}}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="closePopup(pageType)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left">
        <div class="pl-0 mt-3">
            <form [formGroup]="registerForm" (ngSubmit)="onUpdateBuilding()">
                <div class="form-group">
                    <label class="float-left">{{
                        "buildingNameCaps" | translate
                        }}</label>
                    <input id="building_name" type="text" formControlName="buildingName" class="form-control"  />
                    <div *ngIf="submitted && f.buildingName.errors" >
                        <div *ngIf="f.buildingName.errors.required" class="text-left text-danger">
                            {{"enterBuildingNameSmall" | translate}}
                        </div>
                        
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-main btnSmall smallBtn mt-2 float-right" id="done" name="done">
                        {{"update" | translate}}
                    </button>
                </div>
            </form>
            

        </div>
    </div>
</div>


<div class="modal-card" *ngIf="pageType == 'editFloors'">
    <div class="modal-header p-0">
        <h6 class="semi-bold-blue">
           {{"editFloor" | translate}}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="closePopup(pageType)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left">
        <div class="pl-0 mt-3">
            <form [formGroup]="editFloorForm" (ngSubmit)="onUpdateFloors()">
                <div class="form-group">
                    <label class="float-left">{{
                        "floorName" | translate
                        }}</label>
                    <input id="floor_name" type="text" formControlName="floorName" class="form-control"  />
                    <div *ngIf="submitted && editFloorForm.controls.floorName.errors" >
                        <div *ngIf="editFloorForm.controls.floorName.errors.required" class="text-left text-danger">
                            {{"enterFloorName" | translate}}
                        </div>
                        
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-main btnSmall smallBtn mt-2 float-right" id="done" name="done">
                        {{"update" | translate}}
                    </button>
                </div>
            </form>
            

        </div>
    </div>
</div>

<div class="modal-card" *ngIf="pageType == 'editfloorsWithTenant'">
    <div class="modal-header p-0">
        <h6 class="semi-bold-blue">
           {{"editFloor" | translate}}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="closePopup('editBuilding')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left">
        <div class="pl-0 mt-3">
          
                <div class="form-row">

                    <div class="form-group col-md-4">
                    <label class="float-left">FLOOR NAME <span class="color-red">&nbsp;*</span> </label>
                    <input id="floor_name" type="text" [(ngModel)]="floorNameTenant"  class="form-control"  />
                 
                        <div *ngIf="floorNameTenant?.trim()==''" class="text-left text-danger">
                            {{"enterFloorName" | translate}}
                        </div>
                        </div>
                       
                      
                            <div class="form-group col-md-4 mt-neg">
                              <label for="inputCountry"
                                >TENANT NAME
                               </label
                              >

                  <mat-form-field appearance="fill" style="display: block">
                    <mat-select
                      [(ngModel)]="tenantId"
                      placeholder="Select Tenant"
                      
                      class="mat-css-arrow mat-css-down"
                    >
                      <mat-option
                        *ngFor="let ten of tenantListOriginal; let i = index"
                        [value]="ten.tenantId"
                      >
                        {{ ten.name }}
                      </mat-option>
                    </mat-select>
                   
                  </mat-form-field>
                  </div>
                        <img    src="../../../../assets/images/plusicon.png"
                        class="w-20 mt-3 ml-2 float-right cursor-pointer " (click)="updateTenantList()" >
                
                </div>
                <div class="row col-12" *ngIf="tenantList.length > 0">
                    <div
                      class="form-group col-lg-4"
                      *ngFor="let ele of tenantList; let i = index"
                    >
                      <div
                        matTooltip="{{ ele.name }}"
                        class="inner-card-auto d-flex justify-content-between"
                      >
                        <span>{{
                          ele.name.length <= 12
                            ? ele.name
                            : ele.name.slice(0, 12) + "..."
                        }}</span>
                        <span
                          aria-hidden="true"
                          class="cursor-pointer"
                          (click)="removeTenant(i)"
                          >&times;</span
                        >
                      </div>
                    </div>
                  </div>


                <div class="text-center">
                    <button *ngIf="data['dialogData']['fData']['name'] !=floorNameTenant "  class="btn btn-main btnSmall smallBtn mt-2 float-right" (click)="editFloorTenantSubmit()">
                        {{"save" | translate}}
                    </button>
                </div>
           
            

        </div>
    </div>
</div>



