import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, throwError, BehaviorSubject, of } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";
import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { v4 as uuidv4 } from "uuid";
@Injectable()
export class AmenitiesService {
  moduleId;
  BASEURL;
  EMSURL;
  BASEEMSURL;
  SPACEURL;
  departmentSubject$ = new BehaviorSubject<any>([]);
  departments$ = this.departmentSubject$.asObservable();
  buildingSubject$ = new BehaviorSubject<any>([]);
  buildings$ = this.buildingSubject$.asObservable();
  floorSubject$ = new BehaviorSubject<any>([]);
  floors$ = this.floorSubject$.asObservable();
  constructor(
    public http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public moduleidService: ModuleidService
  ) {
    //this.BASE_URL = environment.BASE_URL+'ems/';
    //alert(baseUrl);
    this.BASEURL = baseUrl;
    this.EMSURL = environment.BASE_URL + "ems/";
    this.BASEEMSURL = environment.BASE_URL;
    this.SPACEURL = environment.BASE_URL_SPACE;
    this.moduleId = moduleidService.getModueId();
  }

  private _refreshNeeded = new Subject<void>();
  get refreshNeeded() {
    return this._refreshNeeded;
  }

  getAllFloorDetails(zoneId, data: any): Observable<any> {
    let searchParams = data.searchArr;
    if (searchParams) {
      let status = "states=PARTIAL,COMPLETE";
      let leaseStart = "";
      let leaseEnd = "";
      let buildingIds = "";
      if (searchParams.status != "") {
        status = "states=" + searchParams.status;
      }

      if (searchParams.leaseStart != "") {
        leaseStart = "&leaseStart=" + searchParams.leaseStart;
      }

      if (searchParams.leaseEnd != "") {
        leaseEnd = "&leaseEnd=" + searchParams.leaseEnd;
      }

      if (searchParams.buildingIds != "") {
        buildingIds = "&buildingIds=" + searchParams.buildingIds;
      }

      searchParams = status + leaseStart + leaseEnd + buildingIds;
    } else {
      searchParams = "";
    }

    let url = this.BASEURL + `onboarding/floorDetails?` + searchParams;
    //return this.http.get<any>(url, { headers: headers });
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getAllWorkStations(zoneId, data: any): Observable<any> {
    let searchParams = "";
    if (data.searchType != "" && data.searchValue != "") {
      searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
    }

    // let url =
    //     this.BASEURL +
    //     `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
    //     searchParams;
    let url =
      this.BASEURL +
      `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
      searchParams;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getActiveWorkStations(zoneId, data: any): Observable<any> {
    let searchParams = "";
    if (data.searchType != "" && data.searchValue != "") {
      searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
    }

    // let url =
    //     this.BASEURL +
    //     `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
    //     searchParams;
    let url =
      this.BASEURL +
      `entitytype/getAllEnabled?size=${data.pageSize}&page=${data.pageIndex}` +
      searchParams;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  sendPostRequest(data: any): Observable<any> {
    let url = this.BASEURL + "transaction/update-status";
    return this.http.post<any>(url, data);
  }

  changeActivityStatus(zoneId, data: any): Observable<any> {
    let url = this.BASEURL + "socialdistancing/changeReEntryStatus";
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  createOrUpdateAssests(zoneId, data: any, pageType): Observable<any> {
    let url;
    if (pageType == "editWorkStation") {
      url = this.BASEURL + "entitytype/update";
      return this.http
        .put<any>(url, data, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe(
          tap(() => {
            this._refreshNeeded.next();
          })
        );
    } else {
      url = this.BASEURL + "entitytype/create";
      return this.http
        .post<any>(url, data, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe(
          tap(() => {
            this._refreshNeeded.next();
          })
        );
    }
  }

  createOrUpdateWorkStation(zoneId, data: any, pageType): Observable<any> {
    let url;
    if (pageType == "editWorkStation") {
      url = this.BASEURL + "entitytype/update";
      return this.http
        .put<any>(url, data, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe(
          tap(() => {
            this._refreshNeeded.next();
          })
        );
    } else {
      url = this.BASEURL + "entitytype/create";
      return this.http
        .post<any>(url, data, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe(
          tap(() => {
            this._refreshNeeded.next();
          })
        );
    }
  }

  createOrUpdateDepartment(zoneId, data: any, pageType): Observable<any> {
    let url;

    url = this.EMSURL + "department/";

    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  enableOrDisableFloor(zoneId, data: any): Observable<any> {
    let url;

    if (data.type == "enable") {
      url = this.BASEURL + `onboarding/floor/enable`;
    } else {
      url = this.BASEURL + `onboarding/floor/disable`;
    }
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          //this._refreshNeeded.next();
        })
      );
  }

  enableOrDisableWorkStation(zoneId, data: any): Observable<any> {
    let url;
    var id = {};
    if (data.type == "ENABLED") {
      url = this.BASEURL + `entitytype/enable`;
      id["id"] = data.id;
    } else {
      url = this.BASEURL + `entitytype/disable`;
      id["id"] = data.id;
    }
    return this.http
      .put<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  enableOrDisableDepartment(zoneId, data: any): Observable<any> {
    let url;
    url = this.EMSURL + `department/`;
    var id = {};
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  enableOrDisablePeople(zoneId, data: any): Observable<any> {
    let url;
    url = this.EMSURL + `user/admin/changeStatus`;

    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  downloadFile(zoneId, data): Observable<any> {
    let url;
    url = this.BASEURL + `templates/download/key/${data}`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadFloor(zoneId): Observable<any> {
    let url;
    url = this.SPACEURL + `templates/download/key/AMENITIES_MAPPING`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadReportByFloor(zoneId, floorId): Observable<any> {
    let url;
    url = this.SPACEURL + `amenity/downloadCSVAmenitiesSeatWise`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadDeskAmenitiesReportByFloorId(zoneId, floorId): Observable<any> {
    let url;
    url =
      this.SPACEURL + `amenity/downloadCSVAmenitiesSeatWise?floorId=${floorId}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  deleteAmenities(zoneId, data): Observable<any> {
    let url;
    url = this.SPACEURL + `amenity/disable`;

    return this.http.put<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  loadPeople(zoneId, data: any): Observable<any> {
    let url =
      this.EMSURL +
      `user/all?sortBy=createdAt&searchColumn=${data.searchBy}&searchValue=${data.searchValue}&page=${data.pageIndex}&size=${data.pageSize}&defaultZoneId=${zoneId}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getStatus(currentZone): Observable<any> {
    const url = `${this.EMSURL}activityStatus/all`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
    });
  }

  postFile(zoneId, fileToUpload): Observable<any> {
    let url = this.BASEURL + `allocation/uploadCSV`;
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    var yourHeadersConfig = {
      "content-type": "multipart/form-data",
    };
    return this.http.post(url, formData, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  postAmenities(zoneId, fileToUpload): Observable<any> {
    let url;
    url = this.SPACEURL + `amenity/uploadCSV`;

    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":41, "moduleId":1,"sessionId":"5a133622-c3a6-4112-b880-29de9b13593a","correlationId":"${uuidv4()}"}`,
      }),
    };
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getFloorDetailsByFloorId(zoneId, data) {
    let url = this.BASEURL + `onboarding/floorOnboarding?zoneId=${data.zoneId}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getAllAllocations(zoneId, data) {
    let searchParams = "";
    if (data.searchType != "" && data.searchValue != "") {
      searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
    }

    // let url =
    //     this.BASEURL +
    //     `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
    //     searchParams;
    let url =
      this.BASEURL +
      `allocation/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
      searchParams;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  searchBookingStatus(searchString, zoneId, pageSize, pageIndex) {
    let url =
      this.BASEURL +
      `allocation/search/checkIn?` +
      searchString +
      `&size=` +
      pageSize +
      `&page=` +
      pageIndex;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  searchMeetingRoomBookingStatus(searchString, zoneId, pageSize, pageIndex) {
    let url =
      this.BASEURL +
      `allocation/search/checkIn?` +
      searchString +
      `&size=` +
      pageSize +
      `&page=` +
      pageIndex;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  searchAllocation(searchString, zoneId, pageSize, pageIndex) {
    let url =
      this.BASEURL +
      `allocation/search?` +
      searchString +
      `&size=` +
      pageSize +
      `&page=` +
      pageIndex +
      `&isPermanant=true`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  deleteAllocation(zoneId, data): Observable<any> {
    let url = this.BASEURL + `booking/cancel`;
    return this.http.put<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  deleteMeetinRoomAllocation(zoneId, data): Observable<any> {
    let url = this.BASEURL + `booking/cancel`;
    return this.http.put<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  searchAllocationStatus(searchString, zoneId, pageSize, pageIndex) {
    let url =
      this.BASEURL +
      `allocation/search?` +
      searchString +
      `&size=` +
      pageSize +
      `&page=` +
      pageIndex +
      `&isPermanant=false`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  searchMeetingRoomAllocationStatus(searchString, zoneId, pageSize, pageIndex) {
    let url =
      this.BASEURL +
      `allocation/search?` +
      searchString +
      `&size=` +
      pageSize +
      `&page=` +
      pageIndex +
      `&isPermanant=false`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  getAllDepartments(zoneId) {
    let url = this.EMSURL + `department/getAll`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getLocationAvailability(currentZone, rowdata, floorId = ""): Observable<any> {
    const url = `${this.BASEURL}entity/findAvailableCountsSocialDistancing`;
    return this.http
      .post<any>(url, rowdata, {
        params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError(this.handleError)
      );
  }
  getAvailabilityMapView(currentZone, rowdata): Observable<any> {
    const url = `${this.BASEURL}entity/availabilityMapViewSocialDistancing`;
    return this.http
      .post<any>(url, rowdata, {
        params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError(this.handleError)
      );
  }
  bookSeat(currentZone, data): Observable<any> {
    const url = `${this.BASEURL}socialdistancing/assignSeats`;
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError(this.handleError)
      );
  }
  /**autocomplete */
  getAutocompleteList(data, zoneId): Observable<any> {
    let term = data.searchTerm.trim();
    if (term == "" || term.length <= 2) {
      let resData = [];
      if (data.searchType === "departmentName") {
        this.departments$.subscribe(async (res) => {
          resData = res;
        });
        return of(resData);
      } else if (
        data.searchType === "floor" ||
        data.searchType === "deskNumber"
      ) {
        this.buildings$.subscribe(async (res) => {
          resData = res;
        });
        return of(resData);
      } else {
        return of([]);
      }
    }
    // if(term == "" || term.length <= 2) {
    //     return of([]);
    // }
    if (data.searchType === "user") {
      let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
      return this.http
        .get<any>(url, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe(
          map((res) => res["response"]),
          catchError((err) => of([]))
        );
    } else if (data.searchType === "departmentName") {
      let resData = [];
      this.departments$
        .pipe(
          map((departments) =>
            departments.filter((department) => {
              let depName = department["name"].toLowerCase().toString();
              let searchTerm = term.toLowerCase();
              let res = depName.indexOf(searchTerm);
              if (res >= 0) {
                return department;
              }
            })
          )
        )
        .subscribe((res) => {
          resData = res;
        });

      return of(resData);
    } else if (
      data.searchType === "floor" ||
      data.searchType === "deskNumber"
    ) {
      let resData = [];
      this.buildings$
        .pipe(
          map((buildings) =>
            buildings.filter((building) => {
              let depName = building["name"].toLowerCase().toString();
              let searchTerm = term.toLowerCase();
              let res = depName.indexOf(searchTerm);
              if (res >= 0) {
                return building;
              }
            })
          )
        )
        .subscribe((res) => {
          resData = res;
        });

      return of(resData);
    }
  }

  getBuilding(zoneId, data) {
    let url = this.EMSURL + "zones/childZones/v2";
    return this.http
      .post(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        shareReplay(1),
        map((res) => res["response"].zoneMap[zoneId]),
        catchError((err) => of([])),

        map((res) => {
          if (res.length) {
            return res.map((res) => ({
              userId: res.zoneId,
              ...res,
            }));
          } else {
            return of([]);
          }
        }),

        tap((res) => {
          this.buildingSubject$.next(res);
        })
      );
  }

  /*get floor*/

  getFloors(zoneId, data): Observable<any> {
    let url = this.EMSURL + "zones/childZones/v2";
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        shareReplay(1),
        map((res) => res["response"]["zoneMap"][data.zoneIds[0]]),
        map((res) => {
          if (res.length) {
            return res.map(({ id: userId, ...rest }) => ({
              userId,
              ...rest,
            }));
          } else {
            return of([]);
          }
        }),
        tap((res) => {
          this.floorSubject$.next(res);
        }),
        catchError((err) => of([]))
      );
  }

  /* for floor dropdown data*/

  getFloorDropDown(data) {
    // let term = data.searchTerm.trim();
    let resData = [];
    this.floors$
      .pipe(
        map((floors) =>
          floors.filter((floor) => {
            let depName = floor["name"].toLowerCase().toString();
            let searchTerm = data.trim().toLowerCase();
            let res = depName.indexOf(searchTerm);
            if (res >= 0) {
              return floor;
            }
          })
        )
      )
      .subscribe((res) => {
        resData = res;
      });

    return of(resData);
  }
  getDepartmentList(zoneId) {
    let url = `${this.EMSURL}department/getAll`;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        shareReplay(1),
        map((res) => res["response"]),
        catchError((err) => of([])),
        map((res) => {
          if (res.length) {
            return res.map(({ id: userId, ...rest }) => ({
              userId,
              ...rest,
            }));
          } else {
            return of([]);
          }
        }),
        tap((res) => {
          this.departmentSubject$.next(res);
        })
      );
  }
  /**autocomplete */
  handleError = (err) => {
    /*let errorMsgData = (err as any).error;
    const message = errorMsgData["message"];
    this.toastrService.error(message);*/
    return throwError(err);
  };

  getTenants(currentZone): Observable<any> {
    let url =
      this.BASEEMSURL + `tms/tenant/list?page=0&size=10000&sort=createdAt,desc`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
    });
  }

  getAllShifts(currentZone): Observable<any> {
    let url = this.BASEEMSURL + `ems/shifts/getAll`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
    });
  }

  saveUserDetails(zoneId, data: any) {
    let url = this.EMSURL + "user/admin/addUser";
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  saveupdatedUserDetails(zoneId, data: any) {
    let url = this.EMSURL + "user/admin/updateUserDetails";
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  getAmenities(zoneId): Observable<any> {
    let url;
    url = this.SPACEURL + `amenity/get/category?AmenityCategory=SEAT`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
    // return this.http.get<any>(url, httpOptions);
  }

  postAmenitiesWithSeats(zoneId, obj) {
    let url = this.SPACEURL + "entity/updateMultiAmenity";

    return this.http
      .post<any>(url, obj, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  loadAmenitiesForFloors(zoneId, floorId) {
    let url =
      this.SPACEURL + "entity/getCountEntityAmenityForZone?zoneId=" + floorId;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  createOrUpdateAmenities(zoneId, data: any, pageType): Observable<any> {
    let url;
    if (pageType == "editAmenity") {
      url = this.SPACEURL + "amenity/update";

      return this.http
        .put<any>(url, data, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe(
          tap(() => {
            this._refreshNeeded.next();
          })
        );
    } else {
      url = this.SPACEURL + "amenity/createV2";

      return this.http
        .post<any>(url, data, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe(
          tap(() => {
            this._refreshNeeded.next();
          })
        );
    }
  }

  deleteDeskAmenities(data, zoneId) {
    let url = this.SPACEURL + "amenity/UnTagAndtagAmenities";

    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  editDeskAmenities(data, zoneId) {
    let url = this.SPACEURL + "amenity/editAmenitiesForMultiEntities";

    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }
}
