import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, OnDestroy, ɵConsole } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatInput, MatSelect, MatOption, MatCheckbox, MatTableDataSource, MatRadioButton, MatRadioGroup } from '@angular/material';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { modulesIds } from '../../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import 'rxjs/add/observable/of';
import { CommonService } from '../../../services/common-service.service';
import { CustomersService } from '../service/customers.service';
import { routerTransition } from '../../../router.animations';
import { Router } from '@angular/router';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { TMSModalComponent } from './tms/tms-modal.component';
import { ModalRequestServiceService } from '../../../custom-modal/service/modal-request-service.service';
import { AlertService } from '../../../services/alert-service.service';
import { type } from 'os';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customers-modal',
  templateUrl: './customers-modal.component.html',
  styleUrls: ['./customers-modal.component.scss']
})
export class CustomersModalComponent implements OnInit, AfterViewInit, OnDestroy {

    private currentZone;


  public regionArray;
  public countryArray;
  public countriesListForRegion;
  public stateArray;
  public statesListForCountry;
  public cityArray;
  public citiesListForState;
  public siteArray;
  public sitesListForCity
  public buildingArray;
  public buildingListForSite;
  public floorArray;
  public floorListForBuilding;
  public tenantSalesProfile;
  public tenantCenterManager;

  public tenantProfile;
  selectedFloors;
  private currentTenantSite;

  public countryCodeList = [];
  public customFieldList:any=[]

  tmsDialogRef: MatDialogRef<TMSModalComponent>;

  private subs;

    constructor(
        public matSnackBar: MatSnackBar,
        public dialogRef: MatDialogRef<CustomersModalComponent>,
        public modalRequestServiceService: ModalRequestServiceService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
        public commonService: CommonService,
        public customersService: CustomersService,
        public toastrService: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any) { 
      
        }
    
        ngOnInit(){
          let currentContext = this;
          this.currentZone = currentContext.data['currentZone'];
          this.commonService.changeSiteSelectionFlag(false);
          this.subs = this.modalRequestServiceService.currentFloor.subscribe( floor => {
            let floorDuplicate = false;  
            if(floor && this.selectedFloors){
              
              if((this.selectedFloors.size!=0)){
                this.selectedFloors.forEach( (elem) => {
                
                  if(!floorDuplicate && (elem.name) && (elem.name === (floor as any).name) && (elem.id === (floor as any).id)){
                    floorDuplicate = true;
                  }
                });
              }
              if(!floorDuplicate){
                if(Object.keys(floor).length!=0){
                  this.selectedFloors.add((floor as any))
                }
              }else{
                  if(this.selectedFloors.size !=0 ){
                  }
              }
            }
          })

          if (currentContext.data['type'] === 'editTenantProfile') {
            this.regionArray = currentContext.data['regionArray'];
            this.countryArray = currentContext.data['countryArray'];
            this.stateArray = currentContext.data['stateArray'];
            this.cityArray = currentContext.data['cityArray'];
            this.siteArray = currentContext.data['siteArray'];
            this.buildingArray = currentContext.data['buildingArray'];
            this.floorArray = currentContext.data['floorArray'];
            this.countryCodeList = currentContext.data['countryCodeList'];      
            this.tenantProfile = currentContext.data['tenantProfile'];
            this.customFieldList = currentContext.data['customFieldList'];
            if(this.tenantProfile.tenantPOCLastName == undefined || this.tenantProfile.tenantPOCLastName == null){
              this.tenantProfile.tenantPOCLastName = '';
            }
            let phoneStr: string = this.tenantProfile.phone;
            if(phoneStr!=null){
              if(phoneStr.indexOf('-') < -0){
                this.tenantProfile.phoneCountryCode = phoneStr.substring(0, 3);
                this.tenantProfile.phone = phoneStr.substring(3,phoneStr.length);
              }else{
                this.tenantProfile.phoneCountryCode = phoneStr.split('-')[0];
                this.tenantProfile.phone = phoneStr.split('-')[1];
              }
            }
            else{

            }
            let tenantPOCPhoneStr: string = this.tenantProfile.tenantPOCPhone;
            if(tenantPOCPhoneStr==='') tenantPOCPhoneStr = phoneStr;
            if(tenantPOCPhoneStr!=null){
              if(tenantPOCPhoneStr.indexOf('-') < -0){
                this.tenantProfile.tenantPOCPhoneCountryCode = tenantPOCPhoneStr.substring(0, 3);
                this.tenantProfile.tenantPOCPhone = tenantPOCPhoneStr.substring(3,tenantPOCPhoneStr.length);
              }else{
                this.tenantProfile.tenantPOCPhoneCountryCode = tenantPOCPhoneStr.split('-')[0];
                this.tenantProfile.tenantPOCPhone = tenantPOCPhoneStr.split('-')[1];
              }
            }
            else{
              
            }
            this.selectedFloors = new Set<any>();
      
            this.siteArray.filter((site)=>{if(site.id == currentContext.data['currentZone']){        
              this.currentTenantSite = {siteId:currentContext.data['currentZone'],siteName: site['name']};
            }});
      
            if((currentContext.data as any).tenantProfile.zoneIds.length > 0){
              currentContext.data.tenantProfile.zoneIds.forEach( zoneId => {
                let currentFloorDetail = {};
                this.floorArray.forEach( (floorElem) => {
                  if(zoneId === floorElem.id){
                    currentFloorDetail = floorElem;
                  }
                });
            let floorStr = '';
            let currentRegionId = (currentFloorDetail as any).regionId;
            let currentCountryId = (currentFloorDetail as any).countryId;
            let currentStateId = (currentFloorDetail as any).stateId;
            let currentCityId = (currentFloorDetail as any).cityId;
            let currentSiteId = (currentFloorDetail as any).siteId;
            let currentBuildingId = (currentFloorDetail as any).buildingId;
            let currentFloorId = (currentFloorDetail as any).id;     
      
            //this.regionArray.filter((region)=>{if(region.id == currentRegionId){floorStr = (region['name'])}});
            //this.countryArray.filter((country)=>{if(country.id == currentCountryId){floorStr = country['name']+', '+floorStr}});
            //this.stateArray.filter((state)=>{if(state.id == currentStateId){floorStr = state['name']+', '+floorStr}});
            //this.cityArray.filter((city)=>{if(city.id == currentCityId){floorStr = city['name']+', '+floorStr}});
            this.siteArray.filter((site)=>{if(site.id == currentSiteId){
              floorStr = site['name']+floorStr;
            }});
      
            this.buildingArray.filter((building)=>{if(building.id == currentBuildingId){floorStr = building['name']+', '+floorStr}});
            this.floorArray.filter((floor)=>{if(floor.id == currentFloorId){floorStr = floor['name']+', '+floorStr}});
      
                let floor = {};
                (floor as any).id = zoneId;
                (floor as any).name = floorStr;
                this.selectedFloors.add(floor);
              });
            }
          }
        }

        ngAfterViewInit() {
          this.ref.detectChanges();
        }

        ngOnDestroy() {
          this.modalRequestServiceService.ngOnDestroy();
        }

        onNoClick(): void {
          let currentContext = this;
          currentContext.commonService.changeZone(currentContext.currentZone);
          this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
          // console.log(this.dialog.openDialogs[this.dialog.openDialogs.length - 1])
          // this.dialogRef.close();    
        }

        cancel(){
            let currentContext = this;
            currentContext.onNoClick();
        }

        public addFloorToTenant(){
          let data = {};
          data['type'] = 'addFloorToTenant';
          data['regionArray'] = this.regionArray;
          data['countryArray'] = this.countryArray;
          data['stateArray'] = this.stateArray;
          data['cityArray'] = this.cityArray;
          data['siteArray'] = this.siteArray;
          data['buildingArray'] = this.buildingArray;
          data['floorArray'] = this.floorArray; 
          data['currentSite'] = this.currentTenantSite;//this is the current site selected on the whole dashboard
          this.openTMSDialog(data);
        }

        public openTMSDialog(data): void {
          this.tmsDialogRef = this.dialog.open(TMSModalComponent, {
            width: '600px',
            panelClass: 'ws-user-info-dialog',
            data: data
          });
          this.tmsDialogRef.afterClosed().subscribe(result => {
            if(typeof result['selectedUser'] != 'undefined') {
              this.tenantProfile.tenantPOCEmail = result['selectedUser'][0]['email'];
              this.tenantProfile.tenantPOCFirstName = result['selectedUser'][0]['name'];
              this.tenantProfile.tenantPOCFirstName = result['selectedUser'][0]['name'];
              this.tenantProfile.tenantPOCPhoneCountryCode = '';
                this.tenantProfile.tenantPOCPhone = '';
              if( result['selectedUser'][0]['phoneNum'] != null) {
                let conuntryCode  = result['selectedUser'][0]['phoneNum'].split('-')[0];
                let mobile  = result['selectedUser'][0]['phoneNum'].split('-')[1];
                this.tenantProfile.tenantPOCPhoneCountryCode = conuntryCode;
                this.tenantProfile.tenantPOCPhone = mobile;
              }
              // this.tenantProfile.tenantPOCEmail = result['selectedUser']['email'];
            }
          });
      
        }

        removeFloor(floor){      
          this.selectedFloors.forEach( (elem) =>{
            if(elem.name === floor.name){
              this.selectedFloors.delete(floor);
            }
          });
        }
      
        isValidFloorId(floorId){
          return typeof floorId === 'number';
        }

        getImageUrl(files: FileList, event, index){
          let fileToUpload = files.item(0);
          if ((fileToUpload.size < 5242880)){
              this.customersService.uploadFile(fileToUpload, this.currentZone).subscribe(res=>{
                  // newField.value = (res as any).response['fileUrl'];
                  this.customFieldList[index]['value'] = (res as any).response['fileUrl'];
              }, err => {
  
              })
  
          }else {
              this.toastrService.error('Invalid filetype selected!');
          }
        }

        editTenantProfile(tenantProfile) {
          var currentContext = this;
          var updateTenantProfile = {};
          var addrObj = {};
          
          addrObj['address_line'] = tenantProfile.addressLine;
          addrObj['city'] = tenantProfile.addressCity;
          addrObj['state'] = tenantProfile.addressState;
          addrObj['country'] = tenantProfile.addressCountry;
          addrObj['zipcode'] = tenantProfile.companyZipCode;
          updateTenantProfile['tenant_id'] = tenantProfile.tenantId;
          updateTenantProfile['name'] = tenantProfile.name;
          updateTenantProfile['website'] = tenantProfile.website;
          updateTenantProfile["email"] = tenantProfile.email;
          updateTenantProfile['phone'] = tenantProfile.phoneCountryCode+'-'+tenantProfile.phone;
          updateTenantProfile["user_reg_type"] = tenantProfile.allowedEmailType;
          updateTenantProfile['zone_ids'] = [];
          this.selectedFloors.forEach( floor => {
            if((floor as any).id){
              updateTenantProfile['zone_ids'].push((floor as any).id);
            }
          });
         
          updateTenantProfile['description'] = tenantProfile.description;
          updateTenantProfile['address'] = addrObj;
          updateTenantProfile['active'] = tenantProfile.active;
          updateTenantProfile["tenant_poc"] = {
            "first_name": tenantProfile.tenantPOCFirstName,
            "last_name": tenantProfile.tenantPOCLastName,
            "email": tenantProfile.tenantPOCEmail,
            "phone": tenantProfile.tenantPOCPhoneCountryCode+'-'+tenantProfile.tenantPOCPhone
          }
          updateTenantProfile['meta_data'] = {
            'gst': tenantProfile.tenantGST,
            'pan': tenantProfile.tenantPAN,
            'tan': tenantProfile.tenantTAN
          };

          updateTenantProfile['meta_data'] = {
            'custom_fields' : {}
          }
        if(this.customFieldList.length > 0) {
            let customFieldObj: any = {}
            let validationFlag:boolean = true;
            for(let i=0; i< this.customFieldList.length; i++) {
                if(this.customFieldList[i]['is_mandatory']) {
                    if( typeof this.customFieldList[i]['value'] == 'undefined' || this.customFieldList[i]['value'] == '') {
                        validationFlag = false;
                    }else {
                        // this.TenantRequest['meta_data']['custom_fields'].push({[this.customFieldList[i]['custom_fields_id']] :  this.customFieldList[i]['value']})
                        updateTenantProfile['meta_data']['custom_fields'][this.customFieldList[i]['custom_fields_id']]=this.customFieldList[i]['value'];
                    }
                }else {
                  updateTenantProfile['meta_data']['custom_fields'][this.customFieldList[i]['custom_fields_id']]=this.customFieldList[i]['value'];
                }
            }
    
            if(!validationFlag) {
                this.toastrService.error('Please enter all mandatory fields');
                return;
            }
        }
          this.customersService.updateTenantProfileById(updateTenantProfile, this.currentZone).subscribe(res => {
            setTimeout(() => {
              this.dialogRef.close();
              //this.commonService.openSnackBar((res as any).message, '');
              this.toastrService.success((res as any).message);
            }, 2000); 
            currentContext.commonService.changeZone(currentContext.currentZone);
          }, err => {
            this.commonService.openSnackBar(err.error.message, '');
          });
        //}
        }

        resendActivationLink(){
    
          

          if(this.tenantProfile.tenantPOCEmail == ''){
            this.toastrService.error('Point of contact mail doesn\'t exists');
            return;
          }else{
            let dataReq = {
              "email": this.tenantProfile.tenantPOCEmail,
              "otpType":"ADD USER"
            }
            this.customersService.sendActivationLink(dataReq, this.currentZone).subscribe(res => {
              setTimeout(() => {
                this.dialogRef.close();
                //this.commonService.openSnackBar((res as any).message, '');
                this.toastrService.success((res as any).message);
              }, 2000);
            });
          }
          
        }
        onChangeUser =() => {
          let data:any = {};
          data['type'] = 'changeUser';
          data['tenantId'] = this.tenantProfile.tenantId;
          this.openTMSDialog(data);
        }
}