<div *ngIf="pageType == 'addEvent'">
  <div class="modal-header pl-0">
    <h5>
     
      Onboard Tenant
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">Tenants</p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="row">
      <p class="f-16 pl-3 enable-mutiday font-14 fontColor">
        How would you like to create new tenants ?
      </p>
    </div>

    <div class="form-row">
      <mat-radio-group
        
        class="font-color pl-3 csm-class"
        [(ngModel)]="addType"
      >
        <mat-radio-button
          class="pb-0 mb-3 custom-radio-btn custom-radio-btn-label"
          value="manual"
        >
          Add Manually
        </mat-radio-button>

        <mat-radio-button
          class="pb-0 mb-3 custom-radio-btn custom-radio-btn-label"
          value="excel"
        >
          Add via excel
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <ng-container *ngIf="addType == 'excel'">
      <div class="row">
        <div class="col-md-5">
          <label></label>
          <button
            type="button"
            class="form-control1"
            (click)="downloadViaExcelTemplate()"
          >
            <span style="font-family: 'Open Sans'">{{
              "downloadTemplate" | translate
            }}</span>
            <span class="ml-3"
              ><img
                src="./assets/images/downloadTemplate.png"
                style="width: 1rem"
            /></span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <label for="uploadFile" class="label1 float-left mt-2 font-label"
            >{{ "upload" | translate }} (.xlsx {{ "file" | translate }})</label
          >
          <div class="input-group clear-both float-left">
            <input
              type="file"
              class="form-control custom-file-input"
              id="importFile"
              aria-describedby="inputGroupFileAddon01"
              (click)="fileInputXLSX.value = null"
              #fileInputXLSX
              accept=".xlsx"
              (change)="uploadExcel($event.target.files)"
            />
            <label
              (click)="fileInputXLSX.click()"
              class="custom-file-label1 custom-upload-btn font-label"
              for="inputGroupFile01"
              #labelImport
            ></label>
          </div>
        </div>
        <div
          class="input-group col-md-4 mt-3"
          *ngIf="showUploadedExcelFileName != ''"
        >
          <p class="inner-card-auto p-1">
            <span class="text-overflow-hidden ml-1">{{
              showUploadedExcelFileName
            }}</span>
            <button
              type="button"
              class="close text-right right-flex"
              aria-label="Close"
            >
              <span aria-hidden="true" (click)="showUploadedExcelFileName = ''"
                >&times;</span
              >
            </button>
          </p>
        </div>
      </div>

      <div
        class="row col-12 text-danger"
        *ngIf="submittedExcel && showUploadedExcelFileName == ''"
      >
        Please upload file
      </div>
    </ng-container>

    <button
      *ngIf="addType != ''"
      (click)="selectType()"
      class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      Next
    </button>
  </div>
</div>


<div *ngIf="pageType == 'delCat'">
  <div class="modal-header pl-0">
    <h5>
     Alert
      
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">Tenants</p>
  </div> -->

  <div class="modal-body width-400 align-left">
    <div class="row">
      <p class="f-16 pl-3 enable-mutiday font-14 fontColorSemi">
        You are attempting to delete the tenant “{{data['name']}}”. Deleting the company will also delete the {{data['count']}} users mapped under the company.
      </p>
    </div>
    <div class="row">
      <p class="f-16 pl-3 enable-mutiday font-14 fontColor">
        Are you sure you want to proceed to delete the tenant company “{{data['name']}}” ?      </p>
    </div>

    <div class="row" style="float: right;" >
      <button
   
      (click)="closePopup()"
      class="btn btn-main btn-white pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      No
    </button>
      <button
     
      (click)="closePopupDelCat()"
      class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      Yes
    </button>
   
    </div>


  </div>

  </div>
<div *ngIf="pageType == 'onboardingExcelTable'">
  <div class="modal-header pl-0 pr-0">
    <h5>{{ "alert" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body width-400 align-left mt-2 pb-0">
    <ng-container *ngIf="incorrectData.length > 0">
      <p class="regular-semibold-font">
        {{ "successfullyUploaded" | translate }}
        {{ TotalNoOfEntitiesUploadedRNW }} tenant ,
        {{ "belowAreTheListOfEntitiesWhichHaveIssues" | translate }}.
      </p>
      <table mat-table [dataSource]="showIncorrectData" class="vms-error-table">
        <!-- First Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="align-left">
            Tenant Company Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [ngClass]="{
              'is-incorrect': element.errorFields.includes('Name')
            }"
            [matTooltip]="getToolTipData(element.name)"
          >
            {{ element.name | slice : 0 : 20 }}
            <span *ngIf="element.name?.length > 20">...</span>
          </td>
        </ng-container>

        <!-- Capacity is only for Collab Spaces -->

        <!-- Reason Column -->
        <ng-container matColumnDef="reason">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="align-left"
            style="width: 20vw"
          >
            {{ "reason" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-left"
            [matTooltip]="getToolTipData(element.reason)"
          >
            <span
              >{{ element.reason | slice : 0 : 30 }}
              <ng-container *ngIf="element.reason?.length > 30"
                >...</ng-container
              >
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="incorrectDataColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: incorrectDataColumns"></tr>
      </table>
      <!-- <ng-container *ngIf="totalIncorrectLength > 10">
        <mat-paginator
          class="w-100"
          #paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[10, 25, 100]"
          [showFirstLastButtons]="true"
          (page)="getNextRecords($event)"
          [length]="totalIncorrectLength"
        ></mat-paginator>
      </ng-container> -->

      <div class="row text-center button-alignToEnd float-right p-2 mt-2">
        <button
          class="btn btn-main f-size-small pl-3 pr-3 btn-radius p-1 m-1 filter btn-primary pull-right"
          (click)="reUploadEntities()"
        >
          {{ "reuploadFilesWithFixes" | translate }}
        </button>
        <button
          class="btn-main-white-nb ml-4 btn btn-main f-size-small pl-3 pr-3 btn-radius p-1 m-1 filter btn-primary pull-right"
          (click)="proceedToOnboard()"
        >
          {{ "proceedToOnboardTheRemainingEntries" | translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="correctData.length > 0 && incorrectData.length == 0">
      <p class="regular-semibold-font">
        {{ "successfullyUploaded" | translate }}
        {{ noOfEntitiesUploaded }} tenant,
        {{ "proceedToOnboard" | translate }}.
      </p>
      <button
        class="btn btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
        (click)="proceedToOnboard()"
      >
        {{ "proceed" | translate }}
      </button>
    </ng-container>
  </div>
</div>
<div *ngIf="pageType == 'onboarding_reUploadCSV'">
  <div class="modal-header pl-0 pr-0">
    <h5>{{ "reUploadFile" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body width-400 align-left mt-2">
    <div class="row">
      <div class="col-12 pl-2">
        <img
          class="cursor-pointer"
          src="../../assets/images/jumpree_digital/download.png"
          (click)="downloadCurrentCSVFile()"
          width="45"
        />
        <span class="regular-semibold-font">
          {{ "downloadCurrentFile" | translate }}
        </span>
      </div>
    </div>
    <p class="regular-semibold-font mt-3">
      {{ "uploadFileWithFixes" | translate }}
    </p>
    <div class="row">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
        <label for="uploadFile" class="label1"
          >{{ "upload" | translate }} (.xlsx {{ "file" | translate }})</label
        >
        <div class="input-group">
          <input
            type="file"
            #fileInput
            class="form-control custom-file-input"
            id="importFile"
            (click)="fileInput.value = null"
            aria-describedby="inputGroupFileAddon01"
            accept=".xlsx"
            (change)="uploadExcel($event.target.files)"
          />
          <label
            class="custom-file-label1 custom-upload-btn"
            for="inputGroupFile01"
          >
          </label>
        </div>
      </div>
    </div>
    <div class="row m-0 mt-4" *ngIf="showUploadedExcelFileName">
      <p class="pConfirmationMessage custom-shadow ml-0">
        {{ showUploadedExcelFileName }}
        <span
          class="ml-4 cursor-pointer font-weight-bold"
          (click)="showUploadedExcelFileName = undefined"
          >×</span
        >
      </p>
    </div>
    <div class="row" *ngIf="showUploadedExcelFileName">
      <div class="col">
        <button
          type="button"
          class="btn btn-main f-size-small btn-radius p-1 m-2 btnSmall filter btn-primary pull-right"
          (click)="validateExcelOnboarding()"
        >
          {{ "proceed" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="pageType == 'addEventManual'">
  <div class="modal-header pl-0">
    <h5>Onboard tenant</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">Tenant</p>
  </div>
  <div class="modal-body width-400 align-left">
    <form>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Tenant name</label>
          <input
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="category"
            class="form-control blue-regular"
            id="dir_id"
            placeholder="Enter tenant company name"
            type="text"
        maxlength="50"
          />
          <div
          class="d-block invalid-feedback"
          *ngIf="category != '' "
        >
         {{50-category.length }} characters left
        </div>
        </div>
      </div>
    </form>

    <div class="row col-12" *ngIf="categoryList.length > 0">
      <div
        class="form-group col-lg-4"
        *ngFor="let ele of categoryList; let i = index"
      >
        <div
          matTooltip="{{ ele.category }}"
          class="inner-card-auto d-flex justify-content-between"
        >
          <span>{{
            ele.category.length <= 12
              ? ele.category
              : ele.category.slice(0, 12) + "..."
          }}</span>
          <span
            aria-hidden="true"
            class="cursor-pointer"
            (click)="removeCategory(i)"
            >&times;</span
          >
        </div>
      </div>
    </div>

    <button
    *ngIf=" (category && category?.trim()!='' ) || categoryList.length > 0 "
      (click)="onSubmitCateory()"
      class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      Save
    </button>
    <button
    *ngIf=" (category && category?.trim()!='' )  "
      (click)="addCategory()"
      class="btn btn-main btn-white pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      Add More
    </button>
  </div>
</div>

<div *ngIf="pageType == 'editCat'">
  <div class="modal-header pl-0">
    <h5>Edit tenant</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <p class="italic mt-2 openSans pl-2 mb-0">People</p>
  </div>
  <div class="modal-body width-400 align-left">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label>tenant name</label>
        <input
          [(ngModel)]="editCatName"
          class="form-control blue-regular"
          id="dir_id"
          placeholder="Enter tenant company name"
          type="text"
          [ngClass]="{
            'is-invalid': submitted && editCatName == ''
          }"
          maxlength="50"
          required
        />

        <div
          class="d-block invalid-feedback"
          *ngIf="submitted && editCatName == ''"
        >
        Tenant Name required
        </div>
        <div
          class="d-block invalid-feedback"
          *ngIf="editCatName != '' "
        >
         {{50-editCatName.length }} characters left
        </div>
      </div>
    </div>

    <button
      (click)="submitEditCat()"
      class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
    >
      Save
    </button>
  </div>
</div>
