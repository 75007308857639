import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import * as _ from "lodash";
export class DigitalBuildingsModalComponent {
    constructor(dialogRef, data, dialog, formBuilder, digitalBuildingsService, toasterService, commonService, translate, loaderService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.digitalBuildingsService = digitalBuildingsService;
        this.toasterService = toasterService;
        this.commonService = commonService;
        this.translate = translate;
        this.loaderService = loaderService;
        this.floorNameTenant = '';
        this.tenantId = null;
        this.tenantListOriginal = [];
        this.tenantList = [];
        this.buildingNameTenant = '';
        this.onUpdateBuilding = () => {
            this.submitted = true;
            if (this.registerForm.get('buildingName').value.trim() == "") {
                this.registerForm.get('buildingName').reset();
            }
            if (this.registerForm.invalid) {
                return false;
            }
            else {
                let data = {
                    id: this.registerForm.value["id"],
                    name: this.registerForm.value["buildingName"]
                };
                this.digitalBuildingsService.UpdateBuilding(this.currentZone, data).subscribe((res) => {
                    this.toasterService.success(this.translate.instant("buildingNameUpdatedSuccessfully"));
                    this.closePopup(this.pageType);
                });
            }
        };
        this.onUpdateFloors = () => {
            this.submitted = true;
            if (this.editFloorForm.get('floorName').value.trim() == "") {
                this.editFloorForm.get('floorName').reset();
            }
            if (this.editFloorForm.invalid) {
                return false;
            }
            else {
                let data = {
                    id: this.editFloorForm.value["id"],
                    name: this.editFloorForm.value["floorName"]
                };
                this.digitalBuildingsService.UpdateBuilding(this.currentZone, data).subscribe((res) => {
                    this.toasterService.success(this.translate.instant("floorNameUpdatedSuccessfully"));
                    this.closePopup('editBuilding');
                });
            }
        };
        this.commonService.currentZone.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (res) {
                this.currentZone = res;
            }
        }));
    }
    ngOnInit() {
        this.pageType = this.data['type'];
        this.modalData = this.data['dialogData'];
        this.currentZone = this.data['currentZone'];
        if (this.pageType == "editBuilding") {
            this.registerForm = this.formBuilder.group({
                id: [this.data["dialogData"]["buildingId"]],
                buildingName: [this.data["dialogData"]["buildingName"], [Validators.required]]
            });
        }
        if (this.pageType == "editFloors") {
            console.log(this.data['dialogData']);
            this.editFloorForm = this.formBuilder.group({
                id: [this.data["dialogData"]["id"]],
                floorName: [this.data["dialogData"]["name"], [Validators.required]]
            });
        }
        if (this.pageType == "editfloorsWithTenant") {
            console.log(this.data['dialogData']);
            this.floorNameTenant = this.data["dialogData"]['fData']["name"];
            this.tenantListOriginal = this.data["dialogData"]['tenantList'];
            this.tenantList = this.data["dialogData"]['linkedTenant'];
            this.tenantList = this.cloneList(this.tenantList);
        }
        if (this.pageType == "openDialogdata") {
            this.msg = this.data['dialogData'];
        }
    }
    closePopup(type) {
        this.dialogRef.close(type);
    }
    get f() { return this.registerForm.controls; }
    editFloorTenantSubmit() {
        if (this.floorNameTenant.trim() == '') {
            return false;
        }
        else {
            let data = {
                id: this.data["dialogData"]['fData']["id"],
                name: this.floorNameTenant
            };
            const $save = this.digitalBuildingsService.UpdateBuilding(this.currentZone, data);
            this.loaderService.showLoaderUntilCompleted($save).subscribe((res) => {
                this.toasterService.success(this.translate.instant("floorNameUpdatedSuccessfully"));
                this.closePopup('editBuilding');
            });
        }
    }
    cloneList(list) {
        return _.cloneDeep(list);
    }
    updateTenantList() {
        if (!this.tenantId) {
            return;
        }
        let tenantExists = false;
        this.tenantList.find((e) => {
            if (e.tenantId == this.tenantId)
                tenantExists = true;
        });
        if (tenantExists) {
            this.tenantId = null;
            this.toasterService.error('Tenant already added');
            return;
        }
        const obj = this.tenantListOriginal.find((e) => {
            if (e.tenantId == this.tenantId)
                return e;
        });
        const $addTenant = this.digitalBuildingsService.addTenantWithFloor(this.currentZone, this.data["dialogData"]['fData']['id'], this.tenantId);
        this.loaderService.showLoaderUntilCompleted($addTenant).subscribe((e) => {
            this.tenantList = this.cloneList(this.tenantList);
            this.tenantList.push(obj);
            this.tenantList = this.cloneList(this.tenantList);
            this.tenantId = null;
        });
    }
    removeTenant(i) {
        const $delTenant = this.digitalBuildingsService.deleteTenantWithFloor(this.currentZone, this.data["dialogData"]['fData']['id'], this.tenantList[i].tenantId);
        this.loaderService.showLoaderUntilCompleted($delTenant).subscribe((e) => {
            this.tenantList = this.cloneList(this.tenantList);
            this.tenantList = this.tenantList.filter((e) => e.tenantId != this.tenantList[i].tenantId);
            this.tenantList = this.cloneList(this.tenantList);
        });
    }
}
