import { Observable } from "rxjs";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/moduleid-service";
export class DigitalBookingProService {
    constructor(http, baseUrl, moduleidService) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.BASEEMSURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
    }
    deleteSpace(id) {
        let url = `${this.BASEEMSURL}amenitybooking/globalCategory/delete?id=${id}`;
        return this.http.delete(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    editSpaces(dataObj) {
        let url = this.BASEEMSURL + `amenitybooking/globalCategory/update`;
        return this.http.post(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    postDigitalBookingPro(zoneId, data) {
        // https://digitaldev.smartenspaces.com/amenitybooking/globalCategory/create
        // let dataL = {
        //     "name":"Desk",
        //     "amenityType":"COLLABORATIVE"
        // }
        console.log("booking data is... ", data);
        // const endpoint = 'https://digitaldev.smartenspaces.com/ems/' + "module/updateConfig?generate=true";
        const endpoint = this.BASEEMSURL +
            "amenitybooking/globalCategory/global-admin/create";
        return this.http.post(endpoint, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    //this for amenity edit through global admin
    createcategory(dataObj) {
        let url = this.BASEEMSURL +
            `amenitybooking/entitytype/global-admin/create/all`;
        return this.http.post(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    //this for amenity add and activate through global admin
    createcategoryNew(dataObj, spaceTypeId) {
        let url = this.BASEEMSURL +
            `amenitybooking/entitytype/global-admin/create/all/withDisable?globalCategory=${spaceTypeId}`;
        return this.http.post(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    createTitleUpdate(dataObj) {
        let url = this.BASEEMSURL + `/amenitybooking/globalCategory/update`;
        return this.http
            .post(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    //this for amenity edit through global admin
    addonsAndSpecialRequest(dataObj) {
        let url = this.BASEEMSURL + `amenitybooking/amenity/global-admin/create`;
        return this.http.post(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    //this for amenity add and activate through global admin
    addonsAndSpecialRequestNew(dataObj, spaceTypeId, specialRequesttype) {
        let url = this.BASEEMSURL + `amenitybooking/amenity/global-admin/create/all?globalCategory=${spaceTypeId}&specialRequest=${specialRequesttype}`;
        return this.http.post(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    createcategoryUpdate(dataObj) {
        let url = this.BASEEMSURL + `amenitybooking/entitytype/global-admin/update`;
        return this.http.put(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    addonsAndSpecialRequestUpdate(dataObj) {
        let url = this.BASEEMSURL + `amenitybooking/amenity/global-admin/update`;
        return this.http.put(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
}
DigitalBookingProService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DigitalBookingProService_Factory() { return new DigitalBookingProService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("BASE_URL"), i0.ɵɵinject(i2.ModuleidService)); }, token: DigitalBookingProService, providedIn: "root" });
