import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, of, BehaviorSubject, throwError } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";
import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { v4 as uuidv4 } from "uuid";
@Injectable()
export class ManageRequestsService {
  moduleId;
  BASEURL;
  EMSURL;
  SPACEURL;
  MRURL;
  BASE_URL_WFH;
  BASE_URL_DIGITAL;
  userSubject$ = new BehaviorSubject<any>([]);
  users$ = this.userSubject$.asObservable();
  departmentSubject$ = new BehaviorSubject<any>([]);
  departments$ = this.departmentSubject$.asObservable();
  buildingSubject$ = new BehaviorSubject<any>([]);
  buildings$ = this.buildingSubject$.asObservable();
  floorSubject$ = new BehaviorSubject<any>([]);
  floors$ = this.buildingSubject$.asObservable();

  currentViewSubject$ = new BehaviorSubject<string>("bookingList");
  currentView$ = this.currentViewSubject$.asObservable();

  rosterData = new BehaviorSubject<any>([]);
  rosterDataObj = this.rosterData.asObservable();

  bookingData = new BehaviorSubject<any>([]);
  showCalender = new BehaviorSubject<boolean>(false);
  showColleague = new BehaviorSubject<boolean>(false);
  constructor(
    public http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public moduleidService: ModuleidService
  ) {
    //this.BASE_URL = environment.BASE_URL+'ems/';
    //alert(baseUrl);
    this.BASEURL = baseUrl;
    this.EMSURL = environment.BASE_URL + "ems/";
    this.SPACEURL = environment.BASE_URL_SPACE;
    this.MRURL = environment.BASE_URL + "meetingroom/";
    this.BASE_URL_WFH = environment.BASE_URL_WFH;
    this.BASE_URL_DIGITAL = environment.BASE_URL;
    this.moduleId = moduleidService.getModueId();
  }

  bookingCalender(data) {
    this.bookingData.next(data);
  }

  currentViewSubject(data) {
    this.currentViewSubject$.next(data);
  }

  showBookingCalender(data) {
    this.showCalender.next(data);
  }

  //Filter Datas of Booking Roster
  bookingRoster(rosterData: any) {
    this.rosterData.next(rosterData);
  }

  showFindColleague(data) {
    this.showColleague.next(data);
  }

  findAllSeatsOfBuildings(zoneId, data) {
    let url;
    url = this.BASEURL + `entity/findAvailableCounts`;

    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  findAllAvailabilitySeatsMapView(zoneId, data) {
    let url;
    url = this.BASEURL + `entity/availabilityMapView`;

    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getAllFloorDetails(zoneId, data: any): Observable<any> {
    let searchParams = data.searchArr;
    if (searchParams) {
      let status = "states=PARTIAL,COMPLETE";
      let leaseStart = "";
      let leaseEnd = "";
      let buildingIds = "";
      if (searchParams.status != "") {
        status = "states=" + searchParams.status;
      }

      if (searchParams.leaseStart != "") {
        leaseStart = "&leaseStart=" + searchParams.leaseStart;
      }

      if (searchParams.leaseEnd != "") {
        leaseEnd = "&leaseEnd=" + searchParams.leaseEnd;
      }

      if (searchParams.buildingIds != "") {
        buildingIds = "&buildingIds=" + searchParams.buildingIds;
      }

      searchParams = status + leaseStart + leaseEnd + buildingIds;
    } else {
      searchParams = "";
    }

    //const headers = new HttpHeaders().set('ss-header', '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":1,"sessionId":"2c234e44-c39f-4a0b-ba78-52992466abbd","enterpriseId":1}');
    //let url = `http://192.168.1.37:9084/spacemanagement/onboarding/floorDetails/getAll?size=${data.pageSize}&page=${data.pageIndex}`+searchParams;
    let url = this.BASEURL + `requests/approved?` + searchParams;
    //return this.http.get<any>(url, { headers: headers });
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  assignSeats(zoneId, data: any) {
    let url;
    url = this.BASEURL + `requests/assignSeats`;

    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getAllDepartments(zoneId, flrZoneId) {
    let url;
    url =
      this.BASEURL + `socialdistancing/departmentCounts?zoneId=` + flrZoneId;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  findDetailsByTicketId(zoneId, ticketNo) {
    let url;
    url = this.BASEURL + `requests/ticketId?ticketId=` + ticketNo;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getAllBookings(
    zoneId,
    pageIndex,
    pageSize,
    searchString,
    type
  ): Observable<any> {
    let url =
      this.BASEURL +
      `booking/get?page=` +
      pageIndex +
      `&size=` +
      pageSize +
      `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING,` +
      searchString +
      ",demandType:" +
      type;
    //let url = this.BASEURL + `booking/get?search=startTime>0,requestType:ADMIN_BOOKING,&page=`+ pageIndex +`&size=`+ pageSize;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getAllBookingsV2(
    zoneId,
    pageIndex,
    pageSize,
    searchString,
    type,
    frompage
  ): Observable<any> {
    let usersearch = "";
    if (frompage == "user") {
      usersearch = ",userId:" + localStorage.getItem("ssadmin_userId");
    }
    let url =
      this.BASEURL +
      `booking/getV2?page=` +
      pageIndex +
      `&size=` +
      pageSize +
      `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING,` +
      searchString +
      ",demandType:" +
      type +
      usersearch;
    //let url = this.BASEURL + `booking/get?search=startTime>0,requestType:ADMIN_BOOKING,&page=`+ pageIndex +`&size=`+ pageSize;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  deleteMyBooking(zoneId, data): Observable<any> {
    let url = this.BASEURL + `booking/cancel`;
    return this.http.put<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  deleteBooking(zoneId, data): Observable<any> {
    let url = this.BASEURL + `booking/cancel/admin`;
    return this.http.put<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  saveBooking(reqObj, zoneId): Observable<any> {
    let url = `${this.BASEURL}booking/create/admin`;
    return this.http
      .post(url, reqObj, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(catchError((err) => throwError(err)));
  }

  availableEntitiesInArea(reqObj, zoneId) {
    let url = `${this.BASEURL}entity/availableEntitiesInArea`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  availableAllEntitiesInFloor(reqObj, zoneId) {
    let url = `${this.BASEURL}entity/allEntitiesInArea`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }
  getAvailableCounts(reqObj, currentZone): Observable<any> {
    let url = `${this.BASEURL}entity/findAvailableCounts`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }
  getAvailabilityMapData(reqObj, currentZone): Observable<any> {
    let url = `${this.BASEURL}entity/availabilityMapView`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }
  getBookingAutocompleteList(data, zoneId): Observable<any> {
    let term = data.searchTerm.trim();
    /** Old way of department search */
    // if(term == "" || term.length <= 2) {
    //     return of([]);
    // }
    // if (data.searchType === "employee") {
    //     let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
    //     return this.http
    //         .get<any>(url, {
    //             params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    //         })
    //         .pipe(
    //             map((res) => res["response"]),
    //             catchError((err) => of([]))
    //         );
    // } else {
    //     let resData = [];
    //     this.departments$
    //         .pipe(
    //             map((departments) =>
    //                 departments.filter((department) => {
    //                     let depName = department["name"]
    //                         .toLowerCase()
    //                         .toString();
    //                     let searchTerm = term.toLowerCase();
    //                     let res = depName.indexOf(searchTerm);
    //                     if (res >= 0) {
    //                         return department;
    //                     }
    //                 })
    //             )
    //         )
    //         .subscribe((res) => {
    //             resData = res;
    //         });

    //     return of(resData);
    // }

    /** new way department by search */
    if (data.searchType === "department") {
      let resData = [];
      this.departments$
        .pipe(
          map((departments) =>
            departments.filter((department) => {
              let depName = department["name"].toLowerCase().toString();
              let searchTerm = term.toLowerCase();
              let res = depName.indexOf(searchTerm);
              if (res >= 0) {
                return department;
              }
            })
          )
        )
        .subscribe((res) => {
          resData = res;
        });

      return of(resData);
    } else {
      if (term == "" || term.length <= 2) {
        return of([]);
      }
      if (data.searchType === "employee" || data.searchType === "user") {
        let url=''
        if(localStorage.getItem('tenant_Engagement') == "true"){
          url = `${this.EMSURL}user/search/name/email/tenant/list?name=${term}`;
        }
        else
     url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;

        return this.http
          .get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, 3),
          })
          .pipe(
            map((res) => res["response"]),
            catchError((err) => of([]))
          );
      }
    }
  }

    getBookingAutocompleteList2(data, zoneId): Observable<any> {
        let term = data.searchTerm.trim();
        if(data.searchType === "department"){
            let resData = [];
            this.departments$
                .pipe(
                    map((departments) =>
                        departments.filter((department) => {
                            let depName = department["name"]
                                .toLowerCase()
                                .toString();
                            let searchTerm = term.toLowerCase();
                            let res = depName.indexOf(searchTerm);
                            if (res >= 0) {
                                return department;
                            }
                        })
                    )
                )
                .subscribe((res) => {
                    resData = res;
                });
                
            return of(resData);
        }else{
            if(term == "" || term.length <= 2) {
                return of([]);
            }
            if (data.searchType === "employee" || data.searchType === "user") {
                let url = `${this.EMSURL}user/search/v3?searchString=${term}&searchParams=name,email,phone_num`;
                return this.http
                    .get<any>(url, {
                        params: new ZoneAndModuleHttpParams(zoneId, 3),
                    })
                    .pipe(
                        map((res) => res["response"]),
                        catchError((err) => of([]))
                    );
            }
        }
    }


  getBookingAutocompleteManagerList(data, zoneId): Observable<any> {
    let term = data.searchTerm.trim();
    if (term == "" || term.length <= 2) {
      return of([]);
    }
    if (data.searchType === "employee" || data.searchType === "user") {
      let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num&searchManagersOnly=true`;
      return this.http
        .get<any>(url, {
          params: new ZoneAndModuleHttpParams(zoneId, 3),
        })
        .pipe(
          map((res) => res["response"]),
          catchError((err) => of([]))
        );
    }
  }

  downloadCsv(searchString, zoneId, pageIndex, pageSize): Observable<any> {
    let url =
      this.BASEURL +
      `booking/downloadCSV?page=` +
      pageIndex +
      `&size=` +
      pageSize +
      `&sort=createdAt,ASC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING`;
    if (searchString) {
      url += `,${searchString}`;
    }
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadCsvBydate(
    searchString,
    zoneId,
    pageIndex,
    pageSize,
    startDate,
    endDate,
    demandType
  ): Observable<any> {
    let url;
    url =
      this.BASEURL +
      `booking/downloadNewFormatCSV?page=0&size=10&sort=createdAt,DESC&startTime=${startDate}&endTime=${endDate}&search=requestType:BOOKING_REQUEST,%27requestType:ADMIN_BOOKING,,demandType:${demandType}`;

    if (searchString) {
      url += `,${searchString}`;
    }
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadMyBookingCsv(
    zoneId,
    pageIndex,
    pageSize,
    downloadSearch
  ): Observable<any> {
    let url =
      this.BASEURL +
      `booking/downloadCSV?page=` +
      pageIndex +
      `&size=` +
      pageSize +
      `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING,${downloadSearch}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadCSVStatus(searchString, zoneId): Observable<any> {
    // let url;
    // if(startTime && endTime) {
    //     url = this.BASEURL + 'allocation/downloadCSV?searchCriteria=DATE_RANGE&time=' + startTime + '&endTime=' + endTime + '';
    // } else if(searchString)
    // {
    //     url = this.BASEURL + 'allocation/downloadCSV?'+searchString;
    // } else {
    //     url = this.BASEURL + 'allocation/downloadCSV';
    // }
    // return this.http.get<any>(url, {
    //     params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    // });

    let url = this.BASEURL + `allocation/downloadCSV?${searchString}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadCSVStatusMR(searchString, zoneId): Observable<any> {
    // let url;
    // if(startTime && endTime) {
    //     url = this.BASEURL + 'allocation/downloadCSV/mr?searchCriteria=DATE_RANGE&time=' + startTime + '&endTime=' + endTime + '';
    // } else if(searchString)
    // {
    //     url = this.BASEURL + 'allocation/downloadCSV/mr?'+searchString;
    // } else {
    //     url = this.BASEURL + 'allocation/downloadCSV/mr';
    // }
    let url = this.BASEURL + `allocation/downloadCSV/mr?${searchString}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  /**autocomplete */
  getAutocompleteList(data, zoneId): Observable<any> {
    let term = data.searchTerm.trim();
    if (term == "" || term.length <= 2) {
      let resData = [];
      if (data.searchType === "departmentName") {
        this.departments$.subscribe(async (res) => {
          resData = res;
        });
        return of(resData);
      } else if (
        data.searchType === "building" ||
        data.searchType === "floor" ||
        data.searchType === "meetingroom" ||
        data.searchType === "deskNumber"
      ) {
        this.buildings$.subscribe(async (res) => {
          resData = res;
        });
        return of(resData);
      } else if (data.searchType === "user") {
        this.users$.subscribe(async (res) => {
          resData = res;
        });

        return of(resData);
      } else {
        return of([]);
      }
    }
    if (data.searchType === "user") {
      let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
      return this.http
        .get<any>(url, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
        .pipe(
          map((res) => res["response"]),
          catchError((err) => of([]))
        );
    } else if (data.searchType === "departmentName") {
      let resData = [];
      this.departments$
        .pipe(
          map((departments) =>
            departments.filter((department) => {
              let depName = department["name"].toLowerCase().toString();
              let searchTerm = term.toLowerCase();
              let res = depName.indexOf(searchTerm);
              if (res >= 0) {
                return department;
              }
            })
          )
        )
        .subscribe((res) => {
          resData = res;
        });

      return of(resData);
    } else if (
      data.searchType === "building" ||
      data.searchType === "floor" ||
      data.searchType === "meetingroom" ||
      data.searchType === "deskNumber"
    ) {
      let resData = [];
      this.buildings$
        .pipe(
          map((buildings) =>
            buildings.filter((building) => {
              let depName = building["name"].toLowerCase().toString();
              let searchTerm = term.toLowerCase();
              let res = depName.indexOf(searchTerm);
              if (res >= 0) {
                return building;
              }
            })
          )
        )
        .subscribe((res) => {
          resData = res;
        });

      return of(resData);
    }

    /** Show department data without any search character */
    // if(data.searchType === "departmentName"){
    //     let resData = [];
    //     this.departments$
    //         .pipe(
    //             map((departments) =>
    //                 departments.filter((department) => {
    //                     let depName = department["name"]
    //                         .toLowerCase()
    //                         .toString();
    //                     let searchTerm = term.toLowerCase();
    //                     let res = depName.indexOf(searchTerm);
    //                     if (res >= 0) {
    //                         return department;
    //                     }
    //                 })
    //             )
    //         )
    //         .subscribe((res) => {
    //             resData = res;
    //         });

    //     return of(resData);
    // }else{
    //     if(term == "" || term.length <= 2) {
    //         return of([]);
    //     }
    //     if (data.searchType === "user") {
    //         let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
    //         return this.http
    //             .get<any>(url, {
    //                 params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    //             })
    //             .pipe(
    //                 map((res) => res["response"]),
    //                 catchError((err) => of([]))
    //             );
    //     }else if (data.searchType === "building" || data.searchType === "deskNumber") {
    //         let resData = [];
    //         this.buildings$
    //             .pipe(
    //                 map((buildings) =>
    //                     buildings.filter((building) => {
    //                         let depName = building["name"]
    //                             .toLowerCase()
    //                             .toString();
    //                         let searchTerm = term.toLowerCase();
    //                         let res = depName.indexOf(searchTerm);
    //                         if (res >= 0) {
    //                             return building;
    //                         }
    //                     })
    //                 )
    //             )
    //             .subscribe((res) => {
    //                 resData = res;
    //             });

    //         return of(resData);
    //     }

    // }
  }
  getAllBuildings(zoneId, data) {
    let url = this.EMSURL + "zones/childZones/v2";
    return this.http
      .post(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"].zoneMap[zoneId]),
        catchError((err) => of([]))
      );
  }

  getBuilding(zoneId, data) {
    let url = this.EMSURL + "zones/childZones/v2";
    return this.http
      .post(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        shareReplay(1),
        map((res) => res["response"].zoneMap[zoneId]),
        catchError((err) => of([])),

        map((res) => {
          if (res.length) {
            return res.map((res) => ({
              userId: res.zoneId,
              ...res,
            }));
          } else {
            return of([]);
          }
        }),

        tap((res) => {
          this.buildingSubject$.next(res);
        })
      );
  }

  /*get floor*/

  getFloors(zoneId, data): Observable<any> {
    let url = this.EMSURL + "zones/childZones/v2";
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        shareReplay(1),
        map((res) => res["response"]["zoneMap"][data.zoneIds[0]]),
        catchError((err) => of([])),
        map((res) => {
          if (res.length) {
            return res.map(({ id: userId, ...rest }) => ({
              userId,
              ...rest,
            }));
          } else {
            return of([]);
          }
        }),
        tap((res) => {
          this.floorSubject$.next(res);
        })
      );
  }

  /* for floor dropdown data*/

  getFloorDropDown(data) {
    //let term = data.searchTerm.trim();

    let resData = [];
    this.floors$
      .pipe(
        map((floors) =>
          floors.filter((floor) => {
            let depName = floor["name"].toLowerCase().toString();
            let searchTerm = data.trim().toLowerCase();
            let res = depName.indexOf(searchTerm);
            if (res >= 0) {
              return floor;
            }
          })
        )
      )
      .subscribe((res) => {
        resData = res;
      });

    return of(resData);
  }
  getDepartmentList(zoneId) {
    let url = `${this.EMSURL}department/getAll`;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        shareReplay(1),
        map((res) => res["response"]),
        catchError((err) => of([])),
        map((res) => {
          if (res.length) {
            return res.map(({ id: userId, ...rest }) => ({
              userId,
              ...rest,
            }));
          } else {
            return of([]);
          }
        }),
        tap((res) => {
          this.departmentSubject$.next(res);
        })
      );
  }

  getBookingStatus(searchString, zoneId, pageSize, pageIndex) {
    let searchParams = "";
    // if (data.searchType != "" && data.searchValue != "") {
    //     searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
    // }

    // let url =
    //     this.BASEURL +
    //     `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
    //     searchParams;
    let url =
      this.BASEURL +
      `allocation/search/checkIn?size=${pageSize}&page=${pageIndex}${searchString}`;
    // +
    // searchParams;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getAllMyDeskBookings(searchString, zoneId, pageSize, pageIndex) {
    let searchParams = "";
    let url;
    if (searchString) {
      url =
        this.BASEURL +
        `allocation/search/userCheckIn?${searchString}&size=${pageSize}&page=${pageIndex}`;
    } else {
      url =
        this.BASEURL +
        `allocation/search/userCheckIn?size=${pageSize}&page=${pageIndex}`;
    }
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  deskBookingCheckIn(zoneId, data) {
    let url = `${this.BASEURL}booking/validateAndCheckIn`;
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  deskBookingCheckOut(zoneId, data) {
    let url = `${this.BASEURL}booking/cancel`;
    return this.http
      .put<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getAllCategory(zoneId) {
    let entityType = "MEETING_ROOM";
    let url;
    url = `${this.BASEURL}entitytype/get/category?entityCategory=` + entityType;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, 3),
      })
      .pipe();
  }

  getAllAmenitiesData(zoneId, pageIndex, pageSize) {
    let url;
    url = `${this.BASEURL}amenity/get/amenity?page=${pageIndex}&size=${pageSize}`;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, 3),
      })
      .pipe();
  }

  getAllAmenities(zoneId) {
    let url;
    url = `${this.BASEURL}amenity/get/amenity`;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, 3),
      })
      .pipe();
  }

  getAllSpecialRequestsData(zoneId, pageIndex, pageSize) {
    let url;
    url = `${this.BASEURL}amenity/get/specialRequest?page=${pageIndex}&size=${pageSize}`;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, 3),
      })
      .pipe();
  }

  getAllSpecialRequests(zoneId) {
    let url;
    url = `${this.BASEURL}amenity/get/specialRequest`;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, 3),
      })
      .pipe();
  }

  getAllMeetingRooms(zoneId, pageIndex, pageSize) {
    let url;
    url = `${this.BASEURL}entity/get?page=${pageIndex}&size=${pageSize}`;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, 3),
      })
      .pipe();
  }

  createAmenity(currentZone, reqObj) {
    let url = `${this.BASEURL}amenity/createV2`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, 3),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  updateAmenity(currentZone, reqObj) {
    let url = `${this.BASEURL}amenity/update`;
    return this.http
      .put<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  deleteAmenity(currentZone, reqObj) {
    let url = `${this.BASEURL}amenity/disable`;
    return this.http
      .put<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getConfig(currentZone) {
    let url;
    let modId = 3;
    url = `${this.EMSURL}module/getConfig?moduleId=${modId}&zoneId=${currentZone}`;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(currentZone, 3),
      })
      .pipe();
  }

  getAllUsersBookings(
    zoneId,
    pageIndex,
    pageSize,
    searchString,
    type
  ): Observable<any> {
    if (searchString == "") {
      searchString = undefined;
    }
    let url =
      this.BASEURL +
      `booking/get?page=` +
      pageIndex +
      `&sort=createdAt,DESC&` +
      `&size=` +
      pageSize +
      `&search='requestType:MR_BOOKING_REQUEST,'requestType:MR_ADMIN_BOOKING,` +
      searchString +
      ",demandType:" +
      type;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  deleteMeetingRoomsBooking(zoneId, data): Observable<any> {
    let url = this.BASEURL + `booking/cancel/admin`;
    return this.http.put<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }
  getAllQrcodeFloorDetails(zoneId) {
    let data: any = {
      zoneIds: [zoneId],
      childLevel: "FLOOR",
    };
    let url = this.EMSURL + `zones/childZones`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  getAllFloorDetailsQrcode(zoneId, data: any): Observable<any> {
    let searchParams = data.searchArr;
    if (searchParams) {
      let status = "states=PARTIAL,COMPLETE";
      let leaseStart = "";
      let leaseEnd = "";
      let buildingIds = "";
      if (searchParams.status != "") {
        status = "states=" + searchParams.status;
      }

      if (searchParams.leaseStart != "") {
        leaseStart = "&leaseStart=" + searchParams.leaseStart;
      }

      if (searchParams.leaseEnd != "") {
        leaseEnd = "&leaseEnd=" + searchParams.leaseEnd;
      }

      if (searchParams.buildingIds != "") {
        buildingIds = "&buildingIds=" + searchParams.buildingIds;
      }

      searchParams = status + leaseStart + leaseEnd + buildingIds;
    } else {
      searchParams = "";
    }

    let url = this.BASEURL + `socialdistancing/floorDetails`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  getSpaceViewAnalytics(data, currentZone) {
    let url = `${this.BASEURL}allocation/mapView?dayTime=${data.dayTime}&mapViewType=${data.viewType}&zoneId=${data.floorId}`;
    return this.http.get(url, {
      params: new ZoneAndModuleHttpParams(currentZone, 3),
    });
  }

  printSeats(zoneId, printAll, floorId) {
    let url =
      this.BASEURL + `reports/qrCodes?zoneId=${floorId}&showAll=${printAll}`;
    //return this.http.get<any>(url, { headers: headers });
    return this.http
      .get(url, {
        params: new ZoneAndModuleHttpParams(zoneId, 3),
        responseType: "blob",
      })
      .pipe();
  }

  saveBookingQrcode(zoneId, data: any): Observable<any> {
    var seatIds = data["selectedSeats"]
      .map(function (obj) {
        return obj.id;
      })
      .join(",");

    let url = this.BASEURL + `reports/qrCodes/entities?entityIds=` + seatIds;
    //return this.http.get<any>(url, { headers: headers });
    return this.http
      .get(url, {
        params: new ZoneAndModuleHttpParams(zoneId, 3),
        responseType: "blob",
      })
      .pipe();
  }

  getmeetingRoomAvailableCounts(reqObj, currentZone): Observable<any> {
    let url = `${this.BASEURL}entity/findAvailableCounts/mr`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, 3),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getDecorated(reqObj, currentZone): Observable<any> {
    let url = `${this.BASEURL}entity/get/decorated`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, 3),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  bookMeetingRoom(reqObj, currentZone): Observable<any> {
    let url = `${this.BASEURL}booking/create/admin`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, 3),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getConfigUpdate(reqObj, currentZone): Observable<any> {
    let url = `${this.EMSURL}module/updateConfig?generate=true`;
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, 3),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getMeetingRoomBookingStatus(searchString, zoneId, pageSize, pageIndex) {
    let searchParams = "";
    let url =
      this.BASEURL +
      `allocation/search/checkIn?size=${pageSize}&page=${pageIndex}${searchString}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  downloadMeetingRoomsCsv(zoneId, pageIndex, pageSize): Observable<any> {
    let url;
    url =
      this.BASEURL +
      `booking/downloadCSV/mr?page=` +
      pageIndex +
      `&size=` +
      pageSize +
      `&sort=createdAt,DESC&search=requestType:MR_BOOKING_REQUEST,'requestType:MR_ADMIN_BOOKING`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  downloadmeetingRoomCSVStatus(zoneId: any): Observable<any> {
    let url = this.BASEURL + "allocation/downloadCSV/mr";
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  getAllAvailableSeatAndBookedSeats(data, zoneId, userId) {
    let url;
    let colleagueUser = "";
    if (userId != null) {
      colleagueUser = `&collegueUserId=${userId}`;
    }

    let colleagueFlow = "";
    if (data.colleageFlow == true) {
      colleagueFlow = "&colleageFlow=true";
    }
    if (data.type != undefined && data.type == "teamMateCalender") {
      url =
        this.BASE_URL_WFH +
        `calendar/getAllStatusAndAvailableAndBookedSeats?startTime=${data.firstDate}&endTime=${data.lastDate}` +
        colleagueUser +
        colleagueFlow;
    } else if (data.type != undefined && data.type == "myCalender") {
      url =
        this.BASE_URL_WFH +
        `calendar/getAllStatusAndAvailableAndBookedSeats?startTime=${data.firstDate}&endTime=${data.lastDate}` +
        colleagueUser +
        colleagueFlow;
    } else {
      url =
        this.BASE_URL_WFH +
        `calendar/getAllStatusAndAvailableAndBookedSeats?startTime=${data.firstDate}&endTime=${data.lastDate}` +
        colleagueUser +
        colleagueFlow;
    }

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getUserType(zoneId, type, userId?) {
    let url;
    //url = this.BASE_URL_WFH + `getSettingsForUser`;
    if (!!userId) {
      url =
        this.BASE_URL_WFH + `wfh-settings/getSettingsForUser?proxyId=${userId}`;
    } else {
      url = this.BASE_URL_WFH + `wfh-settings/getSettingsForUser`;
    }
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getUserTypeBulk(zoneId, zoneIds) {
    let url;
    url = `${this.EMSURL}module/configBulk?zoneId=${zoneIds}&moduleId=1`;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  deleteCalnder(startTime, endTime, zoneId) {
    let url;
    url =
      this.BASE_URL_WFH +
      `rosters/deleteRostersInDateRange?startTime=${startTime}&endTime=${endTime}`;

    return this.http
      .delete<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getSeatAvailablity(zoneId, data) {
    let url =
      this.SPACEURL +
      "entity/findAvailableCountsForEachDayInGivenRangeWithOptionalFilter";

    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  saveSearchByUserId(data, zoneId) {
    let url = this.SPACEURL + "recentsearch/create";
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getAllRecentSearchs(zoneID) {
    let url = this.SPACEURL + "recentsearch/getAll";
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneID, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getAllDepartmentList(zoneId) {
    let url =
      this.SPACEURL + "recentsearch/department/getAll?pageNumber=0&pageSize=10";
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getConfigTimings(zoneId) {
    let url;
    let data = [1, 483, 48];
    url = this.EMSURL + `module/getConfigMapWithModuleId?zoneId=${zoneId}`;
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getManualBookings(zoneId, reqObj) {
    let url = this.SPACEURL + "entity/findAvailableCounts";
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

  getRecommendations(zoneId, data) {
    let url = this.SPACEURL + "entity/recommendation/singleUser";
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getAvailabilityMapDataBooking(reqObj, currentZone): Observable<any> {
    let url = this.SPACEURL + "entity/availabilityMapViewForListOfDays";
    return this.http
      .post<any>(url, reqObj, {
        params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => throwError(err))
      );
  }

    getAvailabilityMapDataBookingDigital(reqObj, currentZone, gecId): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    currentZone +
                    ',"moduleId": 1,"sessionId":"' + localStorage.getItem('ssadmin_session') + '","gecId":"'+gecId+'"}'
            })
            // {"correlationId":"cf0bdf52-86f4-44ec-8320-298a6886d7e8","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,
            // "moduleId":9,"sessionId":"22f08a24-f1cb-4c10-b380-fbe99d259d6c"}
        };
        let url
        if(localStorage.getItem('tenant_Engagement') == "true"){
        url = this.BASE_URL_DIGITAL+"amenitybooking/entity/availabilityMapViewForListOfDaysOptimized/tenant";
        }else{
        url = this.BASE_URL_DIGITAL+"amenitybooking/entity/availabilityMapViewForListOfDaysOptimized";
        }
        return this.http
            .post<any>(url, reqObj, httpOptions)
            .pipe(
                map((res) => res["response"]),
                catchError((err) => throwError(err))
            );
    }

  getAmenities(zoneId): Observable<any> {
    let url;
    url = this.SPACEURL + `amenity/get/category?AmenityCategory=SEAT`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
    // return this.http.get<any>(url, httpOptions);
  }

  saveMultiBooking(reqObj, zoneId): Observable<any> {
    let url = this.SPACEURL + "/booking/create/wfh?isRosterUpdation=true";

    return this.http
      .post(url, reqObj, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(catchError((err) => throwError(err)));
  }
  addQuestionResponse(zoneId, reqObj) {
    let url = this.BASEURL + `question-response/addAnswers`;
    return this.http.post<any>(url, reqObj, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getQuestionResponse(zoneId, userId) {
    let url = this.BASEURL + `question-response/getAll?userId=${userId}`;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(map((o) => o.response.content));
  }

  getConfigSettings(zoneId) {
    let url = this.SPACEURL + `sm-settings/get`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  updateConfigSettings(data, zoneId) {
    let url;
    url = this.SPACEURL + `sm-settings/save`;
    return this.http.post(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getBlockedDates(zoneId, startDate, EndDate) {
    let url =
      this.SPACEURL +
      `blockedDays/getBlockedDaysWithinDateRange?startDate=` +
      startDate +
      `&endDate=` +
      EndDate;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

    wayFinding(zoneId,reqObj) {
        let url = `https://routing.smartenspaces.com/wayfinding/routeToSeat?zone_id=${reqObj.zoneId}&entity_id=${reqObj.entityInfoId}&base_url=${environment.BASE_URL}`;
        //let url = `https://routing.smartenspaces.com/wayfinding/routeToSeat?zone_id=29&entity_id=1261&base_url=https://jumpreeparallel.smartenspaces.com`;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            });
    }

  //meeting room settings

  getIntegrationData(zoneId) {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
          zoneId +
          ', "moduleId":53,"sessionId":"' +
          localStorage.getItem("ssadmin_session") +
          '"}',
      }),
    };
    let url = `${this.BASEURL}settings/get`;
    return this.http.get(url, httpOptions);
  }

  testIntegrationData(data, zoneId): Observable<Object> {
    const endpoint = this.BASEURL + "booking/create/integrationTest";
    return this.http
      .post(endpoint, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(shareReplay());
  }
  testGsuiteIntegrationData(data, zoneId): Observable<Object> {
    const endpoint = this.BASEURL + "booking/create/integrationTest";
    return this.http
      .post(endpoint, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(shareReplay());
  }
  setupIntegrationData(data, zoneId): Observable<Object> {
    const endpoint = this.BASEURL + "settings/save";
    return this.http
      .post(endpoint, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(shareReplay());
  }

  getMeetingVideoConference(zoneId) {
    let searchParams = "";
    let url = this.EMSURL + `module/getConfig?moduleId=53&zoneId=${zoneId}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
  }

  getMeetingVideoConferenceSave(data, zoneId): Observable<Object> {
    var dataVal = {
      config: JSON.stringify(data),
      moduleId: "53",
      zoneId: `${zoneId}`,
    };

    const endpoint = this.EMSURL + "module/updateConfig";
    return this.http.post(endpoint, dataVal, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  videoTestIntegration(data, zoneId): Observable<Object> {
    const endpoint = this.BASEURL + "booking/create/zoomTest";
    return this.http
      .post(endpoint, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(shareReplay());
  }

  videoSetUpIntegration(data, zoneId): Observable<Object> {
    const endpoint = this.BASEURL + "settings/save";
    return this.http
      .post(endpoint, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(shareReplay());
  }

  getSettingsAvailability(zoneId): Observable<any> {
    let url = this.BASEURL + `sm-settings/getSettingsIfPresent`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getAllPermanentAllocations(
    searchString,
    zoneId,
    pageSize,
    pageIndex
  ): Observable<any> {
    let url =
      this.BASEURL +
      `allocation/search?` +
      searchString +
      `&size=` +
      pageSize +
      `&page=` +
      pageIndex +
      `&isPermanant=true`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  deleteAllocation(zoneId, data): Observable<any> {
    let url = this.BASEURL + `booking/cancel`;
    return this.http.put<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  searchAllocation(searchString, zoneId, pageSize, pageIndex) {
    let url =
      this.BASEURL +
      `allocation/search?` +
      searchString +
      `&size=` +
      pageSize +
      `&page=` +
      pageIndex +
      `&isPermanant=true`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  clearCacheData(zoneId) {
    let url = `${this.BASEURL}clearCache`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 3),
    });
    //return this.http.get(url, httpOptions);
  }

  clearCacheDataSettings(zoneId) {
    let url = `${this.BASEURL}clearCache`;

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, 1),
    });
    //return this.http.get(url, httpOptions);
  }

  saveSeatId(data, zoneId) {
    let url;
    url = this.SPACEURL + `workStatus/save`;
    return this.http.post(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getEmployeeNameAutoCompleteList(data, zoneId): Observable<any> {
    let term = data.searchTerm.trim();
    if (term == "" || term.length <= 2) {
      return of([]);
    }
    let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
    // let url = `${this.EMSURL}user/search/v2/serviceRequestExecutive?searchString=${term}&searchParams=name,email,phone_num`
    // + `&role=SUPER_ADMIN,Service Request Admin,Service Request Executive`;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => of([]))
      );
  }
}
