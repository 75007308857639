<div class="scroller">
    <div *ngIf="pageType == 'globalSetup'">
        <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button> -->
        <div>
            <p class="pg-main anchor-main">
                {{"hi" | translate}}!
                <span class="pg-main anchor-main text-captialize">{{
                    userName
                }}</span>
            </p>
            <ng-container *ngIf="!isShowableTrial">
                <h1 class="heading-font-bold">{{"welcomeToJumpree" | translate}}</h1>
                <p class="pg-g-main mb-1 mt-4">
                    {{"yourLicenseIsNow" | translate}}
                    <span class="anchor-main"> {{"activeSmall" | translate}} </span>
                </p>
                <p class="pg-g-main">
                    {{"weLookForwardToGiving" | translate}} {{ clientName }} {{"theSmall" | translate}}
                    <span class="customWorkplace anchor-main"
                        >{{"bestWorkplaceExperience" | translate}}</span
                    >
                </p>
            </ng-container>
            <ng-container *ngIf="isShowableTrial">
                <h1 class="heading-font-bold">
                    {{"welcomeToJumpreeAndThankYouForExpressingYourIntrest" | translate}}
                </h1>
                <p class="pg-g-main mb-1 mt-4">
                    {{"asThisTrialVersionYouWillHaveTheOpportunity" | translate}}
                </p>
                <p class="pg-g-main">
                    {{"beforeGettingStartedHereAreAFewImportantThings" | translate}}
                </p>
                <ul>
                    <li>
                        <p class="pg-g-main">
                            {{"thisIsATrialVersionWhileWeHaveTakenStepsTo" | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="pg-g-main">
                            {{"provideFeedbackWeRelyOnYoruFeedbackToHelpUs" | translate}}
                        </p>
                    </li>
                </ul>

                <p class="pg-g-main">
                    {{"weHopeYouEnjoyTryingOurTrialVersionOfJumpree" | translate}}
                </p>
            </ng-container>
            <!-- <button type="button" class="btn btn-main custom-btn w-200 mt-5" (click)="showLocationTab()">Get Started</button>
            <p class="pg-main mt-3">Too busy to onboard? <a href="#" class="anchor-main customLinkAdmin">Assign a new global admin</a></p> -->

            <div class="">
                <div class="col-lg-12 col-md-12 h-200" *ngIf="!isShowableTrial">
                    <img
                        class="d-block m-auto w-40 h-100"
                        src="../../assets/images/jumpree_digital/globe_map.svg"
                    />

                    <button
                        type="button"
                        class="btn btn-main float-right mediumBtn"
                        style="margin-top: -5%"
                        (click)="closePopup(pageType)"
                    >
                        {{"thanks" | translate}}!
                    </button>
                </div>
                <div class="col-lg-12 col-md-12 " *ngIf="isShowableTrial">
                    <button
                        type="button"
                        class="btn btn-main float-right mediumBtn"
                        (click)="closePopup(pageType)"
                    >
                        {{"thanks" | translate}}!
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'addLocationsManually'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn()"
                ></i>
                {{"addLocations" | translate}} <span *ngIf="!individual">(1/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupLocations' | translate}}"></app-sub-heading>
            <form
                autocomplete="off"
                [formGroup]="addLocationsManuallyForm"
                (ngSubmit)="submitaddLocationsManuallyForm()"
            >
                <div class="pl-0">
                    <div class="row">
                        <div class="form-group col-lg-4 col-md-12">
                            <label class="float-left formLabel"
                                >{{"locationNameCaps" | translate}}
                                <span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control blueText "
                                placeholder="{{'typeLocationName' | translate}}"
                                formControlName="locationName"
                                autocomplete="off"
                            />
                            <div
                                *ngIf="
                                    submitted &&
                                    addLocationsManuallyForm.controls
                                        .locationName.errors
                                "
                            >
                                <div class="text-danger formLabel">
                                    {{"locationIsRequired" | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-4 col-md-12">
                            <label class="float-left formLabel"
                                >{{"countryCaps" | translate}}
                                <span class="text-danger">*</span></label
                            >
                            <!-- <input type="text" #searchTerm
                      class="form-control blueText inputPadding" 
                      autocomplete="off"
                      (paste)="getAutocompleteList(searchTerm.value)"
                      (input)="getAutocompleteList(searchTerm.value)"
                      placeholder="" formControlName="country"
                          ><i class="fa fa-search iconSearch"></i> -->
                            <input
                                #searchTerm
                                class="form-control blueText inputPadding"
                                [matAutocomplete]="auto"
                                [formControl]="countryCtrl"
                                formControlName="country"
                                autocomplete="false"
                                placeholder="{{'searchCountry' | translate}}"
                            />
                            <i class="fa fa-search iconSearch"></i>

                            <mat-autocomplete
                                autocomplete="false"
                                class="autoComplete"
                                #auto="matAutocomplete"
                            >
                                <mat-option
                                    *ngFor="
                                        let country of filteredCountry | async
                                    "
                                    [value]="country.name"
                                    (click)="countrySelection(country.name)"
                                >
                                    <span>{{ country.name }}</span>
                                </mat-option>
                            </mat-autocomplete>

                            <div
                                *ngIf="
                                    addLocationsManuallyForm.controls.country
                                        .errors?.incorrect
                                "
                                class="mt-1"
                            >
                                <div class="text-danger formLabel">
                                    {{"incorrectCountryValue" | translate}}
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    addLocationsManuallyForm.controls.country
                                        .errors?.required && submitted
                                "
                                class="mt-1"
                            >
                                <div class="text-danger formLabel">
                                    {{"countryRequired" | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-4 col-md-12">
                            <label class="float-left formLabel"
                                >{{"stateCaps" | translate}} <span class="text-danger">*</span></label
                            >
                            <!-- <input type="text" class="form-control blueText inputPadding"   placeholder="" formControlName="state"
                          ><i class="fa fa-search iconSearch"></i> -->
                            <input
                                #searchTerm
                                class="form-control blueText inputPadding"
                                [matAutocomplete]="auto1"
                                [formControl]="stateCtrl"
                                formControlName="state"
                                [readonly]="isReadonlyState"
                                autocomplete="off"
                                placeholder="{{'searchState' | translate}}"
                            />
                            <i class="fa fa-search iconSearch"></i>

                            <mat-autocomplete #auto1="matAutocomplete">
                                <mat-option
                                    *ngFor="
                                        let state of filteredStates1 | async
                                    "
                                    [value]="state.name"
                                    (click)="stateSelection(state.name)"
                                >
                                    <span>{{ state.name }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <div
                                *ngIf="
                                    addLocationsManuallyForm.controls.state
                                        .errors?.incorrect
                                "
                                class="mt-1"
                            >
                                <div class="text-danger formLabel">
                                    {{"incorrectStateValue" | translate}}
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    addLocationsManuallyForm.controls.state
                                        .errors?.required && submitted
                                "
                                class="mt-1"
                            >
                                <div class="text-danger formLabel">
                                    {{"stateRequired" | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-4 col-md-12">
                            <label class="float-left formLabel"
                                >{{"cityCaps" | translate}} <span class="text-danger">*</span></label
                            >
                            <!-- <input type="text" class="form-control blueText inputPadding"   placeholder="" formControlName="city"
                          ><i class="fa fa-search iconSearch"></i> -->
                            <input
                                #searchTerm
                                class="form-control blueText  inputPadding"
                                [matAutocomplete]="auto2"
                                [formControl]="cityCtrl"
                                formControlName="city"
                                [readonly]="isReadonlyCity"
                                autocomplete="off"
                                placeholder="{{'searchCity' | translate}}"
                            />
                            <i class="fa fa-search iconSearch"></i>

                            <mat-autocomplete #auto2="matAutocomplete">
                                <mat-option
                                    *ngFor="let city of filteredCity | async"
                                    [value]="city.name"
                                    (click)="citySelection(city.name)"
                                >
                                    <span>{{ city.name }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <div
                                *ngIf="
                                    addLocationsManuallyForm.controls.city
                                        .errors?.incorrect
                                "
                                class="mt-1"
                            >
                                <div class="text-danger formLabel">
                                    {{"incorrectCityValue" | translate}}
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    addLocationsManuallyForm.controls.city
                                        .errors?.required && submitted
                                "
                                class="mt-1"
                            >
                                <div class="text-danger formLabel">
                                    {{"cityRequired" | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-4 col-md-12">
                            <label class="float-left formLabel"
                                >{{"timezoneCaps" | translate}}
                                <span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control blueText "
                                placeholder="{{'enterTimezone' | translate}}"
                                formControlName="timezone"
                                autocomplete="off"
                                [formControl]="timeZoneCtrl"
                                [matAutocomplete]="auto3"
                                autocomplete="off"
                            />
                            <mat-autocomplete #auto3="matAutocomplete">
                                <mat-option
                                    *ngFor="
                                        let zone of filteredTimezones | async
                                    "
                                    value="{{ zone.value }}"
                                    (click)="selectTimeZone(zone.value)"
                                >
                                    {{ zone.name }}
                                </mat-option>
                            </mat-autocomplete>

                            <div
                                *ngIf="
                                    addLocationsManuallyForm.controls.timezone
                                        .errors?.required && submitted
                                "
                            >
                                <div class="text-danger formLabel">
                                    {{"timezoneIsRequired" | translate}}
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    addLocationsManuallyForm.controls.timezone
                                        .errors?.incorrect
                                "
                                class="mt-1"
                            >
                                <div class="text-danger formLabel">
                                   {{"incorrectTimezoneValue" | translate}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="form-group col-lg-4 col-md-12">
                            <p>
                               {{"locationsAdded" | translate}}:
                                <span
                                    class="regular-bold-blue"
                                    style="text-decoration: none"
                                    >{{ countLocation }}</span
                                >
                            </p>
                        </div>
                        <div class="form-group col-lg-8 col-md-12">
                            <button
                                class="btn btn-main btnSmall  semi-bold-blue ml-3 smallBtn float-right"
                                type="submit"
                            >
                                {{"next" | translate}}
                            </button>
                            <button
                                type="button"
                                class="btn btn-main btnSmall btn-main-outline  smallBtn float-right marginAddMoreBtn"
                                (click)="addMoreLocationManully()"
                            >
                                {{"addMore" | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'openDialogAddDepartment'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnDepartment()"
                ></i>
                {{"configureDepartments" | translate}} <span *ngIf="!individual">(2/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading
                title="{{'globalSetupDepartment' | translate}}"
            ></app-sub-heading>
            <div class="pl-0 mt-3">
                <div class="row col-12">
                    <ul class="list-unstyled px-0">
                        <li class="regular-font">
                            {{"howWouldYouLikeToOnboardYourDepartment" | translate}}
                        </li>
                    </ul>

                    <mat-radio-group
                        aria-label=""
                        class="form-control px-0 radioBtn d-flex flex-column h-auto"
                        style="border: none"
                        [(ngModel)]="addDepartmentType"
                        required
                    >
                        <!-- <mat-radio-button
                  id="ad_integration"
                  value="ad_integration"
                  class="regular-font mb-3"
                >
                {{ 'AD Integrations' | translate }}
                <span class="light-blue-anchor ml-2 informationIcon">i</span>
                </mat-radio-button> -->

                        <mat-radio-button
                            id="add_manually"
                            class="regular-font mb-3 col-sm-3 p-0"
                            value="add_manually"
                        >
                            {{ "addManually" | translate }}
                        </mat-radio-button>

                        <mat-radio-button
                            id="add_excel"
                            class="regular-font mb-3 col-sm-3 p-0"
                            value="add_excel"
                        >
                            {{ "addViaExcel" | translate }}
                        </mat-radio-button>
                    </mat-radio-group>

                    <div
                        *ngIf="
                            submittedDepartmentType && addDepartmentType == ''
                        "
                        class="text-danger"
                    >
                       {{ "pleaseSelectOneOption" | translate }}
                    </div>
                    <ng-container *ngIf="addDepartmentType == 'add_excel'">
                        <div class="row">
                            <div class="col-md-5">
                                <label></label>
                                <button
                                    type="button"
                                    class="form-control1"
                                    (click)="downloadTemplate('DEPARTMENTS')"
                                >
                                    <span style="font-family: 'Open Sans';">{{
                                        "downloadTemplate" | translate
                                    }}</span>
                                    <span class="ml-3"
                                        ><img
                                            src="./assets/images/downloadTemplate.png"
                                            style="width: 1rem;"
                                    /></span>
                                </button>
                            </div>
                            <div class="col-md-7">
                                <label
                                    for="uploadFile"
                                    class="label1 float-left mt-2 font-label"
                                    >{{ "upload" | translate }} (.csv
                                    {{ "file" | translate }})</label
                                >
                                <div
                                    class="input-group col-md-6 clear-both float-left"
                                >
                                    <input
                                        type="file"
                                        class="form-control custom-file-input"
                                        id="importFile"
                                        aria-describedby="inputGroupFileAddon01"
                                        accept=".csv,text/csv"
                                        (change)="
                                            uploadCSV($event.target.files)
                                        "
                                        #fileUploader
                                    />
                                    <label
                                        class="custom-file-label1 custom-upload-btn font-label"
                                        for="inputGroupFile01"
                                        #labelImport
                                    ></label>
                                </div>
                                <div
                                    class="input-group col-md-6 mt-3"
                                    *ngIf="uploadedFileName != ''"
                                >
                                    <p
                                        class="card inner-card-auto text-center p-1 d-inline-block"
                                    >
                                        <span
                                            class="text-overflow-hidden mt-2"
                                            >{{ uploadedFileName }}</span
                                        >
                                        <button
                                            type="button"
                                            class="close text-right mt-2"
                                            aria-label="Close"
                                        >
                                            <span
                                                aria-hidden="true"
                                                (click)="removeUploadedFile()"
                                                >&times;</span
                                            >
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row col-12 text-danger"
                            *ngIf="submitted && uploadedFileName == ''"
                        >
                           {{ "pleaseUploadFile" | translate }}
                        </div>
                    </ng-container>
                </div>

                <div class="mt-3">
                    <button
                        class="btn btn-main btnSmall smallBtn mt-2 float-right"
                        id="next"
                        name="next"
                        (click)="addDepartmentManually()"
                    >
                        {{ "next" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'openDialogNisaanAddDepartment'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnDepartment()"
                ></i>
                {{"configureDepartments" | translate}} <span *ngIf="!individual">(2/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading
                title="{{'globalSetupDepartment' | translate}}"
            ></app-sub-heading>
            <div class="pl-0 mt-3">
                <div class="row col-12">
                    <ul class="list-unstyled px-0">
                        <li class="regular-font">
                            {{"howWouldYouLikeToOnboardYourDepartment" | translate}}
                        </li>
                    </ul>

                    <mat-radio-group
                        aria-label=""
                        class="form-control px-0 radioBtn d-flex flex-column h-auto"
                        style="border: none"
                        [(ngModel)]="addDepartmentType"
                        required
                    >
                        <!-- <mat-radio-button
                  id="ad_integration"
                  value="ad_integration"
                  class="regular-font mb-3"
                >
                {{ 'AD Integrations' | translate }}
                <span class="light-blue-anchor ml-2 informationIcon">i</span>
                </mat-radio-button> -->

                        <mat-radio-button
                            id="add_excel"
                            class="regular-font mb-3 col-sm-3 p-0"
                            value="add_excel"
                        >
                            {{ "addViaExcel" | translate }}
                        </mat-radio-button>
                    </mat-radio-group>

                    <div
                        *ngIf="
                            submittedDepartmentType && addDepartmentType == ''
                        "
                        class="text-danger"
                    >
                        {{ "pleaseSelectOneOption" | translate }}
                    </div>
                    <ng-container *ngIf="addDepartmentType == 'add_excel'">
                        <div class="row">
                            <div class="col-md-5">
                                <label></label>
                                <button
                                    type="button"
                                    class="form-control1"
                                    (click)="
                                        downloadTemplate('DEPARTMENTSNISSAN')
                                    "
                                >
                                    <span style="font-family: 'Open Sans';">{{
                                        "downloadTemplate" | translate
                                    }}</span>
                                    <span class="ml-3"
                                        ><img
                                            src="./assets/images/downloadTemplate.png"
                                            style="width: 1rem;"
                                    /></span>
                                </button>
                            </div>
                            <div class="col-md-7">
                                <label
                                    for="uploadFile"
                                    class="label1 float-left mt-2 font-label"
                                    >{{ "upload" | translate }} (.csv
                                    {{ "file" | translate }})</label
                                >
                                <div
                                    class="input-group col-md-6 clear-both float-left"
                                >
                                    <input
                                        type="file"
                                        class="form-control custom-file-input"
                                        id="importFile"
                                        aria-describedby="inputGroupFileAddon01"
                                        accept=".csv,text/csv"
                                        (change)="
                                            uploadCSV($event.target.files)
                                        "
                                        #fileUploader
                                    />
                                    <label
                                        class="custom-file-label1 custom-upload-btn font-label"
                                        for="inputGroupFile01"
                                        #labelImport
                                    ></label>
                                </div>
                                <div
                                    class="input-group col-md-6 mt-3"
                                    *ngIf="uploadedFileName != ''"
                                >
                                    <p
                                        class="card inner-card-auto text-center p-1 d-inline-block"
                                    >
                                        <span
                                            class="text-overflow-hidden mt-2"
                                            >{{ uploadedFileName }}</span
                                        >
                                        <button
                                            type="button"
                                            class="close text-right mt-2"
                                            aria-label="Close"
                                        >
                                            <span
                                                aria-hidden="true"
                                                (click)="removeUploadedFile()"
                                                >&times;</span
                                            >
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row col-12 text-danger"
                            *ngIf="submitted && uploadedFileName == ''"
                        >
                            {{ "pleaseUploadFiles" | translate }}
                        </div>
                    </ng-container>
                </div>

                <div class="mt-3">
                    <button
                        class="btn btn-main btnSmall smallBtn mt-2 float-right"
                        id="next"
                        name="next"
                        (click)="addDepartmentManually()"
                    >
                       {{ "next" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'onboardDepartmentStepTwo'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnDepartment()"
                ></i>
                 {{ "configureDepartments" | translate }} <span *ngIf="!individual">(2/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupDepartment' | translate}}"></app-sub-heading>
        <div class="pl-0 mt-3">
            <div class="row col-12">
                <span class="semi-bold-blue pr-2">{{ "step2" | translate }} </span>
                <ul class="list-unstyled px-0">
                    <li
                        class="regular-font"
                        *ngIf="!digitalVersion || jumpreeProNonTeams"
                    >
                        {{ "letsOnboardYor" | translate }}
                        <span
                            class="light-blue-anchor"
                            (click)="redirectIntro('DEPARTMENTS')"
                            >{{ "departmentsSmall" | translate }}</span
                        >
                    </li>
                    <li
                        *ngIf="digitalVersion && !jumpreeProNonTeams"
                        class="semi-bold-blue"
                    >
                        {{ "noteTheDepartmentFieldFromAzureADUserInformation" | translate }}
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-5">
                <img
                    class="imgPosition w-50"
                    src="../../assets/images/jumpree_digital/addlocationintro.svg"
                />
            </div>

            <div class="row mr-1 float-right mt-5">
                <!-- <button
            class="btn btn-main btnSmall btn-main-outline semi-bold-blue smallBtn mt-2"
            id="next"
            name="next"
            (click)="skipIntro()"
          >
            Skip
          </button> -->
                <button
                    class="btn btn-main btnSmall smallBtn mt-2 ml-3"
                    id="next"
                    name="next"
                    (click)="showAddOnDepartment()"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'onboardUsersStepThree'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                ></i>
                {{ "addUsersN" | translate }} <span *ngIf="!individual">(3/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
        <div class="pl-0 mt-3">
            <div class="row col-12 px-0 mx-0">
                <span class="semi-bold-blue pr-2"> {{ "step3" | translate }} </span>
                <ul class="list-unstyled px-0">
                    <li class="regular-font">
                         {{ "letsOnboardYourUsers" | translate }}
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-5">
                <img
                    class="imgPosition w-50 usersImageWidth"
                    src="../../assets/images/jumpree_digital/group_15065.png"
                />
            </div>

            <div class="mt-2">
                <button
                    class="btn btn-main btnSmall smallBtn mt-2 ml-4 float-right"
                    id="next"
                    name="next"
                    (click)="showOnboardUsersAllLocations()"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'accessToAllUsersLocations'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                ></i>
                {{ "addUsersN" | translate }} <span *ngIf="!individual">(3/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
        <div class="pl-0 mt-3">
            <div class="row col-12 mx-0 px-0">
                <ul class="list-unstyled">
                    <li class="regular-font">
                        {{ "wouldYourUsersGetAccessToAllYourLocations" | translate }}
                        <!-- <span class="light-blue-anchor">Users</span> -->
                    </li>
                </ul>
            </div>

            <div class="col-12 customMargin">
                <div class="float-right px-0">
                    <button
                        class="btn btn-main btnSmall smallBtn mt-2 mr-4"
                        name="next"
                        (click)="showGeneralUserRole(true)"
                    >
                        {{ "yes" | translate }}
                    </button>
                    <button
                        class="btn btn-main btnSmall btn-main-outline semi-bold-blue smallBtn alertPopupBtnMargin mt-2"
                        name="next"
                        (click)="showGeneralUserRole(false)"
                    >
                        {{ "no" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'onboardSettingsStepFour'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnSettings()"
                ></i>
                {{ "viewSettings" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupSettings' | translate}}"></app-sub-heading>
        <div class="pl-0 mt-3">
            <div class="row col-12">
                <span class="semi-bold-blue pr-2">{{ "step4" | translate }} </span>
                <ul class="list-unstyled px-0">
                    <li class="regular-font">
                        {{ "all" | translate }}
                        <span class="semi-bold-blue">{{ "globalSettings" | translate }}</span>
                        {{ "willBeViewableInThisTab" | translate }}
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-5">
                <img
                    class="imgPosition w-50"
                    src="../../assets/images/jumpree_digital/Group_15064.png"
                />
            </div>

            <div class="col-12 text-right" style="margin-top: 10%">
                <button
                    class="btn btn-main btnSmall smallBtn mt-2 ml-4"
                    id="next"
                    name="next"
                    (click)="showSettingsOnboarding()"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'showSettingsOnboard'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnSettings()"
                ></i>
                {{ "viewSettings" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="G{{'globalSetupSettings' | translate}}"></app-sub-heading>
        <div class="pl-0 mt-3">
            <div class="row col-12">
                <h6 class="semi-bold-blue pr-2 w-100">User Onboarding</h6>
            </div>

            <div class="row col-12">
                <p
                    class="mt-0 w-100 mb-2 regular-font"
                    style="font-size: 1rem;"
                >
                    <img
                        src="../../assets/images/jumpree_digital/check-blue.svg"
                    />
                    {{ "automaticallyMappedToALLlocations" | translate }}
                </p>
                <p
                    class="mt-0 w-100 mb-2 regular-font pl-4 ml-3"
                    style="font-size: 1rem;"
                >
                    {{ "mappedToSelectedLocations" | translate }}
                </p>
            </div>

            <div class="col-12 pl-0">
                <button
                    class="btn btn-main btnSmall smallBtn mt-2 ml-4 float-right mt-5"
                    name="next"
                    (click)="redirectSettingsIntro()"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'globalOnboarding'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "globalOnboarding" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupp' | translate}}"></app-sub-heading>
            <div class="row col-12">
                <!-- <span class="semi-bold-blue pr-2">Step 4 : </span> -->
                <ul class="list-unstyled px-0">
                    <li class="regular-font">
                        {{ "youHaveSuccessfullyCompletedYour" | translate }}
                        <span class="semi-bold-blue">{{ "globalSetup" | translate }}</span>
                    </li>
                </ul>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="card-body">
                        <div class="row mb-5 px-5 justify-content-center">
                            <div
                                class="card inner-card-main-copy inner-card-main"
                            >
                                <div class="card-body text-center d-flex">
                                    <div class="m-auto w-75">
                                        <span class="blueTickPos"
                                            ><img
                                                src="../../assets/images/jumpree_digital/check-blue.svg"
                                        /></span>
                                        <img
                                            class="w-100 mb-4"
                                            src="assets/images/jumpree_digital/departmentsIntro.png"
                                            alt=""
                                        />
                                        <h6 class="semi-bold-blue mt-4">
                                            {{"locations" | translate}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="card inner-card-main-copy inner-card-main"
                            >
                                <div class="card-body text-center d-flex">
                                    <div class="m-auto w-75">
                                        <span class="blueTickPos"
                                            ><img
                                                src="../../assets/images/jumpree_digital/check-blue.svg"
                                        /></span>
                                        <img
                                            class="w-75 mb-4"
                                            src="../../assets/images/jumpree_digital/addlocationintro.svg"
                                            alt=""
                                        />
                                        <h6 class="semi-bold-blue">
                                            {{"departments" | translate}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="card inner-card-main-copy inner-card-main"
                            >
                                <div class="card-body text-center d-flex">
                                    <div class="m-auto w-75">
                                        <span class="blueTickPos"
                                            ><img
                                                src="../../assets/images/jumpree_digital/check-blue.svg"
                                        /></span>
                                        <img
                                            class="w-100 mb-4"
                                            src="../../assets/images/jumpree_digital/group_15065.png"
                                            alt=""
                                        />
                                        <h6 class="semi-bold-blue">
                                            {{"people" | translate}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="card inner-card-main-copy inner-card-main"
                            >
                                <div class="card-body text-center d-flex">
                                    <div class="m-auto w-75">
                                        <span class="blueTickPos"
                                            ><img
                                                src="../../assets/images/jumpree_digital/check-blue.svg"
                                        /></span>
                                        <img
                                            class="w-75 mb-4"
                                            src="../../assets/images/jumpree_digital/Group_15064.png"
                                            alt=""
                                        />
                                        <h6 class="semi-bold-blue">
                                            {{"cardConfig" | translate}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 text-right">
                <button
                    class="btn btn-main btnSmall smallBtn mt-2 ml-4"
                    id="next"
                    name="next"
                    (click)="closeGlobalOnboarding()"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'generalUserRole'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                ></i>
                {{ "alert" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <div class="pl-0 mt-3">
                <div class="row col-12">
                    <h6 class="semi-bold-blue pr-2 w-100">
                        {{ "allUsersWillBeGivenTheGeneralUserRule" | translate }}
                    </h6>
                </div>

                <div class="row col-12 d-block">
                    <h6 class="semi-bold-blue mt-1">{{"app" | translate}}</h6>
                    <p
                        class="pg-main mb-0"
                        style="color: #838484; font-size: 1rem;"
                    >
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"booking" | translate}}
                    </p>
                    <p
                        class="pg-main mb-0"
                        style="color: #838484; font-size: 1rem;"
                    >
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"workStatus" | translate}}
                    </p>
                    <h6 class="semi-bold-blue  mt-1">{{"dashboard" | translate}}</h6>
                    <p
                        class="pg-main  mb-0"
                        style="color: #838484; font-size: 1rem;"
                    >
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"bookingMyBookings" | translate}}
                    </p>
                    <p
                        class="pg-main  mb-0"
                        style="color: #838484; font-size: 1rem;"
                    >
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"workStatus" | translate}}
                    </p>
                    <p
                        class="pg-main  mb-0"
                        style="color: #838484; font-size: 1rem;"
                    >
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"faqs" | translate}}
                    </p>
                </div>

                <div class="col-12">
                    <span class="float-left mt-3 onboardCustomUsers">
                        <a
                            class="custonLink semi-bold-blue"
                            href="javascript:void(0)"
                            (click)="goToRoles()"
                            >{{"lrnMoreAboutRoles" | translate}}</a
                        >
                    </span>
                    <div class="float-right row">
                        <!-- <button
                            class="btn btn-main btnSmall btn-main-outline semi-bold-blue smallBtn mt-2"
                            name="next"
                            (click)="showAddOnUsers(false)"
                        >
                            Do Not Assign
                        </button> -->
                        <button
                            class="btn btn-main btnSmall smallBtn mt-2 ml-4 proceedUserMargin"
                            name="next"
                            (click)="showAddOnUsers(true)"
                        >
                            {{ "proceed" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'proceedGlobal'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "completeYourGlobalSetupIn3EasySteps" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>

        <div class="pl-0 mt-3">
            <div class="row col-12">
                <img
                    class="imgPosition w-50"
                    src="../../assets/images/jumpree_digital/addlocationintro.svg"
                />
            </div>
            <div class="row col-12 mt-1">
                <p class="regular-font" *ngIf="!jumpreeProNonTeams">
                    {{ "proceedToSelectAndOnboardUsersFromYourActiveDirectory" | translate }}
                </p>
            </div>
            <button
                class="btn btn-main btnSmall smallBtn mt-2 float-right"
                id="next"
                name="next"
                (click)="proceedToPeopleTab()"
            >
                {{ "proceed" | translate }}
            </button>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'LocationIntro'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "completeYourGlobalSetupIn3EasySteps" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupp' | translate}}"></app-sub-heading>

        <div class="pl-0 mt-3">
            <div class="row col-12">
                <ul class="list-unstyled user-steps">
                    <li>
                        <span class="semi-bold-blue">{{ "step1" | translate }} </span>
                        <span class="regular-font">{{ "addLocations" | translate }} </span>
                    </li>
                    <li>
                        <span class="semi-bold-blue  mb-3">{{ "step2" | translate }} </span>
                        <span class="regular-font"
                            >Configure Departments (Optional)</span
                        >
                    </li>
                    <li>
                        <span class="semi-bold-blue">{{ "step3" | translate }} </span>
                        <span class="regular-font"
                            >{{ "onboardYourUsersByMappingThemToLocationsDeptsRoles" | translate }}</span
                        >
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-3">
                <img
                    class="imgPosition w-40"
                    src="../../assets/images/jumpree_digital/addlocationintro.svg"
                />
            </div>
            <button
                class="btn btn-main btnSmall smallBtn mt-2 float-right"
                id="next"
                name="next"
                (click)="selectAddLocation()"
            >
                {{ "next" | translate }}
            </button>
        </div>
    </div>

    <div
        class="modal-card fixedHeight"
        *ngIf="
            pageType == 'addDepartmentManually' ||
            pageType == 'editDepartmentManually'
        "
    >
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnDepartment()"
                    *ngIf="pageType != 'editDepartmentManually'"
                ></i>
                {{ pageType == "addDepartmentManually" ? getTranslate('add') : getTranslate('edit') }}
                {{"departments" | translate}} <span *ngIf="!individual">(2/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupDepartment' | translate}}"></app-sub-heading>
        <form
            [formGroup]="departmentForm"
            (ngSubmit)="submitDepartment()"
            #myForm="ngForm"
        >
            <div class="pl-0">
                <div class="row col-12 px-0">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel"
                            >{{"departmentNameCaps" | translate}}
                            <span class="text-danger">*</span></label
                        >
                        <input
                            type="text"
                            class="form-control blueText"
                            placeholder="{{'Entername' | translate}}"
                            formControlName="departmentName"
                        />
                        <div
                            *ngIf="
                                submittedDepartment &&
                                departmentForm.controls.departmentName.errors
                            "
                        >
                            <div class="text-danger formLabel">
                               {{"departmentIsRequired" | translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-12" *ngIf="departmentList.length > 0">
                    <div
                        class="form-group col-lg-4"
                        *ngFor="let dept of departmentList; let i = index"
                    >
                        <div
                            class="inner-card-auto d-flex justify-content-between"
                        >
                            <span>{{ dept }}</span>
                            <span
                                aria-hidden="true"
                                (click)="removeDepartment(dept)"
                                >&times;</span
                            >
                        </div>
                    </div>
                </div>
                <div class="row col-12 mt-5 pr-0">
                    <div class="form-group col-lg- col-md-12 pr-0">
                        <button
                            class="btn btn-main btnSmall  semi-bold-blue ml-3 smallBtn float-right"
                            type="submit"
                        >
                            {{ "next" | translate }}
                        </button>
                        <button
                            *ngIf="pageType != 'editDepartmentManually'"
                            type="button"
                            class="btn btn-main btnSmall btn-main-outline smallBtn float-right marginAddMoreBtn"
                            (click)="addMoreDepartments(); myForm.resetForm()"
                        >
                            {{"addMore" | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-card" *ngIf="pageType == 'selectAddLocations'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn()"
                    *ngIf="locationCountForIntroScreen == 0"
                ></i>
                {{ "addLocations" | translate }} <span *ngIf="!individual">(1/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupLocations' | translate}}"></app-sub-heading>

            <div class="row col-12">
                <ul class="list-unstyled">
                    <li class="regular-font">
                        {{ "howWouldYouLikeToOnboardYourLocations" | translate }}<span
                            class="light-blue-anchor ml-2 informationIcon"
                            (click)="redirectIntro('LOCATIONS')"
                            >i</span
                        >
                    </li>
                </ul>
                <mat-radio-group
                    aria-label=""
                    class="form-control px-0 radioBtn d-flex flex-column h-auto"
                    style="border: none"
                    [(ngModel)]="addLocationType"
                    required
                >
                    <!-- <mat-radio-button
                  id="ad_integration"
                  value="ad_integration"
                  class="regular-font mb-3"
                >
                {{ 'AD Integrations' | translate }}
                <span class="light-blue-anchor ml-2 informationIcon">i</span>
                </mat-radio-button> -->

                    <mat-radio-button
                        id="add_manually"
                        class="regular-font mb-3 col-sm-3 p-0"
                        value="add_manually"
                    >
                        {{ "addManually" | translate }}
                    </mat-radio-button>

                    <mat-radio-button
                        id="add_excel"
                        class="regular-font col-sm-3 p-0"
                        value="add_excel"
                    >
                        {{ "addViaExcel" | translate }}
                    </mat-radio-button>
                </mat-radio-group>

                <ng-container *ngIf="addLocationType == 'add_excel'">
                    <div class="row">
                        <div class="col-md-5">
                            <label></label>
                            <button
                                type="button"
                                class="form-control1"
                                (click)="downloadTemplate('LOCATION')"
                            >
                                <span style="font-family: 'Open Sans';">{{
                                    "downloadTemplate" | translate
                                }}</span>
                                <span class="ml-3"
                                    ><img
                                        src="./assets/images/downloadTemplate.png"
                                        style="width: 1rem;"
                                /></span>
                            </button>
                        </div>
                        <div class="col-md-7">
                            <label
                                for="uploadFile"
                                class="label1 float-left mt-2 font-label"
                                >{{ "upload" | translate }} (.csv
                                {{ "file" | translate }})</label
                            >
                            <div class="input-group col-md-6 clear-both">
                                <input
                                    type="file"
                                    class="form-control blueText custom-file-input"
                                    id="importFile"
                                    aria-describedby="inputGroupFileAddon01"
                                    accept=".csv,text/csv"
                                    (change)="uploadCSV($event.target.files)"
                                    #fileUploader
                                />
                                <label
                                    class="custom-file-label1 custom-upload-btn font-label"
                                    for="inputGroupFile01"
                                    #labelImport
                                ></label>
                            </div>
                            <p
                                class="text-danger"
                                *ngIf="submitted && uploadedFileName == ''"
                            >
                                {{ "pleaseUploadFile" | translate }}
                            </p>
                            <div
                                class="input-group col-md-6 mt-3"
                                *ngIf="uploadedFileName != ''"
                            >
                                <p
                                    class="card inner-card-auto text-center p-1 d-inline-block"
                                >
                                    <span class="text-overflow-hidden mt-2">{{
                                        uploadedFileName
                                    }}</span>
                                    <button
                                        type="button"
                                        class="close text-right mt-2"
                                        aria-label="Close"
                                    >
                                        <span
                                            aria-hidden="true"
                                            (click)="removeUploadedFile()"
                                            >&times;</span
                                        >
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div
                *ngIf="submittedLocationType && addLocationType == ''"
                class="text-danger"
            >
                {{ "pleaseSelectOneOption" | translate }}
            </div>
            <div class="mt-2">
                <button
                    class="btn btn-main btnSmall smallBtn mt-2 float-right"
                    id="next"
                    name="next"
                    (click)="addLocationOptions()"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'addLocationsOptions'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn()"
                ></i>
                {{ "addLocations" | translate }} <span *ngIf="!individual">(1/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupLocations' | translate}}"></app-sub-heading>
            <form [formGroup]="clientForm" (ngSubmit)="submitAddLocation()">
                <div class="row">
                    <div class="form-group col-lg-4 col-md-12">
                        <label class="float-left formLabel"
                            >{{ "locationNameCaps" | translate }}</label
                        >
                        <input
                            type="text"
                            class="form-control blueText"
                            placeholder="{{'typeLocationName' | translate}}"
                            formControlName="locationName"
                        />
                        <div
                            *ngIf="
                                submitted &&
                                clientForm.controls.locationName.errors
                            "
                        >
                            <div class="text-danger formLabel">
                                {{ "enterValidLocation" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-lg-8 col-md-12">
                        <label class="float-left formLabel"
                            >{{ "serchForAddress" | translate }}</label
                        >
                        <!-- (click)="selectLocation()"               -->
                        <div class="w-100 d-inline-flex">
                            <input
                                type="text"
                                class="form-control blueText inputPadding"
                                placeholder="{{'searchLocation' | translate}}"
                                formControlName="searchAddress"
                                #search
                                (input)="loaderShow()"
                                (click)="loadLocations()"
                            />
                            <span>
                                <i
                                    class="fa fa-spinner fa-spin ml-2"
                                    *ngIf="nameSearchIsLoading"
                                ></i>
                            </span>
                            <i class="fa fa-search iconSearch mt-2"></i>
                        </div>

                        <div
                            *ngIf="
                                submitted &&
                                clientForm.controls.searchAddress.errors
                            "
                        >
                            <div class="text-danger formLabel">
                                {{ "addressIsRequired" | translate }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group col-lg-4 col-md-12">
                    <button type="button" class="btn btn-main btnClear semi-bold-blue btn-main-outline btnSmall smallBtn  mt-4 float-right" (click)="addLocationsManually()">Add Manually</button> 
                </div>                 -->
                </div>

                <div class="row" *ngIf="showInfo">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "countryCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText inputPadding "
                            readOnly
                            placeholder=""
                            formControlName="country"
                        /><i class="fa fa-search iconSearch"></i>
                        <span
                            *ngIf="
                                submitted && clientForm.controls.country.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "countryIsRequired" | translate }}</span
                            >
                        </span>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "stateCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText inputPadding "
                            readOnly
                            placeholder=""
                            formControlName="state"
                        /><i class="fa fa-search iconSearch"></i>
                        <span
                            *ngIf="
                                submitted && clientForm.controls.state.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "stateIsRequired" | translate }}</span
                            >
                        </span>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "cityCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText inputPadding "
                            readOnly
                            placeholder=""
                            formControlName="city"
                        /><i class="fa fa-search iconSearch"></i>
                        <span
                            *ngIf="
                                submitted && clientForm.controls.country.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "cityIsRequired" | translate }}</span
                            >
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div
                        class="form-group col-lg-4 col-md-12 pr-0"
                        *ngIf="showInfo"
                    >
                        <label class="float-left formLabel">{{ "timezoneCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText"
                            readOnly
                            placeholder=""
                            formControlName="timezone"
                        />
                        <span
                            *ngIf="
                                submitted && clientForm.controls.timezone.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "timezoneIsRequired" | translate }}</span
                            >
                        </span>
                    </div>

                    <div class="form-group col-lg-8 col-md-12 pr-0 mt-3">
                        <div class="map" id="mapped" #map></div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="form-group col-lg-4 col-md-12">
                        <p>
                            {{ "locationsAdded" | translate }} :
                            <span
                                class="regular-bold-blue"
                                style="text-decoration: none;"
                                >{{ countLocation }}</span
                            >
                        </p>
                    </div>
                    <div class="form-group col-lg-8 col-md-12">
                        <button
                            class="btn btn-main btnSmall semi-bold-blue ml-3  smallBtn  float-right"
                            type="submit"
                        >
                            {{ "next" | translate }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-main btnSmall btn-main-outline smallBtn  float-right marginAddMoreBtn"
                            (click)="addMoreLocation()"
                        >
                            {{ "addMore" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'editLocationsOptions'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn()"
                ></i>
                {{ "editLocations" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupLocations' | translate}}"></app-sub-heading>
        <form [formGroup]="clientForm" (ngSubmit)="submitEditLocationOptions()">
            <div class="pl-0">
                <div class="row col-12 px-0">
                    <div class="form-group col-lg-4 col-md-12 pr-0 ">
                        <label class="float-left formLabel"
                            >{{ "locationNameCaps" | translate }}</label
                        >
                        <input
                            type="text"
                            class="form-control blueText"
                            formControlName="locationName"
                        />
                        <div
                            *ngIf="
                                submitted &&
                                clientForm.controls.locationName.errors
                            "
                        >
                            <div class="text-danger formLabel">
                                {{ "locationIsRequired" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-12 px-0">
                    <div class="form-group col-lg-8 col-md-12 pr-0">
                        <label class="float-left formLabel"
                            >{{ "serchForAddress" | translate }}</label
                        >
                        <!-- (click)="selectLocation()"               -->
                        <input
                            type="text"
                            class="form-control blueText inputPadding"
                            placeholder=""
                            formControlName="searchAddress"
                            #search
                            (click)="loadLocations()"
                        /><i class="fa fa-search iconSearch"></i>
                        <span
                            *ngIf="
                                submitted &&
                                clientForm.controls.searchAddress.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "addressIsRequired" | translate }}</span
                            >
                        </span>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <button
                            type="button"
                            class="btn btn-main btnClear semi-bold-blue btn-main-outline btnSmall smallBtn  mt-3 float-right"
                            (click)="editLocationsManually()"
                        >
                            {{ "editManually" | translate }}
                        </button>
                    </div>
                </div>

                <div class="row col-12 px-0" *ngIf="showInfo">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "countryCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText inputPadding"
                            placeholder=""
                            formControlName="country"
                        /><i class="fa fa-search iconSearch"></i>
                        <span
                            *ngIf="
                                submitted && clientForm.controls.country.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "countryIsRequired" | translate }}</span
                            >
                        </span>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "stateCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText inputPadding"
                            placeholder=""
                            formControlName="state"
                        /><i class="fa fa-search iconSearch"></i>
                        <span
                            *ngIf="
                                submitted && clientForm.controls.state.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "stateIsRequired" | translate }}</span
                            >
                        </span>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "cityCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText inputPadding"
                            placeholder=""
                            formControlName="city"
                        /><i class="fa fa-search iconSearch"></i>
                        <span
                            *ngIf="
                                submitted && clientForm.controls.country.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "cityIsRequired" | translate }}</span
                            >
                        </span>
                    </div>
                </div>

                <div class="row col-12 px-0">
                    <div
                        class="form-group col-lg-4 col-md-12 pr-0"
                        *ngIf="showInfo"
                    >
                        <label class="float-left formLabel">{{ "timezoneCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText"
                            placeholder=""
                            formControlName="timezone"
                        />
                        <span
                            *ngIf="
                                submitted && clientForm.controls.timezone.errors
                            "
                        >
                            <span class="text-danger formLabel"
                                >{{ "timezoneIsRequired" | translate }}</span
                            >
                        </span>
                    </div>

                    <div class="form-group col-lg-8 col-md-12 pr-0 mt-3">
                        <div class="map" id="mapped" #map></div>
                    </div>
                </div>

                <div class="row col-12 mt-2 pr-0">
                    <div class="form-group col-lg-12 col-md-12 pr-0">
                        <button
                            class="btn btn-main btnSmall  semi-bold-blue ml-4  smallBtn  float-right"
                            type="submit"
                        >
                            {{ "save" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-card" *ngIf="pageType == 'editLocationsManually'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn()"
                    *ngIf="!individual"
                ></i>
                 {{ "editLocations" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupLocations' | translate}}"></app-sub-heading>
        <form
            [formGroup]="addLocationsManuallyForm"
            (ngSubmit)="submiteditLocationsManuallyForm()"
        >
            <div class="pl-0">
                <div class="row col-12 px-0">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel"
                            >{{ "locationNameCaps" | translate }}</label
                        >
                        <input
                            type="text"
                            class="form-control blueText"
                            placeholder=""
                            formControlName="locationName"
                            autocomplete="off"
                        />
                        <div
                            *ngIf="
                                submitted &&
                                addLocationsManuallyForm.controls.locationName
                                    .errors
                            "
                        >
                            <div class="text-danger formLabel">
                                {{ "locationIsRequired" | translate }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group col-lg-8 col-md-12 pr-0">
              <button type="button" class="btn btn-main btnClear semi-bold-blue btn-main-outline btnSmall smallBtn  mt-3 float-right" (click)="showEditGoogleMap()">Edit Through Google Maps</button> 
          </div>   -->
                </div>
                <div class="row col-12 px-0">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "countryCaps" | translate }}</label>
                        <!-- <input type="text" #searchTerm
                      class="form-control blueText inputPadding" 
                      autocomplete="off"
                      (paste)="getAutocompleteList(searchTerm.value)"
                      (input)="getAutocompleteList(searchTerm.value)"
                      placeholder="" formControlName="country"
                          ><i class="fa fa-search iconSearch"></i> -->
                        <input
                            #searchTerm
                            class="form-control blueText inputPadding"
                            [matAutocomplete]="auto"
                            [formControl]="countryCtrl"
                            formControlName="country"
                            autocomplete="off"
                            [readonly]="readOnly"
                        />
                        <i class="fa fa-search iconSearch"></i>

                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                                *ngFor="let country of filteredCountry | async"
                                [value]="country.name"
                                (click)="countrySelection(country.name)"
                            >
                                <span>{{ country.name }}</span>
                            </mat-option>
                        </mat-autocomplete>

                        <div
                            *ngIf="
                                addLocationsManuallyForm.controls.country.errors
                                    ?.incorrect
                            "
                            class="mt-1"
                        >
                            <div class="text-danger formLabel">
                                {{ "incorrectCountryValue" | translate }}
                            </div>
                        </div>
                        <div
                            *ngIf="stateCtrl.errors?.required && submitted"
                            class="mt-1"
                        >
                            <div class="text-danger formLabel">
                                {{ "countryRequired" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "stateCaps" | translate }}</label>
                        <!-- <input type="text" class="form-control blueText inputPadding"   placeholder="" formControlName="state"
                          ><i class="fa fa-search iconSearch"></i> -->
                        <input
                            #searchTerm
                            class="form-control blueText inputPadding"
                            [matAutocomplete]="auto1"
                            [formControl]="stateCtrl"
                            formControlName="state"
                            [readonly]="readOnly"
                            autocomplete="off"
                        />
                        <i class="fa fa-search iconSearch"></i>

                        <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option
                                *ngFor="let state of filteredStates1 | async"
                                [value]="state.name"
                                (click)="stateSelection(state.name)"
                            >
                                <span>{{ state.name }}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <div
                            *ngIf="
                                addLocationsManuallyForm.controls.state.errors
                                    ?.incorrect
                            "
                            class="mt-1"
                        >
                            <div class="text-danger formLabel">
                                {{ "incorrectStateValue" | translate }}
                            </div>
                        </div>
                        <div
                            *ngIf="stateCtrl.errors?.required && submitted"
                            class="mt-1"
                        >
                            <div class="text-danger formLabel">
                               {{ "stateRequired" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">CITY</label>
                        <!-- <input type="text" class="form-control blueText inputPadding"   placeholder="" formControlName="city"
                          ><i class="fa fa-search iconSearch"></i> -->
                        <input
                            #searchTerm
                            class="form-control blueText inputPadding"
                            [matAutocomplete]="auto2"
                            [formControl]="cityCtrl"
                            formControlName="city"
                            [readonly]="readOnly"
                            autocomplete="off"
                        />
                        <i class="fa fa-search iconSearch"></i>

                        <mat-autocomplete #auto2="matAutocomplete">
                            <mat-option
                                *ngFor="let city of filteredCity | async"
                                [value]="city.name"
                                (click)="citySelection(city.name)"
                            >
                                <span>{{ city.name }}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <div
                            *ngIf="
                                addLocationsManuallyForm.controls.city.errors
                                    ?.incorrect
                            "
                            class="mt-1"
                        >
                            <div class="text-danger formLabel">
                                {{ "incorrectCityValue" | translate }}
                            </div>
                        </div>
                        <div
                            *ngIf="cityCtrl.errors?.required && submitted"
                            class="mt-1"
                        >
                            <div class="text-danger formLabel">
                               {{ "cityRequired" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-12 px-0">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "timezoneCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText"
                            placeholder=""
                            formControlName="timezone"
                            autocomplete="off"
                            [readonly]="readOnly"
                        />
                        <div
                            *ngIf="
                                submitted &&
                                addLocationsManuallyForm.controls.timezone
                                    .errors
                            "
                        >
                            <div class="text-danger formLabel">
                               {{ "timeZoneIsRequired" | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col-12 mt-2 pr-0">
                    <div class="form-group col-lg-12 col-md-12 pr-0">
                        <button
                            class="btn btn-main btnSmall  semi-bold-blue ml-4 smallBtn float-right"
                            type="submit"
                        >
                            {{ "save" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div
        class="modal-card"
        *ngIf="
            pageType == 'showAssignNewGlobal' || pageType == 'editGlobalUser'
        "
    >
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
               {{ "assignNewGlobalAdmin" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupp' | translate}}"></app-sub-heading>
        <form
            [formGroup]="assignNewGlobalForm"
            (ngSubmit)="submitassignNewGlobalForm()"
        >
            <div class="form-row">
                <div class="form-group col-lg-12 col-md-4 col-sm">
                    <label class="float-left formLabel">{{"SearchUser" | translate}}</label>
                    <div class="input-group pl-0">
                        <div class="d-inline-flex w-100">
                            <input
                                #name
                                type="text"
                                name="name"
                                [(ngModel)]="eName"
                                formControlName="userSearch"
                                autocomplete="off"
                                class="form-control blueText"
                                placeholder="{{'SearchUser' | translate}}"
                                (input)="
                                    getEmployeeNameAutocompleteList(name.value)
                                "
                                (change)="handleEmptyInput($event)"
                                [ngClass]="{
                                    'is-invalid':
                                        submitted &&
                                        assignNewGlobalForm.controls.userSearch
                                            .errors
                                }"
                                required
                                [matAutocomplete]="auto"
                            />
                            <span>
                                <i
                                    class="fa fa-spinner fa-spin ml-2"
                                    *ngIf="nameSearchIsLoading"
                                ></i>
                            </span>
                        </div>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                                class="autocompleteSearch"
                                *ngFor="
                                    let dropData of nameAutocompleteList$
                                        | async
                                "
                                [value]="
                                    dropData?.firstName +
                                    ' ' +
                                    dropData?.lastName
                                "
                                (click)="onselectNameData(dropData)"
                            >
                                <span>{{
                                    dropData?.firstName +
                                        " " +
                                        (dropData?.lastName
                                            ? dropData.lastName
                                            : "")
                                }}</span>
                                <!-- <div class="row customRow pt-3">
                    <div class="px-3 custom-img">
                      <img
                        [src]="
                          dropData.imageUrl === null
                            ? './assets/images/jumpree_digital/avatar@3x.jpg'
                            : dropData.imageUrl
                        "
                        alt="img"
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-10 text-left pl-0">
                      <p class="regular-font mb-1 mt-2">{{ dropData.name }}</p>
                    </div>
                  </div> -->
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
                <!-- <div class="form-group col-lg-3 col-md-4 col-sm-4">
                      <button id="searchBtn" type="submit" class="btn btn-main btnSmall mt-4 customBtn px-3">
                          {{ "search" | translate }}
                      </button>
                      <button type="button" class="btn btn-main btnSmall ml-2 mt-4 customBtn" id="reset" (click)="reset()">
                          {{ "clear" | translate }}
                      </button>
                  </div> -->
            </div>
            <div class="pl-0">
                <div class="row col-12 px-0 mt-3">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "firsNameCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText"
                            placeholder="{{'enterFirstName' | translate}}"
                            formControlName="fname"
                        />
                        <div
                            *ngIf="
                                submitted &&
                                assignNewGlobalForm.controls.fname.errors
                            "
                        >
                            <div class="text-danger formLabel">
                                {{ "firstNameIsRequired" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "lastNameCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText"
                            placeholder="{{'enterLastName' | translate}}"
                            formControlName="lname"
                        />
                        <div
                            *ngIf="
                                submitted &&
                                assignNewGlobalForm.controls.lname.errors
                            "
                        >
                            <div class="text-danger formLabel">
                                {{ "lastNameIsRequred" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">{{ "emailCaps" | translate }}</label>
                        <input
                            type="text"
                            class="form-control blueText"
                            placeholder="{{'enterMailId' | translate}}"
                            formControlName="email"
                        />
                        <div
                            *ngIf="
                                submitted &&
                                assignNewGlobalForm.controls.email.errors
                            "
                        >
                            <div class="text-danger formLabel">
                                {{ "enterValidEmailId" | translate }}
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">PHONE NUMBER</label>
                        <div class="input-group">
                            <div class="input-group-prepend w-30 p-0">
                                <select
                                    id="phone_value"
                                    [(ngModel)]="countryCodeVal"
                                    class="form-control blueText p-0"
                                    formControlName="countryCode"
                                >
                                    <option
                                        [value]="countryCode.countryCode"
                                        *ngFor="
                                            let countryCode of countryCodeList
                                        "
                                        >{{ countryCode.countryCode }}</option
                                    >
                                </select>
                            </div>
                            <input
                                type="number"
                                [(ngModel)]="phoneVal"
                                formControlName="phone"
                                class="form-control blueText"
                                autocomplete="off"
                                placeholder="Enter number"
                            />
                        </div>

                        <div
                            *ngIf="
                                submitted &&
                                assignNewGlobalForm.controls.phone.errors
                            "
                        >
                            <p class="text-danger formLabel">
                                Phone Number Required
                            </p>
                        </div>
                    </div> -->
                </div>

                <div class="row col-12 mt-2 pr-0 mt-4">
                    <div class="form-group col-md-12 pr-0">
                        <button
                            type="submit"
                            class="btn btn-main btnSmall smallBtn float-right"
                            type="submit"
                            *ngIf="assignNewGlobalForm.valid"
                        >
                            {{ "proceed" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-card" *ngIf="pageType == 'sendEmailPopup'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <!-- <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtn()"
                ></i> -->
                {{ "assignNewGlobalAdmin" | translate }}
            </h5>
        </div>
        <app-sub-heading title="{{'globalSetupp' | translate}}"></app-sub-heading>
        <div class="pl-0">
            <div class="col-12 mt-3 pl-0">
                <p class="regular-font-blue">
                    {{ "anEmailHasBeenSentSucessfullyTo" | translate }}
                    {{ assignNewGlobalForm.get("fname").value }}
                    {{ this.assignNewGlobalForm.get("lname").value }}.
                </p>
            </div>
            <div class="col-12 mt-5">
                <img
                    class="imgPosition mt-5 w-50"
                    src="../../assets/images/jumpree_digital/envelope.svg"
                />
            </div>

            <div class="row col-12 mt-2 pr-0 mt-4">
                <!-- <div class="form-group col-md-12 col-lg-6 pr-0">
                    <p class="gray-regular float-left">
                        Didn't receive an email ?
                        <span
                            class="semi-bold-blue cursor-pointer"
                            style="text-decoration: underline"
                            (click)="resendMail()"
                            >Resend
                        </span>
                    </p>
                </div> -->
                <div class="form-group col-md-12 col-lg-12 pr-0">
                    <button
                        class="btn btn-main btnSmall smallBtn float-right"
                        (click)="redirectLocation()"
                    >
                        {{ "done" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'showAddedMembers'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "globalAdmins" | translate }}
            </h5>
        </div>
        <app-sub-heading title="{{'globalSetupp' | translate}}"></app-sub-heading>
        <div class="pl-0 mt-3">
            <ul class="list-unstyled">
                <li
                    class="container w-100 px-0 mb-3"
                    *ngFor="let gb of globalUsers; let i = index"
                >
                    <div class="blueCircle text-upper">{{ gb.letters }}</div>
                    <span
                        class="blueText mt-2 ml-3 text-captialize d-inline-block font-bold"
                        >{{ gb.name }}</span
                    >
                    <span class="blueText"> ({{ gb.email }})</span>
                    <!-- <span class="float-right"
                ><img
                role="button"
                class="deleteIcon"
                src="../../assets/images/jumpree_digital/delete-icon.svg"
                (click)="deleteGlobalUser(gb)"
            /></span> -->
                </li>
            </ul>

            <div class="col-12 px-0">
                <button
                    class="btn btn-main btnSmall smallBtn mt-4 float-right"
                    id="next"
                    name="next"
                    (click)="closeGlobalPopup()"
                >
                    {{ "done" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div
        class="modal-card"
        *ngIf="pageType == 'addUserManually' || pageType == 'editUsers'"
    >
        <div class="modal-header p-0">
            <!-- <h5 class="semi-bold-blue"><i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtnUsers()" *ngIf="!individual && pageType != 'editUsers'"></i> {{pageType != 'editUsers' ? 'Add' : 'Edit'}} Users <span *ngIf="!individual">(3/3)</span></h5> -->
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                    *ngIf="pageType != 'editUsers'"
                ></i>
                {{ pageType != "editUsers" ? getTranslate('add') : getTranslate('edit') }} {{"users" | translate}}
                <span *ngIf="!individual">(3/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <form [formGroup]="addUsers" (ngSubmit)="showAlertPopup(pageType)">
                <div>
                    <div class="row col-12 px-0">
                        <label class="float-left formLabel col-12"
                            >EMAIL ADDRESS <span class="text-danger">*</span>
                        </label>
                        <div class="form-group col-lg-12 col-md-12 pr-0">
                            <div class="input-group pl-0">
                                <!-- <mat-icon class="alignserachIcon" matPrefix>search</mat-icon> -->
                                <input
                                    #name
                                    type="text"
                                    name="name"
                                    [(ngModel)]="eName"
                                    formControlName="userSearch"
                                    autocomplete="off"
                                    class="form-control blueText textIndent"
                                    placeholder="{{'SearchUser' | translate}}"
                                    (input)="
                                        getEmployeeNameAutocompleteList(
                                            name.value
                                        )
                                    "
                                    [ngClass]="{
                                        'is-invalid':
                                            submitted &&
                                            assignNewGlobalForm.controls
                                                .userSearch.errors
                                    }"
                                    required
                                    [matAutocomplete]="auto"
                                />
                                <!-- <mat-icon class="aggregator-arrow-selector alignDropdownIcon" matSuffix>
                    <div class="mat-select-arrow-wrapper" (click)="openFullAggregatorsList()">
                      <div class="mat-select-arrow"></div>
                    </div>
                  </mat-icon> -->
                                <i
                                    style="position: absolute;
                  right: 10px;
                  top: 10px;"
                                    class="fa fa-spinner loading_span"
                                    *ngIf="nameSearchIsLoading"
                                    aria-hidden="true"
                                ></i>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option
                                        class="autocompleteSearch"
                                        *ngFor="
                                            let dropData of nameAutocompleteList$
                                                | async
                                        "
                                        [value]="
                                            dropData?.firstName +
                                            ' ' +
                                            dropData?.lastName
                                        "
                                        (click)="onselectNameData(dropData)"
                                    >
                                        <span>{{
                                            dropData?.firstName +
                                                " " +
                                                dropData?.lastName
                                        }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{ "firsNameCaps" | translate }}
                                <span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control blueText"
                                placeholder="{{'Entername' | translate}}"
                                formControlName="employeeName"
                            />
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsers.controls.employeeName.errors
                                "
                            >
                                <div class="text-danger formLabel">
                                    {{ "firstNameIsRequired" | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{ "lastNameCaps" | translate }}
                                <span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control blueText"
                                placeholder="{{'Entername' | translate}}"
                                formControlName="lastName"
                            />
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsers.controls.lastName.errors
                                "
                            >
                                <div class="text-danger formLabel">
                                    {{ "lastNameIsRequired" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{ "emailCaps" | translate }} <span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control blueText"
                                placeholder="{{'enterEmailID' | translate}}"
                                formControlName="email"
                            />
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsers.controls.email.errors
                                "
                            >
                                <div class="text-danger formLabel">
                                   {{ "emailIsRequired" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{ "phoneCaps" | translate }} <span class="text-danger">*</span></label
                            >
                            <div class="d-flex w-100">
                                <select
                                    id="phone_value"
                                    class="form-control blueText p-0 w-30"
                                    formControlName="countryCode"
                                >
                                    <option
                                        [value]="countryCode.countryCode"
                                        *ngFor="
                                            let countryCode of countryCodeList
                                        "
                                        >{{ countryCode.countryCode }}</option
                                    >
                                </select>
                                <input
                                    type="text"
                                    class="form-control blueText formLabel"
                                    placeholder="{{'enterPhoneNumber' | translate}}"
                                    formControlName="phone"
                                />
                            </div>
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsers.controls.countryCode.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{ "countryCodeIsReq" | translate }}
                                </p>
                            </div>
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsers.controls.phone.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{ "phoneIsRequired" | translate }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{ "employeeID" | translate }}
                                <!-- <span class="text-danger">*</span> -->
                            </label>
                            <input
                                type="text"
                                class="form-control blueText formLabel"
                                placeholder="{{'enterEmpId' | translate}}"
                                formControlName="employeeId"
                            />
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsers.controls.employeeId.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{"employeeIDIsRequired" | translate}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{"groupsDept" | translate}}</label
                            >
                            <!-- <input type="text" class="form-control blueText"  placeholder="Select Here"  formControlName="groups"                       
                  > -->
                            <mat-select
                                formControlName="groups"
                                class="form-control "
                                placeholder="{{'selectDept' | translate}}"
                            >
                                <ng-container
                                    *ngFor="
                                        let department of DEPARTMENT_ELEMENT_DATA
                                    "
                                >
                                    <mat-option
                                        *ngIf="department.status"
                                        [value]="department.id"
                                    >
                                        {{ department.name }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsers.controls.groups.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{"grpsDeptIsRequired" | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-12 pt-4">
                            <mat-checkbox
                                class="example-margin"
                                formControlName="globalAdmin"
                                >{{"globalAdmin" | translate}}</mat-checkbox
                            >
                        </div>
                    </div>
                    <div class="row" *ngIf="!addUsers.get('globalAdmin').value">
                        <div class="form-group col-lg-12 col-md-12">
                            <p class="regular-semibold-font">
                                {{"taggedLocations" | translate}}:
                                <span *ngIf="selectedLocations">
                                    {{ selectedLocations.length }} </span
                                ><span *ngIf="!selectedLocations"> 0</span>
                                (Onboarded locations - {{ locationLength }})
                            </p>
                            <div class="regular-blue pl-1 mb-4">
                                <button
                                    class="smallBtnCircleAdd semi-bold-blue"
                                    (click)="
                                        showTagLocations(
                                            pageType,
                                            addUsers.get('locations')
                                        )
                                    "
                                >
                                    +
                                </button>
                                <span class="gray-regular ml-3 font-small"
                                    >{{"tagLocations" | translate}}</span
                                >
                            </div>
                            <ng-container
                                formArrayName="locations"
                                class="pl-1"
                            >
                                <div
                                    class="regular-semibold-font"
                                    *ngFor="
                                        let creds of addUsers.get('locations')[
                                            'controls'
                                        ];
                                        let i = index
                                    "
                                    [formGroupName]="i"
                                >
                                    <p class="regular-semibold-font">
                                        {{ creds.get("names").value["name"] }}
                                        <mat-slide-toggle
                                            class="toggleBtn ml-5"
                                            formControlName="toggleControl"
                                            (change)="toggleLocation($event, i)"
                                        ></mat-slide-toggle>
                                    </p>

                                    <ng-container
                                        *ngIf="creds.get('toggleControl').value"
                                    >
                                        <p class="regular-semibold-font">
                                            {{"Roles" | translate}}
                                            <button
                                                class="smallBtnCircleAdd ml-3 semi-bold-blue"
                                                (click)="
                                                    showRoles(
                                                        creds.get('roles')
                                                            .value,
                                                        i,
                                                        pageType
                                                    )
                                                "
                                            >
                                                +
                                            </button>
                                            <span
                                                class="gray-regular ml-3 font-small"
                                                >{{"addRoles" | translate}}</span
                                            >
                                        </p>
                                        <div
                                            *ngIf="
                                                submittedUsers &&
                                                !creds.get('roles').valid
                                            "
                                        >
                                            <p class="text-danger formLabel">
                                                {{"pleasesSelectRoles" | translate}}
                                            </p>
                                        </div>
                                        <div class="row p-1 mt-2">
                                            <div
                                                class="form-group col-lg-4"
                                                *ngFor="
                                                    let role of creds.get(
                                                        'roles'
                                                    ).value;
                                                    let j = index
                                                "
                                            >
                                                <div
                                                    class="inner-card-auto  d-flex justify-content-between"
                                                >
                                                    <span
                                                        class="gray-regular font-small"
                                                        >{{ role.name }}</span
                                                    >
                                                    <span
                                                        aria-hidden="true"
                                                        (click)="
                                                            removeRoles(i, j)
                                                        "
                                                        >&times;</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="form-group col-lg-12 col-md-12">
                            <button
                                class="btn btn-main btnSmall  semi-bold-blue ml-4 smallBtn float-right"
                                type="submit"
                            >
                                {{ "done" | translate }}
                            </button>
                            <button
                                *ngIf="pageType != 'editUsers'"
                                type="button"
                                (click)="addMoreUsers(pageType)"
                                class="btn btn-main btnSmall btn-main-outline smallBtn float-right marginAddMoreBtn"
                            >
                                {{ "addMore" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div
        class="modal-card"
        *ngIf="
            pageType == 'addUserManuallyNonTeams' ||
            pageType == 'editUsersNonTeams'
        "
    >
        <div class="modal-header p-0">
            <!-- <h5 class="semi-bold-blue"><i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtnUsers()" *ngIf="!individual && pageType != 'editUsers'"></i> {{pageType != 'editUsers' ? 'Add' : 'Edit'}} {{"users" | translate}} <span *ngIf="!individual">(3/3)</span></h5> -->
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                    *ngIf="pageType != 'editUsersNonTeams'"
                ></i>
                {{ pageType != "editUsersNonTeams" ? getTranslate('onboardBtn') : getTranslate('edit') }} {{"users" | translate}}
                <!-- <span *ngIf="!individual">(3/3)</span> -->
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="
                    pageType != 'editUsersNonTeams'
                        ? closePopupInMiddle(pageType)
                        : closePopup(pageType)
                "
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <form
                [formGroup]="addUsersNonTeams"
                (ngSubmit)="submitAddUserNonTeams(pageType)"
            >
                <div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{"firsNameCaps" | translate}}
                                <span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control blueText"
                                placeholder="{{'Entername' | translate}}"
                                formControlName="employeeName"
                            />
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsersNonTeams.controls.employeeName
                                        .errors
                                "
                            >
                                <div class="text-danger formLabel">
                                    {{"firstNameIsRequired" | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{"lastNameCaps" | translate}}
                                <!-- <span class="text-danger">*</span> -->
                            </label>
                            <input
                                type="text"
                                class="form-control blueText"
                                placeholder="{{'Entername' | translate}}"
                                formControlName="lastName"
                            />
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsersNonTeams.controls.lastName.errors
                                "
                            >
                                <div class="text-danger formLabel">
                                    {{"lastNameIsRequired" | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control blueText"
                                placeholder="{{'enterEmailID' | translate}}"
                                formControlName="email"
                            />
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsersNonTeams.controls.email.errors
                                "
                            >
                                <div class="text-danger formLabel">
                                    {{"emailIsRequired" | translate}}
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6 col-md-12  ">
                            <label class="float-left formLabel"
                                >{{ "employeeID" | translate }}
                                <!-- <span class="text-danger">*</span> -->
                            </label>
                            <input
                                type="text"
                                class="form-control blueText formLabel"
                                placeholder="{{'enterEmpId' | translate}}"
                                formControlName="employeeId"
                            />
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    addUsersNonTeams.controls.employeeId.errors
                                "
                            >
                                <p class="text-danger formLabel">
                                    {{"employeeIDIsRequired" | translate}}
                                </p>
                            </div>
                        </div>
                            
                        <div class="form-group col-lg-6 col-md-12  " *ngIf="!isTenantEngagement()">
                            <label class="float-left formLabel"
                                >{{"deptCaps" | translate}}
                                <span class="text-danger">*</span></label
                            >
                            <div class="input-group pl-0">
                                <mat-icon
                                    class="alignSearchIconGray pl-2"
                                    matPrefix
                                    >search</mat-icon
                                >
                                <input
                                    type="text"
                                    autocomplete="off"
                                    id="locationSelection"
                                    class="form-control blueText textIndent float-right pl-3"
                                    placeholder="{{'selectDept' | translate}}"
                                    [formControl]="departName"
                                    required
                                    [matAutocomplete]="autoDept"
                                    #customInputDept
                                />
                                <i
                                    style="position: absolute;right: 10px;top: 10px;"
                                    class="fa fa-spinner loading_span"
                                    *ngIf="nameSearchIsLoading"
                                    aria-hidden="true"
                                ></i>
                                <mat-autocomplete
                                    multiple
                                    #autoDept="matAutocomplete"
                                >
                                    <ng-container
                                        *ngFor="
                                            let dropData of filteredDepartments
                                                | async
                                        "
                                    >
                                        <mat-option
                                            class="autocompleteSearch"
                                            *ngIf="dropData.status"
                                            [value]="dropData.name"
                                            (click)="selectDept(dropData)"
                                        >
                                            <span>{{ dropData.name }}</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </div>

                            <div *ngIf="submittedUsers && dept == null">
                                <p class="text-danger formLabel">
                                    {{"departmentIsRequired" | translate}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-12 col-md-12">
                            <mat-checkbox
                                class="example-margin"
                                formControlName="globalAdmin"
                                >{{"globalAdmin" | translate}} </mat-checkbox
                            >
                        </div>
                        <div
                            class="form-group col-lg-12 col-md-12"
                            *ngIf="!addUsersNonTeams.get('globalAdmin').value"
                        >
                            <ng-container *ngIf="!accessAllLocationsNonTeams">
                                <div class="d-flex">
                                    <div
                                        class="form-group col-lg-6 pl-0 col-md-12"
                                    >
                                        <label class="formLabel"
                                            >{{"locationCaps" | translate}}
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="input-group pl-0">
                                            <mat-icon
                                                class="alignSearchIconGray pl-2"
                                                matPrefix
                                                >search</mat-icon
                                            >
                                            <input
                                                type="text"
                                                autocomplete="off"
                                                id="locationSelection"
                                                class="form-control blueText textIndent float-right pl-3"
                                                placeholder="{{'tagLocation' | translate}}"
                                                [formControl]="
                                                    searchLocation2NonTeams
                                                "
                                                required
                                                [matAutocomplete]="auto5"
                                                #customInput5
                                            />
                                            <i
                                                style="position: absolute;right: 10px;top: 10px;"
                                                class="fa fa-spinner loading_span"
                                                *ngIf="nameSearchIsLoading"
                                                aria-hidden="true"
                                            ></i>
                                            <mat-autocomplete
                                                multiple
                                                #auto5="matAutocomplete"
                                            >
                                                <ng-container
                                                    *ngFor="
                                                        let dropData of filteredLocationsNonTeams
                                                            | async
                                                    "
                                                >
                                                    <mat-option
                                                        class="autocompleteSearch"
                                                        *ngIf="
                                                            dropData.status ==
                                                            'ENABLED'
                                                        "
                                                        [value]="dropData.name"
                                                        (click)="
                                                            selectCustomLocationNonTeams(
                                                                dropData
                                                            )
                                                        "
                                                    >
                                                        <span>{{
                                                            dropData.name
                                                        }}</span>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </div>
                                        <div
                                            *ngIf="
                                                submittedUsers &&
                                                customLocationList.length == 0
                                            "
                                        >
                                            <p class="text-danger formLabel">
                                               {{"locationIsRequired" | translate}}
                                            </p>
                                        </div>
                                    </div>
                                    <button
                                        class="smallBtnCircleAdd semi-bold-blue mt-4 ml-3"
                                        (click)="tagCustomLocationsNonTeams()"
                                        type="button"
                                    >
                                        +
                                    </button>
                                </div>
                                <ng-container
                                    *ngIf="customLocationList.length > 0"
                                >
                                    <div class="col-12 d-flex ">
                                        <div
                                            class="form-group w-fit ml-1"
                                            *ngFor="
                                                let role of customLocationList;
                                                let i = index
                                            "
                                        >
                                            <div
                                                class="inner-card-auto justify-content-between align-items-center py-1"
                                            >
                                                <span
                                                    class="gray-regular font-small mr-1"
                                                    >{{ role.name }}</span
                                                >
                                                <span
                                                    aria-hidden="true"
                                                    (click)="
                                                        removeCustomLoc(
                                                            i,
                                                            role.zoneId
                                                        )
                                                    "
                                                    class="cursor-pointer"
                                                    >&times;</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    (customLocationList.length > 0 &&
                                        !accessAllLocationsNonTeams) ||
                                    accessAllLocationsNonTeams
                                "
                            >
                                <p class="regular-semibold-font">
                                   {{"additionalRolesCaps" | translate}}
                                    <mat-slide-toggle
                                        class="toggleBtn ml-5"
                                        (change)="
                                            toggleNonTeamsLocations($event)
                                        "
                                        [formControl]="additionalRoles"
                                    ></mat-slide-toggle>
                                </p>
                                <div class="row" *ngIf="addLocNonTeams">
                                    <div class="form-group col-lg-5 col-md-12">
                                        <label class="formLabel"
                                            >{{"locationCaps" | translate}}</label
                                        >
                                        <div
                                            class="input-group pl-0"
                                            *ngIf="accessAllLocationsNonTeams"
                                        >
                                            <mat-icon
                                                class="alignSearchIconGray pl-2"
                                                matPrefix
                                                >search</mat-icon
                                            >
                                            <input
                                                type="text"
                                                autocomplete="off"
                                                id="locationSelection"
                                                class="form-control blueText textIndent float-right pl-3"
                                                placeholder="{{'tagLocation' | translate}}"
                                                [formControl]="
                                                    searchLocationNonTeams
                                                "
                                                required
                                                [matAutocomplete]="auto6"
                                                #customInput1
                                            />
                                            <i
                                                style="position: absolute;
                                                                                            right: 10px;
                                                                                            top: 10px;"
                                                class="fa fa-spinner loading_span"
                                                *ngIf="nameSearchIsLoading"
                                                aria-hidden="true"
                                            ></i>
                                            <mat-autocomplete
                                                multiple
                                                #auto6="matAutocomplete"
                                            >
                                                <ng-container
                                                    *ngFor="
                                                        let dropData of filteredLocations
                                                            | async
                                                    "
                                                >
                                                    <mat-option
                                                        class="autocompleteSearch"
                                                        [value]="dropData.name"
                                                        *ngIf="
                                                            dropData.status ==
                                                            'ENABLED'
                                                        "
                                                        (click)="
                                                            selectLocationNonTeams(
                                                                dropData
                                                            )
                                                        "
                                                    >
                                                        <span>{{
                                                            dropData.name
                                                        }}</span>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </div>
                                        <div
                                            class="input-group pl-0"
                                            *ngIf="!accessAllLocationsNonTeams"
                                        >
                                            <mat-icon
                                                class="alignSearchIconGray pl-2"
                                                matPrefix
                                                >search</mat-icon
                                            >
                                            <input
                                                type="text"
                                                autocomplete="off"
                                                id="locationSelection"
                                                class="form-control blueText textIndent float-right pl-3"
                                                placeholder="{{'tagLocationLoc' | translate}}"
                                                [formControl]="
                                                    searchLocationNonTeams
                                                "
                                                required
                                                [matAutocomplete]="auto7"
                                                #customInput1
                                            />
                                            <i
                                                style="position: absolute;
                                                                                            right: 10px;
                                                                                            top: 10px;"
                                                class="fa fa-spinner loading_span"
                                                *ngIf="nameSearchIsLoading"
                                                aria-hidden="true"
                                            ></i>
                                            <mat-autocomplete
                                                multiple
                                                #auto7="matAutocomplete"
                                            >
                                                <mat-option
                                                    class="autocompleteSearch"
                                                    *ngFor="
                                                        let dropData of filteredCustomLocationsAdditionalNonTeams
                                                            | async
                                                    "
                                                    [value]="dropData.name"
                                                    (click)="
                                                        selectLocationNonTeams(
                                                            dropData
                                                        )
                                                    "
                                                >
                                                    <span>{{
                                                        dropData.name
                                                    }}</span>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-5 col-md-12">
                                        <label class="formLabel">{{"Roles" | translate}}</label>
                                        <div class="input-group pl-0">
                                            <mat-icon
                                                class="alignSearchIconGray pl-2"
                                                matPrefix
                                                >search</mat-icon
                                            >
                                            <input
                                                type="text"
                                                autocomplete="off"
                                                class="form-control pl-3 blueText textIndent float-right"
                                                placeholder="{{'tagRoles' | translate}}"
                                                required
                                                [formControl]="
                                                    searchRoleNonTeams
                                                "
                                                [matAutocomplete]="auto"
                                                #customInput
                                            />
                                            <i
                                                style="position: absolute;
                                                  right: 10px;
                                                  top: 10px;"
                                                class="fa fa-spinner loading_span"
                                                *ngIf="nameSearchIsLoading"
                                                aria-hidden="true"
                                            ></i>
                                            <mat-autocomplete
                                                #auto="matAutocomplete"
                                            >
                                                <ng-container
                                                    *ngFor="
                                                        let dropData of filteredRoles
                                                            | async
                                                    "
                                                >
                                                    <mat-option
                                                        class="autocompleteSearch"
                                                        *ngIf="
                                                            dropData.id != 13
                                                        "
                                                        [value]="dropData.name"
                                                        (click)="
                                                            selectRolesNonTeams(
                                                                dropData
                                                            )
                                                        "
                                                    >
                                                        <span>{{
                                                            dropData.name ==
                                                            "SUPER_ADMIN"
                                                                ? "Super Admin"
                                                                : dropData.name
                                                        }}</span>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </div>
                                    </div>
                                    <button
                                        class="smallBtnCircleAdd semi-bold-blue mt-4 ml-5"
                                        (click)="tagRolesLocationsNonTeams()"
                                        type="button"
                                    >
                                        +
                                    </button>
                                </div>
                            </ng-container>

                            <div
                                class="row align-items-center"
                                *ngIf="locationsListNonTeams.length > 0"
                            >
                                <ng-container
                                    *ngFor="
                                        let loc of locationsListNonTeams;
                                        let i = index
                                    "
                                >
                                <ng-container *ngIf="hasValidRoles(loc)">
                                    <h6 class="semi-bold-blue col-5">
                                        {{ loc.name }}
                                    </h6>
                                    <div class="row p-1 mt-2 col-7 pt-0 ml-1">
                                        <div
                                            class="form-group w-fit ml-1"
                                            *ngFor="
                                                let role of loc.roles;
                                                let j = index
                                            "
                                        >
                                            <div
                                                class="inner-card-auto  d-flex justify-content-between align-items-center py-1"
                                            >
                                                <span
                                                    class="gray-regular font-small mr-1"
                                                    >{{ role.role }}</span
                                                >
                                                <span
                                                    aria-hidden="true"
                                                    (click)="
                                                        removeRolesNonTeams(
                                                            i,
                                                            j
                                                        )
                                                    "
                                                    class="cursor-pointer"
                                                    >&times;</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="form-group col-lg-12 d-flex col-md-12">
                            <p
                                class="mr-2 blueText"
                                *ngIf="accessAllLocationsNonTeams"
                            >
                                {{"usersWillBeMappedToAllLocationTaggedToGeneralUserRole" | translate}}
                                <span
                                    class="light-blue-anchor ml-2 informationIcon"
                                    >i</span
                                >
                            </p>
                            <p
                                class="mr-2 blueText"
                                *ngIf="!accessAllLocationsNonTeams"
                            >
                                {{"allUsersAreTaggedToAGeneralUserRole" | translate}}
                                <span
                                    class="light-blue-anchor ml-2 informationIcon"
                                    >i</span
                                >
                            </p>
                            <div class="col-5 px-0">
                                <button
                                    class="btn btn-main btnSmall  semi-bold-blue ml-4 smallBtn float-right"
                                    type="submit"
                                >
                                    {{"done" | translate}}
                                </button>
                                <button
                                    *ngIf="pageType != 'editUsersNonTeams'"
                                    type="button"
                                    (click)="addMoreUsers(pageType)"
                                    class="btn btn-main btnSmall btn-main-outline smallBtn float-right marginAddMoreBtn"
                                >
                                    {{"addMore" | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div
        class="modal-card"
        *ngIf="
            pageType == 'addUserManuallyNe' ||
            pageType == 'addUsersTeams' ||
            pageType == 'editUsersTeams'
        "
    >
        <div class="modal-header p-0">
            <!-- <h5 class="semi-bold-blue"><i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtnUsers()" *ngIf="!individual && pageType != 'editUsers'"></i> {{pageType != 'editUsers' ? 'Add' : 'Edit'}} Users <span *ngIf="!individual">(3/3)</span></h5> -->
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                    *ngIf="pageType != 'editUsersTeams'"
                ></i>
                {{ pageType != "editUsersTeams" ? getTranslate('add') : getTranslate('edit') }} {{"users" | translate}}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <form [formGroup]="addUsers" (ngSubmit)="showAlertPopup(pageType)">
                <div class="form-group row">
                    <label
                        for="staticEmail"
                        class="col-sm-4 col-form-label formLabel"
                        >{{ addUsers.controls["employeeName"].value }}
                    </label>
                    <div class="col-sm-8">
                        <mat-checkbox
                            class="example-margin"
                            formControlName="globalAdmin"
                            >{{"globalAdmin" | translate}}</mat-checkbox
                        >
                    </div>
                </div>

                <div
                    class="form-group row "
                    *ngIf="!addUsers.controls['globalAdmin'].value"
                >
                    <ng-container
                        *ngIf="enableAllSites"
                        [formGroup]="addTeamsUserForm"
                    >
                        <label
                            for="staticEmail"
                            class="col-sm-4 col-form-label formLabel"
                            >{{"locations" | translate}}
                        </label>
                        <label class="col-form-label col-sm-8 formLabel">
                            {{"additionalRoles" | translate}}</label
                        >
                        <ng-container
                            *ngFor="
                                let row of getDynamicRow?.controls;
                                let i = index
                            "
                        >
                            <div class="col-sm-4">
                                {{ row.get("locations").value }}
                            </div>
                            <div class="col-sm-8">
                                <div class="mb-2">
                                    <mat-icon class="alignserachIcon" matPrefix
                                        >search</mat-icon
                                    >
                                    <input
                                        type="text"
                                        name="name"
                                        autocomplete="off"
                                        class="form-control blueText textIndent float-right"
                                        placeholder="{{'tagRoles' | translate}}"
                                        required
                                        [formControl]="searchControl"
                                        [matAutocomplete]="auto"
                                        #customInput
                                    />
                                    <i
                                        style="position: absolute;
                  right: 10px;
                  top: 10px;"
                                        class="fa fa-spinner loading_span"
                                        *ngIf="nameSearchIsLoading"
                                        aria-hidden="true"
                                    ></i>
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <ng-container
                                            *ngFor="
                                                let dropData of filteredRoles
                                                    | async
                                            "
                                        >
                                            <mat-option
                                                class="autocompleteSearch"
                                                *ngIf="dropData.id != 13"
                                                [value]="dropData.name"
                                                (click)="
                                                    addRolesToLocation(
                                                        dropData,
                                                        i
                                                    )
                                                "
                                            >
                                                <span>{{
                                                    dropData.name ==
                                                    "SUPER_ADMIN"
                                                        ? "Super Admin"
                                                        : dropData.name
                                                }}</span>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                    <span
                                        *ngIf="
                                            allSitesSubmitted &&
                                            row.get('roles').invalid
                                        "
                                        class="text-danger"
                                        >{{"pleaseSelectatleastoneRole" | translate}}</span
                                    >
                                </div>
                                <div class="d-flex flex-wrap">
                                    <div
                                        class="inner-card-auto ml-2 mb-2"
                                        *ngFor="
                                            let role of row.get('roles').value;
                                            let j = index
                                        "
                                    >
                                        <span>{{ role["roleName"] }}</span>
                                        <span
                                            aria-hidden="true"
                                            class="mr-2 ml-2"
                                            (click)="
                                                removeTeamLoctaionRoles(i, j)
                                            "
                                            >&times;</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!enableAllSites">
                        <div class="row col-12">
                            <div class="col-sm-4">
                                <label
                                    for="staticEmail"
                                    class="col-form-label formLabel"
                                    >{{"tagLocation" | translate}}</label
                                >
                            </div>
                            <div class="col-sm-8">
                                <div class="mt-1">
                                    <mat-icon class="alignserachIcon" matPrefix
                                        >search</mat-icon
                                    >
                                    <input
                                        type="text"
                                        name="name"
                                        autocomplete="off"
                                        id="locationSelection"
                                        class="form-control blueText textIndent float-right"
                                        placeholder="{{'tagLocations' | translate}}"
                                        [formControl]="searchLocation"
                                        required
                                        [matAutocomplete]="auto"
                                        #customInput
                                    />
                                    <i
                                        style="position: absolute;
                    right: 10px;
                    top: 10px;"
                                        class="fa fa-spinner loading_span"
                                        *ngIf="nameSearchIsLoading"
                                        aria-hidden="true"
                                    ></i>
                                    <mat-autocomplete
                                        multiple
                                        #auto="matAutocomplete"
                                    >
                                        <mat-option
                                            class="autocompleteSearch"
                                            *ngFor="
                                                let dropData of filteredLocations
                                                    | async
                                            "
                                            [value]="dropData.name"
                                            (click)="addLocationTeams(dropData)"
                                        >
                                            <span>{{ dropData.name }}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <span
                                        *ngIf="
                                            allSitesSubmitted &&
                                            addTeamsUserForm.invalid
                                        "
                                        class="text-danger"
                                        >{{"pleaseSelectAtleastOneLocation" | translate}}</span
                                    >
                                </div>
                            </div>
                        </div>

                        <!-- Displaying locations & roles manually-->
                        <ng-container [formGroup]="addTeamsUserForm">
                            <div
                                class="row col-12 mt-2"
                                *ngIf="getDynamicRow.length > 0"
                            >
                                <div class="col-sm-4">
                                    <label
                                        for="staticEmail"
                                        class="col-form-label formLabel"
                                        >{{ "locations" | translate }}
                                    </label>
                                </div>
                                <label
                                    class="col-form-label col-sm-8 formLabel"
                                >
                                    {{ "additionalRoles" | translate }}</label
                                >
                            </div>
                            <ng-container
                                *ngFor="
                                    let row of getDynamicRow?.controls;
                                    let i = index
                                "
                            >
                                <div class="row col-12">
                                    <div class="col-sm-4">
                                        <p
                                            class="col-form-label formLabel f-16"
                                        >
                                            {{ row.get("locations").value }}
                                            <img
                                                role="button"
                                                class="deleteTeamIcon"
                                                src="../../assets/images/jumpree_digital/delete-icon.svg"
                                                (click)="deleteTeamsLocation(i)"
                                            />
                                        </p>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="mt-1">
                                            <mat-icon
                                                class="alignserachIcon"
                                                matPrefix
                                                >search</mat-icon
                                            >
                                            <input
                                                type="text"
                                                name="name"
                                                autocomplete="off"
                                                class="form-control blueText textIndent float-right selectionRoles"
                                                placeholder="{{'tagRoles' | translate}}"
                                                [formControl]="searchControl"
                                                required
                                                [matAutocomplete]="auto"
                                                #customInput
                                            />
                                            <i
                                                style="position: absolute;
                        right: 10px;
                        top: 10px;"
                                                class="fa fa-spinner loading_span"
                                                *ngIf="nameSearchIsLoading"
                                                aria-hidden="true"
                                            ></i>
                                            <mat-autocomplete
                                                #auto="matAutocomplete"
                                            >
                                                <ng-container
                                                    *ngFor="
                                                        let dropData of filteredRoles
                                                            | async
                                                    "
                                                >
                                                    <mat-option
                                                        class="autocompleteSearch"
                                                        [value]="dropData.name"
                                                        (click)="
                                                            addRolesToLocation(
                                                                dropData,
                                                                i
                                                            )
                                                        "
                                                        *ngIf="
                                                            dropData.id != 13
                                                        "
                                                    >
                                                        <span>
                                                            {{
                                                                dropData.name ==
                                                                "SUPER_ADMIN"
                                                                    ? "Super Admin"
                                                                    : dropData.name
                                                            }}
                                                        </span>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </div>

                                        <div class="d-flex flex-wrap">
                                            <div
                                                class="inner-card-auto ml-2 mt-2"
                                                *ngFor="
                                                    let role of row.get('roles')
                                                        .value;
                                                    let j = index
                                                "
                                            >
                                                <span>{{
                                                    role["roleName"]
                                                }}</span>
                                                <span
                                                    aria-hidden="true"
                                                    class="mr-2 ml-2 cursor-pointer"
                                                    (click)="
                                                        removeTeamLoctaionRoles(
                                                            i,
                                                            j
                                                        )
                                                    "
                                                    >&times;</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>

                <div
                    class="form-group row "
                    *ngIf="addLocationRoles?.length > 0"
                >
                    <div class="col-sm-4">
                        <span class="regular-semibold-font">{{ "locations" | translate }}</span>
                    </div>
                    <div class="col-sm-8 row">
                        <span class="regular-semibold-font"
                            >{{ "additionalRoles" | translate }}</span
                        >
                    </div>
                </div>

                <ng-container
                    *ngFor="let location of addLocationRoles; let i = index"
                >
                    <div class="form-group row ">
                        <div class="col-sm-4 ">
                            <label
                                for="staticEmail"
                                class="col-form-label formLabel"
                                >{{ location.key }}
                                <i
                                    (click)="deleteLocation(location.key)"
                                    class="fa fa-solid fa-trash"
                                ></i
                            ></label>
                        </div>
                        <div class="col-sm-8 row">
                            <div
                                class="input-group col-sm-4  pl-0"
                                *ngFor="
                                    let role of location.value;
                                    let j = index
                                "
                            >
                                <div
                                    class="inner-card-auto d-flex justify-content-between"
                                >
                                    <span>{{ role }}</span>
                                    <span
                                        aria-hidden="true"
                                        (click)="removeLoctaionRoles(i, role)"
                                        >&times;</span
                                    >
                                </div>
                            </div>

                            <div class="input-group pl-0 col-sm-4">
                                <mat-icon class="alignserachIcon" matPrefix
                                    >search</mat-icon
                                >
                                <input
                                    type="text"
                                    name="name"
                                    [(ngModel)]="location.roleVal"
                                    autocomplete="off"
                                    class="form-control blueText textIndent"
                                    placeholder="{{'SearchUser' | translate}}"
                                    [ngModelOptions]="{ standalone: true }"
                                    required
                                    [matAutocomplete]="auto"
                                    #customInput
                                />
                                <!-- <i class="arrowDropDownIcon down alignDropdownIcon"></i> -->
                                <!-- <mat-icon class="aggregator-arrow-selector alignDropdownIcon" matSuffix>
                              <div class="mat-select-arrow-wrapper" >
                                <div class="mat-select-arrow"></div>
                              </div>
                            </mat-icon> -->
                                <i
                                    style="position: absolute;
                            right: 10px;
                            top: 10px;"
                                    class="fa fa-spinner loading_span"
                                    *ngIf="nameSearchIsLoading"
                                    aria-hidden="true"
                                ></i>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option
                                        class="autocompleteSearch"
                                        *ngFor="let dropData of rolesSelectList"
                                        [value]="dropData"
                                        (click)="
                                            addRolesToLocation(dropData, i)
                                        "
                                    >
                                        <span>{{ dropData }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <span class="bottomTitle"
                            >{{ "everyUserIsMappedAGeneralUserByDefault" | translate }}</span
                        >
                    </div>
                    <div class="col-sm-6">
                        <button
                            class="btn btn-main btnSmall ml-4 smallBtn float-right"
                            type="submit"
                        >
                            {{ "next" | translate }}
                        </button>
                    </div>
                </div>

                <!-- <div class="row col-12 px-0">
          <label class="float-left formLabel col-12"
              >{{ addUsers.controls['employeeName'].value }} <span class="text-danger">*</span>
          </label>
          <div class="form-group col-lg-12 col-md-12 pr-0">
         
              <div class="input-group pl-0">
                <mat-checkbox class="example-margin" formControlName="globalAdmin">Global Admin</mat-checkbox>
              </div>
       
          </div>
        </div>     -->
            </form>
        </div>
    </div>

    <div class="modal-card fixedHeight" *ngIf="pageType == 'tagLocations'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                ></i>
                {{ "tagLocation" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <form>
                <div class="row">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="formLabel">{{ "locationCaps" | translate }}</label>
                        <div class="input-group form-control">
                            <mat-select
                                [formControl]="selectedloc"
                                [(value)]="selectedLocations"
                                placeholder="{{'selectLocation' | translate}}"
                                multiple
                            >
                                <mat-option
                                    *ngFor="let zone of LOCATIONS_ELEMENT_DATA"
                                    [value]="zone"
                                >
                                    {{ zone.name }}
                                </mat-option>
                            </mat-select>
                        </div>
                        <!-- <div *ngIf="submittedDepartment && departmentForm.controls.departmentName.errors">
                  <div class="text-danger formLabel">Locations is required</div>
                </div> -->
                    </div>
                </div>
                <div class="row">
                    <div
                        class="form-group col-lg-4 "
                        *ngFor="let zone of selectedLocations; let i = index"
                    >
                        <div
                            class="inner-card-auto d-flex justify-content-between"
                        >
                            <span>{{ zone.name }}</span>
                            <span
                                aria-hidden="true"
                                (click)="removeUserLocation(i)"
                                >&times;</span
                            >
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="form-group col-lg- col-md-12 pr-0">
                        <button
                            class="btn btn-main btnSmall  semi-bold-blue ml-4 smallBtn float-right"
                            type="button"
                            (click)="selectedLocation()"
                        >
                            {{ "done" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-card fixedHeight" *ngIf="pageType == 'showRoles'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                ></i>
                {{ "addRoles" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
        <form>
            <div class="pl-0">
                <div class="row">
                    <div class="form-group col-lg-4 col-md-12 pr-0">
                        <label class="float-left formLabel">Roles</label>
                        <div class="input-group form-control ">
                            <mat-select
                                [formControl]="selectedRol"
                                [(value)]="selectedRoles"
                                placeholder="{{'selectRoles' | translate}}"
                                multiple
                            >
                                <mat-option
                                    *ngFor="let roles of roleList"
                                    [value]="roles"
                                >
                                    {{ roles.name }}
                                </mat-option>
                            </mat-select>
                        </div>
                        <!-- <div *ngIf="submittedDepartment && departmentForm.controls.departmentName.errors">
                <div class="text-danger formLabel">Roles is required</div>
              </div> -->
                    </div>
                </div>
                <div class="row">
                    <div
                        class="form-group col-lg-4 "
                        *ngFor="let role of selectedRoles; let i = index"
                    >
                        <div
                            class="inner-card-auto d-flex justify-content-between"
                        >
                            <span>{{ role.name }}</span>
                            <span
                                aria-hidden="true"
                                (click)="removeUserRoles(i)"
                                >&times;</span
                            >
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="form-group col-md-12 pr-0">
                        <button
                            class="btn btn-main btnSmall btn-main-outline semi-bold-blue ml-4 smallBtn float-right"
                            type="button"
                            (click)="selectedRole()"
                        >
                            {{ "done" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-card" *ngIf="pageType == 'addUsers'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="closePopup(pageType)"
                ></i>
                {{ "addUsersN" | translate }} <span *ngIf="!individual">(3/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <div class="row col-12">
                <p class="regular-semibold-font">
                    {{ "howWouldYouLikeToOnboardNewUsers" | translate }}
                    <!-- <span class="light-blue-anchor" (click)="redirectIntro('USERS')">users?</span> -->
                </p>
            </div>
            <div class="row col-12">
                <mat-radio-group
                    aria-label=""
                    class="radioBtn d-flex flex-column h-auto"
                    [(ngModel)]="addUserType"
                    required
                >
                    <!-- <mat-radio-button id="ad_integration" value="ad_integration" class="mb-3">
                        {{ 'AD Integrations' | translate }}
                        <span class="light-blue-anchor ml-2 informationIcon">i</span>
                      </mat-radio-button> -->

                    <mat-radio-button
                        id="add_manually"
                        class="mb-3"
                        value="add_manually"
                    >
                        {{ "addManually" | translate }}
                    </mat-radio-button>

                    <mat-radio-button
                        id="add_excel"
                        class="mb-3"
                        value="add_excel"
                    >
                        {{ "addViaExcel" | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div
                *ngIf="submittedUserType && addUserType == ''"
                class="text-danger regular-semibold-font"
            >
                {{ "pleaseSelectOneOption" | translate }}
            </div>
            <ng-container *ngIf="addUserType == 'add_excel'">
                <div class="row">
                    <div class="col-lg-5">
                        <label></label>
                        <button
                            type="button"
                            class="form-control1"
                            (click)="downloadTemplate('USERS')"
                        >
                            <span style="font-family: 'Open Sans';">{{
                                "downloadTemplate" | translate
                            }}</span>
                            <span class="ml-3"
                                ><img
                                    src="./assets/images/downloadTemplate.png"
                                    style="width: 1rem;"
                            /></span>
                        </button>
                    </div>
                    <div class="col-lg-7 col-md-12">
                        <label
                            for="uploadFile"
                            class="label1 float-left mt-2 font-label"
                            >{{ "upload" | translate }} (.csv
                            {{ "file" | translate }})</label
                        >
                        <div class="input-group col-md-6 clear-both float-left">
                            <input
                                type="file"
                                class="form-control custom-file-input"
                                id="importFile"
                                aria-describedby="inputGroupFileAddon01"
                                accept=".csv,text/csv"
                                (change)="uploadCSV($event.target.files)"
                                #fileUploader
                            />
                            <label
                                class="custom-file-label1 custom-upload-btn font-label"
                                for="inputGroupFile01"
                                #labelImport
                            ></label>
                        </div>
                        <div
                            class="input-group col-md-6 mt-3"
                            *ngIf="uploadedFileName != ''"
                        >
                            <p
                                class="card inner-card-auto text-center p-1 d-inline-block"
                            >
                                <span class="text-overflow-hidden mt-2">{{
                                    uploadedFileName
                                }}</span>
                                <button
                                    type="button"
                                    class="close text-right mt-2"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        (click)="removeUploadedFile()"
                                        >&times;</span
                                    >
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="row col-12 text-danger"
                    *ngIf="submitted && uploadedFileName == ''"
                >
                   {{ "pleaseUploadFile" | translate }}
                </div>
            </ng-container>
            <div class="col-12 text-right px-0" style="margin-top: 10%">
                <!-- <div class="align-middle mr-4 onboardCustomUsers mt-3 d-inline-block">You can onboard Upto 500 Users</div> -->
                <button
                    class="btn btn-main btnSmall smallBtn mt-2 float-right"
                    id="next"
                    name="next"
                    (click)="addUsersManually(userCountForIntroScreen)"
                >
                    {{ "next" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'showUserData'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="showUserDataBackBtn()"
                ></i>
                {{ "alert" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="mt-3">
            <ng-container *ngIf="showIncorrectData.length > 0">
                <p class="regular-semibold-font">
                    {{
                        "belowAreTheListOfEntitiesWhichHaveIssues" | translate
                    }}.
                </p>
                <table
                    mat-table
                    class="pb-2"
                    [dataSource]="showIncorrectData"
                    *ngIf="showIncorrectData.length > 0"
                >
                    <!-- Employee Name Column -->
                    <ng-container matColumnDef="employeeName">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-left table-column-100"
                        >
                            {{ "Employee" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left mat-column-100"
                            [matTooltip]="getToolTipData(element.employeeName)"
                        >
                            {{ element.employeeName | slice: 0:20 }}
                            <span *ngIf="element.employeeName.length > 20"
                                >...</span
                            >
                        </td>
                    </ng-container>

                    <!-- Department Column -->
                    <ng-container matColumnDef="department">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-left mat-column-100"
                        >
                            {{ "department" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left mat-column-100"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'Department'
                                )
                            }"
                        >
                            {{ element.department }}
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-left mat-column-100"
                        >
                            {{ "email" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left mat-column-100"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'Email'
                                )
                            }"
                        >
                            {{ element.email }}
                        </td>
                    </ng-container>

                    <!-- Global Admin -->
                    <ng-container matColumnDef="globalAdmin">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-left mat-column-100"
                        >
                            {{ "globalAdmin" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left mat-column-100"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'globalAdmin'
                                )
                            }"
                        >
                            {{ element.globalAdmin }}
                        </td>
                    </ng-container>

                    <!-- Location Column -->
                    <ng-container matColumnDef="location">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-left mat-column-100"
                        >
                            {{ "location" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left mat-column-100"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'Location'
                                )
                            }"
                        >
                            {{ element.location }}
                        </td>
                    </ng-container>

                    <!-- Additional Roles -->
                    <ng-container matColumnDef="additionalRole">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-left mat-column-100"
                        >
                            {{ "additionalRole" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left mat-column-100"
                            [ngClass]="{
                                'is-incorrect': element.errorFields.includes(
                                    'additionalRole'
                                )
                            }"
                        >
                            <span *ngIf="element.additionalRole !== ''">
                                {{ element.additionalRole }}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Reason Column -->
                    <ng-container matColumnDef="reason">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-left mat-column-100"
                        >
                            {{ "reason" | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-left mat-column-100"
                            [matTooltip]="getToolTipData(element.reason)"
                        >
                            {{ element.reason | slice: 0:20 }}
                            <span *ngIf="element.reason.length > 20">...</span>
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="displayIncorrectUserDatas"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayIncorrectUserDatas"
                    ></tr>
                </table>

                <ng-container *ngIf="totalIncorrectLength > 10">
                    <mat-paginator
                        class="w-100"
                        #paginator
                        [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]"
                        [showFirstLastButtons]="true"
                        (page)="getNextRecords($event)"
                        [length]="totalIncorrectLength"
                    ></mat-paginator>
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngIf="incorrectData.length > 0">
            <div
                class="row text-center button-alignToEnd pr-2 mt-2 float-right"
            >
                <button
                    class="btn btn-primary btn-main btn-radius font-regular"
                    (click)="reUploadUsers()"
                >
                    {{ "reuploadFilesWithFixes" | translate }}
                </button>
                <button
                    class="btn btn-primary btn-main-white-nb btn-radius regular-semibold-font ml-4"
                    (click)="proceedToSaveUserDatas()"
                >
                    {{ "proceedToSaveTheRemainingEntries" | translate }}
                </button>
            </div>
        </ng-container>
        <ng-container
            *ngIf="correctData.length > 0 && incorrectData.length == 0"
        >
            <p class="regular-semibold-font">
                {{ "successfullyUploaded" | translate }}
                {{ noOfEntitiesUploaded }} entities,
                {{ "proceedToSave" | translate }}.
            </p>
            <button
                class="btn btn1 btn-primary btn-main btn-radius font-regular float-right"
                (click)="proceedToSaveUserDatas()"
            >
                {{ "proceed" | translate }}
            </button>
        </ng-container>
    </div>

    <div class="modal-card" *ngIf="pageType == 'userBulkUpload_reUploadExcel'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <!-- <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="reUploadFileBackBtn()"
                ></i> -->
                {{ "reUploadFile" | translate }}
            </h5>
            <!-- <h5>{{'reUploadFile' | translate }}</h5> -->
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopup('showUserData')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div>
            <div class="row mt-2">
                <div class="col-12 pl-0">
                    <img
                        class="cursor-pointer pl-2"
                        src="../../assets/images/jumpree_digital/download.png"
                        (click)="downloadCurrentExcelFile()"
                        width="55"
                    />
                    <span class="regular-semibold-font">
                        {{ "downloadCurrentFile" | translate }}
                    </span>
                </div>
            </div>
            <p class="regular-semibold-font mt-3">
                {{ "uploadFileWithFixes" | translate }}
            </p>
            <div class="row">
                <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                    <label for="uploadFile" class="label1"
                        >{{ "upload" | translate }} (.xlsx
                        {{ "file" | translate }})</label
                    >
                    <div class="input-group">
                        <input
                            type="file"
                            class="form-control custom-file-input"
                            id="importFile"
                            aria-describedby="inputGroupFileAddon01"
                            accept=".xlsx"
                            (change)="uploadCSV($event.target.files)"
                            #fileUploader
                        />
                        <label
                            class="custom-file-label1 custom-upload-btn font-label"
                            for="inputGroupFile01"
                            #labelImport
                        ></label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div
                    class="input-group col-md-6 mt-3"
                    *ngIf="uploadedFileName && uploadedFileName != ''"
                >
                    <p class="pConfirmationMessage custom-shadow ml-0">
                        {{ uploadedFileName }}
                        <span
                            class="ml-4 cursor-pointer font-weight-bold"
                            (click)="removeUploadedFile()"
                            >×</span
                        >
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="uploadedFileName != ''">
                <div class="col">
                    <button
                        type="button"
                        class="btn btn1 btn-main float-right rounded-pill"
                        (click)="addUsersManually(userCountForIntroScreen)"
                    >
                        {{ "proceed" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'addUsersNew'">
        <div class="modal-header p-0" *ngIf="!addViaExcelNew">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="closePopup(pageType)"
                ></i>
               {{"addUsersN" | translate}} <span *ngIf="!individual">(3/3)</span>
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-header p-0" *ngIf="addViaExcelNew">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="backBtnUsers()"
                ></i>
                {{"onboardUser" | translate}}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <ng-container *ngIf="addViaExcelNew">
                <div class="row col-12">
                    <p class="regular-blue" *ngIf="accessAllLocationsNonTeams">
                        {{"onboardUsersByMappingThemToPlansAdditionlRoles" | translate}}
                    </p>
                    <p class="regular-blue" *ngIf="!accessAllLocationsNonTeams">
                        {{"onboardUsersByMappingThemToPlans" | translate}}
                    </p>
                </div>
            </ng-container>

            <ng-container *ngIf="!addViaExcelNew">
                <div class="row col-12">
                    <p class="regular-semibold-font">
                       {{"howWouldYouLikeToOnboardNewUsers" | translate}}
                        <!-- <span class="light-blue-anchor" (click)="redirectIntro('USERS')">users?</span> -->
                    </p>
                </div>
                <div class="row col-12">
                    <mat-radio-group
                        aria-label=""
                        class="radioBtn d-flex flex-column h-auto"
                        [(ngModel)]="addUserType"
                        required
                    >
                        <!-- <mat-radio-button id="ad_integration" value="ad_integration" class="mb-3">
                        {{ 'AD Integrations' | translate }}
                        <span class="light-blue-anchor ml-2 informationIcon">i</span>
                      </mat-radio-button> -->

                        <mat-radio-button
                            id="add_manually"
                            class="mb-3"
                            value="add_manually_non_digital"
                        >
                            {{ "addManually" | translate }}
                        </mat-radio-button>

                        <mat-radio-button
                            id="add_excel"
                            class="mb-3"
                            value="add_excel_non_digital"
                        >
                            {{ "addViaExcel" | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div
                    *ngIf="submittedUserType && addUserType == ''"
                    class="text-danger regular-semibold-font"
                >
                    {{ "pleaseSelectOneOption" | translate }}
                </div>
            </ng-container>
            <ng-container *ngIf="addViaExcelNew">
                <div class="row mt-2">
                    <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                        <button
                            type="button"
                            class="form-control1"
                            (click)="downloadUserNonTeam()"
                        >
                            <span style="font-family: 'Open Sans';">{{
                                "downloadTemplate" | translate
                            }}</span>
                            <span class="ml-3"
                                ><img
                                    src="./assets/images/downloadTemplate.png"
                                    style="width: 1rem;"
                            /></span>
                        </button>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                        <label
                            for="uploadFile"
                            class="label1 float-left mt-2 font-label"
                            >{{ "upload" | translate }} (.xlsx
                            {{ "file" | translate }})</label
                        >
                        <div class="input-group clear-both float-left">
                            <input
                                type="file"
                                class="form-control custom-file-input"
                                id="importFile"
                                aria-describedby="inputGroupFileAddon01"
                                accept=".xlsx"
                                (change)="uploadCSV($event.target.files)"
                                #fileUploader
                            />
                            <label
                                class="custom-file-label1 custom-upload-btn font-label"
                                for="inputGroupFile01"
                                #labelImport
                            ></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="input-group col-md-6 mt-3"
                        *ngIf="uploadedFileName != ''"
                    >
                        <p class="pConfirmationMessage custom-shadow ml-0 mb-0">
                            {{ uploadedFileName }}
                            <span
                                class="ml-4 cursor-pointer font-weight-bold"
                                (click)="removeUploadedFile()"
                                >×</span
                            >
                        </p>
                    </div>
                </div>
                <div
                    class="row col-12 text-danger"
                    *ngIf="submitted && uploadedFileName == ''"
                >
                    {{"pleaseUploadFiles" | translate}}
                </div>
            </ng-container>
            <div class="col-12 text-right px-0">
                <!-- <div class="align-middle mr-4 onboardCustomUsers mt-3 d-inline-block">You can onboard Upto 500 Users</div> -->
                <button
                    class="btn btn1 btn-main btnSmall smallBtn mt-2 float-right"
                    id="next"
                    name="next"
                    (click)="addUsersManually(userCountForIntroScreen)"
                >
                    {{"next" | translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'ShowAlert'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{"alert" | translate}}
            </h5>
        </div>
        <div class="col-12 pl-0 mt-2">
            <p class="semi-heading italic">
                Global Setup > {{ globalSetupTypeForAlertPopup }}
            </p>
        </div>
        <div class="modal-body align-left pl-0">
            <h5 class="semi-bold-blue">{{"globalSetupNotCompleted" | translate}}</h5>
            <div class="font-weight-bold">
                {{"youHaveNotCompletedTheGlobalSetupWouldYouLikeToExit" | translate}}
            </div>
            <div class="customMargin">
                <ng-container>
                    <div class="form-group col-lg-12 col-md-12 pr-0">
                        <button
                            type="button"
                            class="btn btn-main btnSmall smallBtn float-right ml-4"
                            (click)="CloseAlert()"
                        >
                            {{"yes" | translate}}
                        </button>
                        <button
                            class="btn btn-main btnSmall btn-main-outline semi-bold-blue smallBtn float-right alertPopupBtnMargin"
                            type="submit"
                            (click)="goBack(globalSetupTypeForAlertPopup)"
                        >
                            {{"no" | translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'ShowAlertPopup'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "alert" | translate }}
            </h5>
        </div>

        <div class="modal-body align-left pl-0">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <h6 class="regular-blue">
                {{ "allUsersMustBeMappedToOneMoreLocationsToCompleteTheOnboarding" | translate }}
            </h6>

            <div class="customMargin">
                <ng-container>
                    <div class="form-group col-lg-12 col-md-12 pr-0">
                        <button
                            type="button"
                            class="btn btn-main btnSmall smallBtn float-right ml-4"
                            (click)="backBtnUsers()"
                        >
                            {{ "ok" | translate }}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'ShowAlertRole'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{ "alert" | translate }}
            </h5>
        </div>

        <div class="modal-body align-left">
            <app-sub-heading title="{{'globalSetupPeople' | translate}}"></app-sub-heading>
            <div class="row">
                <h6 class="regular-blue pl-3">
                    {{ "ifYouRemoveAllTheRolesInaLocation" | translate }}
                </h6>
            </div>
            <div class="row col-12 d-inline-block">
                <button
                    class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                    type="button"
                    (click)="removeRoleAndDisableLocation()"
                >
                    {{ "yes" | translate }}
                </button>
                <button
                    class="btn btn-White btn-main btnSmall btn-radius mr-2 float-right font-regular"
                    type="button"
                    (click)="goBack(globalSetupTypeForAlertPopup)"
                >
                    {{ "no" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'incorrectEntries'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">{{ "alert" | translate }}</h5>
            <!-- <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closePopupInMiddle(pageType)"
            >
                <span aria-hidden="true">&times;</span>
            </button> -->
        </div>
        <div class="row col-12">
            <p class="semi-heading italic">
                {{ "globalSetupCaps" | translate }} >
                <span *ngIf="fileType == 'USERS'" class="semi-heading"
                    >{{ "people" | translate }}</span
                >
                <span *ngIf="fileType == 'DEPARTMENTS'" class="semi-heading"
                    >{{ "deptCaps" | translate }}</span
                >
                <span *ngIf="fileType == 'LOCATIONS'" class="semi-heading"
                    >{{ "locations" | translate }}</span
                >
            </p>
        </div>

        <div class="modal-body">
            <div class="row col-12">
                <p class="semi-bold-blue" *ngIf="fileType == 'USERS'">
                    {{ "belowUsersWillNotBeOnboarded" | translate }}
                </p>
                <p class="semi-bold-blue" *ngIf="fileType == 'DEPARTMENTS'">
                    {{ "theBelowDepartmentsWillNotBeOnboardedWouldYouLikeToProcedAnyway" | translate }}
                </p>
                <p class="semi-bold-blue" *ngIf="fileType == 'LOCATIONS'">
                    {{ "theBelowLocationWillNotBeOnboardedWouldYouLikeToProceed" | translate }}
                </p>
            </div>
            <div class="form-group row mb-0 p-0">
                <ng-container *ngIf="fileType == 'USERS'">
                    <table mat-table [dataSource]="incorrectData" class="mb-4">
                        <!-- Sl No -->
                        <ng-container matColumnDef="slNo">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "SlNo" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element; let i = dataIndex"
                                class="align-left"
                            >
                                {{ element.slNo }}
                            </td>
                        </ng-container>

                        <!-- First Name Column -->
                        <ng-container matColumnDef="firstName">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "firstName" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="getToolTipData(element.firstName)"
                            >
                                {{ element.firstName | slice: 0:20 }}
                                <span *ngIf="element.firstName.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <!-- Last Name Column -->
                        <ng-container matColumnDef="lastName">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "lastName" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                            >
                                {{ element.lastName }}
                            </td>
                        </ng-container>

                        <!-- Email Column -->
                        <ng-container matColumnDef="email">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "email" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [ngClass]="{
                                    'is-incorrect': element.errorFields.includes(
                                        'Email'
                                    )
                                }"
                            >
                                {{ element.email }}
                            </td>
                        </ng-container>

                        <!-- Phone Column -->
                        <ng-container matColumnDef="phone">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "phone" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                            >
                                {{ element.phone }}
                            </td>
                        </ng-container>

                        <!-- Employee ID Column -->
                        <ng-container matColumnDef="empCode">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "employeeId" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [ngClass]="{
                                    'is-incorrect': element.errorFields.includes(
                                        'Employee ID'
                                    )
                                }"
                            >
                                {{ element.empCode }}
                            </td>
                        </ng-container>

                        <!-- Department Column -->
                        <ng-container matColumnDef="departmentName">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "department" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [ngClass]="{
                                    'is-incorrect': element.errorFields.includes(
                                        'Department'
                                    )
                                }"
                            >
                                {{ element.departmentName }}
                            </td>
                        </ng-container>

                        <!-- Location Column -->
                        <ng-container matColumnDef="location">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "location" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [ngClass]="{
                                    'is-incorrect': element.errorFields.includes(
                                        'Location'
                                    )
                                }"
                            >
                                {{ element.location }}
                            </td>
                        </ng-container>

                        <!-- Roles Column -->
                        <ng-container matColumnDef="roles">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "roles" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [ngClass]="{
                                    'is-incorrect': element.errorFields.includes(
                                        'Roles'
                                    )
                                }"
                            >
                                {{ element.roles }}
                            </td>
                        </ng-container>

                        <!-- Reason Column -->
                        <ng-container matColumnDef="reason">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "reason" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="getToolTipData(element.reason)"
                            >
                                {{ element.reason | slice: 0:20 }}
                                <span *ngIf="element.reason.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumnsIncorrectData"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="
                                let row;
                                columns: displayedColumnsIncorrectData
                            "
                        ></tr>
                    </table>
                </ng-container>

                <ng-container *ngIf="fileType == 'DEPARTMENTS'">
                    <table mat-table [dataSource]="incorrectData" class="mb-4">
                        <!-- Sl No -->
                        <ng-container matColumnDef="slNo">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "SlNo" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element; let i = dataIndex"
                                class="align-left"
                            >
                                {{ element.slNo }}
                            </td>
                        </ng-container>

                        <!-- First Name Column -->
                        <ng-container matColumnDef="departmentName">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "departmentName" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="
                                    getToolTipData(element.departmentName)
                                "
                            >
                                {{ element.departmentName | slice: 0:40 }}
                                <span *ngIf="element.departmentName.length > 40"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <!-- Reason Column -->
                        <ng-container matColumnDef="reason">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "reason" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="getToolTipData(element.reason)"
                            >
                                {{ element.reason | slice: 0:20 }}
                                <span *ngIf="element.reason.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumnsIncorrectData"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="
                                let row;
                                columns: displayedColumnsIncorrectData
                            "
                        ></tr>
                    </table>
                </ng-container>

                <ng-container *ngIf="fileType == 'LOCATIONS'">
                    <table mat-table [dataSource]="incorrectData" class="mb-4">
                        "locationName", "country", "state", "city", "timeZone"
                        <ng-container matColumnDef="slNo">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "SlNo" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element; let i = dataIndex"
                                class="align-left"
                            >
                                {{ element.slNo }}
                            </td>
                        </ng-container>

                        <!-- First Name Column -->
                        <ng-container matColumnDef="locationName">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "locationName" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="
                                    getToolTipData(element.locationName)
                                "
                            >
                                {{ element.locationName | slice: 0:20 }}
                                <span *ngIf="element.locationName.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="country">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "country" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="getToolTipData(element.country)"
                            >
                                {{ element.country | slice: 0:20 }}
                                <span *ngIf="element.country.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="state">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "state" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="getToolTipData(element.state)"
                            >
                                {{ element.state | slice: 0:20 }}
                                <span *ngIf="element.state.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="city">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "city" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="getToolTipData(element.city)"
                            >
                                {{ element.city | slice: 0:20 }}
                                <span *ngIf="element.city.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="timeZone">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "timeZone" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="getToolTipData(element.timeZone)"
                            >
                                {{ element.timeZone | slice: 0:20 }}
                                <span *ngIf="element.timeZone.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <!-- Reason Column -->
                        <ng-container matColumnDef="reason">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="align-left"
                            >
                                {{ "reason" | translate }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                class="align-left"
                                [matTooltip]="getToolTipData(element.reason)"
                            >
                                {{ element.reason | slice: 0:20 }}
                                <span *ngIf="element.reason.length > 20"
                                    >...</span
                                >
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumnsIncorrectData"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="
                                let row;
                                columns: displayedColumnsIncorrectData
                            "
                        ></tr>
                    </table>
                </ng-container>
                <div class="row col-12 d-inline-block">
                    <button
                        class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
                        type="button"
                        (click)="proceedToUpload()"
                    >
                        {{ "yes" | translate }}
                    </button>
                    <button
                        class="btn btn-main-outline btnSmall btn-radius mr-2 float-right font-regular"
                        type="button"
                        (click)="closeAlertPopup()"
                    >
                        {{ "no" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'uploadCsvWithFixes'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="closeReUploadUsersPopup()"
                ></i>
                {{ "reuploadFile" | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closeReUploadUsersPopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body align-left">
            <app-sub-heading title="{{ titleForReupload }}"></app-sub-heading>
            <ng-container>
                <div class="row col-12 mt-3 d-flex align-items-center">
                    <button
                        style="margin-left: 2px;"
                        id="download_btn"
                        mat-mini-fab
                        class="btn btn-main mat-mini-fab bgColor"
                        (click)="downloadCurrentFile()"
                    >
                        <img src="./assets/images/save.svg" alt="" />
                    </button>
                    <span class="ml-3 regular-blue" style="font-size: 14px;">{{
                        "downloadCurrentFile" | translate
                    }}</span>
                </div>
            </ng-container>
            <div class="row col-12 mt-4">
                <p class="semi-bold-blue mb-0">
                    {{ "uploadFileWithFixes" | translate }}
                </p>
            </div>

            <ng-container>
                <div class="row col-12">
                    <div class="col-lg-4 col-md-4 px-0">
                        <label for="uploadFile" class="font-label"
                            >{{ "upload" | translate }} (.csv
                            {{ "file" | translate }})</label
                        >
                        <div class="input-group">
                            <input
                                type="file"
                                class="form-control  custom-file-input"
                                id="importFile"
                                aria-describedby="inputGroupFileAddon02"
                                accept=".csv,text/csv"
                                (change)="uploadCSV($event.target.files)"
                                #fileUploader
                            />
                            <label
                                class="custom-file-label1 custom-upload-btn font-label"
                                for="inputGroupFile02"
                                #labelImport
                            ></label>
                        </div>
                    </div>
                    <div
                        class="col-lg-4 col-md-12 mt-3"
                        *ngIf="uploadedFileName != ''"
                    >
                        <p
                            class="card inner-card-auto text-center p-1 d-inline-block w-100"
                        >
                            <span class="text-overflow-hidden mt-2">{{
                                uploadedFileName
                            }}</span>
                            <button
                                type="button"
                                class="close text-right mt-2"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    (click)="removeUploadedFile()"
                                    >&times;</span
                                >
                            </button>
                        </p>
                    </div>
                </div>
                <div>
                    <button
                        type="button"
                        class="btn btn-main pl-3 pr-3 rounded-pill float-right"
                        (click)="proceedUploadFile()"
                    >
                        {{ "next" | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'viewRoles'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                <i
                    class="fa fa-angle-left mr-2 arrowIcon"
                    (click)="goToUsers()"
                ></i>
                {{ "roles" | translate }}
            </h5>
        </div>

        <div class="modal-body p-0">
            <div class="d-inline-flex align-items-center text-left">
                <div>
                    <img
                        src="./assets/images/arrowprev.png"
                        class="cursor-pointer navImgNext nextprev-35"
                        aria-hidden="true"
                        *ngIf="pageCount == 1 && totalPages > 1"
                    />

                    <img
                        src="./assets/images/arrow_next.png"
                        class="cursor-pointer navImgNextPrev nextprev-25"
                        aria-hidden="true"
                        *ngIf="pageCount > 1"
                        (click)="nextPrevPage('p')"
                    />
                    <!-- <img src="./assets/images/arrow_next.png" class="cursor-pointer navImgNextPrev w-20"
                                aria-hidden="true" *ngIf="pageCount>1" (click)="nextPrevPage('p')"/>

                <img src="./assets/images/arrowprev.png" class="cursor-pointer navImg w-30" *ngIf="pageCount==1 && totalPages>1" /> -->
                </div>

                <div *ngFor="let rolesInd of roles; let i = index">
                    <div
                        class="roles-section m-1"
                        *ngIf="
                            i >= displayPerPage * pageCount - displayPerPage &&
                            i < displayPerPage * pageCount
                        "
                    >
                        <div class="u-roles">
                            <span class="regular-bold-blue">{{
                                rolesInd.role
                            }}</span>
                        </div>

                        <div
                            class="inner-card-roles mt-3 scroller resposibilities"
                        >
                            <ng-container
                                *ngFor="
                                    let categoryInd of rolesInd['category'];
                                    let k = index
                                "
                            >
                                <div class="u-view">
                                    <b class="view-access">{{
                                        categoryInd.privileges
                                    }}</b>
                                </div>

                                <ng-container
                                    *ngFor="
                                        let categoryRoleInd of categoryInd[
                                            'roles'
                                        ]
                                    "
                                >
                                    <p style="white-space:pre">
                                        <ng-container>
                                            <img
                                                src="assets/images/activeRight.png"
                                                class="w-20"
                                            />
                                            {{ categoryRoleInd | translate }}
                                        </ng-container>
                                    </p>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="ml-4">
                    <img
                        src="./assets/images/arrow_next.png"
                        class="cursor-pointer navImgNext nextprev-25"
                        aria-hidden="true"
                        (click)="nextPrevPage('n')"
                        *ngIf="totalPages > 1 && pageCount != totalPages"
                    />
                    <img
                        src="./assets/images/arrowprev.png"
                        class="cursor-pointer navImg1 nextprev-35"
                        *ngIf="totalPages > 1 && pageCount == totalPages"
                    />
                </div>
            </div>

            <div class="form-group col-md-12 col-lg-12 pr-0">
                <button
                    class="btn btn-main btnSmall smallBtn float-right"
                    (click)="goToUsers()"
                >
                    {{ "done" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="modal-card" *ngIf="pageType == 'showSecondaryDepartments'">
        <div class="modal-header p-0">
            <h5 class="semi-bold-blue">
                {{"secondaryDepartments" | translate}}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="closeAlertPopup()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-sub-heading title="{{'globalSetupDept' | translate}}"></app-sub-heading>

        <div class="pl-0 mt-3">
            <div class="row col-12">
                <ul class="list-unstyled user-steps">
                    <li>
                        <!-- <span class="semi-bold-blue">Primary Department : </span> -->
                        <span class="semi-bold-blue">{{
                            nissanSecondaryDeptData?.userData
                                ?.primaryDepartmentName
                        }}</span>
                    </li>
                    <li>
                        <span class="semi-bold-blue  mb-3"
                            >{{"secondaryDepartments" | translate}} :
                        </span>
                        <ng-container
                            *ngFor="
                                let data of nissanSecondaryDeptData?.userData
                                    ?.secondaryDepartments
                            "
                        >
                            <ul class="list-unstyled listDiv">
                                <li
                                    class="regular-font d-flex align-items-center mt-2"
                                >
                                    <span class="blue-circle mr-2"></span>
                                    {{ data.name }}
                                </li>
                            </ul>
                        </ng-container>
                    </li>
                </ul>
            </div>

            <button
                class="btn btn-main btnSmall smallBtn mt-2 float-right"
                id="next"
                name="next"
                (click)="closeAlertPopup()"
            >
                {{"done" | translate}}
            </button>
        </div>
    </div>

    <div  *ngIf="pageType == 'disableUser'">
        <div class="modal-header">
            <h6 class="modal-title">
                <strong>{{ "Disable User" | translate }}</strong>
            </h6>
            <button type="button" class="close" aria-label="Close" (click)="CloseAlert()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body p-3" style="text-align: left !important;">
            <form [formGroup]="disableUserForm" (ngSubmit)="onSubmitDisableUser()">
                <div>
                    <p class="fontDisable mt-2">Do you also want to delete all ongoing and future space bookings (flexible and permanent bookings) for "{{data?.userName}}" ?</p>
                    <div class="ml-4">
                    <mat-radio-group class="custom-radio-group" [(ngModel)]="selectDisableUser"
                        formControlName="disableUsers">
                        <mat-radio-button [value]="1">{{ 'Yes' | translate }}</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="2" class="mt-2">{{ 'No' | translate }}</mat-radio-button>
 
                    </mat-radio-group>
                     </div>
                    <!-- <p>{{ 'allbookingsassociated' | translate }}</p>
                        <p>{{ 'allbookingsdeleted' | translate }}</p>
                        <p>{{ 'allbookingsmadebothdeleted' | translate }}</p> -->
                </div>
                <div class="text-center mt-3" *ngIf="selectDisableUser!=null">
                    <button class="btn btn-main btnSmall smallBtn  float-right">
                        {{ "Disable user" | translate }}
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 m-2"></div>
    </div>
</div>
<div class="pr-3" *ngIf="pageType == 'confirmForSettingTenant'">
    <div class="modal-header p-0 ">
        <h5 class="semi-bold-blue">
            {{ "alert" | translate }}
        </h5>
    </div>
  
    <div class="modal-body align-left pr-3">
    
        <div class="row mt-3 " >
            <p class="fontColor pl-3 mb-1 " *ngIf="!tenantSettingsFalseToTrue">
                Existing users will remain assigned to all locations, and you can edit their location access in the People tab. 
            </p>
            <p class="fontColor pl-3 mb-1 " *ngIf="tenantSettingsFalseToTrue">
                You are attempting to map {{data["totalUserCount"]}} onboarded users to all {{ data["siteCount"]}} locations.
            </p>
            <p class="fontColor pl-3 mb-1 " *ngIf="tenantSettingsFalseToTrue">
                 Any user unmapped to location/(s) will receive access credentials via the onboarding email. 
            </p>
        </div>
      
        <div class="row " *ngIf="!tenantSettingsFalseToTrue">
            <p class="fontColor pl-3 ">
                New users need to be mapped to specific locations.            </p>
        </div>
        <div class="row mt-2">
            <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
                <p
                class="fontColorSemi "
                    style="text-align: justify;"
                >
                Are you sure you want to proceed?
            </p>
                </div>
                </div>
        <div class="row col-12 d-inline-block">
            <button
                class="btn btn-main  pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                type="button"
                (click)="alertYes()"
            >
                {{ "yes" | translate }}
            </button>
            <button
                class="btn btn-main btn-white pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                type="button"
                (click)="alertNo()"
            >
                {{ "no" | translate }}
            </button>
        </div>
    </div>
  </div>
