import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-delete-tag-modal',
  templateUrl: './delete-tag-modal.component.html',
  styleUrls: ['./delete-tag-modal.component.scss']
})
export class DeleteTagModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteTagModalComponent>) { }

  ngOnInit() {
  }

  closePopup() {
    this.dialogRef.close();
  }

  submit(type){
    if(type == 'all'){
      this.dialogRef.close({event: '',data: 'allDesks'});
    } else {
      this.dialogRef.close({event: '',data: 'selectDesks'});
    }
  }
}
