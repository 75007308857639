<div>
    <mat-tab-group style="background-color: #fff"  *ngIf="data.type == 'addUserRole' || data.type == 'editUserRole'" >
        <mat-tab label="Details" >
            <div class="col-sm-12" *ngIf="data.type == 'addUserRole' || data.type == 'editUserRole'">
                <div class="modal-header">
                    <h4 class="modal-title" >
                        {{ "editAUser" | translate }}
                    </h4>
                    <button id="close_btn"
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="onCloseRoleModal()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                    <form class="form-group"  
                    (ngSubmit) = "addEditUserRole(data.type,f.valid)"
                    name="form"
                    #f="ngForm"
                    novalidate>
                        <div class="form-row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-6">
                            <label class="">{{
                                "firstName" | translate
                            }}*</label>
                            <div class="input-group">
                                <input id="first_name" class="form-control" placeholder="{{'firstName' | translate}}" [(ngModel)]="createUser.firstName" name="firstName" #fName="ngModel"  required>
                                <span class="invalid-feedback" *ngIf="!fName.valid">{{'pleaseEnterFirstName' | translate}}</span>
                            </div>
                    </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-6">
                            <label class="">{{
                                "lastName" | translate
                            }}*</label>
                            <div class="input-group">
                                <input id="last_name" class="form-control" [(ngModel)]="createUser.lastName" placeholder="{{'lastName' | translate}}" #lName="ngModel" name="lastName" required>
                                <span class="invalid-feedback" *ngIf="!lName.valid">{{'pleaseEnterLastName' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-6">
                            <label class="">{{
                                "email" | translate
                            }}<span *ngIf="!isEmailEnabledCheck">*</span></label>
                            <div class="input-group" *ngIf="!isEmailEnabledCheck">
                                <input id="email_id" class="form-control"  [(ngModel)]="createUser.userEmail" name="userEmail" placeholder="{{'email' | translate}}" #email="ngModel" email required >
                                <span class="invalid-feedback" *ngIf="!email.valid">{{'pleaseEnterValidEmailId' | translate}}</span>
                            </div>
                            <div class="input-group" *ngIf="isEmailEnabledCheck">
                                <input id="email_id" class="form-control"  [(ngModel)]="createUser.userEmail" name="userEmail" placeholder="{{'email' | translate}}" (keyup)="ValidateEmail($event.target.value)">
                            </div>
                            <div class="form-control-feedback">
                                <p *ngIf="showInvalid" class="text-danger">Please enter valid mail</p>
                            </div>
                        </div>
                        <div class="form-group col-lg-2 col-md-2 col-sm-6">
                            <label class="">{{
                                "countryCode" | translate
                            }}*</label>
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select [(ngModel)]="createUser.countryCode" name="countryCode" placeholder="{{'select' | translate}}" id="country_code"
                                    required #countryCode="ngModel">
                                      <mat-option *ngFor="let countryCode of countryCodeList" [value]="countryCode.countryCode">
                                        {{ countryCode.countryCode }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                <span class="invalid-feedback" *ngIf="!countryCode.valid">{{'pleaseEnterCountryCode' | translate}}</span>
                        </div>
                        </div>
                        
                        <div class="form-group col-lg-6 col-md-6 col-sm-6">
                            <label class="">{{
                                "phoneNumber" | translate
                            }}*</label>
                            <div class="input-group">
                                <input id="phone_number" class="form-control" type="text"  [(ngModel)]="createUser.userPhoneno" name="userPhoneno" placeholder="{{'phoneNo' | translate}}" required #phoneNo="ngModel">
                                <span class="invalid-feedback" *ngIf="!phoneNo.valid">{{'pleaseEnterValidPhoneNo' | translate}}</span>
                        </div>
                    </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-6">
                            <label class="">{{
                                "tenant" | translate
                            }}</label>
                            <div class="input-group">
                                <mat-form-field appearance="fill">
                                    <mat-select 
                                    [(ngModel)]="createUser.tenantId" 
                                    name="tenantUser" 
                                    #tenantUsers="ngModel"
                                    placeholder="{{'select' | translate}}" 
                                    id="ems_name"
                                    [selectValidator]="tenantUserList" 
                                    (click)="selectedTenant()"
                                    required
                                    >
                                    <mat-option *ngFor="let ems of tenantUserList" [value]="ems.tenant_id">
                                        {{ ems.name }}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngIf="data.type != 'addUserRole'">
                                    <i class="fa fa-info infoErr" *ngIf="!checkTenantUser()" title="Tenant got disabled"></i>
                                  </ng-container>
                                <!-- <div
                                    *ngIf="submitted && f.tenantUsers.errors"
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="f.tenantUsers.errors.required">
                                        {{ "pleaseentertenant" | translate }}
                                    </div>
                                    <div *ngIf="f.tenantUsers.errors.maxlength">
                                        {{ "maxLengthError" | translate }}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                            <button id="add_more_btn"
                                class="btn btn-primary btn-main pull-right"
                                (click)="addNewChoice()">
                              <i class="fa fa-plus"></i>&nbsp;{{'addMore' | translate}}
                            </button>
                            </div>
                        <div class="d-flex" *ngFor="let roleOption of createUser.roleOptions; let idx = index">
                        <div class="form-group col-lg-5 col-md-5 col-sm-5">
                            <label class="">{{
                                "roles" | translate
                            }}*</label>
                            <div class="input-group">
                            <mat-form-field appearance="fill">
                                <mat-select #rOles="ngModel" [(ngModel)]="roleOption.userRoles" name="userRoles-{{idx}}" placeholder="{{'select' | translate}}" id="role_name"
                                required >{{roleOption[idx] | json}}
                                  <mat-option *ngFor="let roles of roleList" [value]="roles.id">
                                    {{ roles.name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            
                              <!-- {{f.value | json}}
                            <div *ngIf="submitted && f.rOles.errors"
                                class="invalid-feedback">
                                <div *ngIf="f.rOles.errors.required">
                                    {{ "pleaseEnterRole" | translate }}
                                </div>
                                <div *ngIf="f.rOles.errors.maxlength">
                                    {{ "maxLengthError" | translate }}
                                </div>
                            </div> -->
                            </div>
                        </div>
                        <div class="form-group col-lg-5 col-md-5 col-sm-5">
                            <label class="">{{
                                "zoneOrlocation" | translate
                            }}*</label>
                            <div class="input-group">
                            <mat-form-field>
                                <mat-select  multiple id="role_option_{{idx}}" [(ngModel)]="roleOption.userZones" name="userZones-{{idx}}" #userRoles="ngModel" required>
                                     <app-select-check-all 
                                    [model]="userRoles" 
                                    [values]="zoneListId">
                                    </app-select-check-all>  
                                  <mat-option  [value]="zone.id" *ngFor="let zone of zoneList"> {{ zone.name }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            <!-- <div
                                *ngIf="submitted && f.userRoles.errors"
                                class="invalid-feedback">
                                <div *ngIf="f.userRoles.errors.required">
                                    {{ "pleaseEnterZone" | translate }}
                                </div>
                                <div *ngIf="f.userRoles.errors.maxlength">
                                    {{ "maxLengthError" | translate }}
                                </div>
                            </div> -->
                            </div>
                        </div>
                        <div class="form-group col-lg-1 col-md-1 col-sm-1 mt-4">
                            <span (click)="removeUserRole(idx)"><i class="fa fa-trash"></i></span>
                        </div>
                        </div>
                        <div class="form-group col-lg-2 col-md-2 col-sm-12"></div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12 br-1"></div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                            <button type="submit" id="update_btn"
                                class="btn btn-primary btn-main btnSmall mr-1 pull-right">
                                {{'update' | translate}}
                            </button>
                            <button type="reset" id="reset_data_btn"
                                class="btn btn-default btn-main btnSmall mr-1 pull-right"
                                (click)="onCloseRoleModal()">
                                {{'cancel' | translate}}
                            </button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
                </div>
                </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Additional Details"  *ngIf="data.type != 'addUserRole'">
            <div class="row"  style="width: 900px !important">
                <div class="col-sm-12">
                    <div class="modal-header">
                        <h4 class="modal-title" >
                            {{ "additionalDetails" | translate }}
                        </h4>
                        <button id="close_btn" type="button" class="close" aria-label="Close" (click)="onCloseRoleModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                <div class="modal-body" style="height: auto !important;">
                    <!-- <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12" *ngFor=' let item of DataList;let j="index" '  style="margin-top: 50px;" >
                            <div *ngIf="item.isActive">
                                <b>Q.</b><span><b>{{item.metadata.question}}</b></span>
                 
                                <div class='row' *ngIf = 'item.fieldType == "RADIO"'  >
                                 <div class='col-md-8'  >
                                   <mat-radio-group class="example-radio-group" [(ngModel)]="item.metadata.defaultValue">
                                     <mat-radio-button class="example-radio-button"  *ngFor="let ans of item?.answerOptions " [value]="ans.value" style="margin-left:10px">
                                       <b>{{ans.ques}} </b>   
                                     </mat-radio-button>
                                   </mat-radio-group> 
                 
                                 </div>
                                   
                                </div>
                                 
                 
                                 <div *ngIf = 'item.fieldType == "CHECKBOX"' class='row'>
                                       <div class='col-md-8' >
                                         <section  *ngIf ="!!item?.answerOptions"  style="margin-right: 10px;" >  
                                           <mat-checkbox *ngFor="let ans of item?.answerOptions"  [checked]="ans.checked"  [(ngModel)]="ans.checked"   ngDefaultControl >
                                                     <b>{{ans.ques}}</b>
                                           </mat-checkbox> 
                                      </section>
                                       </div>    
                                  </div>
                                  <div *ngIf = 'item.fieldType == "TEXT"' class='row'>
                                   <div class='col-md-4'>
                                       <input type ="text" [(ngModel)]="item.metadata.defaultValue"  [value]="item.metadata.defaultValue">
                                 
                                   </div>    
                                 </div>
                               
                                  <div *ngIf = 'item.fieldType == "DROPDOWN"' class='row'>
                                   <div class='col-md-4' >
                                     <mat-select [(ngModel)]="item.metadata.defaultValue">
                                       <mat-option    *ngFor="let ans of item?.answerOptions"  [value]="ans.value">{{ans.ques}}</mat-option>
                                     </mat-select> 
                              
                                   </div>    
                              </div>
                              </div>
                        </div>
                    </div> -->
                    <div class="row" >
                        <div class="col-12" >
                            <ng-container *ngIf="!!DataList && DataList.length;else noQuestion" >
                                <form class="form row" #frm="ngForm">
                                    <div class="form-group col-md-6 col-lg-6 col-sm-12" *ngFor="let item of DataList; let i = index">
                                        <ng-container *ngIf="item?.isActive">
                                            <ng-container *ngIf="item?.fieldType == 'TEXT'">
                                                <ng-container *ngIf="!!item?.isMandatory;else textNonReq">
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <input #textinput="ngModel"  type="text" name="{{item?.metadata.question}}" [(ngModel)]="item.metadata.defaultValue"  [value]="item.metadata.defaultValue" class="form-control" autocomplete="off" required [ngClass]="{
                                                        'is-invalid': (textinput.invalid && !textinput.pristine)
                                                    }"/>
                                                </ng-container>
                                                <ng-template #textNonReq>
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <input  type="text" name="{{item?.metadata.question}}" [(ngModel)]="item.metadata.defaultValue"  [value]="item.metadata.defaultValue" class="form-control" autocomplete="off" />
                                                </ng-template>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="item?.fieldType == 'NUMBER' ">
                                                <ng-container *ngIf="!!item?.isMandatory;else numberNonReq">
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <input #numberInput="ngModel" type="number" name="{{item?.metadata.question}}" [(ngModel)]="item.answer" class="form-control" autocomplete="off" required [ngClass]="{
                                                        'is-invalid': (numberInput.invalid && !numberInput.pristine)
                                                    }"/>
                                                </ng-container>
                                                <ng-template #numberNonReq>
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <input  type="number" name="{{item?.metadata.question}}" [(ngModel)]="item.answer" class="form-control" autocomplete="off" />
                                                </ng-template>
                                            </ng-container> -->
                                            <ng-container *ngIf="item?.fieldType == 'RADIO' ">
                                                <ng-container *ngIf="!!item?.isMandatory;else radioNonReq">
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <br/>
                                                    <mat-radio-group #radioInput="ngModel" name="{{item?.metadata.question}}"  aria-label="Select an option" required [(ngModel)]="item.metadata.defaultValue" [ngClass]="{
                                                        'is-invalid': (radioInput.invalid && !radioInput.pristine)
                                                    }">
                                                    <mat-radio-button class="example-radio-button" name="{{ item?.metadata.question +'_'+i+'_'+k }}"  *ngFor="let ans of item?.answerOptions; let k= index " [value]="ans.value"  [ngStyle]="{'margin-left': k!=0 ? '10px' : '0px'}">
                                                        <b>{{ans.ques}} </b>   
                                                      </mat-radio-button>
                                                    </mat-radio-group>
                                                </ng-container>
                                                <ng-template #radioNonReq>
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <br/>
                                                    <mat-radio-group name="{{item?.metadata.question}}" aria-label="Select an option" [(ngModel)]="item.metadata.defaultValue">
                                                        <mat-radio-button class="example-radio-button" name="{{ item?.metadata.question +'_'+i+'_'+k }}"  *ngFor="let ans of item?.answerOptions; let k= index " [value]="ans.value" [ngStyle]="{'margin-left': k!=0 ? '10px' : '0px'}">
                                                            <b>{{ans.ques}} </b>   
                                                          </mat-radio-button>
                                                    </mat-radio-group>
                                                </ng-template>
                                            </ng-container>
                                            <ng-container *ngIf="item?.fieldType == 'CHECKBOX' ">
                                                <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                <br/>
                                                <ng-container *ngIf="!!item?.isMandatory;else checkboxNonReq">
                                                        <mat-checkbox *ngFor="let ans of item?.answerOptions; let k= index" name="{{ item?.metadata.question +'_'+i+'_'+k }}"  [checked]="ans.checked"  [(ngModel)]="ans.checked"   ngDefaultControl [ngStyle]="{'margin-left': k!=0 ? '10px' : '0px'}">
                                                            <b>{{ans.ques}}</b>
                                                  </mat-checkbox> 
                                                </ng-container>
                                                <ng-template #checkboxNonReq>
                                                        <mat-checkbox *ngFor="let ans of item?.answerOptions; let k= index" name="{{ item?.metadata.question +'_'+i+'_'+k }}" [checked]="ans.checked"  [(ngModel)]="ans.checked"   ngDefaultControl  [ngStyle]="{'margin-left': k!=0 ? '10px' : '0px'}">
                                                            <b>{{ans.ques}}</b>
                                                  </mat-checkbox>
                                                </ng-template>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="item?.fieldType == 'MULTI_SELECT_DROPDOWN'">
                                                <ng-container *ngIf="!!item?.isMandatory;else multiSelectNonReq">
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <mat-select
                                                        class="form-control"
                                                        #multiDropDownInpu="ngModel"
                                                        name="{{ item?.metadata.question +'_'+i }}"
                                                        [(ngModel)]="item.metadata.defaultValue"
                                                        multiple
                                                        required
                                                        [ngClass]="{ 'is-invalid': (multiDropDownInpu.invalid && !multiDropDownInpu.pristine) }"
                                                    >
                                                    <mat-option    *ngFor="let ans of item?.answerOptions"  [value]="ans.value">{{ans.ques}}</mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-template #multiSelectNonReq>
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <mat-select
                                                        class="form-control"
                                                        name="{{ item?.metadata.question +'_'+i }}"
                                                        [(ngModel)]="item.metadata.defaultValue"
                                                        multiple
                                                    >
                                                    <mat-option    *ngFor="let ans of item?.answerOptions"  [value]="ans.value">{{ans.ques}}</mat-option>
                                                    </mat-select>
                                                </ng-template>
                                            </ng-container> -->
                                            <ng-container *ngIf="item?.fieldType == 'DROPDOWN' ">
                                                <ng-container *ngIf="!!item?.isMandatory;else selectNonReq">
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <mat-select
                                                        class="form-control"
                                                        name="{{ item?.metadata.question +'_'+i }}"
                                                        [(ngModel)]="item.metadata.defaultValue"
                                                        #singleDropDown = "ngModel"
                                                        required
                                                        [ngClass]="{
                                                            'is-invalid': (singleDropDown.invalid && !singleDropDown.pristine)
                                                        }"
                                                    >
                                                    <mat-option    *ngFor="let ans of item?.answerOptions"  [value]="ans.value">{{ans.ques}}</mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-template #selectNonReq>
                                                    <label class="text-uppercase font-weight-bold">{{item?.metadata.question}}</label>
                                                    <mat-select 
                                                        class="form-control"
                                                        name="{{ item?.metadata.question +'_'+i }}"
                                                        [(ngModel)]="item.metadata.defaultValue"
                                                    >
                                                    <mat-option    *ngFor="let ans of item?.answerOptions"  [value]="ans.value">{{ans.ques}}</mat-option>
                                                    </mat-select>
                                                </ng-template>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 mt-5">
                                        <button
                                            class="pull-left btn btn-warning"
                                            type="button"
                                            (click)="onCloseRoleModal()"
                                            >
                                              {{'cancel' | translate}}
                                            </button>
                                        <button  type="button " class = "btn btn-primary float-right" (click)="updateAnsers(frm)" [disabled]="frm.form.invalid"> 
                                            {{!!additionalModuleId ? ('update' | translate) : 'save' | translate}} 
                                       </button>
                                    </div>
                                </form>
                            </ng-container>
                            <ng-template #noQuestion>
                                <div class="text-center">
                                    {{'noData' | translate}}
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    </div>
