<div>
        <ng-container *ngIf="printStepper$ | async as printStepper">
            <div class="container" *ngIf="printStepper === 1">
                <div class="modal-header">
                    <h6 class="modal-title font-weight-bold">
                        {{ 'convertPdf' | translate }}
                    </h6>
                    <button id="close_modal"
                        type="button"
                        class="close btn-circle"
                        aria-label="Close"
                        (click)="closeModal()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mb-4">
                    <div class="row">
                        <div class="col-12 font-weight-bold text-left">
                            {{'pdfConfirmText' | translate}}
                        </div>
                        <div class="col-12 mt-3">
                            <button type="button" id="cancel_pdf_btn" class="btn btn-default btn-sm float-left border border-secondary" (click)="closeModal()"> {{'cancel' | translate }} </button>
                            <button type="button" id="convert_btn" class="btn btn-main btn-sm float-right px-4"  (click)="getPdf()"> {{'convertNow' | translate }} </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" *ngIf="printStepper === 2">
                <div class="modal-header">
                    <h6 class="modal-title font-weight-bold">
                        {{ 'convertPdf' | translate }}
                    </h6>
                    <button id="dismis_modal"
                        type="button"
                        class="close btn-circle"
                        aria-label="Close"
                        (click)="closeModal()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mb-4">
                    <div class="row p-0 m-0">
                        <div class="container-fluid  row justify-content-center align-items-center p-0 m-0">
                            <div class="col-md-12 col-lg-12 col-sm-12 text-center ">
                                <img src="assets/images/pdf.svg" class="img-responsive"/>
                            </div>
                            <div class="col-12 font-weight-bold text-center mt-4 h6 text-muted">
                                {{'pdfConvertedMsg' | translate}}
                            </div>
                            <div class="col-12 font-weight-bold text-center mt-2">
                                <a href="javascript:void(0)" id="view_pdf" (click)="viewPdf()" class="viewPdf"><b>{{'viewPdf' | translate}}</b></a>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <button id="download_now_btn" type="button" class="btn btn-default btn-sm float-left border border-secondary" (click)="downloadPdf()"> {{'download' | translate }} </button>
                            <button id="share_now_btn" type="button" class="btn btn-main btn-sm float-right px-4"  (click)="sharePdfStep()"> {{'shareNow' | translate }} </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" *ngIf="printStepper === 3">
                <div class="modal-header">
                    <h6 class="modal-title font-weight-bold">
                        {{ 'sharePdfWith' | translate }}
                    </h6>
                    <button id="close_modal_view"
                        type="button"
                        class="close btn-circle"
                        aria-label="Close"
                        (click)="closeModal()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mb-4">
                    <div class="row p-0 m-0">
                        <div class="container-fluid  row justify-content-center align-items-center p-0 m-0">
                            <div class="col-md-12 col-lg-12 col-sm-12">
                                <mat-form-field class="w-100 example-chip-list">
                                    <mat-chip-list #chipList aria-label="User selection">
                                      <mat-chip id="user_selection"
                                        *ngFor="let item of searchUserList; let i = index"
                                        [selectable]="selectable"
                                        [removable]="removable"
                                        (removed)="onRemove(item)">
                                        <div  style="width: auto;height: auto;border-radius: 50px;">
                                            <span *ngIf="!!item.imgUrl" style="width: 25px;height: 25px;border-radius: 100%; float: left;"><img [src]="item.imgUrl" style="width: 25px;height: 25px;border-radius: 100%;"></span>
                                            <span class="text-uppercase btn btn-circle legend-circle" style="float: left;" *ngIf="!item.imgUrl">{{item.legend}}</span>
                                        <span class="mat-chip-name">
                                            {{item.display}}
                                        </span>
                                        <!-- <span (click)="input.removeItem(item, i)"  class="ng2-tag__remove-button btn btn-sm btn-circle-cross">&times;</span> -->
                                    </div>
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                      </mat-chip>
                                      <input id="name_input"
                                        placeholder="{{'typeNameHere' | translate}}"
                                        #Input
                                        [formControl]="searchInpCtrl"
                                        [matAutocomplete]="auto"
                                        [matChipInputFor]="chipList"
                                        (input) = "requestAutocompleteItems(Input.value)"
                                        >
                                        <i class="fa fa-spinner loadingspan" *ngIf="isloading" aria-hidden="true"></i>
                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                      <mat-option *ngFor="let item of userList$ | async" [value]="item">
                                        <div  style="width: auto;height: auto;border-radius: 50px;">
                                            <span *ngIf="!!item.imgUrl"><img [src]="item.imgUrl" style="width: 25px;height: 25px;border-radius: 100%;"></span>
                                        <span class="text-uppercase  btn btn-circle legend-circle" *ngIf="!item.imgUrl" >{{item.legend}}</span>
                                            <span  class="" style="margin-top: 9px;position: relative;">
                                                {{item.display}}
                                            </span>
                                        </div>
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>
                            </div>
                            <!-- <div class="col-md-12 col-lg-12 col-sm-12">
                                <tag-input #input 
                                [(ngModel)]="searchUserList"
                                placeholder="{{'typeNameHere' | translate}}"
                                [onTextChangeDebounce]="500"
                                secondaryPlaceholder=" {{'typeNameHere' | translate}}"
                                [onlyFromAutocomplete]="true"
                                (onTextChange)="requestAutocompleteItems($event)"
                                (onAdd)="onAdd($event)"
                                (onRemove)="onRemove($event)"
                                class="tag-user"
                                >
                                <ng-template item-template let-item="item" let-index="index" zIndex="10000">
                                        <div  style="width: auto;height: auto;border-radius: 50px;">
                                            <span *ngIf="!!item.imgUrl"><img [src]="item.imgUrl" style="width: 25px;height: 25px;border-radius: 100%;"></span>
                                            <span class="text-uppercase btn btn-circle legend-circle" *ngIf="!item.imgUrl">{{item.legend}}</span>
                                        <span  style="margin-top: 9px;position: relative;">
                                            {{item.display}}
                                        </span>
                                        <span (click)="input.removeItem(item, index)"  class="ng2-tag__remove-button btn btn-sm btn-circle-cross">&times;</span>
                                    </div>
                                </ng-template>
                                    <tag-input-dropdown 
                                    [showDropdownIfEmpty]="true"
                                    [autocompleteObservable]="userList$"
                                    zIndex="10000">
                                        <ng-template item-template let-item="item" let-index="index" zIndex="10000">
                                            <div  style="width: auto;height: auto;border-radius: 50px;">
                                                <span *ngIf="!!item.imgUrl"><img [src]="item.imgUrl" style="width: 25px;height: 25px;border-radius: 100%;"></span>
                                            <span class="text-uppercase  btn btn-circle legend-circle" *ngIf="!item.imgUrl" >{{item.legend}}</span>
                                                <span  class="" style="margin-top: 9px;position: relative;">
                                                    {{item.display}}
                                                </span>
                                            </div>
                                        </ng-template>
                                    </tag-input-dropdown>
                                </tag-input>
                            </div> -->
                            <div class="col-md-12 col-lg-12 col-sm-12">
                                <div class="form-group">
                                  <label for=""></label>
                                  <textarea type="text" [(ngModel)]="userMsg" name="user-msg" id="userMsg" class="form-control" placeholder="{{'typeMsgHere' | translate}}" aria-describedby="helpMsgId" rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <button id="cancel_btn" type="button" class="btn btn-default btn-sm float-left border border-secondary" (click)="closeModal()"> {{'cancel' | translate }} </button>
                            <button id="send_btn" type="button" class="btn btn-main btn-sm float-right px-4"  (click)="sharePdf()"> {{'send' | translate }} </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" *ngIf="printStepper === 4">
                <div class="modal-header">
                    <h6 class="modal-title font-weight-bold">
                        {{ 'sharePdfWith' | translate }}
                    </h6>
                    <button id="close_btn"
                        type="button"
                        class="close btn-circle"
                        aria-label="Close"
                        (click)="closeModal()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mb-4">
                    <div class="row p-0 m-0">
                        <div class="container-fluid  row justify-content-center align-items-center p-0 m-0">
                            <div class="col-md-12 col-lg-12 col-sm-12 text-center">
                                <img src="assets/images/pdf_ok.svg" class="img-responsive"/>
                            </div>
                            <div class="col-12 font-weight-bold text-center mt-4 h6">
                                {{'sharePdfSuccess' | translate}}
                            </div>
                            <div class="col-12 mt-3 text-center">
                                <button id="ok_btn" type="button" class="btn btn-main btn-sm px-4"  (click)="closeModal()"> {{'ok' | translate }} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
</div>