import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { environment } from "../environments/environment";
import {
    Router,
    // import as RouterEvent to avoid confusion with the DOM Event
    Event as RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError,
    RouteConfigLoadStart,
    RouteConfigLoadEnd
  } from '@angular/router';
import { LoaderService } from "loaderService";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    constructor(@Inject(DOCUMENT) private _document: HTMLDocument, 
                private router: Router,
                private loaderService: LoaderService) {
        // this._document.getElementById('appFavicon').setAttribute('href', environment.FAVICON_PATH);
        let title = this._document.getElementsByTagName("title");
        if (title && title.length > 0) {
            title[0].text = "Jumpree";
        }

        this.router.events.subscribe((e : RouterEvent) => {
            this.navigationInterceptor(e);
        })
    }

    ngOnInit() {
        const targetDiv = document.getElementById("notenant");
        targetDiv.style.display = "none";

        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.loaderService.loadingOn();
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loaderService.loadingOff();
            }
        });
    }

    navigationInterceptor(event: RouterEvent): void {
        //For waiting still module loads
        if (event instanceof NavigationStart) {
          this.loaderService.loadingOn();
        }
        if (event instanceof NavigationEnd) {
            this.loaderService.loadingOff();
        }
    
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loaderService.loadingOn();
        }
        if (event instanceof NavigationError) {
            this.loaderService.loadingOff();
        }
      }
}
