import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'commonService';
import { LoaderService } from 'loaderService';
import { ToastrService } from 'ngx-toastr';
import { IntegrationService } from '../../integration.service';
import { TranslateService } from "@ngx-translate/core";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
@Component({
  selector: 'app-integration-manage-modal',
  templateUrl: './integration-manage-modal.component.html',
  styleUrls: ['./integration-manage-modal.component.scss']
})
export class IntegrationManageSuperAdminModalComponent implements OnInit {
  currentZone;
  pageType;
  spaces;
  integrations;
  selectedSpace;
  selectedSpaceName = '';
  selectedIntegration;
  buildingFloorView = [];
  spans = [];
  buildingFloorDisplayColumns = ['building', 'floor', 'onboardedEntities', 'mappedEntities', 'action'];
  emailValidationData;
  entitiesBeforeValidation = [];
  submittedEmails = false;
  validEmailsCheck = false;
  page = 0;
  size = 2000;
  totalEntities = 0;
  disableData;
  mappedEntities: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IntegrationManageSuperAdminModalComponent>,
    private integrationService: IntegrationService,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentZone = localStorage.getItem("currentZone");
    this.pageType = this.data['type'];
    if (this.pageType == 'disabledIntegration') {
      this.disableData = this.data['disableData'];
    } else if (this.pageType == 'addIntegration_buildingFloorView') {
      this.selectedSpace = this.data['spaceData']['spaceId'];
      this.selectedSpaceName = this.data['spaceData']['spaceName'];
      this.disableData = this.data['spaceData'];
      this.loaderService.loadingOn();
      this.disableMapping(this.selectedSpace, this.disableData['integrationsDto']['integrationId'], 
        'ENABLED', this.disableData['integrationsDto']['integrationsType']);
    }
  }

  getIntegrationMapping() {
    const integrationMapping = this.integrationService.getIntegrationMapping(this.currentZone, 0, 100000);
    this.loaderService.showLoaderUntilCompleted(integrationMapping).subscribe(res => {
      this.getIntegrationStructure();
      let response = res['response']['content'];
      if (!!response && response.length > 0) {
        this.spaces = res['response'];
      }
    });
  }

  getIntegrationStructure() {
    const integrationStructure = this.integrationService.getIntegrationStructure(this.currentZone);
    this.loaderService.showLoaderUntilCompleted(integrationStructure).subscribe(res => {
      let response = res['response'];
      if (!!response && response.length > 0) {
        this.integrations = response;
      }
    });
  }

  closePopup() {
    this.data["closePopup"] = true;
    this.dialogRef.close(this.data);
  }

  saveMapping(spaceId, integrationId, status, integrationType) {
    
    let data = {
      "globalCategoryId": spaceId,
      "integrationId": integrationId,
      "integrationsType": integrationType,
      "status": status
    }
    this.integrationService.saveMappings(this.currentZone, data).subscribe(res => {
      if (!!res) {
        if (integrationType != 'OFFICE365') {
          this.loaderService.loadingOff();
          this.data["closePopup"] = false;
          this.dialogRef.close(this.data);
        }
      }
    }, err => {
      this.loaderService.loadingOff();
      this.toastrService.error(err.error.message);
    });
  }

  disableMapping(spaceId, integrationId, status, integrationType) {
    let data = {
      "gecId": spaceId,
      "integrationId": integrationId,
      "integrationsType": integrationType,
      "status": status
    }
    this.integrationService.saveMappings(this.currentZone, data).subscribe(res => {
      if (!!res) {
        this.loaderService.loadingOff();
        if(!this.data["refresh"])
        {
        this.toastrService.success(res.message);
        }
        if(this.pageType == 'addIntegration_buildingFloorView'){
          this.getBuildingAndFloorDatas(this.selectedSpace);
        } else {
          this.data["closePopup"] = false;
          this.dialogRef.close(this.data);  
        }
      }
    }, err => {
      this.loaderService.loadingOff();
      this.toastrService.error(err.error.message);
    });
  }

  getIntegrationType(integrationId): string {
    return this.integrations.filter(f => f.id == integrationId)[0]['integrationType'];
  }

  spaceChanges(event) {
    this.selectedSpaceName = this.spaces.filter(f => f.id == event.value)[0]['entityCategoryName'];
  }

  confirmMessage(msg) {
    if (msg == 'No') {
      this.pageType = 'addIntegration_map';
    } else {
      this.saveMapping(this.selectedSpace, this.selectedIntegration, 'ENABLED', this.getIntegrationType(this.selectedIntegration));
      this.getBuildingAndFloorDatas(this.selectedSpace);
      this.pageType = 'addIntegration_buildingFloorView';
    }
  }

  getBuildingAndFloorDatas(selectSpaceId) {
    const buildingAndFloor = this.integrationService.getBuildingAndFloor(this.currentZone, selectSpaceId, this.page, this.size);
    this.loaderService.showLoaderUntilCompleted(buildingAndFloor).subscribe(res => {
      if (!!res['response'] && res['response']['content']) {
        let buildingFloorDataSource = [];
        let response = res['response']['content'].filter(c => c.status != 'IDENTIFIED');
        this.totalEntities = response.length;
        this.mappedEntities = response.filter(en1 => en1.email != null).length;
        if (response && response.length > 0) {
          let floorId;
          let no = 0;
          for (var i = 0; i < response.length; i++) {
            const en = response[i];
            if (floorId == en['zoneLocationDto']['zoneId']) {
              buildingFloorDataSource[buildingFloorDataSource.length - 1]['entity'].push(en);
            } else {
              let data = {
                building: en['zoneLocationDto']['building']['name'],
                floor: en['zoneLocationDto'],
                entity: [en],
                no: no++
              };
              floorId = en['zoneLocationDto']['zoneId'];
              buildingFloorDataSource.push(data);
            }
          }
          this.buildingFloorView = buildingFloorDataSource;
          this.cacheSpan("building", (d) => d.building);
        }
      }
    });
  }

  getMappedEntities(en: any[]) {
    return en.filter(en1 => en1.email != null).length;
  }

  cacheSpan(key, accessor) {
    for (let i = 0; i < this.buildingFloorView.length;) {
      let currentValue = accessor(this.buildingFloorView[i]);
      let count = 1;
      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.buildingFloorView.length; j++) {
        if (currentValue != accessor(this.buildingFloorView[j])) {
          break;
        }
        count++;
      }
      if (!this.spans[i]) {
        this.spans[i] = {};
      }
      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      this.buildingFloorView[i]["Rowspan"] = count;
      i += count;
    }
  }

  getRowSpan(col, index, sno, rowspan) {
    if (index == 0) {
      for (let i = sno; i < this.buildingFloorView.length;) {
        let currentValue = this.buildingFloorView[i][col];
        let count = 1;
        // Iterate through the remaining rows to see how many match
        // the current value as retrieved through the accessor.
        for (let j = i + 1; j < this.buildingFloorView.length; j++) {
          if (currentValue != this.buildingFloorView[j][col]) {
            break;
          }
          count++;
        }
        if (!this.spans[i]) {
          this.spans[i] = {};
        }
        return count;
      }
    } else {
      return rowspan;
    }
  }

  rightArrow(data) {
    this.pageType = 'addIntegration_entityEmailMapping';
    this.submittedEmails = false;
    this.validEmailsCheck = false;
    this.emailValidationData = data;
    this.entitiesBeforeValidation = [];
    if (data['entity'].length > 0) {
      this.entitiesBeforeValidation = data['entity'].map(en => {
        return {
          entity: en,
          email: en.email,
          valid: true,
          floorId: undefined,
          reason: undefined
        }
      });
    }
  }

  emailValidationConfirmMessage(choice: string) {
    if (choice == 'No') {
      this.pageType = 'addIntegration_entityEmailMapping';
    } else if (choice == 'Yes') {
      let entities = this.filterValidatedEntities();
      if (entities.length > 0) {
        let data = {
          entityInfoDTOList: entities
        }
        let formData = new FormData();
        formData.append("model", JSON.stringify(data));
        const saveEntities = this.integrationService.saveEntities(this.currentZone, formData, this.selectedSpace);
        this.loaderService.showLoaderUntilCompleted(saveEntities).subscribe(res => {
          if (!!res) {
            this.toastrService.success(this.translate.instant('entitiesSuccessfullySaved'));
            this.data["closePopup"] = false;
            this.dialogRef.close(this.data);
          }
        }, err => {

        });
      } else {
        this.pageType = 'addIntegration_buildingFloorView';
      }
    }
  }

  filterValidatedEntities(): any {
    let entities = [];
    this.entitiesBeforeValidation.forEach(en => {
      if (en.valid) {
        let entity = en.entity;
        entity['email'] = en.email;
        entity['status'] = 'ENABLED';
        entities.push(entity);
      }
    });
    return entities;
  }
  emailNotExist = false;
  validateEmails() {
    let emailList = [];
    this.emailNotExist = false;
    this.entitiesBeforeValidation.map((entity, index) => {
      let emailId = entity.email;
      if (emailId && emailId !== '') {
        if (validateEmail(emailId)) {
          if (!emailList.includes(emailId)) {
            emailList.push(entity.email);
          } else {
            entity.valid = false;
            entity.reason = this.translate.instant("dplEmailID");
            this.emailNotExist = true;
            this.toastrService.error(this.translate.instant('pleaseCheckDuplicateEmailIDS'));
          }
        }
        else {
          entity.valid = false;
          entity.reason = this.translate.instant("enterValidMailID");
          // this.emailNotExist = true;
          // this.toastrService.error('Please check the invalid Emails');
        }
      } else {
        entity.valid = false;
        entity.reason = this.translate.instant("emailIDMissing");
        // emailNotExist = true;
        // this.toastrService.error('Please fill all the entities');
      }
    });
    if (this.emailNotExist) {
      return;
    }
    if(emailList.length == 0){
      this.toastrService.error(this.translate.instant('pleaseAddEmailIDS'));
      return;
    }
    const validateExternalEmailIDs = this.integrationService.externalEmailValidator(this.currentZone, this.selectedSpace, emailList);
    this.loaderService.showLoaderUntilCompleted(validateExternalEmailIDs).subscribe(res => {
      let response = res['response'];
      if (!!response) {
        this.entitiesBeforeValidation.map(entity => {
          let emailId = entity.email;
          var filteredEntity = response.filter(ef => ef.email == emailId);
          if (filteredEntity.length > 0) {
            const en = filteredEntity[0];
            entity.valid = en.valid;
            entity.reason = en.reason;
          }
        });
        this.validEmailsCheck = this.entitiesBeforeValidation.some(ef => ef.valid == false);
        if (!this.validEmailsCheck)
          this.toastrService.success(this.translate.instant('successfullyValidated'));
      }
      this.submittedEmails = true;
    }, err => {
      this.submittedEmails = false;
      this.toastrService.error(err.error.message)
    });
  }

  emailValidationDoneBtn() {
    let entities = this.filterValidatedEntities();
    let data = {
      entityInfoDTOList: entities
    }
    let formData = new FormData();
    formData.append("model", JSON.stringify(data));
    const saveEntities = this.integrationService.saveEntities(this.currentZone, formData, this.selectedSpace);
    this.loaderService.showLoaderUntilCompleted(saveEntities).subscribe(res => {
      if (!!res) {
        this.toastrService.success(this.translate.instant('entitiesSuccessfullySaved'));
        this.getBuildingAndFloorDatas(this.selectedSpace);
        this.pageType = 'addIntegration_buildingFloorView';
      }
    }, err => { });
  }

  buildingFloorMapDoneBtn() {
    this.data["closePopup"] = false;
    this.dialogRef.close(this.data);
  }

  disableIntegration(choice: string) {
    this.data["closePopup"] = false;
    this.data['choice'] = choice;
    if (choice == 'Yes') {
      this.loaderService.loadingOn();
      this.disableMapping(this.disableData['spaceId'], this.disableData['integrationsDto']['integrationId'], 
        'DISABLED', this.disableData['integrationsDto']['integrationsType']);
    } else {
      this.dialogRef.close(this.data);
    }
  }

  getIntegrationRespectiveName(integration): string {
    let result;
    switch (integration) {
      case 'OFFICE365':
        result = 'Outlook';
        break;
      case 'TEAMS':
        result = 'Teams';
        break;
      default:
        result = integration;
    }
    return result;
  }
}