import { HttpHeaders } from '@angular/common/http';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/moduleid-service";
export class CuratedServicesService {
    constructor(http, baseUrl, moduleidService) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.BASEEMSURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
    }
    saveCuratedSerivces(zoneId, data) {
        console.log(zoneId);
        let url = this.EMSURL + `curated-services/create`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getCuratedServices(zoneId, data) {
        // console.log(zoneId);
        let url;
        if (data && data.type && data.type == "SEARCH") {
            url = this.EMSURL + `curated-services/super-admin/get?search=${data.searchString}`;
        }
        else {
            url = this.EMSURL + `curated-services/super-admin/get`;
        }
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }
    getCuratedServicesGU(zoneId, data) {
        let url;
        if (data && data.type && data.type == "SEARCH") {
            url = this.EMSURL + `curated-services/general-user/get?search=${data.searchString}`;
        }
        else {
            url = this.EMSURL + `curated-services/general-user/get`;
        }
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' + zoneId +
                    ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '"}'
            })
        };
        return this.http.get(url, httpOptions); // return this.http.get<any>(url,httpOptions);
        //   return this.http
        //     .get<any>(url, {
        //       params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        //     });
    }
    saveCuratedOrder(zoneId, data) {
        let url = this.EMSURL + `curated-services/set-priority`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    updateCuratedServices(zoneId, data) {
        let url = this.EMSURL + `curated-services/update`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
}
CuratedServicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CuratedServicesService_Factory() { return new CuratedServicesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("BASE_URL"), i0.ɵɵinject(i2.ModuleidService)); }, token: CuratedServicesService, providedIn: "root" });
