import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";

import { ToastrService } from "ngx-toastr";
import { NgForOfContext } from "@angular/common";
import * as _ from "lodash";
import { PeopleTenantAdminService } from "../service/people-tenant-admin.service";
import { LoaderService } from "loaderService";

import { CommonService } from "commonService";
import { ExcelService } from "../../global-setup/services/excel.service";
import { PeopleService } from "../people.service";

@Component({
  selector: "app-event-modal",
  templateUrl: "./people-tenant-admin-modal.component.html",
  styleUrls: ["./people-tenant-admin-modal.component.scss"],
})
export class peopleTenantAdminModal implements OnInit {
  pageType;
  addType = "";
  currentZone;
  editCatName = "";
  editCatData;
  addUsersManuallyForm: FormGroup;
  incorrectDataColumns = [
    // "slNo",
    "fname",
    "lname",
    "email",
    "tenantAdmin",
    "reason",
  ];
  manualForm;
  fb = this.formBuilder;
  submittedExcel;
  listViewExcelResponse;
  incorrectData;
  correctData;
  noOfEntitiesUploaded;
  saveCorrectData;
  totalIncorrectLength;
  showIncorrectData;
  TotalNoOfEntitiesUploadedRNW = 0;
  submitted = false;

  category = "";
  categoryList = [];
  excelUploadedFile = null;
  submittedUsers=false;
  showUploadedExcelFileName = "";
  editPeopleMode=false;
  tenantIdUnique=70010;
  showExcel=false;
  onboardUserEmailVerify
  disableTenantEditForm=false
  rolesData
  constructor(
    public dialogRef: MatDialogRef<peopleTenantAdminModal>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private eventCatService: PeopleTenantAdminService,
    private toastService: ToastrService,
    private peopleService:PeopleService,
    private loader: LoaderService,
    private excelService: ExcelService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.pageType = data["pageType"];
    console.log(this.pageType)
    this.currentZone = data["currentZone"];
  }
  
  ngOnInit() {
    this.addUsersManuallyForm = this.formBuilder.group({
      employeeName: ["", [Validators.required, Validators.pattern('^[A-Za-z ]+$')]],
      lastName: ["", [ Validators.required,Validators.pattern('^[A-Za-z ]+$')]],
      email: ["", [Validators.required, Validators.email]],
      tenantAdmin:[false]
    });

  if(this.data['edit'] && this.data['pageType']=='onboardUserManualy'){
    this.editPeopleMode=true;
    
    this.editFormSetup(this.data['element']);
  
    
    }
    this.rolesData=this.data['rolesData']
    if(this.rolesData){

      this.rolesData.forEach((e)=>{ if(e.name=='Tenant Admin'){
        this.tenantIdUnique=e.id
      } })
    
  
  }
  
  }

  editFormSetup(ele){
console.log(ele['userId'], localStorage.getItem('ssadmin_userId'))
if(ele['userId']==localStorage.getItem('ssadmin_userId')){
this.disableTenantEditForm=true;
}

    let i=false;
     ele.roles.forEach((e)=>{if( e.id==this.tenantIdUnique)i=true })
    let flag=false;
    if(i){
      flag=true;
    }
    this.addUsersManuallyForm.patchValue({
      employeeName:ele.firstName,
      lastName:ele.lastName,
      email:ele.email,
      
      tenantAdmin:flag

    })
  


  }
  get f() {
    return this.addUsersManuallyForm.controls;
  }
  
  
  submitManuallyFormTenant(e){
    this.submittedUsers=true
    if (this.addUsersManuallyForm.invalid) {
    //  this.toastrService.error('Please fill all the mandatory fields')
      return;
    }
  

this.submittedUsers=false;
let roles=[];
if(this.f.tenantAdmin.value)
roles.push({"roleId":this.tenantIdUnique ,zoneIds:[this.currentZone] });
if(!this.editPeopleMode ) 
{
  roles.push({"roleId":13,zoneIds:[this.currentZone] });
}

// let i=false;
// roles.find((e)=>{if(e.roleId==13)i=true});
// if(!i)
// roles.push({"roleId":13 ,zoneIds:[this.currentZone] });

let payLoad={
  "allSiteAccess": false,
  "email": this.f.email.value,
  "firstName": this.f.employeeName.value,
  "password": null,
  "lastName": this.f.lastName.value,
  "rolesToAssign": roles,
  "phoneNum": "",
  "tenantId": localStorage.getItem("tenantId"),
  "empCode": "",
  "deptId": null,
  "departName": "",
  "grade": "MGR",
 
  "supervisorId": "",
  "autoAssign": false,
  "globalAdmin": false
}
if(!this.editPeopleMode) 
{const $onBoard= this.peopleService.onBoardUsers(payLoad,this.currentZone,false,false);
 this.loader.showLoaderUntilCompleted($onBoard).subscribe((res)=>{
  this.toastService.success('User has been successfully onboarded')
this.closePopup();
 },(e)=>{} )
console.log(payLoad)}
else{
  payLoad['id']=this.data['element']['userId']
  const $onBoard= this.peopleService.editUsers(payLoad,this.currentZone,false,false);
 this.loader.showLoaderUntilCompleted($onBoard).subscribe((res)=>{
  this.toastService.success('User has been successfully edited')
this.closePopup();
 },(e)=>{} )
console.log(payLoad)
}

  }
  
  
  selectType() {
    if (this.addType == "manual") {
      this.pageType = "verifyMail";
    } else {
      
      if(this.showExcel)
      { this.submittedExcel = true;
      if (this.showUploadedExcelFileName != "") {
        this.validateExcelOnboarding();
      }}
      else this.showExcel=true;
    }
  }
 
  validateEmail(email) {
    // Regular expression to match a basic email pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test the email against the regex
    return emailRegex.test(email);
  }
  verifyEmail(){

    const  verify$= this.peopleService.verifyEmail(this.onboardUserEmailVerify);
    this.loader.showLoaderUntilCompleted(verify$).subscribe(
     (res) => {
         if(res['response']=="User does not exist in the system"){
           this.pageType = "onboardUserManualy";
         
           // this.loadDepartment();
       
           // this.getCountryCodes();
           this.addUsersManuallyForm.patchValue({
               email:this.onboardUserEmailVerify
           })
         }
         else{
           this.toastService.error(res['response'])
         }
        
 
 
 
       },
       (error) => {
 
       }
   );
  
 }

  validateExcelOnboarding() {
    const validateCSVOnboarding$ = this.eventCatService.validateExcelOnboarding(
      this.excelUploadedFile,
      this.showUploadedExcelFileName,
      this.currentZone
    );
    this.loader
      .showLoaderUntilCompleted(validateCSVOnboarding$)

      .subscribe(
        (res) => {
          if (!!res["response"]) {
            this.listViewExcelResponse = res["response"];
            if (res["response"]["value"].length > 0) {
              this.incorrectData = [];
              this.correctData = [];
              this.saveCorrectData = [];
           
              res["response"]["value"].forEach((entity) => {
              
                if (entity.csvDto) {
                  let {    firstName,
                    lastName,
                    corporateEmail,tenantAdmin, } = entity.csvDto;
                  let uploadData = {
                 
                    firstName,
                    lastName,
                    corporateEmail,
                    tenantAdmin,
                    errorFields: entity.columns,
                    reason: entity.reason,
                  };
                  if (entity.columns.length > 0) {
                    this.incorrectData.push(uploadData);
                  } else {
                    this.correctData.push(uploadData);
                    this.saveCorrectData.push(entity.csvDto);
                  }
                 
                }
              });
              this.TotalNoOfEntitiesUploadedRNW =
                this.incorrectData.length + this.correctData.length;
              this.noOfEntitiesUploaded = this.saveCorrectData.length;
              if (this.incorrectData.length == 0) {
                this.dialogRef.updateSize("45%");
              } else {
                this.totalIncorrectLength = this.incorrectData.length;
                this.showIncorrectData = this.incorrectData.slice(0, 10);
              }
            } else {
              this.toastService.error("No Enteries were added");
              return;
            }
          }
          this.pageType = "onboardingExcelTable";
          this.dialogRef.updateSize("75%");

          // if (this.digitalVersion) {
          //   if (!this.approach || this.approach == "") {
          //     this.updateConfig('LIST');
          //   }
          // }
        },
        (error) => {
          this.commonService.openSnackBar(
            "Something went wrong!! Please make sure file is in correct format",
            ""
          );
        }
      );
  }

  closePopup() {
    
    this.dialogRef.close(this.pageType);
  }


 
  

  

  downloadViaExcelTemplate(value?) {
    const customQues$ = this.peopleService.downloadBulkUploadEntities(
      this.currentZone,false
    );

    this.loader.showLoaderUntilCompleted(customQues$).subscribe(
      (res: Blob) => {
        if (!!res) {
          const fileName = "onboardUser.xlsx";
          this.excelService.saveApiResponseToFile(res, fileName);
        }
      },
      (err) => {}
    );
  }

  uploadExcel(files: FileList) {
    var fileName = Array.from(files)
      .map((f) => f.name)
      .join(", ");
    const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (extension === "xlsx" || extension === "xls" || extension=='csv') {
      this.excelUploadedFile = files.item(0);
      this.showUploadedExcelFileName = fileName;
    } else {
      this.toastService.error("Please import valid .xlsx file.");
    }
  }
  getToolTipData(hoverData) {
    return hoverData;
  }
  reUploadEntities() {
    this.pageType = "onboarding_reUploadCSV";
    this.excelUploadedFile = undefined;
    this.showUploadedExcelFileName = undefined;
    this.dialogRef.updateSize("40%");
  }
  downloadCurrentCSVFile() {
    const customQues$ = this.peopleService.downloadBulkUploadEntities(
      this.currentZone,false,this.listViewExcelResponse
    );

    this.loader.showLoaderUntilCompleted(customQues$).subscribe(
      (res: Blob) => {
        if (!!res) {
          const fileName = "onboardUser.xlsx";
          this.excelService.saveApiResponseToFile(res, fileName);
        }
      },
      (err) => {}
    );
  }
  camelCasingToNormal(value: string): string {
    return value
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/\b\w/g, (match) => match.toUpperCase()); // capitalize the first letter
  }



  proceedToOnboard(){

    // include tenant id
    if(this.saveCorrectData.length > 0){ 
      const validateCSVOnboarding$ = this.peopleService.saveOnboardedEntitiesViaExcelTenantAdmin(
        this.currentZone,
        this.saveCorrectData,false
      );
      this.loader
        .showLoaderUntilCompleted(validateCSVOnboarding$)
       
        .subscribe(
          (res) => {
         
            this.toastService.success( this.saveCorrectData.length+ " Users has been succesfully onboarded");
            const response = res['response'];
            if(res){
              this.dialogRef.close();
            }
          },
          (err) => {
            this.toastService.error(err['error']['message']);
          }
        );
    } else {
  
      this.toastService.error("Please upload the correct file containing accurate Tenant user data.");
      
    }
  }
}
