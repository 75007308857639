<div *ngIf="pageType == 'showDialog'">
    <div class="divideSection" (click)="handleClick($event)">
        <!-- All cards of Left Section -->
        <div class="LRcss" #leftSection>
            <ng-container *ngIf="leftSectionData?.chartType =='TEXT'">
                <mat-card>
                    <mat-card-content class="d-flex align-items-center">
                        <img src="{{ leftSectionData?.iconUrl? leftSectionData?.iconUrl:bookingIconUrl }}" width="45"
                            height="45" />
                        <div class="d-flex flex-column ml-2">
                            <p class="regular-font mb-2">{{ leftSectionData?.description }}</p>
                            <h5 class="regular-semibold-font mb-0">
                                <ng-container *ngIf="leftSectionData.values">
                                    {{ leftSectionData?.values[0]}}
                                </ng-container>
                                <ng-container *ngIf="!leftSectionData.values">{{ 'N/A' }}</ng-container>
                            </h5>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="leftSectionData?.chartType=='ORDERED_LIST'">
                <mat-card class="w-100 p-0">
                    <h6 class="shadowHeader p-2 pl-3 mb-0">{{leftSectionData?.description}}</h6>
                    <div class="unorderedList-container">
                        <mat-card-content class="p-3" style="max-width: 350px; min-width: 250px;">
                            <div class="d-flex mb-4" *ngFor="let data of leftSectionData?.values; let i = index">
                                <span class="circleBtn" [style.background]="getBgColorByIndex(i)">{{i+1}}</span>
                                <div class="diplayOrderedList ml-1">
                                    <h6 class="regular-semibold-font mb-0">{{ data }}</h6>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center"
                                *ngIf="!leftSectionData.values || leftSectionData.values.length==0">
                                <div class="text-center p-3" style="max-width: 250px;">
                                    <img src="assets/images/no_data.png" class="w-50">
                                    <p class="regular-semibold-font mt-2 mb-0">{{"NoDatasAvailable" | translate}}</p>
                                </div>
                            </div>
                        </mat-card-content>
                    </div>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="leftSectionData?.chartType=='UNORDERED_LIST'">
                <div class="unorderedList-container">
                    <mat-card class="p-0">
                        <h6 class="shadowHeader p-2 pl-3 mb-0">{{leftSectionData?.description}}</h6>
                        <mat-card-content class="p-3 pb-3">
                            <div class="d-flex" *ngFor="let data of leftSectionData?.values; let i = index"
                                [ngClass]="{'mt-3': i > 0}">
                                <!-- <img src="{{ bookingIconUrl }}" width="35" height="35"/> -->
                                <span class="d-block rounded-circle user-legend">{{ getFirstLetters(data) }}</span>
                                <div class="showUserList ml-2">
                                    <p class="regular-semibold-font w-50">
                                        {{ getSplitVal(data, 0) }}
                                    </p>
                                    <p class="regular-semibold-font w-50">
                                        {{ getSplitVal(data, 1) }}
                                    </p>
                                    <!-- <span class="ml-3">{{ getSplitVal(data, 1) }}</span> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center"
                                *ngIf="!leftSectionData.values || leftSectionData.values.length==0">
                                <div class="text-center p-3" style="max-width: 250px;">
                                    <img src="assets/images/no_data.png" class="w-50">
                                    <p class="regular-semibold-font mt-2 mb-0">{{"NoDatasAvailable" | translate}}</p>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
            <ng-container *ngIf="leftSectionData?.chartType =='BAR_CHART_VERTICAL'">
                <mat-card class="w-100 h-100 p-0" style="min-width: 30vw; min-height: 20vh;">
                    <h6 class="regular-semibold-font p-2 pl-3 bottom-line">
                        {{leftSectionData?.description}}
                    </h6>
                    <ng-container *ngIf="leftSectionData?.verticalBarChart">
                        <highcharts-chart class="p-2" style="height: 22vh;" [Highcharts]="Highcharts"
                            [options]="leftSectionData?.verticalBarChart" highcharts-chart>
                        </highcharts-chart>
                    </ng-container>
                    <div class="d-flex justify-content-center"
                        *ngIf="!leftSectionData.values || leftSectionData.values.length==0">
                        <div class="text-center p-3" style="max-width: 250px;">
                            <img src="assets/images/no_data.png" class="w-50">
                            <p class="regular-semibold-font mt-2 mb-0">{{"NoDatasAvailable" | translate}}</p>
                        </div>
                    </div>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="leftSectionData?.chartType =='PIE_CHART' 
                || leftSectionData?.chartType =='COLORED_PIE_CHART'">
                <mat-card class="w-100 h-100 p-0" style="min-width: 20vw; min-height: 20vh;">
                    <h6 class="regular-semibold-font p-2 pl-3 bottom-line">
                        {{ leftSectionData?.description }}
                    </h6>
                    <ng-container *ngIf="leftSectionData.pieChart">
                        <highcharts-chart class="p-2" style="height: 250px !important;" [Highcharts]="Highcharts"
                            [options]="leftSectionData?.pieChart" highcharts-chart>
                        </highcharts-chart>
                    </ng-container>
                    <div class="d-flex justify-content-center"
                        *ngIf="!leftSectionData.values || leftSectionData.values.length==0">
                        <div class="text-center p-3" style="max-width: 250px;">
                            <img src="assets/images/no_data.png" class="w-50">
                            <p class="regular-semibold-font mt-2 mb-0">{{"NoDatasAvailable" | translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="leftSectionData.pieChart" [innerHtml]="leftSectionData['footer']"
                        class="p-2 pl-3 footerNotes alignPCenter"></div>
                </mat-card>
            </ng-container>
        </div>
        <!-- All the dotes in between -->
        <hr class="fill-dottes">
        <!-- All cards of Right Section -->
        <div class="LRcss" #rightSection>
            <ng-container *ngIf="rightSectionData?.chartType =='TABLE_VIEW'">
                <div class="table-container">
                    <table mat-table [dataSource]="rightSectionData?.values" class="example-container">
                        <ng-container *ngFor="let head of rightSectionData?.labels; let i = index">
                            <ng-container [matColumnDef]="head">
                                <th mat-header-cell *matHeaderCellDef> {{head}} </th>
                                <td mat-cell *matCellDef="let element; let j = index">
                                    <span *ngIf="i == 0" [ngStyle]="{'background-color': getBgColorByIndex(j)}"
                                        class="circle">{{element[i]}}
                                    </span>
                                    <ng-container *ngIf="i > 0">{{element[i]}}</ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="rightSectionData?.labels"></tr>
                        <tr mat-row *matRowDef="let row; columns: rightSectionData?.labels;"></tr>
                    </table>
                    <div class="d-flex justify-content-center"
                        *ngIf="!rightSectionData.values || rightSectionData.values.length==0">
                        <div class="text-center p-3" style="max-width: 250px;">
                            <img src="assets/images/no_data.png" class="w-50">
                            <p class="regular-semibold-font mt-2 mb-0">{{"NoDatasAvailable" | translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="rightSectionData.values && rightSectionData.values.length>0" [innerHtml]="footerHtml"
                        class="p-2 pl-3 footerNotes zeroLeftPadding"></div>
                </div>
            </ng-container>
            <ng-container *ngIf="rightSectionData?.chartType =='PIE_CHART'">
                <mat-card class="w-100 h-100" style="min-width: 20vw; min-height: 20vh;">
                    <h6 class="regular-semibold-font ml-2 pt-10">{{ rightSectionData?.description }}</h6>
                    <hr />
                    <highcharts-chart style="height: 250px !important;" [Highcharts]="Highcharts"
                        [options]="rightSectionData?.pieChart" highcharts-chart>
                    </highcharts-chart>
                    <div class="text-center p-3" *ngIf="!rightSectionData.values || rightSectionData.values.length==0"
                        style="max-width: 250px;">
                        <img src="assets/images/no_data.png" class="w-50">
                        <p class="regular-semibold-font mt-2 mb-0">{{"NoDatasAvailable" | translate}}</p>
                    </div>
                    <div *ngIf="rightSectionData.values && rightSectionData.values.length>0" [innerHtml]="footerHtml"
                        class="p-2 pl-3 footerNotes zeroLeftPadding"></div>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="rightSectionData?.chartType =='BAR_CHART_VERTICAL'">
                <mat-card class="w-100 h-100 p-0" style="min-width: 50vw; min-height: 50vh;">
                    <h6 class="shadowHeader p-2 pl-4">{{rightSectionData?.description}}</h6>
                    <ng-container *ngIf="rightSectionData.verticalBarChart">
                        <highcharts-chart style="height: 40vh;" [Highcharts]="Highcharts"
                            [options]="rightSectionData?.verticalBarChart" highcharts-chart>
                        </highcharts-chart>
                    </ng-container>
                    <div class="d-flex justify-content-center"
                        *ngIf="!rightSectionData.values || rightSectionData.values.length==0">
                        <div class="text-center p-3" style="max-width: 300px;">
                            <img src="assets/images/no_data.png" class="w-75">
                            <p class="regular-semibold-font mt-2 mb-0">{{"NoDatasAvailable" | translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="rightSectionData.verticalBarChart" [innerHtml]="footerHtml"
                        class="p-2 pl-3 footerNotes zeroLeftPadding"></div>
                </mat-card>
            </ng-container>
        </div>
    </div>
</div>