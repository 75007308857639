import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { environment, modulesIds } from '../../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class VMSService {

  private moduleId;
  private readonly BASE_URL;
  private readonly BASE_EMS_URL;
  private muduleActionKey;
  private healthActionKey;
  constructor(private http: HttpClient) {
    this.moduleId = 9;
    this.BASE_URL = environment.BASE_URL+'vms/';
    this.BASE_EMS_URL = environment.BASE_URL; 
     this.muduleActionKey = "EXTRA_INFORMATION_LIST";
     this.healthActionKey = "CUSTOM_CHECK_LIST"
  }
  //vms start
  getInvitations(zoneId, type, value,pageIndex,pazesize) {
    console.log('hel anoth')
    let url = this.BASE_URL + "api/fetchRecordsByQuery?type={type}&value={value}&limit="+pazesize+"&offset="+pageIndex+"&siteId=" + zoneId;
    if (value && type) {
      url = url.replace('{type}', type).replace('{value}', value);
    } else {
      url = url.replace('{type}', '').replace('{value}', '');
    } 

    const httpOptions = {
      headers: new HttpHeaders({
      "ss-header":
      `{"version":"1.0",
      "clientKey":"ADMIN_WEB_APP",
      "zoneId":${zoneId}, 
      "moduleId":9,
      "sessionId":"${localStorage.getItem("ssadmin_session")}",
      "correlationId":"${uuidv4()}"}`
      })
      };
      return this.http.get(url,httpOptions);
    // const httpOptions = {
    //   headers: new HttpHeaders({        
    //     'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":'+this.moduleId+'}'
    //   })
    // };
    // return this.http.get(this.BASE_EMS_URL+"ems/module/config", httpOptions)
    //   .map(function (response) {
    //     return response;
    // });
    // return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });

    
  }

  createInvitation(createInvite, zoneId) {
    //  const httpOptions = {
    //   headers: new HttpHeaders({        
    //     'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":'+this.moduleId+
    //     ',"sessionId":"' +
    //     localStorage.getItem("ssadmin_session") +'}'
    //   })
    // };

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //   "ss-header":
    //   `{"version":"1.0",
    //   "clientKey":"ADMIN_WEB_APP",
    //   "zoneId":${zoneId}, 
    //   "moduleId":9,
    //   "sessionId":"${localStorage.getItem("ssadmin_session")}",
    //   "correlationId":"${uuidv4()}"}`
    //   })
    //   };
      const httpOptions = {
        headers: new HttpHeaders({        
          'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId": ${zoneId}, "moduleId":9,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
        })
      };
    const endpoint = this.BASE_URL + "api/createInvitation";
    return this.http.post(endpoint, createInvite, httpOptions);
  }

  getReport(from, to, zoneId) {
    return this.http.get(this.BASE_URL+ "api/reports?" + "startTime=" + from + "&endTime=" + to, { params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  approvePhoto(data, zoneId) {
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId": ${zoneId}, "moduleId":9,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.put(this.BASE_URL + "api/approvePhoto", data, httpOptions);
  }

  getCheckOut(data,zoneId){
   
    // https://jumpreepreprod.smartenspaces.com/vms/api/verifyQRCode/v2
    // return this.http.put(this.BASE_URL + "api/verifyQRCode/v2", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.put(this.BASE_URL + "api/verifyQRCode/v2", data, httpOptions);

  }

  rejectPhoto(data, zoneId) {
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.put(this.BASE_URL + "api/rejectPhoto", data, 
    httpOptions);
  }

  approveActivation(data, zoneId) { 
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.put(this.BASE_URL + "api/activatePass", data,httpOptions);
  }

  rejectActivation(data, zoneId) {
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.put(this.BASE_URL + "api/activatePass", data, httpOptions);
  }


  searchColumnList(zoneId) {
    return this.http.get(this.BASE_URL + "messageCategory/getKeyByCategoryId?categoryId=15", { params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  public manageVisitTableSizeSource = new BehaviorSubject<number>(0);

  tableSize = this.manageVisitTableSizeSource.asObservable();
  setSize(size: number) {

  this.manageVisitTableSizeSource.next(size)
  }
  getReportData(data, zoneId):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.get(this.BASE_URL + `api/reports?startTime=${data['startTime']}&endTime=${data['endTime']}`, 
    httpOptions);
  }
  //vms end

  getAllActiveCustomCheckList(zoneId){
    //return this.http.get(this.BASE_URL + "custom-field/all-active?customKey=CUSTOM_CHECK_LIST", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    return this.http.get(this.BASE_URL + "custom-field/all?page=0&size=1000", { params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  getAllActiveIdentity(zoneId){
    return this.http.get(this.BASE_URL + "custom-field/all-active?customKey=ADDITIONAL_VMS_FIELD&page=0&size=100", { params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  getAllIdentityDetailsByInvitationId(zoneId,invitationId){
    return this.http.get(this.BASE_URL + "custom-field-metadata/all-active?moduleActionKey=ADDITIONAL_VMS_FIELD&moduleEntityId="+invitationId, { params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  getAllActiveFastTrackPassList(zoneId){
    return this.http.get(this.BASE_URL + "custom-field/all-active?customKey=FAST_TRACK_PASS", { params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }
  getAllActiveTerms(zoneId){
    return this.http.get(this.BASE_URL + "custom-field/all-active?customKey=TermsAndConditions", { params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  saveCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field/create", data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }
  updateCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  updateMetaDataCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field-metadata/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  saveMetaDataCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field-metadata/create", data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  getAllCustomCheckList(zoneId,id){
    return this.http.get(this.BASE_URL + "custom-field-metadata/all-active?moduleActionKey=CUSTOM_CHECK_LIST&moduleEntityId="+id,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  getQRcode(zoneId,floorId){
    return this.http.get(this.BASE_EMS_URL + "ems/zones/getZoneQrInfo?id="+zoneId+'&floorId='+floorId,{params: new ZoneAndModuleHttpParams(zoneId, 9) });
  }

  getConfig(zoneId, moduleId){
    //{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"sessionId":"{{sessionId}}", "moduleId":102}
     
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"enterpriseId":"1","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.get(this.BASE_EMS_URL+"ems/module/config", httpOptions)
      .map(function (response) {
        return response;
    });
  }

  updateConfig(zoneId,moduleId,data){

    let datas = {
      "config": JSON.stringify(data),
      "moduleId": 9,
      "zoneId": zoneId
    }
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"enterpriseId":"1","sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.post(this.BASE_EMS_URL+"ems/module/updateConfig?generate=true",datas,httpOptions);
  }

  getAllQusetonsForVistors(zoneId,id){
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"correlationId":"${uuidv4()}"}`
      })
    };

    return this.http.get(this.BASE_URL + "custom-field-metadata/all-active?moduleActionKey=USER_ADD_DETAILS&moduleEntityId="+id,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });

 
  }

  allfieldTypes(zoneId){ 
    
    return this.http.get(this.BASE_URL + "custom-field/get-all-fieldtype", { params: new ZoneAndModuleHttpParams(zoneId, 9) });

  };
  createAdditionalDetails(zoneId,data){
 
    return this.http.post(this.BASE_URL + "custom-field/create/v2", data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }

  additionalDetailslist(zoneId){
    // ems/custom-field/all-custom-key?customKey=USER_ADD_DETAILS ROHIT_TEST_NEXT
    // return this.http.get(this.BASE_URL + custom-field/all-custom-key?customKey=EXTRA_INFORMATION_LIST&page=0&size=1000", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });

    return this.http.get(this.BASE_URL + `custom-field/all-custom-key?customKey=${this.muduleActionKey}&page=0&size=1000`, { params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }

  updateAdditionalDetails(zoneId,data){ 
    return this.http.post(this.BASE_URL + "custom-field/update/v2", data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }

  hyperLinkDetailsList(zoneId){

    return this.http.get(this.BASE_URL + `custom-field/all-custom-key?customKey=POST_APPROVAL_HYPERLINK&page=0&size=1000`, 
    { params: new ZoneAndModuleHttpParams(zoneId, 9) });
 
  }

  savehyperLinkDetailsList(zoneId,data){

    return this.http.post(this.BASE_URL + `custom-field/create/v2`, data,
    { params: new ZoneAndModuleHttpParams(zoneId, 9) });
 
  }


  updatehyperLinkDetailsList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field/update/v2", 
    data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }

  // saveCustomCheckListHeading(zoneId,data){
  //   return this.http.post(this.BASE_URL + "custom-field/create/v2", 
  //   data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  // }
  
  additionalDetailslistanswers(zoneId,id){
    // ems/custom-field/all-custom-key?customKey=USER_ADD_DETAILS
    // =${data.pageSize}&page=${data.pageIndex}`
    // return this.http.get(this.BASE_URL + "custom-field-metadata/get-answer?moduleActionKey=EXTRA_INFORMATION_LIST&moduleEntityId=1162&page=0&size=1000", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
     return this.http.get(this.BASE_URL + `custom-field-metadata/get-answer?moduleActionKey=${this.muduleActionKey}&moduleEntityId=${id}&page=0&size=1000`, { params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }

  resend(zoneId,data){
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":9,"correlationId":"${uuidv4()}"}`
      })
    };

  return this.http.get(this.BASE_URL + `api/assessment-resend?iviId=${data['invitationVisitorInfoId']}`,httpOptions);
  }


  ///////////////////////////HEALTH DECLARTION////////////////////////////

  createHealthDetails(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field/create/v2", data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }


  additionalHealthDetailslist(zoneId){
    return this.http.get(this.BASE_URL + `custom-field/all-custom-key?customKey=${this.healthActionKey}&page=0&size=1000`, { params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }

  updateAdditionalHealthDetails(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field/update/v2", data,{ params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }


  Healthlistanswers(zoneId,id){
    return this.http.get(this.BASE_URL + `custom-field-metadata/get-answer?moduleActionKey=${this.healthActionKey}&moduleEntityId=${id}&page=0&size=1000`, { params: new ZoneAndModuleHttpParams(zoneId, 9) });

  }
}