
import { Component, Inject,Injectable,OnInit, ViewChild  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatFormFieldModule } from '@angular/material';
import { CommonService } from '../../../services/common-service.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { EmergencyService } from "../services/emergency.service";
import { EMSService } from '../../../shared/services/ems.service';
import { AlertService } from '../../../services/alert-service.service';
import { element } from 'protractor';
import { ReplaySubject, Subject } from 'rxjs';
import {debounceTime, delay, tap, filter, map, takeUntil, take} from 'rxjs/operators';

@Component({
  selector: 'app-emergency-modal',
  templateUrl: 'emergency-modal.component.html',
  styleUrls: ['./emergency-modal.component.scss']
})
export class EmergencyModalComponent {
  currentZone;
  model: any = {};
  imgFile;
  url;
  showImage: boolean = false;
  emergencyDataObj:any = {};
  zoneList:any;
  emergencyTypes:any ={};
  countryCodes:string[] = ['+91'];
  types = ['EMAIL', 'SMS'];
  emergencyDetailsObj:any = [{'type': 'EMAIL', 'contact' : ''}];
  isimageUploaded:boolean = false;
  createEmeUserObj:any={};
  emergencyData;
  //autocompletex
  allUsers = [];
  public bankServerSideCtrl: FormControl = new FormControl();
  public bankServerSideFilteringCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  public  filteredServerSideBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  pageLimt:number  = 10;
  pageNo:number = 0;
  pageSize : number = 10;
  totalPage;
  showLoadMore:boolean = false;
  searchQuery = '';
  selectedEmsUser:any = [];
  selectedEmsUserIds = [];
  selectedEmsUserIdss = [];
  showSearch:boolean = false;
  isFormReadonly = false;
  constructor(private fb: FormBuilder,private commonService: CommonService,
    public dialogRef: MatDialogRef<EmergencyModalComponent>,
    private router: Router,
    private emergencyService: EmergencyService,
    private emsService: EMSService,
    public alertService:AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentZone = data["currentZone"];
  }

  ngOnInit() {
    if (this.data["type"] === "addEmergency") {
      this.zoneList = (this.data as any).zoneList;
      this.emergencyTypes = (this.data as any).emergencyTypes;
    }else if (this.data["type"] === "editEmergency"){
      this.zoneList = (this.data as any).zoneList;
      let emergencyData = (this.data as any).emergencyData;
      this.emergencyTypes = (this.data as any).emergencyTypes;
      this.emergencyDataObj.id = emergencyData['id'];
      this.emergencyDataObj.emergencyType = emergencyData['emergency_type']['id'];
      this.emergencyDataObj.zoneId = emergencyData['zone_id'];
      this.emergencyDataObj.countryCode = '+91';
      //let phone = emergencyData['phone_number'].split("-");
      //this.emergencyDataObj.phone_number = (typeof phone[1] != 'undefined' && phone[1] != '') ? phone[1]:'';
      this.emergencyDataObj.phone_number = emergencyData['phone_number'];
      this.emergencyDataObj.sms_content = emergencyData['sms_content'];
      this.url = emergencyData['image_url'];
      this.showImage = true;
      this.emergencyDetailsObj = [];
      if(emergencyData['config_details'].length > 0) {
        emergencyData['config_details'].forEach(element => {
          let contact = '';
          if(element['type'] == 'SMS') {

            // let phone = element['contact'].split('-');            
            // contact = (typeof phone[1] != 'undefined' && phone[1] != '') ? phone[1] : '';
            let phone = element['contact'];
            contact = (typeof phone != 'undefined' && phone != '') ? phone : '';
          }else {
            contact = element['contact'];
          }
          let obj:any = {'type': element['type'], 'contact' : contact};
          this.emergencyDetailsObj.push(obj);    
        })
      }else{
        let obj:any = {'type': '', 'contact' : ''};
        this.emergencyDetailsObj.push(obj);
      }
    }
    else if(this.data['type'] == 'addEmergencyUser') {
      this.emergencyData = this.data['emergencyData'];
      this.getCountryCodes();
      this.createEmeUserObj.countryCode = '+91';
    }
    else if(this.data['type'] == 'viewEmergencyUserList') {
      this.emergencyData = this.data['emergencyData'];
    }

    this.bankServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          this.pageNo = 0;
          
          this.searchQuery = search;
          
          if(this.searchQuery.length >= 3) {
            let data:any = {}
            data.q = search;
            data.page = this.pageNo;
            data.size = this.pageSize;
             // return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
            this.emsService.getUserList(data, this.currentZone).subscribe(res => {
              let felteredData = (res as any).response['content'];
              felteredData.forEach(element => {
                var isFirst = false;
                let i = 0;
                if(this.allUsers.length > 0) {
                  isFirst = false;
                  this.allUsers.forEach(usr => {
                    if(usr.userId == element.userId) {
                      i = 1;
                    }
                  });
                }else {
                  isFirst = true;
                  this.allUsers.push(element); //initial push
                }
                if(i === 0 && !isFirst){
                  this.allUsers.push(element); //  new user added
                }
              })
              if(this.allUsers.length > 0){
                this.allUsers.filter(user => user.name.toLowerCase().indexOf(search) > -1);
              }
              this.filteredServerSideBanks.next(this.allUsers);
              this.totalPage = (res as any).response['totalPages'];
              if(this.totalPage > 1 && this.pageNo < this.totalPage) {
                this.showLoadMore = true;
              }
            })
          }
         
        }),
        delay(500)
      )
      .subscribe(filteredBanks => {
        this.searching = false;
      },error => {
        this.searching = false;
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getCountryCodes(){
    let currentContext = this;
    this.commonService.getCountryCodes(22, currentContext.currentZone).subscribe( res => {
        currentContext.countryCodes = (res as any).response;
    }, err => {
        this.commonService.openSnackBar(err.error.message, '');
    });
}

  closeModal(): void {
    let currentContext = this;
    currentContext.commonService.changeZone(currentContext.currentZone);
    this.dialogRef.close({'emergencyData':this.emergencyData});
  }

  addEditPhonenumber(){
    var data = {
      'phone': this.model.phone
    }
    this.emsService.changeEMSUserStatus(data, this.currentZone).subscribe(res => {
      let response = (res as any).response;
      let message  = (res as any).message;
      if(response) {
        this.commonService.openSnackBar(message,"");
        this.closeModal();
      }else {
        this.commonService.openSnackBar(message,"")
      }
    },err => {
      this.commonService.openSnackBar(err.error.message,"");
    })
  }

  addEditEmail(){
    var data = {
      'email': this.model.email
    }
    this.emsService.changeEMSUserStatus(data, this.currentZone).subscribe(res => {
      let response = (res as any).response;
      let message  = (res as any).message;
      if(response) {
        this.commonService.openSnackBar(message,"");
        this.closeModal();
      }else {
        this.commonService.openSnackBar(message,"")
      }
    },err => {
      this.commonService.openSnackBar(err.error.message,"");
    })
  }


  addDetailObj = ()=> {
    let obj = {'type': 'EMAIL', 'contact' : ''};
    this.emergencyDetailsObj.push(obj);
  }
  removeDetailObj=(index)=>{
    this.emergencyDetailsObj.splice(index, 1);
  }

  createEmergencyUser = (isFrmValid, formValue) => {
    if(!isFrmValid) {
      this.alertService.error('Please try again');
      return;
    }
    if(!this.emergencyData.id) {
      this.alertService.error('Please try again');
      return;
    }


    let createObj:any = {};
    let userObj:any = {};
    userObj.name = this.createEmeUserObj.name;
    userObj.phone = this.createEmeUserObj.countryCode+'-'+ this.createEmeUserObj.mobile;
    userObj.email = this.createEmeUserObj.email;
    userObj.display_name = '';
    userObj.ems_user_id = this.createEmeUserObj.id;
    userObj.is_call_enabled = false;

    createObj.emergency_config_id = this.emergencyData.id;
    createObj.user_details = [];
    createObj.user_details.push(userObj);
    this.emergencyService.createEmergencyUser(this.currentZone, createObj).subscribe(res => {
      let message = (res as any).message
      this.alertService.success(message);
      this.emergencyData = (res as any).response;
      this.isFormReadonly = false;
      this.createEmeUserObj.mobile = '';
      this.createEmeUserObj.countryCode = '+91';
      this.createEmeUserObj.email = '';
      this.createEmeUserObj.id = '';
      this.closeModal();
    },err => {
      let errdata = (err as any).error;
      this.alertService.error(errdata['message']);
    })
  }
  updateEmergencyUser = () => {
    let updateObj:any = {};
  }
  getNextRecords = () => {
    this.pageNo = this.pageNo+1;
    let data:any = {}
    data.q = this.searchQuery;
    data.page = this.pageNo;
    data.size = this.pageSize;
    // return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
    this.emsService.getUserList(data, this.currentZone).subscribe(res => {
      let resData = (res as any).response['content'];

      resData.forEach(element => {
        let i = 0;
        this.allUsers.forEach(usr => {
          if(usr.userId == element.userId) {
            i = 1;
          }
        });
        if(i === 0){
          this.allUsers.push(element); //  new user added
        }
      })
      // resData.forEach(element => {
      //   this.allUsers.push(element);
      // });
      if(this.allUsers.length > 0){
        this.allUsers.filter(user => user.name.toLowerCase().indexOf(this.searchQuery) > -1);
      }
      this.filteredServerSideBanks.next(this.allUsers);
      this.totalPage = (res as any).response['totalPages'];
      if(this.totalPage > 1 && this.pageNo < this.totalPage) {
        this.showLoadMore = true;
      }else {
        this.showLoadMore = false;
      }
    })
  }
  onClickEmsUserCheckbox = (user) => {
    this.showSearch = false;
    if(typeof user.phoneNum != 'undefined' && user.phoneNum != '') {
      const mob = user.phoneNum.split('-');
      if(!!mob[1]) {
        this.createEmeUserObj.mobile = mob[1];
        this.createEmeUserObj.countryCode = mob[0];
      }
    }
    
    this.createEmeUserObj.id = user.userId;
    this.createEmeUserObj.name = user.name;
    this.createEmeUserObj.email = user.email;
    this.isFormReadonly = true;
    
  }
  addNewUser = () => {
    this.showSearch = false;
    this.createEmeUserObj.name = this.searchQuery;
    this.isFormReadonly = false;
    this.createEmeUserObj.mobile = '';
    this.createEmeUserObj.countryCode = '+91';
    this.createEmeUserObj.email = '';
    this.createEmeUserObj.id = '';
  }
  openSearchUser = () => {
    this.showSearch = true;
    this.isFormReadonly = true;
  }
  onChangeUserName = () => {
    this.isFormReadonly = false;
    this.createEmeUserObj.mobile = '';
    this.createEmeUserObj.countryCode = '+91';
    this.createEmeUserObj.email = '';
    this.createEmeUserObj.id = '';
  }
  onSelectFile(event) {
    this.isimageUploaded = true
    var fileName = event.target.value;
    var fileType:string = fileName.substring(
      fileName.lastIndexOf(".") + 1
    );
    fileType = fileType.toLowerCase();
  if(fileType == 'jpg' || fileType == 'png' || fileType == 'jpeg') {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.imgFile = event.target.files[0];
      reader.onload = event => {
        this.url = event.target["result"];
        this.showImage = true;
      };
    }
    var fileToUpload = event.target.files[0];
  }else {
    this.alertService.error('Plese upload valid image type')
  }
  }

  createEditEmergency = ()=> {
    let createObj:any = {};
    if(this.imgFile == '' && typeof this.imgFile == 'undefined' && this.isimageUploaded) {
      this.alertService.error("Please select image");
      return;
    }
    if (this.data["type"] === "editEmergency"){
      createObj['id'] = this.emergencyDataObj['id'];
      createObj['image_url'] = this.url;
      
    }
    createObj['emergency_type_id'] = this.emergencyDataObj['emergencyType'];
    createObj['zone_id'] = this.emergencyDataObj['zoneId'];
    //createObj['phone_number'] = this.emergencyDataObj['countryCode'] +'-'+this.emergencyDataObj['phone_number'];
    createObj['phone_number'] = this.emergencyDataObj['phone_number'];
    createObj['sms_content'] = this.emergencyDataObj['sms_content'];
    createObj['details'] = [];
    if(this.emergencyDetailsObj.length > 0) {
      this.emergencyDetailsObj.forEach(detailObj => {
        let obj:any = {};
        obj['type'] = detailObj['type'];
        if(detailObj['type'] == 'SMS') {
          //obj['contact'] = this.emergencyDataObj['countryCode'] +'-'+detailObj['contact'];
          obj['contact'] = detailObj['contact'];
        }else {
          obj['contact'] = detailObj['contact'];
        }
        createObj['details'].push(obj);
      });
    }


    this.emergencyService.createEmergency(this.currentZone,this.imgFile,  createObj, this.data["type"]).subscribe(res => {
      if (this.data["type"] === "editEmergency"){
        this.alertService.success('Emergency config Updated!');
      }else {
        this.alertService.success('Emergency config Created!');
      }
      
      this.closeModal();
    },err => {
      let data = (err as any).error;
      let message = data['message'];
      this.alertService.error(message);
    });
  }

}