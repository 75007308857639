import { Routes } from "@angular/router";
const ɵ0 = () => import("./login/login.module.ngfactory").then(m => m.LoginModuleNgFactory), ɵ1 = () => import("./login/login.module.ngfactory").then(m => m.LoginModuleNgFactory), ɵ2 = () => import("./signup/signup.module.ngfactory").then(m => m.SignupModuleNgFactory), ɵ3 = () => import("./layout/layout.module.ngfactory").then(m => m.LayoutModuleNgFactory), ɵ4 = () => import("./accountVerify/accountVerify.module.ngfactory").then(m => m.AccountVerifyModuleNgFactory), ɵ5 = () => import("./accountVerify/accountVerify.module.ngfactory").then(m => m.AccountVerifyModuleNgFactory), ɵ6 = () => import("./tenantVerify/tenantVerify.module.ngfactory").then(m => m.TenantVerifyModuleNgFactory), ɵ7 = () => import("./forgotPassword/forgotPassword.module.ngfactory").then(m => m.ForgotPasswordModuleNgFactory), ɵ8 = () => import("./forgotPasswordSuccess/forgotPasswordSuccess.module.ngfactory").then(m => m.ForgotPasswordSuccessModuleNgFactory), ɵ9 = () => import("./frgt-password/frgt-password.module.ngfactory").then(m => m.FrgtPasswordModuleNgFactory), ɵ10 = () => import("./frgt-password-success/frgt-password-success.module.ngfactory").then(m => m.FrgtPasswordSuccessModuleNgFactory), ɵ11 = () => import("./notfound/notfound.module.ngfactory").then(m => m.NotfoundModuleNgFactory);
const routes = [
    { path: "", loadChildren: "./login/login.module#LoginModule" },
    // { path: 'login', loadChildren: './login/login.module#LoginModule' },
    {
        path: "login",
        loadChildren: ɵ0
    },
    {
        path: "login/:msg1/:msg2",
        loadChildren: ɵ1
    },
    {
        path: "signup",
        loadChildren: ɵ2
    },
    {
        path: "layout",
        loadChildren: ɵ3
    },
    {
        path: "accountVerification/:token/:userType",
        loadChildren: ɵ4
    },
    {
        path: "accountVerification/:token",
        loadChildren: ɵ5
    },
    {
        path: "tenantVerification/:token",
        loadChildren: ɵ6
    },
    {
        path: "forgotPassword/:verificationCode",
        loadChildren: ɵ7
    },
    {
        path: "forgotPasswordSuccess",
        loadChildren: ɵ8
    },
    {
        path: "frgt-password",
        loadChildren: ɵ9
    },
    {
        path: "frgt-password-success",
        loadChildren: ɵ10
    },
    {
        path: '404',
        loadChildren: ɵ11
    },
    { path: '**', redirectTo: '/404' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
