import { Injectable } from "@angular/core";
import { Alert, AlertType } from "../models/alert";
import { Subject, Observable } from "rxjs";
import { Router, NavigationStart } from "@angular/router";
import { filter } from 'rxjs/operators';

@Injectable()
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor( router: Router){
        router.events.subscribe( event => {
            if( event instanceof NavigationStart){
                if( this.keepAfterRouteChange ){
                    //only keep for a single route
                    this.keepAfterRouteChange = false;
                }else{
                    //clear all alerts
                    this.clear();
                }
            }
        });
    }

    // subscribe to alerts
    getAlert(alertId?: string): Observable<any> {
        return this.subject.asObservable().pipe(
            filter((x: Alert) => x && x.alertId === alertId)
        );
    }

    // convenience methods
    success(message: string) {

        this.alert(new Alert({ message, type: AlertType.Success }));
    }

    error(message: string) {

        this.alert(new Alert({ message, type: AlertType.Error }));
    }

    info(message: string) {

        this.alert(new Alert({ message, type: AlertType.Info }));
    }

    warn(message: string) {

        this.alert(new Alert({ message, type: AlertType.Warning }));
    }

    // main alert method    
    alert(alert: Alert) {
        this.keepAfterRouteChange = alert.keepAfterRouteChange;
        this.subject.next(alert);
    }

    clear(alertId?: string) {
        this.subject.next(new Alert({ alertId }));
    }
}