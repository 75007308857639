import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'commonService';
import { LeafletService } from 'leafletService';
import { LoaderService } from 'loaderService';
import { Subject, EMPTY, forkJoin, Subscription } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import {
  LeafLetInit,
  MarkerOption,
  PolygonOption,
  TilesOption,
} from '../../../onboarding/onboard-space/models/leaflet.interface';
declare const $;
import * as L from 'leaflet';
import 'leaflet-draw';
import { AmenityBookingService } from '../../services/amenity-booking.service';

@Component({
  selector: 'app-way-finding',
  templateUrl: './way-finding.component.html',
  styleUrls: ['./way-finding.component.scss']
})
export class WayFindingComponent implements OnInit {

  entityId;
  currentZone;
  private unsubscribe$ = new Subject<void>();
  leaflet_url;
  subscription: Subscription = new Subscription();
  mapData: any;
  map: L.Map;
  startZoomLevelDashboard = 7;
  initMapOption: LeafLetInit = {
    mapId: 'mapid',
    minZoom: 10,
    maxZoom: 14,
    attributionControl: false,
    setViewLatLng: [0.25, 0.5],
    zoomLeavel: this.startZoomLevelDashboard,
  };
  userId;
  nodeData;
  onboardedType = '';
  circleRadius = 10000;
  drawnItems;
  onlySeatsList = {};
  minZoomDashboard;
  maxZoomDashboard;
  wayFindingReqObj = {};
  @Input() wayFindingEntity: any;
  @Input() globalId: any;
  @Input() selectedDate: any;
  wayFindingPathColor: string = '#F36C20';

  
  constructor(
    private route: Router,
    private amenityService: AmenityBookingService,
    private loadingService: LoaderService,
    private commonService: CommonService,
    private leafletService: LeafletService,
  ) {
    this.commonService.currentZone.subscribe((res) => {
      this.currentZone = res;
      console.log("permanent-way-finding: this.currentZone", this.currentZone);
    });
  }

  ngOnInit() {
    console.log("Way Finding Entity: ", this.wayFindingEntity);
    this.wayFindingView(this.wayFindingEntity);
  }

  // triggerArrayMethods = async () => {
  //   this.initLeafletMap();
  //   if (!!this.minZoomDashboard) {
  //     this.map.setZoom(parseInt(this.minZoomDashboard));
  //   }
  // };

  // triggerAutoCadArrayMethods = async () => {
  //   this.initLeafletMapAuto();
  //   if (!!this.minZoomDashboard) {
  //     this.map.setZoom(parseInt(this.minZoomDashboard));
  //   }
  // };

  wayFindingView(item) {
    console.log(item);
    let reqObj = {
      userId: item['user']['userId'],
      zoneId: item['zoneLocationDto']['zoneId'],
      entityInfoId: item.entityInfo.id,
      startTime: item['requestDetails']['recurringStartTime'],
      endTime: item['requestDetails']['recurringEndTime']
    };
    this.wayFindingReqObj = reqObj;
    this.openMap(reqObj);
  }

  openMap(data) {
    let date:Date;
    if(this.selectedDate)
      date = new Date(this.selectedDate);
    else
      date = new Date();

    let startDateTime = this.commonService.getTimeStamp(date.getTime(), 'start');
    let endDateTime = this.commonService.getTimeStamp(date.getTime(), 'end');
    let requestObject = {
      "zoneId": data.zoneId,
      "availabilityRequestDto": [
        {
          "request": {
            "requestDetails": {
              "userId": data.userId,
              "zoneId": data.zoneId,
              "startTime": startDateTime,
              "endTime": endDateTime,
              "recurringStartTime":  data.startTime? data.startTime : "000",
              "recurringEndTime": data.endTime? data.endTime : "2359"
            }
          }
        }
      ]
    }
    const MapData$ = this.amenityService.getAvailabilityMapDataBooking(
      requestObject,
      this.currentZone,
      this.globalId
    )
    this.loadingService.showLoaderButDontClose(MapData$)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          this.loadingService.loadingOff();
          return EMPTY;
        })
      )
      .subscribe((res) => {
        this.loadingService.loadingOff();
        if (!!res['value']) {
          if (this.onboardedType != 'DWG') {
            if (!!res && res['circleRadius']) {
              this.circleRadius = res['circleRadius'];
            } else {
              this.circleRadius = 12000;
            }
          }
          if (!!res && res['circleRadius']) {
            this.circleRadius = res['circleRadius'];
          } else {
            this.circleRadius = 12000;
          }
          this.minZoomDashboard = res['minZoomDashboard'];
          this.maxZoomDashboard = res['maxZoomDashboard'];
          this.startZoomLevelDashboard = res['startZoomLevelDashboard'];
          this.onboardedType = res['onboardedType'];
          this.onlySeatsList['availableEntities'] = res['value']['availableEntities'];
          this.onlySeatsList['occupiedEntities'] = res['value']['occupiedEntities'];
          this.leaflet_url = res['tileUrl'];

          if(this.onboardedType == 'MAP'){
            this.initLeafletMap();
            if(!this.startZoomLevelDashboard){
              this.startZoomLevelDashboard = Math.floor((this.minZoomDashboard + this.maxZoomDashboard) / 2);
              this.map.setZoom(this.startZoomLevelDashboard);
            }
          } else if(this.onboardedType == 'DWG'){
            this.initLeafletMapAuto();
            if(!this.startZoomLevelDashboard){
              this.startZoomLevelDashboard = 10;
              this.map.setZoom(this.startZoomLevelDashboard);
            }
          }

          // if (this.onboardedType != 'DWG') {
          //   this.triggerArrayMethods();
          // } else {
          //   this.triggerAutoCadArrayMethods();
          // }
        }
      });
  }

  initLeafletMap = () => {
    // let maxZoomDashboard = Number((this.minZoomDashboard * 1.5).toFixed())
    let tilesOption: TilesOption = {
      tileUrl: this.leaflet_url,
      maxZoom: this.leaflet_url.includes('_imagemap') ? 7 : 14,
      attribution: 'smartenspaces',
      id: 'smartenspaces',
    };
    if (this.map) {
      this.leafletService.deleteMap(this.map);
    }
    this.map = L.map(this.initMapOption.mapId, {
      // minZoom: this.leaflet_url.includes('_imagemap')
      //   ? this.minZoomDashboard
      //   : 4,
      // maxZoom: this.leaflet_url.includes('_imagemap') 
      //   ? maxZoomDashboard
      //   : 7,
      minZoom: !!this.minZoomDashboard ? this.minZoomDashboard : 3,
      maxZoom: !!this.maxZoomDashboard ? this.maxZoomDashboard : 14,
      attributionControl: this.initMapOption.attributionControl,
    }).setView(
      [
        this.initMapOption.setViewLatLng[0],
        this.initMapOption.setViewLatLng[1],
      ],
      this.startZoomLevelDashboard
    );

    if(this.wayFindingReqObj) {
      this.wayFinding(this.wayFindingReqObj);
    }

    forkJoin(
      this.leafletService.addTiles(this.map, tilesOption),
      this.leafletService.initDrawMapControl(this.map)
    ).subscribe(([res2, drawControlObj]) => {
      this.drawnItems = drawControlObj.drawnItems;
    });
    /* entityList in floor edit flow start **/
    if ((!!this.onlySeatsList && this.onlySeatsList['availableEntities'].length > 0) || (!!this.onlySeatsList && this.onlySeatsList['occupiedEntities'].length > 0)) {
      this.callPngLeaflet(this.onlySeatsList);
    }

    this.map.on('zoomend', async (res) => {
      this.startZoomLevelDashboard = this.map.getZoom();

      if (this.map.getZoom() >= 14) {
        $('.leaflet-tooltip.my-labels').css({
          'font-size': '20px',
          '-webkit-text-stroke': '1px black',
          'margin-top': '26px',
          'font-family': 'HelveticaNeue-bold',
          'letter-spacing': '1.5px',
          'font-weight': 'bold',
        });
      } else if (this.map.getZoom() === 11) {
        $('.leaflet-tooltip.my-labels').css({
          'font-size': '5px',
          '-webkit-text-stroke': '0.8px black',
          'margin-top': '2px',
          'font-family': 'HelveticaNeue-bold',
          'letter-spacing': '1.5px',
          'font-weight': 'bold',
        });
      } else if (this.map.getZoom() === 12) {
        $('.leaflet-tooltip.my-labels').css({
          'font-size': '10px',
          '-webkit-text-stroke': '0.8px black',
          'margin-top': '2px',
          'font-family': 'HelveticaNeue-bold',
          'letter-spacing': '1.5px',
          'font-weight': 'bold',
        });
      } else if (this.map.getZoom() === 13) {
        $('.leaflet-tooltip.my-labels').css({
          'font-size': '13px',
          '-webkit-text-stroke': '1px black',
          'margin-top': '15px',
          'font-family': 'HelveticaNeue-bold',
          'letter-spacing': '1.5px',
          'font-weight': 'bold',
        });
      } else {
        $('.leaflet-tooltip.my-labels').css({
          'font-size': 0,
          '-webkit-text-stroke': '1px black',
          'margin-top': '15px',
          'font-family': 'HelveticaNeue-bold',
          'letter-spacing': '1.5px',
          'font-weight': 'bold',
        });
      }
    });
  };

  initLeafletMapAuto = () => {
    let initMapOption: LeafLetInit = {
      mapId: 'mapid',
      minZoom: !!this.minZoomDashboard ? this.minZoomDashboard : 10,
      maxZoom: !!this.maxZoomDashboard ? this.maxZoomDashboard : 14,
      attributionControl: false,
      setViewLatLng: [0.25, 0.5],
      zoomLeavel: this.startZoomLevelDashboard,
    };
    let tilesOption: TilesOption = {
      tileUrl: this.leaflet_url,
      maxZoom: 14,
      attribution: 'smartenspaces',
      id: 'smartenspaces',
    };
    if (this.map) {
      this.leafletService.deleteMap(this.map);
    }
    this.map = L.map(initMapOption.mapId, {
      minZoom: initMapOption.minZoom,
      maxZoom: initMapOption.maxZoom,
      attributionControl: initMapOption.attributionControl,
    }).setView(
      [initMapOption.setViewLatLng[0], initMapOption.setViewLatLng[1]],
      initMapOption.zoomLeavel
    );

    let southWest = L.latLng(-0.1, -0.1),
    northEast = L.latLng(1.1, 1.1);
    let bounds = L.latLngBounds(southWest, northEast);

    this.map.setMaxBounds(bounds);

    if(this.wayFindingReqObj) {
      this.wayFinding(this.wayFindingReqObj);
    }

    forkJoin(
      this.leafletService.addTiles(this.map, tilesOption),
      this.leafletService.initDrawMapControl(this.map)
    ).subscribe(([res2, drawControlObj]) => {
      this.drawnItems = drawControlObj.drawnItems;
    });

    this.map.on('zoomend', (res) => {
      this.startZoomLevelDashboard = this.map.getZoom();
      $('.leaflet-marker-pane').css({
        'z-index': 700,
      });
      if (this.map.getZoom() <= 10) {
        $('.leaflet-marker-icon.my-labels').css('font-size', 0);
      } else if (this.map.getZoom() === 11) {
        $('.leaflet-marker-icon.my-labels').css('font-size', 8);
      } else if (this.map.getZoom() >= 14) {
        $('.leaflet-marker-icon.my-labels').css('font-size', 15);
      } else {
        $('.leaflet-marker-icon.my-labels').css('font-size', 13);
      }
    });
    let seatsGeoJsonAvailableEntities = this.onlySeatsList['availableEntities'];
    let seatsGeoJsonOccupiedEntities = this.onlySeatsList['occupiedEntities']
    let mapArr = [];
    for (var index in seatsGeoJsonAvailableEntities) {
      mapArr.push({
        id: seatsGeoJsonAvailableEntities[index]['id'],
        displayName: seatsGeoJsonAvailableEntities[index]['displayName'],
        attributes: {
          coordinates: seatsGeoJsonAvailableEntities[index]['attributes']['coordinates'],
          mb_tile_max_x: seatsGeoJsonAvailableEntities[index]['attributes']['mb_tile_max_x'],
          mb_tile_max_y: seatsGeoJsonAvailableEntities[index]['attributes']['mb_tile_max_y'],
          mb_tile_min_x: seatsGeoJsonAvailableEntities[index]['attributes']['mb_tile_min_x'],
          mb_tile_min_y: seatsGeoJsonAvailableEntities[index]['attributes']['mb_tile_min_y'],
          circleCenterLat: null,
          circleCenterLng: null,
        },
        config: seatsGeoJsonAvailableEntities[index]['config'],
        status: seatsGeoJsonAvailableEntities[index]['status'],
        color: '#008000',
        occupied: false
      });
    }
    for (var index in seatsGeoJsonOccupiedEntities) {
      mapArr.push({
        id: seatsGeoJsonOccupiedEntities[index]['id'],
        displayName: seatsGeoJsonOccupiedEntities[index]['displayName'],
        attributes: {
          coordinates: seatsGeoJsonOccupiedEntities[index]['attributes']['coordinates'],
          mb_tile_max_x: seatsGeoJsonOccupiedEntities[index]['attributes']['mb_tile_max_x'],
          mb_tile_max_y: seatsGeoJsonOccupiedEntities[index]['attributes']['mb_tile_max_y'],
          mb_tile_min_x: seatsGeoJsonOccupiedEntities[index]['attributes']['mb_tile_min_x'],
          mb_tile_min_y: seatsGeoJsonOccupiedEntities[index]['attributes']['mb_tile_min_y'],
          circleCenterLat: null,
          circleCenterLng: null,
        },
        config: seatsGeoJsonOccupiedEntities[index]['config'],
        status: seatsGeoJsonOccupiedEntities[index]['status'],
        color: '#008000',
        occupied: true
      });
    }
    this.mapData = mapArr;
    this.leafletService
      .addTiles(this.map, tilesOption)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (res) => {
        await this.drawSeatCoordinates();
        var drawnItems = new L.FeatureGroup();
        this.map.addLayer(drawnItems);
      });
  };

  drawSeatCoordinates = async () => {
    if (!!this.mapData) {
      Object.values(this.mapData).forEach((value, index) => {
        let seatData = value;
        if (
          !!seatData['attributes']['coordinates'] &&
          seatData['attributes']['coordinates'].length
        ) {
          let userName = '';
          if (
            seatData['user'] !== null &&
            typeof seatData['user'] != 'undefined'
          ) {
            if (seatData['user']['name'] !== null) {
              if (!!seatData['user']['name']) {
                userName = seatData['user']['name'];
              }
            }
          }

          let polygonArray = [];
          let biggerArray = [];
          let vertices = seatData['attributes']['coordinates'];
          for (let i = 0; i < vertices.length; i++) {
            let objdata = [];
            objdata.push(vertices[i]['yCoordinate']);
            objdata.push(vertices[i]['xCoordinate']);
            polygonArray.push(objdata);
          }
          biggerArray.push(polygonArray);
          let polygonOption: PolygonOption = {
            polygonArray: biggerArray,
            color: seatData['color'],
            fillColor: seatData['color'],
            fillOpacity: 1,
            weight: 0.01,
          };
          /** */
          let seatCoordinates = seatData['attributes'];
          let seatCoordinatesX;
          let seatCoordinatesY;
          if (!!seatCoordinates.mb_tile_max_x) {
            seatCoordinatesX =
              (seatCoordinates.mb_tile_min_x + seatCoordinates.mb_tile_max_x) /
              2;
            seatCoordinatesY =
              (seatCoordinates.mb_tile_min_y + seatCoordinates.mb_tile_max_y) /
              2;
          } else {
            seatCoordinatesX =
              (seatCoordinates.minX + seatCoordinates.maxX) / 2;
            seatCoordinatesY =
              (seatCoordinates.minY + seatCoordinates.maxY) / 2;
          }
          let markerObj: MarkerOption = {
            seatCoordinatesX: seatCoordinatesY,
            seatCoordinatesY: seatCoordinatesX,
            draggable: false,
            iconUrl: './assets/images/checkbox.png',
            iconSize: [32, 32],
            zoomLeavel: this.startZoomLevelDashboard,
          };
          //Show label
          var marker2div = L.divIcon({
            className: 'my-labels',
            iconSize: null,
            html: `
              <div style="color: #0F1F54; background-color: #FFFFFF; padding: 0px 2px; border-radius: 5px">${seatData['displayName']} </div><div>${userName}</div>`,
          });

          if (this.map.getZoom()) {
            if (this.map.getZoom() <= 10) {
              $('.leaflet-marker-icon.my-labels').css('font-size', 0);
            } else if (this.map.getZoom() === 11) {
              $('.leaflet-marker-icon.my-labels').css('font-size', 8);
            } else if (this.map.getZoom() >= 14) {
              $('.leaflet-marker-icon.my-labels').css('font-size', 15);
            } else {
              $('.leaflet-marker-icon.my-labels').css('font-size', 13);
            }
          }

          var marker2 = L.marker(
            new L.LatLng(
              markerObj.seatCoordinatesX,
              markerObj.seatCoordinatesY
            ),
            { icon: marker2div }
          );
          this.map.addLayer(marker2);
          if (seatData['status'] == 'ENABLED' && seatData['occupied']) {
            let wayFindingEnabledEntityIcon =
              './assets/images/entityBookedDwg.svg';
            let myIcon = L.icon({
              iconUrl: wayFindingEnabledEntityIcon,
              iconSize: [25, 25],
              iconAnchor: [16, 17],
              popupAnchor: [0, -28],
            });

            let wayFindingEnabledMarker = L.marker(
              new L.LatLng(
                markerObj.seatCoordinatesX,
                markerObj.seatCoordinatesY
              ),
              {
                draggable: markerObj.draggable,
                icon: myIcon,
              }
            );
            seatData['markerObj'] = wayFindingEnabledMarker;
            this.map.addLayer(wayFindingEnabledMarker);
          } else if (seatData['status'] == 'ENABLED' && !seatData['occupied']) {
            let onboardedEntityIcon = './assets/images/entitySelectedDwg.svg';
            let myIcon = L.icon({
              iconUrl: onboardedEntityIcon,
              iconSize: [25, 25],
              iconAnchor: [16, 17],
              popupAnchor: [0, -28],
            });
            let onboardedMarker = L.marker(
              new L.LatLng(
                markerObj.seatCoordinatesX,
                markerObj.seatCoordinatesY
              ),
              {
                draggable: markerObj.draggable,
                icon: myIcon,
              }
            );
            seatData['markerObj'] = onboardedMarker;
            this.map.addLayer(onboardedMarker);
          }

          let amenitiesDisplay = '';
          if (!!seatData['amenities'] && seatData['amenities'] != null) {
            seatData['amenities'].forEach((element) => {
              if (!!element && element.name != null) {
                amenitiesDisplay += `<p>${element.name}</p>`;
              }
            });
          }

          /**  draw polygon with marker */
          this.leafletService
            .drawPolygon(this.map, polygonOption)
            .pipe(takeUntil(this.unsubscribe$));
        }
      });
    }
  };

  drawMarkerToSeat = (map, Mlat, Mlng, popupmsg, flag) => {
    const startsvg = './assets/images/navigation.svg';
    const endsvg = './assets/images/marker-icon.png';
    if (flag === 'start') {
      var startIcon = L.icon({
        iconUrl: startsvg,
        iconSize: [30, 30], // size of the icon
        iconAnchor: [15, 34], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -45], // point from which the popup should open relative to the iconAnchor
      });
      L.marker([Mlat, Mlng], {
        icon: startIcon,
        draggable: false,
      }).addTo(map);
    }
    if (flag === 'end') {
      var endIcon = L.icon({
        iconUrl: endsvg,
        iconSize: [30, 30], // size of the icon
        iconAnchor: [18, 15], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -45], // point from which the popup should open relative to the iconAnchor
      });

      L.marker([Mlat, Mlng], {
        icon: endIcon,
        draggable: false,
      }).addTo(map);
    }
  };

  drawNodes = (map, nodeData, from = '') => {
    var circleCollection = [];
    var nodes_layer = [];
    var group1 = L.featureGroup();
    let pointList = [];
    if (typeof nodeData['node'] != 'undefined') {
      if (from === 'seat') {
        this.drawMarkerToSeat(
          map,
          nodeData.node[0].latitude,
          nodeData.node[0].longitude,
          '',
          'start'
        );
        this.drawMarkerToSeat(
          map,
          nodeData.node[nodeData.node.length - 1].latitude,
          nodeData.node[nodeData.node.length - 1].longitude,
          '',
          'end'
        );
      }
      for (var i in nodeData.node) {
        pointList.push([nodeData.node[i].latitude, nodeData.node[i].longitude]);
      }
    }

    var circle = new L.Polyline(pointList, {
      // color: '#F36C20',
      color: this.wayFindingPathColor,
      weight: 7,
      opacity: 1,
      smoothFactor: 1,
      dashArray: '1,20',
      lineJoin: 'miter',
      lineCap: 'round',
    }).addTo(group1);

    nodes_layer.push(circle);
    circleCollection.push(circle);
    map.addLayer(group1);

    var myZoom = {
      start: map.getZoom(),
      end: map.getZoom(),
    };

    map.on('zoomstart', function (e) {
      myZoom.start = map.getZoom();
    });
  };

  callPngLeaflet(onlySeatsList) {
    for (var k = 0; k < onlySeatsList['availableEntities'].length; k++) {
      let availableEntities = onlySeatsList['availableEntities'][k]['attributes'];
      let availableEntitiesStatus = onlySeatsList['availableEntities'][k]['status'];
      let availableEntitiesLat = availableEntities.circleCenterLat;
      let availableEntitiesLng = availableEntities.circleCenterLng;
      let availableEntitiesKeyName = onlySeatsList['availableEntities'][k]['displayName'];

      if (availableEntitiesStatus == 'ENABLED') {
        L.circle([availableEntitiesLat, availableEntitiesLng], this.circleRadius)
          .bindTooltip('Display Name: ' + availableEntitiesKeyName)
          .setStyle({
            color: '#ffffff',
            fillColor: 'green',
            fillOpacity: 0.6,
          })
          .addTo(this.map);
      }
    }
    for (var k = 0; k < onlySeatsList['occupiedEntities'].length; k++) {
      let occupiedEntities = onlySeatsList['occupiedEntities'][k]['attributes'];
      let occupiedEntitiesStatus = onlySeatsList['occupiedEntities'][k]['status'];
      let occupiedEntitiesLat = occupiedEntities.circleCenterLat;
      let occupiedEntitiesLng = occupiedEntities.circleCenterLng;
      let occupiedEntitiesKeyName = onlySeatsList['occupiedEntities'][k]['displayName'];

      if (occupiedEntitiesStatus == 'ENABLED') {
        L.circle([occupiedEntitiesLat, occupiedEntitiesLng], this.circleRadius)
          .bindTooltip('Display Name: ' + occupiedEntitiesKeyName)
          .setStyle({
            color: '#ffffff',
            fillColor: 'red',
            fillOpacity: 0.8,
          })
          .addTo(this.map);
      }
    }
  }

  closeWayfindingScreen() {
    this.route.navigate(['/layout/amenity-booking'], {
      skipLocationChange: true,
    });
  }

  wayFinding(reqObj) {
    this.amenityService
      .wayFinding(this.currentZone, reqObj)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          return EMPTY;
        })
      )
      .subscribe((res) => {
        this.nodeData = res;
        if(res['pathColor'])
          this.wayFindingPathColor = res['pathColor'];
        if (this.map) {
          this.drawNodes(this.map, res, 'seat');
        }
      });
  }

}
