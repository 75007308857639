import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "commonService";
import { LoaderService } from "loaderService";
import { ToastrService } from "ngx-toastr";
import { EMPTY, of, Subject } from "rxjs";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { DashboardService } from "../../services/dashboard.services";
import { WorkStatusService } from "src/app/layout/manage-request/manage-booking/containers/work-status/service/work-status.service";
import { FilteredColleaguesModalComponent } from "../filtered-colleagues-modal/filtered-colleagues-modal.component";
import { ConfirmationDialogService } from "confirmationDialogService";
import { WeeklyComponent } from  "../../digital-main-component/reusable-digital-dashboard/weekly/weekly.component";
import { DashboardModalComponent } from "../dashboard-modal.component";
import { AmenityBookingService } from "src/app/layout/amenity-booking/services/amenity-booking.service";
import { DigitalService } from "digitalService";
import { Router } from "@angular/router";
@Component({
  selector: "app-reuseable-search-filter",
  templateUrl: "./reuseable-search-filter.component.html",
  styleUrls: ["./reuseable-search-filter.component.scss"],
})
export class ReuseableSearchFilterComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  searchQueryItems = { searchType: "", searchValue: "" };
  currentZone;
  workStatusForm: FormGroup;
  submitted = false;
  showColleagues = false;
  closeColleagues = false;
  // length = 10;
  // pageSizeOptions = [10, 25, 100];
  pageSize = 10;
  pageIndex = 0;
  workStatusResponse;

  eName: string;
  nameSearchIsLoading: boolean = false;
  workStatusUserId: any;
  pageType;
  private dialogRef;
  dialogData = {};
  interval;

  @Input() colleagueObj;
  @Output() notifyParentColleague: EventEmitter<any> = new EventEmitter();

  nameSearchTermSubject$ = new Subject<any>();
  nameSearchTermAction$ = this.nameSearchTermSubject$.asObservable();
  nameAutocompleteList$ = this.nameSearchTermAction$.pipe(
    tap(() => {
      this.nameSearchIsLoading = true;
    }),
    switchMap((searchTerm) =>
      of(searchTerm).pipe(
        mergeMap((res) =>
          this.workStatusService.getEmployeeNameAutoCompleteList(
            {
              searchTerm,
              searchType: this.eName,
            },
            this.currentZone
          )
        )
      )
    ),
    tap((searchRes) => {
      this.nameSearchIsLoading = false;
    }),
    catchError((err) => {
      return EMPTY;
    })
  );

  colleaguesList = [];

  @ViewChild("name", { static: false }) textInput: ElementRef;
  selectedCalendarDate: any;
  @ViewChild(WeeklyComponent, { static: false })
  weeklyComponent: WeeklyComponent;
  deskBookingEnabled;
  digitalVersion = false;
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    public translate: TranslateService,
    private loaderService: LoaderService,
    private route: Router,
    private amenityService: AmenityBookingService,
    // private emsService: EMSService,
    private digitalService: DigitalService,
    private toastrService: ToastrService,
    private workStatusService: WorkStatusService,
    private dashboardService: DashboardService,
    private confirmationDialogService: ConfirmationDialogService,
  ) {
    this.digitalService.versionCheck.subscribe(async (res) => {
      if (res) {
        this.digitalVersion = res;
      }
    });
    this.amenityService.getOtherUserId.subscribe(data => {
      if (data.type == 'DESKBOOKING' && !this.digitalVersion) {
        this.deskBookingEnabled = true;
      }
    });
    this.commonService.currentZone.subscribe((res) => {
      this.currentZone = res;
    });
  }

  ngOnInit() {
    this.workStatusForm = this.formBuilder.group({
      workstatusSearch: ["", Validators.required],
    });

    
  }

  ngAfterViewInit(){
    if(Object.keys(this.colleagueObj).length > 0 && this.colleagueObj.date !=""){
      this.dateSelected( this.colleagueObj)
    }else{
      this.loadFavouriteColleagues();
    }
  }
  getTranslate(word) {
    let translatedWord = word;
    const test = this.translate.get([word]).subscribe(translation => {
      translatedWord = translation;
    });
    return translatedWord[word];
  }
  loadFavouriteColleagues() {
    let cDate;
    this.colleaguesList = [];
    if (!!this.selectedCalendarDate) {
      // cDate = this.commonService.getTimeStamp(
      //   this.selectedCalendarDate,
      //   "start"
      // );
      cDate = this.selectedCalendarDate;
    } else {
      cDate = this.commonService.getTimeStamp(new Date().getTime(), "start");
    }
    const workStation$ = this.dashboardService.loadColleaguesForDay(this.currentZone,
      cDate,
      true
    );
    const fav$ = this.loaderService
      .showLoaderUntilCompleted(workStation$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if(this.colleagueObj.date!=''){
          this.interval = setInterval(()=>{
            if(typeof this.weeklyComponent != 'undefined'){
              this.weeklyComponent.selectedDate = this.colleagueObj.date;
              let currentDate = this.commonService.getTimeStamp(
                new Date().getTime(),
                "start"
              );
              if(currentDate<=parseInt(this.colleagueObj.date)){
                this.weeklyComponent.updateDynamicallyDates('N',this.colleagueObj.date);
              }else{
                this.weeklyComponent.updateDynamicallyDates('P',this.colleagueObj.date);
              }
              clearInterval(this.interval);
            }
          },1000);
        }

        let userResponse = res["response"]["userLocationDto"];
        if (userResponse.length > 0) {
          this.showColleagues = true;
        } else {
          this.showColleagues = false;
        }
        // let workstatus = ["office", "home", "leave"];
        userResponse.forEach((element) => {
          console.log(element);
          this.colleaguesList.push({
            id: element["userresponseDto"]["userId"],
            image:
              element["userresponseDto"]["imageUrl"] ,
            name: element["userresponseDto"]["name"],
            location: element["allocation"] && element["allocation"]["zoneLocationDto"] ? element["allocation"]["zoneLocationDto"]: null,
            workLocationDto: element["workLocationDto"] ? element["workLocationDto"]: null,
            resentSearch: element["recentSearch"],
            deskNumber: element["deskNumber"] ? element["deskNumber"] : "", 
            // workStatus: workstatus[Math.floor(Math.random() * 3)], // needs to be changed based on response
            workStatus: element["wfhStatus"] ? element["wfhStatus"] : "", // needs to be changed based on response
            deskActiveStatus: "Hidden", //office -> Visible or Hidden
            wayfinding:element["entityInfoConfig"] && element["entityInfoConfig"]["wayFindingEnabled"]?element["entityInfoConfig"]["wayFindingEnabled"] : null,
            allData:element
          });
        });
      });
  }

  getEmployeeNameAutocompleteList = (searchTerm?) => {
    this.nameSearchIsLoading = false;
    this.nameSearchTermSubject$.next(searchTerm.trim());
  };

  onselectNameData = async (val) => {
    this.dialogData = val;
    this.workStatusUserId = val.userId;
    this.eName = val.name.trim();
    this.closeColleaguesList();
    this.showColleaguesModal();
  };

  showColleaguesModal() {
    this.pageType = "selectedColleagueModal";
    var width = "40";
    
    this.openGlobalDialog(width);
  }

  public openGlobalDialog(widthPercetage): void {
    //donn't use for other dialogs Please use other dialog
    var width = "auto";
    if (widthPercetage) {
      width = widthPercetage + "%";
    }
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(FilteredColleaguesModalComponent, {
        width: width,
        height: "auto",
        panelClass: this.pageType=='selectedColleagueModal'?'sm-modal':'md-modal',
        data: {
          type: this.pageType,
          dialogData: this.dialogData,
        },
        disableClose: false,
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        this.dialogRef = false;
        if (result == "colleageRequest") {
          // this.workStatusForm.controls.workstatusSearch.reset();
          this.clearAutoCompleteList();
          this.textInput.nativeElement.blur();
          this.workStatusForm.reset();
          this.showColleaguesList();
          this.resetAutoCompleteNameList();
          this.loadFavouriteColleagues();
        }
        else{
          this.showColleaguesMainModal();
        }
      });
    }
  }

  showColleaguesMainModal() {
    this.pageType = "colleaguesModal";
    this.openGlobalDialogCollegue();
  }
  public openGlobalDialogCollegue(): void {
    let pclass = "";
    let width = "auto";
    if (this.pageType == "colleaguesModal") {
      pclass = "md-modal";
      this.dialogData = {
        date: '',
        findColleagueBlueTick:false,
      }
    } 
   
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(DashboardModalComponent, {
        panelClass: pclass,
        width: width,
        data: {
          type: this.pageType,
          dialogData: this.dialogData,
          //width:'100%'
        },
        disableClose: false,
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        this.dialogRef = false;
       
        
        if (result == "colleagues") {
          // this.onCloseColleagues();
        }
      });
    }
  }
  clearAutoCompleteList() {
    this.nameAutocompleteList$ = null;
  }

  resetAutoCompleteNameList() {
    this.nameSearchTermSubject$ = new Subject<any>();
    this.nameSearchTermAction$ = this.nameSearchTermSubject$.asObservable();
    this.nameAutocompleteList$ = this.nameSearchTermAction$.pipe(
      tap(() => {
        this.nameSearchIsLoading = true;
      }),
      switchMap((searchTerm) =>
        of(searchTerm).pipe(
          mergeMap((res) =>
            this.workStatusService.getEmployeeNameAutoCompleteList(
              {
                searchTerm,
                searchType: this.eName,
              },
              this.currentZone
            )
          )
        )
      ),
      tap((searchRes) => {
        this.nameSearchIsLoading = false;
      }),
      catchError((err) => {
        return EMPTY;
      })
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.workStatusForm.controls;
  }

  onSubmitSearch() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.workStatusForm.invalid) {
      return;
    } else {
      this.searchQueryItems = {
        searchType: "name",
        // searchValue: this.workStatusForm.value.workstatusSearch,
        searchValue: this.workStatusUserId,
      };
      this.getWorkStatus(this.searchQueryItems, 0, this.pageSize);
    }
  }

  getWorkStatus(searchItems, pageIndex = 0, pageSize = 10) {
    let data = {
      searchType: searchItems.searchType,
      searchValue: searchItems.searchValue,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };

    const workStation$ = this.workStatusService.getAllWorkStatus(
      this.currentZone,
      data
    );
    const wrkSub$ = this.loaderService
      .showLoaderUntilCompleted(workStation$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (!!res["response"]) {
            // this.length = res["response"]["totalElements"];
            // this.pageIndex =
            //   this.length < 10 ? 0 : res["response"]["number"];

            this.workStatusResponse = res["response"]["content"];
          }
        },
        (err) => {
          if (err.status == 404) {
            this.toastrService.error(this.translate.instant("somethingWentWrong"));
          }
        }
      );
  }

  showColleaguesList() {
    this.showColleagues = true;
  }

  closeColleaguesList() {
    this.closeColleagues = true;
    // this.dialogRef.close();
    this.notifyParentColleague.emit("true");
    
  }

  deleteColleague(colleague) {
    let searchId = colleague["resentSearch"]["id"];
    let Message = this.translate.instant("areYouSureYouWantToDelete"); 
    this.confirmationDialogService
      .confirm("", Message)
      .then(confirmed => {
        if (confirmed == true) {
          const allocationDetails = this.dashboardService.deleteFavourite(
            searchId
          );
          this.loaderService
            .showLoaderUntilCompleted(allocationDetails)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
              this.toastrService.success(this.translate.instant("colleagueDeletedSuccessfully"));
              // this.getAllStatus("", this.pageIndex, this.pageSize);
      
              this.loadFavouriteColleagues();
      
            });
        }
      });

  }

  dateSelected(dateSelected) {
    this.selectedCalendarDate = dateSelected.date;
    
    this.loadFavouriteColleagues();
  }

  getWorkStatusColor(user) {
    return user.workStatus == "OFFICE"
      ? "workStatusBlue"
      : user.workStatus == "WFH"
      ? "workStatusOrange"
      : user.workStatus == "LEAVE"
      ? "workStatusSkyBlue"
      // : "workStatusSkyBlue";
      : "";
  }
  openWorkstatusDialog(c){
    console.log(c);
    if(c){
    this.dialogData = c;
    this.pageType = "selectedColleagueWorkStatus";
    var width = "50";
    this.dialog.closeAll();
    this.openGlobalDialog(width);
    }
    

  }
  wayFinding(cll){
    console.log(cll);
    let cDate
    if (!!this.selectedCalendarDate) {
      cDate = this.commonService.getTimeStamp(
        this.selectedCalendarDate,
        "start"
      );
    } else {
      cDate = this.commonService.getTimeStamp(new Date().getTime(), "start");
    }
    let userResponseDTO = cll["userresponseDto"];
    let coll;
    coll
    ={
        id: userResponseDTO["userId"],
        image:
            userResponseDTO["imageUrl"] === null
                ? "./assets/images/jumpree_digital/avatar@3x.jpg"
                : userResponseDTO["imageUrl"],
        imageDesk:
            "./assets/images/jumpree_digital/workplace.svg",
        imageLocate: "./assets/images/wayfinidngflexi.png",
        name: userResponseDTO["name"],
        location: cll["locationDetails"]
            ? cll["locationDetails"]
            : "",
        locationInfo:
            cll["allocation"] &&
            cll["allocation"]["zoneLocationDto"]
                ? cll["allocation"]["zoneLocationDto"][
                      "site"
                  ]["name"] +
                  ", " +
                  cll["allocation"]["zoneLocationDto"][
                      "city"
                  ]["name"]
                : "",
        userNameLogo: this.commonService.getFnameLname(
            userResponseDTO["firstName"],
            userResponseDTO["lastName"]
        ),
        workStatus: cll["wfhStatus"],
        deskNo: cll["deskNumber"]
            ? cll["deskNumber"]
            : "",
        gecId: cll["allocation"]
            ? cll["allocation"]["entityInfo"][
                  "entityChildType"
              ]["entityTypeId"]["globalEntityCategory"][
                  "id"
              ]
            : "",
        locationId:
            cll["allocation"] &&
            cll["allocation"]["zoneLocation"]
                ? cll["allocation"]["zoneLocationDto"][
                      "site"
                  ]["id"]
                : "405417",
        startTime: cll["allocation"]
            ? cll["allocation"]
            : "",
        endTime: cll["allocation"]
            ? cll["allocation"]
            : "",
        wayFinding: cll["allocation"]
            ? cll["allocation"]
            : "",
        selectedDate:cDate
  }
    ;
    
    this.wayFindingClose(coll);
    console.log(coll);
}
wayFindingClose(element) {


  let entityDisplayName = element['wayFinding'] ? element['wayFinding']['entityInfo']['displayName']: null;

  let wayFindingData = element['wayFinding'] && element['wayFinding']['wayFindingDto'] ?  element['wayFinding']['wayFindingDto'] : null;

  if(wayFindingData && wayFindingData['wayFindingRedirectionUrl'] && entityDisplayName){
    let url = `${wayFindingData['wayFindingRedirectionUrl']}${entityDisplayName}`;
    window.open(url, '_blank');
  }
  else{

  if (element && element.wayFinding && (element.wayFinding.user || element.wayFinding.bookedForUser)) {
    let colleagueUser = element.wayFinding.user ? element.wayFinding.user : element.wayFinding.bookedForUser;
    let colleagueUserId = colleagueUser['userId'];
    let colleagueName = colleagueUser['name'];
    let type = "";
    if (this.deskBookingEnabled) {
      type = "DESKBOOKING";
    } else {
      type = "SPACEBOOKING"
    }
    this.amenityService.setSelectedDates({});
    let userId = localStorage.getItem("ssadmin_userId");
    let data = {
      "userId": userId,
      "type": type,
      "amenityName": element.wayFinding.entityInfo.displayName,
      "colleagueUserId": colleagueUserId,
      "colleagueName": colleagueName
    }
    this.amenityService.setGlobalData(element.gecId);
    this.amenityService.setonBehalfofUser(data);
    this.amenityService.setMapViewData({});
    this.amenityService.setOutlookOrNot(false);
    this.amenityService.setSelectedStartEndTime(undefined);

    console.log(element);
    const collegueInfo = {
      "colleagueEntityId": element.wayFinding.entityInfo.id,
      "colleagueDeskName": element.wayFinding.entityInfo.displayName,
      "buildingId": element.wayFinding.zoneLocationDto.building.id,
      "buildingName": element.wayFinding.zoneLocationDto.building.name,
      "floorId": element.wayFinding.zoneLocationDto.zoneId,
      "floorName": element.wayFinding.zoneLocationDto.name,
    }
    let objFIndCll =
      [
        {
          "date": element.selectedDate,
          "eventObj": {
            "date": "2024-01-04",
            "dateArr": "2024-01-04",
            "availability": "green",
            "nonAvailability": "red",
            // "availableSeats": 361,
            // "notAvailableSeats": 1,
            "color": "#FFFFFF",
            "deskNo": null,
            "entityId": null,
            "showDeskName": false,
            "rosterPresent": false,
            "allowBooking": true,
            "restricted": false,
            "status": null,
            "statusColorMap": {
              "OUT_OF_OFFICE": "#FFFFFF",
              "OFFICE": "#FFFFFF",
              "WFH": "#FFFFFF",
              "LEAVE": "#FFFFFF",
              "BOOKING": "#FFFFFF"
            },
            "isAllowBookingFalseReason": null,
            "colleagueInfo": collegueInfo
          },
          "startTimeVal": {
            "id": 0,
            "value": "12:00 AM",
            "hour": "00",
            "minute": "00",
            "disabled": false,
            "ampm": "AM"
          },
          "endTimeVal": {
            "id": 1439,
            "value": "11:59 PM",
            "hour": "23",
            "minute": "59",
            "disabled": false,
            "ampm": "PM"
          },
          "entityId": null,
          "colleagueName": colleagueName,
          "colleagueInfo": collegueInfo,
          "bookedDateType": "notBooked",
          "recurringStartTime": "0000",
          "recurringEndTime": "2359",
          "wayFindingSuperAdmin":true
        }
      ];

    this.amenityService.setSelectedDates(objFIndCll);
    let selectedZoneId;
    if(element.wayFinding && element.wayFinding.zoneLocationDto && element.wayFinding.zoneLocationDto.site.id && element.wayFinding.zoneLocationDto.site.id )
    {
      selectedZoneId= element.wayFinding.zoneLocationDto.site.id;
      localStorage.setItem("selectedWayfindingZoneId",this.currentZone);
      this.commonService.changeZone(selectedZoneId);
    } 
    this.loaderService.loadingOn();
    this.amenityService.getAllAmenitiesList(this.currentZone, 0, 500)
      .subscribe((response) => {
        const res = response['response'];
        if (res) {
          
          let filterConfig = res['content'].filter(ress => ress.id == element.gecId);
          this.amenityService.setMyAmenityObj(filterConfig[0]);
          console.log(filterConfig);
          this.route.navigate(["/layout/amenity-booking"], { skipLocationChange: false });
          this.dialog.closeAll();
          setTimeout(() => {
            this.route.navigate(['/layout/amenity-booking/map-view'], { skipLocationChange: true });
          }, 1000);
        }
      }, err => {
        this.loaderService.loadingOff();
      });


  }
}


}
}
