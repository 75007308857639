import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, of, BehaviorSubject, throwError } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";
import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
@Injectable()
export class PeopleService {
    dataObj = new BehaviorSubject<any>({});

    moduleId;
    BASEURL;
    EMSURL;
    WFHURL;
    SPACEURL;
    // userSubject$ = new BehaviorSubject<any>([]);
    // users$ = this.userSubject$.asObservable();
    departmentSubject$ = new BehaviorSubject<any>([]);
    departments$ = this.departmentSubject$.asObservable();

    proxyValue = new BehaviorSubject<any>([]);
    proxyId = this.proxyValue.asObservable();

    wayFindingData = new BehaviorSubject<any>([]);

    locationIDSubject$ = new BehaviorSubject<any>([]);
    locationID$ = this.locationIDSubject$.asObservable();

    calendarViewSubject$ = new BehaviorSubject<any>([]);
    calendarViewData$ = this.calendarViewSubject$.asObservable();


    proxyIDSubject$ = new BehaviorSubject<any>([]);
    proxyIdData$ = this.proxyIDSubject$.asObservable();

    // buildingSubject$ = new BehaviorSubject<any>([]);
    // buildings$ = this.buildingSubject$.asObservable();
    // floorSubject$ = new BehaviorSubject<any>([]);
    // floors$ = this.buildingSubject$.asObservable();

    
    constructor(
        public http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        public moduleidService: ModuleidService
    ) {
        //this.BASE_URL = environment.BASE_URL+'ems/';
        //alert(baseUrl);
        //this.BASEURL = 'http://10.8.0.7:8125/';
        //this.BASEURL = baseUrl;
        this.BASEURL = environment.BASE_URL; //correct
        this.SPACEURL = environment.BASE_URL_SPACE;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.moduleId = moduleidService.getModueId();
        this.WFHURL = environment.BASE_URL_WFH;
    }

    get data() {
        return this.dataObj.asObservable();
    }

    addProxyId(proxyId){
        this.proxyIDSubject$.next(proxyId);
    }

    addCalendarView(locationId){
        this.calendarViewSubject$.next(locationId);
    }

    addLocationId(locationId){
        this.locationIDSubject$.next(locationId);
    }

    bookingCalender(data) {
        this.wayFindingData.next(data);
    }

    getAllsuboordinates(zoneId, userId) {
        let url =
            this.WFHURL +
            `wfh-users/getAll/suboordinates?managerId=` +
            userId;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    addBulk(zoneId, data, proxyId) {
        let url = this.WFHURL + `wfh-users/addBulk?proxyId=` + proxyId;

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getUserZone(zoneId, userId) {
        let url;
        //added proxyId for getting zone specific to the user in teams and proxy tabs
        url = this.EMSURL + "zones/allSites/user?proxyId=" + userId;
        // url = this.WFHURL + `wfh-users/getDefaultZone?userId=` + userId;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getWorkingDaysByTeam(zoneId, teamId) {
        let url;
        url = this.WFHURL + `subteams/getByTeam?teamId=` + teamId;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getBatchWiseData(zoneId, teamId, zone) {
        let url;
        url =
            this.WFHURL +
            `wfh-users/subteam?teamId=` +
            teamId +
            `&zoneId=` +
            zone;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getThreshold(zoneId, teamId, zone, bcp) {
        let url;
        url =
            this.WFHURL +
            `roster-schedule-request/getThresholds?teamId=` +
            teamId +
            `&zoneId=` +
            zone +
            `&isBcpEnabled=` +
            bcp;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getNewThreshold(zoneId, teamId, zone, bcp, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId
        }
        url =
            this.WFHURL +
            `roster-schedule-request/getThresholds/v2?teamId=` +
            teamId +
            `&zoneId=` +
            zone +
            `&isBcpEnabled=` +
            bcp + `${extraParams}`;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getTeams(zoneId, userId) {
        let url;
        if (userId) {
            url = this.WFHURL + `teams/getTeams?proxyId=` + userId;
        } else {
            url = this.WFHURL + `teams/getTeams`;
        }

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getAllTeamsData(zoneId, proxyId) {
        let url;
        if (!!proxyId)
            url = this.WFHURL + `teams/getUserTeams?proxyId=` + proxyId;
        else url = this.WFHURL + `teams/getUserTeams`;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getAllTeams(zoneId) {
        let url;
        url = this.WFHURL + `teams/getUserTeams`;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    addUserToTeam(zoneId, data, userId) {
        let url;
        if (userId) {
            url = this.WFHURL + `wfh-users/add?proxyId=` + userId;
        } else {
            url = this.WFHURL + `wfh-users/add`;
        }

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    scheduler(zoneId, data, userId) {
        let url;
        if (userId) {
            url =
                this.WFHURL +
                `roster-schedule-request/generate?proxyId=` +
                userId;
        } else {
            url = this.WFHURL + `roster-schedule-request/generate`;
        }

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getUsersByTeamId(zoneId, data) {
        let url;
        url = this.WFHURL + `teams/user?teamId=` + data;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getTeamStatTiles(zoneId, userId) {
        let url;
        if (userId) {
            url = this.WFHURL + `teams/stats?proxyId=` + userId;
        } else {
            url = this.WFHURL + `teams/stats`;
        }

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getAllUserTeamSearch(zoneId, searchString, time, userId) {
        let url;
        if (!searchString) {
            searchString = "";
        }
        if (userId) {
            url =
                this.WFHURL +
                `teams/getAll?searchString=` +
                searchString +
                `&time=` +
                time +
                `&proxyId=` +
                userId;
        } else {
            url =
                this.WFHURL +
                `teams/getAll?searchString=` +
                searchString +
                `&time=` +
                time;
        }

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getAllUserTeamByDate(zoneId, time) {
        let url;
        url = this.WFHURL + `teams/getAll?time=` + time;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getLocationForTeam(zoneId, id) {
        let url;
        url = this.WFHURL + `teams/getLocationsForTeam?id=` + id;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    deleteNewTeam(zoneId, id, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId
        }
        url = this.WFHURL + `teams/remove?id=` + id+`${extraParams}`;

        return this.http
            .delete<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    deleteTeam(zoneId, id) {
        let url;
        url = this.WFHURL + `teams/remove?id=` + id;

        return this.http
            .delete<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    deleteWfhUserNewTeam(zoneId, id, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId
        }
        url = this.WFHURL + `wfh-users/remove?id=` + id+`${extraParams}`;
        
        return this.http
            .delete<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    deleteWfhUserTeam(zoneId, id) {
        let url;
        
        url = this.WFHURL + `wfh-users/remove?id=` + id;
        
        return this.http
            .delete<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getCalendarRequestType(zoneId) {
        let url;
        url = this.BASEURL + `ems/module/config`;
        this.moduleId = 483;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }


    getUsersMulitpleLocation(zoneId,siteId,mangerId, proxyId) {
        if(!!proxyId){
            mangerId = proxyId
        }
        let url;
        url = this.WFHURL + `teams/multiSiteSubordinates?managerId=${mangerId}&zoneId=${siteId}`;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getUserCalendar(zoneId, userId, startTime, endTime) {
        let url;
        url =
            this.WFHURL +
            `calendar/user?id=` +
            userId +
            `&startTime=` +
            startTime +
            `&endTime=` +
            endTime;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    updateTeam(zoneId, data) {
        let url;
        url = this.WFHURL + `teams/update`;

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    createTeam(zoneId, data, userId) {
        let url;
        if (userId) {
            url = this.WFHURL + `teams/create?proxyId=` + userId;
        } else {
            url = this.WFHURL + `teams/create`;
        }

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    changeRequest(zoneId, data) {
        let url;
        url = this.WFHURL + `requests/user/create`;

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getTeamRosterCalendar(zoneId, teamId, start, end) {
        let url;
        url =
            this.WFHURL +
            `calendar/team?id=` +
            teamId +
            `&startTime=` +
            start +
            `&endTime=` +
            end;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }
    
    getTeamRosterCalendarV2(zoneId, teamId, start, end, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId
        }
        url =
            this.WFHURL +
            `calendar/teamV2?id=` +
            teamId +
            `&startTime=` +
            start +
            `&endTime=` +
            end + 
            `${extraParams}`;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getPendingRequestsData(zoneId, requestType, userId) {
        let url;
        if (userId) {
            url =
                this.WFHURL +
                `requests/admin?requestType=` +
                requestType +
                `&isPending=true&proxyId=` +
                userId;
        } else {
            url =
                this.WFHURL +
                `requests/admin?requestType=` +
                requestType +
                `&isPending=true`;
        }

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getUserReqData(zoneId, userId) {
        let url;
        url =
            this.WFHURL +
            `requests/user?userId=` +
            userId +
            `&isPending=false`;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    appRejPendingRequests(zoneId, data, userId) {
        let url;
        if (userId) {
            url = this.WFHURL + `requests/approve?proxyId=` + userId;
        } else {
            url = this.WFHURL + `requests/approve`;
        }

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getFloorDetailsByIdTIme(zoneId, data) {
        let url =
            this.WFHURL +
            `teams/mapView?userId=` +
            data.userId +
            `&time=` +
            data.time;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getFloorDetailsByFloorId(zoneId, data) {
        let url =
            this.BASEURL +
            `spacemanagement/onboarding/floorOnboarding?zoneId=${data.zoneId}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getDateBlock(zoneId, start) {
        let url = this.WFHURL + `calendar/getBlock?date=` + start;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    showSaveButton(zoneId, userId) {
        let url;
        if (userId) {
            url =
                this.WFHURL +
                `roster-schedule-request/showAssignButton?proxyId=` +
                userId;
        } else {
            url = this.WFHURL + `roster-schedule-request/showAssignButton`;
        }
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    saveAssignDesksToByTeamId(zoneId,teamId){
        let url;
        
            url = this.WFHURL + `roster-schedule-request/save/v2?teamId=${teamId}`;

        return this.http
            .post<any>(url, null, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    saveAssignDesks(zoneId, userId) {
        let url;
        if (userId) {
            url =
                this.WFHURL +
                `roster-schedule-request/save?proxyId=` +
                userId;
        } else {
            url = this.WFHURL + `roster-schedule-request/save`;
        }

        return this.http
            .post<any>(url, null, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getSwapEligibleRecords(zoneId, source, start, end) {
        let url =
            this.WFHURL +
            `calendar/swapFiltered?userId=` +
            source +
            `&startTime=` +
            start +
            `&endTime=` +
            end;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    
    getSwapEligibleRecordsV2(zoneId, source, start, end) {
        let url =
            this.WFHURL +
            `calendar/swapFilteredV2?userId=` +
            source +
            `&startTime=` +
            start +
            `&endTime=` +
            end;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    swapRecords(zoneId, data) {
        let url;
        url = this.WFHURL + `calendar/swap`;

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    swapNewRecords(zoneId, data, proxyId) {
        let extraParams = '';
        if(!!proxyId){
            extraParams = '?proxyId='+proxyId
        }
        let url;
        url = this.WFHURL + `calendar/swap${extraParams}`;

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getSwapEligibleNewRecordsV2(zoneId, source, start, end, proxyId) {
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId
        }
        let url =
            this.WFHURL +
            `calendar/swapFilteredV2?userId=` +
            source +
            `&startTime=` +
            start +
            `&endTime=` +
            end+`${extraParams}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    clearFutureRoster(zoneId, userId) {
        let url;
        if (userId) {
            url =
                this.WFHURL +
                `roster-schedule-request/clear?proxyId=` +
                userId;
        } else {
            url = this.WFHURL + `roster-schedule-request/clear`;
        }

        return this.http
            .delete<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getLeavesChartData(zoneId, start, end, teamId) {
        let url =
            this.WFHURL +
            `analytics/leaves?startTime=` +
            start +
            `&endTime=` +
            end +
            `&teamId=` +
            teamId;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getTeamChartData(zoneId) {
        let url = this.WFHURL + `analytics/teams`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getChangeReqData(zoneId, teamId) {
        let url = this.WFHURL + `analytics/changeRequests?teamId=` + teamId;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getWfhBarChartData(zoneId, start, end, teamId) {
        let url =
            this.WFHURL +
            `analytics/wfhBar?startTime=` +
            start +
            `&endTime=` +
            end +
            `&teamId=` +
            teamId;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    swapSeats(zoneId, data) {
        let url;
        url = this.WFHURL + `rosters/swapSeats`;

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    getAllGeneral(zoneId, userId, pageNo) {
        let url =
            this.WFHURL + `proxy/getAll?proxyType=GENERAL&pageNo=${pageNo}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getAllGeneralHybridManage(zoneId, userId, pageNo, pageSize, searchUserId) {
  
    let searchParams = "";
    if (!!searchUserId) {
        searchParams = `&userId=${searchUserId}`;
    }
        let url =
            this.WFHURL + `proxy/getAll?proxyType=GENERAL&pageNo=${pageNo}&size=${pageSize}` + searchParams;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getHybridAllSearchProxyList(zoneId, userId,proxyId) {
        let url;
        if(!!proxyId){
            url = this.WFHURL + `proxy/getAllForSearch?proxyType=PROXY&proxyId=`+proxyId;
        } else {
            url = this.WFHURL + `proxy/getAllForSearch?proxyType=PROXY&proxyId=`+userId;
        }
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getHybridAllProxy(zoneId, userId, pageNo,proxyId) {
        let url;
        if(!!proxyId){
            url = this.WFHURL + `proxy/getAll?proxyType=PROXY&pageNo=` + pageNo + `&userId=`+proxyId;
        } else {
        url = this.WFHURL + `proxy/getAll?proxyType=PROXY&pageNo=` + pageNo + `&userId=`+userId;
        }
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getAllProxy(zoneId, userId, pageNo) {
        let url =
          this.WFHURL + `proxy/getAll?proxyType=PROXY&pageNo=`+ pageNo;
        return this.http.get<any>(url, {
          params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
      }


    getAllSearchProxyList(zoneId, userId) {
        let url =this.WFHURL + `proxy/getAllForSearch?proxyType=PROXY`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    acceptProxy(zoneId, data) {
        let url = this.WFHURL + `proxy/approve`;
        return this.http.put<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    deleteProxyData(zoneId, id){
        let url = this.WFHURL + `proxy/delete?id=`+id;
        return this.http.delete<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    rejectProxy(zoneId, data) {
        let url = this.WFHURL + `proxy/reject`;
        return this.http.put<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    createProxy(zoneId, data) {
        let url;
        url = this.WFHURL + `proxy/create`;

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe();
    }

    addGeneralUser(zoneId, userId) {
        var data = {};
        data["userId"] = userId;
        let url = this.WFHURL + `proxy/createGeneral`;
        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError((err) => of([]))
            );
    }

    getProxyUser(zoneId,userId) {
        let url = this.WFHURL + `proxy/getManagerProxy`
        if(!!userId){
           url = this.WFHURL + `proxy/getManagerProxy?userId=`+userId;
        }
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    deleteProxy(zoneId,proxy = null) {
        let url;
        url = this.WFHURL + `proxy/disable`;
        if(!!proxy){
            url+="?userId="+proxy;
        }
        return this.http.post<any>(url, null, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getEmployeeNameAutoCompleteList(data, zoneId): Observable<any> {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
            return of([]);
        }
        let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email`;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError((err) => of([]))
            );
    }

    getAllTeamsByLocationV2(zoneId,selectedZoneId, proxyId){

        let extraParams = '';
        let url;
        url = this.WFHURL + `teams/getReportingTeams/v2`;
        if(!!selectedZoneId){
            url+= '?zoneId='+selectedZoneId
        }

        if(!!proxyId){
            url+= '&proxyId='+proxyId
        }
        return this.http
        .get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
        .pipe(
        ); 
    }
    
    getAllTeamsByLocatioin(zoneId,selectedZoneId, proxyId){

        let extraParams = '';
        let url;
        url = this.WFHURL + `teams/getReportingTeams`;
        if(!!selectedZoneId){
            url+= '?zoneId='+selectedZoneId
        }

        if(!!proxyId){
            url+= '&proxyId='+proxyId
        }
        return this.http
        .get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
        .pipe(
        ); 
    }
    getAllNewHybridTeamsLocation(zoneId, proxyID){
       let url;
       let extraParams = '';
       if(!!proxyID){
        extraParams = '?proxyId='+proxyID;
       }
        url = this.WFHURL + `teams/getCards${extraParams}`;
        return this.http
        .get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
        .pipe(
        ); 
    }

    editUsers(zoneId,data, proxyId, type?){
        let url;
        let extraParams = '';
        let extraParamsQues = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId;
            extraParamsQues = '?proxyId='+proxyId;
        }
        if(type=='PROCEED'){
            url = this.WFHURL + `wfh-users/updateOrAdd?isCreate=true${extraParams}`;
            return this.http
            .put<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe();
        }else{
            url = this.WFHURL + `wfh-users/updateOrAdd${extraParamsQues}`;
            return this.http
            .put<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe();
        }
    }

    addToExistingTeam(zoneId,data, proxyId){
        let url;

            url = this.WFHURL + `wfh-users/addBulkV2`;
            return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe();
      
    }

    saveNewMembers(zoneId,data){
        let url;
        url = this.WFHURL + `wfh-users/updateOrAdd?isCreate=true`;
        return this.http
        .put<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
        .pipe();
    }

    getAllSchedulerRequestsList(zoneId, selectedZoneId, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId;
        }
        //url = this.WFHURL + `teams/getAllTeamSchedules?teamId=${teamId}${extraParams}`;
          url = this.WFHURL + `teams/getAllSchedulesByZone?zoneId=${selectedZoneId}${extraParams}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getAllSchedulerSettingsByTeamLevel(zoneId, selectedZoneId, proxyId) {
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId;
        }
        let url = this.WFHURL + `teams/getSettings?zoneId=${selectedZoneId}${extraParams}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getLocationBasedScheduler(zoneId, locationId, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId;
        }
        url = this.WFHURL + `teams/getAllTeamSchedules?zoneId=${locationId}${extraParams}`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    deleteScheduler(zoneId, scheduleId, startDate, endDate, scheduleType, teamIds, proxyId) {

        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '&proxyId='+proxyId;
        }
        if(scheduleType == 'EMPLOYEE'){
            url = this.WFHURL + `rosters/deleteRostersWithFilters?timeRangeIds=${scheduleId}&startTime=${startDate}&endTime=${endDate}&teamIds=${teamIds}${extraParams}`;
        }else{
            url = this.WFHURL + `rosters/deleteRostersWithFilters?rosterScheduleIds=${scheduleId}&startTime=${startDate}&endTime=${endDate}&teamIds=${teamIds}${extraParams}`;
        }  
        return this.http.delete<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
        
    }

    getUserType(zoneId){
        let url;
        url = this.WFHURL + `wfh-settings/getSettingsForUser`;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
              })
            .pipe(
            );
    }

    getAllAvailableSeatAndBookedSeats(data,zoneId){
        let url;
        if (data.type!=undefined && data.type=="teamMateCalender") {
            url = this.WFHURL + `calendar/getAllStatusAndAvailableAndBookedSeats?startTime=${data.firstDate}&endTime=${data.lastDate}&collegueUserId=${data.userId}`;
        } else {
            url = this.WFHURL + `calendar/getAllStatusAndAvailableAndBookedSeats?startTime=${data.firstDate}&endTime=${data.lastDate}`;
        }
        
      
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
              })
            .pipe();
        
    }

    getSeatAvailablity(zoneId, data){
        let url = this.SPACEURL+"entity/findAvailableCountsForEachDayInGivenRangeWithOptionalFilter";
        
        
        return this.http
                .post<any>(url, data, {
                    params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
                  })
                .pipe();
    }

    notifyTeam(zoneId, userId,startTime,endTime){
        let url = this.WFHURL+`notification/notifyEmailAndPush?userId=${userId}&startTime=${startTime}&endTime=${endTime}`;
        return this.http
                .post<any>(url,{}, {
                    params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
                  })
                .pipe();
    }

    allocateSeatsAutomatic(data,zoneId, type, editFlow, startTime, endTime){
        let status = true;
        if(type == "MANUAL") {
            status = false;
        }
        let url;

        url = this.WFHURL + `rosters/createRostersForSpecificDaysV2?isAutoAllocationEnabled=${status}&editFlow=${editFlow}&startTime=${startTime}&endTime=${endTime}`;
       
        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
              })
            .pipe();
        
    }

    updateConfigSettings(data,zoneId,reset=true){    
        let url;
        url = this.WFHURL + `wfh-settings/save?isReset=${reset}`
        return this.http.post(url,data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
          })
    }

    getConfigSettings(zoneId) {
        let url;
        url = this.WFHURL + `wfh-settings/get`;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
              })
            .pipe(
            );
    }

    getTeamSettigs(zoneId) {
        let url;
        url = this.WFHURL + `teams/getSettingsByTeam`;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
              })
            .pipe(
            );
    }

    getTeamSettigsByLocation(zoneId, locId, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = `&proxyId=${proxyId}`;
        }
        url = this.WFHURL + `teams/getSettings?zoneId=${locId}${extraParams}`;

        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
              })
            .pipe(
            );
    }

    schedulerNew(modalData, data, proxyId) {

        let url;
        let extraParams = '';
        let updateExtraParams = '';
        if(!!proxyId){
            extraParams = '?proxyId='+proxyId;
        }
        let zoneId = modalData.currentZone;
        if(modalData["type"] == "editScheduleForTeam") {
            let updateRosterScheduleId = modalData['teamsList']['schedulerId'];
            url = this.WFHURL + `roster-schedule-request/generateV2?updateRosterScheduleId=${updateRosterScheduleId}`;
        } else {
            url = this.WFHURL + `roster-schedule-request/generateV2${extraParams}`;
        }

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(
            );
    }

    updateScheduler(zoneId, data) {
        let url;
        url = this.WFHURL + `teams/updateSchedularOrganizerForSubTeam`;

        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(
            );
    }

    schedulerOwn(modalData, data, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '?proxyId='+proxyId;
        }
        let dataAc;
        let zoneId = modalData.teamZoneId;
        if(modalData["type"] == "editScheduleOfThereOwn") {
            dataAc = [data];
            let updateRosterScheduleId = modalData['teamsList']['schedulerId'];
            url = this.WFHURL + `timeRange/updateAll${extraParams}`;

            return this.http
            .put<any>(url, dataAc, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(
            );
        } else {
            url = this.WFHURL + `timeRange/create${extraParams}`;

            return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(
            );
        }


    }


    schedulerHybridOwn(modalData, data, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '?proxyId='+proxyId;
        }
        let dataAc;
        let zoneId = modalData.currentZone;
        if(modalData["type"] == "editScheduleOfThereOwn") {
            dataAc = [data];
            let updateRosterScheduleId = modalData['teamsList']['schedulerId'];
            url = this.WFHURL + `timeRange/updateAll${extraParams}`;

            return this.http
            .put<any>(url, dataAc, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(
            );
        } else {
            url = this.WFHURL + `timeRange/create${extraParams}`;

            return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(
            );
        }


    }

    createTeamFirstTime(zoneId, data, proxyId) {
        let url;
        let extraParams = '';
        if(!!proxyId){
            extraParams = '?proxyId='+proxyId
        }
        url = this.WFHURL + `teams/createSubTeams${extraParams}`;
        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            })
            .pipe(
            );
    }

    getWFHTeamRequestsData(zoneId, requestType,proxyId) {
        let url;
        if(!!proxyId){
            url = this.WFHURL + `requests/adminV2?proxyId=`+proxyId;
        } else {
            url = this.WFHURL + `requests/adminV2`;
        }

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getBookingAutocompleteList(data, zoneId): Observable<any> {
        let term = data.searchTerm.trim();

        /** new way department by search */
        if (data.searchType === "department") {
            let resData = [];
            this.departments$
                .pipe(
                    map((departments) =>
                        departments.filter((department) => {
                            let depName = department["name"]
                                .toLowerCase()
                                .toString();
                            let searchTerm = term.toLowerCase();
                            let res = depName.indexOf(searchTerm);
                            if (res >= 0) {
                                return department;
                            }
                        })
                    )
                )
                .subscribe((res) => {
                    resData = res;
                });

            return of(resData);
        } else {
            if (term == "" || term.length <= 2) {
                return of([]);
            }
            if (data.searchType === "employee" || data.searchType === "user") {
                let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
                return this.http
                    .get<any>(url, {
                        params: new ZoneAndModuleHttpParams(zoneId, 3),
                    })
                    .pipe(
                        map((res) => res["response"]),
                        catchError((err) => of([]))
                    );
            }
        }
    }
    getQuestionResponse(zoneId, userId) {
        let url = this.WFHURL + `question-response/getAll?userId=${userId}`;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            }).pipe(
                map(o => o.response.content)
            );
    }
    addQuestionResponse(zoneId, reqObj) {
        let url = this.WFHURL + `question-response/addAnswers`;
        return this.http
            .post<any>(url, reqObj, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            });
    }
    getWorkStatusAnalytics(zoneId, startDate, endDate){
        let url;
        url = this.WFHURL + `analytics/admin/forecast/employeeDrivenStats?startDate=${startDate}&endDate=${endDate}&zoneId=${zoneId}`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    appRejPendingNewRequests(zoneId, data) {
    let url;
    url = this.WFHURL + `requests/approve/v2`;

    return this.http
        .post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
        .pipe(
        );
    }
    
    getAdminTeamForecast(zoneId, data){
        let url;
        url = this.WFHURL + `analytics/admin/forecast/userWithDateRangeDeptWise`;

        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getEmployeeForecast(zoneId, startDate, endDate, deptId){
        let url;
        url = this.WFHURL + `analytics/admin/forecast/userWithDateRangeUserWise?startDate=${startDate}&endDate=${endDate}&deptId=${deptId}`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        }); 
    }

    getTeamStatus(zoneId, startDate, endDate, deptId){
        let url;
        url = this.WFHURL + `analytics/admin/forecast/userCompareWithDateRange?currentStartDate=${startDate}&currentEndDate=${endDate}&compareStartDate=${startDate}&compareEndDate=${endDate}&deptId=${deptId}`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        }); 
    }

    getPercentageForUser(zoneId, currStartDate, currLastDate, compareStartDate, compareLastDate, userId){
        let url;
        url = this.WFHURL + `analytics/manager/forecast/userCompareWithDateRange?currentStartDate=${currStartDate}&currentEndDate=${currLastDate}&compareStartDate=${compareStartDate}&compareEndDate=${compareLastDate}&userId=${userId}`;

        
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getEmpForcast(zoneId, startDate, endDate, deptId){
        let url;
        url = this.WFHURL + `analytics/admin/forecast/userWithDateRangeUserWise?startDate=${startDate}&endDate=${endDate}&deptId=${deptId}`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        }); 
    }

    getAllSearchGeneralList(zoneId, userId) {
        let url = this.WFHURL + `proxy/getAllForSearch?proxyType=GENERAL`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAdminForecast(zoneId, startDate, endDate){
        let url;
        url = this.WFHURL + `analytics/admin/getTeamWisePercentages?startDate=${startDate}&endDate=${endDate}`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getTeamTrends(zoneId, startDate, endDate, location, team, workStatus,proxyItemId = null ) {
          
        let url = this.WFHURL + `analytics/manager/forecast/userWithDateRangeUserWise?startDate=${startDate}&endDate=${endDate}&zoneId=${location}&teamId=${team}&status=${workStatus}`;
        if(!!proxyItemId){
            url += "&proxyId="+proxyItemId;
        }
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getTimeline(zoneId, startDate, endDate, location, team, workStatus,proxyItemId = null) {
        let url = this.WFHURL + `analytics/manager/forecast/userWithDateRangeDayWise?startDate=${startDate}&endDate=${endDate}&zoneId=${location}&teamId=${team}&status=${workStatus}`;
        if(!!proxyItemId){
            url += "&proxyId="+proxyItemId;
        }
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getTimelineDataforDeptDayWise(zoneId, startDate, endDate, deptId) {
        let url = this.WFHURL + `analytics/admin/forecast/userWithDateRangeDayWise?startDate=${startDate}&endDate=${endDate}&deptId=${deptId}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getUserstatus(zoneId, userId) {
        let url = this.WFHURL + `wfh-users/getCurrentWorkStatus?proxyId=${userId}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getLocationTeams(zoneId,proxyItemId=null){
        let url = this.WFHURL + `teams/getReportingTeams`;
        if(!!proxyItemId){
            url += "?proxyId="+proxyItemId;
        }
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getAllDepartments(zoneId){
        let url = this.EMSURL + `department/getAll`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    getManagerDrivenStats(zoneId,startDate,endDate){
        let url;
        url = this.WFHURL + `analytics/admin/forecast/managerDrivenStats?startDate=${startDate}&endDate=${endDate}&zoneId=${zoneId}`;

        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        });
    }

    getMgrEmpPreference(zoneId){
        let url = this.WFHURL + `teams/getReportingTeams`;
        // return this.http.get<any>(url, {
        //     params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        // });
        return {
            "response": {
              "currentPercentage": {
                  "employeeDrivenPercentage": 25.0,
                  "managerDrivenPercentage": 75.0
              },
              "comparePercentage": null
          }
          }
    }

    wayFinding(zoneId,reqObj) {
        let url = `https://routing.smartenspaces.com/wayfinding/routeToSeat?zone_id=${reqObj.zoneId}&entity_id=${reqObj.entityInfoId}&base_url=${environment.BASE_URL}`;
        //let url = `https://routing.smartenspaces.com/wayfinding/routeToSeat?zone_id=29&entity_id=1261&base_url=https://jumpreeparallel.smartenspaces.com`;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            });
    }

    getAvailabilityMapDataBooking(reqObj, currentZone): Observable<any> {
        let url
        if(localStorage.getItem('tenant_Engagement') == "true"){
        url = this.SPACEURL+"/entity/availabilityMapViewForListOfDaysOptimized/tenant";
        }else{
        url = this.SPACEURL+"/entity/availabilityMapViewForListOfDaysOptimized";
        }
        return this.http
            .post<any>(url, reqObj, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
            })
            .pipe(
                
                map((res) => res["response"]),
                catchError((err) => throwError(err))
            );
    }

    getManager(zoneId,userId): Observable<any>{
        let url = this.EMSURL +`user/isManager?userId=`+userId;
        return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
            });
    }

    createNewTeam(zoneId,data, proxyId) {
        let proxyIdParam = '';
        if(!!proxyId){
            proxyIdParam += "?proxyId="+proxyId;
        }
        let url =  this.WFHURL+"teams/createSubTeams/v2"+proxyIdParam;
        return this.http
            .post<any>(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
            .pipe(
                map((res) => res["response"]),
                catchError((err) => throwError(err))
            );
    }

}
