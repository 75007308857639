import * as tslib_1 from "tslib";
import { HttpHeaders } from "@angular/common/http";
import { Subject, throwError, BehaviorSubject, of } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { v4 as uuidv4 } from "uuid";
export class OnboardingService {
    constructor(http, baseUrl, moduleidService) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.departmentSubject$ = new BehaviorSubject([]);
        this.departments$ = this.departmentSubject$.asObservable();
        this.buildingSubject$ = new BehaviorSubject([]);
        this.buildings$ = this.buildingSubject$.asObservable();
        this.floorSubject$ = new BehaviorSubject([]);
        this.floors$ = this.floorSubject$.asObservable();
        this._refreshNeeded = new Subject();
        /**autocomplete */
        this.handleError = (err) => {
            /*let errorMsgData = (err as any).error;
            const message = errorMsgData["message"];
            this.toastrService.error(message);*/
            return throwError(err);
        };
        //this.BASE_URL = environment.BASE_URL+'ems/';
        //alert(baseUrl);
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.BASEEMSURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
    }
    get refreshNeeded() {
        return this._refreshNeeded;
    }
    getAllFloorDetails(zoneId, data) {
        let searchParams = data.searchArr;
        if (searchParams) {
            let status = "states=PARTIAL,COMPLETE";
            let leaseStart = "";
            let leaseEnd = "";
            let buildingIds = "";
            if (searchParams.status != "") {
                status = "states=" + searchParams.status;
            }
            if (searchParams.leaseStart != "") {
                leaseStart = "&leaseStart=" + searchParams.leaseStart;
            }
            if (searchParams.leaseEnd != "") {
                leaseEnd = "&leaseEnd=" + searchParams.leaseEnd;
            }
            if (searchParams.buildingIds != "") {
                buildingIds = "&buildingIds=" + searchParams.buildingIds;
            }
            searchParams = status + leaseStart + leaseEnd + buildingIds;
        }
        else {
            searchParams = "";
        }
        //const headers = new HttpHeaders().set('ss-header', '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":1,"sessionId":"2c234e44-c39f-4a0b-ba78-52992466abbd","enterpriseId":1}');
        //let url = `http://192.168.1.37:9084/spacemanagement/onboarding/floorDetails/getAll?size=${data.pageSize}&page=${data.pageIndex}`+searchParams;
        let url = this.BASEURL + `onboarding/floorDetails?` + searchParams;
        //return this.http.get<any>(url, { headers: headers });
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllWorkStations(zoneId, data) {
        let searchParams = "";
        if (data.searchType != "" && data.searchValue != "") {
            searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
        }
        // let url =
        //     this.BASEURL +
        //     `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
        //     searchParams;
        let url = this.BASEURL +
            `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
            searchParams;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getActiveWorkStations(zoneId, data) {
        let searchParams = "";
        if (data.searchType != "" && data.searchValue != "") {
            searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
        }
        // let url =
        //     this.BASEURL +
        //     `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
        //     searchParams;
        let url = this.BASEURL +
            `/entitytype/getAllEnabled?size=${data.pageSize}&page=${data.pageIndex}` +
            searchParams;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    sendPostRequest(data) {
        let url = this.BASEURL + "transaction/update-status";
        return this.http.post(url, data);
    }
    changeActivityStatus(zoneId, data) {
        let url = this.BASEURL + "socialdistancing/changeReEntryStatus";
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    createOrUpdateAssests(zoneId, data, pageType) {
        let url;
        if (pageType == "editWorkStation") {
            url = this.BASEURL + "entitytype/update";
            return this.http
                .put(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
                .pipe(tap(() => {
                this._refreshNeeded.next();
            }));
        }
        else {
            url = this.BASEURL + "entitytype/create";
            return this.http
                .post(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
                .pipe(tap(() => {
                this._refreshNeeded.next();
            }));
        }
    }
    createOrUpdateWorkStation(zoneId, data, pageType) {
        let url;
        if (pageType == "editWorkStation") {
            url = this.BASEURL + "entitytype/update";
            return this.http
                .put(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
                .pipe(tap(() => {
                this._refreshNeeded.next();
            }));
        }
        else {
            url = this.BASEURL + "entitytype/create";
            return this.http
                .post(url, data, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
                .pipe(tap(() => {
                this._refreshNeeded.next();
            }));
        }
    }
    UpdateBuilding(zoneId, data) {
        let url = this.EMSURL + "zones";
        return this.http
            .put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(tap(() => {
            this._refreshNeeded.next();
        }));
    }
    createOrUpdateDepartment(zoneId, data, pageType) {
        let url;
        url = this.EMSURL + "department/";
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(tap(() => {
            this._refreshNeeded.next();
        }));
    }
    enableOrDisableFloor(zoneId, data) {
        let url;
        if (data.type == "enable") {
            url = this.BASEURL + `onboarding/floor/enable`;
        }
        else {
            url = this.BASEURL + `onboarding/floor/disable`;
        }
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(tap(() => {
            //this._refreshNeeded.next();
        }));
    }
    enableOrDisableWorkStation(zoneId, data) {
        let url;
        var id = {};
        if (data.type == "ENABLED") {
            url = this.BASEURL + `entitytype/enable`;
            id["id"] = data.id;
        }
        else {
            url = this.BASEURL + `entitytype/disable`;
            id["id"] = data.id;
        }
        return this.http
            .put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(tap(() => {
            this._refreshNeeded.next();
        }));
    }
    enableOrDisableDepartment(zoneId, data) {
        let url;
        url = this.EMSURL + `department/`;
        var id = {};
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(tap(() => {
            this._refreshNeeded.next();
        }));
    }
    enableOrDisablePeople(zoneId, data) {
        let url;
        url = this.EMSURL + `user/admin/changeStatus`;
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe();
    }
    downloadFile(zoneId, data) {
        let url;
        url = this.BASEURL + `templates/download/key/${data}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    downloadFloor(zoneId) {
        let url;
        url = this.BASEURL + `onboarding/download/FloorDetails`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    loadPeople(zoneId, data) {
        let url = this.EMSURL +
            `user/all?sortBy=createdAt&searchColumn=${data.searchBy}&searchValue=${data.searchValue}&page=${data.pageIndex}&size=${data.pageSize}&defaultZoneId=${zoneId}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getStatus(currentZone) {
        const url = `${this.EMSURL}activityStatus/all`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        });
    }
    postFile(zoneId, fileToUpload) {
        let url = this.BASEURL + `allocation/uploadCSV`;
        const formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        var yourHeadersConfig = {
            "content-type": "multipart/form-data",
        };
        return this.http.post(url, formData, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    postPeopleFile(zoneId, fileToUpload) {
        let url = this.EMSURL + `user/uploadCSV`;
        const formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        var yourHeadersConfig = {
            "content-type": "multipart/form-data",
        };
        return this.http.post(url, formData, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getFloorDetailsByFloorId(zoneId, data) {
        let url = this.BASEURL + `onboarding/floorOnboarding?zoneId=${data.zoneId}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllAllocations(zoneId, data) {
        let searchParams = "";
        if (data.searchType != "" && data.searchValue != "") {
            searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
        }
        // let url =
        //     this.BASEURL +
        //     `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
        //     searchParams;
        let url = this.BASEURL +
            `allocation/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
            searchParams;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    searchBookingStatus(searchString, zoneId, pageSize, pageIndex) {
        let url = this.BASEURL +
            `allocation/search/checkIn?` +
            searchString +
            `&size=` +
            pageSize +
            `&page=` +
            pageIndex;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    searchMeetingRoomBookingStatus(searchString, zoneId, pageSize, pageIndex) {
        let url = this.BASEURL +
            `allocation/search/checkIn?` +
            searchString +
            `&size=` +
            pageSize +
            `&page=` +
            pageIndex;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, 3),
        });
    }
    searchAllocation(searchString, zoneId, pageSize, pageIndex) {
        let url = this.BASEURL +
            `allocation/search?` +
            searchString +
            `&size=` +
            pageSize +
            `&page=` +
            pageIndex +
            `&isPermanant=true`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    deleteAllocation(zoneId, data) {
        let url = this.BASEURL + `booking/cancel`;
        return this.http.put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    deleteMeetinRoomAllocation(zoneId, data) {
        let url = this.BASEURL + `booking/cancel`;
        return this.http.put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, 3),
        });
    }
    searchAllocationStatus(searchString, zoneId, pageSize, pageIndex) {
        let url = this.BASEURL +
            `allocation/search?` +
            searchString +
            `&size=` +
            pageSize +
            `&page=` +
            pageIndex +
            `&isPermanant=false`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    searchMeetingRoomAllocationStatus(searchString, zoneId, pageSize, pageIndex) {
        let url = this.BASEURL +
            `allocation/search?` +
            searchString +
            `&size=` +
            pageSize +
            `&page=` +
            pageIndex +
            `&isPermanant=false`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, 3),
        });
    }
    getAllDepartments(zoneId) {
        let url = this.EMSURL + `department/getAll`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getLocationAvailability(currentZone, rowdata, floorId = "") {
        const url = `${this.BASEURL}entity/findAvailableCountsSocialDistancing`;
        return this.http
            .post(url, rowdata, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    getAvailabilityMapView(currentZone, rowdata) {
        const url = `${this.BASEURL}entity/availabilityMapViewSocialDistancing`;
        return this.http
            .post(url, rowdata, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    bookSeat(currentZone, data) {
        const url = `${this.BASEURL}socialdistancing/assignSeats`;
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    /**autocomplete */
    getAutocompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
            let resData = [];
            if (data.searchType === "departmentName") {
                this.departments$.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    resData = res;
                }));
                return of(resData);
            }
            else if (data.searchType === "floor" ||
                data.searchType === "deskNumber") {
                this.buildings$.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    resData = res;
                }));
                return of(resData);
            }
            else {
                return of([]);
            }
        }
        // if(term == "" || term.length <= 2) {
        //     return of([]);
        // }
        if (data.searchType === "user") {
            let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
            return this.http
                .get(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
                .pipe(map((res) => res["response"]), catchError((err) => of([])));
        }
        else if (data.searchType === "departmentName") {
            let resData = [];
            this.departments$
                .pipe(map((departments) => departments.filter((department) => {
                let depName = department["name"].toLowerCase().toString();
                let searchTerm = term.toLowerCase();
                let res = depName.indexOf(searchTerm);
                if (res >= 0) {
                    return department;
                }
            })))
                .subscribe((res) => {
                resData = res;
            });
            return of(resData);
        }
        else if (data.searchType === "floor" ||
            data.searchType === "deskNumber") {
            let resData = [];
            this.buildings$
                .pipe(map((buildings) => buildings.filter((building) => {
                let depName = building["name"].toLowerCase().toString();
                let searchTerm = term.toLowerCase();
                let res = depName.indexOf(searchTerm);
                if (res >= 0) {
                    return building;
                }
            })))
                .subscribe((res) => {
                resData = res;
            });
            return of(resData);
        }
    }
    getBuilding(zoneId, data) {
        let url = this.EMSURL + "zones/childZones/v2";
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(shareReplay(1), map((res) => res["response"].zoneMap[zoneId]), catchError((err) => of([])), map((res) => {
            if (res.length) {
                return res.map((res) => (Object.assign({ userId: res.zoneId }, res)));
            }
            else {
                return of([]);
            }
        }), tap((res) => {
            this.buildingSubject$.next(res);
        }));
    }
    /*get floor*/
    getFloors(zoneId, data) {
        let url = this.EMSURL + "zones/childZones/v2";
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(shareReplay(1), map((res) => res["response"]["zoneMap"][data.zoneIds[0]]), map((res) => {
            if (res.length) {
                return res.map((_a) => {
                    var { id: userId } = _a, rest = tslib_1.__rest(_a, ["id"]);
                    return (Object.assign({ userId }, rest));
                });
            }
            else {
                return of([]);
            }
        }), tap((res) => {
            this.floorSubject$.next(res);
        }), catchError((err) => of([])));
    }
    /* for floor dropdown data*/
    getFloorDropDown(data) {
        // let term = data.searchTerm.trim();
        let resData = [];
        this.floors$
            .pipe(map((floors) => floors.filter((floor) => {
            let depName = floor["name"].toLowerCase().toString();
            let searchTerm = data.trim().toLowerCase();
            let res = depName.indexOf(searchTerm);
            if (res >= 0) {
                return floor;
            }
        })))
            .subscribe((res) => {
            resData = res;
        });
        return of(resData);
    }
    getDepartmentList(zoneId) {
        let url = `${this.EMSURL}department/getAll`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(shareReplay(1), map((res) => res["response"]), catchError((err) => of([])), map((res) => {
            if (res.length) {
                return res.map((_a) => {
                    var { id: userId } = _a, rest = tslib_1.__rest(_a, ["id"]);
                    return (Object.assign({ userId }, rest));
                });
            }
            else {
                return of([]);
            }
        }), tap((res) => {
            this.departmentSubject$.next(res);
        }));
    }
    getTenants(currentZone) {
        let url = this.BASEEMSURL + `tms/tenant/list?page=0&size=10000&sort=createdAt,desc`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        });
    }
    getAllShifts(currentZone) {
        let url = this.BASEEMSURL + `ems/shifts/getAll`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        });
    }
    saveUserDetails(zoneId, data) {
        let url = this.EMSURL + "user/admin/addUser";
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(tap(() => {
            this._refreshNeeded.next();
        }));
    }
    saveupdatedUserDetails(zoneId, data) {
        let url = this.EMSURL + "user/admin/updateUserDetails";
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(tap(() => {
            this._refreshNeeded.next();
        }));
    }
    deleteFutureBookings(zoneId, data) {
        let url;
        url = environment.BASE_URL + "amenitybooking/booking/cancelBookings";
        const headers = new HttpHeaders({
            "ss-header": '{"correlationId":"' +
                uuidv4() +
                '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                zoneId +
                ',"moduleId":12001,"sessionId":"' +
                localStorage.getItem("ssadmin_session") +
                '","locale":"en"}'
        });
        return this.http.put(url, data, { headers: headers, responseType: 'blob' });
    }
    deleteFutureBookingspacemanagement(zoneId, data) {
        let url;
        url = environment.BASE_URL + "spacemanagement/booking/cancelBookings";
        const headers = new HttpHeaders({
            "ss-header": '{"correlationId":"' +
                uuidv4() +
                '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                zoneId +
                ',"moduleId":12001,"sessionId":"' +
                localStorage.getItem("ssadmin_session") +
                '","locale":"en"}'
        });
        return this.http.put(url, data, { headers: headers, responseType: 'blob' });
    }
}
