import { BehaviorSubject, of } from "rxjs";
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../services/moduleid-service";
export class DemandSupplyServiceService {
    constructor(http, baseUrl, moduleidService) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.moveDataSubject = new BehaviorSubject(null);
        this.moveDataObj = this.moveDataSubject.asObservable();
        this.departmentSubject$ = new BehaviorSubject([]);
        this.configSubject = new BehaviorSubject([]);
        this.configObj = this.configSubject.asObservable();
        this.departments$ = this.departmentSubject$.asObservable();
        this.userSubject$ = new BehaviorSubject([]);
        this.users$ = this.userSubject$.asObservable();
        //this.BASE_URL = environment.BASE_URL+'ems/';
        //alert(baseUrl);
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.SPACEURL = environment.BASE_URL_SPACE;
        this.moduleId = moduleidService.getModueId();
    }
    getAllDepartments(zoneId) {
        let url = this.EMSURL + `department/getAll`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllCostCodeByDepartment(zoneId, deptId) {
        let url = this.EMSURL +
            `costcode/getAll?departmentId=${deptId}&status=ENABLED&page=0&size=100000`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllCostCode(zoneId) {
        let url = this.EMSURL + `costcode/getAll?status=ENABLED&page=0&size=100000`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllCities(zoneId) {
        let url = this.EMSURL + `zones/getAllZones?level=CITY`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllZonesByCity(zoneId, data) {
        let url = this.EMSURL + `zones/childZones`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllUserAllocationPermanent(zoneId, departmentId, startTime) {
        let url = this.SPACEURL +
            `allocation/getAllCurrentByDemandIdAndType?demandId=${departmentId}&demandType=USER_DEPARTMENT&permanentOnly=true`;
        if (!!startTime)
            url = url + `&startTime=${startTime}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllShifts(zoneId) {
        let url = this.EMSURL + `shifts/getAll`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllWorkstations(zoneId) {
        let url = this.SPACEURL + `entitytype/getAllEnabled?&page=0&size=100000`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllDemandRequest(userId, zoneId, pageSize, pageIndex) {
        let url = this.SPACEURL +
            `demand-requests/getAllCreatedBy?userId=${userId}&page=${pageIndex}&size=${pageSize}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllDemandRequestWithStartEndAndSearch(userId, zoneId, pageSize, pageIndex, startTime, endTime, searchString) {
        let url = this.SPACEURL +
            `demand-requests/getAllCreatedBy?userId=${userId}&page=${pageIndex}&size=${pageSize}`;
        if (startTime != null)
            url = url + `&startTime=${startTime}`;
        if (endTime != null)
            url = url + `&endTime=${endTime}`;
        if (searchString != null)
            url = url + `&searchString=${searchString}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    deleteDemandRequest(zoneId, requestId) {
        let url = this.SPACEURL + `demand-requests/delete?id=${requestId}`;
        return this.http.delete(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    createSpaceRequest(data, zoneId) {
        let url = this.SPACEURL + `demand-requests/create`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    saveSettings(data, zoneId) {
        let url = this.SPACEURL + `sm-settings/saveDS`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    createMoveRequest(data, zoneId) {
        let url = this.SPACEURL + `demand-requests/create`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    downloadRequestsCsv(zoneId) {
        let url = this.SPACEURL + `demand-requests/downloadCSV`;
        // console.log("in service method", url);
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    downloadRequestsCsvForManage(zoneId, state) {
        let url = this.SPACEURL +
            `demand-requests/downloadCSVforManage`;
        if (state !== "ALL") {
            url = url + `?state=${state}`;
        }
        // console.log("in service method", url);
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    updateSpaceRequest(data, zoneId) {
        let url = this.SPACEURL + `demand-requests/update`;
        return this.http.put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllApprovals(zoneId, userId, pageSize, pageIndex) {
        let url = this.SPACEURL +
            `demand-requests/getAllForApproval?userId=${userId}&page=${pageIndex}&size=${pageSize}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllAvailability(data, zoneId) {
        let url = this.SPACEURL + `entity/availableCounts/entityType`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getEntityTypeInsights(zoneId, data) {
        // console.log(data);
        let url = this.SPACEURL + `demand-requests/insights/entityType`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAvailabilityInsights(zoneId, data) {
        let url = this.SPACEURL + `demand-requests/insights/availability`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    approveRejectRequest(zoneId, data) {
        let url;
        // if(type == 'approve'){
        url = this.SPACEURL + `request-approval-mappings/`;
        // }else if(type == 'reject'){
        // url = this.SPACEURL + `demand-requests/insights/availability`;
        // }
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllManageRequests(zoneId, whatIfType, pageIndex, pageSize, showData) {
        let url;
        if (whatIfType) {
            url =
                this.SPACEURL +
                    `demand-requests/get/manageRequest?whatIf=${whatIfType}&page=${pageIndex}&size=${pageSize}`;
        }
        else {
            url =
                this.SPACEURL +
                    `demand-requests/get/manageRequest?page=${pageIndex}&size=${pageSize}`;
        }
        if (showData === "COMPLETE") {
            url = url + `&state=COMPLETE`;
        }
        if (showData === "PENDING") {
            url = url + `&state=PENDING`;
        }
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getRecommendedFloors(zoneId, data) {
        let url;
        url = this.SPACEURL + `entity/recommendation/multiUser`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    assignAutomatically(zoneId, data) {
        let url;
        url = this.SPACEURL + `entity/move/automatic`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getUserAutoCompleteList(data, zoneId) {
        let term = data.searchTerm;
        if (data.searchType === "employee" || data.searchType === "user") {
            let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
            return this.http
                .get(url, {
                params: new ZoneAndModuleHttpParams(zoneId, 3),
            })
                .pipe(map((res) => res["response"]), catchError((err) => of([])));
        }
        else if (data.searchType === "departmentName") {
            term = data.deptSearch;
            let resData = [];
            // console.log(this.departments$);
            this.departments$
                .pipe(map((departments) => departments.filter((department) => {
                let depName = department["name"]
                    .toLowerCase()
                    .toString();
                let searchTerm = term.toLowerCase();
                let res = depName.indexOf(searchTerm);
                if (res >= 0) {
                    return department;
                }
            })))
                .subscribe((res) => {
                resData = res;
            });
            // console.log(resData);
            return of(resData);
        }
    }
    getDepartmentList(zoneId) {
        // console.log("in service");
        let url = `${this.EMSURL}department/getAll`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(shareReplay(1), map((res) => res["response"]), 
        // catchError((err) => of([])),
        // map((res) => {
        //   if (res.length) {
        //     return res.map(({ id: userId, ...rest }) => ({
        //       userId,
        //       ...rest,
        //     }));
        //   } else {
        //     return of([]);
        //   }
        // }),
        tap((res) => {
            this.departmentSubject$.next(res);
            // console.log(this.departmentSubject$);
        }));
    }
    getExistingConfig(zoneId) {
        let url = `${environment.BASE_URL}spacemanagement/sm-settings/getDS`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getUserToMoveOnFloor(zoneId, floorId, demandId, pageIndex, pageSize) {
        let url = `${environment.BASE_URL}spacemanagement/allocation/getUsersToMoveOnFloor?` +
            `zoneId=${floorId}&demandType=USER_DEPARTMENT&permanentOnly=true&` +
            `page=${pageIndex}&size=${pageSize}`;
        if (!!demandId) {
            url = url + `&demandId=${demandId}`;
        }
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllFloorDetails(zoneId, demandId) {
        let url = `${environment.BASE_URL}spacemanagement/allocation/getAllFloorsByDemandIdAndType?currentZoneId=${zoneId}`;
        if (demandId && demandId !== null)
            url = url + `&departmentId=${demandId}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getBookingAutocompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        /** new way department by search */
        if (data.searchType === "department") {
            let resData = [];
            this.departments$
                .pipe(map((departments) => departments.filter((department) => {
                let depName = department["name"]
                    .toLowerCase()
                    .toString();
                let searchTerm = term.toLowerCase();
                let res = depName.indexOf(searchTerm);
                if (res >= 0) {
                    return department;
                }
            })))
                .subscribe((res) => {
                resData = res;
            });
            return of(resData);
        }
        else {
            if (term == "" || term.length <= 2) {
                return of([]);
            }
            if (data.searchType === "employee" || data.searchType === "user") {
                let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
                return this.http
                    .get(url, {
                    params: new ZoneAndModuleHttpParams(zoneId, 3),
                })
                    .pipe(map((res) => res["response"]), catchError((err) => of([])));
            }
        }
    }
    saveApprovalBeforeBooking(zoneId, data) {
        let url = this.SPACEURL + `approvalSetup/`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getApprovalSetup(zoneId, demandId) {
        let url = `${environment.BASE_URL}spacemanagement/approvalSetup/demandId?demandType=DEPARTMENT&demandId=${demandId}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getZonesByType(zoneId, type) {
        let url = this.EMSURL + `zones/getAllZones?level=${type}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
}
DemandSupplyServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DemandSupplyServiceService_Factory() { return new DemandSupplyServiceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("BASE_URL"), i0.ɵɵinject(i2.ModuleidService)); }, token: DemandSupplyServiceService, providedIn: "root" });
