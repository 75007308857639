<form class="form" id="add_meeting" [formGroup]="addAssetForm" (ngSubmit)="onAssetSubmit()">
    <div class="row mt-4">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-lg-4">
                        <div class="form-group">
                            <label class="font-weight-bold float-left">{{'assetName' | translate | uppercase}}</label>
                            <div class="input-group">
                                <input id="asset_name" formControlName="assetName"
                                    class="form-control"
                                    [ngClass]="{'is-invalid': submitted}">
                                <!-- <div *ngIf="submitted && f.assetName.errors" class="invalid-feedback">
                                    <div *ngIf="f.assetName.errors.required">
                                        {{"Pleaseenterthename" | translate}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold float-left">{{'assetCategory' | translate | uppercase}}</label>
                            <div class="input-group">
                                <input id="asset_category" 
                                    class="form-control" formControlName="assetCategory"
                                    [ngClass]="{'is-invalid': submitted}">
                                <!-- <div *ngIf="submitted && f.assetCategory.errors" class="invalid-feedback">
                                    <div *ngIf="f.assetCategory.errors.required">
                                        {{"Pleaseenterthename" | translate}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold float-left">{{'assetManufacturer' | translate | uppercase}}</label>
                            <div class="input-group">
                                <input id="allowed_capacity" class="form-control" formControlName="manufacturer"
                                 [ngClass]="{'is-invalid':submitted }">
                                <!-- <div *ngIf="submitted && f.manufacturer.errors" class="invalid-feedback">
                                    <div *ngIf="f.manufacturer.errors.required">{{
                                    "Pleaseentertheallowedcapacity" | translate
                                }}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold float-left">{{'brand' | translate | uppercase}}</label>
                            <div class="input-group">
                                <input id="brand" class="form-control"
                                    formControlName="brand"
                                    [ngClass]="{'is-invalid':submitted}">
                                <!-- <div *ngIf="submitted && f.brand.errors" class="invalid-feedback">
                                    <div *ngIf="f.brand.errors.required">
                                        {{"Pleaseentertheoutlookemail" | translate}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold float-left">{{'assetModel' | translate | uppercase}}</label>
                            <div class="input-group">
                                <input id="asset_model"
                                    class="form-control" formControlName="model"
                                    [ngClass]="{'is-invalid': submitted}">
                                <!-- <div *ngIf="submitted && f.model.errors" class="invalid-feedback">
                                    <div *ngIf="f.model.errors.required">
                                        {{"Pleaseenterthename" | translate}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 col-lg-4">
                        <div class="form-group">
                            <label class="font-weight-bold float-left">{{'assetSerialNo' | translate | uppercase}}</label>
                            <div class="input-group">
                                <input id="asset_serial_no" class="form-control" formControlName="serialNo"
                                    [ngClass]="{'is-invalid':submitted}">
                                <!-- <div *ngIf="submitted && f.serialNo.errors" class="invalid-feedback">
                                    <div *ngIf="f.serialNo.errors.required">
                                        {{"Pleaseenterthecapacity" | translate}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold float-left">{{'status' | translate | uppercase}}</label>
                            <div class="input-group">
                                <input id="status" class="form-control" formControlName="status"
                                 [ngClass]="{'is-invalid':submitted }">
                                <!-- <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                    <div *ngIf="f.status.errors.required">{{
                                    "Pleaseentertheallowedcapacity" | translate
                                }}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold float-left">{{'assetCondition' | translate | uppercase}}</label>
                            <div class="input-group">
                                <input id="asset_condition" class="form-control" formControlName="condition"
                                    [ngClass]="{'is-invalid':submitted}">
                                <!-- <div *ngIf="submitted && f.condition.errors" class="invalid-feedback">
                                    <div *ngIf="f.condition.errors.required">
                                        {{"Pleaseentertheoutlookemail" | translate}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="btn-align">
                                <label class="font-weight-bold float-left fnt_resize " >{{'assetAudits' | translate}}</label>
                                <mat-slide-toggle id="asset_audits" class="move_right " formControlName="audits" (change)="changeAudit($event)"></mat-slide-toggle>
                            </div>
                            <span class="fnt_resize float-left">{{'assetLastAudited' | translate}}: 25th August 2020</span>
                            <div class="input-group">
                                <!-- <div *ngIf="submitted && f.audits.errors" class="invalid-feedback">
                                    <div *ngIf="f.audits.errors.required">
                                        
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 col-lg-4">
                        <div class="form-group">
                            <ngb-carousel class="carousel-img">  
                                <ng-template ngbSlide>  
                                    <img class="img-wh" src="https://mdbootstrap.com/img/Photos/Slides/img%20(68).jpg">  
                                     
                                </ng-template>  
                                <ng-template ngbSlide>  
                                    <img class="img-wh" src="https://mdbootstrap.com/img/Photos/Slides/img%20(6).jpg">  
                                     
                                </ng-template>  
                                <ng-template ngbSlide>  
                                    <img class="img-wh" src="https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg">  
                                    
                                </ng-template>  
                            </ngb-carousel>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold float-left" style="padding-left: 2%;">{{'barcode' | translate}}</label>
                            <br/>
                            <img class="float-left" style="width:100px;height:100px;" src="assets/images/barcode.jpg">
                            
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12">
                <div class="row">   
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</form>
