import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-personal",
  templateUrl: "./personal.component.html",
  styleUrls: ["./personal.component.scss"],
})
export class PersonalComponent implements OnInit {
  @Output() notifyParentPersonal: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  closeSpaces() {
    this.notifyParentPersonal.emit("true");
  }
}
