<div class="modal-card fixedHeight" *ngIf="pageType == 'scheduleDemo'">
    <div class="p-0 col-12">
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
                <div class="row col-12 px-0">
                <div class="col-8 pl-5 pr-1">
                    <div class="d-flex align-items-end">
                    <img src="assets/images/curated-services/clarity_new-solid.png" class="w-10"><h3 class="semi-bold-blue">Curated Services</h3>
                    </div>
                    <div class="text-left mt-3">
                        <h6 class="semi-bold-blue">Enhanced Brand Integration:</h6>
                        <p class="font-regular-gray text-left">Custom URLs seamlessly incorporate your company's branding and messaging, fostering alignment with organizational values.</p>
                        <h6 class="semi-bold-blue"> Dedicated Employee Spaces:</h6>
                        <p class="font-regular-gray text-left"> Each custom URL provides a dedicated hub for accessing relevant information, engaging with specialized services, and connecting with key resources.</p>
                        <h6 class="semi-bold-blue">Versatile and Scalable Solutions:</h6>
                        <p class="font-regular-gray text-left">Custom URLs accommodate diverse needs across departments, teams, or projects, from onboarding materials to wellness programs and professional development initiatives.</p>
                        <h6 class="semi-bold-blue">Elevated Employee Experience:</h6>
                        <p class="font-regular-gray text-left">Personalized URLs elevate employee satisfaction, streamline communication, and enhance organizational culture, fostering a more connected, engaged, and empowered Workforce.</p>
                    </div>
                    </div>
                <div class="col-4 px-0 align-content-center">
                    <img src="assets/images/curated-services/Teams.svg" class="imageWidthPopup">
                </div>
            </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main w-auto btnSmall btn-radius mr-2p float-right font-regular" *ngIf="!inProgressContactSales" type="button"
                    (click)="resquestScheduledemo()">
                        {{ "Schedule Demo" | translate }}
                    </button>
                    <button class="btn btnSmall btn-main w-auto mt-4 px-4 btn-gray float-right" *ngIf="inProgressContactSales" disabled="true"> Contacted! </button>
                    
                </div>
    </div>
        
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'bookingscheduleDemo'">
    <div class="p-0 col-12">
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
                <div class="row col-12 px-0">
                <div class="col-8 pl-5 pr-1">
                    <div class="d-flex align-items-end">
                    <img src="assets/images/curated-services/clarity_new-solid.png" class="w-10"><h3 class="semi-bold-blue">Booking</h3>
                    </div>
                    <div class="text-left mt-3">
                        <h6 class="semi-bold-blue">Seamless Space Management:</h6>
                        <p class="font-regular-gray text-left">Empower employees to reserve and utilize designated entities and spaces within your company effortlessly.</p>
                        <h6 class="semi-bold-blue"> Flexible Reservation Options:</h6>
                        <p class="font-regular-gray text-left">Book desks, meeting rooms, collaborative spaces, and parking spots according to your specific needs and timeframes.</p>
                        <h6 class="semi-bold-blue">Efficient Resource Utilization:</h6>
                        <p class="font-regular-gray text-left">Ensure optimal use of company resources by allowing employees to schedule their workspace and meeting requirements in advance.</p>
                        <h6 class="semi-bold-blue">Enhanced Employee Experience:</h6>
                        <p class="font-regular-gray text-left">Provide a streamlined booking process, promoting productivity and convenience for all employees.</p>
                        <h6 class="semi-bold-blue">Improved Workspace Dynamics:</h6>
                        <p class="font-regular-gray text-left">Facilitate collaboration and productivity by enabling employees to reserve spaces tailored to their work preferences and project requirements.</p>
                    </div>
                    </div>
                <div class="col-4 px-0 align-content-center">
                    <img src="assets/images/jumpree_digital/Group_15064.png" class="imageWidthPopup">
                </div>
            </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main w-auto btnSmall btn-radius mr-2p float-right font-regular" *ngIf="!inProgressContactSales" type="button"
                    (click)="resquestScheduledemo()">
                        {{ "Schedule Demo" | translate }}
                    </button>
                    <button class="btn btnSmall btn-main w-auto mt-4 px-4 btn-gray float-right" *ngIf="inProgressContactSales" disabled="true"> Contacted! </button>
                    
                    
                </div>
    </div>
        
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'imsscheduleDemo'">
    <div class="p-0 col-12">
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
                <div class="row col-12 px-0">
                <div class="col-8 pl-5 pr-1">
                    <div class="d-flex align-items-end">
                    <img src="assets/images/curated-services/clarity_new-solid.png" class="w-10"><h3 class="semi-bold-blue">Service Requests</h3>
                    </div>
                    <div class="text-left mt-3">
                        <h6 class="semi-bold-blue">Service Management System:</h6>
                        <p class="font-regular-gray text-left">Efficiently track and manage employee requests with customizable categories and escalation levels.</p>
                        <h6 class="semi-bold-blue"> Categories and Subcategories:</h6>
                        <p class="font-regular-gray text-left">Create hierarchies of service categories and subcategories to organize and streamline requests based on their nature and urgency.</p>
                        <h6 class="semi-bold-blue">Assignment of Members:</h6>
                        <p class="font-regular-gray text-left">Assign designated members or teams to execute requests promptly, ensuring accountability and timely resolution.</p>
                        <h6 class="semi-bold-blue">Escalation Levels:</h6>
                        <p class="font-regular-gray text-left">Establish escalation protocols to automatically escalate unresolved issues to higher levels of authority, preventing bottlenecks and ensuring swift action.</p>
                        <h6 class="semi-bold-blue">Seamless Ticket Handling:</h6>
                        <p class="font-regular-gray text-left">Handle service tickets seamlessly from creation to resolution, providing a smooth and transparent process for employees and administrators alike.</p>
                    </div>
                    </div>
                <div class="col-4 px-0 align-content-center">
                    <img src="assets/images/jumpree_digital/Group-15003.png" class="imageWidthPopup">
                </div>
            </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main w-auto btnSmall btn-radius mr-2p float-right font-regular" *ngIf="!inProgressContactSales" type="button"
                    (click)="resquestScheduledemo()">
                        {{ "Schedule Demo" | translate }}
                    </button>
                    <button class="btn btnSmall btn-main w-auto mt-4 px-4 btn-gray float-right" *ngIf="inProgressContactSales" disabled="true"> Contacted! </button>
                    
                    
                </div>
    </div>   
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'workStatusscheduleDemo'">
    <div class="p-0 col-12">
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
                <div class="row col-12 px-0">
                <div class="col-8 pl-5 pr-1">
                    <div class="d-flex align-items-end">
                    <img src="assets/images/curated-services/clarity_new-solid.png" class="w-10"><h3 class="semi-bold-blue">Work Status</h3>
                    </div>
                    <div class="text-left mt-3">
                        <h6 class="semi-bold-blue">Enhance Visibility:</h6>
                        <p class="font-regular-gray text-left">Allow users to indicate their work status, improving transparency and visibility across the team.</p>
                        <h6 class="semi-bold-blue"> Flexibility and Adaptability:</h6>
                        <p class="font-regular-gray text-left">Enable users to seamlessly communicate their work location or availability, fostering a more flexible work environment.</p>
                        <h6 class="semi-bold-blue">Streamlined Collaboration:</h6>
                        <p class="font-regular-gray text-left">Facilitate smoother collaboration by providing clarity on team members' availability and whereabouts.</p>
                        <h6 class="semi-bold-blue">Improved Planning:</h6>
                        <p class="font-regular-gray text-left">Enhance planning and coordination by allowing team members to update their work status, helping to manage expectations and workload effectively.</p>
                    </div>
                    </div>
                <div class="col-4 px-0 align-content-center">
                    <img src="assets/images/jumpree_digital/addlocationintro.svg" class="imageWidthPopup">
                </div>
            </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main w-auto btnSmall btn-radius mr-2p float-right font-regular" *ngIf="!inProgressContactSales" type="button"
                    (click)="resquestScheduledemo()">
                        {{ "Schedule Demo" | translate }}
                    </button>
                    <button class="btn btnSmall btn-main w-auto mt-4 px-4 btn-gray float-right" *ngIf="inProgressContactSales" disabled="true"> Contacted! </button>
                    
                    
                </div>
    </div>   
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'vmsscheduleDemo'">
    <div class="p-0 col-12">
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
                <div class="row col-12 px-0">
                <div class="col-8 pl-5 pr-1">
                    <div class="d-flex align-items-end">
                    <img src="assets/images/curated-services/clarity_new-solid.png" class="w-10"><h3 class="semi-bold-blue">Visitor Management</h3>
                    </div>
                    <div class="text-left mt-3">
                        <h6 class="semi-bold-blue">Visitor Management System:</h6>
                        <p class="font-regular-gray text-left">Efficiently track and manage visitors with a comprehensive management system tailored to your needs.</p>
                        <h6 class="semi-bold-blue"> Customizable Visitor Categories:</h6>
                        <p class="font-regular-gray text-left">Create distinct categories for different types of visitors, allowing for personalized management and tracking.</p>
                        <h6 class="semi-bold-blue">Visitor Access Control:</h6>
                        <p class="font-regular-gray text-left">Issue visitor badges and control access permissions based on predefined criteria, ensuring security and compliance.</p>
                        <h6 class="semi-bold-blue">Seamless Integration and Automation:</h6>
                        <p class="font-regular-gray text-left">Integrate with existing systems and automate processes to streamline visitor management and enhance overall efficiency.</p>
                    </div>
                    </div>
                <div class="col-4 px-0 align-content-center">
                    <img src="assets/images/jumpree_digital/vmsintro.png" class="imageWidthPopup">
                </div>
            </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main w-auto btnSmall btn-radius mr-2p float-right font-regular" *ngIf="!inProgressContactSales" type="button"
                    (click)="resquestScheduledemo()">
                        {{ "Schedule Demo" | translate }}
                    </button>
                    <button class="btn btnSmall btn-main w-auto mt-4 px-4 btn-gray float-right" *ngIf="inProgressContactSales" disabled="true"> Contacted! </button>
                    
                    
                </div>
    </div>   
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'eventsscheduleDemo'">
    <div class="p-0 col-12">
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
                <div class="row col-12 px-0">
                <div class="col-8 pl-5 pr-1">
                    <div class="d-flex align-items-end">
                    <img src="assets/images/curated-services/clarity_new-solid.png" class="w-10"><h3 class="semi-bold-blue">Events</h3>
                    </div>
                    <div class="text-left mt-3">
                        <h6 class="semi-bold-blue">Event Creation Simlipfied</h6>
                        <p class="font-regular-gray text-left">Facilitating seamless event organization with user-friendly features.</p>
                        <h6 class="semi-bold-blue"> Intuitive Event Setup:</h6>
                        <p class="font-regular-gray text-left">Effortlessly create events with intuitive tools and customizable options.</p>
                        <h6 class="semi-bold-blue">Comprehensive Event Management:</h6>
                        <p class="font-regular-gray text-left">Access a range of features for managing every aspect of your event, from invitations to attendee management.</p>
                        <h6 class="semi-bold-blue">Flexible Event Customization:</h6>
                        <p class="font-regular-gray text-left">Tailor events to your specific needs with flexible customization options, including themes, scheduling, and location settings.</p>
                        <h6 class="semi-bold-blue">Enhanced Collaboration:</h6>
                        <p class="font-regular-gray text-left">Collaborate with co-hosts or team members easily, ensuring smooth coordination and execution.</p>
                    </div>
                    </div>
                <div class="col-4 px-0 align-content-center">
                    <img src="assets/images/jumpree_digital/undraw_special_event_-4-aj8 1.png" class="imageWidthPopup">
                </div>
            </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main w-auto btnSmall btn-radius mr-2p float-right font-regular" *ngIf="!inProgressContactSales" type="button"
                    (click)="resquestScheduledemo()">
                        {{ "Schedule Demo" | translate }}
                    </button>
                    <button class="btn btnSmall btn-main w-auto mt-4 px-4 btn-gray float-right" *ngIf="inProgressContactSales" disabled="true"> Contacted! </button>
                    
                    
                </div>
    </div>   
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'smartAccessPartnerScheduleDemoHID'">
    <div class="p-0 col-12">
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
                <div class="row col-12 px-0">
                <div class="col-8 pl-5 pr-1">
                    <div class="d-flex align-items-end">
                        <img src="assets/images/curated-services/clarity_new-solid.png" class="w-10"><h3 class="semi-bold-blue">Access-HID</h3>
                    </div>
                    <div class="text-left mt-3">
                        <p class="font-regular-gray text-left" style="font-size: small;">
                            {{"HIDGlobalIsALeadingProvider"| translate}}
                        </p>
                        <h6 class="semi-bold-blue">{{"seamlessEntryManagement" | translate}}</h6>
                        <p class="font-regular-gray text-left">{{"enhanceYourEntryManagementUsingHID"| translate}}</p>
                        <h6 class="semi-bold-blue">{{"heightenedSecurityMeasures"| translate}}</h6>
                        <p class="font-regular-gray text-left">{{"elevateOrganisationSecurityHID"| translate}}</p>
                        <h6 class="semi-bold-blue">{{"innovationHIDTechnology"| translate}}</h6>
                        <p class="font-regular-gray text-left">{{"utilizeHID"| translate}}</p>
                        
                    </div>
                    </div>
                <div class="col-4 px-0 align-content-center">
                    <img src="assets/images/jumpree_digital/super_admin/HID_Global_logo.png" style="width: 110px;height: 40px;">
                    <img src="assets/images/jumpree_digital/super_admin/undraw_city_life_gnpr.png" class="imageWidthPopup">
                </div>
            </div>
            <div class=" col-12 text-left mb-0 mt-5">
                <button class="btn btn-primary ml-3 btn-main w-auto btnSmall btn-radius mr-2p float-right font-regular" type="button" *ngIf="scheduledDemo === null || scheduledDemo === ''" (click)="resquestScheduledemo()">
                    {{ "Schedule Demo" | translate }}
                </button>
                <button class="btn btnSmall btn-main w-auto mt-4 px-4 btn-gray float-right" *ngIf="scheduledDemo === 'IN_PROGRESS'" disabled="true"> Contacted! </button>
            </div>
        </div>   
</div>

<div class="modal-card fixedHeight" *ngIf="pageType == 'emergencyscheduleDemo'">
    <div class="p-0 col-12">
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closePopup()">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-4">
                <div class="row col-12 px-0">
                <div class="col-8 pl-5 pr-1">
                    <div class="d-flex align-items-end">
                    <img src="assets/images/curated-services/clarity_new-solid.png" class="w-10"><h3 class="semi-bold-blue">Emergency</h3>
                    </div>
                    <div class="text-left mt-3">
                        <h6 class="semi-bold-blue">Emergency Contact Management Simlipfied:</h6>
                        <p class="font-regular-gray text-left">Efficiently manage emergency contacts with user-friendly features designed for quick access and organization.</p>
                        <h6 class="semi-bold-blue"> Intuitive Contact Setup:</h6>
                        <p class="font-regular-gray text-left"> Easily add and organize emergency contacts with intuitive tools, ensuring you always have important information.</p>
                        <h6 class="semi-bold-blue">Comprehensive Contact Management:</h6>
                        <p class="font-regular-gray text-left">Manage all aspects of your emergency contacts, from categorization to quick access.</p>
                        <h6 class="semi-bold-blue">Flexible Contact Customization:</h6>
                        <p class="font-regular-gray text-left">Organize your emergency contacts by categories such as family, medical professionals, or other critical contacts.</p>
                        <h6 class="semi-bold-blue">Enhanced Collaboration:</h6>
                        <p class="font-regular-gray text-left">Share and manage emergency contact information with trusted individuals, ensuring seamless coordination in case of emergencies.</p>
                    </div>
                    </div>
                <div class="col-4 px-0 align-content-center">
                    <img src="assets/images/jumpree_digital/undraw_special_event_-4-aj8 1.png" class="imageWidthPopup">
                </div>
            </div>
                <div class=" col-12 text-left mb-0 mt-5">
                    <button class="btn btn-primary ml-3 btn-main w-auto btnSmall btn-radius mr-2p float-right font-regular" *ngIf="!inProgressContactSales" type="button"
                    (click)="resquestScheduledemo()">
                        {{ "Schedule Demo" | translate }}
                    </button>
                    <button class="btn btnSmall btn-main w-auto mt-4 px-4 btn-gray float-right" *ngIf="inProgressContactSales" disabled="true"> Contacted! </button>
                    
                    
                </div>
    </div>   
</div>











