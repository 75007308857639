<form class="form-group" id="finance_details" [formGroup]="financeForm" (ngSubmit)="onSubmitSearch()">
    <div class="col p-0 d-lg-flex d-md-flex">
        <div class="col-lg-4 col-md-4 col-sm-12 p-0 mt-3">
            <div class="col form-group">
                <label for="costCode" class="float-left">{{ 'costCode' | translate }}</label>
                <div class="input-group">
                    
                        <input  class="form-control py-2 fs-16" id="cost_code" autocomplete="off" type="text"
                            formControlName="costCode">
                    
                    <div *ngIf="submitted && f.costCode.errors" class="invalid-feedback">
                        <div *ngIf="f.costCode.errors.required">Please enter cost code</div>
                    </div>
                </div>
            </div>
            <div class="col form-group">
                <label for="poNumber" class="float-left">{{ 'poNumber' | translate }}</label>
                <div class="input-group">
                    
                        <input  class="form-control py-2 fs-16" id="poNumber" autocomplete="off" type="text"
                            formControlName="poNumber">
                    
                    <div *ngIf="submitted && f.poNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.poNumber.errors.required">Please enter poNumber</div>
                    </div>
                </div>
            </div>
            <div class="col form-group">
                <label for="vendor" class="float-left">{{ 'vendor' | translate }}</label>
                <div class="input-group">
                    
                        <input  class="form-control py-2 fs-16" id="vendor" autocomplete="off" type="text"
                            formControlName="vendor">
                    
                    <div *ngIf="submitted && f.vendor.errors" class="invalid-feedback">
                        <div *ngIf="f.vendor.errors.required">Please enter vendor</div>
                    </div>
                </div>
            </div>
            <div class="col form-group">
                <label for="purchased" class="float-left">{{ 'purchased' | translate }}</label>
                <div class="input-group">
                    
                        <input  class="form-control py-2 fs-16" id="purchased" autocomplete="off" type="text"
                            formControlName="purchased">
                    
                    <div *ngIf="submitted && f.purchased.errors" class="invalid-feedback">
                        <div *ngIf="f.purchased.errors.required">Please enter purchased</div>
                    </div>
                </div>
            </div>
            <div class="col form-group">
                <label for="inService" class="float-left">{{ 'inService' | translate }}</label>
                <div class="input-group">
                    
                        <input  class="form-control py-2 fs-16" id="in-service" autocomplete="off" type="text"
                            formControlName="inService">
                    
                    <div *ngIf="submitted && f.inService.errors" class="invalid-feedback">
                        <div *ngIf="f.inService.errors.required">Please enter in service</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 p-0 mt-3">
            <div class="col pr-0 form-group">
                <label for="purchasePrice" class="float-left">{{ 'purchasePrice' | translate }}</label>
                <!-- <div class="input-group"> -->
                <div class="col d-flex p-0">
                    <div class="col-lg-3 col-md-3 col-sm-6 pl-0">
                        
                            <input  class="form-control py-2 fs-16" id="purchase-price-unit" autocomplete="off"
                                type="text" formControlName="purchasePriceUnit">
                        
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 pl-0">
                        
                            <input  class="form-control py-2 fs-16" id="purchase-price-cost" autocomplete="off"
                                type="text" formControlName="purchasePriceCost">
                        
                    </div>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>

            <div *ngIf="submitted && f.purchasePriceCost.errors" class="invalid-feedback">
                <div *ngIf="f.purchasePriceCost.errors.required">Please enter cost code</div>
            </div>
            <div class="col pr-0 form-group">
                <label for="currentPrice" class="float-left">{{ 'currentPrice' | translate }}</label>
                <!-- <div class="input-group"> -->
                <div class="col d-flex p-0">
                    <div class="col-lg-3 col-md-3 col-sm-6 pl-0">
                        
                            <input  class="form-control py-2 fs-16" id="current-price-unit" autocomplete="off"
                                type="text" formControlName="currentPriceUnit">
                        
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 pl-0">
                        
                            <input  class="form-control py-2 fs-16" id="current-price-cost" autocomplete="off"
                                type="text" formControlName="currentPriceCost">
                        
                    </div>
                </div>
            </div>

            <div *ngIf="submitted && f.purchasePriceCost.errors" class="invalid-feedback">
                <div *ngIf="f.purchasePriceCost.errors.required">Please enter cost code</div>
            </div>
            <div class="col pr-0 form-group">
                <label for="scrapValue" class="float-left">{{ 'scrapValue' | translate }}</label>
                <div class="col d-flex p-0">
                    <div class="col-lg-3 col-md-3 col-sm-6 pl-0">
                        
                            <input  class="form-control py-2 fs-16" id="scrap-value-unit" autocomplete="off"
                                type="text" formControlName="scrapValueUnit">
                        
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 pl-0">
                        
                            <input  class="form-control py-2 fs-16" id="scrap-value-cost" autocomplete="off"
                                type="text" formControlName="scrapValueCost">
                        
                    </div>
                </div>
            </div>

            <div *ngIf="submitted && f.purchasePriceCost.errors" class="invalid-feedback">
                <div *ngIf="f.purchasePriceCost.errors.required">Please enter cost code</div>
            </div>
            <div class="col pr-0 form-group">
                <label for="warrantyExpires" class="float-left">{{ 'warrantyExpires' | translate }}</label>
                <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                    
                        <input  class="form-control py-2 fs-16" id="warranty-expires" autocomplete="off"
                            type="text" [owlDateTimeTrigger]="warranty_expire_date" [owlDateTime]="warranty_expire_date"
                            formControlName="warrantyExpires">
                        <span class="calendarPosition" [owlDateTimeTrigger]="warranty_expire_date" aria-hidden="true">
                            <i class="fa fa-calendar fa-calender-icons"></i>
                        </span>
                        <owl-date-time #warranty_expire_date [pickerType]="'calendar'"></owl-date-time>
                    
                </div>
            </div>

            <div *ngIf="submitted && f.warrantyExpires.errors" class="invalid-feedback">
                <div *ngIf="f.warrantyExpires.errors.required">Please select warranty expires</div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 margin-top-20">
            <div class="form-row">
                <div class="form-group col">
                    <label for="inputEmail4">{{ 'select' | translate }}</label>
                    <div class="input-group">
                        
                        
                        <div *ngIf="submitted && f.searchBy.errors" class="invalid-feedback">
                            <div *ngIf="f.searchBy.errors.required">Please Select Category</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</form>
