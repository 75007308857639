import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, NgModel } from '@angular/forms';
import { MatDialog, MatDialogRef, MatOption, MatSelect, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../services/common-service.service';
import { RolesandresponsibilitiesService } from '../services/rolesandresponsibilities.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ChangeDetectionStrategy } from '@angular/core';
import { LoaderService } from "../../../shared/modules/loader/loader.service";
import { ToastrService } from "ngx-toastr";
import { Constants, environment } from "../../../../environments/environment";

@Component({
  selector: 'app-roles-modal',
  templateUrl: './roles-modal.component.html',
  styleUrls: ['./roles-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RolesModalComponent implements OnInit {
  currentZone;
  responsibilitiesData;
  roleCategories;
  editUsers: FormGroup;
  newRoleData = {};
  newUserData = {};
  submitted = false;
  isRoleEdit: boolean = false;
  createUser: any = { firstName: "", lastName: "", userEmail: "", countryCode: "+91", userPhoneno: "", "roleOptions": [{ userRoles: '', userZones: '' }] }
  roleId;
  responsibilityIds = [];
  zoneList;
  roleList;
  tenantUserList: any;
  createRole: any = {};
  zoneListId: any;
  roleUserName: string = "";
  formdata: FormGroup;
  countryCodeList;
  oAuthLogin = false;
  oAuthLoginUrl: string;
  oAuthLogoutUrl: string;
  roleOptions = [{ option: "" }];
  DataList: any;
  additionalModuleId:any ='';
  isEmailEnabledCheck;
  tenantAdminRoleList: any;
  tenantAdminId;
  loginAdminType;
  showInvalid: boolean;


  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
    private loaderService: LoaderService,
    private manageRoleResponsibilityService: RolesandresponsibilitiesService,
    public dialogRef: MatDialogRef<RolesModalComponent>,
    private router: Router,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService) {
    this.currentZone = data["currentZone"];
    this.getCountryCodes();
  }

  ngOnInit() {
    this.editUsers = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(30)]],
      lastName: ['', [Validators.required, Validators.maxLength(30)]],
      userEmail: ['', Validators.required],
      countryCode: ['', Validators.required],
      userPhoneno: ['', [Validators.required, Validators.maxLength(15)]],
      tenantId: ['', Validators.required],
      roleOption: ['', Validators.required],
      zoneOptions: ['', Validators.required],
    })





    this.isRoleEdit = false;
    this.commonService.currentZone.subscribe(res => {
      //&& this.commonService.isCurrentComponentRouteSame(this.router.url, this.currentRoute)  
      if ((res)
      ) { this.currentZone = res; }
      this.getAllResponsibilityByModuleId();
      this.getAllRoleCategories();


    },
      err => {
        this.commonService.openSnackBar("Something Went Wrong !!", "");
      }
    );


    this.formdata = this.formBuilder.group({
      user: new FormControl("", [Validators.required]),
      role: new FormControl("", [Validators.required]),
      zone: new FormControl("", [Validators.required]),
    })
    if (this.data["type"] == "addRole" || this.data["type"] == "addUserRole") {

      this.zoneList = (this.data as any).zoneList;
      this.roleList = (this.data as any).roleList;
      this.zoneListId = this.zoneList.map(function (value) {
        return value.id;
      });
      if (this.data["type"] == "addUserRole") {
        this.tenantUserList = (this.data as any).tenantUser;
        // this.tenantUserList = this.tenantUserList.filter((item) => {return item.active != false});
      }
    }
    if (this.data["type"] == "editRole") {

      this.isRoleEdit = true
      this.zoneList = (this.data as any).zoneList;
      this.roleList = (this.data as any).roleList;
      let roleData = (this.data as any).userRoleData;
      this.roleUserName = roleData.name;
      var roleId = roleData.role;
      var zoneIds = roleData.zones;
      // this.form.controls['dept'].setValue(selected.id);
      this.formdata.patchValue({
        role: roleId,
        zone: zoneIds
      });
    }


    if (this.data["type"] == "editUserRole") {
      this.isRoleEdit = true
      this.zoneList = (this.data as any).zoneList;
      this.roleList = (this.data as any).roleList;
      this.tenantUserList = (this.data as any).tenantUser;
      // this.tenantUserList = this.tenantUserList.filter((item) => {return item.active != false});
      this.zoneListId = this.zoneList.map(function (value) {
        return value.id;
      });

      // this.loginAdminType = (this.data as any).adminType;
      // this.tenantAdminId = (this.data as any).tenantId;
      // console.log('type and tenant id is... ', this.loginAdminType, this.tenantAdminId)
      // Calling 48 Config API for isEmailHidden check for different customers
      this.commonService.getConfig(this.currentZone,48).subscribe( res => {
        let configDetails = (res as any).response;
        let configResp = JSON.parse(configDetails);
        // console.log('config response is... ', configResp)
        this.tenantAdminRoleList = configResp.ems.profile.tenantAdminRoleList;
        // this.tenantAdminRoleList = ["SALES_MANAGER", "SALES_EXECUTIVE", "SALES_ADMIN", "FACILITY_EXECUTIVE", "test"];
        // console.log('tenant admin role list... ', this.tenantAdminRoleList)
        //   if(this.loginAdminType == 'TENANT_ADMIN') {
        //     console.log('tenant admin')
        //     if(this.tenantAdminRoleList) {
        //       let text = [];
        //       this.roleList.forEach(ele => {
        //         this.tenantAdminRoleList.forEach(item => {
        //           if(ele.name == item){
        //             text.push(ele)
        //           }
        //         });
        //       });
        //     this.roleList = text;
        //   }
        // }
        // console.log('final role list is... ', this.roleList)
        this.isEmailEnabledCheck = configResp.ems.profile.isEmailHidden;
        // console.log('email enabled... ', this.isEmailEnabledCheck)
        if(!!configResp.ems.profile && configResp.ems.profile.isEmailHidden) {
          // console.log('disabled');
        } else {
          // console.log('enabled');
        }
      });
      //this.zoneListId = Object.values(this.zoneList.id);

      //this.zoneListId = Object.values(this.zoneList.id);
      let roleData = (this.data as any).userRoleData;
      var roleId = roleData.role;
      var zoneIds = roleData.zones;
      // this.form.controls['dept'].setValue(selected.id);
      this.formdata.patchValue({
        role: roleId,
        zone: zoneIds
      });


      // this.editUsers.controls.firstName.setValue(roleData.firstName);
      // this.editUsers.controls.lastName.setValue(roleData.lastName);
      // this.editUsers.controls.userEmail.setValue(roleData.email);
      //let mobData = roleData.phoneNum.split('-');
      // this.editUsers.controls.userPhoneno.setValue(mobData[1]);
      // this.editUsers.controls.countryCode.setValue(mobData[0]);
      // //this.editUsers.controls.id.setValue(roleData.userId);
      // this.editUsers.controls.roleOptions.setValue(roleData.totalSelectedRoles);
      // this.editUsers.controls.tenantId.setValue(roleData.tenantId);

      // this.createUser.firstName = this.editUsers.get('firstName').value;
      // this.createUser.lastName = this.editUsers.get('lastName;
      // this.createUser.userEmail = this.editUsers.get('email;
      // //let mobData = roleData.phoneNum.split('-');
      // this.createUser.userPhoneno = mobData[1];
      // this.createUser.countryCode = mobData[0];
      // this.createUser.id = this.editUsers.get('userId;
      // this.createUser.roleOptions = this.editUsers.get('totalSelectedRoles;
      // this.createUser.tenantId = roleData.tenantId;

      this.createUser.firstName = roleData.firstName;
      this.createUser.lastName = roleData.lastName;
      this.createUser.userEmail = roleData.email;
      // console.log(roleData.phoneNum)
      // let mobData = roleData.phoneNum.split('-');
      // this.createUser.userPhoneno = mobData[1];
      // this.createUser.countryCode = mobData[0];
      if (!!roleData.phoneNum) {
        let mobData = roleData.phoneNum.split('-');
        // this.createUser.userPhoneno = (!!mobData[1]) ? mobData[1] : "";
        this.createUser.countryCode = (mobData[0]) ? mobData[0] : "";
        if ((!!mobData[1] && mobData[1] !== "null") && (!!mobData[2] && mobData[2] !== "null") 
            && (!!mobData[3] && mobData[3] !== "null")) {
          this.createUser.userPhoneno = mobData[1]+'-'+mobData[2]+'-'+ mobData[3];
          // console.log(this.createUser.userPhoneno)
        } else if ((!!mobData[1] && mobData[1] !== "null") && (!!mobData[2] && mobData[2] !== "null")) {
          this.createUser.userPhoneno = mobData[1] + '-' + mobData[2];
        } else if (!!mobData[1] && mobData[1] !== "null") {
          this.createUser.userPhoneno = mobData[1];
        } else {
          this.createUser.userPhoneno = "";
        }
      } else {
        this.createUser.userPhoneno = "";
        this.createUser.countryCode = "";
      }

      this.createUser.id = roleData.userId;
      this.createUser.roleOptions = roleData.totalSelectedRoles;
      this.createUser.tenantId = roleData.tenantId;
      var rolesZones = [];
      var selectedRoles = [];
      var selecedLocations = [];
      // for(var i=0;i<roleData.rolesMap.length;i++)
      // {  
      for (let [key, value] of Object.entries(roleData.rolesMap)) {
        var selLoc = [key]
        var selRoles = value;

        for (let [key1, value1] of Object.entries(selRoles)) {
          for (var r = 0; r < roleData.rolesV2.length; r++) {
            if (roleData.rolesV2[r].name == value1) {
              selectedRoles.push({
                //'userZones': roleData.rolesMap[i],
                'userRoles': roleData.rolesV2[r].id
              });
              break;
            }
          }
        }
      }
      //userZones      
      selectedRoles = selectedRoles
        .map(item => item.userRoles)
        .filter((value, index, self) => self.indexOf(value) === index);

      for (let [key, value] of Object.entries(selectedRoles)) {
        selecedLocations[key] = [];
        for (let [key1, value1] of Object.entries(roleData.rolesMap)) {
          for (let [key2, value2] of Object.entries(value1)) {
            for (var r = 0; r < roleData.rolesV2.length; r++) {
              if (roleData.rolesV2[r].name == value2 && roleData.rolesV2[r].id == value) {
                selecedLocations[key].push(parseInt(key1));
                break;
              }
            }
          }
        }
      }
      for (var z = 0; z < selectedRoles.length; z++) {
        rolesZones.push({
          'userRoles': selectedRoles[z],
          'userZones': selecedLocations[z]
        });
      }
      this.editUsers.controls.zoneOptions.setValue(rolesZones);
      this.createUser.roleOptions = rolesZones;
      //this.createUser.id = roleData.id //check with rajesh and pass correct data
      this.getAllDetailsActiveList();
    }
    if (this.data["type"] == "editRoleResponsibilityMapping") {
      let responsibilities = (this.data as any).roleResponsibilityMapInfo.responsibilities;
      for (let res of responsibilities) {
        this.responsibilityIds.push(res.id);
      }
    }
  }

  getCountryCodes() {
    let currentContext = this;
    this.commonService.getCountryCodes(22, this.currentZone).subscribe(res => {
      currentContext.countryCodeList = (res as any).response;
    }, err => {
      this.commonService.openSnackBar(err.error.message, '');
    });
  }

  selectedTenant() {
    let result = this.tenantUserList.filter(el => el.active === true);
    this.tenantUserList = result;
  }

  checkTenantUser(){
    let result = this.tenantUserList.filter(el => el.tenant_id === this.createUser.tenantId);
    if(result.length>0 && result[0]['active']){
      return true;
    }else{
      return false;
    }
  }

  getAllResponsibilityByModuleId() {
    this.manageRoleResponsibilityService.getAllResponsibilityByModuleId(this.currentZone).subscribe(res => {
      this.responsibilitiesData = res["response"];
    }, err => {
      this.commonService.openSnackBar(err.error.message, "");
      this.responsibilitiesData = [];
    })
  }

  getAllRoleCategories() {
    this.manageRoleResponsibilityService.getAllRoleCategories(this.currentZone).subscribe(res => {
      this.roleCategories = res["response"];
    }, err => {
      this.commonService.openSnackBar(err.error.message, "");
      this.roleCategories = [];
    })
  }

  addNewRole(roleData) {
    this.manageRoleResponsibilityService.addNewRole(this.newRoleData, this.currentZone).subscribe(res => {
      this.onNoClick();
      this.commonService.openSnackBar(res["message"], "");
    }, err => {
      this.onNoClick();
      this.commonService.openSnackBar(err.error.message, "");
    })
  }

  onNoClick(): void {
    let currentContext = this;
    currentContext.commonService.changeZone(currentContext.currentZone);
    this.dialogRef.close();
  }


  addRolesResponsibilityMapping(data) {
    let roleResponsibilityMapping = {
      roleId: data.roleResponsibilityMapInfo.role.id,
      responsibilityIds: [] = this.responsibilityIds
    };
    this.manageRoleResponsibilityService.addRolesResponsibilityMapping(this.currentZone, roleResponsibilityMapping).subscribe(res => {

      this.onNoClick();
      this.commonService.openSnackBar(res["message"], "");


    }, err => {
      this.onNoClick();
      this.commonService.openSnackBar(err.error.message, "")

    })
  }

  onChangeResponsibility(responsiblityData, event) {
    // if(this.responsibilityIds.indexOf(responsiblityData.id) > -1){
    //   this.responsibilityIds.splice(this.responsibilityIds.indexOf(responsiblityData.id),1);
    // }
    // else
    //   {
    //     this.responsibilityIds.push(responsiblityData.id);
    //   }
    if (event.checked) {
      this.responsibilityIds.push(responsiblityData.id);

    }
    else {
      this.responsibilityIds.splice(this.responsibilityIds.indexOf(responsiblityData.id), 1);
    }

  }

  autoCheck(row, ResponsibilityList) {
    for (let responsibility of ResponsibilityList) {
      if (row.id === responsibility.id) {
        // if(this.responsibilityIds.indexOf(row.id) < 0)
        // {
        //   this.responsibilityIds.push(row.id);
        //  }
        return true;
      }
    }

  }

  addUserRole = (data) => {
    if (data) {
      let role: any = {};
      role.userId = data.user.userId;
      role.roleId = data.role,
        role.zoneIds = data.zone
      this.manageRoleResponsibilityService.createUserRole(role, this.currentZone).subscribe(res => {
        let response = (res as any).response;
        let message = (res as any).message;
        if (response) {
          this.commonService.openSnackBar(message, "");
          this.onCloseRoleModal();
        } else {
          this.commonService.openSnackBar(message, "")
        }
      })
    }
  }
  //for manage User Profile
  addNewUser() {
    this.manageRoleResponsibilityService.addNewUser(this.newUserData, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar(res["message"], "");
    }, err => {
      this.commonService.openSnackBar(err.console.error.message, "");
    }, () => {
    })
  }

  deleteUser(data) {
    let userId = data.userId;
    this.manageRoleResponsibilityService.deleteUser(userId, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar(res["message"], "");
    }, err => {
      this.commonService.openSnackBar(err.error.message, "");
    })
  }

  editUser(data) {
    this.manageRoleResponsibilityService.editUser(this.newUserData, this.currentZone).subscribe(res => {
      this.commonService.openSnackBar(res["message"], "");
    }, err => {
      this.commonService.openSnackBar(err.error.message, "");
    })
  }

  addEditUserRole = (type, isValid: boolean) => {
    if (type == 'addUserRole') {
      type = 'add';
    } else {
      type = 'edit';
    }
    var dataRoles = [];
    if (isValid) {
      for (var i = 0; i < this.createUser.roleOptions.length; i++) {
        dataRoles.push({
          'roleId': this.createUser.roleOptions[i].userRoles,
          'zoneIds': this.createUser.roleOptions[i].userZones
        })
      }

      var data;
      if (type == "add") {
        data = {
          'email': this.createUser.userEmail,
          'firstName': this.createUser.firstName,
          'password': '',
          'lastName': this.createUser.lastName,
          'phoneNum': this.createUser.countryCode + '-' + this.createUser.userPhoneno,
          'rolesToAssign': dataRoles,
          'tenantId': this.createUser.tenantId

        }
      } else {
// console.log('yes hitting here...')
        data = {
          'id': this.createUser.id,
          'email': this.createUser.userEmail,
          'firstName': this.createUser.firstName,
          'password': '',
          'lastName': this.createUser.lastName,
          'phoneNum': this.createUser.countryCode + '-' + this.createUser.userPhoneno,
          'rolesToAssign': dataRoles,
          'tenantId': this.createUser.tenantId

        }
      }
      // console.log('data is... ', data);
      if(this.isEmailEnabledCheck == false) {
        if (data.email && data.firstName && data.lastName && this.createUser.userPhoneno) {
          this.getAllUserDetails(data, type);
        }
      } else {
        if (data.firstName && data.lastName && this.createUser.userPhoneno) {
          this.getAllUserDetails(data, type);
        }
      }
    }

  }

  getAllUserDetails(data, type) {
    const userDetails = this.manageRoleResponsibilityService.createOrEditManageUserRole(data, this.currentZone, type);
      this.loaderService.showLoaderUntilCompleted(userDetails).subscribe(res => {
        let response = (res as any).response;
        let message = (res as any).message;
        if (response) {
          if (this.data['userRoleData']['userId'] == localStorage.getItem('ssadmin_userId')) {
            this.dialogRef.close();
            this.toastrService.success("Successfully updated, Please login again", "");
            localStorage.removeItem("ssadmin_userImg");
            localStorage.removeItem("ssadmin_session");
            localStorage.removeItem("ssadmin_isLoggedin");
            localStorage.removeItem("ssadmin_user");
            localStorage.removeItem("ssadmin_name");
            localStorage.removeItem("ssadmin_enterpriseId");
            localStorage.removeItem("userObj");
            this.router.navigate(["/login"]);
          } else {
            this.onCloseRoleModal();
            this.toastrService.success(message);
            // window.location.reload();
          }
        } else {
          this.toastrService.success(message);
        }
      }, err => {
        this.toastrService.error(err.error.message);
      })
  }

  addNewChoice() {
    this.createUser.roleOptions.push({ userRoles: '', userZones: '' });
  }

  removeUserRole = index => {
    // if (this.createUser.roleOptions.length == 1) {
    //   alert(`Cann't delete`);
    //   return;
    // }
    this.createUser.roleOptions.splice(index, 1);
  };

  selectAll(select: NgModel, values, array) {

    select.update.emit(values.id);
  }

  deselectAll(select: NgModel) {

    select.update.emit([]);
  }

  onCloseRoleModal(): void {
    let currentContext = this;
    currentContext.commonService.changeZone(currentContext.currentZone);
    this.dialogRef.close();
  }

  get f() {
    return this.editUsers.controls;
  }

  onUpdateUser() {
    this.submitted = true;

    if (this.editUsers.invalid) {
      return;
    }
  }

  closePopup() {
    this.dialogRef.close();
  }

  getAllDetailsActiveList() {
    let urlFormHost = environment.BASE_URL;
    console.log(urlFormHost);
    if(urlFormHost.includes("GWSA")){
      this.manageRoleResponsibilityService.additionalDetailslist(this.currentZone).subscribe(res => {

        this.DataList = (res as any).response.list;

        this.getAllDetailsList();

      })
    }
  }


  getAllDetailsList() {
    this.manageRoleResponsibilityService.additionalDetailsActivelist(this.currentZone, this.data.userRoleData.userId).subscribe(res => {

      let allList = (res as any).response.list;
      if(!!allList && allList.length) {
        let element = allList[(allList.length - 1)];
        this.additionalModuleId = element.id;
        let aItem = element.metadata.questionResponse;
        this.DataList.forEach(item => {
          Object.entries(aItem).map(element => {
            element.forEach(val => {
              if (item.id == element[0]) {
                this.onCheckClicked(val, item);
              }
            });
          });
        });
      }
      

      // allList.forEach(element => {
       
      // });
    }, err => {
      this.additionalModuleId = "";
      if(!!this.DataList) {
        this.DataList.forEach((e) => {
          var questions = [];
    
          if (e.metadata.values != undefined) {
    
            for (var x in e.metadata.values) {
              var obj = {
                ques: e.metadata.values[x],
                checked: false,
                value: x
              }
              questions.push(obj)
    
              e.answerOptions = questions;
    
            }
    
    
    
          }
    
          if (e.answerOptions != undefined && e.answers != undefined) {
    
            for (var i in e.answers[0]) {
              for (var j in e.answerOptions) {
                if (e.answers[0][i] == e.answerOptions[j].value) {
                  e.answerOptions[j].checked = true;
                  e.metadata.defaultValue = e.answerOptions[j].value;
                }
              }
    
    
            }
          }
    
    
        });
      }
      
    });
  }

  onCheckClicked = (item, question) => {

    let aItem = question;

    if (aItem.fieldType == 'RADIO') {
      aItem.answers = [item];
    }
    else if (aItem.fieldType == 'CHECKBOX') {
      aItem.answers = [item];
    }
    else if (aItem.fieldType == 'DROPDOWN') {
      aItem.metadata.defaultValue = item;


    }
    else if (aItem.fieldType == 'TEXT') {
      aItem.metadata.defaultValue = item;
    }


    else {
      if (aItem.answers) {
        const index = aItem.answers.indexOf(item);
        if (index > -1) {
          aItem.answers.splice(index, 1);
        } else {
          aItem.answers.push(item);
        }
      } else {
        aItem.answers = [item];
      }
    }

    let newArr = [aItem];

    newArr.forEach(updatedObj => {
      // For every newArr object (dataObj), find the array index in allData where the IDs match.
      let indexFInallData = this.DataList
        .map(allDataObj => allDataObj.id)
        .indexOf(updatedObj.id); // First make an array of IDs, to use indexOf().
      // If there is a matching ID (and thus an index), replace the existing object in allData with the newArr's object.
      if (indexFInallData !== undefined)
        this.DataList.splice(indexFInallData, 1, updatedObj);
    });




    this.DataList.forEach((e) => {
      var questions = [];

      if (e.metadata.values != undefined) {

        for (var x in e.metadata.values) {
          var obj = {
            ques: e.metadata.values[x],
            checked: false,
            value: x
          }
          questions.push(obj)

          e.answerOptions = questions;

        }



      }

      if (e.answerOptions != undefined && e.answers != undefined) {

        for (var i in e.answers[0]) {
          for (var j in e.answerOptions) {
            if (e.answers[0][i] == e.answerOptions[j].value) {
              e.answerOptions[j].checked = true;
              e.metadata.defaultValue = e.answerOptions[j].value;
            }
          }


        }
      }


    });





  };


  updateAnsers(frm) {
    if(frm.form.invalid) {
      return;
    }

    let data = {}

    this.DataList.forEach((element) => {
      if (!!element.metadata.question) {

        if (element.fieldType == 'RADIO' && !!element.metadata.defaultValue) {

          data[element.id] = [element.metadata.defaultValue];

        }
        else if (element.fieldType == 'CHECKBOX' && !!element.answerOptions) {
          data[element.id] = [];
          element.answerOptions.forEach(element1 => {
            if (element1.checked) {
              data[element.id].push(element1.value)
            }
          });

        }
        else if (element.fieldType == 'TEXT' && !!element.metadata.defaultValue) {

          data[element.id] = element.metadata.defaultValue;
        }
        else if (element.fieldType == 'DROPDOWN') {

          data[element.id] = element.metadata.defaultValue;

        }


      }
    })


    let dataVal =
    {
      // "moduleEntityId": this.data.userRoleData.userId,
      "moduleEntityId": this.data.userRoleData.userId,
      "moduleActionKey": "USER_ADD_DETAILS",
      "metadata": {
        "questionResponse": data,
        "autoApprovalResponse": {},
        "digitalSignatureResponse": {}
      }
    }
    if(!!this.additionalModuleId && this.additionalModuleId != '') {
      dataVal["id"] = this.additionalModuleId;
      this.manageRoleResponsibilityService.updateUserAdditionalDetails(this.currentZone, dataVal).subscribe(res => {
        this.toastrService.success("Updated successfully");
      })
    }else {
      this.manageRoleResponsibilityService.addUserAdditionalDetails(this.currentZone, dataVal).subscribe(res => {
        this.toastrService.success("Saved successfully");
      })
    }
    



  }

  ValidateEmail(email) {
    var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (!!email && !expr.test(email)) {
      this.showInvalid = true;
    } else {
      this.showInvalid = false;
    }
  }
}
