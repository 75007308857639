/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./digitalworkstatus-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fullcalendar/angular/fullcalendar-angular.ngfactory";
import * as i3 from "@fullcalendar/angular";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../digital-dashboard/dashboard-modal/colleagues/colleagues.component.ngfactory";
import * as i7 from "../../digital-dashboard/dashboard-modal/colleagues/colleagues.component";
import * as i8 from "../../digital-dashboard/dashboard-modal/search-location-reuseable-modal/search-location-reuseable-modal.component.ngfactory";
import * as i9 from "../../digital-dashboard/dashboard-modal/search-location-reuseable-modal/search-location-reuseable-modal.component";
import * as i10 from "../../../services/common-service.service";
import * as i11 from "@angular/forms";
import * as i12 from "../../../shared/modules/loader/loader.service";
import * as i13 from "ngx-toastr";
import * as i14 from "../../manage-request/manage-booking/containers/work-status/service/work-status.service";
import * as i15 from "./digitalworkstatus-modal.component";
import * as i16 from "@angular/material/dialog";
import * as i17 from "../service/workstatus.service";
var styles_DigitalWorkStatusModalComponent = [i0.styles];
var RenderType_DigitalWorkStatusModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DigitalWorkStatusModalComponent, data: {} });
export { RenderType_DigitalWorkStatusModalComponent as RenderType_DigitalWorkStatusModalComponent };
function View_DigitalWorkStatusModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "full-calendar", [], null, null, null, i2.View_FullCalendarComponent_0, i2.RenderType_FullCalendarComponent)), i1.ɵdid(1, 6733824, [[1, 4], ["fullcalendar", 4]], 0, i3.FullCalendarComponent, [i1.ElementRef], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarOptions; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DigitalWorkStatusModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "only-calndarview"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalWorkStatusModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarOptions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DigitalWorkStatusModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "modal-body"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("work")); _ck(_v, 3, 0, currVal_0); }); }
function View_DigitalWorkStatusModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "modal-body align-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "semi-bold-blue mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "w-20"], ["src", "./assets/images/checked.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "p", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-primary btn-main btn-Small btn-radius btn-custom-border"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("successMsg")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("yourWorkStatusHasBeenCreatedSuccessfully")); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("doneMsg")); _ck(_v, 12, 0, currVal_2); }); }
function View_DigitalWorkStatusModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["class", "ml-3"], ["src", "./assets/images/jumpree_digital/calendar_icon.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showFullCalendar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-body align-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-colleagues", [], null, [[null, "notifyParentColleague"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notifyParentColleague" === en)) {
        var pd_0 = (_co.getNotification($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ColleaguesComponent_0, i6.RenderType_ColleaguesComponent)), i1.ɵdid(11, 114688, null, 0, i7.ColleaguesComponent, [], null, { notifyParentColleague: "notifyParentColleague" })], function (_ck, _v) { _ck(_v, 11, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("favMsg")); _ck(_v, 3, 0, currVal_0); }); }
function View_DigitalWorkStatusModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header modalHeaderPadding pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h6", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-body align-left pl-1 py-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-search-location-reuseable-modal", [], null, [[null, "notifySearchLocation"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notifySearchLocation" === en)) {
        var pd_0 = (_co.getFilteredSearchLocation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_SearchLocationReuseableModalComponent_0, i8.RenderType_SearchLocationReuseableModalComponent)), i1.ɵdid(10, 114688, null, 0, i9.SearchLocationReuseableModalComponent, [i10.CommonService, i11.FormBuilder, i12.LoaderService, i13.ToastrService, i14.WorkStatusService], { locationList: [0, "locationList"], selectedDate: [1, "selectedDate"], selectedOfficeDates: [2, "selectedOfficeDates"], currentZonePass: [3, "currentZonePass"] }, { notifySearchLocation: "notifySearchLocation" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.locationList; var currVal_2 = _co.selectedDate; var currVal_3 = _co.selectedOfficeDates; var currVal_4 = _co.currentZone; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("selectOfficeMsg")); _ck(_v, 3, 0, currVal_0); }); }
function View_DigitalWorkStatusModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "mt-2 openSans"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-body align-left pl-1 py-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-search-location-reuseable-modal", [], null, [[null, "saveDateLocation"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveDateLocation" === en)) {
        var pd_0 = (_co.saveDateLocation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_SearchLocationReuseableModalComponent_0, i8.RenderType_SearchLocationReuseableModalComponent)), i1.ɵdid(11, 114688, null, 0, i9.SearchLocationReuseableModalComponent, [i10.CommonService, i11.FormBuilder, i12.LoaderService, i13.ToastrService, i14.WorkStatusService], { locationList: [0, "locationList"], selectedOfficeDates: [1, "selectedOfficeDates"], currentZonePass: [2, "currentZonePass"] }, { saveDateLocation: "saveDateLocation" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.locationList; var currVal_3 = _co.selectedOfficeDates; var currVal_4 = _co.currentZone; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("work")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("youCanSelectDifferentLocationsForTheDatesYouAreWorkingFromOffice")); _ck(_v, 7, 0, currVal_1); }); }
export function View_DigitalWorkStatusModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fullCalendarComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalWorkStatusModalComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalWorkStatusModalComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalWorkStatusModalComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalWorkStatusModalComponent_5)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalWorkStatusModalComponent_6)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalWorkStatusModalComponent_7)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pageType == "calendarView"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.pageType == "schedule"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.pageType == "scheduleCreated"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.pageType == "colleaguesModal"); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.pageType == "searchLocation"); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_co.pageType == "selectLocations"); _ck(_v, 12, 0, currVal_5); }, null); }
export function View_DigitalWorkStatusModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-digitalworkstatus-modal", [], null, null, null, View_DigitalWorkStatusModalComponent_0, RenderType_DigitalWorkStatusModalComponent)), i1.ɵdid(1, 4308992, null, 0, i15.DigitalWorkStatusModalComponent, [i10.CommonService, i16.MAT_DIALOG_DATA, i16.MatDialogRef, i17.WorkStatusService, i12.LoaderService, i13.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DigitalWorkStatusModalComponentNgFactory = i1.ɵccf("app-digitalworkstatus-modal", i15.DigitalWorkStatusModalComponent, View_DigitalWorkStatusModalComponent_Host_0, {}, {}, []);
export { DigitalWorkStatusModalComponentNgFactory as DigitalWorkStatusModalComponentNgFactory };
