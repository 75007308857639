import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from 'src/environments/environment';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../services/moduleid-service";
export class EmergencyService {
    constructor(http, baseUrl, moduleidService) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.selectedEmergencySub$ = new BehaviorSubject(null);
        this.selectedEmergencyItem = this.selectedEmergencySub$.asObservable();
        this.BASEURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
        //this.moduleId = 3;
    }
    getAllEmergencyList(zoneId) {
        let url;
        url = this.BASEURL + `communityV2/emergency/category/all/zoneId?zoneId=${zoneId}`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    createEmergencyUser(zoneId, data) {
        let url;
        url = this.BASEURL + `communityV2/emergency/category/userdetails/update/v2`;
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    setEditEmergencyItem(item) {
        this.selectedEmergencySub$.next(item);
    }
    removeEditEmergencyItem() {
        this.selectedEmergencySub$.next(null);
    }
    toggleEmergencyStatus(zoneId, emergencyId) {
        let url;
        url = this.BASEURL + `communityV2/emergency/config/toggleStatus?emergencyConfigId=${emergencyId}`;
        return this.http
            .post(url, {}, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    createEmergencySetup(zoneId, data) {
        const formData = new FormData();
        formData.append('emergency_category', JSON.stringify(data));
        let url;
        url = this.BASEURL + `communityV2/emergency/category/create/v2`;
        return this.http
            .post(url, formData, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    updateEmergencySetup(zoneId, data) {
        //   const httpOptions = {
        //     headers: new HttpHeaders({
        //         "Content-Type": "application/json",
        //         "ss-header":
        //             '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
        //             zoneId +
        //             ', "sessionId":"' +
        //             localStorage.getItem("ssadmin_session") +
        //             '"}'
        //     })
        // };
        const formData = new FormData();
        formData.append('emergency_category', JSON.stringify(data));
        let url;
        url = this.BASEURL + `communityV2/emergency/category/update/v2`;
        return this.http
            .put(url, formData, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe();
    }
    createEmergency(zoneId, fileData, data, type) {
        const formData = new FormData();
        if (fileData) {
            formData.append('image_file', fileData, fileData.name);
        }
        formData.append('emergency_config', JSON.stringify(data));
        const endpoint = this.BASEURL + 'communityV2/emergency/config/v2';
        if (type == 'editEmergency') {
            return this.http.put(endpoint, formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
        }
        else {
            return this.http.post(endpoint, formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
        }
    }
}
EmergencyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmergencyService_Factory() { return new EmergencyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("BASE_URL"), i0.ɵɵinject(i2.ModuleidService)); }, token: EmergencyService, providedIn: "root" });
