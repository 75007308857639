import { environment } from "src/environments/environment";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { throwError, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../services/moduleid-service";
import * as i3 from "ngx-toastr";
export class SocialDistancingService {
    constructor(http, moduleidService, toastrService, baseUrl) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.toastrService = toastrService;
        this.handleError = (err) => {
            /*let errorMsgData = (err as any).error;
            const message = errorMsgData["message"];
            this.toastrService.error(message);*/
            return throwError(err);
        };
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL;
        this.moduleId = this.moduleidService.getModueId();
    }
    getmapDetails(floorZoneId, currentZone, type = "") {
        let url = `${this.BASEURL}socialdistancing/coloredCounts?zoneId=${floorZoneId}`;
        if (!!type) {
            url = `${this.BASEURL}socialdistancing/coloredCounts?zoneId=${floorZoneId}&view=${type}`;
        }
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    getStatus(currentZone) {
        const url = `${this.EMSURL}ems/activityStatus/all`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    getDepartmentDetails(currentZone) {
        const url = `${this.BASEURL}socialdistancing/departmentCounts`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    getAllReAllocations(zoneId, reqObj) {
        let searchParams = "";
        if (reqObj.searchQuery != "") {
            searchParams = `&searchString=${reqObj["searchQuery"]}`;
        }
        if (reqObj["floorId"] != "" &&
            typeof reqObj["floorId"] !== "undefined") {
            searchParams += `&zoneId=${reqObj["floorId"]}`;
        }
        if (reqObj["filter"] != "") {
            searchParams += `&filter=${reqObj["filter"]}`;
        }
        let url = this.BASEURL +
            `socialdistancing/reallocation?size=${reqObj["pageSize"]}&page=${reqObj["pageIndex"]}${searchParams}`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(catchError(this.handleError));
    }
    getAllSocialDistancingDetails(zoneId) {
        let url = this.BASEURL + `socialdistancing/floorDetails`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(catchError(err => of([])));
    }
    getAllSocialDistanceDetails(zoneId) {
        let url = this.BASEURL + `socialdistancing/average`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(catchError(err => of([])));
    }
    getAllSocialDistancingApplyMeters(zoneId, data) {
        let url = this.BASEURL + `socialdistancing/findValidSeats`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllSocialDistancingReEnableSeats(zoneId, data) {
        let url = this.BASEURL + `socialdistancing/reEnableSeats`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    updateSocialDistancingSeats(data, currentZone) {
        const url = `${this.BASEURL}/socialdistancing/updateSocialDistancingInFloor`;
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    getAllSocialDistancingDepartments(zoneId, locId) {
        let locationId = "";
        if (locId != "") {
            locationId = "?zoneId=" + locId;
        }
        let url = this.BASEURL + `socialdistancing/departmentCounts` + locationId;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    addActivityStatus(data, currentZone) {
        const url = `${this.EMSURL}ems/activityStatus`;
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"], this.toastrService.success("Added Activity status successfully")), catchError(this.handleError));
    }
    updateActivityStatus(data, currentZone) {
        const url = `${this.EMSURL}ems/activityStatus`;
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"], this.toastrService.success("Updated Activity status successfully")), catchError(this.handleError));
    }
    getLocationAvailability(currentZone, rowdata, floorId = "") {
        const url = `${this.BASEURL}entity/findAvailableCountsSocialDistancing`;
        return this.http
            .post(url, rowdata, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    getAvailabilityMapView(currentZone, rowdata) {
        const url = `${this.BASEURL}entity/availabilityMapViewSocialDistancing`;
        return this.http
            .post(url, rowdata, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
    bookSeat(currentZone, data) {
        const url = `${this.BASEURL}socialdistancing/assignSeats`;
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError(this.handleError));
    }
}
SocialDistancingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocialDistancingService_Factory() { return new SocialDistancingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModuleidService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject("BASE_URL")); }, token: SocialDistancingService, providedIn: "root" });
