<div class="modal-card" *ngIf="pageType == 'addUsers'">
  <div class="modal-header p-0">
    <h5 class="semi-bold-blue">Add Users</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopupInMiddle()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body align-left">
    <app-sub-heading title="Subscriptions > Add Users"></app-sub-heading>
    <div class="pl-0 mt-3">
      <div class="row col-12">
        <ul class="list-unstyled px-0">
          <li class="regular-font">
            How would you like to onboard your Users?
          </li>
        </ul>

        <mat-radio-group
          aria-label=""
          class="form-control px-0 radioBtn d-flex flex-column h-auto"
          style="border: none"
          [(ngModel)]="addDepartmentType"
          required
        >
          <!-- <mat-radio-button
                  id="ad_integration"
                  value="ad_integration"
                  class="regular-font mb-3"
                >
                {{ 'AD Integrations' | translate }}
                <span class="light-blue-anchor ml-2 informationIcon">i</span>
                </mat-radio-button> -->

          <mat-radio-button
            id="add_manually"
            class="regular-font mb-3"
            value="add_manually"
          >
            {{ "Add Manually" | translate }}
          </mat-radio-button>

          <mat-radio-button
            id="add_excel"
            class="regular-font mb-3"
            value="add_excel"
          >
            {{ "Add Via Excel" | translate }}
          </mat-radio-button>
        </mat-radio-group>

        <div
          *ngIf="submittedDepartmentType && addDepartmentType == ''"
          class="text-danger"
        >
          Please select one option
        </div>
        <ng-container *ngIf="addDepartmentType == 'add_excel'">
          <div class="row">
            <div class='col-md-5'>
              <label></label>
              <button
                type="button"
                class="form-control1"
                (click)="downloadTemplate('SUBSCRIPTIONS')"
              >
                <span style="font-family: 'Open Sans'">{{
                  "downloadTemplate" | translate
                }}</span>
                <span class="ml-3"
                  ><img
                    src="./assets/images/downloadTemplate.png"
                    style="width: 1rem"
                /></span>
              </button>
            </div>
            <div class="col-md-7">
              <label for="uploadFile" class="label1 float-left mt-2 font-label"
                >{{ "upload" | translate }} (.csv
                {{ "file" | translate }})</label
              >
              <div class="input-group col-md-6 clear-both float-left">
                <input
                  type="file"
                  class="form-control custom-file-input"
                  id="importFile"
                  aria-describedby="inputGroupFileAddon01"
                  (change)="uploadCSV($event.target.files)"
                  accept=".csv,text/csv"
                  #fileUploader
                />
                <label
                  class="custom-file-label1 custom-upload-btn font-label"
                  for="inputGroupFile01"
                  #labelImport
                ></label>
               
              </div>
              <div
                class="input-group col-md-6 mt-3"
                *ngIf="uploadedFileName != ''"
              >
                <p class="card inner-card-auto text-center p-1 d-inline-block">
                  <span class="text-overflow-hidden mt-2">{{
                    uploadedFileName
                  }}</span>
                  <button
                    type="button"
                    class="close text-right mt-2"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" (click)="removeUploadedFile()"
                      >&times;</span
                    >
                  </button>
                </p>
              </div>
              <div
              class="row col-12 text-danger"
              *ngIf="submitted && uploadedFileName == ''"
            >
              Please upload file
            </div>
            </div>
          </div>
          
        </ng-container>
      </div>

      <div class="mt-3">
        <button
          class="btn btn-main btnSmall smallBtn mt-2 float-right"
          id="next"
          name="next"
          (click)="addSubscriptionManually()"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-card" *ngIf="pageType == 'addSubManually'">
  <div class="modal-header p-0">
    <h5 class="semi-bold-blue">
      <i
        class="fa fa-angle-left mr-2 arrowIcon"
        (click)="backBtnDepartment('addUsers')"
      ></i>
      Add Users
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopupInMiddle()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body align-left">
    <app-sub-heading title="Subscriptions > Add Users"></app-sub-heading>
    <div class="pl-0 mt-3">
      <form
        [formGroup]="departmentForm"
        #myForm="ngForm"
        (ngSubmit)="submitUsers()"
      >
        <div class="pl-0">
          <div class="row col-12 px-0">
            <label class="float-left formLabel col-12"
                >EMAIL ADDRESS <span class="text-danger">*</span>
            </label>
            <div class="form-group col-lg-7 col-md-12 pr-0">
              
              <div class="d-inline-flex w-100">
              <input
                #searchTerm
                type="text"
                class="form-control blueText"
                placeholder="Enter Email Id"
                formControlName="departmentName"
                [matAutocomplete]="auto"                
                [(ngModel)]="eName"
                autocomplete="false"
                (input)="filterPeoples(eName)"
              />
              <span>
              <i class="fa fa-spinner fa-spin"  *ngIf="showLoader"></i>
              </span>
             </div>
              <mat-autocomplete autocomplete="false"  class="autoComplete" #auto="matAutocomplete">
                <mat-option
                  *ngFor="let people of nameAutocompleteList$ | async"
                  [value]="people.email"
                  (click)="peopleSelection(people.email)"
                >
                  <span>{{ people.email }}</span>
                </mat-option>
              </mat-autocomplete>
              <div
                *ngIf="
                  submittedDepartment &&
                  departmentForm.controls.departmentName.errors
                "
              >
                <div class="text-danger formLabel">
                  Please enter a valid email address
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12" *ngIf="usersList.length > 0">
            <div
              class="form-group"
              *ngFor="let user of usersList; let i = index"
            >
              <div class="inner-card-auto d-flex justify-content-between mr-2">
                <span class="mr-2">{{ user }}</span>
                <span aria-hidden="true" (click)="removeDepartment(user)"
                  >&times;</span
                >
              </div>
            </div>
          </div>
          <div class="row col-12 mt-5 pr-0">
            <div class="form-group col-lg- col-md-12 pr-0">
              <button
                class="btn btn-main btnSmall semi-bold-blue ml-3 smallBtn float-right"
                type="submit"
              >
                Next
              </button>
              <button
                type="button"
                class="btn btn-main btnSmall btn-main-outline smallBtn float-right marginAddMoreBtn"
                (click)="addMoreUsers(); myForm.resetForm()"
              >
                Add More
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal-card" *ngIf="pageType == 'incorrectEntries'">
  <div class="modal-header p-0">
    <h5 class="semi-bold-blue">Alert</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopupInMiddle()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="row col-12">
    <p class="semi-heading italic">Subscriptions</p>
  </div>

  <div class="modal-body">
    <div class="row col-12">
      <p class="semi-bold-blue">
        {{ "belowUsersWillNotBeOnboarded" | translate }}
      </p>
    </div>
    <div class="form-group row mb-0 p-0">
      <ng-container *ngIf="fileType == 'SUBSCRIPTIONS'">
        <table mat-table [dataSource]="incorrectData" class="mb-4">
          <!-- Sl No -->
          <ng-container matColumnDef="slNo">
            <th mat-header-cell *matHeaderCellDef class="align-left">
              {{ "SlNo" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = dataIndex"
              class="align-left"
            >
              {{ element.slNo }}
            </td>
          </ng-container>

          <!-- First Name Column -->
          <ng-container matColumnDef="emailID">
            <th mat-header-cell *matHeaderCellDef class="align-left">
              {{ "Email Adress" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="align-left"
              [matTooltip]="getToolTipData(element.email)"
            >
              {{ element.email | slice : 0 : 20 }}
              <span *ngIf="element.email.length > 20">...</span>
            </td>
          </ng-container>

          <!-- Reason Column -->
          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef class="align-left">
              {{ "reason" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="align-left"
              [matTooltip]="getToolTipData(element.reason)"
            >
              {{ element.reason | slice : 0 : 20 }}
              <span *ngIf="element.reason.length > 20">...</span>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsIncorrectData"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsIncorrectData"
          ></tr>
        </table>
      </ng-container>

      <div class="row col-12 d-inline-block">
        <button
          class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular"
          type="button"
          (click)="proceedToUpload()"
        >
          Yes
        </button>
        <button
          class="btn btn-main-outline btnSmall btn-radius mr-2 float-right font-regular"
          type="button"
          (click)="closeConfirmPopup('')"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-card" *ngIf="pageType == 'uploadCsvWithFixes'">
  <div class="modal-header p-0">
    <h5 class="semi-bold-blue">
      <i
        class="fa fa-angle-left mr-2 arrowIcon"
        (click)="closeReUploadUsersPopup()"
      ></i>
      {{ "reuploadFile" | translate }}
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeReUploadUsersPopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body align-left">
    <app-sub-heading title="Subscriptions / Add Users"></app-sub-heading>
    <ng-container>
      <div class="row col-12 mt-3 d-flex align-items-center">
        <button
          style="margin-left: 2px"
          id="download_btn"
          mat-mini-fab
          class="btn btn-main mat-mini-fab bgColor"
          (click)="downloadCurrentFile()"
        >
          <img src="./assets/images/save.svg" alt="" />
        </button>
        <span class="ml-3 regular-blue" style="font-size: 14px">{{
          "downloadCurrentFile" | translate
        }}</span>
      </div>
    </ng-container>
    <div class="row col-12 mt-4">
      <p class="semi-bold-blue mb-0">{{ "uploadFileWithFixes" | translate }}</p>
    </div>

    <ng-container>
      <div class="row col-12">
        <div class="col-lg-4 col-md-4 px-0">
          <label for="uploadFile" class="font-label"
            >{{ "upload" | translate }} (.csv {{ "file" | translate }})</label
          >
          <div class="input-group">
            <input
              type="file"
              class="form-control custom-file-input"
              id="importFile"
              aria-describedby="inputGroupFileAddon02"
              accept=".csv"
              (change)="uploadCSV($event.target.files)"
            />
            <label
              class="custom-file-label1 custom-upload-btn font-label"
              for="inputGroupFile02"
              #labelImport
            ></label>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 mt-3" *ngIf="uploadedFileName != ''">
          <p class="card inner-card-auto text-center p-1 d-inline-block w-100">
            <span class="text-overflow-hidden mt-2">{{
              uploadedFileName
            }}</span>
            <button
              type="button"
              class="close text-right mt-2"
              aria-label="Close"
            >
              <span aria-hidden="true" (click)="removeUploadedFile()"
                >&times;</span
              >
            </button>
          </p>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-main pl-3 pr-3 rounded-pill float-right"
          (click)="proceedUploadFile()"
        >
          {{ "next" | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
