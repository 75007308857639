import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { routerTransition } from "../router.animations";
import { ForgotPasswordService } from "./forgotPassword.service";
import { ForgotPasswordUser } from "./forgotPasswordUser";
import { PasswordValidator } from "./../shared/validators/passwordValidator";
import { CommonService } from "../services/common-service.service";
import { Router } from "@angular/router";
import { EMSService } from "../shared/services/ems.service";
import { Constants } from "../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "forgot-password",
    templateUrl: "./forgotPassword.component.html",
    styleUrls: ["./forgotPassword.component.scss"],
    animations: [routerTransition()],
    providers: [CommonService]
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordUser: ForgotPasswordUser;
    forgotPasswordForm: FormGroup;
    forgotPasswordRequest: any = {};
    forgotPasswordResponse: any = {};
    forgotPasswordSuccessful: boolean = false;
    forgotPasswordErrorMsg: any = "";
    imagePath: string;
    public appConfig: any = {};
    public enterpriseName: string;
    public pwdValidationHighLight = true;

    showPasswordReset: boolean = false;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public forgotPasswordService: ForgotPasswordService,
        public emsService: EMSService,
        public commonService: CommonService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.forgotPasswordRequest["verificationCode"] =
                params["verificationCode"];
        });

        this.forgotPasswordService
            .checkVerificationCode(
                this.forgotPasswordRequest["verificationCode"]
            )
            .subscribe(
                res => {
                    this.showPasswordReset = true;
                },
                err => {
                    this.showPasswordReset = false;
                    this.forgotPasswordErrorMsg = err.error.message;
                }
            );

        this.forgotPasswordUser = new ForgotPasswordUser();
        this.forgotPasswordUser.email = localStorage.getItem(
            "forgotPassword_emailId"
        );
        this.forgotPasswordForm = new FormGroup(
            {
                email: new FormControl(this.forgotPasswordUser.email),
                password: new FormControl(this.forgotPasswordUser.password, [
                    Validators.required,
                    Validators.minLength(2)
                ]),
                confirmPassword: new FormControl(
                    this.forgotPasswordUser.confirmPassword,
                    [Validators.required, Validators.minLength(2)]
                )
            },
            {
                validators: this.password.bind(this)
            }
        );

        let currentContext = this;
        this.imagePath = "/assets/images/Smarten-Spaces-logo-login.png";
        this.emsService.getConfig(48, 5, 4).subscribe(
            res => {
                currentContext.appConfig = (res as any).response
                    ? (res as any).response
                    : res;
                currentContext.appConfig = JSON.parse(currentContext.appConfig);
                currentContext.enterpriseName = currentContext.appConfig
                    .enterprise
                    ? currentContext.appConfig.enterprise.name
                        ? currentContext.appConfig.enterprise.name
                        : "SS"
                    : "SS";
                if (currentContext.enterpriseName === "MEX") {
                    currentContext.imagePath =
                        "/assets/images/" +
                        currentContext.enterpriseName.toLowerCase() +
                        "-logo.png";
                }
            },
            err => {
                this.commonService.openSnackBar(err.error.message, "");
                this.imagePath = "/assets/images/Smarten-Spaces-logo-login.png";
            }
        );
    }

    password(formGroup: FormGroup) {
        const { value: password } = formGroup.get("password");
        const { value: confirmPassword } = formGroup.get("confirmPassword");
        return password === confirmPassword ? null : { passwordNotMatch: true };
    }

    closeValidation() {
        let currentContext = this;
        currentContext.forgotPasswordErrorMsg = "";
    }

    pwdStatus = true;
    registerNewPassword() {
        this.pwdStatus = true;
        this.route.params.subscribe(params => {
            this.forgotPasswordRequest["verificationCode"] =
                params["verificationCode"];
        });
        this.forgotPasswordRequest["email"] = this.forgotPasswordUser.email;
        this.forgotPasswordRequest[
            "newPassword"
        ] = this.forgotPasswordUser.password;
        this.forgotPasswordService
            .registerNewPassword(this.forgotPasswordRequest)
            .subscribe(
                res => {
                    this.router.navigate(["/forgotPasswordSuccess"]);
                },
                err => {
                    this.pwdStatus = false;
                    if (this.pwdValidationHighLight == true) {
                        this.forgotPasswordErrorMsg = err["error"]["message"];
                        setTimeout(() => {
                            this.forgotPasswordErrorMsg = "";
                        }, 3000);
                    } else {
                        this.commonService.openSnackBar(
                            err["error"]["message"],
                            ""
                        );
                    }
                }
            );
    }
}
