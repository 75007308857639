<div class="row mt-3">
    <div class="col-md-12 bs-linebreak"></div>
    <div class="col-md-12">
        <table mat-table [dataSource]="attachmentSource" class="table-responsive-md">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                    {{'date' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.date | date: 'MMM d, y' || 'NA'}}
                </td>
            </ng-container>
            
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef>
                    {{'time' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.time || 'NA'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    {{'description' | translate | titlecase}}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.description || 'NA'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="attachment">
                <th mat-header-cell *matHeaderCellDef>
                    {{'attachment' | translate}}
                </th>
                <td mat-cell *matCellDef="let element; let i=index;">
                    <img id="edit_asset_{{i}}"
                    src="./assets/images/edit.png"
                    class="block-img ml-4"
                    (click)="editAsset(element)"
                />                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                    {{'action' | translate}}
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <img id="edit_{{i}}"
                    src="./assets/images/edit.png"
                    class="block-img ml-4"
                    (click)="editAsset(element)"
                />

                    <img id="del_{{i}}"
                    src="./assets/images/delete-photo.png"
                    class="block-img ml-4"
                    (click)="deleteAsset(element)"
                />
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!-- <mat-paginator *ngIf="length" class="w-100 mtop-1" #paginator [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="getNextRecords($event)"
            [length]="length"></mat-paginator> -->
    </div>
</div>