import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CommonService } from 'commonService';
import { LoaderService } from 'loaderService';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { HomeService } from 'src/app/layout/home/service/home.service';
import { DemandSupplyServiceService } from '../../../service/demand-supply-service.service';
import { DemandRequestModalComponent } from '../demand-request-modal.component';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-create-space-request',
  templateUrl: './create-space-request.component.html',
  styleUrls: ['./create-space-request.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ]
})
export class CreateSpaceRequestComponent implements OnInit {

  preferredLocationList;
  departmentList;
  costCodeList;
  shiftList;
  workstationList;
  dataSource;
  editData;
  submitted = false;
  currentDate = new Date();
  workstationSource = [];
  displayedColumns = ['workstation', 'quantity', 'action'];
  spaceRequestForm = this.formBuilder.group({
    preferredLocation: ['', Validators.required],
    departmentName: ['', Validators.required],
    costCode: [''],
    shift: ['', Validators.required],
    reason: ['', Validators.required],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    workstationQtyMapping: this.formBuilder.array([
      this.formBuilder.group({
        workstation: ['', Validators.required],
        quantity: ['', Validators.required]
      })
    ])
  });

  @Input() currentZone: number;
  @Input() data;
  @Output() pageType = new EventEmitter<string>();
  @Output() viewInsightsData = new EventEmitter();
  @Output() createdRequestId = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private loadingService: LoaderService,
    private demandSupplyService: DemandSupplyServiceService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<DemandRequestModalComponent>,
  ) { }

  ngOnInit() {
    this.currentDate.setHours(0, 0, 0, 0);
    this.getAllPreferredLocations();
    // console.log('1')
    if (this.data) {
      // console.log('2')

      if (this.data.type == 'editRequest') {
        // console.log('3')

        this.editData = this.data.requestData;
      }
    }
    // console.log(this.editData)

    if (this.editData) {
      this.spaceRequestForm.controls.preferredLocation.setValue(this.editData.preferredZone.zoneId);
      this.getAllDepartments(); this.getAllCostCode(); this.getAllWorkStations();
      this.spaceRequestForm.controls.departmentName.setValue(this.editData.demandId);
      this.editData.tickets.forEach((ticket, index) => {
        // this.workstationQtyMapping.controls.at(index) as FormGroup).get('description').patchValue(item.description);
        // this.workstationQtyMapping.controls.forEach(element => {
        //   console.log(element);
        //   console.log(ticket.entityType.id);
        //   element.setValue(ticket.entityType.id)
        // });
        // this.expenseEditForm.patchValue({
        //   notes: this.expense.notes,
        // })
        let data = {
          'workstation': ticket.entityType.id,
          'quantity': ticket.quantity
        }
        if (index > 0) {
          this.addWorkstationsQty();
        }
        this.workstationQtyMapping.controls[index].setValue(data);

      });
    }
  }

  // setExpenseCategories(){
  //   // let control = this.workstationQtyMapping.controls.expense_expense_categories_attributes;
  //   this.workstationQtyMapping.controls.forEach(x => {
  //     control.push(this.formBuilder.group(x));
  //   })
  // }

  get workstationQtyMapping() {
    return this.spaceRequestForm.controls["workstationQtyMapping"] as FormArray;
  }

  get f() {
    return this.spaceRequestForm.controls;
  }

  getWorkstationName(element) {
    if (this.workstationList && element.workstation) {
      const filter = this.workstationList.filter((workstation) => workstation.id == element.workstation);
      return filter[0].name;
    }
  }

  getAllPreferredLocations() {
    this.homeService
      .getFilteredZonesByLevel("site", this.currentZone)
      .subscribe(
        (res) => {
          this.preferredLocationList = res["response"];
        },
        (err) => { }
      )
  }

  getAllDepartments() {
    const locationId = this.spaceRequestForm.controls.preferredLocation.value;
    const depatment$ = this.demandSupplyService.getAllDepartments(locationId);
    const dept$ = this.loadingService.showLoaderUntilCompleted(depatment$).subscribe(
      res => {
        this.departmentList = res['response'];
      },
      err => { }
    );
  }

  getAllCostCode() {
    const deptId = this.spaceRequestForm.controls.departmentName.value;
    const locationId = this.spaceRequestForm.controls.preferredLocation.value;
    const costCode$ = this.demandSupplyService.getAllCostCodeByDepartment(locationId, deptId);
    const dept$ = this.loadingService.showLoaderUntilCompleted(costCode$).subscribe(
      res => {
        this.costCodeList = res['response']['content'];
      },
      err => { }
    );
  }

  getAllShiftList() {
    const locationId = this.spaceRequestForm.controls.preferredLocation.value;
    const costCode$ = this.demandSupplyService.getAllShifts(locationId);
    const dept$ = this.loadingService.showLoaderUntilCompleted(costCode$).subscribe(
      res => {
        this.shiftList = res['response'];
      },
      err => { }
    );
  }

  getBookTime = (element) => {
    let startTime = element.startTime.toString();
    let endTime = element.endTime.toString();
    let bookingStart = "00:00";
    let bookingEnd = "23:59";
    if (startTime.length === 2) {
      startTime = "00" + startTime;
    }
    if (endTime.length === 2) {
      endTime = "00" + endTime;
    }
    if (startTime.length === 3) {
      startTime = 0 + startTime;
    }
    if (endTime.length === 3) {
      endTime = 0 + endTime;
    }
    bookingStart = (startTime.length !== 1) ? `${startTime.slice(0, 2)}:${startTime.slice(2, 4)}` : bookingStart;
    bookingEnd = (endTime.length !== 1) ? `${endTime.slice(0, 2)}:${endTime.slice(2, 4)}` : bookingEnd;
    return `${bookingStart} - ${bookingEnd}`
  }

  getAllWorkStations() {
    const locationId = this.spaceRequestForm.controls.preferredLocation.value;
    const workstations$ = this.demandSupplyService.getAllWorkstations(locationId);
    const workstation$ = this.loadingService.showLoaderUntilCompleted(workstations$).subscribe(
      res => {
        this.workstationList = res['response']['content'];
      },
      err => { }
    );
  }

  setPageType(value) {
    this.submitted = true;
    if (this.spaceRequestForm.valid) {
      let data = this.collectFormData();
      this.pageType.emit(value);
      this.viewInsightsData.emit(data);
    }
  }

  addWorkstationsQty() {
    const workstation = this.formBuilder.group({
      workstation: [''],
      quantity: ['']
    });
    this.workstationQtyMapping.push(workstation);
  }

  editWorkstation(element) { }

  deleteWorkstation(workstationIndex: number) {
    this.workstationQtyMapping.removeAt(workstationIndex);
  }

  createSpaceRequest() {
    this.submitted = true;
    if (this.spaceRequestForm.valid) {
      
      // if(this.spaceRequestForm.controls.costCode.value){
      //   data['demandType'] = "COST_CODE";
      //   data["demandId"]= this.spaceRequestForm.controls.costCode.value;
      // }
      let data = this.collectFormData();
      if (this.data.type == 'newRequest') { this.createRequest(data); }
      else if (this.data.type == 'editRequest') { this.updateRequest(data); }
    }
  }

  collectFormData(){
    let entityList = [];
      this.spaceRequestForm.controls.workstationQtyMapping.value.forEach(element => {
        let entity = {
          "entityType": {
            // "id":element.workstation.id
            "id": element.workstation
          },
          "quantity": element.quantity
        }
        entityList.push(entity);
      });
      let startDate = this.spaceRequestForm.controls.startDate.value;
      let endDate = this.spaceRequestForm.controls.startDate.value;
      let currentDateUtc = this.commonService.getTimeStamp(startDate.valueOf(), "start");
      let endDateUtc = this.commonService.getTimeStamp(endDate.valueOf(), "end");
      let data = {
        "demandType": "DEPARTMENT",
        "demandId": this.spaceRequestForm.controls.departmentName.value,
        "tickets": entityList,
        "shiftId": this.spaceRequestForm.controls.shift.value,
        "requestType": "NEW_SPACE",
        "comments": this.spaceRequestForm.controls.reason.value,
        "startTime": currentDateUtc,
        "endTime": endDateUtc,
        "isDualUtilization": true,
        "preferredZone": {
          "zoneId": this.spaceRequestForm.controls.preferredLocation.value
        }
      };

      return data;
  }

  createRequest(data) {
    const workstations$ = this.demandSupplyService.createSpaceRequest(data, this.currentZone);
    const workstation$ = this.loadingService.showLoaderUntilCompleted(workstations$).subscribe(
      res => {
        this.createdRequestId.emit(res['response']['requestId']);
        this.setPageType('saveNotification');
        //this.dialogRef.close({updateData: true})
      },
      err => { }
    );
  }

  updateRequest(data) {
    // console.log(data);
    data['id'] = this.data.requestData.id;
    const workstations$ = this.demandSupplyService.updateSpaceRequest(data, this.currentZone);
    const workstation$ = this.loadingService.showLoaderUntilCompleted(workstations$).subscribe(
      res => {
        this.createdRequestId.emit(res['response']['requestId']);
        this.setPageType('updateNotification');
        //this.dialogRef.close({updateData: true})
      },
      err => { }
    );
  }

}
