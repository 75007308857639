import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PersonalComponent } from "./personal.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, TranslateModule,
    TranslateModule.forChild()],
  declarations: [PersonalComponent],
  exports: [PersonalComponent],
})
export class PersonalModule {}
