import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-modal-loader',
    templateUrl: './modal-loader.component.html',
    styleUrls: ['./modal-loader.component.scss']
})
export class ModalLoaderComponent implements OnInit {
    @Input() loading:boolean;
   // @Input() icon: string;
    constructor() {}

    ngOnInit() {}
}
