import * as i0 from "@angular/core";
export class TabNavigationService {
    constructor() {
        this.currentAllocationTabIndex = 0;
        this.currentApprovalTabIndex = 0;
        this.RequestTabIndex = true; // true = means index at 3 , false =  index at 4
    }
    setCurrentAllocationTabIndex(val) {
        if (val === 'Cost Centre Manager') {
            this.currentAllocationTabIndex = 0;
        }
        else if (val === 'Group Manager') {
            this.currentAllocationTabIndex = 1;
        }
        else if (val === 'Facility Approver' || val === 'Facility Manager') {
            this.currentAllocationTabIndex = 2;
        }
        else {
            this.currentAllocationTabIndex = 0;
        }
    }
    setCurrentAllocationTabIndexByNo(val) {
        this.currentAllocationTabIndex = val;
    }
    getCurrentAllocationTabIndex() {
        return this.currentAllocationTabIndex;
    }
    setCurrentApprovalTabIndex(val) {
        if (val === 'Cost Centre Manager') {
            this.currentApprovalTabIndex = 0;
        }
        else if (val === 'Group Manager') {
            this.currentApprovalTabIndex = 1;
        }
        else if (val === 'Facility Approver' || val === 'Facility Manager') {
            this.currentApprovalTabIndex = 2;
        }
        else {
            this.currentApprovalTabIndex = 0;
        }
    }
    setCurrentApprovalTabIndexByNo(val) {
        this.currentApprovalTabIndex = val;
    }
    getCurrentApprovalTabIndex() {
        return this.currentApprovalTabIndex;
    }
}
TabNavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TabNavigationService_Factory() { return new TabNavigationService(); }, token: TabNavigationService, providedIn: "root" });
