import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from 'loaderService';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ToastrService } from 'ngx-toastr';
import { DemandSupplyServiceService } from '../service/demand-supply-service.service';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-demand-supply-modal',
  templateUrl: './demand-supply-modal.component.html',
  styleUrls: ['./demand-supply-modal.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ]
})
export class DemandSupplyModalComponent implements OnInit {

  workStationList;
  cityList;
  locationList;
  laptopList;
  startTime: { hour: 2, minute: 2 };
  endTime: { hour: 3, minute: 50 };
  requestFormGroup;
  pageType;
  currentZone;
  manageRequest = {};
  requestTypeList;
  whatIfAnalysisForm;
  subtasks;
  allComplete: boolean = false;
  availabilityDataSource = [];
  preferredLocationList = [];
  displayedAvailabiltyColumns = ['workstation', 'quantity', 'availability'];
  displayedColumns = ['workstation', 'price', 'quantity', 'approxCost'];
  preferredZoneEntityInsightList: any = [];
  notifyTimesList = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
    "23:59"
  ];

  showApproveRejectComments = false;
  approveRejectComments;
  constructor(
    private demandSupplyService: DemandSupplyServiceService,
    private loadingService: LoaderService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<DemandSupplyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentZone = this.data["currentZone"];
    this.pageType = this.data["type"];
    // console.log(this.pageType);
  }

  ngOnInit() {
    if (this.pageType === 'sendRequest') {
      this.requestFormGroup = new FormGroup({
        workStation: new FormControl('id1'),
        quantity: new FormControl('100'),
        city: new FormControl(1),
        location: new FormControl(1),
        validity: new FormControl("Temporary"),
        startDate: new FormControl(),
        endDate: new FormControl(),
        shiftStart: new FormControl("10:00"),
        shiftEnd: new FormControl("12:00"),
        startTime: new FormControl(this.startTime),
        endTime: new FormControl(this.endTime),
        dualUtilisation: new FormControl(true),
        costCode: new FormControl(),
        departmentName: new FormControl(),
        laptop: new FormControl(15),
        ipPhone: new FormControl(15),
        vpn: new FormControl(15),
        bioMetric: new FormControl(15),
        specialRequest: new FormControl(),
      });
      this.workStationList = [
        { id: 'id1', name: 'Private cabin' },
        { id: 'id2', name: 'Meeting room' },
      ];
      this.cityList = [
        { id: 1, name: 'Singapore' },
        { id: 2, name: 'India' },
      ];
      this.locationList = [
        { id: 1, name: 'Ecoworld' },
        { id: 2, name: 'Ecospace' },
      ];
      this.laptopList = [
        { id: 5, name: '5' },
        { id: 10, name: '10' },
        { id: 15, name: '15' },
      ];
    } else if (this.pageType === 'if-analysis') {
      this.requestTypeList = [
        { id: 'new', name: 'New' },
        { id: 'move', name: 'Move' },
        { id: 'other_location', name: 'Other Location' },
      ];
      this.whatIfAnalysisForm = new FormGroup({
        // requestType: new FormControl('new'),
        whatIfType: new FormControl(),
      });
      this.dialogRef.updateSize('40%', 'auto');
    } else if (this.pageType === 'share-info') {

    } else if (this.pageType === 'share-recommendations') {

    } else if (this.pageType === 'recommendations') {
      this.subtasks = [
        { name: 'EcoSpace', completed: false, desks: "80 desks" },
        { name: 'Milennia', completed: false, desks: '120 desks' },
        { name: 'Titanium', completed: false, desks: '90 desks' }
      ];
      // console.log(this.subtasks)
    } else if (this.pageType === "preferredAllocation" || this.pageType === "availability") {
      // console.log(this.data)
      this.getViewInsightsData(this.data.dataObj);
    } else if(this.pageType == 'approvalNotification' || this.pageType == 'rejectNotification'){
      // this.approveRejectRequest();
      // console.log('in')
      this.showApproveRejectComments = true;
      this.dialogRef.updateSize('40%', 'auto');
    }
  }
  shareRecommendations() {
    this.pageType = 'share-recommendations';
  }
  backToRecommendations() {
    this.pageType = 'recommendations';
  }
  closePopup() {
    this.dialogRef.close();
  }
  sendRequest() {
    // console.log('Send Request', this.requestFormGroup.value);
  }
  whatIfAnalysisApply() {
    this.dialogRef.close({
      whatIf: this.whatIfAnalysisForm.controls.whatIfType.value,
    updateData: true});

  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.subtasks == null) {
      return;
    }
    this.subtasks.forEach(t => t.completed = completed);
  }

  updateAllComplete() {
    this.allComplete = this.subtasks != null && this.subtasks.every(t => t.completed);
    this.subtasks.forEach(t => {
      if (t.completed == true) {
        this.allComplete = true;
      }
    });
  }



  //ab
  getViewInsightsData(event) {
    // console.log('1')
    // console.log(event)
    if (event) {
      // console.log('2')
      // console.log(this.data.type)
      if (this.data.type == "preferredAllocation") {
        this.dialogRef.updateSize("80%","60%");
        let insightList: any = [];
        const requests$ = this.demandSupplyService.getEntityTypeInsights(this.data.currentZone, event);
        const request$ = this.loadingService.showLoaderUntilCompleted(requests$).subscribe(
          res => {
            if (res['response']) {
              this.preferredLocationList = res['response'];
              this.preferredZoneEntityInsightList = res['response'];
              // console.log(this.preferredZoneEntityInsightList)
              this.preferredZoneEntityInsightList.values.forEach(building => {
                // console.log('4')
                building.floorCounts.forEach(floor => {
                  let countRequired = 0;
                  let totalCountAvailable = 0;
                  floor.entityTypeCounts.forEach(entity => {
                    if (this.preferredZoneEntityInsightList["requestedCountsByEntityType"][entity.entityType.id]) {
                      countRequired = countRequired + parseInt(this.preferredZoneEntityInsightList["requestedCountsByEntityType"][entity.entityType.id]);
                    }
                    // console.log(entity.availableCount);
                    totalCountAvailable = totalCountAvailable + parseInt(entity.availableCount);
                  });
                  floor["countRequired"] = countRequired;
                  floor["totalCountAvailable"] = totalCountAvailable;
                  // console.log(`total is ${totalCountAvailable}`)
                });
              });
              // this.toastrService.success(`Request with id ${element.requestId} deleted successfully`);
              // console.log(this.pageIndex);
              // this.getAllRequests(this.pageIndex, this.pageSize);
            } else {
              this.toastrService.error(res['message']);
            }
          },
          err => { }
        )
      } else if (this.data.type == "availability") {
        this.dialogRef.updateSize("80%","60%");
        const requests$ = this.demandSupplyService.getAvailabilityInsights(this.data.currentZone, event);
        const request$ = this.loadingService.showLoaderUntilCompleted(requests$).subscribe(
          res => {
            // console.log(res['response'])
            if (res['response']) {
              this.availabilityDataSource = res['response'];
              // this.toastrService.success(`Request with id ${element.requestId} deleted successfully`);
              // console.log(this.pageIndex);
              // this.getAllRequests(this.pageIndex, this.pageSize);
            } else {
              this.toastrService.error(res['message']);
            }
          },
          err => { }
        )
      }
    }
  }

  getLegendColor(entityId) {
    return this.preferredZoneEntityInsightList.entityTypeColorMap[entityId];
  }

  getRequiredWorkstationCount(entityId) {
    // let count = 0;
    // if(type == 'individual'){
    if (this.preferredZoneEntityInsightList.requestedCountsByEntityType[entityId]) {
      return this.preferredZoneEntityInsightList.requestedCountsByEntityType[entityId]
    } else {
      return 0
    }
    // } else {
    //   if (this.preferredZoneEntityInsightList.requestedCountsByEntityType[entityId]) {
    //     count = count+parseInt(this.preferredZoneEntityInsightList.requestedCountsByEntityType[entityId]);
    //     return count;
    //   }
    // }
  }

  closeConfirmationPopup(){
    this.dialogRef.close({updateData: true});
  }

  approveRejectRequest(){
    let data ;
    this.showApproveRejectComments = false;
    if(this.pageType == 'approvalNotification'){
      data = {
        "demandRequest":{
            "id": this.data.dataObj.id
        },
        "comments":this.approveRejectComments,
        "approvalStatus":"APPROVED"
    }
    } else if(this.pageType == 'rejectNotification'){
      data = {
        "demandRequest":{
            "id": this.data.dataObj.id
        },
        "comments":this.approveRejectComments,
        "approvalStatus":"REJECTED"
    }
    }
    const requests$ = this.demandSupplyService.approveRejectRequest(this.data.currentZone, data);
    const request$ = this.loadingService.showLoaderUntilCompleted(requests$).subscribe(
      res => {
        console.log(res)
      },
      err => {}
    )
  }
}

