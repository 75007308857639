import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { tap, map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../../services/moduleid-service";
export class ExpenseService {
    constructor(translate, http, baseUrl, moduleidService) {
        this.translate = translate;
        this.http = http;
        this.moduleidService = moduleidService;
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.BASEEMSURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
    }
    getTranslate(word) {
        let translatedWord = word;
        const test = this.translate.get([word]).subscribe((translation) => {
            translatedWord = translation;
        });
        return translatedWord[word];
    }
    createExpenseType(data, zoneId) {
        let url = this.BASEURL + `expenseType/multi`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    updateExpenseType(data, zoneId) {
        let url = this.BASEURL + `expenseType/multi`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    disableExpenseType(data, zoneId) {
        let url = this.BASEURL + `expenseType/disable`;
        return this.http.put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    enableExpenseType(data, zoneId) {
        let url = this.BASEURL + `expenseType/enable`;
        return this.http.put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllExpenseTypeWithSearchItem(data, zoneId) {
        let url;
        if (data.key != "" && data.value != "") {
            url = this.BASEURL + `expenseType/getAllMulti?key=${data.key}&operation=:&value=${data.value}`;
        }
        else {
            url = this.BASEURL + `expenseType/getAllMulti`;
        }
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(map(res => res["response"]));
    }
    getAllExpenseType(zoneId) {
        let url = this.BASEURL + `expenseType/getAllMulti`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(map(res => res["response"]));
    }
    getExpenseTypeById(id, zoneId) {
        let url = this.BASEURL + `expenseType/get?id=${id}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    /**expense module */
    getAllExpense(zoneId, data) {
        let url = this.BASEURL + `expense/getAll?size=${data.pageSize}&page=${data.pageIndex}`;
        // return this.http.get<any>("http://localhost:4200/assets/expense.json")
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    createExpense(data, zoneId) {
        let url = this.BASEURL + `expense/create`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    updateExpense(data, zoneId) {
        let url = this.BASEURL + `expense/update`;
        return this.http.put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    disableExpense(data, zoneId) {
        let url = this.BASEURL + `expense/disable`;
        return this.http.put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    enableExpense(data, zoneId) {
        let url = this.BASEURL + `expense/enable`;
        return this.http.put(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    viewExpense(id, zoneId) {
        let url = this.BASEURL + `expense/get?id=${id}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    /**expense module */
    /** recurring Expense Module */
    getAllRecurringExpense(zoneId, data) {
        let url = this.BASEURL + `recurringexpense/getAll?size=${data.pageSize}&page=${data.pageIndex}`;
        // return this.http.get<any>("http://localhost:4200/assets/expense.json")
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    createRecurringExpense(data, zoneId) {
        let url = this.BASEURL + `recurringexpense/create`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    updateRecurringExpense(data, zoneId) {
        let url = this.BASEURL + `recurringexpense/update`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    disableRecurringExpense(data, zoneId) {
        let url = this.BASEURL + `recurringexpense/disable`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    enableRecurringExpense(data, zoneId) {
        let url = this.BASEURL + `recurringexpense/enable`;
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
    viewRecurringExpense(id, zoneId) {
        let url = this.BASEURL + `recurringexpense/get?id=${id}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        }).pipe(tap(res => console.log(res)), map(res => res["response"]));
    }
}
ExpenseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExpenseService_Factory() { return new ExpenseService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject("BASE_URL"), i0.ɵɵinject(i3.ModuleidService)); }, token: ExpenseService, providedIn: "root" });
