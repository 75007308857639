import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import "@fullcalendar/angular";
import { CalendarOptions, DayCellContentArg } from "@fullcalendar/angular"; // useful for typechecking
import { FullCalendarComponent } from "@fullcalendar/angular";
import { MatDialog } from "@angular/material";
import { CommonService } from "commonService";
import dayGridPlugin from "@fullcalendar/daygrid";
import { DashboardService } from "../services/dashboard.services";
import { LoaderService } from "loaderService";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogService } from "confirmationDialogService";
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { EMSService } from "emsService";


@Component({
  selector: "app-my-schedules",
  templateUrl: "./my-schedules.component.html",
  styleUrls: ["./my-schedules.component.scss"],
})
export class MySchedulesComponent implements OnInit {
  // submitted = false;
  currentZone;
  dt = new Date();
  gotIt = true;
  preference = false;
  calenderView = false;
  dateTime = new Date();
  selectedBookingType = "OFFICE";
  selectedDates = [];
  removeDateSelection = false;
  calendarOptions = {};
  events = [];
  preferenceObj= [];
  calendarColors = {
    OFFICE: "#0F1F54",
    WFH: "#f36c20",
    LEAVE: "#86bce3",
  };
  @Output() doneSchedule = new EventEmitter();
  @ViewChild(FullCalendarComponent, { static: false })
  fullCalendarComponent: FullCalendarComponent;
  options=false;

  //Personal Tab Variables
  specialReqList = [];
  preferencesOptions ={};
  preferencesOptionsanswers=[];
  question;
  timeZoneByConfig = "UTC";
  localVal;
  private unsubscribe$ = new Subject<void>();
  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    public dashboardService: DashboardService,
    public loaderService: LoaderService,
    private toastrService: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private translate: TranslateService,
    private emsService: EMSService
  ) {

    const commonTimeSub$ = this.commonService.currentTimeZone.subscribe((res) => {
      this.timeZoneByConfig = res;
    });
    this.localVal = localStorage.getItem("language");
    this.calendarOptions = {
      timeZone: "UTC",
      initialView: "dayGridMonth",
      locale: this.localVal,
      dayCellClassNames: this.customDayCellContent.bind(this),
      plugins: [dayGridPlugin],
      showNonCurrentDates: false,
      height: "auto",
      dateClick: this.handleDateClick.bind(this),
      dayCellDidMount: this.handleCellClick.bind(this),
      schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
      headerToolbar: {
        left: "previousMonth",
        center: "title",
        right: "nextMonth",
      },
      customButtons: {
        previousMonth: {
          text: "Previous",
          icon: "chevron-left",
          click: this.prevMonth.bind(this), // !!!
        },
        nextMonth: {
          text: "Next",
          icon: "chevron-right",
          click: this.nextMonth.bind(this), // !!!
        },
      },
      eventContent: (arg) => {
        let htmlStr = "";
        return {
          html:
            "<div class='eventColor' style='background-color:" +
            arg.event.extendedProps.workStatusColor +
            "'>&nbsp</div>",
        };
      },
      eventSources: [
        {
          events: this.events,
          display: "list-item",
          borderColor: "none",
        },
      ],
      selectOverlap: false,
      // dayHeaderContent: (arg) => {
      //   const weekdaysMap = {
      //     en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun'],
      //     pt: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb','Dom'],
      //     de: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa','So'],
      //     es: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb','Dom']
      //   };

      //   const selectedLanguage = this.localVal || 'en';

      //   const weekdays = weekdaysMap[selectedLanguage];
      //   if (weekdays) {
      //     return weekdays[arg.date.getDay()];
      //   } else {
      //     return weekdaysMap['en'][arg.date.getDay()];
      //   }
      // },
      // dayHeaderContent: (arg) => {
      //   const dayIndex = arg.date.getDay();
      //   const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];
      //   const dayName = daysOfWeek[dayIndex];
      //   return this.translate.instant(dayName);
      // },
    };
  }

  ngOnInit() {
    this.getGotItStatus()
    this.getPreferencesQuestions();
  }

  getGotItStatus(){
    this.commonService.currentZone
    .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
    .subscribe((res) => {
      this.currentZone = res;
    });
    const currentuserModule$ = this.emsService.getActiveDigitalIntro(this.currentZone);
    this.loaderService
      .showLoaderUntilCompleted(currentuserModule$)
      .subscribe(
      (res) => {
        if(res['response'].includes('WorstatusGotIt')){
          this.mySchedule('preference');
        }else{
          this.gotIt = true;
          
        }
      })
  }

  postVisitedDigitalIntroScreenData() {
    let visitedIntroScreenData = "WorstatusGotIt";
    return this.emsService.postVisitedDigitalIntro(
        this.currentZone,
        visitedIntroScreenData
    )
  }

  customDayCellContent(dayCellContentArg: DayCellContentArg) {
    const cellDate = dayCellContentArg.date;
    let startDate =this.commonService.getTimeStampTimeZone(new Date(),this.timeZoneByConfig,'start');
    if(startDate > cellDate.getTime()){
      dayCellContentArg.isPast=true;
    }else{
      dayCellContentArg.isPast=false;
    }
  }

  tabClick = (event) => {
    const tab = event.tab.textLabel;
    if(tab == 'My Work Status'){
      this.updateDateAndLoadInfo()
    }
  }
 

  nextMonth() {
    this.fullCalendarComponent.getApi().next();
    this.updateDateAndLoadInfo();
  }

  prevMonth() {
    this.fullCalendarComponent.getApi().prev();
    this.updateDateAndLoadInfo();
  }

  handleDateClick(arg) {
    let isClickable = false;
    const cellDate = arg.date;
    let startDate =this.commonService.getTimeStampTimeZone(new Date(),this.timeZoneByConfig,'start');
    if(startDate <= cellDate.getTime()){
      isClickable=true;
    }else{
      isClickable=false;
    }


    const selectedDate = this.commonService.getUTCTimeStampTimeZone(
      arg.date.getTime(),
      this.timeZoneByConfig,
      "start"
    );

    
    let eventIndex = this.events.findIndex((res) => {
      return res.start == selectedDate;
    });

    let checkDateExists = this.selectedDates.findIndex(
      (e) => e.date == selectedDate
    );

    if(isClickable)
  {
      if (checkDateExists > -1) {
        this.selectedDates.splice(checkDateExists, 1);
        this.events[eventIndex]["workStatusColor"] = "none";
      } else {




        if(this.events[eventIndex]["workStatusColor"] == 'none'){
          this.selectedDates.push({
            date: selectedDate,
            workstatus: this.selectedBookingType,
          });
          this.events[eventIndex]["workStatusColor"] =
          this.calendarColors[this.selectedBookingType];
          this.fullCalendarComponent.getApi().removeAllEventSources();
          this.fullCalendarComponent.getApi().addEventSource(this.events);
          this.fullCalendarComponent.getApi().refetchResources();
          this.fullCalendarComponent.getApi().refetchEvents();
      }else{
        if(this.events[eventIndex]["workstatus"]!=this.selectedBookingType){
        this.confirmationDialogService
          .confirm("", this.translate.instant("areYouSureYouWantChangeWorkstaus"))
        .then((confirmed) => {
          if (confirmed == true) {
            this.selectedDates.push({
              date: selectedDate,
              workstatus: this.selectedBookingType,
            });
            this.events[eventIndex]["workStatusColor"] =
            this.calendarColors[this.selectedBookingType];
            this.fullCalendarComponent.getApi().removeAllEventSources();
            this.fullCalendarComponent.getApi().addEventSource(this.events);
            this.fullCalendarComponent.getApi().refetchResources();
            this.fullCalendarComponent.getApi().refetchEvents();
            
          }
        });
      }
      }
      }
    }

    this.fullCalendarComponent.getApi().removeAllEventSources();
    this.fullCalendarComponent.getApi().addEventSource(this.events);
    this.fullCalendarComponent.getApi().refetchResources();
    this.fullCalendarComponent.getApi().refetchEvents();
  }

  handleCellClick(arg) {
    // console.log(arg);
  }
  getTranslate(word) {
    let translatedWord = word;
    const test = this.translate.get([word]).subscribe((translation) => {
      translatedWord = translation;
    });
    return translatedWord[word];
  }

  mySchedule(type) {
    if (type == "gotIt") {
      this.gotIt = false;
      this.preference = true;
      this.calenderView = false;
    } else if (type == "preference") {
      this.postVisitedDigitalIntroScreenData().subscribe(()=>{
        this.updateDateAndLoadInfo()
      });
        this.gotIt = false;
        this.preference = false;
        this.calenderView = true;
      
    }
  }

  selectBookingType(event) {
    this.selectedBookingType = event.value;
  }

  updateDateAndLoadInfo() {
    let changedDate = this.fullCalendarComponent.getApi().getDate().getTime();
    this.loadCurrentMonthInfo(changedDate);
  }

  loadCurrentMonthInfo(changedDate) {
    let cdate = new Date(changedDate);
    this.events = [];
    const firstMonth = new Date(
      cdate.getUTCFullYear(),
      cdate.getUTCMonth(),
      1
    );

    const lastMonth = new Date(
      cdate.getUTCFullYear(),
      cdate.getUTCMonth() + 1,
      0
    );
    
    let fdateTimeStamp = this.commonService.getTimeStamp(
      firstMonth.getTime(),
      "start"
    );
    let ldateTimeStamp = this.commonService.getTimeStamp(
      lastMonth.getTime(),
      "end"
    );
    let bookedSeats = this.dashboardService.loadBookedSeats(
      fdateTimeStamp,
      ldateTimeStamp
    );
    this.loaderService
      .showLoaderUntilCompleted(bookedSeats)
      .subscribe((res) => {
        let bookingInfo = res["response"];
        if (bookingInfo.length > 0) {
          bookingInfo.forEach((element) => {
            let color = element["color"];
            if (color == null) {
              color = "none";
            }
            let selectedDate = element["dayStart"];
            let selectedDateIndex = this.selectedDates.findIndex(
              (e) => e.date == selectedDate
            );

            if (selectedDateIndex > -1) {
              color =
                this.calendarColors[
                  this.selectedDates[selectedDateIndex]["workstatus"]
                ];
            }

            let data = {
              title: "",
              start: element.dayStart,
              workStatusColor: color,
              workstatus:element.status
            };
            this.events.push(data);
          });

          this.fullCalendarComponent.getApi().removeAllEventSources();
          this.fullCalendarComponent.getApi().addEventSource(this.events);
          this.fullCalendarComponent.getApi().refetchResources();
          this.fullCalendarComponent.getApi().refetchEvents();
        }
      });
   
    this.fullCalendarComponent.getApi().removeAllEventSources();
    this.fullCalendarComponent.getApi().addEventSource(this.events);
    this.fullCalendarComponent.getApi().refetchResources();
    this.fullCalendarComponent.getApi().refetchEvents();
  }

  myScheduleCreate() {
    let createObj = {};
    this.selectedDates.forEach((res) => {
      let dateToTime = new Date(res.date).getTime();
      let workTypeKeys = Object.keys(createObj);
      let workstatus = res.workstatus;

      if (workTypeKeys.includes(workstatus)) {
        createObj[workstatus]["dayStart"].push(dateToTime);
      } else {
        createObj[workstatus] = {
          dayStart: [dateToTime],
        };
      }
    });
    this.doneSchedule.emit(createObj);
  }

  getPreferences(checkedPreferences) {
 
    let quesObj={
      question: {
        id:this.question['id'],
        questionType: this.question['questionType'],
      },
      userId: localStorage.getItem("ssadmin_userId"),
      answer: {
        id: ''
      }
    }

    if(checkedPreferences['checked'])
    {
      quesObj.answer.id=checkedPreferences['id'];
      this.preferenceObj.push(quesObj);
    }
    else if(!checkedPreferences['checked'])
    {
      var index = this.preferenceObj.splice(this.preferenceObj.findIndex(a => a['answer'].id === checkedPreferences['id']) , 1);
      
    }
  }

  closePreference(type) {
    if(type == "preference") {
      this.doneSchedule.emit("closePreference");
    }
  }
  savePreference(){
      let createRosterAPI = this.dashboardService.createPreference(this.preferenceObj);
      this.loaderService.showLoaderUntilCompleted(createRosterAPI).subscribe(
        (res) => {
          if(res){
          this.toastrService.success(res['message']);
          }
        },
        (err) => {
          this.toastrService.error(this.translate.instant("somethingWentWrong"));
        }
      );
    
  }

  getPreferencesQuestions(){
    let createRosterAPI = this.dashboardService.loadPrefrences();
      this.loaderService.showLoaderUntilCompleted(createRosterAPI).subscribe(
        (res) => { 
          if(res['response']['content'].length>0){
            let response = res['response']['content'][0]['question'];
            let answers = res['response']['content'][0]['answers'].map(res=>res.name);
            this.specialReqList= response['options'];
            this.question = response;
            this.specialReqList.forEach(element => {
              if(element.isEditable){
                let checkedVal = false;
                if(answers.includes(element.name)){
                  checkedVal = true;
                }
                this.preferencesOptions={
                  checked:checkedVal,
                  id:element.id,
                  name: element.name,
                  status: element.status,
                  isEditable:true
                };

                if(checkedVal){
                  let quesObj={
                    question: {
                      id:this.question['id'],
                      questionType: this.question['questionType'],
                    },
                    userId: localStorage.getItem("ssadmin_userId"),
                    answer: {
                      id: ''
                    }
                  }
                  quesObj.answer.id=element.id;
                  this.preferenceObj.push(quesObj);
                }
              }
              else if(!element.isEditable){
                this.preferencesOptions={
                  checked:true,
                  id:element.id,
                  name: element.name,
                  status: element.status,
                  isEditable:false
                }
                
                let quesObj={
                  question: {
                    id:this.question['id'],
                    questionType: this.question['questionType'],
                  },
                  userId: localStorage.getItem("ssadmin_userId"),
                  answer: {
                    id: ''
                  }
                }
                quesObj.answer.id=element.id;
                this.preferenceObj.push(quesObj);
              }              
              this.preferencesOptionsanswers.push(this.preferencesOptions);
            });
          } 
          else{
            this.toastrService.error(this.translate.instant("somethingWentWrong"))
          }     
         
          // this.toastrService.error("Roster created successfully");         
        },
        (err) => {
          this.toastrService.error(this.translate.instant("somethingWentWrong"));
        }
      );
  }
}

