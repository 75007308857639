<mat-card *ngIf="data.type == 'addFloorToTenant'">
    <mat-card-title>
        <div class="row">
            <div class="col-md-12">
                {{'pickTenantFloorForSite' | translate}}: {{selectedSite?.siteName}}
                <button class="close" id="close_btn" type="button" style="text-align: right;">
                    <span (click)="onNoClick()">×</span></button>
            </div>
        </div>
       
    </mat-card-title>
    <mat-card-content>
        <div class="row" style="padding: 1.2rem;">
            <div class="col-md-6" *ngIf="buildingListForSite">
                <mat-form-field appearance="fill">
                    <mat-select id="building_name" required placeholder="{{'selectBuilding' | translate}}" [(ngModel)]="selectedBuilding">
                        <mat-option *ngFor="let building of buildingListForSite" (click)="onSelectBuilding()" [(value)]="building.id" required>
                            {{building.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="floorListForBuilding">
                <mat-form-field appearance="fill">
                    <mat-select id="floor_name" required placeholder="{{'selectFloor' | translate}}" [(ngModel)]="selectedFloor"
                        (change)="onSelectFloor()">
                        <mat-option *ngFor="let floor of floorListForBuilding" [(value)]="floor.id" required>
                            {{floor.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-12">
            <button id="add_floor" class="btn btn-main" (click)="addFloor1()">{{'addFloor' | translate}}</button>
        </div>
    </mat-card-content>
</mat-card>

<div *ngIf="data.type == 'addRolesAndLocation'">
    <div class="row" style="padding:0px 20px">
        <div class="col-md-6">
            <div class="section-title">{{'pickRoles' | translate}}</div>
        </div>
        <div class="col-md-6 text-right">
            <i id="dismiss_modal" class="fa fa-window-close no-click-btn" aria-hidden="true" (click)="onNoClick()"></i>
        </div>
    </div>
    <div class="bs-border">
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12" *ngIf="roleList.length > 0">
                            <div class="form-group">
                                <label>{{'role' | translate | uppercase}}</label>
                                <select id="selected_role" class="form-control" [(ngModel)]="selectedRole" placeholder="Select Role">
                                    <option value="" disabled selected hidden>{{'pickRole' | translate}}</option>
                                    <option *ngFor="let role of roleList" [ngValue]="role.id">{{role.name}}</option>
                                </select>
                            </div>
                            <!-- <mat-form-field appearance="fill">
                                <mat-select required placeholder="Select Role" [(ngModel)]="selectedRole">
                                <mat-option *ngFor="let role of roleList" [(value)]="role.id" required>{{role.name}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                        </div>
                        <!-- </div>
                    <div class="row"> -->
                        <div class="col-md-12" *ngIf="locationList">
                            <!-- <mat-form-field appearance="fill">
                                <mat-select required placeholder="Select site" [(ngModel)]="selectedLocation">
                                <mat-option *ngFor="let location of locationList" [(value)]="location.id" required>{{location.name}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <div class="form-group">
                                <label>{{'location' | translate | uppercase}}</label>
                                <select id="selected_location" class="form-control" [(ngModel)]="selectedLocation"
                                    placeholder="Select Location">
                                    <option value="" disabled selected hidden>{{'pickLocation' | translate}}</option>
                                    <option *ngFor="let location of locationList" [ngValue]="location.id">
                                        {{location.name}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- </div> -->
                        <div class="col-md-12">
                            <button id="add_role" class="btn btn-main" (click)="addRoleAndLocation()">{{'addRole' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<mat-card *ngIf="data.type == 'changeUser'">
        <mat-card-title>
            <div class="row">
                <div class="col-md-12">
                    {{'selectUser' | translate | titlecase}}
                    <button class="close" id="close_user_btn" type="button" style="text-align: right;" >
                        <span (click)="onNoClick()">×</span></button>
                </div>
            </div>
        </mat-card-title>
        <form #form="ngForm" novalidate name="selectUser">
            <div class="form-group">
                <mat-form-field style="margin-top: 15px;">
                    <mat-select id="searched_user" [formControl]="bankServerSideCtrl" placeholder="{{'selectUser' | translate | titlecase}}"  [(ngModel)]="selectedEmsUserIds">
                        <mat-option>
                        <ngx-mat-select-search  placeholderLabel="Search User" noEntriesFoundLabel="'no matching found'"  [formControl]="bankServerSideFilteringCtrl" [searching]="searching"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let user of filteredServerSideBanks | async" [value]="user.userId">
                        {{user.name +' - '+ user.email}}
                        </mat-option>
                        <mat-option *ngIf="showLoadMore" value="loadmore" disabled (click)="getNextRecords()">{{'loadMore' | translate}}...</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
        <mat-card-content> 
            
            <div class="col-md-12 text-right">
                <button id="add_user" class="btn btn-main" (click)="addUser()">{{'addUser' | translate | titlecase}}</button>
            </div>
        </mat-card-content>
    </mat-card>