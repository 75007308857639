import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatInput, MatSelect, MatOption, MatCheckbox, MatTableDataSource, MatRadioButton, MatRadioGroup } from '@angular/material';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { modulesIds } from '../../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import 'rxjs/add/observable/of';
import {forkJoin} from 'rxjs'
import { CommonService } from '../../../services/common-service.service';
import { VMSService } from '../service/vms.service';
import { CrmLead } from '../model/crmLead';
import { PointOfContact } from '../model/pointOfContact';
import { RequirementOfCustomer } from '../model/requirementOfCustomer';
import { routerTransition } from '../../../router.animations';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
 
import { EMSService } from '../../../shared/services/ems.service';
import { TMSService } from '../../../shared/services/tms.service';
// import { Question } from '../../community/model/question';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from "../../../shared/modules/loader/loader.service";
import { takeUntil, distinctUntilChanged, debounceTime, switchMap, mergeMap } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-vms-modal',
  templateUrl: './vms-modal.component.html',
  styleUrls: ['./vms-modal.component.scss']
})
export class VMSModalComponent implements OnInit, AfterViewInit {
  currentZone = 5;
  private unsubscribe$ = new Subject<void>();
  public form: FormGroup;
  isValidParticipantForm: boolean;
  participantName;
  locations = [];
  hosts = [];
  public csvRecords: any[] = [];
  columns: string[] = ['no', 'visitor', 'email', 'phone', 'company', 'remove'];
  visitorList: any[] = [];
  dataSource = [];
  visitorInfo = {'email':'','mobile':"",'company':'' , 'name':''};
  hostInfo = {'scheduleStart': new Date().getTime(),'scheduleEnd':new Date().getTime(),'hostInfo':'','purpose':'','host':'','location':''};
  csvContent: string;
  questionAndAnswers:any = [];
  model: any = {"optionsq":[]};
  loading:boolean = false;
  encryptedQRCode:any = '';
  vmsConfig:any;
  // public encryptedQRCode: string = null
  minDate = new Date(new Date().setHours(0,0,0,0));
  declaration = '';
  questionEntered = false;
  pageType:any;
  intivationId:any;
  DataList: any = [];
  allList: any;
  isAdditional:boolean;
  questionDetails = {};
  questionHealthDetails = {};
  alldetails = [];
  alldetailsList: any[];
  alldetailsListHealth :any[];
  AllQuestionList: any[];
  questionListFinal = [];
  questionListFinalHealth = [];
  attemptedNumber: any;
  assessmentStatus: any;
  moduleEntityId: any;
  createdAt: any;
  isExtraInformationRequired: any;
  customFieldsEnable: any;
  visitorTypes:any;
  storeVisitor=[];
  isVisitorName:any;
  isVisitorEmail:any;
  isVisitorMobile:any;
  isVisitorCompany:any;
  addVisitorInfo:FormGroup;
  visitorInfo_name;visitorInfo_email;
  visitorInfo_mobile;visitorInfo_company;
  submitted= false;
  tenantId;
  constructor(
    public matSnackBar: MatSnackBar,
    private tmsService: TMSService,
    public dialogRef: MatDialogRef<VMSModalComponent>,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public commonService: CommonService,
    public vmsService: VMSService,
    public emsService: EMSService,
    private fb: FormBuilder, 
    private toastrService: ToastrService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.pageType = this.data['type'];
      if(this.data['type'] == 'visitorInfo'){
        
        this.intivationId  = this.data['invitationVisitorInfoId'];
        //this.getAllActiveIdentityAnswered();
      }
      this.currentZone = this.data['currentZone'];
      this.getConfig();  
    }

  ngOnInit() {
    
    if(this.data['type'] == 'createInviteVisitorNew'){
      this.emsService.getCurrentUserModules(this.currentZone).subscribe(profile =>{
        if(profile['response'] !=null){
          this.tenantId = profile['response']['tenantId'];
        }
      });
    }
    if(this.data['type'] == 'showDetails'){
     
      this.questionDetails  = this.data;
      this.showDetailsOfQuestions(this.questionDetails ) 
        
    } 

    if(this.data['type'] == 'showDetailsHealth'){
     
      this.questionHealthDetails  = this.data;
      this.showDetailsOfHealthQuestions(this.questionHealthDetails ) 
        
    }
    if(this.data['type'] == 'visitorInfo'){
 
      //this.getAllCustomCheckList();
      //this.getAllActiveCustomCheckList();
      this.getQuestionAndAnswers();
    }

    if(this.data['type'] == 'additionalDetails'){
     
      this.intivationId  = this.data['content']['invitationVisitorInfoId'];
      this.getAllActiveIdentityFields();
    }

    if(this.pageType == 'showQrcode') {
      this.showQRCode();
    }
    
    this.addVisitorInfo = this.fb.group({
      'visitorName': ['', []],
      'visitorEmail': ['', []],
      'visitorMobile': ['', []],
      'visitorCompany': ['', []]
    });
    
    this.form = this.fb.group({
      visitors: this.fb.array([
        this.fb.group({
          'visitorTypeId': this.fb.control(null, Validators.required),
          'name': this.fb.control(null, Validators.required),
          'email': this.fb.control(null, Validators.required),
          'mobile': this.fb.control(null, Validators.required),
          'company': this.fb.control(null)
        })
      ]),
      scheduleStart: this.fb.control(null, Validators.required),
      scheduleEnd: this.fb.control(null, Validators.required),
      purpose: this.fb.control(null, Validators.required),
      location: this.fb.control(null, Validators.required),
      hostEmail: this.fb.control(null, Validators.required),
      invitationInfoId: this.fb.control(null),
      webEx: this.fb.control(null),
      wifi: this.fb.control(null),

    });

    this.form.get('hostEmail').valueChanges.subscribe(
      term => {
        if (term != '') {
          this.emsService.searchHosts(term, this.currentZone).subscribe(
            data => {
              this.hosts = data['response'].content;
              
            })
        }
      })

    this.form.get('location').valueChanges.subscribe(
      term => {
        if (term != '') {
          this.tmsService.getBuildingListByZoneAndTenant(term, this.currentZone).subscribe(
            data => {
              this.locations = data['response'];
              
            })
        }
      })


    this.form.statusChanges.subscribe(
      (status) => {
        this.isValidParticipantForm = (status === 'VALID') ? true : false;
      }
    );
  }

  get f() {
    return this.addVisitorInfo.controls;
  }

  
  checkOutUser(){
    let dataClose ={};
    this.vmsService.getCheckOut(this.data,this.currentZone).subscribe(res => { 
      dataClose['type'] = 'checkout';
      dataClose['result'] = 'success'; 
      this.dialogRef.close(dataClose);
    }, err => {
      dataClose['type'] = 'checkout';
      dataClose['result'] = 'error'; 
      this.dialogRef.close(dataClose);
      
    });
  }


  checkOutMyVistiotor(){
    let dataClose ={}; 
    this.vmsService.getCheckOut(this.data,this.currentZone).subscribe(res => {
      dataClose['type'] = 'checkout';
      dataClose['result'] = 'success'; 
      this.dialogRef.close(dataClose);
    }, err => {
      dataClose['type'] = 'checkout';
      dataClose['result'] = 'error'; 
      this.dialogRef.close(dataClose);
    });
  }

  
  addOptions(){
    this.model.optionsq.push({qoption:""})
  }

  removeAdditionalInfo=(qoption)=>{
    this.model.optionsq.splice(qoption, 1);
  }

  saveAdditionalDetails(){

  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  onSubmitAdditionalDetails(){
    let metInfo = {};
    this.model.optionsq.forEach(element => {
      if(element['typenumber'].trim() != ''){
        metInfo[element.qoption] = JSON.parse(element['typenumber']);
      }
    });

    if(this.isEmpty(metInfo)){
      this.toastrService.error("Please enter atleast one identity");
      return;
    }
    /*{ 
      idType: this.fastrack.heading,
      IdName : this.fastrack.headingDescription,
    }*/
    let data;
    if(this.isIdentityEditable == true){
      data = {
        "id": this.isIdentityEditableId,
        "moduleEntityId" :  this.intivationId,
        "moduleActionKey" : "ADDITIONAL_VMS_FIELD",
        "metadata" : metInfo
      }
      this.vmsService.updateMetaDataCustomCheckList(this.currentZone,data).subscribe(res => {
        this.toastrService.success("Updated successfully");
      });
    }else{
      data = {
        "moduleEntityId" :  this.intivationId,
        "moduleActionKey" : "ADDITIONAL_VMS_FIELD",
        "metadata" : metInfo
      }
      this.vmsService.saveMetaDataCustomCheckList(this.currentZone,data).subscribe(res => {
        this.toastrService.success("Saved successfully");
      });
    }
    
    
  }


  isIdentityEditable:boolean = false;
  isIdentityEditableId:any;
  getAllActiveIdentityFields(){
    this.model.optionsq = [];
     forkJoin(
      this.vmsService.getAllActiveIdentity(this.currentZone).map((res:Response) => res)
      .catch((res:Response) =>  Observable.of(res)),
      this.vmsService.getAllIdentityDetailsByInvitationId(this.currentZone,this.intivationId).map((res:Response) => res)
      .catch((res:Response) =>  Observable.of(res)),
    ).map((data: any[]) => {
      let response = data[0]['response']['list'];
      let response1;
      if(data[1].hasOwnProperty('response') == true){
        response1 = data[1]['response']['list'];
      }else{
        response1 = [];
      }
     
      this.isIdentityEditable = false;
      if(response.length>0){
        this.model.optionsq = [];
        if(response1.length>0){
          this.isIdentityEditable = true;
          this.isIdentityEditableId = response1[0]['id'];
          for (let [key, value] of Object.entries(response[0]['metadata']['values'])) {
            this.model.optionsq.push({
              qoption: [value],
              typenumber: response1[0]['metadata'][response[0]['metadata']['values'][key]]
            })
          }
        } else {
          for (let [key, value] of Object.entries(response[0]['metadata']['values'])) {
            this.model.optionsq.push({
              qoption: [value],
              typenumber: ''
            })
          }
        }
      }else{
        this.isIdentityEditable = false;
      }


    },err=>{
      this.toastrService.error("Something went wrong!!");
      setTimeout(() => {
        this.closePopup();
      },2000);
    }).subscribe();


/*

    this.vmsService.getAllIdentityDetailsByInvitationId(this.currentZone,this.intivationId).subscribe((res) => {
      let response = res['response']['list'];
      this.isIdentityEditable = false;
      if(response.length>0){
        this.isIdentityEditable = true;
        this.model.optionsq = [];
        this.isIdentityEditableId = response[0]['id'];
        for (let [key, value] of Object.entries(response[0]['metadata'])) {
          this.model.optionsq.push({
            qoption: [key],
            typenumber: [value]
          })
        }
      }else{
        this.isIdentityEditable = false;
      }
    });*/
  }

  getAllActiveIdentityAnswered(){
    this.model.optionsq = [];
    this.vmsService.getAllIdentityDetailsByInvitationId(this.currentZone,this.intivationId).subscribe((res) => {
      let response = res['response']['list'];
      this.isIdentityEditable = false;
      if(response.length>0){
        this.isIdentityEditable = true;
        this.model.optionsq = [];
        this.isIdentityEditableId = response[0]['id'];
        for (let [key, value] of Object.entries(response[0]['metadata'])) {
          this.model.optionsq.push({
            qoption: [key],
            typenumber: [value]
          })
        }
      }else{
        this.isIdentityEditable = false;
      }
    });
  }


  noQuestionsAnswered:boolean = true;
  questionAnswerResponse:any;
  getQuestionAndAnswers(){
//     this.noQuestionsAnswered = true;
// /*
//     ,
//       this.vmsService.getAllActiveCustomCheckList(this.currentZone).map((res:Response) => res)
//       .catch((res:Response) =>  Observable.of(res)),*/
//      forkJoin(
//       this.vmsService.getAllCustomCheckList(this.currentZone,this.intivationId).map((res:Response) => res)
//       .catch((res:Response) =>  Observable.of(res))
//     )
//     .map((data: any[]) => {
//         let userQuesAnsw = [];
//         if(typeof(data[0]['response']) != 'undefined'){
  
//           this.questionAnswerResponse = data[0]['response']['list'][0];
//           this.declaration = !!this.questionAnswerResponse['metadata']['autoApprovalResponse']?this.questionAnswerResponse['metadata']['autoApprovalResponse']['result']:'';
//           this.questionEntered = !!this.questionAnswerResponse['metadata']['question']['questionResponse']?false:true;
//           userQuesAnsw = data[0]['response']['list'][0]['metadata']['question'].filter(function(item) { 
//             return item.moduleActionKey === 'CUSTOM_CHECK_LIST' && item.metadata.fieldType != 'HEADING';  
//           });
//         }else{
//           userQuesAnsw = [];  
//         }
//         // userQuesAnsw["results"] = data[0]['response']['list'][0]['metadata']['']
//         this.questionAndAnswers = userQuesAnsw;
//         /*
//         if(typeof(data[1]['response']) != 'undefined'){
//           let response = data[1]['response']['list'];
//           var filtered = response.filter(function(item) { 
//             return item.moduleActionKey == 'CUSTOM_CHECK_LIST' && item.metadata.fieldType != 'HEADING';  
//           });
//           this.questionAndAnswers = filtered;

//           this.questionAndAnswers.forEach((value, key) => {
//             this.questionAndAnswers[key]['optionsAvailable'] = Object.values(this.questionAndAnswers[key]['metadata']['values'])
//             this.questionAndAnswers[key]['selectedAnswer'] = '';
//             if(userQuesAnsw != ''){
//               this.noQuestionsAnswered = false;
//               this.questionAndAnswers[key]['selectedAnswer'] =  JSON.parse(userQuesAnsw[value['id']]);
//             }
//           });
          
//         }else{

//         }*/
        
  
       
//       },err=>{
        
//         this.toastrService.error("Something went wrong!!");
//         setTimeout(() => {
//           this.closePopup();
//         },2000);
//       }
//     ).
//     subscribe();
  }

  /*
  getAllCustomCheckList(){
    this.vmsService.getAllCustomCheckList(this.currentZone,this.intivationId).subscribe(res=>{
    
    },err=>{

    },()=>{

    });
  }

  getAllActiveCustomCheckList(){
    this.vmsService.getAllActiveCustomCheckList(this.currentZone).subscribe(res=>{
  
    },err=>{

    },()=>{

    });
  }*/

  getSelectedAnswers(ele){
    let answered = [];
    if(typeof(this.questionAnswerResponse['metadata']['questionResponse'][ele['id']]) == 'undefined'){
      answered.push('Not Answered');
    }else{
      this.questionAnswerResponse['metadata']['questionResponse'][ele['id']].forEach(element => {
        answered.push(ele['metadata']['values'][element]);
      });
    }
    return answered;
  }

  checkFlagWithAnswer(ele) {
     let showFlag = 0;
    //let approvalValues = Object.keys(ele['metadata']['question']['approvalConditionValues']); 
    if(typeof(this.questionAnswerResponse['metadata']['questionResponse'][ele['id']]) == 'undefined'){

    }else{
      let answeredOption = this.questionAnswerResponse['metadata']['questionResponse'][ele['id']];
      answeredOption.forEach(element => {
        if(Object.keys(ele['metadata']['approvalConditionValues']).includes(element) == false){
          showFlag = 1;
        }
      })
    } 
    return showFlag;
  }
  
  openSnackBar(message: string, action: string) {
    this.matSnackBar.open(message, action, {
      duration: 5000,
    });
  }

  createLead() {

  }

  addComment() {

  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  onNoClick(): void {
    let currentContext = this;
    currentContext.commonService.changeZone(currentContext.currentZone);
    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();

    this.dialogRef.close();
  }

  cancel() {
    let currentContext = this;
    currentContext.onNoClick();
  }

  closePopup() {
    this.dialogRef.close();
  }

  addParticipant() {

    //start

    const control = new FormGroup({

      'name': new FormControl(null)
    });
    (<FormArray>this.form.get('visitors')).push(control);
    //end

  }

  createInvite() {

  }

  // adding multiple vistors fields at VMS create invite  and add guests screen
  onAddVisitors() {
    const control = new FormGroup({
      'visitorTypeId': this.fb.control(null, Validators.required),
      'name': new FormControl(null),
      'email': new FormControl(null),
      'mobile': new FormControl(null),
      'company': new FormControl(null)
    });
    (<FormArray>this.form.get('visitors')).push(control);
  }
  //remove visitor
  onRemoveVisitors(index: number): void {
    const control = <FormArray>this.form.controls['visitors'];
    control.removeAt(index);
  }

  hostImgageUrl(url) {
    return url = (url != null) ? url : "../../assets/thumbnails/user.png";
  }

  convertDateToMilliseconds(dateStr: any) {
    let milliseconds = Date.parse(dateStr);
    return milliseconds;
  }

  onInviteSubmit(f) {
    //init
    let createInvite = f.value;
    var currentContext = this;

    var zoneId = (createInvite.location !== null) ? createInvite.location.split("|", 2)[0] : 0;
    var hostId = (createInvite.hostEmail !== null) ? createInvite.hostEmail.split("|", 2)[0] : 0;

  

    var request: any = {};

    //init check flags
    let meeting = false;
    let visitors = true;
    //check for meeting validations
    if (createInvite.scheduleStart === null || createInvite.scheduleStart === "") {
      this.openSnackBar('Meeting start date is necessary field!', '');
    } else if (createInvite.scheduleEnd === null || createInvite.scheduleEnd === "") {
      this.openSnackBar('Meeting end date is necessary field!', '');
    } else if (createInvite.purpose === null || createInvite.purpose === "") {
      this.openSnackBar('Meeting purpose is necessary field!', '');
    } else if (zoneId == 0 || (zoneId != " " && !Number(zoneId.trim())) || zoneId == " ") {
      this.openSnackBar('Please select the location details from suggested list!', '');
    } else if (hostId == 0 || (hostId != " " && !Number(hostId.trim())) || hostId == " ") {
      this.openSnackBar('Please select the host details from suggested list!', '');
    } else {
      meeting = true;
    }
    //init
    let vFiled = "";
    //validation for multiple inivites
    createInvite.visitors.some(function (visitor, index) {
      //check first meeting details
      visitor.visitorTypeId = 1;
      if (meeting) {
        //check
        if (visitor.name === null || visitor.name === "") {
          vFiled = "name";
          visitors = false;
          return true;
        } else if (visitor.email === null || visitor.email === "") {
          vFiled = "email";
          visitors = false;
          return true;
        } else if (visitor.mobile === null || visitor.mobile === "") {
          vFiled = "mobile";
          visitors = false;
          return true;
        } else if (visitor.visitorTypeId === null || visitor.visitorTypeId === "") {
          vFiled = "visitorTypeId";
          visitors = false;
          return true;
        } else {
          visitors = true;
        }
      } else {
        return true;
      }
    });

    //check if any invite field is missing and show error message
    if (!visitors) {
      this.openSnackBar('The ' + vFiled + ' of the visitor is necessary field', '');
    }
    //check for validations, if pass validations then service call
    if (visitors && meeting) {
      //set request object
      request.scheduleStart = this.convertDateToMilliseconds(createInvite.scheduleStart);
      request.scheduleEnd = this.convertDateToMilliseconds(createInvite.scheduleEnd);
      request.purpose = createInvite.purpose;
      //request.hostId = localStorage.getItem("ssadmin_userId");
      //request.zoneId = this.currentZone;

      request.hostId = hostId;
      request.zoneId = zoneId;

      request.isWebexEnabled = (createInvite.webEx === true) ? true : false;
      request.isWifiEnabled = (createInvite.wifi === true) ? true : false;

      //init visitor element
      request.visitorList = [];

      //check for bulupload
      if (this.csvRecords != null && this.csvRecords.length > 0) {
        request.visitorList = this.csvRecords;
      } else {
        request.visitorList = createInvite.visitors;
      }

      //find visitor email to send WebEx link
      // var emails = request.visitorList.map(function(val) {
      //   return val.email;
      // }).join(',');
  
      //call service
      this.vmsService.createInvitation(request, this.currentZone).subscribe((res) => {

        if (res["response"]) {

          var respObj = res["response"];

          // if(createInvite.webEx===true){
          //     this.visitorEmails = emails;
          //     let element : HTMLElement = document.getElementById('sendWebExLink') as HTMLElement;
          //     element.click();
          //   }else {
          //     currentContext.openSnackBar('The invitation has been sent.', '');
          //     currentContext.dialogRef.close();
          //     setTimeout(() => { window.location.reload() }, 1000);
          //   }

          currentContext.openSnackBar('The invitation has been sent.', '');
          currentContext.dialogRef.close();
          setTimeout(() => { window.location.reload() }, 1000);
        }
      }, (err) => {
        this.openSnackBar('There was an error while sending the invitation! - ' + err.error.message, '');

      });
    }
  }

  getUrl() {

    return "url('https://s3-ap-southeast-1.amazonaws.com/ss.vms/image/1563370267369-debada0063704691ac7d5872ef460a96-15633702329751471094040-compressed.jpg')";
  }


  //upload file start
  //csv import vistors feature added by Narendra
  @ViewChild('fileImportInput',{static:false}) fileImportInput: any;


  vistorsFileChangeListener($event: any): void {

    var text = [];
    var files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      var input = $event.target;
      var reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = (data) => {
        let csvData: any = reader.result;
        let csvRecordsArray = csvData.split(/\r\n|\n/);

        let headersRow = this.getCsvFileHeaderArray(csvRecordsArray);

        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      }

      reader.onerror = function () {
        alert('Unable to read ' + input.files[0]);
      };

    } else {
      this.toastrService.error('Please import valid .csv file.');
      //alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getCsvFileHeaderArray(csvRecordsArr: any) {

    let headers = csvRecordsArr[0].split(',');
    let headerArray = [];

    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {

    var dataArr = []

    for (let i = 1; i < csvRecordsArray.length; i++) {

      let data = csvRecordsArray[i].split(',');
      // FOR EACH ROW IN CSV FILE IF THE NUMBER OF COLUMNS
      // ARE SAME AS NUMBER OF HEADER COLUMNS THEN PARSE THE DATA
      if (data.length == headerLength) {
        //check
        var csvRecord: VistorsCsvRecord = new VistorsCsvRecord();
        //set record
        csvRecord.name = data[0].trim();
        csvRecord.email = data[1].trim();
        csvRecord.mobile = data[2].trim();
        csvRecord.company = data[3].trim();
        csvRecord.visitorTypeId = data[4].trim();
        dataArr.push(csvRecord);
      }
    }

    return dataArr;
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = "";
    this.csvRecords = [];
  }
  //uplaod file end

  addVisitor() {
    
    var visitor = {}
    visitor['name'] = this.visitorInfo_name;
    visitor['email'] = this.visitorInfo_email;
    visitor['mobile'] = this.visitorInfo_mobile;
    visitor['company'] = this.visitorInfo_company;
    visitor['visitorTypeId'] = 1;

    this.visitorList.push(visitor);
    this.dataSource = this.visitorList;
    this.dataSource = [...this.dataSource];
    this.visitorInfo = {'email':'','mobile':"",'company':'' , 'name':''};
  }

  keyPress(event: any) {
    const pattern = /^(?![\s-])[\w\s-]+$/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.which === 32 &&  event.target.selectionStart === 0) {
      event.preventDefault();
    }  
  }

  checkVisitorValidations() {
    if(typeof this.vmsConfig){
    if(!!this.vmsConfig['preInvite'] && !!this.vmsConfig['preInvite']['visitorConfig'] &&
     !!this.vmsConfig['preInvite']['visitorConfig']['commonVisitorConfig']['fields']) {

      this.storeVisitor = this.vmsConfig['preInvite']['visitorConfig']['commonVisitorConfig']['fields'];

     }
     if(!!this.storeVisitor){
      this.isVisitorName = this.storeVisitor['visitorName'].enabled;
      this.isVisitorEmail = this.storeVisitor['visitorEmail'].enabled;
      this.isVisitorMobile = this.storeVisitor['visitorPhone'].enabled;
      this.isVisitorCompany = this.storeVisitor['visitorCompany'].enabled;
      //set validations
      this.addVisitorInfo.get('visitorName').setValidators(this.setRequired('visitorName'));
      this.addVisitorInfo.get('visitorEmail').setValidators(this.setRequired('visitorEmail'));
      this.addVisitorInfo.get('visitorMobile').setValidators(this.setRequired('visitorPhone'));
      this.addVisitorInfo.get('visitorCompany').setValidators(this.setRequired('visitorCompany'));

     }
    }

    
  }

  setRequired(name: string) {
    if (!this.storeVisitor) {
      return [];
    } else {
      if (this.storeVisitor[name].required)
        return [Validators.required];
      else
        return [];
    }
  }

  onSubmitaddVisitors(){
    this.submitted = true;
    // stop here if form is invalid
    if (this.addVisitorInfo.invalid) {
      return;
    }
    this.addVisitor();
  }

  addCSVVisitor(visitor) {

    this.visitorList.push(visitor);
    this.dataSource = this.visitorList;
    this.dataSource = [...this.dataSource];
  }

  // isAddVisitorActive() {
  //   var result :any ;

  //   if (!this.visitorInfo) {
  //     result = false;

  //   } else {

  //     result = this.visitorInfo['name'] && this.visitorInfo['email'] && this.visitorInfo['mobile'] && this.visitorInfo['company'];

  //     if (!result) {
  //       result = false;
  //     }
  //   }

  //   return result;

  // }

  isSendInvitation() {

    var result = false;
    if (!this.hostInfo) {
      result = false;

    } else {

      result = this.hostInfo['host'] && this.hostInfo['location']
        && this.hostInfo['purpose']
        && this.hostInfo['scheduleStart'] && this.hostInfo['scheduleEnd']
        && this.visitorList
        && this.visitorList.length > 0;
      if (!result) {
        result = false;
      }
    }

    return result;

  }

  removeVisitor(index) {

    if (index > -1) {
      this.visitorList.splice(index, 1);
      this.dataSource = this.visitorList;
      this.dataSource = [...this.dataSource];

    }
  }



  uploadVisitorsCSV(event) {


    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];


      const fileReader = new FileReader();

      fileReader.readAsText(file);

      fileReader.onload = (e) => {
        let csv = fileReader.result;
        let csvString: string = csv.toString();

        let allTextLines = csvString.split(/\r|\n|\r/);
        for (let i = 1; i < allTextLines.length; i++) {
          let data = allTextLines[i].split(',');
          if (data && data.length && data[0] != '') {


            let visitor = this.createVisitor(data);
            this.addCSVVisitor(visitor);


          }
        }


      }
    }
  }

  createVisitor(data) {
    let visitor: {} = {};
    visitor['name'] = data[0];
    visitor['email'] = data[1];
    visitor['mobile'] = data[2];
    visitor['company'] = data[3];
    visitor['visitorTypeId'] = data[4];

    return visitor;

  }

  sendInvitation() {
    this.hostInfo['scheduleStart'] = this.convertDateToMilliseconds(this.hostInfo['scheduleStart']);
    this.hostInfo['scheduleEnd'] = this.convertDateToMilliseconds(this.hostInfo['scheduleEnd']);
    this.hostInfo['zoneId'] = (this.hostInfo['location'] !== null) ? this.hostInfo['location'].split("|", 2)[0] : 0;
    this.hostInfo['hostId'] = (this.hostInfo['host'] !== null) ? this.hostInfo['host'].split("|", 2)[0] : 0;
    this.hostInfo['isWebexEnabled'] = false;
    this.hostInfo['isWifiEnabled'] = false;
    this.hostInfo['visitorList'] = this.visitorList;

    this.vmsService.createInvitation(this.hostInfo, this.currentZone).subscribe((res) => {

      if (res["response"]) {

        var respObj = res["response"];



        this.openSnackBar('The invitation has been sent.', '');
        var data = {};
        data['type'] = 'createInviteVisitorNew';
        data['result'] = 'success';
        this.dialogRef.close(data);

      }
    }, (err) => {
      this.openSnackBar('There was an error while sending the invitation! - ' + err.error.message, '');

    });

  }

  onLocationChange(searchValue: string): void {
    if (searchValue) {
      this.tmsService.getBuildingListByZoneAndTenant(searchValue, this.currentZone).subscribe(
        data => {
          this.locations = data['response'];

        })
    }
  }

  onHostChange(searchValue: string): void {
    if (searchValue) {
      this.emsService.searchHosts(searchValue, this.currentZone).subscribe(
        data => {
          this.hosts = data['response'].content;

        })
    }
  }

  cancelInvitation() {
    this.dialogRef.close();
  }



  onSelectionChanged() {
    var tenantId = (this.hostInfo['host'] !== null) ? this.hostInfo['host'].split("|", 4)[3] : 0;
    if(!tenantId){
      tenantId = this.tenantId;
    }

    this.tmsService.getBuildingListByZoneAndTenant(tenantId, this.currentZone).subscribe(
      data => {
        this.locations = data['response'];
        if(this.locations.length==0){
          this.commonService.openSnackBar("No zones asscoiated with the host, Please select another host","");
        }

      }, err => {
        this.commonService.openSnackBar("No zones asscoiated with the host, Please select another host","");
      })

  }

  onClickLocationBox() {

    this.onSelectionChanged();

  }

  showQRCode() {
    this.loading = true;
    this.vmsService.getQRcode(this.currentZone,this.data['data']['zoneId']).subscribe(res => {
      let resData = JSON.parse(res['response']['content']);
     
      //this.encryptedQRCode = "https://zerotouch.smartenspaces.com/scanner?encryptedZone="+resData.data;
      let urlFormHost = environment.BASE_URL;
      if(urlFormHost.includes("jumpreeqa")  || urlFormHost.includes("digitalpoc") || urlFormHost.includes("digitalvapt") || urlFormHost.includes("digitalstaging") || urlFormHost.includes("digitalqa"))
        {
          this.encryptedQRCode = "https://preprodzerotouch.smartenspaces.com/zerotouch?encryptedZone=" + resData.data;       
        }else{
          this.encryptedQRCode = "https://zerotouch.smartenspaces.com/zerotouch?encryptedZone=" + resData.data;
        }
    },err=>{

    },()=>{
      this.loading = false;
    });
  }

  printPage() {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>QRCode</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  getVisitManagerList(event){
  
    if(event.index==1){ 
      // this.vmsService.getAllQusetonsForVistors(this.currentZone,this.intivationId).subscribe((res:any) => {
       
 
      //  this.allList = (res as any).response.list;
 
      //  let questions= this.allList[0].metadata.question;
      //  let answers =  this.allList[0].metadata.questionResponse;
      
      //  questions.forEach(element => {
      //     for (var x in answers){
      
      //         if(x==element.id){
                
      //           element['selectedValue']= answers[x];
      //         }

      //     }
      // });
 
      // questions.forEach(element => {
        
      //    if(element.fieldType=="RADIO" ){
      //     for(var x in element.metadata.values){
               
      //       if(!!element.selectedValue && element.selectedValue.includes(x)){

 
      //        element['answerSelected']= element.metadata.values[x]
      //       }
           
      //     }
      //    }
      //    else  if(element.fieldType=="CHECKBOX" ){
      //     for(var x in element.metadata.values){
               
      //       if(!!element.selectedValue && element.selectedValue.includes(element.metadata.values[x])){

 
      //        element['answerSelected']= element.metadata.values[x]
      //       }
           
      //     }
      //    }
      //    else {
      //      if(!!element.selectedValue){
      //       element['answerSelected']= element.selectedValue[0]
      //      }
        
      //    }
            
      // });
  
      // this.DataList = questions
       

      //  })


       this.vmsService.additionalDetailslistanswers(this.currentZone,this.intivationId ).subscribe((res:any)=>{
        
        let allList = res.response;   
        this.questionListFinal = [];
        if(allList[0].assessmentStatus != "PENDING" || allList[0].metadata != null){
        let questions= allList[0].metadata.question;
        let answers =  allList[0].metadata.questionResponseNew;

        this.attemptedNumber = allList[0].attemptNumber
        this.moduleEntityId = allList[0].moduleEntityId
        this.assessmentStatus = allList[0].assessmentStatus
        this.createdAt = allList[0].createdAt
           questions.forEach(item => { 
            //  item['selectedValue']= answers[item.id].response.attributes.respondAnswer;
                   Object.entries(answers).forEach(element => {

                    if(item.id == element[0])
                               {   
                                    this.onCheckAnswer(element[1],item)
                                      
                               } 
                     
                   });
           });
           
          }
      },err=>{
     
      })

    }
    if(event.index == 2){ 
      this.vmsService.Healthlistanswers(this.currentZone,this.intivationId ).subscribe((res:any)=>{
       let allList = res.response;  
       this.questionListFinalHealth = [];
       this.attemptedNumber = allList[0].attemptNumber
       this.moduleEntityId = allList[0].moduleEntityId
       this.assessmentStatus = allList[0].assessmentStatus
       this.createdAt = allList[0].createdAt
       if(allList[0].assessmentStatus != "PENDING" || allList[0].metadata != null){
       let questions= allList[0].metadata.question;
       let answers =  allList[0].metadata.questionResponseNew;

    
          questions.forEach(item => { 
           //  item['selectedValue']= answers[item.id].response.attributes.respondAnswer;
                  Object.entries(answers).forEach(element => {

                   if(item.id == element[0])
                              {   
                                   this.onCheckAnswerForHealth(element[1],item)
                                     
                              } 
                    
                  });
          });
         
        }
     },err=>{
     
     })
    

  } 
}

onCheckAnswer(answer,question){

  var KeyName
  var questionList = [];
  let optionVal = null;
  var answerList = [];
  answerList.push(question);
  answerList.forEach((element) => {
  if(question.fieldType =="RADIO"){   
    KeyName = "VALIDATE_RADIO_TYPE";
    if (!!element.metadata.ruleBasedCondition[KeyName]) {
      optionVal =
        element.metadata.ruleBasedCondition[KeyName].attributes.rules
          .options;
      let arrayOption = [];
      for (const [key, value] of Object.entries(optionVal)) {
        let keyVal = { key: key, val: value ,checked:false};
        arrayOption.push(keyVal);
      }  
      for (const property in answer.response.attributes) {
       
        arrayOption.forEach(ans => {
               for(var ques in ans){
               
                 if(ans[ques] == answer.response.attributes[property]){
                  ans.checked= true
                 }
               }
        });
      }
      let queObje = {
        id: element.id,
        fieldType: element.fieldType,
        question: element.metadata.question,
        option: arrayOption,
        fieldKey: KeyName,
        title: element.title,
        isMandatory: element.isMandatory,
      };
      this.questionListFinal.push(queObje);
    }
  }else if (element.fieldType == "CHECKBOX") {
    KeyName = "VALIDATE_CHECKBOX_TYPE";
    if (!!element.metadata.ruleBasedCondition[KeyName]) {
      optionVal =
        element.metadata.ruleBasedCondition[KeyName].attributes.rules
          .options;
      let arrayOption = [];
      for (const [key, value] of Object.entries(optionVal)) {
        let keyVal = { key: key, val: value,checked:false};
        arrayOption.push(keyVal);
      }
       for (const property in answer.response.attributes) {
        arrayOption.forEach(ans => {
               for(var ques in ans){
                answer.response.attributes[property].forEach(check => {
                        //  answer.response.attributes[property])
                  if(check == ans[ques]) {
                    ans.checked= true
                   }
                  });
                 
               }
        });
      }
      let queObje = {
        id: element.id,
        fieldType: element.fieldType,
        question: element.metadata.question,
        option: arrayOption,
        fieldKey: KeyName,
        title: element.title,
        isMandatory: element.isMandatory,
      };
      this.questionListFinal.push(queObje);
    }
  }
  else  if (element.fieldType == "TEXT") {
    KeyName = "VALIDATE_TEXT_TYPE";
    let queObje = {
      id: element.id,
      fieldType: element.fieldType,
      question: element.metadata.question,
      option: optionVal,
      fieldKey: KeyName,
      title: element.title,
      isMandatory: element.isMandatory,
      answer:answer.response.attributes.respondAnswer
    };
    // questionList.push(queObje);
    this.questionListFinal.push(queObje);
  }

  else  if (element.fieldType == "DATE") {
    KeyName = "VALIDATE_DATE_TYPE";
    let dataTypeVal;
    if(element.metadata.ruleBasedCondition.VALIDATE_DATE_TYPE.attributes.rules.type == 'DAY'){
      dataTypeVal = true;
    }else{
      dataTypeVal = false;
    }
    let queObje = {
      id: element.id,
      fieldType: element.fieldType,
      question: element.metadata.question,
      option: optionVal,
      fieldKey: KeyName,
      title: element.title,
      isMandatory: element.isMandatory, 
      timeMillis: answer.response.attributes.timeInMillis,
      dateType: dataTypeVal
      // timeMillis: new Date(answer.response.attributes.timeInMillis)
    };
    // questionList.push(queObje);
    this.questionListFinal.push(queObje);
  }
  else  if (element.fieldType == "DROPDOWN") {
    KeyName = "VALIDATE_DROPDOWN_TYPE";

    let queObje = {
      id: element.id,
      fieldType: element.fieldType,
      question: element.metadata.question,
      option: optionVal,
      fieldKey: KeyName,
      title: element.title,
      isMandatory: element.isMandatory,
      drSelected:answer.response.attributes.selected
       
    };
    // questionList.push(queObje);
    this.questionListFinal.push(queObje);
  }

})

}



onCheckAnswerForHealth(answer,question){
  
  var KeyName
  var questionList = [];
  let optionVal = null;
  var answerList = [];
  answerList.push(question);
  answerList.forEach((element) => {
  if(question.fieldType =="RADIO"){   
    KeyName = "VALIDATE_RADIO_TYPE";
    if (!!element.metadata.ruleBasedCondition[KeyName]) {
      optionVal =
        element.metadata.ruleBasedCondition[KeyName].attributes.rules
          .options;
      let arrayOption = [];
      for (const [key, value] of Object.entries(optionVal)) {
        let keyVal = { key: key, val: value ,checked:false};
        arrayOption.push(keyVal);
      }  
      for (const property in answer.response.attributes) {
        arrayOption.forEach(ans => {
               for(var ques in ans){
                 if(ans[ques] == answer.response.attributes[property]){
                  ans.checked= true
                 }
               }
        });
      }
      let queObje = {
        id: element.id,
        fieldType: element.fieldType,
        question: element.metadata.question,
        option: arrayOption,
        fieldKey: KeyName,
        title: element.title,
        isMandatory: element.isMandatory,
      };
      this.questionListFinalHealth.push(queObje);
    }
  }else if (element.fieldType == "CHECKBOX") {
    KeyName = "VALIDATE_CHECKBOX_TYPE";
    if (!!element.metadata.ruleBasedCondition[KeyName]) {
      optionVal =
        element.metadata.ruleBasedCondition[KeyName].attributes.rules
          .options;
      let arrayOption = [];
      for (const [key, value] of Object.entries(optionVal)) {
        let keyVal = { key: key, val: value,checked:false};
        arrayOption.push(keyVal);
      }
       for (const property in answer.response.attributes) {
    
        arrayOption.forEach(ans => {
               for(var ques in ans){
                answer.response.attributes[property].forEach(check => {
                  if(check == ans[ques]) {
                    ans.checked= true
                   }
                  });
                 
               }
        });
      }
      let queObje = {
        id: element.id,
        fieldType: element.fieldType,
        question: element.metadata.question,
        option: arrayOption,
        fieldKey: KeyName,
        title: element.title,
        isMandatory: element.isMandatory,
      };
      this.questionListFinalHealth.push(queObje);
    }
  }
  else  if (element.fieldType == "TEXT") {
    KeyName = "VALIDATE_TEXT_TYPE";

    let queObje = {
      id: element.id,
      fieldType: element.fieldType,
      question: element.metadata.question,
      option: optionVal,
      fieldKey: KeyName,
      title: element.title,
      isMandatory: element.isMandatory,
      answer:answer.response.attributes.respondAnswer
    };
    // questionList.push(queObje);
    this.questionListFinalHealth.push(queObje);
  }

  else  if (element.fieldType == "DATE") {
    KeyName = "VALIDATE_DATE_TYPE";

    let queObje = {
      id: element.id,
      fieldType: element.fieldType,
      question: element.metadata.question,
      option: optionVal,
      fieldKey: KeyName,
      title: element.title,
      isMandatory: element.isMandatory, 
      timeMillis: answer.response.attributes.timeInMillis
      // timeMillis: new Date(answer.response.attributes.timeInMillis)

    };
    // questionList.push(queObje);
    this.questionListFinalHealth.push(queObje);
  }
  else  if (element.fieldType == "DROPDOWN") {
    KeyName = "VALIDATE_DROPDOWN_TYPE";

    let queObje = {
      id: element.id,
      fieldType: element.fieldType,
      question: element.metadata.question,
      option: optionVal,
      fieldKey: KeyName,
      title: element.title,
      isMandatory: element.isMandatory,
      drSelected:answer.response.attributes.selected
       
    };
    // questionList.push(queObje);
    this.questionListFinalHealth.push(queObje);
  }

})

}

// allList.forEach(element => {
//   let aItem = element.metadata.questionResponse;

//   this.DataList.forEach(item => {
//   Object.entries(aItem).map(element => {
 
//     element.forEach(val => {
//         if (item.id == element[0]) {

//           //  this.onCheckClicked(val, item); 
       
//         }
//         });

//               });
//        });
//    });

DisplayAllQuestions(list){
  let arraList = list;
  let questionList = [];
  let optionVal = null;
  arraList.forEach((element) => {
    let KeyName = "";
    if (element.fieldType == "TEXT") {
      KeyName = "VALIDATE_TEXT_TYPE";

      let queObje = {
        id: element.id,
        fieldType: element.fieldType,
        question: element.metadata.question,
        option: optionVal,
        fieldKey: KeyName,
        title: element.title,
        isMandatory: element.isMandatory,
      };
      questionList.push(queObje);
    } else if (element.fieldType == "RADIO") {
      KeyName = "VALIDATE_RADIO_TYPE";
      if (!!element.metadata.ruleBasedCondition[KeyName]) {
        optionVal =
          element.metadata.ruleBasedCondition[KeyName].attributes.rules
            .options;
        let arrayOption = [];
        for (const [key, value] of Object.entries(optionVal)) {
          let keyVal = { key: key, val: value };
          arrayOption.push(keyVal);
        }
        let queObje = {
          id: element.id,
          fieldType: element.fieldType,
          question: element.metadata.question,
          option: arrayOption,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
        };
        questionList.push(queObje);
      }
    } else if (element.fieldType == "CHECKBOX") {
      KeyName = "VALIDATE_CHECKBOX_TYPE";
      if (!!element.metadata.ruleBasedCondition[KeyName]) {
        optionVal =
          element.metadata.ruleBasedCondition[KeyName].attributes.rules
            .options;
        let arrayOption = [];
        for (const [key, value] of Object.entries(optionVal)) {
          let keyVal = { key: key, val: value };
          arrayOption.push(keyVal);
        }
        let queObje = {
          id: element.id,
          fieldType: element.fieldType,
          question: element.metadata.question,
          option: arrayOption,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
        };
        questionList.push(queObje);
      }
    }
  });
  this.AllQuestionList = questionList;

}


getConfig(){
  const VisitorConfig$ = this.vmsService.getConfig(this.currentZone,9);
  this.loaderService.showLoaderUntilCompleted(VisitorConfig$)
  .pipe(
      takeUntil(this.unsubscribe$)
  )
  .subscribe(res => {

    let configDetails = (res as any).response;
    this.vmsConfig = JSON.parse(configDetails);
    this.checkVisitorValidations();
    let configDet = JSON.parse(configDetails);
    this.isExtraInformationRequired = configDet.commonConfig.isExtraInformationRequired;
    this.customFieldsEnable =  configDet.commonConfig.customFieldsEnable;

     
     try {
      this.isAdditional= configDet.commonConfig.isAdditionalDetailsRequired
     
      } catch (error) {
      this.isAdditional = false
      }

    });
  // this.vmsService.getConfig(this.currentZone,9).subscribe( res => {
  //   let configDetails = (res as any).response;
  //   this.vmsConfig = JSON.parse(configDetails);
  //   let configDet = JSON.parse(configDetails);
  //   this.isExtraInformationRequired = configDet.commonConfig.isExtraInformationRequired;
  //   this.customFieldsEnable =  configDet.commonConfig.customFieldsEnable;

     
  //    try {
  //     this.isAdditional= configDet.commonConfig.isAdditionalDetailsRequired
     
  //     } catch (error) {
  //     this.isAdditional = false
  //     }

  //   });
}


 
  

showDetailsOfQuestions(questionDetails ){
  this.alldetails.push(questionDetails.data)

  let arraList = this.alldetails;
    let questionList = [];
    let optionVal = null;
    arraList.forEach((element) => {
      let KeyName = "";
      if (element.fieldType == "TEXT") {
        KeyName = "VALIDATE_TEXT_TYPE";

        let queObje = {
          id: element.id,
          fieldType: element.fieldType,
          question: element.metadata.question,
          option: optionVal,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
          isAutoApproval:element.isAutoApproval
        };
        questionList.push(queObje);
      } else if (element.fieldType == "RADIO") {
        KeyName = "VALIDATE_RADIO_TYPE";
        if (!!element.metadata.ruleBasedCondition[KeyName]) {
          optionVal =
            element.metadata.ruleBasedCondition[KeyName].attributes.rules
              .options;
          let arrayOption = [];
          for (const [key, value] of Object.entries(optionVal)) {
            let keyVal = { key: key, val: value };
            arrayOption.push(keyVal);
          }
          let queObje = {
            id: element.id,
            fieldType: element.fieldType,
            question: element.metadata.question,
            option: arrayOption,
            fieldKey: KeyName,
            title: element.title,
            isMandatory: element.isMandatory,
            isAutoApproval:element.isAutoApproval
          };
          questionList.push(queObje);
        }
      } else if (element.fieldType == "CHECKBOX") {
        KeyName = "VALIDATE_CHECKBOX_TYPE";
        if (!!element.metadata.ruleBasedCondition[KeyName]) {
          optionVal =
            element.metadata.ruleBasedCondition[KeyName].attributes.rules
              .options;
          let arrayOption = [];
          for (const [key, value] of Object.entries(optionVal)) {
            let keyVal = { key: key, val: value };
            arrayOption.push(keyVal);
          }
          let queObje = {
            id: element.id,
            fieldType: element.fieldType,
            question: element.metadata.question,
            option: arrayOption,
            fieldKey: KeyName,
            title: element.title,
            isMandatory: element.isMandatory,
            isAutoApproval:element.isAutoApproval
          };
          questionList.push(queObje);
        }
      }
      else if(element.fieldType == "DATE"){
        KeyName = "VALIDATE_DATE_TYPE";

        let queObje = {
          id: element.id,
          fieldType: element.fieldType,
          question: element.metadata.question,
          option: optionVal,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
          isAutoApproval:element.isAutoApproval,
          selectedvalue: element.metadata.ruleBasedCondition[KeyName].attributes.rules.type
        };
        questionList.push(queObje);
      }

      else if(element.fieldType == "DROPDOWN"){
        KeyName = "VALIDATE_DROPDOWN_TYPE";
        let queObje = {
          id: element.id,
          fieldType: element.fieldType,
          question: element.metadata.question,
          option: optionVal,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
          isAutoApproval:element.isAutoApproval,
          selectedvalue: element.metadata.ruleBasedCondition[KeyName].attributes.rules.optionList
        };
        questionList.push(queObje);
      }
    });
    this.alldetailsList = questionList;
}

showDetailsOfHealthQuestions(questionDetails){

  this.alldetails.push(questionDetails.data)

  let arraList = this.alldetails;
    let questionList = [];
    let optionVal = null;
    arraList.forEach((element) => {
      let KeyName = "";
      if (element.fieldType == "TEXT") {
        KeyName = "VALIDATE_TEXT_TYPE";

        let queObje = {
          id: element.id,
          fieldType: element.fieldType,
          question: element.metadata.question,
          option: optionVal,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
          isAutoApproval:element.isAutoApproval
        };
        questionList.push(queObje);
      } else if (element.fieldType == "RADIO") {
        KeyName = "VALIDATE_RADIO_TYPE";
        if (!!element.metadata.ruleBasedCondition[KeyName]) {
          optionVal =
            element.metadata.ruleBasedCondition[KeyName].attributes.rules
              .options;
          let arrayOption = [];
          for (const [key, value] of Object.entries(optionVal)) {
            let keyVal = { key: key, val: value };
            arrayOption.push(keyVal);
          }
          let queObje = {
            id: element.id,
            fieldType: element.fieldType,
            question: element.metadata.question,
            option: arrayOption,
            fieldKey: KeyName,
            title: element.title,
            isMandatory: element.isMandatory,
            isAutoApproval:element.isAutoApproval
          };
          questionList.push(queObje);
        }
      } else if (element.fieldType == "CHECKBOX") {
        KeyName = "VALIDATE_CHECKBOX_TYPE";
        if (!!element.metadata.ruleBasedCondition[KeyName]) {
          optionVal =
            element.metadata.ruleBasedCondition[KeyName].attributes.rules
              .options;
          let arrayOption = [];
          for (const [key, value] of Object.entries(optionVal)) {
            let keyVal = { key: key, val: value };
            arrayOption.push(keyVal);
          }
          let queObje = {
            id: element.id,
            fieldType: element.fieldType,
            question: element.metadata.question,
            option: arrayOption,
            fieldKey: KeyName,
            title: element.title,
            isMandatory: element.isMandatory,
            isAutoApproval:element.isAutoApproval
          };
          questionList.push(queObje);
        }
      }
      else if(element.fieldType == "DATE"){
        KeyName = "VALIDATE_DATE_TYPE";

        let queObje = {
          id: element.id,
          fieldType: element.fieldType,
          question: element.metadata.question,
          option: optionVal,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
          isAutoApproval:element.isAutoApproval,
          selectedvalue: element.metadata.ruleBasedCondition[KeyName].attributes.rules.type
        };
        questionList.push(queObje);
      }

      else if(element.fieldType == "DROPDOWN"){
        KeyName = "VALIDATE_DROPDOWN_TYPE";
        let queObje = {
          id: element.id,
          fieldType: element.fieldType,
          question: element.metadata.question,
          option: optionVal,
          fieldKey: KeyName,
          title: element.title,
          isMandatory: element.isMandatory,
          isAutoApproval:element.isAutoApproval,
          selectedvalue: element.metadata.ruleBasedCondition[KeyName].attributes.rules.optionList
        };
        questionList.push(queObje);
      }
    });
    this.alldetailsListHealth = questionList;
}


}

export class VistorsCsvRecord {

  public name: any;
  public email: any;
  public mobile: any;
  public company: any;
  public visitorTypeId: any;
  constructor() { }


//   if (this.inputHealthType == "CHECKBOX" || this.inputHealthType == "RADIO") {
//     this.addCheck = true;
//     let k = 65;   
//     this.allHealthValues = {"optionsq":[{qoption:'',redflag:false},{qoption:'',redflag:false}]};  
//           for(var i=0;i<Object.keys(response.metadata.ruleBasedCondition[abc].attributes.rules.options).length;i++){
   
//                   let optionAvail = response.metadata.ruleBasedCondition[abc].attributes.rules.options[String.fromCharCode(k)];
//                   let ans = response.metadata.ruleBasedCondition[abc].attributes.rules.expectedAnswer
//                       if(i>1){
//                                   this.allHealthValues.optionsq.push({qoption:optionAvail})
//                              }else{
//                                      this.allHealthValues.optionsq[i]['qoption'] = optionAvail;
                                    
     
//                                    }
//                                k++;
//               }    
//               if(this.inputHealthType == "RADIO") {
//                 let ans = response.metadata.ruleBasedCondition[abc].attributes.rules.expectedAnswer
//                 this.allHealthValues.optionsq.forEach(element => {
                           
//                 });
//               }
// }
}