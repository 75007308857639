<div class="row">
  <div class="col-12 mt-3" *ngIf="!selectedOfficeDates.length">
    <p>
      {{"selectOfficeForWorkFromHome" | translate}}
      <span class="semi-bold-blue">{{ selectedDate }}</span>
    </p>
  </div>
  <div class="col-12 customScroll">
    <form
      id="workstatus_form"
      class="form-group"
      [formGroup]="locationForm"
      *ngIf="!selectedOfficeDates.length"
      (ngSubmit)="onSubmitSearch()"
    >
      <div class="form-row">
        <div class="form-group col-lg-12 col-md-4 col-sm">
          <label for="inputEmail4" class="labelColleague"
            >{{"searchLocation" | translate}}</label
          >
          <div class="input-group pl-0">
            <input
              #name
              type="text"
              id="name"
              name="name"
              [(ngModel)]="eName"
              formControlName="locationSearch"
              autocomplete="off"
              class="form-control blueText"
              placeholder="{{'searchLocation' | translate}}"
              (focus)="onFocusEvent($event)"
              [ngClass]="{ 'is-invalid': submitted && f.locationSearch.errors }"
              required
              [matAutocomplete]="auto"
            />
            <!-- <i
              class="fa fa-spinner workStatus_loading_span"
              *ngIf="isloading"
              aria-hidden="true"
            ></i> -->
            <mat-autocomplete #auto="matAutocomplete">
              <!-- <mat-option *ngFor="let dropData of autocompleteList$ | async" [value]="dropData.deskName.trim()" (click)="onselectData(dropData)">
                                    <span>{{dropData?.deskName}}</span>
                                </mat-option> -->
              <mat-option
                #searchDesk
                *ngFor="let dropData of filteredDesks | async"
                [value]="dropData.name.trim()"
                (click)="onselectData(dropData)"
              >
                <span>{{ dropData?.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="text-center m-auto">
          <img
            src="./assets/images/jumpree_digital/colleagues_search.svg"
            alt="Get Started"
            style="width: 230px"
          />
        </div>
        <div class="form-group col-lg-12 col-md-4 col-sm-4 mb-0">
          <button
            id="searchBtn"
            type="submit"
            class="btn btn-main btnSmall mt-4 customBtn px-3 float-right"
            (click)="closeSearchLocationModal()"
          >
            {{"done" | translate}}
          </button>
          <!-- <button type="button" class="btn btn-main btnSmall ml-2 mt-4 customBtn" id="reset" (click)="reset()">
                        {{ "clear" | translate }}
                    </button> -->
        </div>
      </div>
    </form>
    <ng-container *ngIf="selectedOfficeDates.length">
      <form
        id="workstatus_form"
        class="form-group"
        [formGroup]="locationForm"
        (ngSubmit)="onSubmitSearch()"
      >
        <ng-container *ngFor="let date of selectedOfficeDates; let i = index">
          <div class="form-row mt-4">
            <div class="form-group col-lg-3 col-md-3 col-sm mt-2">
              <h4 class="dateFontSize">{{ date.displayDate }}</h4>
            </div>
            <div class="input-group pl-0 col-lg-9 col-md-9 col-sm">
              <input
                #name
                type="text"
                id="name"
                name="name"
                formControlName="locationSearch{{date.key}}"
                autocomplete="off"
                class="form-control blueText"
                placeholder="{{'searchLocation' | translate}}"
                (focus)="onFocusEvent($event)"
                [ngClass]="{
                  'is-invalid': checkError(date)
                }"
                required
                [matAutocomplete]="auto"
              />
              <!-- <i
                class="fa fa-spinner workStatus_loading_span"
                
                aria-hidden="true"
              ></i> -->
              <!-- *ngIf="isloading" commented  as it was throwing error in ng build-->  
              <mat-autocomplete #auto="matAutocomplete">
                <!-- <mat-option *ngFor="let dropData of autocompleteList$ | async" [value]="dropData.deskName.trim()" (click)="onselectData(dropData)">
                                    <span>{{dropData?.deskName}}</span>
                                </mat-option> -->
                <mat-option
                  #searchDesk
                  *ngFor="let dropData of filteredDesks | async"
                  [value]="dropData.name.trim()"
                  (click)="onSelectLocation(dropData, date.key)"
                >
                  <span>{{ dropData?.name }}</span>
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </ng-container>
        <div class="form-group col-lg-12 col-md-4 col-sm-4 mb-0">
          <button
            id="saveBtn"
            type="submit"
            class="btn btn-main btnSmall mt-4 customBtn px-3 float-right"
          >
            {{"next" | translate}}
          </button>
          <!-- <button type="button" class="btn btn-main btnSmall ml-2 mt-4 customBtn" id="reset" (click)="reset()">
                  {{ "clear" | translate }}
              </button> -->
        </div>
      </form>
    </ng-container>
  </div>
</div>
