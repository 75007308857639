import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { routerTransition } from "../router.animations";
import { ForgotPasswordSuccessService } from "./forgotPasswordSuccess.service";
import { CommonService } from "../services/common-service.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "forgot-password",
    templateUrl: "./forgotPasswordSuccess.component.html",
    styleUrls: ["./forgotPasswordSuccess.component.scss"],
    animations: [routerTransition()],
    providers: [CommonService]
})
export class ForgotPasswordSuccessComponent implements OnInit {
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public commonService: CommonService,
        public translate: TranslateService
    ) {}

    ngOnInit() {}
}
