import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";
import { environment } from "../../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";

@Injectable()
export class DashboardService {
    moduleId;
    BASEURL;
    EMSURL;
    ENVURL;
    localVal;
    showWayFinding = new BehaviorSubject<boolean>(false);
    showColleaguesModalOncloseAddFav$ = new BehaviorSubject<string>("");
    showColleaguesModal = this.showColleaguesModalOncloseAddFav$.asObservable();

    private colleagueData = new BehaviorSubject<any>([]);
    getColleagueData = this.colleagueData.asObservable();

    constructor(
        public http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        public moduleidService: ModuleidService
    ) {
        this.BASEURL = baseUrl;
        this.ENVURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
       this.localVal=localStorage.getItem("language");
    }

    showColleaguesModalOncloseAddFav(showColleagues) {
        this.showColleaguesModalOncloseAddFav$.next(showColleagues);
    }

    showWayFindingDigital(wayFindingValue) {
        this.showWayFinding.next(wayFindingValue);
    }

    setonBehalfofColleagues(colleagueData) {
        this.colleagueData.next(colleagueData);
    }

    loadSpaces() {
        let url = this.ENVURL + `amenitybooking/globalCategory/getAll/type`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    loadOverView() {
        let url = this.ENVURL + `ems/user/globaladmin/dashboard/overview`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    loadSuperAdminView(zoneId) {
        let url = this.ENVURL + `ems/user/superadmin/dashboard/overview`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }

    loadActualAndScheduleCount(zoneId, startTime) {
        let url =
            this.ENVURL +
            `amenitybooking/entityCategory/scheduled-active-count?zoneId=${zoneId}&startTime=${startTime}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    loadMeetingForDay(currentZone,date) {
        let url =
            this.ENVURL +
            `amenitybooking/booking/generaluser/startTime/endTime/v3?date=${date}`;
        //let url = "https://run.mocky.io/v3/be88168b-b1b8-46e7-9175-45482c182798";
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        });
    }

    loadColleaguesForDay(zoneId,date, status?) {
        let statusParam = "";
        if (status) {
            statusParam = "&showAll=true";
        }
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                     '","locale":"' + this.localVal +
                    '"}',
            }),
        };
        let url =
            this.ENVURL +
            `amenitybooking/recentsearch/getAll/v3?date=${date}${statusParam}`;

        
        // return this.http.get<any>(url, {
        //     params: new ZoneAndModuleHttpParams(null, this.moduleId),
        // });
        return this.http.get<any>(url, httpOptions);
    }

    //   amenitybooking/booking/generaluser/quickActions?startDate=1669852800000&endDate=1672531199000
    loadCalendarView(fdate, ldate) {
        let url =
            this.ENVURL +
            `amenitybooking/booking/generaluser/quickActions?startDate=${fdate}&endDate=${ldate}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    loadBookedSeats(fdate, ldate) {
        let url =
            this.ENVURL +
            `wfh/calendar/generaluser/getAllStatusAndAvailableAndBookedSeats/v2?startTime=${fdate}&endTime=${ldate}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    loadBookedSeatsProxyId(fdate, ldate, userId) {
        let url =
            this.ENVURL +
            `wfh/calendar/generaluser/getAllStatusAndAvailableAndBookedSeats/v2?startTime=${fdate}&endTime=${ldate}&proxyId=${userId}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    createRoster(data) {
        let url =
            this.ENVURL +
            `wfh/rosters/generaluser/createRostersForSpecificDaysV2?isAutoAllocationEnabled=false&editFlow=true&startTime=1669852800000&endTime=1672531199000`;
        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    createPreference(data) {
        let url = this.ENVURL + `wfh/question-response/addAnswers`;
        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    loadPrefrences() {
        let userId = localStorage.getItem("ssadmin_userId");
        let url =
            this.ENVURL + `/wfh/question-response/getAll/v2?userId=${userId}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    changeRoster(data) {
        let url = this.ENVURL + `wfh/rosters/generaluser/changeRosterZones`;
        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    createFavouriteColleague(data) {
        let url = this.ENVURL + `amenitybooking/recentsearch/create`;
        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    saveWorkStatus(date) {
        let url =
            this.ENVURL +
            `amenitybooking/booking/generaluser/startTime/endTime/v3?date=${date}`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    saveRosterZones(data) {
        let url = this.ENVURL + `wfh/rosters/generaluser/changeRosterZones`;
        return this.http.post<any>(url, data, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    deleteFavourite(data): Observable<any> {
        let url =
            this.ENVURL +
            `amenitybooking/recentsearch/delete/favourite?id=${data}`;
        return this.http.delete<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    deskBookingCheckIn(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId + '","locale":"' + this.localVal +
                    '"}',
            }),
        };
        let url = `${this.ENVURL}amenitybooking/booking/validateAndCheckIn`;
        return this.http.post<any>(url, data, httpOptions);
    }

    deskBookingCheckOut(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header":
                    '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId + '","locale":"' + this.localVal +
                    '"}',
            }),
        };
        let url = `${this.ENVURL}amenitybooking/booking/cancel`;
        return this.http.put<any>(url, data, httpOptions);
    }
    loadSubcriptions() {
        let url = this.ENVURL + `ems/subscriptions/getQuantitiesByPlan`;
        return this.http.get<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
}
