<div>
  <app-modal-loader [loading]="loading"></app-modal-loader>
  <div *ngIf="data['type'] == 'additionalDetails'">
    <mat-card class="example-card">
      <div class="row">
        <div class="col-md-6">
          <mat-card-header>
            <div class="visitor-info-header">
              {{'addOrEdit' | translate}}
            </div>
          </mat-card-header>
        </div>
        <div class="col-md-6">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div style="margin-top:20px;">
          
            <div *ngFor="let selectData of ipcCounterList; let i = index ,let last = last;">
            <div class="form-group row mg-10 col-lg-offset-2" >
              <div class="col-lg-4">
                <label>{{displayType == "BUILDING"?('building' | translate):('floor' | translate) }}</label>
                <select
                  class="form-control"
                  name="selectData.zone_id"
                  [(ngModel)]="selectData.zone_id"
                  [disabled]="!selectData.added"
                >
                <ng-container *ngIf="!selectData.added">
                    <option
                    [value]="searchBy.zoneId"
                    *ngFor="let searchBy of buildingList"
                  > {{searchBy.name }}
                  </option>
                </ng-container>
                <ng-container *ngIf="selectData.added">
                    <option
                    [value]="searchBy.zone_id"
                    *ngFor="let searchBy of addedCounterList"
                  > {{ searchBy.zone_name }}
                  </option>
                </ng-container>
                </select>
              </div>
              <div class="col-lg-4">
                <label>{{'count' | translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="selectData.total_count"
                  class="form-control"
                  placeholder="{{'enterCount' | translate}}"
                  name="selectData.total_count"
                  onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                  maxlength="6"
                />
              </div>
              <div class="col-lg-1" *ngIf="selectData.added">
                  <i  aria-hidden="true" class="fa fa-window-close"  (click)="closeCounnt(i)" style="float:right; color: red;margin-top: 5px"></i>
              </div>
              <div class="col-md-2" *ngIf="last && addMoreEnable">
                  <button
                  mat-raised-button
                    color="primary"
                    class="add-visitor-btn btn btn-main"
                    (click)="addMoreCounnt()"
                  >
                     + {{'addMore' | translate}}
                  </button>
                </div>
            </div>
            
            </div>


            <div class="col-md-2" >
              <button
                mat-raised-button
                color="primary"
                class="add-visitor-btn btn btn-main"
                (click)="saveAdditionalDetails()"
              >
                 {{'save' | translate}}
              </button>
            </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="data['type'] == 'editIpc'">
    <mat-card class="example-card">
      <div class="row">
        <div class="col-md-6">
          <mat-card-header>
            <div class="visitor-info-header">
             {{'edit' | translate}}
            </div>
          </mat-card-header>
        </div>
        <div class="col-md-6">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div style="margin-top:20px;">
          <form name="form">
            <div class="form-group row mg-12">
              <div class="col-lg-5">
                <label>{{displayType == "BUILDING"?('building' | translate):('floor' | translate) }}</label>
                <input
                type="text"
                [(ngModel)]="showBuildingName"
                class="form-control"
                placeholder="{{'enterCount' | translate}}"
                name="showBuildingName"
                [disabled]="true"
                
              />
              </div>
              <div class="col-lg-5">
                <label>{{'company' | translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="showTenantName"
                  class="form-control"
                  placeholder="{{'enterCount' | translate}}"
                  name="showTenantName"
                  maxlength="3"
                  [disabled]="true"
                />
              </div>
            </div>
            <div class="form-group row mg-12">
              <div class="col-lg-5">
                <label>{{'employeeCount' | translate}}</label>
                <input
                type="text"
                [(ngModel)]="empolyeeCount"
                class="form-control"
                placeholder="{{'enterCount' | translate}}"
                name="empolyeeCount"
                maxlength="6"
                
              />
              </div>
              <div class="col-lg-5">
                <label>{{'limit' | translate}}(%)</label>
                <input
                  type="text"
                  [(ngModel)]="limit"
                  class="form-control"
                  placeholder="{{'enterCount' | translate}}"
                  name="limit"
                  maxlength="3"
                />
              </div>
            </div>

            <div class="col-md-2">
              <button
                mat-raised-button
                color="primary"
                class="add-visitor-btn btn btn-main"
                (click)="updateAdditionalDetails()"
              >
                {{'save' | translate}}
              </button>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
