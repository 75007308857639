import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import "@fullcalendar/angular";
import { CalendarOptions, DayCellContentArg } from "@fullcalendar/angular"; // useful for typechecking
import { FullCalendarComponent } from "@fullcalendar/angular";
import { CommonService } from "commonService";
import { DashboardService } from "../services/dashboard.services";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomdaysComponent } from "../../amenity-booking/customdays/customdays.component";
import dayGridPlugin from "@fullcalendar/daygrid";
import { LoaderService } from "loaderService";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import * as moment from "moment";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
declare var $: any;
@Component({
  selector: "app-dashboard-modal",
  templateUrl: "./dashboard-modal.component.html",
  styleUrls: ["./dashboard-modal.component.scss"],
})
export class DashboardModalComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  dateTime = new Date();
  dt = new Date();
  roster = [];
  events = [];
  currentZone;
  eventsModel: any;
  pageType: any;
  locationList = [];

  selectedDate: any;
  rosterData = {};
  selectedOfficeDates = [];

  colleagueObj = {};
  findColleagueBlueTick = false;
  timeZoneByConfig = "UTC";
  localVal;
  calendarOptions = {};
  @ViewChild(FullCalendarComponent, { static: false })
  fullCalendarComponent: FullCalendarComponent;
  constructor(
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomdaysComponent>,
    public dashboardService: DashboardService,
    public loaderService: LoaderService,
    public toastrService: ToastrService,
    private translate: TranslateService,
  ) {
    this.commonService.currentZone
    .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
    .subscribe((res) => {
      this.currentZone = res;
    });
    this.localVal = localStorage.getItem("language");
    this.pageType = this.data["type"];
    if (this.pageType == "schedule") {
      let dialogData = this.data["dialogData"];
      this.locationList = dialogData["zoneList"];
    }

    if (this.pageType == "searchLocation") {
      let dialogData = this.data["dialogData"];
      this.locationList = dialogData["zoneList"];
      let selectedDate = new Date(dialogData["selectedDate"]);
      this.selectedDate = moment(selectedDate).format("Do MMM YYYY");
    }
    if (this.data["type"] == "calendarView") {    
      const commonTimeSub$ = this.commonService.currentTimeZone.subscribe((res) => {
        this.timeZoneByConfig = res;
      });

      let endDate =new Date();
      endDate.setDate(endDate.getDate() + 30);
      let eDate = moment(endDate);
      if(this.data['dialogData']['findColleagueBlueTick'] != undefined && this.data['dialogData']['findColleagueBlueTick']){
        this.findColleagueBlueTick =true;
      }
      
      this.calendarOptions = {
        timeZone: "UTC",
        initialView: "dayGridMonth",
        locale: this.localVal,
        dayCellClassNames: this.customDayCellContent.bind(this),
        // validRange: { 
        //   start:null,                   
        //   end: endDate
        // },
        
        plugins: [dayGridPlugin],
        showNonCurrentDates: false,
        height: "auto",
        dateClick: this.handleDateClick.bind(this),
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        headerToolbar: {
          left: "previousMonth",
          center: "title",
          right: "nextMonth",
        },
        customButtons: {
          previousMonth: {
            text: "Previous",
            icon: "chevron-left",
            click: this.prevMonth.bind(this), // !!!
          },
          nextMonth: {
            text: "Next",
            icon: "chevron-right",
            click: this.nextMonth.bind(this), // !!!
          },
        },
        eventContent: (arg) => {
          let htmlStr = ""; 
          return {
            html:!this.findColleagueBlueTick?
              "<div class='eventColor' style='background-color:" +
              arg.event.extendedProps.workStatusColor +
              "'>&nbsp</div>":'',
          };
        },
        eventSources: [
          {
            events: this.roster,
            display: "list-item",
            borderColor: "none",
          },
        ],
        selectOverlap: false,
        
        // dayHeaderContent: (arg) => {
        //   const weekdaysMap = {
        //     en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        //     pt: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
        //     de: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
        //     es: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom']
        //   };
        
        //   const selectedLanguage = this.localVal || 'en';
        
        //   // Make sure the selected language exists in the map
        //   const weekdays = weekdaysMap[selectedLanguage] || weekdaysMap['en'];
        
        //   return weekdays[arg.date.getDay()];
        // },
        // dayHeaderContent: (arg) => {
        //   const dayIndex = arg.date.getDay();
        //   const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];
        //   const dayName = daysOfWeek[dayIndex];
        //   return this.translate.instant(dayName);
        // },
        
        
        eventDidMount: (info) => {
          const eventDate = moment(info.event.start);
        const nextButton = this.fullCalendarComponent.getApi().el.querySelector('.fc-nextMonth-button');
          
        if (eventDate.isAfter(eDate)) {           
          nextButton.classList.add('fc-state-disabled');
        } else {        
          nextButton.classList.remove('fc-state-disabled');
        }
        }
      };
      
    }

    
  }

  
  customDayCellContent(dayCellContentArg: DayCellContentArg) {
    const cellDate = dayCellContentArg.date;  
    
    let startDate =this.commonService.getTimeStampTimeZone(new Date(),this.timeZoneByConfig,'start');
    
    if(startDate > cellDate.getTime()){
      dayCellContentArg.isPast=true;
    }else{
      dayCellContentArg.isPast=false;
    }

    let currentDateTimeStamp =  this.commonService.getTimeStampTimeZone(
      new Date().getTime(), this.timeZoneByConfig,
      "end"
    );
    let endDate = this.commonService.getTimeStampTimeZoneInDateObj(currentDateTimeStamp, this.timeZoneByConfig, "end")
    endDate.setDate(endDate.getUTCDate() + 30); 
    let timeStamp = this.commonService.getTimeStampTimeZone(endDate.getTime(), 'UTC', "end");
    if (timeStamp < cellDate.getTime()) {      
      dayCellContentArg.isPast=true;    
    }
  }
 
  eventViewRender ()  {
    const currentDate = this.fullCalendarComponent.getApi().getDate();  
    const nextButton = $('.fc-next-button');
    let endDate =new Date();
    endDate.setDate(endDate.getDate() + 30);
  if (currentDate > endDate) {
    nextButton.prop('disabled', true);
    nextButton.addClass('fc-state-disabled');
  } else {
    nextButton.prop('disabled', false);
    nextButton.removeClass('fc-state-disabled');
  }       
}
  ngOnInit() {
    if (this.data["type"] == "calendarView") {
      setTimeout(() => {
        this.updateDateAndLoadInfo();
      }, 500);
    }

    if(this.data["type"] == 'colleaguesModal') {
      let dialogData = this.data["dialogData"];
      this.colleagueObj = dialogData;
    }
  }


  handleDateClick(arg) {

    let isClickable = false;
    const cellDate = arg.date;
    let startDate =this.commonService.getTimeStampTimeZone(new Date().getTime(),this.timeZoneByConfig,'start');
    if(startDate <= cellDate.getTime()){
      isClickable=true;
    }else{
      isClickable=false;
    }

    const selectedDate = this.commonService.getUTCTimeStampTimeZone(
      arg.date.getTime(),
      this.timeZoneByConfig,
      "start"
    );

    let currentDateTimeStamp =  this.commonService.getTimeStampTimeZone(
      new Date().getTime(), this.timeZoneByConfig,
      "end"
    );

    let endDate = this.commonService.getTimeStampTimeZoneInDateObj(currentDateTimeStamp, this.timeZoneByConfig, "end")
    endDate.setDate(endDate.getUTCDate() + 30); 
    let timeStamp = this.commonService.getTimeStampTimeZone(endDate.getTime(), 'UTC', "end");

    let endTimeStamp = this.commonService.getTimeStampTimeZone(cellDate.getTime(), 'UTC', "end");
    if (timeStamp < endTimeStamp) {      
      isClickable=false;
    }

    if(isClickable) {
      let data = {
        type: 'calendarView',
        date: selectedDate
      }
      this.dialogRef.close(data);
    }
  }

  closePopup() {
    this.dialogRef.close("dashboardModal");
  }

  closePopupColleagues() {
    this.dialogRef.close("colleagues");
  }

  loadCurrentMonthInfo(changedDate) {
    this.roster = [];
    this.events = [];
    let cdate = new Date(changedDate);
    const firstMonth = new Date(
      cdate.getUTCFullYear(),
      cdate.getUTCMonth(),
      1
    );

    const lastMonth = new Date(
      cdate.getUTCFullYear(),
      cdate.getUTCMonth() + 1,
      0
    );

    let fdateTimeStamp = this.commonService.getTimeStamp(
      firstMonth.getTime(),
      "start"
    );
    let ldateTimeStamp = this.commonService.getTimeStamp(
      lastMonth.getTime(),
      "end"
    );

    let bookSeats = this.dashboardService.loadCalendarView(
      fdateTimeStamp,
      ldateTimeStamp
    );
    this.loaderService.showLoaderUntilCompleted(bookSeats).subscribe((res) => {
      let bookingInfo = res["response"];
      if (bookingInfo.length > 0) {
        bookingInfo.forEach((element) => {
          let rosterPresent = element["quickAction"];
          let color = null;
          if (rosterPresent) {
            color = "#1F2559";
          }
          let data = {
            title: "",
            start: element.dayStart,
            workStatusColor: color,
          };
          this.events.push(data);
        });

        this.fullCalendarComponent.getApi().removeAllEventSources();
        this.fullCalendarComponent.getApi().addEventSource(this.events);
        this.fullCalendarComponent.getApi().refetchResources();
        this.fullCalendarComponent.getApi().refetchEvents();
      }
    });

    //for removing blur on calendar
    setTimeout(() => {
      Array.from(document.querySelectorAll('button')).forEach(el => el.blur());
    }, 3000);
  }

  nextMonth() {
    this.fullCalendarComponent.getApi().next();
    this.updateDateAndLoadInfo();
  }

  prevMonth() {
    this.fullCalendarComponent.getApi().prev();
    this.updateDateAndLoadInfo();
  }

  updateDateAndLoadInfo() {
    let changedDate = this.fullCalendarComponent.getApi().getDate().getTime();
    this.loadCurrentMonthInfo(changedDate);
  }

  doneSchedule(data) {
    if(data == "closePreference") {
      this.dialogRef.close();
    } else {
      if (data) {
        this.rosterData = data;
        if (
          this.locationList.length > 1 &&
          Object.keys(data).includes("OFFICE")
        ) {
          data["OFFICE"] &&
            data["OFFICE"].dayStart.forEach((date) => {
              let displayDate = moment.utc(date).format("DD MMM");
              this.selectedOfficeDates.push({ key: date, displayDate });
            });
          this.pageType = "selectLocations";
        } else {
          let createRosterAPI = this.dashboardService.createRoster(data);
          this.loaderService.showLoaderUntilCompleted(createRosterAPI).subscribe(
            (res) => {
              this.pageType = "scheduleCreated";
            },
            (err) => {
          
            }
          );
        }
      }
    }
  }

  getNotification(event) {
    if (event == "true") {
      this.closePopup();
    }
  }

  onChange(data) {
  }

  getCurrentMonth(data) {
 
  }

  getFilteredSearchLocation(selectedLocation) {
 
    let data: any = {
      pageType: "",
    };
    if (this.pageType == "searchLocation") {
      data = {
        pageType: this.pageType,
        selectedFilteredLocation: selectedLocation,
      };
    }
    this.dialogRef.close(data);
  }

  saveDateLocation(selectedDateLocation) {

    let createRosterAPI = this.dashboardService.createRoster(this.rosterData);
    this.loaderService.showLoaderUntilCompleted(createRosterAPI).subscribe(
      (res) => {
        let colleagueAPI =
          this.dashboardService.saveRosterZones(selectedDateLocation);
        this.loaderService
          .showLoaderUntilCompleted(colleagueAPI)
          .subscribe((res) => {
    
          });
        this.pageType = "scheduleCreated";
      },
      (err) => {
        this.toastrService.error(this.translate.instant("somethingWentWrong"));
      }
    );
  }

  showFullCalendar() {
    this.closePopupColleagues();
  }
}
