import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'commonService';
import { LoaderService } from 'loaderService';
import { IntegrationService } from '../../integration.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Subject, Subscription, of, EMPTY } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-integration-partners-modal',
  templateUrl: './integration-partners-modal.component.html',
  styleUrls: ['./integration-partners-modal.component.scss']
})
export class IntegrationPartnersModalComponent implements OnInit {
  currentZone;
  pageType;
  currentAmenityId;
  submittedOutlook = false;
  submittedUserZoom = false;
  submittedEmailOutlook = false;
  outlookEditFlow = false;
  outlookForm: FormGroup;
  zoomUserLevelForm: FormGroup;
  outlookEmailForm: FormGroup;
  prevModal = '';
  zoomConfig = {
    applClientId: '', clientSecret: '',
  }
  outlookConfig = {
    appOfficeClientId: '', appOfficeTenant: '', appOfficeClientSecret: '', outlookEmail: '',
    meetingRoomEmail: ''
  }
  amenity = { amenityName: '', upload_appUrl: '', upload_dashboardUrl: '', config: '', id: "" };
  writeCheck = true;
  readCheck = true;
  validateIntegration:Boolean = false;
  skipOutlookIntegration = false;
  disableOutlookWithTeams = false;
  editOutlook = false;
  timezone="Asia/Singapore";

  private unsubscribe$ = new Subject<void>();

  constructor(
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IntegrationPartnersModalComponent>,
    private integrationService: IntegrationService,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.commonService.currentZone.subscribe((res) => {
      this.currentZone = res;
    });
  }

  ngOnInit() {
    // console.log(this.data)
    this.pageType = this.data['type'];
    this.disableOutlookWithTeams = this.data['disableTeams'];
    this.currentAmenityId = this.data['currentAmenityId'];
    this.outlookForm = this.formBuilder.group({
      applicationClientID: ["", Validators.required],
      applicationTenantID: ["", Validators.required],
      applicationClientSecret: ["", Validators.required]
    });
    this.zoomUserLevelForm = this.formBuilder.group({
      applicationClientID: ["", Validators.required],
      applicationClientSecret: ["", Validators.required]
    });
    this.outlookEmailForm = this.formBuilder.group({
      outlookEmail: ["", [Validators.required, Validators.email]],
      meetingRoomEmail: ["", [Validators.required, Validators.email]]
    });
    if(this.pageType == "outlook_permissions") {
      this.amenity.amenityName = this.data['amenityName'];
      this.currentAmenityId = this.data['amenityId'];
      this.outlookEditFlow = true;
    }
    if(this.pageType == 'editZoomUserConfig'){
      const {apiKey, apiSecret} = this.data['zoomConfig']
      this.zoomConfig = {
        applClientId: apiKey,
        clientSecret: apiSecret
      }
      // console.log(this.zoomConfig)
      this.pageType = 'zoomUserConfig'
    }
    if(this.pageType == 'editOutlookConfig'){
      this.editOutlook = true;
      // console.log(this.data['outlookConfigData'])
      this.outlookConfig = this.data['outlookConfigData']
      // console.log(this.outlookConfig)
      this.pageType = 'outlook_application'
    }
  }

  get outLookf() { return this.outlookForm.controls; }
  get zoomUserf() { return this.zoomUserLevelForm.controls; }
  get outLookEmailf() { return this.outlookEmailForm.controls; }

  closePopup() {
    this.disableOutlookWithTeams = false;
    this.pageType = '';
    this.data["closePopup"] = true;
    this.dialogRef.close(this.data);
  }

  openApplicationTenant(){
    this.pageType="outlook_application";
  }

  checkPermissions(){
    this.submittedOutlook = true;
    if(this.outlookForm.valid){
      this.outlookConfig.appOfficeTenant = this.outLookf.applicationTenantID.value;
      this.outlookConfig.appOfficeClientId = this.outLookf.applicationClientID.value;
      this.outlookConfig.appOfficeClientSecret = this.outLookf.applicationClientSecret.value;

      this.permissionCheck();
     // this.pageType = 'mail_integration';
      //this.validateIntegration = false;
    }
  }

  configureUserZoom(){
    this.submittedUserZoom = true;
    if(this.zoomUserLevelForm.valid){
      this.zoomConfig.applClientId = this.zoomUserf.applicationClientID.value;
      this.zoomConfig.clientSecret = this.zoomUserf.applicationClientSecret.value;
      let data = {
        "closePopup": true,
        "zoomConfig": this.zoomConfig,
        "enableUserZoom": true
      }
      this.dialogRef.close(data);
    }
  }

  permissionCheck(){
    let data = {
      "tenantId": this.outlookConfig.appOfficeTenant,
      "clientId": this.outlookConfig.appOfficeClientId,
      "clientSecret": this.outlookConfig.appOfficeClientSecret,
      "userOutlookEmail": "",
      "meetingRoomEmail": ""
    }
    const testInt$ = this.integrationService.permissionCheck(data, this.currentZone);
    this.loaderService.showLoaderUntilCompleted(testInt$)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(res => {
        if (res['response']) {
        
          var permissionList = res['response'];
          if(!permissionList.includes("Calendars.Read")){
            this.readCheck = false;
            this.pageType = "outlook_permissions_fail";    
            // this.toastrService.error("Sorry! We are unable to validate your entry. Please try again");
          } else {
            this.readCheck = true;
          }
          if(!permissionList.includes("Calendars.ReadWrite")){
            this.writeCheck = false;
            this.pageType = "outlook_permissions_fail";
            // this.toastrService.error("Sorry! We are unable to validate your entry. Please try again");
          } else {
            this.writeCheck = true;
          }
          if(this.readCheck && this.writeCheck){
            this.validateIntegration= false;
            this.pageType = 'mail_integration';
          } else {
            // this.pageType = "outlook_permissions_fail";
            this.toastrService.error("Sorry! We are unable to validate your entry. Please try again");
          }
        }
      },error=>{
        // this.readCheck = false;
        // this.writeCheck = false; // need to remove later when we get proper response from the API when enter Invalid
        this.toastrService.error("Invalid. Please enter valid credentials");
      });
  }

  confirmDisableOffice365(prevModal) {
    this.prevModal = prevModal;
    if(this.editOutlook){
      this.pageType = 'proceedToNotUpdateOffice365';
    } else {
      this.pageType = 'proceedToDisableOffice365';
    }
  }

  openPrevModal(prevModal) {
    this.pageType = prevModal || this.prevModal;
    this.prevModal = prevModal || '';
  }

  checkOutlookEmailPermissions(){
    this.submittedEmailOutlook = true;
    if(this.outlookEmailForm.valid){
      this.outlookConfig.outlookEmail = this.outLookEmailf.outlookEmail.value;
      this.outlookConfig.meetingRoomEmail = this.outLookEmailf.meetingRoomEmail.value;
    }

    if(this.outlookEmailForm.valid && !this.validateIntegration){  
      this.validateWholeIntegration();
    // } else {
    //   if(this.outlookEmailForm.valid){
    //     this.validateWholeIntegration();
    //   }
    }
  }

  validateWholeIntegration(){
    this.validateIntegration = true;
    this.integrationService.changeValidateIntegration(true);
    let data = {
      "tenantId": this.outlookConfig.appOfficeTenant,
      "clientId": this.outlookConfig.appOfficeClientId,
      "clientSecret": this.outlookConfig.appOfficeClientSecret,
      "userOutlookEmail": this.outlookConfig.outlookEmail,
      "meetingRoomEmail": this.outlookConfig.meetingRoomEmail
    }
    const testInt$ = this.integrationService.testIntegrationData(data, this.currentZone);
    this.loaderService.showLoaderUntilCompleted(testInt$)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(res => {
        if (res['response']) {
          // console.log(res['response'])
          this.validateIntegration = true;
          this.integrationService.changeValidateIntegration(true);
        
          // console.log(this.validateIntegration);
          // this.permissionCheck();
          // this.pageType = 'onboard_meetingroom';
          this.toastrService.success('Successfully validated');
        }
      },err=>{
        this.validateIntegration = false;
        this.skipOutlookIntegration = true;
        this.toastrService.error("Sorry! we are unable to validate your entry. please try again");
      });
  }

  enableOutlook(){
    let data = {
      "closePopup": true,
      "enableOutlook": true,
      "configData": this.outlookConfig
    }
    this.dialogRef.close(data);
  }

  proceedToDisable(type) {
    let data = {
      "closePopup": true,
      "proceedToDisable": true,
      "type": type
    }
    this.dialogRef.close(data);
  }

  next() {
    this.data["closePopup"] = false;
    this.pageType = 'addIntegration_confirmation';
    // this.dialogRef.close(this.data);
  }
}
