import { Component, Inject, OnInit, Pipe, PipeTransform } from '@angular/core';
import { LoaderService } from "loaderService";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-faq-modal',
  templateUrl: './faq-modal.component.html',
  styleUrls: ['./faq-modal.component.scss']
})
export class FaqModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<FaqModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }
//Close the modal
  closeModal(): void {
    let data = [];
    this.dialogRef.close(data);
  }

}
