import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";
import { environment } from "../../../../environments/environment";
@Injectable()
export class DigitalBuildingsService {
  moduleId;
  BASEURL;
  EMSURL;
  ENVURL;
  BASE_URL_Tenant
  constructor(
    public http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public moduleidService: ModuleidService
  ) {
    this.BASEURL = baseUrl;
    this.ENVURL = environment.BASE_URL;
    this.EMSURL = environment.BASE_URL + "ems/";
    this.BASE_URL_Tenant = environment.BASE_URL + 'tms/tenant/digital/';
    this.moduleId = moduleidService.getModueId();
  }

  addBuildingsFloors(data, zoneId) {
    // console.log(zoneId);
    let url = this.EMSURL + `zones`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  saveBuildingsFloors(data, zoneId) {
    // console.log(zoneId);
    let url = this.EMSURL + `zones/superAdmin/setPriorityOrderForFloor`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getAllTenantData(zoneId){
    return this.http.get(this.BASE_URL_Tenant + `getAll`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  addTenantWithFloor(zoneId,fid,tid){
    let url = this.ENVURL+ `tms/tenant/digital/add/floor?zoneId=${fid}&TenantId=${tid}`;
    return this.http.post<any>(url, {}, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  deleteTenantWithFloor(zoneId,fid,tid){
    let url = this.ENVURL + `tms/tenant/digital/delete/floor?zoneId=${fid}&TenantId=${tid}`;
    return this.http.delete<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getExisitingTenantAssociatedWithFloor(zoneId,fid){
    return this.http.get(this.ENVURL + `tms/tenant/digital/get/floor?zoneId=${fid}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getBuildings(zoneId){
    // console.log(zoneId);
    let url;
    if(!this.isTenantEngagement())
     url = this.EMSURL + `zones/superAdmin/getPriorityOrderList?zoneId=`+zoneId;
    else   url = this.EMSURL + `zones/superAdmin/tenant/getPriorityOrderList?zoneId=`+zoneId;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      })
      .pipe(
      );
  }

  UpdateBuilding(zoneId, data): Observable<any> {
    let url = this.EMSURL + "zones";
    return this.http
      .put<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
      );
  }
  isTenantEngagement(): boolean {
    
    if(localStorage.getItem('tenant_Engagement') == "true"){
        return true;
    }else{
        return false;
    }
  }
  
}


