/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./faq-modal.component";
import * as i4 from "@angular/material/dialog";
var styles_FaqModalComponent = [i0.styles];
var RenderType_FaqModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqModalComponent, data: {} });
export { RenderType_FaqModalComponent as RenderType_FaqModalComponent };
export function View_FaqModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["id", "close_btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "video w-100 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "video", [["class", "w-100 video-h"], ["controls", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "source", [["type", "video/ogg"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "source", [["type", "video/webm"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data["question"]["mediaUrl"]; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.data["question"]["mediaUrl"]; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.data["question"]["mediaUrl"]; _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("yourBrowserDoNotSupportHtmlVideo")); _ck(_v, 10, 0, currVal_3); }); }
export function View_FaqModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq-modal", [], null, null, null, View_FaqModalComponent_0, RenderType_FaqModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.FaqModalComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqModalComponentNgFactory = i1.ɵccf("app-faq-modal", i3.FaqModalComponent, View_FaqModalComponent_Host_0, {}, {}, []);
export { FaqModalComponentNgFactory as FaqModalComponentNgFactory };
