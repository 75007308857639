import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, NgForm } from '@angular/forms';
import 'rxjs/add/observable/of';
import { CommonService } from '../../../services/common-service.service';
import { AlertService } from '../../../services/alert-service.service';
import { EMSService } from '../../../shared/services/ems.service';
import { TMSService } from '../../../shared/services/tms.service';
import { VMSService } from '../service/vms.service';

@Component({
  selector: 'app-vms-modal',
  templateUrl: './peoplecount-modal.component.html',
  styleUrls: ['./peoplecount-modal.component.scss']
})
export class PEOPLECOUNTModalComponent implements OnInit {
  currentZone = 5;

  public form: FormGroup;
  isValidParticipantForm: boolean;
  participantName;
  locations = [];
  hosts = [];
  public csvRecords: any[] = [];
  columns: string[] = ['no', 'visitor', 'email', 'phone', 'company', 'remove'];
  visitorList: any[] = [];
  dataSource = [];
  visitorInfo = {};
  hostInfo = {};
  csvContent: string;
  questionAndAnswers:any = [];
  model: any = {"optionsq":[]};
  loading:boolean = false;
  encryptedQRCode:any = '';
  minDate = new Date(new Date().setHours(0,0,0,0));
  buildingList = [];
  pageType:any;
  intivationId:any;
  building="";
  tenantId='';
  displayType = "BUILDING" ;
  count;
  empolyeeCount;
  limit;
  showBuildingName =null;
  showTenantName = null;
  ipcCounterList = [];
  ipcCounterId=null;
  addedCounterList = [];
  unselectedList = []
  addMoreEnable = false;
  editData = [];
  constructor(
    public matSnackBar: MatSnackBar,
    private tmsService: TMSService,
    public dialogRef: MatDialogRef<PEOPLECOUNTModalComponent>,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public commonService: CommonService,
    public emsService: EMSService,
    private fb: FormBuilder,
    public alertService: AlertService,
    public vmsService: VMSService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      
    }

  ngOnInit() {
    this.currentZone = this.data['currentZone'];
    this.tenantId =  this.data['tenantId'];
    if(this.data['type'] == 'additionalDetails'){ 
       this.buildingList = this.data['buildingList'];
       this.ipcCounterList = this.data['ipcCounterList'];
       this.displayType = this.data['displayType'];
       if(this.ipcCounterList.length == 0){
        this.addMoreCounnt();
       }
       this.getAllAdd();  
    }

    if(this.data['type'] == 'editIpc'){ 
        this.editData = this.data;
        this.showBuildingName = this.data.content['zone_name'];
        this.showTenantName = this.data.content['tenant_name'];
        this.empolyeeCount = this.data.content['total_count'];
        this.limit = this.data.content['allowed_percentage'];
    }
  }

  getAllAdd(){
    if(this.buildingList && this.ipcCounterList){
      this.buildingList.forEach((element,index) => {
        let data=	{
          "id" : null,
          "tenant_id" : this.tenantId,
          "total_count" : '',
          "zone_id" : element.zoneId,
          "zone_type" :this.displayType,
          "zone_name": element.name,
          "added":false
         }
        this.ipcCounterList.forEach((ele) => {
              if(ele.zone_id == element.zoneId){
                   data.id = ele.id;
                   data.tenant_id = ele.tenant_id;
                   data.total_count = ele.total_count;
                   data.zone_id = ele.zone_id;
                   data.zone_type =ele.zone_type;
                   data.zone_name= ele.zone_name;
                   data.added=true;
                  }
        });
        if(data.id == null){
          this.addedCounterList.push(data);
        }
         
      });
      if(this.addedCounterList.length > 1){
        this.addMoreEnable = true;
      }  
      
    }
  }


  
  closePopup() {
    this.dialogRef.close(this.ipcCounterList);
  }
 

  addMoreCounnt(){
    
    
    let data=	{
      "id" : null,
      "tenant_id" : this.tenantId,
      "total_count" : "",
      "zone_id" : "",
      "zone_type" :"BUILDING",
      "zone_name": "selected",
      "added":true
     }
     if(this.buildingList.length-1 > this.ipcCounterList.length){
      this.ipcCounterList.push(data);
      if(this.buildingList.length-1 == this.ipcCounterList.length){
            this.addMoreEnable = false;
      }
      
     }else{
      this.commonService.openSnackBar("Building count should add only once","");
     }
        
  }

  closeCounnt(index){
    if(this.ipcCounterList[index].added && this.ipcCounterList.length > 1 ){
      this.ipcCounterList.splice(index, 1);
      this.addMoreEnable = true;
    }
    
  }

  
  getAllipcCounter(){
    this.vmsService.getAllipcCounter(this.currentZone,"tenantId",this.tenantId,0,50).subscribe(res => {
      this.ipcCounterList=res['response']["content"];  
      }, err => {
        //this.dataSource = [];
        this.commonService.openSnackBar(err.error.message,"");
      });
  }
  

  saveAdditionalDetails(){
    let data = {};
    let submitObjecr = [];
    let formVal = [];
    this.ipcCounterList.forEach(element => {
        if(!!element.zone_id && !!element.total_count){
          formVal.push(true);
          submitObjecr.push(element);
        }else{
          formVal.push(false);
        }
    });
  
    if(!formVal.includes(false)){
     
      this.vmsService.ipcCounterAdd(this.currentZone,submitObjecr).subscribe(res => {
        this.addedCounterList = [];
        this.ipcCounterList  = res['response'];
        this.getAllAdd();
        this.commonService.openSnackBar("Added Successfully","");
      }, err => {
        //this.dataSource = [];
        this.commonService.openSnackBar(err.error.message,"");
      });
    }else{
      this.commonService.openSnackBar("Please enter all fields","");
    }
  }


  updateAdditionalDetails(){
    var data = [];
    if(!!this.empolyeeCount && !!this.limit ){
   if(this.limit <= 100 ){
    this.data.content.total_count=this.empolyeeCount;
    this.data.content.allowed_percentage = this.limit;
    this.data.content.zone_type=this.displayType;
    
      this.vmsService.ipcCounterAdd(this.currentZone,[this.data.content]).subscribe(res => {
        this.commonService.openSnackBar("Added Successfully","");
      }, err => {
        //this.dataSource = [];
        this.commonService.openSnackBar(err.error.message,"");
      });
    }else{
      this.commonService.openSnackBar("Allow maximum capacity 100%  only","");  
    }
   }else{
    this.commonService.openSnackBar("Please enter all fields","");
  }  
  }
}

export class VistorsCsvRecord {

  public name: any;
  public email: any;
  public mobile: any;
  public company: any;
  public visitorTypeId: any;
  constructor() { }
}