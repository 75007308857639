<div class="row container-fluid">
    <div class="card-body mt-3" style="background-color: #fff">
        <div class="row">
            <div class="col-lg-7 mt-5">
                <div class="float-left">
                    <h4 class="semi-bold-blue pl-2">
                         {{"personalSpaces" | translate}}
                    </h4>
                    <p class="pg-main mt-5">
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"canBookedUsedByUser" | translate}}
                    </p>
                    <p class="pg-main mt-3">
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"canBookedByUserOrAdmin" | translate}}
                    </p>
                    <p class="pg-main mt-3">
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"userCantAddParticipans" | translate}}
                    </p>
                    <p class="pg-main mt-3">
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"noIntegrationWithVdoConf" | translate}}
                    </p>
                    <p class="pg-main mt-3">
                        <img
                            src="../../assets/images/jumpree_digital/check-blue.svg"
                        />
                        {{"canPermanentlyAllocatedDept" | translate}}
                    </p>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="imageContainer">
                    <img
                        class="imgGlobal mr-5"
                        src="../../assets/images/jumpree_digital/globe_map.svg"
                    />
                </div>
                <button
                    type="button"
                    class="btn btn-main float-right mt-5 smallBtn"
                    (click)="closeSpaces()"
                >
                {{"gotIt" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
