import * as tslib_1 from "tslib";
import { catchError, map, shareReplay, tap, } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { throwError, of, BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../../../services/moduleid-service";
import * as i3 from "ngx-toastr";
export class WorkStatusService {
    constructor(http, moduleidService, toastrService, baseUrl) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.toastrService = toastrService;
        this.departmentSubject$ = new BehaviorSubject([]);
        this.departments$ = this.departmentSubject$.asObservable();
        this.deskSubject$ = new BehaviorSubject([]);
        this.desks$ = this.deskSubject$.asObservable();
        this.currentViewSubject$ = new BehaviorSubject("work status");
        this.currentView$ = this.currentViewSubject$.asObservable();
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL + 'ems/';
        this.moduleId = this.moduleidService.getModueId();
    }
    showFloorPlan(currentWorkStatus) {
        this.currentViewSubject$.next(currentWorkStatus);
    }
    getAllWorkStatus(zoneId, data) {
        let searchParams = "";
        if (data.searchType != "" && data.searchValue != "") {
            // searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
            searchParams = `&userId=${data.searchValue}`;
        }
        let url = this.BASEURL + `workStatus/getAll?size=${data.pageSize}&page=${data.pageIndex}` + searchParams;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
        // return of({
        //     "message": "Success",
        //     "statusCode": 200,
        //     "api": "/spacemanagement/workStatus/getAll",
        //     "response": {
        //       "content": [
        //         {
        //           "fullName": "admin Smarten",
        //           "email": "admin@smartenspaces.com",
        //           "userId": 1,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Satya sai",
        //           "email": "satya.sai@smartenspaces.com",
        //           "userId": 2,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Test Test",
        //           "email": "test@smartensingapore.onmicrosoft.com",
        //           "userId": 3,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "jeevan b",
        //           "email": "jeevan@smartenspaces.com",
        //           "userId": 4,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Siva Kartheek",
        //           "email": "siva.kartheek@smartenspaces.com",
        //           "userId": 5,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "jeevan b",
        //           "email": "gopi.n@smartenspaces.com",
        //           "userId": 6,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "karthik manohar",
        //           "email": "karthikeyan.m@smartenspaces.com",
        //           "userId": 7,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Yuva l",
        //           "email": "yuval@mailinator.com",
        //           "userId": 8,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Dhoni l",
        //           "email": "dhonil@mailinator.com",
        //           "userId": 9,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "devqa emp 5",
        //           "email": "emp5@smartenspaces.com",
        //           "userId": 10,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "devqa emp 3",
        //           "email": "emp3@smartenspaces.com",
        //           "userId": 11,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "devqa emp 1",
        //           "email": "emp1@smartenspaces.com",
        //           "userId": 12,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "devqa emp 6",
        //           "email": "emp6@smartenspaces.com",
        //           "userId": 13,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "devqa emp 2",
        //           "email": "emp2@smartenspaces.com",
        //           "userId": 14,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "devqa emp 4",
        //           "email": "emp4@smartenspaces.com",
        //           "userId": 15,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Suneel Kumar",
        //           "email": "suneel@smartenspaces.com",
        //           "userId": 16,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Yuva Test",
        //           "email": "yuvat@mailinator.com",
        //           "userId": 17,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Dhoni Test",
        //           "email": "dhonit@mailinator.com",
        //           "userId": 18,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Arun A",
        //           "email": "arun.a@smartenspaces.com",
        //           "userId": 19,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "test_meenu SS",
        //           "email": "meenu@smartenspaces.com",
        //           "userId": 20,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //         {
        //           "fullName": "Venugopala  s",
        //           "email": "venugopala.s@smartenspaces.com",
        //           "userId": 21,
        //           "workStatus": null,
        //           "entityInfo": null,
        //           "zoneId": null
        //         },
        //       ],
        //       "pageable": {
        //         "sort": {
        //           "unsorted": true,
        //           "sorted": false,
        //           "empty": true
        //         },
        //         "pageNumber": 0,
        //         "pageSize": 10,
        //         "offset": 0,
        //         "paged": true,
        //         "unpaged": false
        //       },
        //       "totalPages": 2,
        //       "last": false,
        //       "totalElements": 20,
        //       "sort": {
        //         "unsorted": true,
        //         "sorted": false,
        //         "empty": true
        //       },
        //       "numberOfElements": 10,
        //       "first": true,
        //       "size": 10,
        //       "number": 0,
        //       "empty": false
        //     }
        //   })
    }
    workStatusSave(zoneId, data) {
        let url = this.BASEURL + 'workStatus/save';
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getAllSearchedWorkStatus(zoneId, data) {
        let term = data.searchTerm.trim();
        if (data.searchType == 'deskName') {
            let resData = [];
            this.departments$
                .pipe(map((departments) => departments.filter((department) => {
                let depName = department["name"]
                    .toLowerCase()
                    .toString();
                let searchTerm = term.toLowerCase();
                let res = depName.indexOf(searchTerm);
                if (res >= 0) {
                    return department;
                }
            })))
                .subscribe((res) => {
                resData = res;
            });
            return of(resData);
        }
        else {
            let searchParams = "";
            if (data.searchType != "" && data.searchValue != "") {
                searchParams = `&key=${data.searchType}&operation=:&value=${data.searchValue}`;
            }
            let url = this.BASEURL +
                `entitytype/getAll?size=${data.pageSize}&page=${data.pageIndex}` +
                searchParams;
            return this.http.get(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            });
        }
    }
    /* for desks dropdown data*/
    // getDesksDropDown(data){
    //   let resData = [];
    //       this.desks$
    //           .pipe(
    //               map((desks) =>
    //               desks.filter((desk) => {
    //                       let deskName = desk["name"]
    //                           .toLowerCase()
    //                           .toString();
    //                       let searchTerm = data.trim().toLowerCase();
    //                       let res = deskName.indexOf(searchTerm);
    //                       if (res >= 0) {
    //                           return desk;
    //                       }
    //                   })
    //               )
    //           )
    //           .subscribe((res) => {
    //               resData = res;
    //           });
    //       return of(resData);   
    // }
    getDeskNumberAutocompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        /** new way department by search */
        if (term == "" || term.length <= 2) {
            let resData = [];
            if (data.searchType === "deskNumber") {
                this.desks$.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    resData = res;
                }));
                return of(resData);
            }
            else {
                return of([]);
            }
        }
        else if (data.searchType === "deskNumber") {
            let url = `${this.BASEURL}entity/search/floor?floorId=${data.floorId}`;
            return this.http
                .get(url, {
                params: new ZoneAndModuleHttpParams(zoneId, 1),
            })
                .pipe(map((res) => res["response"]), catchError((err) => of([])));
        }
    }
    // getDesks(zoneId, floorId) {
    //   let url = this.BASEURL + 'entity/search/floor?floorId=' + floorId;
    //   return this.http.get<any>(url, {
    //       // params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    //       params: new ZoneAndModuleHttpParams(zoneId, 1),
    //   });
    // }
    downloadCsv(zoneId, data) {
        let url;
        url = this.BASEURL + `templates/download/key/${data}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    deleteWorkstatus(zoneId, data) {
        let url = this.BASEURL + `workStatus/deleteUserWorkStatus?userWorkStatusId=${data.id}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getDesks(zoneId, floorId) {
        let url = this.BASEURL + 'entity/search/floor?floorId=' + floorId;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
        })
            .pipe(shareReplay(1), map((res) => res['response']), catchError((err) => of([])), map((res) => {
            if (res.length) {
                return res.map((_a) => {
                    var { id: userId } = _a, rest = tslib_1.__rest(_a, ["id"]);
                    return (Object.assign({ userId }, rest));
                });
            }
            else {
                return of([]);
            }
        }), tap((res) => {
            this.deskSubject$.next(res);
        }));
    }
    getAvailabilityMapDataWorkStatus(reqObj, currentZone) {
        let url = this.BASEURL + "entity/availabilityMapViewListForWorkStatus";
        return this.http
            .post(url, reqObj, {
            params: new ZoneAndModuleHttpParams(currentZone, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError((err) => throwError(err)));
    }
    downloadFile(zoneId) {
        let url;
        url = this.BASEURL + `workStatus/download/template`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    postFile(zoneId, fileToUpload) {
        let url = this.BASEURL + `workStatus/uploadCSV`;
        const formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        var yourHeadersConfig = {
            "content-type": "multipart/form-data",
        };
        return this.http.post(url, formData, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getEmployeeNameAutoCompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
            return of([]);
        }
        // let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
        let url = `${this.EMSURL}user/search/v2/limited?searchString=${term}&searchParams=name,email,phone_num`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError((err) => of([])));
    }
}
WorkStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkStatusService_Factory() { return new WorkStatusService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModuleidService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject("BASE_URL")); }, token: WorkStatusService, providedIn: "root" });
