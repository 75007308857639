import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'commonService';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { DigitalBuildingsService } from '../services/digital-buildings.services';
// import { DigitalBuildingsService } from '../services/digital-buildings.services';
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { LoaderService } from 'loaderService';
@Component({
  selector: 'app-digital-buildings-modal',
  templateUrl: './digital-buildings-modal.component.html',
  styleUrls: ['./digital-buildings-modal.component.scss']
})
export class DigitalBuildingsModalComponent implements OnInit {
  pageType: any;
  modalData: any;
  registerForm:FormGroup;
  editFloorForm:FormGroup;
  submitted: boolean;
  currentZone: any;
  bName;
  msg;
  floorNameTenant='';
  tenantId=null;
  tenantListOriginal=[]
  tenantList=[];
  buildingNameTenant=''
  constructor(
    public dialogRef: MatDialogRef<DigitalBuildingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private digitalBuildingsService: DigitalBuildingsService,
    private toasterService: ToastrService,
    private commonService: CommonService, private translate: TranslateService,
    private loaderService:LoaderService
  ) {
    this.commonService.currentZone.subscribe(async (res) => {
      if (res) {
        this.currentZone = res;
      }
    });
   }

  ngOnInit() {
    this.pageType = this.data['type'];
    this.modalData = this.data['dialogData'];
    this.currentZone=this.data['currentZone'];
    if (this.pageType == "editBuilding") {
      this.registerForm = this.formBuilder.group({
          id: [this.data["dialogData"]["buildingId"]],
          buildingName: [this.data["dialogData"]["buildingName"], [Validators.required]]
      });
  }
 


  if (this.pageType == "editFloors") {
    console.log(this.data['dialogData']);
    this.editFloorForm = this.formBuilder.group({
        id: [this.data["dialogData"]["id"]],
        floorName: [this.data["dialogData"]["name"], [Validators.required]]
    });
}

if (this.pageType == "editfloorsWithTenant") {
  console.log(this.data['dialogData']);
  this.floorNameTenant=this.data["dialogData"]['fData']["name"]
  this.tenantListOriginal=this.data["dialogData"]['tenantList'];
 this.tenantList=this.data["dialogData"]['linkedTenant']
 this.tenantList=this.cloneList(this.tenantList);
  
  
}
    if (this.pageType == "openDialogdata") {
      this.msg = this.data['dialogData'];
    }
  
  
}
  closePopup(type){
    this.dialogRef.close(type);
  }
  get f() { return this.registerForm.controls; }
 
  onUpdateBuilding = () => {
    this.submitted = true;  
    if(this.registerForm.get('buildingName').value.trim()==""){
      this.registerForm.get('buildingName').reset(); 
    } 
    if(this.registerForm.invalid) {       
      return false;
    }
    else{
    let data = {
        id: this.registerForm.value["id"],
        name: this.registerForm.value["buildingName"]
    }
    this.digitalBuildingsService.UpdateBuilding(
          this.currentZone,data
    ).subscribe((res) => {
      this.toasterService.success(this.translate.instant("buildingNameUpdatedSuccessfully") );
            this.closePopup(this.pageType);
        });
}
  }

  onUpdateFloors = () => {
    this.submitted = true;
    if(this.editFloorForm.get('floorName').value.trim()==""){
      this.editFloorForm.get('floorName').reset(); 
    } 
    if (this.editFloorForm.invalid) {
        return false;
    }
    else{
    let data = {
        id: this.editFloorForm.value["id"],
        name: this.editFloorForm.value["floorName"]
    }
    this.digitalBuildingsService.UpdateBuilding(
          this.currentZone,data
    ).subscribe((res) => {
      this.toasterService.success(this.translate.instant("floorNameUpdatedSuccessfully"));
            this.closePopup('editBuilding');
        });
}
  }

  editFloorTenantSubmit(){
   


   
    if (this.floorNameTenant.trim()=='') {
        return false;
    }
    else{
    let data = {
        id: this.data["dialogData"]['fData']["id"],
        name: this.floorNameTenant
    }
   const $save= this.digitalBuildingsService.UpdateBuilding(
          this.currentZone,data
    )
        this.loaderService.showLoaderUntilCompleted($save).subscribe((res) => {
          this.toasterService.success(this.translate.instant("floorNameUpdatedSuccessfully"));
                this.closePopup('editBuilding');
            });
}

  }
  cloneList(list) {
    return _.cloneDeep(list);
  }
  updateTenantList(){
    if( !this.tenantId){
return;
    }
    let tenantExists = false;

   this.tenantList.find((e)=>{
      if(e.tenantId==this.tenantId)tenantExists=true;
    })
    if(tenantExists){
      this.tenantId=null;
      this.toasterService.error('Tenant already added')
      return ;
    }
    

const obj=this.tenantListOriginal.find((e)=>{
  if(e.tenantId==this.tenantId)return e;
})

const $addTenant=this.digitalBuildingsService.addTenantWithFloor(this.currentZone,this.data["dialogData"]['fData']['id'],this.tenantId);
this.loaderService.showLoaderUntilCompleted($addTenant).subscribe((e)=>{
  this.tenantList=this.cloneList(this.tenantList)
this.tenantList.push(obj);
this.tenantList=this.cloneList(this.tenantList);


    this.tenantId=null;
})


  }
  removeTenant(i){
    const $delTenant=this.digitalBuildingsService.deleteTenantWithFloor(this.currentZone,this.data["dialogData"]['fData']['id'],this.tenantList[i].tenantId);
    this.loaderService.showLoaderUntilCompleted($delTenant).subscribe((e)=>{
      this.tenantList=this.cloneList(this.tenantList);
      this.tenantList=this.tenantList.filter((e)=>e.tenantId!=this.tenantList[i].tenantId)
      this.tenantList=this.cloneList(this.tenantList);
    })
 
  }
  
}
