import * as tslib_1 from "tslib";
import { HttpHeaders } from "@angular/common/http";
import { Observable, Subject, throwError, BehaviorSubject, of, EMPTY, } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { tap, shareReplay, map, catchError, switchMap, } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { v4 as uuidv4 } from "uuid";
export class AmenityBookingService {
    constructor(http, baseUrl, moduleidService, loaderService, digitalService, toastr) {
        this.http = http;
        this.moduleidService = moduleidService;
        this.loaderService = loaderService;
        this.digitalService = digitalService;
        this.toastr = toastr;
        this.digitalVersion = false;
        this.rosterData = new BehaviorSubject([]);
        this.rosterDataObj = this.rosterData.asObservable();
        this.rosterDataCalendar = new BehaviorSubject([]);
        this.rosterDataObj1 = this.rosterDataCalendar.asObservable();
        this.bookingData = new BehaviorSubject([]);
        this.showCalender = new BehaviorSubject(false);
        this.currentViewSubject$ = new BehaviorSubject("bookingList");
        this.currentView$ = this.currentViewSubject$.asObservable();
        this.data = new BehaviorSubject([]);
        this.currentData = this.data.asObservable();
        this.floorMapData = new BehaviorSubject(null);
        this.floorMapViewDataObj = this.floorMapData.asObservable();
        this.maplist = new BehaviorSubject([]);
        this.mapData = this.maplist.asObservable();
        this.serachTsring = new BehaviorSubject([]);
        this.searchValData = this.serachTsring.asObservable();
        this.mapviewInflexiPermanent = new BehaviorSubject(false);
        this.mapviewInCalflexiPermanentCall = this.mapviewInflexiPermanent.asObservable();
        this.filter = new BehaviorSubject([]);
        this.filterData = this.filter.asObservable();
        this.floor = new BehaviorSubject([]);
        this.floorData = this.floor.asObservable();
        this.dateSelectlist = new BehaviorSubject([]);
        this.dateValues = this.dateSelectlist.asObservable();
        this.timeList = new BehaviorSubject([]);
        this.TimingsData = this.timeList.asObservable();
        this.availableDesksObj = new BehaviorSubject([]);
        this.availableDesksData = this.availableDesksObj.asObservable();
        this.todayDate = new BehaviorSubject([]);
        this.getTodayDate = this.todayDate.asObservable();
        this.userIdVal = new BehaviorSubject([]);
        this.getOtherUserId = this.userIdVal.asObservable();
        this.visitorInfoVal = new BehaviorSubject([]);
        this.getVisitorUserId = this.visitorInfoVal.asObservable();
        this.visitorInfoFlags = new BehaviorSubject([]);
        this.getVisitorInfoFlags = this.visitorInfoFlags.asObservable();
        this.visitorDepartment = new BehaviorSubject([]);
        this.getVisitorDepartment = this.visitorDepartment.asObservable();
        this.participantsUserid = new BehaviorSubject([]);
        this.getparticipantsUserid = this.participantsUserid.asObservable();
        this.checkRosterOfUser = new BehaviorSubject([]);
        this.getRosterUserId = this.checkRosterOfUser.asObservable();
        this.dateSource = new BehaviorSubject(new Date());
        this.currentSelectedDate = this.dateSource.asObservable();
        this.messageSourceAddons = new BehaviorSubject("");
        this.currentMessageAddons = this.messageSourceAddons.asObservable();
        this.messageSourceSpecial = new BehaviorSubject("");
        this.currentMessageSpecial = this.messageSourceSpecial.asObservable();
        this.messageSourceCat = new BehaviorSubject("");
        this.currentMessageCat = this.messageSourceCat.asObservable();
        this.messageSourceCurrentAmenityId = new BehaviorSubject("");
        this.currentmessageSourceCurrentAmenityId = this.messageSourceCurrentAmenityId.asObservable();
        this.messageSourceApproach = new BehaviorSubject("");
        this.currentMessageApproach = this.messageSourceApproach.asObservable();
        this.startEndTime = undefined;
        this.buildingSubject$ = new BehaviorSubject([]);
        this.buildings$ = this.buildingSubject$.asObservable();
        this.floorSubject$ = new BehaviorSubject([]);
        this.floors$ = this.floorSubject$.asObservable();
        this.departmentSubject$ = new BehaviorSubject([]);
        this.departments$ = this.departmentSubject$.asObservable();
        this.userSubject$ = new BehaviorSubject([]);
        this.users$ = this.userSubject$.asObservable();
        this.manageBookingViewmode$ = new BehaviorSubject({
            view: "showTable",
            data: null,
        });
        this.manageBookingMode$ = this.manageBookingViewmode$.asObservable();
        this.isOutlookOrNot = new BehaviorSubject("");
        this.isOutlook = this.isOutlookOrNot.asObservable();
        this.messageSourceValidateIntegration = new BehaviorSubject(false);
        this.currentMessageValidateIntegration = this.messageSourceValidateIntegration.asObservable();
        this.sub$ = new Subject();
        this.action$ = this.sub$.asObservable();
        this.globalAdmin = new BehaviorSubject(false);
        this.globalAdminObj = this.globalAdmin.asObservable();
        this.replaceNewFloorPlanNavination = "onboarding-table"; // 'onboard'
        this.editFloorData = new Subject();
        this.showReplaceFloorPlanVar = false;
        this.bookAvailableDesks = false;
        this.backToManageAmenityPage = new Subject();
        this.editOnboarding = new Subject();
        this.analytics$ = this.action$.pipe(tap(() => this.loaderService.loadingOn()), switchMap((res) => this.http.get(this.url, {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    this.currentZone +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    this.gecId +
                    '"}',
            }),
        })), map((res) => res["response"]), tap(() => this.loaderService.loadingOff()), catchError((err) => {
            this.loaderService.loadingOff();
            return EMPTY;
        }));
        this.leftmoveanalytics$ = this.action$.pipe(tap(() => this.loaderService.loadingOn()), switchMap((res) => this.http.post(this.url, this.leftData, {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    this.currentZone +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    this.gecId +
                    '"}',
            }),
        })), map((res) => res["response"]), tap(() => this.loaderService.loadingOff()), catchError((err) => {
            this.loaderService.loadingOff();
            return EMPTY;
        }));
        this.rightmoveanalytics$ = this.action$.pipe(tap(() => this.loaderService.loadingOn()), switchMap((res) => this.http.post(this.url, this.rightData, {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    this.currentZone +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    this.gecId +
                    '"}',
            }),
        })), map((res) => res["response"]), tap(() => this.loaderService.loadingOff()), catchError((err) => {
            this.loaderService.loadingOff();
            return EMPTY;
        }));
        this._refreshNeeded = new Subject();
        this.globalAmenityInfo = [];
        this.BASEURL = environment.BASE_URL + "amenitybooking";
        //this.BASEURL = environment.BASE_URL + 'spacemanagement';
        //this.BASEURL = environment.BASE_URL + 'amenitybooking';
        //this.BASEURL = "https://amenitybookingdev.smartenspaces.com/" + 'spacemanagement';
        //alert(baseUrl);
        //this.BASEURL = 'http://mt.smartenspaces.com/spacemanagement';
        this.EMSURL = environment.BASE_URL + "ems/";
        //this.EMSURL = "https://amenitybookingdev.smartenspaces.com/" + "ems/";
        this.BASEEMSURL = environment.BASE_URL;
        this.SPACEURL = environment.BASE_URL + "amenitybooking/";
        this.spaceMgtUrl = environment.BASE_URL + "spacemanagement";
        this.WFHURL = environment.BASE_URL_WFH;
        this.upload_Url = environment.BASE_UPLOAD_IMG_URL;
        this.moduleId = moduleidService.getModueId();
        this.BASE_URL_WFH = environment.BASE_URL_WFH;
        if (this.localVal == "" || this.localVal == undefined) {
            this.localVal = "en";
        }
        this.digitalService.versionCheck.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (res) {
                this.digitalVersion = res;
            }
        }));
    }
    setOutlookOrNot(b) {
        this.isOutlookOrNot.next(b);
    }
    changeMessageAddons(message) {
        this.messageSourceAddons.next(message);
    }
    changeMessageSpecial(message) {
        this.messageSourceSpecial.next(message);
    }
    changeMessageCat(message) {
        this.messageSourceCat.next(message);
    }
    changeMessageApproach(message) {
        this.messageSourceApproach.next(message);
    }
    changeValidateIntegration(message) {
        this.messageSourceValidateIntegration.next(message);
    }
    changeMessageAmenityId(message) {
        this.messageSourceCurrentAmenityId.next(message);
    }
    updateGlobalAdmin(message) {
        this.globalAdmin.next(message);
    }
    get refreshNeeded() {
        return this._refreshNeeded;
    }
    bookingCalender(data) {
        this.bookingData.next(data);
    }
    currentViewSubject(data) {
        this.currentViewSubject$.next(data);
    }
    showBookingCalender(data) {
        this.showCalender.next(data);
    }
    setGlobalData(data) {
        this.data.next(data);
    }
    setMapViewData(maplist) {
        this.maplist.next(maplist);
    }
    setSerachTsring(serachTsring) {
        this.serachTsring.next(serachTsring);
    }
    setMapviewInCalflexiPermanent(mapviewInflexiPermanent) {
        this.mapviewInflexiPermanent.next(mapviewInflexiPermanent);
    }
    setFilterAppliedData(filter) {
        this.filter.next(filter);
    }
    setFloor(floor) {
        this.floor.next(floor);
    }
    bookingRoster(rosterData) {
        this.rosterData.next(rosterData);
    }
    bookingRosterCalendar(rosterDataCalendar) {
        this.rosterDataCalendar.next(rosterDataCalendar);
    }
    setTimingsData(timeList) {
        this.timeList.next(timeList);
    }
    setSelectedDates(dateSelectlist) {
        this.dateSelectlist.next(dateSelectlist);
    }
    setTodayDataArr(todayDate) {
        this.todayDate.next(todayDate);
    }
    setonBehalfofUser(userIdVal) {
        this.userIdVal.next(userIdVal);
    }
    setonBehalfofVisitors(visitorInfoVal) {
        this.visitorInfoVal.next(visitorInfoVal);
    }
    setVisitorInfoFlags(visitorInfoFlags) {
        this.visitorInfoFlags.next(visitorInfoFlags);
    }
    setVisitorDepartment(visitorDepartment) {
        this.visitorDepartment.next(visitorDepartment);
    }
    setonParticipants(participantsUserid) {
        this.participantsUserid.next(participantsUserid);
    }
    setonInfoForUserRoster(userInfo) {
        this.checkRosterOfUser.next(userInfo);
    }
    changeDate(date) {
        this.dateSource.next(date);
    }
    floorMapViewData(buildingFloorData) {
        this.floorMapData.next(buildingFloorData);
    }
    getmyAmenityObj() {
        return this.globalAmenityInfo;
    }
    setMyAmenityObj(value) {
        this.globalAmenityInfo = [value];
    }
    setSelectedStartEndTime(val) {
        this.startEndTime = val;
    }
    getSelectedStartEndTime() {
        return this.startEndTime;
    }
    set setReplaceFloorPlan(floor) {
        this.uploadedReplaceFloorPlan = floor;
    }
    get replaceFloorPlan() {
        return this.uploadedReplaceFloorPlan;
    }
    set setNewFloorPlaneReplaceNavigation(val) {
        this.replaceNewFloorPlanNavination = val;
    }
    get navigateNewFloorPlane() {
        return this.replaceNewFloorPlanNavination;
    }
    setEditFloorData(data) {
        this.editFloorData.next(data);
    }
    getEditFloorData() {
        return this.editFloorData;
    }
    set showReplacePlanPage(val) {
        this.showReplaceFloorPlanVar = val;
    }
    get showReplacePlanPage() {
        return this.showReplaceFloorPlanVar;
    }
    set setNewOnboardingMapData(val) {
        this.newOnboardingMapData = val;
    }
    get getNewOnboardingMapData() {
        return this.newOnboardingMapData;
    }
    set setBookAvailableDesks(val) {
        this.bookAvailableDesks = val;
    }
    get getBookAvailableDesks() {
        return this.bookAvailableDesks;
    }
    availableDesks(obj) {
        this.availableDesksObj.next(obj);
    }
    getAllAmenitiesList(zoneId, pageIndex, pageSize) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' + this.localVal +
                    '"}',
            }),
        };
        let url = "";
        if (this.digitalVersion) {
            //Super Admin check required for backward compability
            url = this.BASEURL + `/entityCategory/super-admin/get?page=`;
        }
        else {
            //Super Admin check not required for backward compability
            url = this.BASEURL + `/entityCategory/get?page=`;
        }
        url = url + pageIndex + `&size=` + 500;
        return this.http.get(url, httpOptions);
    }
    getAllAmenitiesListGU(zoneId, pageIndex, pageSize) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/entityCategory/get?page=` +
            pageIndex +
            `&size=` +
            500;
        return this.http.get(url, httpOptions);
    }
    addAmenity(zoneId, amenity) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' + this.localVal + '"}',
            }),
        };
        let url = this.BASEURL + `/entityCategory/create`;
        return this.http.post(url, amenity, httpOptions);
    }
    addAmenitySuperAdmin(zoneId, amenity) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' +
                    localStorage.getItem("language") +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entityCategory/create`;
        return this.http.post(url, amenity, httpOptions);
    }
    addUpdateEntity(zoneId, entity, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' + this.localVal +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entity/create/v3`;
        return this.http.post(url, entity, httpOptions);
    }
    multipleAddUpdateEntities(zoneId, entityList, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entity/createList/v3`;
        return this.http.post(url, entityList, httpOptions);
    }
    addUpdateEntityBulk(zoneId, entityList, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId + '","locale":"' + this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entity/create/bulk/v2`;
        return this.http.post(url, entityList, httpOptions);
    }
    validatePermanentBulkTemplate(zoneId, gecId, fileToUpload) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/allocation/validateCSV/V2`;
        const formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        return this.http.post(url, formData, httpOptions);
    }
    uploadPermanentBulkTemplate(zoneId, gecId, fileToUpload) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/allocation/uploadCSV/V2`;
        const formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        return this.http.post(url, formData, httpOptions);
    }
    addCategory(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entitytype/create/all`;
        return this.http.post(url, data, httpOptions);
    }
    addGlobalCategory(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entitytype/super-admin/upsert/all`;
        return this.http.post(url, data, httpOptions);
    }
    addGlobalAddonsAndSpecialRequests(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/amenity/super-admin/upsert/all`;
        return this.http.post(url, data, httpOptions);
    }
    addAddons(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/amenity/create`;
        return this.http.post(url, data, httpOptions);
    }
    onboardEntities(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/onboarding/onboardEntities`;
        return this.http.post(url, data, httpOptions);
    }
    updateOnboardEntities(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/onboarding/updateCoordinates`;
        return this.http.post(url, data, httpOptions);
    }
    getAllCategory(zoneId, pageIndex, pageSize, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/entityCategory/get?page=` +
            pageIndex +
            `&size=` +
            500;
        return this.http.get(url, httpOptions);
    }
    getAllCapacity(zoneId, entityId, floorId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entity/get/capacity?floorId=` + floorId;
        return this.http.get(url, httpOptions);
    }
    getAllAddons(zoneId, categoryId, floorId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    categoryId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/amenity/get/globalEntityCategory?globalEntityCategroyId=` +
            categoryId;
        if (typeof floorId != "undefined" && floorId != null) {
            url += "&floorId=" + floorId;
        }
        return this.http.get(url, httpOptions);
    }
    getEntityTypesByGecId(zoneId, categoryId, floorId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' + this.localVal +
                    '","gecId":' +
                    categoryId +
                    "}",
            }),
        };
        let url = this.BASEURL +
            `/entitytype/get/globalEntityCategory?globalEntityCategoryId=` +
            categoryId;
        if (typeof floorId != "undefined" && floorId != null) {
            url += "&floorId=" + floorId;
        }
        return this.http.get(url, httpOptions);
    }
    getAllBookingsV2(zoneId, pageIndex, pageSize, searchString, type, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        //let url = `${this.BASEURL}spacemanagement/settings/get`;
        //return this.http.get(url, httpOptions);
        let url = this.BASEURL +
            `/booking/getV3?page=` +
            pageIndex +
            `&size=` +
            pageSize +
            `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING,` +
            searchString +
            ",demandType:" +
            type +
            `&globalEntityId=` +
            entityId;
        return this.http.get(url, httpOptions);
    }
    getAllBookingsV3(zoneId, pageIndex, pageSize, searchString, type, entityId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let searchVal = "";
        if (data.searchString) {
            searchVal = "," + data.searchString;
        }
        let url = "";
        if (data.organiserUserId) {
            url =
                this.BASEURL +
                    `/booking/getV3?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `&globalEntityId=` +
                    entityId +
                    `&userId=` +
                    data.organiserUserId;
        }
        else if (data.amenityUserId) {
            url =
                this.BASEURL +
                    `/booking/getV3?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `,entityName:` +
                    data.amenityUserId +
                    `&globalEntityId=` +
                    entityId;
        }
        else if (data.buildingUserId || data.floorId) {
            url =
                this.BASEURL +
                    `/booking/getV3?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    ",buildingId:" +
                    data.buildingUserId +
                    `,floorId:` +
                    data.floorId +
                    `&globalEntityId=` +
                    entityId;
        }
        else {
            url =
                this.BASEURL +
                    `/booking/getV3?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    searchVal +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `&globalEntityId=` +
                    entityId;
        }
        //let url = this.BASEURL + `/booking/getV3?page=` + pageIndex + `&size=` + pageSize + `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING,` + searchString + ',demandType:' + type + `&globalEntityId=` + entityId;
        return this.http.get(url, httpOptions);
    }
    getAmenities(zoneId, pageIndex, pageSize, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '"}',
            }),
        };
        let url = this.BASEURL + "/entity/getAll";
        return this.http.get(url, httpOptions);
    }
    getAmenitiesV2(zoneId, pageIndex, pageSize, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '"}',
            }),
        };
        let url = this.BASEURL + "/entity/getAll/v2";
        return this.http.get(url, httpOptions);
    }
    approveRejectOutlookBooking(roomEmail, eventId, status, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/booking/acceptReject?userEventId=${eventId}&email=${roomEmail}`;
        return this.http.post(url, { status: status }, httpOptions);
    }
    getMyBookingsV2(zoneId, pageIndex, pageSize, searchString, type, userId, entityId, externalBooking) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let searchVal = "";
        if (searchString) {
            searchVal = "," + searchString;
        }
        //let url = `${this.BASEURL}spacemanagement/settings/get`;
        //return this.http.get(url, httpOptions);
        // let subUrl = externalBooking?'getV3':'getV2';
        let subUrl = "getV3";
        let url = this.BASEURL +
            `/booking/${subUrl}?page=${pageIndex}&size=` +
            pageSize +
            `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
            searchVal +
            ",demandType:" +
            type +
            `&globalEntityId=` +
            entityId +
            `&userId=` +
            userId;
        return this.http.get(url, httpOptions);
    }
    getMyBookingsV2Search(zoneId, pageIndex, pageSize, searchString, type, userId, entityId, organiserUserId, amenityUserId, buildingUserId, floorId, externalBooking) {
        console.log("sm_url", externalBooking);
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        //let url = `${this.BASEURL}spacemanagement/settings/get`;
        //return this.http.get(url, httpOptions);
        // let subUrl = externalBooking?'getV3':'getV2';
        let subUrl = "getV3";
        let url = "";
        let searchVal = "";
        if (searchString) {
            searchVal = "," + searchString;
        }
        if (organiserUserId) {
            // console.log('organiser')
            if (externalBooking) {
                url =
                    this.BASEURL +
                        `/booking/${subUrl}?page=` +
                        pageIndex +
                        `&size=` +
                        pageSize +
                        `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                        "" +
                        ",demandType:" +
                        type +
                        `&globalEntityId=` +
                        entityId +
                        `&userId=` +
                        userId +
                        `&organiserUserId=` +
                        organiserUserId;
            }
            else {
                url =
                    this.BASEURL +
                        `/booking/${subUrl}?page=` +
                        pageIndex +
                        `&size=` +
                        pageSize +
                        `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                        "" +
                        ",demandType:" +
                        type +
                        `&globalEntityId=` +
                        entityId +
                        `&userId=` +
                        organiserUserId;
            }
            // + `&globalEntityId=` + entityId + `&userId=` + userId + `&organiserId=` + organiserUserId;
        }
        else if (amenityUserId) {
            // console.log('amenity')
            url =
                this.BASEURL +
                    `/booking/${subUrl}?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    type +
                    `,entityName:` +
                    amenityUserId +
                    `&globalEntityId=` +
                    entityId +
                    `&userId=` +
                    userId;
        }
        else if (buildingUserId || floorId) {
            // console.log('location')
            url =
                this.BASEURL +
                    `/booking/${subUrl}?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    type +
                    ",buildingId:" +
                    buildingUserId +
                    `,floorId:` +
                    floorId +
                    `&globalEntityId=` +
                    entityId +
                    `&userId=` +
                    userId;
        }
        else {
            url =
                this.BASEURL +
                    `/booking/${subUrl}?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    searchVal +
                    ",demandType:" +
                    type +
                    `&globalEntityId=` +
                    entityId +
                    `&userId=` +
                    userId;
        }
        return this.http.get(url, httpOptions);
    }
    deleteAmenityBooking(zoneId, data, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/booking/cancel`;
        return this.http.put(url, data, httpOptions);
    }
    getAllManageBookingsV2(zoneId, pageIndex, pageSize, searchString, type, entityId) {
        let searchVal = "";
        if (searchString) {
            searchVal = "," + searchString;
        }
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/booking/getV3?page=` +
            pageIndex +
            `&size=` +
            pageSize +
            `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
            searchVal +
            ",demandType:" +
            type +
            `&globalEntityId=` +
            entityId;
        return this.http.get(url, httpOptions);
    }
    getAllManageBookingsV2OnSearch(zoneId, pageIndex, pageSize, searchString, type, entityId, organiserUserId, amenityUserId, buildingUserId, floorId, enableParticipants, dateSelected) {
        let searchVal = "";
        let startTime = "";
        if (searchString) {
            searchVal = "," + searchString;
        }
        if (dateSelected) {
            startTime = ",startTime:" + dateSelected;
        }
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = "";
        if (organiserUserId) {
            // console.log('manage organiser')
            if (enableParticipants) {
                url =
                    this.BASEURL +
                        `/booking/getV3?page=` +
                        pageIndex +
                        `&size=` +
                        pageSize +
                        `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                        "" +
                        startTime +
                        ",demandType:" +
                        type +
                        `&globalEntityId=` +
                        entityId +
                        `&organiserUserId=` +
                        organiserUserId;
            }
            else {
                url =
                    this.BASEURL +
                        `/booking/getV3?page=` +
                        pageIndex +
                        `&size=` +
                        pageSize +
                        `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                        "" +
                        startTime +
                        ",demandType:" +
                        type +
                        `&globalEntityId=` +
                        entityId +
                        `&userId=` +
                        organiserUserId;
            }
        }
        else if (amenityUserId) {
            // console.log('manage amenity')
            url =
                this.BASEURL +
                    `/booking/getV3?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" + startTime +
                    ",demandType:" +
                    type +
                    `,entityName:` +
                    amenityUserId +
                    `&globalEntityId=` +
                    entityId;
        }
        else if (buildingUserId || floorId) {
            // console.log('manage location')
            url =
                this.BASEURL +
                    `/booking/getV3?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" + startTime +
                    ",demandType:" +
                    type +
                    ",buildingId:" +
                    buildingUserId +
                    `,floorId:` +
                    floorId +
                    `&globalEntityId=` +
                    entityId;
        }
        else {
            url =
                this.BASEURL +
                    `/booking/getV3?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    searchVal + startTime +
                    ",demandType:" +
                    type +
                    `&globalEntityId=` +
                    entityId;
        }
        // let url = this.BASEURL + `/booking/getV3?page=` + pageIndex + `&size=` + pageSize + `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING,` + searchString + ',demandType:' + type + `&globalEntityId=` + entityId;
        return this.http.get(url, httpOptions);
    }
    downloadBookingsV2(zoneId, pageIndex, pageSize, userId, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":53,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"10"}',
            }),
        };
        let url = this.BASEURL +
            `/booking/downloadCSV?page=` +
            pageIndex +
            `&size=` +
            pageSize +
            `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING&globalEntityId=` +
            entityId +
            "&userId=" +
            userId;
        return this.http.get(url, httpOptions);
    }
    downloadManageBookingsV2(zoneId, pageIndex, pageSize, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":53,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    entityId +
                    "}",
            }),
        };
        let url = this.BASEURL +
            `/booking/downloadCSV?page=` +
            pageIndex +
            `&size=` +
            pageSize +
            `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING&globalEntityId=` +
            entityId;
        return this.http.get(url, httpOptions);
    }
    getConfigSettings(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/sm-settings/get/amenitybooking`;
        return this.http.get(url, httpOptions);
    }
    updateConfigSettings(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url;
        url = this.BASEURL + `/sm-settings/save/amenitybooking`;
        return this.http.post(url, data, httpOptions);
    }
    getGridView(data, zoneId, gecId, page, size = 10) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url;
        url = this.BASEURL + `/entity/grid-view?page=` + page + `&size=${size}`;
        return this.http.post(url, data, httpOptions);
    }
    getRecomandations(data, zoneId, gecId, page) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url;
        url = this.BASEURL + `/entity/participant-view`;
        return this.http.post(url, data, httpOptions);
    }
    getBlockedDates(zoneId, startDate, EndDate, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/blockedDays/getBlockedDaysWithinDateRange?startDate=` +
            startDate +
            `&endDate=` +
            EndDate +
            `&gecId=` +
            gecId;
        return this.http.get(url, httpOptions);
    }
    testIntegrationData(data, zoneId) {
        const endpoint = this.BASEURL + "/booking/integrationTest";
        return this.http
            .post(endpoint, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(shareReplay());
    }
    disableAmenity(zoneId, amenityId) {
        let url = this.BASEURL + `/entityCategory/softUpdate`;
        return this.http.put(url, amenityId, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    enableAmenity(zoneId, amenityObj) {
        let url = this.BASEURL + `/entityCategory/create`;
        const formData = new FormData();
        formData.append("model", JSON.stringify(amenityObj));
        return this.http.post(url, formData, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    onboardEntity(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entity/create/v2`;
        return this.http.post(url, data, httpOptions);
    }
    getAllAvailableSeatAndBookedSeatsAmenity(data, zoneId, userId, gecId) {
        let url;
        if (!!userId) {
            url =
                this.BASE_URL_WFH +
                    `calendar/getAllStatusAndAvailableAndBookedSeats?startTime=${data.firstDate}&endTime=${data.lastDate}&collegueUserId=${userId}`
                    + `&recurringStartTime=${data.recurringStart}&recurringEndTime=${data.recurringEnd}`;
        }
        else {
            url =
                this.BASE_URL_WFH +
                    `calendar/getAllStatusAndAvailableAndBookedSeats?startTime=${data.firstDate}&endTime=${data.lastDate}`
                    + `&recurringStartTime=${data.recurringStart}&recurringEndTime=${data.recurringEnd}`;
        }
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        return this.http
            .get(url, httpOptions)
            .pipe(catchError(() => of(null)));
    }
    getEmptyResponse() {
        return of({ response: [] });
    }
    getSeatAvailablity(zoneId, data, globalEntityCategroyId, entityId) {
        let url;
        if (!!entityId) {
            url =
                this.SPACEURL +
                    `entity/findAvailableCountsForEachDayInGivenRangeWithOptionalFilter?globalEntityId=` +
                    globalEntityCategroyId +
                    `&entityTypeIds=` +
                    entityId;
        }
        else {
            url =
                this.SPACEURL +
                    `entity/findAvailableCountsForEachDayInGivenRangeWithOptionalFilter?globalEntityId=` +
                    globalEntityCategroyId;
        }
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    globalEntityCategroyId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        return this.http
            .post(url, data, httpOptions)
            .pipe(catchError(() => of(null)));
    }
    getEntityByGecId(zoneId, gecId, page, size) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/entity/get/gecId?gecId=` +
            gecId +
            "&page=" +
            page +
            "&size=" +
            size;
        return this.http.get(url, httpOptions);
    }
    getOnboardingEntityByGecIdAndFloorID(zoneId, gecId, floorId, page, size) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/entity/get/gecId/v2?gecId=` +
            gecId +
            "&floorId=" +
            floorId +
            "&page=" +
            page +
            "&size=" +
            size +
            "&sort=displayName&direction=asc";
        return this.http.get(url, httpOptions);
    }
    getOnboardingEntityByGecId(zoneId, gecId, page, size, isCounts) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/entity/get/gecId/v2?gecId=` +
            gecId +
            "&page=" +
            page +
            "&size=" +
            size;
        if (isCounts)
            url += `&isCounts=true`;
        return this.http.get(url, httpOptions);
    }
    getEntityByGecIdFloorId(zoneId, gecId, page, size, floorId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/entity/get/gecId?gecId=` +
            gecId +
            "&page=" +
            page +
            "&size=" +
            size +
            "&floorId=" +
            floorId;
        return this.http.get(url, httpOptions);
    }
    getEntityByGecIdByFloorId(zoneId, gecId, page, size, floorId, status) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/entity/get/gecId?gecId=` +
            gecId +
            "&page=" +
            page +
            "&size=" +
            size +
            "&floorId=" +
            floorId +
            "&getAll=" +
            status;
        return this.http.get(url, httpOptions);
    }
    downloadFloor(zoneId) {
        let url;
        url = this.SPACEURL + `templates/download/key/AMENITY_BOOKING`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    downloadBulkUploadTemplate(zoneId) {
        let url;
        url = this.SPACEURL + `templates/download/key/PERMANENT_ALLOCATION`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    getTimeZoneData(zoneId) {
        let url;
        url = this.EMSURL + `zones/getZone?zoneId=` + zoneId;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    postAmenities(zoneId, fileToUpload, floorId, entityId) {
        let url;
        url = this.SPACEURL + `onboarding/onboardEntitiesCSV`;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":1,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '"}',
            }),
        };
        const formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        formData.append("floorId", floorId);
        return this.http.post(url, formData, httpOptions);
    }
    getAllEntities(zoneId, pageIndex, pageSize, data, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '"}',
            }),
        };
        let url;
        url =
            this.BASEURL +
                `/entity/get/decorated?page=${pageIndex}&size=` +
                pageSize;
        return this.http.post(url, data, httpOptions);
    }
    getAllBuildings(zoneId, pageIndex, pageSize) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '"}',
            }),
        };
        let url;
        if (localStorage.getItem('tenant_Engagement') == "true") {
            url =
                this.BASEEMSURL +
                    `amenitybooking/entityCategory/getAllZoneLocationsWithParentGrouppingV3/tenant?level=FLOOR&size=100000&page=0`;
        }
        else
            url =
                this.BASEEMSURL +
                    `amenitybooking/entityCategory/getAllZoneLocationsWithParentGrouppingV3?level=FLOOR&size=100000&page=0`;
        return this.http.get(url, httpOptions);
    }
    getAllBuildingsData(zoneId, pageIndex, pageSize, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url;
        if (localStorage.getItem('tenant_Engagement') == "true") {
            url =
                this.BASEEMSURL +
                    `amenitybooking/entityCategory/getAllZoneLocationsWithParentGrouppingV3/tenant?level=FLOOR&size=100000&page=0`;
        }
        else
            url =
                this.BASEEMSURL +
                    `amenitybooking/entityCategory/getAllZoneLocationsWithParentGrouppingV3?level=FLOOR&size=100000&page=0`;
        return this.http.get(url, httpOptions);
    }
    setDefaultLocation(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url;
        url = this.BASEURL + `/userdefaultzone/updateDefaultFloorMapping`;
        return this.http.post(url, data, httpOptions);
    }
    searchAllEntity(zoneId, entityName, entityId, floorId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url;
        url =
            this.BASEURL +
                `/entity/search/name?displayName=${entityName}&floorIds=${floorId}`;
        return this.http.get(url, httpOptions);
    }
    getAvailabilityMapDataBooking(reqObj, currentZone, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    currentZone +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url;
        if (localStorage.getItem('tenant_Engagement') == "true") {
            url = this.SPACEURL + "entity/availabilityMapViewForListOfDaysOptimized/tenant";
        }
        else
            url = this.SPACEURL + "entity/availabilityMapViewForListOfDaysOptimized";
        return this.http.post(url, reqObj, httpOptions).pipe(map((res) => res["response"]), catchError((err) => throwError(err)));
    }
    getFloorDetailsByFloorId(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.SPACEURL + `onboarding/floorOnboarding?zoneId=${data.zoneId}`;
        return this.http.get(url, httpOptions);
    }
    deskBookingCheckIn(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/booking/validateAndCheckIn`;
        return this.http.post(url, data, httpOptions);
    }
    getManualBookings(zoneId, reqObj, gecId) {
        // console.log(reqObj);
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.SPACEURL + "entity/findAvailableCounts";
        return this.http.post(url, reqObj, httpOptions).pipe(map((res) => res["response"]), catchError((err) => throwError(err)));
    }
    getRecommendations(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.SPACEURL + "entity/recommendation/singleUser";
        return this.http.post(url, data, httpOptions).pipe();
    }
    deskBookingCheckOut(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/booking/cancel`;
        return this.http.put(url, data, httpOptions).pipe(map((res) => res["response"]), catchError((err) => throwError(err)));
    }
    saveAmenityMultiBooking(reqObj, zoneId, gecId, userId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = "";
        if (!userId) {
            url =
                this.SPACEURL +
                    `booking/create/wfh/v3?isRosterUpdation=true`;
        }
        else {
            url =
                this.SPACEURL +
                    `booking/create/wfh/v3?isRosterUpdation=true&userId=${userId}`;
        }
        return this.http
            .post(url, reqObj, httpOptions)
            .pipe(catchError((err) => throwError(err)));
    }
    uploadFile(file, data, zoneId, isDarkMode = false) {
        let url = `${this.upload_Url}webconversion/generateTilesUpdated`;
        if (!!isDarkMode) {
            url = `${this.upload_Url}webconversion/generateTilesUpdatedDark`;
        }
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("floorDetails", JSON.stringify(data));
        return this.http.post(url, formData).pipe(
        // timeout(500000),
        catchError((err) => {
            let errorMsgData = err.error;
            const message = errorMsgData["message"];
            this.toastr.error(message);
            return throwError(err);
        }));
    }
    uploadWayFinding(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url;
        url = this.BASEURL + `/onboarding/uploadWayFinding`;
        return this.http.post(url, data, httpOptions);
    }
    edituploadWayFinding(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url;
        url = this.BASEURL + `/floordetailsgecmapping/getById?floorId=` + data;
        return this.http.get(url, httpOptions);
    }
    getSettingsAvailability(zoneId) {
        let url = this.BASEURL + `/sm-settings/getSettingsIfPresent`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    availableAllEntitiesInFloor(reqObj, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = `${this.BASEURL}/entity/allEntitiesInArea`;
        return this.http.post(url, reqObj, httpOptions).pipe(map((res) => res["response"]), catchError((err) => throwError(err)));
    }
    getAllFloorDetails(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let searchParams = data.searchArr;
        if (searchParams) {
            let status = "states=PARTIAL,COMPLETE";
            let leaseStart = "";
            let leaseEnd = "";
            let buildingIds = "";
            if (searchParams.status != "") {
                status = "states=" + searchParams.status;
            }
            if (searchParams.leaseStart != "") {
                leaseStart = "&leaseStart=" + searchParams.leaseStart;
            }
            if (searchParams.leaseEnd != "") {
                leaseEnd = "&leaseEnd=" + searchParams.leaseEnd;
            }
            if (searchParams.buildingIds != "") {
                buildingIds = "&buildingIds=" + searchParams.buildingIds;
            }
            searchParams = status + leaseStart + leaseEnd + buildingIds;
        }
        else {
            searchParams = "";
        }
        //const headers = new HttpHeaders().set('ss-header', '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":1,"sessionId":"2c234e44-c39f-4a0b-ba78-52992466abbd","enterpriseId":1}');
        //let url = `http://192.168.1.37:9084/spacemanagement/onboarding/floorDetails/getAll?size=${data.pageSize}&page=${data.pageIndex}`+searchParams;
        //let url = this.BASEURL + `onboarding/floorDetails?` + searchParams;
        let url = this.BASEURL + `/socialdistancing/floorDetails`;
        //return this.http.get<any>(url, { headers: headers });
        return this.http.get(url, httpOptions);
    }
    getRecentSearchList(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.BASEURL + `/recentsearch/getAll?pageNumber=0&pageSize=100`;
        return this.http.get(url, httpOptions);
    }
    updateFindColleague(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url;
        url = this.BASEURL + `/sm-settings/save/findColleague`;
        return this.http.post(url, data, httpOptions);
    }
    getQuestionResponse(zoneId, userId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.BASEURL + `/question-response/getAll?userId=${userId}`;
        return this.http
            .get(url, httpOptions)
            .pipe(map((o) => o.response.content));
    }
    addQuestionResponse(zoneId, reqObj, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' +
                    localStorage.getItem("language") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.BASEURL + `/question-response/addAnswers`;
        return this.http.post(url, reqObj, httpOptions);
    }
    setZoomLevels(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    ',"locale":"' + this.localVal + '"}',
            }),
        };
        let url;
        url = this.BASEURL + `/onboarding/updateZoomConfig`;
        return this.http.post(url, data, httpOptions);
    }
    wayFinding(zoneId, reqObj) {
        let url = this.SPACEURL + `entity/${reqObj.entityInfoId}`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(map((response) => {
            if (response["response"]["route"] &&
                response["response"]["route"].length > 0) {
                return {
                    node: response["response"]["route"],
                    pathColor: response["response"]["pathColor"],
                };
            }
            else {
                return null;
            }
        }));
    }
    getAllDepartmentList(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url;
        if (localStorage.getItem('tenant_Engagement') == "true") {
            url = this.EMSURL + `department/getAllActive`;
        }
        else {
            url = this.EMSURL + `department/getAll`;
        }
        return this.http.get(url, httpOptions);
    }
    editParticipantsUpdate(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url;
        url = this.BASEURL + `/booking/update/spacebooking`;
        return this.http.post(url, data, httpOptions);
    }
    deleteAddons(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/amenity/disable`;
        return this.http.put(url, data, httpOptions);
    }
    deleteCategory(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + "/entitytype/disable/v2?id=" + data;
        return this.http.post(url, data, httpOptions);
    }
    /**autocomplete */
    getAutocompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
            let resData = [];
            if (data.searchType === "departmentName") {
                this.departments$.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    resData = res;
                }));
                return of(resData);
            }
            else if (data.searchType === "building" ||
                data.searchType === "floor" ||
                data.searchType === "meetingroom" ||
                data.searchType === "deskNumber") {
                this.buildings$.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    resData = res;
                }));
                return of(resData);
            }
            else if (data.searchType === "user") {
                this.users$.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    resData = res;
                }));
                return of(resData);
            }
            else {
                return of([]);
            }
        }
        if (data.searchType === "user") {
            let url = `${this.EMSURL}user/search/v3?searchString=${term}&searchParams=name,email,phone_num`;
            return this.http
                .get(url, {
                params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
            })
                .pipe(map((res) => res["response"]), catchError((err) => of([])));
        }
        else if (data.searchType === "departmentName") {
            let resData = [];
            this.departments$
                .pipe(map((departments) => departments.filter((department) => {
                let depName = department["name"]
                    .toLowerCase()
                    .toString();
                let searchTerm = term.toLowerCase();
                let res = depName.indexOf(searchTerm);
                if (res >= 0) {
                    return department;
                }
            })))
                .subscribe((res) => {
                resData = res;
            });
            return of(resData);
        }
        else if (data.searchType === "building" ||
            data.searchType === "floor" ||
            data.searchType === "meetingroom" ||
            data.searchType === "deskNumber") {
            let resData = [];
            this.buildings$
                .pipe(map((buildings) => buildings.filter((building) => {
                let depName = building["name"]
                    .toLowerCase()
                    .toString();
                let searchTerm = term.toLowerCase();
                let res = depName.indexOf(searchTerm);
                if (res >= 0) {
                    return building;
                }
            })))
                .subscribe((res) => {
                resData = res;
            });
            // console.log('res is... ', resData)
            return of(resData);
        }
        /** Show department data without any search character */
        // if(data.searchType === "departmentName"){
        //     let resData = [];
        //     this.departments$
        //         .pipe(
        //             map((departments) =>
        //                 departments.filter((department) => {
        //                     let depName = department["name"]
        //                         .toLowerCase()
        //                         .toString();
        //                     let searchTerm = term.toLowerCase();
        //                     let res = depName.indexOf(searchTerm);
        //                     if (res >= 0) {
        //                         return department;
        //                     }
        //                 })
        //             )
        //         )
        //         .subscribe((res) => {
        //             resData = res;
        //         });
        //     return of(resData);
        // }else{
        //     if(term == "" || term.length <= 2) {
        //         return of([]);
        //     }
        //     if (data.searchType === "user") {
        //         let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
        //         return this.http
        //             .get<any>(url, {
        //                 params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        //             })
        //             .pipe(
        //                 map((res) => res["response"]),
        //                 catchError((err) => of([]))
        //             );
        //     }else if (data.searchType === "building" || data.searchType === "deskNumber") {
        //         let resData = [];
        //         this.buildings$
        //             .pipe(
        //                 map((buildings) =>
        //                     buildings.filter((building) => {
        //                         let depName = building["name"]
        //                             .toLowerCase()
        //                             .toString();
        //                         let searchTerm = term.toLowerCase();
        //                         let res = depName.indexOf(searchTerm);
        //                         if (res >= 0) {
        //                             return building;
        //                         }
        //                     })
        //                 )
        //             )
        //             .subscribe((res) => {
        //                 resData = res;
        //             });
        //         return of(resData);
        //     }
        // }
    }
    getBuilding(zoneId, data) {
        // console.log('hitting here...')
        let url = this.EMSURL + "zones/childZones/v2";
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(shareReplay(1), map((res) => res["response"].zoneMap[zoneId]), catchError((err) => of([])), map((res) => {
            if (res.length) {
                return res.map((res) => (Object.assign({ userId: res.zoneId }, res)));
            }
            else {
                return of([]);
            }
        }), tap((res) => {
            // console.log('resp is..... ', res)
            this.buildingSubject$.next(res);
        }));
    }
    /*get floor*/
    getFloors(zoneId, data) {
        let url = this.EMSURL + "zones/childZones/v2";
        return this.http
            .post(url, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(shareReplay(1), map((res) => res["response"]["zoneMap"][data.zoneIds[0]]), catchError((err) => of([])), map((res) => {
            if (res.length) {
                return res.map((_a) => {
                    var { id: userId } = _a, rest = tslib_1.__rest(_a, ["id"]);
                    return (Object.assign({ userId }, rest));
                });
            }
            else {
                return of([]);
            }
        }), tap((res) => {
            this.floorSubject$.next(res);
        }));
    }
    getAmenityNameAutoCompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
            return of([]);
        }
        let url = `${this.EMSURL}user/search/v3?searchString=${term}&searchParams=name,email,phone_num`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError((err) => of([])));
    }
    getSettingsAvailabilityAnalytics(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/sm-settings/getSettingsIfPresent`;
        return this.http.get(url, httpOptions);
    }
    getCheckInCheckOutCount(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/analytics/checkInCheckOutCounts?startTime=${data.startTime}&endTime=${data.endTime}`;
        if (data.zoneId)
            url += `&zoneId=${data.zoneId}`;
        return this.http.get(url, httpOptions);
    }
    getchildZonesByLevelv2(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        const endpoint = this.EMSURL + "zones/childZones/v2";
        return this.http.post(endpoint, data, httpOptions).pipe(shareReplay());
    }
    getchildZonesByLevelSpaceView(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        const endpoint = this.BASEURL + "/entityCategory/get/zones";
        return this.http.get(endpoint, httpOptions).pipe(shareReplay());
    }
    getBookingByDepartment(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/analytics/getDepartmentBookings?startTime=${data.startTime}&endTime=${data.endTime}`;
        if (data.zoneId)
            url += `&zoneId=${data.zoneId}`;
        return this.http.get(url, httpOptions);
    }
    getMostPopularBooking(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/analytics/getEntityWiseBookings?startTime=${data.startTime}&endTime=${data.endTime}`;
        if (data.zoneId)
            url += `&zoneId=${data.zoneId}`;
        return this.http.get(url, httpOptions);
    }
    getAnalyticsStats(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/analytics/getAnalyticsStats?startTime=${data.startTime}&endTime=${data.endTime}`;
        if (data.zoneId)
            url += `&zoneId=${data.zoneId}`;
        return this.http.get(url, httpOptions);
    }
    getInvitedPending(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = `${this.BASEURL}/analytics/getInvitedPending?startTime=${data.startTime}&endTime=${data.endTime}`;
        if (data.zoneId)
            url += `&zoneId=${data.zoneId}`;
        return this.http.get(url, httpOptions);
    }
    getFloorsSpaceBooking(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.EMSURL + "zones/childZones";
        return this.http.post(url, data, httpOptions);
    }
    getSocialSeatTypeData(zoneId, floorId, start, end, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/analytics/socialDistBeforeAfterFloorwiseCounts?startTime=` +
            start +
            `&endTime=` +
            end +
            `&zoneId=` +
            floorId;
        return this.http.get(url, httpOptions);
    }
    getBuildingOccupancy(zoneId, start, end, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/analytics/buildingOccupancy?startTime=${start}&endTime=${end}`;
        return this.http.get(url, httpOptions);
    }
    getDeptDeskWiseUtilization(zoneId, start, end, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            "/analytics/desk/departmentWiseUtilization?startTime=" +
            start +
            "&endTime=" +
            end;
        return this.http.get(url, httpOptions);
    }
    getDeptCreditMarketRisk(zoneId, start, end, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            "/analytics/desk/creditMarketRisk?startTime=" +
            start +
            "&endTime=" +
            end;
        return this.http.get(url, httpOptions);
    }
    getTotalArea(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/analytics/onboarding`;
        return this.http.get(url, httpOptions);
    }
    getSpaceSeatTypeData(zoneId, start, end, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/analytics/entityTypeOccupancy?startTime=` +
            start +
            `&endTime=` +
            end;
        return this.http.get(url, httpOptions);
    }
    getTiles(zoneId, currentStart, currentEnd, compareStart, compareEnd, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            `/analytics/tiles/compare?currentStartTime=${currentStart}&currentEndTime=${currentEnd}&compareStartTime=${compareStart}&compareEndTime=${compareEnd}`;
        return this.http.get(url, httpOptions);
    }
    updateCategory(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url;
        url = this.BASEURL + `/entitytype/update`;
        return this.http.put(url, data, httpOptions);
    }
    updateAddonsandSpecReq(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url;
        url = this.BASEURL + `/amenity/update`;
        return this.http.put(url, data, httpOptions);
    }
    deleteEntity(zoneId, id, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entity/disable/` + id;
        return this.http.post(url, {}, httpOptions);
    }
    deleteMultipleEntities(zoneId, gecId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entity/disable`;
        return this.http.post(url, data, httpOptions);
    }
    getParticipantsStatus(zoneId, gecId, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/booking/get/${entityId}`;
        return this.http.get(url, httpOptions);
    }
    getTeamRosterCalendarV2(zoneId, start, end, proxyId, gecId) {
        console.log(proxyId);
        console.log(gecId);
        let url;
        url =
            this.WFHURL +
                `calendar/getColleguesCalendar?startTime=${start}&endTime=${end}&proxyId=${proxyId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        return this.http.get(url, httpOptions).pipe();
    }
    getUserType(zoneId, gecId) {
        let url;
        url = this.WFHURL + `wfh-settings/getSettingsForUser`;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        return this.http.get(url, httpOptions).pipe();
    }
    allocateSeatsAutomatic(data, zoneId, type, editFlow, startTime, endTime, gecId) {
        let status = true;
        if (type == "MANUAL") {
            status = false;
        }
        let url;
        url =
            this.WFHURL +
                `rosters/createRostersForSpecificDaysV2?isAutoAllocationEnabled=${status}&editFlow=${editFlow}&startTime=${startTime}&endTime=${endTime}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        return this.http.post(url, data, httpOptions).pipe();
    }
    notifyTeam(zoneId, userId, startTime, endTime, gecId) {
        let url = this.WFHURL +
            `notification/notifyEmailAndPush?userId=${userId}&startTime=${startTime}&endTime=${endTime}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ', "moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        return this.http.post(url, {}, httpOptions);
        /*
            return this.http
                    .post<any>(url,{}, {
                        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
                      })
                    .pipe();
            */
    }
    getPermanentBookingCount(zoneId, gecId, userId, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entity/getPermanentBookingCounts?userId=${userId}`;
        if (entityId)
            url += `&entityId=${entityId}`;
        return this.http.get(url, httpOptions);
    }
    getAllEnabledEntityType(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.BASEURL + `/entitytype/get/category`;
        return this.http.get(url, httpOptions);
    }
    getPermanentBookingAvailabilityMapData(currentZone, gecId, userId, floorId, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    currentZone +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.SPACEURL +
            `entity/availabilityMapViewPermanent?userId=${userId}&zoneId=${floorId}&entityId=${entityId}`;
        return this.http.get(url, httpOptions).pipe(map((res) => res["response"]), catchError((err) => throwError(err)));
    }
    savePermanentBooking(zoneId, gecId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + "/booking/create/admin";
        return this.http.post(url, data, httpOptions);
    }
    getAllisPermanant(zoneId, pageindex, pagesize, searchString, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.spaceMgtUrl +
            `/allocation/search?&size=${pagesize}&page=${pageindex}&isPermanant=true`;
        return this.http.get(url, httpOptions);
    }
    getPermanentTableData(zoneId, pageIndex, pageSize, gecId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    ',"locale":"' + this.localVal +
                    '"}',
            }),
        };
        let url;
        if (data.organiserUserId) {
            url =
                this.BASEURL +
                    `/allocation/search?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&isPermanant=true&searchCriteria=USER&id=` +
                    data.organiserUserId;
        }
        else if (data.amenityUserId) {
            url =
                this.BASEURL +
                    `/allocation/search?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&isPermanant=true&searchCriteria=DESK&` +
                    `deskName=` +
                    data.amenityUserId;
        }
        else if (data.buildingUserId || data.floorId) {
            url =
                this.BASEURL +
                    `/allocation/search?page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&isPermanant=true&searchCriteria=BUILDING&id=` +
                    data.buildingUserId;
        }
        else if (data.searchString) {
            url =
                this.BASEURL +
                    `/allocation/search?size=${pageSize}&page=${pageIndex}&isPermanant=true&${data.searchString}`;
        }
        else {
            url =
                this.BASEURL +
                    `/allocation/search?size=${pageSize}&page=${pageIndex}&isPermanant=true`;
        }
        //let url = this.BASEURL + `/allocation/search?size=${pageSize}&page=${pageindex}&isPermanant=true&${data.searchString}`;
        return this.http.get(url, httpOptions);
    }
    findSeatsAssignedForGivenRange(zoneId, gecId) {
        const previousDate = new Date();
        const pastDate = new Date();
        const prevoiusMonth = previousDate.getMonth();
        previousDate.setMonth(previousDate.getMonth() - 1);
        while (previousDate.getMonth() === prevoiusMonth) {
            previousDate.setDate(previousDate.getDate() - 1);
        }
        const pastMonth = pastDate.getMonth();
        pastDate.setMonth(pastDate.getMonth() + 2);
        while (pastDate.getMonth() === pastMonth) {
            pastDate.setDate(pastDate.getDate() + 1);
        }
        let data = {
            isWfhRequest: true,
            requestDetails: {
                startTime: previousDate.getTime(),
                endTime: pastDate.getTime(),
                userId: localStorage.getItem("ssadmin_userId"),
                demandId: 1,
                recurringStartTime: 0,
                recurringEndTime: 2359,
                isWfhRequest: false,
            },
        };
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url;
        url = this.BASEURL + `/entity/findSeatsAssignedForGivenRange`;
        //let url = this.BASEURL + `/allocation/search?size=${pageSize}&page=${pageindex}&isPermanant=true&${data.searchString}`;
        //return this.http.get<any>(url, httpOptions);
        return this.http.post(url, data, httpOptions).pipe();
    }
    updateExternalConfig(zoneId, categoryId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    categoryId +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/entityCategory/updateExternalConfig`;
        return this.http.put(url, data, httpOptions);
    }
    checkOutlookConfig(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.BASEURL +
            `/entityCategory/get/config?gecId=${gecId}&zoneId=${zoneId}`;
        return this.http.get(url, httpOptions);
    }
    checkOutlookConfigWithoutCheck(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.BASEURL +
            `/entityCategory/get/config/withoutUpdateCountCheck?gecId=${gecId}&zoneId=${zoneId}`;
        return this.http.get(url, httpOptions);
    }
    permissionCheck(data, zoneId) {
        const endpoint = this.BASEURL + "/booking/permissionCheck";
        return this.http
            .post(endpoint, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(shareReplay());
    }
    externalEmailValidation(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        const endpoint = this.BASEURL + "/booking/externalEmailValidation?emailList=" + data;
        return this.http.get(endpoint, httpOptions).pipe(shareReplay());
    }
    proccedEmailValidation(data, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        const endpoint = this.BASEURL + "/booking/externalEmailValidation?emailList=" + data;
        return this.http.get(endpoint, httpOptions).pipe(shareReplay());
    }
    downloadMyBookingCsv(zoneId, globalId, startTime, endTime, bookingType) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' +
                    localStorage.getItem("language") +
                    '","gecId":' +
                    globalId +
                    "}",
            }),
        };
        let url = this.BASEURL +
            `/booking/downloadCSV?page=0&size=100000&sort=createdAt,DESC&` +
            `search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING&globalEntityId=${globalId}&` +
            `startTime=${startTime}&endTime=${endTime}`;
        if (!!bookingType)
            url += `&bookingType=${bookingType}`;
        else {
            url =
                this.BASEURL +
                    `/booking/downloadCSV/mr?page=0&size=100000&sort=createdAt,DESC&` +
                    `search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING&globalEntityId=${globalId}&` +
                    `startTime=${startTime}&endTime=${endTime}`;
        }
        return this.http.get(url, httpOptions);
    }
    downloadAnalyticsWFO(zoneId, globalId, startTime, endTime) {
        const headers = new HttpHeaders({
            "ss-header": '{"correlationId":"' +
                uuidv4() +
                '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                zoneId +
                ',"moduleId":12001,"sessionId":"' +
                localStorage.getItem("ssadmin_session") +
                '","gecId":"' +
                globalId +
                '"}',
        });
        let url = this.WFHURL +
            `/analytics/getWfoReport?startTime=${startTime}&endTime=${endTime}`;
        return this.http.get(url, { headers: headers, responseType: "blob" });
    }
    downloadAnalyticsfireSafety(zoneId, globalId, startTime) {
        const headers = new HttpHeaders({
            "ss-header": '{"correlationId":"' +
                uuidv4() +
                '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                zoneId +
                ',"moduleId":12001,"sessionId":"' +
                localStorage.getItem("ssadmin_session") +
                '","gecId":"' +
                globalId +
                '"}',
        });
        let url = this.BASEURL + `/booking/fireReport?startTime=${startTime}`;
        return this.http.get(url, { headers: headers, responseType: "blob" });
    }
    loadSpaces() {
        let url = environment.BASE_URL + `amenitybooking/globalCategory/getAll/type`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    getSpaceViewAnalytics(currentZone, data, gecId) {
        let url = `${this.BASEURL}/allocation/mapView?dayTime=${data.dayTime}&mapViewType=${data.viewType}&zoneId=${data.floorId}`;
        if (!!data.timestamp) {
            url = `${this.BASEURL}/allocation/mapView?dayTime=${data.dayTime}&mapViewType=${data.viewType}&zoneId=${data.floorId}&timestamp=${data.timestamp}`;
        }
        this.currentZone = currentZone;
        this.url = url;
        this.gecId = gecId;
        this.sub$.next(data);
        /*return this.http
                .get<any>(url, {
                    params: new ZoneAndModuleHttpParams(currentZone, this.moduleId)
                })
                .pipe(
                    distinctUntilChanged(),
                    shareReplay(),
                    map(res => res["response"]),
                    catchError(err => {
                        let errorMsgData = (err as any).error;
                        const message = errorMsgData["message"];
                        this.toastrService.error("No data found");
                        return throwError(err);
                    })
                );*/
    }
    getSpaceViewAnalyticsWithGc(currentZone, data, gecId, gcId) {
        // let url = `${this.BASEURL}/allocation/mapView?dayTime=${data.dayTime}&mapViewType=${data.viewType}&zoneId=${data.floorId}`;
        let url = `${this.BASEURL}/allocation/mapView?dayTime=${data.dayTime}&mapViewType=${data.viewType}&zoneId=${data.floorId}&timestamp=${data.timestamp}&globalCategoryId=${gcId}`;
        this.currentZone = currentZone;
        this.url = url;
        this.gecId = gecId;
        this.sub$.next(data);
        /*return this.http
            .get<any>(url, {
                params: new ZoneAndModuleHttpParams(currentZone, this.moduleId)
            })
            .pipe(
                distinctUntilChanged(),
                shareReplay(),
                map(res => res["response"]),
                catchError(err => {
                    let errorMsgData = (err as any).error;
                    const message = errorMsgData["message"];
                    this.toastrService.error("No data found");
                    return throwError(err);
                })
            );*/
    }
    getFutureDateBookings(zoneId, gecId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL +
            "/booking/getV3ListOfDays?search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING,demandType:USER_DEPARTMENT&page=0&size=999";
        return this.http.post(url, data, httpOptions);
    }
    getSpecialRequestByEntityId(zoneId, gecId, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = this.BASEURL +
            `amenity/get/specialRequest/entityId?entityId=${entityId}`;
        return this.http.get(url, httpOptions);
    }
    // getEmployeeNameAutoCompleteList(data, zoneId): Observable<any> {
    //     let term = data.searchTerm.trim();
    //     if (term == "" || term.length <= 2) {
    //         return of([]);
    //     }
    //     let url = `${this.EMSURL}user/search/v3?searchString=${term}&searchParams=name,email,phone_num`;
    //     return this.http
    //         .get<any>(url, {
    //             params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    //         })
    //         .pipe(
    //             map((res) => res["response"]),
    //             catchError((err) => of([]))
    //         );
    // }
    getEmployeeNameAutoCompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
            return of([]);
        }
        // let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
        let url = `${this.EMSURL}user/search/v2/limited?searchString=${term}&searchParams=name,email,phone_num`;
        return this.http
            .get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        })
            .pipe(map((res) => res["response"]), catchError((err) => of([])));
    }
    getRecentSearch(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        const endpoint = this.BASEURL + "/recentsearch/getAll?pageNumber=0&pageSize=1000";
        return this.http.get(endpoint, httpOptions).pipe(shareReplay());
    }
    createRecentSearch(zoneId, gecId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + "/recentsearch/create";
        return this.http.post(url, data, httpOptions);
    }
    checkWayFinding(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url;
        url = this.BASEURL + `/onboarding/checkWayFinding`;
        return this.http.post(url, data, httpOptions);
    }
    loadUtilSettings(zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":' +
                    gecId +
                    "}",
            }),
        };
        let url = environment.BASE_URL +
            `analyticsV3/analyticSettings/get?gcdId=${gecId}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    updateUtil(zoneId, data, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = environment.BASE_URL + `analyticsV3/analyticSettings/update`;
        return this.http.put(url, data, httpOptions);
    }
    getBookingAnalytics(data, zoneId, gecId) {
        let building;
        let floor;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        // let url =
        //     environment.BASE_URL +
        //     `analyticsV3/analytics/getGraphs/v5/sia?dashboardId=3&startTime=${data.startTime}&endTime=${data.endTime}`;
        // let url =
        //     environment.BASE_URL +
        //     `analyticsV3/analytics/getGraphs/v4?dashboardId=3&startTime=${data.startTime}&endTime=${data.endTime}`;
        let url = environment.BASE_URL + `analyticsV3/analytics/getGraphs/v4?startTime=${data.startTime}&endTime=${data.endTime}`;
        if (data.buildingIds)
            url += "&buildingIds=" + data.buildingIds;
        if (data.floorIds)
            url += "&floorIds=" + data.floorIds;
        if (data.singleUserParticipant)
            url += "&collaborative=true";
        return this.http.get(url, httpOptions);
    }
    getBookingAnalyticsV2(data, zoneId, gecId) {
        //https://lendleasereplica.smartenspaces.com/analyticsV3/analytics/getGraphs/v5/sia
        let building;
        let floor;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = environment.BASE_URL +
            `analyticsV4/analytics/getGraphs/v5/sia?dashboardId=1&startTime=${data.startTime}&endTime=${data.endTime}`;
        //let url = `https://amenitiesbookingqa.smartenspaces.com/analyticsV3/analytics/getGraphs/v4?startTime=37&endTime=1688688000000`;
        if (data.buildingIds)
            url += "&buildingIds=" + data.buildingIds;
        if (data.floorIds)
            url += "&floorIds=" + data.floorIds;
        if (data.singleUserParticipant)
            url += "&collaborative=true";
        if (zoneId)
            url += "&siteId=" + zoneId;
        return this.http.get(url, httpOptions);
    }
    getBookingAnalyticsGlobal(data, zoneId, gecId, tabSelected) {
        //https://lendleasereplica.smartenspaces.com/analyticsV3/analytics/getGraphs/v5/sia
        let building;
        let floor;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let dashboardId = 2;
        if (tabSelected == '1') {
            dashboardId = 4;
        }
        let url = environment.BASE_URL +
            `analyticsV4/analytics/getGraphs/v5/sia?dashboardId=${dashboardId}&startTime=${data.startTime}&endTime=${data.endTime}`;
        //let url = `https://amenitiesbookingqa.smartenspaces.com/analyticsV3/analytics/getGraphs/v4?startTime=37&endTime=1688688000000`;
        if (data.buildingIds)
            url += "&buildingIds=" + data.buildingIds;
        if (data.floorIds)
            url += "&floorIds=" + data.floorIds;
        if (data.singleUserParticipant)
            url += "&collaborative=true";
        if (zoneId)
            url += "&siteId=" + zoneId;
        return this.http.get(url, httpOptions);
    }
    // validateCSVOnboarding(fileToUpload): Observable<any> {
    //   const httpOptions = {
    //     headers: new HttpHeaders({
    //       "ss-header":
    //         '{"correlationId":"' +
    //         uuidv4() +
    //         '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
    //         localStorage.getItem("ssadmin_session") +
    //         '"}',
    //     }),
    //   };
    //   let url = this.EMSURL + `zones/admin/validateCSV`;
    //   const formData: FormData = new FormData();
    //   formData.append("file", fileToUpload, fileToUpload.name);
    //   return this.http.post(url, formData, httpOptions);
    // }
    validateCSVOnboarding(fileToUpload, floorId, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.SPACEURL +
            `onboarding/validateExcelReqAndAddons?floorId=${floorId}`;
        var formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        return this.http.post(url, formData, httpOptions);
    }
    saveOnboardedEntitiesViaExcel(zoneId, gecId, validDatas, floorId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
            }),
        };
        let url = this.SPACEURL +
            `onboarding/saveExcelReqAndAddons?floorId=${floorId}`;
        return this.http.post(url, validDatas, httpOptions);
    }
    downloadBulkUploadEntities(floorId, zoneId, gecId) {
        const headers = new HttpHeaders({
            "ss-header": '{"correlationId":"' +
                uuidv4() +
                '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                zoneId +
                ',"moduleId":12001,"sessionId":"' +
                localStorage.getItem("ssadmin_session") +
                '","gecId":"' +
                gecId +
                '"}',
        });
        let url = environment.BASE_URL +
            `amenitybooking/onboarding/downloadTemplateReqAndAddons?floorId=${floorId}`;
        // responseType: 'blob' is for xlsx file conversion
        return this.http.get(url, { headers: headers, responseType: "blob" });
    }
    getEmailAutocompleteList(data, zoneId) {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
            return of([]);
        }
        let item = [
            { email: "a@smartenspace.com" },
            { email: "b@smartenspace.com" },
            { email: "c@smartenspace.com" },
            { email: "d@smartenspace.com" },
            { email: "e@smartenspace.com" },
            { email: "f@smartenspace.com" },
        ];
        let ob = new Observable((obs) => {
            obs.next(item);
        });
        return ob;
        // let term = data.searchTerm.trim();
        // if (term == "" || term.length <= 2) {
        //     return of([]);
        // }
        // if (data.searchType === "employee" || data.searchType === "user") {
        //     let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
        //     return this.http
        //         .get<any>(url, {
        //             params: new ZoneAndModuleHttpParams(zoneId, 3),
        //         })
        //         .pipe(
        //             map((res) => res["response"]),
        //             catchError((err) => of([]))
        //         );
        // }
    }
    getInitialVisitorParameters(zoneId, bookingModuleId, gecId) {
        bookingModuleId = bookingModuleId ? bookingModuleId : 12001;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":' + bookingModuleId + ',"sessionId":"' +
                    localStorage.getItem("ssadmin_session") + '","gecId":"' + gecId +
                    '"}',
            }),
        };
        let url = this.BASEURL + '/parameters/getAll';
        return this.http.get(url, httpOptions);
    }
    saveVisitorParameter(data, zoneId, gecId, moduleId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":' + moduleId + ',"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
                'Content-Type': 'application/json',
            }),
        };
        let url;
        url = this.BASEURL + `/parameters/saveParameter`;
        return this.http.post(url, data, httpOptions);
    }
    deleteVisitorParameter(data, zoneId, gecId, moduleId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":' + moduleId + ',"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
                'Content-Type': 'application/json',
                'Request-Type': 'DELETE',
            }),
        };
        let url;
        url = this.BASEURL + `/parameters/delete`;
        return this.http.put(url, data, httpOptions);
    }
    saveEditedVisitorInfo(data, zoneId, gecId, moduleId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":' + moduleId + ',"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '"}',
                'Content-Type': 'application/json',
            }),
        };
        let url;
        url = this.BASEURL + `/parameters/editParameter`;
        return this.http.put(url, data, httpOptions);
    }
    getAllVisitorInternalFlexibleBookingsV3(zoneId, pageIndex, pageSize, searchString, type, entityId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let searchVal = "";
        if (data.searchString) {
            searchVal = "," + data.searchString;
        }
        let url = "";
        if (data.organiserUserId) {
            url =
                this.BASEURL +
                    `/booking/getV3?flexibleBooking=Visitors&page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `&globalEntityId=` +
                    entityId +
                    `&userId=` +
                    data.organiserUserId;
        }
        else if (data.amenityUserId) {
            url =
                this.BASEURL +
                    `/booking/getV3?flexibleBooking=Visitors&page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `,entityName:` +
                    data.amenityUserId +
                    `&globalEntityId=` +
                    entityId;
        }
        else if (data.buildingUserId || data.floorId) {
            url =
                this.BASEURL +
                    `/booking/getV3?flexibleBooking=Visitors&page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    ",buildingId:" +
                    data.buildingUserId +
                    `,floorId:` +
                    data.floorId +
                    `&globalEntityId=` +
                    entityId;
        }
        else {
            url =
                this.BASEURL +
                    `/booking/getV3?flexibleBooking=Visitors&page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    searchVal +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `&globalEntityId=` +
                    entityId;
        }
        //let url = this.BASEURL + `/booking/getV3?page=` + pageIndex + `&size=` + pageSize + `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING,` + searchString + ',demandType:' + type + `&globalEntityId=` + entityId;
        return this.http.get(url, httpOptions);
    }
    getAllInternalFLexibleBookingsV3(zoneId, pageIndex, pageSize, searchString, type, entityId, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let searchVal = "";
        if (data.searchString) {
            searchVal = "," + data.searchString;
        }
        let url = "";
        if (data.organiserUserId) {
            url =
                this.BASEURL +
                    `/booking/getV3?flexibleBooking=Internal&page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `&globalEntityId=` +
                    entityId +
                    `&userId=` +
                    data.organiserUserId;
        }
        else if (data.amenityUserId) {
            url =
                this.BASEURL +
                    `/booking/getV3?flexibleBooking=Internal&page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `,entityName:` +
                    data.amenityUserId +
                    `&globalEntityId=` +
                    entityId;
        }
        else if (data.buildingUserId || data.floorId) {
            url =
                this.BASEURL +
                    `/booking/getV3?flexibleBooking=Internal&page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    "" +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    ",buildingId:" +
                    data.buildingUserId +
                    `,floorId:` +
                    data.floorId +
                    `&globalEntityId=` +
                    entityId;
        }
        else {
            url =
                this.BASEURL +
                    `/booking/getV3?flexibleBooking=Internal&page=` +
                    pageIndex +
                    `&size=` +
                    pageSize +
                    `&sort=startTime,DESC&search=requestType:BOOKING_REQUEST,requestType:ADMIN_BOOKING` +
                    searchVal +
                    ",demandType:" +
                    "USER_DEPARTMENT" +
                    `&globalEntityId=` +
                    entityId;
        }
        //let url = this.BASEURL + `/booking/getV3?page=` + pageIndex + `&size=` + pageSize + `&sort=createdAt,DESC&search=requestType:BOOKING_REQUEST,'requestType:ADMIN_BOOKING,` + searchString + ',demandType:' + type + `&globalEntityId=` + entityId;
        return this.http.get(url, httpOptions);
    }
    saveVisitorBooking(reqObj, zoneId, gecId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.SPACEURL +
            `booking/create/wfh/v3?isRosterUpdation=true`;
        return this.http
            .post(url, reqObj, httpOptions)
            .pipe(catchError((err) => throwError(err)));
    }
    getAllGroupBookingsV5(zoneId, pageIndex, pageSize, searchString, type, entityId, data) {
        console.log("data", data);
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = "";
        if (data.forGroupBookingTitle) {
            url = this.BASEURL + `/groupBooking/getV5?page=` + pageIndex + `&size=` + pageSize + `&sort=startTime,ASC&` + `startTime=${data.
                serachDate}` + `&groupBookingIds=${data.forGroupBookingTitle}`;
        }
        else if (data.forOrganizerName) {
            url = this.BASEURL + `/groupBooking/getV5?page=` + pageIndex + `&size=` + pageSize + `&sort=startTime,ASC&` + `startTime=${data.
                serachDate}` + `&groupBookingIds=${data.forOrganizerName}`;
        }
        else {
            url = this.BASEURL + `/groupBooking/getV5?page=` + pageIndex + `&size=` + pageSize + `&sort=startTime,ASC&` + `startTime=${data.
                serachDate}`;
        }
        return this.http.get(url, httpOptions);
    }
    getGroupBookingDetailsById(zoneId, gecId, bookingId, status) {
        console.log("bookingId", bookingId);
        console.log("status", status);
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/groupBooking/getGroupBookingDetailById?id=${bookingId}&superAdmin=true`;
        return this.http.get(url, httpOptions);
    }
    trackAllUsers(zoneId, gecId, bookingId, startTime) {
        console.log("bookingId", bookingId);
        console.log("status", status);
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/groupBooking/responseTrackingForAllUser?startTime=${startTime}&groupBookingId=${bookingId}`;
        return this.http.get(url, httpOptions);
    }
    deleteGroupBooking(zoneId, entityId, groupBookingId, validate) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/groupBooking/superAdmin/deleteGroupBooking?groupBookingId=${groupBookingId}&validate=${validate}`;
        return this.http.delete(url, httpOptions);
    }
    getAllGroupBookingsV5ForSearch(zoneId, entityId, data) {
        console.log("data", data);
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/groupBooking/getV5?sort=startTime,ASC&` + `startTime=${data.
            serachDate}` + `&superAdmin=true`;
        return this.http.get(url, httpOptions);
    }
    getGroupBookingAnalytics(zoneId, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    entityId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.BASEURL + `/groupBooking/getGroupBookingAnalytics`;
        return this.http.get(url, httpOptions);
    }
    emsConfigCreate(reqObj, zoneId, entityId) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        let url = this.EMSURL + `config/createByZoneId`;
        return this.http.post(url, reqObj, httpOptions);
    }
    downloadGroupBookingCsv(zoneId, globalId, startTime, endTime, bookingType) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","locale":"' +
                    localStorage.getItem("language") +
                    '","gecId":' +
                    globalId +
                    "}",
            }),
        };
        let url = this.BASEURL +
            `/groupBooking/downloadCSV?startTime=${startTime}&endTime=${endTime}`;
        return this.http.get(url, httpOptions);
    }
    getAllocationDetails(zoneId, gecId, startTime, endTime, entityInfoId, data, bookingType) {
        let url;
        if (bookingType && (bookingType === 'Internal' || bookingType === 'Flexible')) {
            let userId = data['bookedForUser']['userId'];
            url = this.BASEURL + `/booking/user/entity/startTime/endTime/V2?startTime=${startTime}&endTime=${endTime}&entityInfoId=${entityInfoId}&userId=${userId}`;
        }
        else {
            url = this.BASEURL + `/booking/user/entity/startTime/endTime/V2?startTime=${startTime}&endTime=${endTime}&entityInfoId=${entityInfoId}`;
        }
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' +
                    zoneId +
                    ',"moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '","gecId":"' +
                    gecId +
                    '","locale":"' +
                    this.localVal +
                    '"}',
            }),
        };
        return this.http.get(url, httpOptions);
    }
}
