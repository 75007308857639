<div class="only-calndarview" *ngIf="pageType == 'calendarView'">
  <full-calendar
    #fullcalendar
    *ngIf="calendarOptions"
    [options]="calendarOptions"
  ></full-calendar>
  <div class="d-flex align-items-center regular-font m-4">
    <span class="calendar-schedule-Office mr-2"></span> {{"youHaveAQuickAction" | translate}}
  </div>
</div>

<div class="modal-card" *ngIf="pageType == 'schedule'">
  <div class="modal-header p-0">
    <h5 class="semi-bold-blue">{{"workStatus" | translate}}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body align-left">
    <app-my-schedules (doneSchedule)="doneSchedule($event)"></app-my-schedules>
  </div>
</div>

<div class="modal-card" *ngIf="pageType == 'scheduleCreated'">
  <div class="modal-header p-0">
    <h5 class="semi-bold-blue">{{"success" | translate}}</h5>
  </div>

  <div class="modal-body align-left">
    <p class="semi-bold-blue">
      <img src="./assets/images/checked.svg" class="w-20">{{"taskCompletedSuccessfully" | translate}}
    </p>
    <p class="float-right">
      <button
        type="button"
        class="btn btn-primary btn-main btn-Small btn-radius btn-custom-border"
        (click)="closePopup()"
      >
      {{"done" | translate}}
      </button>
    </p>
  </div>
</div>

<div class="modal-card" *ngIf="pageType == 'colleaguesModal'">
  <div class="modal-header p-0">
    <h5 class="semi-bold-blue"> {{"myFavInOffice" | translate}}
      <img src="./assets/images/jumpree_digital/calendar_icon.svg" alt="" class="ml-3" (click)="showFullCalendar()"/>
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body align-left">
    <app-colleagues 
      (notifyParentColleague)="getNotification($event)"
      [colleagueObj] = "colleagueObj"
    ></app-colleagues>
  </div>
</div>

<div *ngIf="pageType == 'searchLocation'">
  <div class="modal-header modalHeaderPadding pl-0">
    <h6 class="semi-bold-blue">{{"selectOffice" | translate}}</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body align-left pl-1 py-0">
    <app-search-location-reuseable-modal
      [locationList]="locationList"
      [currentZonePass]="currentZone"
      [selectedDate]="selectedDate"
      [selectedOfficeDates]="selectedOfficeDates"
      (notifySearchLocation)="getFilteredSearchLocation($event)"
    ></app-search-location-reuseable-modal>
  </div>
</div>

<div class="modal-card" *ngIf="pageType == 'selectLocations'">
  <div class="modal-header p-0">
    <h5 class="semi-bold-blue">{{ "workStatus" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <!-- <span aria-hidden="true">You can select different locations for the dates you are working from office</span> -->
    </button>
  </div>
  <p class="mt-2 openSans">
    {{
      "youCanSelectDifferentLocationsForTheDatesYouAreWorkingFromOffice"
        | translate
    }}
  </p>

  <div class="modal-body align-left pl-1 py-0">
    <app-search-location-reuseable-modal
      [locationList]="locationList"
      [currentZonePass]="currentZone"
      [selectedOfficeDates]="selectedOfficeDates"
      (saveDateLocation)="saveDateLocation($event)"
    ></app-search-location-reuseable-modal>
  </div>
</div>
