
    <div class="modal-body">
        <button
        id="close_btn"
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()"
        >
        <span aria-hidden="true">&times;</span>
        </button>
        <div class="video w-100 text-center">
            <video controls class="w-100 video-h"> 
                <source [src]="data['question']['mediaUrl']" type="video/mp4"> 
                <source [src]="data['question']['mediaUrl']" type="video/ogg"> 
                <source [src]="data['question']['mediaUrl']" type="video/webm"> 
                <p>{{"yourBrowserDoNotSupportHtmlVideo" | translate}}</p> 
            </video>
        </div>
    </div>