import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, throwError, BehaviorSubject, of } from "rxjs";
import { ZoneAndModuleHttpParams } from "../../../shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "../../../services/moduleid-service";
import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: "root",
})
export class GlobalSetupService {
  moduleId;
  BASEURL;
  EMSURL;
  BASEEMSURL;
  localVal;
  constructor(
    public http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public moduleidService: ModuleidService
  ) {
    this.BASEURL = baseUrl;
    this.EMSURL = environment.BASE_URL + "ems/";
    this.BASEEMSURL = environment.BASE_URL;
    this.moduleId = moduleidService.getModueId();
    this.localVal = localStorage.getItem("language");
  }

  loadLocations(type?){
    let param = "?page=0&size=100";
    if (type == "ENABLE") {
      param += "&enabled=true";
    }
    let url = this.EMSURL + `zones/admin/sites/get${param}`;
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
          this.moduleId
          }","sessionId": "${localStorage.getItem("ssadmin_session")}","locale":"${this.localVal}"}`,
      }),
    };
    return this.http.get(url, httpOptions);
  }
  searchLocations(userObj) {
    let url =
      this.EMSURL + `zones/sites/admin/search?name=${userObj["searchValue"]}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }


  loadUsers(data, zoneId): Observable<any> {
    let term = data.searchValue.trim();
    if (term == "" || term.length <= 2) {
      return of([]);
    }
    let url = `${this.EMSURL}user/admin/all?sortBy=createdAt&searchColumn=email&searchValue=${data['searchValue']}&page=${data['pageIndex']}&size=${data['pageSize']}`;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]['content']),
        catchError((err) => of([]))
      );
  }

  // loadUsers(userObj) {
  //       console.log(userObj);
  //       let url;        
  //           url = this.EMSURL + `user/admin/all?sortBy=createdAt&searchColumn=email&searchValue=${userObj['searchValue']}&page=${userObj['pageIndex']}&size=${userObj['pageSize']}`;       
  //       return this.http.get<any>(url, {
  //           params: new ZoneAndModuleHttpParams(null, this.moduleId),
  //       });
  //   }
  getCountryName(zoneId): Observable<any> {
    let url = `${this.EMSURL}zones/getAllZones?level=country`;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => of([]))
      );
  }
  getGoogleMap(zoneId, data): Observable<any> {
    let url = this.EMSURL + `zones/admin/getZoneLocation/gmaps`;
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap((res) => console.log(res)),
        map((res) => res["response"])
      );
  }

  deleteGlobalUser(id): Observable<any> {
    let url = `${this.EMSURL}user/admin/globalAdmin/remove?userId=${id}`;
    return this.http
      .post<any>(
        url,
        {},
        {
          params: new ZoneAndModuleHttpParams(null, this.moduleId),
        }
      )
      .pipe(
        map((res) => res["response"]),
        catchError((err) => of([]))
      );
  }

  childZones(zoneId, data): Observable<any> {
    let url = this.EMSURL + `zones/childZones`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  disableSite(zoneId,currentZone?): Observable<any> {
    let url = this.EMSURL + `zones/admin/disable?zoneId=${zoneId}`;
    let ssHeader = {
      "correlationId": `${uuidv4()}`,
      "version": "1.0",
      "clientKey": "ADMIN_WEB_APP",
      "mouduleId": `${this.moduleId}`,
      "sessionId": `${localStorage.getItem("ssadmin_session")}`,
      "locale": `${this.localVal}`
    };
    if(currentZone){
      ssHeader["zoneId"] = currentZone;  
    }

    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": JSON.stringify(ssHeader)
      }),
    };
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
    //       this.moduleId
    //       }","zoneId":${currentZone},"sessionId": "${localStorage.getItem("ssadmin_session")}","locale":"${this.localVal}"}`,
    //   }),
    // };
    return this.http.post<any>(url, {}, httpOptions);
  }

  downloadLocations(zoneId): Observable<any> {
    let url = this.EMSURL + `zones/sites/admin/downloadCsv`;
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
          this.moduleId
        }","sessionId": "${localStorage.getItem("ssadmin_session")}"}`,
      }),
    };
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  downloadUsersCsv(zoneId, dataObj,isTenant): Observable<any> {
    let url = this.EMSURL + `user/admin/getAllUserDetailsCsv`;
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
          this.moduleId
        }","sessionId": "${localStorage.getItem("ssadmin_session")}"}`,
      }),
    };
if(isTenant){
  url=url+'?tenantId=1';
}
    return this.http.post<any>(url, dataObj, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  enableSite(zoneId,currentZone?): Observable<any> {
    let url = this.EMSURL + `zones/admin/enable?zoneId=${zoneId}`;
    let ssHeader = {
      "correlationId": `${uuidv4()}`,
      "version": "1.0",
      "clientKey": "ADMIN_WEB_APP",
      "mouduleId": `${this.moduleId}`,
      "sessionId": `${localStorage.getItem("ssadmin_session")}`,
    }

    if(currentZone){
      ssHeader["zoneId"] = currentZone;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": JSON.stringify(ssHeader)
      }),
    };
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
    //       this.moduleId
    //     }","zoneId":${currentZone},"sessionId": "${localStorage.getItem("ssadmin_session")}"}`,
    //   }),
    // };
    return this.http.post<any>(
      url,
      {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      },
      httpOptions
    );
  }

  deleteSite(zoneId): Observable<any> {
    let url = this.EMSURL + `zones/admin/delete?zoneId=${zoneId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
          this.moduleId
        }","sessionId": "${localStorage.getItem("ssadmin_session")}"}`,
      }),
    };
    return this.http.delete<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  loadGlobalUsers() {
    let url = this.EMSURL + `user/admin/globalAdmin/getAll`;
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
          this.moduleId
          }","sessionId": "${localStorage.getItem("ssadmin_session")}","locale":"${this.localVal}"}`,
      }),
    };
    return this.http.get<any>(url, httpOptions);
  }

  loadRoles() {
    let url = this.EMSURL + `roles/admin/getRolePriviledges`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  updateLocation(zoneId, data) {
    let url = this.EMSURL + `zones/admin/update`;
    return this.http
      .put<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap((res) => console.log(res)),
        map((res) => res["response"])
      );
  }
  createLocation(zoneId, data) {
    let url = this.EMSURL + `zones/admin/create`;
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap((res) => console.log(res)),
        map((res) => res["response"])
      );
  }

  createDepartment(zoneId, data) {
    let url = this.EMSURL + `department/admin/create`;
    console.log("data is... ", data);
    console.log("url is... ", url);
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  updateDepartment(zoneId, data) {
    let url = this.EMSURL + `department/admin/update`;
    console.log("data is... ", data);
    return this.http
      .post<any>(url, data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        tap((res) => console.log(res)),
        map((res) => res["response"])
      );
  }

  downloadOnboardUsersTemplate(zoneId, type) {
    let url;
    if (type == "LOCATION") {
      url = this.EMSURL + `templates/download/key/LOCATIONS`;
    } else if (type == "USERS") {
      url = this.EMSURL + `templates/download/key/PEOPLE`;
    } else if (type == "DEPARTMENTS") {
      url = this.EMSURL + `templates/download/key/DEPARTMENTS`;
    }
    else if (type == "DEPARTMENTSNISSAN") {
      url = this.EMSURL + `templates/download/key/DUMMY_DEPARTMENT`;
    } else if (type == "SUBSCRIPTIONS") {
      url = this.EMSURL + `templates/download/key/SUBSCRIPTIONS`;
    }

    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  validateOnboardUsersTemplate(fileToUpload, type): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") +
          '","locale":"' + this.localVal +
          '"}',
      }),
    };
    let url;
    if (type == "LOCATIONS") {
      url = this.EMSURL + `zones/admin/validateCSV`;
    } else if (type == "USERS") {
      url = this.EMSURL + `user/admin/validateCSV`;
    } else if (type == "DEPARTMENTS") {
      url = this.EMSURL + `department/admin/validateCSV`;
    } else if (type == "SUBSCRIPTIONS") {
      url = this.EMSURL + `user-subscription-mappings/admin/validateCSV`;
    }
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, httpOptions);
  }

  validateOnboardDepartmentTemplate(fileToUpload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") + '","locale":"' +
          this.localVal +
          '"}',
      }),
    };
    let url = this.EMSURL + `department/admin/validateCSV`;
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, httpOptions);
  }

  validateOnboardPrimaryDepartmentTemplate(fileToUpload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") + '","locale":"' +
          this.localVal +
          '"}',
      }),
    };
    let url = this.EMSURL + `department/admin/uploadDummyDepartmentMappingCsv`;
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, httpOptions);
  }

  validateOnboardLocationTemplate(fileToUpload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") + '","locale":"' +
          this.localVal +
          '"}',
      }),
    };
    let url = this.EMSURL + `zones/admin/validateCSV`;
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, httpOptions);
  }

  uploadOnboardUsersTemplate(fileToUpload, type): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") + '","locale":"' +
          this.localVal +
          '"}',
      }),
    };
    let url;
    if (type == "USERS") {
      url = this.EMSURL + `user/admin/uploadCSV`;
    } else if (type == "DEPARTMENTS") {
      url = this.EMSURL + `department/admin/uploadCSV`;
    } else if (type == "LOCATIONS") {
      url = this.EMSURL + `zones/admin/uploadCSV`;
    }
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, httpOptions);
  }
  onBoardGlobalAdmin(data) {
    let url;
    url = this.EMSURL + `user/admin/addUserV2`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  loadDepartments(): Observable<any> {
    let url = this.EMSURL + `department/admin/getAll`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  resendEmail(userId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") +
          '"}',
      }),
    };
    let url =
      this.EMSURL + `user/admin/globalAdmin/resendEmail?userId=${userId}`;
    return this.http.post<any>(
      url,
      {
        params: new ZoneAndModuleHttpParams(null, this.moduleId),
      },
      httpOptions
    );
  }

  onBoardEditGlobalAdmin(data) {
    let url;
    url = this.EMSURL + `user/admin/editUserDetails`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  addUserManually(data) {
    let url;
    url = this.EMSURL + `user/admin/addUserV2`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  updateGlobalSetupUsers(data,teamsVersion?) {
    let url;
    let teamsFlow;
    if(teamsVersion){
      teamsFlow = "?teamsFlow=true";
    }
    //Based on teams we need to call ?teamsFlow=true
    url = this.EMSURL + `user/admin/editUserDetails${teamsFlow}`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
  updateGlobalSetupUsersNonTeams(data) {
    let url;
    let teamsFlow;
    
    //Based on teams we need to call ?teamsFlow=true
    url = this.EMSURL + `user/admin/editUserDetails`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  onBoardUserGlobalAdmin(userId) {
    let url;
    url = this.EMSURL + `user/admin/globalAdmin/add?userId=${userId}`;
    return this.http.post<any>(
      url,
      {},
      {
        params: new ZoneAndModuleHttpParams(null, this.moduleId),
      }
    );
  }

  getAllRoles(zoneId) {
    const endpoint = "roles/getAll";
    return this.http.get(this.EMSURL + endpoint, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getEmployeeNameAutoCompleteList(data, zoneId): Observable<any> {
    // https://digitaldev.smartenspaces.com/ems/user/globaladmin/getADUsers?queryString=a&tenantId=52c3e891-2977-4ae8-9d90-e21944f628ff
    let term = data.searchTerm.trim();
    if (term == "" || term.length <= 2) {
      return of([]);
    }
    // let url = `${this.EMSURL}user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
    let url = `${this.EMSURL}user/globaladmin/getADUsers?queryString=${term}`;
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe(
        map((res) => res["response"]),
        catchError((err) => of([]))
      );
  }

  getCountryCodesGlobalAdmin(moduleId, zoneId) {
    let url = this.EMSURL + "zones/mobileCountryCodes/v2";
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"correlationId":"${uuidv4()}","version":"1.0","clientKey":"ADMIN_WEB_APP","mouduleId": "${
          this.moduleId
          }","sessionId": "${localStorage.getItem("ssadmin_session")}","locale": "${this.localVal}"}`,
      }),
    };
    return this.http.get<any>(url, httpOptions);
  }

  downloadUserBulkUploadTemplate(value:any) {
    let url = this.EMSURL + `user/downloadTemplateBulkUpload`;
    const headers = new HttpHeaders({
      "ss-header":
        '{"correlationId":"' +
        uuidv4() +
        '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
        localStorage.getItem("ssadmin_session") + '","locale":"' + localStorage.getItem("language") +
        '"}'
    })

    // responseType: 'blob' is for xlsx file conversion
    return this.http.post(url, value,{headers: headers, responseType: 'blob'});
  }

  validateExcelUser(fileToUpload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") + '","locale":"' +this.localVal +
          '"}'
      })
    };
    let url = this.EMSURL +`user/validateBulkUpload`;
    var formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, httpOptions);
  }

  saveBulkUploadUserData(data) {
    let url = this.EMSURL + `user/saveBulkUpload`;
    return this.http.post<any>(url,data,
      {
        params: new ZoneAndModuleHttpParams(null, this.moduleId),
      }
    );
  }
}
