import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ModuleidService } from 'src/app/services/moduleid-service';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class CuratedServicesService {

  moduleId;
  BASEURL;
  EMSURL;
  BASEEMSURL;
  constructor(
      public http: HttpClient,
      @Inject("BASE_URL") baseUrl: string,
      public moduleidService: ModuleidService
  ) {
      this.BASEURL = baseUrl;
      this.EMSURL = environment.BASE_URL + "ems/";
      this.BASEEMSURL = environment.BASE_URL;
      this.moduleId = moduleidService.getModueId();
  }

  saveCuratedSerivces(zoneId, data) {
    console.log(zoneId);
    let url = this.EMSURL + `curated-services/create`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getCuratedServices(zoneId,data){
    // console.log(zoneId);
    let url
    if(data && data.type && data.type == "SEARCH"){
      url= this.EMSURL + `curated-services/super-admin/get?search=${data.searchString}`;
    }else{
     url= this.EMSURL + `curated-services/super-admin/get`;
    }
    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
      });
  }
 
  getCuratedServicesGU(zoneId,data){
    let url
    if(data && data.type && data.type == "SEARCH"){
      url= this.EMSURL + `curated-services/general-user/get?search=${data.searchString}`;
    }else{
     url= this.EMSURL + `curated-services/general-user/get`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
          "ss-header":
              '{"correlationId":"' + uuidv4() + '","version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":' + zoneId +
          ',"moduleId":12001,"sessionId":"' + localStorage.getItem('ssadmin_session') + '"}'
      })
  };
  
  return this.http.get<any>(url,httpOptions); // return this.http.get<any>(url,httpOptions);
  //   return this.http
  //     .get<any>(url, {
  //       params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)
  //     });
      
  }

  saveCuratedOrder(zoneId, data){
    let url = this.EMSURL + `curated-services/set-priority`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  updateCuratedServices(zoneId, data){
    let url = this.EMSURL + `curated-services/update`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
}


