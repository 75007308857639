import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, of, BehaviorSubject, throwError } from "rxjs";
import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { ModuleidService } from 'src/app/services/moduleid-service';
import { environment } from 'src/environments/environment';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root'
})


export class SelfAssessmentService {
    moduleId;
    BASEURL;
    selectedSelfAssessmentSub$ = new BehaviorSubject<any>(null);
    selectedSelfAssessmentItem = this.selectedSelfAssessmentSub$.asObservable();

    constructor(
        public http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        public moduleidService: ModuleidService
      ) {
        this.BASEURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
        this.moduleId = 9;
      }

      saveCustomCheckList(zoneId,data){
        return this.http.post(this.BASEURL + "communityV2/assessment/create", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }
    
      getAllCustomCheckList(zoneId){
        return this.http.get(this.BASEURL + "communityV2/assessment/all-active?page=0&size=100000&customKey=HEALTH_ASSESSMENT",{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      deleteCustomCheckList(zoneId,data){
        return this.http.post(this.BASEURL + "communityV2/assessment/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      updateCustomCheckList(zoneId,data){
        return this.http.post(this.BASEURL + "communityV2/assessment/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

     
      getAllUsers(zoneId,pageIndex=0,pazeSize=10,data){
        let searchUserID = '';
        if(!!data.userId && data.userId != ''){
          searchUserID = '&userId='+data.userId
        }else{
          searchUserID = '';
        }
        return this.http.get(this.BASEURL + "communityV2/assessment/user-responses?startTimeDate="+data.selectedDate+"&endTimeDate="+data.endedDate+searchUserID+"&page="+pageIndex+"&size="+10000, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
        //return this.http.get(this.BASE_URL + "communityV2/assessment/user-responses?userId=600&startTimeDate=1531714878520&page=0&size=10&endTimeDate=1561714878520", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      public getUserList(data, zoneId):Observable<any> {
        let url = `${this.BASEURL}ems/user/search/name?q=${data['q']}&page=${data['page']}&size=${data['size']}`;
        return this.http.get(url, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
      }

      downloadCsv(zoneId,data){
        //return this.http.get(this.BASE_URL + "communityV2/assessment/user-responses/csv?userId=111&page=0&size=10&startTimeDate=1&endTimeDate=9999999999999",{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
        let searchUserID = '';
        if(!!data.userId && data.userId != ''){
          searchUserID = '&userId='+data.userId
        }else{
          searchUserID = '';
        }
        return this.http.get(this.BASEURL + "communityV2/assessment/user-responses/csv?startTimeDate="+data.selectedDate+"&endTimeDate="+data.endedDate+searchUserID, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId), responseType: 'blob'} );
       
      }

      temperatureCheck(zoneId){
        return this.http.get(this.BASEURL+"ems/module/getConfig?moduleId=135&zoneId="+zoneId, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
      }

      getQuestions(userId,zoneId){
        return this.http.get(this.BASEURL + `communityV2/assessment/user-assessment/other-user?userId=${userId}`,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }
    
      submitQuestions(data,zoneId){
        return this.http.post(this.BASEURL + "communityV2/assessment/submit/other-user",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      getTemperature(userId,zoneId){
        return this.http.get(this.BASEURL+"communityV2/assessment/user-assessment/temperature/otherusers?userId="+userId, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
      }

      saveTemperature(data,zoneId){
        return this.http.post(this.BASEURL + "communityV2/assessment/submit/temperature/other-user",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      getAssesmentOxygenReading(userId,zoneId){
        return this.http.get(this.BASEURL+"communityV2/assessment/user-assessment/oxygenreading/otherusers?userId="+userId, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});

     }

     submitAssesmentOxygenReading(data,zoneId){
      return this.http.post(this.BASEURL + "communityV2/assessment/submit/oxygenreading/other-user",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    }  

    updateNotificationTime(zoneId,data){

      return this.http.post(this.BASEURL + "communityV2/assessment/updateNotificationTime",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    
    }

    removeNotification(zoneId){

      return this.http.post(this.BASEURL + "communityV2/assessment/removeNotifications",'',{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    
    }

    getTemperaturConfigUpdate(data,zoneId){
  
        let datas = {
          "config": JSON.stringify(data),
          "moduleId": 135,
          "zoneId": zoneId
        }
        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":135,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
          })
        };
        return this.http.post(this.BASEURL+"ems/module/updateConfig?generate=true",datas,httpOptions);
      
      }


      getTemperaturUpdate(data,zoneId){

        return this.http.post(this.BASEURL + "communityV2/assessment/update/temperature",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      getTemperaturCreate(data,zoneId){

        return this.http.post(this.BASEURL + "communityV2/assessment/create/temperature",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      upadateEmailAddress(data,zoneId){

          let features = {
            "config": JSON.stringify(data),
            "moduleId": 135,
            "zoneId": zoneId
          }
          const httpOptions = {
            headers: new HttpHeaders({        
              'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":135,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
            })
          };
          return this.http.post(this.BASEURL+"ems/module/updateConfig?generate=true",features,httpOptions);
        }

      upadateSelfAssessmentVaccination(data,zoneId){

          let features = {
            "config": JSON.stringify(data),
            "moduleId": 135,
            "zoneId": zoneId
          }
          const httpOptions = {
            headers: new HttpHeaders({        
              'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":135,"sessionId":"'+localStorage.getItem('ssadmin_session')+'"}'
            })
          };
          return this.http.post(this.BASEURL+"ems/module/updateConfig?generate=true",features,httpOptions);
        }

        getTemperatureByZone(zoneId){

          return this.http.get(this.BASEURL+"communityV2/custom-field/all-active?customKey=TEMPERATURE_READING", {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
        }

        createOxygenLevel(data,zoneId){
 
        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":135,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
          })
        };
        return this.http.post(this.BASEURL + "communityV2/assessment/create/oxygenreading",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });

      };
        uploadVaccination(data,zoneId){
 
        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":'+this.moduleId+',"sessionId":"'+localStorage.getItem('ssadmin_session')+'"}'
          })
        };
        return this.http.post(this.BASEURL + "communityV2/assessment/create/vaccination",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });

      };

      updateVaccinationStatus(data,zoneId){
 
        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":'+this.moduleId+',"sessionId":"'+localStorage.getItem('ssadmin_session')+'"}'
          })
        };
        
        // if(data.status == "APPROVED") {
        //   return this.http.put(this.BASEURL + "communityV2/assessment/submit/vaccination/status?id="+data.id+"&status=APPROVED",{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) }, httpOptions);
        // } else {
          return this.http.put(this.BASEURL + "communityV2/assessment/submit/vaccination/status?id="+data.id+"&status="+data.status,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) }, httpOptions);
        // }
      };

      updateOxygenLevel(data,zoneId){

        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":135,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
          })
        };
        return this.http.post(this.BASEURL + "communityV2/assessment/update/oxygenreading",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });


      }

      updateUploadVaccination(data,zoneId){

        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":135,"sessionId":"'+localStorage.getItem('ssadmin_session')+'"}'
          })
        };
        return this.http.post(this.BASEURL + "communityV2/assessment/update/vaccination",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      getOximetry(zoneId){

        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":135,"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
          })
        };
        return this.http.get(this.BASEURL + "communityV2/assessment/user-assessment/oxygenreading",{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      getUploadVaccination(zoneId){
        return this.http.get(this.BASEURL + "communityV2/assessment/user-assessment/vaccination",{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      getApproveVaccination(zoneId){

        const httpOptions = {
          headers: new HttpHeaders({        
            'ss-header': '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":'+zoneId+', "moduleId":'+this.moduleId+',"sessionId":"'+localStorage.getItem('ssadmin_session')+'"}'
          })
        };
        return this.http.get(this.BASEURL + "communityV2/assessment/user-assessment/vaccination",{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      getAllVaccinationUsers(zoneId, pageIndex = 0, pazeSize = 10, data){
        let url = this.BASEURL + `communityV2/assessment/all-active/vaccination?page=${pageIndex}&size=${pazeSize}&startTime=${data.startTime}&endTime=${data.endTime}`;
        if(!!data['userId']){
          url +='&userId='+data['userId'];
        }
        return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      resendChecklist(zoneId, id){

        return this.http.post(this.BASEURL + "communityV2/assessment/resent", id, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      getSaNotificationTime(zoneId){

        return this.http.get(this.BASEURL + "communityV2/assessment/getSaNotificationTime",{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      saveCustomMessage(data: any, currentZone: any) {
        let url = this.BASEURL + `communityV2/custommessage/create`;
        return this.http.post(url,data,
          { params: new ZoneAndModuleHttpParams(currentZone,135)}
        );
      }

      updateCustomMessage(data: any, currentZone: any) {
        let url = this.BASEURL + `communityV2/custommessage/update`;
        return this.http.put(url,data,
          { params: new ZoneAndModuleHttpParams(currentZone,135)}
        );
      }

      getCustomMessageSA(customKey: string, currentZone: any) {
        let url = this.BASEURL + `communityV2/custommessage/getByZoneIdCustomKey?zoneId=${currentZone}&customKey=${customKey}`;
        return this.http.get(url,
          { params: new ZoneAndModuleHttpParams(currentZone,135)}
        );
      }

      submitVaccination(data,zoneId){
        let url = this.BASEURL + "communityV2/assessment/submit/vaccination"
        return this.http.post(url,data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      reSubmitVaccination(data,zoneId){
        let url = this.BASEURL + "communityV2/assessment/submit/vaccination/url"
        return this.http.put(url,data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      saveAutoDelete(data: any, currentZone: any) {
        let url = this.BASEURL + `communityV2/autodelete/create`;
        return this.http.post(url,data,
          { params: new ZoneAndModuleHttpParams(currentZone,135)}
        );
      }

      updateAutoDelete(data: any, currentZone: any) {
        let url = this.BASEURL + `communityV2/autodelete/update`;
        return this.http.put(url,data,
          { params: new ZoneAndModuleHttpParams(currentZone,135)}
        );
      }

      getAutoDelete(customKey: string, currentZone: any) {
        let url = this.BASEURL + `communityV2/autodelete/getByZoneIdCustomKey?zoneId=${currentZone}&customKey=${customKey}`;
        return this.http.get(url,
          { params: new ZoneAndModuleHttpParams(currentZone,135)}
        );
      }

      getEmployeeNameAutoCompleteList(data, zoneId): Observable<any> {
        let term = data.searchTerm.trim();
        if (term == "" || term.length <= 2) {
          return of([]);
        }
        let url = `${this.BASEURL}ems/user/search/v2?searchString=${term}&searchParams=name,email,phone_num`;
        return this.http
          .get<any>(url, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
          })
          .pipe(
            map((res) => res["response"]),
            catchError((err) => of([]))
          );
      }

      getVaccinationForOthers(userId,zoneId){
        return this.http.get(this.BASEURL + `communityV2/assessment/user-assessment/vaccination/otherusers?userId=${userId}`,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      submitVaccinationForOthers(data,zoneId){
        return this.http.post(this.BASEURL + "communityV2/assessment/submit/vaccination/other-user",data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }

      uploadImage(file,zoneId){
        let body = new FormData();
        body.append('file', file);
        return this.http.post(this.BASEURL + "ems/media/uploadMedia?targetFolder=Test",body,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
      }
      downloadVaccinationCSV(data,zoneId){
        let url = this.BASEURL + `communityV2/assessment/vaccination/csv?startTimeDate=${data.startTime}&endTimeDate=${data.endTime}`;
        if(data.userId!=null){
          url +=`&userId=${data.userId}`;
        }
        return this.http.get(url,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId), responseType: 'blob'});
      }
 }
