import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';


import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from 'commonService';
import { environment } from 'src/environments/environment';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';



@Injectable()
export class EventCatService {

  private moduleId;
  private readonly BASE_URL;
  private readonly BASE_URL_Tenant;

  constructor(private http: HttpClient, public commonService: CommonService) {
    this.moduleId = 5; 
    this.BASE_URL = environment.BASE_URL ;
    this.BASE_URL_Tenant = environment.BASE_URL + 'tms/tenant/digital/';
  }

  getAllCategory( zoneId ,pageNo, size ) {
    //?page=${pageNo}&size=${size} /page?page=${pageNo}&size=${size}` tms/tenant/name/list?page=${pageNo}&size=${size}&sort=createdAt,desc
    return this.http.get(this.BASE_URL_Tenant + `getAll/page?page=${pageNo}&size=${size}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  getAllCategoryDownloadData(zoneId){
    return this.http.get(this.BASE_URL_Tenant + `getAll`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  addCatedory(data,zoneId){
    return this.http.post(this.BASE_URL + "tms/tenant/digital/saveAll", data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      });
  }
 
  addCatedorySingle(data,zoneId){
    let data1={name:data}
    return this.http.post(this.BASE_URL + `tms/tenant/digital/save`, data1, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      });
  }
  updateCategory(data,zoneId,id,name){
    let data1={name:name , tenant_id:id}
    return this.http.post(this.BASE_URL + `tms/tenant/digital/edit`, data1, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      });
  }
  downloadViaExcelTemplate(zoneId){
    const endpoint = this.BASE_URL + "tms/downloadTenantTemplateBulkUpload";
    const data=null
    return this.http.post(endpoint,data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) ,responseType: 'blob' });
  }

  validateExcelOnboarding(fileToUpload,fileName,zoneId){
    const endpoint = this.BASE_URL + "tms/tenantValidateBulkUpload";
    var formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileName);
    return this.http.post(endpoint,formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllCategoryByName( zoneId ,pageNo, size,name ) {
    return this.http.get(this.BASE_URL + `tms/tenant/digital/search?name=${name}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  delCategory(id,zoneId,flag){
    return this.http.delete(this.BASE_URL + `ems/user/tenant/digital/delete?tenantId=${id}&validate=${flag}`, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

}