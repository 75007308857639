import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from 'commonService';
import { environment } from 'src/environments/environment';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';



@Injectable()
export class TenantPeopleService {

  private moduleId;
  private readonly BASE_URL;
  private readonly BASE_URL_VMS;
  BASE_URL_Tenant
  EMSURL
  constructor(private http: HttpClient, public commonService: CommonService) {
    this.moduleId = 5; 
    this.BASE_URL = environment.BASE_URL ;
    this.BASE_URL_VMS = environment.BASE_URL + 'vms/';
    this.BASE_URL_Tenant = environment.BASE_URL + 'tms/tenant/digital/';
    this.EMSURL = environment.BASE_URL + "ems/";
  }
  getAllTenant( zoneId ,pageNo, size ) {
    //?page=${pageNo}&size=${size}
    return this.http.get(this.BASE_URL_Tenant + `getAll`, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
  addUserManually(data,internal) {
    let url;
    url = this.EMSURL + `user/admin/tenantFlowUser/add?internal=${internal}`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
  updateUser(data,internal) {
    let url;
    url = this.EMSURL + `user/admin/editUserDetails/tenantFlow?internal=${internal}`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
  verifyEmail(email){
    let url;
    url = this.EMSURL + `user/emailValidation?email=${email}`;
    return this.http.get(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });



  }
  edtUserDetails(id){
    let url;
    url = this.EMSURL + `user/getById?id=${id}`;
    return this.http.get(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
  disbaleUser(zoneId,data){
        

    let url;
    url = this.EMSURL + `user/admin/changeStatus`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
  cancelBooking(data){
        

    let url;
    url = this.BASE_URL + `amenitybooking/booking/cancelBookings`;
    return this.http.put<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
  loadPeople(data: any, isTenant): Observable<any> {
    let url = this.EMSURL;
    if (!!data.searchValue) {
        url =
            url +
            `user/admin/tenantFlowUser/getAll?searchString=${data.searchValue}&searchBy=${data.searchBy}&page=${data.pageIndex}&size=${data.pageSize}`;
    } else {
        url =
            url +
            `user/admin/tenantFlowUser/getAll?page=${data.pageIndex}&size=${data.pageSize}`;
     
    }

    return this.http.get<any>(url, {
        params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
}
  updateGlobalSetupUsersNonTeams(data,internal) {
    let url;
    let teamsFlow;
    
    //Based on teams we need to call ?teamsFlow=true
    url = this.EMSURL + `user/admin/editUserDetails?internal=${internal}`;
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }


  downloadUsersCsv(zoneId,): Observable<any> {
    let url = this.EMSURL + `user/admin/getAllUserDetailsCsv/tenant`;
   
    return this.http.post<any>(url, {}, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }
  saveBulkUpload(data,zoneId){
    return this.http.post(this.BASE_URL + "ems/user/saveBulkUpload/global/tenant", data, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      });
  }
 
 
  
  downloadViaExcelTemplate(data={}){
    const endpoint = this.BASE_URL + "tms/downloadUserTemplateBulkUpload/global";
    
    return this.http.post(endpoint,data, { params: new ZoneAndModuleHttpParams(null, this.moduleId) ,responseType: 'blob' });
  }

  validateExcelOnboarding(fileToUpload,fileName,zoneId){
    const endpoint = this.BASE_URL + "ems/user/validateBulkUpload/global/tenant";
    var formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileName);
    return this.http.post(endpoint,formData, { params: new ZoneAndModuleHttpParams(null, this.moduleId) });
  }


}