import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar, MatDialog, MatDialogRef } from "@angular/material";
import { HttpClientModule } from "@angular/common/http";
import "rxjs/add/operator/map";


import "rxjs/add/operator/toPromise";
import { Observable, Subject, throwError } from "rxjs";
import { environment } from "../../environments/environment";

import { tap, shareReplay, map, catchError } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { ZoneAndModuleHttpParams } from "../shared/zone-and-module-http-params/zone-and-module-http-params.component";

import { v4 as uuidv4 } from "uuid";
import * as moment from "moment";
@Injectable()
export class CommonService {
  dialogRef;
  private moduleId;
  private permitterModules;
  private readonly BASE_URL;
  selectedDate = null;

  constructor(
    public matSnackBar: MatSnackBar,
    public http: HttpClient,
    public dialog: MatDialog
  ) {
    this.BASE_URL = environment.BASE_URL + "ems/";
  }

  private currentZoneSource = new BehaviorSubject(
    localStorage.getItem("currentZone")
  );
  private defaultZoneSource = new BehaviorSubject(
    localStorage.getItem("defaultZone")
  );
  defaultZone = this.defaultZoneSource.asObservable();
  private currentTimeZoneSource = new BehaviorSubject(
    localStorage.getItem("currentTimeZone"));
  currentZone = this.currentZoneSource.asObservable();
  currentTimeZone = this.currentTimeZoneSource.asObservable();

  private myProfile = new BehaviorSubject([]);
  getCurrentUserModules = this.myProfile.asObservable();

  private config48 = new BehaviorSubject({});
  getConfig48 = this.config48.asObservable();

  private loggedInSub = new BehaviorSubject(localStorage.getItem("pageLoaded"));
  loggedInObs = this.loggedInSub.asObservable();

  private isGlobalAdminSource = new BehaviorSubject(
    localStorage.getItem("ssadmin_globalAdmin")
  );
  isGlobalAdmin = this.isGlobalAdminSource.asObservable();


  private roleSource = new BehaviorSubject([]);
  currentRoles = this.roleSource.asObservable();

  private currentPageHeaderSource = new BehaviorSubject("SAAS-ADMIN-DASHBOARD");
  currentHeader = this.currentPageHeaderSource.asObservable();

  private siteSelectionFlagSource = new BehaviorSubject(true);
  currentSiteSelectionFlag = this.siteSelectionFlagSource.asObservable();

  // Jumpree Digital Global Setup Enabled
  private isGlobalSetupEnabled = new BehaviorSubject(false);
  isGlobalSetupEnabledType = this.isGlobalSetupEnabled.asObservable();

  /*****Update header title start *******/
  private headerTitle = new BehaviorSubject("Dashboard");
  currentHeaderTitle = this.headerTitle.asObservable();

  private curatedService = new BehaviorSubject<any>(null);
  curatedServiceSidebar$ = this.curatedService.asObservable();

  private bookingModule = new BehaviorSubject<any>(null);
  bookingModuleSidebar$ = this.bookingModule.asObservable();

  private workStatus = new BehaviorSubject<any>(null);
  workStatusSidebar$ = this.workStatus.asObservable();

  private introPopupModules = new BehaviorSubject<any>(null);
  introPopupModules$ = this.introPopupModules.asObservable();

  private assigneeDepartmentDays = new BehaviorSubject<boolean>(false);
  assigneeDepartmentDays$ = this.assigneeDepartmentDays.asObservable();

  updateAssigneeDepartmentDays(message:boolean){
    this.assigneeDepartmentDays.next(message)
  }
  
  private newTagGroupBooking = new BehaviorSubject<boolean>(false);
  newTagGroupBooking$ = this.newTagGroupBooking.asObservable();

  updateNewTagGroupBooking(message: boolean) {
    this.newTagGroupBooking.next(message);
  }

  updateApprovalMessage(message: string) {
    this.headerTitle.next(message);
  }
  /*****Update header title end *******/

  private analyticaDataDigital = new BehaviorSubject([]);
  analyticsDigitalDataItem = this.analyticaDataDigital.asObservable();
  analyticsDigitalData(message:any) {
    this.analyticaDataDigital.next(message);
  }

  /*****Update header title start *******/
  private bgChangeAmenityBooking = new BehaviorSubject("false");
  currentBgChange = this.bgChangeAmenityBooking.asObservable();

  updateBgChangeAmenityBooking(message: string) {
    this.bgChangeAmenityBooking.next(message);
  }
  /*****Update header title end *******/

  // Get Digital Active Intro Screen
  private messageSource = new BehaviorSubject("");
  currentMessage = this.messageSource.asObservable();

  changeZone(zone) {
    localStorage.setItem("currentZone", zone);
    this.currentZoneSource.next(zone);
  }
  changeDefaultZone(zone) {
    localStorage.setItem("defaultZone", zone);
    this.defaultZoneSource.next(zone);
  }

  userLoggedIn() {
    this.loggedInSub.next("true");
  }

  globalAdmin(status) {
    this.isGlobalAdminSource.next(status);
  }

  changeTimeZone(zone) {
    localStorage.setItem("currentTimeZone", zone);
    this.currentTimeZoneSource.next(zone);
  }

  getFnameLname(fname, lname) {
    let fName = fname ? fname.charAt(0).trim().toUpperCase() : "";
    let lName = lname ? lname.charAt(0).trim().toUpperCase() : "";
    let name = fName + " " + lName;
    return name;
  }

  changeModuleId(moduleId) {
    this.currentModuleIdSource.next(moduleId);
  }

  changePageHeader(pageHeader: string) {
    this.currentPageHeaderSource.next(pageHeader);
  }

  changeSiteSelectionFlag(siteSelectionFlag) {
    this.siteSelectionFlagSource.next(siteSelectionFlag);
  }

  GlobalSetupEnabled(siteSelectionFlag) {
    this.isGlobalSetupEnabled.next(siteSelectionFlag);
  }

  passRoles(roles) {
    this.roleSource.next(roles);
  }
  setCurrentUserModules(res) {
    this.myProfile.next(res);
  }

  setConfig48(res) {
    this.config48.next(res);
  }
  setCuratedServiceSidebar(data: any) {
    this.curatedService.next(data);
  }
  setCuratedBookingModule(data: any) {
    this.bookingModule.next(data);
  }
  setWorkStatusSidebar(data: any) {
    this.workStatus.next(data);
  }
  setIntroPopupModules(data: any) {
    this.introPopupModules.next(data);
  }
  private currentModuleIdSource = new BehaviorSubject(1);
  currentModuleId = this.currentModuleIdSource.asObservable();

  openSnackBar(message: string, action: string) {
    this.matSnackBar.open(message, action, {
      duration: 4000,
      // panelClass: msgClass,
      // horizontalPosition: 'center',
      // verticalPosition: 'top',
    });
  }

  //ss-header methods
  getSessionId() {
    return localStorage.getItem("ssadmin_session");
  }

  setModuleId() {
    this.currentModuleId.subscribe((moduleId) => {
      this.moduleId = moduleId;
    });
  }

  getModuleId() {
    return this.moduleId;
  }
  getWindowBaseUrl() {
    let href = window.location.href;
    let res = href.split("/");
    let retUrl = res[0] + '//' + res[2] + '/';
    /// console.log('retUrl = '+retUrl);
    return retUrl;
      //return 'https://vaccinetesting.smartenspaces.com/';
  }
  getConfig(zoneId, moduleId) {
    //{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"sessionId":"{{sessionId}}", "moduleId":102}
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":${moduleId},"sessionId": "${localStorage.getItem(
          "ssadmin_session"
        )}","correlationId":"${uuidv4()}"}`,
      }),
    };
    return this.http
      .get(this.BASE_URL + "module/config", httpOptions)
      .map(function (response) {
        return response;
      });
  }

  getConfigDigitalVersion(moduleId) {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let session_Id = localStorage.getItem("ssadmin_session");
    //let enterpriseId = '';
    let headerObj;
    console.log('module id 5 ',moduleId)

    if (userObj != null && !!session_Id) {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP", "moduleId":${moduleId}, "enterpriseId": ${userObj.enterpriseId}}`,
      };
    } else {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP", "moduleId": ${moduleId} }`,
      };
    }
    const httpOptions = {
      headers: new HttpHeaders(headerObj),
    };
    return this.http
      .get(this.BASE_URL + "module/config", httpOptions)
      .map(function (response) {
        let configDataSiewise = JSON.parse(response["response"]);
        if (configDataSiewise["version"] && (
          configDataSiewise["version"]["deploymentType"] == "TEAMS" ||
          configDataSiewise["version"]["deploymentType"] == "DIGITAL"
        )) {
          return true;
        } else {
          return false;
        }
      });
  }

  getConfigByModuleId(moduleId) {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let session_Id = localStorage.getItem("ssadmin_session");
    //let enterpriseId = '';
    let headerObj;
    console.log('module id 4',moduleId)

    if (userObj != null && !!session_Id) {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP", "moduleId":${moduleId}, "enterpriseId": ${userObj.enterpriseId}}`,
      };
    } else {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP", "moduleId": ${moduleId} }`,
      };
    }
    const httpOptions = {
      headers: new HttpHeaders(headerObj),
    };
    return this.http
      .get(this.BASE_URL + "module/config", httpOptions)
      .map(function (response) {
        return response;
      });
  }
  getConfigBykey() {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let session_Id = localStorage.getItem("ssadmin_session");
    //let enterpriseId = '';
    let headerObj;

    if (userObj != null && !!session_Id) {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP", "enterpriseId": ${userObj.enterpriseId}}`,
      };
    } else {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP"}`,
      };
    }
    const httpOptions = {
      headers: new HttpHeaders(headerObj),
    };
    return this.http
      .get(this.BASE_URL + "config/getByKey?key=Tenant_Enagagement", httpOptions)
      .map(function (response) {
        return response;
      });
  }

  getGroupBookingConfig(){
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let session_Id = localStorage.getItem("ssadmin_session");
    let headerObj;

    if (userObj != null && !!session_Id) {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP", "enterpriseId": ${userObj.enterpriseId}}`,
      };
    } else {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP"}`,
      };
    }
    const httpOptions = {
      headers: new HttpHeaders(headerObj),
    };
    return this.http
      .get(this.BASE_URL + "config/getByKey?key=Group_Booking", httpOptions)
      .map(function (response) {
        return response;
      });
  }

  getConfigByZoneIdAndModuleId(moduleId, zoneId) {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let headerObj;
console.log('module idf 3' , moduleId)
    if (userObj != null) {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":${moduleId},"zoneId":${zoneId}, "enterpriseId":${
          userObj.enterpriseId
        },"correlationId":"${uuidv4()}"}`,
      };
    } else {
      headerObj = {
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP", "moduleId":${moduleId},"zoneId":${zoneId},"correlationId":"${uuidv4()}"}`,
      };
    }
console.log(headerObj)
    const httpOptions = {
      headers: new HttpHeaders(headerObj),
    };
    return this.http
      .get(this.BASE_URL + "module/config", httpOptions)
      .map(function (response) {
        return response;
      });
  }

  getConfigNewDomainFirstSource(zoneId, moduleId) {
    //{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"sessionId":"{{sessionId}}", "moduleId":102}
    console.log('module id 1 ',moduleId)
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":${moduleId},"correlationId":"${uuidv4()}"}`,
      }),
    };
    return this.http
      .get(this.BASE_URL + "module/config", httpOptions)
      .map(function (response) {
        return response;
      });
  }

  setPermittedModules(permittedModules) {
    this.permitterModules = permittedModules;
  }

  getPermittedModules() {
    return this.permitterModules;
  }

  getNthIndex(str, ch, nth) {
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) == ch) {
        if (!--nth) {
          return i;
        }
      }
    }
    return false;
  }

  timeConverter(UNIX_timestamp) {
    let a = new Date(parseInt(UNIX_timestamp));
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let date =
      a.getDate().toString().length <= 1
        ? 0 + a.getDate().toString()
        : a.getDate().toString();
    let month =
      (a.getMonth() + 1).toString().length <= 1
        ? 0 + (a.getMonth() + 1).toString()
        : (a.getMonth() + 1).toString();
    let year = a.getFullYear();
    let monthString = months[a.getMonth()];
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let timeStampObj: any = {};
    timeStampObj.UNIXtimeStamp = a;
    timeStampObj.date = date;
    timeStampObj.monthString = monthString;
    timeStampObj.month = month;
    timeStampObj.year = year;
    timeStampObj.hour = hour;
    timeStampObj.min = min;
    timeStampObj.sec = sec;

    return timeStampObj;
  }
  getTimeStamp(dateObj, type = "start") {
    let timeStampObj = this.timeConverter(dateObj);
    if (type === "start") {
      return new Date(
        `${timeStampObj.year}-${timeStampObj.month}-${timeStampObj.date}T00:00:00.000+00:00`
      ).getTime();
    } else {
      return new Date(
        `${timeStampObj.year}-${timeStampObj.month}-${timeStampObj.date}T23:59:59.000+00:00`
      ).getTime();
    }
  }

  //Get site specific timezone in UTC timestamp
  getTimeStampTimeZone(dateObj, timeZone, type = "start") {
    let localDate = new Date(dateObj);
    dateObj = new Date(
      localDate.toLocaleString("en-US", { timeZone: timeZone })
    ).getTime();
    let timeStampObj = this.timeConverter(dateObj);
    if (type === "start") {
      return new Date(
        `${timeStampObj.year}-${timeStampObj.month}-${timeStampObj.date}T00:00:00.000+00:00`
      ).getTime();
    } else {
      return new Date(
        `${timeStampObj.year}-${timeStampObj.month}-${timeStampObj.date}T23:59:59.000+00:00`
      ).getTime();
    }
  }

  //Return date obj instead of timestamp
  getTimeStampTimeZoneInDateObj(dateObj, timeZone, type = "start") {
    let localDate = new Date(dateObj);
    dateObj = new Date(
      localDate.toLocaleString("en-US", { timeZone: timeZone })
    ).getTime();
    let timeStampObj = this.timeConverter(dateObj);
    if (type === "start") {
      return new Date(
        `${timeStampObj.year}-${timeStampObj.month}-${timeStampObj.date}T00:00:00.000+00:00`
      );
    } else {
      return new Date(
        `${timeStampObj.year}-${timeStampObj.month}-${timeStampObj.date}T23:59:59.000+00:00`
      );
    }
  }

  //Get UTC specific timezone, Nothing related to site
  getUTCTimeStampTimeZone(dateObj, timeZone, type = "start") {
    let localDate = new Date(dateObj);
    dateObj = new Date(
      localDate.toLocaleString("en-US", { timeZone: 'UTC' })
    ).getTime();
    let timeStampObj = this.timeConverter(dateObj);
    if (type === "start") {
      let UTClocalDate = new Date(`${timeStampObj.year}-${timeStampObj.month}-${timeStampObj.date}T00:00:00.000+00:00`);
      return UTClocalDate.getTime();
    } else {

      let UTClocalDate = new Date(`${timeStampObj.year}-${timeStampObj.month}-${timeStampObj.date}T23:59:59.000+00:00`);
      return new Date(
        UTClocalDate.toLocaleString("en-US", { timeZone: 'UTC'})
      ).getTime();
    }
  }

  isCurrentComponentRouteSame(routerUrl: string, currentRoute: string) {
    routerUrl =
      routerUrl.indexOf("?") > 0
        ? routerUrl.substring(0, routerUrl.indexOf("?"))
        : routerUrl;
    return routerUrl === currentRoute;
  }

  paymentUpdateStatus(paymentId, razorpayPaymentId) {
    let url =
      "https://jumpree.smartenspaces.com/payment/transaction/update-status?";
    let queryParams =
      "PAYMENTID={payment_id}&razorpay_payment_id={razorpay_payment_id}"
        .replace("{payment_id}", paymentId)
        .replace("{razorpay_payment_id}", razorpayPaymentId);
    //http://10.1.20.135:7744/payment/transaction/update-status?PAYMENTID=1439&razorpay_payment_id=pay_ByWToo31WYf0m3
    return this.http.post(
      url + queryParams,
      {},
      { params: new ZoneAndModuleHttpParams(5, 2) }
    );
  }

  getCountryCodes(moduleId, zoneId) {
    return this.http.get(this.BASE_URL + "zones/mobileCountryCodes/v2", {
      params: new ZoneAndModuleHttpParams(zoneId, moduleId),
    });
  }

  closeOpenModals(openModalDialogs) {
    openModalDialogs.reverse().forEach((modal) => {
      modal.close();
    });
  }

  fetchS3Details(zoneId) {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId},"correlationId":"${uuidv4()}"}`,
      }),
    };
    return this.http.get(this.BASE_URL + "module/clientKeys", httpOptions);
  }

  getPrivateUrl = async (url) => {
    return url;
    /*
    let currentContext = this;
    let urlPart = url.split("/image/")[1];
    var params = {
      Bucket: localStorage.getItem("bucketName"),
      Key: `image/${urlPart}`,
    };
    var s3 = new AWS.S3({
      accessKeyId: localStorage.getItem("awsAccessKey"),
      secretAccessKey: localStorage.getItem("awsSecretKey"),
      region: localStorage.getItem("regionName"),
    });
    return new Promise((resolve, reject) => {
      let imageUrl = s3.getSignedUrlPromise("getObject", params);
      let testurl = imageUrl.then((imgURL) => {
        return imgURL;
      });
      resolve(testurl);
    });
    */
  };

  getPrivatePublicImage = async (url) => {
    return url;
    /*
    let currentContext = this;
    //let urlPart = url.split('/image/')[1];
    let urlPart = url.split("/");
    let arrKey1 = urlPart.slice(-2)[0];
    let arrKey2 = urlPart.slice(-2)[1];
    let keyVal = arrKey1 + "/" + arrKey2;
    var params = {
      Bucket: localStorage.getItem("bucketName"),
      Key: `${keyVal}`,
    };
    // var params = { Bucket: 'jumpreepreprodcommunity', Key: `image/${urlPart}`};
    if (localStorage.getItem("isPrivateURL") == "private") {
      try {
        var s3 = new AWS.S3({
          accessKeyId: localStorage.getItem("awsAccessKey"),
          secretAccessKey: localStorage.getItem("awsSecretKey"),
          region: localStorage.getItem("regionName"),
        });
        return new Promise((resolve, reject) => {
          s3.getObject(params, (err, data) => {
            if (err) {
              resolve(url);
            } else {
              let imageUrl = s3.getSignedUrlPromise("getObject", params);
              let testurl = imageUrl.then((imgURL) => {
                return imgURL;
              });
              resolve(testurl);
            }
          });
        });
      } catch (error) {
        return url;
      }
    } else {
      return url;
    }
    */

  };

  getCronExpression(type, options) {
    // For daily and weekly options need to be an array of days in format ["MON","TUE","WED","THU","FRI","SAT", "SUN"]
    // For Monthly options needs to the dayof the month for exampke 2nd day will gave option  = '2'
    let cronArray = [];
    switch (type.toLowerCase()) {
      case "daily":
        if (options.length == 7) {
          cronArray = ["59", "59", "23", "*", "*", "?", "*"];
        } else {
          var daily_days = options.join(",");
          cronArray = ["59", "59", "23", "?", "*", daily_days, "*"];
        }
        break;
      case "weekly":
        if (options.length == 7) {
          cronArray = ["59", "59", "23", "?", "*", "*", "*"];
        } else {
          var daily_days = options.join(",");
          cronArray = ["59", "59", "23", "?", "*", daily_days, "*"];
        }
        break;
      case "monthly":
        cronArray = ["59", "59", "23", options, "*", "?", "*"];
        break;
    }
    let cronExpression = cronArray.join(" ");
    return cronExpression;
  }

  parseCronForOptions(type, cron) {
    let cronArray = cron.split(" ");
    let options = [];
    switch (type.toLowerCase()) {
      case "daily":
        if (cronArray[3] === "*")
          options = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
        else {
          options = cronArray[5].split(",");
        }
        break;
      case "weekly":
        if (cronArray[5] === "*")
          options = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
        else {
          options = cronArray[5].split(",");
        }
        break;
      case "monthly":
        options = cronArray[3];
        break;
    }
    return options;
  }

  downloadCSV(res) {
    var hiddenElement = document.createElement("a");

    //hash symbol not taking when download
    //hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(res);
    //both hash symbol it is working
    hiddenElement.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(res);

    hiddenElement.target = "_blank";
    hiddenElement.download = "download.csv";
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
  }

  

  public userAutoLogin() {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"correlationId":"${uuidv4()}"}`,
      }),
    };
    return this.http.post(this.BASE_URL + "user/autoLogin", {}, httpOptions);
  }

  getConfigUpdate(reqObj, currentZone): Observable<any> {
    let url = `${this.BASE_URL}module/updateConfig?generate=true`;

    // const httpOptions = {
    //     headers: new HttpHeaders({
    //         "ss-header":
    //             '{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5}'
    //     })
    // };
    console.log('module id =' , reqObj['moduleId'])
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "ss-header": `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${currentZone},"moduleId":${
          reqObj["moduleId"]
        },"sessionId": "${localStorage.getItem(
          "ssadmin_session"
        )}","correlationId":"${uuidv4()}"}`,
      }),
    };
    return this.http.post(url, reqObj, httpOptions);
    // return this.http
    //     .post<any>(url, reqObj, {
    //         params: new ZoneAndModuleHttpParams(currentZone, 3),
    //     })
    //     .pipe(
    //         map((res) => res["response"]),
    //         catchError((err) => throwError(err))
    //     );
  }

  replaceCSVSpecialChar(name) {
    if (!!name && name != "" && typeof name == "string") {
      return "'" + name;
    } else {
      return name;
    }
  }

  convertUTCtoISTtimeStamp(timeStamp, type = "start") {
    let utcDateTime = new Date(timeStamp);
    let utcDate = utcDateTime.getUTCDate();
    let utcMonth = utcDateTime.getUTCMonth() + 1;
    let utcYear = utcDateTime.getUTCFullYear();

    if (type === "start") {
      return new Date(
        new Date(`${utcMonth}/${utcDate}/${utcYear}`).setHours(0, 0, 0, 0)
      ).getTime();
    } else {
      return new Date(
        new Date(`${utcMonth}/${utcDate}/${utcYear}`).setHours(23, 59, 59, 59)
      ).getTime();
    }
  }

  getSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  convertTimeStampToMonthAndDate(days, fromPage) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let selectedDates = "";
    days.forEach((element) => {
      let date;
      if (fromPage != "colleagueBooking" && fromPage != "manualBooking") {
        if (!element.date) {
          date = new Date(element);
        } else {
          date = new Date(element.date);
        }
      } else {
        if (!element.date) {
          date = new Date(element["selectedDays"][0]["date"]);
        } else {
          date = new Date(element.date);
        }
      }
      selectedDates +=
        monthNames[date.getUTCMonth()] + " " + date.getUTCDate() + ",";
    });

    return selectedDates.slice(0, -1);
  }

  generateTimeList(startMin, endMin, timediff, timeFormat, timeConfig) {
    let timeList = [];
    let hours, minutes, ampm;
    if (timeConfig["lockTimeEnabled"] == false) {
      let hours = timeConfig["startTimeDisplayDefault"]["hourOfDay"] * 60;
      let minutes = timeConfig["startTimeDisplayDefault"]["minOfDay"];
      startMin = hours + minutes;
    }

    if (timeConfig["lockTimeEnabled"] == false) {
      let hours = timeConfig["endTimeDisplayDefault"]["hourOfDay"] * 60;
      let minutes = timeConfig["endTimeDisplayDefault"]["minOfDay"];
      endMin = hours + minutes;
    }

    if (timeConfig["lockTimeEnabled"] == false) {
      for (let i = startMin - timediff; i >= 0; i -= timediff) {
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
          minutes = "0" + minutes; // adding leading zero
        }

        if (timeFormat == 12) {
          ampm = hours % 24 < 12 ? "AM" : "PM";
          hours = hours % 12;

          if (hours === 0) {
            hours = 12;
          }
        } else {
          ampm = "";
        }

        if (hours < 10) {
          hours = "0" + hours; // adding leading zero
        }

        timeList.push({
          id: i,
          value: hours + ":" + minutes + " " + ampm,
        });
      }

      timeList.reverse();
    }
    //7

    for (let i = startMin; i <= endMin; i += timediff) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes; // adding leading zero
      }

      if (timeFormat == 12) {
        ampm = hours % 24 < 12 ? "AM" : "PM";
        hours = hours % 12;

        if (hours === 0) {
          hours = 12;
        }
      } else {
        ampm = "";
      }

      if (hours < 10) {
        hours = "0" + hours; // adding leading zero
      }

      timeList.push({
        id: i,
        value: hours + ":" + minutes + " " + ampm,
      });
    }
    //9-5

    if (timeConfig["lockTimeEnabled"] == true) {
      //This will add 11:59PM incase below condition dont does not work
      if (endMin + timediff > 1439) {
        let i = 1439;
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
          minutes = "0" + minutes; // adding leading zero
        }
        if (timeFormat == 12) {
          ampm = hours % 24 < 12 ? "AM" : "PM";
          hours = hours % 12;

          if (hours === 0) {
            hours = 12;
          }
        } else {
          ampm = "";
        }

        let fHourTime;
        if (timeFormat == 12) {
          fHourTime = 11;
        } else {
          fHourTime = 23;
        }
        timeList.push({
          id: i,
          value: fHourTime + ":59 " + ampm,
        });
      }
    }

    if (timeConfig["lockTimeEnabled"] == false) {
      //This will add 11:59PM incase below condition dont does not work
      if (endMin + timediff > 1439) {
        let i = 1439;
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
          minutes = "0" + minutes; // adding leading zero
        }
        if (timeFormat == 12) {
          ampm = hours % 24 < 12 ? "AM" : "PM";
          hours = hours % 12;

          if (hours === 0) {
            hours = 12;
          }
        } else {
          ampm = "";
        }

        let fHourTime;
        if (timeFormat == 12) {
          fHourTime = 11;
        } else {
          fHourTime = 23;
        }
        timeList.push({
          id: i,
          value: fHourTime + ":59 " + ampm,
        });
      }

      for (let i = endMin + timediff; i < 1440; i += timediff) {
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
          minutes = "0" + minutes; // adding leading zero
        }
        if (timeFormat == 12) {
          ampm = hours % 24 < 12 ? "AM" : "PM";
          hours = hours % 12;

          if (hours === 0) {
            hours = 12;
          }
        } else {
          ampm = "";
        }

        if (hours < 10) {
          hours = "0" + hours; // adding leading zero
        }

        timeList.push({
          id: i,
          value: hours + ":" + minutes + " " + ampm,
        });

        if (i + timediff >= 1440 && i != 1439) {
          let fHourTime;
          if (timeFormat == 12) {
            fHourTime = 11;
          } else {
            fHourTime = 23;
          }
          timeList.push({
            id: 1439,
            value: fHourTime + ":59 " + ampm,
          });
        }
      }
    }
    /*
        if(timeList[timeList.length-1]['id'] < endMin){
            hours = Math.floor(endMin / 60);
            minutes = endMin % 60;
            if (minutes < 10){
                minutes = '0' + minutes; // adding leading zero
            }
            
            if(timeFormat == 12){
              ampm = hours % 24 < 12 ? 'AM' : 'PM';
              hours = hours % 12;
              
              if (hours === 0){
                  hours = 12;
              }
            }else{
              ampm = "";
            }
    
            if (hours < 10){
              hours = '0' + hours; // adding leading zero
            }

            timeList.push({
                id: endMin,
                value: hours+":"+minutes+" "+ampm
            })
        }*/
    return timeList;
  }

  convertSecondsToHoursAndMinutes(i) {
    let hours, minutes;
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }

    if (hours < 10) {
      hours = "0" + hours; // adding leading zero
    }
    return `${hours}${minutes}`;
  }

  convertSecondsToHoursAndMinutesJson(i) {
    let hours, minutes;
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }

    if (hours < 10) {
      hours = "0" + hours; // adding leading zero
    }

    return { hours: `${hours}`, minutes: `${minutes}` };
  }

  convertHoursAndMinutesToMinutes(i) {
    //0900 will be the output
    let hours, minutes;
    hours = parseInt(i.substring(0, 2));
    minutes = parseInt(i.substring(2, 4));
    let min = hours * 60 + minutes;
    return min;
  }

  convertTimeStamp(time) {
    let hour;
    let minutes;
    if (time.length == 4) {
      hour = time.substring(0, 2);
      minutes = time.substring(2, 4);
    } else if (time.length == 3) {
      hour = time.charAt(0);
      minutes = time.substring(1, 3);
    }

    if (time == 0) {
      minutes = "00";
      hour == "00";
    }
    let H = hour;
    let h = H % 12 || 12;
    let ampm = H < 12 || H === 24 ? "AM" : "PM";
    return h + ":" + minutes + " " + ampm;
  }

  populate(timeConfig) {
    let timediff = 15;
    let maxBookingTime = 0;
    if (
      !!timeConfig["timeslotDuration"] &&
      parseInt(timeConfig["timeslotDuration"]) > 0
    ) {
      timediff = timeConfig["timeslotDuration"];
      //timediff = 15;
    }
    //This condition is correct if(!!this.timeConfig['maxBookingTime'] && parseInt(this.timeConfig['maxBookingTime'])>0 && this.timeConfig['limitBookingDuration']==true){
    if (
      !!timeConfig["maxBookingTime"] &&
      parseInt(timeConfig["maxBookingTime"]) > 0 &&
      timeConfig["limitBookingDuration"] == true
    ) {
      maxBookingTime = parseInt(timeConfig["maxBookingTime"]);
    }

    let timeFormat; //Need to make dynamic from settings;
    if (timeConfig["clockType"] == "TWELVE_HOUR") {
      timeFormat = 12;
    } else {
      timeFormat = 24;
    }
    //For Current start & End to  next Start & end time

    let timeList = [];
    let currentHr: any = new Date().getHours();
    let currentMin: any = new Date().getMinutes();
    if (currentMin > 0 && currentMin < 15) {
      currentMin = 15;
    } else if (currentMin > 15 && currentMin < 30) {
      currentMin = 30;
    } else if (currentMin > 30 && currentMin < 45) {
      currentMin = 45;
    } else if (currentMin > 45) {
      currentHr = currentHr + 1;
      currentMin = "00";
    }

    //Lock start time  & end time
    let startMin = 0;
    let endMin = 1440;
    let currentDateStartTime = currentHr * 60 + parseInt(currentMin);
    if (timeConfig["lockTimeEnabled"] == true) {
      let lockStartHour;
      let lockStartMinute;
      let lockEndHour;
      let lockEndMinute;
      lockStartHour = timeConfig["lockedStartTime"]["hourOfDay"] * 60;
      lockStartMinute = timeConfig["lockedStartTime"]["minOfDay"];
      startMin = lockStartHour + lockStartMinute;

      lockEndHour = timeConfig["lockedEndTime"]["hourOfDay"] * 60;
      lockEndMinute = timeConfig["lockedEndTime"]["minOfDay"];
      endMin = lockEndHour + lockEndMinute;
    }

    if (timeConfig["timeDisplayDefaultTimingEnabled"] == true) {
      let lsHours = timeConfig["startTimeDisplayDefault"]["hourOfDay"] * 60;
      let lsMinutes = timeConfig["startTimeDisplayDefault"]["minOfDay"];
      startMin = lsHours + lsMinutes;

      let esHours = timeConfig["endTimeDisplayDefault"]["hourOfDay"] * 60;
      let esMinutes = timeConfig["endTimeDisplayDefault"]["minOfDay"];
      endMin = esHours + esMinutes;
    }

    if (currentDateStartTime < startMin) {
      currentDateStartTime = startMin;
    }

    let presentTime = currentDateStartTime;
    let todayStartTime = currentDateStartTime;
    let todayEndTime = endMin;
    let nextStartTime = startMin;
    let nextEndTime = endMin;
    timeList = this.generateTimeList(
      startMin,
      endMin,
      timediff,
      timeFormat,
      timeConfig
    );
    //Update default values if not exists start
    let result = timeList.map((objArr) => objArr.id);
    if (!result.includes(todayStartTime)) {
      result.every((res, index) => {
        if (res > todayStartTime) {
          todayStartTime = result[index - 1];
          return false;
        } else {
          return true;
        }
      });
    }

    if (maxBookingTime > 0) {
      let todayEndTime = Number(todayStartTime) + Number(maxBookingTime);
      todayEndTime = todayEndTime;
      if (!result.includes(todayEndTime)) {
        result.every((res, index) => {
          if (res > todayEndTime) {
            todayEndTime = result[index - 1];
            return false;
          } else {
            return true;
          }
        });
      }
    }

    if (!result.includes(todayEndTime)) {
      todayEndTime = result[result.length - 1];
    }

    if (todayEndTime === todayStartTime) {
      todayEndTime = null;
    }

    if (!result.includes(nextStartTime)) {
      result.every((res) => {
        if (res > nextStartTime) {
          nextStartTime = res;
          return false;
        } else {
          return true;
        }
      });
    }

    if (!result.includes(nextEndTime)) {
      if (timeConfig["lockEndTime"] == true) {
        result.every((res, index) => {
          if (res > nextEndTime) {
            nextEndTime = result[index - 1];
            return false;
          } else {
            return true;
          }
        });
      } else {
        nextEndTime = result[result.length - 1];
      }
    }
    //Update default values if not exists end
    //Update endTime if maxtime is exists start
    if (maxBookingTime > 0) {
      let endTime: number = nextStartTime;
      let maxTime: number = maxBookingTime;
      let nextEndTime: number = endTime + maxTime;
      nextEndTime = nextEndTime;
      if (!result.includes(nextEndTime)) {
        result.every((res, index) => {
          if (res > nextEndTime) {
            nextEndTime = result[index - 1];
            return false;
          } else {
            return true;
          }
        });
      }
    }

    let settingsConfig = {
      limitBookingDuration: timeConfig["limitBookingDuration"],
      maxBookingTime: maxBookingTime,
      startTimeDisplayDefault: timeConfig["timeDisplayDefaultTimingEnabled"],
      lockTimeEnabled: timeConfig["lockTimeEnabled"],
      startTime: startMin,
      endTime: endMin,
      nextEndTime: nextEndTime,
      nextStartTime: nextStartTime,
      timeList: timeList,
      todayStartTime: todayStartTime,
      todayEndTime: todayEndTime,
      presentTime: presentTime,
    };

    return settingsConfig;
  }

  convertingDateObjectToUTCTimeStamp(date) {
    // Eg:2019-12-26
    //localDate.toLocaleString('en-US', { timeZone: timeZone })
    let a = moment(new Date(date));
    let b = a.utc(date).valueOf();
    return b;
  }

  getConfigSettings(zoneId) {
    let url;
    url = environment.BASE_URL_WFH + `wfh-settings/get`;

    return this.http
      .get<any>(url, {
        params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
      })
      .pipe();
  }

  getActiveDigitalIntroScreen(introScreenActive: any) {
    this.messageSource.next(introScreenActive);
  }

  getTimeZoneData(zoneId): Observable<any> {
    let url;
    url = this.BASE_URL + `zones/getZone?zoneId=` + zoneId;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
    });
  }

  getLastDateOfMonth(timestamp) {
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = date.getMonth();
  
    // Create a Date object for the next month's first day
    let nextMonthFirstDay = new Date(year, month + 1, 1);
  
    // Subtract one day to get the last day of the current month
    let lastDayOfMonth = new Date(nextMonthFirstDay.getTime() - 1);
  
    return lastDayOfMonth.getTime();
  }
}
