import { HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SubscriptionService {
    constructor(http) {
        this.http = http;
        this.moduleId = 9;
        this.BASE_URL = environment.BASE_URL + "vms/";
        this.BASE_EMS_URL = environment.BASE_URL + "ems/";
        this.TEMP_BASE_URL = environment.BASE_URL;
    }
    uploadOnboardUsersTemplate(fileToUpload, planType) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '"}',
            }),
        };
        let url = this.BASE_EMS_URL + `user-subscription-mappings/admin/uploadCSV`;
        const formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        formData.append("planName", planType);
        return this.http.post(url, formData, httpOptions);
    }
    loadSubcriptions() {
        let url = this.BASE_EMS_URL + `subscriptions/getQuantitiesByPlan`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    sendSubscriptionRequest(type) {
        let url = this.BASE_EMS_URL + `user-subscription-mappings/send?type=${type}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    loadSubcriptionsByPlan(title, subObj) {
        let url = this.BASE_EMS_URL +
            `user-subscription-mappings/usersByPlan/paginated?planName=${title}&page=${subObj['pageIndex']}&size=${subObj['pageSize']}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    validateOnboardSubscriptionTemplate(fileToUpload) {
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '"}',
            }),
        };
        let url = this.BASE_EMS_URL + `user-subscription-mappings/admin/validateCSV`;
        const formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);
        return this.http.post(url, formData, httpOptions);
    }
    addUsers(data) {
        let url = this.BASE_EMS_URL + `user-subscription-mappings/add`;
        console.log("data is... ", data);
        console.log("url is... ", url);
        return this.http.post(url, data, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    getAllSubscriptions() {
        let url = this.BASE_EMS_URL + `subscriptions/getAll`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    deleteUser(licenceId) {
        let url = this.BASE_EMS_URL + `user-subscription-mappings/?licenceId=${licenceId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "ss-header": '{"correlationId":"' +
                    uuidv4() +
                    '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":' + this.moduleId + ',"sessionId":"' +
                    localStorage.getItem("ssadmin_session") +
                    '"}',
            }),
        };
        return this.http.delete(url, httpOptions);
    }
    searchSubcriptionsByPlan(title, searchData) {
        let url = this.BASE_EMS_URL +
            `user-subscription-mappings/usersByPlan/search?planName=${title}&searchString=${searchData}`;
        return this.http.get(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
}
SubscriptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.ɵɵinject(i1.HttpClient)); }, token: SubscriptionService, providedIn: "root" });
