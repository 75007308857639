<app-modal-loader [loading]="loading"></app-modal-loader>
<!-- <mat-card class="example-card mat-select-panel" *ngIf="pageType == 'editCheckList'"> -->
  <!-- <div class="row">
    <div class="col-md-6">
      <div class="visitor-info-header">
        {{'edit' | translate}}
      </div>
    </div>
    <div class="col-md-6">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider> -->
  <!-- <mat-card-content>
    <div class="row" id="formCustomCheckList">
      <div class="col-md-12 mg-10"> -->
        <!-- <form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>
          <h6>
            {{'addcustomchecklisttitleandoption' | translate}}
          </h6>
          <p class="subtitle">
            {{'thisfieldswilldisplayatvistorsmanagementapp' | translate}}
          </p>

          <div class="form-group">
            <label for="companyName">{{'title' | translate}}</label>
            <input
              type="text"
              class="form-control"
              name="title"
              [(ngModel)]="model.title"
              #title="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && title.invalid }"
              required
              #mytitle
            />
          </div>

          <div class="form-group row">
            <div class="col-lg-4">
              <label for="companyName">{{'answer' | translate}} </label>
              <span class="addOptions" (click)="addOptions()"
                >{{'+addoptions' | translate}}</span
              >
            </div>
            <div class="col-lg-8">
              <span class="subtitle"
                ><img
                  src="assets/images/redflag.png"
                  width="15px"
                />({{'flagthisanswer' | translate}})</span
              >
            </div>
          </div>
          <ng-container *ngFor="let opt of model.optionsq; let k = index">
            <div class="form-group row">
              <div class="col-lg-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="option{{ k + 1 }}"
                  [(ngModel)]="opt.qoption"
                  name="qoption-{{ k }}"
                  [ngClass]="{ 'is-invalid': f.submitted && qoption.invalid }"
                  required
                  #qoption="ngModel"
                />
              </div>
              <div class="col-lg-8">
                <mat-slide-toggle
                  [(ngModel)]="opt.redflag"
                  name="redflag-{{ k }}"
                  >&nbsp;</mat-slide-toggle
                >
                <small *ngIf="k > 1"
                  ><a
                    href="javascript:void(0)"
                    (click)="removeQuestionOption(k)"
                    ><i class="fa fa-times-circle remove-circle"></i></a
                ></small>
              </div>
            </div>
          </ng-container>

          <b>
            <span class="scheduler-heading">{{'scheduler' | translate}}</span>
            <span class="subtitle"
              >({{'thisfieldswilldisplayatvistorsmanagementapp' | translate}})
              <i class="fa fa-calendar" aria-hidden="true"></i
            ></span>
            <!-- <span class="pull-right"><mat-slide-toggle>&nbsp;</mat-slide-toggle></span> -->
          <!-- </b> -->

          <!-- <section class="scheduler-section">
            <p>{{'repeatevery' | translate}}</p>
            <mat-radio-group
              class="example-section"
              name="repeatSel"
              [(ngModel)]="repeatSelection"
              required
              #repeatSel="ngModel"
            >
              <mat-radio-button
                class="radio-margin"
                value="DAILY"
                [checked]="repeatSelection == 'DAILY'"
              >
                {{'daily' | translate}}
              </mat-radio-button>
              <mat-radio-button
                class="radio-margin"
                value="WEEKLY"
                [checked]="repeatSelection == 'WEEKLY'"
                style="margin-left:15px;"
              >
                {{'weekly' | translate}}
              </mat-radio-button>
              <mat-radio-button
                class="radio-margin"
                value="MONTHLY"
                [checked]="repeatSelection == 'MONTHLY'"
                style="margin-left:15px;"
              >
                {{'monthly' | translate}}
              </mat-radio-button>
            </mat-radio-group>
            <p
              class="help-block text-danger"
              *ngIf="f.submitted && !repeatSel.valid"
            >
              {{'pleaseselectatleastoneoption!' | translate}}
            </p>
          </section> -->

          <!-- <div class="scheduler-section"> -->
            <!-- <p>{{'repeaton' | translate}}</p> -->
            <!-- <section *ngIf="repeatSelection != 'MONTHLY'">
              <div class="example-section">
                <mat-checkbox
                  class="example-checkbox"
                  name="day1"
                  [(ngModel)]="daysSelection.day1"
                  value="1"
                  >{{'MON' | translate}}</mat-checkbox
                >
                <mat-checkbox
                  class="example-checkbox"
                  name="day2"
                  [(ngModel)]="daysSelection.day2"
                  value="2"
                  >{{'TUE' | translate}}</mat-checkbox
                >
                <mat-checkbox
                  class="example-checkbox"
                  name="day3"
                  [(ngModel)]="daysSelection.day3"
                  value="3"
                  >{{'WED' | translate}}</mat-checkbox
                >
                <mat-checkbox
                  class="example-checkbox"
                  name="day4"
                  [(ngModel)]="daysSelection.day4"
                  value="4"
                  >{{'THU' | translate}}</mat-checkbox
                >
                <mat-checkbox
                  class="example-checkbox"
                  name="day5"
                  [(ngModel)]="daysSelection.day5"
                  value="5"
                  >{{'FRI' | translate}}</mat-checkbox
                >
                <mat-checkbox
                  class="example-checkbox"
                  name="day6"
                  [(ngModel)]="daysSelection.day6"
                  value="6"
                  >{{'SAT' | translate}}</mat-checkbox
                >
                <mat-checkbox
                  class="example-checkbox"
                  name="day7"
                  [(ngModel)]="daysSelection.day7"
                  value="7"
                  >{{'SUN' | translate}}</mat-checkbox
                >
              </div>
              <p
                class="help-block text-danger"
                *ngIf="f.submitted && !isRepeatDateSelected"
              >
                {{'pleaseselectatleastoncheckbox' | translate}}
              </p>
            </section> -->

            <!-- <section *ngIf="repeatSelection == 'MONTHLY'"> -->
              <!-- <mat-radio-group name="monthlySelection" [(ngModel)]="monthSelection" required #monthlySelection = "ngModel" [ngClass]="{ 'is-invalid': f.submitted && monthlySelection.invalid }">
                                    <mat-radio-button class="radio-margin" value="onceAMonth">
                                    ONCE A MONTH
                                    </mat-radio-button>
                                    <mat-radio-button class="radio-margin" value="3rdMonday">
                                    3RD MONDAY
                                    </mat-radio-button>
                                </mat-radio-group> -->

              <!-- <mat-select [(ngModel)]="monthSelection" #monthlySelection="ngModel"
              class="form-control col-lg-2" name="monthlySelection" 
              [ngClass]="{
                'is-invalid': f.submitted && monthlySelection.invalid
              }"
            >
              <mat-option *ngFor="let item of days; let i = index"
               value="{{item}}">{{ item }}</mat-option>
              </mat-select> -->
              <!-- <select
                class="form-control col-lg-2"
                name="monthlySelection"
                [(ngModel)]="monthSelection"
                required
                #monthlySelection="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && monthlySelection.invalid
                }"
              >
                <ng-container *ngFor="let item of days; let i = index">
                  <option> {{ item }} </option>
                </ng-container>
              </select> -->
              <!-- <p
                class="help-block text-danger"
                *ngIf="f.submitted && !monthlySelection.valid"
              >
                {{'pleaseselectatleastoneoption!' | translate}}
              </p>
            </section> -->
          <!-- </div> -->

          <!-- <div id="example2-cron" class="cron-builder">
                        </div>
                        <input type="hidden" id="example2-result" name="regExp" [(ngModel)] = "regularExpression"> -->
          <!-- <div class="scheduler-section">
            <p>{{'recurrence' | translate}}</p>

            <section class="example-section"> -->
              <!-- <label>
                <input
                  placeholder="Start date"
                  class="form-control"
                  name="startDate"
                  [(ngModel)]="dateTime1"
                  [owlDateTimeTrigger]="dt1"
                  [owlDateTime]="dt1"
                  required
                  #startDate="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && startDate.invalid }"
                />
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
              </label> -->
              <!-- <label class="example-input-wrapper">
                                    <input placeholder="Start time"
                                            class="form-control"
                                            name="startTimer"
                                            [(ngModel)]="dateTime2"
                                            [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2"
                                            required
                                            #startTimer = "ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && startTimer.invalid }"
                                            >
                                    <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
                                </label> -->

              <!-- <label class="example-input-wrapper">
                {{'to' | translate}}
              </label> -->

              <!-- <label class="example-input-wrapper">
                <input
                  placeholder="End date"
                  class="form-control"
                  name="endDate"
                  [(ngModel)]="dateTime3"
                  [owlDateTimeTrigger]="dt3"
                  [owlDateTime]="dt3"
                  required
                  #endDate="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && endDate.invalid }"
                />
                <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
              </label> -->
              <!-- <label class="example-input-wrapper">
                                    <input placeholder="End time"
                                            class="form-control"
                                            name="endTimer"
                                            [(ngModel)]="dateTime4"
                                            [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4"
                                            required
                                            #endTimer = "ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && endTimer.invalid }"
                                            >
                                    <owl-date-time [pickerType]="'timer'" #dt4></owl-date-time>
                                </label> -->
            <!-- </section> -->
          <!-- </div> -->
          <!-- <div class="form-group">
            <button type="submit" id="submit-question" class="btn btn-main btnSmall pull-right">
              {{'update' | translate}} 
            </button>
          </div> -->
        <!-- </form> -->
      <!-- </div> -->
    <!-- </div> -->
  <!-- </mat-card-content> -->
<!-- </mat-card> -->

<div *ngIf="pageType == 'editCheckList'">
  <div class="modal-header">
    <h5 class="modal-title font-weight-bold">{{'editQuestion' | translate}}</h5>
      <button id="close_modal" type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body py-0">
      <div class="modal_content text-justify">
          <!-- Edit Question Modal Content -->
          <div class="container-fluid">
            <form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>
              <div class="row">
                <div class="col-lg-9">
                  <!-- <h5 class="font-weight-bold">{{'addQuestion' | translate}}</h5> -->
                    <!-- <h6>{{'addcustomchecklisttitleandoption' | translate}}
                      </h6> -->
                   
                      <div class="form-group mt-2">
                        <p class="fontColor">1. {{'title' | translate}}</p>
                        <p class="pt-1 mb-1">{{'enterTitle' | translate}}</p>
                        <input
                          type="text"
                          class="form-control"
                          name="title"
                          maxlength = "300"
                          [(ngModel)]="model.title"
                          #title="ngModel"
                          [ngClass]="{
                            'is-invalid': f.submitted && title.invalid
                          }"
                          required
                          #mytitle
                        />
                      </div>
  
                    <!-- Step 2 -->
                    <div class="form-group">
                      <p class="fontColor">2. {{'selectResponseFormat' | translate}}</p>
                      <mat-radio-group aria-label="Select an option" class="font-variant-none d-flex" (change)="selectResponseFormat($event)">
                        <div class="col-12 px-0">
                          <mat-radio-button class="colorBlue" value="radio" [checked]="true">
                            <span class="wrap-mat-radio-label"> {{'radioButton' | translate}} </span><span style="color: red;" >*</span>
                          </mat-radio-button>
                          <p class="ml-4 fnt_sz mt-1">{{'userCanSelectOneAnswer' | translate}}</p>
                        </div>
                      </mat-radio-group>
                      <p class="p1">{{'note' | translate}}: <span style="color: red;">*</span> {{'selectedFormatsUsedToAcceptOrReject' | translate}}.</p>
                  </div>
                  <div class="form-group">
                    <p class="fontColor">3. {{'addResponses' | translate}}</p>
                    <div class="row">
                      <div class="col-3 pr-0">
                        <p class="pt-1">{{'enterResponse' | translate}} {{' | '}}</p>
                      </div>
                      <div class="col-3 pl-0">
                        <input id="optionValues" type="text" class="form-control" [(ngModel)]="optionValues" name="optionValues">
                      </div>
                      <div class="col-1 p-0">
                        <img class="block-img w-75" src="./assets/images/cricle-plus.svg" (click)="addOptions1()">
                      </div>
                    </div>
                  <div class="row">
                      <div class="col-12" *ngFor="let option of options; let i = index">
                         <mat-radio-button disabled class="example-radio-button" [value]="option">{{option}}</mat-radio-button>
                         <a href="javascript:void(0)" (click)="removeOption(i)"><i class="fa fa-times-circle remove-circle"></i></a>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-12">
                        <p class="fontColor">4. {{'automaticResponseAssessment' | translate}}</p>
                        <p class="p1"><span style="color: red;">*</span> {{'systemWillAutomaticallyReject' | translate}}.</p>
                      </div>
                      <div>
                        <mat-checkbox class="mat-checkbox1" *ngFor="let option of options" name="option" [value]="option"
                            [(ngModel)]="selectedOptions[option]" [ngModelOptions]="{standalone: true}">
                            {{ option }}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                    <div class="form-group">
                      <p class="fontColor">5. {{'scheduler' | translate}}</p>
                      <b class="fnt_sz_schedule">{{'howOftenWouldYouLikeAskQuestion' | translate}}?</b>
                    </div>
                    <section class="scheduler-section">
                      <p>{{'repeatevery' | translate}}</p>
                      <mat-radio-group
                        class="example-section"
                        name="repeatSel"
                        [(ngModel)]="repeatSelection"
                        required
                        #repeatSel="ngModel"
                      >
                        <mat-radio-button
                          class="radio-margin"
                          value="DAILY"
                          [checked]="repeatSelection == 'DAILY'"
                        >{{'daily' | translate}}
                        </mat-radio-button>
                        <mat-radio-button
                          class="radio-margin"
                          value="WEEKLY"
                          [checked]="repeatSelection == 'WEEKLY'"
                          style="margin-left: 15px"
                        >{{'weekly' | translate}}
                        </mat-radio-button>
                        <mat-radio-button
                          class="radio-margin"
                          value="MONTHLY"
                          [checked]="repeatSelection == 'MONTHLY'"
                          style="margin-left: 15px"
                        >{{'monthly' | translate}}
                        </mat-radio-button>
                        <!-- <mat-radio-button
                          class="radio-margin"
                          value="ONCE"
                          [checked]="repeatSelection == 'ONCE'"
                          style="margin-left: 15px"
                        >{{'onlyOnce' | translate}}
                        </mat-radio-button> -->
                      </mat-radio-group>
                      <p
                        class="help-block text-danger"
                        *ngIf="f.submitted && !repeatSel.valid"
                      >
                       {{'pleaseselectatleastoneoption' | translate}}!
                      </p>
                    </section>
  
                    <div class="scheduler-section">
                      <p>{{'repeaton' | translate}}</p>
                      <section *ngIf="repeatSelection != 'MONTHLY'">
                        <div class="example-section">
                          <mat-checkbox
                            class="example-checkbox"
                            name="day1"
                            [(ngModel)]="daysSelection.day1"
                            value="MON"
                            >{{'MON' | translate}}</mat-checkbox
                          >
                          <mat-checkbox
                            class="example-checkbox"
                            name="day2"
                            [(ngModel)]="daysSelection.day2"
                            value="TUE"
                            >{{'TUE' | translate}}</mat-checkbox
                          >
                          <mat-checkbox
                            class="example-checkbox"
                            name="day3"
                            [(ngModel)]="daysSelection.day3"
                            value="WED"
                            >{{'WED' | translate}}</mat-checkbox
                          >
                          <mat-checkbox
                            class="example-checkbox"
                            name="day4"
                            [(ngModel)]="daysSelection.day4"
                            value="THU"
                            >{{'THU' | translate}}</mat-checkbox
                          >
                          <mat-checkbox
                            class="example-checkbox"
                            name="day5"
                            [(ngModel)]="daysSelection.day5"
                            value="FRI"
                            >{{'FRI' | translate}}</mat-checkbox
                          >
                          <mat-checkbox
                            class="example-checkbox"
                            name="day6"
                            [(ngModel)]="daysSelection.day6"
                            value="SAT"
                            >{{'SAT' | translate}}</mat-checkbox
                          >
                          <mat-checkbox
                            class="example-checkbox"
                            name="day7"
                            [(ngModel)]="daysSelection.day7"
                            value="SUN"
                            >{{'SUN' | translate}}</mat-checkbox
                          >
                        </div>
                        <p
                          class="help-block text-danger"
                          *ngIf="f.submitted && !isRepeatDateSelected"
                        >
                        {{'pleaseselectatleastonecheckbox' | translate}} 
                        </p>
                      </section>
                      <!-- Custom Month Section -->
                      <!-- <section *ngIf="repeatSelection == 'MONTHLY'">
                        <mat-radio-group class="example-section mt-3" name="customSel" [(ngModel)]="customMonthSelection" required 
                          #customSel="ngModel" (change)="getCustomMonth($event)">
                            <mat-radio-button class="radio-margin" value="Once" [checked]="customMonthSelection == 'Once'">
                              {{'onceAMonth' | translate}}
                            </mat-radio-button>
                            <mat-radio-button class="radio-margin" value="Twice" [checked]="customMonthSelection == 'Twice'" style="margin-left: 15px">
                              {{'twiceAMonth' | translate}}
                            </mat-radio-button>
                            <mat-radio-button class="radio-margin" value="Custom" [checked]="customMonthSelection == 'Custom'" style="margin-left: 15px">
                              {{'custom' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="custom-modal-section" *ngIf="customDays == true">
                          <div class="card shadow p-4" style="border-radius: 1rem;">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-pull-12">
                                <h6 class="font-weight-bold">CUSTOM DAYS
                                  <i class="fa fa-times float-right fa-1x" aria-hidden="true" (click)="hideCustomDays()"></i>
                                </h6>
                              </div>
                            </div>
                            <hr>
                            <div class="form-group">
                              <form>
                                <div class="form-row mt-4">
                                    <div class="form-group col-2 mt-1 mb-0 pr-0">
                                        <p>{{'monthly' | translate}}</p>
                                    </div>
                                    <div class="form-group col-2">
                                        <button mat-mini-fab class="btn btn-main bgColor">
                                            <img src="./assets/images/calendar_icon.svg" alt="" />
                                        </button>
                                    </div>
                                    <div class="form-group col-8">
                                        <div class="row">
                                          <div class="col-lg-12">
                                            <div class="card shadow p-3" style="border-radius: 1rem; width: 334px;">
                                              <full-calendar #fullcalendar [options]="calendarOptions"></full-calendar>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt-4">
                                    <button class="btn btn-primary btn-main btnSmall mr-1 pull-right border_radius">
                                        {{'apply' | translate}}
                                    </button>
                                </div>
                            </form>
                            </div>
                          </div>
                        </div>
                      </section> -->
                      <section *ngIf="repeatSelection == 'MONTHLY'">
                        <mat-select
                          placeholder="Select date"
                          class="form-control col-lg-4"
                          name="monthlySelection"
                          required
                          [(ngModel)]="monthSelection"
                          #monthlySelection="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              f.submitted && monthlySelection.invalid
                          }"
                        >
                          <ng-container
                            *ngFor="let item of days; let i = index"
                          >
                            <mat-option [value]="item">
                              {{ item }}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                        <p
                          class="help-block text-danger"
                          *ngIf="f.submitted && !monthlySelection.valid"
                        >
                         {{'pleaseselectatleastoneoption' | translate}}!
                        </p>
                      </section>
                    </div>
  
                    <div class="scheduler-section">
                      <p>{{'recurrence' | translate}}</p>
  
                      <section class="example-section">
                        <label>
                          <input
                            placeholder="Start date"
                            class="form-control"
                            name="startDate"
                            [(ngModel)]="dateTime1"
                            [owlDateTimeTrigger]="dt1"
                            required
                            [owlDateTime]="dt1"
                            #startDate="ngModel"
                            [ngClass]="{
                              'is-invalid': f.submitted && startDate.invalid
                            }"
                            [min]="minDate"
                          />
                          <owl-date-time
                            [pickerType]="'calendar'"
                            #dt1
                          ></owl-date-time>
                        </label>
                        <label class="example-input-wrapper"> {{'to' | translate}} </label>
  
                        <label class="example-input-wrapper">
                          <input
                            placeholder="End date"
                            class="form-control"
                            name="endDate"
                            [(ngModel)]="dateTime3"
                            [owlDateTimeTrigger]="dt3"
                            [owlDateTime]="dt3"
                            required
                            #endDate="ngModel"
                            [min]="this.dateTime1"
                            [ngClass]="{
                              'is-invalid': f.submitted && endDate.invalid
                            }"
                          />
                          <owl-date-time
                            [pickerType]="'calendar'"
                            #dt3
                          ></owl-date-time>
                        </label>
                      </section>
                    </div> 
                    <div class="form-group mb-0">&nbsp;</div>
                    <!-- <div class="form-row">
                      <div class="form-group col-lg-4 col-md-12 col-sm-12">
                        <label for="">{{'time' | translate}}</label>
                        <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              id="time"
                              [(ngModel)]="scheduleTime"
                              name="scheduleTime"
                              aria-label="Text input with dropdown button"
                            />
                            <div class="input-group-append">
                              <mat-select id="scheduleTimeType"
                                    [(value)]="scheduleTimeType"
                                    name="scheduleTimeType"
                                    class="form-control" style="min-width: 70px;"
                                    (ngModelChange)="scheduleTimeChange()">
                                <mat-option value="PM">PM</mat-option>
                                <mat-option value="AM">AM</mat-option>
                              </mat-select>
                            </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="form-group">
                      <button
                        type="submit"
                        id="submit-question"
                        class="btn btn-main pull-right"
                      >
                        {{ 'create' | translate }}
                      </button>
                    </div> -->
                </div>
                <div class="col-lg-3">
                  <!-- <div class="notes jumbotron">
                    <b>{{'note' | translate}}:</b>
                    {{'incasenoanswersareflaggedthesystemwillcapturetheresponsesandprovideaccess' | translate}} 
                   </div> -->
                </div>
                <div class="form-group col-12 text-right pr-0 mt-4">
                  <button type="submit" id="submit-question" class="btn btn-main btnSmall pull-right border_radius">
                    {{'update' | translate}} 
                  </button>
                </div>
              </div>
            </form>
          </div>
      </div>
  </div>
  <!-- <div class="modal-footer border-0 m-2 p-0 float-right"></div> -->
</div>

<mat-card class="example-card" *ngIf="pageType == 'userCheckList'">
  <div class="row">
    <div class="col-md-6">
      <div class="visitor-info-header">
        {{ this.data["data"]["userName"] }} -
        {{ this.data["data"]["tenantDto"]["name"] }}
      </div>
    </div>
    <div class="col-md-6">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="row">
      <div class="col-md-7">
        <ng-container *ngFor="let que of selfAssessment; let i = index">
          <h6 class="mg-10">
            <b>{{ i + 1 }}. {{ que["metadata"]["question"] }}</b>
          </h6>
          <div class="">
            <p>
              {{'ans.' | translate}} {{ getSelectedAnswers(que) }}
              <!-- {{que['metadata']['values'][this.data['data']['metadata']['questionResponse'][que.id]] }} -->
              <span *ngIf="checkFlagWithAnswer(que) == 1"
                ><img src="assets/images/redflag.png" width="15px"
              /></span>
            </p>
          </div>
        </ng-container>
      </div>
      <div class="col-lg-5">  
         
        <b *ngIf="tempShow">{{'temperature' | translate}}</b>
        <div
          class="row"
          *ngFor="let temp of temperatureData"
          style="padding:7px;"
        >
          <div class="col-lg-8">
            <div>{{'temperatureat' | translate}} {{ temp.createdDate | date: "h:mm a" }}</div>
          </div>
          <div class="col-lg-4">
            <b [ngStyle]="{ color: checkTemperature(temp.tem,temp.units) ? 'green' : 'red' }">
              {{ !!temp.tem ? temp.tem : "" }}
              {{
                !!temp.units
                  ? temp.units == "ferhanite"
                    ? "&#8457;"
                    : "&#8451;"
                  : ""
              }}
            </b>
          </div>
        </div>
        <hr *ngIf="tempShow && oxygenShow">
        <b *ngIf="oxygenShow">{{'oximeter' | translate}}</b>
        <div class="row" *ngFor="let oxy of oxygenReads">
          
          <div class="col-md-8">{{'oximeterat' | translate}} {{ oxy.createdDate | date: "h:mm a" }} </div>
          <div class="col-md-4" [ngStyle]="{ color: checkOxygen(oxy.value) ? 'green' : 'red' }"> {{oxy.value}}% </div>

     </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="example-card mat-select-panel bottom-scroll" *ngIf="pageType == 'takeAssessment'">
  <div class="row">
    <div class="col-md-6">
      <div class="visitor-info-header">
        {{'takeassessment' | translate}}
      </div>
    </div>
    <div class="col-md-6">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-card-content>

    <div class="row" style="margin-top:10px;">
      <div class="col-lg-4">
        <mat-select
          [formControl]="bankServerSideCtrl"
          [(ngModel)]="selectedEmsUserIds"
          class="form-control"
          placeholder="Search users by name"
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search User By Name"
              noEntriesFoundLabel=""
              [formControl]="bankServerSideFilteringCtrl"
              [searching]="searching"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let user of filteredServerSideBanks | async"
            [value]="user"
          >
            {{ user.name + " - " + user.email }}
          </mat-option>
          <mat-option
            *ngIf="showLoadMore"
            value="loadmore"
            disabled
            (click)="getNextRecords()"
            >{{'loadmore...' | translate}}</mat-option
          >
        </mat-select>
      </div>

      <div class="col-md-3">
        <button type="button" class="btn btn-main btnSmall" (click)="getQuestion()">
         {{ 'Search' | translate }}
        </button>
      </div>

      <div class="col-md-6"></div>
      
      <ng-container *ngIf="temperatureCheckEnabled && temperatureCheckDisaply">
        
        <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
            <!-- <p class="fontColor">{{'details' | translate}}</p> -->
            <div class="form-row align-items-center">
              <div class="col-md-8">
                <!-- <b>Desmond Eagle</b>
                <p>DesmondEagle@gmail.com</p> -->
                <div>{{'enteryourbodytemperature(C/F)' | translate}}</div>
              </div>
              <div class="col-md-1 text-right mt-3">
                <img src="./assets/images/temperature.svg" alt="temperature">
              </div>
              <div class="col-md-3"> 
                <label>{{'enterBodyTemperature' | translate}} (&#8451;)</label>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    id="temValue"
                    [(ngModel)]="temValue"
                    name="temValue"
                    aria-label="Text input with dropdown button"
                    value="35"
                    maxLength="6"
                  />
                  <div class="input-group-append">
                    <mat-select id="temValueType" style="min-width: 65px;"
                    [(ngModel)]="temValueType"
                    class="form-control" name="temValueType"
                  >
                    <mat-option value="F">&#8457;</mat-option>
                    <mat-option value="C">&#8451;</mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </ng-container>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div
      class="input-group-append pull-right col-lg-12 mt-5"
      *ngIf="temSubmitEnable"
    >
      <span style="margin-left:93%;margin-top: -5%"
        class="btn btn-main btnSmall"
        (click)="saveTemperature()"
        id="basic-addon2"
        >{{'submit' | translate}}</span
      >
    
    </div>
    </div>
    <div class="row" style="margin:10px;margin-left:0px;">
      <div class="col-lg-7" style="padding:0px;">
        <div *ngIf="selfAssessmentSoryy">
          <img
            src="assets/images/sorrypage.png"
            style="width: 300px;"
          />
          <div
            class="card"
            style="width: 18rem;background-color:#041027;margin-top:10px;"
          >
            <div class="card-body row">
              <div class="col-lg-2">
                <img
                  src="assets/images/identity.png"
                  style="width:40px;color:#fff"
                />
              </div>
              <div class="col-lg-10">
                <h5 class="card-title">
                  <span style="color:#fff">{{'sorry' | translate}}</span>
                </h5>
                <p
                  style="color:#fff;margin:10px;font-size:10px;text-align: justify;"
                  class="card-text"
                >
                  {{ selfAssessmentMsg }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selfAssessmentThank">
          <img
            src="assets/images/group_7051.png"
            style="width: 300px;"
          />
          <div
            class="card"
            style="width: 18rem;background-color:#041027;margin-top:10px;"
          >
            <div class="card-body row">
              <div class="col-lg-2">
                <img
                  src="assets/images/shield.png"
                  style="width:40px;color:#fff"
                />
              </div>
              <div class="col-lg-10">
                <h5 class="card-title">
                  <span style="color:#fff">{{'congratulation' | translate}}</span>
                </h5>
                <div style="color:#fff">
                  {{'validtill' | translate}}
                </div>
                <div style="color:#fff">
                  {{ selfassessmentRes?.validUpto | date: "d/MMM y" }}
                </div>
                <div style="color:#fff">
                  {{ !!selectedEmsUserIds ? selectedEmsUserIds?.name : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container
          *ngFor="let que of questionList; let k = index; let last = last"
        >
          <div style="margin-top:10px;" *ngIf="!selfAssessmentThank">
            <div>{{ que.metadata.question }} </div>
            <mat-radio-group class="example-section"
              name="optradio-{{ k }}"
             [(ngModel)]="que.defaultValue">
                <mat-radio-button class="radio-margin" *ngFor="let option of que.options; let i = index"
                [value]="option.key">{{ option.value }}&nbsp;&nbsp;</mat-radio-button>
              </mat-radio-group>
            <!-- <ng-container *ngFor="let option of que.options; let i = index">
                <mat-radio-group
              class="example-section"
              name="optradio-{{ k }}"
              [(ngModel)]="que.defaultValue"
              required
            >
              <mat-radio-button
                class="radio-margin"
                [value]="option.key"
              >
              {{ option.value }}
              </mat-radio-button>
            </mat-radio-group>
             
            </ng-container> -->
          </div>
          <div
            class="input-group-append"
            *ngIf="last"
            style="margin-top: 10px;"
          >
            <span
              class="btn btn-main btnSmall"
              (click)="submitQuestion()"
              id="basic-addon2"
              >{{'submit' | translate}}</span
            >
          </div>
        </ng-container>
      </div>
      <div class="col-lg-5">
        <!-- <div
          class="input-group-append pull-right col-lg-12"
          *ngIf="temSubmitEnable"
        >
          <span style="margin-left:89%"
            class="input-group-text btn btn-primary"
            (click)="saveTemperature()"
            id="basic-addon2"
            >Submit</span
          >
        </div> -->
        <div
          class="row"
          *ngFor="let temp of temperatureData"
          style="padding:7px;margin-left:12%"
        >
          <div class="col-lg-12">
            <div>{{'temperatureat' | translate}} {{ temp.createdDate | date: "h:mm a" }} 
              <b [ngStyle]="{ color: checkTemperature(temp.tem,temp.units) ? 'green' : 'red' }" style="margin-left:18%">
                {{ !!temp.tem ? temp.tem : "" }}
                {{
                  !!temp.units
                    ? temp.units == "ferhanite"
                      ? "&#8457;"
                      : "&#8451;"
                    : ""
                }}
              </b>
            </div>
          </div>
          <!-- <div class="col-lg-3">
            <b [ngStyle]="{ color: checkTemperature(temp.tem,temp.units) ? 'green' : 'red' }">
              {{ !!temp.tem ? temp.tem : "" }}
              {{
                !!temp.units
                  ? temp.units == "ferhanite"
                    ? "&#8457;"
                    : "&#8451;"
                  : ""
              }}
            </b>
          </div> -->
        </div>
        <br>
        <ng-container style="height:400px;overflow: auto;"  *ngIf = 'oxymeterCheckEnable && oxymeterCheckDisplay'> 
          <hr>
          <div class = "row">
            <div class='col-lg-2'></div>
            <div class='col-lg-10' style="margin-left: 25%">
          <span>{{'oximeter' | translate}}</span>
          <div class="input-group">  
              <input type="number" min="0" max="100" [(ngModel)]="oxygenReading" (input)="checkValidOxy(oxygenReading)" class="form-control col-lg-6"> 
              <input type="text"  value="%"  class="form-control col-lg-2">&nbsp;&nbsp;  
        <button style="font-size: small" *ngIf="oxymeterSubmitEnable"
        type="button"
        [disabled]="hideOxygen"
        class="btn btn-main btnSmall"
        (click)="submitoxygenAssesment()" >
      {{ 'submit' | translate }}
      </button>
        </div><br> 
        <div class="row" *ngFor="let oxy of oxygenReads">
             <div class="col-md-8">{{'oximeterat' | translate}} {{ oxy.createdDate | date: "h:mm a" }} </div>
             <div class="col-md-4" [ngStyle]="{ color: checkOxygen(oxy.value) ? 'green' : 'red' }"> {{oxy.value}}% </div>
  
        </div> 
        </div>
        
      </div>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
