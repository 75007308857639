import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTable, MAT_DIALOG_DATA } from '@angular/material';
import { ResourceTimelineLane } from '@fullcalendar/resource-timeline';
import { CommonService } from 'commonService';
import { EMSService } from 'emsService';
import { LoaderService } from 'loaderService';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged } from 'rxjs/operators';
import { DemandSupplyServiceService } from '../../service/demand-supply-service.service';
@Component({
    selector: "app-demand-request-modal",
    templateUrl: "./demand-request-modal.component.html",
    styleUrls: ["./demand-request-modal.component.scss"],
})
export class DemandRequestModalComponent implements OnInit {

    requestId;
    breakpoint;
    costDataSource = [
        {
            workstation: "Cabin",
            price: "$200",
            quantity: 30,
            cost: "$6000",
        },
        {
            workstation: "Running COunter",
            price: "$100",
            quantity: 20,
            cost: "$2000",
        },
    ];
    availabilityDataSource = [];
    displayedAvailabiltyColumns = ["workstation", "quantity", "availability"];
    displayedColumns = ["workstation", "price", "quantity", "approxCost"];
    preferredZoneEntityInsightList: any = [];
    preferredLocationList = [
        {
            floors: [
                {
                    name: "Floor 1",
                    used: 10,
                    total: 10,
                    entities: [
                        {
                            used: 10,
                            total: 10,
                            color: "#0F1F54",
                        },
                    ],
                },
                {
                    name: "Floor 2",
                    used: 30,
                    total: 50,
                    entities: [
                        {
                            used: 10,
                            total: 20,
                            color: "#0F1F54",
                        },
                        {
                            used: 20,
                            total: 30,
                            color: "pink",
                        },
                    ],
                },
            ],
        },
        {
            floors: [
                {
                    name: "Floor 1",
                    used: 30,
                    total: 50,
                    entities: [
                        {
                            used: 10,
                            total: 20,
                            color: "#0F1F54",
                        },
                        {
                            used: 20,
                            total: 30,
                            color: "pink",
                        },
                    ],
                },
            ],
        },
        {
            floors: [
                {
                    name: "Floor 1",
                    used: 10,
                    total: 10,
                    entities: [
                        {
                            used: 10,
                            total: 10,
                            color: "#0F1F54",
                        },
                    ],
                },
                {
                    name: "Floor 2",
                    used: 30,
                    total: 50,
                    entities: [
                        {
                            used: 10,
                            total: 20,
                            color: "#0F1F54",
                        },
                        {
                            used: 20,
                            total: 30,
                            color: "pink",
                        },
                    ],
                },
            ],
        },
        {
            floors: [
                {
                    name: "Floor 1",
                    used: 10,
                    total: 10,
                    entities: [
                        {
                            used: 10,
                            total: 10,
                            color: "#0F1F54",
                        },
                    ],
                },
                {
                    name: "Floor 2",
                    used: 30,
                    total: 50,
                    entities: [
                        {
                            used: 10,
                            total: 20,
                            color: "#0F1F54",
                        },
                        {
                            used: 20,
                            total: 30,
                            color: "pink",
                        },
                    ],
                },
            ],
        },
    ];
    modulePermissions = {};
    currentZone;
    constructor(
        private demandSupplyService: DemandSupplyServiceService,
        private loadingService: LoaderService,
        private emsService: EMSService,
        private commonService: CommonService,
        private toastrService: ToastrService,
        public dialogRef: MatDialogRef<DemandRequestModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.commonService.currentZone
            .pipe(distinctUntilChanged())
            .subscribe((res) => {
                if (res) {
                    this.currentZone = res;
                    this.getCurrentUserModules(this.currentZone);
                }
            });
    }

    isShowable(responsibility) {
        if (this.modulePermissions[this.currentZone]) {
            return this.modulePermissions[this.currentZone].includes(
                responsibility
            );
        }
        return false;
    }
    getCurrentUserModules(zone) {
        this.emsService.getCurrentUserModules(zone).subscribe(
            (res) => {
                this.modulePermissions = {};
                this.modulePermissions[zone] = [];
                for (var i in res["response"]["responsibilities"]) {
                    if (zone == i) {
                        this.modulePermissions[zone] =
                            res["response"]["responsibilities"][i];
                    }
                }
            },
            (err) => {
                this.commonService.openSnackBar(err.error.message, "");
            }
        );
    }
    requestIdEvent(event) {
        this.requestId = event;
    }
    closeConfirmationPopup() {
        this.dialogRef.close({ updateData: true });
    }

    onResize(event) {
        this.breakpoint = event.target.innerWidth <= 400 ? 1 : 6;
    }

    changePageType(event) {
        this.data.type = event;
        if (this.data.type == "newRequest" || this.data.type == "editRequest") {
            this.dialogRef.updateSize("80%", "auto"); //create request modal size
        } else if (
            this.data.type == "saveNotification" ||
            this.data.type == "updateNotification"
        ) {
            this.dialogRef.updateSize("58%", "auto");
        } else if (this.data.type == "moveRequest") {
            this.dialogRef.close({ updateData: false });
        } else {
            this.dialogRef.updateSize("80%", "60%");
        }
    }

    getViewInsightsData(event) {
        if (event) {
            if (this.data.type == "preferredAllocation") {
                let insightList: any = [];
                const requests$ = this.demandSupplyService.getEntityTypeInsights(
                    this.data.currentZone,
                    event
                );
                const request$ = this.loadingService
                    .showLoaderUntilCompleted(requests$)
                    .subscribe(
                        (res) => {
                            if (res["response"]) {
                                this.preferredLocationList = res["response"];
                                this.preferredZoneEntityInsightList =
                                    res["response"];
                                this.preferredZoneEntityInsightList[
                                    "values"
                                ].forEach((building) => {
                                    building.floorCounts.forEach((floor) => {
                                        let countRequired = 0;
                                        let totalCountAvailable = 0;
                                        floor.entityTypeCounts.forEach(
                                            (entity) => {
                                                if (
                                                    this
                                                        .preferredZoneEntityInsightList[
                                                        "requestedCountsByEntityType"
                                                    ][entity.entityType.id]
                                                ) {
                                                    countRequired =
                                                        countRequired +
                                                        parseInt(
                                                            this
                                                                .preferredZoneEntityInsightList[
                                                                "requestedCountsByEntityType"
                                                            ][
                                                                entity
                                                                    .entityType
                                                                    .id
                                                            ]
                                                        );
                                                }
                                                // console.log(
                                                //     entity.availableCount
                                                // );
                                                totalCountAvailable =
                                                    totalCountAvailable +
                                                    parseInt(
                                                        entity.availableCount
                                                    );
                                            }
                                        );
                                        floor["countRequired"] = countRequired;
                                        floor[
                                            "totalCountAvailable"
                                        ] = totalCountAvailable;
                                        // console.log(
                                        //     `total is ${totalCountAvailable}`
                                        // );
                                    });
                                });
                                // this.toastrService.success(`Request with id ${element.requestId} deleted successfully`);
                                // console.log(this.pageIndex);
                                // this.getAllRequests(this.pageIndex, this.pageSize);
                            } else {
                                this.toastrService.error(res["message"]);
                            }
                        },
                        (err) => {}
                    );
            } else if (this.data.type == "availability") {
                const requests$ = this.demandSupplyService.getAvailabilityInsights(
                    this.data.currentZone,
                    event
                );
                const request$ = this.loadingService
                    .showLoaderUntilCompleted(requests$)
                    .subscribe(
                        (res) => {
                            // console.log(res["response"]);
                            if (res["response"]) {
                                this.availabilityDataSource = res["response"];
                                // this.toastrService.success(`Request with id ${element.requestId} deleted successfully`);
                                // console.log(this.pageIndex);
                                // this.getAllRequests(this.pageIndex, this.pageSize);
                            } else {
                                this.toastrService.error(res["message"]);
                            }
                        },
                        (err) => {}
                    );
            }
        }
    }

    getLegendColor(entityId) {
        return this.preferredZoneEntityInsightList.entityTypeColorMap[entityId];
    }

    getRequiredWorkstationCount(entityId) {
        // let count = 0;
        // if(type == 'individual'){
        if (
            this.preferredZoneEntityInsightList.requestedCountsByEntityType[
                entityId
            ]
        ) {
            return this.preferredZoneEntityInsightList
                .requestedCountsByEntityType[entityId];
        } else {
            return 0;
        }
        // } else {
        //   if (this.preferredZoneEntityInsightList.requestedCountsByEntityType[entityId]) {
        //     count = count+parseInt(this.preferredZoneEntityInsightList.requestedCountsByEntityType[entityId]);
        //     return count;
        //   }
        // }
    }

    getMoveRequestData(event) {
        // console.log(event)
    }

    closePopup() {
        if (this.data.type == "newRequest" || this.data.type == "editRequest") {
            this.dialogRef.close({ updateData: false });
        } else {
            // console.log(this.data.type);
            this.data.type = "newRequest";
            this.dialogRef.updateSize("80%", "auto"); //create request modal size
        }
    }
}

