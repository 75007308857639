import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LoaderService } from "loaderService";
import { DashboardService } from "../../services/dashboard.services";
import { CommonService } from "commonService";
import { DayCellContentArg } from "@fullcalendar/core";
import { FullCalendarComponent } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
@Component({
  selector: "app-filtered-colleagues-modal",
  templateUrl: "./filtered-colleagues-modal.component.html",
  styleUrls: ["./filtered-colleagues-modal.component.scss"],
})
export class FilteredColleaguesModalComponent implements OnInit {
  pageType;
  userInfo = {
    id: "",
    name: "",
    img: "",
    deskNo: "",
    loc: "",
    statusColor:"",
    department: ""
  };
    constructor(
    private loaderService: LoaderService,
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilteredColleaguesModalComponent>,
    private commonService: CommonService
  ) {
    
  }
  timeZoneByConfig = "UTC";
  // @ViewChild(FullCalendarComponent, { static: false })
  // fullCalendarComponent: FullCalendarComponent;
  @ViewChild('fullCalendar', { static: false }) fullCalendarComponent!: FullCalendarComponent;
  calendarOptions = {};
  events = [];
  selectedDates = [];
  calendarColors = {
    OFFICE: "#0F1F54",
    WFH: "#f36c20",
    LEAVE: "#86bce3",
  };
  ngOnInit() {

    this.pageType = this.data["type"];
    let dialogData = this.data["dialogData"];
    if(this.pageType=='selectedColleagueWorkStatus'){ 
      this.calendarOptions = {
        timeZone: "UTC",
        initialView: "dayGridMonth",
        dayCellClassNames: this.customDayCellContent.bind(this),
        plugins: [dayGridPlugin],
        showNonCurrentDates: false,
        height: "auto",
        // dateClick: this.handleDateClick.bind(this),
        // dayCellDidMount: this.handleCellClick.bind(this),
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        headerToolbar: {
          left: "previousMonth",
          center: "title",
          right: "nextMonth",
        },
        customButtons: {
          previousMonth: {
            text: "Previous",
            icon: "chevron-left",
            click: this.prevMonth.bind(this), // !!!
          },
          nextMonth: {
            text: "Next",
            icon: "chevron-right",
            click: this.nextMonth.bind(this), // !!!
          },
        },
        eventContent: (arg) => {
          let htmlStr = "";
          return {
            html:
              "<div class='eventColor' style='background-color:" +
              arg.event.extendedProps.workStatusColor +
              "'>&nbsp</div>",
          };
        },
        eventSources: [
          {
            events: this.events,
            display: "list-item",
            borderColor: "none",
          },
        ],
        selectOverlap: false,
      };
      setTimeout(() => {
        this.updateDateAndLoadInfo();
      }, 500);
      
      
    }else if(this.pageType=='selectedColleagueModal'){
    this.loadInfo(dialogData["userId"]);
    this.userInfo["id"] = dialogData["userId"];
    this.userInfo["name"] = dialogData["name"];
    this.userInfo["location"] = dialogData["location"];
    this.userInfo["img"] = dialogData["imageUrl"];
    this.userInfo["statusColor"] = 'none';
    this.userInfo["department"] = dialogData["departName"];
    }
    
  }

  customDayCellContent(dayCellContentArg: DayCellContentArg) {
    const cellDate = dayCellContentArg.date;
    let startDate =this.commonService.getTimeStampTimeZone(new Date(),this.timeZoneByConfig,'start');
    if(startDate > cellDate.getTime()){
      dayCellContentArg.isPast=true;
    }else{
      dayCellContentArg.isPast=false;
    }
  }

  prevMonth() {
    this.fullCalendarComponent.getApi().prev();
    this.updateDateAndLoadInfo();
  }
  nextMonth() {
    this.fullCalendarComponent.getApi().next();
    this.updateDateAndLoadInfo();
  }
  loadInfo(userId) {
    let currentMillis = this.commonService.getTimeStamp(
        new Date().getTime(),
        "start"
    );
    let endDateInMillis = currentMillis + 86400000 - 1; //For getting endTime in millis
    let currentBookings = this.loaderService
    .showLoaderUntilCompleted(this.dashboardService.loadBookedSeatsProxyId(
      currentMillis,
      endDateInMillis,
      userId
    )).subscribe(res=>{
      if(res['response'].length>0){
        this.userInfo["statusColor"]= res['response'][0]['color'] != null? res['response'][0]['color'] : 'none';
        //this.userInfo["statusColor"] = res[0]['color'];
      }
    })
  }
  

  sendColleagueRequest() {
    let usrId = localStorage.getItem("ssadmin_userId");
    let data = {
      searchId: this.userInfo["id"],
      userId: Number(usrId),
    };
    let colleagueAPI = this.dashboardService.createFavouriteColleague(data);
    this.loaderService
      .showLoaderUntilCompleted(colleagueAPI)
      .subscribe((res) => {
        if(res) {
          this.dialogRef.close("colleageRequest");
          this.dashboardService.showColleaguesModalOncloseAddFav("ShowColleaguesModal");
        }
      });
  }

  closePopup() {
    this.dialogRef.close();
  }


  updateDateAndLoadInfo() {
    let changedDate = this.fullCalendarComponent.getApi().getDate().getTime();
    this.loadCurrentMonthInfo(changedDate);
  }

  loadCurrentMonthInfo(changedDate) {
    let cdate = new Date(changedDate);
    this.events = [];
    const firstMonth = new Date(
      cdate.getUTCFullYear(),
      cdate.getUTCMonth(),
      1
    );

    const lastMonth = new Date(
      cdate.getUTCFullYear(),
      cdate.getUTCMonth() + 1,
      0
    );
    
    let fdateTimeStamp = this.commonService.getTimeStamp(
      firstMonth.getTime(),
      "start"
    );
    let ldateTimeStamp = this.commonService.getTimeStamp(
      lastMonth.getTime(),
      "end"
    );
    let bookedSeats = this.dashboardService.loadBookedSeatsProxyId(
      fdateTimeStamp,
      ldateTimeStamp,
      this.data["dialogData"]["id"]
    );
    this.loaderService
      .showLoaderUntilCompleted(bookedSeats)
      .subscribe((res) => {
        let bookingInfo = res["response"];
        if (bookingInfo.length > 0) {
          bookingInfo.forEach((element) => {
            let color = element["color"];
            if (color == null) {
              color = "none";
            }
            let selectedDate = element["dayStart"];
            let selectedDateIndex = this.selectedDates.findIndex(
              (e) => e.date == selectedDate
            );

            if (selectedDateIndex > -1) {
              color =
                this.calendarColors[
                  this.selectedDates[selectedDateIndex]["workstatus"]
                ];
            }

            let data = {
              title: "",
              start: element.dayStart,
              workStatusColor: color,
              workstatus:element.status
            };
            this.events.push(data);
          });

          this.fullCalendarComponent.getApi().removeAllEventSources();
          this.fullCalendarComponent.getApi().addEventSource(this.events);
          this.fullCalendarComponent.getApi().refetchResources();
          this.fullCalendarComponent.getApi().refetchEvents();
        }
      });
   
    this.fullCalendarComponent.getApi().removeAllEventSources();
    this.fullCalendarComponent.getApi().addEventSource(this.events);
    this.fullCalendarComponent.getApi().refetchResources();
    this.fullCalendarComponent.getApi().refetchEvents();
  }
}
