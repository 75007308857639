<mat-dialog-content>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="container-fluid p-0">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="building">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "Building" | translate }}
                </th>
                <td class="border-right-0" mat-cell *matCellDef="let data; let i = dataIndex"
                    [attr.rowspan]="getRowSpan('building', i)" [style.display]="
                                getRowSpan('building', i) ? '' : 'none'
                            " style="border-right: 1px solid rgba(0, 0, 0, 0.12);">
                    {{ data?.building?.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="floor">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "floor" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.floor?.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="workstations">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "workstations" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <!-- {{ element?.workstation?.count }} -->
                    {{ 'NA' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="employees">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "relocationEmployees" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <!-- {{ element.value }} -->
                    {{ 'NA' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="moveEmployees">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "moveEmployees" | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-radio-group [(ngModel)]="row.selected" (change)="radioSelected(row)">
                        <mat-radio-button value="custom">Custom</mat-radio-button>
                        <mat-radio-button value="all" [checked]="true">All</mat-radio-button>
                    </mat-radio-group>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <button type="button" (click)="proceed()" class="btn btn float-right btn-main rounded-pill m-3">{{'proceed' |
            translate}}</button>
    </div>
</mat-dialog-content>