/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fullcalendar/angular/fullcalendar-angular.ngfactory";
import * as i3 from "@fullcalendar/angular";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../my-schedules/my-schedles.component.ngfactory";
import * as i7 from "../my-schedules/my-schedles.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../services/common-service.service";
import * as i10 from "../services/dashboard.services";
import * as i11 from "../../../shared/modules/loader/loader.service";
import * as i12 from "ngx-toastr";
import * as i13 from "../../../shared/confirmation-dialog/confirmation-dialog.service";
import * as i14 from "../../../shared/services/ems.service";
import * as i15 from "./colleagues/colleagues.component.ngfactory";
import * as i16 from "./colleagues/colleagues.component";
import * as i17 from "./search-location-reuseable-modal/search-location-reuseable-modal.component.ngfactory";
import * as i18 from "./search-location-reuseable-modal/search-location-reuseable-modal.component";
import * as i19 from "@angular/forms";
import * as i20 from "../../manage-request/manage-booking/containers/work-status/service/work-status.service";
import * as i21 from "./dashboard-modal.component";
var styles_DashboardModalComponent = [i0.styles];
var RenderType_DashboardModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardModalComponent, data: {} });
export { RenderType_DashboardModalComponent as RenderType_DashboardModalComponent };
function View_DashboardModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "full-calendar", [], null, null, null, i2.View_FullCalendarComponent_0, i2.RenderType_FullCalendarComponent)), i1.ɵdid(1, 6733824, [[1, 4], ["fullcalendar", 4]], 0, i3.FullCalendarComponent, [i1.ElementRef], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarOptions; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DashboardModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "only-calndarview"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "d-flex align-items-center regular-font m-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "calendar-schedule-Office mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarOptions; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("youHaveAQuickAction")); _ck(_v, 5, 0, currVal_1); }); }
function View_DashboardModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-body align-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-my-schedules", [], null, [[null, "doneSchedule"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("doneSchedule" === en)) {
        var pd_0 = (_co.doneSchedule($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MySchedulesComponent_0, i6.RenderType_MySchedulesComponent)), i1.ɵdid(10, 114688, null, 0, i7.MySchedulesComponent, [i8.MatDialog, i9.CommonService, i10.DashboardService, i11.LoaderService, i12.ToastrService, i13.ConfirmationDialogService, i5.TranslateService, i14.EMSService], null, { doneSchedule: "doneSchedule" })], function (_ck, _v) { _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("workStatus")); _ck(_v, 3, 0, currVal_0); }); }
function View_DashboardModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "modal-body align-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "w-20"], ["src", "./assets/images/checked.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "p", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-primary btn-main btn-Small btn-radius btn-custom-border"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("success")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("taskCompletedSuccessfully")); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("done")); _ck(_v, 12, 0, currVal_2); }); }
function View_DashboardModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["class", "ml-3"], ["src", "./assets/images/jumpree_digital/calendar_icon.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showFullCalendar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-body align-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-colleagues", [], null, [[null, "notifyParentColleague"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notifyParentColleague" === en)) {
        var pd_0 = (_co.getNotification($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_ColleaguesComponent_0, i15.RenderType_ColleaguesComponent)), i1.ɵdid(11, 114688, null, 0, i16.ColleaguesComponent, [], { colleagueObj: [0, "colleagueObj"] }, { notifyParentColleague: "notifyParentColleague" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.colleagueObj; _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("myFavInOffice")); _ck(_v, 3, 0, currVal_0); }); }
function View_DashboardModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header modalHeaderPadding pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h6", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-body align-left pl-1 py-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-search-location-reuseable-modal", [], null, [[null, "notifySearchLocation"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notifySearchLocation" === en)) {
        var pd_0 = (_co.getFilteredSearchLocation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_SearchLocationReuseableModalComponent_0, i17.RenderType_SearchLocationReuseableModalComponent)), i1.ɵdid(10, 114688, null, 0, i18.SearchLocationReuseableModalComponent, [i9.CommonService, i19.FormBuilder, i11.LoaderService, i12.ToastrService, i20.WorkStatusService], { locationList: [0, "locationList"], selectedDate: [1, "selectedDate"], selectedOfficeDates: [2, "selectedOfficeDates"], currentZonePass: [3, "currentZonePass"] }, { notifySearchLocation: "notifySearchLocation" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.locationList; var currVal_2 = _co.selectedDate; var currVal_3 = _co.selectedOfficeDates; var currVal_4 = _co.currentZone; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("selectOffice")); _ck(_v, 3, 0, currVal_0); }); }
function View_DashboardModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "mt-2 openSans"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-body align-left pl-1 py-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-search-location-reuseable-modal", [], null, [[null, "saveDateLocation"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveDateLocation" === en)) {
        var pd_0 = (_co.saveDateLocation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_SearchLocationReuseableModalComponent_0, i17.RenderType_SearchLocationReuseableModalComponent)), i1.ɵdid(11, 114688, null, 0, i18.SearchLocationReuseableModalComponent, [i9.CommonService, i19.FormBuilder, i11.LoaderService, i12.ToastrService, i20.WorkStatusService], { locationList: [0, "locationList"], selectedOfficeDates: [1, "selectedOfficeDates"], currentZonePass: [2, "currentZonePass"] }, { saveDateLocation: "saveDateLocation" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.locationList; var currVal_3 = _co.selectedOfficeDates; var currVal_4 = _co.currentZone; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("workStatus")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("youCanSelectDifferentLocationsForTheDatesYouAreWorkingFromOffice")); _ck(_v, 7, 0, currVal_1); }); }
export function View_DashboardModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fullCalendarComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardModalComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardModalComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardModalComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardModalComponent_5)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardModalComponent_6)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardModalComponent_7)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pageType == "calendarView"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.pageType == "schedule"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.pageType == "scheduleCreated"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.pageType == "colleaguesModal"); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.pageType == "searchLocation"); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_co.pageType == "selectLocations"); _ck(_v, 12, 0, currVal_5); }, null); }
export function View_DashboardModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard-modal", [], null, null, null, View_DashboardModalComponent_0, RenderType_DashboardModalComponent)), i1.ɵdid(1, 114688, null, 0, i21.DashboardModalComponent, [i9.CommonService, i8.MAT_DIALOG_DATA, i8.MatDialogRef, i10.DashboardService, i11.LoaderService, i12.ToastrService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardModalComponentNgFactory = i1.ɵccf("app-dashboard-modal", i21.DashboardModalComponent, View_DashboardModalComponent_Host_0, {}, {}, []);
export { DashboardModalComponentNgFactory as DashboardModalComponentNgFactory };
