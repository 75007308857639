import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./shared";

const routes: Routes = [
    { path: "", loadChildren: "./login/login.module#LoginModule" },
    // { path: 'login', loadChildren: './login/login.module#LoginModule' },
    {
        path: "login",
        loadChildren: () =>
            import("./login/login.module").then(m => m.LoginModule)
    },
    {
        path: "login/:msg1/:msg2",
        loadChildren: () =>
            import("./login/login.module").then(m => m.LoginModule)
    },
    {
        path: "signup",
        loadChildren: () =>
            import("./signup/signup.module").then(m => m.SignupModule)
    },
    {
        path: "layout",
        loadChildren: () =>
            import("./layout/layout.module").then(m => m.LayoutModule)
    },
    {
        path: "accountVerification/:token/:userType",
        loadChildren: () =>
            import("./accountVerify/accountVerify.module").then(
                m => m.AccountVerifyModule
            )
    },
    {
        path: "accountVerification/:token",
        loadChildren: () =>
            import("./accountVerify/accountVerify.module").then(
                m => m.AccountVerifyModule
            )
    },
    {
        path: "tenantVerification/:token",
        loadChildren: () =>
            import("./tenantVerify/tenantVerify.module").then(
                m => m.TenantVerifyModule
            )
    },
    {
        path: "forgotPassword/:verificationCode",
        loadChildren: () =>
            import("./forgotPassword/forgotPassword.module").then(
                m => m.ForgotPasswordModule
            )
    },
    {
        path: "forgotPasswordSuccess",
        loadChildren: () =>
            import("./forgotPasswordSuccess/forgotPasswordSuccess.module").then(
                m => m.ForgotPasswordSuccessModule
            )
    },
    {
        path: "frgt-password",
        loadChildren: () =>
            import("./frgt-password/frgt-password.module").then(
                m => m.FrgtPasswordModule
            )
    },
    {
        path: "frgt-password-success",
        loadChildren: () =>
            import("./frgt-password-success/frgt-password-success.module").then(
                m => m.FrgtPasswordSuccessModule
            )
    },
    {
        path: '404', 
        loadChildren: () =>
        import("./notfound/notfound.module").then(
            m => m.NotfoundModule
        )
    },
    {path: '**', redirectTo: '/404'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
