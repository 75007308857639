import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatInput, MatSelect, MatOption, MatCheckbox, MatTableDataSource, MatRadioButton, MatRadioGroup, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import 'rxjs/add/observable/of';
import { CommonService } from '../../../services/common-service.service';
import { VMSService } from '../service/vms.service';
import { CrmLead } from '../model/crmLead';
import { PointOfContact } from '../model/pointOfContact';
import { RequirementOfCustomer } from '../model/requirementOfCustomer';
import { routerTransition } from '../../../router.animations';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { EMSService } from '../../../shared/services/ems.service';
import { TMSService } from '../../../shared/services/tms.service';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { LoaderService } from "loaderService";
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from "rxjs/observable/forkJoin";
import * as _ from 'lodash';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { ExcelService } from '../../amenity-booking/services/excel.service';
import { invalid } from 'moment-timezone';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vms-modal',
  templateUrl: './vms-modal.component.html',
  styleUrls: ['./vms-modal.component.scss']
})
export class VMSModalDigiComponent implements OnInit, AfterViewInit {
  currentZone = 5;
  displayedProxyColumns = ['User', 'Proxy', 'Actions'];
  dataSourceProxy = [];
  disableDone = false;
  proxyData = [];
  index;
  updateProxy = false;
  public form: FormGroup;
  isValidParticipantForm: boolean;
  participantName;
  locations = [];
  hosts = [];
  public csvRecords: any[] = [];
  columns: string[] = ['name', 'email', 'phone', 'action'];
  visitorList: any[] = [];
  dataSource = [];
  visitorInfo: any = {};
  hostInfo: any = {};
  showDisable = false;
  csvContent: string;
  toolTipText: string = "Disabled";
  IstooltipShown: boolean = false;
  questionAndAnswers: any = [];
  model: any = { "optionsq": [] };
  encryptedQRCode: any = '';
  minDate = new Date(new Date().setHours(0, 0, 0, 0));
  declaration = '';
  questionEntered = false;
  pageType: any;
  intivationId: any;
  DataList: any = [];
  allList: any;
  isAdditional: boolean;
  addVisitorInfo: FormGroup;
  notifyToogle = false;
  user;
  userProxy;
  userId;
  proxyId;
  editProxyBtn = false;
  vmsConfig: any;
  storeVisitor;
  isVisitorEmail: any;
  isVisitorMobile: any;
  isVisitorCompany: any;
  isVisitorName: any;
  submitted = false;
  editable = false;
  editedQuestion: any;
  headings: any;
  editableHeading = false;
  headingCheckList: any = {};
  modelvms: any = { "optionsq": [{ qoption: '', redflag: false }, { qoption: '', redflag: false }] };
  @ViewChild("mytitle", { static: true }) nameField: ElementRef;
  errorMessage: any;
  constructor(
    public matSnackBar: MatSnackBar,
    private tmsService: TMSService,
    public dialogRef: MatDialogRef<VMSModalDigiComponent>,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public commonService: CommonService,
    public vmsService: VMSService,
    public emsService: EMSService,
    public excelService: ExcelService,
    private fb: FormBuilder,
    public loaderService: LoaderService,
    private toastrservice: ToastrService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pageType = this.data['type'];
    if (this.data['type'] == 'visitorInfo') {
      this.intivationId = this.data['invitationVisitorInfoId'];
    }

  }
  nameSearchTermSubject$ = new Subject<any>();
  nameSearchTermAction$ = this.nameSearchTermSubject$.asObservable();
  nameAutocompleteList$ = this.nameSearchTermAction$.pipe(
    tap(() => {
    }),
    switchMap((searchTerm) =>
      of(searchTerm).pipe(
        mergeMap((res) =>
          this.emsService.searchHostsUser(
            searchTerm,
            this.currentZone
          )
        )
      )
    ),
    tap((searchRes) => {
    }),
    catchError((err) => {
      return null;
    })
  );
  countryCodeList;
  countryCodeAddLocation;
  getCountryCodes() {
    //  let currentContext = this;
    this.commonService.getCountryCodes(22, this.currentZone).subscribe(res => {
      this.countryCodeList = (res as any).response;
      this.visitorInfo.countryCode = this.countryCodeList[0]['countryCode'];
    }, err => {
      this.commonService.openSnackBar(err.error.message, '');
    });
  }


  ngOnInit() {
    this.currentZone = this.data['currentZone'];
    if (this.data['type'] == 'visitorInfo') {

      // this.getAllCustomCheckList();
      this.getAllActiveCustomCheckList();
      this.getQuestionAndAnswers();
    }
    if (this.data['type'] == 'customchecklistadd') {
    }


    // if (this.data['type'] == 'customchecklistedit') {
    //   let element = this.data['data']
    //   this.editQuestion(element);
    // }



    if (this.data['type'] == 'additionalDetails') {

      this.intivationId = this.data['content']['invitationVisitorInfoId'];
      this.getAllActiveIdentityFields();
    }
    if (this.data['type'] == 'customiseinstrution') {
      this.getAllActiveCustomCheckList()
    }
    if (this.pageType == 'showQrcode') {
      this.showQRCode();


    }
    if (this.pageType == 'proxypopup') {
      if (this.data['data']) {
        this.editProxyBtn = true;
        this.user = this.data['data']['userName'];
        this.userProxy = this.data['data']['proxyName'];
        this.notifyToogle = this.data['data']['optNotifications'];
        this.userId = this.data['data']['userId'];
        this.proxyId = this.data['data']['proxyId'];
      }
    }

    if (this.pageType == 'createInviteVisitorNew') {
      this.getCountryCodes();
      this.addVisitorInfo = this.fb.group({
        'visitorName': ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
        'visitorEmail': ['', [Validators.email]],
        'visitorMobile': ['', [Validators.required]],
        'visitorCompany': ['', []],
        'visitorCountryCode': ['', [Validators.required]]
      });
      this.getConfig();
    }


    this.form = this.fb.group({
      visitors: this.fb.array([
        this.fb.group({
          'visitorTypeId': this.fb.control(null, Validators.required),
          'name': this.fb.control(null, Validators.required),
          'email': this.fb.control(null, Validators.required),
          'mobile': this.fb.control(null, Validators.required),
          'company': this.fb.control(null)
        })
      ]),
      scheduleStart: this.fb.control(null, Validators.required),
      scheduleEnd: this.fb.control(null, Validators.required),
      purpose: this.fb.control(null, Validators.required),
      location: this.fb.control(null, Validators.required),
      hostEmail: this.fb.control(null, Validators.required),
      invitationInfoId: this.fb.control(null),
      webEx: this.fb.control(null),
      wifi: this.fb.control(null),

    });

    this.form.get('hostEmail').valueChanges.subscribe(
      term => {
        if (term != '') {
          this.emsService.searchHosts(term, this.currentZone).subscribe(
            data => {
              this.hosts = data['response'].content;

            })
        }
      })

    this.form.get('location').valueChanges.subscribe(
      term => {
        if (term != '') {
          this.tmsService.getBuildingListByZoneAndTenant(term, this.currentZone).subscribe(
            data => {
              this.locations = data['response'];

            })
        }
      })


    this.form.statusChanges.subscribe(
      (status) => {
        this.isValidParticipantForm = (status === 'VALID') ? true : false;
      }
    );
    this.downloadReportTypeChange(event);
  }
  onSubmitHeading(f) {
    let data;

    let metInfo = {
      question: this.headingCheckList.heading,
      fieldType: 'HEADING',
      description: this.headingCheckList.headingDescription,
      defaultValue: '',
      values: {},
      approvalConditionValues: {}
    }

    if (this.editableHeading == true) {
      let isEditable;
      if (this.headings[0].isEditable == true) {
        isEditable = 1;
      } else {
        isEditable = 0;
      }

      let isMandatory;
      if (this.headings[0].isMandatory == true) {
        isMandatory = 1;
      } else {
        isMandatory = 0;
      }

      let isActive = true;
      // if (this.headings[0].isActive == true) {
      //   isActive = 1;
      // } else {
      //   isActive = 0;
      // }


      data = {
        "id": this.headings[0].id,
        "isEditable": isEditable,
        "isMandatory": isMandatory,
        "moduleActionKey": this.headings[0].moduleActionKey,
        "isActive": isActive,
        "isGlobal": 0,
        "isAutoApproval": 0,
        'metadata': metInfo
      }


      const up$ = this.vmsService.updateCustomCheckList(this.currentZone, data)
      this.loaderService.showLoaderUntilCompleted(up$).subscribe(res => {
        //  f.resetForm();
        this.toastrservice.success("Saved successfully");
        this.getAllActiveCustomCheckList();
        //this.editable = false;
        this.editableHeading = false;
        //this.model = {"optionsq":[{qoption:''},{qoption:''}]};
        this.closePopup();
      });



    } else {
      data = {
        "moduleActionKey": 'CUSTOM_CHECK_LIST',
        "isEditable": 1,
        "isMandatory": 1,
        "isActive": true, /// in case of update,
        "isGlobal": 0,
        "isAutoApproval": 0,
        'metadata': metInfo
      }




      const up$ = this.vmsService.saveCustomCheckList(this.currentZone, data)
      this.loaderService.showLoaderUntilCompleted(up$).subscribe(res => {
        //  f.resetForm();
        this.toastrservice.success("Saved successfully");
        this.getAllActiveCustomCheckList();
        //this.editable = false;
        this.editableHeading = false;
        //this.model = {"optionsq":[{qoption:''},{qoption:''}]};
        this.closePopup();
      });

    }


  }
  showExtraDesc: boolean = false;
  showToogleIcon: boolean = false;
  onChange(enable: boolean) {
    if (enable) {
      this.showExtraDesc = true;
      this.showToogleIcon = true;
    } else {
      this.showExtraDesc = false;
      this.showToogleIcon = false;
    }
  }
  checkOutUser() {
    let dataClose = {};
    this.vmsService.getCheckOut(this.data, this.currentZone).subscribe(res => {
      dataClose['type'] = 'checkout';
      dataClose['result'] = 'success';
      this.dialogRef.close(dataClose);
    }, err => {
      dataClose['type'] = 'checkout';
      dataClose['result'] = 'error';
      this.dialogRef.close(dataClose);

    });
  }


  checkOutMyVistiotor() {
    let dataClose = {};
    this.vmsService.getCheckOut(this.data, this.currentZone).subscribe(res => {
      dataClose['type'] = 'checkout';
      dataClose['result'] = 'success';
      this.dialogRef.close(dataClose);
    }, err => {
      dataClose['type'] = 'checkout';
      dataClose['result'] = 'error';
      this.dialogRef.close(dataClose);
    });
  }


  addOptions() {
    let temp = this.modelvms.optionsq;
    temp.push({ qoption: "", redflag: false });
    this.modelvms.optionsq = _.cloneDeep(temp)
  }
  removeQuestionOption = (qoption) => {
    let temp = this.modelvms.optionsq;
    temp.splice(qoption, 1);
    this.modelvms.optionsq = _.cloneDeep(temp)
  }

  isRedFlagValid: boolean = false;
  onSubmit(f: NgForm) {
    if (!f.valid) {
      if (this.modelvms.optionsq.length <= 1) {
        this.toastrservice.error("Add at least two answers.");
        return;
      }

      return
    }


    let questionOptions = Object.values(this.modelvms.optionsq);
    let optionsArray = {};
    let approvalOptionsArray = {};
    let redFlagsChecked = [];
    let i = 65;
    questionOptions.forEach(key => {
      if (i > 92) {
        this.toastrservice.error("You can't have options more than 26");
        return;
      }
      optionsArray[String.fromCharCode(i)] = key['qoption'];
      redFlagsChecked.push(key['redflag']);
      if (key['redflag'] == false) {
        approvalOptionsArray[String.fromCharCode(i)] = 'PRESENT'
      }
      i++;
    })


    let approvalLen = Object.keys(approvalOptionsArray).length;
    let metInfo;

    let redFlagAdded = redFlagsChecked.includes(true);
    let redFlagNotAdded = redFlagsChecked.includes(false);

    //check answeres flagged or not flagged
    // if(f.valid && (redFlagAdded == false || redFlagNotAdded == false)){
    //   approvalLen = 0;
    //   if(redFlagAdded == false){
    //     this.alertService.error("Please flag atleast one option");
    //   }


    // }


    if (this.modelvms.optionsq.length <= 1) {
      this.toastrservice.error("Add at least two answers.");
      return;
    }

    if (redFlagNotAdded == false) {
      this.toastrservice.error("Please unflag atleast one option");
      return;
    }

    if (f.valid == true && approvalLen > 0) {
      this.isRedFlagValid = false;
      if (this.showToogleIcon == true) {
        metInfo = {
          question: this.modelvms.title,
          fieldType: "RADIO",
          description: this.modelvms.extraDescription,
          defaultValue: '',
          values: JSON.stringify(optionsArray),
          approvalConditionValues: JSON.stringify(approvalOptionsArray)
        }
      } else {
        metInfo = {
          question: this.modelvms.title,
          fieldType: "RADIO",
          description: '',
          defaultValue: '',
          values: JSON.stringify(optionsArray),
          approvalConditionValues: JSON.stringify(approvalOptionsArray)
        }
      }


      let data;
      if (this.editable == true) {
        let isEditable;
        if (this.editedQuestion.isEditable == true) {
          isEditable = 1;
        } else {
          isEditable = 0;
        }

        let isMandatory;
        if (this.editedQuestion.isMandatory == true) {
          isMandatory = 1;
        } else {
          isMandatory = 0;
        }

        let isActive;
        if (this.editedQuestion.isActive == true) {
          isActive = 1;
        } else {
          isActive = 0;
        }



        data = {
          "id": this.editedQuestion.id,
          "isEditable": isEditable,
          "isMandatory": isMandatory,
          "moduleActionKey": this.editedQuestion.moduleActionKey,
          "isActive": isActive, /// in case of update,
          "isGlobal": 0,
          "isAutoApproval": 1,
          'metadata': metInfo
        }
      } else {
        data = {
          "isEditable": 1,
          "isMandatory": 1,
          "moduleActionKey": 'CUSTOM_CHECK_LIST',
          "isActive": 1, /// in case of update,
          "isGlobal": 0,
          "isAutoApproval": 1,
          'metadata': metInfo
        }
      }

      if (this.editable == true) {
        const customQues$ = this.vmsService.updateCustomCheckList(this.currentZone, data)

        this.loaderService.showLoaderUntilCompleted(customQues$).subscribe(res => {
          this.closePopup();
          this.getAllActiveCustomCheckList();
          this.editable = false;
          //this.editableHeading = false;
          this.modelvms = { "optionsq": [{ qoption: '', redflag: false }, { qoption: '', redflag: false }] };
          this.toastrservice.success("Saved successfully");
          f.resetForm();
        }, err => {

        }, () => {
        });


      } else {
        if (this.modelvms.optionsq.length <= 1) {
          this.toastrservice.error("Add at least two answers.");
          return;
        }
        const customQues$ = this.vmsService.saveCustomCheckList(this.currentZone, data)

        this.loaderService.showLoaderUntilCompleted(customQues$).subscribe(res => {
          this.closePopup()
          this.getAllActiveCustomCheckList();
          this.editable = false;
          //this.editableHeading = false;
          this.modelvms = { "optionsq": [{ qoption: '', redflag: false }, { qoption: '', redflag: false }] };
          this.toastrservice.success("Saved successfully");
          f.resetForm();
        }, err => {

        }, () => {
        });
      }
    } else {
      this.isRedFlagValid = true;
    }
  }
  getAllActiveCustomCheckList() {
    this.vmsService.getAllActiveCustomCheckList(this.currentZone).subscribe(res => {
      let response = res['response']['list'];
      var filtered = response.filter(function (item) {
        return item.moduleActionKey === 'CUSTOM_CHECK_LIST' && item.metadata.fieldType != 'HEADING';
      });

      var headingsInfo = response.filter(function (item) {
        return item.metadata.fieldType == 'HEADING' && item.moduleActionKey === 'CUSTOM_CHECK_LIST';
      });

      this.headings = headingsInfo;
      // this.dataSource = filtered;
      let activeArr = [];
      filtered.forEach(element => {
        if (element.metadata.question.length > 30) {
          element.showMore = true;
        } else {
          element.showMore = false;
        }
        if (typeof element.metadata.description != 'undefined') {
          if (element.metadata.description.length > 30) {
            element.showMoreDes = true;
          } else {
            element.showMoreDes = false;
          }
        } else {
          element.showMoreDes = false;
        }

        activeArr.push(element);

      });
      this.dataSource = activeArr;
      if (this.headings.length > 0) {
        this.editableHeading = true;
        this.headingCheckList.heading = this.headings[0]['metadata']['question'];
        this.headingCheckList.headingDescription = this.headings[0]['metadata']['description'];
      }

    }, err => {
      // this.dataSource = [];
    });
  }

  editQuestion(element) {
    document.querySelector('#formCustomCheckList').scrollIntoView({ behavior: 'smooth' });
    // this.nameField.nativeElement.focus();
    this.modelvms = { "optionsq": [{ qoption: '', redflag: false }, { qoption: '', redflag: false }] };
    this.editable = true;

    this.modelvms.title = element.metadata.question;

    let k = 65;
    let approvalCond = Object.keys(element.metadata.approvalConditionValues);
    for (var i = 0; i < Object.keys(element.metadata.values).length; i++) {
      let redFlagStatus;
      if (approvalCond.includes(String.fromCharCode(k)) == true) {
        redFlagStatus = false;
      } else {
        redFlagStatus = true;
      }

      let optionAvail = element.metadata.values[String.fromCharCode(k)];
      if (i > 1) {
        this.modelvms.optionsq.push({ qoption: optionAvail, redflag: redFlagStatus })
      } else {
        this.modelvms.optionsq[i]['qoption'] = optionAvail;
        this.modelvms.optionsq[i]['redflag'] = redFlagStatus;
      }
      k++;
    }

    if (element.metadata.description != '') {
      this.showToogleIcon = true;
      this.showExtraDesc = true;
      this.modelvms.extraDescription = element.metadata.description;
    } else {
      this.showToogleIcon = false;
      this.showExtraDesc = false;
      this.modelvms.extraDescription = '';
    }
    this.editedQuestion = element;
  }
  removeAdditionalInfo = (qoption) => {
    this.model.optionsq.splice(qoption, 1);
  }

  saveAdditionalDetails() {

  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  onSubmitAdditionalDetails(f) {
    let metInfo = {};
    this.model.optionsq.forEach(element => {
      if (element['typenumber'].trim() != '') {
        metInfo[element.qoption] = JSON.parse(element['typenumber']);
      }
    });

    if (this.isEmpty(metInfo)) {
      this.toastrservice.error("Please enter atleast one identity");
      return;
    }
    /*{ 
      idType: this.fastrack.heading,
      IdName : this.fastrack.headingDescription,
    }*/
    let data;
    if (this.isIdentityEditable == true) {
      data = {
        "id": this.isIdentityEditableId,
        "moduleEntityId": this.intivationId,
        "moduleActionKey": "ADDITIONAL_VMS_FIELD",
        "metadata": metInfo
      }
      this.vmsService.updateMetaDataCustomCheckList(this.currentZone, data).subscribe(res => {
        this.toastrservice.success("Updated successfully");
      });
    } else {
      data = {
        "moduleEntityId": this.intivationId,
        "moduleActionKey": "ADDITIONAL_VMS_FIELD",
        "metadata": metInfo
      }
      this.vmsService.saveMetaDataCustomCheckList(this.currentZone, data).subscribe(res => {
        this.toastrservice.success("Saved successfully");
      });
    }


  }


  isIdentityEditable: boolean = false;
  isIdentityEditableId: any;
  getAllActiveIdentityFields() {
    this.model.optionsq = [];
    forkJoin(
      this.vmsService.getAllActiveIdentity(this.currentZone).map((res: Response) => res)
        .catch((res: Response) => Observable.of(res)),
      this.vmsService.getAllIdentityDetailsByInvitationId(this.currentZone, this.intivationId).map((res: Response) => res)
        .catch((res: Response) => Observable.of(res)),
    ).map((data: any[]) => {
      let response = data[0]['response']['list'];
      let response1;
      if (data[1].hasOwnProperty('response') == true) {
        response1 = data[1]['response']['list'];
      } else {
        response1 = [];
      }

      this.isIdentityEditable = false;
      if (response.length > 0) {
        this.model.optionsq = [];
        if (response1.length > 0) {
          this.isIdentityEditable = true;
          this.isIdentityEditableId = response1[0]['id'];
          for (let [key, value] of Object.entries(response[0]['metadata']['values'])) {
            this.model.optionsq.push({
              qoption: [value],
              typenumber: response1[0]['metadata'][response[0]['metadata']['values'][key]]
            })
          }
        } else {
          for (let [key, value] of Object.entries(response[0]['metadata']['values'])) {
            this.model.optionsq.push({
              qoption: [value],
              typenumber: ''
            })
          }
        }
      } else {
        this.isIdentityEditable = false;
      }


    }, err => {
      this.toastrservice.error("Something went wrong!!");
      setTimeout(() => {
        this.closePopup();
      }, 2000);
    }).subscribe();


    /*
    
        this.vmsService.getAllIdentityDetailsByInvitationId(this.currentZone,this.intivationId).subscribe((res) => {
          let response = res['response']['list'];
          this.isIdentityEditable = false;
          if(response.length>0){
            this.isIdentityEditable = true;
            this.model.optionsq = [];
            this.isIdentityEditableId = response[0]['id'];
            for (let [key, value] of Object.entries(response[0]['metadata'])) {
              this.model.optionsq.push({
                qoption: [key],
                typenumber: [value]
              })
            }
          }else{
            this.isIdentityEditable = false;
          }
        });*/
  }

  getAllActiveIdentityAnswered() {
    this.model.optionsq = [];
    this.vmsService.getAllIdentityDetailsByInvitationId(this.currentZone, this.intivationId).subscribe((res) => {
      let response = res['response']['list'];
      this.isIdentityEditable = false;
      if (response.length > 0) {
        this.isIdentityEditable = true;
        this.model.optionsq = [];
        this.isIdentityEditableId = response[0]['id'];
        for (let [key, value] of Object.entries(response[0]['metadata'])) {
          this.model.optionsq.push({
            qoption: [key],
            typenumber: [value]
          })
        }
      } else {
        this.isIdentityEditable = false;
      }
    });
  }


  noQuestionsAnswered: boolean = true;
  questionAnswerResponse: any;
  getQuestionAndAnswers() {
    this.noQuestionsAnswered = true;
    /*
        ,
          this.vmsService.getAllActiveCustomCheckList(this.currentZone).map((res:Response) => res)
          .catch((res:Response) =>  Observable.of(res)),*/
    forkJoin(
      this.vmsService.getAllCustomCheckList(this.currentZone, this.intivationId).map((res: Response) => res)
        .catch((res: Response) => Observable.of(res))
    )
      .map((data: any[]) => {
        let userQuesAnsw = [];
        if (typeof (data[0]['response']) != 'undefined') {

          this.questionAnswerResponse = data[0]['response']['list'][0];
          this.declaration = !!this.questionAnswerResponse['metadata']['autoApprovalResponse'] ? this.questionAnswerResponse['metadata']['autoApprovalResponse']['result'] : '';
          this.questionEntered = !!this.questionAnswerResponse['metadata']['question']['questionResponse'] ? false : true;
          userQuesAnsw = data[0]['response']['list'][0]['metadata']['question'].filter(function (item) {
            return item.moduleActionKey === 'CUSTOM_CHECK_LIST' && item.metadata.fieldType != 'HEADING';
          });
        } else {
          userQuesAnsw = [];
        }
        // userQuesAnsw["results"] = data[0]['response']['list'][0]['metadata']['']
        this.questionAndAnswers = userQuesAnsw;
        /*
        if(typeof(data[1]['response']) != 'undefined'){
          let response = data[1]['response']['list'];
          var filtered = response.filter(function(item) { 
            return item.moduleActionKey == 'CUSTOM_CHECK_LIST' && item.metadata.fieldType != 'HEADING';  
          });
          this.questionAndAnswers = filtered;
          console.log(this.questionAndAnswers);
          this.questionAndAnswers.forEach((value, key) => {
            this.questionAndAnswers[key]['optionsAvailable'] = Object.values(this.questionAndAnswers[key]['metadata']['values'])
            this.questionAndAnswers[key]['selectedAnswer'] = '';
            if(userQuesAnsw != ''){
              this.noQuestionsAnswered = false;
              this.questionAndAnswers[key]['selectedAnswer'] =  JSON.parse(userQuesAnsw[value['id']]);
            }
          });
          
        }else{

        }*/



      }, err => {

        this.toastrservice.error("Something went wrong!!");
        setTimeout(() => {
          this.closePopup();
        }, 2000);
      }
      ).
      subscribe();
  }

  /*
  getAllCustomCheckList(){
    this.vmsService.getAllCustomCheckList(this.currentZone,this.intivationId).subscribe(res=>{
    
    },err=>{

    },()=>{

    });
  }

  getAllActiveCustomCheckList(){
    this.vmsService.getAllActiveCustomCheckList(this.currentZone).subscribe(res=>{
  
    },err=>{

    },()=>{

    });
  }*/

  getSelectedAnswers(ele) {
    let answered = [];
    if (typeof (this.questionAnswerResponse['metadata']['questionResponse'][ele['id']]) == 'undefined') {
      answered.push('Not Answered');
    } else {
      this.questionAnswerResponse['metadata']['questionResponse'][ele['id']].forEach(element => {
        answered.push(ele['metadata']['values'][element]);
      });
    }
    return answered;
  }

  checkFlagWithAnswer(ele) {
    let showFlag = 0;
    //let approvalValues = Object.keys(ele['metadata']['question']['approvalConditionValues']); 
    if (typeof (this.questionAnswerResponse['metadata']['questionResponse'][ele['id']]) == 'undefined') {

    } else {
      let answeredOption = this.questionAnswerResponse['metadata']['questionResponse'][ele['id']];
      answeredOption.forEach(element => {
        if (Object.keys(ele['metadata']['approvalConditionValues']).includes(element) == false) {
          showFlag = 1;
        }
      })
    }
    return showFlag;
  }

  openSnackBar(message: string, action: string) {
    this.matSnackBar.open(message, action, {
      duration: 5000,
    });
  }

  createLead() {

  }

  addComment() {

  }

  ngAfterViewInit() {
    this.ref.detectChanges();
    if (this.data['type'] == 'customchecklistedit') {
      let element = this.data['data']
      this.editQuestion(element);
    }
  }

  onNoClick(): void {
    let currentContext = this;
    currentContext.commonService.changeZone(currentContext.currentZone);
    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();

    this.dialogRef.close();
  }

  cancel() {
    let currentContext = this;
    currentContext.onNoClick();
  }

  closePopup() {
    this.dialogRef.close(this.pageType);
  }
  closePopupCustom() {
    this.dialogRef.close('CUSTOM_NOT_SAVED');
  }
  addParticipant() {

    //start

    const control = new FormGroup({

      'name': new FormControl(null)
    });
    (<FormArray>this.form.get('visitors')).push(control);
    //end

  }

  createInvite() {

  }

  // adding multiple vistors fields at VMS create invite  and add guests screen
  onAddVisitors() {
    const control = new FormGroup({
      'visitorTypeId': this.fb.control(null, Validators.required),
      'name': new FormControl(null),
      'email': new FormControl(null),
      'mobile': new FormControl(null),
      'company': new FormControl(null)
    });
    (<FormArray>this.form.get('visitors')).push(control);
  }
  //remove visitor
  onRemoveVisitors(index: number): void {
    const control = <FormArray>this.form.controls['visitors'];
    control.removeAt(index);
  }

  hostImgageUrl(url) {
    return url = (url != null) ? url : "../../assets/thumbnails/user.png";
  }

  convertDateToMilliseconds(dateStr: string) {
    let milliseconds = Date.parse(dateStr);
    return milliseconds;
  }

  onInviteSubmit(f: NgForm) {
    //init
    let createInvite = f.value;
    var currentContext = this;

    var zoneId = (createInvite.location !== null) ? createInvite.location.split("|", 2)[0] : 0;
    var hostId = (createInvite.hostEmail !== null) ? createInvite.hostEmail.split("|", 2)[0] : 0;



    var request: any = {};

    //init check flags
    let meeting = false;
    let visitors = true;
    //check for meeting validations
    if (createInvite.scheduleStart === null || createInvite.scheduleStart === "") {
      this.openSnackBar('Meeting start date is necessary field!', '');
    } else if (createInvite.scheduleEnd === null || createInvite.scheduleEnd === "") {
      this.openSnackBar('Meeting end date is necessary field!', '');
    } else if (createInvite.purpose === null || createInvite.purpose === "") {
      this.openSnackBar('Meeting purpose is necessary field!', '');
    } else if (zoneId == 0 || (zoneId != " " && !Number(zoneId.trim())) || zoneId == " ") {
      this.openSnackBar('Please select the location details from suggested list!', '');
    } else if (hostId == 0 || (hostId != " " && !Number(hostId.trim())) || hostId == " ") {
      this.openSnackBar('Please select the host details from suggested list!', '');
    } else {
      meeting = true;
    }
    //init
    let vFiled = "";
    //validation for multiple inivites
    createInvite.visitors.some(function (visitor, index) {
      //check first meeting details
      visitor.visitorTypeId = 1;
      if (meeting) {
        //check
        if (visitor.name === null || visitor.name === "") {
          vFiled = "name";
          visitors = false;
          return true;
        } else if (visitor.email === null || visitor.email === "") {
          vFiled = "email";
          visitors = false;
          return true;
        } else if (visitor.mobile === null || visitor.mobile === "") {
          vFiled = "mobile";
          visitors = false;
          return true;
        } else if (visitor.visitorTypeId === null || visitor.visitorTypeId === "") {
          vFiled = "visitorTypeId";
          visitors = false;
          return true;
        } else {
          visitors = true;
        }
      } else {
        return true;
      }
    });

    //check if any invite field is missing and show error message
    if (!visitors) {
      this.openSnackBar('The ' + vFiled + ' of the visitor is necessary field', '');
    }
    //check for validations, if pass validations then service call
    if (visitors && meeting) {
      //set request object
      request.scheduleStart = this.convertDateToMilliseconds(createInvite.scheduleStart);
      request.scheduleEnd = this.convertDateToMilliseconds(createInvite.scheduleEnd);
      request.purpose = createInvite.purpose;
      //request.hostId = localStorage.getItem("ssadmin_userId");
      //request.zoneId = this.currentZone;

      request.hostId = hostId;
      request.zoneId = zoneId;

      request.isWebexEnabled = (createInvite.webEx === true) ? true : false;
      request.isWifiEnabled = (createInvite.wifi === true) ? true : false;

      //init visitor element
      request.visitorList = [];

      //check for bulupload
      if (this.csvRecords != null && this.csvRecords.length > 0) {
        request.visitorList = this.csvRecords;
      } else {
        request.visitorList = createInvite.visitors;
      }

      //find visitor email to send WebEx link
      // var emails = request.visitorList.map(function(val) {
      //   return val.email;
      // }).join(',');

      //call service
      this.vmsService.createInvitation(request, this.currentZone).subscribe((res) => {

        if (res["response"]) {

          var respObj = res["response"];

          // if(createInvite.webEx===true){
          //     this.visitorEmails = emails;
          //     let element : HTMLElement = document.getElementById('sendWebExLink') as HTMLElement;
          //     element.click();
          //   }else {
          //     currentContext.openSnackBar('The invitation has been sent.', '');
          //     currentContext.dialogRef.close();
          //     setTimeout(() => { window.location.reload() }, 1000);
          //   }

          currentContext.openSnackBar('The invitation has been sent.', '');
          currentContext.dialogRef.close();
          setTimeout(() => { window.location.reload() }, 1000);
        }
      }, (err) => {
        this.openSnackBar('There was an error while sending the invitation! - ' + err.error.message, '');

      });
    }
  }

  getUrl() {

    return "url('https://s3-ap-southeast-1.amazonaws.com/ss.vms/image/1563370267369-debada0063704691ac7d5872ef460a96-15633702329751471094040-compressed.jpg')";
  }


  //upload file start
  //csv import vistors feature added by Narendra
  @ViewChild('fileImportInput', { static: true }) fileImportInput: any;


  vistorsFileChangeListener($event: any): void {

    var text = [];
    var files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      var input = $event.target;
      var reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = (data) => {
        let csvData: any = reader.result;
        let csvRecordsArray = csvData.split(/\r\n|\n/);

        let headersRow = this.getCsvFileHeaderArray(csvRecordsArray);

        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      }

      reader.onerror = function () {
        alert('Unable to read ' + input.files[0]);
      };

    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getCsvFileHeaderArray(csvRecordsArr: any) {

    let headers = csvRecordsArr[0].split(',');
    let headerArray = [];

    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {

    var dataArr = []

    for (let i = 1; i < csvRecordsArray.length; i++) {

      let data = csvRecordsArray[i].split(',');
      // FOR EACH ROW IN CSV FILE IF THE NUMBER OF COLUMNS
      // ARE SAME AS NUMBER OF HEADER COLUMNS THEN PARSE THE DATA
      if (data.length == headerLength) {
        //check
        var csvRecord: VistorsCsvRecord = new VistorsCsvRecord();
        //set record
        csvRecord.name = data[0].trim();
        csvRecord.email = data[1].trim();
        csvRecord.mobile = data[2].trim();
        csvRecord.company = data[3].trim();
        csvRecord.visitorTypeId = data[4].trim();
        dataArr.push(csvRecord);
      }
    }

    return dataArr;
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = "";
    this.csvRecords = [];
  }
  //uplaod file end

  addVisitor() {
    var visitor = {}

    if (!this.isEditVisitor) {
      visitor['name'] = this.visitorInfo['name'];
      visitor['email'] = this.visitorInfo['email'];
      visitor['mobile'] = this.visitorInfo['countryCode'] + '-' + this.visitorInfo['mobile'];
      visitor['company'] = this.visitorInfo['company'];
      visitor['visitorTypeId'] = 1;
      // visitor['countryCode'] = this.visitorInfo['countryCode'];
      let arrTemp = _.cloneDeep(this.visitorList)
      arrTemp.push(visitor);
      this.visitorList = _.cloneDeep(arrTemp)
      this.dataSource = _.cloneDeep(this.visitorList);
      //  this.dataSource = [...this.dataSource];
      // this.visitorInfo = {};
    }
    else {

      visitor['name'] = this.visitorInfo['name'];
      visitor['email'] = this.visitorInfo['email'];
      visitor['mobile'] = this.visitorInfo['countryCode'] + '-' + this.visitorInfo['mobile'];
      visitor['company'] = this.visitorInfo['company'];
      visitor['visitorTypeId'] = 1;
      // visitor['countryCode'] = this.visitorInfo['countryCode'];

      let arrTemp = _.cloneDeep(this.visitorList)
      // arrTemp.push(visitor);

      arrTemp[this.editVisitorIndex] = visitor
      this.visitorList = _.cloneDeep(arrTemp)
      this.dataSource = _.cloneDeep(this.visitorList);
      //   this.dataSource = [...this.dataSource];
      // this.visitorInfo = {};
      this.isEditVisitor = false;
      this.editVisitorIndex = -1;


    }
    this.submitted = false
  }

  addCSVVisitor(visitor) {
    this.visitorList.push(visitor);
    this.dataSource = _.cloneDeep(this.visitorList);
    //this.dataSource = [...this.dataSource];
  }

  isAddVisitorActive() {
    var result = false;
    if (!this.visitorInfo) {
      result = false;

    } else {

      result = this.visitorInfo['name'] && this.visitorInfo['email'] && this.visitorInfo['mobile'] && this.visitorInfo['company'];
      if (!result) {
        result = false;
      }
    }

    return result;

  }

  isSendInvitation() {

    var result = false;
    if (!this.hostInfo) {
      result = false;

    } else {

      result = this.hostInfo['host'] && this.hostInfo['location']
        && this.hostInfo['purpose']
        && this.hostInfo['scheduleStart'] && this.hostInfo['scheduleEnd']
        && this.visitorList
        && this.visitorList.length > 0;
      if (!result) {
        result = false;
      }
    }

    return result;

  }

  removeVisitor(index) {

    if (index > -1) {
      let arrT = _.cloneDeep(this.visitorList)
      arrT.splice(index, 1);
      this.visitorList = _.cloneDeep(arrT)
      this.dataSource = _.cloneDeep(this.visitorList);
      //  this.dataSource = [...this.dataSource];

    }
  }



  // uploadVisitorsCSV(event) {


  //   let fileList: FileList = event.target.files;
  //   if (fileList.length > 0) {
  //     let file: File = fileList[0];
  //     // Define a regular expression to match the CSV pattern
  //     let pattern = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;

  //     const fileReader = new FileReader();

  //  //   fileReader.readAsText(file);
  //     fileReader.readAsText(file, 'UTF-8');

  //     fileReader.onload = (e) => {
  //       let csv = fileReader.result;

  //       let csvString: string = csv.toString();
  //       console.log("CSV Content:", csvString); // Debugging

  //       let allTextLines = csvString.split(/\r\n|\n/);

  //       console.log("Number of Lines:", allTextLines.length); // Debugging

  //       for (let i = 1; i < allTextLines.length; i++) {
  //         let data = allTextLines[i].split(pattern);
  //         console.log("Data for Line", i, ":", data); // Debugging
  //         if (data && data.length && data[0] != '') {
  //           let visitor = this.createVisitor(data);
  //           let visitorID:any = "";
  //           if (visitor["visitorTypeId"] != "" && !isNaN(visitor["visitorTypeId"])) {
  //             visitorID = parseInt(visitor["visitorTypeId"]);
  //           } else {
  //             visitorID = "";
  //           }
  //           visitor["visitorTypeId"] = visitorID; //conveting because of some backend issue
  //           this.addCSVVisitor(visitor);


  //         }
  //       }


  //     }
  //   }
  // }

  // createVisitor(data) {
  //   let visitor: {} = {};
  //   visitor['name'] = data[0];
  //   visitor['email'] = data[1];
  //   visitor['mobile'] = data[2];


  //   return visitor;

  // }


  uploadVisitorsCSV(event) {
    const fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      const file: File = fileList[0];
      const fileReader = new FileReader();

      fileReader.readAsText(file, 'UTF-8');

      fileReader.onload = () => {
        const csv = fileReader.result as string;

        const allTextLines = csv.split(/\r\n|\n/);


        for (let i = 1; i < allTextLines.length; i++) {
          const data = allTextLines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

          if (data && data.length >= 3 && data[0] !== '' && data[1] !== '' && data[2] !== '') {
            const visitor = this.createVisitor(data);
            this.addCSVVisitor(visitor);
          }
        }
      };
    }
  }

  createVisitor(data) {
    return {
      'name': data[0],
      'email': data[1],
      'mobile': data[2],
    };
  }

  sendInvitation() {
    this.showDisable = true;
    let hostInfoTemp = JSON.parse(JSON.stringify(this.hostInfo));
    hostInfoTemp['scheduleStart'] = this.convertDateToMilliseconds(hostInfoTemp['scheduleStart']);
    hostInfoTemp['scheduleEnd'] = this.convertDateToMilliseconds(hostInfoTemp['scheduleEnd']);

    hostInfoTemp['isWebexEnabled'] = false;
    hostInfoTemp['isWifiEnabled'] = false;
    hostInfoTemp['visitorList'] = this.visitorList;

    if (hostInfoTemp.hasOwnProperty('location')) {
      delete hostInfoTemp['location'];

    }
    if (hostInfoTemp.hasOwnProperty('host')) {
      delete hostInfoTemp['host'];

    }

    const currentuserModule$ = this.vmsService.createInvitation(hostInfoTemp, this.currentZone)

    this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe((res) => {

      if (res["response"]) {
        this.showDisable = false;
        var respObj = res["response"];



        this.openSnackBar('The invitation has been sent.', '');
        var data = {};
        data['type'] = 'createInviteVisitorNew';
        data['result'] = 'success';
        this.dialogRef.close(data);

      }
    }, (err) => {
      this.showDisable = false;
      // this.openSnackBar('There was an error while sending the invitation! - ' + err.error.message, '');

    });

  }




  onLocationChange(searchValue) {

    //  this.nameSearchTermSubject$.next(searchTerm.trim());
    if (searchValue != '') {
      this.hostInfo['zoneId'] = null;
      searchValue = searchValue.toLowerCase().toString().trim().replace(/\s/g, "");
      ;
      this.locations = this.locationsOrg.filter((item) => {

        let name = item.name.toLowerCase().toString().trim().replace(/\s/g, "");
        ;
        let res = name.indexOf(searchValue);
        if (res >= 0) return item;
        let addr = item.address.toLowerCase().toString().trim().replace(/\s/g, "");

        res = addr.indexOf(searchValue);

        if (res >= 0) return item;
        name = name + ',' + addr;
        res = name.indexOf(searchValue);
        if (res >= 0) return item;

      })
      this.locations = _.cloneDeep(this.locations);


    }
    else {
      this.locations = _.cloneDeep(this.locationsOrg)
    }
    if (this.locations.length == 0) {
      this.toastrservice.error('No Zones associated with this location,name or address ')
    }


    //     if (searchValue) {
    //       this.tmsService.getBuildingListByZoneAndTenant(searchValue, this.currentZone).subscribe(
    //         data => {
    //           this.locations = data['response'];

    //         })
    //     }
  }

  onHostChange(searchValue: string): void {
    if (searchValue) {
      this.hostInfo['hostId'] = null
      this.hostInfo['zoneId'] = null
      this.hosts = [];
      this.hostInfo.location = ''
      this.locations = []
      this.emsService.searchHosts(searchValue, this.currentZone).subscribe(
        data => {
          if (!!data['response']) {
            let dataBlocked = data['response'].content;
            let activeArray = dataBlocked.filter((item) => item.status != "BLOCKED")
            this.hosts = activeArray;
          }
          else {
            this.toastrservice.error('No host associated with this email id/name')
            this.hosts = []
            this.locations = []

          }

        })
    }
  }

  cancelInvitation() {
    this.dialogRef.close();
  }

  selectedHostFirst = null
  onlocationChange(value) {
    if (value) {
      this.hostInfo['zoneId'] = null
    }
  }
  locationsOrg;
  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    let selectedHost
    if (event)
      selectedHost = this.hosts.find(host => host.email === event.option.value);

    if (event == null) selectedHost = this.selectedHostFirst

    // Access the 'tenantId' property
    const tenantId = selectedHost.tenantId;
    this.hostInfo['hostId'] = selectedHost.userId;

    let arr = Object.keys(selectedHost.zonesMap)

    let i = arr.includes(this.currentZone.toString());




    if (i) {
      const getAllZoneDatabyLevel$ = this.emsService.getAllZoneDataByLevel(this.currentZone, 'FLOOR');
      this.loaderService.showLoaderUntilCompleted(getAllZoneDatabyLevel$).subscribe(data => {
        // let responseObject = (res as any).response;
        this.locations = data['response']['content'];
        this.locations = this.locations.filter(key => {

          return (key.site.id == this.currentZone && key['status'] == "ENABLED");

        });
        //this.dataSource2 = new MatTableDataSource<Element>(this.locations);  
        this.locationsOrg = this.locations

        if (this.locations.length == 0) {
          this.commonService.openSnackBar("No zones asscoiated with the host, Please select another host", "");
        }
      }, err => {
        this.commonService.openSnackBar("No zones asscoiated with the host, Please select another host", "");
      }, () => {
      })
    }
    else {
      this.commonService.openSnackBar("No zones asscoiated with the host, Please select another host", "");
    }

  }
  onClickLocationBox() {
    //this.onSelectionChanged(null);
  }

  showQRCode() {
    const currentuserModule$ = this.vmsService.getQRcode(this.currentZone, this.data['data']['zoneId']);
    this.loaderService.showLoaderUntilCompleted(currentuserModule$).subscribe(res => {
      let resData = JSON.parse(res['response']['content']);

      //this.encryptedQRCode = "https://zerotouch.smartenspaces.com/scanner?encryptedZone="+resData.data;
      let urlFormHost = environment.BASE_URL;
      if(urlFormHost.includes("jumpreeqa")  || urlFormHost.includes("digitalpoc") || urlFormHost.includes("digitalvapt") || urlFormHost.includes("digitalstaging") || urlFormHost.includes("digitalqa"))
        {
          this.encryptedQRCode = "https://preprodzerotouch.smartenspaces.com/zerotouch?encryptedZone=" + resData.data;       
        }else{
          this.encryptedQRCode = "https://zerotouch.smartenspaces.com/zerotouch?encryptedZone=" + resData.data;
        }
      

    }, err => {

    }, () => {
    });
  }

  printPage() {
    let printContents, popupWin;
    printContents = document.querySelector('#print-section-qr-code').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>QRCode</title>
          <style>
          //........Customized style.......
          </style>
        </head>
        <body onload="window.print();window.onmouseover = function() { self.close(); }">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  getVisitManagerList(event) {

    if (event.index == 1) {
      this.vmsService.getAllQusetonsForVistors(this.currentZone, this.intivationId).subscribe((res: any) => {


        this.allList = (res as any).response.list;

        let questions = this.allList[0].metadata.question;
        let answers = this.allList[0].metadata.questionResponse;

        questions.forEach(element => {
          for (var x in answers) {

            if (x == element.id) {

              element['selectedValue'] = answers[x];
            }

          }
        });

        questions.forEach(element => {

          if (element.fieldType == "RADIO") {
            for (var x in element.metadata.values) {

              if (!!element.selectedValue && element.selectedValue.includes(x)) {


                element['answerSelected'] = element.metadata.values[x]
              }

            }
          }
          else if (element.fieldType == "CHECKBOX") {
            for (var x in element.metadata.values) {

              if (!!element.selectedValue && element.selectedValue.includes(element.metadata.values[x])) {


                element['answerSelected'] = element.metadata.values[x]
              }

            }
          }
          else {
            if (!!element.selectedValue) {
              element['answerSelected'] = element.selectedValue[0]
            }

          }

        });

        this.DataList = questions


      })

    }
  }

  getConfig() {

    this.vmsService.getConfig(this.currentZone, 9).subscribe(res => {
      let configDetails = (res as any).response;
      this.vmsConfig = JSON.parse(configDetails);
      let configDet = JSON.parse(configDetails);
      this.checkVisitorValidations();
      try {
        this.isAdditional = configDet.commonConfig.isAdditionalDetailsRequired

      } catch (error) {
        this.isAdditional = false
      }

    });
  }
  isVisitorCountryCode;
  checkVisitorValidations() {
    if (typeof this.vmsConfig) {
      if (!!this.vmsConfig['preInvite'] && !!this.vmsConfig['preInvite']['visitorConfig'] &&
        !!this.vmsConfig['preInvite']['visitorConfig']['commonVisitorConfig']['fields']) {
        this.storeVisitor = this.vmsConfig['preInvite']['visitorConfig']['commonVisitorConfig']['fields'];
      }
      if (!!this.storeVisitor) {
        this.isVisitorName = this.storeVisitor['visitorName'].enabled;
        this.isVisitorEmail = this.storeVisitor['visitorEmail'].enabled;
        this.isVisitorMobile = this.storeVisitor['visitorPhone'].enabled;
        this.isVisitorCompany = this.storeVisitor['visitorCompany'].enabled;
        //   this.isVisitorCountryCode = this.storeVisitor['visitorCountryCode'].enabled;
        //set validations
        // this.addVisitorInfo.get('visitorName').setValidators(this.setRequired('visitorName'));
        // this.addVisitorInfo.get('visitorEmail').setValidators(this.setRequired('visitorEmail'));
        // this.addVisitorInfo.get('visitorMobile').setValidators(this.setRequired('visitorPhone'));
        // this.addVisitorInfo.get('visitorCompany').setValidators(this.setRequired('visitorCompany'));
        //   this.addVisitorInfo.get('visitorCountryCode').setValidators(this.setRequired('visitorCountryCode'));
      }
    }
  }
  setRequired(name: string) {
    if (!this.storeVisitor) {
      return [];
    } else {
      if (this.storeVisitor[name].required)
        return [Validators.required];
      else
        return [];
    }
  }

  invalidNumber = false
  mobileChange() {
    this.invalidNumber = false
  }
  onSubmitaddVisitors() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addVisitorInfo.invalid) {
      return;
    } else {
      this.submitted = false;
      let phnNumber = this.visitorInfo.countryCode + '-' + this.visitorInfo.mobile;
      const getPhone$ = this.vmsService.ValidatePhoneNo(phnNumber, this.currentZone);

      this.loaderService.showLoaderUntilCompleted(getPhone$).subscribe((res: any) => {
        this.addVisitor();
        this.addVisitorInfo.reset();
        this.visitorInfo.countryCode = this.countryCodeList[0]['countryCode']
      }, (error) => {
        this.invalidNumber = true
        if (error.status == 401) {
          this.invalidNumber = false;
          this.toastrservice.error("Invalid session. Please relogin.");
        }
        else if (error.status != 400) { 
            this.invalidNumber = false;
            this.toastrservice.error(error.error.message);
          }
          else 
          this.invalidNumber = true;
      });
    }
  }

  get fav() {
    return this.addVisitorInfo.controls;
  }

  addProxyUser() {
    if ((this.user != null && this.user.trim().length > 0) && (this.userProxy != null && this.userProxy.trim().length > 0) && (this.userId != null && this.proxyId != null)) {
      if (this.userId == this.proxyId) {
        this.userId = null;
        this.proxyId = null;
        this.user = null;
        this.userProxy = null;
        this.commonService.openSnackBar("User & proxy cannot be same", "");
        return;
      }
      else {
        let obj1 = {
          User: this.user.trim(),
          Proxy: this.userProxy.trim(),
          Notify_User: this.notifyToogle,
          Actions: true,
          userId: this.userId,
          proxyId: this.proxyId,
        }

        let exist = this.dataSourceProxy.some((item) => item.userId === this.userId);



        if (this.updateProxy) {
          this.editProxyBtnMod = false
          const filteredData = this.dataSourceProxy.filter((item, index) => index !== this.index);
          let existUpdate = filteredData.some((item) => item.userId === this.userId);
          if (existUpdate) {
            this.commonService.openSnackBar("User already exist", "");
            this.updateProxy = false;
            this.user = null;
            this.userProxy = null;
            this.userId = null;
            this.proxyId = null;
          }
          else {


            this.dataSourceProxy[this.index].User = this.user.trim();
            this.dataSourceProxy[this.index].Proxy = this.userProxy.trim();
            this.dataSourceProxy[this.index].Notify_User = this.notifyToogle;
            this.dataSourceProxy[this.index].userId = this.userId
            this.dataSourceProxy[this.index].proxyId = this.proxyId;
            this.dataSourceProxy = [...this.dataSourceProxy];
            this.commonService.openSnackBar("Data Updated", "");
            this.updateProxy = false;
            this.user = null;
            this.userProxy = null;
            this.userId = null;
            this.proxyId = null;
          }



        }
        else if (exist && !this.updateProxy) {
          this.commonService.openSnackBar("User already exist", "");
        }
        else {




          this.dataSourceProxy.push(obj1);
          this.dataSourceProxy = [...this.dataSourceProxy];
          this.user = null;
          this.userProxy = null;
          this.notifyToogle = false;
          this.userId = null;
          this.proxyId = null;
        }



      }
    }
    else {
      this.commonService.openSnackBar("Please Enter Value", "");
      return;
    }
  }
  deleteProxy(ele) {
    let i
    i = this.dataSourceProxy.indexOf(ele);
    if (i !== -1) {
      this.dataSourceProxy.splice(i, 1);
      this.dataSourceProxy = [...this.dataSourceProxy];
      this.proxyData.splice(i, 1);
    }
  }
  editProxyBtnMod = false
  editProxy(ele) {
    this.editProxyBtnMod = true
    this.updateProxy = true;
    this.index = this.dataSourceProxy.indexOf(ele);
    this.user = ele.User;
    this.userProxy = ele.Proxy;
    this.userId = ele.userId;
    this.proxyId = ele.proxyId;
    this.notifyToogle = ele.Notify_User;
  }
  // notifyToggleEdit(checked,ele){
  // let index=this.dataSourceProxy.indexOf(ele);
  //   this.dataSourceProxy[index].Notify_User = checked;
  //   this.dataSourceProxy=[...this.dataSourceProxy];
  //   this.toastrservice.success("Notify user updated");
  // }
  onSelectUser(ele) {
    this.hosts = [];
    const getUserProxy$ = this.vmsService.getUserProxy(this.currentZone, ele.userId);
    this.loaderService.showLoaderUntilCompleted(getUserProxy$).subscribe((res: any) => {
      let response = res;
      if (response) {
        this.toastrservice.error("The chosen user already has a proxy assigned");
        this.user = null;
      }
      else {
        this.userId = ele.userId;
      }
    }, (error) => {
      this.toastrservice.error("Something Went wrong");
      this.user = null

    });
    this.nameSearchTermSubject$.next(null);
  }
  onSelectProxyUser(ele) {
    this.hosts = [];
    this.proxyId = ele.userId;
    let isSwap1 = this.dataSourceProxy.some((item) => item.userId == this.proxyId && item.proxyId == this.userId)


    const allProxy$ = this.vmsService.getAllProxy(this.currentZone);



    this.loaderService.showLoaderUntilCompleted(allProxy$).subscribe(res => {
      if (res) {
        let object = (res as any);

        let isSwap2 = object.some((item) => item.userId == this.proxyId && item.proxyId == this.userId)

        if (isSwap1 || isSwap2) {
          this.toastrservice.error('Proxy Swapping Not Possible')
          this.userProxy = null;
          this.proxyId = null
        }
        else {
          this.proxyId = ele.userId
        }

      }
    },
      err => {
      }, () => {
      });



    this.nameSearchTermSubject$.next(null);
  }
  onSearchUser(searchValue: string): void {
    this.userId = null
    this.nameSearchTermSubject$.next(searchValue.trim());
  }
  onSearchProxy(searchValue: string): void {
    this.proxyId = null
    this.nameSearchTermSubject$.next(searchValue.trim());
  }

  createProxy() {
    this.disableDone = true;
    this.dataSourceProxy.forEach(element => {
      let obj2 = {
        userId: element.userId,
        proxyId: element.proxyId,
        optNotifications: element.Notify_User
      }
      this.proxyData.push(obj2);
    });
    const createProxy$ = this.vmsService.createProxy(this.currentZone, this.proxyData);
    this.loaderService.showLoaderUntilCompleted(createProxy$).subscribe((res: any) => {
      this.dialogRef.close(this.pageType);
      this.toastrservice.success("Proxy created Successfully");
    });
  }
  updateProxyApi() {
    if (this.userId == this.proxyId) {
      this.proxyId = null;
      this.userProxy = null;
      this.commonService.openSnackBar("User & proxy cannot be same", "");
      return;
    }
    else if ((this.userProxy && this.userProxy.trim().length == 0) || this.proxyId == null) {
      this.commonService.openSnackBar("Please enter a value", "");
      return;
    }

    let obj = {
      userId: this.userId,
      proxyId: this.proxyId,
      optNotifications: this.notifyToogle
    }
    this.vmsService.updateProxy(this.currentZone, obj).subscribe((res: any) => {
      this.dialogRef.close(this.pageType);
      this.toastrservice.success("Proxy updated Successfully");
    });
  }

  changetooltipTrue() {
    this.IstooltipShown = !this.IstooltipShown;
    //this.IstooltipShown = true;
  }


  toggleProxyChanged(event: any) {
    if (event) {
      this.toolTipText = "Enabled";
    }
    else {
      this.toolTipText = "Disabled";
    }
  }
  startDate;
  endDate;
  selectedType: string = "current_week";
  filterType: any[] = [
    { id: "current_week", name: "Current week" },
    { id: "last_15_days", name: "Last 15 days" },
    { id: "last_month", name: "Last month" },
    { id: "custom_date", name: "Custom date" },
  ];
  inviteType: any[] = [
    { id: 1, name: "Manually" },
    { id: 2, name: "Excel" },

  ];
  downloadReportTypeChange(event) {
    const today = new Date();
    if (this.selectedType == "current_week") {
      this.startDate = new Date();
      this.endDate = new Date();
      this.startDate.setDate(today.getDate() - today.getDay() + 1);
      this.endDate.setDate(today.getDate() + (7 - today.getDay()));
    } else if (this.selectedType == "last_15_days") {
      this.startDate = new Date();
      this.endDate = new Date();
      this.startDate.setDate(today.getDate() - 15); // Subtracting 15 days from current date
    } else if (this.selectedType == "last_month") {
      this.startDate = new Date();
      this.endDate = new Date();
      this.startDate.setMonth(this.startDate.getMonth() - 1); // Subtracting 1 month from current month
    } else if (this.selectedType == "custom_date") {
      this.startDate = new Date();
      this.endDate = new Date();
    }
  }
  downloadReport = () => {


    let startDate = new Date(this.startDate);
    let endDate = new Date(this.endDate);
    let sd: any = "";
    let ed: any = "";
    if (startDate.getTime() <= endDate.getTime()) {
      sd = this.commonService.getTimeStamp(startDate.getTime(), "start");
      ed = this.commonService.getTimeStamp(endDate.getTime(), "end");
    }
    else {

    }
    let data: any = {};
    data.startTime = sd
    data.endTime = ed;

    // let startDate = new Date(this.startDate);
    // let endDate = new Date(this.endDate);

    let getReport = this.vmsService.getReportData(data, this.currentZone);
    let getAllColumns = this.vmsService.getAllCustomColumns(this.currentZone);
    forkJoin([getReport, getAllColumns]).subscribe(result => {

      let resData = (result[0] as any).response;
      let columns = (result[1] as any);
      let vmsdownloadList = [];
      let obj: any = {};
      obj.startDate = "Start Date";
      obj.endDate = "End Date";
      obj.visitors = "Visitors";
      obj.Emailaddress = "Email Address"
      obj.mobile = "Mobile";
      obj.host = "Host";
      obj.company = "Company";
      obj.checkIn = "Check In";
      obj.checkOut = "Check Out";
      obj.purpose = "Purpose Of Visit";
      obj.type = "Type";


      let columnFieldNames = columns.map(column => column.fieldName);
      columns.forEach((ele, index) => {
        obj['customField' + index] = ele['fieldName'];
      })
      resData.forEach(element => {
        if (!!element.visitorEmail) {
          element.visitorEmail = element.visitorEmail
        }
        else {
          element.visitorEmail = "NA"
        }

        // element['customFieldResponse'].map(res=>{
        //res['fieldName] = res['fieldValue]
        // })
      });


      vmsdownloadList = resData.map((elem) => {
        let obj = {
          startDate: new Date(elem.scheduledStart).toDateString(),
          endDate: new Date(elem.scheduledEnd).toDateString(),
          visitors: this.commonService.replaceCSVSpecialChar(elem.visitorName),
          Emailaddress: this.commonService.replaceCSVSpecialChar(elem.visitorEmail),
          mobile: this.commonService.replaceCSVSpecialChar(elem.visitorMobile.replace("+", " +")),
          host: this.commonService.replaceCSVSpecialChar(elem.hostName),
          company: this.commonService.replaceCSVSpecialChar(elem.hostCompany),
          checkIn:
            elem.timeIn != null
              ? new Date(elem.timeIn).toLocaleString()
              : "--",
          checkOut:
            elem.timeOut != null
              ? new Date(elem.timeOut).toLocaleString()
              : "--",
          purpose: this.commonService.replaceCSVSpecialChar(elem.purpose),
          type: this.getPlatformType(elem.platformType),
        };

        // Get field names from customFieldResponse
        let responseFieldNames = elem.customFieldResponse.map(response => response.fieldName);

        // Iterate over column field names
        columnFieldNames.forEach((fieldName, index) => {
          let fieldValue = "";
          if (!responseFieldNames.includes(fieldName)) {
            fieldValue = "NA";
          } else {
            let responseIndex = responseFieldNames.indexOf(fieldName);
            fieldValue = elem.customFieldResponse[responseIndex].fieldValue;
          }
          obj['customField' + index] = fieldValue;
        });
        return obj;
      });
      vmsdownloadList.unshift(obj);
      new Angular5Csv(vmsdownloadList, "Visitor List");

    },
      (err) => {

      });
  };
  getPlatformType(value: String) {
    value = value.replace("_", " ");
    return value;
  }

  selectedInviteType = null;
  inviteVisitor1() {
    // this.pageType='createInviteVisitorManually'
    if (this.selectedInviteType == 1) {
      this.pageType = 'createInviteVisitorManually'
      this.data.width = "65%";
    }
    else {

      this.submittedExcel = true;
      if (this.showUploadedExcelFileName != '') {
        this.validateExcelOnboarding()
      }

    }


  }

  closeInvite1() {
    this.pageType = 'createInviteVisitorNew'
  }
  closeInvite2() {

    if (this.selectedInviteType == 1) {
      this.pageType = 'createInviteVisitorManually'
      this.data.width = "65%";
    }
    else {
      this.pageType = 'onboardingExcelTable'
    }

  }

  inviteVisitor2() {
    this.pageType = 'createInviteVisitorHost'
    this.data.width = "48%";
  }

  // displayLocation(i){
  //   let selectedLocation = _.cloneDeep( this.locations[i]);

  //   // Access the 'zoneId' property
  //  // this.hostInfo.location=''
  //   let val=_.cloneDeep(selectedLocation.name +","+selectedLocation.address)
  //   return val;
  // }

  onLocationSelectionChanged(event: MatAutocompleteSelectedEvent) {
    const selectedIndex = event.option.value;

    // Access the selected 'location' object using the index
    let selectedLocation = _.cloneDeep(this.locations[selectedIndex]);

    // Access the 'zoneId' property
    // this.hostInfo.location=''

    this.hostInfo.location = selectedLocation.name + "," + selectedLocation.address

    const zoneId = selectedLocation.zoneId
    const siteId = selectedLocation.site['id'];
    // Now you can use 'zoneId' as needed
    this.hostInfo['zoneId'] = zoneId
    this.hostInfo['siteId'] = siteId;
    this.hostInfo.location = selectedLocation.name + "," + selectedLocation.address
    this.locations = []
  }
  isEditVisitor = false
  editVisitorIndex = -1
  editVisitor(index) {
    const selectedVisitor = this.visitorList[index];
    // Populate the form with the details of the selected visitor
    let parts = selectedVisitor.mobile.split('-');
    // let arrT=_.cloneDeep(this.visitorList)
    // arrT.splice(index, 1);
    // this.visitorList=_.cloneDeep(arrT)
    // this.dataSource = _.cloneDeep( this.visitorList);
    // this.dataSource = [...this.dataSource]; 
    this.addVisitorInfo.setValue({
      visitorName: selectedVisitor.name,
      visitorEmail: selectedVisitor.email || '',
      visitorCountryCode: parts[0],
      visitorMobile: parts[1],
      visitorCompany: '',
      // Add other form controls as needed
    });
    this.isEditVisitor = true
    this.editVisitorIndex = index

  }
  fileToUpload;
  uploadedFileName = ''
  submittedExcel = false
  @ViewChild("fileUploader", { static: false }) fileUploader: ElementRef;
  @ViewChild("labelImport", { static: false }) labelImport: ElementRef;
  uploadCSV(files: FileList) {
    this.submittedExcel = false;
    // this.templateData = [];
    // this.correctData = [];
    // this.incorrectData = [];

    // this.uploadedFileList = Array.from(files);
    if (this.isCSVFile(files[0])) {
      this.fileToUpload = files.item(0);
      if (this.fileToUpload) {
        this.labelImport.nativeElement.innerText = "";
        this.uploadedFileName = Array.from(files)
          .map((f) => f.name)
          .join(", ");
      } else {
        this.toastrservice.error("No file selected!");
        //alert("No file selected!");
      }
    } else {
      this.toastrservice.error("Please import valid .csv file.");
      this.fileReset();
    }
    this.fileUploader.nativeElement.value = null;
  }

  removeUploadedFile() {
    this.submittedExcel = false;
    this.uploadedFileName = "";
    this.fileToUpload = null;
    this.labelImport.nativeElement.innerText = "";
    this.fileUploader.nativeElement.value = null;
  }


  downloadViaExcelTemplate(value?) {

    const customQues$ = this.vmsService.downloadViaExcelTemplate(this.currentZone)

    this.loaderService.showLoaderUntilCompleted(customQues$).subscribe((res: Blob) => {

      if (!!res) {
        const fileName = 'visitors.xlsx';
        this.excelService.saveApiResponseToFile(res, fileName);
      }
    }, err => {

    });


  }
  excelUploadedFile
  showUploadedExcelFileName = '';
  uploadExcel(files: FileList) {
    var fileName = Array.from(files).map(f => f.name).join(", ");
    const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (extension === 'xlsx' || extension === 'xls') {
      this.excelUploadedFile = files.item(0);
      this.showUploadedExcelFileName = fileName;
    } else {
      this.toastrservice.error("Please import valid .xlsx file.");
    }
  }

  listViewExcelResponse
  incorrectData
  correctData
  noOfEntitiesUploaded
  saveCorrectData
  totalIncorrectLength
  showIncorrectData
  TotalNoOfEntitiesUploadedRNW = 0;
  validateExcelOnboarding() {



    const validateCSVOnboarding$ = this.vmsService.validateExcelOnboarding(
      this.excelUploadedFile,
      this.showUploadedExcelFileName,
      this.currentZone
    );
    this.loaderService
      .showLoaderUntilCompleted(validateCSVOnboarding$)

      .subscribe(
        (res) => {

          if (!!res['response']) {
            this.listViewExcelResponse = res['response'];
            if (res["response"]["value"].length > 0) {
              this.incorrectData = [];
              this.correctData = [];
              this.saveCorrectData = [];
              res["response"]["value"].forEach((entity) => {
                if (entity.csvDto) {
                  let {
                    name,
                    email,
                    mobile,

                  } = entity.csvDto;
                  let uploadData = {
                    name,
                    email,
                    mobile,
                    errorFields: entity.columns,
                    reason: entity.reason,
                  };
                  if (entity.columns.length > 0) {
                    this.incorrectData.push(uploadData);
                  } else {
                    this.correctData.push(uploadData);
                    this.saveCorrectData.push(entity.csvDto);
                  }
                }
              });
              this.TotalNoOfEntitiesUploadedRNW = this.incorrectData.length + this.correctData.length
              this.noOfEntitiesUploaded = this.saveCorrectData.length;
              if (this.incorrectData.length == 0) {
                this.dialogRef.updateSize('45%');
              } else {
                this.totalIncorrectLength = this.incorrectData.length;
                this.showIncorrectData = this.incorrectData.slice(0, 10);
              }
            }
            else {
              this.toastrservice.error('No Enteries were added');
              return;
            }
          }
          this.pageType = 'onboardingExcelTable';
          this.dialogRef.updateSize('75%');


          // if (this.digitalVersion) {
          //   if (!this.approach || this.approach == "") {
          //     this.updateConfig('LIST');
          //   }
          // }
        },
        (error) => {
          this.commonService.openSnackBar(
            "Something went wrong!! Please make sure file is in correct format",
            ""
          );
        }
      );
  }
  pageSize = 10;
  incorrectDataColumns = [
    // "slNo",
    "name",
    "email",
    "mobile",
    "reason",
  ];
  getNextRecords(event) {
    this.pageSize = event['pageSize'];
    const startIndex = this.pageSize * (event['pageIndex']);
    const endIndex = this.pageSize * (event['pageIndex'] + 1)
    this.showIncorrectData = this.incorrectData.slice(startIndex, endIndex);
  }
  getToolTipData(hoverData) {
    return hoverData;
  }
  reUploadEntities() {
    this.pageType = 'onboarding_reUploadCSV';
    this.excelUploadedFile = undefined
    this.showUploadedExcelFileName = undefined;
    this.dialogRef.updateSize('40%');
  }
  camelCasingToNormal(value: string): string {
    return value
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      .replace(/\b\w/g, (match) => match.toUpperCase()); // capitalize the first letter
  }
  downloadCurrentCSVFile() {

    var tempData = [...this.incorrectData];
    tempData.push(...this.correctData);
    let downloadArr = tempData.map(row => {
      var errors = row.errorFields.map(v => this.camelCasingToNormal(v));
      var entityDatas;



      entityDatas = {
        'Name': row.name,
        'Email': row.email,
        'Mobile': row.mobile,
        "Error Fields": errors ? errors.toString() : errors,
        "Reason": row.reason
      };

      return entityDatas;
    });
    const fileName = 'visitors';
    this.excelService.convertJSONtoXLSX(downloadArr, fileName);

  }
  proceedToOnboard(i) {

    this.visitorList = [];
    if (this.correctData.length > 0) {
      this.correctData.forEach((e) => {
        let visitor = {}
        const parts = e['mobile'].split('-');
        visitor['name'] = e['name'];
        visitor['email'] = e['email'];
        visitor['mobile'] = e['mobile'];
        visitor['company'] = e['company'];
        visitor['visitorTypeId'] = 1;
        // visitor['countryCode'] = parts[0];
        this.visitorList.push(visitor);
      })
      this.dataSource = _.cloneDeep(this.visitorList);
      //this.dataSource = [...this.dataSource];
      this.pageType = 'createInviteVisitorHost'
      if (i == 1) {
        this.dialogRef.updateSize('48%');
      }

    }
    else {
      this.toastrservice.error("No Valid Entry's Found")
    }
  }

  CCSelectionChange() {
    this.invalidNumber = false
  }


}

export class VistorsCsvRecord {

  public name: any;
  public email: any;
  public mobile: any;
  public company: any;
  public visitorTypeId: any;
  constructor() { }
}



