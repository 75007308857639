<div>
    <mat-card class="example-card" *ngIf="pageType == 'showDetails'" style="overflow: hidden;">
        <div class="row">
            <div class="col-md-6">
                <div class="visitor-info-header">
                    {{ "preview" | translate }}
                </div>
            </div>
            <div class="col-md-6">
                <button type="button" id="close_btn" class="close" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider>
        <mat-card-content>
            <div *ngFor=' let item of alldetailsList'>
                <div class="row">
                    <div class="col-md-9 visitor-details">
                        <div class="visitor-details-inner">

                            <div class="row detail-row">

                                <div class="col-md-5 user-detail-value">{{'fieldType' | translate}}</div>
                                <div class="col-md-4 user-detail-value">{{item.type}}</div>
                            </div>

                            <!-- <div class="row detail-row">
                             <div class="col-md-5 user-detail-value">Is Mandatory</div>
                             <div class="col-md-4 user-detail-value">{{item.isMandatory}}</div>
                         </div>  -->

                        </div>
                    </div>
                    <div class="col-md-3">

                    </div>
                </div>
                <hr>
                <b>Q.</b><span><b> {{item.question}}</b></span>
                <div class="row" style="margin-left: 15px;">

                    <div class='row' *ngIf='item.type == "RADIO_BUTTON"'>

                        <div class='col-md-8'>
                            <mat-radio-group class="example-radio-group">
                                <mat-radio-button class="example-radio-button" *ngFor="let ans of item.option; let i=index;"
                                   id="ans_{{i}}" style="margin-left:10px;font-size: 20px;" value="ans">
                                    <b>{{ans}} </b>
                                </mat-radio-button>
                            </mat-radio-group>

                        </div>

                    </div>


                    <div *ngIf='item.type == "CHECKBOX"' class='row'>
                        <br>
                        <div class='col-md-8'>
                            <section *ngIf="!!item.option" style="margin-right: 10px;">
                                <mat-checkbox *ngFor="let ans of item.option; let i=index;" ngDefaultControl
                                id="checkbox_ans_{{i}}" style="margin-left:20px;font-size: 20px;">
                                    <b style="font-size: 20px;">{{ans}}</b>
                                </mat-checkbox>
                            </section>
                        </div>
                    </div>
                    <div *ngIf='item.type == "TEXT"' class='row'>
                        <div class='col-md-4'><br>
                            <input type="text" class="form-control" style="width: 100px">

                        </div>
                    </div>
                    <div *ngIf='item.type == "NUMBER"' class='row'>
                        <div class='col-md-4'><br>
                            <input type="number" class="form-control" style="width: 100px">

                        </div>
                    </div>
                    <div *ngIf='item.type == "DATE"' class='row'>
                        <!-- <span>Selected Type</span>  -->
                        <!-- <div class='col-md-4'>
                       <br> 
                     <input type ="text" [(ngModel)]="item .selectedvalue"  style="width: auto" class="form-control">
               
                 </div>     -->
                    </div>

                    <div *ngIf='item.type == "SINGLE_SELECT_DROPDOWN"' class='row'>
                        <!-- <span>Selected Type</span>  -->
                        <div class='col-md-4'>
                            <br>
                            <mat-select style="width: 150px" class="form-control" id="selected_val">
                                <!-- <option></option> -->
                                <mat-option *ngFor="let it of item.selectedvalue">{{it}}</mat-option>
                            </mat-select>

                        </div>
                    </div>

                    <div *ngIf='item.type == "MULTI_SELECT_DROPDOWN"' class='row'>
                        <!-- <span>Selected Type</span>  -->
                        <div class='col'>
                            <br>
                            <mat-select style="width: 150px" id="multiselected_val" class="form-control" multiple>
                                <!-- <option></option> -->
                                <mat-option *ngFor="let it of item.selectedvalue">{{it}}</mat-option>
                            </mat-select>

                        </div>
                    </div>

                    <div *ngIf='item.type == "FILE_UPLOAD"' class='row'>
                        <div class='col'>
                            <label for="inputEmail4"
                            >{{ "upload" | translate }} 
                            </label
                        >
                        <!-- (.csv{{ "file" | translate }}) -->
                        <div class="input-group" id="">
                        <!-- <div class="input-group" id="uploadCSV"> -->
                            <input [(ngModel)]="uploadData" [ngModelOptions]="{standalone: true}"
                                type="file"
                                class="form-control custom-file-input"
                                id="importFile"
                                (change)="uploadCSV($event.target.files)"
                                aria-describedby="inputGroupFileAddon01"
                            />
                            <label
                                class="custom-file-label custom-upload-btn"
                                for="inputGroupFile01"
                                #labelImport
                            ></label>
                        </div>
                        </div>
                    </div>

                </div>
            </div>

        </mat-card-content>
    </mat-card>
</div>
