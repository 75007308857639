import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { CommonService } from "commonService";
import { dateFormat } from "highcharts";
import { LoaderService } from "loaderService";
import { ToastrService } from "ngx-toastr";
import { DemandSupplyServiceService } from "../../../service/demand-supply-service.service";

import * as moment from "moment-timezone";
import {
    DateTimeAdapter,
    OWL_DATE_TIME_FORMATS,
    OWL_DATE_TIME_LOCALE,
} from "ng-pick-datetime";
import { MomentDateTimeAdapter } from "ng-pick-datetime-moment";

export const MY_CUSTOM_FORMATS = {
    parseInput: "LL LT",
    fullPickerInput: "LL LT",
    datePickerInput: "LL",
    timePickerInput: "LT",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
};

@Component({
    selector: "app-create-move-request",
    templateUrl: "./create-move-request.component.html",
    styleUrls: ["./create-move-request.component.scss"],
    providers: [
        {
            provide: DateTimeAdapter,
            useClass: MomentDateTimeAdapter,
            deps: [OWL_DATE_TIME_LOCALE],
        },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    ],
})
export class CreateMoveRequestComponent implements OnInit {
    manageTab = false;
    @Input() currentZone: number;
    @Output() pageType = new EventEmitter<string>();
    @Output() moveDataObj = new EventEmitter();
    @Output() viewInsightsData = new EventEmitter();
    @Output() createdRequestId = new EventEmitter<string>();

    @Input() config;

    departmentList = [];
    departmentSelected = false;
    cityList;
    preferredZoneList;
    submitted = false;
    moveTeamUserList = [];
    dataSource = [];
    displayedColumns = [
        "employee",
        "site",
        "building",
        "floor",
        "workstation",
        "currentWorkstation",
        "preferredWorkstation",
        "action",
    ];
    workstationList = [];
    moveRequestForm = this.formBuilder.group({
        moveType: ["team"],
        moveByTeamForm: this.formBuilder.group({
            startDate: [moment(), Validators.required],
            department: ["", Validators.required],
            reason: ["", Validators.required],
            moveToType: ["2"],
            city: ["", Validators.required],
            preferredLocation: ["", Validators.required],
        }),
    });

    getFormControlErrors(param) {
        return (
            this.submitted &&
            (this.f.moveByTeamForm.get(param) as FormArray).errors
        );
    }

    getDeptSelectedFormControlError(param) {
        return (
            this.departmentSelected &&
            (this.f.moveByTeamForm.get(param) as FormArray).errors
        );
    }

    constructor(
        private formBuilder: FormBuilder,
        private demandSupplyService: DemandSupplyServiceService,
        private loadingService: LoaderService,
        private commonService: CommonService,
        private toastrService: ToastrService
    ) {}
    ngOnInit() {
        this.getDepartmentList();
        // this.getCostCodeList();
        this.getCityList();
        // this.getAllAllocation();
        this.getAllWorkstations();
    }

    get f() {
        return this.moveRequestForm.controls;
    }

    // get f() {
    //   return this.moveRequestForm.controls.moveByTeamForm['controls'];
    // }

    setPageType(value) {
        this.submitted = true;
        // console.log(this.moveTeamUserList.length > 0);
        if (
            this.moveRequestForm.controls.moveByTeamForm["valid"] &&
            this.moveTeamUserList.length > 0
        ) {
            let data = this.collectFormData();
            this.pageType.emit(value);
            this.viewInsightsData.emit(data);
        } else if (
            this.moveRequestForm.controls.moveByTeamForm["valid"] &&
            this.moveTeamUserList.length == 0
        ) {
            this.toastrService.error("Please select users to move");
        }
    }

    getDepartmentList() {
        const department$ = this.demandSupplyService.getAllDepartments(
            this.currentZone
        );
        this.loadingService.showLoaderUntilCompleted(department$).subscribe(
            (res) => {
                this.departmentList = res["response"];
            },
            (err) => {}
        );
    }

    // getCostCodeList() {
    //     let moveByTeamControls = this.moveRequestForm.controls
    //         .moveByTeamForm as FormGroup;
    //     // const deptId = moveByTeamControls.controls.department.value;
    //     const locationId = moveByTeamControls.controls.preferredLocation.value;
    //     const costCode$ = this.demandSupplyService.getAllCostCode(
    //         this.currentZone
    //     );
    //     const dept$ = this.loadingService
    //         .showLoaderUntilCompleted(costCode$)
    //         .subscribe(
    //             (res) => {
    //                 this.costCodeList = res["response"]["content"];
    //             },
    //             (err) => {}
    //         );
    // }

    getAllAllocation() {
        this.departmentSelected = true;
        if (
            this.moveRequestForm.get("moveByTeamForm").get("department").invalid
        ) {
            return;
        }
        let departmentId = this.moveRequestForm
            .get("moveByTeamForm")
            .get("department").value;

        let moveByTeamFormControl = this.moveRequestForm.controls
            .moveByTeamForm as FormGroup;
        const allocation$ = this.demandSupplyService.getAllUserAllocationPermanent(
            moveByTeamFormControl.controls.preferredLocation.value,
            departmentId,
            !!this.moveRequestForm.controls.moveByTeamForm["controls"][
                "startDate"
            ]["value"]
                ? this.moveRequestForm.controls.moveByTeamForm["controls"][
                      "startDate"
                  ]["value"]
                      .toDate()
                      .getTime()
                : null
        );
        const allocations$ = this.loadingService
            .showLoaderUntilCompleted(allocation$)
            .subscribe(
                (res) => {
                    this.dataSource = res["response"];
                },
                (err) => {}
            );
    }

    getCityList() {
        const cities$ = this.demandSupplyService.getAllCities(this.currentZone);
        const city$ = this.loadingService
            .showLoaderUntilCompleted(cities$)
            .subscribe(
                (res) => {
                    this.cityList = res["response"];
                },
                (err) => {}
            );
    }

    getPreferredLocationList() {
        // console.log(
        //     "formValues",
        //     this.moveRequestForm.controls.moveByTeamForm.value.city
        // );
        let moveByTeamGroup = this.moveRequestForm.controls
            .moveByTeamForm as FormGroup;
        let cityId = moveByTeamGroup.controls.city.value;
        let data = {
            zoneIds: [cityId],
            childLevel: "SITE",
        };
        const cities$ = this.demandSupplyService.getAllZonesByCity(
            this.currentZone,
            data
        );
        const city$ = this.loadingService
            .showLoaderUntilCompleted(cities$)
            .subscribe(
                (res) => {
                    this.preferredZoneList =
                        res["response"]["zoneMap"][
                            this.moveRequestForm.controls.moveByTeamForm.value.city
                        ];
                },
                (err) => {}
            );
    }

    getUserLegend = (users) => {
        if (!!users.firstName) {
            const fName = users.firstName.length
                ? users.firstName.charAt(0)
                : "";
            const lName = users.lastName.length ? users.lastName.charAt(0) : "";
            return `${fName}${lName}`;
        }
        return "";
    };

    selectMoveUsers(event, element) {
        // if(event.checked == true){
        let moveUserExists;
        if (this.moveTeamUserList.length > 0) {
            moveUserExists = this.moveTeamUserList.findIndex((data) => {
                return data == element;
            });
            if (moveUserExists >= 0) {
                if (event.checked == false) {
                    this.moveTeamUserList.splice(moveUserExists, 1);
                }
            } else {
                this.moveTeamUserList.push(element);
            }
        } else {
            this.moveTeamUserList.push(element);
        }
        // console.log(this.moveTeamUserList);

        // }
    }

    // setPageType(value){
    //   this.pageType.emit(value);
    // }

    createMoveRequest() {
        // console.log(this.moveRequestForm.valid && this.moveTeamUserList);
        let moveDataObj = this.collectFormData();
        if (!this.moveRequestForm.valid) {
            return;
        } else if (this.moveTeamUserList.length <= 0) {
            this.toastrService.error("Please select users to move");
            return;
        }
        const workstations$ = this.demandSupplyService.createSpaceRequest(
            moveDataObj,
            this.currentZone
        );
        const workstation$ = this.loadingService
            .showLoaderUntilCompleted(workstations$)
            .subscribe(
                (res) => {
                    this.createdRequestId.emit(res["response"]["requestId"]);
                    this.moveDataObj.emit(moveDataObj);
                    this.setPageType("saveNotification");
                    //this.dialogRef.close({updateData: true})
                },
                (err) => {}
            );
        this.submitted = true;
        // if (
        //     this.moveRequestForm.controls.moveByTeamForm["valid"] &&
        //     this.moveTeamUserList.length
        // ) {
        //     moveDataObj = this.collectFormData();
        // } else if (!this.moveTeamUserList.length) {
        //     this.toastrService.error("Please select users to move");
        // }
        // console.log(moveDataObj);
        // this.manageTab = true;
        // this.demandSupplyService.moveDataSubject.next(moveDataObj);
        // this.setPageType('moveRequest');

        // this.moveDataObj.emit(moveDataObj);
        // const costCode$ = this.demandSupplyService.createMoveRequest(data, this.currentZone);
        // const costcodes$ = this.loadingService.showLoaderUntilCompleted(costCode$).subscribe(
        //   res => {
        //     console.log(res)
        //   },
        //   err => { }
        // );
    }

    startDate = new Date();

    collectFormData() {
        let currentStartTimeUtc = this.moveRequestForm.controls.moveByTeamForm[
            "controls"
        ]["startDate"]["value"]
            .toDate()
            .getTime();
        let currentEndTimeUtc = null;
        let ticketList = [];
        let totalReqdWorkstationMapping = [];
        this.moveTeamUserList.forEach((user) => {
            // console.log(user);
            let data = {
                enitityType: user["entityType"]["id"],
                required: 1,
            };
            let entity = {
                entityType: {
                    id: user["entityType"]["id"],
                },
                movedUsers: [user.user.userId],
                quantity: 1,
            };
            totalReqdWorkstationMapping.push(data);
            ticketList.push(entity);
        });
        // console.log(totalReqdWorkstationMapping);
        totalReqdWorkstationMapping = Array.from(
            totalReqdWorkstationMapping.reduce(
                (m, { enitityType, required }) =>
                    m.set(enitityType, (m.get(enitityType) || 0) + required),
                new Map()
            ),
            ([enitityType, required]) => ({ enitityType, required })
        );
        // console.log(
        //     "rahul",
        //     this.moveRequestForm.controls.moveByTeamForm["controls"]
        // );
        let moveDataObj = {
            demandType: "DEPARTMENT",
            demandId: this.moveRequestForm.controls.moveByTeamForm["controls"][
                "department"
            ]["value"],
            tickets: ticketList,
            shiftId: 1,
            startTime: currentStartTimeUtc,
            endTime: currentEndTimeUtc,
            requestType: "MOVE_TEAM",
            isDualUtilization: true,
            preferredZone: {
                zoneId: this.moveRequestForm.controls.moveByTeamForm[
                    "controls"
                ]["preferredLocation"]["value"],
            },
            totalWorkstationMapping: totalReqdWorkstationMapping,
            comments: this.moveRequestForm.controls.moveByTeamForm["controls"][
                "reason"
            ]["value"],
        };

        return moveDataObj;
    }

    getAllWorkstations() {
        const allWorkstations$ = this.demandSupplyService.getAllWorkstations(
            this.currentZone
        );
        this.loadingService
            .showLoaderUntilCompleted(allWorkstations$)
            .subscribe(
                (res) => {
                    this.workstationList = res["response"]["content"];
                },
                (err) => {}
            );
    }

    getUserListLength() {
        return `You have selected ${this.moveTeamUserList.length} employees for move`;
    }
}
