import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, pipe } from "rxjs";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
import { ModuleidService } from "src/app/services/moduleid-service";
import { environment } from "src/environments/environment";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class DigitalBookingProService {
    moduleId;
    BASEURL;
    EMSURL;
    BASEEMSURL;
    constructor(
        public http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        public moduleidService: ModuleidService
    ) {
        this.BASEURL = baseUrl;
        this.EMSURL = environment.BASE_URL + "ems/";
        this.BASEEMSURL = environment.BASE_URL;
        this.moduleId = moduleidService.getModueId();
    }

    deleteSpace(id): Observable<any> {
        let url = `${this.BASEEMSURL}amenitybooking/globalCategory/delete?id=${id}`;
        return this.http.delete<any>(url, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    editSpaces(dataObj): Observable<any> {
        let url = this.BASEEMSURL + `amenitybooking/globalCategory/update`;
        return this.http.post<any>(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    public postDigitalBookingPro(zoneId, data) {
        // https://digitaldev.smartenspaces.com/amenitybooking/globalCategory/create
        // let dataL = {
        //     "name":"Desk",
        //     "amenityType":"COLLABORATIVE"
        // }
        console.log("booking data is... ", data);
        // const endpoint = 'https://digitaldev.smartenspaces.com/ems/' + "module/updateConfig?generate=true";
        const endpoint =
            this.BASEEMSURL +
            "amenitybooking/globalCategory/global-admin/create";
        return this.http.post(endpoint, data, {
            params: new ZoneAndModuleHttpParams(zoneId, this.moduleId),
        });
    }
    //this for amenity edit through global admin
    createcategory(dataObj): Observable<any> {
        let url =
            this.BASEEMSURL +
            `amenitybooking/entitytype/global-admin/create/all`;
        return this.http.post<any>(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    //this for amenity add and activate through global admin
    createcategoryNew(dataObj,spaceTypeId): Observable<any> {
        let url =
            this.BASEEMSURL +
            `amenitybooking/entitytype/global-admin/create/all/withDisable?globalCategory=${spaceTypeId}`;
        return this.http.post<any>(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    createTitleUpdate(dataObj): Observable<any> {
        let url = this.BASEEMSURL + `/amenitybooking/globalCategory/update`;
        return this.http
          .post<any>(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
          })
        }
    //this for amenity edit through global admin
    addonsAndSpecialRequest(dataObj): Observable<any> {
        let url =
            this.BASEEMSURL + `amenitybooking/amenity/global-admin/create`;
        return this.http.post<any>(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
    //this for amenity add and activate through global admin
    addonsAndSpecialRequestNew(dataObj,spaceTypeId,specialRequesttype): Observable<any> {
        let url =
            this.BASEEMSURL + `amenitybooking/amenity/global-admin/create/all?globalCategory=${spaceTypeId}&specialRequest=${specialRequesttype}`;
        return this.http.post<any>(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    createcategoryUpdate(dataObj): Observable<any> {
        let url =
            this.BASEEMSURL + `amenitybooking/entitytype/global-admin/update`;
        return this.http.put<any>(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }

    addonsAndSpecialRequestUpdate(dataObj): Observable<any> {
        let url =
            this.BASEEMSURL + `amenitybooking/amenity/global-admin/update`;
        return this.http.put<any>(url, dataObj, {
            params: new ZoneAndModuleHttpParams(null, this.moduleId),
        });
    }
}
