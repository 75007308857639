<div class="colleaguesContainer">
    <!-- <div class="col-12 customScroll" *ngIf="!showSearchColleagues">
        <div class="text-center m-auto">
            <img src="./assets/images/jumpree_digital/colleagues_getstarted.svg" alt="Get Started" class="w-30"/>
            <p class="semi-bold-blue mt-4">Create a custom group of your favourite colleague and sync your schedules</p>
            <button class="btn btn-main btnSmall smallBtn float-right ml-4" (click)="showSearchColleague()">Get Started</button>
        </div>
    </div> -->
    <div class="col-12 p-0" *ngIf="showSearchColleagues">
        <div class="searchColleaguesContainer">
            <app-reuseable-search-filter 
            (notifyParentColleague)="getNotification($event)"
            [colleagueObj] = "colleagueObj"
            ></app-reuseable-search-filter>
        </div>
    </div>
</div>
