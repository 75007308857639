<div class="page-style" *ngIf="data.type == 'uploadLogo'">
  <mat-card>
      <mat-card-title>
          <!-- {{
            getTranslate("Upload Logo")
        }} -->
        Upload Logo
          <i id="close_modal"
            class="fa fa-window-close"
            style="float:right"
            aria-hidden="true"
            (click)="onCloseEventModal()"
          ></i>
        </mat-card-title>
        <hr />
       
        <mat-card-content>
          <form (ngSubmit)="f.form.valid && uploadLogoFile()" #f="ngForm">
            <div class="row">
                <div class="col col-lg-6">
                    <label for="file" class="formfield">
                      <!-- {{ getTranslate("Choose File") }} -->
                      Choose File
                    </label>
                    <input  type="file" required id="file" style="background-color: gainsboro" (change)="handleFileInput($event.target.files)" [(ngModel)]="layoutFile" #layoutFileText="ngModel" required name="layoutFileText" class="form-control">
                </div>
                <div class="col col-lg-12 errMsg" *ngIf="f.submitted && layoutFileText.invalid">
                  <!-- {{ getTranslate("File is required") }} -->
                  File is required
                </div>
            </div>
            <div class="row">
              <div class="col col-lg-6">
                  <br />
                  <button id="submit_btn" mat-raised-button type="submit" color="primary">
                    <!-- {{ getTranslate("Submit") }} -->
                    Submit
                  </button>
              </div>
            </div>
          </form>    
        </mat-card-content>
  </mat-card>
</div>

<div class="page-style" *ngIf="data.type == 'password'">
  <mat-card>
    <mat-card-title>
      {{"changePassword" | translate}}
      <i id="change_pwd"
        class="fa fa-window-close"
        style="float:right"
        aria-hidden="true"
        (click)="onCloseEventModal()"
      ></i>
    </mat-card-title>
    <hr />

    <mat-card-content>
      <form>
        <div class="row">
          <div class="col col-lg-6">
            <label for="file" class="formfield"> {{"oldPassword" | translate}}</label>
            <!-- <show-hide-password
                                size="sm"
                                icon="fontawesome"
                                btnStyle="secondary"
                                [btnOutline]="false"
                            > -->
            <input
              type="password"
              required
              id="old_password"
              [(ngModel)]="old_password"
              required
              name="old_password"
              class="form-control"
            />
            <!-- </show-hide-password> -->
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-6">
            <label for="file" class="formfield">{{"newPassword" | translate}} </label>
            <!-- <show-hide-password
                                size="sm"
                                icon="fontawesome"
                                btnStyle="secondary"
                                [btnOutline]="false"
                            > -->
            <input
              type="password"
              required
              id="new_password"
              [(ngModel)]="new_password"
              required
              name="new_password"
              class="form-control"
            />
            <!-- </show-hide-password> -->
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-6">
            <label for="file" class="formfield"> {{"confirmPasswordSmall" | translate}}</label>
            <!-- <show-hide-password
                                size="sm"
                                icon="fontawesome"
                                btnStyle="secondary"
                                [btnOutline]="false"
                            > -->
            <input
              type="password"
              required
              id="confirm_password"
              [(ngModel)]="confirm_password"
              required
              name="confirm_password"
              class="form-control"
            />
            <!-- </show-hide-password> -->
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-6">
            <br />
            <button id="change_btn"
              (click)="changePassword()"
              mat-raised-button
              type="submit"
              class="btn-primary"
              [disabled]="pswDisabled"
            >
              {{"submit" | translate}}
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div class="page-style" *ngIf="data.type == 'selectLocation'">
  <mat-card>
    <h5 class="semi-bold-blue">
      Select Office Location
      <!-- <i id="change_pwd"
        class="fa fa-window-close"
        style="float:right"
        aria-hidden="true"
        (click)="onCloseEventModal()"
      ></i> -->
      </h5>
    <hr class="hrBlue"/>

    <mat-card-content>
      <form>
        <div class="row">
          <div class="col col-lg-12">
            <p class="regular-blue">You have access to {{data?.zones?.length}} offices, choose where you predominantly work out of</p>
            <label for="file" class="formfield mt-1">Search locations </label>
            <input
              type="text"
              required
              class="form-control mt-1"
              name="search"
              [(ngModel)]="searchFilter"
              placeholder="Search location name"
            />
            

          </div>
          <div class="col col-12 mt-4 hRadio pl-1">
            <mat-radio-group class="example-radio-group" [(ngModel)]="selectedZone" (change)="selectedZoneRadio($event)" name="locationSelected">
              <ng-container *ngFor="let zone of filteredZones">
                <mat-radio-button class="example-radio-button pl-3 col-12 mt-2 gray-regular" [value]="zone.id">
                  {{zone.name}}
                </mat-radio-button>
              </ng-container>
            </mat-radio-group>
            
          </div>
          <div class="col-12 justify-content-end">
          <button
              (click)="saveZone()"
              
              type="submit"
              class="btn btn-main btnSmall smallBtn ml-4 mt-2 float-right"
              *ngIf="selectedZone"
            >
              Next
            </button>
          </div>
        </div>
        
      </form>
    </mat-card-content>
  </mat-card>
</div>