import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { Observable } from "rxjs/Observable";
import { ZoneAndModuleHttpParams } from "src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component";
@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  private moduleId;
  private readonly BASE_URL;
  private readonly TEMP_BASE_URL;
  private readonly BASE_EMS_URL;

  constructor(private http: HttpClient) {
    this.moduleId = 9;
    this.BASE_URL = environment.BASE_URL + "vms/";
    this.BASE_EMS_URL = environment.BASE_URL + "ems/";
    this.TEMP_BASE_URL = environment.BASE_URL;
  }

  uploadOnboardUsersTemplate(fileToUpload, planType): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") +
          '"}',
      }),
    };
    let url = this.BASE_EMS_URL + `user-subscription-mappings/admin/uploadCSV`;

    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("planName", planType);
    return this.http.post(url, formData, httpOptions);
  }

  loadSubcriptions() {
    let url = this.BASE_EMS_URL + `subscriptions/getQuantitiesByPlan`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  sendSubscriptionRequest(type) {
    let url = this.BASE_EMS_URL + `user-subscription-mappings/send?type=${type}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  loadSubcriptionsByPlan(title,subObj) {
    let url =
      this.BASE_EMS_URL +
      `user-subscription-mappings/usersByPlan/paginated?planName=${title}&page=${subObj['pageIndex']}&size=${subObj['pageSize']}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  validateOnboardSubscriptionTemplate(fileToUpload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":12001,"sessionId":"' +
          localStorage.getItem("ssadmin_session") +
          '"}',
      }),
    };
    let url =
      this.BASE_EMS_URL + `user-subscription-mappings/admin/validateCSV`;
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, httpOptions);
  }

  addUsers(data) {
    let url = this.BASE_EMS_URL + `user-subscription-mappings/add`;
    console.log("data is... ", data);
    console.log("url is... ", url);
    return this.http.post<any>(url, data, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  getAllSubscriptions() {
    let url =
      this.BASE_EMS_URL +`subscriptions/getAll`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }

  deleteUser(licenceId): Observable<any> {
    let url = this.BASE_EMS_URL + `user-subscription-mappings/?licenceId=${licenceId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        "ss-header":
          '{"correlationId":"' +
          uuidv4() +
          '","version":"1.0","clientKey":"ADMIN_WEB_APP","moduleId":'+this.moduleId+',"sessionId":"' +
          localStorage.getItem("ssadmin_session") +
          '"}',
      }),
    };
    
    return this.http.delete(url, httpOptions);
  }

  searchSubcriptionsByPlan(title,searchData) {
    let url =
      this.BASE_EMS_URL +
      `user-subscription-mappings/usersByPlan/search?planName=${title}&searchString=${searchData}`;
    return this.http.get<any>(url, {
      params: new ZoneAndModuleHttpParams(null, this.moduleId),
    });
  }
}
