/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./confirmation-dialog.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmationDialogComponent = [i0.styles];
var RenderType_ConfirmationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationDialogComponent, data: {} });
export { RenderType_ConfirmationDialogComponent as RenderType_ConfirmationDialogComponent };
function View_ConfirmationDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_ConfirmationDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationDialogComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "modal-body align-left pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [["class", "regular-blue mt-2 f-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "customMargin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "form-group col-lg-12 col-md-12 pr-0 mb-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-main btnSmall smallBtn mb-2 btn-main-outline"], ["id", "decline_btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-main btnSmall smallBtn  ml-3 mb-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.btnCancelText)); _ck(_v, 11, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.btnOkText)); _ck(_v, 14, 0, currVal_3); }); }
function View_ConfirmationDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-main btnSmall smallBtn  mb-2 btn-main-outline"], ["id", "decline_btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.btnCancelText)); _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "scroller"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "modal-header p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h5", [["class", "semi-bold-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "modal-body align-left pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [["class", "regular-blue mt-2 f-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "customMargin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "form-group col-lg-12 col-md-12 pr-0 mb-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationDialogComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "btn btn-main btnSmall smallBtn  ml-3 mb-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttons; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("alert")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 8, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform(_co.btnOkText)); _ck(_v, 15, 0, currVal_3); }); }
export function View_ConfirmationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationDialogComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.alert; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.alert; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ConfirmationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-dialog", [], null, null, null, View_ConfirmationDialogComponent_0, RenderType_ConfirmationDialogComponent)), i1.ɵdid(1, 114688, null, 0, i4.ConfirmationDialogComponent, [i5.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationDialogComponentNgFactory = i1.ɵccf("app-confirmation-dialog", i4.ConfirmationDialogComponent, View_ConfirmationDialogComponent_Host_0, { title: "title", message: "message", btnOkText: "btnOkText", btnCancelText: "btnCancelText", buttons: "buttons", alert: "alert" }, {}, []);
export { ConfirmationDialogComponentNgFactory as ConfirmationDialogComponentNgFactory };
