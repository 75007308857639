/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-tag-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i6 from "@angular/material/divider";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./delete-tag-modal.component";
var styles_DeleteTagModalComponent = [i0.styles];
var RenderType_DeleteTagModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteTagModalComponent, data: {} });
export { RenderType_DeleteTagModalComponent as RenderType_DeleteTagModalComponent };
export function View_DeleteTagModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(4, 49152, null, 0, i3.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 2, 3, "div", [["class", "info-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h5", [["class", "colorPrimary font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["id", "close_popup"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i5.View_MatDivider_0, i5.RenderType_MatDivider)), i1.ɵdid(14, 49152, null, 0, i6.MatDivider, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 4, "mat-dialog-content", [["class", "mt-4 mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "p", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, null, 8, "div", [["class", "row mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["class", "btn btn-main mt-4 w-100 buttonRounded"], ["id", "allDesks"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit("all") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(23, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(25, 0, null, null, 3, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "button", [["class", "btn btn-main mt-4 w-100 buttonRounded"], ["id", "selectDesks"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit("select") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(27, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("deleteAmenities")); _ck(_v, 7, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 14).vertical ? "vertical" : "horizontal"); var currVal_2 = i1.ɵnov(_v, 14).vertical; var currVal_3 = !i1.ɵnov(_v, 14).vertical; var currVal_4 = i1.ɵnov(_v, 14).inset; _ck(_v, 13, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("wouldLikeToDeleteTheSelectedAmenity")); _ck(_v, 18, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("fromAllDesks")); _ck(_v, 23, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 27, 0, i1.ɵnov(_v, 28).transform("selectDesks")); _ck(_v, 27, 0, currVal_7); }); }
export function View_DeleteTagModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-tag-modal", [], null, null, null, View_DeleteTagModalComponent_0, RenderType_DeleteTagModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.DeleteTagModalComponent, [i7.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteTagModalComponentNgFactory = i1.ɵccf("app-delete-tag-modal", i8.DeleteTagModalComponent, View_DeleteTagModalComponent_Host_0, {}, {}, []);
export { DeleteTagModalComponentNgFactory as DeleteTagModalComponentNgFactory };
