import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountVerifyService } from "./accountVerify.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "account-verify",
    templateUrl: "./accountVerify.component.html",
    styleUrls: ["./accountVerify.component.scss"]
})
export class AccountVerifyComponent implements OnInit {
    token: string;
    userType: string;
    accountVerifyRequest: any = {};
    accountVerifyMessage: string = "Account Unverified";
    accountverifiedName: string;
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public accountVerifyService: AccountVerifyService,
        private titleService: Title,
        public translate: TranslateService
    ) {}
    ngOnInit() {
        var currentContext = this;
        this.route.params.subscribe(params => {
            this.token = params["token"];
            this.userType = params["userType"];
            this.accountVerifyRequest["token"] = this.token;
        });
        this.titleService.setTitle("Account Verification - SS Admin Dashboard");
        this.accountVerifyService
            .verifyAccount(this.accountVerifyRequest)
            .subscribe(
                res => {
                    this.accountVerifyMessage = JSON.stringify(
                        res["message"] + " for user account"
                    );
                    this.accountverifiedName =
                        res["response"]["name"] +
                        "(" +
                        res["response"]["email"] +
                        ")";
                    if (currentContext.userType) {
                        this.accountVerifyMessage += "(tenant):";
                        this.router.navigate([
                            "/login",
                            this.accountVerifyMessage,
                            this.accountverifiedName
                        ]);
                    }
                },
                err => {
                    this.accountVerifyMessage =
                        "Something went wrong! ," +
                        JSON.stringify(err["error"]["message"]);
                }
            );
    }
}
