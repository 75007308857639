<div class="row mt-4">
  <div class="pl-2 d-flex calendarDiv justify-content-between">
    <!-- <div>Date</div> -->

    <ng-container
      *ngFor="
        let dates of calenderDates.slice(0 + (page - 1) * 7, 7 * page);
        let i = index;
        let first = first;
        let last = last
      "
    >
      <div class="pr-3 nextPrevflex" (click)="nextPrevWeek('P')" *ngIf="first">
        <span class="nextprevImg bg-white" *ngIf="!disablePrevBtn">
          <!-- <img src="/assets/images/jumpree_digital/leftArrowIcon.png" alt="" class="w-50 m-auto"> -->
          <i class="fa fa-chevron-left m-auto cl-blue p-1"></i>
        </span>
      </div>

      <div
        class="col p-3 d-flex"
        [ngClass]="{
          roundedBorderFirstDate: first,
          roundedBorderLastDate: last
        }"
      >
        <div
          class="text-center cursor-pointer d-flex flex-column"
          (click)="loadDateInfo(dates.id, 'MANUAL')"
        >
          <span
            class="grayedOut"
            [ngClass]="[selectedDate == dates.id ? 'highlightDate' : '']"
            >{{ dates.date }}</span
          >
          <span
            class="customWidthColor mt-2"
            [ngClass]="[selectedDate == dates.id ? 'col-blue' : '']"
            >{{ dates.day }}</span
          >
        </div>
      </div>

      <div class="pl-3 nextPrevflex" (click)="nextPrevWeek('N')" *ngIf="last">
        <span class="nextprevImg bg-white" *ngIf="!disableNextBtn">
          <!-- <img src="/assets/images/jumpree_digital/rightArrowIcon.png" alt="" class="w-50 m-auto"> -->
          <i class="fa fa-chevron-right m-auto cl-blue p-1"></i>
        </span>
      </div>
    </ng-container>
  </div>
</div>
