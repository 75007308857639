/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-action-icons-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../print-pdf/print-pdf.component.ngfactory";
import * as i3 from "../../print-pdf/print-pdf.component";
import * as i4 from "ngx-toastr";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../print-pdf/print-pdf.service";
import * as i7 from "../../../../shared/modules/loader/loader.service";
import * as i8 from "@angular/common";
import * as i9 from "./map-action-icons-modal.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../map-action-icons.service";
var styles_MapActionIconsModalComponent = [i0.styles];
var RenderType_MapActionIconsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapActionIconsModalComponent, data: {} });
export { RenderType_MapActionIconsModalComponent as RenderType_MapActionIconsModalComponent };
function View_MapActionIconsModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "print-pdf", [], null, [[null, "closePopup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closePopup" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PrintPdfComponent_0, i2.RenderType_PrintPdfComponent)), i1.ɵdid(2, 245760, null, 0, i3.PrintPdfComponent, [i4.ToastrService, i5.TranslateService, i6.PrientPdfService, i7.LoaderService], { currentZone: [0, "currentZone"], view: [1, "view"], floorId: [2, "floorId"], gecId: [3, "gecId"], showCircle: [4, "showCircle"], dayTime: [5, "dayTime"], timestamp: [6, "timestamp"] }, { closePopup: "closePopup" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentZone; var currVal_1 = _co.pdfView; var currVal_2 = _co.floorId; var currVal_3 = _co.gecId; var currVal_4 = _co.showCircle; var currVal_5 = _co.dayTime; var currVal_6 = _co.timestamp; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_MapActionIconsModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapActionIconsModalComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pageType === "print-map"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MapActionIconsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "map-action-icons-modal", [], null, null, null, View_MapActionIconsModalComponent_0, RenderType_MapActionIconsModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.MapActionIconsModalComponent, [i10.MatDialogRef, i4.ToastrService, i5.TranslateService, i11.MapActionIconsService, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapActionIconsModalComponentNgFactory = i1.ɵccf("map-action-icons-modal", i9.MapActionIconsModalComponent, View_MapActionIconsModalComponent_Host_0, {}, {}, []);
export { MapActionIconsModalComponentNgFactory as MapActionIconsModalComponentNgFactory };
