<!-- Manage booking for flexible or Permanent -->
<div class="" *ngIf="pageType == 'bookNowManageBooking'">
    <div class="modal-header pl-2">
        <h5>{{'bookNow' | translate}}</h5>
        <!-- <h6 class="dark-blue"><strong>{{'bookNow' | translate}}</strong></h6> -->
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 mt-3">
        <p class="dark-blue ml-3">{{'bookingType' | translate}}</p>
    </div>
    <div class="modal-body width-400 align-left">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group mt-0"
            [(ngModel)]="manageBookingFOrP">
            <mat-radio-button class="example-radio-button" value="flexible">{{'flexible' | translate}}
            </mat-radio-button>
            <p class="ml-2 small" style="color: grey;">{{'flexibleNoteContent' | translate}} <span *ngIf="visitorBookingEnabled">
            {{'visitorFlexible' | translate}}
            </span></p>

            <div *ngIf="manageBookingFOrP === 'flexible' && visitorBookingEnabled">
                <mat-radio-group aria-labelledby="nested-radio-group-label" class="nested-radio-group mt-0 ml-3"
                    [(ngModel)]="manageBookingForFlexible" (change)="updateManageBookingForFlexible($event.value)">
                    <mat-radio-button class="example-radio-button" value="internal"><span *ngIf="entityCategoryName" class="regular-font">{{entityCategoryName}}</span> {{'deskEmployee' | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" value="visitor"><span *ngIf="entityCategoryName" class="regular-font">{{entityCategoryName}}</span> {{'deskExternalVisitor' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        
            <ng-container *ngIf="amenityViewType !== 'LIST'">
                <mat-radio-button class="example-radio-button" value="permanent">{{'permanent' | translate}}
                </mat-radio-button>
                <p class="ml-2 small" style="color: grey;">{{'PermananetNoteContent' | translate}}</p>
            </ng-container>
        </mat-radio-group>
        <ng-container *ngIf="manageBookingFOrP == 'permanent'">
            <div class="row ml-0">
                <div class="col-md-6 pl-0">
                    <img height="50" width="auto" *ngIf="searchOption != 'singleUser'"
                        class="block-img ml-auto imageSize" src="./assets/images/search_white.png"
                        (click)="searchOption = 'singleUser'" />
                    <img height="50" width="auto" class="block-img ml-auto imageSize"
                        src="./assets/images/search_blue.png" *ngIf="searchOption == 'singleUser'" />
                    <span class="dark-blue">{{"singleuser" | translate}}</span>
                </div>
                <!-- Uncomment when bulk upload doing -->
                <div class="col-md-6 pl-0">
                    <img height="50" width="auto" class="block-img ml-auto imageSize"
                        src="./assets/images/upload_white.png" (click)="searchOption = 'bulkUpload'"
                        *ngIf="searchOption !== 'bulkUpload'" />
                    <img height="50" width="auto" class="block-img ml-auto imageSize"
                        src="./assets/images/upload_blue.png" (click)="searchOption = 'bulkUpload'"
                        *ngIf="searchOption == 'bulkUpload'" />
                    <span class="dark-blue">{{"bulkupload" | translate}}</span>
                </div>
            </div>
            <ng-container *ngIf="searchOption == 'singleUser'">
                <div class="row">
                    <div class="col-7">
                        <label for="name">{{ 'searchEmployeeName' | translate | uppercase}}</label>
                        <input type="text" id="name" name="name" [(ngModel)]="eName" #name="ngModel" autocomplete="off"
                            class="form-control" placeholder="{{'employeeName' | translate}}"
                            (input)="getEmployeeNameAutocompleteList(name.value)"
                            [ngClass]="{'is-invalid': (name.invalid && name.touched)}" required
                            [matAutocomplete]="auto">
                        <i class="fa fa-spinner loading_span" *ngIf="nameSearchIsLoading" aria-hidden="true"></i>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let dropData of nameAutocompleteList$ | async"
                                [value]="dropData.name.trim()" (click)="onselectNameData(dropData)">
                                <span>{{dropData?.name}}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <span class="invalid-feedback"
                            *ngIf="name.invalid && name.touched">{{'pleaseEnterValidEmployeeName'
                            | translate}}
                        </span>
                    </div>
                </div>
                <ng-container *ngIf="!!searchedUserId">
                    <div class="row">
                        <div class="col-7">
                            <label for="empId">{{ 'employeeCode' | translate | uppercase}}</label>
                            <input type="text" name="empId" id="empId" class="form-control" [(ngModel)]="empId"
                                readonly>
                        </div>
                        <div class="col-5">
                            <label for="departmentName">{{ 'departmentName' | translate | uppercase }}</label>
                            <input type="text" name="departmentName" id="departmentName" class="form-control"
                                [(ngModel)]="departmentName" readonly>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <button type="button" class="btn btn-main float-right rounded-pill" (click)="proceed()" [disabled]="!proceedbtn">
                                {{'proceed' | translate }}
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="searchOption == 'bulkUpload'">
                <div class="row mt-1">
                    <div class="col-md-6 offset-md-6 form-group">
                        <button type="button" class="form-control1" (click)="downloadTemplate()">
                            {{'downloadTemplate' | translate}}
                            <span class="ml-3"><img src="./assets/images/downloadTemplate.png"
                                    style="width: 1rem;" /></span>
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="templateDownloaded">
                <!-- <ng-container> -->
                    <div class="row">
                        <div class="col-md-6 offset-md-6">
                            <label for="uploadFile" class="label1">{{ "upload" | translate }} (.csv
                                {{ "file" | translate }})</label>
                            <div class="input-group">
                                <input type="file" class="form-control custom-file-input" id="importFile"
                                    aria-describedby="inputGroupFileAddon01" accept=".csv"
                                    (change)="uploadCSV($event.target.files)" />
                                <label class="custom-file-label1 custom-upload-btn" for="inputGroupFile01"
                                    #labelImport></label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row mt-1">
                        <div class="col-md-6 offset-md-6">
                            <ul *ngFor="let file of uploadedFileList;let idx='index' "
                                style="list-style-type:none;margin-left: -40px;">
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <li>
                                            <span class="uploadFileChips">
                                                <span>
                                                    {{file.name | slice: 0:20}}
                                                    <span *ngIf="file.name.length > 20">
                                                        ...
                                                    </span>
                                                </span>
                                                <span class="pl-4">
                                                    <a href="javascript:void(0)" (click)="deleteArr(idx)">
                                                        <i class="fa" style="color: #504e4eeb; font-size: 16px;">
                                                            &#10006;</i>
                                                    </a>
                                                </span>
                                            </span>
                                        </li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div> -->
                    <div class="row mt-4">
                        <div class="col">
                            <button type="button" class="btn btn-main float-right pl-3 pr-3 rounded-pill"
                                (click)="proceedUploadFile()">
                                {{'proceed' | translate }}
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="(!visitorBookingEnabled && manageBookingFOrP == 'flexible') ||  (visitorBookingEnabled && manageBookingForFlexible == 'internal')">
            <div class="row mt-2">
                <div class="col">
                    <button type="button" class="btn btn-main float-right rounded-pill" (click)="proceed()">
                        {{'proceed' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="visitorBookingEnabled && manageBookingForFlexible == 'visitor'">
            <div class="row mt-2">
                <div class="col">
                    <button type="button" class="btn btn-main float-right rounded-pill" (click)="proceedVisitor()">
                        {{'proceed' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="pageType == 'bookNowManageVisitor'">
    <div class="modal-header pl-1">
        <h5>{{'bookNow' | translate}}</h5>
        <!-- <h6 class="dark-blue"><strong>{{'reuploadFile' | translate}}</strong></h6> -->
        <button type="button" class="close" aria-label="Close" (click)="closeVisitorManagePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="width-400">
        <div class="col-12 mt-1">
            <p class="font-italic font-weight-light regular-font pl-2">{{'booking' | translate}}</p>
          </div>

          

          <div class="visitor-modal-body modal-body">
            <p class="font-weight-bold regular-font ">{{'visitorText' | translate}}</p>
            
            <ng-container>
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="hname"
                            > HOST USER NAME
                            <span class="text-danger">&nbsp;*</span>
                          </label>
                          <input
                             id="hname"
                             name="hname"
                             type="text"
                             class="form-control blue-regular"
                             placeholder="Search User Name"
                             [(ngModel)]="eName"
                             #name="ngModel"
                             autocomplete="off"
                             (input)="getEmployeeNameAutocompleteList(name.value)"
                             [matAutocomplete]="auto"
                             required
                          >
                          <i class="fa fa-spinner fa-spin loading_span" *ngIf="nameSearchIsLoading" aria-hidden="true"></i>
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let dropData of nameAutocompleteList$ | async"
                                [value]="dropData?.id" (click)="onselectNameData(dropData)">
                                <span>{{dropData?.name}}</span>
                            </mat-option>
                          </mat-autocomplete>
                          <small class="text-danger"
                          *ngIf="!validSelection && !nameSearchIsLoading && name.touched && name.value">Enter Valid Host Name
                         </small>
                          <div *ngIf="name.invalid && (name.dirty || name.touched) && !name.value">
                            <div *ngIf="name.errors.required" class="text-danger">
                              <small>Host User Name is required.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                </form>
                
                <form [formGroup]="visitorInformationForm" (ngSubmit)="onFormSubmit()">
                    <ng-container>
                        <div class="form-row">
                            <ng-container *ngFor="let field of visitorParams">
                                <div class="form-group col-md-6" *ngIf="field && (field.name == 'First Name of Visitor' || field.name == 'Last Name of Visitor')">
                                    <label>{{ field.name }}
                                        <span class="text-danger" *ngIf="field.isMandatory">&nbsp;*</span>
                                        </label>
                                        <input 
                                         matInput 
                                         [placeholder]="getPlaceholder(field.name)"
                                         class="form-control blue-regular dynamicField" 
                                         [formControlName]="sanitizeName(field.name)" 
                                         maxlength="50"
                                         (input)="onBlur(field.name)"
                                         [type]="field.name === 'Email of Visitor' ? 'email' : 'text'"
                                       >
                                       <div *ngIf="visitorInformationForm.controls[sanitizeName(field.name)].invalid && visitorInformationForm.controls[sanitizeName(field.name)].touched">
                                        <small class="text-danger" *ngIf="visitorInformationForm.controls[sanitizeName(field.name)].hasError('required')">{{field.name}} is required</small>
                                      </div>
                                       <div *ngIf="field.name === 'Email of Visitor' && visitorInformationForm.controls[sanitizeName(field.name)].invalid && visitorInformationForm.controls[sanitizeName(field.name)].touched">
                                        <small class="text-danger" *ngIf="visitorInformationForm.controls[sanitizeName(field.name)].hasError('email')">Invalid email address</small>
                                      </div>
                                </div>
                            </ng-container>
                        </div>
                          <ng-container *ngFor="let field of visitorParams">
                            <div class="form-row" *ngIf="field.name !== 'Tag to Department' && field.name !== 'First Name of Visitor' && field.name !== 'Last Name of Visitor'">
                                <div class="form-group col-md-6">
                                    <label>{{ field.name }}
                                    <span class="text-danger" *ngIf="field.isMandatory">&nbsp;*</span>
                                    </label>
                                    <input 
                                     matInput 
                                     [placeholder]="getPlaceholder(field.name)"
                                     class="form-control blue-regular dynamicField" 
                                     [formControlName]="sanitizeName(field.name)" 
                                     [maxlength]="field.name === 'Email of Visitor' ? 254 : 50"
                                     (blur) = "onBlur(field.name)"
                                     [type]="field.name === 'Email of Visitor' ? 'email' : 'text'"
                                   >
                                   <div *ngIf="visitorInformationForm.controls[sanitizeName(field.name)].invalid && visitorInformationForm.controls[sanitizeName(field.name)].touched">
                                    <small class="text-danger" *ngIf="visitorInformationForm.controls[sanitizeName(field.name)].hasError('required')">{{field.name}} is required</small>
                                  </div>
                                   <div *ngIf="field.name === 'Email of Visitor' && visitorInformationForm.controls[sanitizeName(field.name)].invalid && visitorInformationForm.controls[sanitizeName(field.name)].touched">
                                    <small class="text-danger" *ngIf="visitorInformationForm.controls[sanitizeName(field.name)].hasError('email')">Invalid email address</small>
                                  </div>
                                </div>
                              </div>
                              <div class="form-row" *ngIf="field.name === 'Tag to Department'">
                                <div class="form-group col-md-6 pl-1">
                                    <label>{{ field.name }}
                                    <span class="text-danger" *ngIf="field.isMandatory">&nbsp;*</span>
                                    <img
                                    class="ml-2 mb-2"
                                    mat-button
                                    matTooltipClass="custom-tooltip"
                                    matTooltipPosition="right"
                                    [matTooltip]="getToolTipData('Tagging visitors to departments will show all flexible desks mapped to departments or those unmapped to deparments. Not tagging visitors will only display unmapped flexible desks.')"
                                    src="../../../../assets/images/event/info.png"
                                  />
                                </label>
                                <mat-form-field  appearance="fill" style="display: block">
                                    <mat-select    
                                    [(ngModel)]="department"
                                    [placeholder]="getPlaceholder(field.name)"
                                    [formControlName]="sanitizeName(field.name)"
                                    >
                                     
                                    <mat-option *ngFor="let department of departmentListVal; let i = index" [value]="department.id">{{ department.name }}</mat-option>
                                    </mat-select>
                                 
                                </mat-form-field>
                                <div *ngIf="visitorInformationForm.controls[sanitizeName(field.name)].invalid && visitorInformationForm.controls[sanitizeName(field.name)].touched">
                                    <small class="text-danger" *ngIf="visitorInformationForm.controls[sanitizeName(field.name)].hasError('required')">{{field.name}} is required</small>
                                  </div>
                                </div>
                             
                              </div>
                          </ng-container>
                    </ng-container>
                    <div class="form-row">
                        <div class="col-12">
                          <button
                            type="submit"
                            class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                            [hidden]="visitorInformationForm.invalid || !validSelection"
                          >
                          {{'proceed' | translate }}
                          </button>
                        </div>
                      </div>

                </form>
            </ng-container>
          </div>
    </div>
 
</div>

<div *ngIf="pageType == 'emailAlert'">
    <div class="modal-header pl-1">
        <h5>Alert</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeUploadFixesPopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>  
    <div class="email-alert width-400">
        <p class="mt-2 regular-font">{{'emailAlertText' | translate}} </p>
        <p class="mt-2 regular-font">{{'emailAlertSubText' | translate}} </p>
    </div>   
    <div class="d-flex justify-content-end my-3 mx-4">
        <button type="button"  class="btn btn-main btno rounded-pill mr-3" (click)="backToVisitorPopup()">
            {{'no' | translate}}
        </button>
        <button type="button" class="btn btn-main btnyes btn-primary rounded-pill " (click)="sendVisitorInfoWithoutEmail()">
            {{'yes' | translate}}
        </button>
    </div>

</div>

<div class="" *ngIf="pageType == 'uploadCsvWithFixes'">
    <div class="modal-header pl-2">
        <h5>{{'reuploadFile' | translate}}</h5>
        <!-- <h6 class="dark-blue"><strong>{{'reuploadFile' | translate}}</strong></h6> -->
        <button type="button" class="close" aria-label="Close" (click)="closeUploadFixesPopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ng-container>
        <div class="col-12 mt-3 pl-4">
            <button
                id="download_btn"
                mat-mini-fab
                class="btn btn-main mat-mini-fab bgColor"
                (click)="downloadCurrentFile()">
                <img src="./assets/images/save.svg" alt="" />
            </button>
            <span class="pl-3 regular-blue" style="font-size: 14px;">{{'downloadCurrentFile' | translate}}</span>
        </div>
    </ng-container>
    <div class="col-12 mt-5">
        <p class="dark-blue ml-2 mb-0">{{'uploadFileWithFixes' | translate}}</p>
    </div>
    <div class="modal-body width-400 align-left">
        <ng-container>
            <div class="row">
                <div class="ml-3">
                    <label for="uploadFile" class="label1">{{ "upload" | translate }} (.csv
                        {{ "file" | translate }})</label>
                    <div class="input-group">
                        <input type="file" class="form-control custom-file-input" id="importFile"
                            aria-describedby="inputGroupFileAddon02" accept=".csv"
                            (change)="uploadCSV($event.target.files)" />
                        <label class="custom-file-label1 custom-upload-btn" for="inputGroupFile02"
                            #labelImport></label>
                    </div>
                </div>
            </div>
            <!-- <div class="row mt-1">
                <div class="ml-3">
                    <ul *ngFor="let file of uploadedFileList;let idx='index' "
                        style="list-style-type:none;margin-left: -40px;">
                        <div class="row mt-3">
                            <div class="col-12">
                                <li>
                                    <span class="uploadFileChips">
                                        <span>
                                            {{file.name | slice: 0:20}}
                                            <span *ngIf="file.name.length > 20">
                                                ...
                                            </span>
                                        </span>
                                        <span class="pl-4">
                                            <a href="javascript:void(0)" (click)="deleteArr(idx)">
                                                <i class="fa" style="color: #504e4eeb; font-size: 16px;">
                                                    &#10006;</i>
                                            </a>
                                        </span>
                                    </span>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div> -->
            <div class="row mt-5">
                <div class="col">
                    <button type="button" class="btn btn-main pl-3 pr-3 float-right rounded-pill"
                        (click)="proceedUploadFile()">
                        {{'proceed' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- Booking via map Start Date Selection -->
<div *ngIf="pageType == 'bookViaMapDateSelection'">
    <div class="modal-header pl-0">
        <h5>{{'bookingConfirmation' | translate}}</h5>
        <!-- <h6 style="font-family: 'OpenSans-SemiBold';">{{'bookingConfirmation' | translate}}</h6> -->
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body width-400 align-left">
        <p> <span style="font-family: 'Open Sans';">{{'permanentlyAllocateDesk' | translate}}</span>
            <span style="font-family: 'OpenSans-SemiBold';">{{mapDateSelectionData['amenitySeat']}}</span>
            <span style="font-family: 'Open Sans';">{{'for'| translate}}</span>
            <span style="font-family: 'OpenSans-SemiBold';">{{mapDateSelectionData['username']}}</span>?
        </p>
        <p *ngIf="mapDateSelectionData['currentUser']">
            <span style="font-family: 'OpenSans-SemiBold';">{{mapDateSelectionData['currentUser']['name']}}</span>
            <span style="font-family: 'Open Sans';">{{'permanentAllocationsWillBe'  | translate}}</span>
            <span style="font-family: 'OpenSans-SemiBold';">{{'cancelled' | translate}}.</span>
        </p>
        <p *ngIf="!mapDateSelectionData['currentUser']">
            <span style="font-family: 'Open Sans';">{{'allTheFlexibleBookingsForDesk'| translate}}</span>
            <span style="font-family: 'OpenSans-SemiBold';">{{mapDateSelectionData['amenitySeat']}}</span>
            <span style="font-family: 'Open Sans';">{{'willBe'| translate}}</span>
            <span style="font-family: 'OpenSans-SemiBold';">{{'cancelled' | translate}}</span>
            <span style="font-family: 'Open Sans';">{{'andUsersWillBeNotified' | translate}}</span>
        </p>
        <div class="row mt-4">
            <div class="col-7 form-group">
                <div class="small font-fold">{{'selectStartDateForBooking' | translate}}</div>
                <label for="startDate">{{'date' | translate | uppercase}}</label>
                <div class="input-group">
                    <input placeholder="{{ startDate }}" class="form-control" name="startDate" [(ngModel)]="startDate"
                        [owlDateTimeTrigger]="startDate1" [owlDateTime]="startDate1" />
                    <span [owlDateTimeTrigger]="startDate1"><i class="fa fa-calendar fa-calender-icons"></i></span>
                    <owl-date-time [pickerType]="'calendar'" #startDate1>
                    </owl-date-time>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <button type="button" class="btn btn-main float-right rounded-pill" (click)="proceedDateSelection()">
                    {{'proceed' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Booking via map Start Date Selection -->
<div *ngIf="pageType == 'viewParmenentDetails'">
    <div class="modal-header">
        <h5>{{'details' | translate}}</h5>
        <!-- <h6 class="dark-blue"><strong>{{'details' | translate}}</strong></h6> -->
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body width-400 align-left">
        <h6 style="color: #0F1F54 !important; font-family: 'OpenSans-SemiBold';">{{'Desk No. 203'}}</h6>
        <p style="color: #0F1F54 !important; font-family: 'OpenSans-SemiBold';">{{'Booking ID'}}</p>
        <p style="color: #0F1F54 !important; font-family: 'OpenSans';">{{'B1256099'}}</p>

        <div class="row mt-2">
            <div class="col">
                <button type="button" class="btn btn-main float-right rounded-pill"
                    (click)="doneViewPermanentBooking()">
                    {{'done' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'viewParmenentDetailsflexi'">
    <div class="modal-header  width-400">
        <h4 class="dark-blue" style="margin-left: -9px;">{{ 'details' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left ">
        <br />
        <div class="spanFontSizeLarge">{{data?.dataArray?.amenityName}}</div>
        <span class="spanFontSizeSmall">{{data?.dataArray?.entityInfo?.displayName}}</span>
        <br /> <br />
        <div class="spanFontSizeLarge"> {{ 'bookingID' | translate }}</div>
        <span class="spanFontSizeSmall"> {{data?.dataArray?.bookingId}}</span>
        <br /><br />
        <div class="spanFontSizeLarge  mb-15"> {{data?.dataArray?.enableParticipants ? ('organiser'| translate) : ('name' | translate)}}</div>
        <span class="spanFontSizeSmall" *ngIf="data?.dataArray?.createdByUser"><span
                class='legendCircle'>{{getUserLegendName(data.dataArray)}}</span> {{data.dataArray.name}} </span>
        <span class="spanFontSizeLarge"  *ngIf="!data?.dataArray?.createdByUser ">
            <img *ngIf="data?.dataArray?.userImage" class="roundCircleLarge" [src]="data?.dataArray?.userImage" />
            <span *ngIf="data?.dataArray?.userImage == null"
                class='legendCircle'>{{getUserLegendName(data.dataArray)}}</span>
            {{data.dataArray.name}}
        </span>
        <br /> <br />
        <!-- Category -->
        <div class="spanFontSizeLarge"> {{'category' | translate}}</div>
        <span class="spanFontSizeSmall"> {{getCategoryName(data?.dataArray?.entityInfo?.entityType?.name)}}</span>

        <div class="spanFontSizeLarge mt-4"> {{ 'bookingType' | translate }}</div>
        <span class="spanFontSizeSmall"> {{ 'flexible' | translate }}</span>
        <br /><br />
        <div class="spanFontSizeLarge"> {{ 'when' | translate }}</div>
        <span class="spanFontSizeSmall"> {{data?.dataArray?.requestDetails?.startTime | date: 'dd MMM yyyy':'UTC'
            }}</span>
        <br /> <br />
        <div class="spanFontSizeLarge"> {{ 'time' | translate }}</div>
        <span class="spanFontSizeSmall"> {{getBookTime(data.dataArray,"both")}}</span>

        <div class="spanFontSizeLarge mt-3"> {{'checkedIn' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data.dataArray.checkinTime"> {{data?.dataArray?.checkinTime | date :'h:mm a'}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data.dataArray.checkinTime"> {{'na' | translate}}</span>

        <div class="spanFontSizeLarge mt-3"> {{'checkedOut' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data.dataArray.checkoutTime"> {{data?.dataArray?.checkoutTime | date :'h:mm a'}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data.dataArray.checkoutTime"> {{'na' | translate}}</span>

        <div class="spanFontSizeLarge mt-3"> {{'Location' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data?.dataArray?.zoneLocationDto && data?.dataArray?.zoneLocationDto != 'NA'"> 
            {{data?.dataArray?.zoneLocationDto?.name}},{{data?.dataArray?.zoneLocationDto?.building?.name}}, {{data?.dataArray?.zoneLocationDto?.site?.name}}, {{data?.dataArray?.zoneLocationDto?.city?.name}}, {{data?.dataArray?.zoneLocationDto?.state?.name}}, {{data?.dataArray?.zoneLocationDto?.country?.name}}</span>
        <span class="spanFontSizeSmall" *ngIf="data?.dataArray?.zoneLocationDto && data?.dataArray?.zoneLocationDto == 'NA'"> {{'na' | translate}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data?.dataArray?.zoneLocationDto"> {{'na' | translate}}</span>
        <br /> <br />
        <div class="spanFontSizeLarge"> {{'specialrequests' | translate}} </div>
        <span class="spanFontSizeSmall"> {{data?.dataArray?.amenityArr}}</span>
        <br /> <br />


    </div>

    <div class="modal-body width-400 align-left">
        <div class="form-group col-12 mb-0">

            <button class="btn btn-primary btn-main btn-radius mr-2 float-right prl-5" type="button"
                (click)="closePopup()">
                {{'done' | translate}}
            </button>

        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'viewParmenentDetailsflexiVisitor'">
    <div class="modal-header  width-400">
        <h4 class="dark-blue" style="margin-left: -9px;">Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left ">
        <br />
        <div class="spanFontSizeLarge">{{data?.dataArray?.amenityName}}</div>
        <span class="spanFontSizeSmall">{{data?.dataArray?.entityInfo?.displayName}}</span>
        <br /> <br />
        <div class="spanFontSizeLarge"> {{ 'bookingID' | translate }}</div>
        <span class="spanFontSizeSmall"> {{data?.dataArray?.bookingId}}</span>
        <br /><br />
        <div class="spanFontSizeLarge  mb-15"> {{data?.dataArray?.enableParticipants ? ('organiser'| translate) : ('name' | translate)}}</div>
        <div class="profile-circle" *ngIf="data?.dataArray?.createdByUser">
            <span class="legendCircleNew">{{getUserLegendName(data.dataArray)}}</span>
            <span class="userName">{{data.dataArray.name}} </span> 
        </div>
        <span class="spanFontSizeLarge"  *ngIf="!data?.dataArray?.createdByUser ">
            <img *ngIf="data?.dataArray?.userImage" class="roundCircleLarge" [src]="data?.dataArray?.userImage" />
            <span *ngIf="data?.dataArray?.userImage == null"
                class='legendCircle'>{{getUserLegendName(data.dataArray)}}</span>
            {{data.dataArray.name}}
        </span>
        <br /> 
        <!--Visitor Info-->
        <div>
            <div class="spanFontSizeLarge mb-2">Visitor Name</div>
            <div class="spanFontSizeSmall" *ngIf="data?.dataArray?.visitorName">{{data?.dataArray?.visitorName}}</div>
        </div>
        <div *ngFor="let data of visitorDataBooked">
            <div *ngIf="data.name !== 'First Name of Visitor' && data.name !== 'Last Name of Visitor' && data.name !== 'Tag to Department'">
                <div class="spanFontSizeLarge my-3">{{data.name}}</div>
                <div class="spanFontSizeSmall">{{data.value || 'NA'}}</div>
            </div>
            <div *ngIf="data.name === 'Tag to Department'">
                <div class="spanFontSizeLarge my-3">{{data.name}}</div>
                <div class="spanFontSizeSmall">{{getDeptName(data.value) || 'NA'}}</div>

            </div>
      

        </div> 
        <!-- Category -->
        <div class="spanFontSizeLarge"> {{'category' | translate}}</div>
        <span class="spanFontSizeSmall"> {{getCategoryName(data?.dataArray?.entityInfo?.entityType?.name)}}</span>

        <div class="spanFontSizeLarge mt-4"> {{ 'bookingType' | translate }}</div>
        <span class="spanFontSizeSmall"> Flexible Visitors</span>
        <br /><br />
        <div class="spanFontSizeLarge"> {{ 'when' | translate }}</div>
        <span class="spanFontSizeSmall"> {{data?.dataArray?.requestDetails?.startTime | date: 'dd MMM yyyy':'UTC'
            }}</span>
        <br /> <br />
        <div class="spanFontSizeLarge"> {{ 'time' | translate }}</div>
        <span class="spanFontSizeSmall"> {{getBookTime(data.dataArray,"both")}}</span>

        <div class="spanFontSizeLarge mt-3"> {{'checkedIn' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data.dataArray.checkinTime"> {{data?.dataArray?.checkinTime | date :'h:mm a'}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data.dataArray.checkinTime"> {{'na' | translate}}</span>

        <div class="spanFontSizeLarge mt-3"> {{'checkedOut' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data.dataArray.checkoutTime"> {{data?.dataArray?.checkoutTime | date :'h:mm a'}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data.dataArray.checkoutTime"> {{'na' | translate}}</span>

        <div class="spanFontSizeLarge mt-3"> {{'Location' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data?.dataArray?.zoneLocationDto && data?.dataArray?.zoneLocationDto != 'NA'"> 
            {{data?.dataArray?.zoneLocationDto?.name}},{{data?.dataArray?.zoneLocationDto?.building?.name}}, {{data?.dataArray?.zoneLocationDto?.site?.name}}, {{data?.dataArray?.zoneLocationDto?.city?.name}}, {{data?.dataArray?.zoneLocationDto?.state?.name}}, {{data?.dataArray?.zoneLocationDto?.country?.name}}</span>
        <span class="spanFontSizeSmall" *ngIf="data?.dataArray?.zoneLocationDto && data?.dataArray?.zoneLocationDto == 'NA'"> {{'na' | translate}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data?.dataArray?.zoneLocationDto"> {{'na' | translate}}</span>
        <br /> <br />
        <div class="spanFontSizeLarge"> {{'specialrequests' | translate}} </div>
        <span class="spanFontSizeSmall"> {{data?.dataArray?.amenityArr}}</span>
        <br /> <br />


    </div>

    <div class="modal-body width-400 align-left">
        <div class="form-group col-12 mb-0">

            <button class="btn btn-primary btn-main btn-radius mr-2 float-right prl-5" type="button"
                (click)="closePopup()">
                {{'done' | translate}}
            </button>

        </div>
    </div>
</div>


<div class="" *ngIf="pageType == 'viewParmenentDetailspermanent'">
    <div class="modal-header  width-400">
        <h4 class="dark-blue" style="margin-left: -9px;">{{ 'details' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left ">
        <br />
        <div class="spanFontSizeLarge"> {{data?.data?.entityChildType?.entityTypeId?.globalEntityCategory?.entityCategoryName}} </div>
        <span class="spanFontSizeSmall"> {{data?.data?.entityInfo?.displayName}}</span>
        <br /> <br />
        <div class="spanFontSizeLarge"> {{ 'bookingID' | translate }}</div>
        <span class="spanFontSizeSmall"> {{data?.data?.bookingId}}</span>
        <br /><br />
        <div class="spanFontSizeLarge  mb-15"> {{data?.data?.enableParticipants ? 'Organiser' : ('name' | translate)}}</div>
        <span class="spanFontSizeSmall"><span class='legendCircle'>{{getUserLegendName(data.data.user)}}</span>
            {{data.data.user.name}} </span>

        <!-- Category -->
        <div class="spanFontSizeLarge mt-4"> {{'category' | translate}}</div>
        <span class="spanFontSizeSmall"> {{getCategoryName(data?.data?.entityInfo?.entityType?.name)}}</span>

        <div class="spanFontSizeLarge mt-4"> {{ 'bookingType' | translate }}</div>
        <span class="spanFontSizeSmall"> {{ 'permanent' | translate }}</span>
        <br /><br />
        <div class="spanFontSizeLarge"> {{ 'when' | translate }}</div>
        <span class="spanFontSizeSmall"> {{data?.whenDate | date: 'dd MMM yyyy':'UTC' }}</span>
        <br /> <br />
        <div class="spanFontSizeLarge"> {{ 'time' | translate }}</div>
        <span class="spanFontSizeSmall"> {{getBookTime(data.data,"both")}}</span>
        
        <div class="spanFontSizeLarge mt-3"> {{'checkedIn' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data.data.checkinTime"> {{data?.data?.checkinTime | date :'h:mm a'}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data.data.checkinTime"> {{'na' | translate}}</span>

        <div class="spanFontSizeLarge mt-3"> {{'checkedOut' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data.data.checkoutTime"> {{data?.data?.checkoutTime | date :'h:mm a'}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data.data.checkoutTime"> {{'na' | translate}}</span>

        <div class="spanFontSizeLarge mt-3"> {{'Location' | translate}}</div>
        <span class="spanFontSizeSmall" *ngIf="data?.data?.zoneLocationDto && data?.data?.zoneLocationDto != 'NA'"> 
            {{data?.data?.zoneLocationDto?.name}}{{','}}
            {{data?.data?.zoneLocationDto?.address}}
        </span>
        <span class="spanFontSizeSmall" *ngIf="data?.data?.zoneLocationDto && data?.data?.zoneLocationDto == 'NA'"> 
            {{'na' | translate}}</span>
        <span class="spanFontSizeSmall" *ngIf="!data?.data?.zoneLocationDto"> {{'na' | translate}}
        </span>
        <br /> <br />
        <!-- <div class="spanFontSizeLarge"> Special Requests </div>
        <span class="spanFontSizeSmall"> Stationary</span> -->
        <!-- <br /> <br /> -->


    </div>

    <div class="modal-body width-400 align-left">
        <div class="form-group col-12 mb-0">

            <button class="btn btn-primary btn-main btn-radius mr-2 float-right prl-5" type="button"
                (click)="closePopup()">
                {{'done' | translate}}
            </button>

        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'incorrectEntries'">
    <div class="modal-header pl-0">
        <h5> Alert</h5>
        <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!-- <div class="col-12 pl-4">
        <p class="italic openSans">{{'upload' | translate}}</p>
    </div> -->
    <div class="col-12 pl-4 pt-4">
        <p class="openSans">
            {{'belowUsersWillNotBeOnboarded' | translate}}
        </p>
    </div>
    <div class="modal-body width-400 ">
        <div class="form-group col-12 mb-0 pr-0 pl-0">
            <!-- <div class="col-12 mb-4"> -->
                <table mat-table [dataSource]="incorrectData" class="mb-4" >
                    <!-- Sl No -->
                    <ng-container matColumnDef="slNo">
                        <th mat-header-cell *matHeaderCellDef class="align-left">{{'SlNo' | translate}}</th>
                        <td mat-cell *matCellDef="let element; let i = dataIndex" class="align-left">{{ element.slNo }}</td>
                    </ng-container>
            
                    <!-- Emp Email Column -->
                    <ng-container matColumnDef="empEmail">
                        <th mat-header-cell *matHeaderCellDef class="align-left">{{'employeeEmail' | translate}}</th>
                        <td mat-cell *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect':
                                element.errorFields.includes('Employee Email')
                            }"
                            [matTooltip]="getToolTipData(element.empEmail)"
                        >
                            {{ element.empEmail | slice: 0:20 }}
                            <span *ngIf="element.empEmail.length > 20">...</span>
                        </td>
                    </ng-container>
            
                    <!-- Entity Name Column -->
                    <ng-container matColumnDef="entityName">
                        <th mat-header-cell *matHeaderCellDef class="align-left">{{'entityName' | translate}}</th>
                        <td mat-cell *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect':
                                element.errorFields.includes('Entity Name')
                            }"
                        >
                            {{ element.entityName }}
                        </td>
                    </ng-container>
            
                    <!-- Building Column -->
                    <ng-container matColumnDef="building">
                        <th mat-header-cell *matHeaderCellDef class="align-left">{{'building' | translate}}</th>
                        <td mat-cell *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect':
                                element.errorFields.includes('Building Name')
                            }"
                        >
                            {{ element.building }}
                        </td>
                    </ng-container>
            
                    <!-- Floor Column -->
                    <ng-container matColumnDef="floor">
                        <th mat-header-cell *matHeaderCellDef class="align-left">{{'floor' | translate}}</th>
                        <td mat-cell *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect':
                                element.errorFields.includes('Floor Name')
                            }"
                        >
                            {{ element.floor }}
                        </td>
                    </ng-container>
            
                    <!-- Start Date Column -->
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef class="align-left">{{'startDate' | translate}}</th>
                        <td mat-cell *matCellDef="let element"
                            class="align-left"
                            [ngClass]="{
                                'is-incorrect':
                                element.errorFields.includes('Start Date(DD-MM-YYYY)')
                            }"
                        >
                            {{ element.startDate }}
                        </td>
                    </ng-container>
            
                    <!-- Reason Column -->
                    <ng-container matColumnDef="reason">
                        <th mat-header-cell *matHeaderCellDef class="align-left">{{'reason' | translate}}</th>
                        <td mat-cell *matCellDef="let element"
                            class="align-left"
                            [matTooltip]="getToolTipData(element.reason)">
                            {{element.reason | slice: 0:20}}
                            <span *ngIf="element.reason.length > 20">...</span>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsIncorrectData"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsIncorrectData;"></tr>
                </table>
            <!-- </div> -->
            <button class="btn btn-primary btn-main btnSmall btn-radius btn-custom-border mr-2 float-right font-regular" type="button"
                (click)="proceedToUpload()">
                Yes
            </button>
            <button class="btn btn-White btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="closeConfirmPopup()">
                No
            </button>
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'groupBookingDetails'">
    <div class="modal-header  width-400">
        <h5 class="dark-blue" style="margin-left: -9px;">{{data?.dataArray?.groupTitle}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body width-400" style="margin-top: 28px;">
        <table class="col-12">
            <tr>
                <td class="heading6 col-4" style="text-align: start;">
                    Organizer
                </td>
                <td class="col-8">
                    <div style="display:flex;align-items: center;text-align: center;border: 1px solid linear-gradient(135deg, #FFFFFF 44.5%, #F0F5F9 129%); border-radius: 24px;background: linear-gradient(135deg, #FFFFFF 44.5%, #F0F5F9 129%);">
                        <img [src]="data.dataArray.hostDetails.imageUrl ? data.dataArray.hostDetails.imageUrl :'https://avatar.iran.liara.run/public/boy?username=Ash'" 
                             class="roundCircleLarge" 
                             style="height:30px !important;width: 30px !important;"
                        />
                        <span style="color: #192048; font-size: 14px; font-family: 'Open Sans'; font-weight: 600; line-height: 21px; margin-left: 10px;">
                            {{data.dataArray.hostDetails.username}}
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="heading6 col-4" style="padding-top:8px;text-align: start">
                    Booking Time
                </td>
                <td class="col-8" style="text-align: start;padding-top:8px">
                    <span style="font-size: 14px; font-weight: 400; font-family: 'Open Sans'; color: #5C5D5D; line-height: 19px;">
                        {{convertTo12HrFormat(data.dataArray.recurringStartTime)}} to {{convertTo12HrFormat(data.dataArray.recurringEndTime)}}
                    </span>
                </td>
            </tr>
        </table>
        <table class="col-12 mt-24" >
            <thead>
                <tr>
                    <th class="tableHeading">Date</th>
                    <th class="tableHeading">Location</th>
                    <th class="tableHeading">Participants</th>
                </tr>
            </thead>
            <tbody *ngIf="objectKeys(groupBookingDetails?.bookingDeskDetails).length > 0">
                <ng-container  *ngFor="let item of objectEntries(groupBookingDetails?.bookingDeskDetails)">
                    <tr *ngIf="item[1].zoneName">
                            <td class="tabletd"><span class="tzableColSpan">{{formatDate(item[0])}}</span></td>
                            <td class="tabletd"><span class="tableColSpan">{{item[1].zoneName}}</span></td>
                            <td class="tabletd" >
                                <div [ngStyle]="{'cursor': item[1].allocationCount === 0 ? 'default' : 'pointer'}" style="display: flex;align-items: center;cursor: pointer;" (click)=" item[1].allocationCount !== 0 && showDeskDetails(item)">
                                    <img src="./assets/images/family.png" alt="" class="mr-8" />
                                    <span class="participants" [ngClass]="{'disabled-participants':item[1].allocationCount === 0}">{{item[1].allocationCount}} Desks Booked</span>
                                </div>
                            </td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody *ngIf="objectKeys(groupBookingDetails?.bookingDeskDetails).length === 0">
                <tr>
                    <td colspan="3">
                      <span style ="text-align: center; display: block;">NO Data</span>
                    </td>
                  </tr>
            </tbody>
        </table>
    </div>
    
</div>

<div class="" *ngIf="pageType == 'showDeskDetails'">
    <div class="modal-header width-400" style="padding: 1rem 1rem 0rem 0rem;">
        <div class="text-left" style="margin-right:12px">
            <i class="fa fa-chevron-left cursor-pointer" (click)="goBack()"></i>
        </div>
        <h5 class="dark-blue">{{data?.dataArray?.groupTitle}} on {{dateOn}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body width-400" style="margin-top: 28px;">
        <table class="col-12">
            <tr>
                <td class="heading6 col-4" style="text-align: start;">
                    Organizer
                </td>
                <td class="col-8">
                    <div style="display:flex;align-items: center;text-align: center;border: 1px solid linear-gradient(135deg, #FFFFFF 44.5%, #F0F5F9 129%); border-radius: 24px;background: linear-gradient(135deg, #FFFFFF 44.5%, #F0F5F9 129%);">
                        <img [src]="data.dataArray.hostDetails.imageUrl ? data.dataArray.hostDetails.imageUrl :'https://avatar.iran.liara.run/public/boy?username=Ash'" 
                             class="roundCircleLarge" 
                             style="height:30px !important;width: 30px !important;"
                        />
                        <span style="color: #192048; font-size: 14px; font-family: 'Open Sans'; font-weight: 600; line-height: 21px; margin-left: 10px;">
                            {{data.dataArray.hostDetails.username}}
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="heading6 col-4" style="padding-top:8px;text-align: start">
                    Booking Time
                </td>
                <td class="col-8" style="text-align: start;padding-top:8px">
                    <span class="fs-14 fw-400 ff lh-19" style="color: #5C5D5D;">
                        {{convertTo12HrFormat(data.dataArray.recurringStartTime)}} to {{convertTo12HrFormat(data.dataArray.recurringEndTime)}}
                    </span>
                </td>
            </tr>
            <tr>
                <td class="heading6 col-4" style="padding-top:10px;text-align: start">
                    Location
                </td>
                <td class="col-8" style="text-align: start;padding-top:10px">
                    <span class="fs-14 fw-400 ff lh-19" style="color: #5C5D5D;">
                        {{location}}
                    </span>
                </td>
            </tr>
        </table>
        <table class="col-12 mt-24">
            <tbody>
                <ng-container *ngFor="let item of deskDetailsData">
                <tr *ngIf="item?.deskDetailsWithZoneDto !== null">
                    <td style="text-align: start !important;padding:10px;">
                        <div style="display:flex;align-items: center;text-align: center;border: 1px solid linear-gradient(135deg, #FFFFFF 44.5%, #F0F5F9 129%); border-radius: 24px;background: linear-gradient(135deg, #FFFFFF 44.5%, #F0F5F9 129%);">
                            <div>
                                <img [src]="item.userDTO.imageUrl ?  item.userDTO.imageUrl :'https://avatar.iran.liara.run/public/boy?username=Ash'" class="roundCircleLarge" style="height:24px !important;width: 24px !important;" />
                            </div>
                            <div class="d-flex ml-8">
                                <div class="d-flex align-items-center fs-14 fw-400 ff lh-21" style="color:#5C5D5D;">
                                    {{item.userDTO.username}}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="tabletd">
                        <span class="d-flex align-items-center fs-14 fw-400 ff lh-21" style="color:#5C5D5D;">BOOK - {{item?.deskDetailsWithZoneDto?.allocationId}}</span>
                    </td>
                    <td class="tabletd">
                        <img src="./assets/images/Path 8614.png" alt="" class="mr-8" />
                        <span class="fs-14 fw-400 ff lh-21" style="color:#1F2551;">{{item?.deskDetailsWithZoneDto?.deskName}}</span>
                        <img  *ngIf="item?.deskDetailsWithZoneDto?.groupDesk" src="./assets/images/yellow stars.png" alt="" class="ml-8" />
                    </td>
                    <td class="tabletd">
                        <img (click)="wayFinding(item)" style="cursor: pointer;" *ngIf="item?.deskDetailsWithZoneDto?.wayFindingEnabled"  src="./assets/images/findpath.png" alt="" />
                    </td>
                  </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<div class="" *ngIf="pageType === 'deleteGroupBooking'">
    <div class="modal-header  width-400">
        <h4 class="dark-blue" style="margin-left: -9px;">Alert</h4>
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
        <div>
            <p *ngIf="deleteDates.length == 1" style="font-family: 'Open Sans';font-size: 16px;font-weight: 600;line-height: 21px;color: #1F2551;margin-top: 25px;margin-left: 26px;margin-right: 10px;">You are attempting to cancel the group booking "{{deleteTitle}}" by {{deleteUser}} on {{deleteDates[0]}}. </p>
            <p *ngIf="deleteDates.length > 1" style="font-family: 'Open Sans';font-size: 16px;font-weight: 600;line-height: 21px;color: #1F2551;margin-top: 25px;margin-left: 26px;margin-right: 10px;">You are attempting to cancel the group booking "{{deleteTitle}}" by {{deleteUser}} for the below dates:</p>
            <p *ngIf="deleteDates.length > 1" style="font-family: 'Open Sans';font-size: 16px;font-weight: 600;line-height: 21px;color: #1F2551;margin-top: 25px;margin-left: 26px;margin-right: 10px;" [innerHTML]="deleteDates.slice(0, -1).join(',<br/>') + ' and <br/>' + deleteDates.slice(-1)"></p>
            <p style="font-family: 'Open Sans';font-size: 14px;font-weight: 400;line-height: 21px;color: #1F2551;margin-top: 12px;margin-left: 26px;margin-right: 10px;">Note: All participants will be notified about the cancellation. Cancel a group booking will not automatically release the desks assigned to participants. Each participants has the option to retain their desks on the cancelled dates</p>            
            <p style="font-family: 'Open Sans';font-size: 16px;font-weight: 600;line-height: 21px;color: #1F2551;margin-top: 12px;margin-left: 26px;margin-right: 10px;">Are you sure would like to proceed?</p>
        </div>
        <div class="d-flex justify-content-end my-3 mx-4">
            <button type="button"  class="btn btn-main btno rounded-pill mr-3" (click)="closePopup()">
                {{'no' | translate}}
            </button>
            <button type="button" class="btn btn-main btnyes btn-primary rounded-pill" (click)="deleteGroupBookingConfirm()">
                {{'yes' | translate}}
            </button>
        </div>
</div>

<div class="" *ngIf="pageType === 'showWayFinding'">
    <div class="modal-header width-400" style="padding: 1rem 1rem 1rem 0rem;">
        <div class="text-left" style="margin-top:4px;margin-right:12px">
            <i class="fa fa-chevron-left cursor-pointer" (click)="backToDeskDetails()"></i>
        </div>
        <h4 class="dark-blue">{{data?.dataArray?.groupTitle}} on {{dateOn}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-way-finding 
        [wayFindingEntity]="wayFindingEntity" 
        [globalId]="globalId" 
        [selectedDate]="selectedDate">
    </app-way-finding>
    </div>
    
</div>

<div class="" *ngIf="pageType === 'gridViewFilter'">
    <div class="modal-header pl-1">
        <h5>Filter</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeGridFilter()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body width-400 align-left">
        <div class="row">
            <div class="col-4">
                <label>{{'startTime' | translate}}</label>
                <mat-select 
                    class="form-control" 
                    id="startTime" 
                    [(ngModel)]="timeList.selectedStartTime"
                    (selectionChange)="updateEndTimeList()">
                    <mat-option *ngFor="let item of timeList.startTimeList; let i = index" 
                        [value]="item.displayValue">
                        {{item.displayValue}}
                    </mat-option>
                </mat-select>
            </div>
        
            <div class="col-4">
                <label>{{'endTime' | translate}}</label>
                <mat-select 
                    class="form-control" 
                    id="endTime" 
                    [(ngModel)]="timeList.selectedEndTime">
                    <mat-option *ngFor="let item of timeList.endTimeList; let i = index" 
                        [value]="item.displayValue">
                        {{item.displayValue}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="" style="margin-top:14px">
            <h6>Select Add-On's</h6>
            <div>
                <div class="amenities-list row col-12"  *ngFor="let item of addonsList; let i = index">
                    <div class="form-group">
                        <mat-checkbox class="ml-1" [checked]="item.selected" (change)="updateList($event,i, 'addOn')">
                            <strong class="fontSizeChkBox">{{item.name}}</strong>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <h6>Special Request</h6>
            <div>
                <div class="amenities-list row col-12"  *ngFor="let item of specialRequestsList; let i = index">
                    <div class="form-group">
                        <mat-checkbox class="ml-1"[checked]="item.selected" (change)="updateList($event,i, 'specialRequest')">
                            <strong class="fontSizeChkBox">{{item.name}}</strong>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <h6>Capacity</h6>
            <div>
                <div class="amenities-list row col-12"  *ngFor="let item of capacityList; let i = index">
                    <div class="form-group">
                        <mat-checkbox class="ml-1"[checked]="item.selected" (change)="updateList($event, i, 'capacity')">
                            <strong class="fontSizeChkBox">{{item.value}}</strong>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body width-400 align-left">
        <div class="form-group col-12 mb-0">
            <button class="btn btn-primary btn-main btn-radius mr-2 float-right prl-5" type="button" (click)=" saveGridFilter()">
                {{'save' | translate}}
            </button>

        </div>
    </div>
</div>

<div class="" *ngIf="pageType === 'gridViewBooking'">
    <div class="modal-header pl-1">
        <h5>Booking</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeGridBooking()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body width-400 align-left">
        <div style="display:flex">
            <div class="left col-6" style="padding-left: 0px;" >

                <!-- Title -->
                <ng-container *ngIf="allowParticipants" style="margin-top: 24px;">
                    <p class="spanFontSizeLarge mb-0" style="margin-left:13px!important;margin-top:8px">Title<span style="color:red">*</span></p>
                    <input type="text" class="form-control inputTitle"id="title_id" [(ngModel)]="titleName"
                        placeholder="{{'enterTitle' | translate}}" maxlength="250" name="title" style="padding-left: 0.5px; margin-left: 15px;font-size: 14px !important; width:80%; margin-top:4px">
                </ng-container>

                <!-- Amenity Details building floor -->
                <div>
                    <p class="text-left spanFontSizeLarge ml-3 mb-0 " style="margin-bottom: 7px !important;margin-top:24px !important" >
                        {{ gridViewBookingRoom}}
                    </p>
                    <p class="text-left ml-3 mb-1 spanFontSizeSmall">
                        {{ gridViewBookingBuilding}}, {{ gridViewBookingFloor}}
                    </p>
                </div>
                <!-- Date -->
                <div>
                    <p class="ml-3  mb-0 spanFontSizeLarge " style="margin-bottom: 7px !important;margin-top:24px !important">
                        {{ "selectedDates" | translate }}
                    </p>
                    <p class="ml-3  mb-0 spanFontSizeSmall ">
                        {{gridViewBookingDate | date: "dd MMM yyyy"}}
                    </p>
                </div>
                <!-- conference link  -->
                <ng-container *ngIf=" allowParticipants && videoConfLinkList && videoConfLinkList.length > 0">
                    <p class="ml-3 mb-2 spanFontSizeLarge" style="margin-top: 24px !important;">{{ "videoConferenceLink" | translate }}
                        <mat-slide-toggle class="ml-4" [(ngModel)]="enableVCLink"></mat-slide-toggle>
                    </p>
                    <mat-radio-group *ngIf="enableVCLink" class="example-radio-group" [(ngModel)]="video_conf_link">
                        <mat-radio-button class="example-radio-button pl-3" *ngFor="let item of videoConfLinkList" [value]="item.integrationId">
                            <span *ngIf="item.integrationName !='Office 365'">{{ item.integrationName }}</span>
                            <span *ngIf="item.integrationName =='Office 365'">Microsoft 365</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>

                <!-- special Request -->
                <ng-container *ngIf="!!specialReqList && specialReqList.length > 0">
                    <p class="ml-3 spanFontSizeLarge mt-2 mb-0 " style="margin-bottom: 7px !important; margin-top: 24px !important;">{{ "specialRequest" | translate }}</p>
                    <div class="d-gridd gridd-cols-2 grid-gap-2 ml-3 mb-3 sp-req">
                        <div *ngFor="let option of specialReqList; let i = index">
                            <div class="form-check1 form-check-inline checkbox-item">
                                <mat-checkbox
                                class="custom-checkbox-btn ml-0 cl"
                                value="{{ option.id }}"
                                [checked]="option.checked"
                                (change)="getAmenities(option)"
                                >
                                </mat-checkbox>
                                <span class="checkbox-label" style="margin-left: 7px;font-size: 12px;margin-top: 4px;">{{ option.name }}</span>
                            </div>
                        </div>
                    </div>
                </ng-container> 
            </div>
            <div class="right col-6" style="padding-left:0px">
                <div class="row">
                    <div class="col-6">
                        <label>{{'startTime' | translate}}</label>
                        <mat-select 
                            class="form-control" 
                            id="startTime" 
                            [(ngModel)]="gridViewBookingTimeList.selectedStartTime"
                            disabled="true">
                            <mat-option *ngFor="let item of gridViewBookingTimeList.startTimeList; let i = index" 
                                [value]="item"
                               >
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </div>
                
                    <div class="col-6">
                        <label>{{'endTime' | translate}}</label>
                        <mat-select 
                            class="form-control" 
                            id="endTime" 
                            [(ngModel)]="gridViewBookingTimeList.selectedEndTime">
                            <mat-option *ngFor="let item of gridViewBookingTimeList.endTimeList; let i = index" 
                                [value]="item">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div>
                    <img [src]="gridViewBookingImage" style="border-radius: 20px;margin-top: 16px;width: 100%;">
                </div>
                <div>
                    <div class="row">
                        <ng-container *ngIf="allowParticipants">
                           
                            <div class="col-12 mt-4 mb-3 pr-4">
                                <button
                                    class="btn btn-main btn-block btn-radius m-0 btn-short"
                                    style="margin-left: 10px !important;display: flex;justify-content: center; align-items: center; text-align: center;"
                                    id="add_participants"
                                    type="button"
                                    (click)="addParticipants()"
                                >
                                    {{ "addParticipants" | translate }}
                                    <span style="margin-left: 6px;"
                                        [class]="
                                        totalParticipantsCount
                                                ? 'roundedCircle'
                                                : ''
                                        "
                                        >{{ totalParticipantsCount }}</span
                                    >
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>   
    </div>
    <div class="modal-body width-400 align-left">
        <div class="form-group col-12 mb-0">
            <button class="btn btn-primary btn-main btn-radius mr-2 float-right prl-5" type="button" (click)="saveGridBooking()" [disabled]="timeSlotError" style="padding-left: 18px; padding-right:18px">
                Book Now
            </button>
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'add_participants'">
    <div class="modal-header pl-0">
        <h5>{{ "addParticipants" | translate }}</h5>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeParticipantsPopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body width-400 align-left" style=" max-height: 300px;overflow-y: auto;">
        <form (ngSubmit)="addSendConfirmationMailtTo()">
            <div class="form-row">
                <div class="form-group align-left col-9">
                    <p class="exampleFormControl">
                        {{ "Pleaseentertheemail" | translate }}
                    </p>
                    <input
                        [(ngModel)]="selectedEmail"
                        type="email"
                        class="form-control"
                        id="add_participant"
                        autocomplete="off"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="alex@gmail.com"
                        (input)="
                            getBookingAutoEmailcompleteList(selectedEmail)
                        "
                        [matAutocomplete]="auto"
                    />
                    <i
                        class="fa fa-spinner loading_span"
                        *ngIf="emailbookingIsloading"
                        aria-hidden="true"
                    ></i>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                            *ngFor="
                                let dropData of bookingAutoemailcompleteList$
                                    | async
                            "
                            [value]="dropData.email.trim()"
                        >
                            <span>{{ dropData?.email }}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <div *ngIf="isErrorInput" class="invalid-feedback">
                        <div>{{ "invalidEmail" | translate }}</div>
                    </div>
                </div>
                <div
                    class="form-group col-3 text-left mb-0 font-regular"
                    style="margin-top: 2.2rem;"
                >
                <button type="submit" class="btnSubmit" style="border: none; background-color: white;">
                <img class="cursor-pointer iconSizeDlt"
                            src="../../../../assets/images/plusicon.png" ></button>
                </div>
            </div>
        </form>
        <div class="row mt-2 ml-2">
            <div
                class="col-12"
                *ngFor="let sendMail of sendCnfMailList; let i = index"
                style="margin: 10px 0px 10px 0px;"
            >
                <span class="sendEmail">
                    <span class="s1">{{ sendMail | translate }}</span>
                    &nbsp;
                    <a
                        class="a1"
                        href="javascript:void(0)"
                        (click)="removeEmail(i)"
                        >x</a
                    >
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                &nbsp;
            </div>
            <div class="col-2">
                <button
                    class="btn btn-primary btn-main btnSmall btn-radius mr-2p float-right font-regular" style="display:flex; justify-content: center; align-items:center;text-align:center"
                    type="button"
                    (click)="sendInvite()"
                >
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'moreParticipantsConfirmation'">
    <div class="modal-header pl-1">
        <h5>Confirmation</h5>
    </div>  
    <div class="email-alert width-400">
        <p >{{data['selectedMeetingParticipantsCount']}} participants have been invited for this meeting. Newly selected "{{data['newMeetingRoom']}}" can accommodate {{data['capacityCount']}}.</p>
        <p>Are you sure want to proceed ?</p>
    </div>   
    <div class="d-flex justify-content-end my-3 mx-4">
        <button type="button"  class="btn btn-main btno rounded-pill mr-3" (click)="confirmNo()">
            {{'no' | translate}}
        </button>
        <button type="button" class="btn btn-main btnyes btn-primary rounded-pill " (click)="confirmYes()">
            {{'yes' | translate}}
        </button>
    </div>
</div>
<div class="" *ngIf="pageType == 'gridViewAlert'">
    <div class="modal-header pl-1">
        <h5>Alert</h5>
    </div>  
    <div class="email-alert width-400">
        <p >{{data['message']}}</p>
        
    </div>   
    <div class="d-flex justify-content-end my-3 mx-4">
        <button type="button" class="btn btn-main btnyes btn-primary rounded-pill " (click)="gridViewAlertConfirm()">
            {{'ok' | translate}}
        </button>
    </div>
</div>

<div class="" *ngIf="pageType == 'swapAlert'">
    <div class="modal-header pl-1">
        <h5>Alert</h5>
    </div>  
    <div class="email-alert width-400">
        <p >{{data['message']}}.</p>
    </div>   
    <div class="d-flex justify-content-end my-3 mx-4">
        <button type="button"  class="btn btn-main btno rounded-pill mr-3" (click)="confirmNo()">
            {{'no' | translate}}
        </button>
        <button type="button" class="btn btn-main btnyes btn-primary rounded-pill " (click)="confirmYes()">
            {{'yes' | translate}}
        </button>
    </div>
</div>

<div class="" *ngIf="pageType == 'alertMultiDay'">
    <div class="modal-header pl-1">
        <h5>Alert: Overnight Edits Restricted to Outlook</h5>
    </div>  
    <div class="email-alert width-400">
        <p>Please note that this booking extends to the next day and was made through Outlook, and any edits or changes must be made directly in Outlook.</p>
        <p>Thank you for your understanding.</p>
    </div>   
    <div class="d-flex justify-content-end my-3 mx-4">
        <button type="button" class="btn btn-main btnyes btn-primary rounded-pill " (click)="gridViewAlertConfirm()">
            {{'ok' | translate}}
        </button>
    </div>
</div>