import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from "moment-timezone";
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import { MomentDateTimeAdapter } from "ng-pick-datetime-moment";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AssetModalComponent } from '../../asset-modal/asset-modal.component';

export const MY_CUSTOM_FORMATS = {
  parseInput: "ll LT",
  fullPickerInput: "ll LT",
  datePickerInput: "ll",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "ll",
  monthYearA11yLabel: "MMMM YYYY",
};  
@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
  providers: [
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class FinanceComponent implements OnInit {
  financeForm: FormGroup;
  submitted = false;
  pageType;
  currentZone;
  constructor(public dialogRef: MatDialogRef<AssetModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  ) {
    this.currentZone = this.data["currentZone"];
    this.pageType = this.data["type"];

    this.financeForm = this.formBuilder.group({
      costCode: [""],
      poNumber: [""],
      vendor: [""],
      purchased: [""],
      inService: [""],
      purchasePriceUnit: [""],
      purchasePriceCost: [""],
      currentPriceUnit: [""],
      currentPriceCost: [""],
      scrapValueUnit: [""],
      scrapValueCost: [""],
      warrantyExpires: [""]
      // searchBy: ["", Validators.required],
      // searchVal: [""],
      // floorName: [""],
      // deskNumber: [""],
      // startDate: [""],
      // endDate: [""],
      // date: [""],
    });
   }

  ngOnInit() {
  }

  get f() {
    return this.financeForm.controls;
  }

  onSubmitSearch(){}

}
