import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { ZoneAndModuleHttpParams } from '../../../shared/zone-and-module-http-params/zone-and-module-http-params.component';
import { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class VMSService {

  private moduleId;
  private tmsModuleId;
  private readonly BASE_URL;
  private readonly BASE_EMS_URL;
  constructor(private http: HttpClient) {
    this.moduleId = 9;
    this.tmsModuleId = 48;
    this.BASE_URL = environment.BASE_URL+'vms/';
    this.BASE_EMS_URL = environment.BASE_URL;
  }

  getAllTenants(zoneId){
    return this.http.get(this.BASE_EMS_URL + "tms/tenant/name/list",{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getCompanyList(zoneId) {
    
    const endpoint = "tms/tenant/list?page=0&size=10000&sort=createdAt,desc"    
    return this.http.get(environment.BASE_URL+endpoint, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }

  getAllZoneDataByLevel(zoneId, level){
    const endpoint = this.BASE_EMS_URL+"ems/zones/getZoneLocationsOfSite?level="+level;
    // const endpoint = this.BASE_URL+"zones/getZoneLocationsOfSite?level="+level;
    return this.http.get(endpoint, {params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }
  ipcCounterAdd(zoneId,data) {
    const endpoint = this.BASE_EMS_URL + "tms/ipc/ipcCounter/add";
    return this.http.post(endpoint, data, { params: new ZoneAndModuleHttpParams(zoneId, this.tmsModuleId) });
  }

  ipcRestriction(zoneId,data) {
    const endpoint = this.BASE_EMS_URL + "tms/ipc/ipcRestriction/add";
    return this.http.post(endpoint, data, { params: new ZoneAndModuleHttpParams(zoneId, this.tmsModuleId) });
  }

  getAllipcCounter(zoneId,serachType = '',searchValue='',pageIndex=0,pageSize=10){
 let serch = '';
    if(serachType && searchValue){
      serch = serachType+":"+searchValue
    }

    const endpoint = this.BASE_EMS_URL+"tms/ipc/ipcCounter/all?page="+pageIndex+"&sort=updatedAt,desc&size="+pageSize+"&search="+serch;
    // const endpoint = this.BASE_URL+"zones/getZoneLocationsOfSite?level="+level;
    return this.http.get(endpoint, {params: new ZoneAndModuleHttpParams(zoneId, this.tmsModuleId)});
  }
  getallUsers(zoneId,data){
    //limit=${data.limit}&offset=${data.offset}

    const endpoint=this.BASE_EMS_URL+`ems/user/all?sortBy=createdAt&searchColumn=${ data.searchColumn }&searchValue=${data.searchValue}&page=${data.offset}&size=${data.limit}`;
    return this.http.get(endpoint,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId)});
  }
   getAllUsers(zoneId,data){
    let searchUserID = '';
    if(data.userId != ''){
      searchUserID = '&userId='+data.userId
    }else{
      searchUserID = '';
    }
    return this.http.get(this.BASE_URL + "communityV2/assessment/user-responses?startTimeDate="+data.selectedDate+"&endTimeDate="+data.endedDate+searchUserID+"&page=0&size=100000", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    //return this.http.get(this.BASE_URL + "communityV2/assessment/user-responses?userId=600&startTimeDate=1531714878520&page=0&size=10&endTimeDate=1561714878520", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllipcAllCount(zoneId,tenantId){
    const endpoint = this.BASE_EMS_URL+"tms/ipc/ipcCounter/tenant-counts?tenant_id="+tenantId;
    // const endpoint = this.BASE_URL+"zones/getZoneLocationsOfSite?level="+level;
    return this.http.get(endpoint, {params: new ZoneAndModuleHttpParams(zoneId, this.tmsModuleId)});
  }


  getAllipcRestriction(zoneId){
    const endpoint = this.BASE_EMS_URL+"tms/ipc/ipcRestriction/all";
    // const endpoint = this.BASE_URL+"zones/getZoneLocationsOfSite?level="+level;
    return this.http.get(endpoint, {params: new ZoneAndModuleHttpParams(zoneId, this.tmsModuleId)});
  }

  getIpcAudit(zoneId,pageIndex,pazesize,tenantId,companyId,checkin,checkout) {
    
    let url;
    if(companyId){
   url = this.BASE_EMS_URL + "tms/ipc/ipcAudit/all?page="+pageIndex+"&size="+pazesize+"&sort=updatedAt,desc&search=tenantId:"+companyId+",checkinTime>"+checkin+",checkoutTime<"+checkout;
    }
    else if(tenantId){
      url = this.BASE_EMS_URL + "tms/ipc/ipcAudit/all?page="+pageIndex+"&size="+pazesize+"&sort=updatedAt,desc&search=userId:"+tenantId+",checkinTime>"+checkin+",checkoutTime<"+checkout;
    }
    else if(checkin && checkout == null ){  
      url = this.BASE_EMS_URL + "tms/ipc/ipcAudit/all?page="+pageIndex+"&size="+pazesize+"&sort=updatedAt,desc&search=checkinTime>"+checkin+",checkoutTime=";
    }
      else {
        url = this.BASE_EMS_URL + "tms/ipc/ipcAudit/all?page="+pageIndex+"&size="+pazesize+"&sort=updatedAt,desc&search=checkinTime>"+checkin+",checkoutTime<"+checkout;
   }
    return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  createInvitation(createInvite, zoneId) {
    const endpoint = this.BASE_URL + "api/createInvitation";
    return this.http.post(endpoint, createInvite, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getReport(from, to, zoneId) {
    return this.http.get(this.BASE_URL+ "api/reports?" + "startTime=" + from + "&endTime=" + to, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  approvePhoto(data, zoneId) {
    return this.http.put(this.BASE_URL + "api/approvePhoto", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  rejectPhoto(data, zoneId) {
    return this.http.put(this.BASE_URL + "api/rejectPhoto", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  approveActivation(data, zoneId) {
    return this.http.put(this.BASE_URL + "api/activatePass", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  rejectActivation(data, zoneId) {
    return this.http.put(this.BASE_URL + "api/activatePass", data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }


  searchColumnList(zoneId) {
    return this.http.get(this.BASE_URL + "messageCategory/getKeyByCategoryId?categoryId=15", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  public manageVisitTableSizeSource = new BehaviorSubject<number>(0);

  tableSize = this.manageVisitTableSizeSource.asObservable();
  setSize(size: number) {

  this.manageVisitTableSizeSource.next(size)
  }
  getReportData(data, zoneId):Observable<any>{
    return this.http.get(this.BASE_URL + `api/reports?startTime=${data['startTime']}&endTime=${data['endTime']}`, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  
  //vms end

  getAllActiveCustomCheckList(zoneId){
    //return this.http.get(this.BASE_URL + "custom-field/all-active?customKey=CUSTOM_CHECK_LIST", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
    return this.http.get(this.BASE_URL + "custom-field/all?page=0&size=1000", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllActiveIdentity(zoneId){
    return this.http.get(this.BASE_URL + "custom-field/all-active?customKey=ADDITIONAL_VMS_FIELD&page=0&size=100", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllIdentityDetailsByInvitationId(zoneId,invitationId){
    return this.http.get(this.BASE_URL + "custom-field-metadata/all-active?moduleActionKey=ADDITIONAL_VMS_FIELD&moduleEntityId="+invitationId, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllActiveFastTrackPassList(zoneId){
    return this.http.get(this.BASE_URL + "custom-field/all-active?customKey=FAST_TRACK_PASS", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  getAllActiveTerms(zoneId){
    return this.http.get(this.BASE_URL + "custom-field/all-active?customKey=TermsAndConditions", { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  saveCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field/create", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
  updateCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  updateMetaDataCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field-metadata/update", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  saveMetaDataCustomCheckList(zoneId,data){
    return this.http.post(this.BASE_URL + "custom-field-metadata/create", data,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getAllCustomCheckList(zoneId,id){
    return this.http.get(this.BASE_URL + "custom-field-metadata/all-active?moduleActionKey=CUSTOM_CHECK_LIST&moduleEntityId="+id,{ params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getQRcode(zoneId,floorId){
    return this.http.get(this.BASE_EMS_URL + "ems/zones/getZoneQrInfo?id="+zoneId+'&floorId='+floorId,{params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getBuildingFloorType(zoneId){
    let url = this.BASE_EMS_URL + "tms/ipc/ipc-scanlevel";
     return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getConfigForModule(zoneId, moduleId){
    //{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":5,"sessionId":"{{sessionId}}", "moduleId":102}
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":${moduleId},"enterpriseId":"1","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.get(this.BASE_EMS_URL+"ems/module/config", httpOptions)
      .map(function (response) {
        return response;
    });
  }


  updateConfig(zoneId,moduleId,data){

    let datas = {
      "config": JSON.stringify(data),
      "moduleId": moduleId,
      "zoneId": zoneId
    }
    const httpOptions = {
      headers: new HttpHeaders({        
        'ss-header': `{"version":"1.0","clientKey":"ADMIN_WEB_APP","zoneId":${zoneId}, "moduleId":${moduleId},"sessionId":"${localStorage.getItem('ssadmin_session')}","correlationId":"${uuidv4()}"}`
      })
    };
    return this.http.post(this.BASE_EMS_URL+"ems/module/updateConfig?generate=true",datas,httpOptions);
  }

  getIpcAuditTenantAll(zoneId,serachType = '',searchValue='',pageIndex=0,pageSize=10){
    let serch = '';
    if(serachType && searchValue){
      serch = serachType+":"+searchValue
    }
    let url = this.BASE_EMS_URL + "tms/ipc/ipcCounter/tenant/all?&sort=updatedAt,desc&page="+pageIndex+"&size="+pageSize+"&search="+serch;
     return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }

  getIpcCounterTenantAll(zoneId,serachType = '',searchValue='',pageIndex=0,pageSize=10){
    let serch = '';
    if(serachType && searchValue){
      serch = "userId:"+searchValue
    }
    let url = this.BASE_EMS_URL + "tms/ipc/ipcAudit/tenant/all?sort=updatedAt,desc&page="+pageIndex+"&size="+pageSize+"&search="+serch;
     return this.http.get(url, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }
}