import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class TabNavigationService {
    
    currentAllocationTabIndex: number=0;
    currentApprovalTabIndex: number=0;
    RequestTabIndex: boolean = true; // true = means index at 3 , false =  index at 4

    setCurrentAllocationTabIndex(val:string){
        
        if(val==='Cost Centre Manager'){
            this.currentAllocationTabIndex = 0;
        }
        else if(val === 'Group Manager'){
            this.currentAllocationTabIndex = 1;
        }
        else if(val === 'Facility Approver' || val=== 'Facility Manager'){
             this.currentAllocationTabIndex = 2;
        }
        else{
            this.currentAllocationTabIndex = 0;
        }
        
    }

    setCurrentAllocationTabIndexByNo(val : number){
        this.currentAllocationTabIndex = val;
    }

    getCurrentAllocationTabIndex(){
        return this.currentAllocationTabIndex;
    }

    setCurrentApprovalTabIndex(val:string){
        
        if(val==='Cost Centre Manager'){
            this.currentApprovalTabIndex = 0;
        }
        else if(val === 'Group Manager'){
            this.currentApprovalTabIndex = 1;
        }
        else if(val === 'Facility Approver' || val=== 'Facility Manager'){
             this.currentApprovalTabIndex = 2;
        }
        else{
            this.currentApprovalTabIndex = 0;
        }
        
    }

    setCurrentApprovalTabIndexByNo(val : number){
        this.currentApprovalTabIndex = val;
    }

    getCurrentApprovalTabIndex(){
        return this.currentApprovalTabIndex;
    }

    // setRequestTabIndex(val:boolean){
    //     this.RequestTabIndex = val;
    // }

    // getRequestTabIndex(){
    //     return this.RequestTabIndex;
    // }
  }

 
