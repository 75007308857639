import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';


import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from 'commonService';
import { environment } from 'src/environments/environment';
import { ZoneAndModuleHttpParams } from 'src/app/shared/zone-and-module-http-params/zone-and-module-http-params.component';



@Injectable()
export class PeopleTenantAdminService {

  private moduleId;
  private readonly BASE_URL;
  private readonly BASE_URL_Tenant;

  constructor(private http: HttpClient, public commonService: CommonService) {
    this.moduleId = 5; 
    this.BASE_URL = environment.BASE_URL ;
    this.BASE_URL_Tenant = environment.BASE_URL + 'tms/tenant/digital/';
  }

  
  downloadViaExcelTemplate(zoneId){
    const endpoint = this.BASE_URL + "tms/downloadUserTemplateBulkUpload";
    const data=null
    return this.http.post(endpoint,data, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) ,responseType: 'blob' });
  }

  validateExcelOnboarding(fileToUpload,fileName,zoneId){
    const endpoint = this.BASE_URL + "ems/user/validateBulkUpload/tenant/v2?superAdmin=false";
    var formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileName);
    return this.http.post(endpoint,formData, { params: new ZoneAndModuleHttpParams(zoneId, this.moduleId) });
  }


}