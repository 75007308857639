import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import "@fullcalendar/angular";
import { CalendarOptions } from "@fullcalendar/angular"; // useful for typechecking
import { FullCalendarComponent } from "@fullcalendar/angular";
import { CommonService } from "commonService";
import { WorkStatusService } from "../service/workstatus.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomdaysComponent } from "../../amenity-booking/customdays/customdays.component";
import dayGridPlugin from "@fullcalendar/daygrid";
import { LoaderService } from "loaderService";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
@Component({
  selector: "app-digitalworkstatus-modal",
  templateUrl: "./digitalworkstatus-modal.component.html",
  styleUrls: ["./digitalworkstatus-modal.component.scss"],
})
export class DigitalWorkStatusModalComponent implements OnInit {
  dateTime = new Date();
  dt = new Date();
  roster = [];
  events = [];
  currentZone
  eventsModel: any;
  pageType: any;
  locationList = [];

  selectedDate: any;
  rosterData = {};
  selectedOfficeDates = [];

  calendarOptions = {};
  @ViewChild(FullCalendarComponent, { static: false })
  fullCalendarComponent: FullCalendarComponent;
  constructor(
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomdaysComponent>,
    public dashboardService: WorkStatusService,
    public loaderService: LoaderService,
    public toastrService: ToastrService
  ) {
    console.log(this.data);
    this.pageType = this.data["type"];
    this.currentZone=this.data["zoneId"]
    if (this.pageType == "schedule") {
      let dialogData = this.data["dialogData"];
      console.log("dialogData",dialogData);
      this.locationList = dialogData["zoneList"];
    }

    if (this.pageType == "searchLocation") {
      let dialogData = this.data["dialogData"];
      this.locationList = dialogData["zoneList"];
      let selectedDate = new Date(dialogData["selectedDate"]);
      this.selectedDate = moment(selectedDate).format("Do MMM YYYY");
    }

    if (this.data["type"] == "calendarView") {
      this.calendarOptions = {
        initialView: "dayGridMonth",
        plugins: [dayGridPlugin],
        showNonCurrentDates: false,
        height: "325px",
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        headerToolbar: {
          left: "previousMonth",
          center: "title",
          right: "nextMonth",
        },
        customButtons: {
          previousMonth: {
            text: "Previous",
            icon: "chevron-left",
            click: this.prevMonth.bind(this), // !!!
          },
          nextMonth: {
            text: "Next",
            icon: "chevron-right",
            click: this.nextMonth.bind(this), // !!!
          },
        },
        eventContent: (arg) => {
          let htmlStr = "";
          return {
            html:
              "<div class='eventColor' style='background-color:" +
              arg.event.extendedProps.workStatusColor +
              "'>&nbsp</div>",
          };
        },
        eventSources: [
          {
            events: this.roster,
            display: "list-item",
            borderColor: "none",
          },
        ],
        selectOverlap: false,
      };
    }
  }

  ngOnInit() {
    if(this.pageType=="selectLocations")
    {
    this.locationList = this.data["dialogData"]["zoneList"];
    this.selectedOfficeDates = this.data["dialogData"]["selectedOfficeDates"];
    this.rosterData = this.data["dialogData"]["roasterData"];
    console.log(this.rosterData);
    }
    console.log("111")
    if (this.data["type"] == "calendarView") {
      setTimeout(() => {
        this.loadCurrentMonthInfo();
      }, 500);
    }
    console.log(this.data);
  }

  ngAfterViewInit(): void {
    //console.log(this.fullCalendarComponent); // undefined
  }

  closePopup() {
    this.dialogRef.close(this.pageType);
  }

  closePopupColleagues() {
    this.dialogRef.close("colleagues");
  }



  loadCurrentMonthInfo() {
    this.roster = [];
    this.events = [];
    const date = new Date(this.dt.getFullYear(), this.dt.getMonth(), 1);
    const lastMonth = new Date(
      this.dt.getFullYear(),
      this.dt.getMonth() + 1,
      0
    );
    console.log(date);
    let fdateTimeStamp = this.commonService.getTimeStamp(
      date.getTime(),
      "start"
    );
    let ldateTimeStamp = this.commonService.getTimeStamp(
      lastMonth.getTime(),
      "end"
    );
    let bookSeats = this.dashboardService.loadCalendarView(
      fdateTimeStamp,
      ldateTimeStamp
    );
    this.loaderService.showLoaderUntilCompleted(bookSeats).subscribe((res) => {
      let bookingInfo = res["response"];
      if (bookingInfo.length > 0) {
        bookingInfo.forEach((element) => {
          let rosterPresent = element["quickAction"];
          let color = null;
          if (rosterPresent) {
            color = "#1F2559";
          }
          let data = {
            title: "",
            start: element.dayStart,
            workStatusColor: color,
          };
          this.events.push(data);
        });

        this.fullCalendarComponent.getApi().removeAllEventSources();
        this.fullCalendarComponent.getApi().addEventSource(this.events);
        this.fullCalendarComponent.getApi().refetchResources();
        this.fullCalendarComponent.getApi().refetchEvents();
      }
    });
  }

  nextMonth() {
    this.fullCalendarComponent.getApi().next();
    this.updateDateAndLoadInfo();
  }

  prevMonth() {
    this.fullCalendarComponent.getApi().prev();
    this.updateDateAndLoadInfo();
  }

  updateDateAndLoadInfo() {
    let changedDate = this.fullCalendarComponent.getApi().getDate();
    this.dt.setFullYear(changedDate.getFullYear());
    let m: any = changedDate.getMonth();
    let d: any = changedDate.getDate();
    if (m <= 9) {
      m = "0" + m;
    }
    if (d <= 9) {
      d = "0" + d;
    }

    this.dt.setMonth(m);
    this.dt.setDate(1);
    this.loadCurrentMonthInfo();
  }

  doneSchedule(data) {
    if(data == "closePreference") {
      console.log('closePreference is coming here...')
      this.dialogRef.close();
    } else {
      if (data) {
        this.rosterData = data;
        if (
          this.locationList.length > 1 &&
          Object.keys(data).includes("OFFICE")
        ) {
          data["OFFICE"] &&
            data["OFFICE"].dayStart.forEach((date) => {
              let displayDate = moment(date).format("Do MMM");
              this.selectedOfficeDates.push({ key: date, displayDate });
            });
          this.pageType = "selectLocations";
        } else {
          let createRosterAPI = this.dashboardService.createRoster(data);
          this.loaderService.showLoaderUntilCompleted(createRosterAPI).subscribe(
            (res) => {
              // console.log('res', res)
              // this.toastrService.error("Roster created successfully");
              this.pageType = "scheduleCreated";
            },
            (err) => {
              this.toastrService.error("Something went wrong..!!");
            }
          );
        }
      }
    }
  }

  getNotification(event) {
    console.log("event here is dashboard modal... ", event);
    if (event == "true") {
      this.closePopup();
    }
  }

  onChange(data) {
    //alert("Triggered" + data);
    console.log("Triggered", data);
  }

  getCurrentMonth(data) {
    console.log(data);
  }

  getFilteredSearchLocation(selectedLocation) {
    console.log("selectedLocation is... ", selectedLocation);
    let data: any = {
      pageType: "",
    };
    if (this.pageType == "searchLocation") {
      data = {
        pageType: this.pageType,
        selectedFilteredLocation: selectedLocation,
      };
    }
    this.dialogRef.close(data);
  }

  saveDateLocation(selectedDateLocation) {
    console.log(selectedDateLocation);
    let createRosterAPI = this.dashboardService.createRoster(this.rosterData);
    this.loaderService.showLoaderUntilCompleted(createRosterAPI).subscribe(
      (res) => {
        // console.log('res', res)
        // this.toastrService.error("Roster created successfully");
        let colleagueAPI =
          this.dashboardService.saveRosterZones(selectedDateLocation);
        this.loaderService
          .showLoaderUntilCompleted(colleagueAPI)
          .subscribe((res) => {
            console.log(res);
          });
        this.pageType = "scheduleCreated";
      },
      (err) => {
        this.toastrService.error("Something went wrong..!!");
      }
    );
  }

  showFullCalendar() {
    this.closePopupColleagues();
  }
}
