import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ConfirmationDialogComponent } from "./confirmation-dialog.component";

@Injectable()
export class ConfirmationDialogService {
    constructor(private modalService: NgbModal) {}

    public confirm(
        title: string,
        message: string,
        btnOkText: string = "OK",
        btnCancelText: string = "Cancel",
        dialogSize: "md" | "sm" | "lg" = "sm"
    ): Promise<boolean> {
        const modalRef = this.modalService.open(ConfirmationDialogComponent, {
            size: dialogSize,
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        modalRef.componentInstance.alert = false;
        return modalRef.result;
    }

    public alert(
        title: string,
        message: string,
        btnOkText: string = "OK",
        btnCancelText: string = "Cancel",
        dialogSize: "md" | "sm" | "lg" = "lg",
        buttons: boolean = true
    ): Promise<boolean> {
        const modalRef = this.modalService.open(ConfirmationDialogComponent, {
            size: dialogSize,
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        modalRef.componentInstance.alert = true;
        modalRef.componentInstance.buttons = buttons;
        return modalRef.result;
    }
}
