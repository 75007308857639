<div class="modal-card p-4" *ngIf="pageType == 'addPeople'">
    <div class="modal-header p-0">
      <h6 class="semi-bold-blue">  Onboard Users</h6>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body align-left">
      <app-sub-heading title="People"></app-sub-heading>
      <div class="pl-0 mt-4">
        <div class="row col-12">
        <ng-container *ngIf="addDepartmentType == '' || !onboardUserSelected">
          <ul class="list-unstyled px-0">
            <li class="regular-font">
              How would you like to onboard  new users?
            </li>
          </ul>
  
          <mat-radio-group
            aria-label=""
            class="form-control px-0 radioBtn d-flex flex-column h-auto"
            style="border: none"
            [(ngModel)]="addDepartmentType"
            required
          >

            <mat-radio-button
              id="add_manually"
              class="regular-font mb-3"
              value="add_manually"
            >
              {{ "Add Manually" | translate }}
            </mat-radio-button>
  
            <mat-radio-button
              id="add_excel"
              class="regular-font mb-3"
              value="add_excel"
            >
              {{ "Add Via Excel" | translate }}
            </mat-radio-button>
            
          </mat-radio-group>
        
          <div
            *ngIf="onboardUserSelected && addDepartmentType == ''"
            class="text-danger"
          >
            Please select one option
          </div>
        </ng-container>
          <ng-container *ngIf="addDepartmentType == 'add_excel' && onboardUserSelected">
            <div class="row">
                <p class="fontColor pl-3">
                    Bulk upload Tenant employees
                    </p>
                    </div>
            <div class="row">
                <p class="fontColorSemi pl-3">Note: Only employees from tenant companies mapped to this location can be onboarded. You will be required to mention employee name, corporate email address and their tenant company name mandatorily.  </p>
              <div class='col-6 pl-3'>
                <label></label>
                <button
                  type="button"
                  class="form-control1"
                  (click)="downloadExcelSheet()"
                >
                  <span style="font-family: 'Open Sans'">{{
                    "downloadTemplate" | translate
                  }}</span>
                  <span class="ml-3"
                    ><img
                      src="./assets/images/downloadTemplate.png"
                      style="width: 1rem"
                  /></span>
                </button>
              </div>
              <div class="row w-100 pl-3">
              <div class="col-12">
                <label for="uploadFile" class="label1 float-left mt-4 font-label"
                  >{{ "upload" | translate }} (.xlsx
                  {{ "file" | translate }})</label
                >
                <div class="input-group col-6 clear-both float-left">
                  <input
                    type="file"
                    class="form-control custom-file-input"
                    id="importFile"
                    aria-describedby="inputGroupFileAddon01"
                    (change)="uploadCSV($event.target.files)"
                    accept=".xlsx"
                    #fileUploader
                  />
                  <label
                    class="custom-file-label1 custom-upload-btn font-label"
                    for="inputGroupFile01"
                    #labelImport
                  ></label>
                 
                </div>
                <div
                  class="input-group col-6 mTop-35"
                  *ngIf="fileName"
                >
                  <p class="card inner-card-auto text-center p-1 d-inline-block">
                    <span class="text-overflow-hidden mt-2">{{
                        fileName
                    }}</span>
                    <button
                      type="button"
                      class="close text-right mt-2"
                      aria-label="Close"
                    >
                      <span aria-hidden="true" (click)="removeUploadedFile()"
                        >&times;</span
                      >
                    </button>
                  </p>
                </div>
                <!-- <div
                class="row col-12 text-danger"
                *ngIf="submitted && fileName != '' || fileName != undefined"
              >
                Please upload file
              </div> -->
              </div>
            </div>
            </div>
            
          </ng-container>
        </div>
  
        <div class="mt-3 mb-3" *ngIf="addDepartmentType == 'add_excel'">
          <button
            class="btn btn-main btnSmall smallBtn mt-2 float-right"
            id="next"
            name="next"
            (click)="onboardUsers()"
          >
            Next
          </button>
        </div>
        <div class="mt-3 mb-3" *ngIf="addDepartmentType == 'add_manually'">
          <button
            class="btn btn-main btnSmall smallBtn mt-2 float-right"
            id="next"
            name="next"
            (click)="onboardUserManualyy()"
          >
            Next
          </button>
        </div>


      </div>
    </div>
  </div>


  <div class="modal-card p-4" *ngIf="pageType == 'addEventManual'">
    <div class="modal-header p-0">
        <h6 class="semi-bold-blue">
            {{"onboardUser" | translate}}
        </h6>
        <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closePopup()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="col-12 pl-0 ">
        <p class="italic mt-2 openSans pl-0 mb-0">
            People
        </p>
    </div>
    <div class="modal-body align-left width-400">
    
        <div class="row mt-3">
            <div class="form-group col-lg-6 col-md-12  ">
                <label class="float-left formLabel"
                    >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
                >
                <input
                    type="email"
                    class="form-control blueText"
                    placeholder="{{'enterEmailID' | translate}}"
                 [(ngModel)]="onboardUserEmailVerify"
                
                />
             
            </div>
            </div>
  
     
        <div class="">
            <ng-container>
                <div class="form-group col-lg-12 col-md-12 pr-0">
                    <button
                        type="button"
                        *ngIf="validateEmail(onboardUserEmailVerify)"
                        class="btn btn-main btnSmall smallBtn float-right ml-4"
                        (click)="verifyEmail()"
                    >
                        {{"next" | translate}}
                    </button>
                  
                </div>
            </ng-container>
        </div>
    </div>
  </div>

  <div class="modal-card p-4" *ngIf=" pageType == 'onboardUserManualy'
  "
>
  <div class="modal-header  p-0">
      <!-- <h5 class="semi-bold-blue"><i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtnUsers()" *ngIf="!individual && pageType != 'editUsers'"></i> {{pageType != 'editUsers' ? 'Add' : 'Edit'}} {{"users" | translate}} <span *ngIf="!individual">(3/3)</span></h5> -->
      <h6 class="semi-bold-blue">{{editPeopleMode?'Edit Users' : 'Onboard Users'}} </h6>
      <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="
             closePopup()
          "
      >
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body align-left width-400">
      <app-sub-heading title="People"></app-sub-heading>

     
 
  <!-- <mat-tab  label="Internal" *ngIf="( !editPeopleMode || (editPeopleMode && tenantId==1 ))"  >
    <form
    [formGroup]="addUsersManuallyForm"
    (ngSubmit)="submitManuallyForm()"
>
    <div>
        <div class="row">
            <div class="form-group col-lg-6 col-md-12  ">
                <label class="float-left formLabel"
                    >{{"firsNameCaps" | translate}}
                    <span class="text-danger">*</span></label
                >
                <input
                    type="text"
                    class="form-control blueText"
                    placeholder="{{'Entername' | translate}}"
                    formControlName="employeeName"
                />
                <div
                    *ngIf="
                        submittedUsers &&
                        addUsersManuallyForm.controls.employeeName
                            .errors
                    "
                >
                    <div class="text-danger formLabel">
                        <div *ngIf="addUsersManuallyForm.get('employeeName').hasError('required') && addUsersManuallyForm.get('employeeName').touched">
                            First Name is required
                          </div>
                          <div *ngIf="addUsersManuallyForm.get('employeeName').hasError('pattern') && addUsersManuallyForm.get('employeeName').touched">
                            First Name must contain only alphabets
                          </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-lg-6 col-md-12  ">
                <label class="float-left formLabel"
                    >{{"lastNameCaps" | translate}}
                    <span class="text-danger">*</span>
                </label>
                <input
                    type="text"
                    class="form-control blueText"
                    placeholder="{{'Entername' | translate}}"
                    formControlName="lastName"
                />
                <div
                    *ngIf="
                        submittedUsers &&
                        addUsersManuallyForm.controls.lastName.errors
                    "
                >
                    <div class="text-danger formLabel">
                        <div *ngIf="addUsersManuallyForm.get('lastName').hasError('required') && addUsersManuallyForm.get('lastName').touched">
                            Last Name is required
                          </div>
                          <div *ngIf="addUsersManuallyForm.get('lastName').hasError('pattern') && addUsersManuallyForm.get('lastName').touched">
                            Last Name must contain only alphabets
                          </div>
                      
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-6 col-md-12  ">
                <label class="float-left formLabel"
                    >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
                >
                <input
                    type="text"
                    class="form-control blueText"
                    placeholder="{{'enterEmailID' | translate}}"
                    formControlName="email"
                />
                <div
                    *ngIf="
                        submittedUsers &&
                        addUsersManuallyForm.controls.email.errors
                    "
                >
                    <div class="text-danger formLabel">
                        {{"emailIsRequired" | translate}}
                    </div>
                </div>
            </div>

         
        </div>

        <div class="row">
          <p class="regular-semibold-font pl-3">
            {{"additionalRolesCaps" | translate}}
             <mat-slide-toggle
                 class="toggleBtn ml-2"
                
                 formControlName="additionalRoles"
             ></mat-slide-toggle>
         </p>
            <div
                class="form-group col-lg-12 col-md-12"
                *ngIf="addUsersManuallyForm.get('additionalRoles').value"
            >
                <ng-container >
                    <div class="d-flex">
                        <div
                            class="form-group col-lg-6 pl-0 col-md-12"
                        >
                            <label class="formLabel"
                                >Roles
                                <span class="text-danger"
                                    >*</span
                                ></label
                            >
                            <div class="input-group pl-0">
                                <mat-icon
                                    class="alignSearchIconGray pl-2"
                                    matPrefix
                                    >search</mat-icon
                                >
                                <input
                                    type="text"
                                    autocomplete="off"
                                    id="locationSelection"
                                    class="form-control blueText textIndent float-right pl-3"
                                    placeholder="Select Role"
                                  
                                    [formControl]="searchRoles"
                                    required
                                    [matAutocomplete]="auto5"
                                    #customInput5
                                />
                                <i
                                    style="position: absolute;right: 10px;top: 10px;"
                                    class="fa fa-spinner loading_span"
                                    *ngIf="nameSearchIsLoading"
                                    aria-hidden="true"
                                ></i>
                                <mat-autocomplete
                                    multiple
                                    #auto5="matAutocomplete"
                                >
                                    <ng-container
                                        *ngFor="
                                            let dropData of filteredRoles
                                                | async
                                        "
                                    >
                                        <mat-option
                                            class="autocompleteSearch"
                                           
                                            [value]="dropData.name"
                                            (click)="
                                            selectCustomRole(
                                                    dropData
                                                )
                                            "
                                        >
                                            <span>{{
                                                dropData.name
                                            }}</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </div>
                            <div
                                *ngIf="
                                    submittedUsers &&
                                    customRoleList.length == 0
                                "
                            >
                                <p class="text-danger formLabel">
                                   {{"locationIsRequired" | translate}}
                                </p>
                            </div>
                        </div>
                        <button
                            class="smallBtnCircleAdd semi-bold-blue mt-4 ml-3"
                            (click)="tagCustomLocationsNonTeams()"
                            type="button"
                        >
                            +
                        </button>
                    </div>
                    <ng-container
                        *ngIf="customRoleList.length > 0"
                    >
                        <div class="col-12 d-flex ">
                            <div
                                class="form-group w-fit ml-1"
                                *ngFor="
                                    let role of customRoleList;
                                    let i = index
                                "
                            >
                                <div
                                    class="inner-card-auto justify-content-between align-items-center py-1"
                                >
                                    <span
                                        class="gray-regular font-small mr-1"
                                        >{{ role.name }}</span
                                    >
                                    <span
                                        aria-hidden="true"
                                        (click)="
                                            removeCustomRole(
                                                i
                                            )
                                        "
                                        class="cursor-pointer"
                                        >&times;</span
                                    >
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
              

               
            </div>
        </div>
        <div class="row mt-2">
            <div class="form-group col-lg-12 d-flex col-md-12">
              
                <p
                    class="mr-2 blueText"
                 
                >
                    {{"allUsersAreTaggedToAGeneralUserRole" | translate}}
                    <span
                        class="light-blue-anchor ml-2 informationIcon"
                        >i</span
                    >
                </p>
                <div class="col-5 px-0">
                    <button
                        class="btn btn-main btnSmall  semi-bold-blue ml-4 smallBtn float-right"
                        type="submit"
                    >
                        {{"done" | translate}}
                    </button>
                  
                </div>
            </div>
        </div>
    </div>
</form>
</mat-tab> -->



  <form
          [formGroup]="addUsersManuallyForm"
          (ngSubmit)="submitManuallyFormTenant(pageType)"
      >
          <div>
            
              <div class="row">
                  <div class="form-group col-lg-6 col-md-12  " 
                 
                  >
                      <label class="float-left formLabel"
                          >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
                      >
                      <input
                          type="text"
                          class="form-control blueText"
                          placeholder="{{'enterEmailID' | translate}}"
                          formControlName="email"
                      />
                      <div
                          *ngIf="
                              submittedUsers &&
                              addUsersManuallyForm.controls.email.errors
                          "
                      >
                          <div class="text-danger formLabel">
                              {{"emailIsRequired" | translate}}
                          </div>
                      </div>
                  </div>
                  <div
                  class="form-group col-lg-6 col-md-12"
                  style="margin-top: -0.3rem;"
              >
                  <ng-container >
                      <div class="">
                          <div
                              class=""
                          >
                              <label class="formLabel"
                                  >Tenant Name
                                  <span class="text-danger"
                                      >*</span
                                  ></label
                              >
                              <div class="input-group pl-0">
                                  <mat-icon
                                      class="alignSearchIconGray pl-2"
                                      matPrefix
                                      >search</mat-icon
                                  >
                                  <input
                                      type="text"
                                      autocomplete="off"
                                      id="locationSelection"
                                      class="form-control blueText textIndent float-right pl-3"
                                      placeholder="Select tenant company"
                                    
                                      [formControl]="searchTenantCompany"
                                      required
                                      [matAutocomplete]="auto5"
                                      #customInput5
                                  />
                                  <i
                                      style="position: absolute;right: 10px;top: 10px;"
                                      class="fa fa-spinner loading_span"
                                      *ngIf="nameSearchIsLoading"
                                      aria-hidden="true"
                                  ></i>
                                  <mat-autocomplete
                                      multiple
                                      #auto5="matAutocomplete"
                                  >
                                      <ng-container
                                          *ngFor="
                                              let dropData of filteredTenantCompany
                                                  | async
                                          "
                                      >
                                          <mat-option
                                              class="autocompleteSearch"
                                             
                                              [value]="dropData.name"
                                              (click)="
                                              selectCustomTenantComapny(
                                                      dropData
                                                  )
                                              "
                                          >
                                              <span>{{
                                                  dropData.name
                                              }}</span>
                                          </mat-option>
                                      </ng-container>
                                  </mat-autocomplete>
                              </div>
                              <div
                                  *ngIf="
                                      submittedUsers &&
                                      !selectedTenantComapny
                                  "
                              >
                                  <p class="text-danger formLabel">
                                     {{"required" | translate}}
                                  </p>
                              </div>
                          </div>
                         
                      </div>
                    
                  </ng-container>
                

                 
              </div>
            
               
              </div>
              <div class="row">
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"firsNameCaps" | translate}}
                        <span class="text-danger">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'Entername' | translate}}"
                        formControlName="employeeName"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersManuallyForm.controls.employeeName
                                .errors
                        "
                    >
                    <div class="text-danger formLabel">
                        <div *ngIf="addUsersManuallyForm.get('employeeName').hasError('required') ">
                          First Name is required
                        </div>
                        <div *ngIf="addUsersManuallyForm.get('employeeName').hasError('pattern') ">
                          First Name must contain only alphabets
                        </div>
                      </div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"lastNameCaps" | translate}}
                        <span class="text-danger">*</span>
                       
                    </label>
                    <input
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'Entername' | translate}}"
                        formControlName="lastName"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersManuallyForm.controls.lastName.errors
                        "
                    >
                    <div class="text-danger formLabel">
                        <div *ngIf="addUsersManuallyForm.get('lastName').hasError('required') ">
                          Last Name is required
                        </div>
                        <div *ngIf="addUsersManuallyForm.get('lastName').hasError('pattern') ">
                          Last Name must contain only alphabets
                        </div>
                      </div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-12 ">
                    <mat-checkbox
                        class="example-margin"
                        formControlName="tenantAdmin"
                        >Tenant Admin</mat-checkbox
                    >
                </div>
            </div>
            
              <div class="row mt-2">
                  <div class="form-group col-lg-12 d-flex col-md-12">
                      <!-- <p
                          class="mr-2 blueText"
                          *ngIf="accessAllLocationsNonTeams"
                      >
                          {{"usersWillBeMappedToAllLocationTaggedToGeneralUserRole" | translate}}
                          <span
                              class="light-blue-anchor ml-2 informationIcon"
                              >i</span
                          >
                      </p> -->
                      <p
                          class="mr-2 blueText"
                     
                      >
                          {{"allUsersAreTaggedToAGeneralUserRole" | translate}}
                          <span
                              class="light-blue-anchor ml-2 informationIcon"
                              >i</span
                          >
                      </p>
                      <div class="col-5 px-0">
                          <button
                              class="btn btn-main btnSmall  semi-bold-blue ml-4 smallBtn float-right"
                              type="submit"
                          >
                              {{"done" | translate}}
                          </button>
                       
                      </div>
                  </div>
              </div>
          </div>
      </form>


    
  </div>
</div>




  <div *ngIf="pageType == 'onboardingExcelTable'" class="px-4 py-3">
    <div class="modal-header p-0 pl-0 pr-0">
        <h6 class="semi-bold-blue">{{'alert' | translate }}</h6>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body width-400 align-left mt-3 pb-0">
        <ng-container *ngIf="incorrectData.length>0">
            <p class="regular-semibold-font">
                {{ "successfullyUploaded" | translate }}
                {{ TotalNoOfEntitiesUploadedRNW }} users ,
                {{'belowAreTheListOfEntitiesWhichHaveIssues' | translate}}.
            </p>    
            <table mat-table [dataSource]="showIncorrectData">
                <!-- First Name Column -->
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef class="align-left">
                        {{ "First name" | translate }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-left"
                        [ngClass]="{
                            'is-incorrect': element.errorFields.includes(
                                'First Name'
                            )
                        }"
                        [matTooltip]="getToolTipData(element.firstName)"
                    >
                        {{ element.firstName | slice: 0:20 }}
                        <span *ngIf="element.firstName?.length > 20"
                            >...</span
                        >
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef class="align-left">
                        {{ "lastName" | translate }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-left"
                        [ngClass]="{
                            'is-incorrect': element.errorFields.includes(
                                'Last Name'
                            )
                        }"
                        [matTooltip]="getToolTipData(element.lastName)"
                    >
                        {{ element.lastName  }}
                        
                    </td>
                </ng-container>

                <ng-container matColumnDef="corporateEmail">
                    <th mat-header-cell *matHeaderCellDef class="align-left">
                        {{ "Corporate Email" | translate }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-left"
                        [ngClass]="{
                            'is-incorrect': element.errorFields.includes(
                                'Email'
                            )
                        }"
                        [matTooltip]="getToolTipData(element.corporateEmail)"
                    >
                        {{ element.corporateEmail | slice: 0:20 }}
                        <span *ngIf="element.corporateEmail?.length > 20">...</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tenantAdmin">
                  <th mat-header-cell *matHeaderCellDef class="align-left">
                    Tenant Admin
                  </th>
                  <td
                      mat-cell
                      *matCellDef="let element"
                      class="align-left"
                      [ngClass]="{
                          'is-incorrect': element.errorFields.includes(
                              'Tenant Admin'
                          )
                      }"
                      [matTooltip]="getToolTipData(element.internalEmployee)"
                  >
                      {{ element.tenantAdmin | slice: 0:20 }}
                      <span *ngIf="element.internalEmployee?.length > 20">...</span>
                  </td>
              </ng-container>
          
                <ng-container matColumnDef="tenantName">
                    <th mat-header-cell *matHeaderCellDef class="align-left">
                        {{ "Tenant Name" | translate }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-left"
                        [ngClass]="{
                            'is-incorrect': element.errorFields.includes('Tenant Name')
                        }"
                        [matTooltip]="getToolTipData(element.tenantName)"
                    >
                        {{ element.tenantName | slice: 0:20 }}
                        <span *ngIf="element.tenantName?.length > 20">...</span>
                    </td>
                </ng-container>
                
         
                <!-- Reason Column -->
                <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef class="align-left" style="width: 20vw;">
                        {{ "reason" | translate }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-left"
                        [matTooltip]="getToolTipData(element.reason)"
                    >
                        <span>{{ element.reason | slice: 0:30 }}
                            <ng-container *ngIf="element.reason?.length > 30">...</ng-container>
                        </span>
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="incorrectDataColumns"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: incorrectDataColumns"
                ></tr>
            </table>
            <ng-container *ngIf="totalIncorrectLength>10">
                <mat-paginator
                    class="w-100"
                    #paginator
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10,25,100]"
                    [showFirstLastButtons]="true"
                    [length]="totalIncorrectLength"
                ></mat-paginator>
            </ng-container>

            <div class="row text-center float-right button-alignToEnd p-2 mt-2">
                <button
                    class="btn btn-primary float-right btn-main rounded-pill font-regular"
                    (click)="reUploadEntities()">
                    {{ "reuploadFilesWithFixes" | translate }}
                </button>
                <button
                    class="btn btn-primary float-right btn-main-white-nb rounded-pill regular-semibold-font ml-4"
                    (click)="proceedToOnboard()">
                    {{ "proceedToOnboardTheRemainingEntries" | translate }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="correctData.length>0 && incorrectData.length == 0">
            <p class="regular-semibold-font ">
                {{ "successfullyUploaded" | translate }}
        {{ noOfEntitiesUploaded }} users. Click on Proceed to onboard the users.
            </p>
            <button
                class="btn btn1 btn-primary btn-main rounded-pill font-regular float-right"
                (click)="proceedToOnboard()">
                {{ "proceed" | translate }}
            </button>
        </ng-container>
    </div>
</div>

<div *ngIf="pageType == 'onboarding_reUploadCSV'" class="px-4 py-3">
    <div class="modal-header pl-0 pr-0">
        <h6 class="semi-bold-blue">{{'reUploadFile' | translate }}</h6>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closePopup()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body align-left mt-2">
        <div class="row">
            
          <div class='col-6 pl-3'>
            <label></label>
            <button
              type="button"
              class="form-control1"
              (click)="downloadCurrentCSVFile()"
            >
              <span style="font-family: 'Open Sans'">{{
                "downloadCurrentFile" | translate
              }}</span>
              <span class="ml-3"
                ><img
                  src="./assets/images/downloadTemplate.png"
                  style="width: 1rem"
              /></span>
            </button>
          </div>
          <p class="regular-semibold-font mt-3 col-12">
            {{ 'uploadFileWithFixes' | translate }} 
        </p>  
          <div class="row w-100 pl-3">
          <div class="col-12">
            <label for="uploadFile" class="label1 float-left font-label"
              >{{ "upload" | translate }} (.xlsx
              {{ "file" | translate }})</label
            >
            <div class="input-group col-6 clear-both float-left">
              <input
                type="file"
                class="form-control custom-file-input"
                id="importFile"
                aria-describedby="inputGroupFileAddon01"
                (change)="uploadCSV($event.target.files)"
                accept=".xlsx"
                #fileUploader
              />
              <label
                class="custom-file-label1 custom-upload-btn font-label"
                for="inputGroupFile01"
                #labelImport
              ></label>
             
            </div>
            <div
              class="input-group col-6 mt-2"
              *ngIf="fileName"
            >
              <p class="card inner-card-auto text-center p-1 d-inline-block">
                <span class="text-overflow-hidden mt-2">{{
                    fileName
                }}</span>
                <button
                  type="button"
                  class="close text-right mt-2"
                  aria-label="Close"
                >
                  <span aria-hidden="true" (click)="removeUploadedFile()"
                    >&times;</span
                  >
                </button>
              </p>
            </div>
            <!-- <div
            class="row col-12 text-danger"
            *ngIf="submitted && fileName != '' || fileName != undefined"
          >
            Please upload file
          </div> -->
          </div>
        </div>
        </div>
      
        <div class="row" *ngIf="fileName">
            <div class="col">
                <button type="button" class="btn btn1 btn-main float-right rounded-pill" (click)="validateExcelOnboarding()">
                    {{'proceed' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>


<div class="px-4 py-3" *ngIf="pageType == 'ShowAlertForMapAllUser'">
    <div class="modal-header p-0">
        <h5 class="semi-bold-blue">
            {{ "alert" | translate }}
        </h5>
    </div>
  
    <div class="modal-body align-left">
    
        <div class="row mt-4">
            <p class="fontColor pl-3">
                You are attempting to add {{data['info']['totalUserCount']}} users to ”{{data['name']}}”. 
            </p>
        </div>

        <div class="row mt-2"   *ngIf="data['info']['tenantDetailsDto'].length!=0" >
            <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
                <p
             
                class="fontColorSemi "
                    style="text-align: justify;"
                >
                The below users cannot be added to this location as tenant company has not be mapped to any floor in this location. 
            </p>
          
                </div>
                <ng-container *ngFor="let ele of data['info']['tenantDetailsDto'] " >

                    <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
                        <p
                    
                        class="fontColorSemi "
                            style="text-align: justify;"
                        >
                       <span class="fontColor custonLinknew cursor-pointer" (click)="getNotMappedUsersList(ele)"  > {{ele['count']}} users </span> &nbsp;  from {{ele['tenantName']}} 
                    </p>
                  
                        </div>

                </ng-container>
                </div>

        <div class="row ">
            <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
                <p
                *ngIf="data['info']['tenantDetailsDto'].length==0"
                class="fontColorSemi "
                    style="text-align: justify;"
                >
                Are you sure you want to proceed?
            </p>
            <p
                *ngIf="data['info']['tenantDetailsDto'].length!=0"
                class="fontColorSemi "
                    style="text-align: justify;"
                >
                Are you sure you want to proceed to onboard the rest of the users?
            </p>
                </div>
                </div>
        <div class="row col-12 d-inline-block">
            <button
                class="btn btn-main  pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                type="button"
                (click)="alertYes()"
            >
                {{ "yes" | translate }}
            </button>
            <button
                class="btn btn-main btn-white pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                type="button"
                (click)="alertNo()"
            >
                {{ "no" | translate }}
            </button>
        </div>
    </div>
  </div>
  
  <div class="px-4 py-3" *ngIf="pageType == 'ShowAlertdisableUser'">
    <div class="modal-header p-0">
        <h5 class="semi-bold-blue">
            {{ "alert" | translate }}
        </h5>
    </div>
  
    <div class="modal-body align-left">
    
        <div class="row mt-4">
            <p class="fontColor pl-3 mb-1">
                You are attempting to disable ”{{data['userName']}}”. 
            </p>
        </div>
      
        <div class="row ">
            <p class="fontColor pl-3">
                User will not have access to “{{data['siteName']}}” and their additional roles (if any) will be void once disabled. 
            </p>
        </div>
        <div class="row mt-2">
            <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
                <p
                class="fontColorSemi "
                    style="text-align: justify;"
                >
                Are you sure you want to proceed?
            </p>
                </div>
                </div>
        <div class="row col-12 d-inline-block">
            <button
                class="btn btn-main  pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                type="button"
                (click)="alertYes()"
            >
                {{ "yes" | translate }}
            </button>
            <button
                class="btn btn-main btn-white pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
                type="button"
                (click)="alertNo()"
            >
                {{ "no" | translate }}
            </button>
        </div>
    </div>
  </div>




<div
class="modal-card p-4"
*ngIf="
    pageType == 'editInternalUserSuperAdmin'
  


"
>
<div class="modal-header p-0">
    <!-- <h5 class="semi-bold-blue"><i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtnUsers()" *ngIf="!individual && pageType != 'editUsers'"></i> {{pageType != 'editUsers' ? 'Add' : 'Edit'}} {{"users" | translate}} <span *ngIf="!individual">(3/3)</span></h5> -->
    <h5 class="semi-bold-blue" >
       
        Edit internal user
        <!-- <span *ngIf="!individual">(3/3)</span> -->
    </h5>
  
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
           
                closePopup()
        "
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="col-12 pl-0">
  <p class="italic mt-2 openSans pl-0 pb-4 mb-0">People</p>
</div>
<div class="modal-body align-left">
   
    <form
        [formGroup]="addUsersManuallyForm"
        (ngSubmit)="submitManuallyForm()"
    >
        <div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"emailCaps" | translate}}<span class="text-danger">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'enterEmailID' | translate}}"
                        formControlName="email"
                        [disabled]="editPeopleMode"
                        [ngClass]="{'disabled': editPeopleMode}"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersManuallyForm.controls.email.errors
                        "
                    >
                        <div class="text-danger formLabel">
                            {{"emailIsRequired" | translate}}
                        </div>
                    </div>
                </div>

            
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"firsNameCaps" | translate}}
                        <span class="text-danger">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'Entername' | translate}}"
                        formControlName="employeeName"
                        [disabled]="editPeopleMode"
                        [ngClass]="{'disabled': editPeopleMode}"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersManuallyForm.controls.employeeName
                                .errors
                        "
                    >
                        <div class="text-danger formLabel">
                            {{"firstNameIsRequired" | translate}}
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-12  ">
                    <label class="float-left formLabel"
                        >{{"lastNameCaps" | translate}}
                        <!-- <span class="text-danger">*</span> -->
                    </label>
                    <input
                        type="text"
                        class="form-control blueText"
                        placeholder="{{'Entername' | translate}}"
                        formControlName="lastName"
                        [disabled]="editPeopleMode"
                        [ngClass]="{'disabled': editPeopleMode}"
                    />
                    <div
                        *ngIf="
                            submittedUsers &&
                            addUsersManuallyForm.controls.lastName.errors
                        "
                    >
                        <div class="text-danger formLabel">
                            {{"lastNameIsRequired" | translate}}
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="row col-12">
                <p class="regular-semibold-font">
                    Is this user an Internal user or Tenant user? 
                    <span
                    matTooltipClass="custom-tooltip1"
                    matTooltipPosition="right"
                    matTooltip="{{getSpecialTooltip()}}"
                    class="light-blue-anchor ml-2 informationIcon"
                    >i</span>
                    <!-- <span class="light-blue-anchor" (click)="redirectIntro('USERS')">users?</span> -->
                </p>
            </div>
            <div class="row col-12">
                <mat-radio-group
                    aria-label=""
                    class="radioBtn d-flex flex-column h-auto"
                    [(ngModel)]="addUserTypeInternalorTenant"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="editPeopleMode"
                    [ngClass]="{'disabled': editPeopleMode}"
                    required
                >
                    <!-- <mat-radio-button id="ad_integration" value="ad_integration" class="mb-3">
                    {{ 'AD Integrations' | translate }}
                    <span class="light-blue-anchor ml-2 informationIcon">i</span>
                  </mat-radio-button> -->

                    <mat-radio-button
                        id="add_manually"
                        class="mb-3"
                        value="internal"
                    >
                        Internal
                    </mat-radio-button>

                    <mat-radio-button
                        id="add_excel"
                        class="mb-3"
                        value="tenant"
                    >
                        Tenant
                    </mat-radio-button>
                </mat-radio-group>
            </div>

<ng-container *ngIf="addUserTypeInternalorTenant=='internal'" >
         
          
         
       
    <div class="row">
        <p class="regular-semibold-font pl-3">
          {{"additionalRolesCaps" | translate}}
           <mat-slide-toggle
               class="toggleBtn ml-2"
               [disabled]="disableAditionalRoles"
               [ngClass]="{'disabled': disableAditionalRoles}"
               formControlName="additionalRoles"
           ></mat-slide-toggle>
       </p>
          <div
              class="form-group col-lg-12 col-md-12"
              *ngIf="addUsersManuallyForm.get('additionalRoles').value"
          >
              <ng-container >
                  <div class="d-flex" *ngIf="!disableAditionalRoles" >
                      <div
                          class="form-group col-lg-6 pl-0 col-md-12"
                      >
                          <label class="formLabel"
                              >Roles
                              <span class="text-danger"
                                  >*</span
                              ></label
                          >
                          <div class="input-group pl-0">
                              <mat-icon
                                  class="alignSearchIconGray pl-2"
                                  matPrefix
                                  >search</mat-icon
                              >
                              <input
                                  type="text"
                                  autocomplete="off"
                                  id="locationSelection"
                                  class="form-control blueText textIndent float-right pl-3"
                                  placeholder="Select Role"
                                  [ngClass]="{'disabled': disableAditionalRoles}"
                                  [formControl]="searchRoles"
                                  required
                                  [matAutocomplete]="auto5"
                                  #customInput5
                              />
                              <i
                                  style="position: absolute;right: 10px;top: 10px;"
                                  class="fa fa-spinner loading_span"
                                  *ngIf="nameSearchIsLoading"
                                  aria-hidden="true"
                              ></i>
                              <mat-autocomplete
                                  multiple
                                  #auto5="matAutocomplete"
                              >
                                  <ng-container
                                      *ngFor="
                                          let dropData of filteredRoles
                                              | async
                                      "
                                  >
                                      <mat-option
                                          class="autocompleteSearch"
                                         
                                          [value]="dropData.name"
                                          (click)="
                                          selectCustomRole(
                                                  dropData
                                              )
                                          "
                                      >
                                          <span>{{
                                            getNameWithoutUnderscore(dropData.name)
                                              
                                          }}</span>
                                      </mat-option>
                                  </ng-container>
                              </mat-autocomplete>
                          </div>
                          <div
                              *ngIf="
                                  submittedUsers &&
                                  customRoleList.length == 0
                              "
                          >
                              <p class="text-danger formLabel">
                                 Roles is required
                              </p>
                          </div>
                      </div>
                      <button
                      [ngClass]="{'disabled': disableAditionalRoles}"
                          class="smallBtnCircleAdd semi-bold-blue mt-4 ml-3"
                          (click)="tagCustomLocationsNonTeams()"
                          type="button"
                      >
                          +
                      </button>
                  </div>
                  <ng-container
                      *ngIf="customRoleList.length > 0"
                  >
                      <div class="col-12 d-flex ">
                          <div
                              class="form-group w-fit ml-1"
                              *ngFor="
                                  let role of customRoleList;
                                  let i = index
                              "
                          >
                              <div
                              [ngClass]="{'disabled': disableAditionalRoles}"
                                  class="inner-card-auto new-chip justify-content-between align-items-center py-1"
                              >
                                  <span
                                  style="color: #0f1f54;"
                                      class="gray-regular font-small mr-1"
                                      >{{
                                        getNameWithoutUnderscore(role.name)
                                        }}</span
                                  >
                                  <span
                                  *ngIf="!disableAditionalRoles"
                                      aria-hidden="true"
                                      (click)="
                                          removeCustomRole(
                                              i
                                          )
                                      "
                                      class="cursor-pointer"
                                      >&times;</span
                                  >
                              </div>
                          </div>
                      </div>
                  </ng-container>
              </ng-container>
            

             
          </div>
      </div>
        </ng-container>
  
            <div class="row mt-2">
                <div class="form-group col-lg-12 d-flex col-md-12 mb-0">
                    <p
                        class="p-0 blueText col-12"
                     
                    >
                    *All users are tagged to a general user role. 
                        
                    </p>
                  
                
                   
                </div>
            </div>
            <div class="row "> <div class="col-12 float-right px-0">
              <button
              *ngIf="customRoleList.length > 0 && !disableAditionalRoles"
                  class="btn btn-main btnSmall float-right  semi-bold-blue ml-4 mr-2 smallBtn float-right"
                  type="submit"
              >
                  {{"save" | translate}}
              </button>
             
          </div></div>
        </div>
    </form>
</div>
</div>


<div class="modal-card p-4" *ngIf="pageType == 'showUnMappedUsers'">
    <div class="modal-header p-0">
      <h5>
        <i class="fa fa-angle-left mr-2 arrowIcon" (click)="backBtnUnmappedUsers()" ></i>
        
        Alert</h5>
     
    </div>
  
    <div class="modal-body width-400 align-left">
        <div class="col-lg-12 mt-neg-1 p-0 ">
            <p class="fontColor mt-3">
                The below list of users from {{notMappedEle['tenantName']}} cannot be onboarded as the tenant is not mapped to {{data['name']}}.
            </p>
        </div>
      <div class="col-lg-12 mt-neg-1 p-0">
       
        <div class="col-lg-12 p-0 col-md-12 col-sm-12 mt-3">
          <table
            class="mat-sort_table"
            mat-table
            [dataSource]="dataSource"
            matSort
            matSortActive="category"
            matSortDirection="desc"
          >
            <!-- <table class="mat-sort_table" mat-table [dataSource]="dataSource" matSort matSortActive="parentGroup"
                matSortDirection="desc"> -->
            <!-- Sl No -->
            <ng-container matColumnDef="name">
              <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                        (click)="sortingData('parentGroup')"> -->
              <th mat-header-cell *matHeaderCellDef class="align-left">
                Name
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = dataIndex"
                class="align-left"
              >
                {{ element.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                        (click)="sortingData('parentGroup')"> -->
              <th mat-header-cell *matHeaderCellDef class="align-left">
                Email
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = dataIndex"
                class="align-left"
              >
                {{ element.email }}
              </td>
            </ng-container>
  
           
  
            <ng-container matColumnDef="status">
              <!-- <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header
                        (click)="sortingData('parentGroup')"> -->
              <th mat-header-cell *matHeaderCellDef class="align-left">
              Status
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = dataIndex"
                class="align-left"
              >
                Tenant not mapped to {{data['name']}}
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div class="col-12" *ngIf="dataSource?.data?.length == 0">
            <p class="text-center bolder mt-2">
              {{ "No Data is available" | translate }}
            </p>
          </div>
        </div>
        <mat-paginator
          class="w-100 mtop-1"
          #paginator
          [pageSize]="pageSizeMat"
          [pageSizeOptions]="pageSizeOptions"
          [showFirstLastButtons]="true"
          (page)="getPrevOrNextRecords($event)"
          [length]="length"
        >
        </mat-paginator>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <button
         (click)="alertYes()"
            class="btn btn-main pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
          >
          Proceed to onboard remaining users
          </button>
          <button
          (click)="navigateToBuilding()"
          class="btn btn-main btn-white pull-right f-size-small pl-4 pr-4 btn-radius p-1 m-1 filter btn-primary"
        >
          Add company to location
        </button>
        </div>
      </div>
    </div>
  </div>
  