<!-- Confirm permission enabled for outlook page -->
<div *ngIf="pageType == 'outlook_permissions'"> 
    <div class="modal-header pl-0">
        <h5>Office 365</h5>
        <button type="button" class="close" aria-label="Close" (click)="confirmDisableOffice365('outlook_permissions')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 pl-4">
        <p class="italic mt-2 openSans">{{'configureTools' | translate}}</p>
    </div>
    <div class="col-12 mt-4 pl-4">
        <p class="dark-blue">{{'pleaseensure' | translate}}</p>
        <p class="font-regular-gray mt-4"><i *ngIf="readCheck" class="fa fa-check"></i> Calendars.Read</p>
        <p class="font-regular-gray"><i *ngIf="writeCheck"  class="fa fa-check"></i> Calendars.ReadWrite</p>
    </div>
    <div class="form-group row col-12 text-left mb-4 mt-5">
        <div class="col-9">
            <!-- <a target="_blank" class="dark-blue text-underline pl-2">{{"ReadMoreAboutOutlook" | translate}}</a> -->
        </div>
        <div class="col-3">
            <button class="btn btn-primary btn-main btnSmall btn-radius mr-2p font-regular" type="button"
            (click)="openApplicationTenant()">
                {{'next' | translate}}
            </button>
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'outlook_application'">
    <div class="modal-header pl-0">
        <h5><i class="fa fa-angle-left cursor-pointer backBtn mr-2"  aria-hidden="true" (click)="openPrevModal('outlook_permissions')"></i>Office 365</h5>
        <button type="button" class="close" aria-label="Close" (click)="confirmDisableOffice365('outlook_application')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 pl-4 mt-2">
        <p class="italic openSans">{{'configureTools' | translate}}</p>
    </div>
    <div class="modal-body width-400 align-left removeScroll">
        <div class="form-group align-left mb-0">
          
            <!-- <div class="card-title">
                {{'setupofficeoroutlookintegration' | translate}}
            </div> -->
            <div class="card-body custom-integration-card-body">
                <div class="row">
                    <form class="form-group" id="outlook_details" [formGroup]="outlookForm" (ngSubmit)="checkPermissions()">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="applicationClientId">Application Client ID</label>
                                <input type="text" class="form-control blue-regular" 
                                    formControlName="applicationClientID" [(ngModel)]="outlookConfig.appOfficeClientId"
                                    [ngClass]="{ 'is-invalid': submittedOutlook && outLookf.applicationClientID.errors }"
                                    placeholder="Enter here">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="dirTenantId">Directory Tenant ID</label>
                                <input type="text" class="form-control blue-regular" id="dir_id"
                                    formControlName="applicationTenantID" [(ngModel)]="outlookConfig.appOfficeTenant"
                                    [ngClass]="{ 'is-invalid': submittedOutlook && outLookf.applicationTenantID.errors }"
                                    placeholder="Enter here">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="clientSecret">Client Key Secret</label>
                                <input type="password" class="form-control blue-regular" id="client_secret"
                                    formControlName="applicationClientSecret" [(ngModel)]="outlookConfig.appOfficeClientSecret"
                                    [ngClass]="{ 'is-invalid': submittedOutlook && outLookf.applicationClientSecret.errors }"
                                    placeholder="Enter here">
                            </div>
                            <div class="form-group col-md-12 mt-4 mb-0">
                                <div class="float-right">
                                    <button type="submit" id="test_card" class="btn btn-primary btn-main btnSmall btn-radius mr-2p font-regular">
                                        {{'next' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'mail_integration'">
    <div class="modal-header pl-0">
        <h5><i class="fa fa-angle-left cursor-pointer backBtn mr-2"  aria-hidden="true" (click)="openApplicationTenant()"></i>Office 365</h5>
        <button type="button" class="close" aria-label="Close" (click)="confirmDisableOffice365('mail_integration')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 pl-4 mt-2">
        <p class="italic openSans">{{'configureTools' | translate}}</p>
    </div>
    <div class="modal-body width-400 align-left pb-0">
        <div class="form-group align-left">
            <form class="form-group" id="outlook_email_details" [formGroup]="outlookEmailForm" (ngSubmit)="checkOutlookEmailPermissions()">
                <div class="row col-12">
                    <div class="form-row">
                        
                        <div *ngIf="!validateIntegration" class="form-group col-md-12">
                            <p for="inputAccountNumber" class="font-regular-gray mb-2" style="font-size: 12px;">Enter valid mail box (This should have an active Office 365 license)</p>
                            <input type="email" class="form-control blue-regular" id="outlook_email"
                            formControlName="outlookEmail" [(ngModel)]="outlookConfig.outlookEmail"
                            [ngClass]="{ 'is-invalid': submittedEmailOutlook && outLookEmailf.outlookEmail.errors }" placeholder="Enter here">
                        </div>
                        <div *ngIf="!validateIntegration" class="form-group col-md-12">
                            <p for="inputAccountNumber" class="font-regular-gray mb-2" style="font-size: 12px;">Enter resource Email ID(Eg.meeting room email ID)</p>
                            <input type="email" class="form-control blue-regular" id="meeting_room_email"
                            formControlName="meetingRoomEmail" [(ngModel)]="outlookConfig.meetingRoomEmail"
                            [ngClass]="{ 'is-invalid': submittedEmailOutlook && outLookEmailf.meetingRoomEmail.errors }" placeholder="Enter here">
                            <p *ngIf="submittedEmailOutlook && outLookEmailf.meetingRoomEmail.errors" class="errorMessage">Please enter a valid email ID</p>
                        </div>

                        <div *ngIf="validateIntegration" class="form-group col-md-12">
                            <label for="applicationClientId">Application Client ID</label>
                            <input type="text" class="form-control font-regular-gray" [(ngModel)]="outlookConfig.appOfficeClientId"
                                [ngModelOptions]="{standalone: true}" id="application_client_id" [disabled]="validateIntegration">
                        </div>
                        <div *ngIf="validateIntegration" class="form-group col-md-12">
                            <label for="dirTenantId">Directory Tenant ID</label>
                            <input type="text" class="form-control font-regular-gray" id="dir_id"
                                [(ngModel)]="outlookConfig.appOfficeTenant" [disabled]="validateIntegration"
                                [ngModelOptions]="{standalone: true}">
                        </div>
                        <div *ngIf="validateIntegration" class="form-group col-md-12">
                            <label for="clientSecret">Client Key Secret</label>
                            <input type="password" class="form-control font-regular-gray" id="client_secret"
                                [(ngModel)]="outlookConfig.appOfficeClientSecret" [disabled]="validateIntegration"
                                [ngModelOptions]="{standalone: true}">
                        </div>

                        <div *ngIf="!validateIntegration" class="form-group col-md-12">
                            <div class="float-right mt-3">
                                <button type="button" id="test_card" *ngIf="skipOutlookIntegration && !editOutlook" class="btn btn-White btn-radius-white font-regular pl-4 pr-4" (click)="closePopup()">
                                    {{'skipIntegrationForNow' | translate}}</button>
                                <button type="submit" id="test_card" class="btn btn-main btnSmall btn-radius font-regular ml-2 mb-0">
                                    {{'validate' | translate}}</button> <!--(click)="validateWholeIntegration()"-->
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div  *ngIf="validateIntegration" class="form-group col-md-12">
                <button type="button" id="test_card" class="btn btn-main btnSmall float-right btn-radius mb-3 font-regular"
                    (click)="enableOutlook()">
                    {{'save' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'proceedToDisableOffice365'">
    <div class="modal-header pl-0">
        <h5>{{'alert' | translate}}</h5>
        <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="col-12 pl-4">
        <p class="italic mt-2 openSans">{{'configureTools' | translate}}</p>
    </div>
    <div class="col-12 pl-4">
        <p class="blue-regular">
            {{'proceedToDiscardOfficeIntegration' | translate}}
        </p>
    </div>
    <div class="modal-body width-400 mt-2" style="padding-right: 15px !important;">
        <div class="form-group col-12 mb-0 pr-0">
            <button class="btn btn-primary btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="closePopup()">
                {{"yes" | translate}}
            </button>
            <button class="btn btn-White btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="openPrevModal(prevModal)">
                {{"no" | translate}}
            </button>
            
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'proceedToNotUpdateOffice365'">
    <div class="modal-header pl-0">
        <h5>{{'alert' | translate}}</h5>
        <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="col-12 pl-4">
        <p class="italic mt-2 openSans">{{'configureTools' | translate}}</p>
    </div>
    <div class="col-12 pl-4">
        <p class="blue-regular">
            {{'proceedToNotToUpdateOfficeIntegration' | translate}}
        </p>
    </div>
    <div class="modal-body width-400 mt-2" style="padding-right: 15px !important;">
        <div class="form-group col-12 mb-0 pr-0">
            <button class="btn btn-primary btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="closePopup()">
                {{"yes" | translate}}
            </button>
            <button class="btn btn-White btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="openPrevModal(prevModal)">
                {{"no" | translate}}
            </button>
            
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'disableOutlook'">
    <div class="modal-header pl-0">
        <h5>{{'alert' | translate}}</h5>
        <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="col-12 pl-4">
        <p class="italic mt-2 openSans">{{'disableIntegration' | translate}}</p>
    </div>
    <div class="col-12 pl-4 pr-4">
        <p class="blue-regular">
            {{ "disableOutlook" | translate }} <span class="semi-bold-blue">{{"office365" | translate}}</span> {{"wouldyoulike" | translate}} <span class="semi-bold-blue">{{"outlookcalendar" | translate}}</span>
        </p>
        <p class="blue-regular" *ngIf="disableOutlookWithTeams">
            {{'disableOutlookWithTeams' | translate}}
        </p>
        <p class="blue-regular">
            {{'wouldYouLikeToProceed' | translate}}
        </p>
    </div>
    <div class="modal-body width-400 mt-4" style="padding-right: 15px !important;">
        <div class="form-group col-12 mb-0 pr-0">
            <button class="btn btn-primary btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="proceedToDisable('OFFICE365')">
                {{"yes" | translate}}
            </button>
            <button class="btn btn-White btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="closePopup()">
                {{"no" | translate}}
            </button>
            
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'disableTeams'">
    <div class="modal-header pl-0">
        <h5>{{'alert' | translate}}</h5>
        <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="col-12 pl-4">
        <p class="italic mt-2 openSans">{{'disableIntegration' | translate}}</p>
    </div>
    <div class="col-12 pl-4 pr-4">
        <p class="blue-regular">
            {{'disableTeams' | translate}}
        </p>
        <p class="blue-regular">
            {{'wouldYouLikeToProceed' | translate}}
        </p>
    </div>
    <div class="modal-body width-400 mt-4" style="padding-right: 15px !important;">
        <div class="form-group col-12 mb-0 pr-0">
            <button class="btn btn-primary btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="proceedToDisable('Teams')">
                {{"yes" | translate}}
            </button>
            <button class="btn btn-White btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="closePopup()">
                {{"no" | translate}}
            </button>
            
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'enableTeamsAlert'">
    <div class="modal-header pl-0">
        <h5>{{'alert' | translate}}</h5>
        <!-- <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="col-12 pl-4">
        <p class="italic mt-2 openSans">{{'enableTeams' | translate}}</p>
    </div>
    <div class="col-12 pl-4 pr-4">
        <p class="blue-regular">
            {{'enableOffice365InOrderToEnableTeams' | translate}}
        </p>
    </div>
    <div class="modal-body width-400 mt-4" style="padding-right: 15px !important;">
        <div class="form-group col-12 mb-0 pr-0">
            <button class="btn btn-primary btn-main btnSmall btn-radius mr-2 float-right font-regular" type="button"
                (click)="closePopup()">
                {{"ok" | translate}}
            </button>
        </div>
    </div>
</div>

<div class="" *ngIf="pageType == 'zoomUserConfig'">
    <div class="modal-header pl-0">
        <h5>{{'zoom' | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 pl-4 mt-2">
        <p class="italic openSans">{{'configureVideoConferencingLinks' | translate}}</p>
    </div>
    <div class="modal-body width-400 align-left removeScroll">
        <div class="form-group align-left mb-0">
            <div class="card-body custom-integration-card-body">
                <div class="row">
                    <form class="form-group" id="user_zoom_details" [formGroup]="zoomUserLevelForm" (ngSubmit)="configureUserZoom()">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="applicationClientId">Application Client ID</label>
                                <input type="text" class="form-control blue-regular" 
                                    formControlName="applicationClientID" [(ngModel)]="zoomConfig.applClientId"
                                    [ngClass]="{ 'is-invalid': submittedUserZoom && zoomUserf.applicationClientID.errors }"
                                    placeholder="Enter here">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="clientSecret">Client Key Secret</label>
                                <input type="password" class="form-control blue-regular" id="client_secret"
                                    formControlName="applicationClientSecret" [(ngModel)]="zoomConfig.clientSecret"
                                    [ngClass]="{ 'is-invalid': submittedUserZoom && zoomUserf.applicationClientSecret.errors }"
                                    placeholder="Enter here">
                            </div>
                            <div class="form-group col-md-12 mt-4 mb-0">
                                <div class="float-right">
                                    <button type="submit" id="test_card" class="btn btn-primary btn-main btnSmall btn-radius mr-2p font-regular">
                                        {{'save' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="outlook_permissions" *ngIf="pageType == 'outlook_permissions_fail'"> 
    <div class="modal-header pl-0">
        <h5>Office 365</h5>
        <button type="button" class="close" aria-label="Close" (click)="confirmDisableOffice365('outlook_permissions_fail')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-12 pl-4">
        <p class="italic mt-2 openSans">{{'configureTools' | translate}}</p>
    </div>
    <div class="col-12 pl-4">
        <p class="dark-blue">{{'pleaseensure' | translate}}</p>
        <p class="openSans mt-4"><i *ngIf="readCheck" class="fa fa-check"></i> <i *ngIf="!readCheck" class="fa fa-close" style="color: red"></i> Calendars.Read</p>
        <p class="openSans"><i *ngIf="writeCheck"  class="fa fa-check"></i>  <i  *ngIf="!writeCheck"  class="fa fa-close" style="color: red"></i> Calendars.ReadWrite</p>
    </div>
    <div class="form-group row col-12 text-left mb-4 mt-4">
        <div class="col-9">
            <p  class="dark-blue semiBold pl-2" style="font-size: 12px;">{{'pleaseensureallabove' | translate}}</p>
        </div>
        <!-- <div class="col-9">
            <a target="_blank" class="dark-blue text-underline pl-2">{{"ReadMoreAboutOutlook" | translate}}</a>
        </div> -->
        <div class="col-3">
            <button type="button" id="test_card" class="btn btn-primary btn-main btn-radius font-regular float-right mt-5" (click)="closePopup()">
                {{'skipIntegrationForNow' | translate}}</button>
            <!-- <button class="btn btn-primary btn-main btn-radius mr-2p float-right mt-5 semiBold" type="button"
            (click)="skipIntegrationForNow()">
                {{'skip' | translate}}
            </button> -->
        </div>
    </div>
</div>
